import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import DataGridCustomize from '@components/DataGrid';
import WorkDayApi from "@/apis/beta/Employee/WorkDayApi";
import { useNotificationContext } from "@contexts/notification";
import { Box, Popper, Button, Typography, IconButton, Select, MenuItem, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Stack } from "@mui/material";
import { AddOutlined, MoreVertOutlined, FilterListOutlined as FilterListOutlinedIcon } from '@mui/icons-material';
import PopperAction from '@components/PopperAction';
import { Link, useLocation } from 'react-router-dom';
import { format_date_short } from "@/functions";

const ListHoliday = () => {
    const location = useLocation();
    let navigate = useNavigate();
    const [page, setPage] = useState({
        page: 1,
        pageSize: 10,
        total: 0
    });
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedYear, setSelectedYear] = useState("");
    const [totalHolidays, setTotalHolidays] = useState(0);
    const { data, refetch: refetchHoliday } = WorkDayApi.listHoliday({
        page: page.page,
        pageSize: page.pageSize,
        month: selectedMonth,
        year: selectedYear
    });
    const [popper, setPopper] = useState({
        anchorEl: undefined,
        data: undefined
    });
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);

    useEffect(() => {
        if (data && data.holidays) {
            setTotalHolidays(data.pagination.total);
        }
    }, [data]);

    const handleOpenEditForm = (holidayId) => {
        navigate(`/workdays/holiday/${holidayId}`);
    };

    const [deleteId, setDeleteId] = useState(null);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const { setNotification } = useNotificationContext();
    const handleOpenDeleteConfirmation = (holidayId) => {
        setDeleteConfirm(true);
        setDeleteId(holidayId);
    };
    const handleDelete = async () => {
        if (!deleteId) {
            console.error('Không tìm thấy id');
            return;
        }
        try {
            await WorkDayApi.DeleteHoliday(deleteId);
            setNotification({
                open: true,
                message: 'Xóa thành công!',
                severity: 'success'
            });
            refetchHoliday();
        } catch (error) {
            console.error('Lỗi khi xóa:', error);
        }
        setDeleteConfirm(false);
        setDeleteId(null);
    };

    useEffect(() => {
        if (location.pathname === "/workdays/holiday") {
            setPage((prevPage) => ({ ...prevPage, page: 1 }));
            refetchHoliday({
                page: page.page,
                pageSize: page.pageSize,
                month: selectedMonth,
                year: selectedYear
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, selectedMonth, selectedYear, refetchHoliday]);

    const [actions] = React.useState([
        {
            order: 1,
            name: "Edit",
            icon: "Edit",
            label: "Chỉnh sửa",
            handleClick: (data) => {
                handleOpenEditForm(data.holiday_id);
            }

        },
        {
            order: 1,
            name: "Delete",
            icon: "Delete",
            label: "Xóa",
            handleClick: (data) => {
                handleOpenDeleteConfirmation(data.holiday_id);
            }
        }
    ]);

    const columns = [
        {
            field: 'holiday_id', headerName: 'ID', width: 100,
            valueGetter: ({ row }) => row?.holiday_id,
            renderCell: ({ value }) => (
                <Link to={`/workdays/holiday/${value}`} replace>
                    <Typography className="react-link-table">{value}</Typography>
                </Link>
            ),

        },
        {
            field: "holiday_date", headerName: "Ngày nghỉ", width: 200,
            renderCell: (params) => (
                <div>
                    {format_date_short(params.value)}
                </div>
            )
        },
        { field: "holiday_name", headerName: "Tên ngày nghỉ", width: 300 },
        {
            field: 'actions', headerName: 'Thao Tác',
            hideable: true,
            width: 100,
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <IconButton
                            size='small'
                            onClick={(event) => {
                                setPopper(prev => ({ ...prev, anchorEl: event?.target, data: row, actions: actions }))
                            }}
                            title='Hành động'
                        >
                            <MoreVertOutlined fontSize='small' sx={{ m: 'auto' }} />
                        </IconButton>
                    </Box>
                )
            }
        }
    ];

    const filteredHolidays = data && data.holidays
        ? data.holidays.filter(holiday => {
            if (selectedMonth && selectedYear) {
                const holidayDate = new Date(holiday.holiday_date);
                return holidayDate.getMonth() + 1 === parseInt(selectedMonth) && holidayDate.getFullYear() === parseInt(selectedYear);
            } else if (selectedMonth) {
                return new Date(holiday.holiday_date).getMonth() + 1 === parseInt(selectedMonth);
            } else if (selectedYear) {
                return new Date(holiday.holiday_date).getFullYear() === parseInt(selectedYear);
            } else {
                return true;
            }
        })
        : [];

    const currentYear = new Date().getFullYear();

    return (
        <Box sx={{ mt: 1.5, mb: 2 }}>
            {popper?.anchorEl !== undefined && <Popper anchorEl={popper?.anchorEl}
                open={Boolean(popper.anchorEl)} style={{ zIndex: 1000 }}>
                <PopperAction {...popper}
                    actions={actions}
                    handleClose={() => setPopper(prev => ({ ...prev, anchorEl: undefined, data: undefined }))} />
            </Popper>}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography sx={{ fontSize: { xs: 18, md: 24 }, fontWeight: "medium" }}>Danh Sách Ngày Nghỉ</Typography>
                <Link to="/workdays/holiday/new" replace>
                    <Button
                        variant="contained"
                        size="small"
                        startIcon={<AddOutlined fontSize="small" />}
                        sx={{
                            height: { xs: 40, md: 42 },
                            minWidth: { xs: '100%', md: 180 },
                        }}
                    >
                        Thêm ngày nghỉ
                    </Button>
                </Link>
            </Box>
            <Box>
                <Paper sx={{ p: 2, mb: 2, width: { xs: '100%', sm: 300 } }}>
                    <Typography sx={{ color: '#777680', mb: 1 }}>Tổng Ngày Nghỉ/Lễ:</Typography>
                    <Typography sx={{ color: '#00000F', fontWeight: "bold", fontSize: { xs: 20, md: 28 } }}>
                        {totalHolidays}
                    </Typography>
                </Paper>
                <Button
                    startIcon={<FilterListOutlinedIcon />}
                    variant="outlined"
                    size="small"
                    onClick={(event) => setFilterAnchorEl(event.currentTarget)}
                    sx={{
                        "&.MuiButton-root": {
                            height: 36,
                            m: "auto 0",
                        },
                    }}
                >
                    Bộ Lọc
                </Button>
                <Popper
                    open={Boolean(filterAnchorEl)}
                    anchorEl={filterAnchorEl}
                    placement="bottom-start"
                >
                    {({ TransitionProps }) => (
                        <Paper sx={{
                            width: {
                                xs: "400px",
                                sm: "350px",
                              },
                              maxWidth: "100%",
                        }}>
                            <Box sx={{ p: 2 }}>
                                <Typography sx={{ fontSize: { xs: 14, md: 18 }, fontWeight: "medium", color: "rgb(26, 35, 126)" }}>Chọn tháng</Typography>
                                <Select
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(e.target.value)}
                                    fullWidth
                                    size="small"
                                    sx={{ mt: 2 }}
                                    displayEmpty
                                >
                                    <MenuItem value="">Tất cả</MenuItem>
                                    {[...Array(12).keys()].map((month) => (
                                        <MenuItem key={month + 1} value={(month + 1).toString()}>{`Tháng ${month + 1}`}</MenuItem>
                                    ))}
                                </Select>
                                <Typography sx={{ fontSize: { xs: 14, md: 18 }, fontWeight: "medium", mt: 2, color: "rgb(26, 35, 126)" }}>Chọn năm</Typography>
                                <Select
                                    value={selectedYear || currentYear.toString()}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    fullWidth
                                    size="small"
                                    sx={{ mt: 2 }}
                                    displayEmpty
                                >

                                    {[...Array(currentYear - 2000 + 1)].map((_, index) => {
                                        const year = currentYear - index;
                                        return (
                                            <MenuItem key={year} value={year}>
                                                {year}
                                            </MenuItem>
                                        );
                                    })}

                                </Select>
                                <Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ mt: 2 }}>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => setFilterAnchorEl(null)}
                                        sx={{ mt: 2 }}
                                    >
                                        Đóng
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                            setFilterAnchorEl(null);
                                            refetchHoliday();
                                        }}
                                        sx={{ mt: 2 }}
                                    >
                                        Lọc
                                    </Button>
                                </Stack>
                            </Box>
                        </Paper>
                    )}
                </Popper>
            </Box>
            <Box>
                <DataGridCustomize
                    rows={filteredHolidays}
                    columns={columns}
                    // rowCount={data?.pagination?.total_pages * page?.pageSize || 0}
                    rowCount={data?.pagination?.total || 0}
                    componentsProps={{
                        pagination: {
                            rowsPerPageOptions: [10, 20, 50, 100],
                            page: page?.page - 1 || 0,
                            pageSize: page?.pageSize || 20,
                            onPageChange: (newPage) =>
                                setPage((prevState) => ({ ...prevState, page: newPage + 1 })),
                            onPageSizeChange: (newPageSize) =>
                                setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
                        },
                        onEdit: handleOpenEditForm,
                    }}
                    sx={{ mt: 2 }}
                    getRowId={(row) => row?.holiday_id}
                />
            </Box>
            <Dialog open={deleteConfirm} onClose={() => setDeleteConfirm(false)}>
                <DialogTitle>Xác nhận xóa</DialogTitle>
                <DialogContent>
                    <Typography>Bạn có chắc chắn xóa ngày nghỉ này?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteConfirm(false)}>Để Sau</Button>
                    <Button onClick={handleDelete}>Xóa</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default withSuspense(
    withPermission(ListHoliday, {
        feature: "workdays",
        action: "list-holiday",
    }),
    MuiSkeleton["GridInformation"]
);






