import Layout from "@/components/Layout";
import { useLayoutContext } from "@/contexts/layout";
import Dashboard from "@features/Dashboard";
import Helper from "@features/Helper";
import Account from "@features/Module/Account";
import Contact from "@features/Module/Contact";
import Customer from "@features/Module/Customer";
import CustomerSpam from "@features/Module/CustomerSpam";
import HRM from "@features/Module/Employee";
import Invoice from "@features/Module/Invoice";
import Service from "@features/Module/Service";
import Setting from "@features/Module/Setting";
import { Box } from "@mui/material";
import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";

import Changelog from "@/features/Module/Changelog";
import CustomerContract from "@/features/Module/Contract/CustomerContract";
import Loading from "@components/shared/MuiSkeleton/Loading";
import CustomerContextProvider from "@contexts/customer";
import StateArrayContextProvider from "@contexts/stateArray";
import Assignee from "@features/Module/Assignee";
import BusinessNotification from "@features/Module/BusinessNotification";
import Document from "@features/Module/Document";
import Drives from "@features/Module/Drives";
import Report from "@features/Module/Report";
import Technical from "@features/Module/Technical";
import Ticket from "@features/Module/Ticket";
import Vcard from "@features/Module/Vcard";
import Warehouse from "@features/Module/WarehouseNew";
import PermissionDenied from "../PermissionDenied";
import NotFound from "../NotFound";
// const DASHBOARD_HIGH_LAYOUT = ["sales", "sale", "account"];

const Business = HRM.ManagementBusiness;
const Dayoff = HRM.ManagementDayOff;
const Employee = HRM.ManagementEmployee;
const Resume = HRM.ManagementResume;
const WorkDay = HRM.ManagementWorkDay;

const ContextProviders = {
  default: Fragment,
  customer: CustomerContextProvider,
  contract: StateArrayContextProvider
};

const DashboardRoute = ({ employee }) => {
  const { isCollapse, permissions, permissionsPending } = useLayoutContext();

  const HelperWorkspace =
    Helper[employee?.employee_department] || Helper.Default;

  const pages = [
    { name: "service", module: { name: "Service", Element: Service } },
    { name: "invoice", module: { name: "Invoice", Element: Invoice } },
    { name: "contact", module: { name: "Contact", Element: Contact } },
    { name: "customer", module: { name: "Customer", Element: Customer } },

    {
      name: "customer",
      module: { name: "CustomerSpam", Element: CustomerSpam },
    },
    { name: "employee", module: { name: "Employee", Element: Employee } },
    { name: "dayoff", module: { name: "Dayoff", Element: Dayoff } },
    { name: "dayoff", module: { name: "Account", Element: Account } },
    { name: "business", module: { name: "Business", Element: Business } },
    { name: "business", module: { name: "Account", Element: Account } },
    { name: "resume", module: { name: "Resume", Element: Resume } },
    { name: "assignee", module: { name: "Assignee", Element: Assignee } },
    { name: "businessnotification", module: { name: "BusinessNotification", Element: BusinessNotification } },
    {
      name: "workdays",
      module: {
        name: "WorkDay",
        Element: WorkDay,
      },
    },
    {
      name: "changelog",
      module: { name: "Changelog", Element: Changelog },
    },
    {
      name: "contract",
      module: { name: "CustomerContract", Element: CustomerContract },
    },

    { name: "account", module: { name: "Account", Element: Account } },
    { name: "product", module: { name: "Product", Element: Setting.Product } },
    { name: "permission", module: { name: "Role", Element: Setting.Role } },
    {
      name: "group-role",
      module: { name: "GroupRole", Element: Setting.GroupRole },
    },
    {
      name: "document",
      module: { name: "Document", Element: Document },
    },
    {
      name: "drives",
      module: { name: "Drives", Element: Drives },
    },
    {
      name: "vcard",
      module: { name: "Vcard", Element: Vcard },
    },
    {
      name: "warehouse",
      module: { name: "Warehouse", Element: Warehouse },
    },
    {
      name: "report",
      module: { name: "report", Element: Report },
    },
    {
      name: "technical",
      module: { name: "technical", Element: Technical },
    },
    {
      name: "ticket",
      module: { name: "ticket", Element: Ticket },
    },
    {
      name: "technical",
      module:
        { name: "reports", Element: Technical },
    },
    {
      name: "scope",
      module:
        { name: "scope", Element: Setting.Scope },
    },
    {
      name: "department",
      module:
        { name: "department", Element: Setting.Department },
    },
  ];

  const domRouteWorkspaceBaseonEmployeeDepartment = () => {
    let Workspace = Dashboard.Default;
    // if (!employee?.employee_department)
    //   return <Route exact path="" element={<NotFound />} />;
    // if (
    //   DASHBOARD_HIGH_LAYOUT?.includes(
    //     employee?.employee_department?.toLowerCase()
    //   )
    // ) {
    //   Workspace = Dashboard["CustomerCare"];
    //   const Provider = getContextProvider("customer");

    //   const Layout = Customer.Report.Layout;

    //   if (!Provider || !Layout) {
    //     return <Route exact path="" element={<Dashboard.Default />} />;
    //   }

    //   [
    //     {
    //       pathname: "",
    //       Component: Customer.Report.QualifiedProspect,
    //     },
    //   ]?.map((item) => {
    //     const Component = Dashboard.Default;
    //     return (
    //       <Route
    //         key={item?.pathname}
    //         exact
    //         path={item?.pathname}
    //         element={React.createElement(
    //           Provider || Fragment,
    //           null,
    //           React.createElement(
    //             Layout || Fragment,
    //             null,
    //             React.createElement(Component || Fragment)
    //           )
    //         )}
    //       />
    //     );
    //   });
    // }
    return <Route exact path="" element={<Workspace />} />;
  };

  const getComponent = (module, string) => {
    let Component = module;

    const keys = string?.split(".");

    for (let i = 1; i < keys.length; i++) {
      Component = Component?.[keys[i]];
    }

    return Component;
  };

  const getLayout = (module, string) => {
    const keys = string.split(".");
    let Layout = module;

    for (let i = 1; i < keys.length; i++) {
      Layout = module?.[keys[i]]?.["Layout"];
      if (Layout) break;
    }

    if (!Layout) {
      Layout = module?.Layout;
    }

    return Layout;
  };

  const getContextProvider = (name) => {
    // ex: customer
    if (!ContextProviders[`${name}`]) return ContextProviders["default"];
    return ContextProviders[`${name}`] || ContextProviders["default"];
  };

  const buildRoute = (permissions) => {
    const allActions = permissions
      ?.reduce((final, item) => {
        final?.push(...item?.actions);
        return final;
      }, [])
      ?.filter((item) => item?.pathname);

    return [
      ...allActions?.map((item) => {
        const module = pages?.find(
          (page) => page?.name === item?.feature
        )?.module;

        const Component = getComponent(module?.Element, item?.module);
        const Layout = item?.is_outlet
          ? getLayout(module?.Element, item?.module)
          : null;

        const Provider = getContextProvider(item?.feature);

        // if (item?.is_outlet && !Layout) {
        //   console.warn(
        //     `Mising Layout in features: ${item?.feature}, pathname: ${item?.pathname}`
        //   );
        // }

        // if (!Component) {
        //   console.warn(
        //     `Mising Component in features: ${item?.feature}, pathname: ${item?.pathname}`
        //   );
        // }

        return (
          <Route
            key={item?.pathname}
            exact
            path={item?.pathname}
            element={React.createElement(
              Provider || Fragment,
              null,
              React.createElement(
                Layout || Fragment,
                null,
                React.createElement(Component || Fragment)
              )
            )}
          />
        );
      }),
    ];
  };

  return (
    <Layout>
      <Box
        sx={{
          width: {
            xs: "100%",
            md: isCollapse ? "calc(100% - 60px)" : "calc(100% - 240px)",
          },
          p: 2,
        }}
      >
        {permissionsPending ? (
          <Loading />
        ) : (
          <Routes>
            {permissions?.length > 0 && domRouteWorkspaceBaseonEmployeeDepartment()}
            {permissions?.length > 0 && buildRoute(permissions)}
            <Route exact path="/helpers" element={<HelperWorkspace />} />
            <Route
              exact
              path="/schedules/new"
              element={<Setting.Shedule.Create />}
            />
            <Route exact path="/*" element={permissions?.length === 0 ? <NotFound /> : <PermissionDenied />} />
          </Routes>
        )}
      </Box>
    </Layout>
  );
};

export default DashboardRoute;
