import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class PermissionGroupApi {

    List = () => {
        return useQueryWithCustomized(
            "permission-groups.list",
            async () => {
                return await requestWithToken(`${API_V3_URL}/permission_groups`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data;
                    })
                    .catch((error) => {
                        return error.response;
                    })
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
                enabled: !!localStorage.getItem("SA"),
            }
        );
    }

    Create = async (data) => {
        return await requestWithToken(`${API_V3_URL}/permission_groups`,
            "POST", { group_permission: data }, {}, {})
            .then(({ group_permission_id: data }) => {
                return data;
            })
            .catch((error) => {
                return error.response;
            });
    };

    Detail = ({ id = -1 }) => {
        return useQueryWithCustomized(
            ["permission-group.detail", id],
            async () => {
                return await requestWithToken(`${API_V3_URL}/permission_groups/${id}`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data;
                    })
                    .catch((error) => {
                        return error.response;
                    })
            },
            {
                staleTime: 10 * 60 * 1000,
                suspense: true,
                enabled: !!localStorage.getItem("SA"),
            }
        );
    }

    Update = async (id, data) => {
        return await requestWithToken(`${API_V3_URL}/permission_groups/${id}`,
            "PUT", { group_permission: data }, {}, {})
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                return error.response;
            });
    }

    Assignee = async ({ id = -1, assigneeIds = [] }) => {
        return await requestWithToken(`${API_V3_URL}/permission_groups/${id}/assignees`, "PUT", {
            group_permission: {
                group_permission_assignee_employee_ids: assigneeIds
            }
        }, {}, {})
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                return error.response;
            });
    }


}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PermissionGroupApi();
