import DataGridCustomize from '@components/DataGrid'
import React, { useState } from 'react'
import ServicePrice from './ServicePrice'
import ServiceMetadata from './ServiceMetadata'
import Footer from './Footer'
import { Box, IconButton } from '@mui/material'
import { DeleteOutlineOutlined } from '@mui/icons-material'
import ServicePriceExceed from './ServicePriceExceed'
import { useStateArrayContext } from '@contexts/stateArray'
import ServiceQty from './ServiceQty'

const DataProducts = ({
    isFetching = false
}) => {
    const {
        state: products,
        removeIndex: remove
    } = useStateArrayContext()

    const [columns] = useState([
        {
            field: "product_name",
            headerName: "Tên Sản Phẩm",
            width: 230,
        },
        {
            field: "service_packages",
            headerName: "Cấu Hình/ Thông Số",
            width: 230,
            renderCell: ({ row }) => <ServiceMetadata data={row} />
        },
        {
            field: "product_value",
            headerName: "SL Mua",
            width: 230,
            renderCell: ({ row }) => <ServiceQty data={row} />
        },
        {
            field: "service_price",
            headerName: "Đơn Giá Bán* (/Tháng)",
            width: 180,
            renderCell: ({ row }) => <ServicePrice data={row} />
        },
        {
            field: "service_price_exceed",
            headerName: "Đơn Giá Đối Soát Vượt *",
            width: 180,
            renderCell: ({ row }) => <ServicePriceExceed data={row} />
        },
        {
            field: "actions",
            headerName: "",
            width: 40,
            renderCell: ({ row }) => <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IconButton size='small' color="error" onClick={() => remove(row?.id)}>
                    <DeleteOutlineOutlined fontSize='small' />
                </IconButton>
            </Box>
        },
    ])
    return (
        <DataGridCustomize
            rows={
                products?.map((contact, index) => ({
                    ...contact,
                    id: index,
                })) || []
            }
            columns={columns}
            loading={isFetching}
            rowCount={products?.length}
            initialState={{
                pinnedColumns: { right: ["actions"] },
            }}
            components={{
                Toolbar: 'disabled',
                Pagination: Footer
            }}
            componentProps={{
                pagination: {
                    data: products
                }
            }}
            isRowSelectable={({ row }) => {
                if (row?.contact_source_status === "MATCHED") return false;
                return true;
            }}
            disableSelectionOnClick
            checkboxSelection
        />
    )
}

export default DataProducts