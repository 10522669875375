import withSuspense from '@/hocs/withSuspense';
import ErrorHelperText from '@components/shared/ErrorHelperText';
import MultipleSelectCustomize from '@components/shared/MultipleSelectCustomize';
import { FormControl, Typography } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EmployeeApi from '@/apis/beta/Employee/EmployeeApi'
import { buildQueryFilterCondition } from '@/functions'
import useDebounce from '@/hooks/useDebounce'
import MuiSkeleton from '@components/shared/MuiSkeleton'

const CustomerSaleFollowers = () => {
    const { control, formState: { errors }, watch } = useFormContext();

    const [state, setState] = React.useState('')

    const debouncedSearchQuery = useDebounce(state, 400)

    const defaultFilterableEmployeeCs = React.useCallback(() => {
        const employeeSelectedIds = watch(`customer.customer_sale_followers`)?.map((e) => e?.employee_id)?.join(',')
        let fieldQueries = [
            {
                name: 'employees.department_id',
                operator: "$in",
                value: '1,2' // default [bod/ cs]
            },
            {
                name: 'employees.employee_id',
                operator: "$eq",
                value: 30 // VNETWORK JSC
            },
            {
                name: 'employees.employee_id',
                operator: "$in",
                disabled: !employeeSelectedIds,
                value: employeeSelectedIds // default employees
            },
        ]
        return buildQueryFilterCondition("$or", 0, fieldQueries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch(`customer.customer_sale_followers`)])

    const seachFilterableEmployeeCs = React.useCallback(() => {
        if ((!debouncedSearchQuery || debouncedSearchQuery?.trim() === '')) return '';
        const employeeSelectedIds = watch(`customer.customer_sale_followers`)?.map((e) => e?.employee_id)?.join(',')
        let fieldQueries = [
            {
                name: 'employees.department_id',
                operator: "$in",
                value: '10,11' // default [bod/ cs]
            }, {
                name: 'employees.employee_id',
                operator: "$in",
                value: employeeSelectedIds // default employees
            }, {
                name: 'employees.employee_id',
                operator: "$eq",
                disabled: !parseInt(debouncedSearchQuery),
                value: parseInt(debouncedSearchQuery)
            },
            { name: 'employee_name', operator: "$contains", value: debouncedSearchQuery },
            { name: 'employee_code', operator: "$contains", value: debouncedSearchQuery }
        ]
        return buildQueryFilterCondition("$or", 0, fieldQueries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery, watch(`customer.customer_sale_followers`)])

    const { data: employees, isFetching } = EmployeeApi.ListEmployees({
        query: defaultFilterableEmployeeCs(),
        searchQuery: seachFilterableEmployeeCs()
    })

    return (
        <React.Fragment>
            <Typography sx={{ mb: 0.5, color: "#777680", fontSize: 14 }}>
                Sale Followers
            </Typography>
            <Controller
                control={control}
                name="customer.customer_sale_followers"
                render={({ field: { value, onChange } }) => {

                    return (
                        <FormControl fullWidth>
                            <MultipleSelectCustomize
                                limitTags={3}
                                value={value}
                                loading={isFetching}
                                options={employees}
                                formatCollection={{ value: "employee_id", label: "employee_name" }}
                                compareAttributes={["employee_name"]}
                                onChange={(event, newValue) => onChange(newValue || [])}
                                input={{
                                    placeholder: "Nhập tên nhân viên SALE tìm kiếm...",
                                    value: state,
                                    onChange: (e) => setState(e?.target?.value || '')
                                }}
                                sx={{
                                    "&.MuiAutocomplete-option": {
                                        textTransform: "uppercase"
                                    }
                                }}
                                mode="server"
                            />
                            {!!errors?.customer?.customer_sale_followers && <ErrorHelperText message={errors?.customer?.customer_sale_followers?.message} />}
                        </FormControl>
                    )
                }} />
        </React.Fragment>
    )
}

export default withSuspense(CustomerSaleFollowers, MuiSkeleton["FormControlTextField"])
