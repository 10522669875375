import {
  dayjsCompare,
  encodeBase64,
  format_numeral_price,
  requestWithToken,
} from "@/functions";
import { API_URL, API_V2_URL, API_V3_URL } from "@/libs/constants";
import _ from "lodash";
import moment from "moment";
import { isEmail } from "validator";
import { enforce, test } from "vest";
import dayjs from "dayjs";
// eslint-disable-next-line no-undef
const vest = require("vest");
enforce.extend({ isEmail });

export const validationCustomizeDatetime = ({
  timeCompare,
  timeDiff,
  condition = "lte", // [gte, lte, eq]
}) => {
  if (condition === "gte") {
    // is greate than
    return moment(timeCompare).diff(moment(timeDiff), "d") >= 0;
  }
  if (condition === "lte") {
    // is lte
    return moment(timeCompare).diff(moment(timeDiff), "d") <= 0;
  }
};

const verifyIsNumber = (value) => {
  const regexNumber = new RegExp("^[0-9]+$");
  return regexNumber.test(value);
};
const verifyLength = (lengthOfValue, min = 0, max) => {
  return min <= lengthOfValue && lengthOfValue <= max;
};

const doesEmailCompanyExist = async (email) => {
  const { employee } = await requestWithToken(`${API_V3_URL}/employees/list`)
    .then(({ data }) => {
      if (data?.error) return data;
      return (
        {
          employee:
            data?.data?.employees?.find((e) => e.employee_email === email) ||
            undefined,
        } || undefined
      );
    })
    .catch((error) => {
      return error.response;
    });
  return employee === undefined;
};

const resolverWarehouseTypes = async (name, type) => {
  const query = `filters[$and][0][warehouse_type_name][$contains]=${name}&filters[$and][1][warehouse_types.warehouse_type_type][$eq]=${type}`;
  return await requestWithToken(
    `${API_V3_URL}/warehouse_types/list?filters=${encodeBase64(query)}`
  );
};

const resolverPostmanTrackings = async (mvd) => {
  const query = `filters[$and][0][postman_tracking_mvd][$contains]=${mvd}`;
  return await requestWithToken(
    `${API_V3_URL}/postman_trackings?view_mode=all&filters=${encodeBase64(
      query
    )}`
  );
};

const doesIndustryExist = async (value) => {
  const { industry } = await requestWithToken(`${API_V3_URL}/industries`)
    .then(({ data }) => {
      if (data?.error) return data;
      return (
        {
          industry:
            data?.data?.industries?.find(
              (i) => i["industry_name"]?.toLowerCase() === value.toLowerCase()
            ) || undefined,
        } || undefined
      );
    })
    .catch((error) => {
      return error.response;
    });
  return industry === undefined;
};

const doesGroupTypeExist = async (value, wpTypeId) => {
  const { group } = await requestWithToken(
    `${API_V3_URL}/warehouse_groups/list`
  )
    .then(({ data }) => {
      if (data?.error) return data;
      return (
        {
          group:
            data?.data?.warehouse_groups?.find(
              (gr) =>
                gr["warehouse_group_name"]?.toLowerCase() ===
                value?.toLowerCase() &&
                gr?.warehouse_type?.warehouse_type_id === wpTypeId
            ) || undefined,
        } || undefined
      );
    })
    .catch((error) => {
      return error.response;
    });
  return group === undefined;
};
const doesWarehouseBrandExist = async (value) => {
  const { brand } = await requestWithToken(`${API_V3_URL}/warehouse_brands`)
    .then(({ data }) => {
      if (data?.error) return data;
      return (
        {
          brand:
            data?.data?.warehouse_brands?.find(
              (gr) =>
                gr["warehouse_brand_name"]?.toLowerCase() ===
                value?.toLowerCase()
            ) || undefined,
        } || undefined
      );
    })
    .catch((error) => {
      return error?.response;
    });
  return brand === undefined;
};

export const doesFieldSelectedEmpty = (value) => {
  const isnotValid = [{}, undefined, 0]?.includes(value);
  enforce(!isnotValid).isTruthy();
};

export const doesCustomerExist = async (value, field) => {
  const { customer } = await requestWithToken(
    `${API_V3_URL}/customers/email?email=${value}`
  )
    .then(({ data }) => {
      if (data?.error) return data;
      return (
        {
          customer: data?.data?.customers?.find(
            (customer) => customer[field]?.toLowerCase() === value.toLowerCase()
          ),
        } || undefined
      );
    })
    .catch((error) => {
      return error.response;
    });
  enforce(customer).isUndefined();
};
export const doesCompanyExist = async (value, field) => {
  const { company } = await requestWithToken(`${API_URL}/companies?page_size=999`)
    .then(({ data }) => {
      if (data?.error) return data;
      return (
        {
          company: data?.data?.companies?.find((cpm) => cpm[field] === value),
        } || undefined
      );
    })
    .catch((error) => {
      return error?.response;
    });
  enforce(company).isUndefined();
};

const doesDomainRecordExist = async (domainId, value) => {
  const { record } = await requestWithToken(
    `${API_V2_URL}/domains/${domainId}/list_record`
  )
    .then(({ data }) => {
      if (data?.error) return data?.data;
      return (
        {
          record: data?.data?.records?.find(
            (record) => record?.Recordname === value
          ),
        } || undefined
      );
    })
    .catch((error) => {
      return error.response;
    });
  enforce(record).isUndefined();
};

const VerifyDomainTail = async (value) => {
  const { domains } = await requestWithToken(`${API_URL}/domain_prices`)
    .then(({ data }) => {
      if (data?.error) return data?.data;
      return {
        domains: data?.data?.domain_prices?.map((d) => d?.dp_extension),
      };
    })
    .catch((error) => {
      return error.response;
    });
  enforce(domains?.includes(value)).isTruthy();
};

const isHasNumber = (string) => {
  const hasNumber = /\d/;
  enforce(!hasNumber.test(string)).isTruthy();
};
const isHasSpecialCharacter = (string) => {
  // eslint-disable-next-line no-useless-escape
  const specialCharacters = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  enforce(!specialCharacters.test(string)).isTruthy();
};
const isHasKeywordNotValid = (string) => {
  const arrNotValid = ["Google", "Facebook"];
  const isExist = arrNotValid?.find((c) => string?.includes(c));
  enforce(!isExist).isTruthy();
};

const customerValidatorByStep = vest.create((data = {}) => {
  if (!data?.currentStep) return;
  vest.skipWhen(
    () => data?.currentStep !== 1,
    () => {
      test(
        "customer.customer_industry",
        "Vui lòng chọn lĩnh vực/ ngành nghề.",
        () => {
          enforce(data?.customer.customer_industry !== -1).isTruthy();
        }
      );
      test(
        "customer.customer_source",
        "Vui lòng chọn nguồn khai thác của khách hàng.",
        () => {
          enforce(data?.customer?.customer_source !== -1).isTruthy();
        }
      );
      test(
        "customer.customer_service_interestings",
        "Khách hàng chưa có dịch vụ quan tâm.",
        () => {
          enforce(
            data?.customer.customer_service_interestings?.length > 0
          ).isTruthy();
        }
      );

      vest.skipWhen(
        () => data?.customer?.customer_type !== "company",
        () => {
          test("company.company_tax", "Mã số thuế không được để trống.", () => {
            enforce(data?.company?.company_tax).isNotEmpty();
          });
          test(
            "company.company_name",
            "Tên công ty không được để trống.",
            () => {
              enforce(data?.company.company_name).isNotEmpty();
            }
          );
          test(
            "company.company_address",
            "Địa chỉ công ty không được để trống.",
            () => {
              enforce(data?.company.company_address).isNotEmpty();
            }
          );
        }
      );
    }
  );
  vest.skipWhen(
    () =>
      data?.currentStep !== 2 ||
      (data?.type === "update" &&
        data?.customer?.customer_email === data?.preCustomerEmail),
    () => {
      test(
        "customer.customer_email",
        "Email khách hàng không được để trống.",
        () => {
          enforce(data?.customer?.customer_email).isNotEmpty();
        }
      );
      test("customer.customer_email", "Email khách hàng không hợp lệ,", () => {
        enforce(data?.customer?.customer_email).isEmail();
      });

      vest.skipWhen(
        (res) => res.hasErrors("customer.customer_email"),
        () => {
          test.memo(
            "customer.customer_email",
            "Email khách hàng đã tồn tại.",
            () => {
              return doesCustomerExist(
                data?.customer?.customer_email,
                "customer_email"
              );
            },
            [data?.customer?.customer_email]
          );
        }
      );
      test(
        "customer.customer_name",
        "Tên khách hàng không được để trống.",
        () => {
          enforce(data?.customer.customer_name).isNotEmpty();
        }
      );
      test(
        "customer.customer_name",
        "Tên khách hàng chứa ký tự/ từ không hợp lệ.",
        () => {
          return isHasKeywordNotValid(data.customer.customer_name);
        }
      );
      test(
        "customer.customer_name",
        "Tên khách hàng không được chứa số.",
        () => {
          return isHasNumber(data.customer.customer_name);
        }
      );
      test(
        "customer.customer_name",
        "Tên khách hàng không được chứa ký tự đặc biệt.",
        () => {
          return isHasSpecialCharacter(data?.customer?.customer_name);
        }
      );
      test("customer.customer_position", "Chức vụ không được để trống.", () => {
        enforce(data?.customer.customer_position).isNotEmpty();
      });
    }
  );
});

const creditValidator = vest.create((data = {}) => {
  test("value", "Số tiền ký quỹ không được để trống", () => {
    enforce(data.value).isNotEmpty();
  });
  test("value", "Số tiền ký quỹ không hợp lệ, phải khác 0", () => {
    enforce(data.value !== 0).isTruthy();
  });
});

// vest velidator when add domain in order/saleplan/trial
const verifyIndentityInformationCustomer = (key, data) => {
  test(`${key}.customer_name`, "Tên khách hàng không được để trống", () => {
    enforce(data?.customer_name).isNotEmpty();
  });
  test(`${key}.customer_country`, "Quốc gia không được để trống", () => {
    enforce(data?.customer_country).isNotEmpty();
  });
  test(`${key}.customer_country`, "Vui lòng chọn quốc gia", () => {
    doesFieldSelectedEmpty(data?.customer_country);
  });
  test(`${key}.customer_province`, "Vui lòng chọn tỉnh/ thành phố", () => {
    doesFieldSelectedEmpty(data?.customer_province);
  });
  test(`${key}.customer_district`, "Vui lòng chọn quận/ huyện", () => {
    doesFieldSelectedEmpty(data?.customer_district);
  });
  test(
    `${key}.customer_address`,
    "Địa chỉ khách hàng không được để trống",
    () => {
      enforce(data?.customer_address).isNotEmpty();
    }
  );
  test(`${key}.customer_email`, "Email khách hàng không được để trống", () => {
    enforce(data?.customer_email).isNotEmpty();
  });
  test(`${key}.customer_email`, "Email khách hàng không hợp lệ", () => {
    enforce(data?.customer_email).isEmail();
  });
  test(`${key}.customer_phone`, "Số điện thoại không được để trống", () => {
    enforce(data?.customer_phone).isNotEmpty();
  });
};

const verifyCustomerSelfInformation = (key, data = {}) => {
  test(
    `${key}.customer_identification_number`,
    "CMND/CCCD không được để trống",
    () => {
      enforce(data?.customer_identification_number).isNotEmpty();
    }
  );
  test(
    `${key}.customer_identification_number`,
    "CMND/CCCD không hợp lệ (9|12 số)",
    () => {
      const requireLength =
        data?.customer_identification_number?.length === 9 ||
        data?.customer_identification_number?.length === 12;
      enforce(requireLength).isTruthy();
    }
  );
  test(
    `${key}.customer_identification_number`,
    "CMND/CCCD không được chứa ký tự khác số",
    () => {
      const isNumber = verifyIsNumber(data?.customer_identification_number);
      enforce(isNumber).isTruthy();
    }
  );
  test(`${key}.customer_gender`, "Vui lòng chọn giới tính", () => {
    doesFieldSelectedEmpty(data?.customer_gender);
  });
  test(`${key}.customer_dob`, "Ngày sinh không được để trống", () => {
    enforce(data?.customer_gender).isNotEmpty();
  });
  test(`${key}.customer_dob`, "Độ tuổi không phù hợp(ex 14-90)", () => {
    const yearOlds = moment().diff(data?.customer_dob, "years");
    const isValidOld = yearOlds > 14 && yearOlds < 90;
    enforce(isValidOld).isTruthy();
  });
};

const domainValidator = vest.create((data = {}) => {
  test("domain_value", "Tên miền không được để trống", () => {
    enforce(data.domain_value).isNotEmpty();
  });
  test("domain_value", "Tên miền không hợp lệ", () => {
    const regex =
      /^([a-zA-Z0-9][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9]*[a-zA-Z0-9-_]*[[a-zA-Z0-9]+$/;
    const isValid = regex.test(data?.domain_value);
    enforce(isValid).isTruthy();
  });
  vest.skipWhen(
    (res) => res.hasErrors("domain_value"),
    () => {
      test.memo(
        "domain_value",
        "Tên miền không hợp lệ",
        async () => {
          return await VerifyDomainTail(
            "." + data.domain_value?.split(".")?.at(-1)
          );
        },
        [data.domain_value]
      );
    }
  );
  test("domain_period", "Vui lòng chọn thời hạn dịch vụ", () => {
    doesFieldSelectedEmpty(data?.domain_period);
  });
  if (data?.domain_type === "transfer") {
    test("domain_epp_code", "Mã EPP code không được để trống", () => {
      enforce(data?.domain_epp_code).isNotEmpty();
    });
  }
  if (data?.dns_information?.dns_type === "dns_other") {
    data?.dns_information?.dns_names.forEach((element, index) => {
      test(
        `dns_information.dns_names.${index}`,
        "DNS không được để trống",
        () => {
          enforce(element?.name).isNotEmpty();
        }
      );
    });
  }
  if (data?.localDomain?.enable) {
    switch (data?.localDomain?.step) {
      case "customer_information":
        verifyIndentityInformationCustomer(
          "customer_information",
          data?.customer_information
        );
        test(
          "customer_information.customer_tax",
          "Mã số thuế không được để trống",
          () => {
            enforce(data?.customer_information?.customer_tax).isNotEmpty();
          }
        );
        test(
          "customer_information.customer_email2",
          "Email khách hàng không hợp lệ",
          () => {
            enforce(data?.customer_information?.customer_email2).isEmail();
          }
        );
        return;
      case "domain_manifest_information":
        verifyIndentityInformationCustomer(
          "domain_manifest_information",
          data?.domain_manifest_information
        );
        if (
          data?.domain_manifest_information?.domain_manifest_type === "business"
        ) {
          test(
            "domain_manifest_information.customer_tax",
            "Mã số thuế không được để trống",
            () => {
              enforce(
                data?.domain_manifest_information?.customer_tax
              ).isNotEmpty();
            }
          );
        } else {
          verifyCustomerSelfInformation(
            "domain_manifest_information",
            data?.domain_manifest_information
          );
        }
        return;
      case "manager_information":
        verifyIndentityInformationCustomer(
          "manager_information",
          data?.manager_information
        );
        verifyCustomerSelfInformation(
          "manager_information",
          data?.manager_information
        );
        return;
      default:
        return null;
    }
  }
});
// end of validator add domain in order/saleplan/trial order

const customerSpamValidator = vest.create((data = {}) => {
  test("customer_spam_name", "Tên khách hàng spam không được để trống", () => {
    enforce(data?.customer_spam_name).isNotEmpty();
  });
  test(
    "customer_spam_email",
    "Email khách hàng spam không được để trống",
    () => {
      enforce(data?.customer_spam_email).isNotEmpty();
    }
  );
  test("customer_spam_email", "Email khách hàng không hợp lệ", () => {
    enforce(data?.customer_spam_email).isEmail();
  });
  vest.skipWhen(
    (res) => res.hasErrors("customer_spam_email"),
    () => {
      test.memo(
        "customer_spam_email",
        "Email này đang có ở danh sách khách hàng",
        () => {
          return doesCustomerExist(data.customer_spam_email, "customer_email");
        },
        [data.customer_spam_email]
      );
    }
  );
  test(
    "customer_spam_phone",
    "Số điện thoại khách hàng không được để trống",
    () => {
      enforce(data?.customer_spam_phone).isNotEmpty();
    }
  );
  test("customer_spam_source", "Nguồn khách hàng không được để trống", () => {
    doesFieldSelectedEmpty(data?.customer_spam_source);
  });
  test(
    "customer_spam_source_note",
    "Nguồn dịch vụ khách hàng không được để trống",
    () => {
      doesFieldSelectedEmpty(data?.customer_spam_source_note);
    }
  );
  test(
    "customer_spam_question",
    "Câu hỏi/ ghi chú khách hàng không được để trống",
    () => {
      enforce(data?.customer_spam_question).isNotEmpty();
    }
  );
});

const transactionValidator = vest.create((data = {}) => {
  test(
    "invoice_paid",
    "Ngày thanh toán không được lớn hơn ngày hiện tại",
    () => {
      const isGreaterThanNow = validationCustomizeDatetime({
        timeCompare: data?.invoice_paid,
        timeDiff: moment(),
      });
      enforce(isGreaterThanNow).isTruthy();
    }
  );

  test('invoice_paid', 'Ngày thanh toán không hợp lệ. Vui lòng thanh toán hóa đơn của tháng trước trước ngày 2 mỗi tháng.', () => {
    const today = dayjs()
    const ranges = dayjsCompare(today.startOf('months'), dayjs(data?.invoice_paid))
    enforce(ranges <= 1).isTruthy()
  })


  test("payment_method", "Vui lòng chọn phương thức thanh toán hợp lệ", () => {
    const paymentMethod = [0, 1, 2, 3];
    enforce(paymentMethod.includes(data?.payment_method)).isTruthy();
  });
  test("transaction_amount", "Số tiền thanh toán phải lớn hơn 0", () => {
    const isNumber =
      verifyIsNumber(data?.transaction_amount) && data.transaction_amount > 0;
    enforce(isNumber).isTruthy();
  });
});

const transactionServiceValidator = vest.create((data = {}) => {
  test(`service_start_date`, "Ngày bắt đầu không được để trống", () => {
    enforce(data?.service_start_date).isNotEmpty();
  });
  test(`service_duedate`, "Ngày gia hạn không được để trống", () => {
    enforce(data?.service_duedate).isNotEmpty();
  });
  test(`service_status`, "Vui lòng chọn trạng thái dịch vụ", () => {
    doesFieldSelectedEmpty(data?.service_status);
  });
  test(
    `service_billing_cycle`,
    "Vui lòng chọn chu kỳ/ thời hạn dịch vụ",
    () => {
      doesFieldSelectedEmpty(data?.service_billing_cycle);
    }
  );
});

const configureExtraValidator = vest.create((data = {}) => {
  // eslint-disable-next-line array-callback-return
  data?.extras.map((config, index) => {
    test(`extras.${index}.name`, "Tên thuộc tính không được để trống", () => {
      enforce(config?.name).isNotEmpty();
    });
    test(
      `extras.${index}.value`,
      "Giá trị thuộc tính không được để trống",
      () => {
        enforce(config?.value).isNotEmpty();
      }
    );
  });
});

const serviceAddonValidator = vest.create((data = {}) => {
  test(`service_addon_billing_cycle`, "Vui lòng chọn thời hạn dịch vụ", () => {
    doesFieldSelectedEmpty(data?.service_addon_billing_cycle);
  });
  test(
    `service_addon_name`,
    "Tên sản phẩm/ dịch vụ không được để trống",
    () => {
      enforce(data?.service_addon_name).isNotEmpty();
    }
  );
  test(
    `service_addon_startdate`,
    "Ngày bắt đầu dịch vụ không được để trống",
    () => {
      enforce(data?.service_addon_startdate).isNotEmpty();
    }
  );
  test(
    `service_addon_duedate`,
    "Ngày gia hạn dịch vụ không được để trống",
    () => {
      enforce(data?.service_addon_duedate).isNotEmpty();
    }
  );
  test(`service_addon_status`, "Vui lòng chọn trạng thái dịch vụ", () => {
    doesFieldSelectedEmpty(data?.service_addon_status);
  });
});

const dnsRecordValidator = vest.create((data = {}) => {
  test(`Recordname`, "Tên bản ghi không được để trống", () => {
    enforce(data?.Recordname).isNotEmpty();
  });
  vest.skipWhen(
    (res) => res.hasErrors("Recordname"),
    () => {
      test.memo(
        "Recordname",
        "Tên bản ghi đã tồn tại",
        async () => {
          return await doesDomainRecordExist(data?.domainId, data?.Recordname);
        },
        [data?.domainId, data?.Recordname]
      );
    }
  );
  test(`Type`, "Vui lòng chọn loại bản ghi", () => {
    doesFieldSelectedEmpty(data?.Type);
  });
  test(`Value`, "Giá trị bản ghi không được để trống", () => {
    enforce(data?.Value).isNotEmpty();
  });
  test(`Preference`, "Độ ưu tiên không được để trống", () => {
    enforce(data?.Preference).isNotEmpty();
  });
  test(`Preference`, "Độ ưu tiên không hợp lệ", () => {
    enforce(data?.Preference).isNumeric();
  });
});

const customerJourneyValidation = vest.create((data = {}) => {
  test("c_journey_week_step", "Vui lòng chọn quá trình bán hàng", () => {
    enforce(data.c_journey_week_step !== -1).isTruthy();
  });
  test("c_journey_week_step_status", "Trạng thái không hợp lệ", () => {
    enforce(data.c_journey_week_step_status !== -1).isTruthy();
  });
  test(
    "customer_journey_note",
    "Kết quả của quá trình không được để trống.",
    () => {
      enforce(data.customer_journey_note).isNotEmpty();
    }
  );
});
const warehouseTypeValidator = vest.create((data = {}) => {
  test("warehouse_type_type", "Loại sản phẩm không hợp lệ", () => {
    const validType = [0, 1, 2];
    const isValid = validType?.includes(data?.warehouse_type_type);
    enforce(!isValid).isTruthy();
  });
  test("warehouse_type_name", "Tên loại sản phẩm không được để trống.", () => {
    enforce(data?.warehouse_type_name).isNotEmpty();
  });
  vest.skipWhen(
    (res) => res.hasErrors("warehouse_type_name"),
    () => {
      if (data?.method === "create") {
        test.memo(
          "warehouse_type_name",
          "Tên thể loại này đã tồn tại, vui lòng nhập tên khác.",
          async () => {
            const response = await resolverWarehouseTypes(
              data.warehouse_type_name,
              parseInt(data?.warehouse_type_type)
            );
            const isNotValid = response?.data?.data?.warehouse_types?.find(
              (t) =>
                t?.warehouse_type_name?.toLowerCase() ===
                data?.warehouse_type_name
            );
            enforce(!isNotValid).isTruthy();
          },
          [data.warehouse_type_name, data?.warehouse_type_type]
        );
      }
    }
  );
});

const accessoryValidator = vest.create((data = {}) => {
  test("warehouse_item_sku", "Mã linh kiện không được để trống.", () => {
    enforce(data?.warehouse_item_sku).isNotEmpty();
  });
  test("warehouse_item_name", "Tên linh kiện không được để trống.", () => {
    enforce(data?.warehouse_item_name).isNotEmpty();
  });
  test("warehouse_group_id", "Vui lòng chọn nhóm.", () => {
    doesFieldSelectedEmpty(data?.warehouse_group_id);
  });
  test("warehouse_item_status", "Vui lòng chọn trạng thái.", () => {
    enforce(parseInt(data?.warehouse_item_status) > -1).isTruthy();
  });
  test("warehouse_item_brand", "Hãng linh kiện không được để trống.", () => {
    enforce(data?.warehouse_item_brand).isNotEmpty();
  });
  test("warehouse_item_unit", "Vui lòng chọn đơn vị.", () => {
    enforce(parseInt(data?.warehouse_item_unit) > -1).isTruthy();
  });
  test("warehouse_item_total", "Số lượng không hợp lệ.", () => {
    enforce(parseInt(data?.warehouse_item_total) > 0).isTruthy();
  });
  test("warehouse_item_price", "Đơn giá không hợp lệ.", () => {
    enforce(parseInt(data?.warehouse_item_price) >= 0).isTruthy();
  });
  test(
    "depreciate.wi_depreciate_date_end",
    "Ngày kết thúc khấu hao không được nhỏ hơn ngày bắt đầu.",
    () => {
      const isGreaterThan = validationCustomizeDatetime({
        timeCompare: data?.depreciate?.wi_depreciate_date_end,
        timeDiff: data?.depreciate?.wi_depreciate_date_start,
        condition: "gte",
      });
      enforce(isGreaterThan).isTruthy();
    }
  );
  test(
    "depreciate.wi_depreciate_price_month",
    `Giá trị khấu hao không hợp lệ( Từ 0đ đến ${format_numeral_price(
      data?.warehouse_item_price
    )}đ )`,
    () => {
      let isValid = parseInt(data?.depreciate?.wi_depreciate_price_month) >= 0;
      if (
        parseInt(data?.depreciate?.wi_depreciate_price_month) >
        data?.warehouse_item_price
      ) {
        return (isValid = false);
      }
      enforce(isValid).isTruthy();
    }
  );
});

const warehouseProcessValidator = vest.create((data = {}) => {
  test("wp_type", "Loại linh kiện không hợp lệ.", () => {
    const isValid = [0, 1, 2, 3].includes(parseInt(data?.wp_type));
    enforce(isValid).isTruthy();
  });
  switch (parseInt(data?.wp_type)) {
    case 0:
      test("wp_from", "Nơi mua không được để trống.", () => {
        enforce(data?.wp_from).isNotEmpty();
      });
      break;
    case 2:
      test("wp_from", "Nơi luân chuyển đi không được để trống.", () => {
        enforce(data?.wp_from?.warehouse_box_id > -1).isTruthy();
      });
      test("wp_to", "Nơi luân chuyển đến không được để trống.", () => {
        enforce(data?.wp_to?.warehouse_box_id > -1).isTruthy();
      });
      break;
    case 1:
    case 3:
      test(
        "wp_to",
        `Nơi ${parseInt(data?.wp_type) === 1 ? "xuất" : "huỷ"
        } không được để trống.`,
        () => {
          enforce(data?.wp_to).isNotEmpty();
        }
      );
      break;
    default:
      break;
  }
  test("wp_feedback", "Lý do không được để trống.", () => {
    enforce(data?.wp_feedback).isNotEmpty();
  });
});

const deviceValidator = vest.create((data = {}) => {
  test(
    "warehouse_type_type",
    "Loại thiết bị/ linh kiện hoặc phụ kiện không hợp lệ.",
    () => {
      enforce([0, 1, 2]?.includes(data?.warehouse_type_type)).isTruthy();
    }
  );
  test("warehouse_item_sku", "Vui lòng nhập mã hàng.", () => {
    enforce(data?.warehouse_item_sku).isNotEmpty();
  });
  test("warehouse_item_name", "Vui lòng nhập tên hàng.", () => {
    enforce(data?.warehouse_item_name).isNotEmpty();
  });
  test("warehouse_group_id", "Vui lòng chọn nhóm hàng.", () => {
    doesFieldSelectedEmpty(data?.warehouse_group_id);
  });
  test("warehouse_item_unit", "Vui lòng chọn đơn vị.", () => {
    enforce(parseInt(data?.warehouse_item_unit) > -1).isTruthy();
  });
  test("warehouse_item_brand", "Tên hãng không được để trống.", () => {
    enforce(data?.warehouse_item_brand).isNotEmpty();
  });
  test("warehouse_item_price", "Đơn giá không hợp lệ.", () => {
    enforce(parseInt(data?.warehouse_item_price) >= 0).isTruthy();
  });
  test("warehouse_item_status", "Vui lòng chọn tình trạng.", () => {
    enforce(parseInt(data?.warehouse_item_status) > -1).isTruthy();
  });
  test(
    "depreciate.wi_depreciate_price_month",
    "Vui lòng nhập giá trị khấu hao trung bình.",
    () => {
      enforce(
        parseInt(data?.depreciate?.wi_depreciate_price_month) >= 0
      ).isTruthy();
    }
  );
  test(
    "depreciate.wi_depreciate_price_month",
    `Giá trị từ 0 đ đến ${format_numeral_price(data?.warehouse_item_price)} đ.`,
    () => {
      enforce(
        parseInt(data?.depreciate?.wi_depreciate_price_month) <=
        parseInt(data?.warehouse_item_price)
      ).isTruthy();
    }
  );
  test("warehouse_item_import_at", "Vui lòng nhập thời gian nhập hàng.", () => {
    enforce(data?.warehouse_item_import_at).isNotEmpty();
  });
  test(
    "warehouse_item_warranty_period",
    "Vui lòng nhập thời gian bảo hành.",
    () => {
      enforce(data?.warehouse_item_warranty_period).isNotEmpty();
    }
  );
  test(
    "warehouse_item_warranty_period",
    "Thời gian bảo hành không hợp lệ.",
    () => {
      const isGreaterThan = validationCustomizeDatetime({
        timeCompare: data?.warehouse_item_warranty_period,
        timeDiff: data?.warehouse_item_import_at,
        condition: "gte",
      });
      enforce(isGreaterThan).isTruthy();
    }
  );
  test(
    "depreciate.wi_depreciate_date_start",
    "Vui lòng nhập thời gian bắt đầu khấu hao.",
    () => {
      enforce(data?.depreciate?.wi_depreciate_date_start).isNotEmpty();
    }
  );
  test(
    "depreciate.wi_depreciate_date_end",
    "Vui lòng nhập thời gian kết thúc khấu hao.",
    () => {
      enforce(data?.depreciate?.wi_depreciate_date_end).isNotEmpty();
    }
  );
  test(
    "depreciate.wi_depreciate_date_end",
    "Thời gian kết thúc khấu hao không hợp lệ.",
    () => {
      const isGreaterThan = validationCustomizeDatetime({
        timeCompare: data?.depreciate?.wi_depreciate_date_end,
        timeDiff: data?.depreciate?.wi_depreciate_date_start,
        condition: "gte",
      });
      enforce(isGreaterThan).isTruthy();
    }
  );
  if (parseInt(data?.warehouse_type_type) === 0) {
    test(
      "warehouse_item_series",
      "Vui lòng nhập series, mỗi series tương ứng với 1 sản phẩm.",
      () => {
        enforce(data?.warehouse_item_series).isNotEmpty();
      }
    );
    test("warehouse_item_u", "Vui lòng nhập kích thước U.", () => {
      enforce(data?.warehouse_item_u).isNotEmpty();
    });
    test("warehouse_item_power", "Vui lòng nhập nguồn điện (W).", () => {
      enforce(data?.warehouse_item_power).isNotEmpty();
    });
  }
  if (parseInt(data?.warehouse_type_type) === 1) {
    test(
      "warehouse_item_series",
      "Vui lòng nhập series, mỗi series tương ứng với 1 sản phẩm.",
      () => {
        enforce(data?.warehouse_item_series).isNotEmpty();
      }
    );
    test("config", "Vui lòng nhập cấu hình", () => {
      let flag = false;
      _.forEach(data?.config, (value) => {
        if (parseInt(value) > 0) return (flag = true);
      });
      enforce(flag).isTruthy();
    });
  }
  if (parseInt(data?.warehouse_type_type) === 2) {
    test("warehouse_item_total", "Vui lòng nhập số lượng linh kiện.", () => {
      enforce(parseInt(data?.warehouse_item_total) > 0).isTruthy();
    });
  }
});

const wbRackInvoiceValidator = vest.create((data = {}) => {
  test("w_rack_invoice_name", "Tên đối soát không được để trống.", () => {
    enforce(data?.w_rack_invoice_name).isNotEmpty();
  });
  test(
    "w_rack_invoice_date_start",
    "Thời gian bắt đầu không được để trống.",
    () => {
      enforce(data?.w_rack_invoice_date_start).isNotEmpty();
    }
  );
  test(
    "w_rack_invoice_date_end",
    "Thời gian kết thúc không được để trống.",
    () => {
      enforce(data?.w_rack_invoice_date_end).isNotEmpty();
    }
  );
  test(
    "w_rack_invoice_custom",
    "Thông tin hoá đơn không hợp lệ, tổng hoá đơn 0đ @@.",
    () => {
      let amount = 0;
      data?.w_rack_invoice_custom.forEach(
        (config) => (amount += parseFloat(config?.price))
      );
      enforce(amount !== 0).isTruthy();
    }
  );
});
const wbRackValidator = vest.create((data = {}) => {
  test("wb_rack_name", "Tên rack không được để trống.", () => {
    enforce(data?.wb_rack_name).isNotEmpty();
  });
  test("wb_rack_height", "Kích thước rack không được để trống.", () => {
    enforce(data?.wb_rack_height).isNotEmpty();
  });
});
const warehouseResourceValidator = vest.create((data = {}) => {
  test(
    "warehouse_resource_type",
    "Vui lòng chọn loại tài nguyên phù hợp.",
    () => {
      enforce(
        [0, 1].includes(parseInt(data?.warehouse_resource_type))
      ).isTruthy();
    }
  );
  test(
    "warehouse_resource_config",
    "Vui cấu hình lại lượng tài nguyên thích hợp.",
    () => {
      let flag = false;
      _.forEach(data?.warehouse_resource_config, function (value) {
        if (value !== 0) {
          flag = true;
        }
      });
      enforce(flag).isTruthy();
    }
  );
});

const warehouseResourceItemValidator = vest.create((data = {}) => {
  test(
    "warehouse_resource_item_name",
    "Tên tài nguyên dịch vụ không được để trống.",
    () => {
      enforce(data?.warehouse_resource_item_name).isNotEmpty();
    }
  );
  vest.skipWhen(
    (res) => res.hasErrors("warehouse_resource_item_name"),
    () => {
      test.memo(
        "warehouse_resource_item_name",
        "Tên tài nguyên này này đã tồn tại, vui lòng nhập tên khác.",
        async () => {
          const isGroupEmtpy = await doesGroupTypeExist(
            data.warehouse_resource_item_name,
            parseInt(data?.warehouse_resource_id)
          );
          enforce(isGroupEmtpy).isTruthy();
        },
        [data.warehouse_resource_item_name]
      );
    }
  );
  test(
    "warehouse_resource_item_service",
    "Loại dịch vụ tài nguyên dịch vụ không được để trống.",
    () => {
      enforce(data?.warehouse_resource_item_service).isNotEmpty();
    }
  );
  test(
    "warehouse_resource_item_service",
    "Vui lòng chọn loại dịch vụ tài nguyên dịch vụ.",
    () => {
      enforce(
        ![-1]?.includes(data?.warehouse_resource_item_service)
      ).isTruthy();
    }
  );
  test("warehouse_resource_item_amount", "Vui lòng nhập số lượng.", () => {
    const isValid = parseInt(data?.warehouse_resource_item_amount) > 0;
    enforce(isValid).isTruthy();
  });
  test(
    "warehouse_resource_item_amount",
    "Vui lòng tính toán lại số lượng và phân chia tài nguyên.",
    () => {
      const amount = parseInt(data?.warehouse_resource_item_amount);
      let flag = true;
      if (amount > 1) {
        _.forEach(["cpu", "ram", "ssd", "hdd", "sas"], function (key) {
          if (
            parseInt(data?.warehouse_resource_item_config[key]) * amount >
            data?.resource_unused_config[key]
          ) {
            flag = false;
          }
        });
      }
      enforce(flag).isTruthy();
    }
  );
  test(
    "warehouse_resource_item_config.cpu",
    "Vượt quá giới hạn tài nguyên còn lại.",
    () => {
      const total =
        parseInt(data?.warehouse_resource_item_config?.cpu) *
        parseInt(data?.warehouse_resource_item_amount);
      enforce(total <= data?.resource_unused_config?.cpu).isTruthy();
    }
  );
  test(
    "warehouse_resource_item_config.ram",
    "Vượt quá giới hạn tài nguyên còn lại.",
    () => {
      const total =
        parseInt(data?.warehouse_resource_item_config?.ram) *
        parseInt(data?.warehouse_resource_item_amount);
      enforce(total <= data?.resource_unused_config?.ram).isTruthy();
    }
  );
  test(
    "warehouse_resource_item_config.ssd",
    "Vượt quá giới hạn tài nguyên còn lại.",
    () => {
      const total =
        parseInt(data?.warehouse_resource_item_config?.ssd) *
        parseInt(data?.warehouse_resource_item_amount);
      enforce(total <= data?.resource_unused_config?.ssd).isTruthy();
    }
  );
  test(
    "warehouse_resource_item_config.hdd",
    "Vượt quá giới hạn tài nguyên còn lại.",
    () => {
      const total =
        parseInt(data?.warehouse_resource_item_config?.hdd) *
        parseInt(data?.warehouse_resource_item_amount);
      enforce(total <= data?.resource_unused_config?.hdd).isTruthy();
    }
  );
  test(
    "warehouse_resource_item_config.hdd",
    "Vượt quá giới hạn tài nguyên còn lại.",
    () => {
      const total =
        parseInt(data?.warehouse_resource_item_config?.sas) *
        parseInt(data?.warehouse_resource_item_amount);
      enforce(total <= data?.resource_unused_config?.sas).isTruthy();
    }
  );
  test(
    "warehouse_items",
    "Vui lòng chọn thiết bị cho tài nguyên dịch vụ.",
    () => {
      enforce(data?.warehouse_items?.length > 0).isTruthy();
    }
  );
});
const investmentResourceValidator = vest.create((data = {}) => {
  test("w_investment_resource_name", "Tên đầu tư không được để trống.", () => {
    enforce(data?.w_investment_resource_name).isNotEmpty();
  });
  test(
    "w_investment_resource_date_start",
    "Ngày bắt đầu đầu tư không được để trống.",
    () => {
      enforce(data?.w_investment_resource_date_start).isNotEmpty();
    }
  );
  test(
    "w_investment_resource_date_end",
    "Ngày kết thúc đầu tư không được để trống.",
    () => {
      enforce(data?.w_investment_resource_date_end).isNotEmpty();
    }
  );
  test(
    "w_investment_resource_date_end",
    "Ngày kết thúc đầu tư không được nhỏ hơn ngày bắt đầu đầu tư.",
    () => {
      const isGreaterThan = validationCustomizeDatetime({
        timeCompare: data?.w_investment_resource_date_end,
        timeDiff: data?.w_investment_resource_date_start,
        condition: "gte",
      });
      enforce(isGreaterThan).isTruthy();
    }
  );
});

const warehouseDepreciateValidator = vest.create((data = {}) => {
  test(
    "warehouse_item_price_depreciate",
    "Giá trị còn lại không được lớn hơn giá nhập kho.",
    () => {
      enforce(
        data?.warehouse_item_price_depreciate <= data?.warehouse_item_price
      ).isTruthy();
    }
  );
  test(
    "depreciate.wi_depreciate_date_start",
    "Ngày bắt đầu khấu hao không được để trống.",
    () => {
      enforce(data?.depreciate?.wi_depreciate_date_start).isNotEmpty();
    }
  );
  test(
    "depreciate.wi_depreciate_date_end",
    "Ngày kết thúc khấu hao không được để trống.",
    () => {
      enforce(data?.depreciate?.wi_depreciate_date_end).isNotEmpty();
    }
  );
  test(
    "depreciate.wi_depreciate_date_end",
    "Ngày kết thúc khấu hao không được nhỏ hơn ngày bắt đầu khấu hao.",
    () => {
      const isGreaterThan = validationCustomizeDatetime({
        timeCompare: data?.depreciate?.wi_depreciate_date_end,
        timeDiff: data?.depreciate?.wi_depreciate_date_start,
        condition: "gte",
      });
      enforce(isGreaterThan).isTruthy();
    }
  );
  test(
    "depreciate.wi_depreciate_price_month",
    "Giá trị khấu hao không được lớn hơn giá nhập kho.",
    () => {
      enforce(
        data?.depreciate?.wi_depreciate_price_month <=
        data?.warehouse_item_price
      ).isTruthy();
    }
  );
});

const warehouseTypeGroup2Validator = vest.create((data = {}) => {
  test("warehouse_type", "Thể loại không được để trống", () => {
    doesFieldSelectedEmpty(data?.warehouse_type);
  });
  test("warehouse_group_name", "Tên nhóm loại không được để trống", () => {
    enforce(data?.warehouse_group_name).isNotEmpty();
  });
  vest.skipWhen(
    (res) =>
      res.hasErrors("warehouse_group_name") ||
      (data?.type === "update" &&
        data?.warehouse_group_name === data?.warehouse_group_name_old),
    () => {
      test.memo(
        "warehouse_group_name",
        "Tên nhóm loại này đã tồn tại, vui lòng nhập tên khác.",
        async () => {
          const isGroupEmtpy = await doesGroupTypeExist(
            data.warehouse_group_name,
            parseInt(data?.warehouse_type?.warehouse_type_id)
          );
          enforce(isGroupEmtpy).isTruthy();
        },
        [data.warehouse_group_name]
      );
    }
  );
  test("warehouse_group_unit", "Đơn vị tính không được để trống", () => {
    doesFieldSelectedEmpty(data?.warehouse_group_unit);
  });
  test("warehouse_brand", "Hãng không được để trống", () => {
    doesFieldSelectedEmpty(data?.warehouse_brand);
  });
  let arr_custom_names = [];
  _.forEach(data?.warehouse_group_custom, (custom, index) => {
    test(
      `warehouse_group_custom.[${index}].name`,
      `Tên cấu hình không được để trống.`,
      () => {
        enforce(data?.warehouse_group_custom[`${index}`]?.name).isNotEmpty();
      }
    );
    test(
      `warehouse_group_custom.[${index}].name`,
      `Tên cấu hình không được lặp lại.`,
      () => {
        enforce(
          !arr_custom_names?.includes(
            data?.warehouse_group_custom[`${index}`]?.name
          )
        ).isTruthy();
      }
    );
    test(
      `warehouse_group_custom.[${index}].data`,
      `Giá trị cấu hình không hợp lệ.`,
      () => {
        enforce(
          parseInt(data?.warehouse_group_custom[`${index}`]?.data) >= 0
        ).isTruthy();
      }
    );
    arr_custom_names.push(custom?.name);
  });
});

const warehouseTypeGroupValidator = vest.create((data = {}) => {
  test("warehouse_type_id", "Thể loại không được để trống", () => {
    enforce(parseInt(data?.warehouse_type_id) > -1).isTruthy();
  });
  test("warehouse_group_name", "Tên nhóm loại không được để trống", () => {
    enforce(data?.warehouse_group_name).isNotEmpty();
  });
  vest.skipWhen(
    (res) =>
      res.hasErrors("warehouse_group_name") ||
      (data?.type === "update" &&
        data?.warehouse_group_name === data?.warehouse_group_name_old),
    () => {
      test.memo(
        "warehouse_group_name",
        "Tên nhóm loại này đã tồn tại, vui lòng nhập tên khác.",
        async () => {
          const isGroupEmtpy = await doesGroupTypeExist(
            data.warehouse_group_name,
            parseInt(data?.warehouse_type_id)
          );
          enforce(isGroupEmtpy).isTruthy();
        },
        [data.warehouse_group_name]
      );
    }
  );
  test("warehouse_group_unit", "Đơn vị tính không được để trống", () => {
    enforce(parseInt(data?.warehouse_group_unit) > -1).isTruthy();
  });
  test("warehouse_brand_id", "Hãng không được để trống", () => {
    enforce(parseInt(data?.warehouse_brand_id) > -1).isTruthy();
  });
  let arr_custom_names = [];
  _.forEach(data?.warehouse_group_custom, (custom, index) => {
    test(
      `warehouse_group_custom.[${index}].name`,
      `Tên cấu hình không được để trống.`,
      () => {
        enforce(data?.warehouse_group_custom[`${index}`]?.name).isNotEmpty();
      }
    );
    test(
      `warehouse_group_custom.[${index}].name`,
      `Tên cấu hình không được lặp lại.`,
      () => {
        enforce(
          !arr_custom_names?.includes(
            data?.warehouse_group_custom[`${index}`]?.name
          )
        ).isTruthy();
      }
    );
    test(
      `warehouse_group_custom.[${index}].data`,
      `Giá trị cấu hình không hợp lệ.`,
      () => {
        enforce(
          parseInt(data?.warehouse_group_custom[`${index}`]?.data) >= 0
        ).isTruthy();
      }
    );
    arr_custom_names.push(custom?.name);
  });
});
const warehouseBrandValidator = vest.create((data = {}) => {
  test("warehouse_brand_name", "Tên hãng không được để trống", () => {
    enforce(data?.warehouse_brand_name).isNotEmpty();
  });
  vest.skipWhen(
    (res) => res.hasErrors("warehouse_brand_name"),
    () => {
      test.memo(
        "warehouse_brand_name",
        "Tên hãng này đã tồn tại, vui lòng nhập tên khác.",
        async () => {
          const isBrandEmpty = await doesWarehouseBrandExist(
            data.warehouse_brand_name
          );
          enforce(isBrandEmpty).isTruthy();
        },
        [data.warehouse_brand_name]
      );
    }
  );
});

const companyValidator = vest.create((data = {}) => {
  test("company_name", "Tên công ty không được để trống", () => {
    enforce(data?.company_name).isNotEmpty();
  });
  vest.skipWhen(
    (res) =>
      res.hasErrors("company_name") ||
      (data?.type === "update" && data.company_name_old === data?.company_name),
    () => {
      test.memo(
        "company_name",
        "Tên công ty này đã tồn tại, vui lòng kiểm tra lại.",
        async () => {
          await doesCompanyExist(
            data.company_name,
            "company_name"
          );
        },
        [data.company_name]
      );
    }
  );
  test("company_tax", "Mã số thuế công ty không được để trống", () => {
    enforce(data?.company_tax).isNotEmpty();
  });
  vest.skipWhen(
    (res) =>
      res.hasErrors("company_tax") ||
      (data?.type === "update" && data.company_tax_old === data?.company_tax),
    () => {
      test.memo(
        "company_tax",
        "Mã số thuế này đã tồn tại với công ty khác, vui lòng kiểm tra lại.",
        async () => {
          await doesCompanyExist(
            data.company_tax,
            "company_tax"
          );
        },
        [data.company_tax]
      );
    }
  );
  test(
    "company_established_date",
    "Ngày thành lập công ty không hợp lệ",
    () => {
      enforce(data?.company_established_date).isNotEmpty();
    }
  );
});

const customercontactValidator = vest.create((data = {}) => {
  test("customer_contact_name", "Tên liên hệ không được để trống", () => {
    enforce(data?.customer_contact_name).isNotEmpty();
  });
  test(
    "customer_contact_name",
    "Tên liên hệ chứa ký tự/ từ không hợp lệ.",
    () => {
      return isHasKeywordNotValid(data?.customer_contact_name);
    }
  );
  test(
    "customer_contact_name",
    "Tên liên hệ không được chứa số.",
    () => {
      return isHasNumber(data?.customer_contact_name);
    }
  );
  test(
    "customer_contact_name",
    "Tên liên hệ không được chứa ký tự đặc biệt.",
    () => {
      return isHasSpecialCharacter(data?.customer_contact_name);
    }
  );
  test("customer_contact_position", "Chức Vụ không được bỏ trống", () => {
    enforce(data?.customer_contact_position).isNotEmpty();
  });
  test("customer_contact_email", "Email không được để trống", () => {
    enforce(data?.customer_contact_email).isNotEmpty();
  });
  test("customer_contact_email", "Email không hợp lệ", () => {
    enforce(data?.customer_contact_email).isEmail();
  });
  test(
    "customer_contact_birthday",
    "Ngày sinh không hợp lệ",
    () => {
      enforce(data?.customer_contact_birthday).isNotEmpty();
    }
  );
  test("customer_contact_birthday", "Độ tuổi không phù hợp(ex 18-90)", () => {
    const yearOlds = moment().diff(data?.customer_contact_birthday, "years");
    const isValidOld = yearOlds > 18 && yearOlds < 90;
    enforce(isValidOld).isTruthy();
  });
  test("customer_contact_phone", "Số điện thoại không được bỏ trống", () => {
    enforce(data?.customer_contact_phone).isNotEmpty();
  });
  test("customer_contact_industry", "Lĩnh vực không được bỏ trống", () => {
    enforce(data?.customer_contact_industry).isNotEmpty();
  });
  test("customer_contact_mission", "Nhiệm vụ không được bỏ trống", () => {
    enforce(data?.customer_contact_mission).isNotEmpty();
  });
});

const industryValidator = vest.create((data = {}) => {
  test("industry_name", "Tên lĩnh vực không được để trống", () => {
    enforce(data?.industry_name).isNotEmpty();
  });

  vest.skipWhen(
    (res) => res.hasErrors("industry_name"),
    () => {
      test.memo(
        "industry_name",
        "Tên lĩnh vực này đã tồn tại, vui lòng nhập tên khác.",
        async () => {
          const isIndustryEmtpy = await doesIndustryExist(data.industry_name);
          enforce(isIndustryEmtpy).isTruthy();
        },
        [data.industry_name]
      );
    }
  );
  test("childrens", "Lĩnh vực này chưa có ngành nghề nào", () => {
    enforce(data?.childrens?.length > 0).isTruthy();
  });
  vest.skipWhen(
    (res) => res.hasErrors("childrens"),
    () => {
      let names = [];
      _.forEach(data.childrens, (item, index) => {
        test(
          `childrens.[${index}].business_industry_name`,
          "Tên ngành nghề này không được để trống.",
          () => {
            enforce(item?.business_industry_name).isNotEmpty();
          }
        );
        test(
          `childrens.[${index}].business_industry_name`,
          "Tên ngành nghề không được trùng/ đã có ở trên.",
          () => {
            enforce(
              !names?.includes(item?.business_industry_name?.toLowerCase())
            ).isTruthy();
          }
        );
        names.push(item?.business_industry_name?.toLowerCase());
      });
    }
  );
});

const employeeTempValidator = vest.create((data = {}) => {
  test("employee_name", "Tên nhân viên không được để trống", () => {
    enforce(data?.employee_name).isNotEmpty();
  });
  // test("employee_code", "Mã nhân viên không được để trống", () => {
  //   enforce(data?.employee_code).isNotEmpty();
  // });
  test("department_id", "Vui lòng chọn phòng ban.", () => {
    enforce(data?.department_id > -1).isTruthy();
  });
  test("position_id", "Vui lòng chọn vị trí/ chức vụ.", () => {
    enforce(data?.position_id > -1).isTruthy();
  });
  test("employee_type", "Vui lòng chọn loại nhân viên.", () => {
    enforce(data?.employee_type > -1).isTruthy();
  });
  test(
    "employee_email_company",
    "Vui lòng nhập email công ty cấp cho nhân viên.",
    () => {
      enforce(data?.employee_email_company).isNotEmpty();
    }
  );
  test("employee_email_company", 'Email phải kết thúc bằng @vnetwork.vn', () => {
    enforce(data?.employee_email_company).matches(/@vnetwork\.vn$/);
  });
  test("employee_email_company", "Email không hợp lệ.", () => {
    enforce(data?.employee_email_company).isEmail();
  });
  vest.skipWhen(
    (res) => res.hasErrors("employee_email_company"),
    () => {
      test.memo(
        "employee_email_company",
        "Email này đã đăng ký tài khoản",
        async () => {
          return await doesEmailCompanyExist(data.employee_email_company);
        },
        [data.employee_email_company]
      );
    }
  );
});

const employeeInformationValidator = vest.create((data = {}) => {
  if (data.step === 0) {
    // thông tin cá nhân
    test(
      "basicInformation.employee_name",
      "Họ & tên nhân viên không được để trống.",
      () => {
        enforce(data?.basicInformation.employee_name).isNotEmpty();
      }
    );
    test(
      "basicInformation.employee_sex",
      "Vui lòng chọn giới tính của bạn.",
      () => {
        enforce(data?.basicInformation.employee_sex > -1).isTruthy();
      }
    );
    test(
      "basicInformation.employee_birthplace",
      "Vui lòng nhập nơi sinh.",
      () => {
        enforce(data?.basicInformation.employee_birthplace).isNotEmpty();
      }
    );
    test(
      "basicInformation.employee_idno",
      "Vui lòng nhập số CCCD/ số định danh cá nhân.",
      () => {
        enforce(data?.basicInformation.employee_idno).isNotEmpty();
      }
    );
    test(
      "basicInformation.employee_idno",
      "Số CCCD/ định danh cá nhân không hợp lệ (12 ký tự).",
      () => {
        enforce(
          data?.basicInformation?.employee_idno?.length === 12
        ).isTruthy();
      }
    );
    test(
      "basicInformation.employee_idno",
      "Số CCCD/ định danh cá nhân không hợp lệ (Không chứa ký tự chữ).",
      () => {
        const isNumber = verifyIsNumber(data?.basicInformation?.employee_idno);
        enforce(isNumber).isTruthy();
      }
    );
    test(
      "basicInformation.employee_idno_issued_on",
      "Số CCCD/ định danh cá nhân không hợp lệ.",
      () => {
        enforce(data?.basicInformation.employee_idno_issued_on).isNotEmpty();
      }
    );
    test(
      "basicInformation.employee_idno_issued_by",
      "Nơi cấp CCCD/ định danh cá nhân không được để trống.",
      () => {
        enforce(data?.basicInformation.employee_idno_issued_by).isNotEmpty();
      }
    );
    test(
      "basicInformation.employee_idno_issued_by",
      "Nơi cấp CCCD/ định danh cá nhân không hợp lệ.",
      () => {
        const isValid =
          data?.basicInformation?.employee_idno_issued_by?.toLowerCase() ===
          "Cục Cảnh sát quản lý hành chính về trật tự xã hội".toLowerCase();
        enforce(isValid).isTruthy();
      }
    );
    test(
      "basicInformation.employee_idno_issued_on",
      "Ngày cấp CCCD/ định danh cá nhân không hợp lệ.",
      () => {
        const isLessThanCurrent = validationCustomizeDatetime({
          timeCompare: data?.basicInformation?.employee_idno_issued_on,
          timeDiff: moment(),
          condition: "lte",
        });
        const isGreaterThanCurrent = validationCustomizeDatetime({
          timeCompare: data?.basicInformation?.employee_idno_issued_on,
          timeDiff: "2016-01-01",
          condition: "gte",
        });
        enforce(isLessThanCurrent && isGreaterThanCurrent).isTruthy();
      }
    );
    test(
      "basicInformation.employee_phone_number",
      "Số điện thoại cá nhân không hợp lệ, chỉ có thể chứa ký tự số.",
      () => {
        const isNumber = verifyIsNumber(
          data?.basicInformation?.employee_phone_number
        );
        enforce(isNumber).isTruthy();
      }
    );
    vest.skipWhen(
      (res) =>
        res.hasErrors("basicInformation.employee_phone_number") ||
        data?.basicInformation?.employee_phone_country !== "VN",
      () => {
        test.memo(
          "basicInformation.employee_phone_number",
          "Số điện thoại không hợp lệ( độ dài số điện thoại)",
          () => {
            const isValid =
              9 <= data?.basicInformation?.employee_phone_number?.length &&
              data?.basicInformation?.employee_phone_number?.length <= 12;
            enforce(isValid).isTruthy();
          },
          [data.basicInformation.employee_phone_number]
        );
      }
    );
  }
  if (data.step === 1) {
    //more information employee - thông tin chi tiết
    test(
      "moreInformation.employee_domicile",
      "Nguyên quán không được để trống.",
      () => {
        enforce(data?.moreInformation.employee_domicile).isNotEmpty();
      }
    );
    test(
      "moreInformation.employee_permanent_address",
      "Địa chỉ thường trú không được để trống.",
      () => {
        enforce(data?.moreInformation.employee_permanent_address).isNotEmpty();
      }
    );
    test(
      "moreInformation.employee_permanent_address_city",
      "Vui lòng chọn tỉnh/ thành phố thường trú.",
      () => {
        enforce(
          data?.moreInformation.employee_permanent_address_city !== -1
        ).isTruthy();
      }
    );
    test(
      "moreInformation.employee_permanent_address_district",
      "Vui lòng chọn quận/ huyện thường trú.",
      () => {
        enforce(
          data?.moreInformation.employee_permanent_address_city !== -1
        ).isTruthy();
      }
    );
    test(
      "moreInformation.employee_permanent_address_ward",
      "Vui lòng chọn phường/ xã thường trú.",
      () => {
        enforce(
          data?.moreInformation.employee_permanent_address_city !== -1
        ).isTruthy();
      }
    );

    if (data?.moreInformation?.employee_temporary_active) {
      test(
        "moreInformation.employee_temporary_address",
        "Địa chỉ tạm trú không được để trống.",
        () => {
          enforce(
            data?.moreInformation.employee_temporary_address
          ).isNotEmpty();
        }
      );
      test(
        "moreInformation.employee_temporary_address_city",
        "Vui lòng chọn tỉnh/ thành phố tạm trú.",
        () => {
          enforce(
            data?.moreInformation.employee_temporary_address_city !== -1
          ).isTruthy();
        }
      );
      test(
        "moreInformation.employee_temporary_address_district",
        "Vui lòng chọn quận/ huyện tạm trú.",
        () => {
          enforce(
            data?.moreInformation.employee_temporary_address_district !== -1
          ).isTruthy();
        }
      );
      test(
        "moreInformation.employee_temporary_address_ward",
        "Vui lòng chọn phường/ xã tạm trú.",
        () => {
          enforce(
            data?.moreInformation.employee_temporary_address_ward !== -1
          ).isTruthy();
        }
      );
    }
  }
  if (data.step === 2 && data?.relationInformations?.length > 0) {
    data?.relationInformations.forEach((relation, index) => {
      test(
        `relationInformations.[${index}].employee_homie_name`,
        "Họ & tên người thân không được để trống.",
        () => {
          enforce(relation.employee_homie_name).isNotEmpty();
        }
      );
      test(
        `relationInformations.[${index}].employee_homie_reference`,
        "Vui lòng chọn mối quan hệ với bản thân.",
        () => {
          enforce(relation.employee_homie_reference !== -1).isTruthy();
        }
      );
      test(
        `relationInformations.[${index}].employee_homie_phone_number`,
        "Số điện thoại người thân không được để trống.",
        () => {
          enforce(relation?.employee_homie_phone_number).isNotEmpty();
        }
      );
      test(
        `relationInformations.[${index}].employee_homie_phone_number`,
        "Số điện thoại người thân không hợp lệ",
        () => {
          const isNumber = verifyIsNumber(
            relation?.employee_homie_phone_number
          );
          const isValid =
            isNumber && relation?.employee_homie_phone_number?.length <= 12;
          enforce(isValid).isTruthy();
        }
      );
    });
  }
  if (data.step === 3) {
    data?.educationAndExpInformations?.length > 0 &&
      data?.educationAndExpInformations?.forEach((education, index) => {
        test(
          `educationAndExpInformations.[${index}].employee_major`,
          "Chuyên ngành không được để trống.",
          () => {
            enforce(education.employee_major).isNotEmpty();
          }
        );
        test(
          `educationAndExpInformations.[${index}].employee_experience`,
          "Kinh nghiệm làm việc không được để trống.",
          () => {
            enforce(education.employee_experience).isNotEmpty();
          }
        );
        test(
          `educationAndExpInformations.[${index}].employee_graduated`,
          "Trường tốt nghiệp không được để trống.",
          () => {
            enforce(education?.employee_graduated).isNotEmpty();
          }
        );
      });
  }
  if (data.step === 4) {
    // nothing to verify
  }
  if (data.step === 5) {
    test(
      `bankingAndTaxInformation.employee_account_bank_number`,
      "Số tài khoản ngân hàng không được để trống.",
      () => {
        enforce(
          data?.bankingAndTaxInformation?.employee_account_bank_number
        ).isNotEmpty();
      }
    );
    test(
      `bankingAndTaxInformation.employee_account_bank_number`,
      "Số tài khoản ngân hàng không hợp lệ.",
      () => {
        const isNumber = verifyIsNumber(
          data?.bankingAndTaxInformation?.employee_account_bank_number
        );
        enforce(isNumber).isTruthy();
      }
    );
    // verify length of employee_account_bank_number
    test(
      `bankingAndTaxInformation.employee_account_bank_number`,
      "Độ dài số tài khoản ngân hàng không hợp lệ.",
      () => {
        const lengthAccountNumber =
          data?.bankingAndTaxInformation?.employee_account_bank_number?.length;
        if (
          data?.bankingAndTaxInformation?.employee_bank_name === "techcombank"
        ) {
          return enforce(lengthAccountNumber === 14).isTruthy();
        }
        if (
          data?.bankingAndTaxInformation?.employee_bank_name === "vietcombank"
        ) {
          return enforce(lengthAccountNumber === 13).isTruthy();
        }
        return enforce(
          [8, 9, 11, 12, 13, 14, 15].includes(lengthAccountNumber)
        ).isTruthy();
      }
    );
  }
  if (data.step === 6) {
    test(
      `accountInformation.employee_email`,
      "Email cá nhân không được để trống.",
      () => {
        enforce(data?.accountInformation?.employee_email).isNotEmpty();
      }
    );
    test(
      `accountInformation.employee_email`,
      "Email cá nhân không hợp lệ.",
      () => {
        enforce(data?.accountInformation?.employee_email).isEmail();
      }
    );
    test(
      `accountInformation.employee_email_company`,
      "Email công ty không được để trống.",
      () => {
        enforce(data?.accountInformation?.employee_email_company).isNotEmpty();
      }
    );
    test(
      `accountInformation.employee_email`,
      "Email công ty không hợp lệ.",
      () => {
        enforce(data?.accountInformation?.employee_email_company).isEmail();
      }
    );
    test(`accountInformation.department_id`, "Vui lòng chọn phòng ban.", () => {
      enforce(data?.accountInformation?.department_id !== -1).isTruthy();
    });
    test(
      `accountInformation.position_id`,
      "Vui lòng chọn vị trí/ chức vụ.",
      () => {
        enforce(data?.accountInformation?.position_id !== -1).isTruthy();
      }
    );
    test(
      `accountInformation.employee_code`,
      "Mã nhân viên không được để trống.",
      () => {
        enforce(data?.accountInformation?.employee_code).isNotEmpty();
      }
    );
    test(
      `accountInformation.employee_role`,
      "Vui lòng chọn phân quyền phù hợp.",
      () => {
        enforce(data?.accountInformation?.employee_role !== -1).isTruthy();
      }
    );
    test(
      `accountInformation.employee_type`,
      "Vui lòng chọn hình thức nhân viên.",
      () => {
        enforce(data?.accountInformation?.employee_type !== -1).isTruthy();
      }
    );
    test(
      `accountInformation.start_worked_date`,
      "Vui lòng chọn ngày bắt đầu làm việc phù hợp.",
      () => {
        const isValid = validationCustomizeDatetime({
          timeCompare: data?.accountInformation?.start_worked_date,
          timeDiff: "2013-01-01",
          condition: "gte",
        });
        enforce(isValid).isTruthy();
      }
    );
  }
});

const dayoffValidator = vest.create((data = {}) => {
  test(`day_off_reason`, "Lý do xin nghỉ phép không được để trống.", () => {
    enforce(data?.day_off_reason).isNotEmpty();
  });
  if (parseInt(data?.day_off_type) !== 0) {
    test(
      "day_off_type",
      "Số ngày nghỉ phép vượt quá giới hạn ngày nghỉ phép năm còn lại được hưởng.",
      () => {
        let numberDayoff = 0;
        _.forEach(data?.day_off_range_time, (dayoff) => {
          if (dayoff.dayoff_option <= 1) {
            return (numberDayoff += 0.5);
          }
          return (numberDayoff += 1);
        });
        return enforce(numberDayoff <= data?.employee_day_off_left).isTruthy();
      }
    );
  }
  if (data?.type !== "create") {
    test(
      `day_off_note`,
      "Lý do huỷ/chỉnh sửa phiếu nghỉ phép không được để trống.",
      () => {
        enforce(data?.day_off_note).isNotEmpty();
      }
    );
  }
});

const businessValidator = vest.create((data = {}) => {
  test("business_place", "Nơi công tác không được để trống", () => {
    enforce(data?.business_place).isNotEmpty();
  });
  test(
    "business_enddate",
    "Thời gian kết thúc không được nhỏ hơn thời gian bắt đầu",
    () => {
      const hourDiff = moment(data?.business_enddate).diff(
        moment(data?.business_startdate),
        "hour"
      );
      enforce(hourDiff > 0).isTruthy();
    }
  );
  test("business_content", "Nội dung công tác không được để trống", () => {
    enforce(data?.business_content).isNotEmpty();
  });
  if (data?.isMeeting) {
    test(
      "business_meeting_customer.b_meeting_customer_name",
      "Tên khách hàng meeting không được để trống.",
      () => {
        enforce(
          data?.business_meeting_customer.b_meeting_customer_name
        ).isNotEmpty();
      }
    );
    test(
      "business_meeting_customer.b_meeting_customer_email",
      "Email khách hàng meeting không được để trống.",
      () => {
        enforce(
          data?.business_meeting_customer.b_meeting_customer_email
        ).isNotEmpty();
      }
    );
    test(
      "business_meeting_customer.b_meeting_customer_email",
      "Email khách hàng meeting không hợp lệ.",
      () => {
        enforce(
          data?.business_meeting_customer.b_meeting_customer_email
        ).isEmail();
      }
    );
    test(
      "business_meeting_customer.b_meeting_customer_phone",
      "Số điện thoại khách hàng meeting không được để trống.",
      () => {
        enforce(
          data?.business_meeting_customer.b_meeting_customer_phone
        ).isNotEmpty();
      }
    );
    test(
      "business_meeting_customer.b_meeting_customer_phone",
      "Số điện thoại khách hàng meeting không hợp lệ.",
      () => {
        const isNumber = verifyIsNumber(
          data?.business_meeting_customer?.b_meeting_customer_phone
        );
        const isValidLength = verifyLength(
          data?.business_meeting_customer?.b_meeting_customer_phone?.length,
          8,
          10
        );
        enforce(isNumber && isValidLength).isTruthy();
      }
    );
    test(
      "business_meeting_customer.b_meeting_customer_position",
      "Chức vụ khách hàng meeting không được để trống.",
      () => {
        enforce(
          data?.business_meeting_customer.b_meeting_customer_position
        ).isNotEmpty();
      }
    );
    test(
      "meeting.meeting_summary",
      "Thông tin summary không được để trống.",
      () => {
        enforce(data?.meeting.meeting_summary).isNotEmpty();
      }
    );
    test("meeting.content", "Nội dung không được để trống.", () => {
      enforce(data?.meeting.meeting_content).isNotEmpty();
    });
    test("meeting.follow_up", "Thông tin followup không được để trống.", () => {
      enforce(data?.meeting.meeting_follow_up).isNotEmpty();
    });
    test(
      "business_meeting_customer.company_id",
      "Vui lòng chọn công ty.",
      () => {
        enforce(data?.business_meeting_customer.company_id > -1).isTruthy();
      }
    );
    //
  }
});

const businessItemValidator = vest.create((data = {}) => {
  if (data?.business_item_type === "another") {
    _.forEach(data?.another, (item, index) => {
      test(
        `another.[${index}].business_item_content`,
        "Thông tin không được để trống.",
        () => {
          enforce(item?.business_item_content).isNotEmpty();
        }
      );
      test(
        `another.[${index}].business_item_value`,
        "Thông tin không được để trống.",
        () => {
          const isNumber = verifyIsNumber(item?.business_item_value);
          enforce(isNumber).isTruthy();
        }
      );
      test(
        `another.[${index}].business_item_price`,
        "Đơn giá không hợp lệ.",
        () => {
          const isNumber = verifyIsNumber(item?.business_item_price);
          enforce(isNumber).isTruthy();
        }
      );
    });
  } else {
    test(
      "business_item_value",
      "Giá trị tương ứng không được để trống.",
      () => {
        enforce(data?.business_item_value).isNotEmpty();
      }
    );
    test("business_item_value", "Giá trị tương ứng không hợp lệ.", () => {
      const isNumber = verifyIsNumber(data?.business_item_value);
      enforce(isNumber).isTruthy();
    });
  }
  if (["ot-x1", "ot-x2"].includes(data?.business_item_type)) {
    test(
      "business_item_end",
      "Thời gian kết thúc phải lớn hơn thời gian bắt đầu.",
      () => {
        const hourDiff = moment(data?.business_item_end).diff(
          moment(data?.business_item_start),
          "hour"
        );
        enforce(hourDiff > 0).isTruthy();
      }
    );
    test(
      "business_item_content",
      "Nội dung công việc không được để trống.",
      () => {
        enforce(data?.business_item_content).isNotEmpty();
      }
    );
    test(
      "business_item_value",
      "Số phút thực hiện công việc không hợp lệ.",
      () => {
        enforce(parseInt(data?.business_item_value) > 0).isTruthy();
      }
    );
    //
  }
});

const contractNumberValidator = vest.create((data = {}) => {
  test(
    "contract_number_owner",
    "Vui lòng chọn công ty/ chủ thể số hợp đồng.",
    () => {
      enforce(data?.contract_number_owner !== -1).isTruthy();
    }
  );
  test(
    "contract_number_service",
    "Vui lòng chọn dịch vụ của số hợp đồng.",
    () => {
      enforce(data?.contract_number_service !== -1).isTruthy();
    }
  );
});

const contractNumberProcessValidator = vest.create((data = {}) => {
  test("contract_process_title", "Tiêu đề không được để trống.", () => {
    enforce(data?.contract_process_title).isNotEmpty();
  });
  test(
    "contract_process_problem",
    "Vui lòng nhập mô tả vấn để cần hỗ trợ.",
    () => {
      enforce(data?.contract_process_problem).isNotEmpty();
    }
  );
  test(
    "contract_process_assignee",
    "Vui lòng chọn người tiếp nhận xử lý vấn đề.",
    () => {
      enforce(data?.contract_process_assignee?.length > 0).isTruthy();
    }
  );
  test("contract_process_deadline", "Thời hạn xử lý không hợp lệ.", () => {
    const isGreaterThan = validationCustomizeDatetime({
      timeCompare: data?.contract_process_deadline,
      timeDiff: moment(),
      condition: "gte",
    });
    enforce(isGreaterThan).isTruthy();
  });
});

const postmanTrackingValidator = vest.create((data = {}) => {
  test("postman_tracking_mvd", "Mã vận đơn không được để trống.", () => {
    enforce(data?.postman_tracking_mvd).isNotEmpty();
  });
  test(
    "postman_tracking_package",
    "Mã số hợp đồng không được để trống.",
    () => {
      enforce(data?.postman_tracking_package).isNotEmpty();
    }
  );
  vest.skipWhen(
    (res) =>
      res.hasErrors("postman_tracking_mvd") ||
      (data?.method === "update" &&
        data?.postman_tracking_mvd === data?.postman_tracking_mvd_old),
    () => {
      test.memo(
        "postman_tracking_mvd",
        "Mã vận đơn này đã tồn tại vui lòng kiểm tra lại.",
        async () => {
          const response = await resolverPostmanTrackings(
            data.postman_tracking_mvd
          );
          const postmanTrackings = response?.data?.data?.postman_trackings;
          if (data?.method === "create") {
            const record = postmanTrackings?.find(
              (t) =>
                t?.postman_tracking_mvd?.toLowerCase() ===
                data?.postman_tracking_mvd?.toLowerCase()
            );
            enforce(!record).isTruthy();
          }
        },
        [data.postman_tracking_mvd]
      );
    }
  );
  test(
    "postman_tracking_partner",
    `Tên công ty/ người ${parseInt(data?.postman_tracking_scope) === 0 ? "nhận" : "gửi"
    } không được để trống.`,
    () => {
      enforce(data?.postman_tracking_partner).isNotEmpty();
    }
  );
  test(
    "postman_tracking_phone",
    "Số điện thoại người nhận không được để trống.",
    () => {
      enforce(data?.postman_tracking_phone).isNotEmpty();
    }
  );
  test("postman_tracking_phone", "Độ dài số điện thoại không hợp lệ.", () => {
    const isValid = verifyLength(
      data?.postman_tracking_phone?.length || 0,
      10,
      12
    );
    enforce(isValid).isTruthy();
  });
  test(
    "postman_tracking_phone",
    "Số điện thoại không hợp lệ, chỉ có thể chứa ký tự số.",
    () => {
      const removeCharactor = data?.postman_tracking_phone?.replaceAll("+", "");
      const isNumber =
        removeCharactor !== "" && verifyIsNumber(removeCharactor);
      enforce(isNumber).isTruthy();
    }
  );

  vest.skipWhen(
    () => parseInt(data?.postman_tracking_scope) === 1,
    () => {
      test("postman_tracking_address", "Địa chỉ không được để trống.", () => {
        enforce(data?.postman_tracking_address).isNotEmpty();
      });
    }
  );

  vest.skipWhen(
    () => parseInt(data?.postman_tracking_scope) === 1,
    () => {
      test(
        "postman_tracking_sent_date",
        "Ngày gửi thư không được để trống.",
        () => {
          enforce(data?.postman_tracking_sent_date).isNotEmpty();
        }
      );
    }
  );
  // vest.skipWhen(
  //   () => parseInt(data?.postman_tracking_scope) === 0,
  //   () => {
  //     test(
  //       "postman_tracking_receive_date",
  //       "Ngày nhận thư không được để trống",
  //       () => {
  //         enforce(data?.postman_tracking_receive_date).isNotEmpty();
  //       }
  //     );
  //   }
  // );
});

const contractValidator = vest.create((data = {}) => {
  test("contract_number_id", "Mã số hợp đồng không được để trống.", () => {
    enforce(data?.contract_number_id).isNotEmpty();
  });
  test("contract_key", "Từ khoá hợp đồng không được để trống.", () => {
    enforce(data?.contract_key).isNotEmpty();
  });
  // test("contract_value", "Tổng tiền hợp đồng không hợp lệ.", () => {
  //   enforce(parseInt(data?.contract_value) > 0).isTruthy();
  // });
  // test(
  //   "contract_status",
  //   "Thao tác huỷ hợp đồng không thể thực hiện tại đây.",
  //   () => {
  //     enforce(parseInt(data?.contract_status) !== 1).isTruthy();
  //   }
  // );
  // test("contract_service", "Dịch vụ hợp đồng không được để trống.", () => {
  //   enforce(Boolean(data?.contract_service)).isTruthy();
  // });
  // test("expiry_date", "Ngày hết hạn hiệu lực hợp đồng không hợp lệ", () => {
  //   const isGt = validationCustomizeDatetime({
  //     condition: "gte",
  //     timeCompare: data?.expiry_date,
  //     timeDiff: data.effective_date,
  //   });
  //   enforce(isGt).isTruthy();
  // });
  // test("file", "Vui lòng chọn tập tin đính kèm.", () => {
  //   enforce(!!data?.document).isTruthy();
  // });
});

const subcontractValidator = vest.create((data = {}) => {
  test(
    "subcontract_code",
    "Mã số phụ lục hợp đồng không được để trống.",
    () => {
      enforce(data?.subcontract_code).isNotEmpty();
    }
  );
  test(
    "subcontract_code_id",
    "Số phụ lục hợp đồng không được để trống.",
    () => {
      enforce(data.subcontract_code_id).isNotEmpty();
    }
  );
  test("subcontract_name", "Tên phụ lục hợp đồng không được để trống.", () => {
    enforce(data.subcontract_name).isNotEmpty();
  });
  // test("document", "Vui lòng chọn tập tin đính kèm.", () => {
  //   enforce(!!data?.document).isTruthy();
  // });
});

const postmanRecieverValidator = vest.create((data = {}) => {
  test(
    "postman_reciever_name",
    "Tên công ty/ khách hàng không được để trống.",
    () => {
      enforce(data?.postman_reciever_name).isNotEmpty();
    }
  );
  test(
    "postman_reciever_phone_number",
    "Số điện thoại không được để trống.",
    () => {
      enforce(data?.postman_reciever_phone_number).isNotEmpty();
    }
  );
  test("postman_reciever_address", "Địa chỉ không được để trống.", () => {
    enforce(data?.postman_reciever_address).isNotEmpty();
  });
});

const customerJourneyValidator = vest.create((data = {}) => {
  test(
    "postman_reciever_name",
    "Tên công ty/ khách hàng không được để trống.",
    () => {
      enforce(data?.postman_reciever_name).isNotEmpty();
    }
  );
  test(
    "postman_reciever_phone_number",
    "Số điện thoại không được để trống.",
    () => {
      enforce(data?.postman_reciever_phone_number).isNotEmpty();
    }
  );
  test("postman_reciever_address", "Địa chỉ không được để trống.", () => {
    enforce(data?.postman_reciever_address).isNotEmpty();
  });
});

const businessUpdateValidator = vest.create((data = {}) => {
  test("meeting.meeting_result", "Vui lòng chọn kết quả công tác.", () => {
    enforce(
      ["followup", "closed"].includes(data?.meeting?.meeting_result)
    ).isTruthy();
  });
  // failed
  if (data?.meeting?.meeting_result === "closed") {
    test("meeting.meeting_failed_reason", "Vui lòng chọn lý do", () => {
      enforce(
        ["budget", "target", "target2", "provider", "others"].includes(
          data?.meeting.meeting_failed_reason
        )
      ).isTruthy();
    });
    vest.skipWhen(
      (res) =>
        res.hasErrors("meeting.meeting_failed_reason") ||
        ["target", "target2"].includes(
          data?.meeting?.meeting_failed_explanation
        ),
      () => {
        if (data?.meeting.meeting_failed_reason === "budget") {
          return test(
            "meeting.meeting_failed_explanation",
            "Vui lòng nhập budget của khách hàng",
            () => {
              enforce(data?.meeting?.meeting_failed_explanation).isNotEmpty();
            }
          );
        }
        if (data?.meeting.meeting_failed_reason === "others") {
          return test(
            "meeting.meeting_failed_explanation",
            "Vui lòng nhập lý do.",
            () => {
              enforce(data?.meeting?.meeting_failed_explanation).isNotEmpty();
            }
          );
        }
        if (data?.meeting.meeting_failed_reason === "provider") {
          return test(
            "meeting.meeting_failed_explanation",
            "Vui lòng nhập tên nhà cung cấp trị.",
            () => {
              enforce(data?.meeting?.meeting_failed_explanation).isNotEmpty();
            }
          );
        }
      }
    );
  }
  if (data?.meeting?.meeting_result === "followup") {
    test("products", "Vui lòng thêm sản phẩm vào kế hoạch bán hàng", () => {
      enforce(data?.products?.length >= 1).isTruthy();
    });
    test(
      "meeting.meeting_provider_explaination",
      "Vui lòng nhập tên nhà cung cấp.",
      () => {
        enforce(data?.meeting?.meeting_provider_explaination).isNotEmpty();
      }
    );
    test(
      "meeting.meeting_budget_explaination",
      "Vui lòng nhập budget khách hàng.",
      () => {
        enforce(data?.meeting?.meeting_budget_explaination).isNotEmpty();
      }
    );
    test(
      "saleplan.saleplan_expected_date",
      "Vui lòng chọn thời gian dự tính bán.",
      () => {
        enforce(data?.saleplan?.saleplan_expected_date).isNotEmpty();
      }
    );
    test(
      "meeting.meeting_influencer_explaination",
      "Vui lòng nhập người đưa ra quyết định.",
      () => {
        enforce(data?.meeting?.meeting_influencer_explaination).isNotEmpty();
      }
    );
    test(
      "meeting.meeting_problem_explaination",
      "Vui lòng nhập vấn đề của khách hàng.",
      () => {
        enforce(data?.meeting?.meeting_problem_explaination).isNotEmpty();
      }
    );
  }
});

const resolverProduct = async (productGroupId, name) => {
  const query = `filters[$and][0][product_group_id][$eq]=${productGroupId}&filters[$and][1][product_name][$contains]=${name}`;
  return await requestWithToken(
    `${API_V3_URL}/products/list?filters=${encodeBase64(query)}`
  );
};

const productValidator = vest.create((data = {}) => {
  test("product_group_id", "Vui lòng chọn gói chính cho sản phẩm", () => {
    enforce(parseInt(data.product_group_id) >= 1).isTruthy();
  });
  test("product_name", "Tên gói sản phẩm không được để trống.", () => {
    enforce(data.product_name).isNotEmpty();
  });
  vest.skipWhen(
    (res) =>
      res.hasErrors("product_name") ||
      res.hasErrors("product_group_id") ||
      (data?.action === "update" &&
        data.product_name === data.product_name_old),
    () => {
      test.memo(
        "product_name",
        "Tên gói sản phẩm/ dịch vụ này đã tồn tại, vui lòng nhập tên khác.",
        async () => {
          const response = await resolverProduct(
            parseInt(data.product_group_id),
            data.product_name
          );
          const isNotValid = response?.data?.data?.products?.find(
            (t) =>
              t?.product_name?.toLowerCase() ===
              data?.product_name.toLowerCase()
          );
          enforce(!isNotValid).isTruthy();
        },
        [data.product_name, data?.product_group_id]
      );
    }
  );
  test(
    "product_configoptions",
    "Vui lòng thiết lập cấu hình cho gói sản phẩm dịch vụ.",
    () => {
      enforce(data.product_configoptions.length > 0).isTruthy();
    }
  );
  vest.skipWhen(
    () => Boolean(data?.product_customizable),
    () => billingCycleValidator({ data: data?.product_billings })
  );

  let product_configoption_names = [];
  data.product_configoptions.forEach((cofg, index) => {
    test(
      `product_configoptions.[${index}].product_configoption_name`,
      "Tên cấu hình không được để trống",
      () => {
        enforce(cofg.product_configoption_name).isNotEmpty();
      }
    );
    test(
      `product_configoptions.[${index}].product_configoption_name`,
      "Tên cấu hình trùng lặp.",
      () => {
        enforce(
          !product_configoption_names.includes(
            cofg.product_configoption_name.toLowerCase()
          )
        ).isTruthy();
      }
    );
    product_configoption_names.push(
      cofg?.product_configoption_name?.toLowerCase()
    );
    test(
      `product_configoptions.[${index}].product_configoption_items`,
      "Vui lòng thiết lập thông số cấu hình.",
      () => {
        enforce(cofg.product_configoption_items.length > 0).isTruthy();
      }
    );
    // handler verify product_configoption_items
    const refProductCogitem = [
      "product_configoptions",
      `[${index}]`,
      "product_configoption_items",
    ].join(".");
    let product_configoption_item_names = [];
    cofg.product_configoption_items?.forEach((item, k) => {
      test(
        `${refProductCogitem}.[${k}].product_configoption_item_name`,
        "Required.",
        () => {
          enforce(item.product_configoption_item_name).isNotEmpty();
        }
      );
      test(
        `${refProductCogitem}.[${k}].product_configoption_item_name`,
        "Duplicated.",
        () => {
          enforce(
            !product_configoption_item_names.includes(
              item.product_configoption_item_name.toLowerCase()
            )
          ).isTruthy();
        }
      );
      product_configoption_item_names.push(
        item?.product_configoption_item_name?.toLowerCase()
      );
      vest.skipWhen(
        () =>
          !!item.product_configoption_item_unlimited ||
          (data.product_customizable &&
            item?.configoption_item_pricings?.length > 0),
        () => {
          test(
            `${refProductCogitem}.[${k}].product_configoption_item_value`,
            "Required.",
            () => {
              enforce(
                parseInt(item.product_configoption_item_value) > 0
              ).isTruthy();
            }
          );
        }
      );
      vest.skipWhen(
        () =>
          (!!data?.product_customizable &&
            item?.configoption_item_pricings?.length !== 0) ||
          !data?.product_customizable,
        () => {
          test(
            `${refProductCogitem}.[${k}].product_configoption_item_pricing`,
            "Required.",
            () => {
              enforce(
                parseInt(item.product_configoption_item_pricing) > 0
              ).isTruthy();
            }
          );
        }
      );
      vest.skipWhen(
        () =>
          !data.product_customizable ||
          item?.configoption_item_pricings?.length === 0,
        () =>
          configoptionItemPricingsValidator({
            data: item.configoption_item_pricings,
            ref: [
              ...Array.from(refProductCogitem.split(".")),
              `[${k}]`,
              "configoption_item_pricings",
            ].join("."),
          })
      );
    });
  });
});

const configoptionItemPricingsValidator = ({ data = [], ref }) => {
  let unlimitedCounter = 0;
  let compareValue = 0;
  data.forEach((pricing, i) => {
    test(`${ref}.[${i}].pricing`, "Thêm giá.", () => {
      enforce(parseInt(pricing.pricing) > 0).isTruthy();
    });
    test(ref, "Duplicated unlimited.", () => {
      enforce(unlimitedCounter <= 1).isTruthy();
    });
    vest.skipWhen(
      () => !!pricing?.unlimited,
      () => {
        let started = parseInt(pricing.start_value);
        test(`${ref}.[${i}].start_value`, `Unvalid.`, () => {
          enforce(started >= compareValue).isTruthy();
        });
        compareValue = started;

        let end = parseInt(pricing.finish_value);
        test(`${ref}.[${i}].finish_value`, `Unvalid.`, () => {
          enforce(end >= compareValue).isTruthy();
        });
        compareValue = end;
      }
    );
    if (pricing?.unlimited) {
      unlimitedCounter += 1;
    }
  });
};

const billingCycleValidator = ({ data = {} }) => {
  let counter = 0;
  data?.forEach((term) => {
    if (parseInt(term.pricing) > 0) counter++;
  });
  test(
    "product_billings",
    "Vui lòng điền thông tin giá của chu kỳ dịch vụ",
    () => {
      enforce(counter > 0).isTruthy();
    }
  );
};

export {
  productValidator,
  businessUpdateValidator,
  customerJourneyValidator,
  postmanRecieverValidator,
  subcontractValidator,
  contractValidator,
  postmanTrackingValidator,
  contractNumberValidator,
  contractNumberProcessValidator,
  businessItemValidator,
  businessValidator,
  dayoffValidator,
  employeeInformationValidator,
  employeeTempValidator,
  customerValidatorByStep,
  creditValidator,
  domainValidator,
  customerSpamValidator,
  transactionValidator,
  configureExtraValidator,
  transactionServiceValidator,
  serviceAddonValidator,
  dnsRecordValidator,
  customerJourneyValidation,
  warehouseTypeValidator,
  accessoryValidator,
  warehouseProcessValidator,
  deviceValidator,
  wbRackValidator,
  wbRackInvoiceValidator,
  warehouseTypeGroupValidator,
  warehouseTypeGroup2Validator,
  warehouseResourceValidator,
  warehouseResourceItemValidator,
  investmentResourceValidator,
  warehouseDepreciateValidator,
  warehouseBrandValidator,
  industryValidator,
  companyValidator,
  customercontactValidator,
};
