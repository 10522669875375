import CustomerTabs from "@components/ManagementCustomer/CustomerTabs";
import { AddOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import DashboardWelcome from "../../shared/DashboardWelcome";

const CustomerCare = ({ children }) => {
  return (
    <Box>
      <Box
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <DashboardWelcome />
        <Link to="customers/new" replace>
          <Button size="small" variant="outlined" startIcon={<AddOutlined />}>
            Thêm khách hàng
          </Button>
        </Link>
      </Box>
      <CustomerTabs />
      {children}
    </Box>
  );
};

export default CustomerCare;
