import React from 'react';

const withSuspense = (Component, Skeleton) =>
    // eslint-disable-next-line react/display-name
    ({ ...props }) => {
        const [loading, setLoading] = React.useState(true);
        // perform handle client-side loaded
        React.useEffect(() => {
            const timer = setInterval(() => setLoading(false), 200)
            return () => clearInterval(timer)
        }, []);

        return loading ? (
            Skeleton ? (
                <Skeleton />
            ) : null
        ) : (
            <React.Suspense fallback={Skeleton ? <Skeleton /> : null}>
                <Component {...props} />
            </React.Suspense>
        );
    };

export default withSuspense;
