import InvoiceItemApi from "@/apis/beta/Invoice/InvoiceItemApi";
import { queryClientRefetcher } from "@/functions";
import withPermission from "@/hocs/withPermission";
import ModalConfirm from "@components/shared/ModalConfirm";
import { Typography } from "@mui/material";
import { useQueryClient } from "react-query";
const Cancel = ({ onClose, invoiceId, invoiceItemId, description = "" }) => {
  const queryClient = useQueryClient();

  const handleCancelledInvoice = async () => {
    const response = await InvoiceItemApi.Cancelled({
      invoiceId: invoiceId,
      invoiceItemId: invoiceItemId,
    });
    if (!response || response?.errors) {
      return {
        message: "Huỷ hoá đơn thất bại. Vui lòng thử lại.",
        status: 404,
      };
    }
    return {
      message: "Huỷ hoá đơn thành công.",
      status: 200,
    };
  };

  const invoiceCallback = () => {
    queryClientRefetcher(queryClient, [
      "invoices.list",
      "invoices.statistic",
      "service.invoices",
      "customer.invoices",
      "invoice.detail",
      "invoice.invoice_items",
      "invoice.informations",
      "invoice.transactions.list",
      "invoice.loggers",
    ]);
  };

  return (
    <ModalConfirm
      callback={invoiceCallback}
      handleClose={onClose}
      handleSubmit={handleCancelledInvoice}
      dialog={{
        title: "Xoá Thông Tin Hoá Đơn",
        content: (
          <Typography component="div">
            Thông tin hóa đơn
            <Typography
              sx={{ display: "inline", fontWeight: "medium", m: "auto 4px" }}
            >
              [{description}]
            </Typography>
            sẽ bị xoá. Thao tác sẽ không thể phục hồi, bạn có muốn thực hiện?
          </Typography>
        ),
      }}
    />
  );
};

export default withPermission(Cancel, {
  feature: "invoice",
  action: "invoice-item-destroy",
  type: "Modal",
});
