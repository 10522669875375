import PermissionGroupApi from "@/apis/beta/Permission/PermissionGroupApi";
import BreadCrumb from "@/components/shared/BreadCrumb";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import GroupRoleForm from "../shared/GroupRoleForm";

const Update = () => {
  const { group_role_id } = useParams();

  const { data } = PermissionGroupApi.Detail({ id: group_role_id });
  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: 18, md: 24 },
          fontWeight: "medium",
        }}
      >
        Chỉnh Sửa Nhóm Quyền
      </Typography>
      <BreadCrumb
        data={[
          { label: "Quản Lý Nhóm Quyền", reactLink: "/group-roles" },
          { label: data?.group_permission?.group_permission_name },
          { label: "Chỉnh Sửa Nhóm Quyền" },
        ]}
      />
      <GroupRoleForm type="update" permissionGroup={data?.group_permission} />
    </Box>
  );
};
export default withSuspense(
  withPermission(Update, { feature: "group-role", action: "show" }),
  null
);
