import ProductAddonApi from "@/apis/beta/Product/ProductAddonApi";
import { format_date_short, format_numeral_price } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import { PRODUCT_UNITS } from "@/libs/constants";
import DataGridCustomize from "@components/DataGrid";
import PopperAction from "@components/PopperAction";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { AddOutlined, MenuOutlined } from "@mui/icons-material";
import MoreVertOutlined from "@mui/icons-material/MoreVertOutlined";
import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { Form } from "../shared";

const ProductAddons = ({ productId }) => {
  const columns = [
    {
      field: "id",
      headerName: ".No",
      width: 60,
    },
    {
      field: "product_name",
      headerName: "Tên Sản Phẩm Addon",
      width: 300,
    },
    {
      field: "unit",
      headerName: "Đơn Vị Tính",
      valueGetter: ({ value }) => {
        return PRODUCT_UNITS?.find((x) => x?.value === value)?.label;
      },
      width: 120,
    },
    {
      field: "pricing",
      headerName: "Đơn Giá Niêm Yết",
      width: 150,
      valueGetter: ({ row }) => row?.pricing?.pricing || "",
      renderCell: ({ value }) => (!!value ? format_numeral_price(value) : ""),
    },
    {
      field: "pricing_minimum",
      headerName: "Đơn Giá Min",
      width: 150,
      valueGetter: ({ row }) => row?.pricing?.pricing_minimum || "",
      renderCell: ({ value }) => (!!value ? format_numeral_price(value) : ""),
    },
    {
      field: "pricing_apply_startdate",
      headerName: "Thời Gian Tính Phí Dịch Vụ",
      width: 300,
      renderCell: ({ row }) =>
        `${format_date_short(
          row?.pricing?.pricing_apply_startdate
        )} - ${format_date_short(row?.pricing?.pricing_apply_duedate)}`,
    },
    {
      field: "actions",
      headerName: "",
      width: 60,
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            size="small"
            sx={{ m: "auto" }}
            onClick={(event) =>
              setPopper({ anchorEl: event?.currentTarget, data: row })
            }
          >
            <MoreVertOutlined fontSize="small" />
          </IconButton>
        </Box>
      ),
    },
  ];

  const [form, setForm] = useState({
    component: null,
    open: false,
    data: undefined,
  });

  const onClose = () =>
    setForm({ component: null, open: false, data: undefined });

  const { data } = ProductAddonApi.List({ productId });

  const renderForm = () => {
    switch (form?.component) {
      case "create":
        return <Form.CreateAddon onClose={onClose} />;
      case "select":
        return (
          <Form.SelectAddon
            onClose={onClose}
            selecteds={[data?.map((p) => p?.product_addon_id)]}
          />
        );
      case "remove":
        return <Form.RemoveAddon onClose={onClose} data={form?.data} />;
      default:
        return null;
    }
  };

  const [popper, setPopper] = useState({
    anchorEl: undefined,
    data: undefined,
  });

  return (
    <Fragment>
      {Boolean(popper?.anchorEl) && (
        <Popover anchorEl={popper?.anchorEl} open={true}>
          <PopperAction
            handleClose={() =>
              setPopper({ anchorEl: undefined, data: undefined })
            }
            data={popper?.data}
            actions={[
              // {
              //   name: "Edit",
              //   icon: "Edit",
              //   label: "Chỉnh sửa",
              // },
              {
                name: "Remove",
                icon: "Delete",
                label: "Xoá ADDON",
                handleClick: (metadata) => {
                  setForm({ open: true, component: "remove", data: metadata });
                  setPopper({ anchorEl: undefined, data: undefined });
                },
              },
            ]}
          />
        </Popover>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          mb: 1.5,
          mt: 1.5,
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: 16, md: 18 },
            fontWeight: "medium",
            m: "auto 0",
          }}
        >
          Danh Sách Sản Phẩm Addons
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: 2,
          }}
        >
          <Button
            size="small"
            variant="outlined"
            disabled={form?.open}
            onClick={() => setForm({ open: true, component: "select" })}
            startIcon={<MenuOutlined fontSize="small" />}
          >
            Chọn Từ Danh Sách Có Sẵn
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={form?.open}
            onClick={() => setForm({ open: true, component: "create" })}
            startIcon={<AddOutlined fontSize="small" />}
          >
            Thêm ADDON
          </Button>
          {form?.open && !!form?.component && renderForm()}
        </Box>
      </Box>
      <DataGridCustomize
        columns={columns}
        components={{
          Toolbar: "disabled",
          Pagination: "disabled",
        }}
        rows={data?.map((product, index) => ({
          ...product,
          id: index + 1,
        }))}
      />
    </Fragment>
  );
};
export default withSuspense(ProductAddons, MuiSkeleton["DataGrid"]);
