import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Collapse, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Addon from "./Addon";
import Service from "./Service";

const Informations = ({ onClose, service: data }) => {
	return (
		<Dialog open={true} maxWidth="md" fullWidth>
			<DialogTitle>Chỉnh sửa cấu hình dịch vụ</DialogTitle>
			<DialogContent>
				<Collapse
					in={
						!!data &&
						data?.service_referrer_type !== "Reducer::Override::Service"
					}
				>
					<Service service={data} onClose={onClose} />
				</Collapse>
				<Collapse
					in={
						!!data &&
						data?.service_referrer_type === "Reducer::Override::Service"
					}
				>
					<Addon service={{ ...data }} onClose={onClose} />
				</Collapse>
			</DialogContent>
		</Dialog>
	);
};

export default withSuspense(Informations, MuiSkeleton["LinearProgress"]);
