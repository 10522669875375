import ContractApi from '@/apis/beta/Contract/ContractApi'
import SubcontractApi from '@/apis/beta/Contract/SubcontractApi'
import BreadCrumb from '@/components/shared/BreadCrumb'
import withPermission from '@/hocs/withPermission'
import withSuspense from "@/hocs/withSuspense"
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { AddOutlined } from '@mui/icons-material'
import { Box, Button, Drawer, Typography } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import PartnerContractInformation from "./Information"
import SubContract from './SubContract'

const Detail = () => {
    const { partner_contract_id } = useParams()
    const { data: contract } = ContractApi.Detail(partner_contract_id)

    const [openSubContractForm, setOpenSubContractForm] = React.useState({
        open: false, type: null, data: null
    })

    const [page, setPage] = React.useState({
        page: {
            page: 0,
            pageSize: 5
        }
    })
    const { data, isFetching, refetch: callbackPartnerSubContract } = SubcontractApi.List({
        id: partner_contract_id,
        query: null,
        page: page?.page + 1,
        pageSize: page?.pageSize
    })

    const handleCloseForm = () => {
        return setOpenSubContractForm({
            open: false,
            type: null,
            data: null
        })
    }

    const getSubcontractForm = (inputData) => {
        switch (inputData?.type) {
            case "create": return <SubContract.Create
                data={inputData?.data}
                handleClose={handleCloseForm}
                callback={callbackPartnerSubContract}
            />;
            case "update": return <SubContract.Update
                data={inputData?.data}
                handleClose={handleCloseForm}
                callback={callbackPartnerSubContract}
            />
            default: return null
        }
    }

    return (
        <Box>
            {(openSubContractForm?.open && !!openSubContractForm?.type) && <Drawer
                open={openSubContractForm?.open}
                anchor="right"
                sx={{ minHeight: 360 }}
            >
                {getSubcontractForm(openSubContractForm)}
            </Drawer>}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: { xs: "column", sm: "row" } }}>
                <Box>
                    <Typography className='page-title'>
                        Thông Tin Hợp Đồng Đối Tác
                    </Typography>
                    <BreadCrumb
                        data={[
                            { label: "Danh Sách Hợp Đồng Đối Tác", reactLink: "/partner_contracts" },
                            { label: partner_contract_id },
                        ]}
                    />
                </Box>
                <Button size='small' sx={{ height: 42, mb:{xs: 2, md: 0} }}
                    startIcon={<AddOutlined />}
                    disabled={contract?.contract_status !== 0 || openSubContractForm?.open}
                    onClick={() => setOpenSubContractForm({
                        open: true, type: "create",
                        data: {
                            subcontract_code: [contract?.contract_number_id, "/PL-"].join(""),
                            contract_id: contract?.contract_id
                        }
                    })}
                    variant="contained"
                >
                    Thêm Phụ Lục Hợp Đồng
                </Button>
            </Box>
            <PartnerContractInformation />
            <SubContract.List
                page={{
                    data: page,
                    handlePage: setPage
                }}
                subcontract={{
                    isFetching: isFetching,
                    subcontracts: data?.subcontracts,
                    callback: callbackPartnerSubContract,
                    total: data?.pagination?.total
                }}
                handleOpenSubcontractForm={setOpenSubContractForm}
            />
        </Box>
    )
}

export default withSuspense(withPermission(Detail, { feature: 'contract', action: 'partner-contract-show' }), MuiSkeleton["LinearProgress"])