import { format_date_short } from '@/functions'
import { Box, Typography } from '@mui/material'
import React from 'react'

const InvoiceCreated = ({ invoice_created_at }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 1,
            justifyContent: 'space-between',
        }}>
            <Box sx={{
                display: 'flex',
                width: { xs: '100%', md: '40%' }
            }}>
                <Typography sx={{
                    fontSize: 14,
                    color: "#777680",
                    m: 'auto 0'
                }}>
                    Ngày Tạo Hoá Đơn
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Typography>
                    {format_date_short(invoice_created_at)}
                </Typography>
            </Box>
        </Box>
    )
}

export default InvoiceCreated