import ProductGroupApi from "@/apis/beta/Product/ProductGroupApi";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import MultipleSelectCustomize from "@components/shared/MultipleSelectCustomize";
import { FormControl } from "@mui/material";
import { useMemo } from "react";

const ServiceInteresting = ({ value, onChange, ...props }) => {
  const { data } = ProductGroupApi.List();
  const options = useMemo(() => {
    return data?.map((item) => {
      return {
        value: item?.product_group_name?.toUpperCase(),
        label: item?.product_group_name?.toUpperCase(),
      };
    });
  }, [data]);

  return (
    <FormControl fullWidth>
      <MultipleSelectCustomize
        limitTags={3}
        options={options}
        value={options?.filter((x) => value?.includes(x?.value))}
        onChange={(event, newValue) => {
          onChange(newValue?.map((x) => x?.value) || []);
        }}
        input={{
          placeholder: "Nhập tên nhóm sản phẩm/ dịch vụ tìm kiếm...",
          ...props?.input,
        }}
        sx={{
          "&.MuiAutocomplete-option": {
            textTransform: "uppercase",
          },
        }}
      />
    </FormControl>
  );
};

export default withSuspense(ServiceInteresting, MuiSkeleton["TextField"]);
