import DocumentApi from "@/apis/beta/Document/DocumentApi";
import { REACT_APP_DOMAIN_FILE } from "@/libs/constants";
import { usePreviewFileContext } from "@contexts/preview-file";
import { Box, CircularProgress, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useQuery } from "react-query";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ViewFileDialog({ open, handleClose, id }) {
  const { data, isLoading } = useQuery(`get-document-${id}`, () =>
    DocumentApi.FindOneShareDocument(id)
  );

  const preview = usePreviewFileContext();

  const columns = [
    {
      field: "file_path",
      headerName: "Tên file",
      width: 500,
      renderCell: ({ row }) => {
        return (
          <Typography fontSize={13}>
            {row?.file_path?.split("/")[row?.file_path?.split("/")?.length - 1]}
          </Typography>
        );
      },
    },
    {
      field: "description",
      headerName: "Mô tả thay đổi",
      width: 500,
      renderCell: ({ row }) => {
        return (
          <Typography fontSize={13}>
            {row?.description ? row?.description : "Không có nội dung thay đổi"}
          </Typography>
        );
      },
    },
    {
      field: "version",
      headerName: "Phiên bản",
      width: 150,
      renderCell: ({ row }) => {
        // const index =
        //   data?.data?.file_version?.findIndex((item) => item?.id === row?.id) +
        //   1;
        // return <Typography fontSize={13}>{`v.${index}`}</Typography>;
        return <Typography fontSize={13}>{row?.version}</Typography>;
      },
    },
  ];

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-describedby="alert-dialog-slide-description"
      >
        {isLoading ? (
          <Box p={4}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogTitle>{data?.data?.data?.name_file}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {data?.data?.data?.description}
              </DialogContentText>

              <Box mt={5}>
                <DataGrid
                  onCellClick={({ row }) => {
                    preview?.showNotUseDrivePath(`${REACT_APP_DOMAIN_FILE}${row?.file_path.replace("pub/", "")}`);
                    handleClose();
                  }}
                  pagination={false}
                  hideFooterPagination
                  columns={columns}
                  autoHeight
                  rows={data?.data?.file_version || []}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button size="small" variant="outlined" onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default ViewFileDialog;
