import ContractApi from "@/apis/beta/Contract/ContractApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useParams } from "react-router";
import ContractInformation from "./ContractInformation";
import Header from "./Header";
import Subcontract from "./Subcontract";

const Detail = () => {
  const params = useParams();

  const { data: contract, refetch: callbackContracts } = ContractApi.Detail(
    params?.contract_id
  );

  return (
    <>
      <Header contract={contract} />
      <ContractInformation
        contract={contract}
        callbackContracts={callbackContracts}
      />
      <Subcontract.List />
    </>
  );
};

export default withSuspense(
  withPermission(Detail, {
    feature: "contract",
    action: "customer-contract-show",
  }),
  MuiSkeleton["LoadingDotBox"]
);
