import { payloadToQueries } from "@/functions";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { Link } from "react-router-dom";

const CreateService = ({ onClose, customer }) => {

  const linked = payloadToQueries(customer)
  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogTitle>Khởi tạo dịch vụ cho khách hàng</DialogTitle>
      <DialogActions>
        <Button size="small" variant="outlined" onClick={onClose}>
          Để Sau
        </Button>
        <Link to={`/customer_contracts/new?${linked}`} target="_blank">
          <Button size="small" variant="contained"
            onClick={(event) => onClose()}>
            Khởi Tạo Dịch Vụ
          </Button>
        </Link>
      </DialogActions>
    </Dialog>
  );
};

export default CreateService;
