import * as echarts from "echarts/core";

import {
  MapChart
} from "echarts/charts";

import {
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  VisualMapComponent
} from "echarts/components";

import { SVGRenderer } from "echarts/renderers";

import geo from "./world.json";

import EChart, { SCHEMES, TOOLTIP_OPTIONS } from "../../EChart";

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  VisualMapComponent,
  SVGRenderer,
  MapChart
]);

echarts.registerMap('World', geo);

export { SCHEMES, TOOLTIP_OPTIONS };

export default EChart;
