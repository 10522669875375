import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import DataGrid from "@/components/DataGrid";
import {
    dayjsCompare,
    format_date_short
} from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import { SERVICE_STATUS_TYPES } from "@/libs/constants";
import { CellLink, CellStatus } from "@components/DataGrid/Cell";
import ServiceActtions from "@components/Templates/shared/Service/ServiceActions";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ServiceTag from "./ServiceTag";

const INITIALIZE_CUSTOMER_SERVICE_QUERIES = [
    `filters[$and][0][tw_services.service_referrer_type][$ne]=Reducer::Override::Service`,
    `filters[$and][1][tw_services.service_create_is_trial][$eq]=1`,
];

const ServicePOC = () => {
    const { customer_id } = useParams();

    const [page, setPage] = React.useState({
        page: 0,
        pageSize: 10,
    });

    const [actions] = useState(['service-cancel', 'service-note'])
    const [columns] = React.useState([
        {
            field: "service_id",
            headerName: "ID",
            width: 60,
            valueGetter: ({ row }) => ({
                label: row?.service_id,
                href: ["services", row?.service_id],
            }),
            renderCell: ({ value }) => (
                <CellLink
                    data={value}
                    options={{
                        target: "_blank",
                    }}
                />
            ),
        },
        {
            field: "service_name",
            headerName: "Sản Phẩm",
            width: 300,
        },
        {
            field: "service_domain",
            headerName: "Domain/ IP",
            width: 150,
            valueGetter: ({ row }) => {
                let text = row?.service_extra_fields?.domain;
                if (!text || text === "") {
                    text = row?.service_extra_fields?.ip;
                }
                return text;
            }
        }, {
            field: "service_tagable",
            headerName: "Service Tag",
            width: 180,
            renderCell: ({ value, row }) => {
                if (!value) return <></>;
                return <ServiceTag serviceId={row?.service_id} />
            }
        },
        {
            field: "services.service_status",
            headerName: "Trạng Thái",
            width: 180,
            valueGetter: ({ row }) =>
                SERVICE_STATUS_TYPES.find((stt) => stt?.value === row?.service_status),
            renderCell: ({ value }) => {
                return <CellStatus component="muiChip" data={{
                    ...value,
                    label: value?.label === "Trial" ? "Đang Sử Dụng" : value?.label
                }} />;
            },
        },
        {
            field: "service_start_date",
            headerName: "Ngày Bắt Đầu POC",
            width: 200,
            valueFormatter: ({ value }) => format_date_short(value),
        },
        {
            field: "service_stopped_at",
            headerName: "Ngày Kết Thúc POC",
            width: 200,
            valueFormatter: ({ value }) => value && format_date_short(value),
        },
        {
            field: "note",
            headerName: "Ghi Chú",
            width: 200,
        },
        {
            field: 'actions',
            headerName: '',
            width: 40,
            valueGetter: () => actions,
            renderCell: ({ value, row }) => <ServiceActtions service={row} actions={[...value]} />
        }
    ]);

    const { data, isFetching } = CustomerApi.CustomerServices({
        customerId: customer_id,
        page: page?.page + 1,
        pageSize: page?.pageSize,
        query: INITIALIZE_CUSTOMER_SERVICE_QUERIES?.join("&"),
    });

    return (
        <Box sx={{ mt: "10px" }}>
            <Typography sx={{ fontSize: 18, fontWeight: 'medium' }}>
                Dịch Vụ POC
            </Typography>
            <DataGrid
                initialState={{
                    pinnedColumns: { right: ["actions"] },
                }}
                rows={
                    data?.services?.map((service) => ({
                        ...service,
                        id: service?.service_id,
                        product: `${service?.service_product_group + " - " + service?.service_product
                            }`,
                    })) || []
                }
                columns={columns}
                loading={isFetching}
                components={{
                    Toolbar: 'disabled',
                }}
                componentsProps={{
                    pagination: {
                        page: page?.page || 0,
                        pageSize: page?.pageSize,
                        rowsPerPageOptions: [5, 10, 20],
                        onPageChange: (newPage) =>
                            setPage((prevState) => ({ ...prevState, page: newPage })),
                        onPageSizeChange: (newPageSize) =>
                            setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
                    },
                }}
                disableSelectionOnClick
                disableColumnFilter
                rowCount={data?.pagination?.total}
                getRowClassName={({ row }) => {
                    if (row?.service_status === "trial") return ``;
                    if (row?.service_status === "cancel")
                        return clsx(`grid-dc-vnetwork`, "row-disabled");
                    if (!row?.service_contract) return ``;
                    const today = dayjs();
                    let classNames = ``;
                    if (
                        dayjsCompare(row?.service_contract?.expiry_date, today, "d") <= 14
                    ) {
                        classNames = `row-warning`;
                    }
                    return clsx(`grid-dc-vnetwork`, classNames);
                }}
            />
        </Box>
    );
};
export default withSuspense(
    withPermission(ServicePOC, {
        feature: "customer",
        action: "customer-service-index",
    }),
    MuiSkeleton["DataGrid"]
);
