import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import CustomerContactApi from "@/apis/beta/Customer/CustomerContactApi";
import JourneyApi from "@/apis/beta/Customer/Journey/JourneyApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, FormControl, Grid } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Filters from "./Filters";
import CreateJourney from "./shared/CreateJourney";
import { JourneyPackages } from "./shared/ViewMode";
import JourneyEmpty from "./JourneyEmpty";

const CustomerJourney = () => {

	const { customer_id: customerId } = useParams();
	const { data: { customer_service_information: csi } } = CustomerApi.Detail(customerId)

	const [customerContactId, setCustomerContactId] = useState(null);

	const { data: journeys, refetch } = JourneyApi.List({
		customerId,
		contactIds: customerContactId,
	});

	const { data: customerContacts } = CustomerContactApi.List({ customerId });

	return (
		<Box>
			<Box sx={{
				display: 'flex',
				justifyContent: 'flex-end',
				flexDirection: { xs: 'column-reverse', md: 'row' },
				gap: { xs: 1.5, md: 2 }
			}}>
				{csi?.customer_type === 'company' && <Filters
					customerId={customerId}
					customerContactId={customerContactId}
					setCustomerContactId={setCustomerContactId}
					data={customerContacts}
				/>}
				<FormControl fullWidth>
					<CreateJourney
						customerType={csi?.customer_type || 'customer'}
						customerContact={customerContacts?.find(
							(cta) => cta?.customer_contact_id === customerContactId
						)}
						refetch={refetch}
					/>
				</FormControl>
			</Box>
			{journeys?.length === 0 && <JourneyEmpty />}
			{journeys
				?.filter((journey) => !journey?.customer_journey_locked)
				?.map((journey, index) => {
					return <JourneyPackages.Detail key={index} journey={journey} />;
				})}
			<Grid container spacing={2} sx={{ mt: 1.5 }}>
				{journeys
					?.filter((journey) => journey?.customer_journey_locked)
					?.map((journey, index) => {
						return (
							<Grid item xs={12} sm={6} md={4}>
								<JourneyPackages.Locked key={index} journey={journey} />
							</Grid>
						);
					})}
			</Grid>
		</Box>
	);
};
export default withSuspense(
	withPermission(CustomerJourney, {
		feature: "customer",
		action: "customer-journey-index",
	}),
	MuiSkeleton["PaperJourney"]
);
