import JourneyApi from "@/apis/beta/Customer/Journey/JourneyApi";
import FormCustomized from "@components/shared/FormCustomized";
import { useEmployeeContext } from "@contexts/employee";
import { useNotificationContext } from "@contexts/notification";
import { vestResolver } from "@hookform/resolvers/vest";
import { AddOutlined } from "@mui/icons-material";
import {
	Box,
	Button,
	Collapse,
	Paper,
	Typography
} from "@mui/material";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { CustomerJourneyCreateValidator } from "./validators";

const cols = { xs: 12, md: 12, xl: 12 }

const CreateJourney = ({
	disabled,
	customerContact,
	refetch,
	customerType = 'company'
}) => {

	const { employee } = useEmployeeContext();
	const { setNotification } = useNotificationContext();
	const { customer_id: customerId } = useParams();


	const JOURNEY_CREATE_FIELDS = [
		{
			name: 'service_interestings', component: 'MultiSelect.Products', cols: cols,
			params: { input: { label: 'Dịch Vụ Quan Tâm/ Khai Thác *', placeholder: 'Chọn dịch vụ quan tâm/ khai thác...' } },
		},
		{
			name: 'sale_tracking_id', component: 'MultiSelect.Employees', cols: cols,
			params: { input: { label: 'Nhân Viên Phụ Trách *', placeholder: 'Chọn nhân viên sale/ phụ trách quá trình bán hàng này...' } },
		},
		{
			name: 'contact_id', component: 'SingleSelect.Contact', cols: cols,
			params: { input: { label: 'Liên Hệ Chính *', placeholder: 'Chọn liên hệ cho quá trình bán hàng...' }, customerId },
			hidden: customerType !== 'company'
		}
	]

	const [isOpen, setOpen] = useState(false);

	const methods = useForm({
		resolver: vestResolver((props) => CustomerJourneyCreateValidator({
			...props,
			customerContact
		})),
		defaultValues: useMemo(() => {
			return {
				service_interestings: [],
				sale_tracking_id: [employee?.employee_id],
				contact_id: customerContact?.customer_contact_id || -1
			}
		}, [customerContact, employee?.employee_id])

	});

	const { mutateAsync, isLoading } = useMutation(JourneyApi.Create)

	const onSubmit = async (values) => {
		const response = await mutateAsync({
			customerId: customerId,
			data: { ...values },
		});
		if (!response || response?.errors) {
			return setNotification({
				open: true,
				message: response?.errors?.[0]?.message,
				severity: "error",
			});
		}

		refetch && refetch()

		setNotification({
			open: true,
			message: "Thêm mới quá trình bán hàng thành công.",
			severity: "success",
		});

		return onClose()
	};

	const onClose = () => setOpen(false)

	return (
		<>
			<Collapse in={!isOpen}>
				<Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
					<Button
						size="small"
						variant="outlined"
						disabled={disabled || isOpen}
						onClick={() => setOpen(!isOpen)}
						startIcon={<AddOutlined fontSize="small" />}
					>
						Thêm Quá Trình
					</Button>
				</Box>
			</Collapse>
			<Collapse in={isOpen}>
				<FormProvider {...methods}>
					<Paper
						component="form"
						sx={{ p: 2, mb: 2 }}
						elevation={8}
						onSubmit={methods.handleSubmit(onSubmit)}
					>
						<Typography
							sx={{
								fontWeight: "medium",
								mb: 1.5,
								textTransform: "capitalize",
							}}
						>
							Thêm mới quá trình bán hàng
						</Typography>
						<FormCustomized
							attributes={JOURNEY_CREATE_FIELDS}
							onClose={onClose}
							submitLabel="Tạo Mới"
							submitNested={true}
							submitting={isLoading} />
					</Paper>
				</FormProvider>
			</Collapse>
		</>
	);
};

export default CreateJourney;
