import { UNLIMITED } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import { VisibilityOffOutlined } from '@mui/icons-material'
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
import { Box, Collapse, FormControl, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

const ProductPricingSteps = () => {
    const { control, watch } = useFormContext()
    const [collapse, handleCollapse] = React.useState(false)

    const { fields } = useFieldArray({ control: control, name: "product_pricing_configures.product_pricing_steps" })

    return (
        <Box>
            <Typography component="div" color="primary" sx={{ fontSize: 14, mr: 0.5 }}>
                [Bảng giá]
                {React.createElement(collapse ? VisibilityOutlined : VisibilityOffOutlined, {
                    color: "primary",
                    fontSize: "small",
                    onClick: () => handleCollapse(prev => !prev),
                    sx: {
                        m: "auto 0",
                        cursor: "pointer",
                        display: 'inline-flex',
                        height: 14,
                        width: 24
                    }
                })}
            </Typography>
            {!!collapse && <Collapse in={!!collapse}>
                {fields?.map((field, index) => {
                    if (watch(`product_payment_type`) === "prevpaid-crosscheck" && !(parseFloat(field?.end) > (parseFloat(watch(`product_pricing_configures.value`) || 0)))) return null
                    return (
                        <Box sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            gap: 1.5,
                            mt: 2
                        }} key={field.id}>
                            <Controller name={`product_pricing_configures.product_pricing_steps.[${index}].start`}
                                control={control}
                                render={({ field: { value } }) => {
                                    return (
                                        <FormControl fullWidth>
                                            <TextField size='small' disabled
                                                value={value}
                                                label="Từ"
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom
                                                }} />
                                        </FormControl>
                                    )
                                }}
                            />
                            <Controller name={`product_pricing_configures.product_pricing_steps.[${index}].end`}
                                control={control}
                                render={({ field: { value } }) => {
                                    const isUnlimited = parseFloat(value) >= UNLIMITED
                                    return (
                                        <FormControl fullWidth>
                                            <TextField size='small' disabled
                                                value={isUnlimited ? "UNLIMITED" : value}
                                                label="Đến"
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    inputComponent: isUnlimited ? "input" : NumericFormatCustom
                                                }} />
                                        </FormControl>
                                    )
                                }}
                            />
                            <Controller name={`product_pricing_configures.product_pricing_steps.[${index}].pricing`}
                                control={control}
                                render={({ field: { value } }) => {
                                    return (
                                        <FormControl fullWidth>
                                            <TextField size='small' disabled
                                                value={value}
                                                label="Đơn giá niêm yết *"
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom
                                                }} />
                                        </FormControl>
                                    )
                                }}
                            />
                            <Controller name={`product_pricing_configures.product_pricing_steps.[${index}].pricing_minimum`}
                                control={control}
                                render={({ field: { value } }) => {
                                    return (
                                        <FormControl fullWidth>
                                            <TextField size='small' disabled
                                                value={value}
                                                label="Đơn giá min *"
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom
                                                }} />
                                        </FormControl>
                                    )
                                }}
                            />
                            <Controller name={`product_pricing_configures.product_pricing_steps.[${index}].pricing_apply`}
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <FormControl fullWidth>
                                            <TextField size='small'
                                                value={value}
                                                onChange={onChange}
                                                label="Đơn giá bán *"
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom
                                                }} />
                                        </FormControl>
                                    )
                                }}
                            />
                        </Box>
                    )
                })}
            </Collapse>}
        </Box>
    )
}

export default ProductPricingSteps