import { Box, Tab, Tabs, Typography } from '@mui/material'
import React, { useState } from 'react'
import MlyticCDNRequests from './CDNRequest'
import MlyticDNSQueries from './DNSQueries'
import MlyticDNSRecords from './DNSRecords'
import MlyticDNSTraffics from './CDNTraffics'

const INITIAL_TABS = [
    {
        value: 'dns_records',
        label: 'DNS no of Records',
        component: MlyticDNSRecords
    }, {
        value: 'dns_queries',
        label: 'DNS Queries',
        component: MlyticDNSQueries
    },
    {
        value: 'cdn_traffics',
        label: 'CDN Traffics',
        component: MlyticDNSTraffics
    }, {
        value: 'cdn_requests',
        label: 'CDN Request',
        component: MlyticCDNRequests
    }
]

const MlyticAnalytics = ({ service }) => {

    const [active, setActive] = useState(INITIAL_TABS[0])
    return (
        <>
            <Tabs
                component={"nav"}
                value={active?.value}
                aria-label="tabs-with-scroll"
                variant="scrollable"
                allowScrollButtonsMobile
                scrollButtons
            >
                {INITIAL_TABS?.map((tab, index) => (
                    <Tab
                        key={index}
                        value={tab?.value}
                        disabled={active?.value === tab?.value}
                        onClick={() => setActive(tab)}
                        label={
                            <Box sx={{ display: "flex" }}>
                                {tab?.icon &&
                                    React.createElement(tab?.icon, {
                                        sx: {
                                            fontSize: { xs: 18, md: 20 },
                                            m: "auto 4px auto 0",
                                            color: active?.value === tab?.value && "#1a237e",
                                        },
                                    })}
                                <Typography
                                    sx={{
                                        fontWeight: "medium",
                                        fontSize: { xs: 14, md: 16 },
                                    }}
                                    color={active?.value === tab?.value ? "primary" : "information"}
                                >
                                    {tab?.label || "Tab name"}
                                </Typography>
                            </Box>
                        }
                    />
                ))}
            </Tabs>
            {React.createElement(active?.component, {
                service: service
            })}
        </>
    )
}

export default MlyticAnalytics