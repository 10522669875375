import { useEmployeeContext } from "@/contexts/employee";
import { format_date_short, format_date_time } from "@/functions";
import { CONTRACT_PROCESS_STATUS } from "@/libs/constants";
import {
  Box,
  Chip,
  StepLabel,
  TextField,
  Typography
} from "@mui/material";
import React from "react";
import PrivateIconWithFormat from "../../shared/icons";
import Assignee from "./Assignee";
import Attachment from "./Attachments";
import Buttons from "./Buttons";
import Feedback from "./Feedback";

const ResolveProblemItem = ({
  employees,
  contractProcess,
  contractProcessType,
}) => {
  const { employee } = useEmployeeContext();
  const [contractProcessStatus, setContractProcessStatus] = React.useState({});

  React.useEffect(() => {
    setContractProcessStatus(
      CONTRACT_PROCESS_STATUS?.find(
        (stt) => stt.value === contractProcess?.contract_process_status
      )
    );
  }, [contractProcess?.contract_process_status]);

  return (
    <StepLabel
      StepIconProps={{
        sx: {
          fontSize: 20,
          mb: `${(contractProcessStatus?.value === 0 &&
            contractProcess?.contract_process_assignee?.includes(
              employee?.employee_id
            )
            ? 272
            : 230) +
            contractProcess?.contract_process_attachments?.length * (63 + 16)
            }px`,
        },
      }}
    >
      <Chip
        size="verysmall"
        variant={`customize-${contractProcessStatus?.chip}`}
        label={contractProcessStatus?.label}
      />
      <Box sx={{ display: "flex", mb: 1 }}>
        <PrivateIconWithFormat icon="CalendarIcon" />
        <Typography>
          Vấn đề{" "}
          <span style={{ color: "#1A237E" }}>
            {contractProcess?.contract_process_title}
          </span>{" "}
          được tạo vào lúc{" "}
          {format_date_time(contractProcess?.contract_process_created, true)} mã
          hợp đồng {contractProcess?.contract_process_code}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", mb: 1 }}>
        <PrivateIconWithFormat icon="OwnerIcon" />
        <Typography>
          {
            employees?.find(
              (e) => e?.employee_id === contractProcess?.contract_process_owner
            )?.employee_name
          }
        </Typography>
      </Box>
      <Box sx={{ display: "flex", mb: 1 }}>
        <PrivateIconWithFormat icon="AlarmIcon" />
        <Typography sx={{ fontSize: 14, m: "auto 0", color: "#9e9e9e" }}>
          Thời Hạn:{" "}
          <span style={{ color: "#000" }}>
            {format_date_short(contractProcess?.contract_process_created)} -
            {format_date_short(contractProcess?.contract_process_deadline)}
          </span>
        </Typography>
      </Box>
      <Assignee
        assignees={employees?.filter((e) =>
          contractProcess?.contract_process_assignee?.includes(
            e?.employee_id
          )
        )} />
      <Feedback feedback={contractProcess?.contract_process_feedback} />
      <TextField
        multiline
        fullWidth
        value={contractProcess?.contract_process_problem}
        disabled
        placeholder="Chưa có thông tin mô tả về vấn đề..."
      />
      <Attachment attachments={contractProcess?.contract_process_attachments} linked={contractProcess.link} />
      <Buttons employee={employee}
        assigneeIds={contractProcess?.contract_process_assignee}
        contractProcess={contractProcess}
        type={contractProcessType}
      />
    </StepLabel>
  );
};

export default ResolveProblemItem;
