import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { format_date_short, queryClientRefetchScope } from "@/functions";
import withPermission from "@/hocs/withPermission";
import DatePicker from "@components/DatePicker";
import { useNotificationContext } from "@contexts/notification";
import { NotInterestedOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

const CancelService = ({ service }) => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();
  const { mutateAsync, isLoading } = useMutation(ServiceApi.Stop);

  const [isError, setError] = useState(null);
  const [metadata, setMetadata] = useState({
    reasonOff: "",
    service_stopped_at: moment(),
  });

  const [openModal, setOpenModal] = useState(false);

  const onClose = () => setOpenModal(false);

  const onSubmit = async () => {
    if (!metadata?.reasonOff || metadata?.reasonOff?.trim() === "") {
      return setError("Lý do huỷ dịch vụ không được để trống.");
    }
    if (metadata?.reasonOff?.trim()?.length < 10) {
      return setError("Lý do huỷ/ stop dịch vụ quá ngắn.");
    }
    let form = {
      service_stopped_at: format_date_short(metadata?.service_stopped_at),
      service_reason_off: metadata?.reasonOff,
    };

    const response = await mutateAsync({
      serviceId: service?.service_id,
      data: form,
    });
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }
    setNotification({
      open: true,
      message: "Cập nhật chu kỳ dịch vụ thành công.",
      severity: "success",
    });
    queryClientRefetchScope(queryClient, "service");
    return onClose();
  };

  return (
    <React.Fragment>
      {openModal && (
        <Dialog open={true} maxWidth="md" fullWidth>
          <DialogTitle>
            Hủy Dịch Vụ
            <Typography component="div">
              Dịch vụ
              <Typography
                sx={{ display: "inline", fontWeight: "medium", m: "auto 4px" }}
              >
                [{service?.service_name}]
              </Typography>
              sẽ được chuyển sang trạng thái hủy. Hóa đơn và các tác vụ bên dưới
              cũng sẽ được áp dụng trạng thái hủy. Thao tác sẽ không thể phục
              hồi, bạn có muốn thực hiện?
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <DatePicker
                  value={metadata?.service_stopped_at}
                  onChange={(newValue) =>
                    setMetadata((prev) => ({
                      ...prev,
                      service_stopped_at: newValue,
                    }))
                  }
                  label="Ngày chính thức off dịch vụ *"
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  label="Lý do huỷ/ stop dịch vụ"
                  multiline
                  minRows={3}
                  value={metadata?.reasonOff}
                  onChange={(event) => {
                    if (!!isError) {
                      setError(null);
                    }
                    setMetadata((prev) => ({
                      ...prev,
                      reasonOff: event?.target?.value,
                    }));
                  }}
                  InputLabelProps={{ shrink: true }}
                  error={!!isError}
                  helperText={isError}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button size="small" variant="outlined" onClick={onClose}>
              Huỷ Thao Tác
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={onSubmit}
              startIcon={
                isLoading && (
                  <CircularProgress size={14} sx={{ color: "#777680" }} />
                )
              }
            >
              Xác nhận
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Button
        size="small"
        variant="outlined"
        disabled={isLoading || service?.service_status === "cancel"}
        startIcon={<NotInterestedOutlined fontSize="small" />}
        sx={{ height: { xs: 36, md: 42 } }}
        onClick={() => setOpenModal(true)}
      >
        huỷ dịch vụ
      </Button>
    </React.Fragment>
  );
};

export default withPermission(CancelService, {
  feature: "service",
  action: "cancelled",
  type: "button",
});
