import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

const ImagePreview = ({ url }) => {
  const [zoom, setZoom] = useState(1);

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        height="100%"
        overflow="auto"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <img
          src={url}
          width="100%"
          height="100%"
          style={{
            aspectRatio: "auto",
            maxWidth: "100%",
            transform: `scale(${zoom})`,
            transition: "all ease-in 0.1s",
            userSelect: "none",
            display: "block",
            margin: "auto",
            objectFit: "contain",
          }}
        />
      </Box>

      <Box
        position="fixed"
        sx={{
          width: "200px",
          borderRadius: "50px",
          transform: "translateX(-50%)",
          left: "50%",
          overflow: "hidden",
          bottom: "40px",
        }}
      >
        <Button
          disabled={zoom >= 5}
          onClick={() => setZoom((prev) => (prev < 2 ? prev + 0.5 : prev))}
          size="large"
          variant="contained"
          sx={{ width: "50%" }}
        >
          <ZoomInIcon />
        </Button>
        <Button
          disabled={zoom <= 1}
          onClick={() => setZoom((prev) => (prev >= 1 ? prev - 0.5 : prev))}
          size="large"
          variant="contained"
          sx={{ width: "50%" }}
        >
          <ZoomOutIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default ImagePreview;
