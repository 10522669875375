import { PRODUCT_UNITS } from '@/libs/constants'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

export const PRODUCT_INFORMATION_TYPES = [
    { value: 'cpu', label: "CPU" },
    { value: 'os', label: "Operator System" },
    { value: 'datacenter', label: "Datacenter" },
    { value: 'ram', label: "Ram" },
    { value: 'disk', label: "Disk" },
    // { value: 'storage', label: "Backup" }
]

export const PRIORITIES = ["os", "datacenter", "cpu", "ram", "disk", "ssl", "cloudsecurities", "cloudstorage", "storage", "bandwidth", "software", "ip"]

export const sortPriorities = (arrs = []) => {
    return arrs?.sort((a, b) => PRIORITIES.indexOf(a?.product_infomation_type) - PRIORITIES.indexOf(b?.product_infomation_type));
}

const ProductConfigoption = () => {

    const { control } = useFormContext()

    const productInformations = useWatch({
        control: control,
        name: "product_informations"
    })

    return (
        <Box>
            <Typography sx={{ fontWeight: 'medium', mb: 1, fontSize: 18 }}>
                Thông Tin Cấu Hình
            </Typography>
            {Array.from(sortPriorities(productInformations))?.map((information, index) => {
                const pInformation = PRODUCT_INFORMATION_TYPES?.find(type => type.value === information?.product_infomation_type)
                if (!pInformation) return null;
                return (
                    <Box sx={{ display: 'flex', mb: 1, width: '100%' }} key={index}>
                        <Typography component="div" sx={{
                            color: "#777680"
                        }}>
                            {pInformation?.label || ""}:
                            {["os", "datacenter"]?.includes(information?.product_infomation_type) &&
                                <ProductInformationSelection name={information?.product_infomation_type} />}
                            {["ram", "disk", "cpu"]?.includes(information?.product_infomation_type) &&
                                <Typography component="span" sx={{
                                    display: 'inline-flex',
                                    color: "#00001F",
                                    flexWrap: 'wrap',
                                    ml: 0.5
                                }}>
                                    {information?.value}{PRODUCT_UNITS.slice(1).find(item => item?.value === information?.unit)?.label || ''}
                                </Typography>}
                            {["ram", "disk"].includes(information?.product_infomation_type) &&
                                <Typography sx={{
                                    color: "#777680",
                                    display: 'inline-block'
                                }}>
                                    ( Mặc định)
                                </Typography>}
                        </Typography>
                    </Box>
                )
            })}
        </Box>
    )
}

const ProductInformationSelection = ({ name = "" }) => {
    const { control } = useFormContext()

    const productConfigoptions = useWatch({
        control: control,
        name: "product_configoptions"
    })

    return (
        <Typography sx={{ display: 'inline-flex', ml: 0.5, color: "#00001F" }}>
            {productConfigoptions?.find((configoption) => configoption?.type === name)?.select?.label}
        </Typography>
    )
}

export default ProductConfigoption