import ServiceInvoiceApi from "@/apis/beta/Service/ServiceInvoiceApi";
import { CellLink } from "@/components/DataGrid/Cell";
import {
  buildQueryFilterCondition,
  customize_truncate,
  format_date_short,
  format_numeral_price
} from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import { INVOICE_STATUS } from "@/libs/constants";
import DataGridCustomize from "@components/DataGrid";
import CellPricing from "@components/DataGrid/Cell/FormatterPricing";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import QuickView from "@features/Module/Invoice/List/QuickView";
import { Box, Checkbox, Chip, Tooltip, Typography } from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";

const Invoice = () => {
  const { service_id } = useParams();
  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [search, setSearch] = React.useState("");
  const debouncedSearchQuery = useDebounce(search, 500);

  const [anchorEl, setAnchorEl] = React.useState({
    refEl: undefined,
    anchorEl: undefined,
    data: undefined,
  });

  const filterableServiceInvoices = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "")
      return null;
    const fieldQueries = [
      {
        name: "invoices.invoice_id",
        operator: "$eq",
        disabled: !parseInt(debouncedSearchQuery),
        value: parseInt(debouncedSearchQuery),
      },
      {
        name: "invoices.invoice_number",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
    ];
    let regex = new RegExp("^" + debouncedSearchQuery?.toLowerCase());
    if (regex && debouncedSearchQuery?.length >= 3) {
      INVOICE_STATUS.forEach(({ label, name }, index) => {
        const valid =
          label?.toLowerCase().match(regex) || name?.toLowerCase().match(regex);
        if (!!valid && !!valid[0]) {
          fieldQueries?.push({
            name: "invoices.invoice_status",
            operator: "$eq",
            value: parseInt(INVOICE_STATUS[index].value),
          });
        }
      });
    }
    return buildQueryFilterCondition("$or", 0, fieldQueries);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const [initialColumns] = React.useState([
    {
      field: "invoices.invoice_id",
      headerName: "ID - Invoice",
      valueGetter: ({ row }) => row.invoice_id,
      width: 100,
      renderCell: ({ value }) => (
        <Link to={`/invoices/${value}`} replace>
          <Typography
            color="primary"
            sx={{
              fontSize: 13,
              textDecoration: "underline",
            }}
          >
            {value}
          </Typography>
        </Link>
      ),
    },
    {
      field: "invoice_number",
      headerName: "Số hoá đơn",
      hide: true,
      width: 120,
    },
    {
      field: "invoice_description",
      headerName: "Mô tả hoá đơn dịch vụ",
      width: 400,
      renderCell: ({ value }) => (
        <Tooltip title={value} placement="top">
          <Typography sx={{ fontSize: 13 }}>
            {customize_truncate(value, 60)}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "invoice_subtotal",
      headerName: "Tạm Tính (Trước Thuế)",
      width: 150,
      renderCell: ({ value }) => <CellPricing price={value} />
    },
    {
      field: "invoice_total",
      headerName: "Tổng tiền",
      width: 150,
      renderCell: ({ value, row }) => {
        return (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
            onClick={(e) => {
              setAnchorEl({
                refEl: e?.currentTarget,
                anchorEl: true,
                data: row,
              });
            }}
          >
            <CellLink
              data={{ label: `${format_numeral_price(value)} đ`, href: [] }}
            />
          </Box>
        );
      },
    },
    {
      field: "service_invoice_term",
      headerName: "Chu kỳ áp dụng",
      width: 220,
      renderCell: ({ value }) => value,
    },
    {
      field: "invoice_tax",
      headerName: "Thuế",
      hide: true,
      width: 80,
      renderCell: ({ value }) => (
        <Checkbox size="small" checked={value !== 0} disabled />
      ),
    },
    {
      field: "invoice_created_at",
      headerName: "Ngày tạo",
      hide: true,
      width: 130,
      renderCell: ({ value }) => format_date_short(value),
    },
    {
      field: "invoice_duedate",
      headerName: "Hạn thanh toán",
      width: 130,
      renderCell: ({ value }) => format_date_short(value),
    },
    {
      field: "invoice_paid",
      headerName: "Ngày thanh toán",
      width: 130,
      renderCell: ({ value }) => (value ? format_date_short(value) : ""),
    },
    {
      field: "invoice_status",
      headerName: "Trạng thái",
      width: 150,
      valueGetter: ({ row }) =>
        INVOICE_STATUS.find((stt) => stt?.value === row?.invoice_status),
      renderCell: ({ value }) => (
        <Chip
          size="small"
          variant={`customize-${value?.chip}`}
          label={value?.name}
        />
      ),
    },
  ]);
  const [columns] = React.useState(initialColumns);

  const { isFetching, data } = ServiceInvoiceApi.List({
    serviceId: service_id,
    page: page?.page + 1,
    pageSize: page?.pageSize || 10,
    query: filterableServiceInvoices() || null,
  });


  return (
    <>
      {Boolean(anchorEl?.anchorEl) && anchorEl?.data !== undefined && (
        <QuickView
          setAnchorEl={setAnchorEl}
          refEl={anchorEl?.refEl}
          invoice_id={anchorEl?.data?.invoice_id}
        />
      )}
      <DataGridCustomize
        rows={
          data?.invoices?.map((invoice) => ({
            ...invoice,
            id: invoice?.invoice_id,
          })) || []
        }
        columns={columns}
        loading={isFetching}
        componentsProps={{
          toolbar: {
            initialColumns: initialColumns,
            inputSearch: {
              placeholder: "Nhập ID phiếu tìm kiếm...",
              value: search,
              onChange: setSearch,
            },
          },
          pagination: {
            page: page?.page || 0,
            pageSize: page?.pageSize || 10,
            rowsPerPageOptions: [5, 10, 25, 50],
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        rowCount={data?.pagination?.total}
      />
    </>
  );
};

export default withSuspense(
  withPermission(Invoice, {
    feature: "service",
    action: "service-invoice-index",
  }),
  MuiSkeleton["DataGrid"]
);
