import { SERVICE_PERIODS } from "@/libs/constants";
import PopperAction from "@components/PopperAction";
import {
	MoreVertOutlined,
	VpnLockOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import React, { Fragment } from "react";
import ServiceAddons from "./ServiceAddons";
import ServiceConfigoption from "./ServiceConfigoption";
import ServiceInformation from "./ServiceInformation";
import ServicePricingAmount from "./ServicePricingAmount";
import ServicePricingConfigures from "./ServicePricingConfigures";

const ServicePreview = ({ data = {}, remover, updater, disabled, insert }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const [INITIAL_ACTIONS] = React.useState([
		{
			order: 1,
			name: "Add",
			icon: "Add",
			label: "Duplicated",
			handleClick: (newProduct) => {
				try {
					insert({ ...newProduct });
				} catch (error) {
					console.log(error);
				}
				return onClose();
			},
		},
		{
			order: 2,
			name: "delete",
			icon: "Delete",
			label: "Xoá",
			handleClick: () => {
				try {
					remover();
				} catch (error) {
					console.log(error);
				}
				return onClose();
			},
		},
	]);

	const onClose = () => setAnchorEl(undefined);

	return (
		<Fragment>
			{!!Boolean(anchorEl) && (
				<Popover anchorEl={anchorEl} open={!!Boolean(anchorEl)}>
					<PopperAction
						actions={INITIAL_ACTIONS}
						data={data}
						handleClose={onClose}
					/>
				</Popover>
			)}
			<Box
				sx={{
					display: "flex",
					flexDirection: { xs: "column-reverse", md: "row" },
					gap: 1,
					justifyContent: "space-between",
					border: "1px solid rgba(0, 0, 0, 0.18)",
					p: 2,
				}}
			>
				<Box sx={{ width: "100%" }}>
					<Typography
						component="div"
						sx={{
							fontSize: 14,
							fontWeight: "medium",
							mb: 0.5,
						}}
					>
						{data?.product_group_name} - {data?.product_name}
						<Typography
							sx={{
								fontSize: 14,
								color: "#777680",
								ml: 0.5,
								display: "inline-block",
							}}
						>
							({" "}
							{
								SERVICE_PERIODS?.find(
									(item) => item?.value === data?.product_billing_cycle
								)?.label
							}
							)
						</Typography>
					</Typography>
					{data?.product_template === "Domain" && (
						<Box
							sx={{
								display: "flex",
								flexDirection: { xs: "column", md: "row" },
								gap: 0.5,
							}}
						>
							<VpnLockOutlined fontSize="small" color="primary" />
							<Typography
								sx={{
									m: "auto 4px",
									fontSize: 14,
									color: "#00001F",
								}}
							>
								{data?.product_metadata?.domain?.domain_type === "register"
									? "Đăng ký mới"
									: "Đổi nhà cung cấp"}
							</Typography>
							<Typography sx={{ fontSize: 14, color: "#777680" }}>
								{" "}
								-{" "}
							</Typography>
							<Typography color="primary" sx={{ fontSize: 14, m: "auto 0" }}>
								{data?.product_metadata?.domain?.domain_value}
							</Typography>
						</Box>
					)}
					<ServiceConfigoption
						serviceConfigoptions={data?.product_configoptions}
					/>
					<ServiceInformation
						serviceInformations={data?.product_informations}
					/>
					<ServicePricingConfigures {...data} />
					{data?.product_addons?.length > 0 && <ServiceAddons {...data} />}
					<ServicePricingAmount {...data} />
				</Box>
				<IconButton
					size="small"
					sx={{
						m: "0 0 auto auto",
						bgcolor: "rgba(0, 0, 0, 0.08)",
					}}
					disabled={!!Boolean(anchorEl)}
					onClick={(event) => setAnchorEl(event?.currentTarget)}
				>
					<MoreVertOutlined fontSize="small" />
				</IconButton>
			</Box>
		</Fragment>
	);
};

export default ServicePreview;
