import { Box, List, Paper, Skeleton, Stack } from '@mui/material'
import React from 'react'

const SkeletonSidebar = () => {
    return (
        <Box
            component={Paper}
            sx={{
                px: 2,
                width: 240,
                height: '100vh',
                position: 'sticky',
                top: 0,
                borderRadius: 0,
            }}
        >
            <Stack justifyContent={'space-between'} sx={{ height: 'inherit' }}>
                <Box sx={{ py: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Skeleton variant='text' sx={{ fontSize: 16, width: 40.21 }} />
                        <Skeleton variant='circular' sx={{ height: 30, width: 30 }} />
                    </Box>
                    <Skeleton variant='rectangular' sx={{ height: 40, borderRadius: '4px', m: "16px 0" }} />
                    <List sx={{ pt: 1, width: '100%' }}>
                        {[1, 2, 3, 4, 5, 6].map((item) =>
                            <Skeleton key={item} variant='rectangular' sx={{
                                width: "100%",
                                height: 42.5,
                                bgcolor: "grey.300", borderRadius: '4px'
                            }} />
                        )}
                    </List>

                </Box>
                <Box sx={{ p: 2, bgcolor: "grey.100", borderRadius: '8px', m: '8px 0' }}>
                    <Skeleton variant='text' sx={{ mb: 0.5 }} />
                    <Skeleton variant='rectangular' sx={{ mb: 0.5, height: 58.5, borderRadius: '4px' }} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', m: "8px 0" }}>
                        <Skeleton variant='rectangular' sx={{ height: 57, width: 96, borderRadius: 0.5 }} />
                        <Skeleton variant='rectangular' sx={{ height: 57, width: 96, borderRadius: 0.5, ml: 0.5 }} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                        <Skeleton variant='text' sx={{ height: 24, width: 100, m: "auto 0" }} />
                        <Skeleton variant='circular' sx={{ height: 30, width: 30, m: 'auto 0' }} />
                    </Box>
                </Box>
            </Stack>
        </Box>
    )
}

export default SkeletonSidebar