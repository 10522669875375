import { AUTH_URL } from '@/libs/constants';
import { API_V3_URL } from "@/libs/constants";

import { requestSimple, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";

class UserApi {
  Login = async (data) => {
    return await requestSimple(
      `${AUTH_URL}/auth/v2/login`,
      "POST",
      data,
      {},
      { withCredentials: true }
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };
  Current = () => {
    const { data } = useQueryWithCustomized(
      'employee.current',
      async () => await requestWithToken(
        `${API_V3_URL}/employees/current`
      )
        .then(({ data }) => {
          if (data?.error) return data;

          return data;
        })
        .catch((error) => {
          return error.response;
        }),
      {
        staleTime: 10 * 60 * 1000,
        enabled: !!localStorage.getItem('SA'),
      }
    );

    return [data?.data?.employee];
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UserApi();
