import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { createContext, useContext, useState } from "react";

const ConfirmContext = createContext();

const ConfirmContextProvider = ({ children }) => {
  const [stateConfirm, setStateConfirm] = useState({
    open: false,
    title: "",
    description: "",
    loading: false,
    handler: async () => {},
    onCancel: () => {},
  });

  const handleConfirmation = async () => {
    setStateConfirm((prev) => ({ ...prev, loading: true }));
    await stateConfirm?.handler();
    closeConfirmation();
  };

  const showConfirmation = ({
    title,
    open,
    description,
    loading,
    handler,
    onCancel,
  }) => {
    return setStateConfirm({
      title,
      open,
      description,
      loading,
      handler,
      onCancel,
    });
  };

  const closeConfirmation = () => {
    return setStateConfirm({
      title: "",
      open: false,
      description: "",
      loading: false,
      handler: () => {},
    });
  };

  return (
    <ConfirmContext.Provider value={{ showConfirmation, closeConfirmation }}>
      {children}

      <Dialog
        open={stateConfirm?.open}
        onClose={closeConfirmation}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>{stateConfirm?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {stateConfirm?.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            disabled={stateConfirm?.loading}
            onClick={() => {
              stateConfirm.onCancel && stateConfirm.onCancel();
              closeConfirmation();
            }}
          >
            Huỷ thao tác
          </Button>
          <Button
            startIcon={stateConfirm?.loading && <CircularProgress size={14} />}
            disabled={stateConfirm?.loading}
            variant="contained"
            size="small"
            onClick={handleConfirmation}
          >
            Đồng ý
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmContext.Provider>
  );
};

export const useConfirmContext = () => {
  return useContext(ConfirmContext);
};

export default ConfirmContextProvider;
