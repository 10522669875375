import ProductApi from '@/apis/beta/Product/ProductApi'
import ProductPricingConfigures from '@components/Templates/ProductForm/Normal/ProductPricingConfigures'
import { useNotificationContext } from '@contexts/notification'
import { Box, Button, CircularProgress } from '@mui/material'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
const Customized = ({
    data = {},
    callback
}) => {

    const { setNotification } = useNotificationContext()

    const { product_id } = useParams()
    const [submit, setSubmit] = React.useState(null)

    const [defaultValues] = React.useState({
        product_pricing_configures: {
            default: {
                pricing: 0
            },
            product_pricing_unit: data?.product_pricing_configures?.product_pricing_unit,
            product_pricing_method: "tierd",
            steps: data?.product_pricing_configures?.product_pricing_steps || []
        }
    })

    const methods = useForm({
        defaultValues: { ...defaultValues }
    })

    const onReset = () => {
        methods.reset({ product_configoptions: defaultValues })
    }

    const rebuildProductPricingConfigures = (metadata = {}) => {
        return {
            product_pricing_unit: metadata?.product_pricing_unit,
            product_pricing_method: metadata?.product_pricing_method,
            default: metadata?.default,
            steps: metadata?.steps || []
        }
    }

    const onSubmit = async (values) => {
        setSubmit("submit")
        const response = await ProductApi.Update({
            id: product_id,
            data: {
                product_pricing_configures: rebuildProductPricingConfigures(values?.product_pricing_configures)
            }
        })
        setSubmit(null)
        if (!response || response?.errors) {
            return setNotification({ open: true, message: response?.errors?.[0]?.message, severity: "error" })
        }
        setNotification({ open: true, message: "Cập nhật bảng giá thành công.", severity: "success" })
        try {
            callback()
        } catch (error) {
            console.log(error?.message)
        }
    }
    return (
        <FormProvider {...methods}>
            <Box component="form" onSubmit={methods.handleSubmit(onSubmit)} sx={{ maxWidth: { xs: '100%', md: "80%" } }}>
                <ProductPricingConfigures />
                <Box sx={{
                    mt: 3,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2
                }}>
                    <Button size="small"
                        variant='text'
                        onClick={onReset}
                        disabled={!!submit}
                        sx={{
                            height: {
                                xs: 36,
                                md: 42
                            }
                        }}>
                        Huỷ thao tác
                    </Button>
                    <Button size="small"
                        type="submit"
                        disabled={!!submit}
                        startIcon={(!!submit && submit === "submit") && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                        variant='contained' sx={{ height: { xs: 36, md: 42 }, minWidth: 150 }}>
                        Lưu thay đổi
                    </Button>
                </Box>
            </Box>

        </FormProvider>

    )
}

export default Customized