import { Box, Popover, Typography } from '@mui/material'
import { useState } from 'react'

const Employees = ({
    employees = [],
    label = 'Danh Sách Nhân Viên'
}) => {
    const [anchorEl, setAnchorEl] = useState(undefined)

    return (
        <Box sx={{
            height: '100%',
            width: '100%',
            alignContent: 'center'
        }}
            onClick={(e) => {
                if (employees?.length <= 2) return null;
                setAnchorEl(e?.currentTarget)
            }}
        // onMouseEnter={(e) => setAnchorEl(e?.currentTarget)}
        >
            {anchorEl && <Popover anchorEl={anchorEl} open={Boolean(anchorEl)}
                onMouseLeave={() => setAnchorEl(undefined)}
                onMouseDown={() => setAnchorEl(undefined)}
                onClose={() => setAnchorEl(undefined)}
            >
                <Box sx={{ p: '16px 24px' }}>
                    <Typography sx={{ fontSize: 14, fontWeight: 'medium', pb: 2 }}>
                        {label}
                    </Typography>
                    {employees?.map((employee, index) => {
                        // eslint-disable-next-line no-unused-vars
                        const [name, _] = employee?.split('::')
                        return (
                            <Typography sx={{ fontSize: 14 }} component="div" key={index}>
                                {name}
                            </Typography>
                        )
                    })}
                </Box>
            </Popover>}
            <Typography sx={{ fontSize: 14 }} component="div">
                {employees?.slice(0, 2)?.map((employee, index) => {
                    // eslint-disable-next-line no-unused-vars
                    const [name, _] = employee?.split('::')
                    return (
                        <Typography sx={{
                            fontSize: 14,
                            display: 'inline-flex',
                            width: 150
                        }} key={index}>
                            {index !== 0 && <Typography sx={{
                                fontSize: 14,
                                m: 'auto 8px',
                            }} color="text.grey">|</Typography>}
                            {name}
                        </Typography>
                    )
                })}
                {employees?.length > 2 && <Typography sx={{ fontSize: 14, ml: 1 }} color="primary" component="span">
                    (+ {employees?.length - 2} người khác)
                </Typography>}
            </Typography>
        </Box>
    )
}

export default Employees