import ProductApi from "@/apis/beta/Product/ProductApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import React from "react";
import { useParams } from "react-router-dom";
import { Modules } from "./modules";

const Pricing = () => {
  const { product_id } = useParams();
  const { data, refetch: callback } = ProductApi.Pricing({
    productId: product_id,
  });
  const Component = Modules[data?.template] || Modules["Default"];

  return <Component data={data} callback={callback} />;
};

export default withSuspense(
  withPermission(Pricing, {
    feature: "product",
    action: "product-pricing-index",
  }),
  MuiSkeleton["Paper"]
);
