import withPermission from "@/hocs/withPermission";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";

const Preview = ({ disabled, ...invoice }) => {
	const handlePreview = () => {
		const url = `/invoices/${invoice?.invoice_id}/preview?preview_type=statement`;

		window.open(
			url,
			`Preview invoices ${invoice?.invoice_id}`,
			"height=600,width=600"
		);
	};

	return (
		<Button
			size="small"
			fullWidth
			disabled={disabled || invoice?.invoice_status === 2}
			variant="outlined"
			onClick={handlePreview}
			startIcon={<RemoveRedEyeOutlined />}
		>
			Xem Trước Email Đối Soát
		</Button>
	);
};
export default withPermission(Preview, {
	feature: "invoice",
	action: "preview",
	type: "button",
});
