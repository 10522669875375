import { Box, Typography } from "@mui/material";

const ProtectedDomain = ({ data }) => {
  return (
    <Box
      sx={{
        p: "8px 16px",
        border: "1px solid rgba(0, 0,0,0.18)",
        borderRadius: "4px",
        mb: 1.25,
      }}
    >
      <Typography sx={{ fontWeight: "medium", fontSize: 14, mb: 0.75 }}>
        Protected Domain No of Records:
      </Typography>
      <Typography sx={{ fontSize: 13, mb: 0.75 }} color="text.grey">
        Số Lượng Domain Được Bảo Vệ:
        <Typography
          sx={{ fontSize: 13, display: "inline-flex", ml: 0.5 }}
          color="text.black"
        >
          {data?.length}
        </Typography>
      </Typography>
      <Box aria-labelledby="domains">
        <Typography sx={{ fontSize: 13, mb: 0.25 }} color="text.grey">
          Danh sách domain được bảo vệ:
        </Typography>
        {data?.map((domain, index) => {
          return (
            <Typography
              sx={{ fontSize: 13, mb: 0.25 }}
              color="text.black"
              key={index}
            >
              {domain?.type} - {domain?.name}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};

export default ProtectedDomain;
