import {
  CUSTOMER_REGISTER_TYPES,
  CUSTOMER_SOURCES,
  INDUSTRIES,
} from "@/libs/constants";
import { Grid, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { CustomerComponents } from "../Components";
import { Fragment } from "react";
import ErrorHelperText from "@components/shared/ErrorHelperText";

const CustomerInformation = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const CUSTOMER_FIELDS = [
    {
      field: "customer.customer_type",
      component: "SelectRadio",
      props: {
        options: CUSTOMER_REGISTER_TYPES,
      },
      cols: 12,
      label: "Loại Khách Hàng",
    },
    {
      field: "company.company_tax",
      component: "CompanyTax",
      hide: watch("customer.customer_type") !== "company",
      label: "Mã Số Thuế Doanh Nghiệp",
      required: true,
      props: {
        name: "company.company_tax",
        call: (company) => {
          setValue(`company.company_id`, company?.company_id);
          setValue(`company.company_name`, company?.company_name);
          setValue(`company.company_address`, company?.company_address);
          return;
        },
      },
      isError: !!errors?.company?.company_tax,
      message: errors?.company?.company_tax?.message,
    },
    {
      field: "company.company_name",
      component: "TextField",
      hide: watch("customer.customer_type") !== "company",
      label: "Tên Công Ty",
      required: true,
      isError: !!errors?.company?.company_name,
      message: errors?.company?.company_name?.message,
    },
    {
      field: "company.company_address",
      component: "TextField",
      hide: watch("customer.customer_type") !== "company",
      label: "Địa Chỉ Công Ty",
      required: true,
      isError: !!errors?.company?.company_address,
      message: errors?.company?.company_address?.message,
    },
    {
      field: "customer.customer_industry",
      component: "SelectClient",
      label: "Lĩnh Vực/ Ngành Nghề",
      required: true,
      props: {
        options: INDUSTRIES,
      },
      isError: !!errors?.customer?.customer_industry,
      message: errors?.customer?.customer_industry?.message,
    },
    {
      field: "customer.customer_website",
      component: "TextField",
      label: "Website",
    },
    {
      field: "customer.customer_source",
      component: "SelectClient",
      label: "Nguồn Khai Thác",
      props: {
        options: CUSTOMER_SOURCES,
      },
      required: true,
      isError: !!errors?.customer?.customer_source,
      message: errors?.customer?.customer_source?.message,
    },
    {
      field: "customer.customer_service_interestings",
      component: "ServiceInteresting",
      label: "Sản Phẩm/ Dịch Vụ Quan Tâm",
      cols: 8,
      required: true,
      isError: !!errors?.customer?.customer_service_interestings,
      message: errors?.customer?.customer_service_interestings?.message,
    },
    {
      field: "customer.customer_event",
      component: "TextField",
      label: "Tên Sự Kiện",
      hide: watch("customer.customer_source") !== "event",
    },
    {
      field: "customer.customer_sale_followers",
      component: "SaleTracking",
      label: "Sale Followers",
      required: true,
      props: {
        name: "customer.customer_sale_followers",
      },
      cols: 8,
      isError: !!errors?.customer?.customer_sale_followers,
      message: errors?.customer?.customer_sale_followers?.message,
    },
  ];
  return (
    <Grid container spacing={2}>
      {CUSTOMER_FIELDS?.map((field, index) => {
        if (field.hide) return null;

        const Component = CustomerComponents[field.component] || null;
        if (!Component) return null;
        return (
          <Grid item xs={field.cols || 12} md={field?.cols || 4} key={index}>
            <Typography sx={{ fontSize: 14, mb: 0.5 }} color="text.grey">
              {field.label} {field.required ? " *" : ""}
            </Typography>
            <Controller
              name={field.field}
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <Fragment>
                    <Component
                      value={value}
                      onChange={onChange}
                      {...field.props}
                    />
                    {field?.isError && (
                      <ErrorHelperText message={field?.message} />
                    )}
                  </Fragment>
                );
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
export default CustomerInformation;
