import { MenuProps } from '@/styles/common';
import { Box, Button, Checkbox, FormControl, ListItemIcon, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';
const SelectionColumns = ({
    store,
    columns,
    setColumns,
    initialColumns,
}) => {

    let hiddenColumnOnLocalStorage = JSON.parse(localStorage?.getItem(store)) || [];
    const selected = columns?.filter(column => !column?.hide)?.map(column => { return column?.field }) || []

    const handleChange = (event, value) => {
        event.preventDefault()
        if (value === 'all') {
            if (selected?.length !== columns?.length) {// hiển thị tất cả columns
                localStorage.setItem(store, '[]')
                return setColumns(prevColumns => prevColumns.map((column) => ({ ...column, hide: false })))
            }
            const hiddenColumns = columns?.filter(column => !column?.hide)?.map(column => column?.field)
            localStorage.setItem(store, JSON.stringify(hiddenColumns))
            return setColumns(prevColumns => prevColumns?.map((column) => {
                if (column?.hideable) {
                    return column
                }
                return { ...column, hide: true }
            }))
        }
        if (hiddenColumnOnLocalStorage?.includes(value)) {
            const newChange = hiddenColumnOnLocalStorage?.filter(column => column !== value)
            localStorage.setItem(store, JSON.stringify(newChange))
        } else {
            localStorage.setItem(store, JSON.stringify([...hiddenColumnOnLocalStorage, value]))
        }
        return setColumns(prevColumns => prevColumns?.map((column) => {
            if (column?.field === value) {
                return { ...column, hide: !column?.hide }
            }
            return { ...column }
        }))
    }
    return (
        <FormControl style={{ width: 250, marginRight: '16px' }}>
            <Select
                size='small'
                labelId="mutiple-select-label"
                multiple
                value={selected}
                onChange={(event, { props: { value } }) => {
                    handleChange(event, value)
                }}
                renderValue={() => <Typography sx={{ fontSize: 14 }}>Chọn cột</Typography>}
                MenuProps={MenuProps}
                fullWidth
            >
                <MenuItem sx={{ height: 38 }} value="all">
                    <ListItemIcon>
                        <Checkbox
                            size='small'
                            checked={selected?.length === columns?.length}
                            indeterminate={selected?.length > 0 && selected?.length < columns?.length}
                        />
                    </ListItemIcon>
                    <ListItemText
                        sx={{ fontSize: 14 }}
                        primary="Chọn tất cả"
                    />
                </MenuItem>
                {columns.map((field, index) => {
                    const isChecked = selected.indexOf(field?.field) > -1;
                    return (
                        <MenuItem key={index} value={field?.field} sx={{ height: 38 }}>
                            <ListItemIcon>
                                <Checkbox size='small' checked={isChecked} />
                            </ListItemIcon>
                            <ListItemText sx={{ fontSize: 14 }} primary={field?.headerName} />
                        </MenuItem>
                    )
                })}
                <Box sx={{ display: 'flex', height: 36, justifyContent: 'flex-end' }}>
                    <Button
                        variant='text'
                        onClick={() => {
                            setColumns(initialColumns)
                            localStorage.removeItem(store)
                        }}
                        size="small"
                        sx={{
                            fontSize: 14,
                            height: 36,
                            fontWeight: 400,
                            m: 'auto 0',
                            ":hover": {
                                cursor: 'pointer'
                            }
                        }}>
                        Mặc định
                    </Button>
                    <Button variant='text' size='small'
                        onClick={() => {
                            setColumns(prevColumns => prevColumns?.map((column) => ({ ...column, hide: false })))
                            localStorage.setItem(store, JSON.stringify(''))
                        }}
                        sx={{
                            fontSize: "14px",
                            height: 36,
                            fontWeight: 400,
                            m: 'auto 0 auto 4px',
                            ":hover": {
                                cursor: 'pointer'
                            }
                        }}>Chọn Tất Cả</Button>
                </Box>
            </Select>
        </FormControl>
    )
}

export default SelectionColumns