import CompanyApi from "@/apis/beta/Company/CompanyApi";
import { format_date_short } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import { INDUSTRIES } from "@/libs/constants";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { EditOutlined } from "@mui/icons-material";
import BusinessOutlined from "@mui/icons-material/BusinessOutlined";
import {
    Box,
    Button,
    Collapse,
    Grid,
    Paper,
    Tooltip,
    Typography
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Update from "../../Update";

const Information = ({ companyId }) => {
    const { data: company, refetch } = CompanyApi.Detail(companyId);

    const [edit, setEdit] = React.useState(false);
    return (
        <>
            <Paper sx={{ p: 2, mb: 2 }}>
                <Box sx={{ display: "flex", mb: 2, alignItems: 'center', flexDirection: 'row', gap: 1.25 }}>
                    <BusinessOutlined fontSize="small" color="primary" />
                    <Typography
                        sx={{
                            fontWeight: "medium",
                            m: "auto 0 auto 8px",
                        }}
                        color="primary"
                    >
                        Công Ty
                    </Typography>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={4} lg={3}>
                        <Typography sx={{ color: "#777680" }}>Tên Công Ty:</Typography>
                        <Typography>
                            {company?.company_name || "Tên Công Ty Chưa Xác Định"}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                        <Typography sx={{ color: "#777680" }}>Ngày Thành Lập:</Typography>
                        <Typography>
                            {format_date_short(company?.company_established_date) || "---"}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                        <Typography sx={{ color: "#777680" }}>Mã Số Thuế:</Typography>
                        <Typography>
                            {company?.company_tax || "Chưa có thông tin"}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                        <Typography sx={{ color: "#777680" }}>Địa Chỉ:</Typography>
                        <Typography>
                            {company?.company_address || "Chưa có thông tin"}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                        <Typography sx={{ color: "#777680" }}>Lĩnh Vực:</Typography>
                        <Tooltip
                            title={
                                INDUSTRIES.find((x) => x.value === company?.company_industry)
                                    ?.childrens?.map((x) => x?.label)
                                    ?.join(", ") || ""
                            }
                        >
                            <Typography>
                                {
                                    INDUSTRIES.find((x) => x.value === company?.company_industry)
                                        ?.label
                                }
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                        <Typography sx={{ color: "#777680" }}>Website:</Typography>
                        {company?.company_website ? (
                            <Link to={company?.company_website} replace>
                                <Typography sx={{ fontSize: 14, color: "#4C57A9" }}>
                                    {company?.company_website}
                                </Typography>
                            </Link>
                        ) : (
                            <Typography sx={{ fontSize: 14, color: "#4C57A9" }}>
                                {"Không có thông tin về website"}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button size="small" variant="outlined"
                        onClick={() => setEdit(true)}
                        startIcon={<EditOutlined fontSize="small" />}>
                        Chỉnh Sửa TT
                    </Button>
                </Box>
            </Paper>
            {edit && (
                <Collapse in={edit} sx={{ mb: 2 }}>
                    <Update
                        data={company}
                        callback={refetch}
                        handleClose={() => setEdit(false)}
                    />
                </Collapse>
            )}
        </>
    );
};

export default withSuspense(Information, MuiSkeleton["GridInformation"]);
