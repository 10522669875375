import { Box, FormControl, TextField, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const WorkContractNumber = () => {
    const { control, formState: { errors } } = useFormContext()

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 0.5, md: 2 }
        }}>
            <Box sx={{ display: 'flex', minWidth: { xs: '100%', md: 300 } }}>
                <Typography component="div" sx={{
                    m: 'auto 0',
                    color: "#777680"
                }}>Mã số hợp đồng
                    <Tooltip
                        placement='top'
                        title="Mã số hợp đồng nhân viên được tự động sinh ra, hạn chế chỉnh sửa trường này.">
                        <Typography sx={{
                            display: 'inline',
                            fontSize: 14,
                            color: '#000',
                            ":hover": {
                                cursor: "pointer"
                            }
                        }}>*</Typography>
                    </Tooltip>
                </Typography>
            </Box>
            <Controller
                control={control}
                name="work_contract_number"
                render={({ field: { value, onChange } }) => {
                    return (
                        <FormControl fullWidth>
                            <TextField size='small'
                                disabled
                                value={value}
                                onChange={onChange}
                                placeholder="Mã số hợp đồng..."
                                error={!!errors?.work_contract_number}
                                helperText={errors?.work_contract_number?.message}
                            />
                        </FormControl>
                    )
                }}
            />

        </Box>
    )
}

export default WorkContractNumber