import CompanyApi from "@/apis/beta/Company/CompanyApi";
import SingleSelectCustomize from "@/components/shared/SingleSelectCustomize";
import { buildQueryFilterCondition } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { createFilterOptions } from "@mui/material";
import moment from "moment";
import React from "react";

const autoCompletedFitler = createFilterOptions();

const SelectionCompanyTax = ({ value, setValue }) => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const [state, setState] = React.useState(null);

  const filterableCompany = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return ``;

    const queries = [
      {
        name: "companies.company_tax",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "company_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "company_id",
        operator: "$eq",
        disabled: !parseInt(value?.company_id),
        value: parseInt(value?.company_id),
      },
    ];
    return buildQueryFilterCondition("$or", 0, queries);
  }, [debouncedSearchQuery, value]);

  const {
    data: { companies },
    isFetching,
  } = CompanyApi.List({
    query: filterableCompany(),
    page: 1,
    pageSize: 10,
  });
  React.useEffect(() => {
    setValue(`company_tax`, state?.company_tax);
    setValue(`company_id`, state?.company_id || -1);

    if (!!state?.company_id && !!state?.company_name) {
      setValue(`company_name`, state?.company_name);
      setValue(`company_id`, state?.company_id);
      setValue(`company_address`, state?.company_address);
      setValue(`company_industry`, state?.company_industry);
      setValue(
        `company_established_date`,
        moment(state?.company_established_date)
      );
      setValue(`company_website`, state?.company_website);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <SingleSelectCustomize
      freeSolo
      value={state}
      options={[
        ...companies?.map((c) => ({
          ...c,
          value: c?.company_tax,
          label: `(${c?.company_tax}) ${c?.company_name}`,
        })),
      ]}
      input={{
        label: "Mã số thuế *",
        placeholder: "Nhập MST/ tên công ty tìm kiếm...",
        value: searchQuery,
        onChange: (event) => {
          setSearchQuery(event?.target?.value);
        },
      }}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setState({
            company_id: -1,
            company_tax: newValue,
            label: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setState({
            company_id: -1,
            company_tax: newValue?.inputValue,
            label: newValue.inputValue,
          });
        } else {
          setState(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = autoCompletedFitler(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.label
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            label: `Add "${inputValue}"`,
          });
        }
        return filtered;
      }}
      mode="server"
      loading={isFetching}
    />
  );
};

export default withSuspense(
  withPermission(SelectionCompanyTax, {
    type: "TextField",
    feature: "company",
    action: "index",
  }),
  MuiSkeleton["TextField"]
);
