import { getRangeDates } from '@/functions'
import DatePicker from '@components/datepickers/DateRangPicker'
import { FormControl, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { useFormContext } from 'react-hook-form'

const ServiceTimes = () => {
    const { watch, setValue } = useFormContext();

    const changeTimes = (newValues = [], scope = '1stTime') => {
        switch (scope) {
            case '1stTime':
                const [months,] = getRangeDates(newValues[0], newValues[1])
                setValue(`service_management_billing.service_billing_startdate`, newValues[0]);
                setValue(`service_management_billing.service_billing_enddate`, newValues[1]);
                //
                setValue(`service_management_billing.service_nextbill_startdate`, dayjs(newValues[1]).add(1, "days"));
                setValue(`service_management_billing.service_nextbill_enddate`, dayjs(newValues[1]).add(1, "days").add(months || 1, 'months').subtract(1, 'days'));
                break;
            case '2ndTime':
                setValue(`service_management_billing.service_nextbill_startdate`, newValues[0]);
                setValue(`service_management_billing.service_nextbill_enddate`, newValues[1]);
                break;
            default:
                break;
        }
    };

    return (
        <Grid container spacing={2} sx={{ mt: 0.5 }}>
            <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: 13, fontWeight: 'medium', pb: 1.25 }}>
                    Thời Gian Thanh Toán Đầu Tiên
                </Typography>
                <FormControl fullWidth>
                    <DatePicker
                        value={[
                            dayjs(
                                watch(`service_management_billing.service_billing_startdate`)
                            ),
                            dayjs(
                                watch(`service_management_billing.service_billing_enddate`)
                            ),
                        ]}
                        handler={(newValues) => changeTimes(newValues, '1stTime')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: 13, fontWeight: 'medium', pb: 1.25 }}>
                    Thời Gian Gia Hạn
                </Typography>
                <FormControl fullWidth>
                    <DatePicker
                        value={[
                            dayjs(watch(`service_management_billing.service_nextbill_startdate`)),
                            dayjs(watch(`service_management_billing.service_nextbill_enddate`)),
                        ]}
                        handler={(newValues) => changeTimes(newValues, '2ndTime')}
                    />
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default ServiceTimes