import { useNotificationContext } from "@/contexts/notification";
import { queryClientRefetcher } from "@/functions";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

import DayoffApi from "@/apis/beta/Employee/DayoffApi";
import ErrorHelperText from "@/components/shared/ErrorHelperText";
import { useQueryClient } from "react-query";

const Bod = ({ data, callback }) => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();

  const [submit, setSubmit] = React.useState(null);
  const [feedback, setFeedback] = React.useState(
    data?.day_off_feedback_bod || ""
  );
  const [error, setError] = React.useState(false);

  const handleManagerConfirmed = async (type = "cancel") => {
    if (feedback?.trim() === "") return setError(true);

    let form = {
      day_off_feedback_bod: feedback,
      day_off_bod_confirm: true,
      day_off_status: type === "cancel" ? 3 : 1,
      //cancel = 3, confirm = 1 => Bod duyệt => Leader duyệt => Phiếu được duyệt
    };
    setSubmit(type);

    const response = await DayoffApi.Approve({
      id: data?.day_off_id,
      data: form,
    });
    setSubmit(false);

    if (!response || response.status !== 200 || response.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message || "Thao tác thất bại.",
        severity: "error",
      });
    }
    queryClientRefetcher(queryClient, ["all_day_offs.list", "day_off.detail"]);
    setNotification({
      open: true,
      message: `${type === "cancel" ? "Từ chối" : "Xác nhận"
        } phiếu nghỉ thành công.`,
      severity: "success",
    });
  };

  return (
    <Paper sx={{ p: 3, mt: 3 }} elevation={10}>
      <Typography
        sx={{
          fontWeight: "medium",
          mb: 0.5,
        }}
      >
        BOD / HR Manager
      </Typography>
      <FormControl fullWidth>
        <TextField
          multiline
          value={feedback}
          disabled={
            (data?.day_off_status !== 0 && data?.day_off_status !== 4) ||
            !!submit
          }
          onChange={(event) => {
            if (error) {
              setError(false);
            }
            setFeedback(event.target.value);
          }}
          placeholder="Vui lòng nhập thông tin phản hồi & ý kiến của bạn..."
        />
        {error && (
          <ErrorHelperText
            message={"Vui lòng nhập thông tin phản hồi / ý kiến của bạn."}
          />
        )}
      </FormControl>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3, gap: 2 }}>
        <Button
          size="small"
          variant="outlined"
          onClick={() => handleManagerConfirmed("cancel")}
          disabled={
            (data?.day_off_status !== 0 && data?.day_off_status !== 4) ||
            !!submit ||
            error
          }
          startIcon={
            submit === "cancel" && (
              <CircularProgress size={14} sx={{ color: "#777680" }} />
            )
          }
          sx={{
            height: { xs: 36, md: 42 },
            minWidth: 120,
          }}
        >
          Không đồng ý
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={() => handleManagerConfirmed("ok")}
          startIcon={
            submit === "ok" && (
              <CircularProgress size={14} sx={{ color: "#777680" }} />
            )
          }
          disabled={
            (data?.day_off_status !== 0 && data?.day_off_status !== 4) ||
            !!submit ||
            error
          }
          sx={{
            height: { xs: 36, md: 42 },
            minWidth: 120,
          }}
        >
          Xác Nhận
        </Button>
      </Box>
    </Paper>
  );
};

export default Bod;
