import { Collapse } from "@mui/material";
import React, { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import ServiceComponentShareds from "../shared";
import ServiceManagementBilling from "../../shared/ServiceManagementBilling";

const EmailServer = ({ isPoc, product }) => {
  const { watch } = useFormContext();
  return (
    <Fragment>
      <Collapse
        in={!!product && !!Boolean(watch(`product_informations`)?.length > 0)}
      >
        {React.createElement(
          ServiceComponentShareds["Package.Information"],
          { product },
          {}
        )}
      </Collapse>
      <Collapse
        in={
          product?.product_addons?.length !== 0 &&
          !isPoc &&
          !!Boolean(watch(`product_customizable`))
        }
      >
        {React.createElement(
          ServiceComponentShareds["Customized.Addons"],
          {
            productAddons: product?.product_addons,
          },
          {}
        )}
      </Collapse>
      {React.createElement(
        ServiceComponentShareds["Package.Payment"],
        { isPoc: isPoc },
        {}
      )}
      {!isPoc && <ServiceManagementBilling />}
    </Fragment>
  );
};

export default EmailServer;
