import { Box, Typography, TextField, Button } from "@mui/material";
import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import WorkDayExport from "@components/DataGrid/Filter/ButtonExports/WorkDayExport";
import ImportWorkDay from "../Button/ImportWorkDay";
import ToolHeader from "../Button/ToolHeader";
import moment from "moment";

const Header = () => {
  const { control, watch, setValue } = useFormContext();
  const [isThisMonth, setIsThisMonth] = useState(true);

  const handleSetDate = (from, to) => {
    setValue("from", from);
    setValue("to", to);
  };

  function getTotalDaysInMonth(month, year) {
    const monthIndex = month - 1;
    const lastDayOfMonth = new Date(year, monthIndex + 1, 0);
    return lastDayOfMonth.getDate();
  }

  return (
    <>
      <Typography
        sx={{
          fontSize: { xs: 18, md: 24 },
          fontWeight: "medium",
        }}
      >
        Danh Sách Ngày Công
      </Typography>

      <Box mt={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            gap={2}
          >
            <Box display="flex" gap={2} flexWrap="wrap">
              <Controller
                control={control}
                name="from"
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField {...params} size="small" label="Từ ngày" />
                    )}
                  />
                )}
              />

              <Controller
                control={control}
                name="to"
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField {...params} size="small" label="Đến ngày" />
                    )}
                  />
                )}
              />

              <Button
                onClick={() => {
                  const currentDate = new Date(Date.now());

                  const from = moment(
                    `${
                      currentDate.getMonth() < 10
                        ? `0${currentDate.getMonth()}`
                        : currentDate.getMonth()
                    }/01/${currentDate.getFullYear()}`
                  );

                  const to = moment(
                    `${
                      currentDate.getMonth() < 10
                        ? `0${currentDate.getMonth()}`
                        : currentDate.getMonth()
                    }/${getTotalDaysInMonth(
                      currentDate.getMonth(),
                      currentDate.getFullYear()
                    )}/${currentDate.getFullYear()}`
                  );

                  setIsThisMonth(false);
                  handleSetDate(from, to);
                }}
                variant={!isThisMonth ? "contained" : "outlined"}
                size="small"
              >
                Tháng Trước
              </Button>
              <Button
                onClick={() => {
                  const currentDate = new Date(Date.now());

                  const from = moment(
                    `${
                      currentDate.getMonth() < 10
                        ? `0${currentDate.getMonth() + 1}`
                        : currentDate.getMonth() + 1
                    }/01/${currentDate.getFullYear()}`
                  );

                  const to = moment(
                    `${
                      currentDate.getMonth() < 10
                        ? `0${currentDate.getMonth() + 1}`
                        : currentDate.getMonth() + 1
                    }/${getTotalDaysInMonth(
                      currentDate.getMonth() + 1,
                      currentDate.getFullYear()
                    )}/${currentDate.getFullYear()}`
                  );

                  setIsThisMonth(true);
                  handleSetDate(from, to);
                }}
                variant={isThisMonth ? "contained" : "outlined"}
                size="small"
              >
                Tháng Này
              </Button>
            </Box>

            <Box display="flex" gap={2} flexWrap="wrap">
              <WorkDayExport query={watch()} />
              <ImportWorkDay />
              <ToolHeader />
            </Box>
          </Box>
        </LocalizationProvider>
      </Box>
    </>
  );
};

export default Header;
