import { SERVICES } from '@/libs/constants';
import ErrorHelperText from '@components/shared/ErrorHelperText';
import MultipleSelectCustomize from '@components/shared/MultipleSelectCustomize';
import { FormControl, Typography } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const CustomerServiceInterest = () => {
    const { control, formState: { errors } } = useFormContext();


    return (
        <React.Fragment>
            <Typography sx={{ mb: 0.5, color: "#777680", fontSize: 14 }}>
                Dịch Vụ Quan Tâm *
            </Typography>
            <Controller
                control={control}
                name="customer.customer_service_interestings"
                render={({ field: { value: values, onChange } }) => {
                    return (
                        <FormControl fullWidth>
                            <MultipleSelectCustomize
                                limitTags={2}
                                options={SERVICES}
                                value={SERVICES?.filter(service => values?.includes(service?.value?.toUpperCase()))}
                                onChange={(event, newValues) => {
                                    onChange([...newValues?.map((item => item?.value))])
                                }}
                                input={{
                                    placeholder: "Nhập tên nhóm sản phẩm/ dịch vụ tìm kiếm..."
                                }}
                                sx={{
                                    "&.MuiAutocomplete-option": {
                                        textTransform: "uppercase"
                                    }
                                }}
                            />
                            {!!errors?.customer?.customer_service_interestings && <ErrorHelperText message={errors?.customer?.customer_service_interestings?.message} />}
                        </FormControl>
                    )
                }} />
        </React.Fragment>
    )
}

export default CustomerServiceInterest