import { Box, Typography } from '@mui/material'
import React from 'react'
import withPermission from '@/hocs/withPermission'
import ReportTabs from '../../shareds/ReportTab'

const Team = () => {
    return (
        <Box>
            <ReportTabs />
            <Typography sx={{
                mb: 2,
                fontSize: 14,
                color: 'grey.700'
            }}>Report.Sale.Team</Typography>
        </Box>
    )
}

export default withPermission(Team, { feature: 'report', action: 'report-sale' })