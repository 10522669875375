import ServiceAddonApi from "@/apis/beta/Service/ServiceAddonApi";
import { queryClientRefetchScope } from "@/functions";
import withPermission from "@/hocs/withPermission";
import { useNotificationContext } from "@contexts/notification";
import AddOutlined from "@mui/icons-material/AddOutlined";
import { Button, Drawer } from "@mui/material";
import { Fragment, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import SERVICE_ADDON_UP_UIS from "../../Addons/Up";

const ServiceCreateAddon = ({ service }) => {
	const { setNotification } = useNotificationContext();
	const queryClient = useQueryClient();
	const [isCreated, setCreated] = useState(false);
	const { mutateAsync, isLoading } = useMutation(ServiceAddonApi.Create);

	const onSubmit = async (values) => {
		let form = values;
		const response = await mutateAsync({
			serviceId: service?.service_id,
			data: form,
		});
		if (!response || response?.errors) {
			return setNotification({
				open: true,
				message: response?.errors?.[0]?.message,
				severity: "error",
			});
		}
		queryClientRefetchScope(queryClient, "service");

		return setNotification({
			open: true,
			message: "Thêm mới dịch vụ addon thành công",
			severity: "success",
		});
	};

	const renderServiceAddonUp = () => {
		const Component = SERVICE_ADDON_UP_UIS[service?.service_template]
		if (!Component) return <></>;
		return <Component submit={isLoading} service={service} onSubmit={onSubmit} onClose={() => setCreated(false)} handleClose={() => setCreated(false)} />;
	}
	return (
		<Fragment>
			{
				<Drawer
					anchor="right"
					open={isCreated}
					onClose={() => setCreated(false)}
				>
					{renderServiceAddonUp()}
				</Drawer>
			}
			<Button
				size="small"
				variant="outlined"
				disabled={isCreated}
				onClick={() => setCreated(true)}
				startIcon={<AddOutlined fontSize="small" />}
			>
				Addon
			</Button>
		</Fragment>
	);
};

export default withPermission(ServiceCreateAddon, {
	feature: "service",
	action: "index",
	type: "Button",
});
