import ServiceNoteApi from "@/apis/beta/Service/ServiceNoteApi";
import { queryClientRefetchScope } from "@/functions";
import withPermission from "@/hocs/withPermission";
import { useNotificationContext } from "@contexts/notification";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

const ServiceCreateNote = ({ service, onClose }) => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();
  const { mutateAsync, isLoading } = useMutation(ServiceNoteApi.Create);

  const [note, setNote] = useState("");
  const onSubmit = async () => {
    let form = {
      note_content: note,
    };

    const response = await mutateAsync({
      serviceId: service?.service_id,
      data: form,
    });
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }
    setNotification({
      open: true,
      message: "Thêm ghi chú dịch vụ thành công.",
      severity: "success",
    });
    queryClientRefetchScope(queryClient, "service");
    return onClose();
  };

  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <DialogTitle>Thêm Ghi Chú</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <FormControl fullWidth>
            <TextField
              label="Ghi chú *"
              multiline
              minRows={3}
              value={note}
              placeholder="Nhập ghi chú..."
              onChange={(event) => {
                setNote(event?.target?.value);
              }}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button size="small" variant="outlined" onClick={onClose}>
          Huỷ Thao Tác
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={onSubmit}
          startIcon={
            isLoading && (
              <CircularProgress size={14} sx={{ color: "#777680" }} />
            )
          }
        >
          Xác nhận
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withPermission(ServiceCreateNote, {
  feature: "service",
  action: "cancelled",
  type: "button",
});
