import { Box, Skeleton } from '@mui/material'
import React from 'react'


const EChartPie = () => {
    return (
        <Box sx={{ width: '100%', height: 300, maxHeight: 300 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', height: 260 }}>
                <Skeleton variant='circular' sx={{ height: 200, width: 200, m: 'auto' }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: 40 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                    {[1, 2, 3].map((item) => {
                        return (
                            <Box sx={{ display: 'flex' }} key={item}>
                                <Skeleton variant='rectangular' sx={{ height: 24, width: 50 }} />
                                <Skeleton sx={{ width: 50, height: 12, m: 'auto 0 auto 4px' }} />
                            </Box>
                        )
                    })}
                </Box>
            </Box>
        </Box>
    )
}

export default EChartPie