import NumericFormatCustom from "@components/NumericFormatCustom";
import { FormControl, TextField } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const ServiceAddonQtyDescrease = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name="service_addon_qty"
      render={({ field: { value, onChange } }) => (
        <FormControl fullWidth sx={{ mb: 1.5 }}>
          <TextField
            label="Thông số cấu hình giảm *"
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            placeholder="0..."
            value={value}
            onChange={onChange}
            InputProps={{
              inputComponent: NumericFormatCustom,
            }}
            error={!!errors?.service_addon_qty}
            helperText={errors?.service_addon_qty?.message}
          />
        </FormControl>
      )}
    />
  );
};

export default ServiceAddonQtyDescrease;
