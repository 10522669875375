import { Box } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import Credit from './Credit';
import InvoiceList from './Invoice';
import Statistical from './Statistical';

const CustomerFinance = () => {
    const { customer_id: customerId } = useParams()

    return (
        <Box>
            <Statistical customerId={customerId} />
            <InvoiceList customerId={customerId} />
            <Credit customerId={customerId} />
        </Box>
    )
}
export default CustomerFinance