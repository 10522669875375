import { ErrorOutline } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'

const NotificationInformation = ({
    title,
    message
}) => {
    return (
        <Box sx={{
            borderRadius: '14px',
            bgcolor: "#1A237E14",
            p: '20px',
            display: 'flex'
        }}>
            <ErrorOutline sx={{ m: 0, mr: 1, color: "#1A237E" }} />
            <Box>
                {title && <Typography sx={{ color: "#1A237E", fontSize: 14 }}>{title}</Typography>}
                <Typography sx={{
                    flexWrap: 'wrap',
                    textAlign: "justify",
                    fontSize: 14
                }}>
                    {message}
                </Typography>
            </Box>
        </Box>
    )
}

export default NotificationInformation