import withPermission from '@/hocs/withPermission'
import { Box, Typography } from '@mui/material'
import React from 'react'

const Promotions = () => {
    return (
        <Box>
            <Typography>
                This feature is processing...
            </Typography>
        </Box>
    )
}

export default
    withPermission(Promotions, {
        feature: "product",
        action: "promotions"
    })
