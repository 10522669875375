import { encodeBase64, requestSimple, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import {
  API_STORAGE_URL,
  API_AUTOMATION,
  API_SERVICE_URL,
} from "@/libs/constants";
import queryString from "query-string";

class ReportWatchApi {
  List = ({ query = null, searchQuery = null, page = 1, pageSize = 20 }) => {
    return useQueryWithCustomized(
      ["watches", query, page, searchQuery, pageSize],
      async () => {
        return await requestWithToken(
          `${API_AUTOMATION}/api/workers/reports?${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${searchQuery}` : ""}&page=${page || 1
          }&page_size=${pageSize || 10}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              pagination: data?.data?.pagination,
              reportList: data?.data?.reports || [],
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };
  async CreateReport(values) {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/reports`,
      "POST",
      values
    );
    return response?.data;
  }
  async GetReport(uuid) {
    const response = await requestSimple(
      `${API_AUTOMATION}/api/workers/reports/${uuid}`
    );
    return response?.data;
  }
  async UpdateReport(uuid, values) {
    if (uuid === null || "") return;
    const response = await requestSimple(
      `${API_AUTOMATION}/api/workers/reports/${uuid}`,
      "PATCH",
      values
    );
    return response?.data;
  }
  Download = async ({ url }) => {
    const response = await requestWithToken(
      `${API_STORAGE_URL}/reports/waf/${url}.pdf`,
      "GET",
      {},
      {
        "Content-Type": "application/pdf",
        "CONTENT-DISPOSITION": "attachment",
      },
      { withCredentials: true, responseType: "blob" }
    );
    return response?.data;
  };
  async GetReportHistory(uuid) {
    const response = await requestSimple(
      `${API_AUTOMATION}/api/workers/reports/${uuid}/histories`
    );
    return response?.data;
  }
  History = ({ uuid }) => {
    return useQueryWithCustomized(
      ["histories", uuid],
      async () => {
        const response = await requestSimple(
          `${API_AUTOMATION}/api/workers/reports/${uuid}/histories`
        );
        return response?.data;
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };
  async ResendReport({ report_uuid, body }) {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/reports/${report_uuid}/resend`,
      "POST",
      body
    );
    return response?.data;
  }
  MlyticList = ({ organization_name }) => {
    return useQueryWithCustomized(
      [
        JSON.stringify([
          "mlytics",
          organization_name
        ]),
      ],
      async () => {
        const response = await requestWithToken(
          `${API_SERVICE_URL}/v2/search/services/s10011/mlytics/?organization_name=${organization_name}`
        );
        return { Customers: response?.data?.data?.customers || [] };
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  
  WafAttribute = ({ report_uuid }) => {
    return useQueryWithCustomized(
      ["waf_attributes", report_uuid],
      async () => {
        const response = await requestSimple(
          `${API_AUTOMATION}/api/workers/reports/${report_uuid}/waf_attribute`
        );
        return response?.data?.data;
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };
  async UpdateWafAttribute(report_uuid, values) {
    if (report_uuid === null || "") return;
    const response = await requestSimple(
      `${API_AUTOMATION}/api/workers/reports/${report_uuid}/update_report_waf`,
      "PATCH",
      {data: values}
    );
    return response?.data;
  }

  CustomersWaf = async (params) => {
    const paramsQueryString = queryString.stringify(
      { ...params, page: params?.pageParam || 1 },
      {
        skipNull: true,
      }
    );

    const results = await requestWithToken(
      `${API_AUTOMATION}/api/workers/reports/list_customers?${paramsQueryString}`,
      "GET",
      {}
    ).then((response) => {
      const data = response.data.data;
      if (response.data.status !== 200) {
        console.log("error", data.data.errors[0].message);
        return [];
      }

      return {
        customers: data?.customers?.map((o) => ({
          id: o.customer_id,
          ...o,
        })),
        pagination: data?.pagination,
      };
    });

    return results;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ReportWatchApi();
