import withPermission from '@/hocs/withPermission'
import { ShareLocationOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import ModalAddressInformation from './ModalAddressInformation'
const AddressInformation = ({
    disabled = true,
    setDisabled,
    customerId = null,
    customerName = "",
    callback,
    ...customer
}) => {
    const [openModal, setOpenModal] = React.useState(false)
    const handlerClick = () => {
        if (openModal) {
            setDisabled(false)
            return setOpenModal(false);
        }
        setDisabled(true)
        return setOpenModal(true)
    }

    return (
        <React.Fragment>
            {openModal && <ModalAddressInformation
                onClose={handlerClick}
            />}
            <Button size='small'
                variant='outlined'
                fullWidth
                disabled={disabled || customer?.customer_service_information?.customer_status !== "active"}
                startIcon={<ShareLocationOutlined fontSize='small' />}
                sx={{ height: { xs: 40, md: 42 } }}
                onClick={handlerClick}
            >
                Thông Tin Địa Chỉ
            </Button>
        </React.Fragment>
    )
}

export default withPermission(AddressInformation, {
    feature: "customer",
    action: "update",
    type: "Button"
})