import BreadCrumb from "@/components/shared/BreadCrumb"
import withPermission from "@/hocs/withPermission"
import { SERVICE_PERIODS } from "@/libs/constants"
import { Box, Typography } from '@mui/material'
import React from 'react'
import ProductForm from '../shared/ProductForm'

const INTIAL_PRODUCT_METADATA = {
    product_group_id: -1,
    product_name: "",
    product_template: "Normal",
    product_subtitle: "",
    product_tax: true,
    product_description: "",
    product_type: "customized", // customized | package
    product_billing_configures: SERVICE_PERIODS?.slice(1)?.map((item) => ({
        label: item?.label,
        name: item?.value,
        pricing: 0
    })) || [], // configoption for packages,
    product_informations: [],
    product_configoptions: [],
    product_pricing_configures: {
        product_pricing_method: "default",
        product_pricing_unit: "gb",
        default: {
            pricing: 0
        },
        steps: [{ start: 0, end: 5000, pricing: 0 }]
    },
    product_addons: []
}

const Create = () => {
    return (
        <Box>
            <Typography sx={{ fontSize: { xs: 18, md: 24 }, fontWeight: 'medium' }}>
                Thêm Sản Phẩm
            </Typography>
            <BreadCrumb
                data={[
                    { label: "Danh Sách Sản Phẩm", reactLink: "/products" },
                    { label: "Thêm Sản Phẩm" }
                ]}
            />
            <ProductForm type="create" defaultValues={INTIAL_PRODUCT_METADATA} />
        </Box>

    )
}

export default withPermission(Create, { feature: "product", action: "create" })