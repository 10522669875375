import React from "react";
import { useParams } from "react-router-dom";
import InvoiceUpdates from "../InvoiceUpdates";
import Information from "./Information";
import InvoiceItems from "./InvoiceItems";

const InvoiceInformation = () => {
  const { invoice_id } = useParams();

  return (
    <React.Fragment>
      <Information invoiceId={invoice_id} />
      <InvoiceItems invoiceId={invoice_id} />
      <InvoiceUpdates invoiceId={invoice_id} />
    </React.Fragment>
  );
};

export default InvoiceInformation;
