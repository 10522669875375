import { WORK_CONTRACT_STATUS } from '@/libs/constants'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, FormControl, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const WorkcontractStatus = () => {
    const { control, formState: { errors } } = useFormContext()

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 0.5, md: 2 },
            mt: 2
        }}>
            <Box sx={{ display: 'flex', minWidth: { xs: '100%', md: 300 } }}>
                <Typography sx={{
                    m: 'auto 0',
                    color: "#777680"
                }}>
                    Trạng thái HĐ
                </Typography>
            </Box>
            <Controller
                control={control}
                name="work_contract_status"
                render={({ field: { value, onChange } }) => {
                    const selected = WORK_CONTRACT_STATUS.find(stt => stt?.value === value)
                    return (
                        <FormControl fullWidth>
                            <SingleSelectCustomize
                                value={selected}
                                options={WORK_CONTRACT_STATUS}
                                onChange={(event, newValue) => onChange(newValue?.value || -1)}
                            />
                            {!!errors?.work_contract_status && <ErrorHelperText message={errors?.work_contract_status?.message} />}
                        </FormControl>
                    )
                }} />
        </Box>
    )
}

export default WorkcontractStatus