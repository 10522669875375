import { format_date_time } from "@/functions";
import { BUSINESS_STATUS_TYPES } from "@/libs/constants";
import { DescriptionOutlined } from '@mui/icons-material';
import { Box, Grid, IconButton, Paper, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

const BusinessInformation = ({
    business
}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const collectBusinessInformation = () => {
        return [
            {
                mobile: 1, default: 1,
                label: "Tên Nhân Viên",
                value: business?.business_employee_name,
            }, {
                mobile: 2, default: 4,
                label: "Phòng Ban",
                value: business?.employee_department_name,
            }, {
                mobile: 3, default: 2,
                label: "Thời Gian Công Tác",
                value: `${format_date_time(moment(business?.business_startdate).add(-7, 'hour'), false)} - 
                ${format_date_time(moment(business?.business_enddate).add(-7, 'hour'), false)}`, //business_enddate
                sx: {
                    minWidth: 150
                }
            }, {
                mobile: 4, default: 5,
                label: "Nơi Công Tác",
                value: business?.business_place,
            }, {
                mobile: 5, default: 3,
                label: "Trạng Thái",
                value: BUSINESS_STATUS_TYPES.find(b => b.value === business?.business_status).label,
            }
        ]
    }

    return (
        <Paper sx={{ p: 3, mb: 2 }}>
            <Box sx={{ display: 'flex', mb: 2 }}>
                <IconButton className="information" sx={{ m: 'auto 8px auto 0' }}>
                    <DescriptionOutlined sx={{ color: '#FFF' }} />
                </IconButton>
                <Typography sx={{
                    m: 'auto 0',
                    fontSize: 24,
                    color: '#00000f'
                }}>#{business?.business_id}</Typography>
            </Box>
            <Grid container spacing={{ xs: 1, md: 2 }}>
                {_.sortBy(Array.from(collectBusinessInformation()), [isMobile ? 'mobile' : 'default'])?.map((item, index) => {
                    if (item.hidden) return null;
                    return <Grid item xs={12} md={4} key={index}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: { xs: 'row', md: 'column' },
                            gap: 0.5
                        }}>
                            <Typography sx={{ ...item?.sx, color: '#777680' }}>
                                {item?.label}:
                            </Typography>
                            <Typography>
                                {item?.value}
                            </Typography>
                        </Box>
                    </Grid>
                })}
            </Grid>
            <Grid container spacing={{}}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: "flex",
                            mt: { xs: 1, md: 2 },
                            flexDirection: { xs: "column", md: "row" },
                            gap: 0.5,
                            mb: 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: "medium" }}>
                            Mục Đích Công Tác:
                        </Typography>
                        <Typography sx={{ flexWrap: "wrap", textAlign: "left" }}>
                            {business?.business_content}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default BusinessInformation