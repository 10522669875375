import InvoiceApi from "@/apis/beta/Invoice/InvoiceApi";
import Loading from "@components/shared/MuiSkeleton/Loading";
import { Box } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import InvoicePDF from "./Download";

const Preview = () => {
  const { invoice_id } = useParams();
  const [searchParams] = useSearchParams();

  const { data, isLoading, isError } = useQuery(
    [`get-invoice-preview-${invoice_id}`],
    () =>
      InvoiceApi.Preview({
        invoiceId: invoice_id,
        type: searchParams.get("preview_type") || "invoice",
      })
  );

  if (isError) {
    return null;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box width="100%">
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1.5 }}>
        <InvoicePDF invoiceId={invoice_id} type={searchParams.get("preview_type") || "invoice"} />
      </Box>
      <div dangerouslySetInnerHTML={{ __html: data }} />
    </Box>
  );
};

export default Preview;
