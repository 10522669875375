import { Typography } from '@mui/material'
import React from 'react'

const ContractTitle = ({
    type
}) => {
    return (
        <Typography sx={{
            fontSize: { xs: 16, md: 18 },
            textTransform: 'uppercase',
            fontWeight: 'medium'
        }}>
            {type === "create" ? "Thêm" : "Chỉnh Sửa"} Hợp Đồng
        </Typography>
    )
}

export default ContractTitle