import DatePicker from '@components/DatePicker'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import { Box, FormControl, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const EmployeeIdentity = () => {
    const { control, formState: { errors } } = useFormContext()

    return (
        <Box sx={{
            p: 1.5,
            border: '1px solid rgba(0, 0, 0, 0.18)',
            borderRadius: 1,
        }}>
            <Typography sx={{
                fontSize: { xs: 16, md: 18 },
                fontWeight: 'medium',
                mb: 1
            }}>
                Thông Tin Định Danh/ Giấy Tờ Tuỳ Thân
            </Typography>
            <Grid container spacing={{ xs: 1.5, md: 2 }}>
                <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
                    <Controller
                        name="employee_idno"
                        rules={{ required: "Số CCCD/ định danh là bắt buộc", pattern: {value:/^[0-9]*$/, message: "Vui lòng nhập số"} }}
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        value={value}
                                        label="Số CCCD/ định danh cá nhân (*)"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onChange={onChange}
                                        size='small'
                                        placeholder='Số CCCD/ định danh cá nhân...'
                                        error={!!errors?.employee_idno}
                                        helperText={errors?.employee_idno?.message}
                                    />
                                </FormControl>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
                    <Controller
                        name="employee_idno_issued_on"
                        control={control}
                        rules={{ required: "Ngày cấp là bắt buộc" }}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <DatePicker value={value}
                                        onChange={onChange}
                                        label="Ngày cấp (*)"
                                    />
                                    {!!errors?.employee_idno_issued_on && <ErrorHelperText
                                        message={errors?.employee_idno_issued_on?.message}
                                    />}
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="employee_idno_issued_by"
                        rules={{ required: "Nơi cấp là bắt buộc" }}
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        value={value}
                                        label="Nơi cấp (*)"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onChange={onChange}
                                        size='small'
                                        placeholder='Nơi cấp CCCD/ định danh cá nhân...'
                                        error={!!errors?.employee_idno_issued_by}
                                        helperText={errors?.employee_idno_issued_by?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default EmployeeIdentity