import { format_numeral_price } from '@/functions'
import { Typography, Box } from '@mui/material'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const MailStorage = ({ position }) => {

    const { control } = useFormContext()

    const mailStorageAddon = useWatch({
        control: control,
        name: `product_configoptions.[${position}].product_addons`
    })

    if (!mailStorageAddon) return;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1
        }}>
            {mailStorageAddon?.map((item, index) => {
                if (!item?.selected) return null;
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }} key={index}>
                        <Typography sx={{ color: "#777680" }} component="span">
                            {item?.product_addon_name === "Storage" ? "Dung lượng thêm" : "Backup dữ liệu theo tuần"}:
                            <Typography sx={{
                                ml: 0.5,
                                display: 'inline-flex',
                                color: "#00001F"
                            }}>{format_numeral_price(item?.product_addon_qty)}GB</Typography>
                        </Typography>
                        <Typography component="span">
                            <Typography sx={{ display: 'inline-flex', textDecoration: 'underline', color: "#777680" }}>đ</Typography>
                            {format_numeral_price(parseFloat(item?.product_addon_pricing) * parseFloat(item?.product_addon_qty))}
                        </Typography>
                    </Box>
                )
            })}
        </Box>
    )
}

export default MailStorage