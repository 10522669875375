import { Box, Typography } from '@mui/material'
import { indigo } from '@mui/material/colors'
import React from 'react'
import { Link } from 'react-router-dom'

const InvoiceServices = ({
    services = [],
    description
}) => {
    if (!services || services?.length === 0) {
        return (
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    p: "4px",
                }}
            >
                <Typography sx={{ fontSize: 14 }}>{description}</Typography>
            </Box>
        )
    }
    return (
        <Box
            sx={{
                display: "flex",
                width: "100%",
                flexDirection: { xs: 'column' },
                p: "4px",
                gap: 0.5
            }}
        >
            {services?.map((item) => {
                return (
                    <Link
                        to={`/services/${item?.service_id}`}
                        replace={true}
                        target="_blank"
                    >
                        <Typography
                            component="div"
                            sx={{
                                fontSize: 14,
                                color: "#00001F",
                                ":hover": { textDecoration: "underline", color: indigo[900] }
                            }}>
                            {item?.service_id} - {item?.service_name}
                            {item?.service_extra_fields?.["domain"] && <Typography sx={{
                                display: 'inline-flex',
                                fontSize: 14,
                                ml: 0.5
                            }}>
                                ({item?.service_extra_fields?.["domain"]})
                            </Typography>}
                            {(item?.service_extra_fields?.["ip"] && !item?.service_extra_fields?.["domain"]) && <Typography sx={{
                                display: 'inline-flex',
                                fontSize: 14,
                                ml: 0.5
                            }}>
                                ({item?.service_extra_fields?.["ip"]})
                            </Typography>}
                        </Typography>
                    </Link>
                )
            })}
        </Box>
    )
}

export default InvoiceServices