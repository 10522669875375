import Department from "./Department"
import Locked from "./Locked"
import ScopeApplied from "./ScopeApplied"
import ScopeAssignees from "./ScopeAssignees"
import ScopeModule from "./ScopeModule"
import ScopeName from "./ScopeName"
import ScopeReadable from "./ScopeReadable"
import ScopeWritable from "./ScopeWritable"
import Team from "./Team"
import Company from "./Company"

const ScopeForm = {
    ScopeName,
    ScopeModule,
    ScopeApplied,
    ScopeWritable,
    ScopeReadable,
    ScopeAssignees,
    Company,
    Team,
    Department,
    Locked
}
export default ScopeForm