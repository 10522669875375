import { requestWithToken } from "@/functions";
import { API_SERVICE_URL } from "@/libs/constants";
import dayjs from "dayjs";
import qs from "qs";
import { useQuery } from "react-query";
export function dateToString(date) {
    return dayjs(date)?.format('YYYY-MM-DDTHH:mm:00[Z]')
}


export function resolveHistoricTimeRange(query, range = '') {
    const { gte, lte } = query;

    console.log(gte, lte)

    switch (range) {
        case '7d':
            return {
                gte: dateToString(dayjs(gte).subtract(7, 'days')),
                lte: gte
            }
        case '30d':
            return {
                gte: dateToString(dayjs(gte).subtract(31, 'days')),
                lte: gte
            }
        default:
            return query
    }
}
async function resolveBandwidth(url = '') {
    const response = await requestWithToken(url)

    return response?.data?.data?.bandwidth
}

export function resolveServiceAnalyticBandwidthHistoric(service_uuid, query, range) {
    const current = qs.stringify(query);
    const history = qs.stringify(resolveHistoricTimeRange(query, range))

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery(["customer.services.usages.bandwidth", service_uuid, query, range], () => Promise.all([
        resolveBandwidth(`${API_SERVICE_URL}/v2/services/${service_uuid}/analytic/usages/bandwidth?${current}`),
        resolveBandwidth(`${API_SERVICE_URL}/v2/services/${service_uuid}/analytic/usages/bandwidth?${history}`)
    ]), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        staleTime: 30 * 60 * 1000,
        enabled: typeof service_uuid !== "undefined",
        suspense: true
    })
}

export function resolveServiceAnalyticViewers(service_uuid = '', query) {
    query = qs.stringify(query);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery(["customer.services.viewers", service_uuid, query], async () => {
        const response = await requestWithToken(`${API_SERVICE_URL}/v2/services/${service_uuid}/analytic/usages/viewers?${query}`)

        return response?.data?.data?.viewers?.map((viewer, index) => ({
            ...viewer,
            id: index
        }))
    }, {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        staleTime: 30 * 60 * 1000,
        enabled: typeof service_uuid !== "undefined"
    })
}