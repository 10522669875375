import React from 'react'
import SwiftStoragePrice from "./Price"
import SwiftStoragePackage from "./Package"


const SwiftStoragePack = ({ ...props }) => {

    return (
        <>
            <SwiftStoragePackage serviceId={props?.service_id} price={props?.service_pricing_configure} />
            <SwiftStoragePrice serviceId={props?.service_id} price={props?.service_pricing_configure} />
        </>
    )
}

export default SwiftStoragePack