import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DocumentApi from "@/apis/beta/Document/DocumentApi";
import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import DepartmentApi from "@/apis/beta/Employee/DepartmentApi";
import { LEVEL_DOCUMENT, TYPE_DOCUMENT } from "../List/shared";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { useEmployeeContext } from "@contexts/employee";
import MultipleSelectCustomize from "@components/shared/MultipleSelectCustomize";
import { onAttachmentPushing } from "@/functions";
import { PREFIX_OF_ATTACHMENT } from "@/libs/constants";
import { useNotificationContext } from "@contexts/notification";

const ManagerDocumentCreate = () => {
  const { employee } = useEmployeeContext();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    register,
  } = useForm({
    defaultValues: {
      name_file: "",
      description: "",
      employee_to_manager_document: employee?.employee_id,
      departments: [],
      employee_to_share: [],
      file_path: [],
      department: "",
      start_day: "",
      end_day: "",
      type_document: "Document",
      type: "",
      level: "",
    },
  });

  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync, isLoading: CreateShareDocumentLoading } = useMutation(
    onSubmit,
    {
      onError: () => enqueueSnackbar("Something went wrong"),
    }
  );

  // const preview = usePreviewFileContext();
  const [files, setFiles] = useState([])

  const history = useNavigate();

  const { data: employees, isLoading } = EmployeeApi.ListEmployees();
  const { data: departments } =
    DepartmentApi.ListDepartments();

  const { isLoading: infoLoading, data: info } = useQuery(
    `get-info-${params.id}`,
    () => DocumentApi.FindOneShareDocument(params.id)
  );

  const { setNotification } = useNotificationContext()

  const isFetching =
    infoLoading ||
    CreateShareDocumentLoading ||
    isLoading;

  const disabledButtonSelect = params?.id
    ? false
    : watch("file_path")?.length >= 1
      ? true
      : false;

  function openPopup() {
    const inputEl = document.createElement("input");
    inputEl.type = "file";
    inputEl.click();

    inputEl.onchange = (e) => {
      const file = e.target?.files?.[0];

      if (file) {
        setFiles(prev => [...prev, file])
      }

      inputEl.remove()
    };
  }

  const generateDocumentId = (values) => {
    const year = new Date().getFullYear();
    const department_name = departments?.departments?.find(
      (item) => item?.department_id === values?.department
    )?.department_name;
    const type = values?.type;
    const name_file = values?.name_file;
    const version = !params?.id
      ? "v1"
      : `v${info?.data?.file_version?.length + values?.file_path?.length}`;
    const level = values?.level;

    return `${year}.${department_name?.split(" ")?.length === 1
      ? department_name
      : department_name
        ?.split(" ")
        ?.map((item) => item[0])
        ?.join("")
      }.${type}.${name_file}.${version}.${level}`;
  };

  async function onSubmit(values) {
    const data = {
      ...values,
      document_id: generateDocumentId(values),
      version: !params.id ? "version 1" : `version ${info?.data?.file_version?.length + values?.file_path?.length}`,
      departments: values?.departments?.map((item) => item?.value),
    };

    let filesUploadResponse = []
    let response;

    if (files?.length > 0) {
      filesUploadResponse = await Promise.all(files?.map(async item => await onAttachmentPushing(item, PREFIX_OF_ATTACHMENT.document, setNotification)))
    }

    const file_path = values?.file_path?.map(item => {
      const find = filesUploadResponse?.find(f => f.attachment_file_name === item.path)
      if (find) {
        return {
          ...item,
          path: find?.attachment_file_url,
          version: data?.version
        }
      }

      return item
    })

    if (!params.id) {
      response = await DocumentApi.CreateShareDocument({ values: { ...data, file_path } });
    } else {
      response = await DocumentApi.UpdateShareDocument({
        values: { ...data, id: params?.id, file_path },
      });
    }

    if (response?.errors) {
      return enqueueSnackbar("Something went wrong");
    }

    history("/files/document?type=my-share");

    return enqueueSnackbar("Create share document success");
  }

  function returnNameFile(file_path) {
    const arrayPath = file_path?.split("/");
    return arrayPath?.[arrayPath?.length - 1 || 0];
  }

  useEffect(() => {
    !params.id &&
      register("file_path", {
        required: { value: true, message: "File is required" },
      });
  }, []);

  useEffect(() => {
    if (!info?.data?.data) return;

    setValue("name_file", info?.data?.data.name_file);
    setValue(
      "departments",
      departments?.departments
        ?.filter((item) =>
          info?.data?.data.departments?.includes(item?.department_id)
        )
        ?.map((item) => ({
          label: item?.department_name,
          value: item?.department_id,
        })) || []
    );
    setValue("description", info?.data?.data.description);
    setValue("version", info?.data?.data.version);
    setValue("document_id", info?.data?.data.document_id);
    setValue(
      "employee_to_manager_document",
      info?.data?.data.employee_to_manager_document
    );
    setValue("department", info?.data?.data.department);
    setValue("is_all", info?.data?.data.is_all);
    setValue("type", info?.data?.data.document_type);
    setValue("level", info?.data?.data.level);
    setValue("start_day", new Date(info?.data?.data.start_day));
    setValue("end_day", new Date(info?.data?.data.end_day));
    setValue("type_document", info?.data?.data.type_document);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info?.data?.data, departments?.departments]);

  useEffect(() => {
    if (!files?.length) return;

    setValue("file_path", files?.map(item => ({
      path: item?.name,
      description: "",
    })))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  return (
    <Box
      onSubmit={handleSubmit(values => mutateAsync(values))}
      component="form"
      sx={{
        width: "100%",
        overflow: "auto",
        backgroundColor: "#fff",
        height: "100%",
      }}
    >
      <Box p={2} width="800px" maxWidth="100%" mx="auto">
        <Typography textAlign="center" fontSize={25} fontWeight="600">
          Chia sẻ tài liệu
        </Typography>

        <Box mt={2}>
          <Controller
            name="name_file"
            defaultValue=""
            control={control}
            rules={{
              required: { message: "Name file is required", value: true },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Tên tài liệu (*)"
                error={!!errors.name_file}
                helperText={errors.name_file?.message}
                size="small"
                variant="outlined"
                sx={{ width: "100%" }}
              />
            )}
          />

          <Controller
            name="description"
            defaultValue=""
            control={control}
            rules={{
              required: { message: "Description is required", value: true },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Mô tả (*)"
                error={!!errors.description}
                helperText={errors.description?.message}
                size="small"
                variant="outlined"
                sx={{ width: "100%", mt: 2 }}
              />
            )}
          />

          <Box mt={2}>
            <Typography>Chọn phòng ban để định danh mã tài liệu</Typography>
            <Controller
              name="department"
              defaultValue=""
              control={control}
              rules={{
                required: { message: "Department is required", value: true },
              }}
              render={({ field }) => (
                <FormControl fullWidth>
                  <Select
                    {...field}
                    error={!!errors.department}
                    size="small"
                    variant="outlined"
                    sx={{ width: "100%", mt: 2 }}
                    disabled={isLoading}
                    fullWidth
                  >
                    {departments?.departments?.map((item) => (
                      <MenuItem
                        value={item?.department_id}
                        key={item?.department_id}
                      >
                        {item?.department_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors?.department?.message && (
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      {errors?.department?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Box>

          <Box mt={2}>
            <Typography>Loại hồ sơ</Typography>
            <Controller
              name="type"
              defaultValue=""
              control={control}
              rules={{
                required: { message: "Type is required", value: true },
              }}
              render={({ field }) => (
                <FormControl fullWidth>
                  <Select
                    {...field}
                    size="small"
                    variant="outlined"
                    error={errors?.type}
                    sx={{ width: "100%", mt: 2 }}
                    disabled={isLoading}
                    fullWidth
                  >
                    {TYPE_DOCUMENT?.map((item) => (
                      <MenuItem value={item?.value} key={item?.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors?.type?.message && (
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      {errors?.type?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Box>

          <Box mt={2}>
            <Typography>Mức độ thông tin tài liệu</Typography>
            <Controller
              name="level"
              defaultValue=""
              control={control}
              rules={{
                required: { message: "Level is required", value: true },
              }}
              render={({ field }) => (
                <FormControl fullWidth>
                  <Select
                    {...field}
                    error={!!errors.level}
                    size="small"
                    variant="outlined"
                    sx={{ width: "100%", mt: 2 }}
                    disabled={isLoading}
                    fullWidth
                  >
                    {LEVEL_DOCUMENT?.map((item) => (
                      <MenuItem value={item?.value} key={item?.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors?.level?.message && (
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      {errors?.level?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Box>

          <Box mt={2}>
            <Typography>
              Chọn phòng ban mà bạn muốn chia sẻ tài liệu này
            </Typography>
            <Controller
              rules={{ required: { value: true, message: "Required!" } }}
              control={control}
              defaultValue={[]}
              name="departments"
              render={({ field: { onChange, value } }) => {
                return (
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <MultipleSelectCustomize
                      options={[
                        {
                          label: "Tất cả phòng ban",
                          value: -1,
                        },
                        ...departments?.departments?.map((item) => ({
                          label: item?.department_name,
                          value: item?.department_id,
                        })),
                      ]}
                      value={value}
                      onChange={(_, value) => {
                        if (value?.some((item) => item?.value === -1)) {
                          setValue(
                            "departments",
                            departments?.departments?.map((item) => ({
                              label: item?.department_name,
                              value: item?.department_id,
                            }))
                          );

                          return
                        }

                        onChange(value);
                      }}
                    />

                    {errors?.department?.message && (
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {errors?.department?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            />
          </Box>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box mt={2}>
              <Typography>Chọn ngày bắt đầu ban hành</Typography>
              <Controller
                name="start_day"
                control={control}
                rules={{
                  required: { message: "Start day is required", value: true },
                }}
                render={({ field: { onChange, ...field } }) => (
                  <FormControl sx={{ width: "100%" }}>
                    <DatePicker
                      {...field}
                      onChange={(value) =>
                        value && onChange(new Date(value?.$d))
                      }
                      inputFormat="DD/MM/YYYY"
                      renderInput={({
                        InputLabelProps,
                        InputProps,
                        ...params
                      }) => (
                        <TextField
                          sx={{ mt: 2 }}
                          {...params}
                          fullWidth
                          disabled
                          size="small"
                          variant="outlined"
                          InputProps={{
                            ...InputProps,
                          }}
                        />
                      )}
                    />
                    {errors?.start_day?.message && (
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {errors?.start_day?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Box>
            <Box mt={2}>
              <Typography>Chọn ngày hết hiệu lực ban hành</Typography>
              <Controller
                name="end_day"
                control={control}
                rules={{
                  required: { message: "End day is required", value: true },
                }}
                render={({ field: { onChange, ...field } }) => (
                  <FormControl sx={{ width: "100%" }}>
                    <DatePicker
                      {...field}
                      onChange={(value) =>
                        value && onChange(new Date(value?.$d))
                      }
                      inputFormat="DD/MM/YYYY"
                      renderInput={({
                        InputLabelProps,
                        InputProps,
                        ...params
                      }) => (
                        <TextField
                          sx={{ mt: 2 }}
                          {...params}
                          fullWidth
                          disabled
                          size="small"
                          variant="outlined"
                          InputProps={{
                            ...InputProps,
                          }}
                        />
                      )}
                    />
                    {errors?.end_day?.message && (
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {errors?.end_day?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Box>
          </LocalizationProvider>

          <Box mt={2}>
            <Typography>
              Chọn nhân viên nhân viên quản lí tài liệu này
            </Typography>
            <Controller
              name="employee_to_manager_document"
              defaultValue=""
              control={control}
              rules={{
                required: {
                  message: "Employee manager file is required",
                  value: true,
                },
              }}
              render={({ field }) => (
                <FormControl fullWidth>
                  <Select
                    {...field}
                    error={!!errors.employee_to_manager_document}
                    size="small"
                    variant="outlined"
                    sx={{ width: "100%", mt: 2 }}
                    disabled={isLoading}
                    fullWidth
                  >
                    {employees?.map((item) => (
                      <MenuItem
                        value={item?.employee_id}
                        key={item?.employee_id}
                      >
                        {item?.employee_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors?.employee_to_manager_document?.message && (
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      {errors?.employee_to_manager_document?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Box>

          <Box mt={2}>
            <Typography>Chọn file</Typography>
            <Button
              disabled={isFetching || disabledButtonSelect}
              onClick={openPopup}
              fullWidth
              sx={{ mt: 2 }}
              color="info"
              variant="contained"
              size="small"
            >
              {params?.id
                ? "Thêm phiên bản mới cho tài liệu"
                : "Click để chọn file"}
            </Button>

            {errors?.file_path?.message && (
              <FormHelperText sx={{ color: "#d32f2f" }}>
                {errors?.file_path?.message}
              </FormHelperText>
            )}

            {watch("file_path")?.length > 0 &&
              watch("file_path")?.map((item, index) => (
                <Box key={item}>
                  <Box sx={{ mt: 2 }} display="flex" alignItems="center">
                    <TextField
                      disabled={true}
                      fullWidth
                      size="small"
                      value={returnNameFile(item?.path)}
                    />
                  </Box>
                  <Controller
                    name={`file_path[${index}].description`}
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Mô tả nội dung cập nhật của file nếu có"
                        size="small"
                        variant="outlined"
                        sx={{ width: "100%", mt: 2 }}
                      />
                    )}
                  />
                </Box>
              ))}
          </Box>

          <Button
            disabled={isFetching}
            type="submit"
            sx={{ mt: 4 }}
            size="small"
            variant="contained"
            fullWidth
          >
            {isFetching ? (
              <CircularProgress size={25} color="info" />
            ) : params?.id ? (
              "Update"
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ManagerDocumentCreate;
