import { Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'

const ProductName = () => {

    const { watch } = useFormContext()
    return (
        <Typography sx={{ fontSize: { xs: 18, md: 24 }, color: "#777680" }} component="span">
            Gói dịch vụ đang chọn:

            <Typography sx={{
                display: 'inline-flex',
                fontSize: { xs: 18, md: 24 },
                ml: 0.5,
                color: "#000"
            }}>
                {watch("product_name")}
            </Typography>

        </Typography>
    )
}

export default ProductName