import EmployeeApi from '@/apis/beta/Employee/EmployeeApi'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import TransitionGroups from '@components/shared/TranstionGroups'
import { PersonOutlineOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const Teammates = () => {
    const { control } = useFormContext()
    const { data: employees } = EmployeeApi.ListEmployees()

    return (
        <>
            <Typography sx={{
                fontSize: 14,
                fontWeight: 'medium',
            }}
                color="text.black"
            >
                Truy Xuất Dữ Liệu Của Tập Nhân Viên
            </Typography>
            <Typography sx={{ fontSize: 13 }} color="text.grey">
                Người được chỉ định sẽ được truy cập tệp thông tin của các nhân viên được chọn dưới đây.
            </Typography>
            <Box aria-label='team.teammates'>
                <Controller
                    name="scope_metadata.teammates"
                    control={control}
                    render={({ field: { value = [], onChange } }) => {
                        return (
                            <TransitionGroups
                                values={employees?.filter(employee => value?.includes(employee?.employee_id))}
                                onDelete={(e) => onChange([...value?.filter(x => x !== e?.employee_id)])}
                                label={'employee_name'}
                                icon={<PersonOutlineOutlined fontSize='small' color="primary" />}
                            >
                                <SingleSelectCustomize
                                    clearOnBlur
                                    options={[...employees?.filter(employee => !value?.includes(employee?.employee_id))]}
                                    value={null}
                                    formatCollection={{ label: 'employee_name', value: 'employee_id' }}
                                    compareAttributes={['employee_name', 'employee_email_company']}
                                    input={{
                                        placeholder: "Chọn nhân viên..."
                                    }}
                                    onChange={(_, newValue) => { onChange([...value, newValue?.employee_id]) }}
                                />
                            </TransitionGroups>
                        )
                    }}
                />
            </Box>
        </>
    )
}

export default withSuspense(Teammates, MuiSkeleton['TextField'])