import EmployeeApi from '@/apis/beta/Employee/EmployeeApi'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import MultipleSelectCustomize from '@components/shared/MultipleSelectCustomize'

const Employees = ({ values = [], onChange, scope, ...props }) => {
    const { data: employees, isFetching } = EmployeeApi.All();

    return (
        <MultipleSelectCustomize
            loading={isFetching}
            options={employees}
            value={employees?.filter(option => values?.includes(option?.employee_id)) || []}
            onChange={(event, newValues) => onChange([...newValues?.map(option => option?.employee_id)])}
            formatCollection={{ label: 'employee_name', value: 'employee_id' }}
            compareAttributes={['employee_name', 'employee_email_company']}
            input={{
                ...props?.input,
            }}
            mode='server'
        />
    )
}

export default withSuspense(Employees, MuiSkeleton['TextField'])