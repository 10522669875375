import DriveApi from "@/apis/beta/Drives/DriveApi";
import { useEmployeeContext } from "@contexts/employee";
import { Box, Breadcrumbs, Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import Folder from "./Folder";
import UploadButton from "./Button/UploadButton";
import queryString from "query-string";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import FileListDataGrid from "./FileListDataGrid";
import useOnBeforeUnload from "@/hooks/useOnBeforeUnload";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";

const MASTER_DEPARTMENTS = ["board of directors", "bod", "admin"];
const MASTER_EMPLOYEE_IDS =
  process.env.NODE_ENV === "development" ? [] : [11, 15];

const checkEmployeeHasAccessMaster = (employee) => {
  if (!employee) return false;
  if (
    MASTER_DEPARTMENTS.includes(employee?.employee_department?.toLowerCase())
  ) {
    return true;
  }
  if (MASTER_EMPLOYEE_IDS.includes(employee?.employee_id)) {
    return true;
  }
  return false;
};

const renderBreadcrumbs = ({ path, location, searchParams, employees }) => {
  const removeFirstLast = (str) => {
    return str?.substr(1, str.length - 2);
  };

  path = removeFirstLast(path)?.split("/");

  return path?.map((item, index) => {
    const newPath = path?.filter((_, i) => i <= index)?.join("/");

    return (
      <Link
        style={{ color: "#3b82f6" }}
        key={item}
        to={{
          pathname: location?.pathname,
          search: queryString.stringify({
            ...queryString.parse(searchParams?.toString()),
            path: `/${newPath}/`,
          }),
        }}
      >
        {employees?.find((em) => em?.employee_uuid === item)?.employee_name ||
          item}
      </Link>
    );
  });
};

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { employee } = useEmployeeContext();
  const location = useLocation();

  const { data: employees } = EmployeeApi.All();

  const { data, isFetching, refetch } = useQueryWithCustomized(
    `drives.list.${searchParams?.get("path")}`,
    () => DriveApi.Show({ path: searchParams?.get("path") })
  );

  useEffect(() => {
    if (searchParams?.get("path")) return;
    backToMyDrives();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams?.get("path")]);

  useOnBeforeUnload(() => {});

  const backToMyDrives = () => {
    const DEFAULT_PATH = `/${employee?.employee_uuid}/`;
    const path = employee?.employee_uuid ? DEFAULT_PATH : "/";

    setSearchParams({
      ...queryString.parse(searchParams.toString()),
      path,
    });
  };

  return (
    <Box>
      <Box>
        <Button
          size="small"
          variant="text"
          startIcon={<ArrowBackIosNewOutlinedIcon size={14} />}
          onClick={backToMyDrives}
        >
          My Drives
        </Button>
      </Box>

      <UploadButton
        isFetching={isFetching}
        prefix={data?.data?.objects?.prefix}
        refetch={refetch}
      />
      <Breadcrumbs
        sx={{ backgroundColor: "#fff", mt: 2 }}
        aria-label="breadcrumb"
      >
        {checkEmployeeHasAccessMaster(employee) && (
          <Link style={{ color: "#3b82f6" }} to={`${location.pathname}?path=/`}>
            root
          </Link>
        )}

        {renderBreadcrumbs({
          path: searchParams.get("path"),
          location,
          searchParams,
          employees,
        })}
      </Breadcrumbs>
      <Box mt={2}>
        <>
          {data?.data?.objects?.common_prefixes?.length === 0 &&
            data?.data?.objects?.contents?.length === 0 && (
              <Box
                width="100%"
                height="100%"
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection="column"
                mt={8}
              >
                <img
                  src="https://ssl.gstatic.com/docs/doclist/images/empty_state_empty_folder.svg"
                  alt="empty"
                  width={400}
                />

                <Typography fontSize={16} mt={2}>
                  Empty Folder
                </Typography>
              </Box>
            )}
        </>

        {data?.data?.objects?.shared?.length > 0 && (
          <Box mt={2}>
            <Typography>Thư mục được chia sẻ cho bạn</Typography>
            <Box mt={2}>
              <Grid container spacing={2}>
                {data?.data?.objects?.shared?.map((item) => (
                  <Grid item lg={3} md={4} sm={6} xs={12} key={item?.key}>
                    <Folder
                      refetch={refetch}
                      prefix={data?.data?.objects?.prefix}
                      disabled={isFetching}
                      item={item}
                      employees={employees}
                      shared
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        )}

        <Box>
          {data?.data?.objects?.common_prefixes?.length > 0 && (
            <Box mt={2}>
              <Typography>Thư mục</Typography>
              <Box mt={2}>
                <Grid container spacing={2}>
                  {data.data?.objects?.common_prefixes?.map((item) => (
                    <Grid item lg={3} md={4} sm={6} xs={12} key={item?.key}>
                      <Folder
                        refetch={refetch}
                        prefix={data?.data?.objects?.prefix}
                        disabled={isFetching}
                        item={item}
                        employees={employees}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          )}

          {data?.data?.objects?.contents?.length > 0 && (
            <Box mt={2}>
              <Typography>Tệp</Typography>
              <FileListDataGrid
                data={data}
                isFetching={isFetching}
                prefix={data?.data?.objects?.prefix}
                refetch={refetch}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default withSuspense(
  withPermission(Home, {
    feature: "drives",
    action: "index",
  }),
  MuiSkeleton["DataGrid"]
);
