import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import DataGrid from "@/components/DataGrid";
import {
  buildQueryFilterCondition,
  dayjsCompare,
  format_date_short,
} from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import { SERVICE_STATUS_TYPES } from "@/libs/constants";
import { CellLink, CellStatus } from "@components/DataGrid/Cell";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Popover, Tooltip, Typography } from "@mui/material";
import { indigo } from "@mui/material/colors";
import clsx from "clsx";
import dayjs from "dayjs";
import React from "react";
import { useParams } from "react-router-dom";
import PreviewConfigoptions from "./PreviewConfigoptions";
import Price from "./Price";
import ServicePOC from "./ServicePOC";
import PrivateToolbar from "./Toolbar";

const INITIALIZE_CUSTOMER_SERVICE_QUERIES = [
  `filters[$and][0][tw_services.service_referrer_type][$ne]=Reducer::Override::Service`,
  `filters[$and][0][tw_services.service_create_is_trial][$eq]=0`,
];

const CustomerService = () => {
  const { customer_id } = useParams();

  const [searchQuery, setSearchQuery] = React.useState(
    localStorage.getItem("customers.customer_serivces.references") || ""
  );
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const filterableCustomerService = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";

    const queries = [
      {
        name: "tw_services.service_id",
        operator: "$eq",
        value: parseInt(debouncedSearchQuery),
        disabled: !parseInt(debouncedSearchQuery),
      },
      {
        name: "service_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
    ];
    return buildQueryFilterCondition("$or", 0, queries);
  }, [debouncedSearchQuery]);

  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [columns] = React.useState([
    {
      field: "service_id",
      headerName: "ID",
      width: 60,
      valueGetter: ({ row }) => ({
        label: row?.service_id,
        href: ["services", row?.service_id],
      }),
      renderCell: ({ value }) => (
        <CellLink
          data={value}
          options={{
            target: "_blank",
          }}
        />
      ),
    },
    {
      field: "service_name",
      headerName: "Sản Phẩm",
      width: 300,
    },
    {
      field: "service_domain",
      headerName: "Domain/ IP",
      width: 150,
      valueGetter: ({ row }) => {
        let text = row?.service_extra_fields?.domain;
        if (!text || text === "") {
          text = row?.service_extra_fields?.ip;
        }
        return text;
      },
      renderCell: ({ value, row }) => {
        return (
          <Box sx={{ width: "100%", height: "100%", display: "flex" }}>
            <Typography
              sx={{
                fontSize: 14,
                m: "auto 0",
                textDecoration: "underline",
                cursor: "pointer",
                color: indigo[900],
              }}
              onClick={(event) =>
                setAnchorEl({
                  anchorEl: event?.currentTarget,
                  serviceId: row?.service_id,
                })
              }
            >
              {value}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "sale_employee_ids",
      headerName: "Người Hưởng Hoa Hồng",
      width: 220,
      valueGetter: ({ row }) =>
        row?.service_employee_commissions
          ?.map((e) => e?.employee_name)
          ?.join(", "),
    },
    {
      field: "service_amount",
      width: 200,
      renderHeader: (params) => (
        <Box sx={{ width: "100%", height: "100%" }}>
          <Tooltip title="Dịch vụ SSL/Domain tính theo năm" placement="top">
            <Typography sx={{ fontWeight: "medium", fontSize: 14 }}>
              {"Đơn Giá Bán "}(Theo tháng*)
            </Typography>
          </Tooltip>
        </Box>
      ),
      renderCell: ({ row }) => <Price data={row} column='service_amount' />,
    },
    {
      field: "service_recurring",
      renderHeader: (params) => (
        <Box sx={{ width: "100%", height: "100%" }}>
          <Tooltip title="Dịch vụ SSL/Domain tính theo năm" placement="top">
            <Typography sx={{ fontWeight: "medium", fontSize: 14 }}>
              {"Đơn Giá Gia Hạn "}(Theo tháng*)
            </Typography>
          </Tooltip>
        </Box>
      ),
      width: 220,
      valueGetter: ({ row }) => {
        if (
          row?.service_status !== "active" &&
          row?.service_status !== "payment-pending"
        )
          return ``;
        return row?.service_recurring;
      },
      renderCell: ({ row }) => <Price column='service_recurring' data={row} />,
    },
    {
      field: "services.service_status",
      headerName: "Trạng Thái",
      width: 140,
      valueGetter: ({ row }) =>
        SERVICE_STATUS_TYPES.find((stt) => stt?.value === row?.service_status),
      renderCell: ({ value }) => {
        return <CellStatus component="muiChip" data={value} />;
      },
    },
    {
      field: "service_start_date",
      headerName: "Ngày Bắt Đầu Sử Dụng DV",
      width: 200,
      valueFormatter: ({ value }) => format_date_short(value),
    },
    {
      field: "service_stopped_at",
      headerName: "Ngày Kết Thúc DV",
      width: 200,
      valueFormatter: ({ value }) => value && format_date_short(value),
    },
    {
      field: "contract_timer",
      headerName: "Hiệu Lực Hợp Đồng Hiện Tại",
      minWidth: 210,
      valueGetter: ({ row }) => {
        if (!row?.service_effective_date) return ``;
        return `(${format_date_short(
          row?.service_effective_date
        )} - ${format_date_short(row?.service_expiry_date)})`;
      },
    },
    {
      field: "service_billing",
      headerName: "Chu kỳ thanh toán hiện tại",
      width: 200,
      valueGetter: ({ row }) => {
        if (row?.service_create_is_trial) return `TRIAL`;
        if (!row?.service_billing_startdate || row?.service_create_is_trial)
          return ``;
        return `(${format_date_short(
          row?.service_billing_startdate
        )} - ${format_date_short(row?.service_billing_enddate)})`;
      },
    },
  ]);

  const { data, isFetching } = CustomerApi.CustomerServices({
    customerId: customer_id,
    page: page?.page + 1,
    pageSize: page?.pageSize,
    query: INITIALIZE_CUSTOMER_SERVICE_QUERIES?.join("&"),
    searchQuery: filterableCustomerService(),
  });

  const [anchorEl, setAnchorEl] = React.useState({
    anchorEl: undefined,
    serviceId: undefined,
  });

  const onCloseref = () =>
    setAnchorEl({ anchorEl: undefined, serviceId: undefined });

  return (
    <Box sx={{ mt: "10px" }}>
      <Typography sx={{ fontSize: 18, fontWeight: 'medium' }}>
        Dịch Vụ Chính Thức
      </Typography>
      {Boolean(anchorEl?.anchorEl) && (
        <Popover
          onClose={onCloseref}
          open={Boolean(anchorEl?.anchorEl)}
          anchorEl={anchorEl?.anchorEl}
        >
          <PreviewConfigoptions serviceId={anchorEl?.serviceId} />
        </Popover>
      )}
      <DataGrid
        initialState={{
          pinnedColumns: { right: ["actions"] },
        }}
        rows={
          data?.services?.map((service) => ({
            ...service,
            id: service?.service_id,
            product: `${service?.service_product_group + " - " + service?.service_product
              }`,
          })) || []
        }
        columns={columns}
        loading={isFetching}
        components={{
          Toolbar: PrivateToolbar,
        }}
        componentsProps={{
          toolbar: {
            searchQuery: searchQuery,
            setSearchQuery: setSearchQuery,
          },
          pagination: {
            page: page?.page || 0,
            pageSize: page?.pageSize,
            rowsPerPageOptions: [5, 10, 20],
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        disableSelectionOnClick
        disableColumnFilter
        rowCount={data?.pagination?.total}
        getRowClassName={({ row }) => {
          if (row?.service_status === "trial") return ``;
          if (row?.service_status === "cancel")
            return clsx(`grid-dc-vnetwork`, "row-disabled");
          if (!row?.service_contract) return ``;
          const today = dayjs();
          let classNames = ``;
          if (
            dayjsCompare(row?.service_contract?.expiry_date, today, "d") <= 14
          ) {
            classNames = `row-warning`;
          }
          return clsx(`grid-dc-vnetwork`, classNames);
        }}
      />
      <ServicePOC />
    </Box>
  );
};
export default withSuspense(
  withPermission(CustomerService, {
    feature: "customer",
    action: "customer-service-index",
  }),
  MuiSkeleton["DataGrid"]
);
