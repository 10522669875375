import CustomerApi from '@/apis/beta/Customer/CustomerApi'
import { format_date_short, sleep } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import { COUNTRIES } from "@/libs/constants"
import Location from '@components/Location'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { useNotificationContext } from '@contexts/notification'
import { Box, Button, Chip, CircularProgress, Drawer, FormControl, Paper, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

const ModalAddressInformation = ({
    onClose
}) => {
    const { setNotification } = useNotificationContext()
    const [submit, setSubmit] = React.useState(false)
    const { customer_id } = useParams()

    const { data: { customer_address_information: address }, refetch: callback } = CustomerApi.Detail(customer_id)

    const { handleSubmit, control, watch, formState: { errors } } = useForm({
        defaultValues: {
            country: address?.country || "VN",
            province: address?.province || "",
            district: address?.district || "",
            ward: address?.ward || "",
            address: address?.address || "",
            zipcode: address?.zipcode || ""
        }
    })

    const onSubmit = async (values) => {
        setSubmit(true)

        const response = await CustomerApi.UpdateAddressInformation({
            customerId: customer_id,
            data: { ...values }
        })
        await sleep(500)
        setSubmit(false)
        if (!response || response?.errors) {
            return setNotification({
                open: true,
                message: response?.errors?.[0]?.message || "Failed.",
                severity: "error"
            })
        }
        try {
            callback()
        } catch (error) {
            console.log(error?.message)
        }
        onClose()
        return setNotification({
            open: true,
            message: "Cập nhật thông tin khách hàng thành công.",
            severity: "success"
        })
    }
    return (
        <Drawer
            open={true}
            anchor="right"
            sx={{ width: { xs: '100%', md: 600 } }}>
            <Paper sx={{ width: '100%', p: 3, height: { xs: 'auto', md: '100vh' } }}
                component="form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column-reverse', md: 'column' },
                    justifyContent: 'space-between',
                    mb: 2
                }}>
                    <Typography sx={{
                        fontSize: { xs: 16, md: 18 },
                        fontWeight: "medium",
                    }}>
                        Thông Tin & Địa Chỉ Khách Hàng
                    </Typography>
                    <Chip
                        sx={{ m: 'auto 0 auto auto' }}
                        size='small'
                        variant='customize-primary'
                        label={`Cập nhật lần cuối: ${format_date_short(address?.updated_at)}`}
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: "column", gap: 2 }}>
                    <Controller
                        name="country"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            const selected = COUNTRIES.find(x => x.value === value) || null
                            return (
                                <Location.Country
                                    value={selected}
                                    onChange={(event, newValue) => onChange(newValue?.value || "")}
                                    error={{ isError: !!errors?.country, message: errors?.country?.message }}
                                />
                            )
                        }} />
                    <Controller
                        name="province"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Location.Province
                                    preSelected={value}
                                    country={watch(`country`)}
                                    onChange={(event, newValue) => onChange(newValue?.value || "")}
                                    error={{ isError: !!errors?.province, message: errors?.province?.message }}
                                />
                            )
                        }} />
                    <Controller
                        name="district"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Location.District
                                    preSelected={value}
                                    province={watch(`province`)}
                                    onChange={(event, newValue) => onChange(newValue?.value || "")}
                                    error={{ isError: !!errors?.district, message: errors?.district?.message }}
                                />
                            )
                        }} />
                    <Controller
                        name="ward"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Location.Ward
                                    preSelected={value}
                                    province={watch(`province`)}
                                    district={watch(`district`)}
                                    onChange={(event, newValue) => onChange(newValue?.value || "")}
                                    error={{ isError: !!errors?.ward, message: errors?.ward?.message }}
                                />
                            )
                        }} />
                    <Controller
                        name="address"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Location.Address
                                    value={value}
                                    onChange={onChange}
                                    error={{ isError: !!errors?.address, message: errors?.address?.message }}
                                />
                            )
                        }} />
                    <Controller
                        name="zipcode"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        label="Zipcode"
                                        InputLabelProps={{ shrink: true }}
                                        placeholder='Nhập zipcode...'
                                        size='small'
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors?.zipcode}
                                        helperText={errors?.zipcode?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Box>
                <Box sx={{
                    mt: 3,
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { xs: 1.5, md: 2 }
                }}>
                    <Button size='small'
                        variant='outlined'
                        disabled={submit}
                        onClick={onClose}
                    >
                        Huỷ Thao Tác
                    </Button>
                    <Button size='small' variant='contained' type="submit"
                        disabled={submit}
                        startIcon={submit && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                    >
                        Lưu Thay Đổi
                    </Button>
                </Box>
            </Paper>
        </Drawer>
    )
}

export default
    withSuspense(ModalAddressInformation, MuiSkeleton["LinearProgress"])
