import { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import PaymentType from "./PaymentType";
import { Box, Collapse } from "@mui/material";
import PricingApply from "./PricingApply";
import ProductPricingMinimum from "./ProductPricingMinimum";
import ProductPricingInit from "./ProductPricingInit";

const Payment = ({ isPoc = false }) => {
  const { watch } = useFormContext();

  return (
    <Fragment>
      {!isPoc && <PaymentType />}
      <Collapse
        in={
          (!watch(`product_customizable`) && !isPoc) ||
          (watch(`product_template`) === "VNIS" && !isPoc)
        }
      >
        <Box
          sx={{
            mt: 1.5,
            mb: 1.5,
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
          }}
        >
          <ProductPricingInit />
          <ProductPricingMinimum />
        </Box>
      </Collapse>
      <Collapse
        in={
          (!watch(`product_customizable`) && !isPoc) ||
          (watch(`product_template`) === "VNIS" && !isPoc)
        }
      >
        <PricingApply />
      </Collapse>
    </Fragment>
  );
};

export default Payment;
