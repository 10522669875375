import { Box, FormControl, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import FORM_BASE from '../form'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import { AddOutlined, DeleteOutlineOutlined } from '@mui/icons-material'
import { UNLIMITED } from '@/libs/constants'

const TablePrices = ({ label, name, attributes = [], ...props }) => {
    const { control } = useFormContext()
    const { fields, append, remove } = useFieldArray({
        control, name
    })

    const steps = useWatch({ control, name })
    const addValues = () => {
        let hash = {}
        for (let i = 0; i < attributes?.length; i++) {
            hash[attributes[i]?.name] = ''
        }
        append({ ...hash, start: steps?.[steps?.length - 1]?.end, end: UNLIMITED })
    }

    return (
        <>
            <Typography sx={{ fontSize: 14, mb: 0.5 }} color="primary">
                {label}
            </Typography>
            <Box sx={{ p: "24px 16px 16px 16px", border: '1px solid #333', ...props?.styled }}>
                {fields?.map((field, index) => {
                    return (
                        <Grid container spacing={2} key={field?.id} sx={{ mb: 1.5 }}>
                            {attributes?.map((attribute, subIndex) => {
                                const Component = FORM_BASE[attribute?.component]
                                if (!Component) return null

                                return (
                                    <Grid item xs={12} md={attribute?.cols?.md || 12} key={subIndex}>
                                        <Controller
                                            name={`${name}.[${index}].${attribute?.name}`}
                                            control={control}
                                            render={({ field: { value, onChange }, fieldState: { error } }) => {
                                                return (
                                                    <FormControl fullWidth>
                                                        <Component value={value} onChange={onChange} {...attribute?.params} />
                                                        {!!error?.message && <ErrorHelperText message={error?.message} />}
                                                    </FormControl>
                                                )
                                            }}
                                        />
                                    </Grid>
                                )
                            })}
                            <Grid item xs={12} md={3} >
                                {index !== 0 && <Box display="flex" justifyContent="flex-end" alignItems="center">
                                    <IconButton size='small' sx={{ border: '1px solid #777680', mt: '4px' }} onClick={() => remove(index)}>
                                        <DeleteOutlineOutlined color="error" fontSize="small" />
                                    </IconButton>
                                </Box>}
                            </Grid>
                        </Grid>
                    )
                })}
                <Box sx={{ mt: 1.25, display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton size="small" onClick={addValues}>
                        <AddOutlined fontSize="small" color="primary" />
                    </IconButton>
                </Box>
            </Box>
        </>
    )
}

export default TablePrices