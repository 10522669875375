import { TextField } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const DateTimePicker = ({
    onChange,
    value,
    disabled,
    ...props
}) => {
    const date = moment(value).format("YYYY-MM-DD HH:mm")

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MuiDateTimePicker
                {...props}
                disabled={disabled}
                inputFormat="DD/MM/YYYY HH:mm"
                InputProps={{
                    type: 'date',
                    sx: {
                        fontSize: 14,
                        height: 40,
                        bgcolor: "#FFF"
                    }
                }}
                onChange={(newValue) => {
                    onChange(moment(newValue?.["$d"])?.format("YYYY-MM-DD HH:mm"))
                }}
                value={date}
                renderInput={(params) => <TextField {...params}
                    label={props?.label || null}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                />}
            />
        </LocalizationProvider>
    )
}

export default DateTimePicker