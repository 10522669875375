import { truncate } from "@/functions"
import { CUSTOMER_JOURNEYS } from "@/libs/constants"
import { ErrorOutlineOutlined } from "@mui/icons-material"
import { Box, Fade, Tooltip, Typography } from '@mui/material'
import moment from "moment"
import React from 'react'
import { Link } from 'react-router-dom'

const TooltipOverlay = ({ step, status }) => {
    return (
        <Box sx={{ opacity: 1 }}>
            <Typography sx={{ fontSize: 14 }}>{CUSTOMER_JOURNEYS.find(s => s.value === step)?.label}</Typography>
        </Box>
    )
}

const Weekly = ({
    data
}) => {
    if (data.disabled || !data?.customerJourney) {
        return <></>
    }
    const handleGetUrl = () => {
        let redirect = ["", "customers", data?.customerId]
        if (data?.customerJourney && data.customerJourney?.customer_journeyable_type === "CustomerJourney") {
            redirect.push(...["customer-journeys", data.customerJourney?.customer_journeyable_id, "upsales", data.customer_journey_id])
        } else {
            redirect.push("customer-journey")
        }
        try {
            const regex = new RegExp(/[1-9]/)
            const week = data?.c_journey_week_title.split(" ")?.at(-1)?.match(regex)
            if (week && week.length > 0) {
                return redirect.join("/") + `?week=${parseInt(week[0])}`
            }
            return redirect.join("/") + `?week=${parseInt(data?.c_journey_week_title)}`
        } catch (error) {

            return redirect.join("/")
        }
    }

    return (
        <Box sx={{
            padding: "4px 8px",
            height: '100%',
            width: '100%',
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
        }}>
            <Box sx={{
                m: 'auto 0',
                width: '100%',
                display: 'flex',
                gap: 0.5
            }}>
                {!!data?.c_journey_week_step && <React.Fragment>
                    <Tooltip arrow
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 500 }}
                        title={<TooltipOverlay step={data?.c_journey_week_step}
                            status={data?.c_journey_week_step_status}
                        />}
                        placement="top">
                        <Link to={handleGetUrl()} replace target="_blank">
                            <Typography sx={{
                                fontSize: 14,
                                m: 'auto 0',
                                "&:hover": {
                                    color: "#1d237f",
                                    textDecoration: "underline"
                                }
                            }}>
                                {truncate(CUSTOMER_JOURNEYS.find(t => t.value === data?.c_journey_week_step)?.label, 30)}
                            </Typography>
                        </Link>
                    </Tooltip>
                </React.Fragment>}
                {!data?.c_journey_week_step && <React.Fragment>
                    {(data.index === 1 || (data.index === 2 && !["Monday", "Tuesday"].includes(moment().format('dddd')))) && <></>}
                    {(data.index === 3 || data.index === 4) &&
                        <Link to={handleGetUrl()} replace target="_blank">
                            <Typography
                                color="primary"
                                sx={{
                                    fontSize: 14,
                                    m: 'auto'
                                }}>Cập nhật</Typography>
                        </Link>}
                    {(data.index === 2 && ["Monday", "Tuesday"].includes(moment().format("dddd"))) && (
                        <React.Fragment>
                            <Tooltip title="Vui lòng cập nhật thông tin trước ngày thứ 3 mỗi tuần">
                                <ErrorOutlineOutlined
                                    fontSize="small"
                                    color="warning"
                                    sx={{ m: 'auto 0' }}
                                />
                            </Tooltip>
                            <Link to={handleGetUrl()} replace target="_blank">
                                <Typography
                                    color="warning"
                                    sx={{
                                        fontSize: 14,
                                        display: "inline",
                                    }}>Cập nhật thông tin</Typography>
                            </Link>
                        </React.Fragment>
                    )}
                </React.Fragment>}
            </Box>
        </Box>
    )
}

export default Weekly