import BareMetalServer from "./BareMetalServer";
import CDN from "./CDN";
import CloudServer from "./CloudServer";
import Domain from "./Domain";
import EG from "./EG";
import EmailServer from "./EmailServer";
import Hosting from "./Hosting";
import Support from "./Support";
import SwiftFederation from "./SwiftFederation";
import Vnis from "./Vnis";
export const Templates = {
  "Cloud Server": CloudServer,
  "Dedicated Server": CloudServer,
  Colocation: CloudServer,
  Hosting: Hosting,
  "Hosting Linux": Hosting,
  "Hosting Windows": Hosting,
  "Hosting CDN": Hosting,
  "Email Server": EmailServer,
  "Email V Pro": EmailServer,
  "Email V Business": EmailServer,
  "Email V Premium": EmailServer,
  "Bare Metal Server": BareMetalServer,
  VNIS: Vnis,
  Addon: Support,
  Other: Support,
  Domain: Domain,
  Support: Support,
  "Content Delivery Network": CDN,
  Trancoding: SwiftFederation,
  Transmuxing: SwiftFederation,
  Recording: SwiftFederation,
  Storage: SwiftFederation,
  "Mail Cloud Security": EG,
  "Receive Guard": EG,
  Default: null,
};
