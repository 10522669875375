import { BUSINESS_PRIVATE_TYPES, BUSINESS_TYPES } from "@/libs/constants";
import { useEmployeeContext } from "@contexts/employee";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

const BusinessType = ({ locked = false }) => {
  const { employee } = useEmployeeContext();

  const { control } = useFormContext();

  const businessCollections = useMemo(() => {
    if (
      BUSINESS_PRIVATE_TYPES?.split(",")?.includes(
        employee?.employee_email_company
      )
    ) {
      return BUSINESS_TYPES;
    }
    if (
      ["sales", "sale", "customercare", "account", "accountant"]?.includes(
        employee?.employee_department?.toLowerCase()
      )
    ) {
      return [BUSINESS_TYPES[0], BUSINESS_TYPES[2]];
    }
    return BUSINESS_TYPES;
  }, [employee]);

  return (
    <Controller
      control={control}
      name="business_type"
      render={({ field: { value, onChange } }) => (
        <FormControl fullWidth>
          <RadioGroup
            value={value}
            onChange={(event) => onChange(event?.target?.value)}
            sx={{
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 0, md: 2 },
            }}
          >
            {businessCollections.map((type) => (
              <FormControlLabel
                disabled={locked && type?.value !== 'meeting'}
                key={type?.value}
                {...type}
                control={<Radio size="small" />}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};

export default BusinessType;
