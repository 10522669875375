import DatePicker from '@components/datepickers/DateRangPicker'
import { FormControl } from '@mui/material'
import dayjs from 'dayjs'
import { useFormContext } from 'react-hook-form'

const Dates = ({
    index = -1
}) => {

    const { watch, setValue } = useFormContext()

    const onChangeDates = (values = []) => {
        setValue(`service_configoptions.[${index}].commitment.startdate`, values?.[0])
        setValue(`service_configoptions.[${index}].commitment.enddate`, values?.[1])
    }
    return (
        <FormControl fullWidth>
            <DatePicker
                value={[
                    dayjs(watch(`service_configoptions.[${index}].commitment.startdate`)),
                    dayjs(watch(`service_configoptions.[${index}].commitment.enddate`))]}
                handler={onChangeDates}
                localeText={{
                    start: `Ngày bắt đầu hiệu lực`,
                    end: `Ngày kết thúc gói hiện tại`
                }}
            />
        </FormControl>
    )
}

export default Dates