import { format_date_short, format_numeral_price } from '@/functions'
import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from '@mui/material'
import _ from 'lodash'

const Preview = ({ lines = [] }) => {
    return (
        <Paper sx={{ p: 3 }}>
            <Typography sx={{ fontSize: 14, fontWeight: 'medium', mb: 2 }}>
                Preview of Service Usage
            </Typography>
            <TableContainer sx={{
                "& .MuiTableCell-root": {
                    borderLeft: "1px solid rgba(224, 224, 224, 1)",
                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                },
                "& .MuiTableHead-root": {
                    borderTop: "1px solid rgba(224, 224, 224, 1)"
                }
            }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>STT</TableCell>
                            <TableCell>Dịch Vụ CDN</TableCell>
                            <TableCell>Mô Tả</TableCell>
                            <TableCell>Thông Số Sử Dụng</TableCell>
                            <TableCell>Đơn Giá</TableCell>
                            <TableCell>Chu Kỳ</TableCell>
                            <TableCell>Phí Dịch Vụ</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lines?.map((item, index) => {
                            if (item?.invoice_items?.length === 0) return null;

                            return item?.invoice_items?.map((invoiceItem, itemIndex) => {
                                if (invoiceItem?.items?.length === 0) return null;

                                return invoiceItem?.items?.map((line, lineIndex) => {
                                    return (
                                        <TableRow key={lineIndex}>
                                            {lineIndex === 0 && <TableCell rowSpan={invoiceItem?.counting}
                                                sx={{ textAlign: 'center' }}
                                            >
                                                {itemIndex + 1 + _.sumBy(lines?.slice(0, index), (line) => line?.row_count)}
                                            </TableCell>}
                                            {lineIndex === 0 && <TableCell rowSpan={invoiceItem?.counting}>
                                                {invoiceItem?.name}
                                            </TableCell>}
                                            <TableCell>{line?.invoice_item_description}</TableCell>
                                            <TableCell sx={{
                                                textAlign: 'right'
                                            }}>
                                                {line?.invoice_item_value}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    textAlign: 'right'
                                                }}
                                            >
                                                {parseFloat(line?.invoice_item_pricing_apply) > 0 ? format_numeral_price(line?.invoice_item_pricing_apply) : ''}
                                            </TableCell>
                                            {itemIndex === 0 && lineIndex === 0 && <TableCell rowSpan={_.sumBy(item?.invoice_items, (item) => item?.counting)}>
                                                {format_date_short(line?.invoice_item_startdate)} {" - "}
                                                {format_date_short(line?.invoice_item_enddate)}
                                            </TableCell>}
                                            <TableCell sx={{
                                                textAlign: 'right'
                                            }}>
                                                {line?.invoice_item_payment_desposit ?
                                                    `(${format_numeral_price(line?.invoice_item_amount)})`
                                                    : line?.invoice_item_amount > 0 ? format_numeral_price(line?.invoice_item_amount) : ''}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            })

                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={6} sx={{
                                textAlign: 'center',
                                fontSize: 14,
                            }}>
                                Tạm Tính
                            </TableCell>
                            <TableCell colSpan={1}
                                sx={{
                                    textAlign: 'right',
                                    fontSize: 14,
                                    color: '#00001F'
                                }}>
                                {format_numeral_price(_.sumBy(lines, (line) => {
                                    return _.sumBy(line?.invoice_items, (item) => {
                                        return _.sumBy(item?.items, (e) => e?.invoice_item_amount)
                                    })
                                }))}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={6} sx={{
                                textAlign: 'center',
                                fontSize: 14,
                            }}>
                                VAT 10%
                            </TableCell>
                            <TableCell colSpan={1} sx={{
                                textAlign: 'right',
                                fontSize: 14,
                                color: '#00001F',
                            }}>
                                {format_numeral_price(_.sumBy(lines, (line) => {
                                    return _.sumBy(line?.invoice_items, (item) => {
                                        return _.sumBy(item?.items, (e) => {
                                            if (!e?.invoice_item_tax) {
                                                return 0
                                            }
                                            return e?.invoice_item_amount * 0.1
                                        })
                                    })
                                }))}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={6} sx={{
                                fontWeight: 'medium',
                                textAlign: 'center',
                                fontSize: 14,
                                color: '#00001F'
                            }}>
                                Tổng Tiền
                                <Typography sx={{ fontSize: 13 }}>
                                    Bằng Chữ: Render to words...
                                </Typography>
                            </TableCell>
                            <TableCell colSpan={1} sx={{
                                textAlign: 'right',
                                fontSize: 14,
                                color: '#00001F',
                                fontWeight: 'medium'
                            }}>
                                {format_numeral_price(_.sumBy(lines, (line) => {
                                    return _.sumBy(line?.invoice_items, (item) => {
                                        return _.sumBy(item?.items, (e) => {
                                            if (!e?.invoice_item_tax) {
                                                return e?.invoice_item_amount
                                            }
                                            return (e?.invoice_item_amount * 0.1) + e?.invoice_item_amount
                                        })
                                    })
                                }))}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Paper>
    )
}

export default Preview