import React from 'react';
import { Box, Typography } from '@mui/material';

const Developer = () => {
  return (
    <Box>
      <Typography sx={{ fontSize: 14, color: 'grey.700' }}>
        Helper Developer
      </Typography>
    </Box>
  );
};

export default Developer;
