import { format_date_short } from "@/functions";
import { CONTRACT_NUMBER_STATUS_TYPES, INDUSTRIES } from "@/libs/constants";
import { CellLink, CellPricing, CellStatus } from "@components/DataGrid/Cell";
import _ from "lodash";

const INITIAL_COLUMNS = [
    {
        field: "sale_tracking_id",
        headerName: "SALE Phụ Trách",
        minWidth: 260,
    }, {
        field: "customer_industry",
        headerName: "Lĩnh Vực",
        width: 180,
    }, {
        field: "customer_created_at",
        headerName: "Ngày Tạo KH",
        width: 150,
        renderCell: ({ value }) => !!value && format_date_short(value)
    }, {
        field: "service_id",
        headerName: "Dịch Vụ",
        width: 300,
        valueGetter: ({ row }) => {
            if (!row?.service_id) return null;
            return ({
                label: `${row?.service_id} - ${row?.service_name}`,
                href: ["services", row?.service_id],
            })
        },
        renderCell: ({ value }) => value && <CellLink data={value} />,
    }, {
        field: "service_start_date",
        headerName: "Ngày Bắt Đầu Sử Dụng",
        width: 180,
        renderCell: ({ value }) => !!value && format_date_short(value)
    }, {
        field: "contract_value",
        headerName: "Giá Trị Hợp Đồng",
        width: 180,
        renderCell: ({ value }) => !!value && <CellPricing price={value} />
    }, {
        field: "contract_expiry_date",
        headerName: "Hiệu Lực Hợp Đồng",
        width: 350,
        valueGetter: ({ row }) => {
            if (!row?.contract_number_id) return null;
            let label = row?.contract_number_id
            label += ` (${format_date_short(row?.contract_effective_date)} - ${format_date_short(row?.contract_expiry_date)})`
            return {
                label,
                href: ['customer_contracts', row?.contract_id]
            }
        },
        renderCell: ({ value }) => !!value && <CellLink data={value} />
    }, {
        field: "contract_status",
        headerName: "Trạng Thái HĐ",
        width: 150,
        valueGetter: ({ value }) => {
            return CONTRACT_NUMBER_STATUS_TYPES?.find(x => x.value === value)
        },
        renderCell: ({ value }) => !!value && <CellStatus component="muiChip" data={value} />
    },
]


const buildCustomerActives = ({ customers = [], employees = [] }) => {
    let actives = []
    _.each(customers, (customer) => {
        const services = customer?.services
        const service = services[0]
        actives.push({
            hierarchy: [customer?.customer_id],
            customer_id: customer?.customer_id,
            customer_name: customer?.customer_name?.toUpperCase(),
            customer_company_name: customer?.customer_company_name?.toUpperCase(),
            sale_tracking_id: employees?.filter((employee) => service?.employee_sale_ids?.includes(employee?.employee_id))?.map((employee) => employee?.employee_name)?.join(', '),
            customer_industry: customer?.customer_industry?.toUpperCase(),
            customer_created_at: customer?.customer_created_at,
            service_id: service?.service_id,
            service_name: service?.service_name,
            service_start_date: service?.service_start_date,
            contract_id: service?.contract_id,
            contract_value: service?.service_contract_value,
            contract_number_id: service?.service_contract_number_id,
            contract_effective_date: service?.service_contract_effective_date,
            contract_expiry_date: service?.service_contract_expiry_date,
            contract_status: service?.contract_status
        })
        if (!!services && services?.length) {
            services?.slice(1)?.forEach((s) => {
                actives.push({
                    hierarchy: [customer?.customer_id, s?.service_id],
                    customer_id: '',
                    customer_name: '',
                    customer_company_name: '',
                    sale_tracking_id: employees?.filter((employee) => s?.employee_sale_ids?.includes(employee?.employee_id))?.map((employee) => employee?.employee_name)?.join(', '),
                    customer_industry: customer?.customer_industry?.toUpperCase(),
                    customer_created_at: customer?.customer_created_at,
                    service_id: s?.service_id,
                    service_name: s?.service_name,
                    service_start_date: s?.service_start_date,
                    contract_id: service?.contract_id,
                    contract_value: s?.service_contract_value,
                    contract_number_id: s?.service_contract_number_id,
                    contract_effective_date: s?.service_contract_effective_date,
                    contract_expiry_date: s?.service_contract_expiry_date,
                    contract_status: s?.contract_status
                })
            })
        }
    })
    return actives
}


const buildFilterables = ({ employees = [], productGroups = [] }) => {
    return [
        {
            field: "personal_informations.name",
            label: "Tên Khách Hàng",
            operators: { type: "string" }
        }, {
            field: "customer_service_informations.customer_sale_followers",
            label: "SALE Phụ Trách",
            operators: {
                type: "object",
                operators: ["$included"],
                collections: employees?.map((employee) => ({ value: employee?.employee_id, label: employee?.employee_name })),
            },
        }, {
            field: "customer_service_informations.customer_industry",
            label: "Lĩnh Vực",
            operators: { type: "object", collections: INDUSTRIES },
        }, {
            field: "customers.customer_created_at",
            label: "Ngày Tạo Khách Hàng",
            operators: { type: "date" },
        }
    ]
}
const CustomerActiveLibs = {
    columns: INITIAL_COLUMNS,
    rows: buildCustomerActives,
    filterables: buildFilterables
}
export default CustomerActiveLibs;