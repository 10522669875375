import { INITIAL_OPERATORS } from '@/libs/constants'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { FormControl } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export const buildConditionQueries = (queryWith = 'object') => {
    switch (queryWith) {
        case 'object':
        case 'number':
            return ['$in', '$nin', '$eq', '$ne']
        case 'string':
            return ['$contains', '$notContains']
        case "date":
        case "price":
            return ["$btw", "$gte", "$lte", "$eq"]
        default:
            break;
    }
}

const Column = ({
    attributes = [],
}) => {
    const { control, setValue } = useFormContext()

    const onChangeColumn = (column) => {
        let queries = []
        if (column?.operators?.operators && column.operators?.operators?.length > 0) {
            queries = [...column.operators.operators]
        } else {
            queries = buildConditionQueries(column.operators?.type || 'string')
        }
        const operators = INITIAL_OPERATORS.filter(operator => queries?.includes(operator?.operator)) || []

        setValue(`input.type`, column?.operators?.type || 'string')
        setValue(`input.collections`, column?.operators?.collections || [])
        setValue(`input.mode`, column?.operators?.mode || 'client')
        setValue(`input.scope`, column?.operators?.scope || 'Default')
        setValue(`operator.operator`, operators[0]?.operator)
        if (column?.operators?.type === 'object') {
            setValue(`input.string`, [])
        } else {
            setValue(`input.string`, '')
        }
        return setValue(`operator.collections`, operators)
    }

    return (
        <Controller
            name="fieldSelect"
            control={control}
            render={({ field: { value, onChange } }) => {
                const selected = attributes?.find(attribute => attribute?.field === value) || null
                return (
                    <FormControl fullWidth>
                        <SingleSelectCustomize
                            value={selected}
                            options={attributes}
                            formatCollection={{
                                value: 'field',
                                label: 'label'
                            }}
                            onChange={(_, newValue) => {
                                onChangeColumn(newValue)
                                onChange(newValue?.field || "")
                            }}
                            input={{
                                label: 'Cột *',
                                placeholder: 'Chọn cột tìm kiếm...'
                            }}
                        />

                    </FormControl>
                )
            }}
        />
    )
}

export default Column