import { getAttachmentIcon } from "@/functions";
import { ATTACHMENT_ICONS } from "@/libs/constants";
import { formatFileSize } from "@features/Module/Drives/shared";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";

const SingleDocument = ({
  document = {},
  handleDelete,
  handleOpen,
  ...props
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        mt: 1,
        justifyContent: "space-between",
        p: 1,
        border: "1px solid #9e9e9e",
        borderRadius: "4px",
        ...props?.sx,
      }}
    >
      <IconButton
        sx={{ width: 42, height: 42, bgcolor: "#78909C1F", mr: 1.5 }}
        onClick={handleOpen || undefined}
      >
        {React.createElement(
          ATTACHMENT_ICONS[getAttachmentIcon(document?.type)],
          {
            sx: { fontSize: 24 },
            color: "primary",
          }
        )}
      </IconButton>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: `calc(100% - 54px)`,
        }}
      >
        <Box>
          <Typography
            onClick={handleOpen || undefined}
            sx={{ cursor: "pointer", fontSize: 14 }}
          >
            {document?.name}
          </Typography>
          <Typography sx={{ fontSize: 13, color: "#757575" }}>
            {formatFileSize(document?.size)}
          </Typography>
        </Box>
      </Box>
      {handleDelete !== undefined && (
        <IconButton
          size="small"
          onClick={handleDelete}
          sx={{ m: "auto 0", height: 32, width: 32 }}
        >
          <DeleteOutlineOutlined fontSize="small" color="error" />
        </IconButton>
      )}
    </Box>
  );
};

export default SingleDocument;
