import { Grid, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import CustomerContact from "../../Create/BasicInformation/shared/Contact";
import CustomerDob from "../../Create/BasicInformation/shared/CustomerDob";
import CustomerEmail from "../../Create/BasicInformation/shared/CustomerEmail";
import CustomerName from "../../Create/BasicInformation/shared/CustomerName";
import CustomerPhone from "../../Create/BasicInformation/shared/CustomerPhone";
import CustomerPosition from "../../Create/BasicInformation/shared/CustomerPosition";

const CustomerInformation = () => {
  const { control } = useFormContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <CustomerName />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomerPosition />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomerEmail />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomerPhone />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomerDob />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomerContact />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ mb: 0.5, color: "#777680", fontSize: 14 }}>
          Ghi Chú
        </Typography>
        <Controller
          name="customer.customer_note"
          control={control}
          render={({ field }) => (
            <TextField
              multiline
              {...field}
              placeholder="Nhập ghi chú về khách hàng..."
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
export default CustomerInformation;
