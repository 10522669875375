import { isEmail } from 'validator';
import { create, enforce, test } from "vest";
// eslint-disable-next-line no-undef
enforce.extend({ isEmail })

const orderValidator = create((data = {}) => {
    test("customer_id", "Vui lòng chọn chủ sở hữu cho hợp đồng này.", () => {
        enforce(parseInt(data?.customer_id) > 0).isTruthy()
    })
})

export default orderValidator;
