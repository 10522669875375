import EmployeeApi from '@/apis/beta/Employee/EmployeeApi'
import { buildQueryFilterCondition } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import useDebounce from '@/hooks/useDebounce'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { FormControl } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { SelectionUIs } from '..'

const Employee = ({
    operation = '$eq',
    value,
    onChange,
    initQueries = null
}) => {

    const [searchQuery, setSearchQuery] = useState('')
    const debouncedSearchQuery = useDebounce(searchQuery, 500)

    const filterEmployee = useCallback(() => {
        if (!debouncedSearchQuery) return null;
        let queries = [
            {
                name: "employee_name",
                operator: "$contains",
                value: debouncedSearchQuery,
            }, {
                name: "employee_email_company",
                operator: "$contains",
                value: debouncedSearchQuery,
            }
        ]
        if (!!value && typeof value === 'object') {
            queries.push({
                name: "employee_id",
                operator: "$in",
                value: value?.value,
                disabled: !value?.value,
            })
            const employeeIds = value?.map(x => x?.value)
            queries.push({
                name: "employee_id",
                operator: "$in",
                value: employeeIds.join(","),
                disabled: !employeeIds || !employeeIds.length,
            })
        }
        return buildQueryFilterCondition("$or", 0, queries)
    }, [debouncedSearchQuery, value])

    const { data: { employees }, isFetching } = EmployeeApi.List({
        page: 1,
        pageSize: typeof value === 'object' ? 15 : (value?.length + 15),
        query: initQueries,
        searchQuery: filterEmployee(),
    })

    return (
        <FormControl fullWidth>
            {React.createElement(SelectionUIs[operation], {
                onChange: (_, employee) => onChange(employee || null),
                isLoading: isFetching,
                mode: "server",
                value,
                options: employees?.map(employee => ({
                    value: employee?.employee_id,
                    label: employee?.employee_name
                })),
                input: {
                    placeholder: 'Tìm kiếm nhân viên...',
                    label: 'Nhân viên (*)',
                    value: searchQuery,
                    onChange: (e) => {
                        try {
                            setSearchQuery(e?.target?.value || '')
                        } catch (e) {
                            alert(e.message)
                        }
                    }
                }
            })}
        </FormControl>
    )
}

export default withSuspense(Employee, MuiSkeleton["TextField"])