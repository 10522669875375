import { activeTab } from '@/functions';
import MuiScrollTabs from '@components/shared/MuiScrollTabs';
import { useLayoutContext } from '@contexts/layout';
import {
    DisplaySettingsOutlined as ServiceInformation
} from '@mui/icons-material';
import React from 'react';
import { useParams } from 'react-router-dom';

const InvoiceTabs = () => {
    const { invoice_id } = useParams()

    const { permissions } = useLayoutContext();

    const invoiceCollections = React.useMemo(() => {
        let arrs = []
        permissions?.forEach((collection) => {
            if (['invoice'].includes(collection.feature)) {
                arrs.push(...collection?.actions)
            }
        })
        return arrs
    }, [permissions])
    const [INITIAL_SERVICE_TABS] = React.useState([
        {
            value: "information",
            label: "Thông Tin Cơ Bản",
            href: '',
            icon: ServiceInformation,
            hidden: !activeTab({ collections: invoiceCollections, feature: "invoice", action: "show" })
        },
        // {
        //     value: "services",
        //     label: "Dịch vụ liên kết",
        //     href: 'services',
        //     icon: SettingsOutlined,
        //     hidden: !activeTab({ collections: invoiceCollections, feature: "invoice", action: "services" })
        // }
    ])
    return (
        <MuiScrollTabs prefix={`invoices/${invoice_id}`} initialTabs={INITIAL_SERVICE_TABS?.filter(tab => !tab.hidden)} />
    )
}

export default InvoiceTabs