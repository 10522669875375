import { INDUSTRIES } from "@/libs/constants";
import DataGridCustomize from "@components/DataGrid";
import SkeletonDataGrid from "@components/Skeletons/DataGrid";
import { useCustomerContext } from "@contexts/customer";
import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import ContractStatus from "./ContractStatus";
import ContractTotal from "./ContractTotal";
import ServicePaid from "./ServicePaid";
import Service from "./Services";
import Weekly from "./Weekly";
import ServiceStartdate from "./ServiceStartdate";

export const BaseCustomerComponents = {
  Service: Service,
  ContractStatus: ContractStatus,
  PaidAmount: ServicePaid,
  ContractTotal: ContractTotal,
  CustomerJourney: Weekly,
  ServiceStartdate: ServiceStartdate,
};

const ManagementCustomer = ({
  status,
  hiddenFields = [],
  // search = "",
  // handlerSearch,
  page = {
    page: 0,
    pageSize: 10,
  },
  columns = [],
  handlerPage,
  customers = [],
  isFetching = true,
  total = 0,
  enableSale = false,
  enableCS = false,
  ...props
}) => {
  const { queryString, onChangeQueryString } = useCustomerContext();

  const [baseColumns] = React.useState([
    {
      field: "customers.customer_id",
      headerName: "ID",
      width: 60,
      valueGetter: ({ row }) => row?.customer_id,
      operators: { type: "number" },
      renderCell: ({ value }) => (
        <Link to={`/customers/${value}`} replace>
          <Typography className="react-link-table">{value}</Typography>
        </Link>
      ),
      ordered: 1,
    },
    {
      field: "customer_name",
      headerName: "Tên Khách Hàng",
      width: 250,
      valueGetter: ({ row }) => {
        if (row?.customer_company_name && !!row?.customer_company_name)
          return row?.customer_company_name;
        return row?.customer_name;
      },
      operators: { type: "string" },
      ordered: 2,
    },
    {
      field: "customer_service_informations.customer_cs_followers",
      headerName: "CS Phụ Trách",
      width: 250,
      hide: !enableCS,
      valueGetter: ({ row }) =>
        row?.customer_cs_followers?.map((e) => e?.employee_name),
      renderCell: ({ value }) => <Service services={value} />,
    },
    {
      field: "customer_service_informations.customer_sale_followers",
      headerName: "SALE Phụ Trách",
      width: 250,
      hide: !enableSale,
      valueGetter: ({ row }) =>
        row?.customer_sale_followers?.map((e) => e?.employee_name),
      renderCell: ({ value }) => <Service services={value} />,
      ordered: 3,
    },
    {
      field: "customer_service_informations.customer_industry",
      headerName: "Lĩnh Vực",
      width: 180,
      valueGetter: ({ row }) =>
        INDUSTRIES.find(
          (industry) => industry?.value === row?.customer_industry
        )?.label || "",
    },
    {
      field: "customer_email",
      headerName: "Email",
      width: 200,
      hide: true,
    },
  ]);

  const columnsUnPicked =
    JSON.parse(
      localStorage.getItem(`customers.${status}.list.hiddenColumns`)
    ) || [];

  const [customerCols, setCustomerCols] = React.useState(
    [...baseColumns, ...columns]
      ?.sort((a, b) => a?.ordered - b?.ordered)
      ?.filter((x) => {
        if (columnsUnPicked?.includes(x?.field)) {
          return { ...x, hide: true };
        }
        return { ...x };
      })
  );

  return (
    <React.Fragment>
      <DataGridCustomize
        rows={
          customers.map((customer, index) => ({ ...customer, id: index })) || []
        }
        columns={customerCols}
        loading={isFetching}
        componentsProps={{
          toolbar: {
            store: ["customers.", status, ".list.hiddenColumns"].join(""),
            columns: customerCols?.filter((col) => !col?.hideable),
            setColumns: setCustomerCols,
            initialColumns: customerCols,
            feature: "customer",
            inputSearch: !onChangeQueryString
              ? {}
              : {
                  placeholder: "Nhập ID, tên khách hàng tìm kiếm...",
                  value: queryString,
                  onChange: (newFilterString) =>
                    onChangeQueryString(newFilterString),
                },
          },
          pagination: {
            rowsPerPageOptions: [5, 10, 25],
            page: page?.page || 0,
            pageSize: page?.pageSize || 10,
            onPageChange: (newPage) =>
              handlerPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              handlerPage((prevState) => ({
                ...prevState,
                pageSize: newPageSize,
              })),
          },
        }}
        rowCount={total}
        {...props}
      />
    </React.Fragment>
  );
};

export const LoadingCustomer = () => {
  return <SkeletonDataGrid length={8} />;
};

export default ManagementCustomer;
