import React from "react";

import _ from "lodash";
import { Box, Button, Link, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import DataGridProCustomize from "@components/DataGridProCustomize";
import Cell from "@components/DataGrid/Cell";
import CellConfig from "@components/DataGrid/CellConfig2";
import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import WarehouseModelApi from "@/apis/beta/Warehouse/WarehouseModelApi";
import { useNotificationContext } from "@contexts/notification";
import DialogRemoveItems from "../Dialogs/DialogRemoveItems";

const TableChild = ({
  type = "view",
  warehouse_item = {},
  refetchItem = () => {},
}) => {
  const { data: warehouse_models } = WarehouseModelApi.All({ short: true });
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleClose = () => 
    {
      setOpen(false)
    };
  const columns = [
    {
      field: "warehouse_model_id",
      headerName: "Loại",
      valueGetter: ({ row, value }) =>
        _.find(warehouse_models, { value: row.warehouse_model_id })
          ?.warehouse_type?.warehouse_type_name,
      width: 200,
    },
    {
      field: "warehouse_item_name",
      headerName: "Model",
      valueGetter: ({ row, value }) => `${value}`,
      renderCell: ({ value, row }) =>
        value && (
          <Link
            component={NavLink}
            to={`/warehouse/items/${row.warehouse_item_id}/detail`}
          >
            {" "}
            <Cell content={value.toUpperCase()} />
          </Link>
        ),
      width: 300,
    },
    {
      field: "warehouse_item_service_tag",
      headerName: "Serial Number / Service Tag",
      width: 300,
    },
    {
      field: "capacity",
      headerName: "Capacity",
      width: 250,
      renderCell: ({ value }) => value && <CellConfig configures={value} />,
    },
    {
      field: "actions",
      headerName: "Thao tác",
      width: 175,
      renderCell: ({ value, row }) => {
        return value
      }

    }
  ];

  const { setNotification } = useNotificationContext();

  const removeItems = async(ids) => {
    setLoading(true);
    await WarehouseItemApi.RemoveChildMany(ids,warehouse_item?.warehouse_item_id).then((response) => {
      const data = response.data;
      if (data.status !== 200) {
        setNotification({
          open: true,
          message: data.errors[0].message,
          severity: "error",
        });
        return;
      }
      refetchItem();
      setNotification({
        open: true,
        message: "Gỡ các linh kiện thành công.",
        severity: "success",
      });
      setLoading(false);
      handleClose();
    }).catch(err => {
      setNotification({
        open: true,
        message: err,
        severity: "error",
      });
      setLoading(false);
    }
    );
  }

  const reBuildData = React.useCallback(() => {
    const group = _.uniqBy(
      warehouse_item?.children,
      (o) => o.warehouse_item_name
    );
    let newData = [];
    group.forEach((o, index) => {
      const children = _.filter(
        warehouse_item?.children,
        (item) => item.warehouse_item_name === o.warehouse_item_name
      );

      let object = {
        id: `${index}`,
        group: [`${index + 1}`],
        warehouse_item_name: o.warehouse_item_name,
        warehouse_item_id: o.warehouse_item_id,
        warehouse_model_id: o.warehouse_model_id,
        warehouse_item_service_tag: children
          .map((o) => o.warehouse_item_service_tag)
          .join(" , "),
        capacity: o.capacity,
        children_ids: children?.map((o) => o?.warehouse_item_id),
      }
      if (children.length <= 1) {
        object["actions"] = <Button variant="outlined" color="error" 
        size="small"
        onClick={() => 
          {
            setOpen(true)
            setData(o?.warehouse_item_id)
          }
        }
        >
          Đưa về kho
        </Button>
      }
      if (object?.children_ids.length > 1) {
        object["actions"] = <Button variant="outlined" color="error" 
        size="small"
        onClick={() => 
          {
            setOpen(true)
            setData(object?.children_ids)
          }
        }
        >
          Đưa tất cả về kho
        </Button>
      }
      newData.push(object);
      if (children.length > 1) {
        children.forEach((item, i) => {
          newData.push({
            id: `${index}.${i + 1}`,
            group: [`${index + 1}`, `${index + 1}.${i + 1}`],
            ...item,
            actions: <Button variant="outlined" color="error" 
            size="small"
            onClick={() => 
              {
                setOpen(true)
                setData(item?.warehouse_item_id)
              }
            }
            >
              Đưa về kho
            </Button> 
          });
        });
      }
    });
    return newData;
  }, [warehouse_item?.children]);

  return (
    <Box>
      <Box>
        <Typography sx={{ color: "#00000F", mb: 2 }}>
          LINH KIỆN BÊN TRONG
        </Typography>

        <DataGridProCustomize
          rows={reBuildData()}
          columns={columns.filter((o) => !o.hide)}
          disabled={["Pagination", "Toolbar"]}
          treeData
          getTreeDataPath={(row) => row?.group}
          initialState={{
            pinnedColumns: { left: ["__tree_data_group__"], right: ["actions"] },
          }} />
      </Box>
      <DialogRemoveItems
        open={open}
        handleClose={handleClose}
        data={data}
        loading={loading}
        removeItems={() => removeItems(data)}
      />
    </Box>
  );
};
export default TableChild;
