import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import EmployeeDepartment from "./EmployeeDepartment";

const EmployeeAccountInformation = () => {
  const { employee_id } = useParams();

  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Box
      sx={{
        p: 1.5,
        border: "1px solid rgba(0, 0, 0, 0.18)",
        borderRadius: 1,
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: 16, md: 18 },
          fontWeight: "medium",
          mb: 1,
        }}
      >
        Thông Tin Tài Khoản
      </Typography>
      <Grid container spacing={{ xs: 1.5, md: 2 }}>
        <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
          <Controller
            name="employee_email_company"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    disabled
                    value={value}
                    label="Email công ty (*)"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={onChange}
                    size="small"
                    placeholder="...@vnetwork.vn"
                    error={!!errors?.employee_email_company}
                    helperText={errors?.employee_email_company?.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
          <Controller
            name="employee_code"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    disabled
                    value={value}
                    label="Mã nhân viên (*)"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={onChange}
                    size="small"
                    placeholder="Mã nhân viên..."
                    error={!!errors?.employee_code}
                    helperText={errors?.employee_code?.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        {employee_id && <EmployeeDepartment />}
      </Grid>
    </Box>
  );
};

export default EmployeeAccountInformation;
