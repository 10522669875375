import { Box, Skeleton } from '@mui/material'
import React from 'react'
import TextField from '../TextField'

const FormControlTextField = (props) => {
    return (
        <Box sx={{ display: 'flex', ...props }}>
            <Box sx={{ width: 300, minWidth: 300 }}>
                <Skeleton variant='text' sx={{ width: { xs: 100, md: 200 } }} />
            </Box>
            <Box sx={{ width: '100%' }}>
                <TextField />
            </Box>
        </Box>
    )
}

export default FormControlTextField