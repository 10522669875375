import DragDropFile from "@components/shared/DragDropFile";
import SingleDocument from "@components/shared/SingleDocument";
import { Box } from "@mui/material";
import BorderLinearProgress from "@/components/BorderLinearProgress";

const JourneyAttachment = ({ file, setFile, state }) => {
  const onChangeFile = (files) => {
    console.log(files);
    setFile((prevState) => ({ ...prevState, file: files }));
  };
  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <DragDropFile handler={onChangeFile} />
      {file && (
        <SingleDocument
          document={file}
          handleDelete={() =>
            setFile((prevState) => ({ ...prevState, file: null }))
          }
        />
      )}
      {state?.loading && <BorderLinearProgress value={state?.percen} />}
    </Box>
  );
};

export default JourneyAttachment;
