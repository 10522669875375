import { format_date_short } from "@/functions";
import { Box, Typography } from "@mui/material";
import React from "react";

const ServiceStartdate = ({ services }) => {
  return (
    <Box
      sx={{
        p: "4px",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: { xs: "center", md: "flex-start" },
      }}
    >
      {services?.map((service, index) => (
        <Typography key={index} sx={{ fontSize: 14, m: "auto 0 auto auto" }}>
          {!!service?.service_start_date
            ? format_date_short(service?.service_start_date)
            : "..."}
        </Typography>
      ))}
    </Box>
  );
};

export default ServiceStartdate;
