import { format_date_time } from '@/functions'
import { Box, Typography } from '@mui/material'
import React from 'react'

const Message = ({ message, assignee }) => {
    return (
        <Box>
            <Box sx={{
                display: "flex",
                bgcolor: "#7776808F",
                p: '4px 8px',
                borderTopLeftRadius: "7px",
                borderTopRightRadius: "7px"
            }}>
                <Typography
                    component="div"
                    sx={{
                        fontWeight: "medium",
                        fontSize: 13
                    }}
                >
                    {message?.direction === "INCOMING" &&
                        message?.senders?.[0]?.actorId}
                    {message?.direction === "OUTGOING" &&
                        assignee?.name}
                    <Typography sx={{
                        fontSize: 13,
                        display: "inline-flex",
                        ml: 0.5
                    }}>
                        {format_date_time(message?.createdAt, false)}
                    </Typography>
                </Typography>
            </Box>
            <Box sx={{
                p: "12px 16px",
                display: 'flex',
                border: "1px solid rgba(0, 0, 0, 0.38)"
            }}>
                <Typography sx={{ fontSize: 13 }}>
                    {message?.text}
                </Typography>
            </Box>
        </Box>
    )
}

export default Message