import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";

class ManufactureApi {
  getAll = () =>
    useQueryWithCustomized(
      ["warehouse.manufactures.all"],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/manufactures/list`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.manufactures?.map((o) => ({
              value: o.manufacture_id,
              label: o.manufacture_name,
              ...o,
            }));
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );

  Create = (params) =>
    requestWithToken(`${API_WAREHOUSE_V1_URL}/manufactures`, "POST", {
      manufacture: params,
    });
}

export default ManufactureApi = new ManufactureApi();
