import { API_V3_URL } from "@/libs/constants";
import { requestWithToken, encodeBase64 } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";

class ContractApi {
  List = ({
    query,
    searchQuery = null,
    contractType = "CustomerContract",
    page = 1,
    pageSize = 20,
  }) =>
    useQueryWithCustomized(
      ["contract.list", query, searchQuery, contractType, page, pageSize],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/contracts?contract_type=${contractType}${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ""
          }&page=${page}&page_size=${pageSize}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        suspense: true,
      }
    );

  Detail = (id) =>
    useQueryWithCustomized(
      ["contract.detail", id],
      async () => {
        return await requestWithToken(`${API_V3_URL}/contracts/${id}`)
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.contract;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        suspense: true,
      }
    );

  Create = async (data) => {
    return await requestWithToken(
      `${API_V3_URL}/contracts`,
      "POST",
      {
        ...data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  createContractCustomer = async (data) => {
    return await requestWithToken(
      `${API_V3_URL}/contracts/create_contract_customer`,
      "POST",
      {
        ...data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  createContract = async (data) => {
    return await requestWithToken(
      `${API_V3_URL}/contracts/create_contract`,
      "POST",
      {
        ...data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  updateContractCustomer = async (id, data) => {
    return await requestWithToken(
      `${API_V3_URL}/contracts/${id}/update_contract_customer`,
      "PUT",
      {
        ...data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Update = async ({ contractId, data }) => {
    return await requestWithToken(
      `${API_V3_URL}/contracts/${contractId}`,
      "PUT",
      {
        contract: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Cancel = async (contractId, reason) => {
    return await requestWithToken(
      `${API_V3_URL}/contracts/${contractId}`,
      "DELETE",
      {
        contract: {
          contract_note: reason,
        },
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  GenerateContractServiceNumber = ({ servicename = "" }) =>
    useQueryWithCustomized(
      ["contract.generate_contract_service_number", servicename],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/contracts/contract_service_number`,
          "POST",
          {
            contract: { service_name: servicename },
          },
          {},
          {}
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.contract_number;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        suspense: true,
      }
    );

  ContractAttachment = async ({ contractId = -1 }) => {
    return await requestWithToken(
      `${API_V3_URL}/contracts/${contractId}/attachments`
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data?.contract?.attachments;
      })
      .catch((error) => {
        return error?.response;
      });
  };

  ContractAttachments = ({ contractId = -1 }) => {
    useQueryWithCustomized(
      ["contract.attachments.list", contractId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/contracts/${contractId}/attachments`,
          "GET",
          {},
          {},
          {}
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.contract?.attachments;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        suspense: true,
      }
    );
  };

  Extension = async ({ contractId = -1 }) => {
    return await requestWithToken(
      `${API_V3_URL}/contracts/${contractId}/extension`,
      "POST",
      {},
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data;
      })
      .catch((error) => {
        return error?.response;
      });
  };

  Merged = async ({ contractIds = [] }) => {
    return await requestWithToken(
      `${API_V3_URL}/contracts/merged`,
      "PUT",
      {
        contract_ids: contractIds
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data;
      })
      .catch((error) => {
        return error?.response;
      });
  };

  Clone = async ({ contractId = -1 }) => {
    return await requestWithToken(
      `${API_V3_URL}/contracts/${contractId}/clone`, "POST")
      .then(({ data }) => {
        if (data?.error || data?.errors) return data;
        return data;
      })
      .catch((error) => {
        return error?.response;
      });
  };

  Retention = async ({ contractId = -1 }) => {
    return await requestWithToken(
      `${API_V3_URL}/contracts/${contractId}/retention`, "POST")
      .then(({ data }) => {
        if (data?.error || data?.errors) return data;
        return data;
      })
      .catch((error) => {
        return error?.response;
      });
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ContractApi();
