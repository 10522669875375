import React from 'react'
import { NumericFormat } from 'react-number-format';

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
    const { onChange, prefix, suffix, ...other } = props;
    return <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
            onChange({
                target: {
                    name: props.name,
                    value: values?.value
                }
            })
        }}
        decimalSeparator="."
        thousandSeparator
        valueIsNumericString
        suffix={suffix || ""}
        prefix={prefix || ""}
    />
})
export default NumericFormatCustom