import JourneyApi from "@/apis/beta/Customer/Journey/JourneyApi";
import { format_date_monent_js } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Paper,
	Typography,
} from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { JourneyPackages } from "../ViewMode";

const JourneyLocked = ({ journey }) => {

	const [view, setView] = useState(false);
	const { data: service } = JourneyApi.FindService({
		customerId: journey?.customer_id,
		journeyId: journey?.customer_journey_id,
	});
	return (
		<Paper sx={{ p: "8px 16px", borderRadius: "4px", height: '100%' }}>
			{view && (
				<Dialog open={true} fullWidth maxWidth="md">
					<DialogTitle>
						Bạn đang xem lại thông tin của quá trình bán hàng [Journey -{" "}
						{journey?.customer_journey_id}]
					</DialogTitle>
					<DialogContent>
						<JourneyPackages.Detail journey={journey} mode="view" />
					</DialogContent>
					<DialogActions>
						<Button size="small" onClick={() => setView(false)}>
							Đóng
						</Button>
					</DialogActions>
				</Dialog>
			)}
			<Box sx={{ display: "flex", justifyContent: "space-between" }}>
				<Typography
					sx={{ fontSize: 14, fontWeight: "medium" }}
					color="text.black"
				>
					[Journey - {journey?.customer_journey_id}]
				</Typography>
				<IconButton size="small" disabled={view} onClick={() => setView(true)}>
					<VisibilityOutlined fontSize="small" color="primary" />
				</IconButton>
			</Box>
			{journey?.service_referrer_id !== -1 ? (
				<Link
					to={`/services/${journey?.service_referrer_id}`}
					replace
					target="_blank"
				>
					<Typography sx={{ fontSize: 14 }}>{service?.service_name}</Typography>
				</Link>
			) : (
				<Typography sx={{ fontSize: 14 }} color="text.grey">
					Chưa xác định dịch vụ tạo thành công.
				</Typography>
			)}
			{!!journey?.customer_contact_id && <Box sx={{ display: 'flex' }}>
				<Typography sx={{ fontSize: 14 }} color="text.grey">
					Liên Hệ:
					<Link to={`/customers/${journey?.customer_id}?contact_id=${journey?.contact_id}`} replace>
						<Typography component="span" color="primary" sx={{
							ml: 0.5,
							fontSize: 14,
							":hover": {
								textDecoration: 'underline',
							}
						}}>
							{journey?.contact_type === 'company' ? journey?.company_name : journey?.customer_name}
						</Typography>
					</Link>
				</Typography>
			</Box>}
			{journey?.customer_journey_locked && (
				<Typography color="text.grey" sx={{ fontSize: 12, textAlign: "right" }}>
					Locked at:{" "}
					{format_date_monent_js(
						journey?.customer_journey_locked_at,
						"DD/MM/YYYY HH:mm"
					)}
				</Typography>
			)}
		</Paper>
	);
};

export default withSuspense(JourneyLocked, MuiSkeleton["LoadingDotBox"]);
