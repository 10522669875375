import InvoiceApi from "@/apis/beta/Invoice/InvoiceApi";
import withPermission from "@/hocs/withPermission";
import ModalConfirm from "@components/shared/ModalConfirm";
import { DeleteForeverOutlined } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
const Destroy = ({ disabled = true, setDisabled, callback, invoice_id }) => {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = React.useState(false);

  const handlerClick = () => {
    if (openModal) {
      setDisabled(false);
      return setOpenModal(false);
    }
    setDisabled(true);
    return setOpenModal(true);
  };

  const handleCancelledInvoice = async () => {
    const response = await InvoiceApi.Cancelled({
      invoiceId: invoice_id,
    });
    if (!response || response?.status !== 200) {
      return {
        message: "Huỷ hoá đơn thất bại. Vui lòng thử lại.",
        status: 404,
      };
    }
    return {
      message: response?.data?.message || "Xoá hoá đơn thành công.",
      status: 200,
      action: navigate(`/invoices`, { replace: true }),
    };
  };

  return (
    <React.Fragment>
      {openModal && (
        <ModalConfirm
          callback={callback}
          handleClose={handlerClick}
          handleSubmit={handleCancelledInvoice}
          dialog={{
            title: "Huỷ Hoá Đơn",
            content: (
              <Typography component="div">
                Hoá đơn ID số
                <Typography
                  sx={{
                    display: "inline",
                    fontWeight: "medium",
                    m: "auto 4px",
                  }}
                >
                  [{invoice_id}]
                </Typography>
                sẽ bị xoá khỏi hệ thống. Thao tác sẽ không thể phục hồi, bạn có
                muốn thực hiện?
              </Typography>
            ),
          }}
        />
      )}
      <Button
        size="small"
        fullWidth
        variant="outlined"
        color="error"
        disabled={disabled}
        startIcon={<DeleteForeverOutlined />}
        onClick={handlerClick}
      >
        Xoá hoá đơn
      </Button>
    </React.Fragment>
  );
};

export default withPermission(Destroy, {
  feature: "invoice",
  action: "cancelled",
  type: "button",
});
