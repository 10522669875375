import UserApi from '@/apis/UserApi';
import { queryStringParse } from '@/functions';
import { APP_PACKAGE } from '@/libs/constants';
import { useNotificationContext } from '@contexts/notification';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LoginForm from './LoginForm';

const Login = () => {
	const navigate = useNavigate();
	const { setNotification } = useNotificationContext()

	const precheckEmail = (data) => {
		if (data?.email?.match(/@vnetwork.vn$/)) {
			return data;
		}
		return {
			...data,
			email: data?.email + '@vnetwork.vn'
		}
	}

	const onSubmit = (data) => {
		UserApi.Login(precheckEmail(data)).then(async ({ data, errors }) => {
			if (errors) return setNotification({
				open: true,
				message: errors[0]?.message,
				severity: "error"
			});

			const { access_token, exp, uuid } = data;
			if (typeof access_token === 'undefined') return;

			localStorage.setItem('SA', access_token);
			localStorage.setItem('EXP', exp);
			localStorage.setItem('UUID', uuid);

			const redirect = window.location.search !== '' ? queryStringParse(window.location.search).redirect : '/';

			navigate(redirect, { replace: true });
		});
	};

	return (
		<Grid container>
			<Grid item lg={6} md={6} xs={12}>
				<Box sx={{ bgcolor: '#FFFFFF', padding: 3 }}>
					<Box sx={{ width: '100%' }}>
						<Typography sx={{ fontSize: 15, color: 'grey.700' }}>
							Brand
						</Typography>
					</Box>
					<Box sx={{ my: 3, display: 'flex', height: 'calc(100vh - 10rem)' }}>
						<Box sx={{ m: 'auto' }}>
							<Typography variant='h5' sx={{ fontWeight: 500 }}>
								Login
							</Typography>
							<Typography
								sx={{ mb: 5, color: 'grey.600' }}
							>
								Enter your credential to access all things in one place.
							</Typography>
							<LoginForm onSubmit={onSubmit} />
						</Box>
					</Box>
					<Box sx={{ width: '100%' }}>
						<Typography sx={{ fontSize: 13, color: 'grey.700' }}>
							&copy; {new Date().getFullYear()} VNETWORK JSC (build-
							{APP_PACKAGE.version}.{process.env.REACT_APP_ENV})
						</Typography>
					</Box>
				</Box>
			</Grid>
			<Grid item lg={6} md={6} xs={12}>
				<Box sx={{ bgcolor: '#F8F8F8', height: '100vh' }} />
			</Grid>
		</Grid>
	);
};

export default Login;
