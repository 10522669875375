import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class ServiceBillingApi {

    Detail = ({ serviceId = -1 }) => {
        return useQueryWithCustomized(
            ["service.billing", serviceId],
            async () => {
                return await requestWithToken(`${API_V3_URL}/services/${serviceId}/service_management_billing`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data?.billing;
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            }
        );
    }

    Update = async ({
        serviceId = -1,
        data = {}
    }) => {
        return await requestWithToken(`${API_V3_URL}/services/${serviceId}/service_management_billing`,
            "PUT", {
            service_management_billing: data
        }, {}, {})
            .then(({ data }) => {
                if (data?.error) return data;
                return data?.data;
            })
            .catch((error) => {
                return error.response;
            });
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ServiceBillingApi();
