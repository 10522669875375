import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class InvoiceNoteApi {

    List = ({ invoiceId = null }) => {
        return useQueryWithCustomized(
            ["invoice.notes.list", invoiceId],
            async () => {
                return await requestWithToken(`${API_V3_URL}/invoices/${invoiceId}/notes`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data?.notes;
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            }
        )
    }


    Create = async ({ invoiceId, data }) => {
        return await requestWithToken(`${API_V3_URL}/invoices/${invoiceId}/notes`, "POST", {
            note: data
        }, {}, {})
            .then(({ data }) => {
                return {
                    invoice: data?.data?.invoice,
                    status: data?.status
                };
            })
            .catch((error) => {
                return error.response;
            });
    }

    Update = async ({ invoiceId = null, noteId = null, data = {} }) => {
        return await requestWithToken(`${API_V3_URL}/invoices/${invoiceId}/notes/${noteId}`, "PUT", {
            note: data
        }, {}, {})
            .then(({ data }) => {
                if (data?.error) return data;
                return data?.data
            })
            .catch((error) => { return error })
    }

    Destroy = async ({ invoiceId = null, noteId = null }) => {
        return await requestWithToken(`${API_V3_URL}/invoices/${invoiceId}/notes/${noteId}`, "DELETE")
            .then(({ data }) => {
                if (data?.error) return data;
                return data?.data;
            })
            .catch((error) => { return error })
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new InvoiceNoteApi();
