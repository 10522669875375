import ProductApi from '@/apis/beta/Product/ProductApi'
import { selectObjectKeys } from "@/functions"
import PrivateCDNConfigoptions from '@components/Templates/ProductForm/PrivateCDN'
import { useNotificationContext } from '@contexts/notification'
import { Box, Button, CircularProgress } from '@mui/material'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

const PrivateCDN = ({
    data = {},
    callback
}) => {

    const { setNotification } = useNotificationContext()

    const { product_id } = useParams()
    const [submit, setSubmit] = React.useState(null)

    const [defaultValues] = React.useState(
        data?.product_configoptions?.map((item) => {
            return (
                {
                    ...selectObjectKeys(item, ["name", "unit"]),
                    steps: item?.product_pricing_steps?.map((step) => ({ ...step, prevId: step?.id }))
                }
            )
        })
    )
    const methods = useForm({
        defaultValues: {
            product_configoptions: defaultValues
        }
    })

    const onReset = () => {
        methods.reset({ product_configoptions: defaultValues })
    }

    const rebuildProductConfigoptions = (arrs = []) => {
        return arrs?.map((item) => {
            return (
                {
                    ...selectObjectKeys(item, ["name", "unit"]),
                    steps: item?.steps?.map((step) => ({
                        ...selectObjectKeys(step, ["start", "end", "pricing", "pricing_minimum"]),
                        id: step?.prevId || -1
                    }))
                }
            )
        })
    }

    const onSubmit = async (values) => {
        setSubmit("submit")
        const response = await ProductApi.Update({
            id: product_id,
            data: {
                product_configoptions: rebuildProductConfigoptions(values?.product_configoptions)
            }
        })
        setSubmit(null)
        if (!response || response?.errors) {
            return setNotification({ open: true, message: response?.errors?.[0]?.message, severity: "error" })
        }
        setNotification({ open: true, message: "Cập nhật bảng giá thành công.", severity: "success" })
        try {
            callback()
        } catch (error) {
            console.log(error?.message)
        }
    }
    return (
        <FormProvider {...methods}>
            <Box component="form" onSubmit={methods.handleSubmit(onSubmit)} sx={{ maxWidth: { xs: '100%', md: "80%" } }}>
                <PrivateCDNConfigoptions />
                <Box sx={{
                    mt: 3,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2
                }}>
                    <Button size="small"
                        variant='text'
                        onClick={onReset}
                        disabled={!!submit}
                        sx={{
                            height: {
                                xs: 36,
                                md: 42
                            }
                        }}>
                        Huỷ thao tác
                    </Button>
                    <Button size="small"
                        type="submit"
                        disabled={!!submit}
                        startIcon={(!!submit && submit === "submit") && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                        variant='contained' sx={{ height: { xs: 36, md: 42 }, minWidth: 150 }}>
                        Lưu thay đổi
                    </Button>
                </Box>
            </Box>

        </FormProvider>

    )
}

export default PrivateCDN