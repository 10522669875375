import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { queryClientRefetchScope } from "@/functions";
import withPermission from "@/hocs/withPermission";
import { PRODUCT_UNITS } from "@/libs/constants";
import NumericFormatCustom from "@components/NumericFormatCustom";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { useNotificationContext } from "@contexts/notification";
import { sortPriorities } from "@features/Module/Setting/Product/Cart/ProductOne";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import WarehouseProcessApi from "@/apis/beta/Warehouse/WarehouseProcessApi";
import DialogShowProcessProblems from "@components/DialogShowProcessProblems";
import CancelCompletedProcessesExport from "@features/Module/WarehouseNew/WarehouseProcesses/Processes/CancelCompletedProcessExport";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  Paper,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useMemo, useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import WarehouseProcesses from "../../../ServiceButtons/WarehouseProcesses";
import ServerBuilder from "../ServerBuilder";

const PRIVATE_SERVICE_MODELS = [
  "server",
  "cpu",
  "processor",
  "disk",
  "memory",
  "ram",
  "card",
  "network",
];
const SORT_PRIORITIES = [
  "server",
  "processor",
  "cpu",
  "disk",
  "ram",
  "memory",
  "card",
  "network",
  "ip",
  "bandwidth",
  "datacenter",
  "rack",
  "u",
  "power",
  "software",
  "os",
  "other",
  "default",
];
// const searchData = {current:{}};
const ServiceInformations = ({ service, addons = [] }) => {
  const [open, setOpen] = useState(true);
  const [addonData, setAddonData] = useState({});
  const [showProcessProblems, setShowProcessProblems] = useState({
    open: false,
    data: {},
    hide: true,
  });
  const [showCancelProcesses, setShowCancelProcesses] = useState({
    open: false,
  });
  // const { searchData, setSearchData } = useSearchDataContext();
  // const searchData = useRef({});
  const { mutateAsync, isLoading } = useMutation(
    ServiceApi.UpdateServiceInformations
  );
  const { setNotification } = useNotificationContext();
  const queryClient = useQueryClient();
  const { data: service_process, refetch: refetchServiceProcess } = WarehouseProcessApi.FindServiceByServiceId({ service_id: service?.service_id || "" })
  const rebuildServiceInformations = useMemo(() => {
    return sortPriorities(
      service?.service_informations,
      SORT_PRIORITIES,
      "service_information_type"
    )?.map((item) => {
      return item;
    });
  }, [service]);
  const methods = useForm({
    defaultValues: {
      service_informations: rebuildServiceInformations,
    },
  });
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = methods;
  const { fields } = useFieldArray({
    control,
    name: "service_informations",
  });

  const onSubmit = async (values) => {
    const response = await mutateAsync({
      serviceId: service?.service_id,
      data: values,
    });
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }
    queryClientRefetchScope(queryClient, "service");
    return setNotification({
      open: true,
      message: "Cập nhật thông tin dịch vụ thành công.",
      severity: "success",
    });
  };

  const precheckAddonStatus = (information) => {
    if (
      information?.statemente_key === "Reducer::Override::Service::Addon" &&
      information?.service_addon_id > 0
    ) {
      const addon = addons?.find(
        (item) => item?.service_id === information?.service_addon_id
      );
      return !["cancel", "stop"].includes(addon?.service_status);
    }
    return false;
  };
  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          gap: 0.5,
          alignItems: "center",
          mt: 1.5,
        }}
      >
        <Typography
          sx={{ fontSize: { xs: 14, md: 16 }, fontWeight: "medium" }}
          color="primary"
        >
          Thông Tin Cấu Hình
        </Typography>
        <IconButton size="small" onClick={() => setOpen(!open)}>
          {open && <VisibilityOffOutlined fontSize="small" />}
          {!open && <VisibilityOutlined fontSize="small" />}
        </IconButton>
      </Box>
      <Slide in={open} direction="left" mountOnEnter unmountOnExit>
        <Box>
          {open && (
            <Paper
              sx={{ p: 2, mt: 1 }}
              component="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              {fields?.map((information, index) => {
                if (information?.service_information_type === "cpu")
                  return null;
                const { data: warehouseItemsSearch } = WarehouseItemApi.Search_ServiceTags({
                  service_information_type: information?.service_information_type,
                  warehouse_brand_name: addonData?.[information?.service_information_type]?.warehouse_brand_name || information?.warehouse_brand_name || "",
                  warehouse_model_name: addonData?.[information?.service_information_type]?.warehouse_model_name || information?.warehouse_model_name || ""
                })
                const options = warehouseItemsSearch?.warehouse_items?.map((item) => {
                  return (
                    {
                      value: `${item?.warehouse_item_service_tag}|${item?.warehouse_item_id}`,
                      label: item?.warehouse_item_service_tag + " - " + item?.warehouse_item_id
                    }
                  )
                }) || [];
                const isStopped = precheckAddonStatus(information);
                return (
                  <Fragment key={information.id}>
                    {index !== 0 && <Divider sx={{ m: "8px 0 16px 0" }} />}
                    <Box
                      key={information?.id}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1.5,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", md: "row" },
                          gap: 1.5,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 1.5,
                            width: {
                              xs: "100%",
                              md: "calc((100% - 24px) / 3)",
                            },
                            minWidth: {
                              xs: "100%",
                              md: "calc((100% - 24px) / 3)",
                            },
                          }}
                        >
                          <Controller
                            name={`service_informations.[${index}].service_information_type`}
                            control={control}
                            render={({ field }) => {
                              return (
                                <FormControl
                                  fullWidth
                                  sx={{
                                    width: "40%",
                                  }}
                                >
                                  <TextField
                                    label="Loại"
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    {...field}
                                    InputProps={{
                                      endAdornment: isStopped ? "OFF" : null,
                                      sx: {
                                        backgroundColor: isStopped
                                          ? "#ffcdd2 !important"
                                          : "unset",
                                      },
                                      inputProps: {
                                        style: {
                                          textTransform: "uppercase",
                                        },
                                      },
                                    }}
                                    disabled
                                  />
                                </FormControl>
                              );
                            }}
                          />
                          <Controller
                            name={`service_informations.[${index}].name`}
                            control={control}
                            render={({ field }) => {
                              return (
                                <FormControl fullWidth>
                                  <TextField
                                    label="Tên"
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    {...field}
                                    disabled
                                  />
                                </FormControl>
                              );
                            }}
                          />
                        </Box>
                        {PRIVATE_SERVICE_MODELS.includes(
                          information?.service_information_type
                        ) && (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: { xs: "column", md: "row" },
                                gap: 1.5,
                              }}
                            >
                              <Controller
                                name={`service_informations.[${index}].warehouse_brand_name`}
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                  return (
                                    <FormControl
                                      sx={{
                                        width: "calc((100% - 12px) / 2)",
                                        maxWidth: "calc((100% - 12px) / 2)",
                                        minWidth: "calc((100% - 12px) / 2)",
                                      }}
                                      fullWidth
                                    >
                                      <TextField
                                        label="Manufacture"
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        // {...field}
                                        value={value}
                                        onChange={(event) => {
                                          onChange(event.target.value);
                                          setAddonData(
                                            (prev) => {
                                              return {
                                                ...prev,
                                                [information?.service_information_type]: {
                                                  ...prev[information?.service_information_type],
                                                  warehouse_brand_name: event.target.value
                                                }
                                              }
                                            }
                                          );
                                          // callbackRefetchSearch();
                                        }}
                                      />
                                    </FormControl>
                                  );
                                }}
                              />
                              <Controller
                                name={`service_informations.[${index}].warehouse_capacity_value`}
                                control={control}
                                render={({ field }) => {
                                  return (
                                    <FormControl fullWidth>
                                      <TextField
                                        label="Thông Số"
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                          sx: {
                                            pr: 0,
                                          },
                                          inputComponent: NumericFormatCustom,
                                        }}
                                        placeholder="0..."
                                        size="small"
                                        {...field}
                                      />
                                    </FormControl>
                                  );
                                }}
                              />
                              <Controller
                                name={`service_informations.[${index}].warehouse_capacity_unit`}
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                  const selected =
                                    PRODUCT_UNITS.find(
                                      (x) => x?.value === value
                                    ) || null;
                                  return (
                                    <FormControl fullWidth>
                                      <SingleSelectCustomize
                                        value={selected}
                                        options={PRODUCT_UNITS}
                                        input={{
                                          label: "Đơn vị tính",
                                          placeholder: "Chọn đơn vị tính...",
                                        }}
                                        onChange={(event, newValue) =>
                                          onChange(newValue?.value || "")
                                        }
                                      />
                                    </FormControl>
                                  );
                                }}
                              />
                            </Box>
                          )}

                        {!PRIVATE_SERVICE_MODELS.includes(
                          information?.service_information_type
                        ) &&
                          (information?.service_information_type?.match(
                            /(ip|bandwidth|power|u)/
                          ) ? (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: { xs: "column", md: "row" },
                                gap: 1.5,
                              }}
                            >
                              <Controller
                                name={`service_informations.[${index}].warehouse_capacity_value`}
                                control={control}
                                render={({ field }) => {
                                  return (
                                    <FormControl fullWidth>
                                      <TextField
                                        label="Thông Số"
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                          sx: {
                                            pr: 0,
                                          },
                                          inputComponent: NumericFormatCustom,
                                        }}
                                        placeholder="0..."
                                        size="small"
                                        {...field}
                                      />
                                    </FormControl>
                                  );
                                }}
                              />
                              <Controller
                                name={`service_informations.[${index}].warehouse_capacity_unit`}
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                  const selected =
                                    PRODUCT_UNITS.find(
                                      (x) => x?.value === value
                                    ) || null;
                                  return (
                                    <FormControl fullWidth>
                                      <SingleSelectCustomize
                                        value={selected}
                                        options={PRODUCT_UNITS}
                                        input={{
                                          label: "Đơn vị tính",
                                          placeholder: "Chọn đơn vị tính...",
                                        }}
                                        onChange={(event, newValue) =>
                                          onChange(newValue?.value || "")
                                        }
                                      />
                                    </FormControl>
                                  );
                                }}
                              />
                            </Box>
                          ) : (
                            <Controller
                              name={`service_informations.[${index}].description`}
                              control={control}
                              render={({ field }) => {
                                return (
                                  <FormControl fullWidth>
                                    <TextField
                                      label="Mô tả"
                                      InputLabelProps={{ shrink: true }}
                                      size="small"
                                      placeholder="Thông tin mô tả..."
                                      {...field}
                                    />
                                  </FormControl>
                                );
                              }}
                            />
                          ))}
                      </Box>
                      {PRIVATE_SERVICE_MODELS.includes(
                        information?.service_information_type
                      ) && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: { xs: "column", md: "row" },
                              gap: 1.5,
                              m: "auto 0 auto auto",
                              width: {
                                xs: "100%",
                                md: "calc((100% - 24px) / 3 * 2 + 12px)",
                              },
                              minWidth: {
                                xs: "100%",
                                md: "calc((100% - 24px) / 3 * 2 + 12px)",
                              },
                            }}
                          >
                            <Controller
                              name={`service_informations.[${index}].warehouse_model_name`}
                              control={control}
                              render={({ field: { value, onChange } }) => {
                                return (
                                  <FormControl fullWidth>
                                    <TextField
                                      label="Model"
                                      InputLabelProps={{ shrink: true }}
                                      size="small"
                                      // {...field}
                                      value={value}
                                      onChange={(event) => {
                                        // console.log(event.target.value);
                                        onChange(event.target.value);
                                        setAddonData(
                                          (prev) => {
                                            return {
                                              ...prev,
                                              [information?.service_information_type]: {
                                                ...prev[information?.service_information_type],
                                                warehouse_model_name: event.target.value
                                              }
                                            }
                                          })
                                        // callbackRefetchSearch();
                                      }
                                      }
                                    />
                                  </FormControl>
                                );
                              }}
                            />
                            <Controller
                              name={`service_informations.[${index}].warehouse_item_service_tag`}
                              control={control}
                              render={({ field: { value, onChange } }) => {
                                const selected = options.find(
                                  (x) => x?.value === value
                                ) || null;
                                return (
                                  <FormControl fullWidth>
                                    <SingleSelectCustomize
                                      value={selected}
                                      options={options}
                                      input={{
                                        label: "Service_tags",
                                        placeholder: value || "Vui lòng chọn _tag...",
                                      }}
                                      onChange={(event, newValue) =>
                                        onChange(newValue?.value || "")
                                      }
                                    />
                                  </FormControl>
                                );
                              }}
                            />
                          </Box>
                        )}

                      {information?.service_information_type?.match(
                        /(processor|server)/
                      ) && (
                          <Box
                            sx={{
                              width: {
                                xs: "100%",
                                md: "calc((100% - 24px) / 3 * 2 + 12px)",
                              },
                              m: "auto 0 auto auto",
                            }}
                          >
                            <ServerBuilder
                              position={fields?.findIndex(
                                (item) => item?.service_information_type === "cpu"
                              )}
                            />
                            <Controller
                              name={`service_informations.[${index}].description`}
                              control={control}
                              render={({ field }) => {
                                return (
                                  <FormControl fullWidth>
                                    <TextField
                                      multiline
                                      label="Mô tả"
                                      placeholder="Mô tả thông tin..."
                                      InputLabelProps={{ shrink: true }}
                                      size="small"
                                      {...field}
                                    />
                                  </FormControl>
                                );
                              }}
                            />
                          </Box>
                        )}
                    </Box>
                  </Fragment>
                );
              })}
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: 1.5,
                  justifyContent: "flex-end",
                }}
              >
                {service_process ?

                  <>
                    {service_process.warehouse_process_status === "completed"
                      &&
                      <Button
                        size="small"
                        // disabled={isLoading}
                        // onClick ={() => {}}
                        variant="contained"
                        color="error"
                        onClick={() => setShowCancelProcesses({ open: true, data: service_process })}
                      >
                        Huỷ phiếu
                      </Button>
                    }
                    <Button
                      size="small"
                      // disabled={isLoading}
                      onClick={() => { window.open(`../warehouse/processes/${service_process.warehouse_process_id}/detail`) }}
                      variant="contained"
                    >
                      đi tới phiếu
                    </Button>
                  </>
                  :
                  <>
                    {isDirty && (
                      <Fragment>
                        <Button
                          size="small"
                          disabled={isLoading}
                          variant="outlined"
                        >
                          Huỷ Thao Tác
                        </Button>
                        <Button
                          size="small"
                          disabled={isLoading}
                          startIcon={
                            isLoading && (
                              <CircularProgress
                                size={14}
                                sx={{ color: "#777680" }}
                              />
                            )
                          }
                          variant="contained"
                          type="submit"
                        >
                          Cập Nhật
                        </Button>
                      </Fragment>
                    )}
                    {!showProcessProblems.hide && <Button
                      size="small"
                      startIcon={
                        <WarningAmberIcon
                          size={14}
                        />
                      }
                      variant="contained"
                      color="warning"
                      onClick={() => {
                        setShowProcessProblems({
                          ...showProcessProblems,
                          open: true
                        })
                      }}
                    >
                      Chi tiết lỗi
                    </Button>}
                    <WarehouseProcesses
                      service={service}
                      setShowProcessProblems={setShowProcessProblems}
                      refetchServiceProcess={refetchServiceProcess}
                    />
                  </>
                }
              </Box>
            </Paper>
          )}
        </Box>
      </Slide>
      {showCancelProcesses?.open && (
        <CancelCompletedProcessesExport
          service={service}
          open={showCancelProcesses?.open}
          data={showCancelProcesses?.data}
          handleClose={() => setShowCancelProcesses({ open: false })}
          refetch={refetchServiceProcess}
        />
      )}
      <DialogShowProcessProblems
        open={showProcessProblems.open}
        handleClose={() => setShowProcessProblems({ ...showProcessProblems, open: false })}
        data={showProcessProblems.data}
        originalUrl={"../warehouse"}
      />
    </FormProvider>
  );
};
export default withPermission(ServiceInformations, {
  feature: "service",
  action: "technical",
  type: "Block",
});
