import { removeObjectKeys } from "@/functions";
import { SERVICE_METADATA } from "@/libs/constants";
import CloudServiceClusterPreview from "@components/Templates/ClusterService(k8s)/PreviewCreate";
import ServiceForm from "@components/Templates/ServiceForm";
import ServicePreview from "@components/Templates/ServiceForm/shared/ServicePreview";
import VnisServicePreview from "@components/Templates/Vnis(Mlytics)/PreviewCreate";
import { AddOutlined } from "@mui/icons-material";
import { Box, Button, Collapse, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import Overview from "./Overview";
import TranscodePreview from "@components/Templates/SwiftFederation/Transcoding/TranscodingPreview";
import StorageLFDPreview from "@components/Templates/SwiftFederation/Storage(LFD)/StoragePreview";
import MlyticsServicePreview from "@components/Templates/Vnis(Mlytics)/PreviewCreate";


const ServicePreviews = {
	NodeServerCluster: CloudServiceClusterPreview,
	VNIS: VnisServicePreview,
	Mlytics: MlyticsServicePreview,
	"SwiftFederation.Trans": TranscodePreview,
	"SwiftFederation.StorageLFD": StorageLFDPreview,
}

const ContractServices = ({ disabled }) => {
	const { control, watch } = useFormContext();
	const { fields, append, remove, update } = useFieldArray({
		control: control,
		name: "services",
	});

	const [modal, setModal] = React.useState({
		open: false,
		type: null,
		data: null,
	});

	const performServiceMetadata = (metadata) => {
		return removeObjectKeys(metadata, ["index"]);
	};

	const saver = (newValue) => {
		if (newValue?.index >= 0) {
			update(newValue?.index, performServiceMetadata(newValue));
		} else {
			append(performServiceMetadata(newValue));
		}
		return closer();
	};

	const closer = () => setModal({ open: false, type: null, data: null });

	return (
		<React.Fragment>
			{!disabled && (
				<Box
					sx={{
						display: "flex",
						flexDirection: { xs: "column", md: "row" },
						gap: { xs: 0, md: 1.5 },
						mb: 2,
					}}
				>
					<Box sx={{ minWidth: 300 }}>
						<Typography sx={{ fontSize: 14, color: "#777680" }}>
							Dịch Vụ *
						</Typography>
					</Box>
					<Box
						sx={{
							width: "100%",
							display: "flex",
							flexDirection: "column",
							gap: 2,
						}}
					>
						{modal?.open && parseInt(watch(`contract_customer_id`)) > 0 && (
							<Collapse in={modal?.open}>
								<ServiceForm
									customerId={parseInt(watch(`contract_customer_id`))}
									type={modal?.type || "create"}
									orderredProductIds={
										fields?.map((item) => item?.product_id) || []
									}
									saver={saver}
									closer={closer}
									defaultValues={modal?.data}
								/>
							</Collapse>
						)}
						{!modal?.open && (
							<Collapse in={!modal?.open}>
								<Box sx={{ display: "flex" }}>
									<Button
										size="small"
										variant="outlined"
										disabled={
											disabled ||
											modal?.open ||
											parseInt(watch(`contract_customer_id`)) <= 0
										}
										onClick={() =>
											setModal({
												open: true,
												type: "create",
												data: {
													...SERVICE_METADATA,
													service_management_billing: {
														service_billing_startdate: dayjs(),
														service_billing_enddate: dayjs().get('date') === 1 ? dayjs().endOf('month') : dayjs().add(1, 'months').endOf('month'),
														service_nextbill_startdate: (dayjs().get('date') === 1 ? dayjs().endOf('month') : dayjs().add(1, 'months').endOf('month')).add(1, 'days'),
														service_nextbill_enddate: (dayjs().get('date') === 1 ? dayjs().endOf('month') : dayjs().add(1, 'months').endOf('month'))?.add(1, 'days')?.endOf('month'),
														service_control_startdate: Date.now(),
														service_control_enddate: Date.now(),
													}
												},
											})
										}
										startIcon={<AddOutlined fontSize="small" />}
										sx={{
											height: { xs: 36, md: 40 },
											minWidth: { xs: "100%", md: 150 },
										}}
									>
										Chọn Dịch Vụ
									</Button>
								</Box>
							</Collapse>
						)}
					</Box>
				</Box>
			)}
			<Box
				sx={{
					display: "flex",
					flexDirection: { xs: "column", md: "row", gap: { xs: 1.5, md: 0 } },
				}}
			>
				<Box sx={{ minWidth: 300 }}>
					<Typography sx={{ fontSize: 14, color: "#777680" }}>
						Danh Sách Dịch Vụ Đã Chọn *
					</Typography>
				</Box>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
						gap: 1.5,
					}}
				>
					{(!fields || fields?.length === 0) && (
						<Box sx={{ display: 'flex', width: '200px', height: '200px', justifyContent: 'center' }}>
							{/* <img alt="logo" width="auto" src="/images/original-cart-empty.png" /> */}
						</Box>
					)}
					{fields &&
						fields?.map((field, index) => {
							let Component = ServicePreviews[field?.product_template]
							if (!Component) {
								Component = ServicePreview
							};

							return (
								<Component
									data={{ ...field, index: index }}
									key={field.id}
									disabled={disabled}
									remover={() => remove(index)}
									updater={() =>
										setModal({
											open: true,
											type: "update",
											data: { ...field, index: index },
										})
									}
									insert={append}
								/>
							)
						})}
					<Overview products={fields} />
				</Box>
			</Box>
		</React.Fragment>
	);
};

export default ContractServices;
