import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";
class CustomerContactApi {
  List = ({ customerId = -1 }) => {
    return useQueryWithCustomized(
      ["customers.contacts", customerId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/customers/${customerId}/contacts`
        )
          .then(({ data }) => {
            if (data?.error || data?.errors) return []
            return data?.data?.contacts?.map((contact) => {
              return {
                ...contact,
                name: contact?.contact_type === 'company' ? contact?.company_name : contact?.name
              }
            }) || [];
          })
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Detail = ({ customerId = -1, customercontactId }) => {
    return useQueryWithCustomized(
      ["customers.customer_contacts.show", customercontactId],
      async () => {
        return await requestWithToken(`${API_V3_URL}/customers/${customerId}/customer_contacts/${customercontactId}`)
          .then(({ data }) => {
            return data?.data?.customer_contact;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };
  Create = async ({ customerId = -1, data }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/contacts`, "POST", { contact: data },
    )
      .then(({ data }) => {
        if (data?.error || data?.errors) return data;
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  Locked = async ({ customerId = -1, contactId }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/contacts/${contactId}`, "DELETE",
    )
      .then(({ data }) => {
        if (data?.error || data?.errors) return data;
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  Update = async ({ contact = {}, customerId = -1, contactId }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/contacts/${contactId}`,
      "PUT",
      {
        contact: contact,
      })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  // Updates = ({customerId = -1,customercontactId}) => {
  //   return useQueryWithCustomized(
  //     ["customers.customer_contacts.customer_contact_updates", customercontactId],
  //     async () => {
  //       return await requestWithToken(
  //         `${API_V3_URL}/customers/${customerId}/customer_contacts/${customercontactId}/customer_contact_updates`
  //       )
  //         .then(({ data }) => {
  //           if (data?.error) return data;
  //           return data?.data?.customer_contact_updates || [];
  //         })
  //         .catch((error) => {
  //           return error.response;
  //         });
  //     },
  //     {
  //       suspense: true,
  //       staleTime: 10 * 60 * 1000,
  //     }
  //   );
  // };

}
// eslint-disable-next-line import/no-anonymous-default-export
export default new CustomerContactApi();