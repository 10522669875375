import { Box, Typography } from "@mui/material";
import React from "react";

const NoneAccept = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: 150,
        bgcolor: "#777680",
        borderRadius: "4px",
      }}
    >
      <Typography sx={{ m: "auto" }}>
        Sản phẩm này không cung cấp tạo dịch vụ trên hình thức DÙNG THỬ.
      </Typography>
    </Box>
  );
};

export default NoneAccept;
