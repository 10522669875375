import { encodeBase64, requestWithToken } from "@/functions";
import { API_V3_URL } from "@/libs/constants";
import axios from "axios";

class FileApi {
  GetPresignedTokenAttachment = async ({ filename }) => {
    return await requestWithToken(
      `${API_V3_URL}/uploads/get_presigned_url`,
      "POST",
      {
        filename: filename,
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return {
          token: data?.data?.token,
          filename: data?.data?.file_name,
          status: data?.status,
        };
      })
      .catch((error) => {
        return error.response;
      });
  };

  GetTokenAttachmentOnStorage = async (filename) => {
    return await requestWithToken(
      `${API_V3_URL}/uploads/${encodeBase64(filename)}/get_token`
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return {
          token: data?.data.token,
          filename: data?.data.file_name,
          status: data?.status,
        };
      })
      .catch((error) => {
        return error.response;
      });
  };

  UploadAttachmentToStorage = async ({
    token,
    file,
    handleUploadFile,
    callback,
  }) => {
    return await axios
      .put(token, file, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: async (event) => {
          const currentPercenUpload = Math.round(
            (event.loaded * 100) / event.total
          );
          handleUploadFile((prevState) => ({
            ...prevState,
            percen: currentPercenUpload,
          }));
          callback && callback(currentPercenUpload, file);
        },
      })
      .then((response) => {
        if (response.error) return false;
        return true;
      })
      .catch((error) => {
        return error.response;
      });
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new FileApi();
