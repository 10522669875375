import { MenuProps } from '@/styles/common'
import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const Datacenter = ({ position }) => {
    const { control, watch } = useFormContext()

    return (
        <Box>
            <Typography sx={{
                fontSize: { xs: 16, md: 20 },
                fontWeight: 'medium',
            }}>
                Datacenter
            </Typography>
            <Controller
                name={`product_configoptions.[${position}].select`}
                control={control}
                render={({ field: { value, onChange } }) => {
                    const options = watch(`product_configoptions.[${position}].options`) || []
                    const selected = value?.value
                    return (
                        <FormControl fullWidth>
                            <Select
                                size='small'
                                value={selected}
                                onChange={(event) => onChange(options?.find(item => item.value === event?.target?.value) || null)}
                                MenuProps={{
                                    ...MenuProps
                                }}
                            >
                                {options.map((item, index) => {
                                    return (
                                        <MenuItem
                                            key={index}
                                            value={item?.value}
                                        >
                                            {item?.label}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    )
                }}
            />

        </Box>
    )
}

export default Datacenter