import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { queryClientRefetchScope } from "@/functions";
import withPermission from "@/hocs/withPermission";
import ModalConfirm from "@components/shared/ModalConfirm";
import { ToggleOnOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Fragment, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

const RegisterService = ({ service }) => {
  const queryClient = useQueryClient();
  const [isCreated, setCreated] = useState(false);
  const { mutateAsync } = useMutation(ServiceApi.Register);

  const onSubmit = async () => {
    const response = await mutateAsync(service?.service_id);
    if (!response || response?.errors) {
      return {
        status: 400,
        message:
          response?.errors?.[0]?.message || "Failed when register service.",
      };
    }
    return {
      status: 200,
      message: "Đăng ký dịch vụ thành công.",
    };
  };
  const callback = () => queryClientRefetchScope(queryClient, "service");
  return (
    <Fragment>
      {isCreated && (
        <ModalConfirm
          handleSubmit={onSubmit}
          callback={callback}
          handleClose={() => setCreated(false)}
          dialog={{
            title: "Đăng Ký Dịch Vụ",
            content: `Dịch vụ ${service?.service_name} sẽ được khởi tạo trên hệ thống.`,
          }}
        />
      )}
      <Button
        size="small"
        variant="outlined"
        disabled={
          isCreated ||
          !!service?.service_creatable_uuid ||
          service?.service_status === "cancel"
        }
        onClick={() => setCreated(true)}
        startIcon={<ToggleOnOutlined fontSize="small" />}
      >
        Đăng Ký Dịch Vụ
      </Button>
    </Fragment>
  );
};

export default withPermission(RegisterService, {
  feature: "service",
  action: "register",
  type: "Button",
});
