import { Box } from '@mui/material'
import Cancel from './Cancel'
import Resolve from './Resolve'

const Buttons = ({
    employee,
    assigneeIds = [],
    contractProcess,
    type
}) => {
    return (
        <Box sx={{
            display: 'flex',
            mt: 1,
            flexDirection: { xs: 'column', md: 'row' },
            gap: 2
        }}>
            {(assigneeIds?.includes(employee?.employee_id) && contractProcess?.contract_process_status === 0) && <Resolve contractProcess={contractProcess} type={type} />}
            {contractProcess?.contract_process_owner === employee?.employee_id && contractProcess?.contract_process_status === 0 &&
                <Cancel contractProcess={contractProcess} contractType={type} />}
        </Box>
    )
}

export default Buttons