import { Box, Button, Chip, StepLabel, Typography } from '@mui/material';
import React from 'react';
import PrivateIconWithFormat from '../shared/icons';
import FormContract from './FormContract';

const StepScanUpload = ({
    contractNumber,
    callbackContractNumber
}) => {

    const [openForm, setOpenForm] = React.useState(false)
    return (
        <React.Fragment>
            {openForm && <FormContract data={contractNumber}
                callbackContractNumber={callbackContractNumber}
                handleClose={() => setOpenForm(false)} />}
            <StepLabel
                StepIconProps={{
                    sx: {
                        fontSize: 20,
                        mb: `72px`
                    }
                }}
            >
                <Chip size='verysmall'
                    variant="customize-pending"
                    label="Scan & Upload" />
                <Box sx={{ display: 'flex', mb: 1 }}>
                    <PrivateIconWithFormat icon="ScanIcon" />
                    <Typography sx={{ mr: 2 }}>
                        Hợp đồng {contractNumber?.contract_number_id} {contractNumber?.contract_number_status === 5 ? "đang chờ Scan & Upload." : "đã Scan & Upload thành công."}
                    </Typography>
                </Box>
                <Button size="small" sx={{ height: 36 }}
                    disabled={openForm || contractNumber?.contract_number_status !== 6}
                    onClick={() => setOpenForm(true)}
                >Tải Lên File Hợp Đồng</Button>
            </StepLabel>
        </React.Fragment>
    )
}

export default StepScanUpload