import ContractApi from '@/apis/beta/Contract/ContractApi'
import BreadCrumb from "@/components/shared/BreadCrumb"
import withPermission from '@/hocs/withPermission'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { Box, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useParams } from 'react-router-dom'
import ContractForm from '../shared/ContractForm'

const Update = () => {
    const { contract_id } = useParams()
    const { data: contract, refetch: callback } = ContractApi.Detail(contract_id)

    const [CONTRACT_METADATA] = React.useState({
        contract_customer_id: contract?.customer?.customer_id,
        contract_number_id: contract?.contract_number_id,
        effective_date: moment(contract?.contract_effective_date),
        expiry_date: moment(contract?.contract_expiry_date),
        contract_value: contract?.contract_value,
        contract_note: contract?.contract_note,
        contract_status: contract?.contract_status,
        contract_type: 1,
        contract_id: contract_id,
        services: [contract?.product]
    })

    const [CONTRACT_ATTACHMENT] = React.useState(!contract?.attachments?.[0] ? null : {
        name: contract?.attachments?.[0]?.attachment_file_name,
        url: contract?.attachments?.[0]?.attachment_file_url,
        type: contract?.attachments?.[0]?.attachment_file_type,
        size: contract?.attachments?.[0]?.attachment_file_size,
    })

    return (
        <Box>
            <Typography sx={{ fontSize: { xs: 18, md: 24 }, fontWeight: 'medium' }}>
                Cập Nhật Hợp Đồng
            </Typography>
            <BreadCrumb
                data={[
                    { label: "Danh Sách Hợp Đồng", reactLink: "/customer_contracts" },
                    { label: contract_id, reactLink: `/customer_contracts/${contract_id}` },
                    { label: "Cập nhật" }
                ]}
            />
            <ContractForm
                type="update"
                defaultValues={CONTRACT_METADATA}
                defaultAttachment={CONTRACT_ATTACHMENT}
                callback={callback}
                contractDisabled={contract?.contract_status === 0 || contract?.contract_status === 2} // hoan tat | huy
            />
        </Box>
    )
}

export default withSuspense(withPermission(Update, { feature: 'contract', action: 'customer-contract-update' }), MuiSkeleton["JourneyForm"])