import DatePicker from "@components/DatePicker";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import { FormControl } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const ServiceStoppedAt = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name="service_stopped_at"
      render={({ field: { value, onChange } }) => (
        <FormControl fullWidth sx={{ mb: 1.5 }}>
          <DatePicker
            value={value}
            onChange={onChange}
            label="Ngày chính thức giảm *"
          />
          {!!errors?.service_duedate && (
            <ErrorHelperText message={errors?.service_duedate?.message} />
          )}
        </FormControl>
      )}
    />
  );
};

export default ServiceStoppedAt;
