import AddOutlined from "@mui/icons-material/AddOutlined";
import { Box, Button, Drawer, Typography } from "@mui/material";
import { useState } from "react";
import Create from "../../Create";
import Import from "../../Import";

const ContactHeader = () => {
  const [drawer, setDrawer] = useState({ open: false, type: undefined });
  return (
    <Box
      sx={{
        mb: 2,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: { xs: "column", md: "row" },
        gap: { xs: 1, md: 0 },
      }}
    >
      {drawer?.open && (
        <Drawer open={drawer?.open} anchor="right" sx={{ minWidth: 500 }}>
          <Box sx={{ height: "100vh", p: "24px 16px" }}>
            {drawer?.type === "create" && (
              <Create
                onClose={() => setDrawer({ open: false, type: undefined })}
              />
            )}
            {drawer?.type === "import" && (
              <Import
                onClose={() => setDrawer({ open: false, type: undefined })}
              />
            )}
          </Box>
        </Drawer>
      )}
      <Typography
        sx={{
          fontSize: { xs: 18, md: 24 },
          fontWeight: "medium",
        }}
      >
        Khách Hàng Marketing
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          gap: 2,
        }}
      >
        <Button
          size="small"
          variant="outlined"
          onClick={() => setDrawer({ open: true, type: "create" })}
          startIcon={<AddOutlined fontSize="small" />}
        >
          Thêm khách hàng
        </Button>
        <Import />
      </Box>
    </Box>
  );
};

export default ContactHeader;
