import BreadCrumb from '@components/shared/BreadCrumb'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'

const Header = () => {
    const { employee_id, work_contract_id } = useParams()
    return (
        <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontSize: { xs: 18, md: 24 }, fontWeight: 'medium' }}>
                Chi Tiết Hợp Đồng
            </Typography>
            <BreadCrumb
                data={[
                    { label: "QLHĐ Nhân Viên", reactLink: `/employees/${employee_id}/work_contracts` },
                    { label: work_contract_id },
                    { label: "Chi Tiết Hợp Đồng" }
                ]}
            />
        </Box>
    )
}

export default Header