import { Box, Grid, Paper, Skeleton } from '@mui/material'
import React from 'react'

const JourneyForm = () => {
    return (
        <Paper sx={{ p: 3 }}>
            <Grid container spacing={2}>
                {[1, 2, 3, 4].map(item =>
                    <React.Fragment key={item}>
                        <Grid item xs={12} md={3}>
                            <Skeleton variant="text" sx={{ width: 180 }} />
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Skeleton variant="rectangular" sx={{
                                height: item !== 3 ? 40 : 100,
                                borderRadius: '4px'
                            }} />
                            {item === 3 && <Box sx={{ mt: 1 }}>
                                {[1, 2, 3].map(i =>
                                    <Skeleton variant="text" key={i} sx={{ width: i !== 3 ? '100%' : '50%' }} />
                                )}
                            </Box>}
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Skeleton variant="rectangular" sx={{
                    height: 36,
                    width: 150
                }} />
            </Box>
        </Paper>
    )
}

export default JourneyForm