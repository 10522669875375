import WorkcontractApi from "@/apis/beta/Employee/WorkcontractApi"
import DataGrid from '@/components/DataGrid'
import { CellLink, CellStatus } from "@/components/DataGrid/Cell"
import { buildQueryFilterCondition, format_date_short, handleRedirectOpenLink } from "@/functions"
import withPermission from '@/hocs/withPermission'
import withSuspense from '@/hocs/withSuspense'
import useDebounce from '@/hooks/useDebounce'
import useFilters, { Filter } from '@/hooks/useFilters'
import { WORK_CONTRACT_STATUS } from "@/libs/constants"
import DataGridFilter from "@components/DataGrid/Filter"
import PopperAction from "@components/PopperAction"
import FormatTextNumeralVND from "@components/shared/FormatTextNumeralVND"
import MuiSkeletonDefault from "@components/Skeletons/Default"
import { useNotificationContext } from "@contexts/notification"
import { MoreVertOutlined } from '@mui/icons-material'
import { Box, IconButton, Popper } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'
import Destroy from "../Destroy"
import Header from "./Header"

const List = () => {
    const location = useLocation()
    const [query, updateQuery] = useFilters('work-contract.list.filters', location);

    const { setNotification } = useNotificationContext()

    const [searchQuery, setSearchQuery] = React.useState('')
    const debouncedSearchQuery = useDebounce(searchQuery, 500)
    const filterableWorkContract = React.useCallback(() => {
        if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === '') return '';
        const fieldQueries = [
            { name: 'employees.employee_name', operator: "$contains", value: debouncedSearchQuery },
            { name: 'employees.employee_code', operator: "$contains", value: debouncedSearchQuery },
            {
                name: 'employees.employee_id', operator: "$eq", value: parseInt(debouncedSearchQuery),
                disabled: !parseInt(debouncedSearchQuery)
            },
            {
                name: 'work_contracts.work_contract_id', operator: "$eq",
                value: parseInt(debouncedSearchQuery),
                disabled: !parseInt(debouncedSearchQuery)
            },
            { name: 'work_contracts.work_contract_number', operator: "$contains", value: debouncedSearchQuery },
        ]
        return buildQueryFilterCondition("$or", 0, fieldQueries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery])

    const [page, setPage] = React.useState({
        page: 0,
        pageSize: 20
    });
    const [popper, setPopper] = React.useState({
        anchorEl: undefined,
        data: undefined
    })

    const [initialColumns] = React.useState([
        {
            field: 'work_contracts.work_contract_id', headerName: 'ID', width: 80,
            hideable: true,
            operators: { type: 'number' },
            valueGetter: ({ row }) => {
                return {
                    label: `HĐLĐ-${row?.work_contract_id}`,
                    href: ['employees', row?.employee?.employee_id, 'work_contracts', row?.work_contract_id]
                }
            },
            renderCell: ({ value }) => <CellLink data={value} />
        }, {
            field: 'employee.employee_name', headerName: 'Tên Nhân Viên', width: 200,
            valueGetter: ({ row }) => row?.employee?.employee_name,
            operators: { type: 'string' },
        }, {
            field: 'work_contract_type', headerName: 'Loại Hợp Đồng', width: 150,
            operators: { type: 'string' },
            renderCell: ({ value }) => value === 1 && "Chính Thức"
        }, {
            field: 'work_contract_number', headerName: 'Mã Hợp Đồng', width: 150,
            operators: { type: 'string' },
        }, {
            field: 'work_contract_startdate', headerName: 'Ngày bắt đầu', width: 150,
            operators: { type: "date" },
            valueFormatter: ({ value }) => format_date_short(value)
        }, {
            field: 'work_contract_enddate', headerName: 'Ngày kết thúc', width: 150,
            operators: { type: "date" },
            valueFormatter: ({ value }) => format_date_short(value)
        }, {
            field: 'work_contract_term', headerName: 'Thời hạn hợp đồng', width: 160,
            operators: { type: 'string' }
        }, {
            field: 'work_contract_status', headerName: 'TT. Hợp Đồng',
            width: 150,
            valueGetter: ({ row }) => WORK_CONTRACT_STATUS.find(stt => stt?.value === row?.work_contract_status),
            operators: {
                type: 'object',
                collections: WORK_CONTRACT_STATUS
            },
            renderCell: ({ value }) => <CellStatus component="muiChip" data={value} />
        }, {
            field: 'work_contract_created_at', headerName: 'Ngày Upload HĐ', width: 150,
            valueFormatter: ({ value }) => format_date_short(value),
            operators: { type: 'date' }
        }, {
            field: 'work_contract_salary_basic', headerName: 'Lương CB', width: 150,
            operators: { type: 'number' },
            renderCell: ({ value }) => <FormatTextNumeralVND value={value} />
        }, {
            field: 'work_contract_salary_allowance', headerName: 'Lương Trợ Cấp', width: 150,
            operators: { type: 'number' },
            renderCell: ({ value }) => <FormatTextNumeralVND value={value} />
        }, {
            field: 'actions', headerName: 'Thao Tác', hideable: true,
            width: 80,
            renderCell: ({ row }) => {
                let currentActions = actions
                if (row?.work_contract_status === 3) {
                    currentActions = currentActions?.filter(item => item?.name !== "cancel-workcontract" && item?.name !== "update-workcontract")
                }
                return (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <IconButton
                            onClick={(event) => {
                                setPopper(prev => ({ ...prev, anchorEl: event?.target, data: row, actions: currentActions }))
                            }}
                            title='Hành động'
                        >
                            <MoreVertOutlined sx={{ m: 'auto' }} />
                        </IconButton>
                    </Box>
                )
            }
        }
    ])
    const [columns, setColumns] = React.useState(initialColumns || [])
    React.useEffect(() => {
        setColumns(initialColumns)
    }, [initialColumns])
    const filterables = columns.filter((column) => (column?.filterable || column?.operators) && !column?.hide)
        .map((column) => {
            return ({
                field: column?.key ? column.key : column.field,
                label: column?.headerName,
                operators: column?.operators
            })
        })
    const { data, isFetching, refetch: callback } = WorkcontractApi.List({
        page: page?.page + 1,
        pageSize: page?.pageSize || 20,
        query: Filter.stringify(query),
        searchQuery: filterableWorkContract() === "" ? null : filterableWorkContract()
    })
    const [action, setAction] = React.useState({
        open: false,
        name: undefined,
        data: undefined
    })

    const [actions] = React.useState([
        {
            order: 1,
            name: "open-workcontract-attachment",
            icon: "See",
            label: "Xem PLHĐ",
            handleClick: async (values) => {
                if (!values?.link) {
                    return setNotification({ open: true, message: "Không tìm thấy file hợp đồng tải lên.", severity: 'error' })
                }
                handleClosePopper()
                return handleRedirectOpenLink(values?.link)
            }
        }, {
            order: 2,
            name: "cancel-workcontract",
            icon: "Delete",
            label: "Huỷ HĐNV",
            handleClick: (values) => {
                setAction(prev => ({ ...prev, open: true, data: values, name: "cancel-workcontract" }))
                return handleClosePopper()
            }
        }
    ])
    const handleClosePopper = () =>
        setPopper(prev => ({ ...prev, anchorEl: undefined, data: undefined, actions: [] }))

    const handleClearAction = () => setAction(prev => ({ ...prev, open: false, name: undefined, data: undefined }))

    const getModalConfirm = (actionName, data) => {
        switch (actionName) {
            case "cancel-workcontract":
                return <Destroy data={data} handleClose={handleClearAction} callback={callback} />
            default: break;
        }
    }

    return (
        <Box>
            {popper?.anchorEl !== undefined && <Popper anchorEl={popper?.anchorEl}
                open={Boolean(popper.anchorEl)}>
                <PopperAction {...popper} handleClose={handleClosePopper} />
            </Popper>}
            {(action.open && action?.name !== undefined) && getModalConfirm(action?.name, action?.data)}
            <Header />
            <DataGridFilter callback={updateQuery}
                filters={{
                    key: 'work-contract.list.filters',
                    filterables: filterables,
                }} />
            <DataGrid
                rows={data?.work_contracts?.map((wContract) => ({ ...wContract, id: wContract?.work_contract_id })) || []}
                columns={columns}
                loading={isFetching}
                componentsProps={{
                    toolbar: {
                        store: "work-contract.list.hiddenColumns",
                        columns: columns?.filter(col => !col?.hideable),
                        setColumns: setColumns,
                        initialColumns: initialColumns,
                        feature: 'work-contract',
                        inputSearch: {
                            placeholder: "Nhập ID/ mã hợp đồng, mã/ tên nhân viên tìm kiếm...",
                            value: searchQuery,
                            onChange: setSearchQuery
                        }
                    },
                    pagination: {
                        rowsPerPageOptions: [10, 20, 50, 100],
                        page: page?.page || 0,
                        pageSize: page?.pageSize || 20,
                        onPageChange: (newPage) => setPage(prevState => ({ ...prevState, page: newPage })),
                        onPageSizeChange: (newPageSize) => setPage((prevState) => ({ ...prevState, pageSize: newPageSize }))
                    }
                }}
                rowCount={data?.pagination?.total}
            />
        </Box>
    )
}

export default withSuspense(withPermission(List, { feature: 'work-contract', action: 'index' }), MuiSkeletonDefault)