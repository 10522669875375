import { Box, FormControl, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const GroupPermissionName = () => {
    const { control } = useFormContext()
    return (
        <React.Fragment>
            <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 1,
                mb: 2
            }}>
                <Box sx={{ display: 'flex', width: 300, minWidth: 300 }}>
                    <Typography sx={{ m: 'auto 0' }}
                        color="custom.grey">
                        Tên nhóm quyền
                    </Typography>
                </Box>
                <Controller
                    control={control}
                    name="group_permission_name"
                    rules={{ required: "Vui lòng nhập tên nhóm quyền." }}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FormControl fullWidth>
                                <TextField
                                    value={value}
                                    onChange={onChange}
                                    size="small"
                                    placeholder='Nhập tên nhóm quyền...'
                                    fullWidth
                                />
                            </FormControl>
                        )
                    }} />
            </Box>
        </React.Fragment>
    )
}

export default GroupPermissionName