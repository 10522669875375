import withPermission from '@/hocs/withPermission';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import Information from './Information';


const Detail = ({ companyId }) => {
    const renderCompanyInformation = () => {
        switch (companyId > 0) {
            case true: return <Information companyId={companyId} />
            default: return null
        }
    }

    const [openWarning] = React.useState({
        open: localStorage.getItem('company.data.create') !== null
            && JSON.parse(localStorage.getItem('company.data.create') || `{}`)?.customerId === 114
    })

    return (
        <Box>
            {openWarning?.open && <Dialog fullWidth maxWidth="sm" open={true} >
                <DialogTitle>
                    Có dữ liệu cần cập nhật
                </DialogTitle>
                <DialogContent>
                    <Typography component="div">
                        Khách hàng
                        có dữ liệu công ty cần cập nhật.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ height: 36 }} size="small">
                        Bỏ Qua & Không Nhắc Lại
                    </Button>
                </DialogActions>
            </Dialog>}
            {renderCompanyInformation()}
        </Box>
    )
}

export default withPermission(Detail, { feature: "company", action: "show", type: "element" }) 