import BusinessApi from "@/apis/beta/Employee/BusinessApi";
import { useNotificationContext } from "@/contexts/notification";
import { queryClientRefetcher } from "@/functions";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

const Leader = ({ data, callback }) => {
  const queryClient = useQueryClient();
  const { business_id } = useParams();
  const { setNotification } = useNotificationContext();

  const [submit, setSubmit] = React.useState(null);
  const [feedback, setFeedback] = React.useState(
    data?.business_leader_feedback || "Oke"
  );

  const handleLeaderConfirmed = async (status = "cancel") => {
    let form = {
      business_leader_feedback: feedback,
      business_leader_confirm: true,
      business_status: status === "cancel" ? 3 : 0,
    };
    setSubmit(status);
    const response = await BusinessApi.Approve({
      businessId: business_id,
      data: form,
    });
    setSubmit(false);
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message || "Thao tác thất bại.",
        severity: "error",
      });
    }

    queryClientRefetcher(queryClient, ["business.list", "business.show"]);
    return setNotification({
      open: true,
      message: `${
        status === "cancel" ? "Từ chối" : "Xác nhận"
      } phiếu nghỉ thành công.`,
      severity: "success",
    });
  };

  return (
    <Paper sx={{ p: 3, mt: 3 }} elevation={10}>
      <Typography
        sx={{
          fontWeight: "medium",
          mb: 0.5,
        }}
      >
        Trưởng Phòng
      </Typography>
      <FormControl fullWidth>
        <TextField
          multiline
          value={feedback}
          disabled={
            data?.business_status !== 0 ||
            !!submit ||
            !!data?.business_leader_confirm
          }
          onChange={(event) => {
            setFeedback(event.target.value);
          }}
          placeholder="Vui lòng nhập thông tin phản hồi & ý kiến của bạn..."
        />
      </FormControl>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3, gap: 2 }}>
        <Button
          size="small"
          variant="outlined"
          onClick={() => handleLeaderConfirmed("cancel")}
          disabled={
            data?.business_status !== 0 ||
            !!submit ||
            data?.business_leader_confirm
          }
          startIcon={
            submit === "cancel" && (
              <CircularProgress size={14} sx={{ color: "#777680" }} />
            )
          }
          sx={{
            height: { xs: 36, md: 42 },
            minWidth: 120,
          }}
        >
          Không đồng ý
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={() => handleLeaderConfirmed("success")}
          startIcon={
            submit === "success" && (
              <CircularProgress size={14} sx={{ color: "#777680" }} />
            )
          }
          disabled={
            data?.business_status !== 0 ||
            !!submit ||
            data?.business_leader_confirm
          }
          sx={{
            height: { xs: 36, md: 42 },
            minWidth: 120,
          }}
        >
          Xác Nhận
        </Button>
      </Box>
    </Paper>
  );
};

export default Leader;
