import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { queryClientRefetchScope } from "@/functions";
import withPermission from "@/hocs/withPermission";
import ModalConfirm from "@components/shared/ModalConfirm";
import { AutorenewOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Fragment, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

const ServiceRenew = ({ service }) => {
  const queryClient = useQueryClient();
  const [isCreated, setCreated] = useState(false);
  const { mutateAsync } = useMutation(ServiceApi.Renew);

  const onSubmit = async () => {
    const response = await mutateAsync(service?.service_id);
    if (!response || response?.errors) {
      return {
        status: 400,
        message: response?.errors?.[0]?.message || "Failed when renew service.",
      };
    }
    return {
      status: 200,
      message: "Gia hạn dịch vụ thành công.",
    };
  };
  const callback = () => queryClientRefetchScope(queryClient, "service");
  return (
    <Fragment>
      {isCreated && (
        <ModalConfirm
          handleSubmit={onSubmit}
          callback={callback}
          handleClose={() => setCreated(false)}
          dialog={{
            title: "Gia Hạn Dịch Vụ",
            content: `Dịch vụ ${service?.service_name} sẽ được gia hạn với chu kỳ mới.`,
          }}
        />
      )}
      <Button
        size="small"
        variant="outlined"
        disabled={isCreated || service?.service_status !== "active"}
        onClick={() => setCreated(true)}
        startIcon={<AutorenewOutlined fontSize="small" />}
      >
        Gia Hạn Dịch Vụ
      </Button>
    </Fragment>
  );
};

export default withPermission(ServiceRenew, {
  feature: "service",
  action: "renew",
  type: "Button",
});
