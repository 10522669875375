import { Paper } from "@mui/material";
import InvoiceCreated from "./InvoiceCreated";
import InvoiceCustomer from "./InvoiceCustomer";
import InvoiceDuedate from "./InvoiceDuedate";
import InvoiceLabel from "./InvoiceLabel";
import InvoiceNotification from "./InvoiceNotification";
import InvoicePaid from "./InvoicePaid";
import InvoicePaymentFee from "./InvoicePaymentFee";
import InvoicePaymentMethod from "./InvoicePaymentMethod";
import InvoiceTotal from "./InvoiceTotal";

const InvoiceInformationMetadata = ({ invoice = {} }) => {
  return (
    <Paper
      component="form"
      sx={{
        display: "flex",
        p: 3,
        flexDirection: "column",
        gap: 1.5,
      }}
    >
      <InvoiceNotification {...invoice} />
      <InvoiceLabel {...invoice} />
      <InvoiceCustomer {...invoice} />
      <InvoiceCreated {...invoice} />
      <InvoiceDuedate {...invoice} />
      <InvoiceTotal {...invoice} />
      <InvoicePaid />
      <InvoicePaymentMethod {...invoice} />
      <InvoicePaymentFee {...invoice} />
    </Paper>
  );
};

export default InvoiceInformationMetadata;
