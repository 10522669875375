import { customize_truncate, format_numeral_price } from "@/functions";
import { PRODUCT_UNITS } from "@/libs/constants";
import NumericFormatCustom from "@components/NumericFormatCustom";
import { PRODUCT_INFORMATION_TYPES } from "@components/Templates/ProductForm/shared/ProductInformation";
import { Box, FormControl, TextField, Typography } from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import Metadata from "../Metadata";

const renderInformation = ({ information = {} }) => {
  switch (information?.product_infomation_type) {
    case "cpu":
      return (
        <Typography sx={{ fontSize: 14 }}>
          {information?.unit === "qty" && "x"}
          {format_numeral_price(information?.value)}
          {PRODUCT_UNITS?.slice(4)?.find(
            (unit) => unit?.value === information?.unit
          )?.label || ""}
        </Typography>
      );
    case "os":
    case "datacenter":
      return (
        <Typography sx={{ fontSize: 14 }}>
          {information?.option?.default?.label}
        </Typography>
      );
    case "ram":
    case "disk":
    case "bandwidth":
    case "storage":
    case "account":
    case "account-v2":
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography sx={{ fontSize: 14 }}>
            {information?.unit === "qty" && "x"}
            {format_numeral_price(information?.value)}
            {PRODUCT_UNITS?.slice(4)?.find(
              (unit) => unit?.value === information?.unit
            )?.label || ""}
          </Typography>
        </Box>
      );
    case "ssl":
    case "software":
    case "cloudsecurities":
    case "cloudstorage":
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography sx={{ fontSize: 14 }}>Tuỳ chỉnh</Typography>
        </Box>
      );
    case "information":
      return <Typography sx={{ fontSize: 14 }}>Enabled</Typography>;
    default:
      return (
        <Typography sx={{ fontSize: 14 }}>
          {information?.unit === "qty" && "x"}
          {information?.value}
          {(information?.unit !== "qty" &&
            PRODUCT_UNITS?.slice(1)?.find(
              (unit) => unit?.value === information?.unit
            )?.label) ||
            ""}
        </Typography>
      );
  }
};

const Information = ({ product }) => {
  const { control } = useFormContext();

  const productInformations = useWatch({
    control: control,
    name: "product_informations",
  });

  if (productInformations?.length === 0) return null;
  return (
    <Box
      sx={{
        border: "1px solid rgba(0, 0, 0, 0.18)",
        p: 2,
        borderRadius: "4px",
        mb: 1.5,
      }}
    >
      <Typography
        sx={{
          fontWeight: "medium",
          fontSize: 14,
        }}
        color="primary"
      >
        [Thông Tin Sản Phẩm/ Dịch Vụ]
      </Typography>
      <Metadata />
      {productInformations?.map((information, index) => {
        if (product?.product_name?.match(/Customized/)) {
          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 1,
                borderBottom:
                  index !== productInformations?.length - 1
                    ? "1px solid rgba(0, 0, 0, 0.08)"
                    : "none",
                mt: 1.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: {
                    xs: "100%",
                    md: "calc((100% - 8px) / 2)",
                  },
                  flexDirection: "row",
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#777680",
                    m: "auto 0",
                  }}
                >
                  {customize_truncate(
                    PRODUCT_INFORMATION_TYPES?.find(
                      (item) => item?.value === information?.name
                    )?.label || information?.name,
                    100
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: {
                    xs: "100%",
                    md: "calc((100% - 8px) / 2)",
                  },
                }}
              >
                <Controller
                  control={control}
                  name={`product_informations.[${index}].value`}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <FormControl fullWidth>
                        <TextField
                          size="small"
                          InputProps={{
                            inputComponent: NumericFormatCustom,
                            endAdornment:
                              PRODUCT_UNITS?.slice(4)?.find(
                                (x) => x.value === information?.unit
                              )?.label || "",
                          }}
                          value={value}
                          onChange={onChange}
                        />
                      </FormControl>
                    );
                  }}
                />
              </Box>
            </Box>
          );
        }
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 1,
              borderBottom:
                index !== productInformations?.length - 1
                  ? "1px solid rgba(0, 0, 0, 0.08)"
                  : "none",
              mt: 1.5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: {
                  xs: "100%",
                  md: "calc((100% - 8px) / 2)",
                },
                flexDirection: "row",
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  color: "#777680",
                }}
              >
                {customize_truncate(
                  PRODUCT_INFORMATION_TYPES?.find(
                    (item) => item?.value === information?.name
                  )?.label || information?.name,
                  100
                )}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: {
                  xs: "100%",
                  md: "calc((100% - 8px) / 2)",
                },
              }}
            >
              {renderInformation({ information })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default Information;
