import List from './List';
import Create from "./Create";
import Detail from './Detail';
import Update from './Update';
import Cancel from './Cancel'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    List,
    Create,
    Detail,
    Update,
    Cancel
};
