import DayoffApi from "@/apis/beta/Employee/DayoffApi"
import BreadCrumb from "@/components/shared/BreadCrumb"
import withPermission from '@/hocs/withPermission'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeletonDefault from "@components/Skeletons/Default"
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import DayoffForm from '../shared/Form'

const Cancel = () => {

    const { dayoff_id } = useParams()
    const { data, refetch: callback } = DayoffApi.Detail(dayoff_id)
    return (
        <Box>
            <Typography className='page-title'>Chỉnh Sửa Phiếu Nghỉ</Typography>
            <BreadCrumb
                data={[
                    { label: "Tài Khoản Cá Nhân", reactLink: "/account/dayoffs" },
                    { label: `PN-${dayoff_id}` },
                    { label: "Chỉnh Sửa Phiếu Nghỉ" }
                ]}
            />
            <DayoffForm
                type='update'
                data={data}
                callback={callback}
            />
        </Box>
    )
}

export default withSuspense(withPermission(Cancel, { feature: "dayoff", action: "update" }), MuiSkeletonDefault)