import { requestWithToken } from "@/functions";
import { API_V3_URL } from "@/libs/constants";
import qs from "query-string"

class ExcelApi {

  ContractsExport = async ({ query }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/reports/contracts?filters=${query}`,
      "GET",
      {},
      {
        "Content-Type": "application/vnd.ms-excel",
        "CONTENT-DISPOSITION": "attachment",
      },
      { withCredentials: true, responseType: "blob" }
    );
    return response?.data;
  };

  async DayOffs({ filters }) {
    const response = await requestWithToken(
      `${API_V3_URL}/reports/excel/dayoffs?filters=${filters}`,
      "GET",
      {},
      {
        "Content-Type": "application/vnd.ms-excel",
        "CONTENT-DISPOSITION": "attachment",
      },
      { withCredentials: true, responseType: "blob" }
    );

    return response?.data;
  }

  async Businesses({ filters }) {
    const response = await requestWithToken(
      `${API_V3_URL}/reports/excel/businesses?filters=${filters}`,
      "GET",
      {},
      {
        "Content-Type": "application/vnd.ms-excel",
        "CONTENT-DISPOSITION": "attachment",
      },
      { withCredentials: true, responseType: "blob" }
    );

    return response?.data;
  }

  async Contact({ filters }) {
    const response = await requestWithToken(
      `${API_V3_URL}/reports/contact?filters=${filters}`,
      "GET",
      {},
      {
        "Content-Type": "application/vnd.ms-excel",
        "CONTENT-DISPOSITION": "attachment",
      },
      { withCredentials: true, responseType: "blob" }
    );

    return response?.data;
  }

  async CustomerQualifiedProspects({ filters }) {
    const response = await requestWithToken(
      `${API_V3_URL}/reports/customers/qualified-prospects?filters=${filters}`,
      "GET",
      {},
      {
        "Content-Type": "application/vnd.ms-excel",
        "CONTENT-DISPOSITION": "attachment",
      },
      { withCredentials: true, responseType: "blob" }
    );

    return response?.data;
  }

  async WorkDays(formValue) {
    const form = {
      from: formValue?.from?.format("DD/MM/YYYY"),
      to: formValue?.to?.format("DD/MM/YYYY"),
      type: "export",
    };

    const query = qs.stringify(form);

    const response = await requestWithToken(
      `${API_V3_URL}/work_days/export_excel_file_with_client_data?${query}`,
      "POST",
      {},
      {
        "Content-Type": "application/vnd.ms-excel",
        "CONTENT-DISPOSITION": "attachment",
      },
      { withCredentials: true, responseType: "blob" }
    );

    return response?.data;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ExcelApi();
