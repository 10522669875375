import { Box, FormControl, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const PreviewInformation = () => {
  const { control } = useFormContext();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: 1,
        mt: 2,
      }}
    >
      <Box sx={{ width: 300, minWidth: 300, display: "flex" }}>
        <Typography sx={{ fontSize: 14, color: "#777680" }}>
          Thông tin tổng quát
        </Typography>
      </Box>
      <Controller
        control={control}
        name="schedule_preview_information"
        render={({ field }) => {
          return (
            <FormControl fullWidth>
              <TextField
                {...field}
                multiline
                placeholder="Nhập thông tin về hoạt động & meeting..."
              />
            </FormControl>
          );
        }}
      />
    </Box>
  );
};

export default PreviewInformation;
