import BreadCrumb from "@/components/shared/BreadCrumb"
import withPermission from '@/hocs/withPermission'
import { Box, Typography } from '@mui/material'
import moment from "moment"
import React from 'react'
import CustomerContractForm from "../shared/ContractForm"

const CONTRACT_METADATA = {
    contract_customer_id: -1,
    customer_contact_id: -1,
    customer_contact_name: '',
    contract_number_id: "",
    effective_date: moment(),
    expiry_date: moment().add(1, "year").add(-1, "days"),
    contract_value: 0,
    contract_note: "",
    contract_type: 1,
    contract_status: 1,
    services: []
}

const Create = () => {
    return (
        <Box>
            <Typography sx={{
                fontSize: { xs: 18, md: 24 },
                fontWeight: 'medium'
            }}>
                Hợp Đồng
            </Typography>
            <BreadCrumb
                data={[
                    { label: "Danh Sách Hợp Đồng", reactLink: "/customer_contracts" },
                    { label: "Thêm Hợp Đồng" }
                ]}
            />
            <CustomerContractForm
                type="create"
                defaultValues={CONTRACT_METADATA}
                contractDisabled={false} />
        </Box>
    )
}

export default withPermission(Create, { feature: 'contract', action: 'customer-contract-create' })