import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { AddOutlined, DeleteOutlineOutlined } from '@mui/icons-material'
import { Box, FormControl, IconButton, TextField } from '@mui/material'
import React from 'react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'

const InformationOption = ({ position = 0 }) => {

    const { control } = useFormContext()

    const { fields, append, remove } = useFieldArray({
        control: control,
        name: `product_informations.[${position}].option.options`
    })

    const options = useWatch({
        control: control,
        name: `product_informations.[${position}].option.options`
    })
    return (
        <Box sx={{ display: 'flex', flexDirection: "column" }}>
            {fields && fields.map((field, index) => {
                return (
                    <Box key={field.id} sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: 1.5,
                        mb: 1.5,
                        borderBottom: {
                            xs: '1px solid rgba(0, 0, 0, 0.18)', md: 'none'
                        }
                    }}>
                        <Controller
                            name={`product_informations.[${position}].option.options.[${index}].label`}
                            control={control}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <FormControl fullWidth>
                                        <TextField size='small'
                                            InputLabelProps={{ shrink: true }}
                                            label="Tên"
                                            value={value}
                                            onChange={onChange} />
                                    </FormControl>
                                )
                            }}
                        />
                        <Controller
                            name={`product_informations.[${position}].option.options.[${index}].value`}
                            control={control}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <FormControl fullWidth>
                                        <TextField size='small'
                                            InputLabelProps={{ shrink: true }}
                                            label="Giá trị"
                                            value={value}
                                            onChange={onChange}
                                        />
                                    </FormControl>
                                )
                            }} />
                        <IconButton size='small' onClick={() => remove(index)} sx={{ m: 'auto 0 auto auto' }}>
                            <DeleteOutlineOutlined fontSize='small'
                                sx={{
                                    ":hover": {
                                        color: "#ef5350"
                                    }
                                }}

                            />
                        </IconButton>
                    </Box>
                )
            })}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1.5 }}>
                <IconButton size="small"
                    sx={{ width: 40, height: 40, minWidth: 40, m: 'auto 0' }}
                    onClick={() => append({ label: "", value: "" })}>
                    <AddOutlined fontSize='small' />
                </IconButton>
            </Box>
            <Controller
                name={`product_informations.[${position}].option.default`}
                control={control}
                render={({ field: { value, onChange } }) => {
                    const selected = options?.find(item => item?.value === value?.value)
                    return (
                        <FormControl fullWidth>
                            <SingleSelectCustomize
                                value={selected}
                                options={options?.filter((item) => !!item?.value && !!item?.label) || []}
                                onChange={(event, newValue) => onChange(newValue || null)}
                                input={{
                                    placeholder: "Giá trị mặc định...",
                                    label: "Giá trị mặc định"
                                }}
                            />
                        </FormControl>
                    )
                }}
            />
        </Box>
    )
}

export default InformationOption