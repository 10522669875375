import { Grid, Typography } from '@mui/material';
import React from 'react';
import NextDayoff from './NextDayoff';
import RecentDayoff from './RecentDayoff';
import Statistical from './Statistical';
import BusinessRecently from "../BusinessRecently"

const Leave = () => {

    return (
        <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Statistical />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Typography sx={{
                    fontWeight: 'medium'
                }}
                    color="primary"
                >Danh sách nghỉ phép</Typography>
                <RecentDayoff />
                <NextDayoff />
                <BusinessRecently />
            </Grid>
        </Grid>
    )
}

export default Leave