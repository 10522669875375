import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const EmployeeExperienceInformation = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Box
      sx={{
        p: 1.5,
        border: "1px solid rgba(0, 0, 0, 0.18)",
        borderRadius: 1,
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: 16, md: 18 },
          fontWeight: "medium",
          mb: 1,
        }}
      >
        Chuyên Ngành & Kinh Nghiệm
      </Typography>
      <Grid container spacing={{ xs: 1.5, md: 2 }}>
        <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
          <Controller
            name="employee_graduated"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    value={value}
                    label="Trường Đại Học..."
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={onChange}
                    size="small"
                    placeholder="Trường..."
                    error={!!errors?.employee_health_insurance}
                    helperText={errors?.employee_health_insurance?.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
          <Controller
            name="employee_major"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    value={value}
                    label="Chuyên ngành (*)"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={onChange}
                    size="small"
                    placeholder="Chuyên ngành..."
                    error={!!errors?.employee_major}
                    helperText={errors?.employee_major?.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="employee_experience"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    value={value}
                    onChange={onChange}
                    size="small"
                    multiline
                    minRows={3}
                    placeholder="Mô tả kinh nghiệm..."
                    error={!!errors?.employee_experience}
                    helperText={errors?.employee_experience?.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmployeeExperienceInformation;
