import { UNLIMITED } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import { RemoveOutlined } from '@mui/icons-material'
import AddOutlined from '@mui/icons-material/AddOutlined'
import { Box, Collapse, FormControl, IconButton, TextField } from '@mui/material'
import React from 'react'

const ClusterPriceExceed = ({ data, setState }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            p: '4px 0',
            minHeight: '50px',
            height: '100%'
        }}>
            {data?.price_ranges?.map((range, index) => {
                return (
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        width: '100%',
                        flexDirection: 'row',
                        gap: 1.5,
                    }}
                        key={index}
                    >
                        <Collapse in={data?.payment_config_method === 'progressive'} sx={{ minWidth: 231.5 }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 1.5,
                            }}>
                                <FormControl sx={{ minWidth: 100 }}>
                                    <TextField size='small'
                                        InputProps={{
                                            inputComponent: NumericFormatCustom,
                                        }}
                                        placeholder='Từ...'
                                        fullWidth
                                        variant='outlined'
                                        sx={{ bgcolor: "#FFF" }}
                                        value={range?.range_start}
                                        onChange={(event) => {
                                            setState(preData => [
                                                ...preData?.slice(0, data?.id - 1),
                                                {
                                                    ...data,
                                                    price_ranges: [
                                                        ...data?.price_ranges?.slice(0, index),
                                                        {
                                                            ...range,
                                                            range_start: event?.target?.value
                                                        },
                                                        ...data?.price_ranges?.slice(index + 1)
                                                    ]
                                                },
                                                ...preData?.slice(data?.id + 1)
                                            ])

                                        }}
                                    />
                                </FormControl>
                                <FormControl sx={{ minWidth: 130 }}>
                                    <TextField size='small'
                                        InputProps={{
                                            inputComponent: parseFloat(range?.range_end) >= UNLIMITED ? 'input' : NumericFormatCustom,
                                        }}
                                        placeholder='Đến...'
                                        fullWidth
                                        variant='outlined'
                                        sx={{ bgcolor: "#FFF" }}
                                        value={parseFloat(range?.range_end) >= UNLIMITED ? 'UNLIMITED' : range?.range_end}
                                        onChange={(event) => {
                                            setState(preData => [
                                                ...preData?.slice(0, data?.id),
                                                {
                                                    ...data,
                                                    price_ranges: [
                                                        ...data?.price_ranges?.slice(0, index),
                                                        {
                                                            ...range,
                                                            range_end: event?.target?.value
                                                        },
                                                        ...data?.price_ranges?.slice(index + 1)
                                                    ]
                                                },
                                                ...preData?.slice(data?.id + 1)
                                            ])
                                        }}
                                    />
                                </FormControl>
                            </Box>
                        </Collapse>
                        <FormControl fullWidth>
                            <TextField size='small'
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                }}
                                fullWidth
                                variant='outlined'
                                sx={{ bgcolor: "#FFF" }}
                                value={range?.pricing}
                                onChange={(event) => {
                                    setState(preData => [
                                        ...preData?.slice(0, data?.id),
                                        {
                                            ...data,
                                            price_ranges: [
                                                ...data?.price_ranges?.slice(0, index),
                                                {
                                                    ...range,
                                                    pricing: event?.target?.value
                                                },
                                                ...data?.price_ranges?.slice(index + 1)
                                            ]
                                        },
                                        ...preData?.slice(data?.id + 1)
                                    ])
                                }}
                                placeholder='Giá...'
                            />
                        </FormControl>
                        <Collapse in={(data?.payment_config_method === 'progressive')} sx={{ minWidth: 32 }}>
                            {(index === 0) &&
                                <IconButton size='small' sx={{
                                    border: '1px solid rgba(0, 0, 0, 0.18)',
                                    width: 32,
                                    minWidth: 32
                                }}
                                    onClick={() => {
                                        setState(preData => [
                                            ...preData?.slice(0, data?.id),
                                            {
                                                ...data,
                                                price_ranges: [
                                                    ...data?.price_ranges, {
                                                        range_id: -1,
                                                        price_id: -1,
                                                        range_start: 0,
                                                        range_end: UNLIMITED,
                                                        pricing: 0
                                                    }]
                                            },
                                            ...preData?.slice(data?.id + 1)
                                        ])
                                    }}
                                >
                                    <AddOutlined fontSize='small' />
                                </IconButton>}
                            {(data?.price_ranges?.length - 1 === index && data?.price_ranges?.length > 1) && <IconButton size='small' sx={{
                                border: '1px solid rgba(0, 0, 0, 0.18)',
                                width: 32,
                                minWidth: 32
                            }}
                                onClick={() => {
                                    setState(preData => [
                                        ...preData?.slice(0, data?.id),
                                        {
                                            ...data,
                                            price_ranges: [...data?.price_ranges?.slice(0, index)]
                                        },
                                        ...preData?.slice(data?.id + 1)
                                    ])
                                }}
                            >
                                <RemoveOutlined fontSize='small' />
                            </IconButton>}
                        </Collapse>
                    </Box>
                )
            })}

        </Box>
    )
}

export default ClusterPriceExceed