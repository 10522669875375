import { Grid, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { CustomerComponents } from "../Components";
import { Fragment } from "react";
import ErrorHelperText from "@components/shared/ErrorHelperText";

const SecondStep = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const CUSTOMER_FIELDS = [
    {
      field: "customer.customer_name",
      component: "TextField",
      required: true,
      label: "Họ & Tên",
      isError: !!errors?.customer?.customer_name,
      message: errors?.customer?.customer_name?.message,
    },
    {
      field: "customer.customer_email",
      component: "TextField",
      required: true,
      label: "Email",
      isError: !!errors?.customer?.customer_email,
      message: errors?.customer?.customer_email?.message,
    },
    {
      field: "customer.customer_phone",
      component: "TextField",
      label: "Số Điện Thoại",
    },
    {
      field: "customer.customer_position",
      component: "TextField",
      required: true,
      label: "Chức Vụ",
      isError: !!errors?.customer?.customer_position,
      message: errors?.customer?.customer_position?.message,
    },
    {
      field: "customer.customer_contact_social",
      component: "Contact",
      required: true,
      label: "Kênh Liên Hệ Chính",
      props: {
        name: "customer.customer_contact_social",
      },
      isError: !!errors?.customer?.customer_contact_social,
      message: errors?.customer?.customer_contact_social?.message,
    },
    {
      field: "customer.customer_dob",
      component: "DatePicker",
      label: "Ngày Sinh",
    },
    {
      field: "customer.customer_note",
      component: "TextField",
      label: "Ghi Chú",
      cols: 12,
      props: {
        placeholder: "Nhập ghi chú khách hàng",
        multiline: true,
      },
    },
  ];

  return (
    <Grid container spacing={2}>
      {CUSTOMER_FIELDS?.map((field, index) => {
        if (field.hide) return null;
        const Component = CustomerComponents[field.component] || null;
        if (!Component) return null;
        return (
          <Grid item xs={field.cols || 12} md={field?.cols || 4} key={index}>
            <Typography sx={{ fontSize: 14, mb: 0.5 }} color="text.grey">
              {field.label} {field.required ? " *" : ""}
            </Typography>
            <Controller
              name={field.field}
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <Fragment>
                    <Component
                      value={value}
                      onChange={onChange}
                      {...field.props}
                    />
                    {field?.isError && (
                      <ErrorHelperText message={field?.message} />
                    )}
                  </Fragment>
                );
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
export default SecondStep;
