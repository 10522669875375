import React from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

const withAuth =
  (Component) =>
  ({ ...props }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams()

    const accessToken = localStorage.getItem("SA") || "";

    React.useEffect(() => {
      if (!accessToken) navigate(
        `/auth/login?redirect=${encodeURIComponent(
          `${location?.pathname}?${searchParams?.toString()}`
        )}`
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Component {...props} />;
  };

export default withAuth;
