import Layout from "./Layout"
import CustomerInformation from "./CustomerInformation"
import CustomerJourney from "./CustomerJourney"
import Finance from "./CustomerFinance"
import Service from "./CustomerService"
import Contract from "./CustomerContract"
import Mailer from "./CustomerMailer"
import Mailers from "./ManagementServiceInvoice"

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	Layout,
	CustomerInformation,
	CustomerJourney,
	Contract,
	Finance,
	Service,
	Mailer,
	Mailers,
}