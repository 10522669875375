import ErrorHelperText from "@components/shared/ErrorHelperText";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { FormControl } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

export const PRIVATE_PACKAGE_PAYMENT_TYPES = [
  { value: true, label: "Trả trước" },
  { value: false, label: "Trả sau" },
];

const PaymentType = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      name="product_paid"
      control={control}
      render={({ field: { value, onChange } }) => {
        const selected =
          PRIVATE_PACKAGE_PAYMENT_TYPES.find((item) => item?.value === value) ||
          null;
        return (
          <FormControl fullWidth sx={{ mt: 1.5 }}>
            <SingleSelectCustomize
              options={PRIVATE_PACKAGE_PAYMENT_TYPES}
              value={selected}
              input={{
                label: "Hình thức thanh toán",
                placeholder: "Nhập hình thức thanh toán...",
              }}
              onChange={(event, newValue) => onChange(newValue?.value || -1)}
            />
            {!!errors?.product_payment_type && (
              <ErrorHelperText
                message={errors?.product_payment_type?.message}
              />
            )}
          </FormControl>
        );
      }}
    />
  );
};
export default PaymentType;
