import { PRODUCT_UNITS } from "@/libs/constants";
import NumericFormatCustom from "@components/NumericFormatCustom";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { AddOutlined, DeleteOutlineOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import InformationOption from "../InformationOptions";

export const PRODUCT_INFORMATION_TYPES = [
  { value: "os", label: "Operator System" },
  { value: "datacenter", label: "Datacenter" },
  { value: "cpu", label: "CPU" },
  { value: "ssl", label: "SSL" },
  { value: "mysql", label: "MySQL" },
  { value: "subdomain", label: "Subdomain" },
  { value: "bandwidth", label: "Bandwidth" },
  { value: "website", label: "Website" },
  { value: "storage", label: "Storage" },
  { value: "cloudstorage", label: "CloudStorage" },
  { value: "cloudsecurities", label: "CloudSecurities" },
  { value: "ram", label: "RAM" },
  { value: "disk", label: "DISK" },
  { value: "ip", label: "IP" },
  { value: "software", label: "Software" },
  { value: "u", label: "Số U" },
  { value: "power", label: "Power" },
  { value: "inbound", label: "In Bound" },
  { value: "outbound", label: "Out Bound" },
  { value: "mail-account", label: "Tài khoản email" },
  { value: "mail-address", label: "Địa chỉ email" },
  { value: "mail-sent", label: "Số lượng mail được gửi trong 1 ngày" },
  { value: "mail-sent-max", label: "Dung lượng email gửi tối đa" },
  { value: "mail-receiver-max", label: "Dung lượng email nhận tối đa" },
  { value: "mail-security", label: "Bảo mật email" },
  { value: "account", label: "Bảo vệ một chiều" },
  { value: "account-v2", label: "Bảo vệ hai chiều" },
  { value: "addon-domain", label: "Domain" },
  { value: "fqdn", label: "FQDN" },
  { value: "tb", label: "TB (Traffic" },
  { value: "milion-requests", label: "Milion Requests" },
  { value: "rule", label: "Rules(s)" },
  { value: "pack/site", label: "Pack/ Site" },
  { value: "number-of-cdns", label: "Number of CDNs" },
  { value: "milion-queries", label: "Milion Queries" },
];

const ProductInformation = () => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "product_informations",
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: 1,
        mt: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {fields &&
          fields.map((field, index) => {
            return (
              <Box
                sx={{
                  p: 1.5,
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", md: "row" },
                  gap: 0.5,
                  border: "1px solid rgba(0, 0, 0, 0.18)",
                  borderRadius: "4px",
                }}
                key={field.id}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 1.5,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      gap: 1,
                    }}
                  >
                    <Controller
                      name={`product_informations.[${index}].name`}
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <FormControl fullWidth>
                            <TextField
                              size="small"
                              placeholder="Tên cấu hình..."
                              value={value}
                              label="Tên *"
                              error={
                                !!errors?.product_informations?.[index]?.name
                              }
                              helperText={
                                errors?.product_informations?.[index]?.name
                                  ?.message
                              }
                              InputLabelProps={{ shrink: true }}
                              onChange={onChange}
                            />
                          </FormControl>
                        );
                      }}
                    />
                    <Controller
                      name={`product_informations.[${index}].product_infomation_type`}
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        const selected = PRODUCT_INFORMATION_TYPES?.find(
                          (item) => item.value === value
                        );
                        return (
                          <FormControl fullWidth>
                            <SingleSelectCustomize
                              value={selected}
                              input={{
                                placeholder: "Loại...",
                                label: "Loại...",
                              }}
                              options={PRODUCT_INFORMATION_TYPES}
                              onChange={(event, newValue) =>
                                onChange(newValue?.value || -1)
                              }
                            />
                          </FormControl>
                        );
                      }}
                    />
                    <Controller
                      name={`product_informations.[${index}].value`}
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <FormControl fullWidth>
                            <TextField
                              size="small"
                              value={value}
                              label="Giá trị"
                              placeholder="Example: 1"
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                inputComponent: NumericFormatCustom,
                              }}
                              onChange={onChange}
                            />
                          </FormControl>
                        );
                      }}
                    />
                    <Controller
                      name={`product_informations.[${index}].unit`}
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        const selected =
                          PRODUCT_UNITS.find((item) => item.value === value) ||
                          null;
                        return (
                          <FormControl
                            fullWidth
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 1,
                            }}
                          >
                            <SingleSelectCustomize
                              fullWidth
                              options={PRODUCT_UNITS}
                              value={selected}
                              input={{
                                label: "Đơn vị tính ",
                                placeholder: "Chọn đơn vị tính...",
                              }}
                              onChange={(event, newValue) =>
                                onChange(newValue?.value || -1)
                              }
                            />
                          </FormControl>
                        );
                      }}
                    />
                  </Box>
                  <Controller
                    name={`product_informations.[${index}].subtitle`}
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <FormControl fullWidth>
                          <TextField
                            size="small"
                            placeholder="Mô tả..."
                            value={value}
                            label="Subtitle *"
                            error={
                              !!errors?.product_informations?.[index]?.subtitle
                            }
                            helperText={
                              errors?.product_informations?.[index]?.subtitle
                                ?.message
                            }
                            InputLabelProps={{ shrink: true }}
                            onChange={onChange}
                          />
                        </FormControl>
                      );
                    }}
                  />
                  <Collapse
                    in={
                      watch(`product_informations.[${index}].customize`) &&
                      !watch(`product_informations.[${index}].optional`)
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        gap: 1,
                      }}
                    >
                      <Controller
                        name={`product_informations.[${index}].min`}
                        control={control}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <FormControl fullWidth>
                              <TextField
                                size="small"
                                value={value}
                                label="Minimum"
                                placeholder="Example: 1"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                }}
                                onChange={onChange}
                              />
                            </FormControl>
                          );
                        }}
                      />
                      <Controller
                        name={`product_informations.[${index}].max`}
                        control={control}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <FormControl fullWidth>
                              <TextField
                                size="small"
                                value={value}
                                label="Maximum"
                                placeholder="Example: 1"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                }}
                                onChange={onChange}
                              />
                            </FormControl>
                          );
                        }}
                      />
                    </Box>
                  </Collapse>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        gap: 1,
                        m: "0 0 auto 0",
                      }}
                    >
                      <Controller
                        name={`product_informations.[${index}].customize`}
                        control={control}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <FormControl fullWidth>
                              <FormControlLabel
                                label="Customize"
                                control={
                                  <Checkbox
                                    size="small"
                                    checked={!!value}
                                    onChange={(event) => {
                                      const checked = event?.target?.checked;
                                      onChange(checked);
                                      if (
                                        watch(
                                          `product_informations.[${index}].optional`
                                        )
                                      ) {
                                        return setValue(
                                          `product_informations.[${index}].optional`,
                                          false
                                        );
                                      }
                                    }}
                                  />
                                }
                              />
                            </FormControl>
                          );
                        }}
                      />
                      <Controller
                        name={`product_informations.[${index}].optional`}
                        control={control}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <FormControl
                              fullWidth
                              disabled={
                                !watch(
                                  `product_informations.[${index}].customize`
                                )
                              }
                            >
                              <FormControlLabel
                                label="Thêm option tuỳ chỉnh"
                                control={
                                  <Checkbox
                                    size="small"
                                    checked={value}
                                    onChange={onChange}
                                  />
                                }
                              />
                            </FormControl>
                          );
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        minWidth: { xs: "100%", md: "calc((100% - 8px) / 2)" },
                      }}
                    >
                      <Collapse
                        in={watch(`product_informations.[${index}].optional`)}
                      >
                        <InformationOption position={index} />
                      </Collapse>
                    </Box>
                  </Box>
                </Box>
                <IconButton
                  size="small"
                  sx={{
                    m: "0 0 auto auto",
                  }}
                  onClick={() => remove(index)}
                >
                  <DeleteOutlineOutlined
                    fontSize="small"
                    sx={{
                      ":hover": {
                        color: "#ef5350",
                      },
                    }}
                  />
                </IconButton>
              </Box>
            );
          })}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="small"
            startIcon={<AddOutlined fontSize="small" />}
            sx={{
              minWidth: 150,
              m: "auto 0",
              height: 36,
            }}
            variant="outlined"
            onClick={() => append({ name: "", value: 0, unit: "qty" })}
          >
            thuộc tính
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductInformation;
