import CustomerContactApi from '@/apis/beta/Customer/CustomerContactApi'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { FormControl } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ContractContact = ({ customerId = -1 }) => {
    const { control, setValue } = useFormContext()
    const { data: contacts } = CustomerContactApi.List({ customerId: customerId })
    return (
        <Controller
            name="customer_contact_id"
            control={control}
            render={({ field: { value, onChange } }) => {
                return (
                    <FormControl fullWidth>
                        <SingleSelectCustomize
                            options={[...contacts]}
                            value={[...contacts]?.find(contact => contact?.customer_contact_id === value) || null}
                            input={{
                                label: 'Người Quản Lý/ Tiếp Nhận',
                                placeholder: 'Chọn thông tin người tiếp nhận hợp đồng...'
                            }}
                            formatCollection={{
                                value: 'customer_contact_id',
                                label: 'name'
                            }}
                            compareAttributes={['name']}
                            onChange={(_, newValue) => {
                                onChange(newValue?.customer_contact_id || '')
                                setValue('customer_contact_name', newValue?.name)
                            }}

                        />
                    </FormControl>
                )
            }}
        />

    )
}

export default withSuspense(ContractContact, MuiSkeleton["TextField"])