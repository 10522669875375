import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import { queryClientRefetcher } from "@/functions";
import NumericFormatCustom from "@components/NumericFormatCustom";
import { useNotificationContext } from "@contexts/notification";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

const CreditModal = ({ commitCredit = 0, onClose, customerId = -1 }) => {
  const { setNotification } = useNotificationContext();
  const queryClient = useQueryClient();

  const [credit, setCredit] = useState(commitCredit);

  const { mutateAsync, isLoading } = useMutation(
    CustomerApi.ChangeCreditCommitment
  );

  const onSubmit = async () => {
    const response = await mutateAsync({
      customerId,
      commitmentCredit: credit,
    });
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: "Cập nhật mốc ký quỹ thất bại.",
        severity: "error",
      });
    }
    queryClientRefetcher(queryClient, ["customer.credit.commitment"]);
    setNotification({
      open: true,
      message: "Cập nhật mốc ký quỹ thành công.",
      severity: "success",
    });
    return onClose();
  };
  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <DialogTitle>Chỉnh sửa Mốc Ký Quỹ</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 1.25 }} />
        <FormControl fullWidth mt={2}>
          <TextField
            label="Mốc ký quỹ cố định"
            InputLabelProps={{ shrink: true }}
            value={credit}
            InputProps={{
              inputComponent: NumericFormatCustom,
            }}
            helperText={
              "Khi số dư ký quỹ khách hàng xuống dưới 30% mốc này, hệ thống sẽ gửi email thông báo cho CS Team"
            }
            placeholder="Nhập mốc ký quỹ của khách hàng..."
            onChange={(event) => setCredit(event?.target?.value)}
            size="small"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          size="small"
          disabled={isLoading}
          variant="outlined"
        >
          Hủy
        </Button>
        <Button
          disabled={isLoading}
          startIcon={
            isLoading && (
              <CircularProgress size={14} sx={{ color: "#777680" }} />
            )
          }
          onClick={onSubmit}
          size="small"
          variant="contained"
        >
          Lưu Thay Đổi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreditModal;
