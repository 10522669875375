import { handleRedirectOpenLink } from "@/functions";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

const CreateBusiness = ({ onClose, customer, ...metadata }) => {
    console.log(`metadata`, metadata)
    const handleClick = () => {
        onClose();
        return handleRedirectOpenLink(
            `/businesses/new?customer_id=${customer?.customer_id}&journey_id=${metadata?.journey_id}&journey_update_id=${metadata?.journey_update_id}`
        );
    };
    return (
        <Dialog open={true} fullWidth maxWidth="sm">
            <DialogTitle>Tạo Phiếu Công Tác Gặp Khách Hàng</DialogTitle>
            <DialogActions>
                <Button size="small" variant="outlined" onClick={onClose}>
                    Để Sau
                </Button>
                <Button size="small" variant="contained" onClick={handleClick}>
                    Tạo Phiếu Công Tác
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateBusiness;
