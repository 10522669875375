import MuiScrollTabs from '@/components/shared/MuiScrollTabs'
import { useLayoutContext } from '@/contexts/layout'
import { Box, Typography } from '@mui/material'
import React from 'react'

const INITIAL_REPORT_TABS = [
    {
        value: 0,
        name: 'report-summary',
        label: "Index",
        pathname: ''
    },
    {
        value: 1,
        name: 'report-sale',
        label: "Sales",
        pathname: 'sales'
    },
    {
        value: 2,
        name: 'report-marketing',
        label: "Marketings",
        pathname: 'marketings'
    },
    {
        value: 3,
        name: "report-warehouse",
        label: "KHO",
        pathname: 'warehouses'
    },
]

const ReportTabs = () => {
    const { permissions } = useLayoutContext();
    const reportFeature = permissions?.find((f) => f.feature === 'report')?.actions?.map(({ action }) => action) || []
    const acceptTabs = INITIAL_REPORT_TABS.filter((t) => reportFeature?.includes(t.name))
    return (
        <Box sx={{ mb: 2 }}>
            <Typography sx={{ mb: 2, fontSize: 14, color: 'grey.700' }}>
                Reports
            </Typography>

            <MuiScrollTabs prefix={`reports`}
                initialTabs={acceptTabs}
            />
        </Box>
    )
}

export default ReportTabs