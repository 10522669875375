import { AUTH_URL } from "@/libs/constants";
import { requestWithToken } from '../functions';
import useQueryWithCustomized from './useQueryWithCustomized';

const resolveUserInformation = async () =>
  await requestWithToken(`${AUTH_URL}/auth/v2/user_info`)
    .then(({ data }) => {
      if (data?.error) return data;

      return data;
    })
    .catch((error) => {
      return error.response;
    });

const useAuth = () => {
  const { data } = useQueryWithCustomized(
    'user.information',
    async () => await resolveUserInformation(),
    {
      staleTime: 10 * 60 * 1000,
      enabled: !!localStorage.getItem('SA'),
    }
  );

  return [data?.data?.user];
};

export default useAuth;
