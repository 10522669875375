import { requestWithToken } from "@/functions";
import { API_SERVICE_URL } from "@/libs/constants";
import { useQuery } from "react-query";
import qs from 'query-string'

async function resolveVMSTranscoding(url = '') {
    const response = await requestWithToken(url)

    return response?.data?.data
}

export function resolveServiceAnalyticTranscoding(service_uuid, query) {
    const current = qs.stringify(query);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery(["customer.services.transcoding.vms.duration", service_uuid, query], () => Promise.all([
        resolveVMSTranscoding(`${API_SERVICE_URL}/viewers/services/${service_uuid}/analytic/usages/vms/durations/transcoding?${current}`)
    ]), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        staleTime: 30 * 60 * 1000,
        enabled: typeof service_uuid !== "undefined",
        suspense: true
    })
}


export function resolveServiceAnalyticTranscodingUsages(service_uuid, query) {
    const current = qs.stringify(query);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery(["customer.services.transcoding.vms.usages", service_uuid, query], () => Promise.all([
        resolveVMSTranscoding(`${API_SERVICE_URL}/viewers/services/${service_uuid}/analytic/usages/vms/transcoding?${current}`)
    ]), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        staleTime: 30 * 60 * 1000,
        enabled: typeof service_uuid !== "undefined",
        suspense: true
    })
}
