/* eslint-disable react-hooks/exhaustive-deps */
import { UNLIMITED } from "@/libs/constants";
import NumericFormatCustom from "@components/NumericFormatCustom";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import { FormControl, TextField } from "@mui/material";
import React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

const ProductPricingAmount = () => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const productPricingSteps = useWatch({
    control: control,
    name: "product_pricing_configures.product_pricing_steps",
  });

  const onChangeConfigures = React.useCallback(() => {
    const commitment = parseFloat(watch(`product_pricing_configures.value`));
    if (productPricingSteps.length > 0 && commitment >= 0) {
      const idx = productPricingSteps?.findIndex(
        (item) =>
          parseFloat(item?.start) <= commitment &&
          parseFloat(item?.end) >= commitment
      );
      if (idx > -1) {
        setValue(
          "product_pricing_configures.pricing",
          productPricingSteps[idx]?.pricing || 0
        );
      } else {
        setValue(
          "product_pricing_configures.pricing",
          productPricingSteps[productPricingSteps.length - 1]?.pricing || 0
        );
      }
    }
    if (watch(`product_payment_type`) !== "prevpaid-crosscheck") {
      return setValue(`product_pricing_amount`, 0);
    }
    if (commitment >= UNLIMITED) {
      setValue(
        `product_pricing_amount`,
        parseFloat(watch(`product_pricing_configures.pricing_amount`))
      );
    } else {
      setValue(
        `product_pricing_amount`,
        Math.round(
          commitment *
            parseFloat(watch(`product_pricing_configures.pricing_apply`))
        )
      );
    }
  }, [
    watch(`product_payment_type`),
    watch(`product_pricing_configures.value`),
    watch(`product_pricing_configures.commitment_term`),
    watch(`product_pricing_configures.pricing_amount`),
    watch(`product_pricing_configures.pricing_apply`),
    productPricingSteps,
  ]);

  React.useEffect(() => {
    onChangeConfigures();
  }, [onChangeConfigures]);

  return (
    <Controller
      name="product_pricing_amount"
      control={control}
      render={({ field: { value } }) => {
        return (
          <FormControl fullWidth>
            <TextField
              size="small"
              label="Tạm tính *"
              value={value}
              placeholder="0"
              disabled
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
            {!!errors?.product_pricing_amount && (
              <ErrorHelperText
                message={errors?.product_pricing_amount?.message}
              />
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default ProductPricingAmount;
