import MultipleSelectCustomize from '@components/shared/MultipleSelectCustomize'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import React from 'react'
import Employee from './Employee'
import { Typography } from '@mui/material'


export const SelectionUIs = {
    $eq: SingleSelectCustomize,
    $ne: SingleSelectCustomize,
    $in: MultipleSelectCustomize,
    $nin: MultipleSelectCustomize,
    $included: MultipleSelectCustomize,
}

const SCOPES = {
    Employee,
    Default: () => <>
        <Typography>Cột tìm kiếm chưa được hỗ trợ</Typography>
    </>
}

const ServerCollection = ({
    operation = '$eq',
    scope = "Employee",
    value = [],
    onChange = () => { }// methods form setValue(`input.objects`, newValues)
}) => {
    return (
        <>
            {React.createElement(SCOPES[scope || "Default"], {
                operation,
                value: ['$eq', '$ne'].includes(operation) ? (value[0] || null) : value,
                onChange: (newValues) => {
                    if (['$eq', '$ne'].includes(operation)) {
                        return onChange([newValues])
                    }
                    return onChange([...newValues])
                }
            })}
        </>
    )
}

export default ServerCollection