import { Box, Button,CircularProgress, FormControl,TextField, Typography, Drawer } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import DatePicker from '@components/datepickers/DateRangPicker';
import SubcontractApi from "@/apis/beta/Contract/SubcontractApi";
import {useQueryClient } from "react-query";
import { queryClientRefetcher } from "@/functions";
import NumericFormatCustom from '@components/NumericFormatCustom';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useNotificationContext } from "@contexts/notification";
const SubcontractUpdate = ({ onClose, defaultValues = {}}) => {
    const queryClient = useQueryClient();
    const methods = useForm({
      defaultValues: {
        subcontract_code: defaultValues?.subcontract_code,
        subcontract_name: defaultValues?.subcontract_name,
        subcontract_effective_date: defaultValues?.subcontract_effective_date,
        subcontract_expiry_date: defaultValues?.subcontract_expiry_date,
        subcontract_value: defaultValues?.subcontract_value,
        subcontract_note: defaultValues?.subcontract_note,
      },
    });
    const { setNotification } = useNotificationContext();
    const {
      control,
      handleSubmit,
      formState: { errors },
      watch,
      setValue,
    } = methods;
    const [submit, setSubmit] = useState(false);
    const onSubmit = async (values) => {
      try {
        setSubmit(true);
        const response = await SubcontractApi.Update({
          contractId: defaultValues?.contract_id,
          subcontractId: defaultValues.subcontract_id,
          subcontract_code: `${values?.subcontract_code}${values?.subcontract_code_id}`,
          data: {
            ...values
          },
        });
        setSubmit(false)
        if (response?.error) {
            setNotification({
                 open: true,
                 message: "Cập nhật phụ lục hợp đồng thất bại.",
                 severity: "error",
            })
        } else {
            setNotification({
                open: true,
                message: "Cập nhật thông tin phụ lục hợp đồng thành công!",
                severity: "success",
            })
            callback();
            onClose();
        }
        } catch (error) {
        console.error('Update failed:', error);
      };
    };

    const callback = () => {
        queryClientRefetcher(queryClient, [
          "contract.subcontract.list",
          "subcontract_code_number"
        ])
      }
    
    return (
      <Drawer open={true} anchor="right" onClose={onClose}>
        <FormProvider {...methods}>
          <Box
            sx={{ p: 3, width: { xs: '100%', md: 600 } }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Typography sx={{ textTransform: 'uppercase', fontWeight: 'medium', mb: 2 }}>
              Chỉnh Sửa Phụ Lục Hợp Đồng
            </Typography>
  
            <Typography sx={{ fontWeight: 'medium', mb: 1 }}>Số Phụ Lục</Typography>
  
            <Controller
              control={control}
              name="subcontract_code"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.subcontract_code}
                  helperText={errors?.subcontract_code?.message}
                  disabled
                  placeholder="Nhập số phụ lục"
                  size="small"
                  fullWidth
                  sx={{ mb: 2 }}
                />
              )}
            />
  
            <Controller
              control={control}
              name="subcontract_name"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.subcontract_name}
                  helperText={errors?.subcontract_name?.message}
                  placeholder="Nhập tên phụ lục hợp đồng..."
                  size="small"
                  fullWidth
                  label="Tên Phụ Lục Hợp Đồng *"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
  
            <FormControl fullWidth sx={{ mt: 2 }}>
                <DatePicker
                    value={[
                    dayjs(watch(`subcontract_effective_date`)).format('YYYY-MM-DD'),
                    dayjs(watch(`subcontract_expiry_date`)).format('YYYY-MM-DD'),
                    ]}
                    handler={(newDates) => {
                    setValue(`subcontract_effective_date`, dayjs(newDates[0]).format('YYYY-MM-DD'));
                    setValue(`subcontract_expiry_date`, dayjs(newDates[1]).format('YYYY-MM-DD'));
                    }}
                />
            </FormControl>
  
            <Controller
              control={control}
              name="subcontract_value"
              render={({ field: { value, onChange } }) => (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <TextField
                    label="Giá trị hợp đồng *"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    value={value}
                    onChange={onChange}
                    InputProps={{ inputComponent: NumericFormatCustom }}
                    placeholder="Nhập giá trị hợp đồng..."
                  />
                </FormControl>
              )}
            />
  
            <Controller
              control={control}
              name="subcontract_note"
              render={({ field: { value, onChange } }) => (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <TextField
                    label="Ghi chú *"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    size="small"
                    minRows={3}
                    value={value}
                    onChange={onChange}
                    placeholder="Nhập ghi chú hợp đồng..."
                  />
                </FormControl>
              )}
            />
  
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 2,
                gap: 2,
                flexDirection: { xs: 'column', md: 'row' },
              }}
            >
              <Button
                size="small"
                sx={{ height: 36, mr: 2 }}
                onClick={onClose}
                variant="text"
                disabled={submit}
              >
                Huỷ Thao Tác
              </Button>
              <Button
                size="small"
                sx={{ height: 36 }}
                startIcon={
                    submit && (
                      <CircularProgress
                        sx={{ m: "auto 0", color: "#777680" }}
                        size={14}
                      />
                    )
                  }
                variant="contained"
                type="submit"
                disabled={submit}
              >
                Cập Nhật
              </Button>
            </Box>
          </Box>
        </FormProvider>
      </Drawer>
    );
  };

  export default SubcontractUpdate