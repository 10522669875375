import { CellLink } from "@components/DataGrid/Cell"
import CellCheckbox from "@components/DataGrid/Cell/CellCheckbox"
import ScopeActions from "./Actions"
import Assignees from "./helpers/Assignees"
import Departments from "./helpers/Departments"

const SCOPE_MODULES = [
    { value: 'SERVICE', label: 'Dịch Vụ' },
    { value: 'CUSTOMER', label: 'Khách Hàng' },
    { value: 'INVOICE', label: 'Hoá Đơn' },
    { value: 'DAY-OFF', label: 'Nghỉ Phép' },
    { value: 'BUSINESS', label: 'Công Tác' },
    { value: 'CONTRACT', label: 'Hợp Đồng' }
]

export const SCOPE_APPLIEDS = [
    { value: 'COMPANY', label: 'Toàn Bộ Công Ty' },
    { value: 'DEPARTMENT', label: 'Theo Phòng Ban' },
    { value: 'TEAM', label: 'Theo Team' }
]

const INITIAL_COLUMNS = [
    {
        field: 'scope_name',
        headerName: 'Tên Quyền',
        width: 300,
        valueGetter: ({ row }) => {
            return {
                label: row?.scope_name,
                href: ["scopes", row?.scope_id]
            }
        },
        renderCell: ({ value }) => <CellLink data={value} />
    }, {
        field: 'scope_module',
        headerName: 'Tính Năng | Module',
        width: 150,
        valueGetter: ({ row }) => {
            return SCOPE_MODULES?.find(module => module.value === row?.scope_module)
        },
        renderCell: ({ value }) => value?.label || ''
    }, {
        field: 'scope_applied',
        headerName: 'Phạm Vi Truy Cập',
        width: 180,
        valueGetter: ({ row }) => {
            return SCOPE_APPLIEDS?.find(module => module.value === row?.scope_applied)
        },
        renderCell: ({ value }) => value?.label || ''
    }, {
        field: 'scope_readable',
        headerName: 'Xem',
        width: 80,
        renderCell: ({ value }) => <CellCheckbox checked={value} />
    }, {
        field: 'scope_writable',
        headerName: 'Modified',
        width: 80,
        renderCell: ({ value }) => <CellCheckbox checked={value} />
    }, {
        field: 'scope_executable',
        headerName: 'Executable',
        width: 95,
        renderCell: ({ value }) => <CellCheckbox checked={value} />
    }, {
        field: 'scope_assignees',
        headerName: 'Người được phép truy cập',
        width: 200,
        renderCell: ({ value: employees = [] }) => <Assignees employees={employees} />
    }, {
        field: 'ignores',
        headerName: 'Tệp Thông Tin Không Được Truy Cập',
        width: 280,
        renderCell: ({ value: employees = [] }) => <Assignees
            label="Tệp Thông Tin Không Được Truy Cập"
            employees={employees} />
    }, {
        field: 'teammates',
        headerName: 'Tệp Thông Tin Được Truy Cập',
        width: 250,
        renderCell: ({ value: employees = [], row }) => {
            if (row?.scope_applied === SCOPE_APPLIEDS[0]?.value) return null;
            if (row?.scope_applied === SCOPE_APPLIEDS[1]?.value) return <Departments departments={row?.departments} />;
            return <Assignees
                label="Tệp Thông Tin Được Truy Cập"
                employees={employees} />
        }
    }, {
        field: 'reject_columns',
        headerName: 'Trường (Thuộc Tính) Không Được Phép Truy Cập',
        width: 300,
        renderCell: ({ value: fields }) => fields?.join(', ')
    }, {
        field: 'actions',
        headerName: '',
        width: 40,
        renderCell: ({ row }) => <ScopeActions scope={row}
            actions={[
                'assignee', 'destroy'
            ]} />
    }
]

const buildScopeRows = ({
    scopes = [],
    employees = [],
    departments = []
}) => {
    return scopes?.map(scope => ({
        id: scope?.scope_id,
        ...scope,
        scope_assignees: employees?.filter(employee => scope?.scope_assignees?.includes(employee?.employee_id)),
        ignores: employees?.filter(employee => scope?.ignores?.includes(employee?.employee_id)),
        teammates: employees?.filter(employee => scope?.teammates?.includes(employee?.employee_id)),
        departments: departments?.filter(department => scope?.departments?.includes(department?.department_id)),
    })) || []  // Return empty array if scopes are not provided or empty array.
}

const filterables = [
    { field: "scopes.scope_name", label: "Tên Quyền", operators: { type: "string" } },
    { field: "scopes.scope_module", label: "Tên Quyền", operators: { type: "object", collections: SCOPE_MODULES } },
    { field: "scopes.scope_applied", label: "Phạm Vi Truy Cập", operators: { type: "object", collections: SCOPE_APPLIEDS } },
]


const ScopeManagementLibs = {
    columns: INITIAL_COLUMNS,
    rows: buildScopeRows,
    filterables: filterables,
}
export default ScopeManagementLibs