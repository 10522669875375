import React from 'react'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'

const FormatterReactRouterLink = ({
    data = { label: '', href: [] },
    options = {}
}) => {

    if (data?.href?.length === 0) return (
        <Typography sx={{
            fontSize: 14,
            textDecoration: data?.label !== "" ? 'underline' : "none",
            ":hover": { cursor: 'pointer' }
        }} color={data?.label !== "" && 'primary'} >
            {data?.label || '---'}
        </Typography>
    )
    return (
        <Link to={`/${data?.href?.join('/')}`} title={data?.label} replace {...options}>
            <Typography sx={{
                fontSize: 14,
                textDecoration: data?.label !== "" ? 'underline' : "none",
                ":hover": { cursor: 'pointer' }
            }} color={data?.label !== "" && 'primary'} >
                {data?.label || '---'}
            </Typography>
        </Link>
    )
}

export default FormatterReactRouterLink