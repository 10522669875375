// import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
// import BreadCrumb from "@/components/shared/BreadCrumb";
// import { sleep } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
// import { employeeInformationValidator } from "@/libs/validators";
// import { vestResolver } from "@hookform/resolvers/vest";
// import {
//   Box,
//   Button,
//   CircularProgress,
//   Dialog,
//   DialogActions,
//   DialogTitle,
//   Grid,
//   Paper,
//   Step,
//   StepContent,
//   StepLabel,
//   Stepper,
//   Typography,
// } from "@mui/material";
// import _ from "lodash";
// import moment from "moment";
// import React from "react";
// import { FormProvider, useForm, useWatch } from "react-hook-form";
// import { useParams } from "react-router-dom";
// import AccountInformation from "./AccountInformation";
// import BankingAndTaxInformation from "./BankingAndTaxInformation";
// import BasicInfomation from "./BasicInformation";
// import EducationAndExpInformation from "./EducationAndExpInformation";
// import InsuranceInformation from "./InsuranceInformation";
// import MoreInformation from "./MoreInformation";
// import RelationInformation from "./RelationInformation";

import MuiSkeleton from "@components/shared/MuiSkeleton";
import SubmitInformation from "@features/Module/Account/SubmitInformation";

// const Update = () => {
//   const { employee_id } = useParams();
//   const { data: employee } = EmployeeApi.Detail(employee_id);
//   const [submit, setSubmit] = React.useState(false);
//   const defaultSteps = [
//     { label: "Thông Tin Cơ Bản" },
//     { label: "Thông Tin Chi Tiết" },
//     { label: "Thông Tin Người Thân" },
//     { label: "Chuyên Ngành & Kinh Nghiệm Làm Việc" },
//     { label: "Thông Tin Bảo Hiểm" },
//     { label: "Thông Tin Thuế & Ngân Hàng" },
//     { label: "Thông Tin Tài Khoản" },
//   ];
//   const [openDialog, setOpenDialog] = React.useState({
//     open:
//       localStorage.getItem(`employee_id_${employee_id}.data.update`) !== null ||
//       localStorage.getItem(`employee_id_${employee_id}.data.update`) === "",
//   });

//   const methods = useForm({
//     resolver: vestResolver((data) =>
//       employeeInformationValidator({
//         step: activeStep,
//         ...data,
//       })
//     ),
//     defaultValues: {
//       basicInformation: {
//         employee_name: employee?.employee_name,
//         employee_sex: parseInt(employee?.employee_sex) || 1, //gender
//         employee_birthday: employee?.employee_birthday
//           ? moment(employee?.employee_birthday)
//           : moment(),
//         employee_birthplace: employee?.employee_birthplace || "", //noi sinh
//         employee_idno: employee?.employee_idno || "", //CMND - CCCD
//         employee_idno_issued_on: employee?.employee_idno_issued_on
//           ? moment(employee?.employee_idno_issued_on)
//           : moment(), // ngay cap
//         employee_idno_issued_by:
//           employee?.employee_idno_issued_by ||
//           "Cục Cảnh sát quản lý hành chính về trật tự xã hội",
//         employee_phone_country: "VN",
//         employee_phone_number: employee?.employee_phone_number || "",
//         employee_marriage: parseInt(employee?.employee_marriage) || 1, //collection -> tt hôn nhân
//       },
//       moreInformation: {
//         employee_domicile: employee?.employee_domicile || "", //
//         employee_nationality:
//           typeof employee?.employee_nationality === "object"
//             ? employee?.employee_nationality?.value || "VN"
//             : employee?.employee_nationality || "VN", //quốc tịch
//         employee_nation:
//           typeof employee?.employee_nation === "object"
//             ? employee?.employee_nation?.value || "Kinh"
//             : employee?.employee_nationality || "Kinh", //dan toc
//         employee_religion:
//           typeof employee?.employee_religion === "object"
//             ? employee?.employee_religion?.value || "Không"
//             : employee?.employee_religion || "Không", // tôn giáo
//         // thong tin thường trú ?
//         employee_permanent_address: employee?.employee_permanent_address || "",
//         employee_country:
//           typeof employee?.employee_country === "object"
//             ? employee?.employee_country?.value || "VN"
//             : employee?.employee_country || "VN",
//         employee_permanent_address_city:
//           typeof employee?.employee_permanent_address_city === "object"
//             ? employee?.employee_permanent_address_city?.value || -1
//             : employee?.employee_permanent_address_city || -1,
//         employee_permanent_address_district:
//           typeof employee?.employee_permanent_address_district === "object"
//             ? employee?.employee_permanent_address_district?.value || -1
//             : employee?.employee_permanent_address_district || -1,
//         employee_permanent_address_ward:
//           typeof employee?.employee_permanent_address_ward === "object"
//             ? employee?.employee_permanent_address_ward?.value || -1
//             : employee?.employee_permanent_address_ward || -1,
//         // thong tin tam tru ?
//         employee_temporary_active:
//           employee?.employee_temporary_address &&
//           employee?.employee_temporary_address !== ""
//             ? true
//             : false,
//         employee_temporary_address: employee?.employee_temporary_address || "",
//         employee_temporary_address_city:
//           typeof employee?.employee_temporary_address_city === "object"
//             ? employee?.employee_temporary_address_city?.value || -1
//             : employee?.employee_temporary_address_city || -1,
//         employee_temporary_address_district:
//           typeof employee?.employee_temporary_address_district === "object"
//             ? employee?.employee_temporary_address_district?.value || -1
//             : employee?.employee_temporary_address_district || -1,
//         employee_temporary_address_ward:
//           typeof employee?.employee_temporary_address_ward === "object"
//             ? employee?.employee_temporary_address_ward?.value || -1
//             : employee?.employee_temporary_address_ward || -1,
//       },
//       relationInformations: [
//         {
//           employee_homie_name: "",
//           employee_homie_sex: 1,
//           employee_homie_reference: -1,
//           employee_homie_phone_country: "VN",
//           employee_homie_phone: "",
//         },
//       ],
//       educationAndExpInformations: [
//         {
//           employee_major: employee?.employee_major || "", // chuyên ngành
//           employee_experience: employee?.employee_experience || "", // KNLV
//           employee_graduated: employee?.employee_experience || "", //Trướng
//         },
//       ],
//       insuranceInformation: {
//         employee_health_insurance: employee?.employee_health_insurance || "", //
//         employee_social_insurance: employee?.employee_social_insurance || "", //BHXH
//         employee_signup_hospital: employee?.employee_signup_hospital || "", //nơi đăng ký khám chữa bệnh
//       },
//       bankingAndTaxInformation: {
//         employee_tin: employee?.employee_tin || "", //MST cá nhân
//         employee_bank_name: employee?.employee_bank_name || "techcombank",
//         employee_account_bank_number:
//           employee?.employee_account_bank_number || "",
//         employee_bank_branch: employee?.employee_bank_branch || "", // chi nhanh ngan hang -> collections
//       },
//       accountInformation: {
//         employee_email: employee?.employee_email || "",
//         employee_email_company: employee?.employee_email_company || "",
//         employee_code: employee?.employee_code,
//         department_id: employee?.department_id || -1,
//         employee_role: employee?.employee_role || -1,
//         position_id: employee?.position_id || -1,
//         employee_type: employee?.employee_type || -1,
//         start_worked_date: employee?.start_worked_date
//           ? moment(employee?.start_worked_date)
//           : moment(),
//         employee_note: employee?.employee_note || "",
//       },
//     },
//   });

//   const [activeStep, setActiveStep] = React.useState(0);

//   const homies = useWatch({
//     control: methods.control,
//     name: "relationInformations",
//   });

//   const handleNext = async (values) => {
//     setSubmit(true);
//     let keySteps = [
//       "basicInformation",
//       "moreInformation",
//       "relationInformations",
//       "educationAndExpInformations",
//       "insuranceInformation",
//       "bankingAndTaxInformation",
//       "accountInformation",
//     ];
//     const key = keySteps.at(activeStep);
//     try {
//       const dataOnLocal =
//         localStorage.getItem(`employee_id_${employee_id}.data.update`) || "{}";
//       let dataJson = JSON.parse(dataOnLocal);
//       dataJson[key] = values[key];
//       localStorage.setItem(
//         `employee_id_${employee_id}.data.update`,
//         JSON.stringify(dataJson)
//       );
//       setActiveStep((prevStep) => prevStep + 1);
//     } catch (error) {
//       console.log(error);
//       return setSubmit(false);
//     }
//     await sleep(300);
//     setSubmit(false);
//   };
//   const handleBack = () => {
//     return setActiveStep((prevStep) => prevStep - 1);
//   };
//   const onSubmit = async (data) => {
//     console.log(data);
//     //     setSubmit(true)
//     //     localStorage.setItem(`employee_id_${employee_id}.data.update`, JSON.stringify(data))
//     //     let dataCompany = {}
//     //     let dataProject = {}
//     //     if (data?.company?.is_new_company) {
//     //         // handler create a new company
//     //         const dataCompanyCreate = _.pick(data?.company, ["company_name", "company_tax", "company_address", "company_established_date", "company_website"])
//     //         const companyResponse = await CompanyApi.Create({
//     //             ...dataCompanyCreate,
//     //             company_industry: data?.customer?.customer_industry,
//     //             company_industry_detail: data?.customer?.company_industry_detail
//     //         })
//     //         if (!companyResponse || companyResponse.status !== 201) {
//     //             setSubmit(false)
//     //             return setNotification({
//     //                 open: true,
//     //                 message: 'Tạo mới công ty thất bại. Vui lòng thử lại',
//     //                 severity: 'error',
//     //             })
//     //         }
//     //         refetchCompanies();
//     //         dataCompany = { company_id: companyResponse?.data?.company?.company_id }
//     //     } else {
//     //         dataCompany = {
//     //             company_id: data?.company?.company_object_selected?.company_id
//     //         }
//     //     }
//     //     if (data?.project?.is_new_project) {
//     //         const dataProjectCreate = _.pick(data?.project, ["project_name", "project_note"])
//     //         const project = await ProjectApi.Create(dataProjectCreate, dataCompany?.company_id)
//     //         if (!project || project?.status !== 201) {
//     //             setSubmit(false)
//     //             return setNotification({
//     //                 open: true,
//     //                 message: 'Tạo mới dự án thất bại. Vui lòng thử lại',
//     //                 severity: 'error',
//     //             })
//     //         }
//     //         dataProject = { project_id: project?.data?.project?.project_id }
//     //     } else {
//     //         dataProject = {
//     //             project_id: data?.project?.project_object_selected?.map(project => project?.project_id)
//     //         }
//     //     }
//     //     let dataSubmit = _.pick(data?.customer, ["customer_type", "customer_name", "customer_email", "customer_country_phone", "customer_position", "customer_note", "customer_phone", "customer_dob", "customer_address", "customer_source", "customer_status", "customer_note", "customer_source", "event_name", "customer_website"])
//     //     dataSubmit = {
//     //         ...dataSubmit,
//     //         company_id: dataCompany?.company_id,
//     //         customer_project: dataProject?.project_id || [],
//     //         customer_service_interesting_temp: data?.customer?.customer_service_interesting?.map(service => service?.value)
//     //             || [],
//     //         customer_country_temp: data?.customer?.customer_country,
//     //         customer_province_temp: data?.customer?.customer_province,
//     //         customer_district_temp: data?.customer?.customer_district,
//     //         customer_industry_temp: data?.customer?.customer_industry,
//     //         customer_industry_detail_temp: data?.customer?.customer_industry_detail,
//     //         start_time: new Date()
//     //     }
//     //     const customer = await CustomerApi.Create(dataSubmit)
//     //     if (!customer || customer?.status !== 201) {
//     //         setSubmit(false);
//     //         return setNotification({
//     //             open: true,
//     //             message: 'Thêm mới khách hàng thất bại. Vui lòng thử lại hoặc liên hệ phòng ban Dev!!!',
//     //             severity: 'error'
//     //         })
//     //     }
//     //     setSubmit(false);
//     //     setNotification({
//     //         open: true,
//     //         message: 'Thêm mới khách hàng thành công. Đi đến xem nè (^-^)',
//     //         severity: 'success'
//     //     })
//     //     localStorage.removeItem(`employee_id_${employee_id}.data.update`)
//     //     return navigate(`/customers/${customer?.data?.customer?.customer_id}`, { replace: true })
//   };
//   const getStepContent = () => {
//     switch (activeStep) {
//       case 0:
//         return <BasicInfomation />;
//       case 1:
//         return <MoreInformation />;
//       case 2:
//         return <RelationInformation />;
//       case 3:
//         return <EducationAndExpInformation />;
//       case 4:
//         return <InsuranceInformation />;
//       case 5:
//         return <BankingAndTaxInformation />;
//       case 6:
//         return <AccountInformation />;

//       default:
//         throw new Error();
//     }
//   };
//   const handleReset = async () => {
//     const employee = localStorage.getItem(
//       `employee_id_${employee_id}.data.update`
//     );
//     const decodeEmployee = JSON.parse(employee);
//     const keySteps = [
//       "basicInformation",
//       "moreInformation",
//       "relationInformations",
//       "educationAndExpInformations",
//       "insuranceInformation",
//       "bankingAndTaxInformation",
//       "accountInformation",
//     ];
//     _?.forEach(keySteps, (step) => {
//       if (decodeEmployee[step] && decodeEmployee[step] !== {}) {
//         methods.setValue(`${step}`, { ...decodeEmployee?.[`${step}`] });
//       }
//     });
//     setOpenDialog({ open: false });
//   };

//   React.useEffect(() => {
//     const handleSaveData = (values) => {
//       return localStorage.setItem(
//         `employee_id_${employee_id}.data.update`,
//         JSON.stringify(values || `{}`)
//       );
//     };
//     window.addEventListener("beforeunload", () =>
//       methods.handleSubmit(handleSaveData)
//     );
//     return () =>
//       window.removeEventListener("beforeunload", () =>
//         methods.handleSubmit(handleSaveData)
//       );
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   const getTitleButton = (index) => {
//     let label = "Lưu & Tiếp Tục";
//     if (index === 2 && homies?.length === 0) return "Bỏ Qua & Tiếp Tục";
//     if (index === 4) {
//       let flag = false;
//       _?.forEach(methods.watch("insuranceInformation"), (value, key) => {
//         if (value !== "" && !!value) return (flag = true);
//       });
//       if (!flag) return "Bỏ Qua & Tiếp Tục";
//     }
//     if (index === 6) return "Cấp tài khoản";
//     return label;
//   };

//   return (
//     <Box>
//       {openDialog?.open && (
//         <Dialog fullWidth open={openDialog?.open}>
//           <DialogTitle sx={{ m: "auto" }}>
//             Đã có dữ liệu cũ, bạn có muốn khôi phục?
//           </DialogTitle>
//           <DialogActions>
//             <Button
//               variant="outlined"
//               onClick={() => {
//                 localStorage.removeItem(
//                   `employee_id_${employee_id}.data.update`
//                 );
//                 setOpenDialog({ open: false });
//               }}
//               sx={{ height: 35 }}
//             >
//               Không
//             </Button>
//             <Button
//               variant="contained"
//               onClick={() => handleReset()}
//               sx={{ height: 35 }}
//             >
//               Có
//             </Button>
//           </DialogActions>
//         </Dialog>
//       )}
//       <Typography className="page-title">Chỉnh Sửa Thông Tin</Typography>
//       <BreadCrumb
//         data={[
//           { label: "Danh Sách Nhân Viên", reactLink: "/employees" },
//           { label: employee?.employee_id },
//           { label: "Chỉnh Sửa Thông Tin" },
//         ]}
//       />
//       <Paper sx={{ p: "30px" }}>
//         <Grid container>
//           <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
//             <Stepper activeStep={activeStep} orientation="vertical">
//               {defaultSteps.map((step, index) => {
//                 return (
//                   <Step key={step.label}>
//                     <StepLabel>
//                       <Typography
//                         sx={{
//                           m: "auto 0",
//                           fontWeight: "medium",
//                           fontSize: 24,
//                         }}
//                       >
//                         {step.label}
//                       </Typography>
//                     </StepLabel>
//                     <StepContent>
//                       <FormProvider {...methods}>
//                         <Box
//                           component={"form"}
//                           sx={{ width: "100%" }}
//                           onSubmit={
//                             index === 6
//                               ? methods.handleSubmit(onSubmit)
//                               : methods.handleSubmit(handleNext)
//                           }
//                         >
//                           {getStepContent()}
//                           <Box
//                             sx={{
//                               display: "flex",
//                               justifyContent: "flex-end",
//                               mt: "30px",
//                             }}
//                           >
//                             {index !== 0 && (
//                               <Button
//                                 disabled={submit}
//                                 size="small"
//                                 variant="outlined"
//                                 onClick={handleBack}
//                                 sx={{
//                                   height: 42,
//                                   mr: 2,
//                                 }}
//                               >
//                                 Quay Lại
//                               </Button>
//                             )}
//                             <Button
//                               variant="contained"
//                               type="submit"
//                               size="small"
//                               sx={{ height: 42 }}
//                               disabled={submit}
//                               startIcon={
//                                 submit && (
//                                   <CircularProgress
//                                     size={14}
//                                     sx={{
//                                       color: "#757575",
//                                     }}
//                                   />
//                                 )
//                               }
//                             >
//                               {getTitleButton(index)}
//                             </Button>
//                           </Box>
//                         </Box>
//                       </FormProvider>
//                     </StepContent>
//                   </Step>
//                 );
//               })}
//             </Stepper>
//           </Grid>
//         </Grid>
//       </Paper>
//     </Box>
//   );
// };

export default withSuspense(
  withPermission(SubmitInformation, { feature: "employee", action: "update" }),
  MuiSkeleton["GridInformation"]
);
