import ServiceApi from "@/apis/beta/Service/ServiceApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, FormControl, Paper, TextField } from "@mui/material";
import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { SystemButtons } from "../ServiceSystem/shared/Buttons";

const ServiceMailer = () => {
  const { service_id: serviceId } = useParams();

  const [submit, setSubmit] = React.useState(null);

  const { data } = ServiceApi.Mailer({ serviceId });

  const [defaultValues] = React.useState({
    mailer_receiver: data?.mailer_receiver?.join(","),
    mailer_cc: data?.mailer_cc?.join(","),
    mailer_bcc: data?.mailer_bcc?.join(","),
  });

  const methods = useForm({
    defaultValues: defaultValues,
  });

  return (
    <FormProvider {...methods}>
      <Paper
        component="form"
        sx={{
          p: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: 1.5,
          }}
        >
          <Controller
            name="mailer_receiver"
            control={methods.control}
            rules={{
              required: "Vui lòng nhập ít nhất một email người nhận.",
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    label="Người nhận *"
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                    placeholder="Email người nhận..."
                    size="small"
                  />
                </FormControl>
              );
            }}
          />
          <Controller
            name="mailer_cc"
            control={methods.control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    value={value}
                    label="Email cc"
                    InputLabelProps={{ shrink: true }}
                    onChange={onChange}
                    placeholder="Email cc..."
                    size="small"
                  />
                </FormControl>
              );
            }}
          />
          <Controller
            name="mailer_bcc"
            control={methods.control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    label="Email Bcc"
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                    placeholder="Email bcc..."
                    size="small"
                  />
                </FormControl>
              );
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
            mt: 2,
          }}
        >
          <SystemButtons.Preview serviceId={serviceId} disabled={submit} />
          <SystemButtons.Sendmail
            serviceId={serviceId}
            submit={submit}
            setSubmit={setSubmit}
          />
          <SystemButtons.ServiceUpdateMailer
            submit={submit}
            setSubmit={setSubmit}
            serviceId={serviceId}
          />
        </Box>
      </Paper>
    </FormProvider>
  );
};

export default withSuspense(
  withPermission(ServiceMailer, {
    feature: "service",
    action: "service-mailer-index",
  }),
  MuiSkeleton["GridInformation"]
);
