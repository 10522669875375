import withPermission from '@/hocs/withPermission'
import EmailOutlined from '@mui/icons-material/EmailOutlined'
import { Button } from '@mui/material'
import React from 'react'
import PrecheckSendmail from './Precheck'

const SendEmail = ({
    disabled = true,
    setDisabled,
    callback,
    ...invoice
}) => {

    const [open, setOpen] = React.useState(false)

    return (
        <React.Fragment>
            {open && <PrecheckSendmail invoiceId={invoice?.invoice_id}
                onClose={() => setOpen(false)}
            />}
            <Button size="small"
                fullWidth
                variant="outlined"
                disabled={disabled || open}
                onClick={() => setOpen(true)}
                startIcon={<EmailOutlined fontSize='small' />}
            >
                Gửi Hoá Đơn Đối Soát
            </Button>
        </React.Fragment>
    )
}

export default
    withPermission(SendEmail, { feature: "invoice", action: "send-mail", type: "button" })