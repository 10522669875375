import ProductApi from "@/apis/beta/Product/ProductApi";
import { buildQueryFilterCondition } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { FormControl } from "@mui/material";
import { useCallback, useState } from "react";

const Product = ({
    disabled = false,
    queries = '',
    onSelect = () => { },
    ...props
}) => {

    const [state, setState] = useState("");

    const debouncedSearchQuery = useDebounce(state, 500);

    const filterableProduct = useCallback(() => {
        if (!debouncedSearchQuery || debouncedSearchQuery.trim() === "")
            return null;
        const fieldQueries = [
            {
                name: "product_name",
                operator: "$contains",
                value: debouncedSearchQuery,
            },
        ];
        return buildQueryFilterCondition("$or", 0, fieldQueries);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery]);

    const {
        data: { products },
        isFetching,
    } = ProductApi.ListProducts({
        query: queries,
        searchQuery: filterableProduct(),
    });

    return (
        <FormControl fullWidth>
            <SingleSelectCustomize
                disabled={disabled}
                loading={isFetching}
                options={products}
                onChange={async (event, newValue) => {
                    return await onSelect(newValue || null)
                }}
                compareAttributes={["product_id", "product_name"]}
                formatCollection={{
                    value: "product_id",
                    label: "product_name",
                }}
                input={{
                    label: props?.label || "Cloud Service Node (k8s) *",
                    placeholder: "Chọn sản phẩm/ dịch vụ...",
                    value: state,
                    onChange: (e) => setState(e?.target?.value || ""),
                }}
            />
        </FormControl>
    );
};

export default withSuspense(Product, MuiSkeleton["TextField"]);
