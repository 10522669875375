import DatePicker from '@components/datepickers/DateRangPicker'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, Collapse, FormControl } from '@mui/material'
import dayjs from 'dayjs'

const DurationRanges = ({ durationRanges = {}, setDurationRanges, options = [] }) => {

    const today = dayjs()
    const INITIAL_DURATION_RANGES = [
        {
            range: 7,
            label: 'Last 7 Days',
            gte: today?.subtract(7, 'day'),
            lte: today
        },
        {
            range: 30,
            label: 'Last 30 Days',
            gte: today?.subtract(31, 'day'),
            lte: today
        },
        ...options,
        {
            range: '',
            label: 'Customized',
        },
    ]
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: { xs: 2, md: 1 },
            flexDirection: { xs: 'column', md: 'row' }
        }}>
            <FormControl
                disabled
                fullWidth sx={{ maxWidth: { xs: '100%', md: 300 }, minWidth: { xs: '100%', md: 300 } }}>
                <SingleSelectCustomize
                    options={INITIAL_DURATION_RANGES}
                    value={INITIAL_DURATION_RANGES?.find(x => x?.range === durationRanges?.range)}
                    compareAttributes={['label']}
                    formatCollection={{
                        label: 'label',
                        value: 'label'
                    }}
                    input={{
                        label: 'Thời gian (*)',
                        placeholder: "Chọn thời gian...."
                    }}
                    onChange={(event, newValue) => setDurationRanges(preDuration => ({ ...preDuration, ...newValue }))}
                />
            </FormControl>
            <Collapse in={durationRanges?.range === ''}>
                {durationRanges?.range === '' && <FormControl fullWidth>
                    <DatePicker
                        value={[dayjs(durationRanges?.gte), dayjs(durationRanges?.lte)]}
                        handler={(newValues) => {
                            setDurationRanges(preDuration => ({
                                ...preDuration,
                                gte: newValues[0]?.$d || newValues[0],
                                lte: !newValues?.[1] ? null : (newValues[1]?.$d || newValues[1])
                            }))
                        }}
                        sx={{
                            ".MuiFormControl-fullWidth": {
                                ".MuiInputBase-root": {
                                    height: 40
                                }
                            }
                        }}
                    />
                </FormControl>}
            </Collapse>
        </Box>
    )
}

export default DurationRanges