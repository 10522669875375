import ProcessForm from "@features/Module/Contract/NumberContract/Detail/Process/Form";
import ProcessStepper from "@features/Module/Contract/NumberContract/Detail/Process/ProcessStepper";
import {
  Box,
  Button,
  Drawer,
  Step,
  Stepper,
  Typography
} from "@mui/material";
import { useState } from "react";

const Process = ({
  subcontract,
  employees = [],
  callbackSubcontract,
  contract = {},
}) => {
  const [openForm, setOpenForm] = useState(false);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: "medium", textTransform: "uppercase" }}>
          tiến trình xử lý
        </Typography>
        <Button
          size="small"
          sx={{ m: "auto 0", height: 36 }}
          onClick={() => setOpenForm(true)}
          disabled={openForm}
        >
          Tạo Tiến Trình
        </Button>
        {openForm && <Drawer anchor="right" open={true}>
          <ProcessForm
            type={"subcontracts"}
            subcontract={subcontract}
            handleClose={() => setOpenForm(false)}
          />
        </Drawer>}
      </Box>
      <Stepper
        activeStep={8} // go to last step
        orientation="vertical"
        sx={{ mb: 2 }}
      >
        <Step>
          <ProcessStepper.StepStart subcontract={subcontract} />
        </Step>
        {([0, 8].includes(subcontract?.subcontract_status) ||
          subcontract?.contract_processes?.length > 0) && (
            <Step>
              <ProcessStepper.StepResolveProblem
                callback={callbackSubcontract}
                contract_processes={subcontract?.contract_processes}
                employees={employees}
                type={"subcontracts"}
              />
            </Step>
          )}
        {([0, 8].includes(subcontract?.subcontract_status) ||
          subcontract?.subcontract_status >= 4) && (
            <Step>
              <ProcessStepper.StepPrintAndSign
                contractNumber={{
                  ...subcontract,
                  contract_number_created_at: subcontract.subcontract_updated_at,
                  contract_number_status: subcontract.subcontract_status,
                  contract_number_stt: subcontract?.subcontract_id,
                  contract_number_id: subcontract?.subcontract_code,
                  contract_number_owner:
                    contract?.customer?.company_name ||
                    contract?.customer?.customer_name,
                }}
                type={"subcontract"}
                callbackContractNumber={callbackSubcontract}
              />
            </Step>
          )}
        {([0, 8].includes(subcontract?.subcontract_status) || // completed
          subcontract?.subcontract_status >= 6) && ( // now is active step upload
            <Step>
              <ProcessStepper.StepScanUploadSubContract
                subcontract={subcontract}
                contract={contract}
                callback={callbackSubcontract}
              />
            </Step>
          )}
        {[0, 8].includes(subcontract?.subcontract_status) && ( // only completed
          <Step>
            <ProcessStepper.StepCompleted
              contractNumber={{
                ...subcontract,
                contract_number_id: subcontract?.subcontract_code,
                contract_attachments: subcontract?.subcontract_attachments,
              }}
            />
          </Step>
        )}
        {subcontract?.subcontract_status === 2 && ( // only cancel
          <Step>
            <ProcessStepper.StepCancel
              contractNumber={{
                ...subcontract,
                contract_number_id: subcontract?.subcontract_code,
              }}
            />
          </Step>
        )}
      </Stepper>
    </Box>
  );
};
export default Process;
