import { AppBar, Box, Button, Container, Dialog, FormControl, Grid, IconButton, InputLabel, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, Slide, TextField, Toolbar, Typography, InputAdornment, OutlinedInput } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import CancelIcon from '@mui/icons-material/Cancel';
import DnsIcon from '@mui/icons-material/Dns';
import { useForm, Controller } from 'react-hook-form';
import ReportWatchApi from '@/apis/beta/Threshold/Report/ReportWatchApi';
import { useNotificationContext } from "@contexts/notification";
import Autocomplete from '@mui/material/Autocomplete';
import SearchCustomers from '../SearchCustomers';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const renderOption = (option) => {
    return (
        <MenuItem 
            {...option}
            key={option["data-option-index"]} 
            sx={{ '&:hover': { background: "#f0f0f0" } }}
        >
            {option["key"]}
        </MenuItem>
    );
};
const DialogAddReport = (props) => {
    const [mail, setMail] = useState('');
    const [domain, setDomain] = useState('');
    const { setNotification } = useNotificationContext();
    const { control, handleSubmit, formState: { errors }, setValue, watch } = useForm(
        {
            defaultValues: {
                report_name: '',
                report_priority: 0,
                report_description: '',
                // report_bind: 'waf::report::weekly',
                report_interval: 'weekly',
                // report_referrer_type: 'Customer',
                report_scope: 'default',
                report_status: 'active',
                report_type: 'waf',
                report_receivers: [],
            }
        });
    const [reportMetadata, setReportMetadata] = useState({
        mlytics_id: '',
        domains: [],
        // owners: [],
        source_domains: '',
    });
    const report_scopes = [
        { name: 'default', value: 'default' },
        { name: 'DetailOriented', value: 'DetailOriented' },
    ]
    const report_intervals = [
        { name: 'Mỗi tuần', value: 'weekly' },
        { name: 'Mỗi tháng', value: 'monthly' },
    ]
    const handleOnchangeMail = (value) => {
        setMail(value);
    }
    const handleOnchangeDomain = (value) => {
        setDomain(value);
    }
    const handleAddMail = () => {
        if (mail.replaceAll(" ", "") === "") return;
        if (watch("report_receivers").includes(mail)) return;
        // setReportMetadata({ ...reportMetadata, owners: [...reportMetadata.owners, mail] });
        setValue("report_receivers", [...watch("report_receivers"), mail]);
        setMail('');
    }
    const handleAddDomain = () => {
        if (domain.replaceAll(" ", "") === "") return;
        if (reportMetadata.domains.includes(domain)) return;
        setReportMetadata({ ...reportMetadata, domains: [...reportMetadata.domains, domain] });
        setDomain('');
    }
    const handleRemoveMail = (index) => {
        setValue("report_receivers", watch("report_receivers").filter((item, i) => i !== index));
        // setReportMetadata({ ...reportMetadata, owners: reportMetadata.owners.filter((item, i) => i !== index) });
    }
    const handleRemoveDomain = (index) => {
        setReportMetadata({ ...reportMetadata, domains: reportMetadata.domains.filter((item, i) => i !== index) });
    }

    const handleOnchangeSource_domains = (value) => {
        setReportMetadata({ ...reportMetadata, source_domains: value });
    }
    const createReport = async (report) => {
        console.log(report);
        await ReportWatchApi.CreateReport({
            report: report,
        }).then((response) => {
            if (!response?.data.report) {
                return setNotification({
                    open: true,
                    message: response?.errors?.[0]?.message || "Tạo báo cáo mới thất bại.",
                    severity: "error",
                });
            }
            setNotification({
                open: true,
                message: "Tạo báo cáo mới thành công.",
                severity: "success",
            });
            if (props.refetch !== undefined) {
                props.refetch();
            }
            props.handleClose();
        });
    }

    const onSubmit = (data) => {
        const obj = {
            report: data,
            waf_attribute: reportMetadata
        }
        createReport(obj);
    }
    const [autoCompleteValue, setAutoCompleteValue] = useState('');
    const {
        data,
    } = ReportWatchApi.MlyticList({
        organization_name: autoCompleteValue || ''
    });
    const onInputChange = (current_input) => {
        if (!current_input) return;
        if (!current_input.target?.value) return;
        setAutoCompleteValue(current_input.target?.value || '');
        setReportMetadata({ ...reportMetadata, mlytics_id: current_input.target?.value || '' });
    }

    const onAutocompleteOnChange = (value) => {
        if (!value?.organizationName) return;
        setAutoCompleteValue(value.organizationName);
        setReportMetadata({ ...reportMetadata, mlytics_id: value.customerId });
    }

    const handleChangeCustomer = (value) => {
        setValue("report_referrer_id",value?.customer_id);
    }

    return (
        <>
            <Dialog
                hideBackdrop
                fullScreen
                keepMounted
                open={props.open}
                onClose={props.handleClose}
                TransitionComponent={Transition}
                scroll='paper'
                onSubmit={handleSubmit(onSubmit)}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Thêm báo cáo
                        </Typography>
                        <Button
                            autoFocus color="inherit"
                            onClick={handleSubmit(onSubmit)}
                            type='submit'
                        >
                            Lưu thông tin
                        </Button>
                    </Toolbar>
                </AppBar>
                <Container component={'form'} maxWidth="sm" sx={{ mt: 2 }}>
                    <Controller
                        name='report_name'
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) =>
                            <>
                                <TextField
                                    id="outlined-basic"
                                    label="Tên người dùng"
                                    sx={{ mt: 2 }}
                                    variant="outlined"
                                    {...field}
                                />
                                {errors.report_name?.type === 'required' && <p role="alert" style={{ color: 'red', fontSize: '12px', paddingLeft: "10px" }}>Cần nhập tên người dùng</p>}
                            </>
                        }
                    />
                    <Controller
                        name='report_description'
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) =>
                            <>
                                <TextField
                                    id="outlined-basic"
                                    label="Mô tả"
                                    sx={{ mt: 2 }}
                                    variant="outlined"
                                    {...field}
                                />
                            </>
                        }
                    />
                    <Controller
                        name='report_priority'
                        control={control}
                        render={({ field }) =>
                            <>
                                <TextField
                                    type='number'
                                    id="outlined-basic"
                                    label="Mức độ ưu tiên (0-10)"
                                    placeholder='Mức độ ưu tiên càng cao thì được ưu tiên xuất report trước'
                                    sx={{ mt: 2 }}
                                    variant="outlined"
                                    {...field}
                                />
                            </>
                        }
                    />
                    <Controller
                        name='report_scope'
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) =>
                            <>
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="report-scope-select-label">Hình thức gửi báo cáo</InputLabel>
                                    <Select
                                        labelId="report-scope-select-label"
                                        id="report-scope-select"
                                        {...field}
                                        label="Hình thức gửi báo cáo"
                                        defaultChecked="default"
                                    >
                                        {report_scopes?.map((item) =>
                                            <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                {errors.report_scope?.type === 'required' && <p role="alert" style={{ color: 'red', fontSize: '12px', paddingLeft: "10px" }}>Cần chọn hình thức gửi báo cáo</p>}
                            </>
                        }
                    />
                    <Controller
                        name='report_interval'
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) =>
                            <>
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="label-report_interval">Tần suất gửi</InputLabel>
                                    <Select
                                        labelId="label-report_interval"
                                        id="report_interval-select"
                                        {...field}
                                        label="Tần suất gửi"
                                        defaultChecked="weekly"
                                    >
                                        {report_intervals.map((item) =>
                                            <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                {errors.report_interval?.type === 'required' && <p role="alert" style={{ color: 'red', fontSize: '12px', paddingLeft: "10px" }}>Cần chọn tần suất gửi báo cáo</p>}
                            </>
                        }
                    />
                    <Controller
                        name='report_status'
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) =>
                            <>
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="label-report_status">Trạng thái</InputLabel>
                                    <Select
                                        labelId="label-report_status"
                                        id="report_status-select"
                                        {...field}
                                        label="Trạng thái"
                                        defaultChecked="active"
                                    >
                                        {[{name: "active", value: "active"}, {name: "inactive", value: "inactive" }].map((item) =>
                                            <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                {errors.report_interval?.type === 'required' && <p role="alert" style={{ color: 'red', fontSize: '12px', paddingLeft: "10px" }}>Cần chọn trạng thái gửi báo cáo</p>}
                            </>
                        }
                    />

                    <Autocomplete
                        options={data?.Customers || []}
                        getOptionLabel={(option) => option.organizationName}
                        value={{ organizationName: autoCompleteValue }}
                        freeSolo
                        renderOption={renderOption}
                        onChange={(_, newValue) => {
                            if (!newValue) {
                                setAutoCompleteValue('');
                                setReportMetadata({ ...reportMetadata, mlytics_id: '' });
                            } else {
                                onAutocompleteOnChange(newValue);
                            }
                        }}
                        onInputChange={onInputChange}
                        sx={{ width: '100%', mt: 2 }}
                        renderInput={(params) => <TextField {...params} label="Mlytics_id" placeholder="Nhập tên để search" variant="outlined" onKeyDown={(e) => e.key === "Enter" && e.preventDefault()} />}
                    />
                    <Box sx={{ mt: 2, backgroundColor: '#f5f5f5', p: 2, borderRadius: '4px' }}>
                        <Controller
                            name='report_referrer_id'
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) =>
                                <>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount">ID customer</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            startAdornment={<InputAdornment position="start">ID: </InputAdornment>}
                                            label="ID customer"
                                            {...field}
                                            placeholder='Chọn customer bên dưới'
                                            readOnly
                                        />
                                    {errors.report_referrer_id?.type === 'required' && <p role="alert" style={{ color: 'red', fontSize: '12px', paddingLeft: "10px" }}>Cần chọn customer</p>}
                                    </FormControl>
                                    <Box sx={{mt:2}}>
                                        <SearchCustomers
                                            limit={5}
                                            size={8}
                                            maxHeight={400}
                                            onChange={handleChangeCustomer}
                                        />    
                                    </Box> 
                                </>
                            }
                        />
                         
                    </Box> 
                    <TextField id="outlined-basic" sx={{ mt: 2 }}
                        label="Source Domains"
                        variant="outlined"
                        value={reportMetadata.source_domains}
                        placeholder="elastic/history/...."
                        onChange={(e) => handleOnchangeSource_domains(e.target.value)}
                    />
                    <TextField
                        type='mail'
                        id="outlined-basic" label="Mail người liên hệ" sx={{ mt: 2 }} variant="outlined"
                        value={mail}
                        placeholder='Thêm mail vào danh sách'
                        onChange={(e) => handleOnchangeMail(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleAddMail();
                            }
                        }
                        }
                    />
                    <Button variant='contained' sx={{ mt: 2, float: 'right' }}
                        onClick={() => handleAddMail()}
                    >
                        Thêm Mail
                    </Button>
                    <TextField id="outlined-basic" sx={{ mt: 2 }} label="Domain" variant="outlined"
                        value={domain}
                        placeholder='Thêm domain vào danh sách'
                        onChange={(e) => handleOnchangeDomain(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleAddDomain();
                            }
                        }
                        }
                    />
                    <Button variant='contained' sx={{ mt: 2, float: 'right' }}
                        onClick={() => handleAddDomain()}
                    >Thêm Domain
                    </Button>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                                Mail người liên hệ
                            </Typography>
                            <List dense={true}>
                                {
                                    watch("report_receivers").map((mail, index) =>
                                        <ListItem
                                            key={index}
                                            secondaryAction={
                                                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveMail(index)}>
                                                    <CancelIcon />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemAvatar>
                                                <ContactMailIcon />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={mail}
                                            />
                                        </ListItem>
                                    )}
                            </List>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                                Domains
                            </Typography>
                            <List dense={true}>
                                {
                                    reportMetadata.domains.map((domain, index) =>
                                        <ListItem
                                            key={index}
                                            secondaryAction={
                                                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveDomain(index)}>
                                                    <CancelIcon />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemAvatar>
                                                <DnsIcon />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={domain}
                                            />
                                        </ListItem>
                                    )}
                            </List>
                        </Grid>
                    </Grid>
                    <Button variant='contained' sx={{ mt: 2 }} type="submit">Thêm báo cáo</Button>
                </Container>

            </Dialog>
        </>
    );
}

export default DialogAddReport;
