import dayjs from "dayjs";
import { isEmail } from "validator";
import { create, enforce, test } from "vest";
// eslint-disable-next-line no-undef
enforce.extend({ isEmail });

const contractServiceValidator = create((data = {}) => {
  test(
    "contract_customer_id",
    "Vui lòng chọn chủ sở hữu cho hợp đồng này.",
    () => {
      enforce(parseInt(data?.contract_customer_id) > 0).isTruthy();
    }
  );

  test("effective_date", "Vui lòng chọn ngày bắt đầu dịch vụ.", () => {
    enforce(data?.effective_date).isNotEmpty();
  });
  test("expiry_date", "Vui lòng chọn lại ngày kết thúc dịch vụ.", () => {
    const isValid =
      dayjs(data?.expiry_date).diff(dayjs(data?.effective_date), "day") >= 0;
    enforce(isValid).isTruthy();
  });
});

export default contractServiceValidator;
