import { Button } from '@mui/material'
import React from 'react'
import withPermission from '@/hocs/withPermission'
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const ButtonExport = () => {
    return (
        <Button variant='outlined'
            size='small'
            startIcon={<FileDownloadIcon fontSize='small' />}
            sx={{ height: 36, m: 'auto 0' }}
        >
            Tải Xuống
        </Button>
    )
}

export default withPermission(ButtonExport, { feature: 'customer', action: 'export', type: "button" })