import { Box, Typography } from '@mui/material'
import React from 'react'
import PrivateIconWithFormat from '../../../shared/icons'

const Feedback = ({ feedback = '' }) => {
    if (!feedback) return null;
    return (
        <Box sx={{ display: "flex", mb: 1 }}>
            <PrivateIconWithFormat icon="ChatIcon" />
            <Typography sx={{ color: "#9e9e9e", fontSize: 14, m: "auto 0" }}>
                Phản Hồi Người Tiếp Nhận:{" "}
                <span style={{ color: "#ff9800" }}>
                    {feedback}
                </span>
            </Typography>
        </Box>
    )
}

export default Feedback