import { format_number_usage, roundjs, sortPriorities } from "@/functions"
import withSuspense from "@/hocs/withSuspense"
import DataGridCustomize from "@components/DataGrid"
import MuiSkeleton from "@components/shared/MuiSkeleton"
import _ from "lodash"
import { useMemo, useState } from "react"
import { resolveServiceGcoreAnalyticHistoric } from "../services"

const GcoreBoard = ({ uuid = '', payload = {} }) => {

    const [columns] = useState([
        { field: 'id', headerName: 'STT', width: 60 },
        { field: 'name', headerName: 'Row Labels', width: 200 },
        {
            field: 'billing_value', headerName: 'Sum từ file detail (minutes)', width: 250,
            valueFormatter: ({ value }) => format_number_usage(value)
        }, {
            field: 'realities', headerName: 'Thời gian sử dụng thực tế (theo đơn vị tính tiền)', hideable: false,
            valueGetter: ({ row }) => {
                let use = roundjs((row?.billing_value / 60), 2)
                if (use < 24) {
                    use = 24
                }
                if (!['instance', 'load_balancer'].includes(row?.type)) {
                    use = roundjs((use / 24 / 30), 2)
                }
                return use
            }
        }, {
            field: 'round_realities', headerName: 'Làm tròn', hideable: false,
            valueGetter: ({ row }) => {
                let use = roundjs((row?.billing_value / 60), 2)
                if (use < 24) {
                    use = 24
                }
                if (!['instance', 'load_balancer'].includes(row?.type)) {
                    use = roundjs((use / 24 / 30), 2)
                    use = Math.ceil(use)
                } else {
                    use = roundjs(use, 0)
                }
                return use
            }
        }
        // {
        //     field: 'billing_value', headerName: 'Thời gian sử dụng thực tế (theo đơn vị tính tiền)', width: 400,
        //     valueFormatter: ({ value }) => format_number_usage(value)
        // },
    ])

    // console.log(data?.results?.filter(x => x?.type === 'floatingip'))


    const { data: [gCore], isLoading } = resolveServiceGcoreAnalyticHistoric(uuid, {
        ...payload
    })
    const rows = useMemo(() => {
        const categories = _.groupBy(sortPriorities(gCore?.results, [
            'external_ip',
            'floatingip',
            'instance',
            'load_balancer',
            'volume',
            'image',
        ], 'type'), (resource) => resource?.type + (resource?.flavor || '') + (resource?.volume_type || ''))

        let resources = []
        _.forEach(categories, ([resource, ...childrens], category) => {
            const billing_value = _.sumBy([resource, ...childrens], (x) => x?.billing_value)
            const name = resource?.flavor || (resource?.type + (!!resource?.volume_type ? ` - ${resource?.volume_type}` : ''))

            resources.push({
                ...resource,
                billing_value,
                name

            })
        })

        return resources?.map((x, index) => ({ ...x, id: index + 1 }))
    }, [gCore])
    return (
        <DataGridCustomize
            loading={isLoading}
            components={{
                Toolbar: 'disabled',
                Pagination: 'disabled',
            }}
            columns={columns}
            rows={rows}
        />
    )
}

export default withSuspense(GcoreBoard, MuiSkeleton["LoadingDotBox"])
