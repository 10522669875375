import { useNotificationContext } from "@contexts/notification";
import { useQuery } from "react-query";

const useQueryWithCustomized = (key, func, options = {}) => {
  const { setNotification } = useNotificationContext();

  const notifyError = (error) => {
    setNotification({
      open: true,
      message:
        error?.errors?.[0]?.message ||
        error?.response?.data?.errors?.[0]?.message ||
        "Something went wrong",
      severity: "error",
    });
  };

  return useQuery(key, func, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      notifyError(error);
    },
    onSuccess: (response) => {
      if (response?.errors) notifyError(response);
    },
    ...options,
  });
};

export default useQueryWithCustomized;
