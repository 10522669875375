import List from "./List";
import Detail from "./Detail";
import Create from "./Create";
import Update from "./Update";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    List,
    Detail,
    Create,
    Update,
} 