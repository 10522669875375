import React from 'react'
import CartIcon from '@mui/icons-material/AddShoppingCartOutlined';
import withPermission from '@/hocs/withPermission';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const ButtonCreateOrder = ({ customerId, ...customer }) => {

    return (
        <Link to={`/customers/${customerId}/orders/new?order_type=new-order`} replace>
            <Button
                disabled={customer?.customer_service_information?.customer_status === "cancelled"}
                fullWidth
                variant='outlined'
                size='small'
                startIcon={<CartIcon fontSize='small' />}
                sx={{ height: { xs: 40, md: 42 } }} >
                Đơn Hàng Mới
            </Button>
        </Link>

    )
}

export default withPermission(ButtonCreateOrder, {
    feature: 'customer',
    action: 'customer-order-create',
    type: "Button"
})