import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import withSuspense from "@/hocs/withSuspense";
import { BaseComponents } from "@components/Components";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SearchDevice from "@features/Module/WarehouseNew/WarehouseBoxes/Boxes/SearchDevice";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import TableGroupWarehouseItem from "../FormImport/TableGroupWarehouseItem";
import WarehouseItemForm from "../WarehouseItemForm";
import AddCords from "./AddCords";
import AddChildItemFormExport from "./AddchildItemFormExport";

const FormExport = ({ handleCreate, isFetching, handleChange, data = null }) => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = useFormContext();

  const params = useParams();
  const [showFormAddCords, setShowFormAddCords] = useState(false);

  const { data: customer, isLoading } = CustomerApi.Detail(
    watch("customer_id")
  );
  const [showForm, setShowForm] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);
  const [isResetMetadata, setIsResetMetadata] = useState(true);

  useEffect(() => {
    setValue("warehouse_process_from", "KHO VNETWORK");
    if (data) {
      setValue("warehouse_process_to", data?.warehouse_process_to);
      setValue("warehouse_process_type", data?.warehouse_process_type);
      // setValue("warehouse_process_metadata", data?.warehouse_process_metadata);
      setValue("customer_id", data?.customer_id);
      if (watch("warehouse_process_metadata")?.length === 0) {
        data?.warehouse_process_metadata?.map((item) =>
          handleOnChange({ ...item, id: item.warehouse_item_id })
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue("warehouse_process_to", customer?.customer_company?.company_name || customer?.customer_information?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);
  const handleShowDialogEditItemInProcessMetadata = (values) => {
    setShowForm(true);
    let dataEdit = watch("warehouse_process_metadata")?.find(
      (item) => item?.id === values?.id
    ) || null;
    if (!dataEdit) {
      watch("warehouse_process_metadata")?.forEach((item) => {
        if (!dataEdit) {
          dataEdit = item?.children?.find((child) => child?.warehouse_item_id === values?.warehouse_item_id);
        }
      })
    }
    setDataEdit(
      dataEdit
    );
  };
  const handleEditProcessMetadataCurrent = (values) => {
    setValue(
      "warehouse_process_metadata",
      watch("warehouse_process_metadata")?.map((item, index) => {
        if (item?.id === values?.id) {
          return values
        }
        else {
          let cloneItem = item
          item?.children?.forEach((child, index) => {
            if (child?.warehouse_item_id === values?.warehouse_item_id) {
              cloneItem["children"][index] = values
            }
          })
          return cloneItem
        }
      }
      )
    );
    setShowForm(false);
    setDataEdit(null);
  };
  if (isResetMetadata) {
    setValue("warehouse_process_metadata", []);
    setIsResetMetadata(false);
  }
  const handleOnChange = (values) => {
    setValue("warehouse_process_metadata", [
      ...watch("warehouse_process_metadata"),
      values,
    ]);
  };
  return (
    <Box
    >
      {isLoading && <MuiSkeleton.LinearProgress />}

      <Grid container mt={2}>
        <Grid xs={4}>
          <Typography>Chọn Khách Hàng *</Typography>
        </Grid>

        <Grid xs={8}>
          <Controller
            name="customer_id"
            rules={{ required: "Vui lòng chọn khách hàng" }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth>
                <BaseComponents.Customer
                  name="customer_id"
                  onChange={onChange}
                  value={value}
                />

                {errors?.customer_id && (
                  <ErrorHelperText message={errors?.customer_id?.message} />
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid xs={4}>
          <Typography>Lý do *</Typography>
        </Grid>

        <Grid xs={8}>
          <Controller
            rules={{ required: "Vui lòng nhập lý do" }}
            name="warehouse_process_reason"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField multiline {...field} placeholder="Nhập lý do..." />
                {!!errors?.warehouse_process_reason && (
                  <ErrorHelperText
                    message={errors?.warehouse_process_reason?.message}
                  />
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid xs={4}>
          <Typography>Ghi chú</Typography>
        </Grid>

        <Grid xs={8}>
          <Controller
            name="warehouse_process_note"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField multiline {...field} placeholder="Nhập ghi chú..." />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid xs={4}>
          <Typography>Tìm Kiếm Thiết Bị & Linh Kiện *</Typography>
        </Grid>

        <SearchDevice
          limit={8}
          size={8}
          maxHeight={400}
          onChange={handleChange}
          device={false}
          paramsSearch={{
            warehouse_box_item_reference_id: 1,
            warehouse_box_item_reference_type: "WarehouseBox",
          }}
          processData = {watch("warehouse_process_metadata")}
        />
      </Grid>

      <Grid container mt={2}>
        <Grid xs={4}>
          <Typography>Xuất dây *</Typography>
        </Grid>

        <Grid xs={4}>
          <Button
            onClick={() => setShowFormAddCords(true)}
            variant="outlined"
            size="small"
            startIcon={<SyncAltIcon />}
          >
            Chọn dây
          </Button>
        </Grid>
      </Grid>

      {watch("warehouse_process_metadata")?.length > 0 && (
        <TableGroupWarehouseItem
          warehouse_process_metadata={[...watch("warehouse_process_metadata")]}
          // use={["delete"]}
          handleRemoveItemInProcessMetadata={(id) => {
            setValue(
              "warehouse_process_metadata",
              watch("warehouse_process_metadata").filter(
                (item) => item?.id !== id
              )
            );
          }}
          handleShowDialogEditItemInProcessMetadata={
            handleShowDialogEditItemInProcessMetadata
          }
          process_type={3}
        />
      )}

      {showFormAddCords && (
        <AddCords
          open={showFormAddCords}
          onClose={() => setShowFormAddCords(false)}
          onChange={handleChange}
          params={{
            warehouse_box_item_reference_id: 1,
            warehouse_box_item_reference_type: "WarehouseBox",
          }}
        />
      )}

      <Grid container mt={2}>
        <Grid xs={4}></Grid>

        <Grid xs={8}>
          <Box display="flex" flexDirection="column" gap={2}>
            {!dataEdit && showForm && (
              <WarehouseItemForm
                dataEdit={dataEdit}
                onClose={() => setShowForm(false)}
                onChange={handleOnChange}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      {dataEdit && showForm && (
        <AddChildItemFormExport
          data={dataEdit}
          onChange={handleEditProcessMetadataCurrent}
          onClose={() => {
            setShowForm(false);
            setDataEdit(null);
          }}
          group={dataEdit?.warehouse_group_id}
        />
      )}
      <Box mt={4}>
        <Button
          type={"button"}
          disabled={showFormAddCords || isFetching}
          variant="contained"
          size="small"
          fullWidth
          startIcon={isFetching && <CircularProgress size={14} />}
          onClick={handleSubmit(handleCreate)}
        >
          {params?.id ? "Cập nhật phiếu" : "Thêm phiếu"}
        </Button>
      </Box>
    </Box>
  );
};

export default withSuspense(FormExport, MuiSkeleton["LinearProgress"]);
