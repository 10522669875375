import ServicePriceApi from '@/apis/beta/Service/ServicePriceApi'
import { format_date_short, queryClientRefetchScope } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import { UNLIMITED } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import FormCustomized from '@components/shared/FormCustomized'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { useNotificationContext } from '@contexts/notification'
import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

const cols = { xs: 12, md: 6, xl: 3 }
const PackagePrice = ({ price, serviceId }) => {

    const queryClient = useQueryClient()
    const { setNotification } = useNotificationContext()
    const { mutateAsync, isLoading } = useMutation(ServicePriceApi.ChangeServiceConfig)
    const { mutateAsync: serviceUpdatePayment } = useMutation(ServicePriceApi.UpdatePaymentConfig)

    const { data: { payment } } = ServicePriceApi.PaymentConfig({ serviceId })

    const methods = useForm({
        defaultValues: useMemo(() => {
            return ({
                unit: price?.unit,
                id: price?.id,
                commitment: {
                    id: price?.commitment?.service_commitment_configure_id || -1,
                    value: price?.commitment?.value || 0,
                    pricing_apply: price?.commitment?.pricing_apply || 0,
                    pricing_amount: price?.commitment?.pricing_amount || 0,
                    startdate: !!price?.commitment?.startdate ? dayjs(price?.commitment?.startdate) : null,
                    enddate: !!price?.commitment?.enddate ? dayjs(price?.commitment?.enddate) : null,
                    term: price?.commitment?.term || 1,
                    prepaid: price?.commitment?.prepaid,
                    depreciatable: price?.commitment?.depreciatable,
                    commitment_available: price?.commitment?.commitment_available || 0,
                    warning_enabled: price?.commitment?.warning_enabled,
                    warning_values: [...price?.commitment?.warning_values]
                },
                payment_config: {
                    payment_config_optional: payment?.payment_config_optional
                }
            })
        }, [price, payment])
    })

    const updatePaymentConfig = async (name = 'default') => await serviceUpdatePayment({ serviceId, name })
    const updateServicePackage = async (form = {}) => await mutateAsync({ serviceId, data: form })

    const SERVICE_TRANS_FIELDS = [
        {
            name: 'commitment.prepaid', component: 'SingleSelect', cols: { xs: 12, md: 6, xl: 3 },
            params: {
                options: [{ value: true, label: 'Trả Trước' }, { value: false, label: 'Trả Sau' }],
                input: {
                    label: 'Hình Thức Thanh Toán *',
                    placeholder: 'Chọn hình thức thanh toán...',
                },
            }
        }, {
            name: 'commitment.value', component: 'InputUnlimitCustomized', cols: { xs: 8, md: 4, xl: 2.25 },
            trigger: () => {
                if (parseInt(methods.watch(`commitment.value`)) >= UNLIMITED) return;
                methods.setValue('commitment.pricing_amount', methods.watch(`commitment.value`) * methods.watch(`commitment.pricing_apply`))
            },
            params: {
                label: 'Gói cố định', placeholder: 'Nhập cấu hình gói cố định...',
                helperText: `Example: 200(*)/ 1 tháng hoặc 200(*)/ 3 tháng.`
            }
        },
        {
            name: 'commitment.term', component: 'SingleSelect', cols: { xs: 4, md: 2, xl: 0.75 },
            params: {
                options: Array.from({ length: 12 }, (__, i) => i + 1)?.map((value) => ({ value: value, label: value })),
                input: { label: 'Tháng', placeholder: '---' }
            }
        },
        {
            name: 'commitment.pricing_apply', component: 'TextField', cols,
            params: {
                label: 'Đơn giá bán', placeholder: 'Nhập đơn giá bán...',
                InputProps: { inputComponent: NumericFormatCustom }
            },
            trigger: () => {
                if (parseInt(methods.watch(`commitment.value`)) >= UNLIMITED) return;
                methods.setValue('commitment.pricing_amount', methods.watch(`commitment.value`) * methods.watch(`commitment.pricing_apply`))
            },
        },
        {
            name: 'commitment.pricing_amount', component: 'TextField', cols,
            params: {
                label: 'Tạm tính', placeholder: 'Nhập giá tạm tính...',
                InputProps: { inputComponent: NumericFormatCustom }
            },
        }, {
            name: 'commitment.depreciatable', component: 'Switch', cols: { xs: 12, md: 3, xl: 1.75 },
            params: {
                label: 'Khấu Hao & Trừ Dần Theo Gói',
                disabled: parseInt(methods.watch(`commitment.term`)) <= 1
            },
        }, {
            name: 'payment_config.payment_config_optional', component: 'SingleSelect',
            cols: { xs: 12, md: 3, xl: 1.25 },
            params: {
                options: [{ value: 'hour', label: 'Giờ' }, { value: 'stream', label: 'Luồng' }],
                input: {
                    label: 'Loại Đối Soát *',
                    placeholder: 'Chọn loại đối soát...',
                },
            }
        }, {
            name: 'commitment.commitment_available', component: 'TextField', cols,
            params: {
                label: 'Cấu Hình Còn Lại', placeholder: 'Nhập cấu hình còn lại...',
                InputProps: { inputComponent: NumericFormatCustom },
                disabled: !methods.watch(`commitment.depreciatable`)
            },
        }, {
            name: '', component: 'DateRange', unController: true, cols: { xs: 12, md: 6, xl: 6 },
            params: {
                value: [methods.watch(`commitment.startdate`), methods.watch(`commitment.enddate`)],
                onChange: (newValues) => {
                    methods.setValue('commitment.startdate', newValues[0])
                    methods.setValue('commitment.enddate', newValues[1])
                }
            }
        }, {
            name: 'commitment.warning_enabled', component: 'Switch', cols: { xs: 12, md: 3, xl: 3 },
            params: {
                label: 'Thông Báo Ngưỡng Sử Dụng'
            },
        }, {
            name: 'commitment.warning_values', component: 'ArrayField', cols: { xs: 12, md: 12, xl: 12 },
            params: {
                styled: {
                    padding: 0,
                    border: 'none',
                },
                label: 'Cấu Hình Mốc Cảnh Báo Sử Dụng',
                name: 'commitment.warning_values',
                attributes: [
                    {
                        name: 'priority', component: 'SingleSelect', cols: { xs: 5, md: 3, xl: 3 },
                        params: {
                            options: [
                                { value: 'LOW', label: 'Low' },
                                { value: 'MEDIUM', label: 'Medium' },
                                { value: 'HIGH', label: 'High' },
                            ],
                            input: { label: 'Mốc Cảnh Báo', placeholder: 'Nhập mốc cảnh báo...' }
                        }
                    },
                    {
                        name: 'value', component: 'TextField', cols: { xs: 5, md: 3, xl: 3 },
                        params: {
                            label: 'Mốc Cảnh Báo', placeholder: 'Nhập mốc cảnh báo...',
                            InputProps: { inputComponent: NumericFormatCustom }
                        }
                    },
                ]
            }
        }
    ]

    const onSubmit = async (values) => {
        const form = {
            ...(_.omit(values, ['payment_config'])),
            commitment: {
                ...values?.commitment,
                startdate: format_date_short(values?.commitment?.startdate?.$d) || format_date_short(values?.commitment?.startdate),
                enddate: format_date_short(values?.commitment?.enddate?.$d) || format_date_short(values?.commitment?.enddate),
            }
        }

        const [servicePayment, servicePrice] = await Promise.all([
            updatePaymentConfig(values?.payment_config?.payment_config_optional),
            updateServicePackage(form)
        ])

        if (!servicePayment || !servicePrice || servicePrice?.errors || servicePayment?.errors) {
            return setNotification({ open: true, message: 'Cập nhật gói cố đinh dịch vụ thất bại.', severity: 'error' })
        }
        queryClientRefetchScope(queryClient, 'service')
        return setNotification({
            open: true, message: 'Thay đổi gói cố định thành công', severity: 'success'
        })
    }
    return (
        <FormProvider {...methods}>
            <Box
                component="form"
                onSubmit={methods.handleSubmit(onSubmit)}
                sx={{
                    p: 2,
                    border: '1px solid rgba(0,0,0,0.18)',
                    borderRadius: '4px'
                }}>
                <Typography sx={{ fontWeight: 'medium', mb: 1.25 }}>
                    Gói Cố Định
                </Typography>
                <FormCustomized attributes={SERVICE_TRANS_FIELDS}
                    submitNested={true}
                    submitting={isLoading}
                />
            </Box>
        </FormProvider>
    )
}

export default withSuspense(PackagePrice, MuiSkeleton["Paper"])