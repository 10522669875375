import { Box, FormControl, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ScheduleTitle = () => {
    const { control, formState: { errors } } = useFormContext()
    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1, mt: 2 }}>
            <Box sx={{ width: 300, minWidth: 300, display: 'flex' }}>
                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                    Tên Hoạt Động | Meeting *
                </Typography>
            </Box>
            <Controller
                control={control}
                name="schedule_title"
                render={({ field: { value, onChange } }) => {
                    return (
                        <FormControl fullWidth>
                            <TextField
                                size='small'
                                placeholder='Nhập tên hoạt động & meeting...'
                                value={value}
                                error={!!errors?.schedule_title}
                                helperText={errors?.schedule_title?.message}
                                onChange={onChange} />
                        </FormControl>
                    )
                }} />
        </Box>
    )
}

export default ScheduleTitle