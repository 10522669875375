import withPermission from '@/hocs/withPermission'
import { Button } from '@mui/material'
import React from 'react'

const ButtonAssigneeCustom = ({ handleClick }) => {
    return (
        <Button variant='contained'
            onClick={handleClick}
            size='small'
            sx={{
                height: 42,
                m: 'auto 0 auto 16px'
            }}
        >
            Chỉ Định
        </Button>
    )
}

export default withPermission(ButtonAssigneeCustom, { feature: 'customer', action: 'assignee' })