import BreadCrumb from "@/components/shared/BreadCrumb"
import withPermission from '@/hocs/withPermission'
import { Box, Typography } from '@mui/material'
import React from 'react'
import DayoffForm from '../shared/Form'

const Create = () => {
    return (
        <Box>
            <Typography className='page-title'>Tạo Phiếu Nghỉ</Typography>
            <BreadCrumb
                data={[
                    { label: "Tài Khoản Cá Nhân", reactLink: "/account/dayoffs" },
                    { label: "Tạo Phiếu Nghỉ" }
                ]}
            />
            <DayoffForm type='create' />
        </Box>
    )
}

export default withPermission(Create, { feature: "dayoff", action: "create" })