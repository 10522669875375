import { Box, Typography } from '@mui/material'
import React from 'react'
import withPermission from '@/hocs/withPermission'
import ReportTabs from '../shareds/ReportTab'

const Warehouse = () => {
    return (
        <Box>
            <ReportTabs />
            <Typography sx={{ fontSize: 14, mb: 2, color: 'grey.700' }}>Report.Warehouse</Typography>
        </Box>
    )
}

export default withPermission(Warehouse, { feature: 'report', action: 'report-warehouse' })