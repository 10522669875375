import Informations from "../v2/Informations";
import ConversantService from "./ConversantService";
import DeviceLiquidation from "./DeviceLiquidation";
import DomainService from "./DomainService";
import EmailServer from "./EmailServer";
import VnisService from "./VnisService";

const ServiceTemplates = {
  DomainService: DomainService,
  ConversantService: ConversantService,
  StorageLFDService: ConversantService,
  TranscodingService: ConversantService,
  TransmuxingService: ConversantService,
  RecordingService: ConversantService,
  VnisService: VnisService,
  DeviceLiquidation: DeviceLiquidation,
  Default: Informations,
  EmailService: EmailServer,
};
export default ServiceTemplates;
