/* eslint-disable jsx-a11y/alt-text */
import DriveApi from "@/apis/beta/Drives/DriveApi";
import WorkDayApi from "@/apis/beta/Employee/WorkDayApi";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import { REACT_APP_DOMAIN_FILE } from "@/libs/constants";
import { usePreviewFileContext } from "@contexts/preview-file";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";

const RequestWorkDays = ({
  open,
  handleClose,
  defaultDateTime,
  employee_id,
  metadata,
  employee_name,
  miss_date_id,
}) => {
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      data: metadata
        ? metadata
        : [
          {
            miss_date: dayjs(defaultDateTime),
            miss_work_n_day: 2,
            miss_name: "",
            miss_image: "",
          },
        ],
    },
  });

  const navigate = useNavigate();
  const preview = usePreviewFileContext();

  const submit = async (values) => {
    let data = values?.data;

    if (data?.some((item) => item?.miss_image)) {
      data = await Promise.all(
        data?.map(async (item) => {
          const file = item?.miss_image;

          if (!file) {
            return item;
          }

          const key = `minh-chung-ngay-nghi/${Date.now()}-${file?.file?.name}`;

          const response = await DriveApi.PresignedUrl(key);

          if (response?.errors || !response?.data?.url) {
            return notify("error", "Upload file error");
          }

          await axios.put(response?.data?.url, file?.file, {
            headers: {
              "Content-Type": file.type,
            },
          });

          return {
            ...item,
            miss_image: key,
          };
        })
      );
    }

    const response = await WorkDayApi.RequestEditWorkDays({
      employee_id,
      miss_dates: data?.map((item) => ({
        ...item,
        miss_date: item?.miss_date?.format("DD-MM-YYYY"),
      })),
    });

    return response;
  };

  const { mutateAsync, isLoading, notify } =
    useMutationWithNotification(submit);

  const { mutateAsync: EditListWorkDay, isLoading: EditListWorkDayLoading } =
    useMutation(WorkDayApi.EditListWorkDay);

  const handleSelectFile = (index) => {
    if (metadata) {
      return preview.show(metadata[index]?.miss_image);
    }

    const input = document.createElement("input");
    input.type = "file";

    input.onchange = (e) => {
      const [file] = e.target.files;

      if (!file?.type?.startsWith("image")) {
        return notify("error", "File must be image");
      }

      if (watch(`data[${index}].miss_image`)) {
        URL.revokeObjectURL(watch(`data[${index}].miss_image`)?.preview);
      }

      if (file) {
        setValue(`data[${index}].miss_image`, {
          file,
          preview: URL.createObjectURL(file),
        });
      }

      input.remove();
    };

    input.click();
  };

  const handleRequestWorkDays = async (values) => {
    if (metadata) {
      const formData = {
        miss_dates: watch("data"),
        employee_id,
        miss_date_id,
      };

      const response = await EditListWorkDay(formData);

      if (!response?.errors) {
        notify("success", "Success");
        navigate("/workdays/manage");
        handleClose();
      }
    } else {
      const response = await mutateAsync(values);

      if (!response?.errors) {
        notify("success", "Success");
        handleClose();
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <Box
        onSubmit={handleSubmit(handleRequestWorkDays)}
        component="form"
        p={2}
      >
        <Typography
          sx={{
            fontSize: { xs: 18, md: 24 },
            fontWeight: "medium",
          }}
        >
          {employee_name
            ? "Xét duyện ngày công " + employee_name
            : "Thêm Ngày Công Bị Mất"}
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Box mt={2}>
            {watch("data")?.map((_, index) => (
              <LocalizationProvider dateAdapter={AdapterDayjs} key={index}>
                <Box display="flex" alignItems="center" mb={2} gap={2} flexWrap="wrap">
                  <Controller
                    control={control}
                    name={`data[${index}].miss_date`}
                    render={({ field }) =>
                      !metadata ? (
                        <DatePicker
                          {...field}
                          disabled={metadata}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              sx={{ flex: 1 }}
                              label="Ngày nghĩ"
                            />
                          )}
                          inputFormat="DD/MM/YYYY"
                        />
                      ) : (
                        <TextField
                          {...field}
                          disabled
                          sx={{ flex: 1 }}
                          size="small"
                          label="Ngày nghĩ"
                        />
                      )
                    }
                  />

                  <Tabs
                    scrollButtons="auto"
                    value={watch("data")?.[index]?.miss_work_n_day}
                    onChange={(_, value) => {
                      setValue(`data.${index}.miss_work_n_day`, value);
                    }}
                    variant="standard"
                    sx={{
                      mr: 3,
                    }}
                  >
                    <Tab disabled={metadata} label="Buổi sáng" value={0} />
                    <Tab disabled={metadata} label="Buổi chiều" value={1} />
                    <Tab disabled={metadata} label="Cả Ngày" value={2} />
                  </Tabs>

                  <Box sx={{ flex: 1 }} onClick={() => handleSelectFile(index)}>
                    {watch(`data[${index}].miss_image`) ? (
                      <Button fullWidth>
                        <img
                          src={
                            watch(`data[${index}].miss_image`).preview ||
                            `${REACT_APP_DOMAIN_FILE}drives/${watch(
                              `data[${index}].miss_image`
                            )}`
                          }
                          style={{
                            objectFit: "cover",
                            width: "100%",
                            height: "100%",
                            border: "1px solid #ccc",
                            borderRadius: 4,
                          }}
                        />
                      </Button>
                    ) : (
                      <Button
                        disabled={metadata}
                        variant="outlined"
                        size="small"
                        fullWidth
                      >
                        Chọn File Ảnh Minh Chứng
                      </Button>
                    )}
                  </Box>

                  <Controller
                    control={control}
                    name={`data[${index}].miss_name`}
                    render={({ field }) => (
                      <TextField
                        multiline
                        fullWidth
                        {...field}
                        sx={{ flex: 1 }}
                        disabled={metadata}
                        placeholder="Ghi chú"
                      />
                    )}
                  />

                  <Button
                    disabled={metadata}
                    onClick={() => {
                      if (watch("data")?.length === 1) return;
                      setValue("data", [
                        ...watch("data")?.filter((_, i) => i !== index),
                      ]);
                    }}
                  >
                    <CloseIcon />
                  </Button>

                  {metadata && (
                    <Controller
                      name={`data[${index}].approval`}
                      control={control}
                      render={({ field }) => {
                        console.log(field);
                        return <Checkbox {...field} />;
                      }}
                    />
                  )}
                </Box>
              </LocalizationProvider>
            ))}
          </Box>

          {!metadata && (
            <Button
              onClick={() =>
                setValue("data", [
                  ...watch("data"),
                  {
                    miss_date: dayjs(defaultDateTime),
                    miss_name: "",
                    miss_work_n_day: 0,
                  },
                ])
              }
              variant="outlined"
              size="small"
            // sx={{ mt: 2 }}
            >
              <AddIcon />
            </Button>
          )}

          <Box mt={4}>
            <Button
              fullWidth
              disabled={isLoading || EditListWorkDayLoading}
              startIcon={
                (isLoading || EditListWorkDayLoading) && (
                  <CircularProgress size={14} />
                )
              }
              type="submit"
              variant="contained"
              size="small"
            >
              Lưu
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RequestWorkDays;
