import { useState } from 'react'

const useArrayObjects = ({ objects = [] }) => {
    const [state, setState] = useState(objects || [])

    const insert = (newObject = {}) => {
        setState(preState => [...preState, newObject])
    }

    const removeIndex = (removeIndex = 0) => {
        setState(preState => preState?.filter((_, i) => i !== removeIndex))
    }

    const insertIndex = (newObject = {}, index = -1) => {
        setState(preState => [...preState?.slice(0, index), newObject, preState?.slice(index)])
    }

    const update = (object = {}, index = 0) => {
        setState(preState => [
            ...preState?.slice(0, index),
            { ...object },
            ...preState?.slice(index + 1)
        ])
    }

    const updateAttribute = (attribute = {}, index = 0) => {
        setState(preState => [
            ...preState?.slice(0, index),
            { ...preState[index], ...attribute },
            ...preState?.slice(index + 1)
        ])
    }
    const clear = () => setState([])
    return {
        state,
        insert,
        insertIndex,
        removeIndex,
        update,
        updateAttribute,
        clear
    }
}


export default useArrayObjects