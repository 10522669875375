import React from "react";
import ServiceHeader from "../ServiceHeader";
import ServiceTabs from "../ServiceTabs";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <ServiceHeader />
      <ServiceTabs />
      {children}
    </React.Fragment>
  );
};

export default Layout;
