import Layout from "./Layout"
import InvoiceInformation from "./InvoiceInformation"
import Mailer from "./Mailer"
import Service from "./Service"

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Layout,
    InvoiceInformation,
    Mailer,
    Service,
}