import CompanyApi from "@/apis/beta/Company/CompanyApi";
import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import { queryClientRefetcher } from "@/functions";
import withPermission from "@/hocs/withPermission";
import { INDUSTRIES } from "@/libs/constants";
import DatePicker from "@components/DatePicker";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { useNotificationContext } from "@contexts/notification";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import CompanyTax from "../shared/CompanyTax";
// import { sleep } from "react-query/types/core/utils";

const Precheck = ({ customerId = -1, onClose }) => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = React.useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      company_id: -1,
      company_name: "",
      company_tax: "",
      company_website: "",
      company_established_date: moment(),
      company_industry: "",
      company_address: "",
    },
  });

  const onSubmit = async (values) => {
    setSubmit(true);
    let cid = -1;
    if (parseInt(values?.company_id) !== -1) {
      cid = parseInt(values?.company_id);
    } else {
      const company = await CompanyApi.Create({
        ..._.omit(values, ["company_id"]),
      });
      setSubmit(false);

      if (!company || company?.errors || company?.company?.company_id === -1) {
        return setNotification({
          open: true,
          message: company?.errors?.[0]?.message || "Tạo công ty thất bại",
          severity: "error",
        });
      }

      cid = company?.company?.company_id || -1;
    }

    const response = await CustomerApi.ChangeCustomerType({
      customerId,
      data: {
        cid,
        customer_type: "company",
      },
    });

    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }

    setNotification({
      open: true,
      message: `Chuyển đổi loại khách hàng thành công.`,
      severity: "success",
    });

    queryClientRefetcher(queryClient, [
      "customers.list",
      "customers.detail",
      "companies.list",
      "customer.customer-journeys",
      "companies.list",
      "company.company_updates",
      "customer.customer_updates",
    ]);
    return onClose();
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ pt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="company_tax"
            render={({ field: { value } }) => {
              return <CompanyTax value={value} setValue={setValue} />;
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="company_name"
            render={({ field }) => (
              <TextField
                {...field}
                label="Tên Công Ty *"
                disabled={parseInt(watch(`company_id`)) !== -1}
                InputLabelProps={{ shrink: true }}
                error={!!errors?.company_name}
                helperText={errors?.company_name?.message}
                placeholder="Nhập tên công ty..."
                size="small"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="company_website"
            render={({ field }) => (
              <TextField
                {...field}
                disabled={parseInt(watch(`company_id`)) !== -1}
                placeholder="Nhập website công ty..."
                size="small"
                label="Website"
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="company_address"
            render={({ field }) => (
              <TextField
                {...field}
                disabled={parseInt(watch(`company_id`)) !== -1}
                placeholder="Nhập địa chỉ công ty..."
                size="small"
                label="Địa chỉ"
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="company_established_date"
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <DatePicker
                  value={value}
                  onChange={onChange}
                  disabled={parseInt(watch(`company_id`)) !== -1}
                  label="Ngày Thành Lập *"
                />
                {!!errors?.company_established_date && (
                  <ErrorHelperText
                    message={errors?.company_established_date?.message}
                  />
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="company_industry"
            render={({ field: { value, onChange } }) => {
              const selected =
                INDUSTRIES?.find((i) => i?.value === value) || null;
              return (
                <FormControl fullWidth>
                  <SingleSelectCustomize
                    disabled={parseInt(watch(`company_id`)) !== -1}
                    value={selected}
                    options={INDUSTRIES}
                    input={{
                      label: "Lĩnh Vực *",
                      placeholder: "Nhập lĩnh vực/ ngành nghề tìm kiếm...",
                    }}
                    onChange={(event, newValue) =>
                      onChange(newValue?.value || -1)
                    }
                  />
                  {!!errors?.company_industry && (
                    <ErrorHelperText
                      message={errors?.company_industry?.message}
                    />
                  )}
                </FormControl>
              );
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          mt: 3,
          justifyContent: "flex-end",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
        }}
      >
        <Button
          size="small"
          variant="outlined"
          onClick={onClose}
          disabled={submit}
        >
          Huỷ thao tác
        </Button>
        <Button
          size="small"
          variant="contained"
          type="submit"
          disabled={submit}
          startIcon={
            submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
          }
        >
          Xác nhận
        </Button>
      </Box>
    </Box>
  );
};

export default withPermission(Precheck, {
  feature: "company",
  action: "create",
});
