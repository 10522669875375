import { Button } from '@mui/material'
import React from 'react'
import { ScreenRotationAltOutlined } from '@mui/icons-material';
import withPermission from '@/hocs/withPermission';

const Refund = () => {

    const onSubmit = async () => {

    }

    return (
        <Button size="small"
            fullWidth
            variant="outlined"
            disabled={true}
            onClick={onSubmit}
            startIcon={<ScreenRotationAltOutlined fontSize='small' />}
        >
            hoàn tiền
        </Button>
    )
}

export default withPermission(Refund, { feature: "invoice", action: "refund", type: "button" })