import ButtonBaseExport from "../shared/ButtonBaseCustom";
import { useMutation } from "react-query";
import { Filter } from "@/hooks/useFilters";
import { downloadFileWithBinaryResponse } from "@/functions";
import ExcelApi from "@/apis/shared/Reports/ExcelApi";


const ContractsExport = ({ query }) => {
    const { mutateAsync, isLoading } = useMutation(ExcelApi.ContractsExport);

    const handler = async () => {
        const response = await mutateAsync({
            file: true,
            query: Filter.stringify(query),
        });
        if (!response) return;
        downloadFileWithBinaryResponse(`${Date.now()}.xlsx`, response);
    };

    return (
        <ButtonBaseExport
            handler={handler}
            loading={isLoading}
            disabled={isLoading}
        />
    );
};

export default ContractsExport;
