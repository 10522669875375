import { format_numeral_price } from '@/functions'
import { SERVICE_PERIODS } from '@/libs/constants'
import { Box, Divider, Paper, Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import ButtonSubmit from './ButtonSubmit'
import ProductConfigoption from './Configoption'
import ProductAddons from './ProductAddons'
import ProductPricingAmount from './ProductPricingAmount'

const Overview = () => {

    const { watch } = useFormContext()

    return (
        <Box sx={{
            minWidth: 350,
            position: 'relative'
        }}>
            <Paper sx={{
                maxWidth: '100%',
                p: 3,
                position: 'sticky',
                top: 16,
                right: 16
            }}>
                <Typography sx={{
                    fontWeight: 'medium',
                    fontSize: 20,
                    textTransform: 'capitalize',
                    mb: 3
                }}>
                    Tóm tắt đơn hàng
                </Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Typography sx={{
                        fontWeight: 400,
                        color: "#777680"
                    }}>
                        {watch("product_name")}
                    </Typography>
                    <Typography component="span" sx={{
                        m: 'auto 0',
                        fontWeight: 'medium'
                    }}>
                        <Typography sx={{
                            display: 'inline-flex',
                            textDecoration: "underline",
                            fontWeight: 'medium'
                        }}>đ</Typography>
                        {format_numeral_price(watch("product_pricing_amount"))}
                    </Typography>
                </Box>
                <Divider sx={{ m: '16px 0' }} />

                <ProductConfigoption />
                <Divider sx={{ m: '16px 0' }} />
                <ProductAddons />
                <Divider sx={{ m: '16px 0' }} />
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <Typography sx={{
                        fontWeight: 400,
                        color: "#777680"
                    }}>
                        Thời hạn mua:
                    </Typography>
                    <Typography sx={{ fontWeight: 'medium' }}>
                        {SERVICE_PERIODS.find(s => s.value === watch("product_billing_cycle"))?.coefficient} Tháng
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <Typography sx={{
                        fontWeight: 400,
                        color: "#777680"
                    }}>
                        Đơn giá mua:
                    </Typography>
                    <Typography component="span" sx={{ color: "#777680" }}>
                        <Typography sx={{ display: 'inline-flex', textDecoration: "underline" }}>đ</Typography>
                        {format_numeral_price(watch(`product_pricing_per_month`))}/ Tháng
                    </Typography>
                </Box>
                <Divider sx={{ m: '16px 0' }} />
                <ProductPricingAmount />
                <ButtonSubmit />
            </Paper>
        </Box>
    )
}

export default Overview