import InvoiceApi from '@/apis/beta/Invoice/InvoiceApi'
import withPermission from '@/hocs/withPermission'
import ModalConfirm from '@components/shared/ModalConfirm'
import { CheckOutlined } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import React from 'react'
const PaymentTransaction = ({
    disabled = true,
    setDisabled,
    callback,
    ...invoice
}) => {
    const [openModal, setOpenModal] = React.useState(false)
    const onSubmit = async () => {
        const transaction = await InvoiceApi.Approved({
            invoiceId: invoice?.invoice_id,
        })
        if (!transaction || transaction?.errors) {
            return {
                message: transaction?.errors?.[0]?.message || "Approved thất bại. Vui lòng thử lại!",
                status: 404
            }
        }

        return {
            message: `Approved hoá đơn thành công.`,
            status: 200
        }
    }

    const handlerClick = () => {
        if (openModal) {
            setDisabled(false)
            return setOpenModal(false);
        }
        setDisabled(true)
        return setOpenModal(true)
    }
    return (
        <React.Fragment>
            {openModal && <ModalConfirm
                callback={callback}
                handleClose={handlerClick}
                handleSubmit={onSubmit}
                dialog={{
                    title: "Xác Nhận Hoá Đơn",
                    content: <Typography component="div">
                        Hoá đơn số
                        <Typography sx={{ display: 'inline', fontWeight: 'medium', m: 'auto 4px' }}>
                            [{invoice?.invoice_id}]
                        </Typography>
                        sẽ được xác nhận để bộ phận kế toán có thể thực hiện thao tác xuất hoá đơn.
                        Thao tác sẽ không thể phục hồi, bạn có muốn thực hiện?
                    </Typography>
                }} />}
            <Button size='small'
                variant='contained'
                fullWidth
                disabled={disabled || !!invoice?.cskh_confirmed}
                onClick={handlerClick}
                startIcon={<CheckOutlined />}
            >
                Xác Nhận Hoá Đơn
            </Button>
        </React.Fragment>
    )
}

export default withPermission(PaymentTransaction, { feature: "invoice", action: "invoice-transaction-create", type: "button" })