import AccountApi from "@/apis/beta/Employee/AccountApi";
import { useNotificationContext } from "@contexts/notification";
import { vestResolver } from "@hookform/resolvers/vest";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import changePasswordValidator from "./validator";

const ChangePassword = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: vestResolver(changePasswordValidator),
    defaultValues: {
      password: "",
      new_password: "",
      new_password_confirmation: "",
    },
  });

  const { setNotification } = useNotificationContext();

  const { mutateAsync, isLoading } = useMutation(AccountApi.ChangePasssword);

  const handleChangePass = async (values) => {
    const response = await mutateAsync(values);

    if (response?.errors)
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message || "Something went wrong",
        severity: "error",
      });

    setNotification({
      open: true,
      message: "Change password success",
      severity: "success",
    });
  };

  return (
    <Box
      onSubmit={handleSubmit(handleChangePass)}
      component="form"
      sx={{ border: 1, borderRadius: "4px", p: 3 }}
    >
      <Typography sx={{ textTransform: "uppercase", mb: 2 }}>
        Thay đổi mật khẩu
      </Typography>
      <Stack direction={"row"} spacing={2}>
        <Box sx={{ width: "calc(100% /3)" }}>
          <Typography sx={{ mb: 0.5 }}>Nhập mật khẩu cũ*</Typography>
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <TextField
                {...field}
                type="password"
                fullWidth
                size="small"
                helperText={errors?.password?.message}
                error={!!errors?.password}
              />
            )}
          />
        </Box>
        <Box sx={{ width: "calc(100% /3)" }}>
          <Typography sx={{ mb: 0.5 }}>Nhập mật khẩu mới *</Typography>
          <Controller
            control={control}
            name="new_password"
            render={({ field }) => (
              <TextField
                {...field}
                type="password"
                fullWidth
                size="small"
                helperText={errors?.new_password?.message}
                error={!!errors?.new_password}
              />
            )}
          />
        </Box>
        <Box sx={{ width: "calc(100% /3)" }}>
          <Typography sx={{ mb: 0.5 }}>Nhập lại mật khẩu mới*</Typography>
          <Controller
            control={control}
            name="new_password_confirmation"
            render={({ field }) => (
              <TextField
                {...field}
                type="password"
                fullWidth
                size="small"
                helperText={errors?.new_password_confirmation?.message}
                error={!!errors?.new_password_confirmation}
              />
            )}
          />
        </Box>
      </Stack>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="outlined"
          size="small"
          sx={{ mr: 2, height: 36 }}
          disabled={isLoading}
        >
          Huỷ Thao Tác
        </Button>
        <Button
          disabled={isLoading}
          variant="contained"
          startIcon={
            isLoading && (
              <CircularProgress size={14} sx={{ color: "#777680" }} />
            )
          }
          size="small"
          type="submit"
          sx={{ height: 36, minWidth: 120 }}
        >
          Lưu Thay Đổi
        </Button>
      </Box>
    </Box>
  );
};

export default ChangePassword;
