import { Box, Button, Chip, StepLabel, Typography } from "@mui/material";
import React from "react";
import PrivateIconWithFormat from "../shared/icons";
import FormSubcontract from "./FormSubcontract";


const StepScanUploadSubContract = ({ contract, subcontract, callback }) => {

    const [openForm, setOpenForm] = React.useState(false);

    return (
        <React.Fragment>
            {openForm && <FormSubcontract contract={contract} subcontract={subcontract} handleClose={()=> {setOpenForm(false)}}/>}
            <StepLabel
                StepIconProps={{
                    sx: {
                        fontSize: 20,
                        mb: `72px`
                    }
                }}
            >
                <Chip
                    size='verysmall'
                    variant="customize-pending"
                    label="Scan & Upload" />
                <Box sx={{ display: 'flex', mb: 1 }}>
                    <PrivateIconWithFormat icon="ScanIcon" />
                    <Typography sx={{ mr: 2 }}>
                        Phụ Lục Hợp đồng {subcontract?.subcontract_code} {subcontract?.subcontract_status === 5 ? "đang chờ Scan & Upload." : "đã Scan."}
                    </Typography>
                </Box>
                <Button size="small" sx={{ height: 36 }}
                    disabled={openForm}
                    onClick={() => setOpenForm(true)}
                >Tải Lên File Hợp Đồng Phụ Lục</Button>
            </StepLabel>
        </React.Fragment>
    )
}

export default StepScanUploadSubContract;