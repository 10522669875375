import NumericFormatCustom from "@components/NumericFormatCustom";
import { FormControl, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const Amount = () => {
  const { control } = useFormContext();

  return (
    <Controller
      name="product_pricing_amount"
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <TextField
              size="small"
              value={value}
              onChange={onChange}
              label="Giá Bán Gói *"
              placeholder="Nhập giá bán..."
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
          </FormControl>
        );
      }}
    />
  );
};

export default Amount;
