import NumericFormatCustom from "@components/NumericFormatCustom";
import { Box, FormControl, TextField } from "@mui/material";
import React, { Fragment } from "react";
import { Controller, useFormContext } from "react-hook-form";

const ServerConfigBuilder = ({ position = -1 }) => {
  const { control } = useFormContext();

  if (position === -1) return null;
  return (
    <Fragment>
      <Box sx={{ display: "flex", gap: 1.5, width: "100%", mb: 1.5 }}>
        <Controller
          name={`service_informations.[${position}].name`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormControl fullWidth>
              <TextField
                label="CPU"
                InputLabelProps={{ shrink: true }}
                placeholder="Thông tin CPU..."
                value={value}
                onChange={onChange}
                size="small"
              />
            </FormControl>
          )}
        />
        <Controller
          name={`service_informations.[${position}].warehouse_capacity_value`}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <TextField
                label="vCores"
                InputLabelProps={{ shrink: true }}
                {...field}
                size="small"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  endAdornment: "Core(s)",
                }}
                placeholder="Nhập thông số Core..."
              />
            </FormControl>
          )}
        />
      </Box>
    </Fragment>
  );
};

export default ServerConfigBuilder;
