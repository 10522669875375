import Detail from "./Detail";
import List from "./List";
import Preview from "./Preview";
import Layout from "./Layout";
import Mailer from "./Mailers";
import Statement from "./Statement";
import Confirmation from "./Confirmations";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  List,
  Detail,
  Preview,
  Layout,
  Mailer,
  Statement,
  Confirmation
};
