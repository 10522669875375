import { Box, Paper, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import DailyBookingRoom from '../DailyBookingRoom'
import { useLayoutContext } from '@/contexts/layout';

const UpcomingSchedule = () => {

    const [hover, setHover] = React.useState(false)

    const {
        isCollapse,
    } = useLayoutContext();

    const getDayofWeek = () => {
        let data = [
            { title: "Hôm nay", value: moment().format("DD/MM/YYYY") },
            { title: "Ngày mai", value: moment().add(1, 'd').format("DD/MM/YYYY") },
            { title: "Ngày kia", value: moment().add(2, 'd').format("DD/MM/YYYY") },
            { title: moment().add(3, 'd').format('dddd'), value: moment().add(3, 'd').format("DD/MM/YYYY") },
            { title: moment().add(4, 'd').format('dddd'), value: moment().add(4, 'd').format("DD/MM/YYYY") },
            { title: moment().add(5, 'd').format('dddd'), value: moment().add(5, 'd').format("DD/MM/YYYY") },
        ]
        return data.filter(item => item?.title !== "Sunday")
    }
    const [dayofWeeks] = React.useState(getDayofWeek())

    return (
        <Paper sx={{
            p: 2, mt: 2,
            display: { xs: 'none', md: 'block' }
        }} elevation={hover ? 8 : 2}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 2
            }}>
                <Typography sx={{ fontWeight: 'medium', m: 'auto 0' }}>
                    Company Activities & Room Meetings
                </Typography>
            </Box>
            <Box sx={{
                width: '100%',
                maxWidth: '100%',
                display: 'flex',
                overflow: "hidden",
                overflowX: 'auto',
                "&::-webkit-scrollbar": {
                    height: 2.5,
                    "&-track": {
                        background: '#f1f1f1'
                    },
                    "&-thumb": {
                        background: "#1a237f"
                    }
                }
            }}>
                {dayofWeeks.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <DailyBookingRoom data={{ ...item }} />
                        </React.Fragment>
                    )
                })}
            </Box>
        </Paper>
    )
}

export default UpcomingSchedule