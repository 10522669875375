import { format_date_short } from "@/functions";
import { UNLIMITED } from "@/libs/constants";
import _ from "lodash";

const rebuildProduct = (metadata = {}, scope) => {
    let form = _.pick(metadata, [
        'product_id', 'product_name', 'product_group_id', 'product_group_name', 'product_payment_type',
        'product_template'
    ])


    switch (scope) {
        case "SwiftFederation.Trans":
        case "SwiftFederation.StorageLFD":
            const transcodePrice = metadata?.product_pricing_configures
            form = {
                ...form,
                service_pricing_configure: {
                    method: transcodePrice?.method,
                    unit: transcodePrice?.unit,
                    steps: transcodePrice?.product_pricing_steps || []
                },
                service_commitment_configure: {
                    unit: transcodePrice?.unit,
                    value: transcodePrice?.value,
                    pricing_apply: transcodePrice?.pricing_apply,
                    pricing_amount: transcodePrice?.pricing_amount,
                    term: transcodePrice?.term,
                    prepaid: transcodePrice?.prepaid,
                },
                service_management_billing: {
                    statement: metadata?.service_management_billing?.statement
                }
            }

            break;

        case "Mlytics":
            form = {
                ...form,
                service_configoptions: metadata?.product_configoptions?.map((configoption) => {
                    return ({
                        name: configoption?.name,
                        value: configoption?.value,
                        unit: configoption?.unit,
                        service_pricing_configure: {
                            method: 'tierd',
                            unit: configoption?.unit,
                            steps: configoption?.product_pricing_steps?.map((step) => ({
                                start: 0,
                                end: UNLIMITED,
                                pricing_apply: step?.pricing_apply
                            }))
                        },
                        service_commitment_configure: {
                            value: configoption?.value,
                            unit: configoption?.unit,
                        }
                    })
                }),
                product_price: metadata?.product_pricing_amount
            }
            break;
        default:
            form = _.pick(metadata, [
                "product_id",
                "product_name",
                "product_group_id",
                "product_group_name",
                "product_payment_type",
                "product_type",
                "product_template",
                "service_extra_fields",
                "product_pricing_amount",
                "product_pricing_init",
                "product_addons",
                "product_paid",
            ]);
            form.product_addon_mergebill = !form.product_addon_mergebill;
            // perform date of bill
            form.service_management_billing = {
                service_billing_startdate: format_date_short(
                    metadata?.service_management_billing?.service_billing_startdate?.$d ||
                    metadata?.service_management_billing?.service_billing_startdate
                ),
                service_billing_enddate: format_date_short(
                    metadata?.service_management_billing?.service_billing_enddate?.$d
                ),
                service_nextbill_startdate: format_date_short(
                    metadata?.service_management_billing?.service_nextbill_startdate?.$d
                ),
                service_nextbill_enddate: format_date_short(
                    metadata?.service_management_billing?.service_nextbill_enddate?.$d
                ),
                service_control_startdate: format_date_short(
                    metadata?.service_management_billing?.service_control_startdate?.$d
                ),
                service_control_enddate: format_date_short(
                    metadata?.service_management_billing?.service_control_enddate?.$d
                ),
            };
            if (metadata?.product_template?.match(/(SwiftFederation)/)) {
                form.product_configoptions = metadata?.product_configoptions || [];
            }
            if (metadata?.product_template?.match(/(SwiftFederation.Default)/)) {
                form.product_pricing_configures = metadata?.product_pricing_configures;
            }
            if (!metadata?.product_template?.match(/(SwiftFederation|Domain)/)) {
                form.product_informations = metadata?.product_informations || [];
            }
            if (metadata?.product_template?.match(/(Domain)/)) {
                form.product_billing_cycle = metadata?.product_billing_cycle;
                form.product_metadata = {
                    domain: {
                        ...metadata?.domain,
                        domain_registrationperiod: metadata?.product_billing_cycle,
                    },
                };
            }
            break;
    }

    form.service_management_billing = {
        ...form?.service_management_billing,
        service_billing_startdate: format_date_short(
            metadata?.service_management_billing?.service_billing_startdate?.$d ||
            metadata?.service_management_billing?.service_billing_startdate
        ),
        service_billing_enddate: format_date_short(
            metadata?.service_management_billing?.service_billing_enddate?.$d
        ),
        service_nextbill_startdate: format_date_short(
            metadata?.service_management_billing?.service_nextbill_startdate?.$d
        ),
        service_nextbill_enddate: format_date_short(
            metadata?.service_management_billing?.service_nextbill_enddate?.$d
        ),
        service_control_startdate: format_date_short(
            metadata?.service_management_billing?.service_control_startdate?.$d
        ),
        service_control_enddate: format_date_short(
            metadata?.service_management_billing?.service_control_enddate?.$d
        ),
    };

    return form

}

export default rebuildProduct