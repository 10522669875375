import { create, enforce, skipWhen, test } from "vest";

const suite = create((data = {}) => {

    test("website", "Vui lòng chọn website chứa vùng phân quyền.", () => {
        enforce(data?.website !== -1).isTruthy()
    })
    test("feature", "Vui lòng nhập tên tính năng.", () => {
        enforce(data?.feature).isNotEmpty()
    })

    test('ations', "Vui lòng thêm ít nhất 1 tính năng để khởi tạo quyền", () => {
        enforce(data?.actions?.length > 0).isTruthy()
    })
    skipWhen(
        (res) => res.hasErrors("actions"),
        () => {
            let names = []
            data?.actions?.forEach((action, index) => {
                test(`actions.[${index}].action_name`, "Tên hàm/ thao tác không được để trống.", () => {
                    enforce(action?.action_name).isNotEmpty()
                })

                const idx = names.indexOf(action?.action_name?.toLowerCase())

                test(`actions.[${index}].action_name`, `Tên hàm/ thao tác bị trùng lặp [${idx}].`, () => {
                    enforce(idx === -1).isTruthy()
                })
                names.push(action?.action_name?.toLowerCase())
            });
        }
    )
})
export default suite;
