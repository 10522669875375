import WorkDayApi from "@/apis/beta/Employee/WorkDayApi";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { resolve_work_n_day } from "../../shared";

const EditWorkDay = ({ open, handleClose, values }) => {
  const { handleSubmit, control } = useForm({
    defaultValues: {
      work_n_day: resolve_work_n_day(values?.work_n_day),
      note: values?.work_day_note,
    },
  });

  const { notify, isLoading, mutateAsync, handleCallRefetcher } =
    useMutationWithNotification(WorkDayApi.EditWorkDays);

  const handleEditWorkDay = async (formValues) => {
    const response = await mutateAsync({
      employee_id: values?.employee_id,
      work_day_date: dayjs(values?.work_day_date).format("DD/MM/YYYY"),
      ...formValues,
    });

    if (response?.errors)
      return notify(
        "error",
        response?.errors?.[0]?.message || "Something went wrong"
      );

    notify("success", "Saved");
    handleCallRefetcher("Employee.WorkDay.List");
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle textAlign="center">
        Chỉnh sửa ngày công - Nhân Viên: {values?.employee_name} - Ngày:{" "}
        {dayjs(values?.work_day_date).format("DD/MM/YYYY")}
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit(handleEditWorkDay)} mt={2}>
          <Controller
            control={control}
            name="work_n_day"
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Ngày công</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  label="Ngày công"
                  fullWidth
                  {...field}
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={0.5}>0.5</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                </Select>
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name="note"
            render={({ field }) => (
              <TextField
                multiline
                fullWidth
                {...field}
                minRows={5}
                placeholder="Ghi chú"
              />
            )}
          />

          <Box display="flex" gap={2} mt={2} justifyContent="end">
            <Button size="small" variant="outlined" onClick={handleClose}>
              Huỷ bỏ
            </Button>
            <Button
              type="submit"
              startIcon={isLoading && <CircularProgress size={14} />}
              size="small"
              disabled={isLoading}
              variant="contained"
            >
              Lưu
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditWorkDay;
