import { requestWithToken } from "@/functions";
import { API_V3_URL } from "@/libs/constants";

class ServiceTimeApi {

    ServiceStartdate = async ({ serviceId, data }) => {
        return await requestWithToken(
            `${API_V3_URL}/services/${serviceId}/times/startdate`,
            "PUT",
            {
                service: data,
            },
            {},
            {}
        )
            .then(({ data }) => {
                return { service: data?.data?.service };
            })
            .catch((error) => {
                return error.response;
            });
    };

    NextBill = async ({ serviceId, data }) => {
        return await requestWithToken(
            `${API_V3_URL}/services/${serviceId}/times/nextbill`,
            "PUT",
            {
                service_billing: data,
            },
            {},
            {}
        )
            .then(({ data }) => {
                return { service: data?.data?.service };
            })
            .catch((error) => {
                return error.response;
            });
    };

    FirstBill = async ({ serviceId, data }) => {
        return await requestWithToken(
            `${API_V3_URL}/services/${serviceId}/times/first_bill`,
            "PUT",
            {
                service_billing: data,
            },
            {},
            {}
        )
            .then(({ data }) => {
                return { service: data?.data?.service };
            })
            .catch((error) => {
                return error.response;
            });
    };

    CurrentBill = async ({ serviceId, data }) => {
        return await requestWithToken(
            `${API_V3_URL}/services/${serviceId}/times/current`,
            "PUT",
            {
                service_billing: data,
            },
            {},
            {}
        )
            .then(({ data }) => {
                return { service: data?.data?.service };
            })
            .catch((error) => {
                return error.response;
            });
    };

    Statement = async ({ serviceId, data }) => {
        return await requestWithToken(
            `${API_V3_URL}/services/${serviceId}/times/statement`,
            "PUT",
            {
                service_billing: data,
            },
            {},
            {}
        )
            .then(({ data }) => {
                return { service: data?.data?.service };
            })
            .catch((error) => {
                return error.response;
            });
    };

    ChangeDateRenew = async ({ serviceId, data }) => {
        return await requestWithToken(
            `${API_V3_URL}/services/${serviceId}/times/offset_renew`,
            "PUT",
            {
                service: data,
            },
            {},
            {}
        )
            .then(({ data }) => {
                return { service: data?.data?.service };
            })
            .catch((error) => {
                return error.response;
            });
    };

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ServiceTimeApi();
