import MuiSkeleton from '@components/shared/MuiSkeleton'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useState } from 'react'
import { resolveServiceLMSNoofLiveJobs } from '../services'
import TranscodeTimes from '../shared/TranscodeTimes'
import withSuspense from '@/hocs/withSuspense'

const NoLiveJobs = ({ service }) => {
    const today = dayjs()
    const [year, setYear] = useState({
        value: today?.format('MM-YYYY'),
        label: today?.format('MM-YYYY'),
        gte: today?.startOf('month').format('DD/MM/YYYY'),
        lte: today?.endOf('month').format('DD/MM/YYYY'),
    })
    const {
        data: [liveJobs],
        isFetching, isLoading
    } = resolveServiceLMSNoofLiveJobs(service?.service_creatable_uuid, {
        ...year
    })
    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box sx={{
                    mt: 1.5,
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between', alignItems: 'center'
                }}>
                    <Typography sx={{ fontSize: 14, fontWeight: 'medium' }}>
                        No of LiveJobs
                    </Typography>
                    <TranscodeTimes year={year} setYear={setYear} />
                </Box>
                <TableContainer>
                    <Table size='small' sx={{
                        border: "1px solid #333",
                        bgcolor: "#7776801F"
                    }} aria-label="lms.no_of_live_jobs">
                        <TableHead sx={{ bgcolor: "#FFF" }}>
                            <TableRow>
                                <TableCell>Customer</TableCell>
                                <TableCell align="right">H264</TableCell>
                                <TableCell align="right">H265</TableCell>
                            </TableRow>
                        </TableHead>
                        {(!isLoading && !isFetching && !!liveJobs) ? <TableBody>
                            {_.map(liveJobs, (liveJob, key) => (
                                <TableRow
                                    key={key}
                                >
                                    <TableCell component="th" scope="row">
                                        {key}
                                    </TableCell>
                                    <TableCell align="right">
                                        {Object.values(liveJob)[0]?.h264MaxConcurrentNum || ''}
                                    </TableCell>
                                    <TableCell align="right">
                                        {Object.values(liveJob)[0]?.h265MaxConcurrentNum || ''}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody> : <MuiSkeleton.LoadingDotBox />}
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}

export default withSuspense(NoLiveJobs, MuiSkeleton["LoadingDotBox"])