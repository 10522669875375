import withPermission from '@/hocs/withPermission'
import { AddOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const Create = () => {
    return (
        <Link to="/customer_contracts/new" replace>

            <Button variant="contained"
                size="small" startIcon={<AddOutlined />}
            >
                Thêm Hợp Đồng Mới
            </Button>
        </Link>
    )
}

export default withPermission(Create, {
    feature: "contract",
    action: "customer-contract-create",
    type: "Button"
})