import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { format_date_short, queryClientRefetchScope } from "@/functions";
import withPermission from "@/hocs/withPermission";
import ModalConfirm from "@components/shared/ModalConfirm";
import { ReceiptLongOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Fragment, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

const CreateInvoiceRenew = ({ service }) => {
  const location = useNavigate();
  const queryClient = useQueryClient();
  const [isCreated, setCreated] = useState(false);
  const { mutateAsync } = useMutation(ServiceApi.CreateInvoiceRenew);

  const onSubmit = async () => {
    const response = await mutateAsync(service?.service_id);
    if (!response || response?.errors) {
      return {
        status: 400,
        message:
          response?.errors?.[0]?.message || "Failed when create invoice.",
      };
    }
    return {
      status: 200,
      message: "Tạo hoá đơn gia hạn dịch vụ thành công.",
      redirect: location(
        `/invoices/${response?.data?.invoice?.invoice_id}` ||
          `/services/${service?.service_id}/invoices`,
        { replace: true }
      ),
    };
  };
  const callback = () => queryClientRefetchScope(queryClient, "service");
  return (
    <Fragment>
      {isCreated && (
        <ModalConfirm
          handleSubmit={onSubmit}
          callback={callback}
          handleClose={() => setCreated(false)}
          dialog={{
            title: "Tạo hoá đơn gia hạn",
            content: `Dịch vụ ${
              service?.service_name
            } sẽ được tạo một hoá đơn gia hạn với chu kỳ từ ${format_date_short(
              service?.service_nextbill_startdate
            )} đến ${format_date_short(service?.service_nextbill_enddate)}.`,
          }}
        />
      )}
      <Button
        size="small"
        variant="outlined"
        disabled={isCreated || service?.service_status !== "active"}
        onClick={() => setCreated(true)}
        startIcon={<ReceiptLongOutlined fontSize="small" />}
      >
        Tạo Hoá Đơn Gia Hạn
      </Button>
    </Fragment>
  );
};

export default withPermission(CreateInvoiceRenew, {
  feature: "service",
  action: "create-invoice-renew",
  type: "Button",
});
