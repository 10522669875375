import MultipleSelectCustomize from '@components/shared/MultipleSelectCustomize'
import { FormControl } from '@mui/material'

const Projects = ({
    onChange,
    value,
    projects = []
}) => {
    return (
        <FormControl fullWidth sx={{ maxWidth: { xs: '100%', md: 250 } }}>
            <MultipleSelectCustomize
                value={projects?.filter(project => value?.projects?.includes(project?.id)) || []}
                options={projects}
                formatCollection={{
                    label: 'name',
                    value: 'id'
                }}
                compareAttributes={['name']}
                limitTags={2}
                onChange={(event, newValue) => onChange(preProps => ({
                    ...preProps,
                    projects: [...newValue?.map(project => project?.id)]
                }))}
                input={{
                    label: 'Projects',
                    placeholder: 'Chọn projects tương ứng...'
                }}
            />
        </FormControl>
    )
}

export default Projects