import { encodeBase64, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class ServiceInvoiceApi {
    List = ({
        serviceId = null,
        query = null,
        page = 1,
        pageSize = 20
    }) => {
        return useQueryWithCustomized(
            ["service.invoices", serviceId, query, page, pageSize],
            async () => {
                return await requestWithToken(`${API_V3_URL}/services/${serviceId}/service_invoices?${query ? `&filters=${encodeBase64(query)}` : ''}&page=${page}&page_size=${pageSize}`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return {
                            invoices: data?.data?.invoices,
                            pagination: data?.data?.pagination
                        }
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            }
        );
    }

    CreateCounterInvoice = async ({ serviceId }) => {
        return await requestWithToken(`${API_V3_URL}/services/${serviceId}/service_invoices/create_invoice_counter`, "POST",
            {}, {}, {})
            .then(({ data }) => {
                if (data?.error) return data;
                return data?.data;
            })
            .catch((error) => {
                return error?.response
            });
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ServiceInvoiceApi();
