import { CUSTOMER_STATUS_TYPES } from '@/libs/constants'
import { Chip, Grid, Typography } from '@mui/material'
import React from 'react'

const CustomerStatus = ({ customer_id, customer_service_information }) => {


    const status = React.useMemo(() => {
        return CUSTOMER_STATUS_TYPES?.find(stt => stt.value === customer_service_information?.customer_status)
    }, [customer_service_information?.customer_status])

    return (
        <Grid container spacing={2} direction={{ xs: "column-reverse", md: "row" }}>
            <Grid item xs={12} md={6} xl={4}>
                <Typography sx={{
                    fontSize: 24,
                    fontWeight: 'medium',
                    mb: 1
                }}>
                    #{customer_id}
                </Typography>
            </Grid>
            <Grid item xs={12} md={6} xl={8} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Chip
                    {...status?.muiChip}
                    label={status?.label}
                    sx={{ textTransform: 'uppercase' }}
                />
            </Grid>
        </Grid>
    )
}

export default CustomerStatus