import { Box, IconButton, Slide, Stack } from "@mui/material";
import React, { Fragment, useState } from "react";
import ServiceCreateAddon from "./CreateAddon";
import DecreaseConfig from "./DecreaseConfig";
import CreateInvoiceRenew from "./CreateInvoiceRenew";
import RegisterService from "./RegisterService";
import CancelService from "./CancelService";
import ServiceRenew from "./RenewService";
import ServiceCreateNote from "./ServiceCreateNote";
import { KeyboardArrowLeftOutlined } from "@mui/icons-material";

const ServiceButtons = ({ service }) => {
  const [show, setShow] = useState(true);
  return (
    <Stack
      display="flex"
      direction={{ xs: "column", md: "row" }}
      gap={{ xs: 1.5, md: 1.5 }}
      alignItems="center"
      justifyContent="space-between"
      minHeight={42}
    >
      <IconButton size="small" onClick={() => setShow(!show)}>
        <KeyboardArrowLeftOutlined
          fontSize="small"
          sx={{
            transform: show ? "rotate(-180deg)" : "unset",
            transition: "transform 0.5s flash",
          }}
        />
      </IconButton>
      <Slide in={show} mountOnEnter unmountOnExit direction="left">
        <Box
          sx={{
            display: "flex",
            gap: 1.5,
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <ServiceCreateNote service={service} />
          {service?.service_creatable_type !== "DeviceLiquidation" && (
            <Fragment>
              <RegisterService service={service} />
              {service?.service_creatable_type === "DomainService" && (
                <ServiceRenew service={service} />
              )}
              <CancelService service={service} />
              <CreateInvoiceRenew service={service} />
              {service?.service_creatable_type !== "ConversantService" && (
                <DecreaseConfig service={service} />
              )}
              {service?.service_creatable_type !== "ConversantService" && (
                <ServiceCreateAddon service={service} />
              )}
            </Fragment>
          )}
        </Box>
      </Slide>
    </Stack>
  );
};

export default ServiceButtons;
