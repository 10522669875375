import ServiceApi from "@/apis/beta/Service/ServiceApi";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Typography } from "@mui/material";
import React from "react";
import { VNISComponents } from "./Components";

const ConversantService = ({ serviceId }) => {
  const { data } = ServiceApi.Usages({ serviceId });
  return (
    <Box>
      {!data && (
        <Box sx={{ minHeight: 150, display: "flex", justifyContent: "center" }}>
          <Typography sx={{ fontSize: 14 }} color="text.grey">
            Dịch vụ hiện tại không khả dụng, vui lòng thử lại sau ít phút hoặc
            liên hệ với Dev Team.
          </Typography>
        </Box>
      )}
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {Object.keys(data?.usages)?.map((item, index) => {
          const Component = VNISComponents[item];
          if (!Component) return null;
          return React.createElement(Component, {
            data: data?.usages[item],
            key: index,
          });
        })}
      </Box>
    </Box>
  );
};

export default withSuspense(ConversantService, MuiSkeleton["DataGrid"]);
