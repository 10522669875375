import { sleep } from '@/functions';
import { CircularProgress, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const CustomerEmail = () => {
    const { control, formState: { errors } } = useFormContext();
    const [customerEmailPending, setCustomerEmailPending] = React.useState(false);

    return (
        <React.Fragment>
            <Typography sx={{ mb: 0.5, color: "#777680", fontSize: 14 }}>
                Email *
            </Typography>
            <Controller
                name="customer.customer_email"
                control={control}
                rules={{ required: true }}
                render={({ field }) =>
                    <TextField
                        value={field.value}
                        onChange={async (event) => {
                            field.onChange(event.target.value)
                            setCustomerEmailPending(true)
                            await sleep(500);
                            setCustomerEmailPending(false)
                        }}
                        error={!!errors?.customer?.customer_email}
                        helperText={errors?.customer?.customer_email?.message}
                        size="small"
                        InputProps={{
                            endAdornment: customerEmailPending ?
                                <CircularProgress size={14} /> : undefined,
                            placeholder: 'Nhập email khách hàng...',
                        }}
                        fullWidth
                    />
                }
            />
        </React.Fragment>
    )
}

export default CustomerEmail