import DatePicker from "@/components/DatePicker";
import ErrorHelperText from "@/components/shared/ErrorHelperText";
import NumericFormatCustom from "@components/NumericFormatCustom";
import {
  Box,
  FormControl,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const Saleplan = ({ saleplanId, disabled }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box sx={{ mt: 2 }} id={"Business.Contact.Saleplan"}>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        <Grid item xs={12} md={12}>
          <Typography sx={{ mb: 0.5, fontSize: 14 }}>Nhà Cung Cấp</Typography>
          <Controller
            name="meeting.meeting_provider_explaination"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <TextField
                  disabled={disabled}
                  value={value}
                  onChange={onChange}
                  placeholder="Nhập tên nhà cung cấp mà khách hàng đang sử dụng..."
                  InputProps={{
                    sx: {
                      bgcolor: "#FFF",
                    },
                  }}
                  size="small"
                />
                {!!errors?.meeting?.meeting_provider_explaination && (
                  <ErrorHelperText
                    message={
                      errors?.meeting?.meeting_provider_explaination?.message
                    }
                  />
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ mb: 0.5, fontSize: 14 }}>
            Ngân Sách Khách Hàng Là Bao Nhiêu? (Chi phí/ tháng)
          </Typography>
          <Controller
            name="meeting.meeting_budget_explaination"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <TextField
                  size="small"
                  value={value}
                  onChange={onChange}
                  disabled={disabled}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  sx={{ bgcolor: "#FFF" }}
                  error={!!errors?.meeting?.meeting_budget_explaination}
                  helperText={
                    errors?.meeting?.meeting_budget_explaination?.message
                  }
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ mb: 0.5, fontSize: 14 }}>
            Thời Gian Dự Kiến Bán Được
          </Typography>
          <Controller
            name="saleplan.saleplan_expected_date"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <DatePicker
                  disabled={disabled}
                  value={value}
                  onChange={onChange}
                />
                {!!errors?.saleplan?.saleplan_expected_date && (
                  <ErrorHelperText
                    message={errors?.saleplan?.saleplan_expected_date?.message}
                  />
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography sx={{ mb: 0.5, fontSize: 14 }}>
            Người Có Khả Năng Đưa Ra Quyết Định
          </Typography>
          <Controller
            name="meeting.meeting_influencer_explaination"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <TextField
                  value={value}
                  disabled={disabled}
                  onChange={onChange}
                  placeholder="Nhập tên người những có khả năng đưa ra quyết định..."
                  InputProps={{
                    sx: {
                      bgcolor: "#FFF",
                    },
                  }}
                  size="small"
                />
                {!!errors?.meeting?.meeting_influencer_explaination && (
                  <ErrorHelperText
                    message={
                      errors?.meeting?.meeting_influencer_explaination?.message
                    }
                  />
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography sx={{ mb: 0.5, fontSize: 14 }}>
            Vấn Đề Khách Hàng Đang Gặp Phải
          </Typography>
          <Controller
            name="meeting.meeting_problem_explaination"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <TextField
                  multiline
                  value={value}
                  disabled={disabled}
                  onChange={onChange}
                  size="small"
                  placeholder="Nhập vấn đề khách hàng đang gặp phải..."
                />
                {!!errors?.meeting?.meeting_problem_explaination && (
                  <ErrorHelperText
                    message={
                      errors?.meeting?.meeting_problem_explaination?.message
                    }
                  />
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography sx={{ mb: 0.5, fontSize: 14 }}>Ghi Chú</Typography>
          <Controller
            name="saleplan.saleplan_note"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <TextField
                  value={value}
                  disabled={disabled}
                  onChange={onChange}
                  placeholder="Nhập ghi chú..."
                  InputProps={{
                    sx: {
                      bgcolor: "#FFF",
                    },
                  }}
                  size="small"
                />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Saleplan;
