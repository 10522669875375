import List from "./List";
import Create from "./Create";
import Update from "./Update";
import Detail from "./Detail";
import Show from "./Show";
import Precheck from "./Precheck";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  List,
  Create,
  Update,
  Detail,
  Show,
  Precheck,
};
