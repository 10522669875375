import InvoiceApi from '@/apis/beta/Invoice/InvoiceApi';
import { useNotificationContext } from '@/contexts/notification';
import { sleep } from '@/functions';
import withPermission from '@/hocs/withPermission';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useQueryClient } from 'react-query';

const MergeInvoice = ({
    data,
    handleClose
}) => {

    const queryClient = useQueryClient()
    const { setNotification } = useNotificationContext()

    const [submit, setSubmit] = React.useState(false);
    const handleMergeInvoice = async () => {
        setSubmit(true)
        const response = await InvoiceApi.Merge({ invoiceIds: [...data] })
        await sleep(500)
        setSubmit(false)
        if (!response || response?.errors) {
            return setNotification({ open: true, message: "Gộp hoá đơn thất bại.", severity: "error" })
        }
        setNotification({ open: true, message: "Gộp hoá đơn thành công.", severity: "success" })
        handleCallback()
        return handleClose();
    }

    const handleCallback = () => {
        const refetchQueries = [
            "invoice.transactions.list",
            "invoices.list",
            "invoices.statistic",
            "invoice.detail",
            "invoice.informations",
            "invoice.mailers.list",
            "invoice.loggers",
            "invoice.services.list",
            "invoice.invoice_items",
            "invoice.quickview",
            "invoice.preview-mailer",
            "customer.invoices", // list invoice
            "customers-invoices.statistics", // report customer invoice
            "customer.serivce-credits", // list credits
            "customer.service-credit" //report of credits
        ]
        refetchQueries?.forEach((key) => {
            try {
                queryClient.refetchQueries([`${key}`])
            } catch (error) {
                // pass
            }
        })
    }

    return (
        <Dialog open={true} fullWidth>
            <DialogTitle sx={{ fontSize: 24 }}>
                Gộp Hoá Đơn
            </DialogTitle>
            <DialogContent>
                <Typography component="div">
                    Các Invoices
                    <Box sx={{ fontWeight: "medium", display: 'inline', m: 'auto 4px' }}>
                        [{data?.join(", ")}]
                    </Box>
                    sẽ được gộp chung vào invoice đầu tiên. Thao tác sẽ không thể được khôi phục, bạn có muốn thực hiện?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined'
                    disabled={submit}
                    onClick={handleClose}
                    sx={{ height: 42 }}>Huỷ Thao Tác</Button>
                <Button variant='contained'
                    disabled={submit}
                    onClick={handleMergeInvoice}
                    startIcon={submit
                        ? <CircularProgress size={16} sx={{ color: "#757575" }} />
                        : undefined}
                    sx={{ height: 42 }}>Xác Nhận</Button>
            </DialogActions>

        </Dialog>
    )
}

export default withPermission(MergeInvoice, {
    feature: "invoice",
    action: "merge",
    type: "Modal"
})