import { FormControl, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const CustomerWebsite = () => {
    const { control } = useFormContext();

    return (
        <React.Fragment>
            <Typography sx={{ mb: 0.5, color: "#777680", fontSize: 14 }}>
                Website
            </Typography>
            <Controller
                control={control}
                name="customer.customer_website"
                render={({ field: { value, onChange } }) => {
                    return (
                        <FormControl fullWidth>
                            <TextField
                                value={value}
                                onChange={onChange}
                                placeholder="Nhập tên website công ty..."
                                size='small'
                                fullWidth
                            />
                        </FormControl>
                    )
                }} />
        </React.Fragment>
    )
}

export default CustomerWebsite