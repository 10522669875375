import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import { format_numeral_price } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Paper, Typography } from "@mui/material";
import ButtonChangeCredit from "../ButtonChangeCredit";
import withPermission from "@/hocs/withPermission";
const CreditCommitment = ({ customerId }) => {
  const { data } = CustomerApi.CreditCommitment({
    customerId,
  });

  return (
    <Paper sx={{ p: 2 }}>
      <Typography sx={{ color: "#777680", mb: 1 }}>Mốc Ký Quỹ</Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          sx={{
            color: "#00000F",
            fontWeight: "bold",
            fontSize: { xs: 20, md: 28 },
          }}
        >
          {format_numeral_price(data?.customer_credit_commitment)}
          <span style={{ textDecoration: "underline" }}>đ</span>
        </Typography>
        <Box sx={{ marginLeft: "auto" }}>
          <ButtonChangeCredit
            credit={data?.customer_credit_commitment}
            customerId={customerId}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default withSuspense(
  withPermission(CreditCommitment, {
    feature: "customer",
    action: "credit-commitment",
    type: "Block",
  }),
  MuiSkeleton["Paper"]
);
