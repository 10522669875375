import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import MuiScrollTabs from "@/components/shared/MuiScrollTabs";
import { isObjEmpty } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useLayoutContext } from "@contexts/layout";
import CustomerContractIcon from "@mui/icons-material/DocumentScannerOutlined";
import ServiceIcon from "@mui/icons-material/DynamicFormOutlined";
import OutBoxIcon from "@mui/icons-material/OutboxOutlined";
import CustomerJourneyIcon from "@mui/icons-material/PendingActionsOutlined";
import CustomerInformationIcon from "@mui/icons-material/PermContactCalendarOutlined";
import CustomerFinanceIcon from "@mui/icons-material/RequestQuoteOutlined";
import React from "react";
import { useParams } from "react-router-dom";

const CustomerTabs = () => {
  const { permissions } = useLayoutContext();

  const { customer_id } = useParams();
  const { data: customer } = CustomerApi.Detail(customer_id);

  const findCustomerRoles = React.useCallback(() => {
    return (
      permissions?.find(({ feature }) => feature === "customer")?.actions || []
    );
  }, [permissions]);

  const [roles, setRoles] = React.useState(findCustomerRoles());
  React.useEffect(() => {
    setRoles(findCustomerRoles());
  }, [findCustomerRoles]);

  const isHiddenTab = (name = "") => {
    const available = roles?.find(({ action }) => action === name) || {};
    return isObjEmpty(available);
  };

  const initialTabs = [
    {
      value: "customer-information",
      label: "Thông Tin Cơ Bản",
      href: "",
      icon: CustomerInformationIcon,
      hidden: !customer || isHiddenTab("show"),
    },
    {
      value: "customer-journey",
      label: "Quá Trình Bán Hàng",
      href: "customer-journey",
      icon: CustomerJourneyIcon,
      hidden: !customer || isHiddenTab("customer-journey-index"),
    },
    {
      value: "customer-contract",
      label: "Hợp đồng",
      href: "contracts",
      icon: CustomerContractIcon,
      hidden:
        !customer ||
        isHiddenTab("customer-contract-index") ||
        ['qualified-prospects', 'cool-lead'].includes(customer?.customer_service_information.customer_status),
    },
    {
      value: "finance",
      label: "Hoá Đơn & Ký Quỹ",
      href: "finance",
      icon: CustomerFinanceIcon,
      hidden:
        isHiddenTab("customer-invoice-index") ||
        ['qualified-prospects', 'cool-lead'].includes(customer?.customer_service_information.customer_status),
    },
    {
      value: "service",
      label: "Dịch Vụ",
      href: "services",
      icon: ServiceIcon,
      hidden:
        isHiddenTab("customer-service-index") ||
        ['qualified-prospects', 'cool-lead'].includes(customer?.customer_service_information.customer_status),
    },
    {
      value: "group_mailers",
      label: "Gửi Hoá Đơn Dịch Vụ",
      href: "mailers",
      icon: OutBoxIcon,
      hidden:
        isHiddenTab("customer-group-service-mailer-index") ||
        customer?.customer_service_information.customer_status !== "active",
    }, {
      value: "customer_mailers",
      label: "Thông Tin Nhận Email",
      href: "customer_mailer",
      icon: OutBoxIcon,
    },
  ];

  return (
    <MuiScrollTabs
      prefix={`customers/${customer_id}`}
      initialTabs={initialTabs?.filter((tab) => !tab?.hidden)}
    />
  );
};

export default withSuspense(CustomerTabs, MuiSkeleton["ScrollTabs"]);
