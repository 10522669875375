import BusinessApi from "@/apis/beta/Employee/BusinessApi";
import withPermission from "@/hocs/withPermission";
import { useNotificationContext } from "@contexts/notification";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const Destroy = ({ data, callback, handleClose }) => {
  const [submit, setSubmit] = React.useState(false);
  const [state, setState] = React.useState("");
  const { setNotification } = useNotificationContext();

  const onCancelBusiness = async () => {
    if (!state || state.trim() === "") {
      return setNotification({
        open: true,
        message: "Vui lòng nhập lý do huỷ phiếu công tác.",
        severity: "error",
      });
    }
    setSubmit(true);

    const response = await BusinessApi.Cancel(data?.business_id, state);

    setSubmit(false);
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }
    try {
      callback();
    } catch (error) {
      console.log(error?.message);
    }
    handleClose();
    return setNotification({
      open: true,
      message: "Huỷ phiếu công tác thành công.",
      severity: "success",
    });
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={true}>
      <DialogTitle>Huỷ Phiếu Công Tác</DialogTitle>
      <DialogContent>
        <Typography component="div">
          Phiếu công tác
          <Typography
            sx={{
              fontWeight: "medium",
              display: "inline-flex",
              m: "auto 4px",
            }}
          >
            [IBN - {data?.business_id}]
          </Typography>{" "}
          sẽ được huỷ. Thao tác sẽ không thể được khôi phục, bạn có muốn thực
          hiện?
        </Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <TextField
            multiline
            placeholder="Nhập lý do huỷ phiếu..."
            value={state}
            onChange={(event) => setState(event?.target?.value)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="outlined"
          sx={{ height: { xs: 36, md: 42 } }}
          disabled={submit}
          onClick={handleClose}
        >
          Huỷ thao tác
        </Button>
        <Button
          size="small"
          variant="contained"
          sx={{ height: { xs: 36, md: 42 }, minWidth: 150 }}
          disabled={submit}
          startIcon={
            submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
          }
          onClick={onCancelBusiness}
        >
          Xác nhận
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withPermission(Destroy, {
  feature: "business",
  action: "cancel",
  type: "button",
});
