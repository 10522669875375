import PopperAction from '@components/PopperAction'
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined'
import { Box, IconButton, Popover, Typography } from '@mui/material'
import { indigo } from '@mui/material/colors'
import React from 'react'

const ProductConfigoptionDetail = ({
    remover,
    updater,
    data = {}
}) => {

    const [anchorEl, setAnchorEl] = React.useState(null)

    const [actions] = React.useState([
        {
            order: 1,
            icon: "Edit",
            label: 'Chỉnh sửa',
            handleClick: (values) => {
                updater()
                return clear()
            }
        },
        {
            order: 2,
            icon: "Delete",
            label: 'Xoá',
            handleClick: () => {
                remover()
                return clear()
            }
        }
    ])

    const clear = () => setAnchorEl(null)
    return (
        <Box sx={{
            p: 2,
            border: '1px solid rgba(0, 0, 0, 0.18)',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', md: 'row' },
            ":hover": {
                border: '1px solid #000099'
            }
        }}>
            {!!anchorEl && <Popover open={true} anchorEl={anchorEl}>
                <PopperAction actions={actions} data={data} handleClose={clear} />
            </Popover>}
            <Box sx={{ width: '100%' }}>
                <Typography sx={{
                    fontSize: 14,
                    color: "#777680",
                    mb: 0.5
                }}>
                    Tên cấu hình: {data?.name}
                </Typography>
                <Typography sx={{
                    fontSize: 13,
                    color: "#77768099",
                }}>
                    + {data?.steps?.length} mốc giá
                </Typography>
            </Box>
            <IconButton size='small'
                sx={{ width: 40, height: 40, m: 'auto 0 auto auto' }}
                onClick={(event) => setAnchorEl(event?.currentTarget)}>
                <MoreVertOutlined fontSize='small' sx={{ ":hover": { color: indigo[900] } }} />
            </IconButton>
        </Box>
    )
}

export default ProductConfigoptionDetail