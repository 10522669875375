import MuiScrollTabs from "@/components/shared/MuiScrollTabs";
import { isObjEmpty } from "@/functions";
import { useLayoutContext } from "@contexts/layout";
import { CheckCircleOutlineOutlined, MailOutlined, ReceiptLongOutlined } from "@mui/icons-material";
import AccessTimeOutlined from "@mui/icons-material/AccessTimeOutlined";
import React from "react";

const InvoiceMainTabs = () => {
	const { permissions } = useLayoutContext();

	const serviceCollections = React.useMemo(() => {
		let arrs = [];
		permissions?.forEach((collection) => {
			if (["invoice"].includes(collection.feature)) {
				arrs.push(...collection?.actions);
			}
		});
		return arrs;
	}, [permissions]);

	const isHiddenTab = ({ f = "", a = "" }) => {
		const available =
			serviceCollections?.find(
				({ feature, action }) => feature === f && action === a
			) || {};
		return isObjEmpty(available);
	};
	const initialTabs = [
		{
			value: "default",
			label: "Danh Sách Hoá Đơn",
			href: "",
			icon: ReceiptLongOutlined,
			hidden: isHiddenTab({ f: "invoice", a: "index" }),
			title: "Danh sách hoá đơn trên hệ thống"
		}, {
			value: "confirmations",
			label: "Hoá Đơn Đã Được Xác Nhận",
			href: "confirmations",
			icon: CheckCircleOutlineOutlined,
			hidden: isHiddenTab({ f: "invoice", a: "confirmations" }),
			title: "Danh sách hoá đơn đã được bộ phận CSKH xác nhận"
		}, {
			value: "statement",
			label: "Hoá Đơn Đối Soát Dịch Vụ",
			href: "statement",
			icon: AccessTimeOutlined,
			hidden: isHiddenTab({ f: "invoice", a: "index" }),
			title: "Danh sách hoá đơn chuẩn bị được phát sinh trên hệ thống"
		},
		{
			value: "mailers",
			label: "Email Hoá Đơn",
			href: "mailers",
			icon: MailOutlined,
			hidden: isHiddenTab({ f: "invoice", a: "mailers" }),
			title: "Danh sách hoá đơn đã gửi mail & thông tin chi tiết"
		},
	];
	return (
		<MuiScrollTabs
			prefix={`invoices`}
			initialTabs={initialTabs?.filter((tab) => !tab?.hidden)}
		/>
	);
};

export default InvoiceMainTabs;
