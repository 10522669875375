import { requestWithToken, encodeBase64 } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class CompanyApi {
  List = ({ query = null, page = 1, pageSize = 20 }) => {
    return useQueryWithCustomized(
      ["companies.list", query, page, pageSize],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/companies?page=${page}&page_size=${pageSize}${query ? `&filters=${encodeBase64(query)}` : ``
          }`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
        enabled: !!localStorage.getItem("SA"),
      }
    );
  };

  Create = async (data) => {
    return await requestWithToken(
      `${API_V3_URL}/companies`,
      "POST",
      {
        company: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return {
          ...data,
          status: data?.status,
          company: data?.data?.company,
        };
      })
      .catch((error) => {
        return error.response;
      });
  };

  Update = async (data, companyId) => {
    return await requestWithToken(
      `${API_V3_URL}/companies/${companyId}`,
      "PUT",
      {
        company: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Detail = (companyId) => {
    return useQueryWithCustomized(
      ["company.detail", companyId],
      async () => {
        return await requestWithToken(`${API_V3_URL}/companies/${companyId}`)
          .then(({ data }) => {
            return data?.data?.company;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Updates = ({ companyId = -1 }) => {
    return useQueryWithCustomized(
      ["company.company_updates", companyId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/companies/${companyId}/company_updates`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.company_updates || [];
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CompanyApi();
