import { Box, Chip, Typography } from '@mui/material'
import React from 'react'
import AdminManageIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import _ from 'lodash';
import ModalRole from './ModalRole';

const ManageList = ({ roles = [], name = "" }) => {

    const [show, setShow] = React.useState(false)

    const handleClose = () => setShow(false)

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                <AdminManageIcon fontSize='small' color="primary" />
                <Typography
                    color="primary"
                    sx={{
                        fontWeight: 'medium',
                        m: 'auto 0'
                    }}>
                    Phân Quyền
                </Typography>
            </Box>
            {show && <ModalRole
                name={name}
                roles={roles}
                handleClose={handleClose} />}
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
                mb: 2
            }}>
                {Array.from(_.sortBy(roles, ['permission_name'])?.slice(0, 5)?.map((role, index) => {
                    return (
                        <Chip size='small' key={index}
                            sx={{ m: '0' }}
                            label={
                                <Typography
                                    color='custom.grey'
                                    sx={{
                                        fontSize: 13,
                                        textTransform: 'capitalize'
                                    }} component="div">
                                    {role?.permission_website_name === "datacentral" ? "DC" : role?.permission_website_name}
                                    [{role?.permission_feature_name}]
                                    <Typography sx={{
                                        display: 'inline',
                                        fontSize: 13,
                                        color: '#000',
                                        ml: 0.5
                                    }}>
                                        [{role?.permission_action_name}]
                                    </Typography>
                                </Typography>
                            } />
                    )
                }))}
                {roles && roles?.length > 5 && <Typography
                    onClick={() => setShow(true)}
                    sx={{
                        fontSize: 13,
                        ":hover": {
                            textDecoration: "underline",
                            cursor: "pointer"
                        }
                    }}
                    color="primary">
                    +{roles?.length - 5} quyền...
                </Typography>}
            </Box>
        </React.Fragment>
    )
}

export default ManageList