import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const Loading = () => {
    return (
        <Box sx={{
            width: '100%',
            minHeight: 200,
            justifyContent: 'center'
        }}>
            <CircularProgress size={30}
                sx={{
                    color: "#777680",
                    m: 'auto'
                }} />
        </Box>
    )
}

export default Loading