import { Box, FormControl, RadioGroup, Radio, Typography, FormControlLabel } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const OS = ({ position }) => {

    const { control, watch } = useFormContext()
    return (
        <Box>
            <Typography sx={{
                fontSize: { xs: 16, md: 20 },
                fontWeight: 'medium',
            }}>
                Lựa chọn hệ điều hành
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 0.5, color: "#777680" }}>
                Lựa chọn hệ điều hành cần thiết cho {watch("product_name")} của bạn.
            </Typography>
            <Controller
                name={`product_configoptions.[${position}].select`}
                control={control}
                render={({ field: { value, onChange } }) => {
                    const options = watch(`product_configoptions.[${position}].options`) || []

                    const selected = value?.value
                    return (
                        <FormControl fullWidth>
                            <RadioGroup value={selected}
                                onChange={(event) => onChange(options?.find(item => item.value === event?.target?.value) || null)}
                                sx={{
                                    flexDirection: 'row'
                                }}
                            >
                                {options.map((item, index) => {
                                    return (
                                        <FormControlLabel
                                            sx={{
                                                maxWidth: { xs: '50%', md: 'calc((100% - 12px * 4) / 3)' },
                                                width: { xs: '50%', md: 'calc((100% - 12px * 4) / 3)' },
                                            }}
                                            key={index}
                                            {...item}
                                            label={<Typography component="span" sx={{ fontSize: 14 }}>
                                                {item?.label}
                                                {!!item?.subtitle && <Typography sx={{
                                                    color: "#777680",
                                                    display: 'inline-flex',
                                                    fontSize: 14
                                                }}>
                                                    ( {item?.subtitle})
                                                </Typography>}
                                                {!!item?.description && <Typography sx={{
                                                    fontSize: 14
                                                }}>
                                                    {item?.description}
                                                </Typography>}
                                            </Typography>}
                                            control={<Radio size='small'
                                                sx={{
                                                    "&.Mui-checked": {
                                                        color: "#ef5350"
                                                    }
                                                }}
                                            />}
                                        />
                                    )
                                })}


                            </RadioGroup>
                        </FormControl>
                    )
                }}
            />

        </Box>
    )
}

export default OS