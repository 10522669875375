import { Box, Grid, Paper, Skeleton } from '@mui/material'
import React from 'react'

const GridInformation = () => {
    return (
        <Paper sx={{ p: '30px', mb: 2 }}>
            <Box sx={{ display: 'flex', mb: 2 }}>
                <Skeleton variant="circular" sx={{ height: 40, width: 40, m: 'auto 8px auto 0' }} />
                <Skeleton variant="text" className="page-title" sx={{ width: 100 }} />
            </Box>
            {[1, 2, 3].map(index =>
                <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                    {[1, 2, 3, 4].map(item =>
                        <Grid item xl={3} lg={3} md={3} sm={4} xs={6} key={`${index}.${item}`}>
                            <Skeleton variant="text" sx={{ width: 100 }} />
                            <Skeleton variant="text" sx={{ width: 200 }} />
                        </Grid>
                    )}
                </Grid>
            )}
        </Paper>
    )
}

export default GridInformation