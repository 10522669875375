import BreadCrumb from "@/components/shared/BreadCrumb"
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'

const CustomerHeader = () => {
    const { customer_id } = useParams()
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: {
                xs: 'column',
                md: 'row'
            },
            mb: 2
        }}>
            <Box>
                <Typography sx={{ fontSize: { xs: 18, md: 24 }, fontWeight: 'medium' }}>
                    Thông Tin Chi Tiết
                </Typography>
                <BreadCrumb
                    data={[
                        { label: "Khách Hàng", reactLink: "/customers" },
                        { label: customer_id || "---" }
                    ]}
                />
            </Box>
        </Box>
    )
}

export default CustomerHeader