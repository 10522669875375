import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { format_bytes, format_numeral_price } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import NumericFormatCustom from "@components/NumericFormatCustom";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, FormControl, Paper, TextField, Typography } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";
import ButtonCreateInvoice from "../ButtonCreateInvoice";

const OtherService = ({ serviceId, usages, service }) => {
  const { data: price } = ServiceApi.PricingAndConfigoptions({ id: serviceId });

  const usageReal = useMemo(() => {
    if (service?.service_product_id !== 306) return Object.values(usages)[0];
    return format_bytes(Object.values(usages)[0], 1000, "GB");
  }, [usages, service]);

  const linesBuilder = useMemo(() => {
    if (usageReal <= 0 || !price || !price?.service_pricing_configure?.steps)
      return [];
    const steps = price?.service_pricing_configure?.steps;
    let rows = [];

    rows.push({
      title: "Tổng Sử Dụng",
      usage: usageReal,
    });

    const commitment = price?.service_pricing_configure?.commitment?.value || 0;

    if (commitment > 0) {
      rows.push({
        title: "Gói Cố Định",
        usage: commitment,
        pricing_apply:
          price?.service_pricing_configure?.commitment?.pricing_apply,
        pricing_amount:
          price?.service_pricing_configure?.commitment?.pricing_amount,
      });
    }
    if (commitment > usageReal) return rows;
    switch (price?.service_pricing_configure?.method) {
      case "progressive":
        let ranges = steps?.filter(
          (range) =>
            (range?.start < usageReal && range.end > commitment) ||
            (usageReal <= range?.end && usageReal > range?.start)
        );
        _.forEach(ranges, (range) => {
          let start = range.start;
          if (commitment > start) {
            start = commitment;
          }
          let used = usageReal - start;
          if (usageReal >= range?.end) {
            used = range.end - start;
          }
          let pricing_amount = used * range?.pricing_apply;
          rows.push({
            title: `[${format_numeral_price(
              range.start
            )} - ${format_numeral_price(range.end)}]`,
            usage: used,
            pricing_apply: range?.pricing_apply,
            pricing_amount,
          });
        });

        break;
      case "tierd":
        let range = steps?.find(
          (step) => step?.start <= usageReal && usageReal < step?.end
        );
        if (!range) {
          range = steps[steps.length - 1];
        }
        let pricing_amount = range?.pricing_apply;

        pricing_amount *= usageReal - commitment;
        rows.push({
          title: "Tổng sử dụng",
          usage: usageReal,
          pricing_apply: range?.pricing_apply,
          pricing_amount,
        });

        break;
      default:
        return [];
    }

    return rows;
  }, [price, usageReal]);

  return (
    <Paper sx={{ p: 3 }}>
      {linesBuilder?.map((line, index) => {
        return (
          <Box sx={{ mt: 1, display: "flex", gap: 1.5 }} key={index}>
            <FormControl fullWidth>
              <TextField disabled size="small" value={line?.title} />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                disabled
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                size="small"
                value={line?.usage}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                disabled
                size="small"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                value={line?.pricing_apply}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                disabled
                size="small"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                value={line?.pricing_amount}
              />
            </FormControl>
          </Box>
        );
      })}
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}>
        <Typography sx={{ fontSize: 18, fontWeight: "medium" }}>
          Tạm Tính:
        </Typography>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", minWidth: 300 }}
        >
          <Typography sx={{ fontSize: 18 }}>
            {format_numeral_price(
              _.sumBy(linesBuilder, (line) => line?.pricing_amount)
            )}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}>
        <Typography sx={{ fontSize: 18, fontWeight: "medium" }}>
          VAT:
        </Typography>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", minWidth: 300 }}
        >
          <Typography sx={{ fontSize: 18 }}>
            {format_numeral_price(
              _.sumBy(linesBuilder, (line) => line?.pricing_amount) * 0.1
            )}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}>
        <Typography sx={{ fontSize: 18, fontWeight: "medium" }}>
          Thành Tiền:
        </Typography>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", minWidth: 300 }}
        >
          <Typography sx={{ fontSize: 18 }}>
            {format_numeral_price(
              _.sumBy(linesBuilder, (line) => line?.pricing_amount) * 0.1 +
                _.sumBy(linesBuilder, (line) => line?.pricing_amount)
            )}
          </Typography>
        </Box>
      </Box>
      <ButtonCreateInvoice serviceId={serviceId} />
    </Paper>
  );
};

export default withSuspense(OtherService, MuiSkeleton["LinearProgress"]);
