import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import { buildQueryFilterCondition } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import Contact from "@components/shared/FormCustomized/Contact";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { Box, Collapse, FormControl, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

const ContractCustomer = ({ disabled }) => {

	const [customer, setCustomer] = useState(undefined)
	const {
		control,
		setValue,
		formState: { errors },
		watch,
	} = useFormContext();

	const [searchParams] = useSearchParams();

	const [state, setState] = React.useState("");

	const debouncedSearchQuery = useDebounce(state, 400);

	const filterableCustomer = React.useCallback(() => {
		let fieldQueries = [];
		if (parseInt(watch(`contract_customer_id`)) > 0) {
			fieldQueries.push({
				name: "customers.customer_id",
				operator: "$eq",
				value: parseInt(watch(`contract_customer_id`)),
			});
		}

		if (!!debouncedSearchQuery && !(debouncedSearchQuery?.trim() === "")) {
			fieldQueries.push(
				...[
					{
						name: "customers.customer_id",
						operator: "$eq",
						disabled: !parseInt(debouncedSearchQuery),
						value: parseInt(debouncedSearchQuery),
					},
					{
						name: "customers.customer_name",
						operator: "$contains",
						value: debouncedSearchQuery,
					},
					{
						name: "company_name",
						operator: "$contains",
						value: debouncedSearchQuery,
					},
					{
						name: "customers.customer_email",
						operator: "$contains",
						value: debouncedSearchQuery,
					},
				]
			);
		}
		return buildQueryFilterCondition("$or", 0, fieldQueries);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchQuery, watch(`contract_customer_id`)]);

	const {
		isFetching,
		data: { customers },
	} = CustomerApi.List({
		page: 1,
		pageSize: 15,
		query: filterableCustomer(),
	});

	React.useEffect(() => {
		if (!!searchParams.get("customer_id")) {
			setValue(`contract_customer_id`, searchParams.get("customer_id"));
		}
		if (!!searchParams.get("contact_id")) {
			setValue('customer_contact_id', parseInt(searchParams.get("contact_id")));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams.get("customer_id"), searchParams.get("contact_id")]);

	useEffect(() => {
		if (!parseInt(watch(`contract_customer_id`))) return;
		setCustomer(customers?.find(item => item?.customer_id === parseInt(watch(`contract_customer_id`))))

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customers, watch(`contract_customer_id`)])

	const rebuildCustomerSelections = React.useMemo(() => {
		if (!customers || customers?.length === 0) return [];

		return customers?.map((customer, index) => {
			let label = customer?.customer_id;
			if (customer?.customer_type !== "individual") {
				if (!!customer?.customer_company?.company_name) {
					label += ` - ${customer?.customer_company?.company_name}`;
				}
				label += ` - (${customer?.customer_name})`;
			} else {
				label += ` - ${customer?.customer_name} (${customer?.customer_email})`;
			}

			return {
				value: customer?.customer_id,
				label,
			};
		});
	}, [customers]);


	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: { xs: "column", md: "row" },
				gap: { xs: 0.5 },
			}}
		>
			<Box sx={{ minWidth: { xs: "100%", md: 300 } }}>
				<Typography sx={{ fontSize: 14, color: "#777680" }}>
					Khách Hàng *
				</Typography>
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }} >
				<Controller
					name="contract_customer_id"
					control={control}
					render={({ field: { value, onChange } }) => {
						let options = rebuildCustomerSelections;
						const selected =
							options?.find((v) => parseInt(v?.value) === parseInt(value)) ||
							null;
						return (
							<FormControl fullWidth>
								<SingleSelectCustomize
									loading={isFetching}
									value={selected}
									disabled={disabled}
									options={options}
									onChange={(event, newValue) => {
										onChange(newValue?.value || -1)
										setCustomer(customers?.find(customer => customer?.customer_id === newValue?.value) || undefined)
									}}
									input={{
										placeholder: "Nhập tên & Id khách hàng tìm kiếm...",
										value: state,
										onChange: (e) => setState(e?.target?.value || ""),
									}}
									mode="server"
								/>
								{!!errors?.contract_customer_id && (
									<ErrorHelperText
										message={errors?.contract_customer_id?.message}
									/>
								)}
							</FormControl>
						);
					}}
				/>
				<Collapse in={!!customer && (customer?.customer_service_information?.customer_status !== 'qualified-prospects')}>
					<Controller
						name='customer_contact_id'
						control={control}
						render={({ field: { value, onChange } }) => (
							<FormControl fullWidth>
								<Contact
									customerId={watch(`contract_customer_id`)}
									value={value}
									disabled={Boolean(parseInt(searchParams?.get('contact_id')))}
									onChange={onChange}
									input={{
										label: 'Người Quản Lý/ Tiếp Nhận *',
										placeholder: 'Chọn thông tin liên hệ tạo hợp đồng...'
									}}

								/>
							</FormControl>
						)} />
				</Collapse>
			</Box>
		</Box>
	);
};

export default withSuspense(
	ContractCustomer,
	MuiSkeleton["FormControlTextField"]
);
