import WorkcontractApi from '@/apis/beta/Employee/WorkcontractApi'
import withPermission from '@/hocs/withPermission'
import moment from 'moment'
import React from 'react'
import { useParams } from 'react-router-dom'
import WorkContractForm from '../shared/WorkContractForm'
import Header from './Header'

const Create = () => {
    const { data } = WorkcontractApi.GWorkcontractNumber({})
    const { employee_id } = useParams()

    const defaultValues = {
        work_contract_number: data,
        employee_id: employee_id,
        work_contract_term: '12 tháng',
        work_contract_startdate: moment(),
        work_contract_enddate: moment(),
        work_contract_status: 1,
        work_contract_salary_basic: 0,
        work_contract_salary_allowance: 0
    }
    return (
        <React.Fragment>
            <Header />
            <WorkContractForm type="create" defaultValues={defaultValues} />


        </React.Fragment>
    )
}

export default withPermission(Create, { feature: "work-contract", action: 'create' })