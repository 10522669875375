import { Box, FormControl, RadioGroup, Radio, Typography, FormControlLabel } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const Software = ({ position }) => {

    const { control, watch } = useFormContext()

    return (
        <Box>
            <Typography sx={{
                fontSize: { xs: 16, md: 20 },
                fontWeight: 'medium',
            }}>
                SSL
            </Typography>
            <Controller
                name={`product_configoptions.[${position}].select`}
                control={control}
                render={({ field: { value, onChange } }) => {
                    let options = watch(`product_configoptions.[${position}].product_addons`) || []
                    const selected = value?.product_addon_id || -1

                    return (
                        <FormControl fullWidth>
                            <RadioGroup value={selected}
                                onChange={(event) => onChange(options?.find(item => item.product_addon_id === parseInt(event?.target?.value)) || undefined)}
                            >
                                {options.map((item, index) => {
                                    return (
                                        <FormControlLabel
                                            key={index}
                                            value={item?.product_addon_id}
                                            label={item?.product_addon_name}
                                            control={<Radio size='small'
                                                sx={{
                                                    "&.Mui-checked": {
                                                        color: "#ef5350"
                                                    }
                                                }}
                                            />} />
                                    )
                                })}


                            </RadioGroup>
                        </FormControl>
                    )
                }}
            />

        </Box>
    )
}

export default Software