import { Box } from "@mui/material";

const IframePreview = ({ url }) => {
  return (
    <Box height="100%" sx={{ backgroundColor: "#fff" }}>
      <iframe
        width="100%"
        height="100%"
        style={{ border: "none" }}
        src={url}
        allowFullScreen
        title={url}
      />
    </Box>
  );
};

export default IframePreview;
