import EmployeeApi from '@/apis/beta/Employee/EmployeeApi';
import withSuspense from '@/hocs/withSuspense';
import MuiSkeleton from '@components/shared/MuiSkeleton';
import GroupPeopleIcon from '@mui/icons-material/GroupsOutlined';
import KeyboardArrowDownOutlined from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Box, Chip, IconButton, Typography } from '@mui/material';
import React from 'react';

const ManageEmployee = ({ assigneeIds = [] }) => {
    const [active, setActive] = React.useState(false)
    const { data: employees } = EmployeeApi.ListEmployees({ page: 1, pageSize: 999 });

    return (
        <React.Fragment>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 1
            }}>
                <Box sx={{
                    display: 'flex',
                    gap: 1
                }}>
                    <GroupPeopleIcon fontSize='small' color="primary" sx={{ m: 'auto 0' }} />
                    <Typography
                        color="primary"
                        sx={{
                            fontWeight: 'medium',
                            m: 'auto 0'
                        }}>
                        Nhân Viên
                    </Typography>
                </Box>
                <IconButton size='small' onClick={() => setActive(!active)}>
                    <KeyboardArrowDownOutlined fontSize='small' sx={{
                        m: 'auto 0',
                        transition: 'all 0.5s ease-in-out',
                        transform: active && 'rotate(-180deg)'
                    }} />
                </IconButton>
            </Box>
            {(assigneeIds && assigneeIds?.length === 0) ? <Typography
                color="custom.grey"
                sx={{ fontSize: 14 }}
            >
                Chưa phân bổ cho nhân viên
            </Typography> :
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1
                }}>
                    {assigneeIds?.slice(0, active ? 100 : 5)?.map((id, index) => {
                        const employee = employees?.find(e => e?.employee_id === id)
                        return (
                            <Chip
                                label={employee?.employee_name}
                                key={index}
                                sx={{ height: 32, mr: 1, mb: 1, borderRadius: '7px' }}
                            />
                        )
                    })}
                    {(!active && assigneeIds.length > 5) && <Chip
                        label={`+ ${assigneeIds?.length - 5} mores...`}
                        sx={{ height: 32, mr: 1, mb: 1, borderRadius: '7px' }}
                    />}
                </Box>}
        </React.Fragment>
    )
}

export default withSuspense(ManageEmployee, MuiSkeleton["Loading"])