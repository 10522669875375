import { UNLIMITED } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import { AddOutlined, DeleteOutlineOutlined } from '@mui/icons-material'
import { Box, Button, FormControl, IconButton, TextField, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import React from 'react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'

const PricingSteps = () => {
    const { control } = useFormContext()
    const { fields, append, remove, update } = useFieldArray({
        control: control,
        name: "product_pricing_configures.steps"
    })

    const productPricingConfigures = useWatch({
        control: control,
        name: "product_pricing_configures.steps"
    })

    const handlerAddStep = () => {
        let newStep = { start: 0, end: 0, pricing: 0 }
        if (productPricingConfigures?.length === 0) {
            newStep.end = 5000
        } else {
            const lastIdx = productPricingConfigures?.length - 1
            newStep.start = productPricingConfigures[lastIdx]?.end || 0
            switch (parseInt(newStep.start)) {
                case 0: newStep.end = 5000
                    break;
                case 5000: newStep.end = 10000
                    break;
                case 10000: newStep.end = 50000
                    break;
                case 50000: newStep.end = 100000
                    break;
                case 100000: newStep.end = 200000
                    break;
                case 200000: newStep.end = 300000
                    break;
                case 300000: newStep.end = 400000
                    break;
                case 400000: newStep.end = 500000
                    break;
                case 500000: newStep.end = 800000
                    break;
                case 800000: newStep.end = 1000000
                    break;
                case 1000000: newStep.end = 999999999
                    break;
                case 2: newStep.end = 5
                    break;
                case 10: newStep.end = 25
                    break;
                case 25: newStep.end = 50
                    break;
                case 50: newStep.end = 100
                    break;
                case 100: newStep.end = 999999999
                    break;
                case 80: newStep.end = 200
                    break;
                case 200: newStep.end = 999999999
                    break;
                default:
                    break;
            }
        }
        return append(newStep)
    }
    return (
        <React.Fragment>
            {fields && fields?.map((field, index) => {
                return (
                    <Box key={field.id} sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: 1.5,
                        mt: 2
                    }}>
                        <Box sx={{
                            display: 'flex',
                            gap: 1.5,
                            flexDirection: {
                                xs: 'column', md: 'row'
                            },
                            width: {
                                xs: '100%', md: "calc((100% - 12px) / 2)"
                            }
                        }}>
                            <Controller
                                name={`product_pricing_configures.steps.[${index}].start`}
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <FormControl fullWidth>
                                            <TextField size='small'
                                                value={value}
                                                onChange={onChange}
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom
                                                }}
                                                label="Từ *"
                                                InputLabelProps={{ shrink: true }} />
                                        </FormControl>
                                    )
                                }} />
                            <Controller
                                name={`product_pricing_configures.steps.[${index}].end`}
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    const isUnlimited = parseFloat(value) === UNLIMITED
                                    return (
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Đến *"
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    sx: {
                                                        paddingRight: 0,
                                                    },
                                                    inputComponent: isUnlimited ? "input" : NumericFormatCustom,
                                                    endAdornment: (index === fields.length - 1) ?
                                                        <Box sx={{
                                                            display: 'flex',
                                                            m: 'auto 0 auto auto',
                                                            width: '100%',
                                                            maxWidth: { xs: 'calc(100% / 3)', md: 'calc(100% / 2)' },
                                                            height: '100%',
                                                            bgcolor: "rgba(0, 0, 0, 0.18)",
                                                            borderTopRightRadius: '4px',
                                                            borderBottomRightRadius: '4px',
                                                            cursor: "pointer"
                                                        }}
                                                            onClick={() => {
                                                                if (parseFloat(value) >= UNLIMITED) {
                                                                    return;
                                                                }
                                                                return update(index, { ...field, end: UNLIMITED })
                                                            }}
                                                        >
                                                            <Typography sx={{
                                                                m: 'auto 8px auto auto',
                                                                fontSize: 13,
                                                                textTransform: 'uppercase',
                                                                color: value === UNLIMITED ? green[800] : "#777680"
                                                            }}>
                                                                Unlimited
                                                            </Typography>
                                                        </Box>
                                                        : null
                                                }}
                                                value={isUnlimited ? "" : value}
                                                onChange={onChange}
                                                placeholder='0'
                                                fullWidth
                                                size='small' />
                                        </FormControl>
                                    )
                                }} />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gap: 1.5,
                            flexDirection: {
                                xs: 'column', sm: 'row'
                            },
                            width: {
                                xs: '100%', sm: "calc((100% - 12px) / 2)"
                            }
                        }}>
                            <Controller
                                name={`product_pricing_configures.steps.[${index}].pricing`}
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Giá niêm yết *"
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom
                                                }}
                                                value={value}
                                                onChange={onChange}
                                                placeholder='0'
                                                size='small' />
                                        </FormControl>
                                    )
                                }} />
                            <Controller
                                name={`product_pricing_configures.steps.[${index}].pricing_minimum`}
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Giá min *"
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom
                                                }}
                                                value={value}
                                                onChange={onChange}
                                                placeholder='0'
                                                size='small' />
                                        </FormControl>
                                    )
                                }} />
                            <IconButton size='small' sx={{
                                visibility: (index === 0 || index !== fields.length - 1) ? "hidden" : "visible",
                                height: 40,
                                width: 40,
                                m: 'auto 0',
                                ":hover": {
                                    color: "#ef5350"
                                }
                            }}
                                onClick={() => remove(index)}
                            >
                                <DeleteOutlineOutlined fontSize='small' />
                            </IconButton>
                        </Box>
                    </Box>
                )
            })}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1.5 }}>
                <Button variant='outlined' size="small"
                    startIcon={<AddOutlined fontSize='small' />}
                    sx={{ height: 36, minWidth: 150 }}
                    onClick={handlerAddStep}
                >
                    Thêm mốc
                </Button>
            </Box>
        </React.Fragment>
    )
}

export default PricingSteps