import { useNotificationContext } from '@contexts/notification'
import { AddOutlined } from '@mui/icons-material'
import { Box, Button, Collapse, Typography } from '@mui/material'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import AddonForm, { INITITAL_ADDON_METADATA } from "./AddonForm"
import AddonOne from './AddonOne'

const ProductAddons = () => {

    const { setNotification } = useNotificationContext()
    const { control } = useFormContext()
    const { fields, append, remove, update } = useFieldArray({
        control: control,
        name: "product_addons"
    })

    const [addon, setAddon] = React.useState({
        open: false,
        data: undefined,
        type: undefined
    })

    const handleSaver = ({ method = 'create', product = {} }) => {
        try {
            if (method === "create") {
                append(product)
            } else {
                const idx = fields.findIndex((item) => item.product_name === product.product_name)
                if (idx >= 0) {
                    update(idx, product)
                } else {
                    return setNotification({ open: true, message: "Không tìm thấy dịch vụ.", severity: 'error' })
                }
            }
        } catch (error) {
            console.log(error?.message)
        }

        return setAddon({ open: false, data: undefined, type: undefined })
    }
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 1,
            mt: 2
        }}>
            <Box sx={{ display: 'flex', minWidth: 300 }}>
                <Box sx={{ width: '100%' }}>
                    <Typography sx={{ fontSize: 14, color: "#777680" }}>
                        Dịch Vụ Addons *
                    </Typography>
                    <Typography sx={{ fontSize: 13, color: "#77768099" }}>
                        Dịch vụ addons sẽ được cấu hình tuỳ chỉnh theo dịch vụ chính
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ width: '100%' }}>
                {!addon.open && <Collapse in={!addon.open}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                        <Button size='small' variant='outlined'
                            disabled={!!addon.open}
                            onClick={() => setAddon({ open: true, data: INITITAL_ADDON_METADATA, type: "create" })}
                            startIcon={<AddOutlined fontSize='small' />} sx={{
                                height: 36,
                                minWidth: 150
                            }}>addons</Button>
                    </Box>
                </Collapse>}
                <Collapse in={!!addon.open}>
                    <AddonForm
                        {...addon}
                        close={() => setAddon({ open: false, data: undefined, type: undefined })}
                        saver={handleSaver} />
                </Collapse>
                {fields && fields.map((item, index) => {
                    return (
                        <AddonOne key={item.id}
                            data={item}
                            remover={() => remove(index)}
                            updater={({ ...props }) => setAddon({ ...props })}
                        />
                    )
                })}
            </Box>
        </Box>
    )
}

export default ProductAddons