import ServiceApi from '@/apis/beta/Service/ServiceApi'
import { queryClientRefetchScope } from "@/functions"
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { useNotificationContext } from '@contexts/notification'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'


const ServiceActive = ({
    onClose,
    data,
}) => {

    const { setNotification } = useNotificationContext()
    const queryClient = useQueryClient()

    const { mutateAsync, isLoading } = useMutation(ServiceApi.EnableService)

    const onSubmit = async () => {
        const response = await mutateAsync({ serviceId: data?.service_id })
        if (!response || response?.errors) {
            return setNotification({ open: true, message: response?.errors?.[0]?.message, severity: "error" })
        }

        queryClientRefetchScope(queryClient, 'service')
        setNotification({ open: true, message: "Kích hoạt dịch vụ thành công", severity: "success" })
        return onClose()
    }
    return (
        <Dialog open={true}
            fullWidth
            maxWidth="md">
            <DialogTitle>
                Kích Hoạt Dịch Vụ
            </DialogTitle>
            <DialogContent>
                <Typography>
                    Dịch vụ {data?.service_name} sẽ được chuyển sang trạng thái kích hoạt.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button size='small'
                    variant='outlined'
                    disable={isLoading}
                    onClick={onClose}
                >
                    Huỷ Thao Tác
                </Button>
                <Button size='small'
                    disable={isLoading}
                    variant='contained'
                    onClick={onSubmit}
                    startIcon={isLoading && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                >
                    Lưu Thay Đổi
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withSuspense(ServiceActive, MuiSkeleton["LinearProgress"])