import { Button } from '@mui/material'
import React from 'react'

const ButtonAddFollower = ({
    handleClick
}) => {
    return (
        <Button
            variant='outlined'
            size='small'
            sx={{ height: 42 }}
            onClick={handleClick}
        >
            Thêm Người Theo Dõi
        </Button>
    )
}

export default ButtonAddFollower