import ServiceApi from '@/apis/beta/Service/ServiceApi'
import { queryClientRefetchScope } from '@/functions'
import { useNotificationContext } from '@contexts/notification'
import { DeleteOutlineOutlined } from '@mui/icons-material'
import AddOutlined from '@mui/icons-material/AddOutlined'
import { Box, Button, Collapse, FormControl, IconButton, Paper, Typography } from '@mui/material'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import IDC from './IDC'
import KeyboardArrowDownOutlined from '@mui/icons-material/KeyboardArrowDownOutlined'

const ColocationMetadata = ({ service }) => {

    const [visibility, setVisibility] = useState(true);

    const queryClient = useQueryClient();
    const { setNotification } = useNotificationContext();

    const methods = useForm({
        defaultValues: useMemo(() => {
            return {
                ...(_.pick(service?.metadata, [
                    "idc",
                ])),
            };
        }, [service]),
    });
    const { control } = methods

    const { mutateAsync, isLoading } = useMutation(ServiceApi.Update);
    const onSubmit = async (values) => {
        let form = {
            service_extra_fields: {
                ...service?.service_extra_fields,
                ...values,
            },
        };
        const response = await mutateAsync({
            serviceId: service?.service_id,
            data: form,
        });
        if (!response || response?.errors) {
            return setNotification({
                open: true,
                message: response?.errors?.[0]?.message,
                severity: "error",
            });
        }
        queryClientRefetchScope(queryClient, "service");

        return setNotification({
            open: true,
            message: "Cập nhật thông tin cấu hình dịch vụ thành công.",
            severity: "success",
        });
    };

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'idc'
    })
    return (
        <Paper sx={{ p: 3, mt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                <Typography sx={{ fontSize: 16, fontWeight: 'medium' }} color="primary">
                    Thông Tin Chi Tiết (Technical)
                </Typography>
                <IconButton size="small" onClick={() => setVisibility(!visibility)}>
                    <KeyboardArrowDownOutlined fontSize='small' color='primary'
                        sx={{
                            transition: 'all 0.5s ease-in-out',
                            transform: visibility ? "rotate(-180deg)" : "unset",
                        }} />
                </IconButton>
            </Box>
            <Collapse in={visibility}>
                <FormProvider {...methods}>
                    <Box sx={{ mt: 1 }} component="form" onSubmit={methods.handleSubmit(onSubmit)}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center', mb: 0.75
                        }}>
                            <Typography sx={{
                                fontSize: 14,
                                color: '#00001F'
                            }}>
                                Thông Tin IDC
                            </Typography>
                            <IconButton size='small' onClick={() => append({ id: -1, name: '', rack_id: -1, rack_name: '' })}>
                                <AddOutlined fontSize='small' color="primary" />
                            </IconButton>
                        </Box>
                        {fields?.map((field, index) => {
                            return (
                                <Box sx={{ display: 'flex', flexDirection: { xs: "column", md: 'row' }, gap: 1.5, mb: 1.5 }} key={field.id}>
                                    <IDC index={index} />
                                    <FormControl fullWidth>
                                        <IconButton size='small'
                                            onClick={() => remove(index)}
                                            sx={{
                                                height: 40,
                                                width: 40
                                            }}>
                                            <DeleteOutlineOutlined fontSize='small' color="error" />
                                        </IconButton>
                                    </FormControl>
                                </Box>
                            )
                        })}
                    </Box>
                    {methods?.formState?.isDirty && <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button size='small' variant='contained' type="submit" disabled={isLoading}>
                            Lưu Thông Tin
                        </Button>
                    </Box>}
                </FormProvider>
            </Collapse>
        </Paper>
    )
}

export default ColocationMetadata