import { Box, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { renderToString } from 'react-dom/server';
import EChart, { SCHEMES, TOOLTIP_OPTIONS, useMarkArea } from "@/components/EChart";

import { resolveServiceAnalyticBandwidthHistoric } from "../../services";
import { formatBandwidthUnit, resolveMaxOfArray } from "@/functions";

import dayjs from "dayjs";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import withSuspense from "@/hocs/withSuspense";

const MarkerSolid = renderToString(<Box sx={{ display: 'inline-block', width: 10, height: 10, bgcolor: "#2196f3", my: 'auto', mr: 1 }} />)
const MarkerDashed = renderToString(<Box sx={{ display: 'inline-block', width: 10, height: 10, border: '2px dashed #2196f3', my: 'auto', mr: 1 }} />)

const BandwidthChart = (props = {}) => {
  const {
    data: [
      current,
      history
    ],
    isLoading
  } = resolveServiceAnalyticBandwidthHistoric(props?.service?.service_uuid, props?.query, props?.range || '30d')

  const maxBandwidth = resolveMaxOfArray(current?.data?.map((item) => item.value));

  const timestamps = current?.data?.map((item) => dayjs(item.timestamp)?.format("YYYY-MM-DD"));
  const zeroTimestamps = current?.data?.filter((item) => item.value === 0)?.map((item) => dayjs(item.timestamp)?.format("YYYY-MM-DD"));

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [markAreaStart, markAreaStop] = useMarkArea(timestamps, zeroTimestamps)
  const options = {
    tooltip: {
      trigger: 'axis',
      ...TOOLTIP_OPTIONS,
      formatter: function (params) {
        const [current, history] = params

        return `
          ${MarkerSolid}<b>${dayjs(current?.name)?.format("DD MMM YYYY")}</b> ${current?.value !== 0 ? formatBandwidthUnit(current?.value) : '-'}<br/>
          ${MarkerDashed}<b>${dayjs(history?.name)?.subtract(dayjs(history?.name)?.daysInMonth(), 'days')?.format("DD MMM YYYY")}</b> ${formatBandwidthUnit(history?.value)}
        `
      }
    },
    grid: {
      top: '5%',
      left: -45,
      right: '0%',
      bottom: '0%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: timestamps,
      axisLine: {
        lineStyle: {
          type: [3, 3],
          dashOffset: 3
        }
      },
      axisLabel: {
        align: 'left',
        formatter: (value) => dayjs(value)?.format("DD MMM")
      }
    },
    yAxis: {
      type: 'value',
      splitNumber: 2,
      splitLine: {
        show: true,
        lineStyle: {
          color: "#e0e0e0",
          type: [3, 3],
          dashOffset: 3
        }
      },
      axisLabel: {
        align: 'left',
        margin: 50,
        color: 'black',
        formatter: (value) => formatBandwidthUnit(value, 0)?.replace(".00", "")
      }
    },
    color: [SCHEMES.primary[0], SCHEMES.primary[0]],
    series: [
      {
        data: current.data?.map((item) => item.value),
        label: {
          formatter: ({ value }) => formatBandwidthUnit(value)
        },
        type: 'line',
        animation: false,
        showSymbol: false,
        markArea: zeroTimestamps?.length !== 0 && {
          data: [
            [
              {
                name: 'Updating Data',
                xAxis: markAreaStart,
                itemStyle: {
                  color: '#f0f0f0'
                },
                label: {
                  show: true,
                  color: '#aaa',
                  offset: [-125, 7.5],
                  fontSize: 16,
                  rotate: 90
                }
              }, {
                xAxis: markAreaStop
              }
            ]
          ]
        }
      },
      {
        data: history?.data?.map((item) => item.value),
        label: {
          formatter: ({ value }) => formatBandwidthUnit(value)
        },
        type: 'line',
        animation: false,
        showSymbol: false,
        lineStyle: {
          type: [5, 10],
          dashOffset: 5
        }
      },
    ]
  };

  return (
    <>
      <Box sx={{ borderBottom: `1px solid ${grey[300]}`, pb: 1 }}>
        <Typography fontSize={14} fontWeight={700} data-ui={`ContentDeliveryNetwork.Dashboard.BandwidthChart.[${props.service_uuid}]`}>
          Bandwidth peak Trends
        </Typography>
      </Box>
      {!isLoading && current && history ? (
        <Box sx={{ mt: 3 }}>
          <EChart options={options} />
          <Box sx={{ my: 2 }}>
            <Stack direction="row" justifyContent="space-between" sx={{ mb: 0.25 }}>
              <Stack direction="row">
                <Box sx={{ display: 'inline-block', width: 10, height: 10, bgcolor: "#2196f3", my: 'auto', mr: 1 }} />
                <Typography fontSize={14} sx={{ my: 'auto' }}>Bandwidth</Typography>
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row">
                <Box sx={{ display: 'inline-block', width: 10, height: 10, border: '2px dashed #2196f3', my: 'auto', mr: 1 }} />
                <Typography fontSize={14} sx={{ my: 'auto' }}>Bandwidth (previous)</Typography>
              </Stack>
            </Stack>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ borderRadius: 1, bgcolor: grey[100], px: 1.5, py: 1, display: 'inline-block' }}>
              <Typography fontSize={13}>
                Max Bandwidth: {formatBandwidthUnit(Number(maxBandwidth))}, Average Bandwidth: {formatBandwidthUnit(Number(current?.averageThroughput))}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <MuiSkeleton.GradientCircularProgress />
      )}
    </>
  )
}

export default withSuspense(BandwidthChart, MuiSkeleton["GradientCircularProgress"])