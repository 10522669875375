
import Destroy from './Destroy';
import Create from "./Create";
import Detail from './Detail';
import List from './List';
import Update from './Update';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    List,
    Create,
    Detail,
    Update,
    Destroy
};
