import BusinessApi from "@/apis/beta/Employee/BusinessApi";
import { useNotificationContext } from "@/contexts/notification";
import { queryClientRefetcher } from "@/functions";
import { DAYOFF_STATUS_CONFIRM_TYPES } from "@/libs/constants";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useQueryClient } from "react-query";

const ModalConfirm = ({ type = "leader-confirm", handleClose, data }) => {
  const queryClient = useQueryClient();

  const { setNotification } = useNotificationContext();

  const [submit, setSubmit] = React.useState(false);

  const [feedback, setFeedback] = React.useState({
    description: "Oke em",
    option: true,
  });
  const [error, setError] = React.useState(false);
  const onSubmit = async () => {
    if (feedback?.description === "") return setError(true);
    setSubmit(true);

    const response = await BusinessApi.Approve({
      businessId: data?.business_id,
      data:
        type === "leader-confirm"
          ? {
              business_leader_feedback: feedback?.description,
              business_leader_confirm: feedback?.option,
              business_status: !!feedback?.option ? 0 : 3,
            }
          : {
              business_bod_feedback: feedback?.description,
              business_bod_confirm: feedback?.option,
              business_status: !!feedback?.option ? 1 : 3,
            },
    });

    setSubmit(false);
    if (!response || !!response?.errors)
      return setNotification({
        open: true,
        severity: "error",
        message:
          response?.errors?.[0]?.message || "Xác nhận phiếu công tác thất bại.",
      });
    queryClientRefetcher(queryClient, ["business.list", "business.show"]);
    setNotification({
      open: true,
      severity: "success",
      message: response?.data?.message || "Xác nhận phiếu công tác thành công.",
    });
    return handleClose();
  };

  const handleChangeInput = (event) => {
    error && setError(false);
    return setFeedback((prev) => ({
      ...prev,
      description: event?.target?.value,
    }));
  };

  return (
    <Dialog open={true} fullWidth maxWidth="md">
      <DialogTitle>Duyệt Phiếu Công Tác</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", mb: 1 }}>
          <Box sx={{ width: "50%", display: "flex" }}>
            <Typography>
              Tên Nhân Viên: {data?.employee?.employee_name}
            </Typography>
          </Box>
          <Box sx={{ width: "50%", display: "flex" }}>
            <Typography>Phòng Ban: {data?.employee_department_name}</Typography>
          </Box>
        </Box>
        {type === "bod-confirm" && (
          <React.Fragment>
            <Typography sx={{ mb: 0.5, mt: 2 }}>
              Xác nhận của Trưởng Bộ Phận
            </Typography>
            <FormControl fullWidth disabled>
              <RadioGroup row value={true}>
                {DAYOFF_STATUS_CONFIRM_TYPES?.map((type) => (
                  <FormControlLabel
                    key={type.value}
                    label={type.value ? " Đồng ý" : "Từ chối"}
                    control={<Radio value={type?.value} size="small" />}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <Typography
              sx={{
                mb: 0.5,
                mt: 1,
                fontSize: 14,
                color: "#777680",
              }}
            >
              Phản hồi *
            </Typography>
            <TextField
              multiline
              fullWidth
              value={data?.day_off_feedback_lead}
              disabled
            />
          </React.Fragment>
        )}
        <Typography sx={{ mb: 0.5, mt: 2 }}>
          Xác nhận của {type === "bod-confirm" ? "BOD/ BOM" : "Trưởng Bộ Phận"}
        </Typography>
        <FormControl fullWidth>
          <RadioGroup
            row
            value={feedback?.option}
            onChange={() =>
              setFeedback((prev) => ({ ...prev, option: !prev?.option }))
            }
          >
            {DAYOFF_STATUS_CONFIRM_TYPES?.map((type) => (
              <FormControlLabel
                key={type.value}
                label={type.value ? " Đồng ý" : "Từ chối"}
                control={<Radio value={type?.value} size="small" />}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Typography sx={{ mb: 0.5, mt: 1 }}>Phản hồi</Typography>
        <FormControl fullWidth>
          <TextField
            multiline
            value={feedback?.description}
            onChange={handleChangeInput}
            placeholder="Nhập phản hồi phiếu nghỉ..."
          />
          {error && (
            <FormHelperText sx={{ color: "#ef5350" }}>
              Vui lòng nhập thông tin phản hồi phiếu.
            </FormHelperText>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          sx={{ height: { xs: 40, md: 42 } }}
          onClick={handleClose}
        >
          Huỷ Thao Tác
        </Button>
        <Button
          variant="contained"
          size="small"
          disabled={submit}
          startIcon={
            submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
          }
          sx={{
            height: { xs: 40, md: 42 },
            minWidth: { xs: "100%", md: 150 },
          }}
          onClick={onSubmit}
        >
          Xác Nhận
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirm;
