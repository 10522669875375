import ContractProcessApi from "@/apis/beta/Contract/ContractProcessApi";
import FileApi from "@/apis/shared/File/FileApi";
import BorderLinearProgress from "@/components/BorderLinearProgress";
import InputFileCustomize from "@/components/shared/InputFileCustomize";
import SingleDocument from "@/components/shared/SingleDocument";
import { useNotificationContext } from "@/contexts/notification";
import { CONTRACT_PROCESS_STATUS } from "@/libs/constants";
import { MenuProps } from "@/styles/common";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";

const FormFeedback = ({
  handleClose,
  contractProcess,
  contractProcessType = "contracts",
}) => {
  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [uploadFile, handleUploadFile] = React.useState({
    loading: false,
    percen: 0,
  });
  const { control, handleSubmit } = useForm({
    defaultValues: {
      contract_process_feedback: "",
      contract_process_status: contractProcess?.contract_process_status,
    },
  });

  const onSubmit = async (values) => {
    setSubmit(true);
    let form = {
      ...values,
    };
    if (!!file && !!file?.name) {
      const prefixPathname = `${contractProcessType === "subcontracts" ? "subcontracts" : "contracts"
        }/contractProcesses/documents`;
      const token = await FileApi.GetPresignedTokenAttachment({
        filename: {
          name: file?.name,
          folder: prefixPathname,
        },
      });
      if (!token.token || token.status !== 200) {
        setSubmit(false);
        return setNotification({
          open: true,
          message: "Lấy thông tin xử lý file thất bại",
          severity: "error",
        });
      }
      handleUploadFile((prevState) => ({
        ...prevState,
        loading: true,
        percen: 0,
      }));
      const uploaded = await FileApi.UploadAttachmentToStorage({
        token: token?.token,
        file: file,
        handleUploadFile: handleUploadFile,
      });
      handleUploadFile((prevState) => ({
        ...prevState,
        loading: false,
        percen: 0,
      }));
      if (!uploaded) {
        setSubmit(false);
        return setNotification({
          open: true,
          message: "Tải dữ liệu thất bại",
          severity: "error",
        });
      }
      form = {
        ...form,
        contract_process_document: Array.from([
          {
            name: file?.name,
            type: file?.type,
            size: file?.size,
            url: ["pub", prefixPathname, token?.filename].join("/"),
          },
        ]),
      };
    }
    await ContractProcessApi.Update(
      contractProcess?.contract_process_reference_id,
      contractProcess?.contract_process_id,
      {
        contract_process_link: window.location.href,
        contract_process: form,
      },
      contractProcessType === "subcontracts" ? "subcontracts" : "contracts"
    ).then((response) => {
      if (response?.status !== 200 || !response?.data?.contract_process) {
        return [
          setNotification({
            open: true,
            message: response?.error?.[0].message,
            severity: "error",
          }),
          setSubmit(false),
        ];
      }

      return [
        handleClose(),
        setNotification({
          open: true,
          message: "Cập nhật trạng thái & phản hồi vấn đề thành công",
          severity: "success",
        }),
      ];
    });
  };
  const fileHandler = async (files) => {
    const extension = files[0].name.split(".")[1]?.toLowerCase();
    if (extension === undefined) {
      return setNotification({
        open: true,
        severity: "error",
        message: "Thể loại tập tin không được hỗ trợ",
      });
    }
    return setFile(files[0]);
  };

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogTitle>Phản Hồi & Cập Nhật Trạng Thái</DialogTitle>
      <DialogContent>
        <Typography sx={{ fontSize: 14, color: "#757557", mb: 2 }}>
          Mã Số Hợp Đồng:{" "}
          <span style={{ color: "#000" }}>
            {contractProcess?.contract_process_code
              ?.split("/")
              .slice(
                0,
                contractProcess?.contract_process_code?.split("/")?.length - 1
              )
              .join("/")}
          </span>
        </Typography>
        <Typography sx={{ mb: 0.5 }}>Trạng Thái</Typography>
        <Controller
          control={control}
          name="contract_process_status"
          render={({ field: { value, onChange } }) => (
            <FormControl fullWidth>
              <Select
                size="small"
                value={value}
                MenuProps={MenuProps}
                onChange={(event) => onChange(event?.target?.value)}
              >
                {CONTRACT_PROCESS_STATUS?.map((item) => (
                  <MenuItem
                    value={item?.value}
                    key={item?.value}
                    sx={{ height: 38, fontSize: 14 }}
                  >
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="contract_process_feedback"
          rules={{ required: "Vui lòng nhập phản hồi về vấn đề" }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <TextField
                {...field}
                label="Phản Hồi/ Cách Xử Lý Vấn Đề *"
                multiline
                InputLabelProps={{ shrink: true }}
                error={!!error}
                helperText={error?.message}
                placeholder="Nhập phản hồi về vấn đề..."
              />
            </FormControl>
          )}
        />
        <Typography sx={{ mb: 0.5, mt: 2 }}>Tập Tin Đính Kèm</Typography>
        <InputFileCustomize fileHandler={fileHandler} />
        {file && (
          <React.Fragment>
            <SingleDocument
              document={file}
              handleDelete={() => setFile(null)}
            />
            {uploadFile?.loading && (
              <BorderLinearProgress value={uploadFile.percen} />
            )}
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          disabled={submit}
          sx={{ height: 36 }}
          variant="text"
          onClick={handleClose}
        >
          Huỷ Thao Tác
        </Button>
        <Button
          size="small"
          disabled={submit}
          sx={{ height: 36 }}
          startIcon={
            submit && <CircularProgress size={14} sx={{ color: "#757575" }} />
          }
          variant="outlined"
          onClick={handleSubmit(onSubmit)}
        >
          Cập Nhật
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormFeedback;
