import withPermission from '@/hocs/withPermission'
import { AccountBalanceWalletOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'

const ButtonCreditCreate = ({
    disabled = true,
    setOpenForm
}) => {
    return (
        <Button variant='outlined'
            disabled={disabled}
            onClick={() => setOpenForm(true)}
            startIcon={<AccountBalanceWalletOutlined />}
            sx={{
                height: 36,
                m: 'auto 16px',
            }}
            size="small"
        >
            Ký Quỹ
        </Button>
    )
}

export default withPermission(ButtonCreditCreate, {
    feature: "customer",
    action: "customer-credit-create",
    type: "Button"
})