import ContactApi from "@/apis/beta/Customer/ContactApi";
import { queryClientRefetcher } from "@/functions";
import ModalConfirm from "@components/shared/ModalConfirm";
import { Typography } from "@mui/material";
import React from "react";
import { useQueryClient } from "react-query";

const MarkSpam = ({ data, handleClose }) => {
  const queryClient = useQueryClient();
  const onSubmit = async () => {
    const response = await ContactApi.Markspam({
      contactId: data?.contact_source_id,
    });
    if (!response || response?.errors) {
      return {
        status: 400,
        message:
          response?.errors?.[0]?.message ||
          "Chuyển khách hàng sang trạng thái SPAM thất bại.",
      };
    }
    return {
      status: 200,
      message: "Chuyển khách hàng sang trạng thái SPAM thành công",
    };
  };

  const callback = () => {
    queryClientRefetcher(queryClient, [
      "contact.list",
      "contact.detail",
      "contacts.statistic",
    ]);
  };
  return (
    <ModalConfirm
      onlyClose={
        data?.contact_source_status !== "SPAM" &&
        data?.contact_source_status !== "UNVERIFIED" &&
        data?.contact_source_status !== ""
      }
      callback={callback}
      handleClose={handleClose}
      handleSubmit={onSubmit}
      dialog={{
        title: `Markspam`,
        content: (
          <Typography component="div" sx={{ fontSize: 14 }}>
            [Contact ID: {data?.contact_source_id}] Đánh dấu khách hàng spam?
          </Typography>
        ),
      }}
    />
  );
};

export default MarkSpam;
