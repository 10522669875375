import { Button } from '@mui/material'
import React from 'react'
import withPermission from '@/hocs/withPermission'

const ButtonCancelCustomer = ({
    handleClick
}) => {
    return (
        <Button
            variant='outlined'
            size='small'
            sx={{ height: 42 }}
            onClick={handleClick}
        >
            Hủy Theo Dõi
        </Button>
    )
}

export default withPermission(ButtonCancelCustomer, { feature: 'customer', action: 'cancel', type: "button" })