import withPermission from '@/hocs/withPermission'
import { AddOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'

const ButtonCreate = ({ handleClick, disabled }) => {
    return (
        <Button variant='contained'
            disabled={disabled}
            onClick={handleClick}
            sx={{ height: { xs: 36, md: 42 } }} size="small"
            startIcon={<AddOutlined fontSize='small' />}
        >
            Thêm nhân viên mới
        </Button>
    )
}

export default withPermission(ButtonCreate, { feature: "employee", action: 'create', type: "button" })