import { formatRequestUnit } from '@/functions'
import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'

const Compare = ({
    results = {},
    query = {}
}) => {
    return (
        <Box sx={{ pb: 1, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3 }}>
            <Typography sx={{ fontSize: 14 }} color="text.black">
                So sánh với ({dayjs(query?.compare_start_time * 1000).format('DD MMM')} - {dayjs(query?.compare_end_time * 1000).format('DD MMM')})
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="primary">
                Percentage: {results?.result?.compare_percentage}%
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="primary">
                Requests: {formatRequestUnit(Math.abs(results?.result?.compare_value))}
            </Typography>
        </Box>
    )
}

export default Compare