import ContactApi from "@/apis/beta/Customer/ContactApi";
import { queryClientRefetcher } from "@/functions";
import { CONTACT_CHANNEL, INDUSTRIES } from "@/libs/constants";
import { useNotificationContext } from "@contexts/notification";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import BaseContactComponents from "../shared/BaseComponents";
import { useEmployeeContext } from "@contexts/employee";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import { vestResolver } from "@hookform/resolvers/vest";
import customerMarketingValidator from "./validator";

const Create = ({ onClose }) => {
  const queryClient = useQueryClient();
  const { employee } = useEmployeeContext();
  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = useState(false);
  const methods = useForm({
    resolver: vestResolver(customerMarketingValidator),
    defaultValues: {
      contact_source_name: "",
      contact_source_email: "",
      contact_source_position: "",
      contact_source_company: "",
      contact_source_phone: "",
      contact_source_industry: "",
      contact_source_profile: "",
      contact_source_website: "",
      contact_source_channel: "marketing-approach",
      contact_source_interested_services: [],
      contact_source_notes: "",
      contact_source_status: "MATCHED",
      contact_source_object_type: "Employee",
      contact_source_object_id: employee?.employee_id,
    },
  });

  const onSubmit = async (values) => {
    setSubmit(true);
    let form = {
      ...values,
    };
    const response = await ContactApi.Create({
      data: form,
    });
    setSubmit(false);
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }
    queryClientRefetcher(queryClient, ["contact.list"]);
    onClose();
    return setNotification({
      open: true,
      message: "Thêm khách hàng marketing thành công.",
      severity: "success",
    });
  };
  const {
    formState: { errors },
  } = methods;

  const CONTACT_SOURCE_FIELDS = [
    {
      key: "contact_source_name",
      label: "Tên *",
      component: "TextField",
      isError: !!errors?.contact_source_name,
      message: errors?.contact_source_name?.message,
    },
    {
      key: "contact_source_position",
      label: "Chức Vụ *",
      component: "TextField",
      isError: !!errors?.contact_source_position,
      message: errors?.contact_source_position?.message,
    },
    {
      key: "contact_source_phone",
      label: "Số Điện Thoại *",
      component: "TextField",
      isError: !!errors?.contact_source_phone,
      message: errors?.contact_source_phone?.message,
    },
    {
      key: "contact_source_email",
      label: "Email *",
      component: "TextField",
      isError: !!errors?.contact_source_email,
      message: errors?.contact_source_email?.message,
    },
    {
      key: "contact_source_company",
      label: "Công Ty",
      component: "TextField",
    },
    {
      key: "contact_source_website",
      label: "Website *",
      component: "TextField",
      isError: !!errors?.contact_source_website,
      message: errors?.contact_source_website?.message,
    },
    {
      key: "contact_source_profile",
      label: "Profile (Hồ Sơ MXH)",
      component: "TextField",
      cols: 12,
    },
    {
      key: "contact_source_industry",
      label: "Ngành *",
      component: "SingleSelect",
      cols: 12,
      props: {
        options: INDUSTRIES,
      },
      isError: !!errors?.contact_source_industry,
      message: errors?.contact_source_industry?.message,
    },
    {
      key: "contact_source_channel",
      label: "Kênh Liên Hệ *",
      component: "SingleSelect",
      cols: 12,
      props: {
        options: CONTACT_CHANNEL,
      },
      isError: !!errors?.contact_source_channel,
      message: errors?.contact_source_channel?.message,
    },
    {
      key: "contact_source_interested_services",
      label: "Giải Pháp *",
      component: "ServiveInteresting",
      cols: 12,
      isError: !!errors?.contact_source_interested_services,
      message: errors?.contact_source_interested_services?.message,
    },
    {
      key: "contact_source_status",
      label: "Phân Loại Khách Hàng *",
      component: "SingleSelect",
      cols: 12,
      props: {
        options: [
          { value: "MATCHED", label: "Đúng đối tượng" },
          { value: "UN-MATCHED", label: "Không đúng đối tượng" },
          { value: "SPAM", label: "SPAM" },
          { value: "", label: "Chưa phân loại" },
        ],
      },
    },
    {
      key: "contact_source_notes",
      label: "Ghi chú",
      component: "TextField",
      props: {
        multiline: true,
      },
      cols: 12,
    },
  ];
  return (
    <Fragment>
      <Typography
        sx={{
          fontSize: { xs: 18, md: 16 },
          fontWeight: "medium",
          mb: 1.75,
        }}
      >
        Thêm Khách Hàng Marketing
      </Typography>
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
          sx={{ width: { xs: "100%", md: 500 } }}
        >
          <Grid container spacing={2}>
            {CONTACT_SOURCE_FIELDS?.map((option, index) => {
              const Component = BaseContactComponents[option?.component];
              if (!Component) return null;
              return (
                <Grid item xs={12} md={option?.cols || 6} key={index}>
                  <Controller
                    name={option.key}
                    control={methods.control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <Component
                          {...field}
                          label={option?.label}
                          {...option?.props}
                        />
                        {option?.isError && (
                          <ErrorHelperText message={option?.message} />
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: { xs: "column", md: "row" },
              gap: 1.5,
            }}
          >
            <Button
              size="small"
              variant="outlined"
              disabled={submit}
              onClick={onClose}
            >
              Huỷ Thao Tác
            </Button>
            <Button
              size="small"
              variant="contained"
              type="submit"
              disabled={submit || !methods?.formState?.isDirty}
              startIcon={
                submit && (
                  <CircularProgress size={14} sx={{ color: "#777680" }} />
                )
              }
            >
              Thêm KH Marketing
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </Fragment>
  );
};

export default Create;
