import React from 'react'
import SwiftTransPackage from "./Package"
import SwiftTransPrice from "./Price"

const ServicePackage = ({ ...props }) => {
    return (
        <>
            <SwiftTransPackage serviceId={props?.service_id} price={props?.service_pricing_configure} />
            <SwiftTransPrice serviceId={props?.service_id} price={props?.service_pricing_configure} />
        </>
    )
}

export default ServicePackage