import { Box, Skeleton } from '@mui/material'
import React from 'react'
import JourneyForm from '../JourneyForm'

const PaperJourney = () => {
    return (
        <React.Fragment>
            <Skeleton variant='text' sx={{ width: 350, mb: 0.5 }} />
            <Box sx={{ height: 36, borderLeft: '2px solid rgba(0, 0, 0, 0.18)', ml: 0.5 }} />
            <Skeleton variant='text' sx={{ width: 300, mb: 1.5 }} />
            <JourneyForm />
            <Skeleton variant='text' sx={{ width: 250, mt: 3 }} />
        </React.Fragment>
    )
}

export default PaperJourney