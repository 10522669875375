import CreateBusiness from "./CreateBusiness";
import CreateServicePOC from "./CreatePoc";
import CreateService from "./CreateService";
import CreateTicket from "./CreateTicket";

export const JourneyHelperModals = {
  POC: CreateServicePOC,
  CreateService: CreateService,
  CreateBusiness: CreateBusiness,
  CreateTicket: CreateTicket
};
