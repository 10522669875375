import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import { queryClientRefetchScope } from "@/functions";
import withPermission from "@/hocs/withPermission";
import { useNotificationContext } from "@contexts/notification";
import Company from "@features/Module/Company";
import { MultipleStopOutlined } from "@mui/icons-material";
import {
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from "@mui/material";
import React from "react";
import { useQueryClient } from "react-query";

const SwitchCustomerType = ({
	disabled = true,
	setDisabled,
	customerId = null,
	customerName = "",
	...customer
}) => {
	const queryClient = useQueryClient();
	const { setNotification } = useNotificationContext();
	const [customerType, setCustomerType] = React.useState(
		customer?.customer_service_information?.customer_type
	);

	React.useEffect(() => {
		setCustomerType(customer?.customer_service_information?.customer_type);
	}, [customer?.customer_service_information?.customer_type]);

	const [submit, setSubmit] = React.useState(false);
	const [openModal, setOpenModal] = React.useState(false);

	const convertIndividual = async () => {
		setSubmit(true);
		const response = await CustomerApi.ChangeCustomerType({
			customerId,
			data: {
				customer_type: "individual",
				cid: -1,
			},
		});
		setSubmit(false);

		if (!response || response?.errors)
			return setNotification({
				open: true,
				message: response?.errors?.[0]?.message,
				severity: "error",
			});
		setNotification({
			open: true,
			message: `Chuyển đổi khách hàng về hình thức cá nhân thành công.`,
			severity: "success",
		});
		queryClientRefetchScope(queryClient, 'customer');
		return handlerClick();
	};

	const handlerClick = () => {
		if (openModal) {
			setDisabled(false);
			return setOpenModal(false);
		}
		setDisabled(true);
		return setOpenModal(true);
	};

	return (
		<React.Fragment>
			{openModal && (
				<Dialog open={true} maxWidth="md" fullWidth>
					<DialogTitle>
						Thay đổi loại khách hàng
						<Typography
							sx={{
								fontSize: 14,
								color: "#777680",
							}}
						>
							Khách hàng
							<Typography
								sx={{
									fontWeight: "medium",
									fontSize: 14,
									display: "inline-flex",
									m: "auto 4px",
								}}
							>
								{customerName}
							</Typography>
							đang là khách hàng{" "}
							{!customerType === "company" ? "doanh nghiệp" : "cá nhân"}. Thao
							tác này sẽ chuyển đổi khách hàng này về dạng khách hàng{" "}
							{customerType === "company" ? "cá nhân" : "doanh nghiệp"}.
						</Typography>
					</DialogTitle>
					<DialogContent>
						{customerType !== "company" && (
							<Collapse in={true}>
								<Company.Precheck
									customerId={customerId}
									onClose={() => {
										setOpenModal(false);
										return setDisabled(false);
									}}
								/>
							</Collapse>
						)}
					</DialogContent>
					{customerType === "company" && (
						<DialogActions>
							<Button
								size="small"
								variant="outlined"
								onClick={handlerClick}
								disabled={submit}
							>
								Huỷ thao tác
							</Button>
							<Button
								size="small"
								variant="contained"
								onClick={convertIndividual}
								disabled={submit}
								startIcon={
									submit && (
										<CircularProgress size={14} sx={{ color: "#777680" }} />
									)
								}
							>
								Xác nhận
							</Button>
						</DialogActions>
					)}
				</Dialog>
			)}
			<Button
				size="small"
				variant="outlined"
				fullWidth
				disabled={disabled || submit}
				startIcon={<MultipleStopOutlined fontSize="small" />}
				onClick={handlerClick}
			>
				{customer?.customer_service_information?.customer_type === 'individual' ? 'Chuyển Sang KHDN' : 'Chuyển Sang KHCN'}
			</Button>
		</React.Fragment>
	);
};

export default withPermission(SwitchCustomerType, {
	feature: "customer",
	action: "change-customer-type",
	type: "Button",
});
