import { FormControl, TextField } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const DomainName = () => {
    const { control, formState: { errors } } = useFormContext()

    return (
        <Controller
            name="domain.domain_value"
            control={control}
            render={({ field: { value, onChange } }) => {
                return (
                    <FormControl fullWidth sx={{ mt: 1 }}>
                        <TextField
                            label="Tên miền *"
                            InputLabelProps={{ shrink: true }}
                            value={value}
                            onChange={onChange}
                            placeholder="Nhập tên miền..."
                            size='small'
                            error={!!errors?.domain?.domain_value}
                            helperText={errors?.domain?.domain_value?.message}
                        />
                    </FormControl>
                )
            }}
        />
    )
}

export default DomainName