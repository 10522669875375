import ErrorHelperText from '@components/shared/ErrorHelperText'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { FormControl } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const PRIVATE_CUSTOMIZED_PAYMENT_TYPES = [
    { value: "postpaid", label: "Trả Sau Theo Lưu Lượng Sử Dụng" },
    { value: "prevpaid-crosscheck", label: "Theo Gói Cam Kết, Đối Soát Vượt" }
]

const ProductPaymentType = () => {

    const { control, formState: { errors } } = useFormContext()
    return (
        <Controller
            name="product_payment_type"
            control={control}
            render={({ field: { value, onChange } }) => {
                const selected = PRIVATE_CUSTOMIZED_PAYMENT_TYPES?.find(v => v.value === value) || null
                return (
                    <FormControl fullWidth>
                        <SingleSelectCustomize
                            options={PRIVATE_CUSTOMIZED_PAYMENT_TYPES}
                            value={selected}
                            input={{
                                label: "Hình thức dịch vụ",
                                placeholder: "Nhập hình thức dịch vụ tìm kiếm..."
                            }}
                            onChange={(event, newValue) => onChange(newValue?.value || -1)}
                        />
                        {!!errors?.product_payment_type &&
                            <ErrorHelperText message={errors?.product_payment_type?.message} />}
                    </FormControl>
                )
            }} />
    )
}
export default ProductPaymentType