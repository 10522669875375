import { API_V3_URL } from "@/libs/constants";
import { requestWithToken, encodeBase64 } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";

class ContractProcessApi {
  List = ({ query = null, searchQuery = null, page = 1, pageSize = 20 }) => {
    return useQueryWithCustomized(
      ["contract-process.list", query, searchQuery, page, pageSize],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/contract_processes?${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ""}&page=${page || 1
          }&page_size=${pageSize || 20}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Create = async (contractNumberId, data, type = "contracts") => {
    return await requestWithToken(
      `${API_V3_URL}/${type}/${contractNumberId}/contract_processes`,
      "POST",
      {
        ...data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Update = async (
    contractNumberId,
    contractProcessId,
    data,
    type = "contracts"
  ) => {
    return await requestWithToken(
      `${API_V3_URL}/${type}/${contractNumberId}/contract_processes/${contractProcessId}`,
      "PUT",
      {
        ...data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Cancel = async ({
    contractId = -1,
    processId = -1,
    type = "contracts" }) => {
    return await requestWithToken(`${API_V3_URL}/${type}/${contractId}/contract_processes/${processId}`, "DELETE")
      .then(({ data }) => {
        if (data.error) return data;
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ContractProcessApi();
