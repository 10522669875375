import { Box } from '@mui/material'

const JourneyEmpty = () => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
        }}>
            <img alt="logo" width="500px" src="/images/original-empty.png" />
        </Box>
    )
}

export default JourneyEmpty