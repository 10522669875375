import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import WarehouseProcessApi from "@/apis/beta/Warehouse/WarehouseProcessApi";
import WarehouseProcessLogApi from "@/apis/beta/Warehouse/WarehouseProcessLogApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import { WAREHOUSE_PROCESS_TYPE } from "@/libs/constants";
import DialogShowProcessProblems from "@components/DialogShowProcessProblems";
import HeaderTitle from "@components/Title/HeaderTitle";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useEmployeeContext } from "@contexts/employee";
import { useNotificationContext } from "@contexts/notification";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import PaperLoading from "../../Shared/Loading/PaperLoading";
import FormExport from "./Form/FormExport";
import FormImport from "./Form/FormImport";
import FormTransfer from "./Form/FormTransfer";

const ProcessesNew = () => {
  const { employee } = useEmployeeContext();
  const params = useParams();
  const [prevData, setPrevData] = useState({});
  const [showProcessProblems, setShowProcessProblems] = useState({
    open: false,
    data: {},
    hide: true,
  });
  const {
    data: warehouse_processes_data,
    isLoading: warehouse_processes_show_loading,
  } = WarehouseProcessApi.Show(params?.id);

  const methods = useForm({
    defaultValues: {
      warehouse_process_type: WAREHOUSE_PROCESS_TYPE?.[0]?.value,
      warehouse_process_to: "",
      warehouse_process_from: "",
      warehouse_process_reason: "",
      warehouse_process_note: "",
      warehouse_process_metadata: [],
      employee_id: employee?.employee_id,
      customer_id: null,
    },
  });

  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = methods;

  const {
    mutateAsync: CreateProcessAsync,
    isLoading: CreateProcessLoading,
    notify,
  } = useMutationWithNotification(WarehouseProcessApi.Create);

  const bottomRefEl = useRef(null);

  const { setNotification } = useNotificationContext();

  const { mutateAsync: UpdateProcessAsync, isLoading: UpdateProcessLoading } =
    useMutationWithNotification(WarehouseProcessApi.Update);

  const [inProgress, setInProgress] = useState(false);
  const handleCreate = async (values) => {
    if (inProgress) return;
    setInProgress(true);
    const newValues = {
      ...values,
      warehouse_process_metadata: [
        ...values?.warehouse_process_metadata?.map(
          ({ warehouse_group_id, children, id, ...rest }) => ({
            ...rest,
            children: children?.map(
              ({ warehouse_group_id, warehouse_owner_name, id, ...rest }) =>
                rest
            ),
          })
        ),
      ],
      warehouse_process_type: Number(values?.warehouse_process_type),
    };
    if (newValues?.warehouse_process_type !== 0) {
      const ids = []
      newValues?.warehouse_process_metadata?.forEach((item) => {
        ids?.push(item?.warehouse_item_id)
        item?.children?.map((child) => ids?.push(child?.warehouse_item_id))
      })
      const paramItems = ids?.map(x => "warehouse_item_id[]=" + x).join("&");
      const checkItemExported = await WarehouseItemApi.CheckItemsExported({
        params: paramItems
      })
      const checkItemExistInProcess = await WarehouseProcessApi.CheckItemExistInProcess({
        params: paramItems + "&warehouse_process_id=" + params?.id
      })
      if (checkItemExistInProcess?.length > 0 || checkItemExported?.length > 0) {
        setShowProcessProblems(prev => {
          return {
            ...prev,
            hide: false,
            data: {
              // ...prev.data,
              ItemsExported: checkItemExported,
              ItemsInProcess: checkItemExistInProcess
            }
          }
        }
        )
        notify("error", "Sản phẩm tồn tại trong phiếu bán đang chờ khác hoặc đã bán (xem tại chi tiết lỗi)");
        setInProgress(false);
        return;
      }
    }
    let response = null;
    let action = null;
    if (!params?.id) {
      response = await CreateProcessAsync(newValues);
      action = "CREATE";
    } else {
      response = await UpdateProcessAsync({
        id: params?.id,
        params: newValues,
      });
      action = "UPDATE";
    }

    if (!response?.errors) {
      const action_process = response?.data?.warehouse_process?.warehouse_process_code?.split("-")[0]?.toLowerCase();
      const warehouse_process_log = {
        warehouse_process_id: response?.data?.warehouse_process?.warehouse_process_id,
        warehouse_process_name: response?.data?.warehouse_process?.warehouse_process_code,
        warehouse_process_update_type: `${action}-${action_process?.toUpperCase()}`,
        warehouse_process_updater_id: employee?.employee_id,
        warehouse_process_updater_name: employee?.employee_name,
        metadata: action === "CREATE" ? response?.data?.warehouse_process || newValues : { previous: prevData, new: newValues },
        warehouse_process_update_description: `${action === "CREATE" ? "Tạo mới" : "Cập nhật"} ${response?.data?.warehouse_process?.warehouse_process_code}`,
      }
      await WarehouseProcessLogApi.createProcessLog(warehouse_process_log);
      navigate(
        `/warehouse/processes/${response?.data?.warehouse_process?.warehouse_process_id || params?.id
        }/detail`
      );
      notify("success", "Success");
    }
    setInProgress(false);
  };

  const handleChange = (warehouse_item) => {
    const oldWarehouseItem = [...watch("warehouse_process_metadata")];

    if (
      oldWarehouseItem?.some(
        (item) => item?.warehouse_item_id === warehouse_item?.warehouse_item_id
      )
    ) {
      return setNotification({
        open: true,
        message: "Bạn đã chọn item này trước đó rồi",
        severity: "error",
      });
    }
    if (
      oldWarehouseItem?.some(
        (item) => item?.children?.some((child) => child?.warehouse_item_id === warehouse_item?.warehouse_item_id)
      )
    ) {
      return setNotification({
        open: true,
        message: "Bạn đã có linh kiện này trong thiết bị đã chọn trước đó rồi",
        severity: "error",
      });
    }
    if (
      oldWarehouseItem?.some(
        (item) => warehouse_item?.children?.some((child) => child?.warehouse_item_id === item?.warehouse_item_id)
      )
    ) {
      return setNotification({
        open: true,
        message: "Linh kiện trong thiết bị này đã tồn tại trong phiếu rồi",
        severity: "error",
      });
    }


    setValue("warehouse_process_metadata", [
      ...oldWarehouseItem,
      {
        ...warehouse_item,
        id: warehouse_item?.warehouse_item_id || Math.random(),
      },
    ]);

    // scrollToLastScreen();
  };

  const scrollToLastScreen = () => {
    setTimeout(() => {
      bottomRefEl?.current?.scrollIntoView({
        behavior: "smooth",
      });
    }, 100);
  };

  const formProps = {
    handleCreate,
    handleChange,
    isFetching:
      CreateProcessLoading ||
      UpdateProcessLoading ||
      warehouse_processes_show_loading,
  };

  const form = {
    0: <FormImport {...formProps} />,
    1: <FormExport {...formProps} />,
    2: <FormTransfer {...formProps} />,
    3: <FormExport {...formProps} data={warehouse_processes_data?.warehouse_process} />,
  };

  useEffect(() => {
    if (!warehouse_processes_data) return;
    setPrevData(warehouse_processes_data?.warehouse_process);
    const {
      warehouse_process_type,
      warehouse_process_to,
      warehouse_process_reason,
      warehouse_process_note,
      warehouse_process_from,
      warehouse_process_metadata,
      employee_id,
    } = warehouse_processes_data?.warehouse_process;

    setValue("warehouse_process_type", warehouse_process_type);
    setValue("warehouse_process_to", warehouse_process_to);
    setValue("warehouse_process_reason", warehouse_process_reason);
    setValue("warehouse_process_note", warehouse_process_note);
    setValue("warehouse_process_from", warehouse_process_from);
    setValue(
      "warehouse_process_metadata",
      warehouse_process_metadata?.map((item) => ({
        ...item,
        id: Math.random(),
        children: item?.children?.map((child) => ({
          ...child,
          id: Math.random(),
        })),
        warehouse_group_id: item?.children?.length > 0 && 1,
      }))
    );
    setValue("employee_id", employee_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse_processes_data]);

  if (warehouse_processes_show_loading) return <PaperLoading />;

  return (
    <FormProvider {...methods}>
      <HeaderTitle>
        {warehouse_processes_data
          ? `Cập Nhất Phiếu #${warehouse_processes_data?.warehouse_process?.warehouse_process_id}`
          : "Thêm Phiếu"}
      </HeaderTitle>

      <Box mt={2}>
        <Paper sx={{ p: 2 }}>
          <Box>
            {!warehouse_processes_data && (
              <Grid container>
                <Grid xs={4}>
                  <Typography>Loại phiếu *</Typography>
                </Grid>

                <Grid xs={8}>
                  <Controller
                    rules={{ required: "Vui lòng chọn loại phiếu" }}
                    name="warehouse_process_type"
                    control={control}
                    render={({ field }) => (
                      <FormControl>
                        <RadioGroup {...field} sx={{ display: "flex" }}>
                          {WAREHOUSE_PROCESS_TYPE?.map((item, index) => (
                            <FormControlLabel
                              key={index}
                              value={item?.value}
                              control={<Radio />}
                              label={item?.label}
                            />
                          ))}
                        </RadioGroup>

                        {!!errors?.warehouse_process_type && (
                          <ErrorHelperText
                            message={errors?.warehouse_process_type?.message}
                          />
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            )}

            <Box>{form?.[watch("warehouse_process_type")]}</Box>
            {!showProcessProblems.hide &&
              <Button
                size="small"
                sx={{ mt: 2 }}
                startIcon={
                  <WarningAmberIcon
                    size={14}
                  />
                }
                variant="contained"
                color="warning"
                onClick={() => {
                  setShowProcessProblems({
                    ...showProcessProblems,
                    open: true
                  })
                }}
              >
                Chi tiết lỗi
              </Button>}
          </Box>
        </Paper>
      </Box>
      <DialogShowProcessProblems
        open={showProcessProblems.open}
        handleClose={() => setShowProcessProblems({ ...showProcessProblems, open: false })}
        data={showProcessProblems.data}
        originalUrl={".."}
      />
      <div ref={bottomRefEl} />
    </FormProvider>
  );
};

export default withSuspense(
  withPermission(ProcessesNew, {
    feature: "warehouse",
    action: "processes-new",
  }),
  MuiSkeleton["GridInformation"]
);
