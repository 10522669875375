
import Slide from "@mui/material/Slide";
import {
  Typography,
  Dialog,
  DialogTitle,
  Button,
  Box,
  DialogContent,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import IframePreview from "./Preview/IframePreview";
import NoPreview from "./Preview/NoPreview";
import ImagePreview from "./Preview/ImagePreview";
import { styled } from "@mui/material/styles";
import VideoPlayer from "./Preview/VPlayer";
import DriveApi from "@/apis/beta/Drives/DriveApi";
import DeleteIcon from "@mui/icons-material/Delete";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import { useConfirmContext } from "@contexts/confirm";
import { useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import PdfPreview from "./Preview/PdfPreview";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { REACT_APP_DOMAIN_FILE } from "@/libs/constants";
import { buildTime, generateToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import LinearProgress from "@components/shared/MuiSkeleton/LinearProgress";
import { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog = styled(Dialog)(() => {
  return {
    ".MuiPaper-root": {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
    },
    ".MuiDialogContent-root": {
      padding: 0,
    },
    ".MuiTypography-root": {
      paddingLeft: 8,
      paddingRight: 8,
    },
  };
});

function DialogPreviewFiles({
  handleClose,
  open,
  path,
  name,
  type,
  useDrivePath = true,
  listFile,
  switchPath,
}) {
  const proxyPreviewOffice = (url) =>
    `https://view.officeapps.live.com/op/embed.aspx?src=${url}`;

  const [searchParams] = useSearchParams();

  const queryClient = useQueryClient();

  const { showConfirmation } = useConfirmContext();

  const { mutateAsync, isLoading: deleteFileLoading } =
    useMutationWithNotification(DriveApi.Delete);

  const buildLinkPreviewWithToken = async (link) => {
    const SECRET_TOKEN_CDN =
      "hutdw0xe2lqlcjpxjwbt6q3kicodpjss38xqbdmrv4fen4a1a2yo1jgcfqdnuhq5";

    const time = buildTime();
    const token = await generateToken(
      link.split(REACT_APP_DOMAIN_FILE)?.[1],
      SECRET_TOKEN_CDN
    );

    return `${link}?stime=${time?.stime}&etime=${time?.etime}&encoded=${token}`;
  };

  const { data, isLoading } = useQueryWithCustomized(["buildLinkPreview", path], () =>
    buildLinkPreviewWithToken(
      useDrivePath ? `${REACT_APP_DOMAIN_FILE}drives/${path}` : path
    )
  );

  const renderPreviewFile = (type, previewLinkFile) => {
    switch (type?.toLowerCase()) {
      case "doc":
      case "docx":
      case "xlsx":
      case "ppt":
      case "pptx":
      case "xls": {
        return <IframePreview url={proxyPreviewOffice(previewLinkFile)} />;
      }

      case "pdf":
        return <PdfPreview url={previewLinkFile} />;

      case "txt":
      case "csv":
      case "html":
        return <IframePreview url={previewLinkFile} />;

      case "gif":
      case "jpg":
      case "jpeg":
      case "jfif":
      case "pjpeg":
      case "pjp":
      case "png":
      case "svg":
      case "webp":
        return <ImagePreview url={previewLinkFile} />;

      case "mp4":
      case "mov":
      case "MOV":
      case "m4a":
      case "mp3":
      case "mkv":
        return <VideoPlayer source={previewLinkFile} />;

      default: {
        return <NoPreview />;
      }
    }
  };

  const handleDeleteFile = async () => {
    await mutateAsync(path);
    queryClient.refetchQueries([`drives.list.${searchParams?.get("path")}`]);
    handleClose();
  };

  const prev = listFile?.[listFile?.findIndex((item) => item === path) - 1];
  const next = listFile?.[listFile?.findIndex((item) => item === path) + 1];

  return (
    <div>
      <CustomDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        hideBackdrop
        fullScreen
      >
        <DialogTitle sx={{ backgroundColor: "#000", padding: 0 }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Button
                onClick={() => {
                  handleClose();
                }}
              >
                <ArrowBackIcon sx={{ color: "#fff" }} fontSize="medium" />
              </Button>

              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
                fontSize="14px"
                ml={2}
              >
                {name}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center">
              {useDrivePath && <a
                href={
                  useDrivePath
                    ? data
                    : path
                }
                target="_blank"
              >
                <Button>
                  <DownloadIcon sx={{ color: "#fff" }} />
                </Button>
              </a>}

              {useDrivePath && (
                <Button
                  onClick={() =>
                    showConfirmation({
                      title: "Bạn có chắc muốn xoá file này",
                      open: true,
                      description:
                        "Thao tác này xoá file vĩnh viễn không thể khôi phục",
                      loading: deleteFileLoading,
                      handler: handleDeleteFile,
                    })
                  }
                >
                  <DeleteIcon sx={{ color: "#fff" }} />
                </Button>
              )}

              {/* {useDrivePath && (
                <Button onClick={() => setShowDialogShare(true)}>
                  <ShareIcon sx={{ color: "#fff" }} />
                </Button>
              )} */}
            </Box>
          </Box>
        </DialogTitle>

        {isLoading ? (
          <LinearProgress />
        ) : (
          <DialogContent sx={{ width: "100%", height: "100%" }}>
            {renderPreviewFile(type, data)}
          </DialogContent>
        )}

        {prev && (
          <Box
            onClick={() => {
              switchPath(prev);
            }}
            sx={{
              position: "absolute",
              top: "50%",
              left: 0,
              transform: "translateY(-50%)",
              cursor: "pointer",
              color: "gray",
            }}
            width={80}
            height={80}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ArrowBackIosNewIcon fontSize="large" />
          </Box>
        )}

        {next && (
          <Box
            onClick={() => {
              switchPath(next);
            }}
            sx={{
              position: "absolute",
              top: "50%",
              right: 0,
              transform: "translateY(-50%)",
              cursor: "pointer",
              color: "gray",
            }}
            width={80}
            height={80}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ArrowForwardIosIcon fontSize="large" />
          </Box>
        )}
      </CustomDialog>

      {/* {showDialogShare && (
        <DrivesShare
          handleClose={() => setShowDialogShare(false)}
          open={showDialogShare}
          path={`${searchParams?.get("path")}${searchParams.get("fileName")}`}
        />
      )} */}
    </div>
  );
}

export default DialogPreviewFiles;
