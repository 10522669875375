import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class SubcontractApi {
    List = ({
        id,
        query = "",
        page = 1,
        pageSize = 10
    }) =>
        useQueryWithCustomized(
            ["contract.subcontract.list", id, query, page, pageSize],
            async () => {
                return await requestWithToken(`${API_V3_URL}/contracts/${id}/subcontracts?`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data;
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                staleTime: 10 * 60 * 1000,
                suspense: true
            }
        );

    SubcontractCodeNumber = ({ contractId }) =>
        useQueryWithCustomized(
            ["subcontract_code_number", contractId],
            async () => {
                return await requestWithToken(`${API_V3_URL}/contracts/${contractId}/subcontracts/subcontract_code_number`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data;
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                staleTime: 10 * 60 * 1000,
                suspense: true
            }
        );

    SubcontractPending = () =>
        useQueryWithCustomized(
            ["subcontract_code_pending"],
            async () => {
                return await requestWithToken(`${API_V3_URL}/subcontracts/subcontracts_pending`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data;
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                staleTime: 10 * 60 * 1000,
                suspense: true
            }
        );

    Create = async ({
        contractId,
        data
    }) => {
        return await requestWithToken(`${API_V3_URL}/contracts/${contractId}/subcontracts`, "POST", {
            subcontract: { ...data }
        }, {}, {})
            .then(({ data }) => {
                if (data?.error) return data;
                return data;
            })
            .catch((error) => {
                return error?.response;
            })
    }

    Update = async ({
        contractId,
        subcontractId,
        data
    }) => {
        return await requestWithToken(`${API_V3_URL}/contracts/${contractId}/subcontracts/${subcontractId}`, "PUT", {
            subcontract: { ...data }
        }, {}, {})
            .then(({ data }) => {
                if (data?.error) return data;
                return data;
            })
            .catch((error) => {
                return error?.response;
            })
    }


    Cancel = async ({
        contractId,
        subcontractId,
        reason
    }) => {
        return await requestWithToken(`${API_V3_URL}/contracts/${contractId}/subcontracts/${subcontractId}`, "DELETE", {
            subcontract: {
                subcontract_note: reason
            }
        }, {}, {})
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                return error.response
            })
    }

    Detail = (id, subcontract_id) =>
        useQueryWithCustomized(
            ["subcontract.detail", subcontract_id],
            async () => {
                return await requestWithToken(`${API_V3_URL}/contracts/${id}/subcontracts/${subcontract_id}`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data?.subcontract;
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                staleTime: 10 * 60 * 1000,
                suspense: true
            }
        );

    CheckCode = async ({
        contractId = -1,
        subcontractCode = ''
    }) => {
        return await requestWithToken(`${API_V3_URL}/contracts/${contractId}/subcontracts/check_code?subcontract_code=${subcontractCode}`, "GET", {}, {}, {})
            .then(({ data }) => {
                if (data?.error) return data;
                return data;
            })
            .catch((error) => {
                return error?.response;
            })
    }

}
// eslint-disable-next-line import/no-anonymous-default-export
export default new SubcontractApi();