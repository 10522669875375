import InvoiceItemApi from "@/apis/beta/Invoice/InvoiceItemApi";
import { queryClientRefetcher } from "@/functions";
import withPermission from "@/hocs/withPermission";
import { PRODUCT_UNITS } from "@/libs/constants";
import NumericFormatCustom from "@components/NumericFormatCustom";
import FormCustomized from "@components/shared/FormCustomized";
import { useNotificationContext } from "@contexts/notification";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

const Create = ({ onClose, invoiceId, invoiceItem, customerId }) => {
	const { setNotification } = useNotificationContext();
	const [submit, setSubmit] = useState(false);
	const queryClient = useQueryClient();

	const [state, setState] = useState(false);
	const [autoCalculate, setAutoCalculate] = useState(true)

	const methods = useForm({
		defaultValues: {
			invoice_item_name: invoiceItem?.invoice_item_name,
			invoice_item_referrer_type: 'Reducer::Override::Service',
			invoice_item_description: "",
			invoice_item_value: 1,
			invoice_item_subtotal: 0,
			invoice_item_deposit: 0,
			invoice_item_amount: 0,
			invoice_item_tax: true,
			invoice_item_pricing: 0,
			invoice_item_pricing_apply: 0,
			invoice_item_referrer_id: invoiceItem?.invoice_item_referrer_id,
			invoice_item_startdate: invoiceItem?.invoice_item_startdate,
			invoice_item_enddate: invoiceItem?.invoice_item_enddate,
			invoice_item_unit: invoiceItem?.invoice_item_unit,
			invoice_item_hidden: false
		},
	});
	const { handleSubmit, watch, setValue } = methods;

	const onSubmit = async (values) => {
		setSubmit(true);
		let form = { ...values, service_id: values?.invoice_item_referrer_id };
		const response = await InvoiceItemApi.Create({
			invoiceId: invoiceId,
			data: form,
		});
		if (!response || response?.errors) {
			return setNotification({
				open: true,
				message: response?.errors?.[0]?.message,
				severity: "error",
			});
		}
		queryClientRefetcher(queryClient, [
			"invoices.list",
			"invoices.statistic",
			"service.invoices",
			"customer.invoices",
			"invoice.detail",
			"invoice.invoice_items",
			"invoice.informations",
			"invoice.transactions.list",
			"invoice.loggers",
		]);
		setNotification({
			open: true,
			message:
				response?.data?.message || "Cập nhật thông tin hoá đơn thành công",
			severity: "success",
		});
		return onClose();
	};

	const cols = { xs: 12, md: 12, xl: 12 }
	const INVOICE_ITEM_FIELDS = [
		{
			name: 'invoice_item_referrer_id', component: 'SingleSelect.CustomerService', cols,
			params: {
				customerId: customerId,
				input: {
					label: 'Dịch Vụ *', placeholder: 'Chọn dịch vụ...'
				}
			}
		},
		{
			name: 'invoice_item_name', component: 'TextField', cols, params: {
				label: 'Tên/ Thông Tin Dòng Hoá Đơn',
				placeholder: 'Nhập tên/thông tin dòng hoá đơn...'
			},
		}, {
			name: 'invoice_item_description', component: 'TextField', cols, params: {
				label: 'Thông Tin Mô Tả Hoá Đơn',
				placeholder: 'Nhập thông tin mô tả hoá đơn...',
				multiline: true
			},
		}, {
			name: 'invoice_item_value', component: 'InputUnlimitCustomized', cols,
			params: {
				label: "Thông Số Sử Dụng (Ex: Số Tháng, Số Sử Dụng/ Số Năm,...)",
				placeholder: 'Nhập thông số sử dụng...'
			}, hidden: state
		},
		{
			name: 'invoice_item_pricing_apply', component: 'TextField', cols: { xs: 12, md: 6, xl: 6 },
			params: {
				label: 'Đơn Giá Bán *',
				placeholder: 'Nhập đơn giá bán...',
				InputProps: { inputComponent: NumericFormatCustom }
			}, hidden: state
		},
		{
			name: 'invoice_item_subtotal', component: 'TextField', cols: { xs: 12, md: 6, xl: 6 },
			params: {
				label: 'Tạm Tính *',
				placeholder: 'Nhập giá tạm tính Ex: T.Số sử dụng * đơn giá bán...',
				InputProps: { inputComponent: NumericFormatCustom }
			}, hidden: state
		}, {
			name: 'invoice_item_deposit', component: 'TextField', cols: { xs: 12, md: 6, xl: 6 },
			params: {
				label: 'Miễn Giảm/ Chiết Khấu',
				placeholder: 'Nhập giá miễn giảm/ chiết khấu thành tiền...',
				InputProps: { inputComponent: NumericFormatCustom }
			}
		},
		{
			name: 'invoice_item_amount', component: 'TextField', cols: { xs: 12, md: 6, xl: 6 },
			params: {
				label: 'Thành Tiền',
				placeholder: 'Nhập giá thành tiền...',
				InputProps: { inputComponent: NumericFormatCustom }
			}
		},
		{
			name: 'invoice_item_tax', component: 'Checkbox', cols: { xs: 12, md: 6, xl: 6 },
			params: { label: 'Thuế (VAT = 10%)' }
		},
		{
			name: 'invoice_item_unit', component: 'SingleSelect', cols: { xs: 12, md: 6, xl: 6 },
			params: {
				options: PRODUCT_UNITS,
				input: { label: 'Đơn Vị Tính', placeholder: 'Chọn đơn vị tính...' }
			}
		},
		{
			name: 'dates', unController: true, component: 'DateRange', cols,
			params: {
				values: [methods.watch(`invoice_item_startdate`), methods.watch(`invoice_item_enddate`)],
				onChange: (newDates) => {
					methods.setValue(`invoice_item_startdate`, newDates[0])
					methods.setValue(`invoice_item_enddate`, newDates[1])
				}
			}
		},
	]

	useEffect(() => {
		if (!autoCalculate) return;
		let subtotal =
			parseFloat(watch("invoice_item_value")) *
			parseFloat(watch("invoice_item_pricing_apply")) || 0;
		return setValue(`invoice_item_subtotal`, subtotal);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch("invoice_item_value"), watch("invoice_item_pricing_apply")]);

	useEffect(() => {
		if (!autoCalculate) return;

		let amount =
			parseFloat(watch("invoice_item_subtotal")) -
			parseFloat(watch("invoice_item_deposit")) || 0;
		return setValue(`invoice_item_amount`, amount);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch("invoice_item_subtotal"), watch("invoice_item_deposit")]);

	return (
		<Dialog open={true} maxWidth="md" fullWidth>
			<DialogTitle>
				Bạn Đang Thao Tác Thêm Dòng Trên Hoá Đơn [Invoice - {invoiceId}]
			</DialogTitle>
			<DialogContent>
				<FormProvider {...methods}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<Box sx={{
								width: '100%',
								display: 'flex',
								alignContent: 'center',
								border: '1px solid rgb(192, 192, 192)',
								borderRadius: '4px',
								pl: '16px',
								height: '40px'
							}}>
								<FormControlLabel
									control={<Checkbox size="small"
										checked={state} onChange={(event) => {
											setState(event?.target?.checked);
											setValue(`invoice_item_pricing_apply`, 0);
											return setValue(`invoice_item_value`, 1);
										}} />}
									label={<Typography sx={{ fontSize: 14 }}>Thêm Dòng Hoá Đơn Chiết Khấu (Miễn/ Giảm)</Typography>}
								/>
							</Box>
						</Grid>
						<Grid item xs={12} md={6}>
							<Box sx={{
								width: '100%',
								display: 'flex',
								alignContent: 'center',
								border: '1px solid rgb(192, 192, 192)',
								borderRadius: '4px',
								pl: '16px',
								height: '40px'
							}}>
								<FormControlLabel
									control={<Checkbox size="small"
										checked={autoCalculate} onChange={(event) => setAutoCalculate(event?.target?.checked)} />}
									label={<Typography sx={{ fontSize: 14 }}>Tự Động Nhân Đơn Giá</Typography>}
								/>
							</Box>
						</Grid>
					</Grid>

					<Box
						component="form"
						sx={{ display: "flex", flexDirection: "column", gap: 1.5, mt: 2 }}
					>
						<FormCustomized attributes={INVOICE_ITEM_FIELDS} />
					</Box>
				</FormProvider>
			</DialogContent>
			<DialogActions>
				<Button
					size="small"
					disable={submit}
					variant="outlined"
					onClick={onClose}
				>
					Huỷ Thao Tác
				</Button>
				<Button
					size="small"
					disable={true}
					variant="contained"
					onClick={handleSubmit(onSubmit)}
				>
					Lưu Thay Đổi
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default withPermission(Create, {
	feature: "invoice",
	action: "invoice-item-update",
	type: "Modal",
});
