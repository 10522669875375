import DataGrid from "@/components/DataGrid";
import { Box, Button, Typography } from "@mui/material";
import DialogShowJsonData from "@components/DialogShowJsonData";
import moment from "moment";
import { useState } from "react";
import { useNotificationContext } from "@contexts/notification";
import WarehouseResoureServiceApi from "@/apis/beta/Warehouse/WarehouseResoureServiceApi";

const ResourceServiceItemLog = ({ 
  service_tag,
  resource_id, 
  resource_service_id, 
  setPageNumber, 
  setPageSize,
  pageNumber,
  pageSize,
  data, 
  refetch }) => {
  const [dialogInfor, setDialogInfor] = useState({
    open: false,
    data: {},
    title: "Thông tin chi tiết"
  })
  const [loading, setLoading] = useState(false);

  const handleChangePageSize = (pageSize) => {
    setPageSize(pageSize);
    setPageNumber(0);
  }
  const handleChangePageNumber = (number) => {
    setPageNumber(number);
  }
  const { setNotification } = useNotificationContext();
  // if (data?.length > 1) {
  //   data?.sort((a, b) => new Date(b?.warehouse_resource_service_item_created_at) - new Date(a?.warehouse_resource_service_item_created_at));
  // }
  const handleUpdateService = async(id) => {
    setLoading(true);
    const response = await WarehouseResoureServiceApi.RemoveItemFromResourceService(resource_id, resource_service_id, id)
    if (!response || response?.status !== 200) {
      setLoading(false);
      return setNotification({
        open: true,
        message: "Ngừng thiết bị thất bại",
        severity: "error"
      })
    }
    refetch();
    setLoading(false);
    setNotification({
      open: true,
      message: "Ngừng thiết bị thành công",
      severity: "success"
    })
  }
  const columns = 
    [{
        field: "warehouse_item_name",
        headerName: "Tên thiết bị",
        width: 150,
    },
    {
        field: "warehouse_item_service_tag",
        headerName: "Service tag/Serial number",
        width: 200,
    },
    {
      field: "employee_name_create",
      headerName: "Nhân viên thêm thiết bị",
      width: 200,
      renderCell: ({ value, row }) =>
        `${row?.employee_id_create}_${value}`,
    },
    {
      field: "employee_name_remove",
      headerName: "Nhân viên ngưng thiết bị",
      width: 200,
      renderCell: ({ value, row }) =>
        value ?
        `${row?.employee_id_remove}_${value}` : "---",

    },
    {
      field: "warehouse_resource_service_item_created_at",
      headerName: "Ngày thêm",
      width: 150,
      renderCell: ({ value }) =>
        value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "---",
    },
    {
      field: "warehouse_resource_service_item_remove_at",
      headerName: "Ngày xoá",
      width: 150,
      renderCell: ({ value }) =>
        value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "---",
    },
    {
      field: "actions",
      headerName: "Thao tác",
      width: 175,
      renderCell: ({ value, row }) => {
        return (
         <>
            {
              !row?.employee_id_remove && 
              <Button 
                size="small"
                variant={service_tag === row?.warehouse_item_service_tag ? "contained" : "outlined"}
                disabled={loading}
                color="error"
                onClick={ () => handleUpdateService(row?.warehouse_item_id)}
              >
                Remove
              </Button>
            }
          </>
        )
      }
    },
  ]

  const onClose = () => {
    setDialogInfor((prev) => ({ ...prev, open: false, data: {} }));
  }

  return (
    <Box sx={{ mt: 2 }}>
      <DialogShowJsonData
        open={dialogInfor.open}
        onClose={onClose}
        title={dialogInfor.title}
        metadata={dialogInfor.data}
        noTranslate={true}
      />
      <Typography
        sx={{ fontSize: { xs: 16, md: 18 }, fontWeight: "medium", mb: 0.5 }}
      >
        Lịch Sử Thiết Bị
      </Typography>
      <DataGrid
        rows={
          data?.warehouse_resource_service_item_logs?.map((item, index) => ({ ...item, id: index }))
        }
        columns={columns}
        componentsProps={{
          toolbar: {
            store: "watches.hiddenColumns",
            // columns: columns?.filter((col) =>?.hideable),
            feature: "warehouse_resource_service",
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: pageNumber || 0,
            pageSize: pageSize || 10,
            onPageChange: (newPage) =>
              handleChangePageNumber(newPage),
            onPageSizeChange: (newPageSize) =>
              handleChangePageSize(newPageSize)
          }
        }}
        rowCount={data?.pagination?.total}
      />
    </Box>
  );
};

export default ResourceServiceItemLog
