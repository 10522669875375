import { WEEK_DAYS } from '@/libs/constants'
import { Chip, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'

const BusinessRangeTime = ({ data }) => {

    const findDayofWeek = React.useCallback(() => {
        let timer = moment(data?.business_startdate).add(-7, 'hours')
        return {
            ...WEEK_DAYS?.find(k => k.value === timer?.isoWeekday()),
            timer: timer
        }
    }, [data?.business_startdate])

    const [date, setDate] = React.useState(findDayofWeek())
    React.useEffect(() => {
        setDate(findDayofWeek())
    }, [findDayofWeek])


    const formatterStartdate = () => {
        return `${moment(date?.timer).format("DD/MM")} ${moment(date?.timer).format("HH:mm")}`
    }

    const formatterEnddate = () => {
        let enddate = moment(data?.business_enddate)?.add(-7, 'hours')
        return `${moment(enddate).format("DD/MM")} ${moment(enddate).format("HH:mm")}`
    }
    return (
        <Chip
            icon={<IconButton size='small'
                sx={{
                    height: 26,
                    width: 26,
                    m: 'auto auto auto 0',
                    bgcolor: `${date?.color}`,
                    ":hover": {
                        bgcolor: `${date?.color}`
                    }
                }}
            >
                <Typography sx={{ fontSize: 13, color: "#000009" }}>
                    {date?.label}
                </Typography>
            </IconButton>}
            sx={{
                bgcolor: `${date?.color}3f`,
            }}
            label={<Typography component="div" sx={{ fontSize: 12, fontWeight: 'medium' }}>
                {formatterStartdate() + ' - ' + formatterEnddate()}
            </Typography>}
        />
    )
}

export default BusinessRangeTime