import ServiceComponentShareds from "@components/Templates/ServiceForm/modules/shared";
import POC from "@components/Templates/ServiceForm/modules/SwiftFederation/POC";
import ServiceManagementBilling from "@components/Templates/ServiceForm/shared/ServiceManagementBilling";
import { Collapse } from "@mui/material";
import React, { Fragment } from "react";
import { useFormContext } from "react-hook-form";

const ColocationCreateView = ({ isPoc, product }) => {
    const { watch } = useFormContext();

    if (isPoc) return <POC />
    return (
        <Fragment>
            <Collapse
                in={!!product && !!Boolean(watch(`product_informations`)?.length > 0)}
            >
                {React.createElement(
                    ServiceComponentShareds["Package.Information"],
                    { product },
                    {}
                )}
            </Collapse>
            <Collapse
                in={
                    product?.product_addons?.length !== 0 &&
                    !isPoc &&
                    !!Boolean(watch(`product_customizable`))
                }
            >
                {React.createElement(
                    ServiceComponentShareds["Customized.Addons"],
                    {
                        productAddons: product?.product_addons,
                    },
                    {}
                )}
            </Collapse>
            {React.createElement(
                ServiceComponentShareds["Package.Payment"],
                { isPoc },
                {}
            )}
            <ServiceManagementBilling />
        </Fragment>
    );
};

export default ColocationCreateView;
