import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import ProductGroupApi from "@/apis/beta/Product/ProductGroupApi";
import DataGridFilter from "@/components/DataGrid/Filter";
import {
  buildQueryFilterCondition,
  format_date_short,
  format_numeral_price,
} from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import useFilters, { Filter } from "@/hooks/useFilters";
import { INDUSTRIES, INITIALIZE_SERVICE_GROUPS } from "@/libs/constants";
import BaseDataGridCustomer, {
  LoadingCustomer,
  BaseCustomerComponents,
} from "@components/ManagementCustomer";
import { useCustomerContext } from "@contexts/customer";
import { Box } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";

const initialState = { page: 0, pageSize: 10 };

const Mainboard = () => {
  const location = useLocation();

  const [query, updateQuery] = useFilters(`customers.list.filters`, location);

  const { queryString } = useCustomerContext();
  const debouncedSearchQuery = useDebounce(queryString, 500);

  const filterableCustomer = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
    const fieldQueries = [
      {
        name: "customers.customer_id",
        operator: "$eq",
        disabled: !parseInt(debouncedSearchQuery),
        value: parseInt(debouncedSearchQuery),
      },
      {
        name: "customers.customer_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "company_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "customers.customer_email",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
    ];
    return buildQueryFilterCondition("$or", 0, fieldQueries);
  }, [debouncedSearchQuery]);

  const [page, setPage] = React.useState(initialState);

  const { data, isFetching } = CustomerApi.CustomerJourneys({
    page: page?.page + 1,
    pageSize: page?.pageSize,
    query: Filter.stringify(query),
    searchQuery: filterableCustomer(),
    type: "active",
  });

  const { data: productGroups } = ProductGroupApi.List();

  const handlerGetFilterables = React.useCallback(() => {
    return (
      data?.employees.map((e) => ({
        value: e?.employee_id,
        label: e?.employee_name,
      })) || []
    );
  }, [data]);

  const [columns] = React.useState([
    {
      field: "customer_created_at",
      headerName: "Ngày tạo khách hàng",
      width: 180,
      valueFormatter: ({ value }) => format_date_short(value),
    },
    {
      field: "customer_services",
      headerName: "Sản phẩm/ dịch đang sử dụng",
      width: 350,
      valueGetter: ({ row }) =>
        row?.services?.map((item) => item?.service_name) || [],
      renderCell: ({ value }) => (
        <BaseCustomerComponents.Service services={value} />
      ),
    },
    {
      field: "services",
      headerName: "Thời gian bắt đầu dịch vụ",
      width: 180,
      renderCell: ({ value }) => (
        <BaseCustomerComponents.ServiceStartdate services={value} />
      ),
    },
    {
      field: "customer_contracts",
      headerName: "Giá trị hợp đồng(theo tháng)",
      minWidth: 220,
      valueGetter: ({ row }) =>
        row?.services?.map((item) =>
          item?.service_amount
            ? `đ` + format_numeral_price(item?.service_amount)
            : "HĐ nguyên tắc"
        ) || [],
      renderCell: ({ value }) => (
        <BaseCustomerComponents.Service services={value} />
      ),
    },
    {
      field: "contract_effective_date",
      headerName: "Hiệu lực hợp đồng",
      width: 150,
      valueGetter: ({ row }) =>
        row?.services?.map((item) =>
          item?.service_contract_effective_date
            ? format_date_short(item?.service_contract_effective_date)
            : "---"
        ) || [],
      renderCell: ({ value }) => (
        <BaseCustomerComponents.Service services={value} />
      ),
    },
    {
      field: "contract_expiry_date",
      headerName: "Thời gian kết thúc",
      width: 150,
      valueGetter: ({ row }) =>
        row?.services?.map((item) =>
          item?.service_contract_expiry_date
            ? format_date_short(item?.service_contract_expiry_date)
            : "---"
        ) || [],
      renderCell: ({ value }) => (
        <BaseCustomerComponents.Service services={value} />
      ),
    },
    {
      field: "contract_status",
      headerName: "Trạng thái hợp đồng",
      width: 180,
      valueGetter: ({ row }) => row?.services || [],
      renderCell: ({ value }) => (
        <BaseCustomerComponents.ContractStatus data={value} />
      ),
    },
  ]);

  const [filterables] = React.useState([
    {
      field: "customers.customer_id",
      label: "ID Khách Hàng",
      operators: {
        type: "number",
      },
    },
    {
      field: "customers.customer_name",
      label: "Tên Khách Hàng",
      operators: {
        type: "string",
      },
    },
    {
      field: "customer_service_informations.customer_sale_followers",
      label: "SALE Phụ Trách",
      operators: {
        type: "object",
        operators: ["$included"],
        collections: handlerGetFilterables(),
      },
    },
    {
      field: "customer_service_informations.customer_cs_followers",
      label: "CS Phụ Trách",
      operators: {
        type: "object",
        operators: ["$included"],
        collections: handlerGetFilterables(),
      },
    },
    {
      field: "customer_service_informations.customer_industry",
      label: "Lĩnh Vực",
      operators: {
        type: "object",
        collections: INDUSTRIES,
      },
    },
    {
      field: "customer_service_informations.customer_service_interestings",
      label: "SP/ DV Quan Tâm",
      operators: {
        type: "object",
        operators: ["$included"],
        collections: productGroups
          ?.filter((productGroup) => !productGroup?.product_group_hidden)
          ?.map((productGroup) => {
            return {
              label: productGroup?.product_group_name,
              value: productGroup?.product_group_name?.toUpperCase(),
            };
          }),
      },
    },
    {
      field: "customer_services",
      label: "SP/DV Đang Sử Dụng",
      operators: {
        type: "object",
        operators: ["$included"],
        collections: INITIALIZE_SERVICE_GROUPS,
      },
    },
  ]);

  return (
    <Box>
      {/* {data?.employees && data.employees.length !== 0 && ( */}
        <DataGridFilter
          callback={updateQuery}
          filters={{
            key: "customers.list.filters",
            filterables: filterables,
            exportFeature: "ManageCustomerSold",
          }}
        />
      {/* )} */}
      <BaseDataGridCustomer
        status="active"
        columns={columns}
        page={page}
        handlerPage={setPage}
        isFetching={isFetching}
        customers={data?.customers || []}
        total={data?.pagination?.total}
        enableSale={true}
        getRowHeight={({ model }) => {
          const PADDING_OFFSET_Y = 8;
          const SERVICES =
            24 * (model?.services?.length || 0) + PADDING_OFFSET_Y;
          const SERVICE_INTERSTINGS =
            24 * (model?.customer_service_interestings?.length || 0) +
            PADDING_OFFSET_Y;
          const SALES =
            24 * (model?.customer_sale_followers?.length || 0) +
            PADDING_OFFSET_Y;
          const CS =
            24 * (model?.customer_cs_followers?.length || 0) + PADDING_OFFSET_Y;
          return Math.max(SERVICES, SALES, CS, SERVICE_INTERSTINGS, 52);
        }}
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(Mainboard, { feature: "customer", action: "customer-active" }),
  LoadingCustomer
);
