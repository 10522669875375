import NumericFormatCustom from '@components/NumericFormatCustom'
import { useStateArrayContext } from '@contexts/stateArray'
import { FormControl, TextField } from '@mui/material'

const ServiceQty = ({ data }) => {
    const methods = useStateArrayContext()
    if (data?.product_type === "node") return null
    return (
        <FormControl fullWidth>
            <TextField
                size='small'
                value={data?.product_value}
                InputProps={{
                    inputComponent: NumericFormatCustom
                }}
                onChange={(event) => {
                    methods.updateAttribute({
                        product_informations: [{
                            ...data?.product_informations?.[0],
                            warehouse_capacity_value: event?.target?.value
                        }],
                    }, data?.id)
                    methods.updateAttribute({
                        product_value: event?.target?.value
                    }, data?.id)
                }}
            />
        </FormControl>
    )
}

export default ServiceQty