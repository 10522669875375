import { Box, Button, CircularProgress, FormControl, Grid } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import ErrorHelperText from '../ErrorHelperText'
import FORM_BASE from './form'

const GridFormLayout = ({ attributes = {} }) => {
    const { control } = useFormContext()
    return (
        <Grid container spacing={2}>
            {attributes?.map(({ name, component, ...attribute }, index) => {
                if (attribute?.hidden) {
                    if (attribute?.offset) {
                        return <Grid item xs={0} md={attribute?.cols?.md || 6} xl={attribute?.cols?.xl || 4} key={index} />
                    }
                    return null;
                };


                const Component = FORM_BASE[component]
                if (!Component) return null;

                const trigger = attribute?.trigger || undefined

                if (attribute?.unController) {
                    return (
                        <Grid item xs={12} md={attribute?.cols?.md || 6} xl={attribute?.cols?.xl || 4} key={index}>
                            <Component {...attribute?.params} />
                        </Grid>
                    )
                }

                return (
                    <Grid item xs={12} md={attribute?.cols?.md || 6} xl={attribute?.cols?.xl || 4} key={index}>
                        <Controller
                            name={name}
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => {
                                return (
                                    <FormControl fullWidth>
                                        <Component value={value} onChange={(...params) => {
                                            onChange(...params)
                                            trigger && trigger()
                                        }} {...attribute?.params} />
                                        {!!error?.message && <ErrorHelperText message={error?.message} />}
                                    </FormControl>
                                )
                            }}
                        />
                    </Grid>
                )
            })}
        </Grid>
    )
}

const FORM_LAYOUTS = {
    Grid: GridFormLayout,
    // Other layouts...
}

const FormCustomized = ({
    layout = "Grid",
    attributes = [],
    submitting = false,
    onClose,
    submitLabel = 'Submit',
    submitNested = false
}) => {
    const { formState: { isDirty } } = useFormContext()
    return (
        <>
            <FORM_LAYOUTS.Grid attributes={attributes} />
            {!!submitNested && <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: { xs: 'column', md: 'row' },
                gap: { xs: 1.5, md: 2 },
                mt: 2
            }}>
                <Button size='small' variant='outlined' disabled={submitting} onClick={onClose}>
                    Huỷ Thao Tác
                </Button>
                <Button size='small' variant='contained' type="submit" disabled={submitting || !isDirty}
                    startIcon={submitting && <CircularProgress sx={{ color: "#777680" }} size={14} />}
                >
                    {submitLabel}
                </Button>
            </Box>}
        </>
    )

}

export default FormCustomized