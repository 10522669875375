import NumericFormatCustom from "@components/NumericFormatCustom";
import ServiceTimes from "@components/Templates/ServiceForm/modules/SwiftFederation/shared/ServiceTimes";
import { PRIVATE_PACKAGE_PAYMENT_TYPES } from "@components/Templates/ServiceForm/modules/shared/Package/Payment/PaymentType";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import { Collapse, FormControl, Grid, TextField, Tooltip, Typography } from "@mui/material";
import { Fragment, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import VPSInformations from "./ProductInformations";

const VPSCreate = ({ isPoc, product }) => {
    const { watch, control, formState: { errors }, setValue } = useFormContext();
    useEffect(() => {
        setValue(`product_addons`, [])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product])
    return (
        <Fragment>
            <Collapse
                in={!!product && !!Boolean(watch(`product_informations`)?.length > 0)}
            >
                <VPSInformations product={product} />
            </Collapse>
            {!isPoc && <>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="product_paid"
                            control={control}
                            render={({ field: { value, onChange } }) => {
                                const selected =
                                    PRIVATE_PACKAGE_PAYMENT_TYPES.find((item) => item?.value === value)
                                    || PRIVATE_PACKAGE_PAYMENT_TYPES[1]
                                return (
                                    <FormControl fullWidth>
                                        <SingleSelectCustomize
                                            options={PRIVATE_PACKAGE_PAYMENT_TYPES}
                                            value={selected}
                                            input={{
                                                label: "Hình thức thanh toán",
                                                placeholder: "Nhập hình thức thanh toán...",
                                            }}
                                            onChange={(event, newValue) => onChange(newValue?.value || -1)}
                                        />
                                        {!!errors?.product_payment_type && (
                                            <ErrorHelperText
                                                message={errors?.product_payment_type?.message}
                                            />
                                        )}
                                    </FormControl>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="product_pricing_amount"
                            control={control}
                            rules={{ required: "Đơn giá bán không được để trống" }}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <FormControl fullWidth>
                                        <TextField
                                            size="small"
                                            label={
                                                <Tooltip title="Đơn giá bán tính theo tháng"
                                                    placement="top"
                                                >
                                                    <Typography sx={{ fontSize: 16 }}>
                                                        Giá bán
                                                        <HelpOutlineOutlined sx={{ display: 'inline-flex' }} fontSize="small" />
                                                    </Typography>
                                                </Tooltip>}
                                            value={value}
                                            placeholder="0"
                                            onChange={onChange}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                inputComponent: NumericFormatCustom,
                                            }}
                                        />
                                        {!!errors?.product_pricing_amount && (
                                            <ErrorHelperText
                                                message={errors?.product_pricing_amount?.message}
                                            />
                                        )}
                                    </FormControl>
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </>}

            <Collapse
                in={
                    !!watch(`product_template`) && !!product?.product_group_id && !isPoc
                }
            >
                <ServiceTimes />
            </Collapse>
        </Fragment>
    );
};

export default VPSCreate;
