import { MenuProps } from '@/styles/common'
import { Box, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const BackupCloudStorage = ({ position }) => {

    const { control, watch } = useFormContext()
    return (
        <Box>
            <Typography sx={{
                fontSize: { xs: 16, md: 20 },
                fontWeight: 'medium',
            }}>
                Backup Cloud Server định kỳ
            </Typography>
            <Controller
                name={`product_configoptions.[${position}].select`}
                control={control}
                render={({ field: { value, onChange } }) => {
                    let options = watch(`product_configoptions.[${position}].product_addons`) || []
                    const selected = value?.product_addon_id || -1
                    return (
                        <FormControl fullWidth>
                            <RadioGroup value={selected}
                                onChange={(event) => onChange(options?.find(item => item.product_addon_id === parseInt(event?.target?.value)) || undefined)}
                            >
                                {options.map((item, index) => {
                                    return (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Box sx={{ display: 'flex', width: '100%' }}>
                                                    <Radio size='small'
                                                        value={item?.product_addon_id}
                                                        checked={selected === item?.product_addon_id}
                                                        sx={{
                                                            "&.Mui-checked": {
                                                                color: "#ef5350"
                                                            }
                                                        }}
                                                    >
                                                        {item?.product_addon_name}
                                                    </Radio>
                                                    <Typography sx={{ fontSize: 14, m: 'auto 0' }}>
                                                        {item?.product_addon_name}
                                                    </Typography>
                                                    {item?.product_addon_name?.match(/Backup dữ liệu theo ngày/) &&
                                                        <Controller
                                                            name={`product_configoptions.[${position}].select.product_addon_qty`}
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => {
                                                                const selected = !value ? 0 : parseInt(value) || 0
                                                                const options = [
                                                                    { value: 0, label: "Chọn mốc backup" },
                                                                    { value: 100, label: "100GB" },
                                                                    { value: 200, label: "200GB" },
                                                                    { value: 300, label: "300GB" },
                                                                    { value: 400, label: "400GB" },
                                                                    { value: 500, label: "500GB" },
                                                                ]
                                                                const disabled = watch(`product_configoptions.[${position}].select`)

                                                                return (
                                                                    <Select size='small'
                                                                        value={selected}
                                                                        onChange={onChange}
                                                                        disabled={disabled?.product_addon_id !== 313}
                                                                        sx={{
                                                                            minWidth: 250,
                                                                            m: 'auto 0 auto auto',
                                                                            color: value === 0 ? "#777680" : ""
                                                                        }}
                                                                        MenuProps={{ ...MenuProps }}
                                                                    >
                                                                        {options?.map(item =>
                                                                            <MenuItem {...item}
                                                                                value={item?.value}
                                                                                disabled={item?.value === 0}
                                                                                key={item?.value}>
                                                                                {item?.label}
                                                                            </MenuItem>)}
                                                                    </Select>
                                                                )
                                                            }}
                                                        />}
                                                </Box>
                                            } />
                                    )
                                })}
                            </RadioGroup>
                        </FormControl>
                    )
                }}
            />

        </Box>
    )
}

export default BackupCloudStorage