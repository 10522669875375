import React from 'react'
import OrderForm from '../shared/OrderForm'
import BreadCrumb from '@components/shared/BreadCrumb'
import { Typography } from '@mui/material'
import { useParams } from 'react-router'
import moment from 'moment'
import withPermission from '@/hocs/withPermission'

const Create = () => {
    const { customer_id } = useParams()

    const INITIAL_ORDER_CREATE_DATA = {
        customer_id: customer_id,
        order_type: "new-order",
        order_send_email: false,
        order_note: "",
        order_amount: 0,
        order_products: [],
        saleplan_expected_date: moment()
    }

    return (
        <React.Fragment>
            <Typography sx={{ fontWeight: 'medium', fontSize: { xs: 18, md: 24 } }}>
                Đơn Hàng Mới
            </Typography>
            <BreadCrumb
                data={[
                    { label: "Khách Hàng", reactLink: "/customers" },
                    { label: customer_id || "---", reactLink: `/customers/${customer_id}/services` },
                    { label: "Thêm đơn hàng mới" },
                ]}
            />
            <OrderForm type="create"
                defaultValues={INITIAL_ORDER_CREATE_DATA}
                saleplanClosed={false}
                customerId={customer_id} />
        </React.Fragment>
    )
}

export default withPermission(Create, { feature: "customer", action: "customer-order-create" })