import { format_date_short, format_numeral_price } from "@/functions"
import { SERVICE_STATUS_TYPES, UNLIMITED } from "@/libs/constants"
import { CellPricing, CellStatus } from "@components/DataGrid/Cell"
import _ from "lodash"
import Contract from "../../shared/Service/ServiceContract"
import ServiceActtions from "../../shared/Service/ServiceActions"

const SwiftCDNButtons = [
    'CreateNote',
    'RegisterService',
    'CancelService',
    'CreateInvoiceRenew',
    'CreateInvoiceStatement',
    'CreateServiceAddon'
]

const SwiftCDNActions = [
    'service-start-date',
    'service-edit',
    'service-date-renew',
    'service-active',
    'service-next-billing',
    'service-current-billing',
    'service-cancel',
    'service-price-amount',
    'service-price-recurring',
    'service-commissions',
    'service-create-invoice-renew',
    'service-information',
    'service-price-amount',
    'service-price-recurring',
    'service-date-statement'
]

const buildSwiftConfig = (swiftCDNConfigs = []) => {
    let CDNConfigs = {}
    let amount = 0
    swiftCDNConfigs?.forEach((cdnConfig) => {
        if (parseFloat(cdnConfig?.commitment?.value) >= UNLIMITED) {
            amount += parseFloat(cdnConfig?.commitment?.pricing_amount || 0)
        } else {
            amount += parseFloat(cdnConfig?.commitment?.value || 0) * parseFloat(cdnConfig?.commitment?.pricing_apply || 0)
        }
        CDNConfigs[cdnConfig?.name] = parseFloat(cdnConfig?.commitment?.value)
    })
    if (amount === 0) {
        amount = parseFloat(swiftCDNConfigs?.[0]?.pricing?.steps[0]?.pricing_apply || 0)
    }
    return {
        ...CDNConfigs,
        service_amount: amount,
        service_recurring: amount
    }
}

const buildSwiftConfigPrice = (metadata = []) => {
    let CDNConfigs = {}
    let amount = 0
    _.forEach(metadata, (values, name) => {
        if (parseFloat(values?.value || 0) >= UNLIMITED) {
            amount += parseFloat(values?.pricing_amount || 0)
        } else {
            amount += parseFloat(values?.value || 0) * parseFloat(values?.pricing_apply || 0)
        }
        CDNConfigs[name] = parseFloat(values?.value)
    })
    return {
        ...CDNConfigs,
        service_amount: amount,
        service_recurring: amount
    }
}

const buildswiftCDNServices = ({ swiftCDNs = [] }) => {
    return swiftCDNs?.map((swiftCDN) => {
        return ({
            ...(_.pick(swiftCDN, [
                'service_create_is_trial',
            ])),
            service_name: `Gói CDN`,
            service_status: 'cancel',
            service_amount: swiftCDN?.service_registry_price,
            service_recurring: swiftCDN?.service_renew_price,
            service_start_date: swiftCDN?.effective_date,
            service_stopped_at: swiftCDN?.expiry_date,
            swiftCDNActions: [],
            ...buildSwiftConfigPrice(swiftCDN?.metadata)
        })
    })
}

const buildswiftCDNService = ({ swiftCDN = {}, swiftCDNConfigs = [] }) => {
    return {
        ...(_.pick(swiftCDN, [
            'service_id',
            'service_amount',
            'service_status',
            'service_recurring',
            'service_start_date',
            'service_stopped_at',
            'service_referrer_id',
            'service_referrer_type',
            'service_create_is_trial',
            'service_employee_commissions',
            'service_metadata',
            'service_billing_enddate',
            'service_billing_startdate',
            'service_nextbill_enddate',
            'service_nextbill_startdate'
        ])),
        ...buildSwiftConfig(swiftCDNConfigs),
        service_name: swiftCDN?.service_product_name?.toUpperCase(),
        swiftCDNActions: SwiftCDNActions?.filter((action) => !['service-information', 'service-price-amount', 'service-price-recurring']?.includes(action)),
        classNames: 'row-service-finally'
    }
}

const swiftCNDRows = ({ swiftCDNs = [], swiftCDN = {}, swiftCDNConfigs = [] }) => {
    return [
        ...buildswiftCDNServices({ swiftCDNs: swiftCDNs }),
        buildswiftCDNService({ swiftCDN, swiftCDNConfigs }),
    ]
}

const formatBandwidthHeader = (name = '') => {
    switch (name) {
        case 'local': return 'Băng Thông Trong Nước';
        case 'global': return 'Băng Thông Quốc Tế';
        case 'tw': return 'Taiwan';
        case 'cn':
        case 'china':
            return 'China';

        default: return name
    }
}

const getServiceColumns = ({ swiftCDNConfigs = [] }) => {
    const bandwidths = swiftCDNConfigs?.length === 1 ?
        [{
            field: 'local',
            headerName: "Băng Thông",
            width: 180,
            renderCell: ({ value }) => {
                if (!value) return "";
                if (parseFloat(value) >= UNLIMITED) return "UNLIMTED";
                return format_numeral_price(value);
            },
        }]
        : swiftCDNConfigs?.map((bandwidth) => {
            return {
                field: bandwidth?.name,
                headerName: formatBandwidthHeader(bandwidth?.name),
                width: 180,
                renderCell: ({ value }) => {
                    if (!value) return "";
                    if (parseFloat(value) >= UNLIMITED) return "UNLIMTED";
                    return format_numeral_price(value);
                },
            }
        })

    return [
        {
            field: "id",
            headerName: ".",
            width: 60,
        }, {
            field: "service_name",
            headerName: "Tên Dịch Vụ",
            width: 300,
        },
        ...bandwidths,
        {
            field: "sale_employee_ids",
            headerName: "Người Hưởng Hoa Hồng",
            width: 220,
            valueGetter: ({ row }) =>
                row?.service_employee_commissions
                    ?.map((e) => e?.employee_name)
                    ?.join(", "),
        }, {
            field: "service_amount",
            width: 200,
            headerName: "Đơn Giá Bán (Theo tháng *) ",
            renderCell: ({ value }) => <CellPricing price={value} />,
        }, {
            field: "service_recurring",
            width: 200,
            headerName: "Đơn Giá Gia Hạn (Theo tháng *) ",
            renderCell: ({ value }) => <CellPricing price={value} />,
        }, {
            field: "services.service_status",
            headerName: "Trạng Thái",
            width: 140,
            valueGetter: ({ row }) =>
                SERVICE_STATUS_TYPES?.find((stt) => stt?.value === row?.service_status),
            renderCell: ({ value }) => {
                return <CellStatus component="muiChip" data={value} />;
            },
        },
        {
            field: "service_start_date",
            headerName: "Ngày Bắt Đầu Sử Dụng DV",
            width: 200,
            valueFormatter: ({ value }) => !!value ? format_date_short(value) : '',
        },
        {
            field: "service_stopped_at",
            headerName: "Ngày Kết Thúc DV",
            width: 200,
            valueFormatter: ({ value }) => !!value ? format_date_short(value) : '',
        },
        {
            field: "service_billing_startdate",
            headerName: "Chu Kỳ Thanh Toán Hiện Tại",
            width: 200,
            valueGetter: ({ row }) => {
                if (row?.service_create_is_trial) return `TRIAL`;
                if (!row?.service_billing_startdate || !row?.service_billing_enddate)
                    return ``;
                return `(${format_date_short(
                    row?.service_billing_startdate
                )} - ${format_date_short(row?.service_billing_enddate)})`;
            },
        }, {
            field: "service_nextbill_startdate",
            width: 220,
            headerName: "Chu Kỳ Gia Hạn",
            valueGetter: ({ row }) => {
                if (row?.service_create_is_trial) return `TRIAL`;
                if (!row?.service_nextbill_startdate || !row?.service_nextbill_enddate)
                    return ``;
                return `(${format_date_short(
                    row?.service_nextbill_startdate
                )} - ${format_date_short(row?.service_nextbill_enddate)})`;
            },
        }, {
            field: "service_contract_name",
            headerName: "Hợp Đồng Hiện Tại",
            minWidth: 350,
            valueGetter: ({ row }) => {
                return {
                    ui: row?.service_referrer_type === 'Reducer::Override::Service' ? "SubContract" : 'Contract',
                    service_id: row?.service_id,
                    service_referrer_id: row?.service_referrer_id
                }
            },
            renderCell: ({ value }) => {
                const ContractUI = Contract[value?.ui]
                if (!ContractUI) return null
                return <ContractUI {...value} />
            }
        }, {
            field: "actions",
            headerName: "",
            width: 40,
            valueGetter: ({ row }) => row?.swiftCDNActions || [],
            renderCell: ({ value, row }) => <ServiceActtions statementable={true} service={row} actions={[...value]} />
        },
    ]
}

const SwiftCDNServiceLibs = {
    columns: getServiceColumns,
    rows: swiftCNDRows,
    activities: SwiftCDNButtons,
    actions: SwiftCDNActions
}
export default SwiftCDNServiceLibs