import { returnNameAndIcon } from "@features/Module/Drives/shared";
import { Box, Button, Drawer, Typography } from "@mui/material";
import React from "react";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";

const Info = ({ open, handleClose, data }) => {
  const { name, icons, type } = returnNameAndIcon(data.key);

  const dataInfo = [
    {
      label: "Icon",
      value: data.type === "folder" ? <FolderCopyOutlinedIcon /> : icons,
    },
    {
      label: "Type",
      value: data.type || type,
    },
    {
      label: "Path",
      value: data.key,
    },
    {
      label: "Owner",
      value: data?.owner_name || "---",
    },
    {
      label: "Preview",
      value: (
        <Button
          onClick={() => {
            data?.preview();
            handleClose();
          }}
          variant="outlined"
          size="small"
          sx={{ mt: 1 }}
        >
          Preview
        </Button>
      ),
    },
  ];

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <Box width={400} sx={{ pt: 2 }}>
        <Box sx={{ pb: 2, borderBottom: "1px solid #e0e0e0", px: 2 }}>
          <Typography
            fontSize={20}
            fontWeight={600}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {data?.name || name}
          </Typography>
        </Box>

        <Box sx={{ px: 2 }}>
          {dataInfo?.map((attribute) => {
            return (
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: 14, mb: 0.25 }} color="text.grey">
                  {attribute?.label}
                </Typography>
                <Typography>{attribute?.value}</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Drawer>
  );
};

export default Info;
