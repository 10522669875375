import EmployeeApi from '@/apis/beta/Employee/EmployeeApi'
import withSuspense from '@/hocs/withSuspense'
import { Box, FormControl, Skeleton, TextField, Typography } from '@mui/material'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const WorkContractEmployee = () => {
    const { control } = useFormContext()

    const employeeId = useWatch({
        control,
        name: 'employee_id'
    })
    const { data, isFetching } = EmployeeApi.Detail(employeeId)
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 0.5, md: 2 },
            mt: 2
        }}>
            <Box sx={{ display: 'flex', minWidth: { xs: '100%', md: 300 } }}>
                <Typography component="div" sx={{
                    m: 'auto 0',
                    color: "#777680"
                }}>
                    Tên nhân viên
                </Typography>
            </Box>
            <FormControl fullWidth>
                {isFetching ? <Skeleton variant='rectangular' sx={{ height: 40 }} /> : <TextField size='small'
                    disabled
                    value={data?.employee_name}
                />}
            </FormControl>
        </Box>
    )
}

const Loading = () => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 0.5, md: 2 },
            mt: 2
        }}>
            <Box sx={{ display: 'flex', minWidth: { xs: '100%', md: 300 } }}>
                <Skeleton variant="text" sx={{ width: 140 }} />
            </Box>
            <FormControl fullWidth>
                <Skeleton variant='rectangular' sx={{ height: 40 }} />
            </FormControl>
        </Box>
    )
}

export default withSuspense(WorkContractEmployee, Loading)