import TextField from "./TextField";
import Paper from "./Paper";
import FormControlTextField from "./FormControlTextField";
import MultiDayoffLine from "./MultiDayoffLine";
import EChartPie from "./EchartPie";
import ScrollTabs from "./ScrollTabs";
import PaperInformation from "./PaperInformation";
import Buttons from "./Buttons";
import PaperInformationButtons from "./PaperInformationButtons";
import ButtonOne from "./ButtonOne";
import Statistical, { StatisticalOne } from "./Statistical";
import DataGrid from "./Datagrid";
import GridInformation from "./GridInformation";
import JourneyForm from "./JourneyForm";
import PaperJourney from "./PaperJourney";
import Grid39 from "./Grid39";
import Loading from "./Loading";
import LinearProgress from "./LinearProgress";
import GradientCircularProgress from "./GradientLoading";
import Text from "./Text";
import Box from "./Box";
import { LoadingDotBox } from "./LoadingDotBox";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Loading,
  GradientCircularProgress,
  TextField,
  Paper,
  FormControlTextField,
  MultiDayoffLine,
  EChartPie,
  ScrollTabs,
  PaperInformation,
  PaperInformationButtons,
  Buttons,
  ButtonOne,
  Statistical,
  StatisticalOne,
  DataGrid,
  GridInformation,
  JourneyForm,
  PaperJourney,
  Grid39,
  LinearProgress,
  Text,
  Box,
  LoadingDotBox
};
