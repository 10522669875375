import { Box, FormControl, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Country from '../shared/Country'
import District from '../shared/District'
import Province from '../shared/Province'
import Ward from '../shared/Ward'

const Customer = () => {
    const { control, watch, formState: { errors } } = useFormContext()
    return (
        <Box>
            <Typography sx={{ fontSize: 14, fontWeight: "medium", mb: 1.5 }}>
                Thông tin khách hàng
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="domain.domain_extra_fields.ownerName"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Tên Khách Hàng (cá nhân/ công ty) *"
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors?.domain?.domain_extra_fields?.ownerName}
                                        helperText={errors?.domain?.domain_extra_fields?.ownerName?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    {watch("domain.domain_extra_fields.for") === "congty" ? (
                        <Controller
                            name="domain.domain_extra_fields.ownerTaxCode"
                            control={control}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <FormControl fullWidth>
                                        <TextField
                                            size='small'
                                            label="Mã Số Thuế *"
                                            InputLabelProps={{ shrink: true }}
                                            value={value}
                                            onChange={onChange}
                                            placeholder="Nhập thông tin mã số thuế..."
                                            error={!!errors?.domain?.domain_extra_fields?.ownerTaxCode}
                                            helperText={errors?.domain?.domain_extra_fields?.ownerTaxCode?.message}
                                        />
                                    </FormControl>
                                )
                            }} />
                    )
                        : <Controller
                            name="domain.domain_extra_fields.ownerID_Number"
                            control={control}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <FormControl fullWidth>
                                        <TextField
                                            size='small'
                                            label="CMND/CCCD *"
                                            placeholder='Nhập CMND/ CCCD...'
                                            InputLabelProps={{ shrink: true }}
                                            value={value}
                                            onChange={onChange}
                                            error={!!errors?.domain?.domain_extra_fields?.ownerID_Number}
                                            helperText={errors?.domain?.domain_extra_fields?.ownerID_Number?.message}
                                        />
                                    </FormControl>
                                )
                            }} />
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    <Country
                        reset='ownerProvince'
                        nameControlManager='ownerCountry' />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Province
                        reset="ownerDistrict"
                        priority="ownerCountry"
                        nameControlManager="ownerProvince" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <District
                        reset="ownerWard"
                        priority="ownerProvince"
                        nameControlManager='ownerDistrict' />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Ward
                        province="ownerProvince"
                        priority="ownerDistrict"
                        nameControlManager='ownerWard' />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Controller
                        name='domain.domain_extra_fields.ownerAddress'
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Địa chỉ *"
                                        placeholder='Nhập địa chỉ khách hàng...'
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors?.domain?.domain_extra_fields?.ownerAddress}
                                        helperText={errors?.domain?.domain_extra_fields?.ownerAddress?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name='domain.domain_extra_fields.ownerEmail1'
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Email Chính *"
                                        placeholder='Nhập email chính của khách hàng...'
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors?.domain?.domain_extra_fields?.ownerEmail1}
                                        helperText={errors?.domain?.domain_extra_fields?.ownerEmail1?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name='domain.domain_extra_fields.ownerEmail2'
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Email Phụ"
                                        placeholder='Nhập email phụ...'
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors?.domain?.domain_extra_fields?.ownerEmail2}
                                        helperText={errors?.domain?.domain_extra_fields?.ownerEmail2?.message} />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name='domain.domain_extra_fields.ownerPhone'
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Số Điện Thoại * "
                                        placeholder='Nhập số điện thoại...'
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors?.domain?.domain_extra_fields?.ownerPhone}
                                        helperText={errors?.domain?.domain_extra_fields?.ownerPhone?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name='domain.domain_extra_fields.ownerFax'
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Số FAX"
                                        placeholder='Số FAX...'
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Customer