import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import { buildQueryFilterCondition } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { useCallback, useMemo, useState } from "react";

const CustomerService = ({ value, onChange, customerId = -1, ...props }) => {
    const [state, setState] = useState("");
    const debouncedSearchQuery = useDebounce(state, 400);

    const filterableCustomer = useCallback(() => {
        let fieldQueries = [];

        if (!!debouncedSearchQuery && !(debouncedSearchQuery?.trim() === "")) {
            fieldQueries.push(
                ...[
                    {
                        name: "tw_service.service_id",
                        operator: "$eq",
                        disabled: !parseInt(debouncedSearchQuery),
                        value: parseInt(debouncedSearchQuery),
                    },
                    {
                        name: "tw_service.service_name",
                        operator: "$contains",
                        value: debouncedSearchQuery,
                    },
                ]
            );
        }
        return buildQueryFilterCondition("$or", 0, fieldQueries);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery, customerId]);

    const {
        isFetching,
        data: { services },
    } = CustomerApi.CustomerServices({
        customerId,
        page: 1,
        pageSize: 15,
        query: filterableCustomer(),
    });

    const rebuildServiceSelections = useMemo(() => {
        if (!services || services?.length === 0) return [];

        return services?.map((service) => {
            let label = service?.service_id;
            label += ` - ${service?.service_name}`;

            return {
                value: service?.service_id,
                label,
            };
        });
    }, [services]);

    const selected = useMemo(() => {
        const service = services?.find((x) => x?.service_id === value);
        if (!service) return null;
        return (
            {
                value: service?.service_id,
                label: `${service?.service_id} - ${service?.service_name}`,
            } || null
        );
    }, [value, services]);

    return (
        <SingleSelectCustomize
            value={selected}
            options={rebuildServiceSelections}
            onChange={(__, newValue) => onChange(newValue?.value || null)}
            input={{
                placeholder: "Nhập ID/ tên dịch vụ tìm kiếm...",
                value: state,
                onChange: (e) => setState(e?.target?.value || ""),
            }}
            sx={{
                "&.MuiAutocomplete-option": {
                    textTransform: "uppercase",
                },
            }}
            loading={isFetching}
            mode="server"
            {...props}
        />
    );
};

export default withSuspense(CustomerService, MuiSkeleton["TextField"]);
