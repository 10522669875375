import AssigneesApi from '@/apis/beta/Assignees/AssigneesApi';
import EmployeeApi from '@/apis/beta/Employee/EmployeeApi';
import withPermission from '@/hocs/withPermission';
import withSuspense from '@/hocs/withSuspense';
import DataGridCustomize from '@components/DataGrid';
import PopperAction from '@components/PopperAction';
import MuiSkeleton from '@components/shared/MuiSkeleton';
import MultipleSelectCustomize from '@components/shared/MultipleSelectCustomize';
import { useNotificationContext } from "@contexts/notification";
import { AddOutlined, MoreVertOutlined } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Popper, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
const List = () => {
    const location = useLocation();
    const [page, setPage] = useState({
        page: 1,
        pageSize: 10,
        total: 0
    });
    const employeesQuery = EmployeeApi.ListEmployees();
    const { data, refetch: refetchAssignees } = AssigneesApi.List({
        assigneeComponent: 'Business', page: page.page,
        pageSize: page.pageSize,
    });
    //const rows = Array.isArray(assignees?.assignees) ? assignees.assignees : [];
    const [popper, setPopper] = React.useState({
        anchorEl: undefined,
        data: undefined
    })
    const [actions] = React.useState([
        {
            order: 1,
            name: "Edit",
            icon: "Edit",
            label: "Chỉnh sửa",
            handleClick: (data) => {
                handleEdit(data);
            }
        }, {
            order: 2,
            name: "Delete",
            icon: "Delete",
            label: "Xóa",
            handleClick: (data) => {
                handleOpenDeleteConfirmation(data.assignee_id);
            }
        }
    ])
    const columns = [
        { field: 'assignee_id', headerName: 'ID', width: 150 },
        { field: 'assignee_name', headerName: 'Tên Thông Báo', width: 600 },
        { field: 'assignee_compoment', headerName: 'Loại Thông Báo', width: 450 },
        {
            field: 'actions', headerName: 'Thao Tác',
            hideable: true,
            width: 100,
            renderCell: ({ row }) => {

                return (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <IconButton
                            size='small'
                            onClick={(event) => {
                                setPopper(prev => ({ ...prev, anchorEl: event?.target, data: row, actions: actions }))
                            }}
                            title='Hành động'
                        >
                            <MoreVertOutlined fontSize='small' sx={{ m: 'auto' }} />
                        </IconButton>
                    </Box>
                )
            }
        }
    ];

    //const getRowId = (row) => row.assignee_id?.toString() || '';

    const [editFormData, setEditFormData] = useState(null);
    const { setNotification } = useNotificationContext();
    const [error, setError] = useState('');
    const [title, setTitle] = useState('');
    const [leaders, setLeaders] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [refreshData, setRefreshData] = useState(false);
    const handleEdit = (rowData) => {
        setEditFormData(rowData);
        setError('');
        setTitle(rowData.assignee_name);
        setLeaders(rowData.leaders || []);
        setEmployees(rowData.employees || []);
    };

    const handleCancelEdit = () => {
        setEditFormData(null);
        setTitle('');
        setLeaders([]);
        setEmployees([]);
    };
    const handleSaveEdit = async () => {
        if (!editFormData || !editFormData.assignee_id) {
            console.error('Không tìm thấy id của assignee.');
            return;
        }
        if (!title) {
            setError("Field is required!")
            return;
        }
        const id = editFormData.assignee_id;
        const updatedAssignee = {
            assignee_name: title,
            leaders: leaders,
            employees: employees
        };
        try {
            await AssigneesApi.Update(id, updatedAssignee);
            setNotification({
                open: true,
                message: 'Cập nhật thành công!',
                severity: 'success'
            });
            setRefreshData(true);
        } catch (error) {
            console.error('Lỗi khi cập nhật:', error);
        }
        setEditFormData(null);
        setTitle('');
        setLeaders([]);
        setEmployees([]);
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
        setError('');
    };
    const handleLeadersChange = (event, newValue) => {
        const selectedLeaders = newValue.map(option => option.employee_id);
        setLeaders(selectedLeaders);
    };
    const handleEmployeesChange = (event, newValue) => {
        const selectedEmployees = newValue.map(option => option.employee_id);
        setEmployees(selectedEmployees);
    };
    useEffect(() => {
        if (refreshData || location.pathname === "/assignee_business") {
            refetchAssignees();
            setRefreshData(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshData, location]);

    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const handleOpenDeleteConfirmation = (assigneeId) => {
        setDeleteConfirm(true);
        setDeleteId(assigneeId);
    };
    const handleDelete = async () => {
        if (!deleteId) {
            console.error('Không tìm thấy id của assignee.');
            return;
        }
        try {
            await AssigneesApi.Delete(deleteId);
            setNotification({
                open: true,
                message: 'Xóa thành công!',
                severity: 'success'
            });
            setRefreshData(true);
        } catch (error) {
            console.error('Lỗi khi xóa:', error);
        }
        setDeleteConfirm(false);
        setDeleteId(null);
    };

    return (
        <Box sx={{ mt: 1.5, mb: 2 }}>
            <Link to="/assignee_business/new" replace>
                <Button
                    variant="contained"
                    size="small"
                    startIcon={<AddOutlined fontSize="small" />}
                    sx={{
                        height: { xs: 40, md: 42 },
                        minWidth: { xs: '100%', md: 180 },
                    }}
                >
                    Thêm Gửi Thông Báo
                </Button>
            </Link>
            {popper?.anchorEl !== undefined && <Popper anchorEl={popper?.anchorEl}
                open={Boolean(popper.anchorEl)} style={{ zIndex: 1000 }}>
                <PopperAction {...popper}
                    actions={actions}
                    handleClose={() => setPopper(prev => ({ ...prev, anchorEl: undefined, data: undefined }))} />
            </Popper>}
            <Box mt={3} style={{ height: 400, width: '100%' }}>
                <DataGridCustomize
                    rows={data.assignees}
                    columns={columns}
                    //rowCount={data?.pagination?.total_pages * page?.pageSize || 0}
                    rowCount={data?.pagination?.total || 0}
                    componentsProps={{
                        pagination: {
                            rowsPerPageOptions: [10, 20, 50, 100],
                            page: page?.page - 1 || 0,
                            pageSize: page?.pageSize || 20,
                            onPageChange: (newPage) =>
                                setPage((prevState) => ({ ...prevState, page: newPage + 1 })),
                            onPageSizeChange: (newPageSize) =>
                                setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
                        },
                    }}
                    sx={{ mt: 2 }}
                    getRowId={(row) => row?.assignee_id}
                />
            </Box>

            <Dialog open={!!editFormData} onClose={handleCancelEdit}>
                <DialogTitle>Thêm thông báo mới</DialogTitle>
                <DialogContent>
                    <TextField
                        id="title"
                        label="Tiêu đề*"
                        variant="outlined"
                        size='small'
                        value={title}
                        onChange={handleTitleChange}
                        fullWidth
                        error={!!error}
                        helperText={error}
                        sx={{ mt: 2 }}
                    />
                    <FormControl fullWidth>
                        <Typography
                            sx={{
                                fontWeight: 'normal',
                                mt: 1.5
                            }}
                            color="black"
                        >
                            Chọn người kiểm duyệt
                        </Typography>
                        <MultipleSelectCustomize
                            limitTags={3}
                            value={employeesQuery.data.filter(emp => leaders.includes(emp.employee_id))}
                            options={employeesQuery.data || []}
                            formatCollection={{
                                value: "employee_id",
                                label: "employee_name",
                            }}
                            compareAttributes={["employee_name"]}
                            onChange={handleLeadersChange}
                            sx={{
                                "&.MuiAutocomplete-option": {
                                    textTransform: "uppercase",
                                },
                            }}
                            mode="server"
                        />

                    </FormControl>

                    <FormControl fullWidth>
                        <Typography
                            sx={{
                                fontWeight: 'normal',
                                mt: 1.5
                            }}
                            color="black"
                        >
                            Chọn nhân viên
                        </Typography>
                        <MultipleSelectCustomize
                            limitTags={3}
                            value={employeesQuery.data.filter(emp => employees.includes(emp.employee_id))}
                            options={employeesQuery.data || []}
                            formatCollection={{
                                value: "employee_id",
                                label: "employee_name",
                            }}
                            compareAttributes={["employee_name"]}
                            onChange={handleEmployeesChange}
                            sx={{
                                "&.MuiAutocomplete-option": {
                                    textTransform: "uppercase",
                                },
                            }}
                            mode="server"
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelEdit}>Hủy</Button>
                    <Button onClick={handleSaveEdit}>Cập Nhật</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteConfirm} onClose={() => setDeleteConfirm(false)}>
                <DialogTitle>Xác nhận xóa</DialogTitle>
                <DialogContent>
                    <Typography>Bạn có chắc chắn xóa thông báo này?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteConfirm(false)}>Để Sau</Button>
                    <Button onClick={handleDelete}>Xóa</Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
};

export default withSuspense(
    withPermission(List, {
        feature: 'businessnotification',
        action: 'index',
    }),
    MuiSkeleton['DataGrid']
);

