import withPermission from '@/hocs/withPermission'
import { Button, CircularProgress } from '@mui/material'
import React from 'react'

const Update = ({
    submit = true,
    onClick
}) => {
    return (
        <Button size="small" type="submit" variant="contained" sx={{
            height: { xs: 36, md: 42 },
            minWidth: 150
        }}
            disabled={!!submit}
            startIcon={(submit === "reset") && <CircularProgress size={14} sx={{ color: "#777680" }} />}
        >
            Huỷ thao tác
        </Button>
    )
}

export default withPermission(Update, {
    feature: 'customer',
    action: "customer-mailer-update",
    type: "Button"
})