import ServiceApi from '@/apis/beta/Service/ServiceApi'
import ServiceTechnicalApi from '@/apis/beta/Service/ServiceTechnicalApi'
import { queryClientRefetchScope } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import useDebounce from '@/hooks/useDebounce'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { useNotificationContext } from '@contexts/notification'
import { Box, Button, FormControl, Grid, Typography } from '@mui/material'
import _ from 'lodash'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

const ServiceManager = ({
    service = {},
}) => {
    const queryClient = useQueryClient();
    const { setNotification } = useNotificationContext();

    const [search, setSearch] = useState(service?.metadata?.name || '')

    const debouncedSearchQuery = useDebounce(search, 400)

    const queries = useCallback(() => {
        return {
            organization_name: debouncedSearchQuery, email: debouncedSearchQuery
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery])
    const methods = useForm({
        defaultValues: useMemo(() => {
            return {
                name: service?.metadata?.name,
                fields: {
                    customer_id: '',
                    organization_id: '',
                    ...service?.metadata?.fields,
                }
            }
        }, [service])
    })

    const { data: { customers }, isFetching } = ServiceTechnicalApi.MlyticCustomers({ queries: queries() })

    const { mutateAsync, isLoading } = useMutation(ServiceApi.Update)
    const onSubmit = async (values) => {
        let form = {
            service_extra_fields: {
                ...service?.service_extra_fields,
                ...values,
            },
        };
        const response = await mutateAsync({
            serviceId: service?.service_id,
            data: form,
        });
        if (!response || response?.errors) {
            return setNotification({
                open: true,
                message: response?.errors?.[0]?.message,
                severity: "error",
            });
        }
        queryClientRefetchScope(queryClient, "service");

        return setNotification({
            open: true,
            message: "Cập nhật thông tin cấu hình dịch vụ thành công.",
            severity: "success",
        });
    };
    return (
        <Fragment>
            <Box sx={{ mt: 2, mb: 2 }}>
                <Typography sx={{ fontSize: 16, fontWeight: 'medium' }} color="primary">
                    Thông Tin Chi Tiết (Technical)
                </Typography>
            </Box>
            <Grid container component="form" onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid item xs={12} md={4} xl={3}>
                    <Controller
                        name="name"
                        control={methods.control}
                        render={({ field: { value, onChange } }) => {
                            const options = _.uniqBy(customers, (customer) => customer?.customerId)?.map((customer) => ({ ...customer, name: customer?.organizationName }))

                            const selected = options?.find(c => c?.organizationName === value)
                            return (
                                <FormControl fullWidth>
                                    <SingleSelectCustomize
                                        loading={isFetching}
                                        value={selected}
                                        options={[
                                            ...options,
                                            {
                                                name: `Thêm Mới: ${search}`,
                                                value: search,
                                                email: search,
                                                lastName: search,
                                                firstName: search,
                                                organizationName: search
                                            }]}
                                        compareAttributes={['firstName', 'lastName', 'email', 'organizationName']}
                                        formatCollection={{
                                            value: 'organizationName',
                                            label: 'name'
                                        }}
                                        input={{
                                            label: 'Tên Portal',
                                            placeholder: 'Portal',
                                            value: search,
                                            onChange: (event) => setSearch(event.target.value)
                                        }}
                                        onChange={(__, newValue) => {
                                            onChange(newValue?.organizationName)
                                            methods.setValue('fields.customer_id', newValue?.customerId || '')
                                            methods.setValue('fields.organization_id', newValue?.organizationId || '')
                                        }}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={8} xl={9}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button size='small' variant='contained' type='submit' disabled={isLoading || !methods?.formState?.isDirty}>
                            Cập Nhật Thông Tin
                        </Button>
                    </Box>
                </Grid>
            </Grid>

        </Fragment>
    )
}

export default withSuspense(ServiceManager, MuiSkeleton["TextField"])