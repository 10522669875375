import useAuth from "@/hooks/useAuth";
import usePermission from "@/hooks/usePermission";
import _ from "lodash";
import React from "react";
import { INITIAL_SIDEBARS } from "../libs/constants";

const LayoutContext = React.createContext();

export const LayoutProvider = ({ children }) => {
  const [auth] = useAuth();
  const [permissions, permissionsPending] = usePermission();

  const findDCPermissions = React.useCallback(() => {
    return (
      permissions?.filter(
        (role) => role?.permission_name?.split("::")[0] === "datacentral"
      ) || []
    );
  }, [permissions]);

  const decontructorRole = React.useCallback(() => {
    return findDCPermissions()?.map((role) => {
      const f_and_action = role?.permission_name.split("::")[1];
      return {
        feature: f_and_action?.split("#")[0],
        action: f_and_action?.split("#")[1],
        pathname: role?.permission_pathname,
        ..._.pick(role, ["module", "is_outlet", "prefix_outlet"]),
      };
    });
  }, [findDCPermissions]);

  const rolesGroupByFeature = React.useCallback(() => {
    return _.chain(decontructorRole())
      .groupBy("feature")
      .map((action, feature) => {
        return {
          feature: feature,
          actions: action,
        };
      })
      .value();
  }, [decontructorRole]);
  const collectionFeature = React.useCallback(() => {
    return rolesGroupByFeature()?.map(({ feature }) => feature) || [];
  }, [rolesGroupByFeature]);

  const handleCollapseSidebar = (isCollapse) => {
    setState((prevState) => ({ ...prevState, isCollapse: isCollapse }));
  };

  const buildSidebarCollections = React.useCallback(() => {
    return (
      INITIAL_SIDEBARS?.filter(
        (sidebar) =>
          !sidebar.required ||
          collectionFeature()?.includes(sidebar?.name) ||
          sidebar?.groupAccepts?.find((s) => collectionFeature()?.includes(s))
      ) || []
    );
  }, [collectionFeature]);
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = React.useState({
    auth: auth,
    sidebars: buildSidebarCollections(),
    isCollapse: false,
    handleCollapseSidebar: handleCollapseSidebar,
    permissions: rolesGroupByFeature(),
    features: collectionFeature(),
  });
  React.useEffect(() => {
    setState((prev) => ({ ...prev, sidebars: buildSidebarCollections() }));
  }, [buildSidebarCollections]);

  React.useEffect(() => {
    if (auth) setState((prevState) => ({ ...prevState, auth: auth }));
  }, [auth]);

  React.useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      sidebars:
        INITIAL_SIDEBARS?.filter(
          (sidebar) =>
            !sidebar.required ||
            collectionFeature()?.includes(sidebar?.name) ||
            sidebar?.groupAccepts?.find((s) =>
              collectionFeature()?.includes(s)
            )
        ) || [],
      handleCollapseSidebar: handleCollapseSidebar,
      permissions: rolesGroupByFeature(),
      features: collectionFeature(),
      permissionsPending,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, permissionsPending]);

  return (
    <LayoutContext.Provider value={state}>{children}</LayoutContext.Provider>
  );
};

export const useLayoutContext = () => {
  return React.useContext(LayoutContext);
};
