import withPermission from '@/hocs/withPermission';
import React from 'react';
import CreditForm from "../shared/CreditForm";

const Update = ({
    customerId,
    data = {},
    onClose
}) => {
    return (
        <CreditForm
            type="update"
            defaultValues={{
                credit_type: data?.credit_type,
                customer_service_credit_id: data?.customer_service_credit_id,
                credit_amount: data?.credit_amount,
                credit_note: data?.credit_note,
                credit_referrer_type: data?.credit_referrer_type,
                credit_referrer_id: data?.credit_referrer_id,
                payment_method: data?.payment_method
            }}
            customerId={customerId}
            onClose={onClose}
        />
    )
}

export default withPermission(Update, { feature: "customer", action: "customer-credit-update", type: "Modal" })