import SubcontractApi from "@/apis/beta/Contract/SubcontractApi";
import { CONTRACT_NUMBER_STATUS_TYPES } from "@/libs/constants";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { useNotificationContext } from "@contexts/notification";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router";

const Information = ({ contract, subcontract }) => {
  const { control, handleSubmit, watch } = useForm({
    defaultValues: useMemo(() => {
      return {
        subcontract_status: subcontract?.subcontract_status,
        subcontract_note: subcontract?.subcontract_note,
      };
    }, [subcontract]),
  });

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { setNotification } = useNotificationContext();

  const [submit, setSubmit] = React.useState(false);

  const onSubmit = (data) => {
    setSubmit(true);
    SubcontractApi.Update({
      contractId: contract?.contract_id,
      subcontractId: subcontract?.subcontract_id,
      data: data,
    }).then((response) => {
      if (!response || !response?.data?.subcontract) {
        return [
          setNotification({
            open: true,
            message: "Cập nhật phụ lục hợp đồng thất bại.",
            severity: "error",
          }),
          setSubmit(false),
        ];
      }

      queryClient.refetchQueries(["subcontract.detail"]);
      return [
        setNotification({
          open: true,
          message: "Cập nhật phụ lục hợp đồng thành công.",
          severity: "success",
        }),
        setSubmit(false),
      ];
    });
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography
        sx={{
          fontWeight: "medium",
          textTransform: "uppercase",
          mb: 2
        }}
      >
        Thông tin phụ lục hợp đồng
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <TextField
            label="Người Phụ Trách"
            disabled
            InputLabelProps={{ shrink: true }}
            size="small"
            defaultValue={subcontract?.employee?.employee_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Công Ty/ Chủ Thể"
            InputLabelProps={{ shrink: true }}
            size="small"
            defaultValue={contract?.customer_company_name || contract?.customer_name}
            InputProps={{
              sx: { bgcolor: "#9E9E9E1F" },
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            control={control}
            name="subcontract_status"
            defaultValue={subcontract?.subcontract_status}
            render={({ field: { value, onChange } }) => (
              <FormControl
                fullWidth
              >
                <SingleSelectCustomize
                  options={CONTRACT_NUMBER_STATUS_TYPES?.filter(x => {
                    switch (subcontract?.subcontract_status) {
                      case 0:
                      case 8: return [subcontract?.subcontract_status].includes(x?.value);
                      case 1: return [1, 2, 3].includes(x?.value);
                      case 2: return [2].includes(x?.value);
                      case 3: return [2, 3, 4].includes(x?.value);
                      case 4: return [2, 4, 6].includes(x?.value);
                      case 6: return [2, 6, 8].includes(x?.value);
                      default: return [2, 8].includes(x?.value)
                    }
                  })}
                  value={CONTRACT_NUMBER_STATUS_TYPES?.find(x => x?.value === value) || null}
                  disabled={[0, 2]?.includes(subcontract?.subcontract_status)}
                  onChange={(_, newValue) => onChange(newValue?.value || -1)}
                  input={{
                    label: 'Trạng thái *',
                    placeholder: 'Chọn trạng thái hợp đồng...'
                  }}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <Controller
            control={control}
            name="subcontract_note"
            defaultValue={subcontract?.subcontract_note}
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth>
                <TextField
                  label="Ghi Chú"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Nhập ghi chú..."
                  multiline {...field} />
                {!!error?.subcontract_note &&
                  watch("subcontract_status") === 2 && (
                    <ErrorHelperText
                      message={error?.subcontract_note?.message}
                    />
                  )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button
          variant="text"
          disabled={submit}
          onClick={() => {
            navigate(`/customer_contracts/${contract?.contract_id}`);
          }}
          size="small"
          sx={{ mr: 2, height: 36 }}
        >
          Huỷ Thao Tác
        </Button>
        <Button
          variant="contained"
          disabled={[0, 2]?.includes(subcontract?.subcontract_status) || submit}
          type="submit"
          startIcon={
            submit && <CircularProgress size={14} sx={{ color: "#757575" }} />
          }
          size="small"
          sx={{ height: 36 }}
        >
          Cập Nhật
        </Button>
      </Box>
    </Box>
  );
};
export default Information;
