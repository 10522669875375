import { Button, CircularProgress } from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { useRef } from "react";
import { useMutation } from "react-query";
import WorkDayApi from "@/apis/beta/Employee/WorkDayApi";
import { useNotificationContext } from "@contexts/notification";

const ImportWorkDay = () => {
  const inputFileRef = useRef();
  const { mutateAsync, isLoading } = useMutation(WorkDayApi.SyncData);

  const { setNotification } = useNotificationContext();

  const handleImport = () => {
    const input = inputFileRef?.current;
    if (!input) return;

    input.click();
    input.onchange = async (e) => {
      const file = e?.target?.files?.[0];
      if (!file) return;
      const response = await mutateAsync(file);
      if (response?.errors)
        return setNotification({
          open: true,
          message: response?.errors?.[0]?.message || "Something went wrong",
          severity: "error",
        });

      setNotification({
        open: true,
        message: "Đồng bộ thành công",
        severity: "success",
      });
    };
  };

  return (
    <Button
      variant="outlined"
      size="small"
      sx={{
        "&.MuiButton-root": {
          height: 36,
          m: "auto 0",
        },
      }}
      onClick={handleImport}
      startIcon={
        isLoading ? <CircularProgress size={14} /> : <ImportExportIcon />
      }
      disabled={isLoading}
    >
      <input type="file" hidden ref={inputFileRef} />
      Đồng bộ dữ liệu
    </Button>
  );
};

export default ImportWorkDay;
