import { requestWithToken, encodeBase64 } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class CustomerServiceCreditApi {
  List = ({ customerId, page, pageSize, query, searchQuery }) => {
    return useQueryWithCustomized(
      [
        "customer.serivce-credits",
        customerId,
        page,
        pageSize,
        query,
        searchQuery,
      ],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/customers/${customerId}/customer_service_credits?${
            query ? `&filters=${encodeBase64(query)}` : ""
          }${
            !!searchQuery && searchQuery !== "" ? `&queries=${searchQuery}` : ""
          }&page=${page || 1}&page_size=${pageSize || 20}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Credit = ({ customerId }) => {
    return useQueryWithCustomized(
      ["customer.service-credit", customerId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/customers/${customerId}/customer_service_credits/statistic`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.credit;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Create = async ({ customerId, data }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/customer_service_credits`,
      "POST",
      {
        credit: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Update = async ({ customerId, creditId, data }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/customer_service_credits/${creditId}`,
      "PUT",
      {
        credit: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error.response;
      });
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CustomerServiceCreditApi();
