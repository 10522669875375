import { format_date_short } from '@/functions'
import { DAYOFF_TIME_OPTIONS } from '@/libs/constants'
import DataGridCustomize from '@components/DataGrid'
import React from 'react'

const DayoffRangeTime = ({ data = [] }) => {
    const [columns] = React.useState([
        {
            field: "#", headerName: "#",
            width: 80,
            valueGetter: ({ row }) => row?.id + 1
        }, {
            field: "startdayoff", headerName: "Ngày Nghỉ",
            width: 150,
            renderCell: ({ value }) => format_date_short(value)
        }, {
            field: "dayoff_option", headerName: "Thời Gian",
            width: 150,
            renderCell: ({ value }) => DAYOFF_TIME_OPTIONS?.find(t => t.value?.toString() === value?.toString())?.label
        }
    ])
    return (
        <DataGridCustomize
            rows={data?.map((item, index) => ({ ...item, id: index }))}
            columns={columns}
            components={{
                Toolbar: "disabled", Pagination: "disabled"
            }}
            paginationMode="client"
        />
    )
}

export default DayoffRangeTime