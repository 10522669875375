import withPermission from '@/hocs/withPermission'
import { AddOutlined } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const ButtonCreate = ({ employeeId = -1 }) => {
    return (
        <Box sx={{ display: 'flex', mb: 2, justifyContent: 'flex-end' }}>
            <Link to={`/employees/${employeeId}/work_contracts/new`} replace>
                <Button size="small" variant="contained" startIcon={<AddOutlined fontSize="small" />}
                    sx={{ height: { xs: 36, md: 42 }, minWidth: 150 }}
                >
                    Hợp Đồng Nhân Viên
                </Button>
            </Link>
        </Box>
    )
}

export default withPermission(ButtonCreate, { feature: 'work-contract', action: 'create', type: 'button' })