import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Button, Chip, Container, Dialog, IconButton, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { API_STORAGE_URL } from '@/libs/constants';
import IosShareIcon from '@mui/icons-material/IosShare';
import Paper from '@mui/material/Paper';
import ReportWatchApi from '@/apis/beta/Threshold/Report/ReportWatchApi';
import useMutationWithNotification from '@/hooks/useMutationWithNotification';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));
const DialogHistoryReport = (props) => {
    const {
        notify,
      } = useMutationWithNotification();
    const {data: history, refetch} = ReportWatchApi.History({uuid: props?.report_uuid});
    const { tasks } = history?.data;
    if (tasks?.length > 0) {
        tasks?.sort((a, b) => new Date(b?.task_completed_at) - new Date(a?.task_completed_at));
    }
    const handleExportReport = async(task) => {
        const url = `${API_STORAGE_URL}reports/waf/${task?.task_completed_at?.slice(0, 7)}/${task?.task_referrer_jid}.pdf`;
        const nameFile = `${props?.historyName?.replaceAll(' ', '')}_${task?.task_completed_at?.slice(0, 10)}`
        // props?.handleExportReport(url, nameFile);
        const anchorElement = document.createElement('a');
        anchorElement.href = url;
        anchorElement.download = `${nameFile}`;
        await fetch(url)
          .then(response => {
            if (response.status !== 200) throw new Error('File not found');
            return response.blob()
          })
          .then(blob => {
            const url = URL.createObjectURL(blob);
            anchorElement.href = url;
            anchorElement.dispatchEvent(new MouseEvent('click'));
          }).catch(err => notify("error", err?.message));
    }
    const tableCell = [
        {
            name: "Ngày hoàn thiện (MM,DD,YYYY)",
            align: "left"
        },
        {
            name: "Loại report",
            align: "center"
        },
        {
            name: "Trạng thái gửi",
            align: "center"
        },
        {
            name: "Xuất file",
            align: "center"
        }
    ]
    return (
        <Dialog
            hideBackdrop
            fullScreen
            keepMounted
            open={props?.open}
            onClose={props?.handleClose}
            TransitionComponent={Transition}
            scroll='paper'
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props?.handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Lịch sử báo cáo
                    </Typography>
                    <Button autoFocus color="inherit" onClick={props?.handleClose}>
                        Thoát
                    </Button>
                </Toolbar>
            </AppBar>
            <Container maxWidth="sm" sx={{ p: 10 }}>
                <Demo>
                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                        Lịch sử báo cáo {props?.historyName}
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    {tableCell.map((cell, index) => (
                                        <TableCell key={index} align={cell?.align}>{cell?.name}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tasks?.map((task, index) => (
                                    new Date(task?.task_completed_at).getFullYear() >= 2024 &&
                                    <TableRow
                                        hover
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="left">
                                            {new Date(task?.task_completed_at).toLocaleString()}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>{task?.task_bind?.replace("waf::report::","")}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Chip size='small' label={task?.task_status} color={task?.task_status === "completed" ? "success" : "fail"} variant="outlined" />
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton edge="end" aria-label="export" onClick={() => handleExportReport(task)}>
                                                <IosShareIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                </Demo>
            </Container>

        </Dialog>
    );
}

export default DialogHistoryReport;
