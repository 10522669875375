import { Box } from "@mui/material";
import Loading from "../GradientLoading";
import DotStretchLoading from "./DotStretchLoading";

export default function LoadingBox() {
    return (
        <Box sx={{ width: "100%", bgcolor: "white", px: 2, pt: 2, pb: 1.5, borderRadius: 1 }}>
            <Loading />
        </Box>
    )
}

export function LoadingDotBox({ sx = {} }) {
    return (
        <Box sx={{ p: 3, ...sx }}>
            <DotStretchLoading />
        </Box>
    )
}