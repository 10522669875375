import { create, enforce, skipWhen, test } from "vest";
import isEmail from 'validator/lib/isEmail';
import { doesCompanyExist, doesCustomerExist } from "@/libs/validators";

enforce.extend({ isEmail });

export const contactCreateValidator = create((data) => {
    test(`contact_type`, "Vui lòng chọn loại thông tin liên hệ.", () => {
        enforce(data.contact_type).isNotEmpty()
    })
    test(`contact_label`, "Vui lòng chọn mục đích tạo của thông tin này.", () => {
        enforce(data.contact_label).isNotEmpty()
    })
    skipWhen(
        data?.contact_type !== 'customer',
        () => {
            test(`personal_information.name`, "Vui lòng nhập họ & tên khách hàng.", () => {
                enforce(data.personal_information.name).isNotEmpty()
            })
            test(`personal_information.email`, "Vui lòng nhập email.", () => {
                enforce(data.personal_information.email).isNotEmpty()
            })
            test(`personal_information.email`, "Email không hợp lệ.", () => {
                enforce(data.personal_information.email).isEmail()
            })
            skipWhen(
                (res) => {
                    return res.hasErrors("personal_information.email")
                },
                () => {
                    test.memo(
                        "personal_information.email",
                        "Email khách hàng đã tồn tại.",
                        () => {
                            return doesCustomerExist(
                                data.personal_information.email,
                                "customer_email"
                            );
                        },
                        [data.personal_information.email]
                    );
                }
            )
            test(`personal_information.phone_number`, "Vui lòng nhập số điện thoại khách hàng.", () => {
                enforce(data.personal_information.email).isNotEmpty()
            })
            test(`personal_information.position`, "Vui lòng nhập số điện thoại khách hàng.", () => {
                enforce(data.personal_information.email).isNotEmpty()
            })
        }
    )
    skipWhen(
        data?.contact_type !== 'company',
        () => {
            test(`company.company_name`, "Vui lòng nhập tên công ty.", () => {
                enforce(data?.company.company_name).isNotEmpty()
            })
            test(`company.company_tax`, "Vui lòng nhập mã số thuế công ty.", () => {
                enforce(data?.company.company_tax).isNotEmpty()
            })
            skipWhen(
                (res) => {
                    return res.hasErrors("company.company_tax")
                },
                () => {
                    test.memo(
                        "company.company_tax",
                        "Mã số thuế này đã tồn tại dưới công ty khác.",
                        () => {
                            return doesCompanyExist(
                                data?.company?.company_tax,
                                "company_tax"
                            );
                        },
                        [data?.company?.company_tax]
                    );
                }
            )
            test(`company.company_address`, "Vui lòng nhập địa chỉ công ty.", () => {
                enforce(data?.company.company_address).isNotEmpty()
            })
        })
})

export const contactUpdateValidator = create((data) => {
    test(`contact_type`, "Vui lòng chọn loại thông tin liên hệ.", () => {
        enforce(data.contact_type).isNotEmpty()
    })
    test(`contact_label`, "Vui lòng chọn mục đích tạo của thông tin này.", () => {
        enforce(data.contact_label).isNotEmpty()
    })

    skipWhen(
        data?.contact_type !== 'customer',
        () => {
            test(`personal_information.name`, "Vui lòng nhập họ & tên khách hàng.", () => {
                enforce(data.personal_information.name).isNotEmpty()
            })
            test(`personal_information.email`, "Vui lòng nhập email.", () => {
                enforce(data.personal_information.email).isNotEmpty()
            })
            test(`personal_information.email`, "Email không hợp lệ.", () => {
                enforce(data.personal_information.email).isEmail()
            })
            skipWhen(
                (res) => {
                    return res.hasErrors("personal_information.email") || data?.email === data?.personal_information?.email
                },
                () => {
                    test.memo(
                        "personal_information.email",
                        "Email khách hàng đã tồn tại.",
                        () => {
                            return doesCustomerExist(
                                data.personal_information.email,
                                "customer_email"
                            );
                        },
                        [data.personal_information.email]
                    );
                }
            )
            test(`personal_information.phone_number`, "Vui lòng nhập số điện thoại khách hàng.", () => {
                enforce(data.personal_information.email).isNotEmpty()
            })
            test(`personal_information.position`, "Vui lòng nhập số điện thoại khách hàng.", () => {
                enforce(data.personal_information.email).isNotEmpty()
            })
        }
    )
})