import { Box, Typography } from '@mui/material'
import React from 'react'
import PrivateIconWithFormat from '../../../shared/icons'

const Assignee = ({ assignees = [] }) => {
    return (
        <Box sx={{ display: "flex", mb: 1 }}>
            <PrivateIconWithFormat icon="AssigneeIcon" />
            <Typography
                component="div"
                sx={{ color: "#9e9e9e", fontSize: 14, m: "auto 0" }}>
                Người Tiếp Nhận:{" "}
                <Typography sx={{
                    display: 'inline-flex',
                    fontSize: 14
                }}
                    color="text.black"
                >
                    {assignees?.map((assignee) => assignee?.employee_name).join(', ')}
                </Typography>
            </Typography>
        </Box>
    )
}

export default Assignee