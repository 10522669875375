import { requestWithToken, requestSimple } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { AUTH_URL, API_PUBLIC_URL } from "@/libs/constants";

class AccountApi {
  async ChangePasssword(values) {
    const response = await requestWithToken(
      `${AUTH_URL}/auth/change_password`,
      "POST",
      values
    );

    return response?.data;
  }

  EmployeePreConfirm = ({ employeeToken }) => {
    return useQueryWithCustomized(
      ["employee.check-token", employeeToken],
      async () => {
        return await requestSimple(
          `${API_PUBLIC_URL}/account/check_token?token=${employeeToken}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error;
          });
      },
      {
        // suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Edit = async ({ token, employee, employee_email }) => {
    const response = await requestSimple(
      `${API_PUBLIC_URL}/account/edit`,
      "PUT",
      { token, employee, employee_email }
    );
    return response?.data;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AccountApi();
