import { requestWithToken, encodeBase64 } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V2_URL, API_V3_URL, API_SERVICE_URL } from "@/libs/constants";

class ServiceApi {
  Statistic = ({ query, searchQuery }) => {
    return useQueryWithCustomized(
      ["services.statistic", query, searchQuery],
      async () => {
        return await requestWithToken(`${API_V3_URL}/services/statistic?${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ""
          }`)
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  List = ({
    sorters = null,
    query = null,
    searchQuery = null,
    page = 1,
    pageSize = 20,
  }) => {
    return useQueryWithCustomized(
      ["services.list", query, page, pageSize, searchQuery, sorters],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/services?${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ""
          }&page=${page}&page_size=${pageSize}${sorters ? `&sorters=${encodeBase64(sorters)}` : ""
          }`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Statement = ({
    query = null,
    searchQuery = null,
    page = 1,
    pageSize = 20,
  }) => {
    return useQueryWithCustomized(
      ["services.statement.list", query, page, pageSize, searchQuery],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/service_usages?page=${page}${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&search=${searchQuery}` : ""
          }&page_size=${pageSize}$`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Detail = ({ id = null }) =>
    useQueryWithCustomized(
      ["services.detail", id],
      async () => {
        return await requestWithToken(`${API_V3_URL}/services/${id}`)
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.service;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  ServiceInformation = ({ serviceId = -1 }) =>
    useQueryWithCustomized(
      ["service.informations", serviceId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/services/${serviceId}/informations`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.service;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );

  ServiceConfigoptions = ({ id = null }) =>
    useQueryWithCustomized(
      ["services.configoptions", id],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/services/${id}/configoptions`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.service;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );

  PricingAndConfigoptions = ({ id = null }) =>
    useQueryWithCustomized(
      ["service.pricing", id],
      async () => {
        return await requestWithToken(`${API_V3_URL}/services/${id}/pricing`)
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.service;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );

  UpdatePricingAndConfigoptions = async ({ serviceId, data }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/pricing`,
      "PUT",
      {
        service: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Update = async ({ serviceId, data }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}`,
      "PUT",
      {
        service: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error || data?.errors) return data;
        return { service: data?.data?.service };
      })
      .catch((error) => {
        return error.response;
      });
  };

  ChangeServiceUUID = async ({ serviceId, serviceUuid = "" }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}`,
      "PUT",
      {
        service: { service_creatable_uuid: serviceUuid },
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  CreateDraftBill = async ({ serviceId = -1 }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/create_draft_invoice`,
      "POST",
      {},
      {},
      {}
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  CreateInvoiceRenew = async (serviceId) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/create_invoice_renew`,
      "POST",
      {},
      {},
      {}
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Addon = async ({ serviceId, data }) => {
    return await requestWithToken(
      `${API_V2_URL}/services/${serviceId}/service_addons`,
      "POST",
      {
        service_addon: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        return {
          service: data?.data?.service,
          status: data?.status,
        };
      })
      .catch((error) => {
        return error.response;
      });
  };
  Register = async (serviceId) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/register`,
      "POST",
      {},
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return {
          status: data?.status,
          service: data?.data,
        };
      })
      .catch((error) => {
        return error.response;
      });
  };

  Cancelled = async (serviceId) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/cancelled`,
      "DELETE",
      {},
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Renew = async (serviceId) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/renew`,
      "POST",
      {},
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  UpdateServiceOption = async ({ data = {}, serviceId }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/service_option`,
      "PUT",
      {
        service_option: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  EnableService = async ({ serviceId = -1 }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/enable`,
      "PUT",
      {
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Confirm = async ({ serviceId = -1 }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/confirmed`,
      "PUT",
      {
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Addons = ({ serviceId = -1 }) =>
    useQueryWithCustomized(
      ["services.addons.list", serviceId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/services/${serviceId}/service_addons`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  UpdateServiceManagementBilling = async ({ serviceId = -1, data = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/service_management_billing`,
      "PUT",
      {
        service_management_billing: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Mailer = ({ serviceId = -1 }) => {
    return useQueryWithCustomized(
      ["service.service-mailer", serviceId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/services/${serviceId}/service_mailers`
        )
          .then(({ data }) => {
            if (data?.errors) return data;
            return data?.data?.service_mailer;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  UpdateMailer = async ({ serviceId = -1, data = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/service_mailers`,
      "PUT",
      {
        service_mailer: data,
      },
      {}
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  CreateAddon = async ({ serviceId = -1, addon = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/service_addons`,
      "POST",
      {
        service_addon: addon,
      },
      {}
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Logger = ({ serviceId = -1 }) => {
    return useQueryWithCustomized(
      ["service.logger", serviceId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/services/${serviceId}/loggers`
        )
          .then(({ data }) => {
            if (data?.errors) return data;
            return data?.data?.loggers || [];
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  SyncServiceInformations = async ({ serviceId = -1 }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/precheck_configoptions`,
      "PUT",
      {},
      {}
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  GetServiceProviders = () => {
    return useQueryWithCustomized(
      ["service.service-providers"],
      async () => {
        return await requestWithToken(`${API_SERVICE_URL}/v2/providers`)
          .then(({ data }) => {
            if (data?.errors) return data;
            return data?.data?.providers;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Stop = async ({ serviceId = -1, data = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/stop`,
      "PUT",
      {
        service: { ...data },
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  ContractReferrer = ({ serviceId = -1 }) => {
    return useQueryWithCustomized(
      ["service.service-contract-referrer", serviceId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/services/${serviceId}/contract`
        )
          .then(({ data }) => {
            if (data?.errors) return data;
            return data?.data?.contract;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  MapWithContract = async ({ serviceId = -1, data = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/create_contract_references`,
      "PUT",
      {
        service: { ...data },
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  GenerateContract = async ({ serviceId = -1, data = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/generate_contract`,
      "POST",
      {
        service: { ...data },
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  UpdateServiceInformations = async ({ serviceId, data = [] }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/informations`,
      "PUT",
      {
        ...data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Usages = ({ serviceId = -1 }) => {
    return useQueryWithCustomized(
      ["service.usages", serviceId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/services/${serviceId}/usages`
        )
          .then(({ data }) => {
            if (data?.errors) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  PackageConfigs = ({ serviceId = -1 }) => {
    return useQueryWithCustomized(
      ["service.packageConfigs", serviceId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/services/${serviceId}/service_package_configs`
        )
          .then(({ data }) => {
            if (data?.errors) return [];
            return data?.data?.service_configs || [];
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  ChangeServiceCommissions = async ({ serviceId, data = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/commissions`,
      "PUT",
      {
        service: data
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  ServiceTag = ({ serviceId = -1 }) => {
    return useQueryWithCustomized(
      ["service.tag", serviceId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/services/${serviceId}/service_tag`
        )
          .then(({ data }) => {
            if (data?.errors) return [];
            return data?.data?.service_tag || '';
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Reject = async ({ serviceId = -1, feedback = '' }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/rejected`,
      "PUT",
      {
        service: {
          feedback
        }
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  RequestAdditional = async ({ serviceId = -1, payload = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/additional`,
      "PUT",
      {
        service: {
          ...payload
        }
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  SummaryUsing = ({ serviceId = -1 }) => {
    return useQueryWithCustomized(
      ["service.summary", serviceId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/services/${serviceId}/usages/summary`,
        )
          .then(({ data }) => {
            if (data?.errors) return [];
            return data?.data || {}
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ServiceApi();
