import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import ProductGroupApi from "@/apis/beta/Product/ProductGroupApi";
import DataGridFilter from "@/components/DataGrid/Filter";
import {
    buildQueryFilterCondition
} from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import useFilters, { Filter } from "@/hooks/useFilters";
import DataGridCustomize from "@components/DataGrid";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useCustomerContext } from "@contexts/customer";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import GridTreeDataGroupingCell from "../shared/GridTreeDataGroupingCell";
import CustomerPOCLibs from "./functions";

const initialState = { page: 0, pageSize: 25 };
const CustomerTrial = () => {
    const location = useLocation();

    const [query, updateQuery] = useFilters(`customers.list.filters`, location);

    const { queryString, onChangeQueryString } = useCustomerContext();
    const debouncedSearchQuery = useDebounce(queryString, 500);

    const filterableCustomer = React.useCallback(() => {
        if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
        const fieldQueries = [
            {
                name: "customers.customer_id",
                operator: "$eq",
                disabled: !parseInt(debouncedSearchQuery),
                value: parseInt(debouncedSearchQuery),
            },
            {
                name: "personal_informations.name",
                operator: "$contains",
                value: debouncedSearchQuery,
            },
            {
                name: "companies.company_name",
                operator: "$contains",
                value: debouncedSearchQuery,
            },
            {
                name: "personal_informations.email",
                operator: "$contains",
                value: debouncedSearchQuery,
            },
        ];
        return buildQueryFilterCondition("$or", 0, fieldQueries);
    }, [debouncedSearchQuery]);

    const [page, setPage] = useState(initialState);

    const { data: { customers, pagination }, isFetching } = CustomerApi.Trial({
        page: page?.page + 1,
        pageSize: page?.pageSize,
        query: Filter.stringify(query),
        searchQuery: filterableCustomer(),
    });

    const { data: productGroups } = ProductGroupApi.List();
    const { data: employees } = EmployeeApi.ListEmployees()

    return (
        <Box>
            <DataGridFilter
                callback={updateQuery}
                filters={{
                    key: `customers.list.filters`,
                    filterables: CustomerPOCLibs?.filterables({ employees, productGroups }),
                    feature: 'CustomerPOC'
                }}
            />
            <DataGridCustomize
                treeData
                groupingColDef={{
                    headerName: 'Tên Khách Hàng',
                    field: 'hierarchy',
                    width: 300,
                    renderCell: (params) => {
                        const { rowNode } = params;
                        return (
                            <GridTreeDataGroupingCell
                                total={rowNode?.children?.length + 1}
                                {...params}
                                rowNode={{
                                    ...rowNode,
                                    groupingKey: !!params?.row?.customer_id && `${params?.row?.customer_id} - ${params?.row?.customer_company_name || params?.row?.customer_name}`
                                }}
                                linked={`/customers/${params?.row?.customer_id}`}
                            />
                        );
                    },
                }}
                getTreeDataPath={(row) => row?.hierarchy}
                columns={[...CustomerPOCLibs.columns]}
                rows={[...CustomerPOCLibs.rows({ customers, employees })?.map((customer, index) => ({ ...customer, id: index }))]}
                componentsProps={{
                    toolbar: {
                        store: ["customers", "trial", "list", "hiddenColumns"].join("."),
                        feature: "customer",
                        inputSearch: !onChangeQueryString
                            ? {}
                            : {
                                placeholder: "Nhập ID, tên khách hàng tìm kiếm...",
                                value: queryString,
                                onChange: (newFilterString) =>
                                    onChangeQueryString(newFilterString),
                            },
                    },
                    pagination: {
                        rowsPerPageOptions: [10, 25, 50, 100],
                        page: page?.page || 0,
                        pageSize: page?.pageSize,
                        onPageChange: (newPage) => setPage(prevState => ({ ...prevState, page: newPage })),
                        onPageSizeChange: (newPageSize) => setPage((prevState) => ({ ...prevState, pageSize: newPageSize }))
                    }
                }}
                loading={isFetching}
                rowCount={pagination?.total}
            />
        </Box>
    );
};

export default withSuspense(withPermission(CustomerTrial, {
    feature: "customer",
    action: "customer-trial",
}), MuiSkeleton["DataGrid"])
