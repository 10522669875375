import withPermission from '@/hocs/withPermission';
import withSuspense from '@/hocs/withSuspense';
import MuiSkeleton from '@components/shared/MuiSkeleton';
import { Box, Typography } from '@mui/material';
import React from 'react';
import ReactMarkdown from 'react-markdown';

const changelog = require('@/CHANGELOG.md');

const List = () => {
  const [content, setContent] = React.useState('');

  React.useEffect(() => {
    fetch(changelog)
      .then((r) => r.text())
      .then((t) => setContent(t));
  }, []);
  return (
    <Box className={'markdown'}>
      <Box sx={{ bgcolor: '#FFFFFF', padding: 3 }}>
        <Typography>
          <ReactMarkdown>{content}</ReactMarkdown>
        </Typography>
      </Box>
    </Box>
  );
};

export default withSuspense(
  withPermission(List, {
    feature: 'changelog',
    action: 'index',
  }),
  MuiSkeleton['DataGrid']
);
