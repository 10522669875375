import React from 'react'
import SubcontractForm from '../shared/Form'
import { Drawer } from '@mui/material'

const Update = ({
    data,
    callback,
    handleClose
}) => {
    return (
        <Drawer open={true} anchor='right'>
            <SubcontractForm
                type="update"
                data={data}
                callback={callback}
                handleClose={handleClose} />
        </Drawer>
    )
}

export default Update