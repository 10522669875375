import { format_number_usage } from '@/functions'
import { PRODUCT_UNITS } from '@/libs/constants'
import { Box, Chip, Typography } from '@mui/material'
import React from 'react'

const ConfigoptionNames = {
    'domains': 'Domains',
    'origin_shield_traffics': 'Origin Shield Traffics',
    'origin_shield_requests': 'Origin Shield Requests',
    'dns_requests': 'DNS Requests',
    'basic_cdn_traffics': 'Basic CDN Traffics',
    'basic_cdn_requests': 'Basic CDN Requests',
}
const ProductConfigoptions = ({ configoptions = [] }) => {
    return (
        <>
            <Typography sx={{ mb: 0.75, fontSize: 14, mt: 1 }} color="primary">Thông Tin Cấu Hình:</Typography>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1.5, flexWrap: 'wrap' }}>
                {configoptions?.map((configoption, index) => {
                    return (
                        <Chip size='small' variant='outlined' key={index}
                            label={<Typography sx={{ fontSize: 13, fontWeight: 'medium' }}>
                                {ConfigoptionNames[configoption?.name]}
                                <Typography sx={{ fontSize: 13 }} color="text.grey" component="span">
                                    {": "}
                                </Typography>
                                <Typography sx={{ fontSize: 13 }} color="text.grey" component="span">
                                    {format_number_usage(configoption?.value)}{" "}
                                </Typography>
                                <Typography sx={{ fontSize: 13 }} color="text.grey" component="span">
                                    {PRODUCT_UNITS.find(unit => unit?.value === configoption?.unit)?.label}
                                </Typography>
                            </Typography>}
                        />
                    )
                })}
            </Box>
        </>
    )
}

export default ProductConfigoptions