import { Box } from '@mui/material'
import { Fragment } from 'react'
import TimeRanges from './TimeRanges'

const AnalyticHeader = ({ times = {}, setTimes }) => {
    return (
        <Fragment>
            <Box sx={{ display: 'flex', mb: 2, justifyContent: 'flex-end', gap: 2, alignItems: 'center' }}>
                <TimeRanges times={times} setTimes={setTimes} />
            </Box>
        </Fragment>
    )
}

export default AnalyticHeader