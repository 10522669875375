import EmployeeApi from '@/apis/beta/Employee/EmployeeApi'
import { buildQueryFilterCondition } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import useDebounce from '@/hooks/useDebounce'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import TransitionGroups from '@components/shared/TranstionGroups'
import { PersonOutlineOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const IgnoreEmployee = () => {
    const { control, watch } = useFormContext()
    const [searchQuery, setSearchQuery] = useState('')
    const debouncedSearchQuery = useDebounce(searchQuery, 400)

    const nestedQueries = useCallback(() => {
        let queryFields = [{
            name: "employees.employee_id",
            operator: "$in",
            disabled: !watch(`scope_metadata.ignores`)?.length,
            value: watch(`scope_metadata.ignores`)?.filter(x => !!parseInt(x))?.join(','),
        }, {
            name: "employees.employee_name",
            operator: "$contains",
            disabled: !debouncedSearchQuery?.trim(),
            value: debouncedSearchQuery
        }]
        return buildQueryFilterCondition('$or', 0, queryFields)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery, watch(`scope_metadata.ignores`)])
    const { data: { employees } } = EmployeeApi.List({
        searchQuery: nestedQueries(),
        pageSize: (watch(`scope_metadata.ignores`)?.length + 10)
    })
    return (
        <>
            <Typography sx={{
                fontSize: 14,
                fontWeight: 'medium',
            }}
                color="text.black"
            >
                Không Bao Gồm Các Nhân Viên Dưới Đây
            </Typography>
            <Typography sx={{ fontSize: 13 }} color="text.grey" component="span">
                Người được chỉ định sẽ
                <Typography sx={{ fontSize: 13, m: 'auto 4px' }} component="span" color="error">
                    không được truy cập
                </Typography>
                tệp thông tin của các nhân viên được chọn dưới đây.
            </Typography>
            <Box aria-label='team.IgnoreEmployee'>
                <Controller
                    name="scope_metadata.ignores"
                    control={control}
                    render={({ field: { value = [], onChange } }) => {
                        const transitionValues = employees?.filter(employee => value?.includes(employee?.employee_id))
                        return (
                            <TransitionGroups
                                values={transitionValues || []}
                                onDelete={(e) => onChange([...value?.filter(x => x !== e?.employee_id)])}
                                label={'employee_name'}
                                icon={<PersonOutlineOutlined fontSize='small' color="primary" />}
                            >
                                <SingleSelectCustomize
                                    clearOnBlur
                                    options={[...employees?.filter(employee => !value?.includes(employee?.employee_id))]}
                                    value={null}
                                    formatCollection={{ label: 'employee_name', value: 'employee_id' }}
                                    compareAttributes={['employee_name', 'employee_email_company']}
                                    input={{
                                        placeholder: "Chọn nhân viên...",
                                        value: searchQuery,
                                        onChange: (event) => setSearchQuery(event.target.value)
                                    }}
                                    onChange={(_, newValue) => {
                                        if (!newValue?.employee_id) return;
                                        onChange([...value?.filter(employeeId => !!parseInt(employeeId)), newValue?.employee_id])
                                        setSearchQuery('')
                                    }}
                                    mode='server'
                                />
                            </TransitionGroups>
                        )
                    }}
                />
            </Box>
        </>
    )
}

export default withSuspense(IgnoreEmployee, MuiSkeleton['TextField'])