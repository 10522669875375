import { AddOutlined } from '@mui/icons-material'
import { Box, Button, Collapse, Typography } from '@mui/material'
import React from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import ProductConfigoptionDetail from "./ProductConfigDetail"
import ProductConfigoption from "./ProductConfigoption"

export const INITITAL_PRIVATE_CDN_CONFIGOPTION = {
    name: "",
    unit: "",
    steps: [{ start: 0, end: 5000, pricing: 0, pricing_minimum: 0 }]
}

const PrivateCDN = () => {
    const { control } = useFormContext()

    const productConfigoptions = useWatch({ control: control, name: "product_configoptions" })

    const { fields, append, remove, update } = useFieldArray({ control: control, name: "product_configoptions" })


    const [state, setState] = React.useState({ open: false, type: undefined, data: undefined })

    const saver = (values) => {
        if (values.index >= 0) {
            update(values.index, values)
        } else {
            append(values)
        }
        return close()
    }

    const close = () => setState({ open: false, data: undefined, type: undefined })
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 1,
            mt: 2
        }}>
            <Box sx={{ display: 'flex', minWidth: 300 }}>
                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                    Cấu Hình Dịch Vụ *
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
                {!state.open && <Collapse in={!state.open}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button size="small"
                            disabled={state.open}
                            variant='outlined'
                            onClick={() => setState({ open: true, type: 'create', data: INITITAL_PRIVATE_CDN_CONFIGOPTION })}
                            sx={{ height: 36 }}
                            startIcon={<AddOutlined />}>Thêm Cấu Hình</Button>
                    </Box>
                </Collapse>}
                {!!state.open && <Collapse in={!!state.open}>
                    <ProductConfigoption
                        close={close}
                        saver={saver}
                        type={state.type}
                        defaultValues={state.data}
                        configoptions={productConfigoptions?.map(({ name }) => name)}
                    />
                </Collapse>}
                {fields && fields.map((field, index) => {
                    return (
                        <ProductConfigoptionDetail remover={() => remove(index)}
                            updater={() => setState({ open: true, data: { ...field, index: index }, type: "update" })}
                            data={field}
                            key={field.id}
                        />
                    )
                })}
            </Box>
        </Box>
    )
}

export default PrivateCDN