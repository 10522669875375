import withPermission from '@/hocs/withPermission'
import { AddOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'

const Update = ({
    submit = true
}) => {
    return (
        <Button size='small'
            type='submit'
            disabled={!!submit}
            startIcon={(!!submit && submit === "submit") && <AddOutlined fontSize='small' sx={{ color: "#777680" }} />}
            variant='contained'>
            Lưu thay đổi
        </Button>
    )
}

export default withPermission(Update, {
    feature: "product",
    action: "product-feature-update",
    type: "Button"
})