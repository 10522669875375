import withPermission from '@/hocs/withPermission'
import { Box, Button, Collapse, Grid, Paper, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import ScopeForm from '../shared/Forms'
import BreadCrumb from '@components/shared/BreadCrumb'
import ScopeButtons from '../shared/Buttons'
import { useMutation, useQueryClient } from 'react-query'
import ScopeApi from '@/apis/beta/Scope/ScopeApi'
import { useNotificationContext } from '@contexts/notification'
import { useNavigate } from 'react-router-dom'
import { queryClientRefetchScope } from '@/functions'

const ScopeCreate = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const { setNotification } = useNotificationContext()
    const { mutateAsync, isLoading } = useMutation(ScopeApi.Create)

    const methods = useForm({
        defaultValues: {
            scope_name: '',
            scope_module: '',
            scope_applied: 'TEAM',
            scope_assignees: [],
            scope_writable: true,
            scope_readable: true,
            scope_metadata: {
                departments: [],
                teammates: [],
                ignores: []
            }
        }
    })

    const onClose = () => navigate(`/scopes`, { replace: true })

    const onSubmit = async (values) => {
        const response = await mutateAsync({ data: values })
        if (!response || response?.errors) {
            return setNotification({ open: true, severity: 'error', message: response?.errors?.[0]?.message })
        }
        setNotification({ open: true, severity: 'success', message: 'Tạo phân quyền truy cập dữ liệu thành công.' })
        queryClientRefetchScope(queryClient, "scope")
        return onClose()
    }
    return (
        <>
            <Box sx={{ display: 'flex', mb: 1.5, flexDirection: 'column', gap: 0.5 }}>
                <Typography sx={{
                    fontSize: { xs: 18, md: 24 },
                    fontWeight: 'medium'
                }}>
                    Phân Quyền Truy Cập Dữ Liệu
                </Typography>
                <BreadCrumb
                    data={[
                        { label: "Quản Lý Phân Quyền", reactLink: "/scopes" },
                        { label: "Tạo mới Phân Quyền Truy Cập Dữ Liệu" }
                    ]}
                />
            </Box>
            <FormProvider {...methods}>
                <Paper sx={{ p: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8} xl={6} sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                            <ScopeForm.ScopeName />
                            <ScopeForm.ScopeModule />
                            <ScopeForm.ScopeApplied />
                            {methods.watch(`scope_applied`) === 'COMAPANY' && <Collapse in={methods.watch(`scope_applied`) === 'COMAPANY'}>
                                <ScopeForm.Company.IgnoreEmployee />
                            </Collapse>}
                            {methods.watch(`scope_applied`) === 'DEPARTMENT' && <Collapse in={methods.watch(`scope_applied`) === 'DEPARTMENT'}>
                                <ScopeForm.Department.Dept />
                                <ScopeForm.Department.IgnoreEmployee />
                            </Collapse>}
                            {methods.watch(`scope_applied`) === 'TEAM' && <Collapse in={methods.watch(`scope_applied`) === 'TEAM'}>
                                <ScopeForm.Team.Teammates />
                            </Collapse>}
                        </Grid>
                        <Grid item xs={12} md={4} xl={6} sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                            <Box sx={{
                                display: 'flex',
                                height: 40,
                                border: '1px solid rgba(0, 0, 0, 0.18)',
                                borderRadius: '4px',
                                alignItems: 'center',
                                flexDirection: { xs: 'column', md: 'row' },
                                gap: 1
                            }}>
                                <Typography sx={{ fontSize: 14, mr: 2.5, pl: 1.25 }} color="text.black">
                                    Quyền truy cập:
                                </Typography>
                                <ScopeForm.ScopeReadable />
                                <ScopeForm.ScopeWritable />
                            </Box>
                            <ScopeForm.ScopeAssignees />
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 2, flexDirection: { xs: 'column', md: 'row' } }}>
                        <Button size='small' variant='outlined' onClick={onClose} disabled={isLoading}>
                            Huỷ Thao Tác
                        </Button>
                        <ScopeButtons.ConfirmCreate onSubmit={methods.handleSubmit(onSubmit)} submitting={isLoading} />
                    </Box>
                </Paper>
            </FormProvider>
        </>
    )
}

export default withPermission(ScopeCreate, { feature: 'scope', action: 'create' })