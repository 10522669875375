import NumericFormatCustom from '@components/NumericFormatCustom'
import { Box, FormControl, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

const ProductBillingConfigures = () => {
    const { control } = useFormContext()

    const { fields } = useFieldArray({
        control: control,
        name: "product_billing_configures"
    })
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 1,
            mt: 2
        }}>
            <Box sx={{ display: 'flex', minWidth: 300 }}>
                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                    Cấu Hình Giá Theo Chu Kỳ *
                </Typography>
            </Box>
            <Box sx={{ width: '100%', maxWidth: '100%', display: 'flex', flexDirection: 'row', gap: 1.5, flexWrap: 'wrap' }}>
                {fields && fields.map((field, index) => {
                    return (
                        <React.Fragment key={field?.id}>
                            <Controller
                                name={`product_billing_configures.[${index}].pricing`}
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <FormControl sx={{ width: { xs: '100%', md: 'calc((100% - 12px * 3) / 4)' } }} fullWidth>
                                            <TextField
                                                label={field?.name}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    sx: {
                                                        textTransform: 'capitalize'
                                                    }
                                                }}
                                                value={value}
                                                onChange={onChange}
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom
                                                }}
                                                size='small' />

                                        </FormControl>
                                    )
                                }} />
                        </React.Fragment>
                    )
                })}
            </Box>
        </Box>
    )
}

export default ProductBillingConfigures