import { create, enforce, skipWhen, test } from "vest";
import isEmail from "validator/lib/isEmail";
import { dayjsCompare, requestWithToken } from "@/functions";
import { API_URL, UNLIMITED } from "@/libs/constants";

enforce.extend({ isEmail });

const VerifyDomainTail = async (productId) => {
	const { domains } = await requestWithToken(`${API_URL}/domain_prices`)
		.then(({ data }) => {
			if (data?.error) return data?.data;
			return {
				domains: data?.data?.domain_prices,
			};
		})
		.catch((error) => {
			return error.response;
		});

	// 133 TLDs
	// VNNIC@
	const extensions = domains?.filter((item) => {
		if (productId === 132) {
			return item?.dp_isVN === true;
		}
		return !item?.dp_isVN;
	});
	return extensions?.map((item) => item.dp_extension);
};

const domainVNNICValidators = (domain) => {
	if (domain.domain_extra_fields.for === "canhan") {
		test(
			"domain.domain_extra_fields.ownerID_Number",
			"Số định danh khách hàng không được để trống.",
			() => {
				enforce(domain?.domain_extra_fields?.ownerID_Number).isNotEmpty();
			}
		);
		test(
			"domain.domain_extra_fields.ownerID_Number",
			"Số định danh khách hàng có chứa ký tự không hợp lệ.",
			() => {
				const rgx = new RegExp(/[0-9]{12}/);
				const isValid = rgx?.test(domain?.domain_extra_fields?.ownerID_Number);
				enforce(isValid).isTruthy();
			}
		);

		test(
			"domain.domain_extra_fields.uiID_Number",
			"Số định danh chủ thể không được để trống.",
			() => {
				enforce(domain.domain_extra_fields?.uiID_Number).isNotEmpty();
			}
		);
		test(
			"domain.domain_extra_fields.uiID_Number",
			"Số định danh chủ thể có chứa ký tự không hợp lệ.",
			() => {
				const rgx = new RegExp(/[0-9]{12}/);
				const isValid = rgx?.test(domain.domain_extra_fields?.uiID_Number);
				enforce(isValid).isTruthy();
			}
		);
		test(
			"domain.domain_extra_fields.uiGender",
			"Vui lòng chọn giới tính khách hàng.",
			() => {
				enforce(domain.domain_extra_fields?.uiGender).isNotEmpty();
			}
		);

		skipWhen(
			(res) =>
				res.hasErrors("domain.domain_extra_fields.uiGender") ||
				res.hasErrors("domain.domain_extra_fields.uiBirthdate"),
			() => {
				test(
					"domain.domain_extra_fields.uiID_Number",
					"Số định danh chủ thể không khớp với thông tin được cung cấp.",
					() => {
						const rgx = new RegExp(/[0-9]{3}[0-3]{1}[0-9]{2}[0-9]{6}/);
						const isValid = rgx?.test(domain.domain_extra_fields?.uiID_Number);
						enforce(isValid).isTruthy();
					}
				);
			}
		);
	}

	if (domain.for === "congty") {
		test(
			"domain.domain_extra_fields.ownerTaxCode",
			"Mã số thuế doanh nghiệp không được để trống.",
			() => {
				enforce(domain.domain_extra_fields?.ownerTaxCode).isNotEmpty();
			}
		);
		test(
			"domain.domain_extra_fields.ownerTaxCode",
			"Mã số thuế doanh nghiệp không hợp lệ.",
			() => {
				const rgx = new RegExp(/[a-zA-Z0-9]/);
				const isValid = rgx?.test(domain.domain_extra_fields?.ownerTaxCode);
				enforce(isValid).isTruthy();
			}
		);

		test(
			"domain.domain_extra_fields.uiTaxCode",
			"Mã số thuế doanh nghiệp không được để trống.",
			() => {
				enforce(domain.domain_extra_fields?.uiTaxCode).isNotEmpty();
			}
		);
		test(
			"domain.domain_extra_fields.uiTaxCode",
			"Mã số thuế doanh nghiệp không hợp lệ.",
			() => {
				const rgx = new RegExp(/[a-zA-Z0-9]/);
				const isValid = rgx?.test(domain.domain_extra_fields?.ownerTaxCode);
				enforce(isValid).isTruthy();
			}
		);
	}

	// validator for customer
	test(
		"domain.domain_extra_fields.ownerName",
		"Tên khách hàng không được để trống.",
		() => {
			enforce(domain.domain_extra_fields?.ownerName).isNotEmpty();
		}
	);
	test(
		"domain.domain_extra_fields.ownerCountry",
		"Vui lòng chọn quốc gia.",
		() => {
			enforce(domain.domain_extra_fields?.ownerCountry).isNotEmpty();
		}
	);
	test(
		"domain.domain_extra_fields.ownerProvince",
		"Vui lòng chọn tỉnh/ thành phố.",
		() => {
			enforce(domain.domain_extra_fields?.ownerProvince).isNotEmpty();
		}
	);
	test(
		"domain.domain_extra_fields.ownerDistrict",
		"Vui lòng chọn quận/ huyện.",
		() => {
			enforce(domain.domain_extra_fields?.ownerDistrict).isNotEmpty();
		}
	);
	test(
		"domain.domain_extra_fields.ownerWard",
		"Vui lòng chọn phường/ xã.",
		() => {
			enforce(domain.domain_extra_fields?.ownerWard).isNotEmpty();
		}
	);
	test(
		"domain.domain_extra_fields.ownerAddress",
		"Địa chỉ chủ thể không được để trống.",
		() => {
			enforce(domain.domain_extra_fields?.ownerAddress).isNotEmpty();
		}
	);
	test(
		"domain.domain_extra_fields.ownerEmail1",
		"Email không được để trống.",
		() => {
			enforce(domain.domain_extra_fields?.ownerEmail1).isNotEmpty();
		}
	);
	test("domain.domain_extra_fields.ownerEmail1", "Email không hợp lệ.", () => {
		enforce(domain.domain_extra_fields?.ownerEmail1).isEmail();
	});

	skipWhen(
		() => domain.domain_extra_fields?.ownerEmail2 === "",
		() => {
			test(
				"domain.domain_extra_fields.ownerEmail2",
				"Email không hợp lệ.",
				() => {
					enforce(domain.domain_extra_fields?.ownerEmail2).isEmail();
				}
			);
		}
	);
	test(
		"domain.domain_extra_fields.ownerEmail2",
		"Email không được để trống.",
		() => {
			enforce(domain.domain_extra_fields?.ownerEmail1).isNotEmpty();
		}
	);
	test(
		"domain.domain_extra_fields.ownerPhone",
		"Số điện thoại không được để trống.",
		() => {
			enforce(domain.domain_extra_fields?.ownerPhone).isNotEmpty();
		}
	);
	test(
		"domain.domain_extra_fields.uiName",
		"Tên chủ thể không được để trống.",
		() => {
			enforce(domain.domain_extra_fields?.uiName).isNotEmpty();
		}
	);
	test(
		"domain.domain_extra_fields.uiCountry",
		"Vui lòng chọn quốc gia.",
		() => {
			enforce(domain.domain_extra_fields?.uiCountry).isNotEmpty();
		}
	);
	test(
		"domain.domain_extra_fields.uiProvince",
		"Vui lòng chọn tỉnh/ thành phố.",
		() => {
			enforce(domain.domain_extra_fields?.uiProvince).isNotEmpty();
		}
	);
	test(
		"domain.domain_extra_fields.uiDistrict",
		"Vui lòng chọn quận/ huyện.",
		() => {
			enforce(domain.domain_extra_fields?.uiDistrict).isNotEmpty();
		}
	);
	test("domain.domain_extra_fields.uiWard", "Vui lòng chọn phường/ xã.", () => {
		enforce(domain.domain_extra_fields?.uiWard).isNotEmpty();
	});
	test(
		"domain.domain_extra_fields.uiAddress",
		"Địa chỉ chủ thể không được để trống.",
		() => {
			enforce(domain.domain_extra_fields?.uiAddress).isNotEmpty();
		}
	);
	test(
		"domain.domain_extra_fields.uiEmail",
		"Email không được để trống.",
		() => {
			enforce(domain.domain_extra_fields?.uiEmail).isNotEmpty();
		}
	);
	test("domain.domain_extra_fields.uiEmail", "Email không hợp lệ.", () => {
		enforce(domain.domain_extra_fields?.uiEmail).isEmail();
	});
	test(
		"domain.domain_extra_fields.uiPhone",
		"Số điện thoại không được để trống.",
		() => {
			enforce(domain.domain_extra_fields?.uiPhone).isNotEmpty();
		}
	);
	// test("domain.domain_extra_fields.uiPhone", "Số điện thoại không hợp lệ.", () => {
	//     const rgx = new RegExp(/[0+]{1}[1-9]{1}[0-9]{9,11}$/)
	//     const isValid = rgx.test(domain.domain_extra_fields.uiPhone)
	//     enforce(isValid).isTruthy()
	// })

	// validator for administrators
	test(
		"domain.domain_extra_fields.adminName",
		"Tên người quản lý không được để trống.",
		() => {
			enforce(domain.domain_extra_fields?.adminName).isNotEmpty();
		}
	);

	test(
		"domain.domain_extra_fields.adminCountry",
		"Vui lòng chọn quốc gia.",
		() => {
			enforce(domain.domain_extra_fields?.adminCountry).isNotEmpty();
		}
	);
	test(
		"domain.domain_extra_fields.adminProvince",
		"Vui lòng chọn tỉnh/ thành phố.",
		() => {
			enforce(domain.domain_extra_fields?.adminProvince).isNotEmpty();
		}
	);
	test(
		"domain.domain_extra_fields.adminDistrict",
		"Vui lòng chọn quận/ huyện.",
		() => {
			enforce(domain.domain_extra_fields?.adminDistrict).isNotEmpty();
		}
	);
	test(
		"domain.domain_extra_fields.adminWard",
		"Vui lòng chọn phường/ xã.",
		() => {
			enforce(domain.domain_extra_fields?.adminWard).isNotEmpty();
		}
	);
	test(
		"domain.domain_extra_fields.adminAddress",
		"Địa chỉ người quản lý không được để trống.",
		() => {
			enforce(domain.domain_extra_fields?.adminAddress).isNotEmpty();
		}
	);
	test(
		"domain.domain_extra_fields.adminEmail",
		"Email người quản lý không được để trống.",
		() => {
			enforce(domain.domain_extra_fields?.adminEmail).isNotEmpty();
		}
	);
	test(
		"domain.domain_extra_fields.adminEmail",
		"Email người quản lý không hợp lệ.",
		() => {
			enforce(domain.domain_extra_fields?.adminEmail).isEmail();
		}
	);
	test(
		"domain.domain_extra_fields.adminPhone",
		"Số điện thoại người quản lý không được để trống.",
		() => {
			enforce(domain.domain_extra_fields?.adminPhone).isNotEmpty();
		}
	);
	// test("domain.domain_extra_fields.adminPhone", "Số điện thoại người quản lý không hợp lệ.", () => {
	//     const rgx = new RegExp(/[0+]{1}[1-9]{1}[0-9]{9,11}$/)
	//     const isValid = rgx.test(domain.domain_extra_fields.adminPhone)
	//     enforce(isValid).isTruthy()
	// })
	test(
		"domain.domain_extra_fields.adminGender",
		"Vui lòng chọn giới tính.",
		() => {
			enforce(domain.domain_extra_fields?.adminGender).isNotEmpty();
		}
	);
	test(
		"domain.domain_extra_fields.adminBirthdate",
		"Vui lòng chọn ngày sinh.",
		() => {
			enforce(domain.domain_extra_fields?.adminBirthdate).isNotEmpty();
		}
	);

	skipWhen(
		(res) => res.hasErrors("domain.domain_extra_fields.adminBirthdate"),
		() => {
			test(
				"domain.domain_extra_fields.adminBirthdate",
				"Độ tuổi không phù hợp [14 - 90] tuổi.",
				() => {
					const current = Date.now();
					const range = dayjsCompare(
						current,
						domain.domain_extra_fields.adminBirthdate,
						"y"
					);

					const isValid = range >= 14 && range <= 90;
					enforce(isValid).isTruthy();
				}
			);
		}
	);
};

const serviceValidator = create((data = {}) => {
	test("product_group_id", "Vui lòng chọn nhóm sản phẩm/ dịch vụ.", () => {
		const isValid =
			data?.product_group_id && data.product_group_id.toString() !== "-1";
		enforce(isValid).isTruthy();
	});

	skipWhen(
		(res) => res.hasErrors(`product_group_id`),
		() => {
			test("product_id", "Vui lòng chọn sản phẩm/ dịch vụ.", () => {
				const isValid =
					data?.product_id && data.product_id?.toString() !== "-1";
				enforce(isValid).isTruthy();
			});
		}
	);

	if (data?.product_template?.match(/(Domain)/)) {
		const domain = data.domain;
		test("domain.domain_value", "Tên miền không được để trống.", () => {
			enforce(domain.domain_value).isNotEmpty();
		});
		skipWhen(
			(res) => res.hasErrors("domain.domain_value"),
			() => {
				test.memo(
					"domain.domain_value",
					"Tên miền không hợp lệ hoặc không được hỗ trợ cho loại dịch vụ này.",
					async () => {
						const endpoint = "." + domain?.domain_value?.split(".")?.at(-1);
						const extensions = await VerifyDomainTail(data?.product_id);

						const isValid = extensions.find((x) => x === endpoint);

						enforce(!!isValid).isTruthy();
					},
					[domain.domain_value]
				);
			}
		);
	}
	if (data?.product_template?.match(/(Domain.PaVietnam)/)) {
		const domain = data.domain;
		domainVNNICValidators(domain);
	}
	if (
		!data?.product_template?.match(/(Domain)/) &&
		!data?.product_template?.match(/(Liquidation)/)
	) {
		serviceManagementBillingValidator(data?.service_management_billing);
	}
});

const serviceManagementBillingValidator = (data) => {
	test(
		"service_management_billing.service_billing_startdate",
		"Vui lòng chọn chu kỳ thanh toán đầu tiên.",
		() => {
			enforce(data?.service_billing_startdate).isNotEmpty();
		}
	);
	test(
		"service_management_billing.service_billing_enddate",
		"Vui lòng chọn ngày kết thúc.",
		() => {
			enforce(data?.service_billing_enddate).isNotEmpty();
		}
	);
	test(
		"service_management_billing.service_billing_enddate",
		"Ngày kết thúc không hợp lệ.",
		() => {
			const valid = dayjsCompare(
				data?.service_billing_enddate?.$d,
				data?.service_billing_startdate?.$d
			);
			enforce(valid > 0).isTruthy();
		}
	);
	test(
		"service_management_billing.service_nextbill_startdate",
		"Vui lòng chọn ngày bắt đầu của Chu Kỳ Gia Hạn.",
		() => {
			enforce(data?.service_nextbill_startdate).isNotEmpty();
		}
	);
	test(
		"service_management_billing.service_nextbill_startdate",
		"Ngày bắt đầu của Chu Kỳ Gia Hạn không hợp lệ.",
		() => {
			const valid = dayjsCompare(
				data?.service_nextbill_startdate?.$d,
				data?.service_billing_enddate?.$d
			);
			enforce(valid === 1).isTruthy();
		}
	);
	test(
		"service_management_billing.service_nextbill_enddate",
		"Vui lòng chọn ngày kết thúc.",
		() => {
			enforce(data?.service_nextbill_enddate).isNotEmpty();
		}
	);
	test(
		"service_management_billing.service_nextbill_enddate",
		"Ngày kết thúc không hợp lệ.",
		() => {
			const valid = dayjsCompare(
				data?.service_nextbill_enddate?.$d,
				data?.service_nextbill_startdate?.$d
			);
			enforce(valid > 0).isTruthy();
		}
	);
};

const serviceTranscodingCreateValidator = create((data = {}) => {
	test("product_group_id", "Vui lòng chọn nhóm sản phẩm/ dịch vụ.", () => {
		enforce(parseInt(data?.product_group_id) > 0).isTruthy();
	});
	test("product_id", "Vui lòng chọn sản phẩm/ dịch vụ.", () => {
		enforce(parseInt(data?.product_id) > 0).isTruthy();
	});

	test("product_payment_type", "Vui lòng chọn hình thức dịch vụ", () => {
		enforce(data?.product_payment_type).isNotEmpty();
	});
	test("product_pricing_configures.unit", "Vui lòng chọn đơn vị tính", () => {
		enforce(data?.product_pricing_configures?.unit).isNotEmpty();
	});
	skipWhen(
		() => data?.product_payment_type === 'postpaid',
		() => {
			test("product_pricing_configures.value", "Vui lòng nhập cấu hình cam kết cho gói cam kết", () => {
				enforce(data?.product_pricing_configures?.value).isNotEmpty();
			});
			test("product_pricing_configures.value", "Vui lòng nhập cấu hình cam kết cho gói cam kết", () => {
				enforce(parseInt(data?.product_pricing_configures?.value) > 0).isTruthy();
			});
			test("product_pricing_configures.term", "Vui lòng chọn số tháng áp dụng cho cấu hình cam kết này.", () => {
				enforce(parseInt(data?.product_pricing_configures?.term) > 0).isTruthy();
			});
			skipWhen(
				() => parseInt(data?.product_pricing_configures?.value) >= UNLIMITED,
				() => {
					test("product_pricing_configures.pricing_apply", "Vui lòng nhập đơn giá bán.", () => {
						enforce(parseInt(data?.product_pricing_configures?.pricing_apply) > 0).isTruthy();
					});
				}
			)
			test("product_pricing_configures.pricing_amount", "Vui lòng nhập giá bán của gói.", () => {
				enforce(parseInt(data?.product_pricing_configures?.pricing_amount) > 0).isTruthy();
			});
			test("product_pricing_configures.prepaid", "Vui lòng chọn hình thức thanh toán.", () => {
				enforce(data?.product_pricing_configures?.prepaid).isNotEmpty();
			});
			test("product_pricing_configures.method", "Vui lòng chọn hình thức đối soát.", () => {
				enforce(data?.product_pricing_configures?.method).isNotEmpty();
			});
		}
	)

	// handle price ranges
	let [start, end] = [0, 0]
	data?.product_pricing_configures?.product_pricing_steps?.forEach((step, index) => {
		test(`product_pricing_configures.product_pricing_steps.[${index}].start`, "Vui nhập số bắt đầu Range này.", () => {
			enforce(step?.start).isNotEmpty();
		});
		test(`product_pricing_configures.product_pricing_steps.[${index}].start`, "Số bắt đầu có vẻ không hợp lệ.", () => {
			enforce(parseInt(step?.start) === start).isTruthy();
		});
		test(`product_pricing_configures.product_pricing_steps.[${index}].end`, "Vui nhập số kết thúc Range này.", () => {
			enforce(step?.end).isNotEmpty();
		});
		test(`product_pricing_configures.product_pricing_steps.[${index}].end`, "Số kết thúc phải lớn hơn số bắt đầu.", () => {
			enforce(parseInt(step?.end) > start).isTruthy();
		});
		start = parseInt(step?.end)
		test(`product_pricing_configures.product_pricing_steps.[${index}].pricing_apply`, "Vui nhập đơn giá.", () => {
			enforce(step?.pricing_apply).isNotEmpty();
		});
		end = parseInt(step?.end)
	})
	const lastRangeIndex = data?.product_pricing_configures?.product_pricing_steps?.length - 1
	test(`product_pricing_configures.product_pricing_steps.[${lastRangeIndex}].end`, "Đang bị thiếu một Range giá cuối cùng, bạn có thể chọn mốc này làm UNLIMITED.", () => {
		enforce(end >= UNLIMITED).isTruthy();
	});
	test(`service_management_billing.statement`, "Vui lòng chọn chu kỳ đối soát dịch vụ.", () => {
		enforce(data?.service_management_billing?.statement).isNotEmpty();
	});
})

const serviceVnisCreateValidator = create((data = {}) => {
	test(`product_name`, "Vui lòng chọn dịch vụ.", () => {
		enforce(data?.product_name).isNotEmpty();
	});
	test(`product_pricing_amount`, "Vui lòng nhập đơn giá bán gói dịch vụ.", () => {
		enforce(data?.product_pricing_amount).isNotEmpty();
	});
	test(`service_management_billing.statement`, "Vui lòng chọn chu kỳ đối soát dịch vụ.", () => {
		enforce(data?.service_management_billing?.statement).isNotEmpty();
	});

	data?.product_configoptions?.forEach((configoption, index) => {
		test(`product_configoptions.[${index}].value`, "Vui lòng nhập cấu hình gói.", () => {
			enforce(configoption?.value).isNotEmpty();
		});
		test(`product_configoptions.[${index}].unit`, "Vui lòng chọn đơn vị tính.", () => {
			enforce(configoption?.unit).isNotEmpty();
		});
	})
})


export default serviceValidator;

export const serviceCreateValidatorCores = {
	"SwiftFederation.Trans": serviceTranscodingCreateValidator,
	"VnisService": serviceVnisCreateValidator,
	"Mlytics": serviceVnisCreateValidator,
	Default: serviceValidator
}