import CustomerSpamApi from "@/apis/beta/Customer/CustomerSpamApi";
import ProductGroupApi from "@/apis/beta/Product/ProductGroupApi";
import BreadCrumb from "@/components/shared/BreadCrumb";
import { useNotificationContext } from "@/contexts/notification";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import { CUSTOMER_SOURCES } from "@/libs/constants";
import { customerSpamValidator } from "@/libs/validators";
import { MenuProps } from "@/styles/common";
import MuiSkeletonDefault from "@components/Skeletons/Default";
import { vestResolver } from "@hookform/resolvers/vest";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const INITIAL_CUSTOMER_SPAM_DATA = {
  customer_spam_name: "",
  customer_spam_email: "",
  customer_spam_phone: "",
  customer_spam_question: "",
  customer_spam_source: 0,
  customer_spam_source_note: 0,
};

const Create = () => {
  const [loading, setLoading] = React.useState(null);

  const navigate = useNavigate();
  const { data: groups } = ProductGroupApi.Products();

  const { setNotification } = useNotificationContext();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    getValues,
  } = useForm({
    resolver: vestResolver(customerSpamValidator),
    defaultValues: INITIAL_CUSTOMER_SPAM_DATA,
  });

  const handleCancel = async () => {
    setLoading("reset");
    clearLoading();
    return reset(INITIAL_CUSTOMER_SPAM_DATA);
  };

  const onSubmit = async (data) => {
    setLoading("submit");
    const response = await CustomerSpamApi.Create(data);
    clearLoading();
    if (!response || response.status !== 201) {
      return setNotification({
        open: true,
        message: "Thêm mới khách hàng spam thất bại.",
      });
    }
    try {
      localStorage.removeItem("customer-spam.data.create");
    } catch (error) {
      console.log(error?.message);
    }
    setNotification({
      open: true,
      message: "Thêm mới khách hàng spam thành công.",
    });
    return navigate("/customer_spams", { replace: true });
  };

  React.useEffect(() => {
    const handleSaveData = () => {
      const data = { ...getValues() };
      return localStorage.setItem(
        "customer-spam.data.create",
        JSON.stringify(data || `{}`)
      );
    };
    window.addEventListener("beforeunload", () => handleSaveData());
    return () =>
      window.removeEventListener("beforeunload", () => handleSaveData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearLoading = () => setLoading(null);

  return (
    <Box>
      <Typography className="page-title">Thêm Khách Hàng</Typography>
      <BreadCrumb
        data={[
          { label: "Danh Sách Khách Hàng Spam", reactLink: "/customer_spams" },
          { label: "Thêm Khách Hàng Spam" },
        ]}
      />
      <Paper
        sx={{ p: "30px" }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <Typography sx={{ m: "auto 0" }}>Tên Khách Hàng Spam</Typography>
          <Controller
            name="customer_spam_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.customer_spam_name}
                helperText={errors?.customer_spam_name?.message}
                InputProps={{
                  placeholder: "Nhập tên khách hàng...",
                  sx: {
                    fontSize: 14,
                    height: 40,
                  },
                }}
                sx={{ maxWidth: "60%" }}
                fullWidth
              />
            )}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <Typography sx={{ m: "auto 0" }}>Email</Typography>
          <Controller
            name="customer_spam_email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.customer_spam_email}
                helperText={errors?.customer_spam_email?.message}
                InputProps={{
                  placeholder: "Nhập email khách hàng...",
                  sx: {
                    fontSize: 14,
                    height: 40,
                  },
                }}
                sx={{ maxWidth: "60%" }}
                fullWidth
              />
            )}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <Typography sx={{ m: "auto 0" }}>Số Điện Thoại</Typography>
          <Controller
            name="customer_spam_phone"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.customer_spam_phone}
                helperText={errors?.customer_spam_phone?.message}
                InputProps={{
                  placeholder: "Nhập số điện thoại khách hàng...",
                  sx: {
                    fontSize: 14,
                    height: 40,
                  },
                }}
                sx={{ maxWidth: "60%" }}
                fullWidth
              />
            )}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <Typography sx={{ m: "auto 0" }}>Nguồn</Typography>
          <Controller
            control={control}
            name="customer_spam_source"
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth sx={{ maxWidth: "60%" }}>
                <Select
                  value={value}
                  onChange={(event) => onChange(event?.target?.value)}
                  MenuProps={MenuProps}
                  sx={{
                    fontSize: 14,
                    height: 40,
                    color: value === 0 ? "#777680" : undefined,
                  }}
                  fullWidth
                >
                  <MenuItem
                    sx={{ height: 38, fontSize: 14, color: "#777680" }}
                    disabled
                    value={0}
                  >
                    --- chọn Nguồn
                  </MenuItem>
                  {CUSTOMER_SOURCES?.map((source) => (
                    <MenuItem
                      sx={{
                        height: 38,
                        fontSize: 14,
                      }}
                      value={source?.value}
                      key={source?.value}
                    >
                      {source?.label}
                    </MenuItem>
                  ))}
                </Select>
                {!!errors?.customer_spam_source && (
                  <FormHelperText
                    sx={{
                      fontSize: 12,
                      color: "#d32f2f",
                    }}
                  >
                    {errors?.customer_spam_source?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <Typography sx={{ m: "auto 0" }}>Nguồn Dịch Vụ</Typography>
          <Controller
            control={control}
            name="customer_spam_source_note"
            render={({ field: { value, onChange } }) => (
              <FormControl
                fullWidth
                sx={{ maxWidth: "60%" }}
                disabled={watch("customer_spam_source") === 0}
              >
                <Select
                  value={value}
                  onChange={(event) => onChange(event?.target?.value)}
                  MenuProps={MenuProps}
                  sx={{
                    fontSize: 14,
                    height: 40,
                    color: value === 0 ? "#777680" : undefined,
                  }}
                  fullWidth
                >
                  <MenuItem
                    sx={{ height: 38, fontSize: 14, color: "#777680" }}
                    disabled
                    value={0}
                  >
                    --- chọn Nguồn Dịch Vụ
                  </MenuItem>
                  {_.sortBy(groups, ["label"], "desc")?.map((group) => (
                    <MenuItem
                      sx={{
                        height: 38,
                        fontSize: 14,
                      }}
                      value={group?.label}
                      key={group?.label}
                    >
                      {group?.label}
                    </MenuItem>
                  ))}
                </Select>
                {!!errors?.customer_spam_source_note && (
                  <FormHelperText
                    sx={{
                      fontSize: 12,
                      color: "#d32f2f",
                    }}
                  >
                    {errors?.customer_spam_source_note?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <Typography sx={{ m: 0 }}>Câu Hỏi/ Ghi Chú</Typography>
          <Controller
            name="customer_spam_question"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth sx={{ width: "60%" }}>
                <TextField
                  multiline
                  {...field}
                  placeholder="Nhập thông tin ghi chú..."
                />
                {!!errors?.customer_spam_question && (
                  <FormHelperText
                    sx={{
                      fontSize: 12,
                      color: "#d32f2f",
                    }}
                  >
                    {errors?.customer_spam_question?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
          }}
        >
          <Button
            variant="outlined"
            disabled={!!loading}
            onClick={handleCancel}
            size="small"
            startIcon={
              loading === "reset" && (
                <CircularProgress size={18} sx={{ color: "#777680" }} />
              )
            }
            sx={{
              height: {
                xs: 36,
                md: 42,
              },
            }}
          >
            Huỷ Thao Tác
          </Button>
          <Button
            variant="contained"
            type="submit"
            size="small"
            disabled={!!loading}
            startIcon={
              loading === "submit" && (
                <CircularProgress size={18} sx={{ color: "#777680" }} />
              )
            }
            sx={{
              height: {
                xs: 36,
                md: 42,
              },
            }}
          >
            Thêm Vào Danh Sách
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default withSuspense(
  withPermission(Create, { feature: "customer-spam", action: "create" }),
  MuiSkeletonDefault
);
