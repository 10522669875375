import ContactApi from "@/apis/beta/Customer/ContactApi";
import { format_date_time, queryClientRefetcher, sleep } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useNotificationContext } from "@contexts/notification";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import BaseContactComponents from "../shared/BaseComponents";
import ContactAssignee from "../shared/ContactAssignee";
import ContactConversation from "../shared/ContactConservation";
import { CONTACT_CHANNEL, INDUSTRIES } from "@/libs/constants";
import { vestResolver } from "@hookform/resolvers/vest";
import customerMarketingValidator from "../Create/validator";

const Detail = ({ contactId, onClose }) => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = React.useState(false);

  const { data } = ContactApi.Detail({
    contactId,
  });

  const methods = useForm({
    resolver: vestResolver(customerMarketingValidator),
    defaultValues: {
      contact_source_email: data?.contact_source_email,
      contact_source_name: data?.contact_source_name,
      contact_source_phone: data?.contact_source_phone,
      contact_source_notes: data?.contact_source_notes,
      contact_source_status: data?.contact_source_status,
      contact_source_website: data?.contact_source_website,
      contact_source_channel: data?.contact_source_channel,
      contact_source_assignee: data?.contact_source_assignee,
      contact_source_interested_services:
        data?.contact_source_interested_services || [],
    },
  });

  const {
    formState: { errors },
  } = methods;

  const CONTACT_SOURCE_FIELDS = [
    {
      key: "contact_source_name",
      label: "Tên *",
      component: "TextField",
      isError: !!errors?.contact_source_name,
      message: errors?.contact_source_name?.message,
    },
    {
      key: "contact_source_position",
      label: "Chức Vụ *",
      component: "TextField",
      isError: !!errors?.contact_source_position,
      message: errors?.contact_source_position?.message,
    },
    {
      key: "contact_source_phone",
      label: "Số Điện Thoại *",
      component: "TextField",
      isError: !!errors?.contact_source_phone,
      message: errors?.contact_source_phone?.message,
    },
    {
      key: "contact_source_email",
      label: "Email *",
      component: "TextField",
      isError: !!errors?.contact_source_email,
      message: errors?.contact_source_email?.message,
    },
    {
      key: "contact_source_company",
      label: "Công Ty",
      component: "TextField",
    },
    {
      key: "contact_source_website",
      label: "Website *",
      component: "TextField",
      isError: !!errors?.contact_source_website,
      message: errors?.contact_source_website?.message,
    },
    {
      key: "contact_source_profile",
      label: "Profile (Hồ Sơ MXH)",
      component: "TextField",
      cols: 12,
    },
    {
      key: "contact_source_industry",
      label: "Ngành *",
      component: "SingleSelect",
      cols: 12,
      props: {
        options: INDUSTRIES,
      },
      isError: !!errors?.contact_source_industry,
      message: errors?.contact_source_industry?.message,
    },
    {
      key: "contact_source_channel",
      label: "Kênh Liên Hệ *",
      component: "SingleSelect",
      cols: 12,
      props: {
        options: CONTACT_CHANNEL,
      },
      isError: !!errors?.contact_source_channel,
      message: errors?.contact_source_channel?.message,
    },
    {
      key: "contact_source_interested_services",
      label: "Giải Pháp *",
      component: "ServiveInteresting",
      cols: 12,
      isError: !!errors?.contact_source_interested_services,
      message: errors?.contact_source_interested_services?.message,
    },
    {
      key: "contact_source_status",
      label: "Phân Loại Khách Hàng *",
      component: "SingleSelect",
      cols: 12,
      props: {
        options: [
          { value: "MATCHED", label: "Đúng đối tượng" },
          { value: "UN-MATCHED", label: "Không đúng đối tượng" },
          { value: "SPAM", label: "SPAM" },
          { value: "", label: "Chưa phân loại" },
        ],
      },
    },
    {
      key: "contact_source_notes",
      label: "Ghi chú",
      component: "TextField",
      props: {
        multiline: true,
      },
      cols: 12,
    },
  ];
  const { handleSubmit } = methods;

  const onSubmit = async (values) => {
    setSubmit(true);
    const response = await ContactApi.Update({
      contactId,
      data: {
        ...values,
      },
    });
    await sleep(500);
    setSubmit(false);
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        severity: "error",
        message:
          response?.errors?.[0]?.message ||
          "Cập nhật thông tin contact thất bại.",
      });
    }

    queryClientRefetcher(queryClient, [
      "contact.list",
      "contact.detail",
      "contacts.statistic",
      "contact.conversations",
    ]);
    return setNotification({
      open: true,
      severity: "success",
      message: "Cập nhật thông tin contact thành công.",
    });
  };

  const [livechat, setOpenLivechat] = React.useState(false);

  return (
    <FormProvider {...methods}>
      <Paper
        sx={{
          height: "100vh",
          p: 3,
          width: "100%",
          maxWidth: { xs: "100%", md: 700 },
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography
          sx={{
            fontWeight: "medium",
            textTransform: "uppercase",
            mb: 1,
          }}
        >
          ISC / {data?.contact_source_object_id} ({data?.contact_source_type})
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mb: 2,
          }}
        >
          <Chip
            size="small"
            variant="outlined"
            label={
              <Typography
                sx={{ fontSize: 13, color: "#777680" }}
                component="div"
              >
                Cập nhật lần cuối:{" "}
                {format_date_time(data?.contact_source_updated_at)}
              </Typography>
            }
            sx={{ m: "auto 0" }}
          />
        </Box>
        <Grid container spacing={2}>
          {CONTACT_SOURCE_FIELDS?.map((option, index) => {
            const Component = BaseContactComponents[option?.component];
            if (!Component) return null;

            return (
              <Grid item xs={12} md={option?.cols || 6} key={index}>
                <Controller
                  name={option.key}
                  control={methods.control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Component
                        {...field}
                        label={option?.label}
                        {...option?.props}
                      />
                      {option?.isError && (
                        <ErrorHelperText message={option?.message} />
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <ContactAssignee />
          </Grid>
          {["LIVE_CHAT", "FB_MESSENGER"].includes(
            data?.contact_source_channel
          ) && (
              <Grid item xs={12}>
                <Box
                  sx={{ p: 1.5, border: "1px solid #333", borderRadius: "4px" }}
                >
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography
                      sx={{
                        fontWeight: "medium",
                        fontSize: 14,
                        textDecoration: "underline",
                        m: "auto 0",
                      }}
                    >
                      LIVE CHAT/ MESSENGERS
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() =>
                        setOpenLivechat((prevLivechat) => !prevLivechat)
                      }
                      sx={{
                        m: "0 0 auto auto",
                        transition: "all 0.5s ease-in-out",
                        transform: livechat && "rotate(-180deg)",
                      }}
                    >
                      <KeyboardArrowDownOutlined fontSize="small" />
                    </IconButton>
                  </Box>
                  <Collapse
                    in={
                      ["LIVE_CHAT", "FB_MESSENGER"].includes(
                        data?.contact_source_channel
                      ) && livechat
                    }
                  >
                    <ContactConversation
                      conversationId={data?.contact_source_object_id}
                      assignee={data?.contact_source_assignee}
                    />
                  </Collapse>
                </Box>
              </Grid>
            )}
        </Grid>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 1.5, md: 2 },
          }}
        >
          <Button
            size="small"
            variant="outlined"
            onClick={onClose}
            disabled={submit}
          >
            Huỷ Thao Tác
          </Button>
          <Button
            size="small"
            disabled={submit}
            startIcon={
              submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
            }
            variant="contained"
            type="submit"
          >
            Lưu Thay Đổi
          </Button>
        </Box>
      </Paper>
    </FormProvider>
  );
};

export default withSuspense(
  withPermission(Detail, {
    feature: "contact",
    action: "index",
  }),
  MuiSkeleton["LinearProgress"]
);
