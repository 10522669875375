import { CircularProgress, Paper } from "@mui/material";
import React from "react";

const PaperLoading = () => {
  return (
    <Paper sx={{ p: 4 }}>
      <CircularProgress />
    </Paper>
  );
};

export default PaperLoading;
