import withPermission from "@/hocs/withPermission";
import AddOutlined from "@mui/icons-material/AddOutlined";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import InvoiceNoteCreate from "../../../Note/Create";
import { useParams } from "react-router-dom";
const CreateNote = () => {
  const { invoice_id: invoiceId } = useParams();

  const [openModal, setOpenModal] = React.useState(false);

  return (
    <React.Fragment>
      {openModal && (
        <Dialog
          open={true}
          fullWidth
          maxWidth="md"
          onClose={() => setOpenModal(false)}
        >
          <DialogTitle>Thêm Ghi Chú</DialogTitle>
          <DialogContent>
            <InvoiceNoteCreate
              invoice_id={invoiceId}
              handleClose={() => setOpenModal(false)}
            />
          </DialogContent>
        </Dialog>
      )}
      <Button
        size="small"
        fullWidth
        variant="contained"
        disabled={openModal}
        startIcon={<AddOutlined />}
        onClick={() => setOpenModal(true)}
      >
        Thêm Ghi Chú
      </Button>
    </React.Fragment>
  );
};

export default withPermission(CreateNote, {
  feature: "invoice",
  action: "invoice-note-create",
  type: "button",
});
