import { PRODUCT_UNITS, UNLIMITED } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import FORM_BASE from '@components/shared/FormCustomized/form'
import { FormControl, Grid, TextField, Typography } from '@mui/material'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

const Translates = {
    'domains': 'Domains',
    'origin_shield_traffics': 'Origin Shield Traffics',
    'origin_shield_requests': 'Origin Shield Requests',
    'dns_requests': 'DNS Requests',
    'basic_cdn_traffics': 'Basic CDN Traffics',
    'basic_cdn_requests': 'Basic CDN Requests',
}

const ProductConfigoptions = () => {

    const { control, watch } = useFormContext()
    const { fields } = useFieldArray({
        control, name: 'product_configoptions'
    })
    return (
        <>
            <Typography sx={{ fontSize: 14, fontWeight: 'medium', pt: 1 }} color="primary">
                Thông Tin Cấu Hình
            </Typography>
            {fields?.map((field, index) => {
                return (
                    <Grid container key={field.id} sx={{ mt: 1.5 }} spacing={2}>
                        <Grid item xs={12} md={3} xl={3}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Tên Cấu Hình *"
                                    disabled
                                    size='small'
                                    value={Translates[field?.name]}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3} xl={3}>
                            <Controller
                                control={control}
                                name={`product_configoptions.[${index}].value`}
                                render={({ field: { value, onChange }, fieldState: { error } }) => {
                                    return (
                                        <FormControl fullWidth>
                                            <FORM_BASE.InputUnlimitCustomized
                                                value={value}
                                                onChange={onChange}
                                                label="Thông Số Gói *"
                                                placeholder="Nhập cấu hình gói..."
                                            />
                                            {!!error && <ErrorHelperText message={error?.message} />}
                                        </FormControl>
                                    )
                                }} />
                        </Grid>
                        <Grid item xs={12} md={3} xl={3}>
                            <Controller
                                control={control}
                                name={`product_configoptions.[${index}].unit`}
                                render={({ field: { value, onChange }, fieldState: { error } }) => {
                                    return (
                                        <FormControl fullWidth>
                                            <FORM_BASE.SingleSelect
                                                options={PRODUCT_UNITS}
                                                value={value}
                                                onChange={onChange}
                                                input={{
                                                    label: "Đơn Vị Tính *",
                                                    placeholder: "Chọn đơn vị tính...",
                                                }} />
                                            {!!error && <ErrorHelperText message={error?.message} />}

                                        </FormControl>
                                    )
                                }} />
                        </Grid>
                        <Grid item xs={12} md={3} xl={3}>
                            <Controller
                                control={control}
                                name={`product_configoptions.[${index}].service_pricing_configure.product_pricing_steps.[0].pricing_apply`}
                                render={({ field: { value, onChange }, fieldState: { error } }) => {
                                    return (
                                        <FormControl fullWidth>
                                            <FORM_BASE.TextField
                                                value={value}
                                                disabled={parseInt(watch(`product_configoptions.[${index}].value`)) >= UNLIMITED}
                                                onChange={onChange}
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom
                                                }}
                                                label="Đơn Giá Đối Soát Vượt"
                                                placeholder="Nhập đơn giá đối ..."
                                            />
                                            {!!error && <ErrorHelperText message={error?.message} />}
                                        </FormControl>
                                    )
                                }} />
                        </Grid>
                    </Grid>
                )
            })}
        </>
    )
}

export default ProductConfigoptions