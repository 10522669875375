import ContactApi from "@/apis/beta/Customer/ContactApi";
import { queryClientRefetcher } from "@/functions";
import ModalConfirm from "@components/shared/ModalConfirm";
import { Typography } from "@mui/material";
import React from "react";
import { useQueryClient } from "react-query";

const ConvertCustomer = ({ data, handleClose }) => {
  const queryClient = useQueryClient();
  const onSubmit = async () => {
    const response = await ContactApi.Verified({
      contactId: data?.contact_source_id,
    });
    if (!response || response?.errors) {
      return {
        status: 400,
        message:
          response?.errors?.[0]?.message ||
          "Thao tác chuyển đổi khách hàng không thành công",
      };
    }
    return {
      status: 200,
      message: "Thao tác chuyển đổi khách hàng thành công",
    };
  };

  const callback = () => {
    queryClientRefetcher(queryClient, [
      "contact.list",
      "contact.detail",
      "contacts.statistic",
    ]);
  };
  return (
    <ModalConfirm
      onlyClose={data?.contact_source_status !== "MATCHED"}
      callback={callback}
      handleClose={handleClose}
      handleSubmit={onSubmit}
      dialog={{
        title: `Convert To Customer`,
        content: (
          <Typography component="div" sx={{ fontSize: 14 }}>
            Contact source <b>{data?.contact_source_id}</b> status is{" "}
            <b>{data?.contact_source_status}</b>. Only verified source can be
            converted to customer, please check and try again.
          </Typography>
        ),
      }}
    />
  );
};

export default ConvertCustomer;
