import { handleRedirectOpenLink } from "@/functions";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

const CreateServicePOC = ({ onClose, customer }) => {
  const handleClick = () => {
    onClose();
    return handleRedirectOpenLink(`/customers/${customer?.customer_id}/poc?contact_id=${customer?.contact_id}`);
  };
  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogTitle>Khởi tạo dịch vụ POC cho khách hàng</DialogTitle>
      <DialogActions>
        <Button size="small" variant="contained" onClick={handleClick}>
          Khởi Tạo POC
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateServicePOC;
