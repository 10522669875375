import { Box, Button, CircularProgress } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import EmployeeAccountInformation from "./EmployeeAccount";
import EmployeeBankingInformation from "./EmployeeBankingInformation";
import EmployeeExperienceInformation from "./EmployeeExpInformation";
import EmployeeIdentity from "./EmployeeIdentity";
import EmployeeInformation from "./EmployeeInformation";
import EmployeeInsuranceInformation from "./EmployeeInsuranceInformation";
import EmployeeNativeLand from "./EmployeeNativeLand";
import EmployeePermanentAddress from "./EmployeePermanentAddress";

import AccountApi from "@/apis/beta/Employee/AccountApi";
import PaperLoading from "@features/Module/WarehouseNew/Shared/Loading/PaperLoading";
import { GENDERS } from "@/libs/constants";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import _ from "lodash";
import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import NotFound from "@features/App/NotFound";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";

const SubmitInformation = () => {
  const [searchParams] = useSearchParams();
  const { employee_id } = useParams();
  const navigate = useNavigate();

  const { data, isFetching: getInfoTokenLoading } =
    AccountApi.EmployeePreConfirm({
      employeeToken: searchParams.get("token"),
    });

  const { data: info, isLoading: infoLoading } =
    EmployeeApi.Detail(employee_id)

  const {
    mutateAsync: EditEmployeeInfo,
    isLoading: EditEmployeeInfoLoading,
    notify,
  } = useMutationWithNotification(AccountApi.Edit);

  const {
    mutateAsync: UpdateEmployeeInfo,
    isLoading: UpdateEmployeeInfoLoading,
  } = useMutationWithNotification(EmployeeApi.Update);

  const isFetching =
    EditEmployeeInfoLoading ||
    UpdateEmployeeInfoLoading;

  const methods = useForm({
    defaultValues: info
      ? {
        ...info?.employee_information,
        employee_name: info?.employee_name,
        employee_email: info?.employee_email,
        employee_email_company: info?.employee_email_company,
        employee_code: info?.employee_code,
        employee_position: info?.position_id,
        employee_department: info?.department_id
      }
      : {
        employee_name: "",
        employee_phone_number: "",
        employee_birthday: moment("01/01/2000"),
        employee_birthplace: "",
        employee_sex: GENDERS?.[0]?.value,
        employee_marriage: "alone",
        employee_email: "",
        employee_idno: "",
        employee_idno_issued_on: moment("01/01/2018"),
        employee_idno_issued_by:
          "Cục Cảnh sát quản lý hành chính về trật tự xã hội.",
        employee_nationality: "VN",
        employee_religion: "Không",
        employee_nation: "Kinh",

        employee_country: "VN",
        employee_permanent_address_city: "SG",
        employee_permanent_address_district: "",
        employee_permanent_address_ward: "",
        employee_permanent_address: "",

        employee_tin: "",
        employee_account_bank_number: "",
        employee_bank_name: "techcombank",
        employee_bank_branch: "",

        employee_health_insurance: "",
        employee_social_insurance: "",
        employee_signup_hospital: "",

        employee_graduated: "",
        employee_major: "",
        employee_experience: "",

        employee_email_company: "",
        employee_code: "",

        employee_contact_homie: [],
      },
  });

  const onSubmit = async (values) => {
    let response = null;

    const employee_information = _.omit(values, [
      "employee_email_company",
      "employee_name",
      "employee_email",
      "employee_temporary_address_open",
      "employee_code",
      "employee_contact_homie",
      "employee_id",
      "employee_information_id",
      "employee_position",
      "employee_department",
      "employee_information_created_at",
      "employee_information_updated_at",
    ]);

    if (data && !employee_id) {
      response = await EditEmployeeInfo({
        token: searchParams.get("token"),
        employee: employee_information,
        employee_email: values?.employee_email,
        // employee_contact_homie: values?.employee_contact_homie,
      });
    } else {
      response = await UpdateEmployeeInfo({
        employee_information,
        employee: {
          employee_email: values?.employee_email,
          position_id: values?.employee_position,
          department_id: values?.employee_department,
          employee_birthday: values?.employee_birthday,
          employee_phone_number: values?.employee_phone_number,
          employee_domicile: values?.employee_domicile,
          employee_nationality: values?.employee_nationality,
          employee_country: values?.employee_country,
          employee_nation: values?.employee_nation,
          employee_religion: values?.employee_religion,
          employee_temporary_address: values?.employee_temporary_address,
          employee_temporary_address_city: values?.employee_temporary_address_city,
          employee_temporary_address_district: values?.employee_temporary_address_district,
          employee_temporary_address_ward: values?.employee_temporary_address_ward,
          employee_permanent_address: values?.employee_permanent_address,
          employee_permanent_address_city: values?.employee_permanent_address_city,
          employee_permanent_address_district: values?.employee_permanent_address_district,
          employee_permanent_address_ward: values?.employee_permanent_address_ward
        },
        employee_id
      });
    }

    if (!response?.errors) {
      notify("success", "Cập nhật thông tin thành công");
      if (employee_id) navigate("/employees");
      else navigate('/')
    }
  };

  useEffect(() => {
    methods.setValue("employee_name", data?.employee?.employee_name);
    methods.setValue(
      "employee_email_company",
      data?.employee?.employee_email_company
    );
    methods.setValue("employee_code", data?.employee?.employee_code);
  }, [data]);

  if (infoLoading || getInfoTokenLoading)
    return (
      <Box p={2}>
        <PaperLoading />
      </Box>
    );

  if (employee_id && !info) return <NotFound />

  if (!data && !employee_id) return <NotFound />

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={methods.handleSubmit(onSubmit)}
        sx={{
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
          }}
        >
          <Box
            sx={{
              minWidth: {
                xs: "100%",
                md: "calc((100% - 16px) / 2)",
              },
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <EmployeeInformation />
            <EmployeeIdentity />
            <EmployeeNativeLand />
            <EmployeePermanentAddress />
          </Box>
          <Box
            sx={{
              minWidth: {
                xs: "100%",
                md: "calc((100% - 16px) / 2)",
              },
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {/* <EmployeeContactInformation /> */}
            <EmployeeExperienceInformation />
            <EmployeeInsuranceInformation />
            <EmployeeBankingInformation />
            <EmployeeAccountInformation />
          </Box>
        </Box>
        <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="small"
            variant="contained"
            sx={{
              minWidth: 150,
              height: { xs: 40, md: 42 },
            }}
            type="submit"
            disabled={isFetching}
            startIcon={isFetching && <CircularProgress size={14} />}
          >
            Xác nhận thông tin
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default withSuspense(SubmitInformation, MuiSkeleton["GridInformation"]);

