import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { format_date_short, queryClientRefetchScope } from "@/functions";
import PopperAction from "@components/PopperAction";
import ModalConfirm from "@components/shared/ModalConfirm";
import MoreVertOutlined from "@mui/icons-material/MoreVertOutlined";
import { Box, IconButton, Popper } from "@mui/material";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import ServiceReject from "./Reject";
import ServiceAdditional from "./Additional";
import dayjs from "dayjs";

const Actions = ({ data }) => {
  const queryClient = useQueryClient();
  const { mutateAsync: verifiedService } = useMutation(ServiceApi.Confirm);
  const { mutateAsync: rejectedService } = useMutation(ServiceApi.Reject);
  const { mutateAsync: requestAddServiceInformation } = useMutation(ServiceApi.RequestAdditional);

  const [state, setState] = useState({
    reason: '',
    deadline: dayjs().set('hour', 17).set('minute', 30)
  })
  const ACTIONS = [
    {
      order: 1,
      icon: "Verified",
      name: "confirm",
      label: "Confirm",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "service-confirm",
          data: row,
        }),
    }, {
      order: 2,
      icon: "Add",
      name: "additional",
      label: "Bổ Sung TT",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "service-additional",
          data: row,
        }),
    }, {
      order: 3,
      icon: "Delete",
      name: "reject",
      label: "Từ Chối",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "service-reject",
          data: row,
        }),
    },
  ];
  const [popper, setPopper] = useState({
    anchorEl: undefined,
    data: undefined,
    actions: [],
  });
  const [action, setAction] = useState({
    open: false,
    name: undefined,
    data: undefined,
  });
  const onClosePopper = () =>
    setPopper((prev) => ({
      ...prev,
      anchorEl: undefined,
      data: undefined,
      actions: [],
    }));

  const onCloseAction = () =>
    setAction({ open: false, name: undefined, data: undefined });

  const callback = () => queryClientRefetchScope(queryClient, "service");

  const submit = async (requestMethod = '') => {
    let response = undefined
    switch (requestMethod) {
      case "service-confirm":
        response = await verifiedService({ serviceId: data?.service_id })
        break;
      case "service-reject":
        response = await rejectedService({ serviceId: data?.service_id, feedback: state?.feedback })
        break;
      case "service-additional":
        response = await requestAddServiceInformation({
          serviceId: data?.service_id, payload: {
            ...state,
            deadline: format_date_short(state?.deadline?.$d || state?.deadline)
          }
        })
        break;
      default: break;
    }
    return responseModal(requestMethod, response)
  }

  const responseModal = (requestMethod = '', payload) => {
    if (!payload || payload?.errors) {
      return {
        status: 400,
        message:
          payload?.errors?.[0]?.message || "Thao tác thất bại.",
      }
    }
    return {
      status: 200,
      message: "Thao tác dịch vụ thành công.",
    }
  }

  const renderComponents = (type) => {
    let payload = {
      handleSubmit: () => submit(type),
      handleClose: onClose,
      callback
    }
    switch (type) {
      case "service-confirm":
        payload = {
          ...payload,
          dialog: {
            title: "Xác nhận kích hoạt dịch vụ",
            content: `Dịch vụ ${data?.service_name} được tạo bởi ${data?.service_creator?.employee_name || 'SALE DEPT'} sẽ được chuyển sang trạng thái kích hoạt.`,
          }
        }
        break;
      case "service-additional":
        payload = {
          ...payload,
          dialog: {
            title: "Yêu cầu bổ sung thông tin khởi tạo dịch vụ",
            content: `Người tạo dịch vụ ${data?.service_creator?.employee_name || 'SALE DEPT'} tạo sẽ được gửi một email thông báo cập nhật/ bổ sung thông tin để khởi tạo dịch vụ.`,
          },
          component: <ServiceAdditional state={state} setState={setState} />
        }
        break;
      case "service-reject":
        payload = {
          ...payload,
          dialog: {
            title: "Từ chối kích hoạt dịch vụ",
            content: `Dịch vụ ${data?.service_name} được tạo bởi ${data?.service_creator?.employee_name || 'SALE DEPT'} sẽ bị từ chối.`,
          },
          component: <ServiceReject state={state} setState={setState} />
        }
        break;
      default: return null;
    }
    return <ModalConfirm {...payload} />
  };

  const onClose = () => {
    onCloseAction()
    return setState({ reason: '', deadline: dayjs() })
  }

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      {action?.open && renderComponents(action?.name)}
      {popper?.anchorEl !== undefined && (
        <Popper
          anchorEl={popper?.anchorEl}
          sx={{
            zIndex: 99,
          }}
          open={Boolean(popper.anchorEl)}
          onMouseLeave={onClosePopper}
        >
          <PopperAction {...popper} handleClose={onCloseAction} />
        </Popper>
      )}
      <IconButton
        size="small"
        onClick={(event) => {
          setPopper((prev) => ({
            ...prev,
            anchorEl: event?.currentTarget,
            data,
            actions: ACTIONS,
          }));
        }}
      >
        <MoreVertOutlined fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default Actions;
