import ServiceApi from "@/apis/beta/Service/ServiceApi";
import ServiceBillingApi from "@/apis/beta/Service/ServiceBillingApi";
import {
  format_bytes,
  format_date_short,
  format_numeral_price,
} from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Paper, Typography } from "@mui/material";
import { Fragment } from "react";
import ContentDeliveryNetwork from "./ContentDeliveryNetwork";
import OtherService from "./Other";

const ConversantService = ({ serviceId, service }) => {
  const { data } = ServiceApi.Usages({ serviceId });

  const { data: billing } = ServiceBillingApi.Detail({ serviceId });

  return (
    <Fragment>
      <Box sx={{ display: "flex" }}>
        <Paper sx={{ width: { xs: "100%", md: "calc(100% / 2)" }, p: 3 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Typography sx={{ fontSize: 14 }} color="text.grey">
              ({format_date_short(billing?.service_control_startdate)} -{" "}
              {format_date_short(billing?.service_control_enddate)})
            </Typography>
          </Box>
          <Typography
            sx={{ fontSize: { xs: 16, md: 18 }, fontWeight: "medium" }}
          >
            Thông Số Sử Dụng
          </Typography>
          {!!data?.usages &&
            Object.keys(data?.usages)?.map((item, index) => {
              return (
                <Typography
                  sx={{
                    mb: 0.5,
                    fontSize: 14,
                    textTransform: "capitalize",
                  }}
                  color="text.grey"
                  key={index}
                >
                  {item}
                  {": "}
                  <Typography
                    sx={{ fontSize: 14, display: "inline-block" }}
                    color="text.black"
                  >
                    {format_numeral_price(
                      format_bytes(data?.usages[item], 1000, "GB")
                    )}{" "}
                    {"GB"}
                  </Typography>
                </Typography>
              );
            })}
        </Paper>
      </Box>
      {service?.service_product_id === 305 && (
        <ContentDeliveryNetwork
          serviceId={serviceId}
          usages={data?.usages || {}}
        />
      )}
      {service?.service_product_id !== 305 && (
        <OtherService
          service={service}
          serviceId={serviceId}
          usages={data?.usages || {}}
        />
      )}
    </Fragment>
  );
};

export default withSuspense(ConversantService, MuiSkeleton["DataGrid"]);
