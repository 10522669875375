import SubcontractApi from "@/apis/beta/Contract/SubcontractApi";
import FileApi from "@/apis/shared/File/FileApi";
import BorderLinearProgress from "@/components/BorderLinearProgress";
import ErrorHelperText from "@/components/shared/ErrorHelperText";
import InputFileCustomize from "@/components/shared/InputFileCustomize";
import SingleDocument from "@/components/shared/SingleDocument";
import { useNotificationContext } from "@/contexts/notification";
import { sleep } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import { subcontractValidator } from "@/libs/validators";
import { vestResolver } from "@hookform/resolvers/vest";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";

const Form = ({ data, callback, handleClose, type = "create" }) => {
  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [uploadFile, handleUploadFile] = React.useState({
    percen: 0,
    isUploading: false,
    isCompleted: false,
  });

  const { data: subcontractCodeNumber } = SubcontractApi.SubcontractCodeNumber({
    contractId: data?.contract_id,
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: vestResolver((props) =>
      subcontractValidator({
        ...props,
        document: file,
      })
    ),
    defaultValues: {
      subcontract_code: data?.subcontract_code?.split("-")[0] + "-",
      subcontract_code_id:
        type === "create"
          ? subcontractCodeNumber?.subcontract_code
          : data?.subcontract_code?.split("-")[-1],
      subcontract_name: "",
      subcontract_description: "",
      subcontract_status: 1,
    },
  });
  React.useEffect(() => {
    if (type === "create") return;
    const attachment = data?.subcontract_attachments?.[0];
    setFile({
      name: attachment?.attachment_file_name,
      type: attachment?.attachment_file_type,
      size: attachment?.attachment_file_size,
      url: attachment?.attachment_file_url,
    });
    const dataReset = {
      subcontract_code: data?.subcontract_code?.split("-")[0] + "-",
      subcontract_code_id: data?.subcontract_code?.split("-")[1],
      subcontract_name: data?.subcontract_name,
      subcontract_description: data?.subcontract_description,
      subcontract_status: data?.subcontract_status,
    };
    reset({ ...dataReset });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, type]);

  const fileHandler = async (files) => {
    const extension = files[0].name.split(".")[1]?.toLowerCase();
    if (extension === undefined) {
      return setNotification({
        open: true,
        severity: "error",
        message: "Thể loại tập tin không được hỗ trợ",
      });
    }
    return setFile(files[0]);
  };

  const onSubmit = async (values) => {
    setSubmit(true);
    let form = {
      subcontract_code: [
        values?.subcontract_code,
        values?.subcontract_code_id,
      ].join(""),
      subcontract_name: values?.subcontract_name,
      subcontract_description: values?.subcontract_description,
      subcontract_status: values?.subcontract_status,
    };
    if (!!file && !!file?.name) {
      const prefixPathname = "subcontracts/documents";
      const token = await FileApi.GetPresignedTokenAttachment({
        filename: {
          name: file?.name,
          folder: prefixPathname,
        },
      });
      if (!token.token || token.status !== 200) {
        setSubmit(false);
        return setNotification({
          open: true,
          message: "Lấy thông tin xử lý file thất bại",
          severity: "error",
        });
      }
      handleUploadFile((prevState) => ({
        ...prevState,
        isLoading: true,
        percen: 0,
      }));
      const uploaded = await FileApi.UploadAttachmentToStorage({
        token: token?.token,
        file: file,
        handleUploadFile: handleUploadFile,
      });
      handleUploadFile((prevState) => ({
        ...prevState,
        isLoading: false,
        isCompleted: true,
      }));
      if (!uploaded) {
        setSubmit(false);
        return setNotification({
          open: true,
          message: "Tải dữ liệu thất bại",
          severity: "error",
        });
      }
      form = {
        ...form,
        subcontract_document: Array.from([
          {
            name: file?.name,
            type: file?.type,
            size: file?.size,
            url: ["pub", prefixPathname, token?.filename].join("/"),
          },
        ]),
      };
    }
    const response =
      type === "create"
        ? await SubcontractApi.Create({
          contractId: data?.contract_id,
          data: form,
        })
        : await SubcontractApi.Update({
          contractId: data?.contract_id,
          subcontractId: data?.subcontract_id,
          data: form,
        });
    await sleep(500);
    if (!response || ![200, 201].includes(response.status)) {
      return setNotification({
        open: true,
        message: "Tạo phụ lục hợp đồng thất bại.",
        severity: "error",
      });
    }
    handleClose();
    try {
      callback();
    } catch (e) {
      console.log("error when callback");
    }
    return setNotification({
      open: true,
      message: "Tạo phụ lục hợp đồng thành công.",
      severity: "success",
    });
  };
  return (
    <Box
      sx={{ height: "100vh", minWidth: 350, p: 3 }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography sx={{
        mb: 2,
        fontSize: 18,
        fontWeight: 'medium'
      }}>
        {type === "create" ? "Thêm" : "Chỉnh Sửa"} Phụ Lục Hợp Đồng
      </Typography>
      <Typography>Số Phụ Lục</Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
          <Controller
            control={control}
            name="subcontract_code"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.subcontract_code}
                helperText={errors?.subcontract_code?.message}
                disabled
                placeholder="Nhập số phụ lục"
                size="small"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
          <Controller
            control={control}
            name="subcontract_code_id"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.subcontract_code_id}
                helperText={errors?.subcontract_code_id?.message}
                placeholder="Nhập số phụ lục"
                size="small"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <Typography>Tên Phụ Lục Hợp Đồng</Typography>
      <Controller
        control={control}
        name="subcontract_name"
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors?.subcontract_name}
            helperText={errors?.subcontract_name?.message}
            placeholder="Nhập tên phụ lục hợp đồng..."
            size="small"
            fullWidth
          />
        )}
      />
      <Typography sx={{ mt: 2 }}>Tệp Tin Đính Kèm</Typography>
      <InputFileCustomize
        fileHandler={fileHandler}
        file={file}
        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
      />
      {!!errors?.document && (
        <ErrorHelperText message={errors?.document?.message} />
      )}
      {file && (
        <React.Fragment>
          <SingleDocument document={file} handleDelete={() => setFile(null)} />
          {(uploadFile?.isUploading || uploadFile?.isCompleted) && (
            <BorderLinearProgress value={uploadFile?.percen} />
          )}
        </React.Fragment>
      )}
      <Typography sx={{ mt: 2 }}>Ghi Chú</Typography>
      <Controller
        name="subcontract_description"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth>
            <TextField
              multiline
              {...field}
              placeholder="Nhập thông tin ghi chú..."
            />
          </FormControl>
        )}
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end", flexDirection: { xs: "column", sm: "row" }, mt: 2 }}>
        <Button
          size="small"
          sx={{ height: 36, mr: { xs: 0, md: 2}, mb:{ xs: 2, md: 0} }}
          disabled={submit}
          onClick={handleClose}
          variant="text"
        >
          Huỷ Thao Tác
        </Button>
        <Button
          size="small"
          sx={{ height: 36 }}
          variant="contained"
          disabled={submit}
          startIcon={
            submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
          }
          type="submit"
        >
          {type === "create" ? "tạo mới" : "cập nhật"}
        </Button>
      </Box>
    </Box>
  );
};

export default withSuspense(Form, null);
