import Layout from "./Layout";
import Dayoff from "./DayOff";
import Business from "./Business";
import WorkContract from "./WorkContract";
import Information from "./Information";
import ChangePassword from "./ChangePassword";
import ContactHomie from "./ContactHomie";
import Banking from "./Banking";
import Insurance from "./Insurance";
import Profile from "./Profile";

import Experience from "./Experience";
import SubmitInformation from "./SubmitInformation";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Layout,
  Dayoff,
  Information,
  Business,
  Banking,
  WorkContract,
  ContactHomie,
  ChangePassword,
  Insurance,
  Experience,
  Profile,
  SubmitInformation,
};
