import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class ServiceSystemApi {
  Detail = ({ serviceId = -1 }) => {
    return useQueryWithCustomized(
      ["service.system-informations", serviceId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/services/${serviceId}/system`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };
  Create = async ({ serviceId = -1, data = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/system`,
      "POST",
      {
        service_system: data,
      },
      {}
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data.data;
      })
      .catch((error) => {
        return error.response;
      });
  };
  Update = async ({ serviceId = -1, data = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/system`,
      "PUT",
      {
        service_system: data,
      },
      {}
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  CreateAttachment = async ({ serviceId = -1, data = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/system/attachments`,
      "POST",
      {
        attachment: data,
      },
      {}
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  CancelAttachment = async ({ serviceId = -1, attachmentId = -1 }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/system/attachments/${attachmentId}`,
      "DELETE",
      {},
      {}
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Preview = async ({ serviceId = -1 }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/system/preview`,
      "GET",
      {},
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };
  NotificationMailer = async ({ serviceId = -1 }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/system/sendmail`,
      "POST",
      {},
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  ServiceMailers = ({ page, pageSize, serviceId }) => {
    return useQueryWithCustomized(
      ["service.mailers", page, pageSize, serviceId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/services/mailers?page=${page}&page_size=${pageSize}${
            !!serviceId ? `&service_id=${serviceId}` : ""
          }`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ServiceSystemApi();
