import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogShowProcessProblems({open,handleClose, data, originalUrl}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Thông tin các thiết bị, linh kiện đã bán hoặc bị trùng</DialogTitle>
        
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography variant="h6" gutterBottom sx={{mt:2}}>
                Các thiết bị, linh kiện đã bán
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Item ID</TableCell>
                        <TableCell align="left">Service Tag</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data?.ItemsExported?.map((row) => 
                                <TableRow
                                    key={row?.warehouse_item_id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row?.warehouse_item_id}
                                    </TableCell>
                                    <TableCell align="left">{row?.warehouse_item_service_tag}</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h6" gutterBottom sx={{mt:2}}>
                Các thiết bị, linh kiện đã tồn tại trong phiếu
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Phiếu</TableCell>
                        <TableCell >Item ID</TableCell>
                        <TableCell >Service Tag</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data?.ItemsInProcess?.map((row) => 
                                <TableRow
                                    key={row?.process?.warehouse_process_id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Link 
                                        color = "primary"
                                        sx={{textDecoration: "underline"}}
                                        onClick= {() => window.open(`${originalUrl}/processes/${row?.process?.warehouse_process_id}/detail`, '_blank')}>
                                            {row?.process?.warehouse_process_code}
                                        </Link>
                                    </TableCell>
                                    <TableCell align="left">{row?.warehouse_item_ids?.join(", ")}</TableCell>
                                    <TableCell align="left">{row?.warehouse_item_service_tags?.join(", ")}</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ẩn</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}