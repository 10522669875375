import ServiceApi from "@/apis/beta/Service/ServiceApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import ServiceTemplates from "./Templates";

const ServiceUsage = () => {
  const { service_id: id } = useParams();

  const { data } = ServiceApi.Detail({ id });

  const serviceConfigTemplate = () => {
    const Template =
      ServiceTemplates[data?.service_creatable_type || "Default"] || null;
    return React.createElement(
      !!Template ? Template : ServiceTemplates["Default"],
      {
        serviceId: id,
        service: data,
      }
    );
  };
  return <Fragment>{serviceConfigTemplate()}</Fragment>;
};

export default withSuspense(
  withPermission(ServiceUsage, {
    feature: "service",
    action: "usage",
  }),
  MuiSkeleton["Loading"]
);
