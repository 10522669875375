import { SearchOutlined } from '@mui/icons-material';
import { Box, FormControl, TextField } from '@mui/material';
import React from 'react';
import ButtonMergeInvoice from "../Merge";

const Toolbar = ({
    handleClick,
    disabled,
    searchQuery,
    setSearchQuery
}) => {

    return (
        <Box sx={{
            minHeight: { xs: 'auto', md: 76 },
            maxWidth: '100%',
            // mt: { xs: 2, md: 0 },
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            gap: 1.5,
            p: "16px 24px 16px 0"
        }}>
            <ButtonMergeInvoice
                disabled={disabled}
                handleClick={handleClick}
            />
            <FormControl fullWidth sx={{ width: { xs: '100%', md: 300 } }}>
                <TextField
                    fullWidth
                    size="small"
                    InputProps={{
                        startAdornment: <SearchOutlined sx={{ color: '#bfbfbf', mr: 1 }} />,
                    }}
                    value={searchQuery}
                    onChange={(event) => setSearchQuery(event?.target?.value)}
                    sx={{ m: 'auto 16px', }}
                    placeholder='Nhập ID hoá đơn để tìm kiếm...'
                />
            </FormControl>
        </Box>
    )
}

export default Toolbar