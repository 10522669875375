import ServicePriceApi from '@/apis/beta/Service/ServicePriceApi'
import { queryClientRefetchScope } from '@/functions'
import { UNLIMITED } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import PopperAction from '@components/PopperAction'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { useNotificationContext } from '@contexts/notification'
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined'
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
import { Box, Button, CircularProgress, Collapse, FormControl, FormControlLabel, Grid, IconButton, Popover, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

const ServicePrice = ({ serviceId = -1, price }) => {

    const [visibility, setVisibility] = useState(true)
    const queryClient = useQueryClient()
    const { setNotification } = useNotificationContext()
    const { mutateAsync, isLoading } = useMutation(ServicePriceApi.ChangeServicePrices)
    const methods = useForm({
        defaultValues: useMemo(() => {
            return ({
                unit: price?.unit,
                id: price?.id,
                method: price?.method,
                steps: _.sortBy(price?.steps, ['start'])
            })
        }, [price])
    })

    const { fields, append, remove, update } = useFieldArray({
        control: methods.control,
        name: 'steps'
    })

    const [popper, setPopper] = useState({ data: undefined, anchorEl: undefined })

    const [actions] = useState([
        {
            order: 1,
            icon: "Add",
            label: "Thêm Dòng",
            handleClick: ({ i }) => {
                try {
                    append({
                        ...methods.watch(`steps.[${i}]`),
                        price_id: -1,
                        start: methods.watch(`steps.[${i}].end`),
                        end: UNLIMITED
                    })
                } catch (error) {
                    alert("Service Price Exception: " + error.message)
                }
                return setPopper({ data: undefined, anchorEl: undefined })
            }
        }, {
            order: 2,
            icon: "Delete",
            label: "Xoá",
            handleClick: ({ i }) => {
                try {
                    remove(i)
                    update(i - 1, {
                        ...(methods.watch(`steps.[${i - 1}]`)),
                        end: UNLIMITED
                    })
                } catch (error) {
                    console.log('failed in remove')
                }
                return setPopper({ data: undefined, anchorEl: undefined })
            }
        },
    ])

    const onSubmit = async (values) => {
        const response = await mutateAsync({
            serviceId,
            data: {
                ...values,
                steps: values?.steps?.map((item) => {
                    return ({
                        price_id: item?.service_pricing_step_id || -1,
                        start: item?.start,
                        end: item?.end,
                        pricing: item?.pricing,
                        pricing_apply: item?.pricing_apply,
                    })
                })
            }
        })
        if (!response || response?.errors) {
            return setNotification({ open: true, message: response?.errors?.[0]?.message, severity: 'error' })
        }
        queryClientRefetchScope(queryClient, 'service')
        return setNotification({
            open: true, message: 'Thay đổi giá đối soát dịch vụ thành công.', severity: 'success'
        })
    }
    return (
        <FormProvider  {...methods}>
            {!!popper?.anchorEl &&
                <Popover open={Boolean(popper?.anchorEl)} anchorEl={popper?.anchorEl}>
                    <PopperAction
                        handleClose={() => setPopper(undefined)}
                        actions={actions}
                        data={popper?.data}
                    />
                </Popover>}
            <Box sx={{
                display: 'flex',
                mb: 1.25,
                mt: 2,
                alignItems: 'center',
                gap: 1.25
            }}>
                <Typography sx={{ fontWeight: 'medium', }}>
                    Giá Đối Soát Vượt
                </Typography>
                <IconButton size='small'
                    sx={{ bgcolor: "#7776801F" }}
                    onClick={() => setVisibility(!visibility)}
                >
                    {visibility ? <VisibilityOutlined fontSize='small' color="primary" sx={{ height: 16, width: 16 }} />
                        : <VisibilityOffOutlined fontSize='small' color="error" sx={{ height: 16, width: 16 }} />}
                </IconButton>
            </Box>
            <Collapse in={visibility}>
                <Box component="form" onSubmit={methods.handleSubmit(onSubmit)} sx={{
                    p: 2,
                    border: '1px solid rgba(0,0,0,0.18)',
                    borderRadius: '4px'
                }}>
                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} md={3}>
                            <Typography
                                color="text.grey"
                                sx={{ fontSize: 14, mb: 0.5 }}>
                                Hình Thức Đối Soát *
                            </Typography>
                            <Controller
                                name="method"
                                control={methods.control}
                                render={({ field: { value, onChange } }) =>
                                    <FormControl fullWidth>
                                        <RadioGroup sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                            value={value}
                                            onChange={onChange}
                                        >
                                            {[
                                                { value: 'progressive', label: 'Luỹ Tiến' },
                                                { value: 'tierd', label: 'Theo Thoả Thuận' },
                                            ]?.map((method) =>
                                                <FormControlLabel
                                                    {...method}
                                                    key={method.value}
                                                    control={<Radio size='small' />}
                                                />
                                            )}
                                        </RadioGroup>
                                    </FormControl>
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        {fields?.map((field, i) => {
                            return (
                                <Grid container spacing={1.5} key={field?.id} sx={{ mb: 1.25 }}>
                                    <Grid item xs={6} md={2.5} xl={2.5}>
                                        <Controller
                                            name={`steps.[${i}].start`}
                                            control={methods.control}
                                            render={({ field }) =>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        {...field}
                                                        disabled={i < fields?.length - 1}
                                                        label='Từ *'
                                                        InputLabelProps={{ shrink: true }}
                                                        size='small'
                                                        InputProps={{
                                                            inputComponent: NumericFormatCustom
                                                        }}
                                                    />
                                                </FormControl>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3} xl={3}>
                                        <Controller
                                            name={`steps.[${i}].end`}
                                            control={methods.control}
                                            render={({ field: { value, onChange } }) => {
                                                const isUnlimited = parseFloat(value) >= UNLIMITED
                                                return (
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            disabled={i < fields?.length - 1}
                                                            label='Đến *'
                                                            InputLabelProps={{ shrink: true }}
                                                            size='small'
                                                            InputProps={{
                                                                sx: {
                                                                    paddingRight: 0,
                                                                },
                                                                inputComponent: isUnlimited ? 'input' : NumericFormatCustom,
                                                                endAdornment: (i === fields?.length - 1) &&
                                                                    <Box sx={{
                                                                        display: "flex",
                                                                        height: "40px",
                                                                        width: isUnlimited ? "calc(100%)" : "calc(100% / 2)",
                                                                        transition: "width 0.75s ease-in-out",
                                                                        bgcolor: "rgba(0, 0, 0, 0.18)",
                                                                        borderTopRightRadius: "4px",
                                                                        borderBottomRightRadius: "4px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                        onClick={() => {
                                                                            if (parseFloat(value) < UNLIMITED) {
                                                                                return onChange(UNLIMITED)
                                                                            }
                                                                            return onChange(field?.start)
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            color="text.success"
                                                                            sx={{
                                                                                m: 'auto 8px auto auto',
                                                                                fontSize: 13,
                                                                                textTransform: 'uppercase',
                                                                                color: isUnlimited ? green[800] : "#777680"
                                                                            }}>
                                                                            Unlimited
                                                                        </Typography>
                                                                    </Box>
                                                            }}
                                                            onChange={onChange}
                                                            value={isUnlimited ? 'UNLIMITED' : value}
                                                            helperText={
                                                                (i + 1 === fields.length) && 'Vui lòng điều chỉnh trước khi thêm mốc mới'
                                                            }
                                                        />
                                                    </FormControl>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3} xl={3}>
                                        <Controller
                                            name={`steps.[${i}].pricing`}
                                            control={methods.control}
                                            render={({ field }) =>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        {...field}
                                                        disabled
                                                        label='Đơn giá niêm yết *'
                                                        InputLabelProps={{ shrink: true }}
                                                        size='small'
                                                        InputProps={{
                                                            inputComponent: NumericFormatCustom
                                                        }}
                                                    />
                                                </FormControl>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3} xl={3}>
                                        <Controller
                                            name={`steps.[${i}].pricing_apply`}
                                            control={methods.control}
                                            render={({ field }) =>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        {...field}
                                                        label='Đơn giá bán *'
                                                        InputLabelProps={{ shrink: true }}
                                                        size='small'
                                                        InputProps={{
                                                            inputComponent: NumericFormatCustom
                                                        }}
                                                    />
                                                </FormControl>
                                            }
                                        />
                                    </Grid>
                                    {(i + 1 === fields?.length) &&
                                        <Grid item xs={0.5} sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <IconButton size='small' sx={{ bgcolor: "#7776801F", m: '4px 0 auto 0' }}
                                                onClick={(event) => setPopper({
                                                    data: { ...field, i },
                                                    anchorEl: event?.currentTarget
                                                })}
                                            >
                                                <MoreVertOutlined fontSize='small' />
                                            </IconButton>
                                        </Grid>}
                                </Grid>
                            )
                        })}
                    </Grid>

                    {methods?.formState?.isDirty && <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        pr: { xs: 0, md: 6.5 }
                    }}>
                        <Button size='small' variant='contained' type='submit' disabled={isLoading}
                            startIcon={isLoading && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                        >
                            Lưu Đơn Giá
                        </Button>
                    </Box>}
                </Box>
            </Collapse>
            <Collapse in={!visibility}>
                <MuiSkeleton.Box />
            </Collapse>
        </FormProvider>
    )
}

export default ServicePrice