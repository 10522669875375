import withPermission from '@/hocs/withPermission'
import { AddOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const ButtonCreate = () => {
    return (
        <Link to={'/customer_spams/new'} replace>
            <Button
                variant='contained'
                startIcon={<AddOutlined />}
                size='small'
                sx={{ height: { xs: 36, md: 42 } }}
            >
                Thêm khách hàng Spam
            </Button>
        </Link>
    )
}

export default withPermission(ButtonCreate, { feature: 'customer', action: 'customer-spam-create', type: "button" })