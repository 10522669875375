import { makeFirstLetterUppercase } from '@/functions'
import { Box, Popover, Typography } from '@mui/material'
import React, { useState } from 'react'

const LastedUpdate = ({ journeyUpdates = [] }) => {
    const [anchorEl, setAnchorEl] = useState(undefined)

    return (
        <Box sx={{
            height: '100%',
            width: '100%',
            alignContent: 'center'
        }}
            onClick={(e) => setAnchorEl(e?.currentTarget)}
            onMouseEnter={(e) => setAnchorEl(e?.currentTarget)}

        >
            {anchorEl && <Popover anchorEl={anchorEl} open={Boolean(anchorEl)}
                onMouseLeave={() => setAnchorEl(undefined)}
                onMouseDown={() => setAnchorEl(undefined)}
                onClose={() => setAnchorEl(undefined)}
            >
                <Box sx={{ p: '16px 24px' }}>
                    <Typography sx={{ fontSize: 14, fontWeight: 'medium', pb: 2 }}>
                        Cập nhật gần nhất (Maximum: 4 cập nhật)
                    </Typography>
                    {journeyUpdates?.reverse()?.map((journeyUpdate, index) => {
                        return (
                            <Typography sx={{ fontSize: 14 }} component="div" key={index} color="primary">
                                {makeFirstLetterUppercase(journeyUpdate?.journey_update_step)}
                                :{" "}
                                <Typography sx={{ fontSize: 14, ml: 0.25 }} color="text.black" component="span">
                                    {journeyUpdate?.journey_update_note}
                                </Typography>
                            </Typography>
                        )
                    })}
                </Box>
            </Popover>}
            <Typography sx={{ fontSize: 14 }} component="div">
                {journeyUpdates?.reverse()?.map((journeyUpdate, index) => {
                    return (
                        <Typography sx={{ fontSize: 14, display: 'inline-flex' }} key={index} color="primary">
                            {index !== 0 && <Typography sx={{ fontSize: 14, m: 'auto 8px' }} color="text.grey">|</Typography>}
                            {makeFirstLetterUppercase(journeyUpdate?.journey_update_step)}
                            :{" "}
                            <Typography sx={{ fontSize: 14, ml: 0.25 }} color="text.black" component="span">
                                {journeyUpdate?.journey_update_note}
                            </Typography>
                        </Typography>
                    )
                })}
            </Typography>
        </Box>
    )
}

export default LastedUpdate