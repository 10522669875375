import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, CircularProgress, TextField } from "@mui/material";
import DriveApi from "@/apis/beta/Drives/DriveApi";
import { useMutation, useQueryClient } from "react-query";
import { containsSpecialCharacter } from "@/functions";
import { useNotificationContext } from "@contexts/notification";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateFolder = ({
  handleClose,
  open,
  folderName,
  prefix,
  type = "folder",
  onChange = () => {},
  refetch,
}) => {
  const initialValueName = (folderName, type) => {
    if (!folderName) return "";
    if (type === "folder") return folderName;
    if (type === "file") {
      const splited = folderName?.split(".");
      if (splited.length === 1) return splited[0];

      splited.pop();
      return splited.join(".");
    }
  };

  const [value, setValue] = React.useState(() =>
    initialValueName(folderName, type)
  );

  const action = folderName ? "Đổi tên" : "Tạo mới";

  const queryClient = useQueryClient();

  const { setNotification } = useNotificationContext();

  const { mutateAsync: createFolder, isLoading } = useMutation(DriveApi.Create);

  const { mutateAsync: renameFolder, isLoading: renameFolderLoading } =
    useMutation(DriveApi.Rename);

  const { mutateAsync: renameFile, isLoading: renameFileLoading } = useMutation(
    DriveApi.RenameFile
  );

  const handleCreateFolder = async () => {
    const key = `${prefix?.replace("pub/drives/", "")}${value}`;
    await createFolder(key);
  };

  const handleRename = async () => {
    let old_prefix;
    let new_prefix;

    if (type === "file") {
      let typeFile = "";
      const splited = folderName?.split(".");

      if (splited.length > 1) {
        typeFile = `.${splited.pop()}`;
      }

      old_prefix = `${prefix?.replace("pub/drives/", "")}${folderName}`;
      new_prefix = `${prefix?.replace(
        "pub/drives/",
        ""
      )}${value?.trim()}${typeFile}`;

      await renameFile({ new_prefix, old_prefix });
    } else {
      old_prefix = `${prefix?.replace("pub/drives", "")}${folderName}/`;
      new_prefix = `${prefix?.replace("pub/drives", "")}${value?.trim()}/`;

      await renameFolder({ new_prefix, old_prefix });
    }
  };

  const handleClick = async () => {
    if (!value?.trim()) {
      return setNotification({
        open: true,
        message: "Folder name can not be empty",
        severity: "error",
      });
    }

    if (containsSpecialCharacter(value)) {
      return setNotification({
        open: true,
        message: "Folder name can not contain special character",
        severity: "error",
      });
    }

    const API = folderName ? handleRename : handleCreateFolder;
    await API();

    refetch
      ? refetch()
      : queryClient.refetchQueries([
          `drives.list.${prefix.replace("pub/drives", "")}`,
        ]);

    handleClose();
    onChange();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle>
          {action} {type}
        </DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <TextField
              onChange={(e) => setValue(e.target.value)}
              label={`Tên ${type}`}
              sx={{ width: "100%" }}
              size="small"
              variant="outlined"
              value={value}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={handleClick}
            disabled={isLoading || renameFolderLoading || renameFileLoading}
            startIcon={
              (isLoading || renameFolderLoading || renameFileLoading) && (
                <CircularProgress size={14} />
              )
            }
          >
            {action}
          </Button>
          <Button variant="outlined" size="small" onClick={handleClose}>
            Huỷ
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateFolder;
