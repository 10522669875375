import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class ScheduleApi {
    Create = async ({ data = {} }) => {
        return await requestWithToken(`${API_V3_URL}/schedules`, "POST", {
            schedule: { ...data }
        }, {}, {})
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return err?.response
            })
    }

    ScheduleBookingRoom = ({ date }) => {
        return useQueryWithCustomized(
            ['schedule.booking.room', date],
            async () => {
                return await requestWithToken(
                    `${API_V3_URL}/schedules/schedule_booking_room?date=${date}`
                )
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data?.schedules;
                    })
                    .catch((error) => {
                        return error.response;
                    })
            },
            {
                staleTime: 10 * 60 * 1000,
                enabled: !!localStorage.getItem("SA"),
            }
        );
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ScheduleApi();
