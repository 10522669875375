import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import React from "react";
import { useParams } from "react-router-dom";
import MuiScrollTabs from "@/components/shared/MuiScrollTabs";
import BankingIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import AdminPanelSettingsOutlined from "@mui/icons-material/AdminPanelSettingsOutlined";

const EmployeeTabs = () => {
  const { employee_id } = useParams();
  const initialTabs = [
    {
      value: "employee-account",
      label: "TT. Tài Khoản",
      href: "account",
      icon: ContactPageOutlinedIcon,
    },
    {
      value: "employee-profile",
      label: "Địa Chỉ & Quốc Tịch",
      href: "profile",
      icon: ContactPageOutlinedIcon,
    },
    {
      value: "employee-dayoffs",
      label: "Nghỉ Phép",
      href: "dayoffs",
      icon: TextSnippetOutlinedIcon,
    },
    {
      value: "customer-business",
      label: "Công Tác",
      href: "businesses",
      icon: WorkHistoryOutlinedIcon,
    },
    {
      value: "employee-work-contract",
      label: "Hợp Đồng",
      href: "work_contracts",
      icon: AssignmentOutlinedIcon,
    },
    {
      value: "banking",
      label: "Thuế & Ngân Hàng",
      href: "bankings",
      icon: BankingIcon,
    }, {
      value: "scope",
      label: "Quyền Truy Cập",
      href: "scopes",
      icon: AdminPanelSettingsOutlined,
    },
  ];

  return (
    <MuiScrollTabs
      prefix={`employees/${employee_id}`}
      initialTabs={initialTabs}
    />
  );
};

export default EmployeeTabs;
