import ConversantService from "./ConversantService";
import Default from "./Default";
import VnisService from "./VnisService";

const PriceTemplates = {
  ConversantService: ConversantService,
  StorageLFDService: ConversantService,
  TranscodingService: ConversantService,
  TransmuxingService: ConversantService,
  RecordingService: ConversantService,
  VnisService: VnisService,
  Default: Default,
  CloudService: Default,
  ColocationService: Default,
  //   Default: Default,
  //   Default: Default,
  //   Default: Default,
};
export default PriceTemplates;
