import { Box, FormControl, TextField, Typography } from '@mui/material'
import _ from 'lodash'
import { Fragment, useMemo } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

const ServiceManager = ({
    cluster = {},
    clusters = []
}) => {
    const methods = useForm({
        defaultValues: useMemo(() => {
            return {
                services: clusters?.map((cluster) => {
                    return ({
                        ..._.pick(cluster?.service_metadata, ['name', 'uuid']),
                        service_id: cluster?.service_id,
                        service_name: cluster?.service_product_name
                    })
                })
            }
        }, [clusters])
    })

    const { fields: services } = useFieldArray({
        control: methods.control,
        name: 'services'
    })
    return (
        <Fragment>
            <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: 16, fontWeight: 'medium' }} color="primary">
                    Thông Tin Chi Tiết (Technical)
                </Typography>
            </Box>
            <Box sx={{
                mt: 2,
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' }, gap: 1.5, flexWrap: 'wrap'
            }}>
                {services?.map((field, index) => {
                    return (
                        <Controller
                            name={`services.[${index}].name`}
                            control={methods.control}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <FormControl fullWidth sx={{
                                        minWidth: { xs: '100%', md: 'calc((100% - 16px * 2) / 3)' },
                                        width: { xs: '100%', md: 'calc((100% - 16px * 2) / 3)' }
                                    }}>
                                        <TextField
                                            label={field?.service_name}
                                            InputLabelProps={{ shrink: true }}
                                            size='small'
                                            value={value}
                                            onChange={onChange}
                                            placeholder='Nhập ID/ IP dịch vụ...'
                                        />
                                    </FormControl>
                                )
                            }}
                            key={field?.id}
                        />
                    )
                })}
            </Box>
        </Fragment>
    )
}

export default ServiceManager