import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { Box, FormControl, TextField, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ContractNumber = () => {

    const { control, formState: { errors } } = useFormContext()

    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ minWidth: 300 }}>
                <Typography component="div" sx={{ fontSize: 14, color: "#777680" }}>
                    Số Hợp Đồng
                    <Tooltip title="Số hợp đồng được tạo tự động dựa vào dịch vụ sử dụng">
                        <Typography sx={{
                            display: 'inline-block',
                            fontSize: 14,
                            color: "#000",
                            ml: '4px',
                            ":hover": {
                                cursor: 'pointer'
                            }
                        }}>*</Typography>
                    </Tooltip>
                </Typography>
            </Box>
            <Controller
                name="contract_number_id"
                control={control}
                render={({ field: { value, onChange } }) => {
                    return (
                        <FormControl fullWidth>
                            <TextField size="small"
                                disabled
                                value={value}
                                onChange={onChange}
                                error={!!errors?.contract_number_id}
                                helperText={errors?.contract_number_id?.message}
                                placeholder='Số hợp đồng...' />
                        </FormControl>
                    )
                }}
            />
        </Box>
    )
}

export default withSuspense(ContractNumber, MuiSkeleton["FormControlTextField"])