import EmployeeApi from "@/apis/beta/Employee/EmployeeApi"
import UserApi from "@/apis/UserApi"
import withSuspense from "@/hocs/withSuspense"
import { Box, Grid, TextField, Typography } from '@mui/material'
import React from 'react'

const Banking = () => {
    const [current] = UserApi.Current()
    const { data: employee } = EmployeeApi.Detail(current?.employee_id)

    return (
        <Box sx={{ border: 1, borderRadius: "4px", p: 3 }}>
            <Typography sx={{ textTransform: "uppercase", mb: 2 }}>Thông Tin Thuế & Ngân Hàng</Typography>
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>Mã Số Thuế Cá Nhân</Typography>
                    <TextField size='small' value={employee?.employee_tin || "Chưa có thông tin"} disabled fullWidth />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>Số Tài Khoản Ngân Hàng</Typography>
                    <TextField size='small' value={employee?.employee_account_bank_number || "Chưa có thông tin"} disabled fullWidth />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>Tên Ngân Hàng</Typography>
                    <TextField size='small' value={employee?.employee_bank_name || "Chưa có thông tin"} disabled fullWidth />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>Chi Nhánh</Typography>
                    <TextField size='small' value={employee?.employee_bank_branch || "Chưa có thông tin"} disabled fullWidth />
                </Grid>
            </Grid>
        </Box>
    )
}

export default withSuspense(Banking, null)