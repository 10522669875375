import withSuspense from "@/hocs/withSuspense";
import List from "../Shared/List";
import DocumentApi from "@/apis/beta/Document/DocumentApi";
import withPermission from "@/hocs/withPermission";
import MuiSkeleton from "@components/shared/MuiSkeleton";

const Share = () => {
  return <List fetchFn={DocumentApi?.GetAllShareDocument} name="my-share" />;
};

export default withSuspense(
  withPermission(Share, {
    feature: "document",
    action: "share",
  }),
  MuiSkeleton["DataGrid"]
);
