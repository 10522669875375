import { dayjsCompare } from '@/functions'
import DatePicker from '@components/datepickers/DateRangPicker'
import { Box, FormControl, Typography } from '@mui/material'
import dayjs from 'dayjs'

const ServiceBilling = ({ billing, setBilling }) => {

    const onChange = (newValues = [], scope = 'firstbill') => {
        switch (scope) {
            case 'firstbill':
                const rangeMonths = dayjsCompare(dayjs(newValues[1]?.$d || newValues[1]).add(1, 'days'), newValues[0]?.$d || newValues[0], 'months')
                setBilling(prevState => ({
                    ...prevState,
                    service_billing_startdate: newValues[0],
                    service_billing_enddate: newValues[1],
                    service_control_startdate: newValues[0],
                    service_control_enddate: newValues[1],
                    service_nextbill_startdate: dayjs(newValues[1]?.$d).add(1, 'days'),
                    service_nextbill_enddate: dayjs(newValues[1]?.$d).add(1, 'days')?.add(rangeMonths, 'months').add(-1, 'days')
                }))
                break;
            case 'nextbill':
                setBilling(prevState => ({
                    ...prevState,
                    service_nextbill_startdate: newValues[0],
                    service_nextbill_enddate: newValues[1]
                }))
                break;
            default:
                break;
        }
    }

    return (
        <Box sx={{ mt: 2 }}>
            <Box
                sx={{
                    mt: 2,
                    p: 2,
                    borderRadius: "4px",
                    border: "1px solid #00001F",
                }}
            >
                <Typography
                    sx={{ fontWeight: "medium", fontSize: 14, mb: 0.75 }}
                    color="primary"
                >
                    [Thiết Lập Thời Gian Dịch Vụ]
                </Typography>
                <Typography sx={{ fontSize: 13, mb: 0.75 }} color="text.black">
                    Chu Kỳ Thanh Toán Đầu Tiên *
                </Typography>
                <FormControl fullWidth sx={{ mb: 1 }}>
                    <DatePicker
                        value={[
                            dayjs(billing?.service_billing_startdate),
                            dayjs(billing?.service_billing_enddate)
                        ]}
                        onChange={(newValues) => onChange(newValues, 'firstbill')}
                    />
                </FormControl>
                <Typography sx={{ fontSize: 13, mb: 0.75 }} color="text.black">
                    Chu Kỳ Gia Hạn *
                </Typography>
                <FormControl fullWidth sx={{ mb: 1 }}>
                    <DatePicker
                        value={[
                            dayjs(billing?.service_nextbill_startdate),
                            dayjs(billing?.service_nextbill_enddate)
                        ]}
                        onChange={(newValues) => onChange(newValues, 'nextbill')}
                    />
                </FormControl>
            </Box>
        </Box>
    )
}

export default ServiceBilling