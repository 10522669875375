export const buildServiceInformations = (informations = [], object = {}) => {
  informations?.forEach((item) => {
    let obj = {};
    switch (item?.name?.toLowerCase().trim()) {
      case "protected domains (dns-no of records)":
        obj["domains"] = item.value;
        break;
      case "cdn & origin shield traffic-vncdn":
        obj["traffic-vncdn"] = item.value;
        break;
      case "cdn & origin shield request - vncdn":
      case " CDN & ORIGIN SHIELD Traffic-VNCDN":
        obj["request-vncdn"] = item.value;
        break;
      case "dns request - tier price":
      case "dns  request - tier price":
        obj["dns-request"] = item.value;
        break;
      default:
        break;
    }

    switch (item?.statemente_key?.toLowerCase().trim()) {
      case "396":
        obj["request-vncdn"] = item.value;
        break;
      default:
        break;
    }
    object = Object.assign(object, obj);
  });
  return object;
};

export const buildServiceInformationColumns = (informations = []) => {
  let arrs = [];
  informations.forEach((item, index) => {
    switch (item?.name?.toLowerCase().trim()) {
      case "protected domains (dns-no of records)":
        return arrs.push({
          field: "domains",
          headerName: "Protected Domains(DNS - No of Records)",
          width: 120,
          renderCell: ({ value }) => (!!value ? value : ""),
        });
      case "cdn & origin shield traffic-vncdn":
        return arrs.push({
          field: "traffic-vncdn",
          headerName: "CDN & ORIGIN SHIELD Traffic-VNCDN",
          width: 120,
          renderCell: ({ value }) => (!!value ? value : ""),
        });
      case "cdn & origin shield request - vncdn":
      case "cdn & origin shield request-vncdn":
        return arrs.push({
          field: "request-vncdn",
          headerName: "CDN & ORIGIN SHIELD  Request - VNCDN",
          width: 120,
          renderCell: ({ value }) => (!!value ? value : ""),
        });
      case "dns request - tier price":
      case "dns  request - tier price":
        return arrs.push({
          field: "dns-request",
          headerName: "DNS  Request - Tier price",
          width: 120,
          renderCell: ({ value }) => (!!value ? value : ""),
        });
      default:
        return null;
    }
  });

  const myIndex = ["domains", "traffic-vncdn", "request-vncdn", "dns-request"];
  return arrs.sort(function (a, b) {
    var x = myIndex.indexOf(a?.field);
    var y = myIndex.indexOf(b?.field);

    return x > y ? -1 : x < y ? 1 : 0;
  });
};

export const buildCurrentlyServiceInformations = (
  service = {},
  metadata,
  addons = []
) => {
  console.log(`debug:service-first-setting:`, metadata);
  let currently = {
    ...metadata,
    status: "Cấu Hình Hiện Tại",
    service_start_date: service?.service_start_date,
    service_billing_startdate: service?.service_billing_startdate,
    service_billing_enddate: service?.service_billing_enddate,
    service_enddate: service?.service_duedate,
    service_tax: service?.service_tax,
    service_status: service?.service_status,
    service_contract_name: "",
  };

  console.log(addons);

  addons?.forEach((addon) => {
    if (
      addon?.service_status !== "cancel" &&
      addon?.service_status !== "stop"
    ) {
      switch (addon?.service_product_name?.toLowerCase()?.trim()) {
        case "protected domains (dns-no of records)":
          currently["domains"] += addon?.service_addon_qty;
          break;
        case "cdn & origin shield traffic-vncdn":
          currently["traffic-vncdn"] += addon?.service_addon_qty;
          break;
        case "cdn & origin shield request - vncdn":
        case "cdn & origin shield request-vncdn":
          currently["request-vncdn"] += addon?.service_addon_qty;
          break;
        case "dns request - tier price":
        case "dns  request - tier price":
          currently["dns-request"] = addon?.service_addon_qty || 0;
          break;
        default:
          break;
      }
    }
  });

  return currently;
};
