import Holiday from "./Holiday";
import ListHoliday from "./ListHoliday";
import UpdateHoliday from "./UpdateHoliday";
import Index from "./Home";
import List from "./List";
import Salary from "./Salary";
import ApprovalMissDate from "./ApprovalMissDate";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  List,
  Salary,
  Holiday,
  ListHoliday,
  UpdateHoliday,
  Index,
  ApprovalMissDate,
};
