import { Box, Typography } from '@mui/material'
import React from 'react'
import Leader from './FeedbackChip'

const DayoffFeedback = ({ data }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            mt: 2,
            gap: { xs: 1, md: 2 }
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'row', md: 'column' },
                gap: 0.5,
                minWidth: { xs: '100%', md: 'calc((100% - (16px * 3)) / 4)' },
            }}>
                <Typography sx={{ color: '#777680' }}>
                    Trưởng Phòng:
                </Typography>
                <Leader status={data?.day_off_status} isConfirm={data?.day_off_leader_confirm} />
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'row', md: 'column' },
                gap: 0.5
            }}>
                <Typography sx={{ color: '#777680' }}>
                    BOD / HR Manager:
                </Typography>
                <Leader status={data?.day_off_status} isConfirm={data?.day_off_bod_confirm} />
            </Box>
        </Box>
    )
}

export default DayoffFeedback