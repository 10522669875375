import ServiceApi from '@/apis/beta/Service/ServiceApi';
import withSuspense from '@/hocs/withSuspense';
import MuiSkeleton from '@components/shared/MuiSkeleton';
import { useNotificationContext } from '@contexts/notification';
import { ArrowBackOutlined, ArrowForwardOutlined, ArticleOutlined as StepSecondIcon, DocumentScannerOutlined as DeclarationDomain, VideoLabel } from '@mui/icons-material';
import { Box, Button, Step, StepLabel, Stepper, Typography } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import AdminDeclaration from './Admin';
import DefaultDeclaration from './Customer';
import UIDeclaration from './UI';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));


const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <DeclarationDomain />,
        2: <StepSecondIcon />,
        3: <VideoLabel />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const steps = [
    "Thông tin đăng ký tên miền",
    "Thông tin bản khai tên miền",
    "Thông tin người quản lý(admin)"
];

const Declaration = ({
    serviceId,
    service
}) => {

    const { setNotification } = useNotificationContext()

    const [active, setActive] = React.useState(0)

    const { data: {
        service_extra_fields: { domain_extra_fields: extraFields }
    } } = ServiceApi.ServiceInformation({ serviceId })

    const methods = useForm({
        defaultValues: {
            for: extraFields?.for || "canhan",
            // step 1
            ownerName: extraFields?.ownerName,
            ownerEmail1: extraFields?.ownerEmail1,
            ownerEmail2: extraFields?.ownerEmail2,
            ownerCountry: extraFields?.ownerCountry,
            ownerProvince: extraFields?.ownerProvince,
            ownerDistrict: extraFields?.ownerDistrict,
            ownerWard: extraFields?.ownerWard,
            ownerAddress: extraFields?.ownerAddress,
            ownerPhone: extraFields?.ownerPhone,
            ownerTaxCode: extraFields?.ownerTaxCode,
            ownerID_Number: extraFields?.ownerID_Number,
            // step 2
            uiName: extraFields?.uiName,
            uiEmail: extraFields?.uiEmail,
            uiCountry: extraFields?.uiCountry,
            uiProvince: extraFields?.uiProvince,
            uiDistrict: extraFields?.uiDistrict,
            uiWard: extraFields?.uiWard,
            uiID_Number: extraFields?.uiID_Number,
            uiAddress: extraFields?.uiAddress,
            uiPhone: extraFields?.uiPhone,
            uiTaxCode: extraFields?.uiTaxCode,
            uiGender: extraFields?.uiGender,
            uiBirthdate: extraFields?.uiBirthdate,
            uiPosition: extraFields?.uiPosition,
            uiCompany: extraFields?.uiCompany,
            // step 3
            adminName: extraFields?.adminName,
            adminEmail: extraFields?.adminEmail,
            adminProvince: extraFields?.adminProvince,
            adminDistrict: extraFields?.adminDistrict,
            adminWard: extraFields?.adminWard,
            adminAddress: extraFields?.adminAddress,
            adminPhone: extraFields?.adminPhone,
            adminGender: extraFields?.adminGender,
            adminBirthdate: moment(extraFields?.adminBirthdate),
            adminPosition: extraFields?.adminPosition,
            adminCompany: extraFields?.company_name,
            adminID_Number: extraFields?.adminID_Number,
            adminFax: extraFields?.adminFax
        }
    })

    const renderStepContent = (number = 0) => {
        switch (number) {
            case 0: return <DefaultDeclaration />
            case 1: return <UIDeclaration />
            case 2: return <AdminDeclaration />
            default: return <Typography>
                Out range of step...
            </Typography>
        }
    }

    const onSubmit = async () => {
        if (service?.service_status === "active") {
            return setNotification({
                open: true,
                severity: "error",
                message: "Dịch vụ đang kích hoạt không thể thay đổi thông tin đăng ký tên miền."
            })
        }
        if (service?.service_status === "stop") {
            return setNotification({
                open: true,
                severity: "error",
                message: "Dịch vụ đã huỷ trên hệ thống, vui lòng đăng ký/ tạo dịch vụ mới."
            })
        }

        return setNotification({
            open: true,
            severity: "warning",
            message: "Chưa sẵn sàng cho cập nhật thông tin..."
        })
    }

    const onNext = () => {
        return setActive(prev => prev + 1)
    }
    const onBack = () => {
        return setActive(prev => (prev - 1))
    }

    return (
        <FormProvider {...methods}>
            <Box component="form"
                sx={{ mt: 2 }}
                onSubmit={active === 2 ? methods.handleSubmit(onSubmit) : methods.handleSubmit(onNext)}>
                <Stepper
                    alternativeLabel
                    activeStep={active}
                    connector={<ColorlibConnector />}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel
                                sx={{
                                    ":hover": {
                                        cursor: "pointer"
                                    }
                                }}
                                onClick={() => setActive(index)}
                                StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box sx={{ mt: 2 }}>
                    {renderStepContent(active)}
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: { xs: 'column', md: 'row' },
                    mt: 2
                }}>
                    <Box>
                        <Button
                            type="submit"
                            size='small'
                            variant="outlined"
                            onClick={methods.handleSubmit(onBack)}
                            disabled={active === 0}
                            startIcon={<ArrowBackOutlined fontSize='small' />}
                        >
                            Quay Lại
                        </Button>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: { xs: 1.5, md: 2 },
                        justifyContent: 'flex-end'
                    }}>
                        <Button
                            type="submit"
                            size='small'
                            variant="contained"
                            endIcon={
                                active !== 2 && <ArrowForwardOutlined fontSize='small' />
                            }
                        >
                            {active === 2 ? "Lưu Thay Đổi" : "Next"}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </FormProvider>

    )
}


export default withSuspense(Declaration, MuiSkeleton["LinearProgress"])