import InvoiceApi from '@/apis/beta/Invoice/InvoiceApi'
import DataGrid from "@/components/DataGrid"
import FormatTextNumeralVND from "@/components/shared/FormatTextNumeralVND"
import { format_date_short } from "@/functions"
import withPermission from '@/hocs/withPermission'
import withSuspense from '@/hocs/withSuspense'
import { SERVICE_PERIODS } from "@/libs/constants"
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { Typography } from '@mui/material'
import React from 'react'
import { Link, useParams } from 'react-router-dom'

const Service = () => {
    const { invoice_id } = useParams()
    const { data: services } = InvoiceApi.Services({ invoiceId: invoice_id })

    const [columns] = React.useState([
        {
            field: "service_name",
            headerName: "Tên Dịch Vụ",
            width: 250,
            valueGetter: ({ row }) => {
                return ({
                    service_id: row?.service_id,
                    service_name: row?.service_name
                })
            },
            renderCell: ({ value }) => value ? <Link to={`/services/${value?.service_id}`}>
                <Typography className='react-link-table'>{value?.service_id} - {value?.service_name}</Typography>
            </Link> : "---"
        }, {
            field: "servoce_domain", headerName: "Domain/ IP",
            hide: true,
            width: 200
        }, {
            field: "service_amount", headerName: "Số Tiền",
            width: 200,
            renderCell: ({ value }) => <FormatTextNumeralVND value={value} />
        }, {
            field: "service_billing_cycle", headerName: "Chu Kỳ",
            width: 200,
            valueGetter: ({ row }) => SERVICE_PERIODS.find(period => period.value === row?.service_billing_cycle)?.label || "---"
        }, {
            field: "service_start_date", headerName: "Ngày Bắt Đầu",
            width: 200,
            renderCell: ({ value }) => value ? format_date_short(value) : "---"
        }, {
            field: "service_duedate", headerName: "Ngày Hết Hạn",
            width: 150,
            renderCell: ({ value }) => value ? format_date_short(value) : "---"
        }, {
            field: "service_created_at", headerName: "Ngày Tạo",
            width: 200,
            renderCell: ({ value }) => value ? format_date_short(value) : "---"
        }
    ])
    return (
        <DataGrid
            columns={columns}
            rows={services?.map(item => ({ ...item, id: item?.service_id }))}
            components={{
                Toolbar: 'disabled',
                Pagination: 'disabled'
            }}
            paginationMode="client"
        />
    )
}

export default withSuspense(withPermission(Service, { feature: "invoice", action: "services" }), MuiSkeleton["Loading"])