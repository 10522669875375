import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Header from "./Header";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import { useParams } from "react-router";
import { EMPLOYEE_TYPES } from "@/libs/constants";
import { useMutation } from "react-query";
import { useNotificationContext } from "@contexts/notification";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";

const UpdateLeaveDay = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      employee_day_off_left: 0,
      employee_type: 0,
    },
  });

  const { setNotification } = useNotificationContext();
  const { employee_id } = useParams();
  const { data, isLoading, refetch: refetchLeaveDay } = EmployeeApi.Detail(employee_id);
  const [refreshData, setRefreshData] = React.useState(false);
  const {
    mutateAsync: UpdateLeaveDayMutate,
    isLoading: UpdateLeaveDayLoading,
  } = useMutation(EmployeeApi.UpdateLeaveDay);

  const isFetching = isLoading || UpdateLeaveDayLoading;

  const onSubmit = async (values) => {
    const response = await UpdateLeaveDayMutate({
      ...values,
      employee_day_off_left: Number(values?.employee_day_off_left),
      employee_id,
    });
    if (response?.errors)
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    setNotification({
      open: true,
      message: "Cập nhật thành công",
      severity: "success",
    });
    setRefreshData(true);
  };

  useEffect(() => {
    if (!data) return;
    setValue("employee_day_off_left", data?.employee_day_off_left);
    setValue("employee_type", data?.employee_type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (refreshData) {
      refetchLeaveDay();
      setRefreshData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Header />

      <Paper sx={{ p: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Typography sx={{ mb: 0.5 }}>Hình thức</Typography>
            <Controller
              name="employee_type"
              control={control}
              rules={{
                required: { value: true, message: "Giá trị bắt buộc!" },
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  fullWidth
                  disabled={isFetching}
                  error={!!errors?.employee_type}
                  helperText={errors?.employee_type?.message}
                  size="small"
                  placeholder="Hình thức..."
                >
                  {EMPLOYEE_TYPES?.map((item) => (
                    <MenuItem value={item?.value}>{item?.label}</MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Typography sx={{ mb: 0.5 }}>Ngày phép còn lại</Typography>
            <Controller
              name="employee_day_off_left"
              control={control}
              rules={{
                required: { value: true, message: "Giá trị bắt buộc!" },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  disabled={isFetching}
                  error={!!errors?.employee_day_off_left}
                  helperText={errors?.employee_day_off_left?.message}
                  size="small"
                  placeholder="Ngày phép..."
                />
              )}
            />
          </Grid>
        </Grid>

        <Box mt={2} textAlign="right">
          <Button
            variant="contained"
            type="submit"
            size="small"
            sx={{ height: 42 }}
            disabled={isFetching}
            startIcon={isFetching && <CircularProgress size={14} />}
          >
            Lưu thông tin
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default withSuspense(
  withPermission(UpdateLeaveDay, {
    feature: "employee",
    action: "update-leave-day",
  }),
  null
);
