import Packages from "./Package"
import Default from "./Default"
import PrivateCDN from "./PrivateCDN"
import Customized from "./Customized"


export const Modules = {
    Normal: Packages,
    Default: Default,
    Customized: Customized,
    PrivateCDN: PrivateCDN
}