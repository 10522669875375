import ServiceApi from "@/apis/beta/Service/ServiceApi";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import {
  buildCurrentlyServiceInformations,
  buildServiceInformations,
} from "@features/Module/Service/Detail/ServiceSetting/v2/Informations/functions";
import React from "react";
import * as _ from "lodash";
import { Box, Paper, Typography } from "@mui/material";

const PreviewConfigoptions = ({ serviceId }) => {
  const { data: information } = ServiceApi.ServiceInformation({
    serviceId,
  });

  const { data } = ServiceApi.Addons({ serviceId });

  const productConfigoptions = React.useMemo(() => {
    let metadata = {
      status: "Ban đầu",
      ..._.pick(information, [
        "service_id",
        "service_start_date",
        "service_duedate",
        "service_billing_startdate",
        "service_billing_enddate",
        "service_amount",
        "service_recurring",
        "service_tax",
        "service_status",
        "service_contract_name",
      ]),
    };

    const addonMetadata = data?.service_addons?.map((addon) => {
      let temp = {};
      switch (addon?.service_product_name?.toLowerCase()) {
        case "vcore":
        case "cpu":
          temp["vCore"] = addon?.service_addon_qty;
          break;
        case "ip":
          temp["ip"] = addon?.service_addon_qty;
          break;
        case "ram":
          temp["ram"] = `${addon?.service_addon_qty} (xGB)`;
          break;
        case "ram(x32gb)":
          temp["ram"] = `${addon?.service_addon_qty} (x32GB)`;
          break;
        case "ram(x64gb)":
          temp["ram"] = `${addon?.service_addon_qty} (x64GB)`;
          break;
        case "ssd":
          temp["storage"] = `${addon?.service_addon_qty} (xGB)`;
          break;
        case "ssd - 960gb":
          temp["storage"] = `${addon?.service_addon_qty} (x960GB SSD)`;
          break;
        case "licence transcoding":
          temp["license"] = `x${addon?.service_addon_qty}`;
          break;
        case "rack":
          temp["idc"] = `x${addon?.service_addon_qty} Rack`;
          break;
        default:
          break;
      }
      return {
        ...metadata,
        service_id: addon?.service_id,
        service_name: addon?.service_name,
        status: `${addon?.service_name}`,
        service_start_date: addon?.service_start_date,
        service_billing_startdate: addon?.service_billing_startdate,
        service_billing_enddate: addon?.service_billing_enddate,
        service_duedate: addon?.service_duedate,
        service_tax: addon?.service_tax,
        service_amount: addon?.service_amount,
        service_status: addon?.service_status,
        service_contract_name: addon?.service_contract?.contract_number_id,
        ...temp,
      };
    });
    metadata = buildServiceInformations(
      information?.service_informations,
      metadata
    );

    return [
      metadata,
      ...addonMetadata,
      buildCurrentlyServiceInformations(
        information,
        metadata,
        data?.service_addons
      ),
    ];
  }, [data, information]);

  const [state, setState] = React.useState(
    productConfigoptions?.find((x) => x?.status === "Cấu Hình Hiện Tại")
  );

  React.useEffect(() => {
    return setState(
      productConfigoptions?.find((x) => x?.status === "Cấu Hình Hiện Tại")
    );
  }, [productConfigoptions]);
  return (
    <Paper sx={{ p: "16px 24px" }}>
      <Typography sx={{ fontSize: 14 }}>Thông tin cấu hình:</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "column", gap: 0.5 },
        }}
      >
        {Object.keys(state)?.map((key) => {
          if (key?.match(/(service|status)/)) return null;
          return (
            <Typography
              sx={{
                fontSize: 14,
                color: "#777680",
                textTransform: "capitalize",
              }}
              component="span"
            >
              {key}:
              <Typography
                sx={{
                  fontSize: 14,
                  color: "#00001F",
                  display: "inline-block",
                  ml: 0.5,
                }}
              >
                {state?.[key]}
              </Typography>
            </Typography>
          );
        })}
      </Box>
    </Paper>
  );
};

export default withSuspense(
  PreviewConfigoptions,
  MuiSkeleton["LinearProgress"]
);
