import { Box, Typography } from "@mui/material";

const ContactHeader = () => {
  return (
    <Box
      sx={{
        mb: 2,
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: 18, md: 24 },
          fontWeight: "medium",
        }}
      >
        Contact HR
      </Typography>
    </Box>
  );
};

export default ContactHeader;
