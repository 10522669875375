import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

const ToolHeader = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="outlined"
        sx={{
          "&.MuiButton-root": {
            height: 36,
            m: "auto 0",
          },
        }}
        startIcon={<MenuIcon />}
      >
        Công Cụ Khác
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Link to="/workdays/salary">
          <MenuItem onClick={handleClose}>Xuất Phiếu Lương</MenuItem>
        </Link>
        <Link to="/workdays/holiday">
          <MenuItem onClick={handleClose}>Quản Lý Ngày Nghỉ Lễ</MenuItem>
        </Link>
      </Menu>
    </div>
  );
};

export default ToolHeader;
