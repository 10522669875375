import CustomerTabs from "@components/ManagementCustomer/CustomerTabs";
import { AddOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const CustomerCare = ({ children }) => {
  return (
    <Box>
      <Box
        sx={{
          mb: 2,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 1.5,
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontWeight: "medium",
            fontSize: { xs: 18, md: 24 },
          }}
        >
          Quản Lý Khách Hàng
        </Typography>
        <Link to="/customers/new" replace>
          <Button
            size="small"
            variant="outlined"
            sx={{
              minWidth: { xs: "100%", md: 150 },
              height: { xs: 36, md: 42 },
            }}
            startIcon={<AddOutlined fontSize="small" />}
          >
            Thêm khách hàng
          </Button>
        </Link>
      </Box>
      <CustomerTabs prefix="customers" />
      {children}
    </Box>
  );
};

export default CustomerCare;
