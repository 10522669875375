import AddPricing from "./AddPricing";
import CreateAddon from "./CreateAddon";
import SelectAddon from "./SelectAddon";
import RemoveAddon from "./RemoveAddon";

export const Form = {
  AddPricing: AddPricing,
  CreateAddon: CreateAddon,
  SelectAddon: SelectAddon,
  RemoveAddon: RemoveAddon,
};
