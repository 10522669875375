import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { queryClientRefetcher } from "@/functions";
import NumericFormatCustom from "@components/NumericFormatCustom";
import { useNotificationContext } from "@contexts/notification";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import { Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

const Addon = ({ service, onClose }) => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = useState(false);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      service_addon_qty: service?.service_addon_qty,
      service_amount: service?.service_amount,
      service_recurring: service?.service_recurring,
    },
  });

  const onSubmit = async (values) => {
    setSubmit(true);
    const response = await ServiceApi.Update({
      serviceId: service?.service_id,
      data: { ...values },
    });
    setSubmit(false);

    if (!response || response?.errors)
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });

    setNotification({
      open: true,
      message: `Cập nhật cấu hình dịch vụ ${service?.service_name} thành công`,
      severity: "success",
    });
    queryClientRefetcher(queryClient, [
      "services.addons.list",
      "service.informations",
      "services.detail",
    ]);
    return onClose();
  };
  return (
    <Fragment>
      <Box
        sx={{ display: "flex", mt: 3, flexDirection: "column", gap: 1.5 }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          label="Tên Dịch Vụ"
          value={service?.service_name}
          disabled
          fullWidth
          size="small"
        />
        <Controller
          name="service_addon_qty"
          control={control}
          render={({ field }) => {
            return (
              <FormControl fullWidth>
                <TextField
                  {...field}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Số lượng mua/ Thông số *"
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                />
              </FormControl>
            );
          }}
        />
        <Controller
          name="service_amount"
          control={control}
          render={({ field }) => {
            return (
              <FormControl fullWidth>
                <TextField
                  {...field}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Đơn giá bán(Tính trên 1 đơn vị tính số lượng/ thông số mua) *"
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                />
              </FormControl>
            );
          }}
        />
        <Controller
          name="service_recurring"
          control={control}
          render={({ field }) => {
            return (
              <FormControl fullWidth>
                <TextField
                  {...field}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Đơn giá gia hạn(Tính trên 1 đơn vị tính số lượng/ thông số mua) *"
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                />
              </FormControl>
            );
          }}
        />
      </Box>
      <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end", gap: 2 }}>
        <Button
          size="small"
          variant="outlined"
          disabled={submit}
          onClick={onClose}
        >
          Huỷ Thao Tác
        </Button>
        <Button
          size="small"
          variant="contained"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={submit}
          startIcon={submit && <CircularProgress size={14} />}
        >
          Lưu Thay Đổi
        </Button>
      </Box>
    </Fragment>
  );
};

export default Addon;
