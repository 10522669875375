import ProductApi from '@/apis/beta/Product/ProductApi'
import withSuspense from "@/hocs/withSuspense"
import { Box } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import Overview from './Overview'
import { sortPriorities } from './Overview/Configoption'
import ProductConfigoption from './ProductConfigoption'
import MuiSkeleton from '@components/shared/MuiSkeleton'

const Product = ({ defaultValues }) => {


    const methods = useForm({
        defaultValues: { ...defaultValues }
    })
    let navigate = useNavigate()

    const onSubmit = (values) => {

        let cart = JSON.parse(localStorage.getItem(`cart`) || '[]')
        if (!!cart) { // k co
            const available = cart?.find(item => item?.product_id === values?.product_id)
            if (!!available) { // available product

                console.log('has product in your cart.')
                return alert("Warning")
            } else {
                console.log('add product in your cart, cart is available',)
                cart.push({
                    product_id: values?.product_id,
                    product_metadata: values
                })
            }

        } else {//present?
            console.log('add product in your cart.')
            cart.push({
                product_id: values?.product_id,
                product_metadata: values
            })
        }
        try {
            localStorage.removeItem(`cart.product.configures`)
        } catch (error) {

        }
        localStorage.setItem(`cart`, JSON.stringify(cart))
        return navigate('/products/cart', { replace: true })
    }
    return (
        <FormProvider {...methods}>
            <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                width: '100%',
                gap: 2
            }} component="form" onSubmit={methods.handleSubmit(onSubmit)}>
                <ProductConfigoption />
                <Overview />
            </Box>
        </FormProvider>
    )
}


const ProductConfigureTemplate = ({ locale = 'vi-VN' }) => {

    const { product_id } = useParams()
    const { data: product } = ProductApi.FetchProductPublic(product_id)

    const location = locale === 'vi-VN' ? 'vi' : 'en'

    let DEFAULT_VALUES = {
        ...product,
        product_pricing_amount: product?.product_billing?.[location]?.terms["monthly"],
        product_pricing_total: 0,
        product_pricing_per_month: 0,
        product_billing_cycle: product?.product_billing_cycle || "monthly",
        product_configoptions: sortPriorities(product?.product_informations)?.filter((item) => !!item?.customize)?.map((item) => {
            let reducer = {
                type: item?.product_infomation_type,
                name: item?.name,
                unit: item?.unit,
                component: item?.render_type,
                subtitle: item?.subtitle
            }
            if (!!item?.optional) {
                reducer = {
                    ...reducer,
                    options: item?.options?.options || [],
                    select: item?.options?.default // {label: ?, value: ?}
                }
            } else {
                reducer = {
                    ...reducer,
                    value: item?.value,
                    max: item?.max,
                    min: item?.min,
                    product_addons: item?.options?.map((addon) => {
                        return ({
                            product_addon_name: addon?.product_name,
                            product_addon_id: addon?.product_addon_id,
                            product_addon_value: addon?.value,
                            product_addon_qty: 0,
                            product_addon_pricing_type: "default",
                            product_addon_pricing: addon?.product_addon_pricing?.[location]?.pricing || 0,
                            product_addon_terms: addon?.product_addon_pricing?.[location]?.terms || [],
                            select: null
                        })
                    })
                }
                if (reducer?.type === "cloudstorage") {

                }
            }
            return reducer
        })
    }

    return (
        <Product defaultValues={DEFAULT_VALUES} />
    )
}

export default withSuspense(ProductConfigureTemplate, MuiSkeleton["LoadingDotBox"]) 