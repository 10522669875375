import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import { buildQueryFilterCondition } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { FormControl } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const ContractCustomer = ({ disabled }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const [state, setState] = React.useState("");

  const debouncedSearchQuery = useDebounce(state, 400);

  const filterableService = React.useCallback(() => {
    let fieldQueries = [];
    if (!!debouncedSearchQuery && !(debouncedSearchQuery?.trim() === "")) {
      fieldQueries.push(
        ...[
          {
            name: "tw_services.service_id",
            operator: "$eq",
            disabled: !parseInt(debouncedSearchQuery),
            value: parseInt(debouncedSearchQuery),
          },
          {
            name: "tw_services.service_name",
            operator: "$contains",
            value: debouncedSearchQuery,
          },
        ]
      );
    }
    return buildQueryFilterCondition("$or", 0, fieldQueries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery, watch(`contract_referrer_id`)]);

  const { isFetching, data } = CustomerApi.CustomerServices({
    customerId: parseInt(watch(`customer_owner`)),
    page: 1,
    pageSize: 15,
    query: filterableService(),
  });

  const rebuildServiceSelections = React.useMemo(() => {
    if (!data?.services || data?.services?.length === 0) return [];

    return data?.services?.map((service) => {
      let label = service?.service_id;
      label += ` - ${service?.service_name}`;

      return {
        value: service?.service_id,
        label,
      };
    });
  }, [data]);

  return (
    <Controller
      name="contract_referrer_id"
      control={control}
      render={({ field: { value, onChange } }) => {
        let options = rebuildServiceSelections;
        const selected =
          options?.find((v) => parseInt(v?.value) === parseInt(value)) || null;
        return (
          <FormControl fullWidth>
            <SingleSelectCustomize
              loading={isFetching}
              value={selected}
              disabled={disabled}
              options={options}
              onChange={(event, newValue) => onChange(newValue?.value || -1)}
              input={{
                placeholder: "Nhập tên/ ID dịch vụ tìm kiếm...",
                value: state,
                onChange: (e) => setState(e?.target?.value || ""),
                label: "Dịch Vụ *",
              }}
              mode="server"
            />
            {!!errors?.contract_referrer_id && (
              <ErrorHelperText
                message={errors?.contract_referrer_id?.message}
              />
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default withSuspense(ContractCustomer, MuiSkeleton["TextField"]);
