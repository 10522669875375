import { FormControl, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const BusinessPlace = () => {
    const { control } = useFormContext()
    return (
        <React.Fragment>
            <Grid item xs={12} md={3.5}>
                <Typography>Địa Điểm Công Tác * </Typography>
            </Grid>
            <Grid item xs={12} md={8.5}>
                <Controller
                    name="business_place"
                    control={control}
                    rules={{ required: "Địa điểm công tác không được để trống." }}
                    render={({ field: { value, onChange },
                        fieldState: { error }
                    }) =>
                        <FormControl fullWidth>
                            <TextField
                                value={value}
                                size="small"
                                onChange={onChange}
                                placeholder="Nhập nơi công tác..."
                                error={!!error}
                                helperText={error?.message}
                            />
                        </FormControl>
                    } />
            </Grid>
        </React.Fragment>
    )
}

export default BusinessPlace