import { format_date_short, format_numeral_price } from "@/functions";
import { CONTRACTS_STATUS } from "@/libs/constants";
import ContractUI from "@features/Module/Contract/shared/ContractUI";
import { DescriptionOutlined } from "@mui/icons-material";
import {
	Box,
	Grid,
	IconButton,
	Paper,
	Typography
} from "@mui/material";
import ContractAttachment from "../ContractAttachment";
import ContractActions from "../../shared/Actions";

const ContractInformation = ({ contract }) => {

	const contractInformations = [
		{
			label: "Số Hợp Đồng",
			value: contract?.contract_number_id,
			component: 'Typography'
		},
		{
			label: "Công Ty/ Chủ Thể",
			value: contract?.customer_company_name || contract?.customer_name,
			component: !!contract?.customer_id ? 'Link' : 'Typography',
			to: `/customers/${contract?.customer_id}`
		},
		{
			label: "Dịch Vụ",
			value: !!contract?.service_id ? `${contract?.service_id} - ${contract?.service_name}` : '',
			component: !!contract?.service_id ? 'Link' : 'Typography',
			to: `/services/${contract?.service_id}`
		},
		{
			label: "Hiệu Lực Hợp Đồng",
			value: `${format_date_short(contract?.contract_effective_date)} - ${format_date_short(contract?.contract_expiry_date)}`,
			component: 'Typography'
		},
		{
			label: "Giá Trị Hợp Đồng",
			value: !!contract?.contract_value ? format_numeral_price(contract?.contract_value) : '',
			component: 'Typography'
		},
		{
			label: "Trạng Thái",
			data: CONTRACTS_STATUS?.find((x) => x?.value === contract?.contract_status),
			component: "muiChip",
		},
		{
			label: "Nhân Viên Phụ Trách",
			component: "Typography",
			value: contract?.employee_name
		},
		{
			label: "Từ Khoá Hợp Đồng",
			cols: 8,
			component: "Typography",
			value: contract?.contract_key
		}, {
			label: "Ghi Chú",
			cols: 12,
			component: "Typography",
			value: contract?.contract_note
		},
	]
	return (
		<Paper sx={{ p: 3, mb: 3 }}>

			<Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
				<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
					<IconButton className="information" size="small">
						<DescriptionOutlined fontSize="small" sx={{ color: "#FFFFFF" }} />
					</IconButton>
					<Typography
						sx={{
							fontSize: { xs: 18, md: 24 },
							fontWeight: "medium",
						}}
					>
						#{contract?.contract_id}
					</Typography>
				</Box>
				<ContractActions.Edit contract={contract} />
			</Box>
			<Grid container spacing={2}>
				{contractInformations?.map((attribute, index) => {
					const Component = ContractUI[attribute?.component]
					if (!Component) return null
					return (
						<Grid item xs={12} md={attribute?.cols || 4} key={index}>
							<Typography sx={{ fontSize: 14, mb: 0.25 }} color="text.grey">{attribute?.label}</Typography>
							<Component {...attribute} />
						</Grid>
					)
				})}
				<Grid item xs={12}>
					<Box sx={{
						display: 'flex',
						flexDirection: { xs: 'column', md: 'row' },
						gap: 2,
						justifyContent: 'flex-end'
					}}>
						{[0, 8].includes(contract?.contract_status) && <ContractActions.Clone contract={contract} contractId={contract?.contract_id} />}
						{![0, 8].includes(contract?.contract_status) && <ContractActions.Retension contract={contract} contractId={contract?.contract_id} />}
					</Box>
				</Grid>
			</Grid>
			{contract?.attachments?.length > 0 && <ContractAttachment attachments={contract?.attachments} />}
		</Paper>
	);
};

export default ContractInformation;
