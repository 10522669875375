import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class ServiceNoteApi {
    List = ({
        serviceId = null
    }) => {
        return useQueryWithCustomized(
            ["service.notes", serviceId],
            async () => {
                return await requestWithToken(`${API_V3_URL}/services/${serviceId}/service_notes`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data?.service_notes;
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            }
        );
    }

    Create = async ({ serviceId = -1, data = {} }) => {
        return await requestWithToken(`${API_V3_URL}/services/${serviceId}/service_notes`, "POST", {
            service_note: data
        }, {})
            .then(({ data }) => {
                if (data.error) return data;
                return data.data;
            })
            .catch((error) => {
                return error.response;
            })
    }

    Update = async ({
        serviceId = -1,
        serviceNoteId = -1,
        data = {}
    }) => {
        return await requestWithToken(`${API_V3_URL}/services/${serviceId}/service_notes/${serviceNoteId}`,
            "PUT",
            {
                service_note: data
            }, {})
            .then(({ data }) => {
                if (data.error) return data;
                return data.data;
            })
            .catch((error) => {
                return error.response;
            })
    }

    Destroy = async ({
        serviceId = -1,
        serviceNoteId = -1,
        data = {}
    }) => {
        return await requestWithToken(`${API_V3_URL}/services/${serviceId}/service_notes/${serviceNoteId}`,
            "DELETE", {}, {})
            .then(({ data }) => {
                if (data.error) return data;
                return data.data;
            })
            .catch((error) => {
                return error.response;
            })
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ServiceNoteApi();
