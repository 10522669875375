import EmployeeApi from "@/apis/beta/Employee/EmployeeApi"
import { format_date_short, format_date_time } from "@/functions"
import withPermission from "@/hocs/withPermission"
import withSuspense from "@/hocs/withSuspense"
import { EMPLOYEE_TYPES } from "@/libs/constants"
import MuiSkeletonDefault from "@components/Skeletons/Default"
import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { useParams } from "react-router-dom"

const Account = () => {
    const { employee_id } = useParams()
    const { data: employee } = EmployeeApi.Detail(employee_id)

    const employeeAccountInformation = () => {
        return [
            {
                label: 'Email Cá Nhân',
                value: employee?.employee_email,
            }, {
                label: 'Phòng Ban',
                value: employee?.employee_department,
            }, {
                label: 'Vị Trí',
                value: employee?.employee_position
            }, {
                label: 'Hình Thức Nhân Viên',
                value: EMPLOYEE_TYPES.find(t => t.value === employee?.employee_type)?.label
            }, {
                label: 'Email Công Ty',
                value: employee?.employee_email_company
            }, {
                label: 'Cấp Ngày',
                value: format_date_short(employee?.employee_created_at)
            }
        ]
    }

    return (

        <Box sx={{
            border: '1px solid rgba(0, 0, 0, 0.18)',
            borderRadius: "4px",
            p: 3
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 0.5,
                mb: { xs: 1, md: 2 }
            }}>
                <Typography sx={{
                    fontWeight: 'medium',
                    textTransform: "uppercase"
                }}>Thông tin tài khoản nhân viên</Typography>
                <Typography component="div" sx={{
                    fontSize: 12,
                    color: "#777680",
                    m: 'auto 0 0 0'
                }}>Cập nhật lần cuối [{format_date_time(employee?.employee_updated_at)}]</Typography>
            </Box>
            <Grid container spacing={2}>
                {Array.from(employeeAccountInformation())?.map((item, index) => {
                    return (
                        <Grid item xs={12} md={6} key={index}>
                            <Typography component="div"
                                sx={{
                                    mb: 0.5,
                                    fontSize: 14,
                                    color: '#777680'
                                }}>{item?.label}
                            </Typography>
                            <TextField size='small'
                                value={item?.value || "Chưa có thông tin"}
                                disabled
                                fullWidth />
                        </Grid>
                    )
                })}
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                <Button size="small"
                    variant="outlined"
                    sx={{
                        height: { xs: 36, md: 42 },
                        minWidth: 120
                    }}
                    disabled
                >
                    Cập nhật
                </Button>
            </Box>
        </Box>
    )
}

export default withSuspense(withPermission(Account, { feature: "employee", action: "account", type: "component" }), MuiSkeletonDefault)