import { API_V3_URL } from "@/libs/constants";
import { requestWithToken } from "../functions";
import useQueryWithCustomized from "./useQueryWithCustomized";

const resolveEmployeePermission = async () =>
  await requestWithToken(`${API_V3_URL}/permissions/employee`)
    .then(({ data }) => {
      if (data?.error) return data;
      return data;
    })
    .catch((error) => {
      return error.response;
    });

const usePermission = () => {
  const { data, isLoading } = useQueryWithCustomized(
    "employee.permissions",
    async () => await resolveEmployeePermission(),
    {
      staleTime: 10 * 60 * 1000,
      enabled: !!localStorage.getItem("SA"),
    }
  );

  return [data?.data?.permissions, isLoading];
};

export default usePermission;
