import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import CustomerCSFollowers from '../../Create/CustomerInformation/shared/CustomerCSFollowers'
import CustomerEvent from '../../Create/CustomerInformation/shared/CustomerEvent'
import CustomerIndustry from '../../Create/CustomerInformation/shared/CustomerIndustry'
import CustomerSaleFollowers from '../../Create/CustomerInformation/shared/CustomerSaleFollowers'
import CustomerServiceInterest from '../../Create/CustomerInformation/shared/CustomerServiceInterest'
import CustomerSource from '../../Create/CustomerInformation/shared/CustomerSource'
import CustomerWebsite from '../../Create/CustomerInformation/shared/CustomerWebsite'

const CustomerInformation = () => {
    const { watch } = useFormContext();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
                <CustomerIndustry />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <CustomerWebsite />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <CustomerSource />
            </Grid>
            {(watch("customer.customer_source") === "event") &&
                <Grid item xs={12} sm={6} md={4}>
                    <CustomerEvent />
                </Grid>}
            <Grid item xs={12} md={(watch("customer.customer_source") === "event") ? 4 : 8}>
                <CustomerServiceInterest />
            </Grid>
            <Grid item xs={12} md={4}>
                <CustomerSaleFollowers />
            </Grid>
            <Grid item xs={12} md={8}>
                <CustomerCSFollowers />
            </Grid>
        </Grid>
    )
}
export default CustomerInformation