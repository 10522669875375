import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const DomainType = () => {

    const { control } = useFormContext()

    return (
        <Box>
            <Typography sx={{ fontSize: 14, fontWeight: "medium" }}>
                Loại Đăng Ký
            </Typography>
            <Controller
                name={"domain.domain_type"}
                control={control}
                render={({ field: { value, onChange } }) => {
                    return (
                        <RadioGroup value={value}
                            onChange={onChange}
                            sx={{
                                display: "flex",
                                flexDirection: {
                                    xs: "column", md: "row"
                                },
                                gap: 0.5
                            }}
                        >
                            {[
                                { value: "register", label: "Đăng ký mới" },
                                { value: "transfer", label: "Đổi nhà cung cấp" }
                            ]?.map((item, index) => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        {...item}
                                        control={<Radio size='small' />}
                                    />
                                )
                            })}
                        </RadioGroup>
                    )
                }}
            />
        </Box>
    )
}

export default DomainType