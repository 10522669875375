import { UNLIMITED } from "@/libs/constants";
import NumericFormatCustom from "@components/NumericFormatCustom";
import {
  DeleteOutlineOutlined,
  VisibilityOffOutlined,
} from "@mui/icons-material";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import {
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import ProductConfigoptionPricingSteps from "./PricingSteps";

const CDN_PRIVATE_PRICING_METHODS = [
  { value: "progressive", label: "Luỹ Tiến" },
  { value: "tierd", label: "Theo Thoả Thuận" },
];

const ProductConfigoptionOne = ({ index, data, remover }) => {
  const { control, watch, setValue } = useFormContext();

  const [show, setShow] = React.useState(false);

  const onChangeCommitment = (event) => {
    const commitment = parseFloat(event?.target?.value);
    const idx = data?.product_pricing_steps?.findIndex(
      (item) =>
        parseFloat(item?.start) <= commitment &&
        parseFloat(item?.end) >= commitment
    );
    return setValue(
      `product_configoptions.[${index}].pricing`,
      data.product_pricing_steps?.[idx]?.pricing
    );
  };
  return (
    <Box sx={{ mb: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 1.5,
          mb: 1.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1.5,
            minWidth: { xs: "100%", md: "calc((100% - 12px) / 2)" },
          }}
        >
          <Controller
            name={`product_configoptions.[${index}].name`}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth sx={{ m: "auto 0 0 0" }}>
                  <TextField
                    label="Tên cấu hình"
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                    placeholder="Cấu hình..."
                    disabled
                    size="small"
                    inputProps={{
                      sx: {
                        textTransform: "uppercase",
                      },
                    }}
                  />
                </FormControl>
              );
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            justifyContent: "space-between",
            gap: 1.5,
            width: "100%",
          }}
        >
          <Controller
            name={`product_configoptions.[${index}].method`}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: "#777680",
                      m: "auto 0",
                    }}
                  >
                    Hình Thức Đối Soát *
                  </Typography>
                  <RadioGroup value={value} onChange={onChange} row>
                    {CDN_PRIVATE_PRICING_METHODS?.map((k, v) => {
                      return (
                        <FormControlLabel
                          {...k}
                          key={v}
                          control={<Radio size="small" />}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              );
            }}
          />
          <IconButton
            size="small"
            title="Bảng giá"
            sx={{
              height: 40,
              width: 40,
              m: "0 0 auto auto",
            }}
            onClick={remover}
          >
            <DeleteOutlineOutlined
              fontSize="small"
              sx={{ ":hover": { color: "#ef5350" } }}
            />
          </IconButton>
        </Box>
      </Box>
      {watch("product_payment_type") !== "postpaid" &&
        watch("product_payment_type").toString() !== "-1" && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 1.5,
                mb: 1.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1.5,
                  minWidth: { xs: "100%", md: "calc((100% - 12px) / 2)" },
                }}
              >
                <Controller
                  name={`product_configoptions.[${index}].value`}
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    const isUnlimited = parseFloat(value) >= UNLIMITED;
                    return (
                      <FormControl fullWidth sx={{ m: "auto 0 0 0" }}>
                        <TextField
                          label="Cấu hình cam kết *"
                          InputLabelProps={{ shrink: true }}
                          value={isUnlimited ? "" : value}
                          onChange={(event) => {
                            onChange(event?.target?.value);
                            onChangeCommitment(event);
                          }}
                          placeholder="0"
                          size="small"
                          InputProps={{
                            sx: {
                              paddingRight: 0,
                            },
                            inputComponent: isUnlimited
                              ? "input"
                              : NumericFormatCustom,
                            endAdornment: (
                              <Box
                                sx={{
                                  display: "flex",
                                  height: "40px",
                                  width: {
                                    xs: isUnlimited
                                      ? "calc(100%)"
                                      : "calc(100% / 3)",
                                    md: isUnlimited
                                      ? "calc(100%)"
                                      : "calc(100% / 4)",
                                  },
                                  transition: "width 0.75s",
                                  bgcolor: "rgba(0, 0, 0, 0.18)",
                                  borderTopRightRadius: "4px",
                                  borderBottomRightRadius: "4px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (isUnlimited) {
                                    return onChange(0);
                                  }
                                  return onChange(UNLIMITED);
                                }}
                              >
                                <Typography
                                  sx={{
                                    m: "auto 8px auto auto",
                                    fontSize: 13,
                                    textTransform: "uppercase",
                                    color: isUnlimited ? green[800] : "#777680",
                                  }}
                                >
                                  Unlimited
                                </Typography>
                              </Box>
                            ),
                          }}
                        />
                      </FormControl>
                    );
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  gap: 1.5,
                  width: "100%",
                }}
              >
                <Controller
                  name={`product_configoptions.[${index}].pricing`}
                  control={control}
                  render={({ field: { value } }) => {
                    return (
                      <FormControl fullWidth sx={{ m: "auto 0 0 0" }}>
                        <TextField
                          label="Đơn giá niêm yết"
                          InputLabelProps={{ shrink: true }}
                          value={value}
                          size="small"
                          placeholder="0"
                          disabled
                          InputProps={{
                            inputComponent: NumericFormatCustom,
                          }}
                        />
                      </FormControl>
                    );
                  }}
                />

                {!(
                  parseFloat(watch(`product_configoptions.[${index}].value`)) >=
                  UNLIMITED
                ) ? (
                  <Controller
                    name={`product_configoptions.[${index}].pricing_apply`}
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <FormControl
                          fullWidth
                          sx={{
                            m: "auto 0 0 0",
                          }}
                        >
                          <TextField
                            label="Đơn giá bán*"
                            InputLabelProps={{ shrink: true }}
                            value={value}
                            onChange={onChange}
                            placeholder="0"
                            size="small"
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                          />
                        </FormControl>
                      );
                    }}
                  />
                ) : (
                  <Controller
                    name={`product_configoptions.[${index}].pricing_amount`}
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <FormControl fullWidth sx={{ m: "auto 0 0 0" }}>
                          <TextField
                            label="Giá bán *"
                            InputLabelProps={{ shrink: true }}
                            value={value}
                            onChange={onChange}
                            placeholder="0"
                            size="small"
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                          />
                        </FormControl>
                      );
                    }}
                  />
                )}
              </Box>
            </Box>
            {parseFloat(watch(`product_configoptions.[${index}].value`)) <
              UNLIMITED && (
              <Controller
                name={`product_configoptions.[${index}].commitment_term`}
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <FormControl fullWidth sx={{ m: "auto 0 12px 0" }}>
                      <TextField
                        label="Thời hạn cấu hình cam kết *"
                        InputLabelProps={{ shrink: true }}
                        value={value}
                        onChange={onChange}
                        placeholder="0"
                        size="small"
                        InputProps={{
                          sx: {
                            paddingRight: 0,
                          },
                          inputComponent: NumericFormatCustom,
                          endAdornment: (
                            <Box
                              sx={{
                                display: "flex",
                                m: "auto 0 auto auto",
                                height: "100%",
                                width: {
                                  xs: "calc(100% / 3)",
                                  md: "calc(100% / 4)",
                                },
                              }}
                            >
                              <Typography
                                sx={{
                                  m: "auto 8px auto auto",
                                  fontSize: 13,
                                  textTransform: "uppercase",
                                  color: "#777680",
                                }}
                              >
                                Tháng
                              </Typography>
                            </Box>
                          ),
                        }}
                      />
                    </FormControl>
                  );
                }}
              />
            )}
          </>
        )}
      <Typography component="div" color="primary" sx={{ fontSize: 14 }}>
        [Bảng giá]
        {React.createElement(
          show ? VisibilityOutlined : VisibilityOffOutlined,
          {
            fontSize: "small",
            color: "primary",
            onClick: () => setShow((prev) => !prev),
            sx: {
              display: "inline-flex",
              width: 24,
              height: 14,
              m: "auto 0",
              cursor: "pointer",
            },
          }
        )}
      </Typography>
      {!!show && (
        <Collapse in={!!show}>
          <ProductConfigoptionPricingSteps index={index} />
        </Collapse>
      )}
    </Box>
  );
};

export default ProductConfigoptionOne;
