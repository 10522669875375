import DataGrid from '@/components/DataGrid';
import DataGridFilter from '@/components/DataGrid/Filter';
import { format_date_short } from '@/functions';
import { DAYOFF_STATUS_CONFIRM_TYPES, DAYOFF_STATUS_TYPES, DAYOFF_TYPE_TYPES } from "@/libs/constants";
import { MoreVertOutlined } from '@mui/icons-material';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { Box, Button, Chip, Collapse, Drawer, IconButton, Popper, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import DayOff from '../../../features/Module/Employee/ManagementDayOff';
import PopperAction from "../../PopperAction";
import DayoffInformation from './DayoffInformation';
import ModalConfirmMultiple from './ModalConfirmMultiple';
const DayoffListShared = ({
    feature = "account",
    filter, // account or management dayoffs
    updateQuery,
    data = [], // dayoffs
    employee = {},
    employees = [],
    departments = [],
    inspector = {},
    pagination = {},
    isFetching = true,
    initialActions = [],
    inputSearch
}) => {

    const [buttonConfirm, setButtonConfirm] = React.useState({
        open: false,
        dayoffIds: []
    })
    const [popper, setPopper] = React.useState({
        anchorEl: undefined,
        data: undefined,
        actions: []
    })
    const handleClosePopper = () =>
        setPopper(prev => ({ ...prev, anchorEl: undefined, data: undefined, actions: [] }))

    const [drawer, setDrawer] = React.useState({
        open: false,
        data: undefined
    })

    const formatEmployeeCollections = employees?.map(e =>
        ({ value: e?.employee_id, label: e?.employee_name })
    ) || []

    const formatDepartmentCollections = departments?.map(d =>
        ({ value: d?.department_id, label: d?.department_name })
    ) || []

    const initialColumns = [
        {
            field: 'day_offs.day_off_id', headerName: 'Mã Phiếu',
            width: 120,
            hideable: true,
            operators: { type: 'number' },
            valueGetter: ({ row }) => row?.day_off_id,
            renderCell: ({ value }) => <Link to={`/dayoffs/${value}`} replace>
                <Typography color="primary" sx={{
                    fontSize: 14,
                    textDecoration: 'underline'
                }}
                    onClick={(event) => {
                        if (feature === "account") {
                            localStorage.setItem('dayoff.referrer.redirect', 'account/dayoffs')
                        }
                    }}
                >
                    {value}
                </Typography>
            </Link>
        }, {
            field: 'day_offs.employee_id', headerName: 'Tên Nhân Viên',
            width: 200,
            hide: feature === "account",
            valueGetter: ({ row }) => {
                return ({
                    employee_id: row?.employee_id,
                    employee_name: row?.employee_name || "---"
                })
            },
            // operators: {
            //     type: 'object',
            //     mode: 'server',
            //     scope: "Employee"
            // }, // using server side
            operators: feature === "account" ? null : {
                type: 'object',
                collections: formatEmployeeCollections
            },
            renderCell: ({ value }) => value?.employee_name
        }, {
            field: 'employees.department_id', headerName: 'Phòng Ban', width: 200,
            hide: true,
            operators: feature === "account" ? null : {
                type: 'object',
                collections: formatDepartmentCollections || []
            }
        }, {
            field: 'day_offs.day_off_type', headerName: 'Loại Nghỉ Phép',
            width: 200,
            hide: feature === "account",
            operators: {
                type: 'object',
                collections: DAYOFF_TYPE_TYPES
            },
            valueGetter: ({ row }) => DAYOFF_TYPE_TYPES.find(
                (status) => status?.value === row?.day_off_type
            ),
            renderCell: ({ value }) =>
                <Typography color={value?.value === 0 ? "primary" : "#66BB6A"} sx={{ fontSize: "0.875rem" }}>{value?.label}</Typography>
        }, {
            field: 'day_off_items.day_off_item_date', headerName: 'Ngày Nghỉ',
            operators: {
                type: "date",
                initQueries: [
                    { condition: "$and", filter: { 'day_off_items.day_off_item_date': { $gte: moment().startOf("month").format("DD/MM/YYYY") } } },
                    { condition: "$and", filter: { 'day_off_items.day_off_item_date': { $lte: moment().endOf("month").format("DD/MM/YYYY") } } },
                ]
            },
            valueGetter: ({ row }) => row?.day_off_range_time,
            width: 200,
            renderCell: ({ value }) => <DayoffInformation times={value} />,
        }, {
            field: 'number_of_day_off', headerName: 'Số Ngày Nghỉ', width: 120,
            operators: {
                type: "number",
            },
            valueFormatter: ({ value }) => value
        }, {
            field: 'day_off_reason', headerName: 'Lý Do',
            width: 250,
            hide: true,
            renderCell: ({ value }) => value,
        }, {
            field: 'day_offs.day_off_leader_confirm', headerName: 'Trưởng Phòng',
            width: 150,
            valueGetter: ({ row }) => {
                const stt = DAYOFF_STATUS_CONFIRM_TYPES.find(
                    (stt) => stt.value === row?.day_off_leader_confirm
                )
                return (row?.day_off_status === 3 && !stt?.value) ? { value: 3, label: "Từ chối", color: "error" } : stt
            },
            operators: {
                type: 'object',
                collections: DAYOFF_STATUS_CONFIRM_TYPES || []
            },
            renderCell: ({ value }) =>
                <Typography sx={{ fontSize: 14 }} color={`custome.${value?.color}`}>{value?.label}</Typography>
        }, {
            field: 'day_offs.day_off_bod_confirm', headerName: 'BOD/ BOM',
            width: 150,
            hide: true,
            valueGetter: ({ row }) => {
                const stt = DAYOFF_STATUS_CONFIRM_TYPES.find(
                    (stt) => stt.value === row.day_off_bod_confirm
                )
                return (row?.day_off_status === 3 && !stt?.value) ? { value: 3, label: "Từ chối", color: "#ef5350" } : stt
            },
            operators: {
                type: 'object',
                collections: DAYOFF_STATUS_CONFIRM_TYPES || []
            },
            renderCell: ({ value }) =>
                <Typography sx={{ fontSize: 14 }} color={value?.color}>{value?.label}</Typography>
        }, {
            field: 'day_offs.day_off_status', headerName: 'Trạng Thái',
            width: 150,
            valueGetter: ({ row }) => {
                return DAYOFF_STATUS_TYPES.find(t => t?.value === row?.day_off_status)
            },
            renderCell: ({ value }) =>
                <Chip size='small'
                    label={value?.label}
                    variant={`customize-${value?.chip}`}
                />,
            operators: {
                type: 'object',
                collections: DAYOFF_STATUS_TYPES,
                initQueries: [
                    { condition: "$and", filter: { 'day_offs.day_off_status': { $eq: 0 } } },
                ]
            },
        }, {
            field: 'day_off_created_at', headerName: 'Ngày Tạo Phiếu',
            operators: { type: 'date' },
            width: 150,
            renderCell: ({ value }) => format_date_short(value),
        }, {
            field: 'actions', headerName: '',
            hideable: true,
            width: 40,
            renderCell: ({ row }) => {
                if (row?.day_off_status !== 0) return null;
                let actions = initialActions;
                if (employee?.employee_id !== row?.employee_id || row?.day_off_bod_confirm || row?.day_off_leader_confirm) {
                    actions = actions?.filter(f => !["update", "dayoff-cancel"].includes(f?.name))
                }
                if (!inspector?.leaders?.includes(employee?.employee_id) || row?.day_off_leader_confirm) {
                    actions = actions?.filter(f => f?.name !== "dayoff-leader-confirm")
                }
                if (!inspector?.master_inspectors?.includes(employee?.employee_id) || row?.day_off_bod_confirm) {
                    actions = actions?.filter(f => f?.name !== "dayoff-bod-confirm")
                }
                if (actions?.length === 0) return null;
                return (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <IconButton
                            size='small'
                            onClick={(event) => {
                                setPopper(prev => ({ ...prev, anchorEl: event?.target, data: row, actions: actions }))
                            }}
                            title='Hành động'
                        >
                            <MoreVertOutlined fontSize='small' sx={{ m: 'auto' }} />
                        </IconButton>
                    </Box>
                )
            }
        }
    ]

    const historyColumsHidden = JSON.parse(localStorage.getItem(filter?.columnsHidden) || `[]`) || []

    const [columns, setColumns] = React.useState(initialColumns?.map((column) => {
        if (historyColumsHidden?.includes(column?.field)) {
            return { ...column, hide: true }
        }
        return { ...column }
    }) || initialColumns)

    const filterables = columns?.filter((column) => !!column?.operators)
        .map((column) => {
            return ({
                field: column?.key ? column?.key : column?.field,
                label: column?.headerName,
                operators: column?.operators
            })
        })
    return (
        <React.Fragment>
            <Collapse in={buttonConfirm?.dayoffIds?.length > 1}>
                <Box sx={{
                    display: 'flex',
                    mb: 2,
                    justifyContent: 'flex-end'
                }}>
                    <Button size='small'
                        variant='contained'
                        startIcon={<VerifiedUserOutlinedIcon fontSize='small' />}
                        disabled={buttonConfirm?.dayoffIds?.length <= 1}
                        onClick={() => setButtonConfirm(prev => ({ ...prev, open: true }))}
                    >
                        Duyệt Phiếu Nghỉ Phép
                    </Button>
                </Box>
            </Collapse>
            {(buttonConfirm?.open && buttonConfirm?.dayoffIds?.length > 1) &&
                <ModalConfirmMultiple
                    dayoffs={data?.filter(dayoff => buttonConfirm?.dayoffIds?.includes(dayoff?.day_off_id))}
                    onClose={() => setButtonConfirm(prevState => ({ ...prevState, open: false }))}
                />
            }
            {(popper?.anchorEl !== undefined && popper?.actions?.length > 0) && <Popper anchorEl={popper?.anchorEl}
                open={Boolean(popper.anchorEl)}
                sx={{ zIndex: 99 }}
            >
                <PopperAction {...popper} handleClose={handleClosePopper} />
            </Popper>}
            {(drawer?.open && drawer?.data !== undefined) &&
                <Drawer
                    anchor="right"
                    open={drawer?.open}
                    sx={{ width: 360, borderTopLeftRadius: '4px', zIndex: 99 }}
                >
                    <DayOff.Detail
                        departments={departments?.departments}
                        data={{
                            ...drawer?.data,
                            employee: employees?.find(e => e?.employee_id === drawer?.data?.employee_id),
                        }}
                        handleClose={() => setDrawer({ open: false, data: undefined })}
                    />
                </Drawer>}
            {feature !== "account" && <DataGridFilter callback={updateQuery}
                filters={{
                    key: filter?.filter,
                    filterables: filterables,
                    exportFeature: "DayOffExport"
                }} />}
            <Box>
                <DataGrid
                    rows={data?.map((day_off) => ({ ...day_off, id: day_off?.day_off_id })) || []}
                    columns={columns}
                    loading={isFetching}
                    componentsProps={{
                        toolbar: {
                            store: filter?.columnsHidden,
                            columns: columns?.filter(col => !col?.hideable),
                            setColumns: setColumns,
                            initialColumns: initialColumns,
                            feature: 'dayoff',
                            inputSearch: inputSearch
                        },
                        pagination: { ...pagination }
                    }}
                    rowCount={pagination?.total}
                    disableSelectionOnClick
                    isRowSelectable={({ row }) => {
                        if (row?.day_off_status !== 0) return false;
                        if (inspector?.master_inspectors?.includes(employee?.employee_id)) {
                            return !row?.business_bod_confirm
                        } else {
                            if (inspector?.leaders?.includes(employee?.employee_id)) return !(row?.business_leader_confirm);
                            return row?.day_off_status === 0
                        }
                    }}
                    checkboxSelection={
                        feature === "management-dayoffs" && (inspector?.master_inspectors?.includes(employee?.employee_id) || inspector?.leaders?.includes(employee?.employee_id))
                    }
                    onSelectionModelChange={(props) => {
                        setButtonConfirm(prevState => ({ ...prevState, dayoffIds: props }))
                    }}
                    initialState={{
                        pinnedColumns: { right: ['actions'] },
                    }}
                />
            </Box>
        </React.Fragment>
    )
}

export default DayoffListShared