import { Box, Checkbox, FormControlLabel } from '@mui/material'

const CellCheckbox = ({
    checked
}) => {
    return (
        <Box sx={{ width: '100%', height: '100%', alignContent: 'center', justifyContent: 'center' }}>
            <FormControlLabel
                control={<Checkbox size='small' checked={checked} disabled
                    sx={{
                        "&.Mui-disabled": {
                            ".MuiSvgIcon-fontSizeSmall": {
                                color: 'rgb(26, 35, 126)'
                            }
                        }
                    }}

                />}
            />
        </Box>
    )
}

export default CellCheckbox