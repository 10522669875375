import FORM_BASE from '@components/shared/FormCustomized/form'
import { FormControl } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

const Committed = ({
    index = -1,
}) => {

    const { control } = useFormContext()
    return (
        <Controller
            name={`service_configoptions.[${index}].commitment.value`}
            control={control}
            render={({ field: { value, onChange } }) => {
                return (
                    <FormControl fullWidth>
                        <FORM_BASE.InputUnlimitCustomized
                            value={value}
                            onChange={onChange}
                            label="Gói Cố Định" />
                    </FormControl>
                )
            }} />
    )
}

export default Committed