import { Box, Typography } from '@mui/material';
import React from 'react';
import { useEmployeeContext } from '@/contexts/employee';

const HelperBase = () => {
  const { employee } = useEmployeeContext();

  return (
    <Box>
      <Typography sx={{ fontSize: '1.75rem' }}>
        Helper, {employee?.employee_name}
      </Typography>
    </Box>
  );
};

export default HelperBase;
