import { INITIAL_OPERATORS } from "@/libs/constants";
import {
  Box,
  Button,
  Paper,
  Popper,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Column, { buildConditionQueries } from "./Column";
import Comparison from "./Comparison";
import Operation from "./Operation";

const PopperPopupState = ({
  fields,
  anchorEl,
  handleClosePopper,
  handleAddQuery,
  handleAddMultiQuery,
}) => {
  const methods = useForm({
    defaultValues: useMemo(() => {
      const column = fields[0]
      let queries = []
      if (column?.operators?.operators && column.operators?.operators?.length > 0) {
        queries = [...column.operators.operators]
      } else {
        queries = buildConditionQueries(column.operators?.type || 'string')
      }
      const operators = INITIAL_OPERATORS.filter(operator => queries?.includes(operator?.operator)) || []
      return {
        fieldSelect: fields[0]?.field,
        operator: {
          operator: operators[0]?.operator,
          collections: operators
        },
        startDate: moment(),
        endDate: moment(),
        object: [],
        input: {
          type: fields[0]?.operators?.type || 'string',
          collections: fields[0]?.operators?.collections || [],
          mode: fields[0]?.operators?.mode || 'client',
          scope: fields[0]?.operators?.scope || null, // Example Scope: (EmployeeApi)
          string: '',
          objects: [],
          startdate: dayjs(),
          enddate: dayjs()
        }
      }
    }, [fields])
  });

  const onSubmit = (data) => {
    const location = [data?.input?.type, data?.operator?.operator].join(':')
    switch (location) {
      case ["date", "$btw"].join(':'):
        let [gte, lte] = [{}, {}]
        gte[data?.fieldSelect] = {
          $gte: moment(data?.input?.startdate?.$d || data?.input?.startdate)?.format("DD/MM/YYYY"),
        }
        lte[data?.fieldSelect] = {
          $lte: moment(data?.input?.enddate?.$d || data?.input?.enddate)?.format("DD/MM/YYYY"),
        };
        handleClosePopper();
        return handleAddMultiQuery([
          { condition: "$and", filter: gte },
          { condition: "$and", filter: lte }
        ])
      case ["date", "$eq"].join(':'):
      case ["date", "$lte"].join(':'):
      case ["date", "$gte"].join(':'):
        let date = {}
        let dateObj = {}
        dateObj[data?.operator?.operator] = moment(data?.input?.startdate?.$d || data?.input?.startdate)?.format("DD/MM/YYYY");
        date[data?.fieldSelect] = dateObj;
        handleClosePopper();
        return handleAddQuery({ condition: "$and", filter: date });

      case ["string", "$eq"].join(':'):
      case ["string", "$ne"].join(':'):
      case ["string", "$contains"].join(':'):
      case ["string", "$notContains"].join(':'):
      case ["number", "$eq"].join(':'):
      case ["number", "$ne"].join(':'):
      case ["number", "$lte"].join(':'):
      case ["number", "$gte"].join(':'):
      case ["price", "$eq"].join(':'):
      case ["price", "$ne"].join(':'):
      case ["price", "$lte"].join(':'):
      case ["price", "$gte"].join(':'):
      case ["string", "$in"].join(':'):
      case ["string", "$nin"].join(':'):
      case ["number", "$in"].join(':'):
      case ["number", "$nin"].join(':'):
        let str = {};
        let obj = {};
        obj[data?.operator?.operator] = data?.input?.string
        str[data?.fieldSelect] = obj;
        handleClosePopper();
        return handleAddQuery({ condition: "$and", filter: str });
      case ["object", "$in"].join(':'):
      case ["object", "$nin"].join(':'):
      case ["object", "$included"].join(':'):
      case ["object", "$eq"].join(':'):
      case ["object", "$ne"].join(':'):
        let object = {};
        let objectValue = {};
        objectValue[data?.operator?.operator] = data?.input?.objects?.map(oj => oj?.value).join(',')
        object[data?.fieldSelect] = objectValue;
        handleClosePopper();
        return handleAddQuery({ condition: "$and", filter: object });
      default:
        break;
    }
  };

  const [conditionQueries, setConditionQueries] = useState([])

  return (
    <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} sx={{ zIndex: 101 }}>
      <FormProvider {...methods}>
        <Paper
          sx={{ mt: 1, width: 300, p: 2 }}
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Typography
            sx={{ mb: 1.25, fontSize: 14, fontWeight: "medium" }}
            color="primary"
          >
            LỌC THÔNG TIN
          </Typography>
          <Column attributes={fields} setConditionQueries={setConditionQueries} />
          <Operation conditionQueries={conditionQueries} />
          <Comparison />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              sx={{
                "&.MuiButton-root": {
                  height: 36,
                  minWidth: 100,
                },
              }}
              size="small"
              onClick={handleClosePopper}
            >
              Huỷ Thao Tác
            </Button>
            <Button
              sx={{
                "&.MuiButton-root": {
                  height: 36,
                  minWidth: 120,
                },
              }}
              size="small"
              type="submit"
            >
              Lọc Thông Tin
            </Button>
          </Box>
        </Paper>
      </FormProvider>
    </Popper>
  );
};

export default PopperPopupState;
