import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

export const METADATA_OPTIONS = [
  { value: "id", label: "ID" },
  { value: "ip", label: "IP" },
  { value: "user", label: "USER" },
  { value: "pass", label: "PASS" },
  { value: "link", label: "LINK" },
  { value: "Cấu hình", label: "CẤU HÌNH" },
];

const Metadata = () => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "metadata",
  });

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 0.5,
          mt: 2,
        }}
      >
        <Typography sx={{ fontSize: 14, fontWeight: "medium" }}>
          Thông tin dịch vụ:
        </Typography>
        <Typography
          onClick={() => append({ key: "", value: "" })}
          sx={{ fontSize: 14, m: "auto 0", textDecoration: "underline" }}
          color="primary"
        >
          + Thêm thông tin
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          mt: 1.5,
          mb: 1.5,
        }}
      >
        {fields &&
          fields?.map((field, index) => {
            return (
              <Box
                sx={{ display: "flex", flexDirection: "row", gap: 1.5 }}
                key={field?.id}
              >
                <Controller
                  name={`metadata.[${index}].key`}
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    const selected =
                      METADATA_OPTIONS?.find((x) => x?.value === value) || null;
                    return (
                      <FormControl
                        fullWidth
                        sx={{
                          maxWidth: {
                            xs: "100%",
                            md: "calc((100% - 16px) / 4)",
                          },
                        }}
                      >
                        <SingleSelectCustomize
                          options={METADATA_OPTIONS}
                          value={selected}
                          onChange={(event, newValue) =>
                            onChange(newValue?.value || "")
                          }
                          input={{
                            label: "Từ khoá/ keyword *",
                            placeholder: "Chọn từ khoá/ tên/ keyword...",
                          }}
                        />
                      </FormControl>
                    );
                  }}
                />
                <Controller
                  name={`metadata.[${index}].value`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <FormControl fullWidth>
                        <TextField
                          {...field}
                          size="small"
                          label="Giá trị *"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="Nhập giá trị..."
                        />
                      </FormControl>
                    );
                  }}
                />
                <IconButton size="small" onClick={() => remove(index)}>
                  <DeleteOutlineOutlined
                    fontSize="small"
                    sx={{
                      ":hover": { color: "#ef5350" },
                    }}
                  />
                </IconButton>
              </Box>
            );
          })}
      </Box>
    </Fragment>
  );
};

export default Metadata;
