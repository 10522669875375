import withPermission from '@/hocs/withPermission';
import ProcessForm from '@features/Module/Contract/NumberContract/Detail/Process/Form';
import { AddOutlined } from '@mui/icons-material';
import { Button, Drawer } from '@mui/material';
import { useState } from 'react';

const CreateContractProcess = ({ contract }) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <Drawer anchor="right" sx={{ width: 450 }} open={open}>
                <ProcessForm
                    type={"contracts"}
                    contractNumber={{
                        ...contract,
                        contract_number_stt: contract?.contract_id,
                    }}
                    handleClose={() => setOpen(false)}
                />
            </Drawer>
            <Button size='small'
                startIcon={<AddOutlined />}
                variant="contained"
                onClick={() => { setOpen({ open: true }) }}
            >
                Thêm Trao Đổi
            </Button>
        </>
    )
}

export default withPermission(CreateContractProcess, {
    feature: 'contract-process',
    action: 'create',
    type: 'Button'
})