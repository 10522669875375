
export const GET_INITIAL_ACTIONS = ({
  feature = "",
  setPopper,
  setAction,
  setNotification,
}) => {
  return [
    {
      order: 1,
      name: "open-contract-attachment",
      icon: "See",
      label: "Xem Hợp Đồng",
      handleClick: (data) => {
        try {
          const link = data.link
            ? data.link
            : data?.attachments[0]?.attachment_file_url
              ? `${process.env.REACT_APP_DOMAIN_FILE
              }${data?.attachments[0]?.attachment_file_url.replace("pub/", "")}`
              : "#";
          window.open(
            link,
            data.contract_id,
            "height=720, width=1080, left=100, top=100, resizable=yes, scrollbars=yes, toolbar=yes, menubar=no, location=no, directories=no, status=yes"
          );
        } catch (e) {
          console.log(e);
        }
      },
    },
    {
      order: 2,
      name: "download-contract-attachment",
      icon: "Download",
      label: "Download",
      handleClick: async (data) => {
        const link = data.link
          ? data.link
          : data?.attachments[0]?.attachment_file_url
            ? `${process.env.REACT_APP_DOMAIN_FILE
            }${data?.attachments[0]?.attachment_file_url.replace("pub/", "")}`
            : "#";

        let contractFilename = data?.attachments[0]?.attachment_file_name
        return await contractDownload({ contractLink: link, contractFilename: contractFilename })
      },
    },
    {
      name: "edit-contract",
      icon: "Edit",
      label: "Chỉnh Sửa Hợp Đồng",
      handleClick: (data) => {
        setAction((prev) => ({
          ...prev,
          open: true,
          data: data,
          name: "edit-contract",
        }));
        setPopper((prev) => ({
          ...prev,
          anchorEl: undefined,
          data: undefined,
        }));
      },
    },
    {
      name: "retension-contract",
      icon: "Renew",
      label: "Gia Hạn Hợp Đồng",
      handleClick: (data) => {
        setAction((prev) => ({
          ...prev,
          open: true,
          data: data,
          name: "retension-contract",
        }));
        setPopper((prev) => ({
          ...prev,
          anchorEl: undefined,
          data: undefined,
        }));
      },
    },
  ];
};

export const contractDownload = async ({
  contractLink = '',
  contractFilename = ''
}) => {
  try {
    let outputName = contractFilename
    if (!outputName) {
      outputName = contractLink?.split('/')?.at(-1)
    }
    const anchorElement = document.createElement('a');
    anchorElement.href = contractLink;
    anchorElement.download = outputName
    return await fetch(contractLink)
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        anchorElement.href = url;
        anchorElement.dispatchEvent(new MouseEvent('click'));
      });

  } catch (e) {
    console.log(e);
  }
}