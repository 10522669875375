import { format_date_short } from "@/functions";

export const queryToCondition = (data = {
    fieldSelect: '',
    input: { type: '', startdate: '', enddate: '', },
    operator: { operator: '' },
}) => {
    const location = [data?.input?.type, data?.operator?.operator].join(':')
    switch (location) {
        case ["object", "$json"].join(':'):
            let strJson = {};
            let json = {};
            json[data?.operator?.operator] = data?.input?.string
            strJson[data?.fieldSelect] = "'employee' as integer";
            return strJson;
        case ["date", "$btw"].join(':'):
            let [gte, lte] = [{}, {}]
            gte[data?.fieldSelect] = {
                $gte: format_date_short(data?.input?.startdate?.$d || data?.input?.startdate)
            }
            if (!!data?.input?.enddate) {
                lte[data?.fieldSelect] = {
                    $lte: format_date_short(data?.input?.enddate?.$d || data?.input?.enddate),
                };
            } else {
                return [gte]
            }
            return [gte, lte];

        case ["date", "$eq"].join(':'):
        case ["date", "$lte"].join(':'):
        case ["date", "$gte"].join(':'):
            let date = {}
            let dateObj = {}
            dateObj[data?.operator?.operator] = format_date_short(data?.input?.startdate?.$d || data?.input?.startdate)
            date[data?.fieldSelect] = dateObj;
            return date;

        case ["string", "$eq"].join(':'):
        case ["string", "$ne"].join(':'):
        case ["string", "$contains"].join(':'):
        case ["string", "$notContains"].join(':'):
        case ["number", "$eq"].join(':'):
        case ["number", "$ne"].join(':'):
        case ["number", "$lte"].join(':'):
        case ["number", "$gte"].join(':'):
        case ["price", "$eq"].join(':'):
        case ["price", "$ne"].join(':'):
        case ["price", "$lte"].join(':'):
        case ["price", "$gte"].join(':'):
        case ["string", "$in"].join(':'):
        case ["string", "$nin"].join(':'):
        case ["number", "$in"].join(':'):
        case ["number", "$nin"].join(':'):
            let str = {};
            let obj = {};
            obj[data?.operator?.operator] = data?.input?.string
            str[data?.fieldSelect] = obj;
            return str;

        case ["object", "$in"].join(':'):
        case ["object", "$nin"].join(':'):
        case ["object", "$included"].join(':'):
        case ["object", "$eq"].join(':'):
        case ["object", "$ne"].join(':'):
        case ["object", "$inRange"].join(':'):
            let object = {};
            let objectValue = {};
            objectValue[data?.operator?.operator] = data?.input?.objects?.map(oj => oj?.value).join(',')
            object[data?.fieldSelect] = objectValue;

            return object;
        default:
            break;
    }
}