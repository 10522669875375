import { Box, Chip, StepLabel, Typography } from '@mui/material';
import React from 'react';
import { format_date_short } from '@/functions';
import PrivateIconWithFormat from '../shared/icons';

const StepReceviePostmanTracking = ({
    data
}) => {
    return (
        <React.Fragment>
            <StepLabel
                StepIconProps={{
                    sx: {
                        fontSize: 20,
                        mb: `56px`
                    }
                }}
            >
                <Chip size='verysmall'
                    variant='customize-purple'
                    label="VNETWORK Đã Nhận" />
                <Box sx={{ display: 'flex', mb: 1 }}>
                    <PrivateIconWithFormat icon="CalendarIcon" />
                    <Typography>
                        Hợp đồng {data?.postman_tracking_package?.split(",")?.join(" | ")} đã được gửi về VNETWORK vào lúc {format_date_short(data?.postman_tracking_receive_date)}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <PrivateIconWithFormat icon="AssigneeIcon" />
                    <Typography sx={{ fontSize: 14, m: 'auto 0', color: "#9E9E9E" }}>
                        Người Tiếp Nhận: <span style={{ color: "#000" }}>{data?.employee?.employee_name}</span>
                    </Typography>
                </Box>
            </StepLabel>
        </React.Fragment>
    )
}

export default StepReceviePostmanTracking