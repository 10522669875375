import { useNotificationContext } from '@/contexts/notification';
import CustomizedProgressBars from '@components/BorderLinearProgress';
import { Alert, Slide, Snackbar, Typography } from '@mui/material';
import React from 'react';

const TransitionDown = (props) => {
  return <Slide {...props} direction="down" />;
};

const NotificationAlert = () => {
  const { notification, setNotification, background, setBackground } = useNotificationContext();

  const clearBackground = () => setBackground({ open: false, done: false, percent: 0, type: "", file: null })
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: "right" }}
        open={background.open}
        onClose={clearBackground}
        TransitionComponent={TransitionDown}
      >
        <Alert onClose={clearBackground} severity="info" sx={{ width: '100%' }}>
          <Typography>
            {!background?.done ?
              background?.type === "download" ? "Đang Tải Tập Tin" : "Đang Thực Thi"
              : "Hoàn Tất"
            }
          </Typography>
          <Typography sx={{ fontSize: 13 }}>{background?.file?.name || "File name.txt"}</Typography>
          {!background?.done && <CustomizedProgressBars value={background?.percent} />}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={notification.open}
        autoHideDuration={notification.duration || 1000}
        onClose={() => setNotification({ open: false, severity: '' })}
        TransitionComponent={TransitionDown}
      >
        <Alert onClose={() => setNotification({ open: false, severity: '' })} severity={notification?.severity} sx={{ width: '100%' }}>
          {notification?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NotificationAlert;
