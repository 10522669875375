import ServiceApi from "@/apis/beta/Service/ServiceApi";
import DataGrid from "@/components/DataGrid";
import { CellLink, CellPricing } from "@/components/DataGrid/Cell";
import DataGridFilter from "@/components/DataGrid/Filter";
import { format_date_short } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import useFilters, { Filter } from "@/hooks/useFilters";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import Actions from "./Actions";

const DeviceLiquidation = () => {
  const location = useLocation();

  const [query, updateQuery] = useFilters(
    "services.confirmations.list.filters",
    location
  );

  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const initialColumns = [
    {
      field: "tw_services.service_id",
      headerName: "ID",
      width: 60,
      disableReorder: true,
      hideable: true,
      valueGetter: ({ row }) => ({
        label: row?.service_id,
        href: ["services", row?.service_id],
      }),
      operators: { type: "number" },
      renderCell: ({ value }) => <CellLink data={value} target="_blank" />,
    },
    {
      field: "tw_services.service_product_id",
      headerName: "Sản Phẩm",
      width: 350,
      valueGetter: ({ row }) => row?.service_name,
    },
    {
      field: "tw_services.service_customer_id",
      headerName: "Khách Hàng",
      width: 200,
      valueGetter: ({ row }) => {
        let label = row?.customer_company_name;
        if (!label || label === "") {
          label = row?.customer_name;
        }
        return {
          label,
          href: ["customers", row?.service_customer_id],
        };
      },
      renderCell: ({ value }) => <CellLink data={value} />,
    },
    {
      field: "service_amount",
      headerName: "Đơn Giá",
      width: 150,
      operators: { type: "price" },
      renderCell: ({ value }) => {
        return <CellPricing price={value} />;
      },
    },
    {
      field: "service_creator",
      headerName: "Người Tạo",
      width: 200,
      valueGetter: ({ row }) => row?.service_creator?.employee_name || '',
    },
    {
      field: "service_start_date",
      headerName: "Ngày Bắt Đầu Sử Dụng Dịch Vụ",
      width: 180,
      renderCell: ({ value }) => format_date_short(value),
      operators: { type: "date" },
    },
    {
      field: "service_billing_startdate",
      headerName: "Chu Kỳ Thanh Toán Đầu Tiên",
      width: 230,
      operators: { type: "date" },
      valueGetter: ({ row }) => {
        if (!row?.service_billing_startdate || !row?.service_billing_enddate) {
          return ``;
        }
        return `${format_date_short(
          row?.service_billing_startdate
        )} - ${format_date_short(row?.service_billing_enddate)}`;
      },
    },
    {
      field: "created_at",
      headerName: "Ngày Tạo",
      width: 180,
      renderCell: ({ value }) => format_date_short(value),
      operators: { type: "date" },
    },
    {
      field: "actions",
      headerName: "",
      width: 40,
      renderCell: ({ row }) => <Actions data={row} />,
    },
  ];

  const historyColumsHidden =
    JSON.parse(localStorage.getItem("services.POC.list.hiddenColumns")) || [];

  const [columns, setColumns] = React.useState(
    initialColumns?.map((column) => {
      if (historyColumsHidden?.includes(column?.field)) {
        return { ...column, hide: true };
      }
      return { ...column };
    }) || initialColumns
  );

  const filterables = columns
    .filter((column) => !!column?.operators && !column?.hide)
    .map((column) => {
      return {
        field: column?.key ? column.key : column.field,
        label: column?.headerName,
        operators: column?.operators,
      };
    });

  const { data, isFetching } = ServiceApi.List({
    page: page?.page + 1,
    pageSize: page?.pageSize || 20,
    query: [
      Filter.stringify(query),
      `filters[$and][${Filter.stringify(query)?.split("&")?.length || 1
      }][tw_services.service_referrer_type][$ne]=Reducer::Override::Service`,
      `filters[$and][${(Filter.stringify(query)?.split("&")?.length || 1) + 1
      }][tw_services.service_status][$eq]=waiting-confirm`,
    ]?.join("&"),
    searchQuery: debouncedSearchQuery || null,
  });

  return (
    <Box>
      <DataGridFilter
        callback={updateQuery}
        filters={{
          key: "services.POC.list.filters",
          filterables: filterables,
        }}
      />
      <Box>
        <DataGrid
          rows={
            data?.services?.map((service) => ({
              ...service,
              id: service?.service_id,
            })) || []
          }
          columns={columns}
          loading={isFetching}
          componentsProps={{
            toolbar: {
              store: "services.POC.list.hiddenColumns",
              columns: columns?.filter((col) => !col?.hideable),
              setColumns: setColumns,
              initialColumns: initialColumns,
              inputSearch: {
                placeholder: "Nhập ID dịch vụ/ ID khách hàng tìm kiếm...",
                value: searchQuery,
                onChange: setSearchQuery,
              },
            },
            pagination: {
              rowsPerPageOptions: [10, 20, 50, 100],
              page: page?.page || 0,
              pageSize: page?.pageSize,
              onPageChange: (newPage) =>
                setPage((prevState) => ({ ...prevState, page: newPage })),
              onPageSizeChange: (newPageSize) =>
                setPage((prevState) => ({
                  ...prevState,
                  pageSize: newPageSize,
                })),
            },
          }}
          getRowHeight={({ model }) => {
            const PADDING_OFFSET_Y = 8;
            const COUNT_COMMITMENT_LINES =
              24 * (model?.commitments?.length || 0) + PADDING_OFFSET_Y;
            return Math.max(COUNT_COMMITMENT_LINES, 52);
          }}
          rowCount={data?.pagination?.total}
          pinnedColumns={{
            right: ["actions"],
          }}
        />
      </Box>
    </Box>
  );
};
export default withSuspense(
  withPermission(DeviceLiquidation, {
    feature: "service",
    action: "confirmations",
  }),
  MuiSkeleton["DataGrid"]
);
