import CustomerApi from '@/apis/beta/Customer/CustomerApi'
import CustomerManagementApi from '@/apis/beta/Customer/CustomerManagementApi'
import { removeObjectKeys } from '@/functions'
import withPermission from '@/hocs/withPermission'
import withSuspense from '@/hocs/withSuspense'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { useNotificationContext } from '@contexts/notification'
import { vestResolver } from '@hookform/resolvers/vest'
import { AddOutlined } from '@mui/icons-material'
import { Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, Paper, TextField, Typography } from '@mui/material'
import { indigo } from '@mui/material/colors'
import React from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import customerManagementValidator from './validator'

const ManagementServiceInvoice = () => {
    const { customer_id } = useParams()
    const [submit, setSubmit] = React.useState(null)

    const { setNotification } = useNotificationContext()

    const { data: { services } } = CustomerApi.CustomerServices({
        customerId: customer_id,
        query: `&filters[$and][0][service_status][$eq]=active`,
        pageSize: 999,
    })

    const { data, refetch: callback } = CustomerManagementApi.List({
        customerId: customer_id
    })

    const [defaultValues] = React.useState({
        managements: data?.managements || [
            {
                customer_management_name: "",
                service_referrer: [],
                mailer_receiver: "",
                mailer_cc: "",
                mailer_bcc: ""
            }
        ]

    })

    const methods = useForm({
        resolver: vestResolver(customerManagementValidator),
        defaultValues: defaultValues
    })

    const { control, formState: { errors } } = methods

    const { fields, append, update } = useFieldArray({
        control: control,
        name: "managements"
    })

    const onSubmit = async (values) => {
        setSubmit("submit")

        let form = []
        values?.managements?.forEach(management => {
            form.push({ ...removeObjectKeys(management, ["id", "customer_id", "created_at", "updated_at"]) })
        });

        const response = await CustomerManagementApi.Create({
            customerId: customer_id,
            managements: form
        })
        setSubmit(null)

        if (!response || response?.errors) {
            return setNotification({
                open: true,
                message: response?.errors?.[0]?.message,
                severity: "error"
            })
        }
        try {
            callback()
        } catch (error) {
            console.log(error?.message)
        }

        return setNotification({
            open: true,
            message: "Cập nhật cấu hình gửi hoá đơn dịch vụ thành công.",
            severity: "success"
        })
    }

    const onReset = () => {
        setSubmit("reset")
        methods.reset({ ...defaultValues })
        return setSubmit(null)
    }

    return (
        <Paper sx={{
            maxWidth: {
                xs: "100%", md: "80%"
            },
            p: 3
        }} component="form" onSubmit={methods.handleSubmit(onSubmit)}>
            <Typography sx={{
                fontSize: 18,
                fontWeight: 'medium',
                mb: 2
            }}>
                Quản lý hoá đơn dịch vụ theo nhóm
            </Typography>
            {fields?.map((field, idx) => {
                return (
                    <Box
                        key={field.id}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            mb: 4
                        }}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: { xs: 'column', md: 'row' },
                            gap: 1.5,
                            borderBottom: { xs: '1px solid rgba(0, 0, 0, 0.18)', md: 'unset' }
                        }}>
                            <Box sx={{
                                minWidth: { xs: '100%', md: '30%' },
                                width: { xs: '100%', md: '30%' }
                            }}>
                                <Typography sx={{ fontWeight: 'medium' }}>
                                    Tên nhóm
                                </Typography>
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Controller
                                    control={control}
                                    name={`managements.[${idx}].customer_management_name`}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <FormControl fullWidth>
                                                <TextField size='small'
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder="ex: Nhóm 1..."
                                                    error={!!errors?.managements?.[idx]?.customer_management_name?.message}
                                                    helperText={errors?.managements?.[idx]?.customer_management_name?.message}
                                                />
                                            </FormControl>
                                        )
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: { xs: 'column', md: 'row' },
                            gap: 1.5,
                            borderBottom: { xs: '1px solid rgba(0, 0, 0, 0.18)', md: 'unset' }
                        }}>
                            <Box sx={{
                                minWidth: { xs: '100%', md: '30%' },
                                width: { xs: '100%', md: '30%' }
                            }}>
                                <Typography sx={{ fontWeight: 'medium' }}>
                                    Dịch vụ
                                </Typography>
                                <Typography sx={{
                                    fontSize: 14,
                                    color: "#777680"
                                }}>
                                    Hoá đơn gia hạn/ đối soát dịch vụ sẽ được gửi theo nhóm dịch vụ dựa trên cấu hình này.
                                </Typography>
                            </Box>
                            <Box sx={{
                                width: '100%',
                                border: '1px solid rgba(0, 0, 0, 0.18)',
                                borderRadius: '4px'
                            }}>
                                {services?.map((service, index) => {
                                    return (
                                        <Box key={index} sx={{
                                            display: 'flex',
                                            p: "12px 0 12px 12px",
                                            justifyContent: 'space-between',
                                            borderTop: index !== 0 && '1px solid rgba(0, 0, 0, 0.28)'
                                        }}>
                                            <Typography sx={{
                                                fontSize: 14,
                                                fontWeight: 'medium',
                                                m: 'auto 0'
                                            }}>
                                                {service?.service_name}
                                            </Typography>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    size='small'
                                                    onChange={(event) => {
                                                        if (!field?.service_referrer?.includes(service?.service_id)) {
                                                            return update(idx, {
                                                                ...field,
                                                                service_referrer: [...field?.service_referrer, service?.service_id]
                                                            })
                                                        } else {
                                                            return update(idx, {
                                                                ...field,
                                                                service_referrer: [...field?.service_referrer?.filter(item => item !== service?.service_id)]
                                                            })
                                                        }
                                                    }}
                                                    checked={field?.service_referrer?.includes(service?.service_id)}
                                                    sx={{
                                                        "&.Mui-selected": {
                                                            color: indigo[900]
                                                        }
                                                    }}
                                                />} />
                                        </Box>
                                    )
                                })}
                                {!!errors?.managements?.[idx]?.service_referrer?.message &&
                                    <ErrorHelperText message={errors?.managements?.[idx]?.service_referrer?.message} />}
                            </Box>
                        </Box>
                        <Divider sx={{ m: 'auto 0' }} />
                        <Box sx={{
                            display: "flex",
                            flexDirection: { xs: 'column', md: 'row' },
                            gap: 1.5,
                            borderBottom: { xs: '1px solid rgba(0, 0, 0, 0.18)', md: 'unset' }
                        }}>
                            <Box sx={{
                                minWidth: { xs: '100%', md: '30%' },
                                width: { xs: '100%', md: '30%' }
                            }}>
                                <Typography sx={{ fontWeight: 'medium' }}>
                                    Mailer
                                </Typography>
                                <Typography sx={{
                                    fontSize: 14,
                                    color: "#777680"
                                }}>
                                    Hoá đơn gia hạn/ đối soát dịch vụ sẽ được gửi theo cấu hình mail này.
                                </Typography>
                            </Box>
                            <Box sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: "column",
                                gap: 1.5
                            }}>
                                <Controller
                                    control={control}
                                    name={`managements.[${idx}].mailer_receiver`}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <FormControl fullWidth>
                                                <TextField size='small'
                                                    label="Người nhận"
                                                    value={value}
                                                    onChange={onChange}
                                                    InputLabelProps={{ shrink: true }}
                                                    placeholder="ex: vnetwork@vnetwork.vn"
                                                    error={!!errors?.managements?.[idx]?.mailer_receiver?.message}
                                                    helperText={errors?.managements?.[idx]?.mailer_receiver?.message}
                                                />
                                            </FormControl>
                                        )
                                    }}
                                />
                                <Controller
                                    control={control}
                                    name={`managements.[${idx}].mailer_cc`}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <FormControl fullWidth>
                                                <TextField size='small'
                                                    label="CC"
                                                    value={value}
                                                    onChange={onChange}
                                                    InputLabelProps={{ shrink: true }}
                                                    placeholder="ex: vnetwork@vnetwork.vn"
                                                />
                                            </FormControl>
                                        )
                                    }}
                                />
                                <Controller
                                    control={control}
                                    name={`managements.[${idx}].mailer_bcc`}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <FormControl fullWidth>
                                                <TextField size='small'
                                                    label="BCC"
                                                    value={value}
                                                    onChange={onChange}
                                                    InputLabelProps={{ shrink: true }}
                                                    placeholder="ex: vnetwork@vnetwork.vn"
                                                />
                                            </FormControl>
                                        )
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                )
            })}
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 1.5,
                mt: 3
            }}>
                <Button size='small'
                    variant='outlined'
                    sx={{
                        height: { xs: 36, md: 42 },
                        minWidth: { xs: '100%', md: 150 }
                    }}
                    disabled={!!submit}
                    startIcon={<AddOutlined size={14} />}
                    onClick={() => {
                        append({
                            customer_management_name: "",
                            service_referrer: [],
                            mailer_receiver: "",
                            mailer_cc: "",
                            mailer_bcc: ""
                        })
                    }}>
                    Thêm nhóm
                </Button>
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 1.5,
                mt: 3
            }}>
                <Button size='small'
                    variant='outlined'
                    sx={{
                        height: { xs: 36, md: 42 },
                        minWidth: { xs: '100%', md: 150 }
                    }}
                    disabled={!!submit}
                    startIcon={(!!submit && submit === "reset")
                        && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                    onClick={onReset}>
                    Huỷ thao tác
                </Button>
                <Button size='small'
                    variant='contained'
                    sx={{
                        height: {
                            xs: 36,
                            md: 42
                        },
                        width: {
                            xs: '100%',
                            md: 150
                        }
                    }}
                    startIcon={(!!submit && submit === "submit")
                        && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                    disabled={!!submit}
                    type="submit"
                >
                    Cập nhật
                </Button>
            </Box>
        </Paper>
    )
}

export default withSuspense(withPermission(
    ManagementServiceInvoice,
    {
        feature: "customer",
        action: "customer-group-service-mailer-index"
    }), MuiSkeleton["Loading"])