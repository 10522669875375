import DataGrid from "@/components/DataGrid";
import { Box } from "@mui/material";
import clsx from "clsx";
import { useMemo } from "react";
import {
  buildServiceAddonInformations,
  buildServiceFinallyInformations,
  buildServiceInformations,
  getPrivateServiceColumns,
  getServiceColumns,
} from "../../shared/functions";

const HostingService = ({ service, addons }) => {
  const serviceInformations = useMemo(() => {
    return buildServiceInformations({
      service,
      serviceInformations: service?.service_informations,
    });
  }, [service]);

  const addonInformations = useMemo(() => {
    return [...buildServiceAddonInformations({ service, addons })];
  }, [service, addons]);

  const initialColumns = useMemo(() => {
    return [
      {
        ...serviceInformations,
      },
      ...addonInformations,
      {
        ...buildServiceFinallyInformations({
          service,
          serviceInformations,
          addonInformations,
        }),
      },
    ];
  }, [service, serviceInformations, addonInformations]);

  const columns = useMemo(() => {
    return [
      {
        field: "id",
        headerName: ".",
        width: 60,
      },
      {
        field: "service_name",
        headerName: "Sản Phẩm",
        width: 300,
      },
      ...getPrivateServiceColumns({
        service_creatable_type: service?.service_creatable_type,
      }),
      ...getServiceColumns(),
    ];
  }, [service]);
  return (
    <Box sx={{ mt: "10px" }}>
      <DataGrid
        initialState={{
          pinnedColumns: { right: ["actions"] },
        }}
        rows={
          initialColumns?.map((service, index) => ({
            ...service,
            id: index + 1,
          })) || []
        }
        columns={columns}
        components={{
          Toolbar: "disabled",
          Pagination: "disabled",
        }}
        disableSelectionOnClick
        disableColumnFilter
        getRowClassName={({ row }) => {
          return clsx(`grid-dc-vnetwork`, row?.classNames || "");
        }}
      />
    </Box>
  );
};
export default HostingService;
