import BreadCrumb from "@/components/shared/BreadCrumb";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import EmployeeTabs from "../EmployeeTabs";
import Information from "../Information";

const Layout = ({ children }) => {
  const { employee_id } = useParams();
  return (
    <Box>
      <Typography className="page-title">Thông Tin Nhân Viên</Typography>
      <BreadCrumb
        data={[
          { label: "Danh Sách Nhân Viên", reactLink: "/employees" },
          { label: employee_id },
        ]}
      />
      <Information />
      <EmployeeTabs />
      {children}
    </Box>
  );
};

export default Layout;
