import withPermission from "@/hocs/withPermission";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import { Button } from "@mui/material";
import React from "react";

const Preview = ({ serviceId = -1, disabled = true }) => {
  const handlePreview = () => {
    const url = `/services/${serviceId}/preview`;
    window.open(url, `Preview services ${serviceId}`, "height=600,width=600");
  };
  return (
    <Button
      size="small"
      variant="outlined"
      onClick={handlePreview}
      disabled={disabled}
      startIcon={<VisibilityOutlined fontSize="small" />}
    >
      Preview
    </Button>
  );
};

export default withPermission(Preview, {
  feature: "service",
  action: "service-system-preview",
  type: "Button",
});
