import { Box, Typography, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { indigo } from "@mui/material/colors";

const UploadTask = ({ tasks }) => {
  const [showFile, setShowFile] = useState(false);

  const renderProcess = (item) => {
    if (item.retry) {
      return item.retry
    }

    if (item.error) {
      return "Error";
    }

    if (item.percent === 100 && item.loading) {
      return <CircularProgress size={14} />;
    }

    if (item?.percent === 100 && !item.error && !item.loading) {
      return <CheckCircleIcon fontSize="16" color="success" />;
    }

    return `${item?.percent}%`;
  };

  useEffect(() => {
    if (tasks?.length > 0) setShowFile(true);
  }, [tasks?.length]);

  return (
    <Box
      boxShadow="0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
      zIndex={999}
      position="fixed"
      bottom={0}
      right="16px"
      width={400}
      maxWidth="100%"
    >
      <Box
        onClick={() => setShowFile((prev) => !prev)}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ backgroundColor: indigo[700], color: "#fff", cursor: "pointer" }}
        px={2}
        py={1}
      >
        <Typography>Uploading {tasks?.length} items</Typography>

        <Box display="flex" alignItems="center">
          <Box size="small" sx={{ p: 0 }} variant="contained">
            {showFile ? (
              <KeyboardArrowDownIcon
                sx={{ cursor: "pointer", color: "#fff" }}
                fontSize="large"
              />
            ) : (
              <ExpandLessIcon
                sx={{ cursor: "pointer", color: "#fff" }}
                fontSize="large"
              />
            )}
          </Box>
        </Box>
      </Box>

      {showFile && (
        <Box sx={{ backgroundColor: "#fff" }} height={300} overflow="scroll">
          {tasks?.map((item, index) => (
            <Box
              key={item?.name + index}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={2}
            >
              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                  textAlign: "left",
                  fontSize: "14px",
                  flex: 1
                }}
                fontWeight="bold"
              >
                {item?.name}
              </Typography>
              <Typography
                ml={3}
                fontSize="14px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {renderProcess(item)}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default UploadTask;
