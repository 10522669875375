import ProductAddonApi from "@/apis/beta/Product/ProductAddonApi";
import ProductApi from "@/apis/beta/Product/ProductApi";
import { queryClientRefetcher } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useNotificationContext } from "@contexts/notification";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Drawer,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

const SelectAddon = ({ onClose, selecteds = [] }) => {
  const { product_id: productId } = useParams();

  const [searchQuery, setSearchQuery] = useState("");

  const { data } = ProductAddonApi.All();

  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();

  const [pickeds, setPickeds] = useState([]);

  const [submit, setSubmit] = useState(false);

  const onSubmit = async () => {
    setSubmit(true);

    const responses = Promise.all(
      pickeds?.map(async (addon_id) => {
        return await ProductApi.PushAddon({ productId, addon_id });
      })
    );

    if (!responses) {
      return setNotification({
        open: true,
        message: "Failed when add addon.",
        severity: "error",
      });
    }
    setNotification({
      open: true,
      message: "Thêm sản phẩm dịch vụ addon thành công.",
      severity: "success",
    });

    queryClientRefetcher(queryClient, ["product.pricing"]);
    return onClose();
  };
  return (
    <Drawer anchor="right" open={true}>
      <Box sx={{ width: { xs: "100%", md: 400 }, padding: 3, height: "100vh" }}>
        <Typography
          sx={{ fontSize: { xs: 16, md: 18 }, fontWeight: "medium", mb: 1.5 }}
          color="text.black"
        >
          Chọn Sản Phẩm Dịch Vụ ADDON
        </Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            size="small"
            InputProps={{
              startAdornment: (
                <SearchOutlined fontSize="small" sx={{ color: "#777680" }} />
              ),
            }}
            value={searchQuery}
            onChange={(event) => setSearchQuery(event?.target?.value)}
            placeholder="Nhập tên sản phẩm addon tìm kiếm..."
          />
        </FormControl>
        <Box
          sx={{
            maxHeight: "50vh",
            overflow: "hidden",
            overflowY: "auto",
          }}
        >
          {(!!searchQuery
            ? data?.filter((x) =>
                x?.product_name
                  ?.toLowerCase()
                  ?.includes(searchQuery?.toLowerCase())
              )
            : data
          )?.map((x, index) => {
            if (selecteds?.includes(x?.product_addon_id)) return null;
            return (
              <Box sx={{ display: "flex" }} key={index}>
                <FormControlLabel
                  label={x?.product_name}
                  control={
                    <Checkbox
                      size="small"
                      checked={pickeds?.includes(x?.product_addon_id)}
                      defaultChecked={false}
                      onChange={(event) => {
                        if (pickeds?.includes(x?.product_addon_id)) {
                          return setPickeds((prevIds = []) => [
                            ...prevIds?.filter(
                              (i) => i !== x?.product_addon_id
                            ),
                          ]);
                        }
                        return setPickeds((prevIds) => [
                          ...prevIds,
                          x?.product_addon_id,
                        ]);
                      }}
                    />
                  }
                />
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 3,
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: 2,
          }}
        >
          <Button
            size="small"
            variant="outlined"
            disabled={submit}
            onClick={onClose}
          >
            Đóng
          </Button>
          <Button
            size="small"
            variant="contained"
            type="submit"
            disabled={submit}
            sx={{
              minWidth: { xs: "100%", md: 168 },
            }}
            onClick={onSubmit}
            startIcon={
              submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
            }
          >
            Thêm
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default withSuspense(SelectAddon, MuiSkeleton["LinearProgress"]);
