import { Typography } from '@mui/material'
import React from 'react'

const ContactHomie = () => {
    return (
        <Typography>
            Feature in development!
        </Typography>
    )
}

export default ContactHomie