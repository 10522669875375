import { AddOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import ModalInvoiceItem from "../Modals";

const PrivateToolbar = ({ items, invoice }) => {
	const { invoice_id: invoiceId } = useParams();
	const [openModal, setOpenModal] = React.useState(false);
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "flex-end",
				mb: 1.5,
				p: '8px 16px 0 auto'
			}}
		>
			<Button
				size="small"
				variant="outlined"
				sx={{ height: 36, minWidth: 150 }}
				onClick={() => setOpenModal(true)}
				disabled={openModal}
				startIcon={<AddOutlined fontSize="small" />}
			>
				Thêm Dòng
			</Button>
			{openModal && (
				<ModalInvoiceItem.Create
					customerId={invoice?.customer?.customer_id}
					onClose={() => setOpenModal(false)}
					invoiceId={invoiceId}
					invoiceItem={items?.[0] || {}}
				/>
			)}
		</Box>
	);
};

export default PrivateToolbar;
