import { ClearOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DateRangePickerDay as MuiDateRangePickerDay } from "@mui/x-date-pickers-pro/DateRangePickerDay";
import dayjs from "dayjs";

const DateRangePickerDay = styled(MuiDateRangePickerDay)(
	({
		theme,
		isHighlighting,
		isStartOfHighlighting,
		isEndOfHighlighting,
		outsideCurrentMonth,
	}) => ({
		...(!outsideCurrentMonth &&
			isHighlighting && {
			borderRadius: 0,
			backgroundColor: "#00b0FF5F",
			"&:hover, &:focus": {
				backgroundColor: "#00b0ff",
			},
		}),
		...(isStartOfHighlighting && {
			backgroundColor: "#00b0FF5F",
			borderTopLeftRadius: "50%",
			borderBottomLeftRadius: "50%",
		}),
		...(isEndOfHighlighting && {
			borderTopRightRadius: "50%",
			borderBottomRightRadius: "50%",
			backgroundColor: "#00b0FF5F",
		}),
	})
);

const DatePicker = ({ value, handler, ...props }) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DateRangePicker
				value={[...value?.map((x) => !!x ? dayjs(x) : null)]}
				format="DD/MM/YYYY"
				defaultValue={[null, null]}
				onChange={(newValue) => {
					handler(newValue);
				}}
				localeText={{
					start: "Thời gian bắt đầu",
					end: "Thời gian kết thúc",
				}}
				slotProps={{
					textField: () => ({
						InputLabelProps: { shrink: true },
						InputProps: {
							endAdornment: !!value[0] && <ClearOutlined fontSize="small" sx={{
								color: "#7776808F",
								cursor: "pointer"
							}} onClick={(event) => {
								event.preventDefault();
								handler([null, null])
							}} />
						}
					})
				}}
				slots={{
					day: DateRangePickerDay,

				}}
				renderLoading={() => "..."}
				onError={(error) => console.error('Error on MuiDatePicker' + (error?.message || JSON.stringify(error)))}
				{...props}
				sx={{
					...props?.sx,
					label: {
						fontSize: 14,
					},
					".MuiInputBase-root": {
						height: { xs: 40, md: 42 },
						fontSize: 14,
					},
					"&.MuiStack-root": {
						".MuiTypography-body1": { display: "none" },
					},
				}}
			/>
		</LocalizationProvider>
	);
};

export default DatePicker;
