import { encodeBase64, requestSimple, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class EmployeeApi {
  List = ({ query = null, searchQuery = null, page = 1, pageSize = 20 }) => {
    return useQueryWithCustomized(
      ["employee.list", query, searchQuery, page, pageSize],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/employees?${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ""
          }&page=${page}&page_size=${pageSize}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              employees: data?.data?.employees || [],
              pagination: data?.data?.pagination
            }
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
        enabled: !!localStorage.getItem("SA"),
      }
    );
  };

  ListEmployees = () => {
    return useQueryWithCustomized(
      ["employees.all"],
      async () => {
        return await requestWithToken(`${API_V3_URL}/employees/list`)
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.employees;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
        // enabled: !!localStorage.getItem("SA"),
      }
    );
  };

  All = () => {
    return useQueryWithCustomized(
      ["employees.preview-all"],
      async () => {
        return await requestWithToken(`${API_V3_URL}/employees/get_employees`)
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.employees;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        // suspense: true,
        staleTime: 10 * 60 * 1000,
        // enabled: !!localStorage.getItem("SA"),
      }
    );
  };

  CreateTemp = async (data) => {
    return await requestWithToken(
      `${API_V3_URL}/employees/create_employee_temp`,
      "POST",
      {
        employee: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Detail = (id) => {
    return useQueryWithCustomized(
      ["employees.detail", id],
      async () => {
        if (!id) return null;
        return await requestWithToken(`${API_V3_URL}/employees/${id}`)
          .then(({ data }) => {
            if (data?.error) return data;

            return data?.data?.employee;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
        enabled: !!localStorage.getItem("SA"),
      }
    );
  };

  CheckTokenAccount = async ({ token = "" }) => {
    return await requestSimple(
      `${API_V3_URL}/check_token_account?token=${token}`,
      "GET",
      {},
      {},
      {
        withCredentials: true,
      }
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error?.response;
      });
  };

  ResetPasswordRequest = async ({ email_employee }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/employees/reset_password_request`,
      "POST",
      {
        email_employee,
      }
    );

    return response?.data;
  };

  ResendToken = async ({ employeeId = -1 }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/employees/${employeeId}/resend_employee_token`,
      "POST",
      {
      }
    );

    return response?.data;
  };

  UpdateLeaveDay = async (values) => {
    const response = await requestWithToken(
      `${API_V3_URL}/employees/update_leave_day`,
      "PUT",
      values
    );

    return response?.data;
  };

  Disabled = async ({ employee_id }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/employees/disable_employee`,
      "POST",
      {
        employee_id,
      }
    );

    return response?.data;
  };

  Update = async ({ employee, employee_information, employee_id }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/employees/${employee_id}`,
      "PUT",
      {
        employee,
        employee_information,
      }
    );
    return response?.data;
  };

  UpdateWorkDates = async ({ employeeId, workdates = {} }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/employees/${employeeId}/work_dates`,
      "PUT",
      {
        employee: {
          ...workdates
        }
      }
    );
    return response?.data;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new EmployeeApi();
