import CVApi from "@/apis/beta/Employee/CVApi";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import { useConfirmContext } from "@contexts/confirm";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/material";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import {
  CV_SCREEN_RESULTS,
  DEPARTMENT,
  FINAL_STATUS,
  TYPES_OF_POSITION,
} from "../../shared";
import { blue, red } from "@mui/material/colors";
import DatePicker from "@components/DatePicker";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import SelectSourceCv from "../../Shared/SelectSourceCv";
import { usePreviewFileContext } from "@contexts/preview-file";

const ResumeInfo = ({ data, refetch, setShowDialogSendMailInterview }) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cv_source_id: data?.data?.cv_source_id,
      types_of_position: data?.data?.types_of_position,
      department: data?.data?.department,
      cv_screen_results: data?.data?.cv_screen_results,
      final_status: data?.data?.final_status,
      hr_comment: data?.data?.hr_comment,
      leader_comment: data?.data?.leader_comment,
      interview_note: data?.data?.interview_note,
      url: data?.data?.url,
      name: data?.data?.name,
      email: data?.data?.email,
      phone: data?.data?.phone,
      backup_phone: data?.data?.backup_phone,
      address: data?.data?.address,
      about: data?.data?.about,
      job_title: data?.data?.job_title,
      day_of_birth: data?.data?.day_of_birth,
    },
  });

  const preview = usePreviewFileContext()

  const { showConfirmation, closeConfirmation } = useConfirmContext();

  const dataInfo = [
    {
      label: "Name",
      value: (
        <Controller
          rules={{ required: { value: true, message: "Required" } }}
          control={control}
          name="name"
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              fullWidth
              helperText={errors?.name?.message}
              error={!!errors?.name}
            />
          )}
        />
      ),
    },
    {
      label: "Email",
      value: (
        <Controller
          rules={{
            required: { value: true, message: "Required" },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
          }}
          control={control}
          name="email"
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              fullWidth
              helperText={errors?.email?.message}
              error={!!errors?.email}
            />
          )}
        />
      ),
    },
    {
      label: "Day Of Birth",
      value: (
        <Controller
          control={control}
          name="day_of_birth"
          render={({ field }) => (
            <FormControl fullWidth>
              <DatePicker {...field} size="small" fullWidth />
              {/* {errors?.day_of_birth?.message && (
                <ErrorHelperText message={errors?.day_of_birth?.message} />
              )} */}
            </FormControl>
          )}
        />
      ),
    },
    {
      label: "Phone",
      value: (
        <Controller
          rules={{
            required: { value: true, message: "Required" },
          }}
          control={control}
          name="phone"
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              fullWidth
              helperText={errors?.phone?.message}
              error={!!errors?.phone}
            />
          )}
        />
      ),
    },
    {
      label: "Address",
      value: (
        <Controller
          rules={{ required: { value: true, message: "Required" } }}
          control={control}
          name="address"
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              fullWidth
              helperText={errors?.address?.message}
              error={!!errors?.address}
            />
          )}
        />
      ),
    },
    {
      label: "Position Applied",
      value: (
        <Controller
          rules={{ required: { value: true, message: "Required" } }}
          control={control}
          name="job_title"
          render={({ field }) => (
            <FormControl fullWidth>
              <Select
                {...field}
                size="small"
                disabled={jobLoading}
                fullWidth
                error={!!errors?.job_title}
              >
                {jobs?.data?.map(({ id, attributes }) => (
                  <MenuItem value={attributes?.title} key={id}>
                    {attributes?.title}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ color: red["400"] }}>
                {errors?.job_title?.message}
              </FormHelperText>
            </FormControl>
          )}
        />
      ),
    },
    {
      label: "Date Apply",
      value: dayjs(data?.data.created_at).format("DD/MM/YYYY"),
    },
    {
      label: "Xem Resume / Portfolio",
      value: (
        <Typography
          onClick={() => {
            if (!data?.data?.url) return;

            data?.data?.type_file === "file"
              ? preview.show("submit_cv" + "/" + data?.data?.url)
              : window.open(data?.data?.url);
          }}
          fontSize={14}
          style={{
            color: blue[500],
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Xem chi tiết
        </Typography>
      ),
    },
    {
      label: "Source",
      value: (
        <Controller
          control={control}
          rules={{ required: "Source is required" }}
          name="cv_source_id"
          render={({ field: { onChange, value, ...field } }) => {
            return (
              <SelectSourceCv field={field} value={value} onChange={onChange} />
            );
          }}
        />
      ),
    },
    {
      label: "Types of Position",
      value: (
        <Controller
          control={control}
          name="types_of_position"
          render={({ field }) => (
            <Select {...field} size="small" fullWidth>
              {TYPES_OF_POSITION.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      ),
    },
    {
      label: "Department",
      value: (
        <Controller
          control={control}
          name="department"
          render={({ field }) => (
            <Select {...field} size="small" fullWidth>
              {DEPARTMENT.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      ),
    },
    {
      label: "CV Screen Results",
      value: (
        <Controller
          control={control}
          name="cv_screen_results"
          render={({ field }) => (
            <Select
              {...field}
              size="small"
              fullWidth
              disabled={data?.data?.cv_screen_results === 1}
            >
              {CV_SCREEN_RESULTS.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      ),
    },
    {
      label: "Final Status",
      value: (
        <Controller
          control={control}
          name="final_status"
          render={({ field }) => (
            <Select {...field} size="small" fullWidth>
              {FINAL_STATUS.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      ),
      hidden: data?.data?.cv_screen_results !== 1,
    },
  ];

  const { data: jobs, isLoading: jobLoading } = useQueryWithCustomized(
    ["get-job"],
    async () => {
      const response = await fetch(`${process.env.REACT_APP_JOBS}`);
      const data = await response.json();
      return data;
    }
  );

  const { mutateAsync, notify, isLoading } = useMutationWithNotification(
    CVApi.Accept
  );

  const handleMailTemplate = (mailTemplate, values) => {
    showConfirmation({
      title: "Thao tác cập nhật này sẽ đi kèm với việc gửi email cho ứng viên",
      description: "Template Email: " + mailTemplate,
      open: true,
      loading: isLoading,
      handler: () => {
        if (mailTemplate === "send_mail_interview") {
          setShowDialogSendMailInterview({
            data: {
              id: data?.data?.id,
              mail_send: [
                ...(data?.data?.mail_send?.length > 0
                  ? data?.data?.mail_send
                  : []),
                mailTemplate,
              ],
              mailTemplate,
              ...values,
            },
            show: true,
          });

          return closeConfirmation();
        }

        return updateCV(mailTemplate, values);
      },
    });
  };

  const updateCV = async (mailTemplate, values) => {
    const response = await mutateAsync({
      id: data?.data?.id,
      mail_send: [
        ...(data?.data?.mail_send?.length > 0 ? data?.data?.mail_send : []),
        mailTemplate,
      ],
      mailTemplate,
      ...values,
      cv_source_id: values?.cv_source_id?.value || values?.cv_source_id,
    });

    if (!response?.errors) notify("success", "Cập nhật thành công");

    refetch();
  };

  const onSubmit = async (values) => {
    const mailTemplate =
      CV_SCREEN_RESULTS?.find(
        (item) => item?.value === watch("cv_screen_results")
      )?.mail_template ||
      FINAL_STATUS?.find((item) => item?.value === watch("final_status"))
        ?.mail_template;

    if (
      [
        ...(data?.data?.mail_send?.length > 0 ? data?.data?.mail_send : []),
      ]?.includes(mailTemplate)
    ) {
      if (
        window.confirm(
          "Có vẻ template mail này đã được gửi trước đó, bạn có muốn gửi lại"
        )
      )
        return handleMailTemplate(mailTemplate, values);

      return;
    }

    if (mailTemplate) return handleMailTemplate(mailTemplate, values);

    updateCV(mailTemplate, values);
  };

  return (
    <Paper
      sx={{ p: 3, mb: 1 }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        {dataInfo
          ?.filter((item) => !item?.hidden)
          ?.map((item) => (
            <Grid key={item?.label} item xl={3} lg={3} md={3} sm={6} xs={12}>
              <Typography sx={{ mb: 1, color: "#777680" }}>
                {item?.label}:
              </Typography>
              <Typography sx={{ color: "#00000F" }}>{item?.value}</Typography>
            </Grid>
          ))}
      </Grid>

      {data?.data?.final_status !== 5 && (
        <Box mt={2}>
          <Typography sx={{ mb: 1, color: "#777680" }}>Nhận xét:</Typography>
          <Controller
            control={control}
            name="interview_note"
            render={({ field }) => (
              <TextField
                multiline
                fullWidth
                {...field}
                placeholder="Nhận xét sau phỏng vấn..."
              />
            )}
          />
        </Box>
      )}

      <Box mt={2}>
        <Typography sx={{ mb: 1, color: "#777680" }}>
          Nhận xét của HR:
        </Typography>
        <Controller
          control={control}
          name="hr_comment"
          render={({ field }) => (
            <TextField multiline fullWidth {...field} placeholder="HR..." />
          )}
        />
      </Box>

      <Box mt={2}>
        <Typography sx={{ mb: 1, color: "#777680" }}>
          Nhận xét của Leader:
        </Typography>
        <Controller
          control={control}
          name="leader_comment"
          render={({ field }) => (
            <TextField multiline fullWidth {...field} placeholder="Leader..." />
          )}
        />
      </Box>

      <Button
        type="submit"
        variant="contained"
        size="small"
        sx={{ mt: 2 }}
        fullWidth
        disabled={isLoading}
        startIcon={isLoading && <CircularProgress size={14} />}
      >
        Update Resume
      </Button>
    </Paper>
  );
};

export default ResumeInfo;
