import React from 'react'
import { TextField, Button } from "@mui/material"
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';

const InputFileCustomize = ({
    file,
    accept,
    disabled,
    fileHandler
}) => {
    const inputFileRef = React.useRef(null)

    const handleClickInputFile = () => {
        inputFileRef?.current?.click()
    }

    return (
        <React.Fragment>
            <TextField
                size='small'
                disabled={disabled}
                placeholder='Chọn Tệp Tin'
                value={file ? file?.name : "Chọn Tệp Tin"}
                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        return handleClickInputFile()
                    }
                }}
                InputProps={{
                    sx: {
                        pr: 0,
                    },
                    endAdornment: <Button variant='contained'
                        startIcon={<AttachmentOutlinedIcon />}
                        size='small' sx={{
                            height: '100%',
                            boxShadow: "none",
                            width: "50%",
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0
                        }}>
                        Đính Kèm Tập Tin
                    </Button>
                }}
                sx={{ cursor: 'pointer' }}
                onClick={handleClickInputFile}
                fullWidth
            />
            <input
                type="file"
                disabled={disabled}
                accept={accept || "all"}
                ref={inputFileRef}
                onChange={(e) => fileHandler(e.target.files)}
                style={{ display: "none" }}
            />
        </React.Fragment>
    )
}

export default InputFileCustomize