import React from 'react'
import { FormControl } from '@mui/material'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SandboxApi from '@/apis/beta/Sandbox/SandboxApi'

const Ward = ({
    preSelected,
    onChange,
    province = "",
    district = "",
    error: {
        isError = false,
        message = ""
    } }) => {
    const [wards] = (!province || province === '-1' || !district || district === '-1') ? [] : SandboxApi.Wards(province, district)

    return (
        <FormControl fullWidth>
            <SingleSelectCustomize
                disabled={!province || !district || province === ""
                    || district === "" || province === "-1" || district === "-1"}
                value={wards?.find(x => x?.value === preSelected) || null}
                options={wards}
                input={{
                    label: "Phường/ Xã",
                    placeholder: "Chọn phường/ xã..."
                }}
                onChange={onChange}
            />
            {!!isError && <ErrorHelperText message={message} />}
        </FormControl>
    )
}

export default withSuspense(Ward, MuiSkeleton["TextField"])
