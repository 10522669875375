/* eslint-disable react/jsx-no-target-blank */
import SingleDocument from '@components/shared/SingleDocument'
import { Typography } from '@mui/material'
import React from 'react'

const Attachment = ({
    attachments = [],
    linked = ''
}) => {
    const handleClick = async (data) => {
        try {
            const link = data?.attachment_file_url
                ? `${process.env.REACT_APP_DOMAIN_FILE
                }${data?.attachment_file_url.replace("pub/", "")}`
                : "#";

            let contractFilename = data?.attachment_file_name

            const anchorElement = document.createElement('a');
            anchorElement.href = link;
            anchorElement.download = contractFilename?.toUpperCase()
            return await fetch(link)
                .then(response => response.blob())
                .then(blob => {
                    const url = URL.createObjectURL(blob);
                    anchorElement.href = url;
                    anchorElement.dispatchEvent(new MouseEvent('click'));
                });

        } catch (e) {
            console.log(e);
        }
    }
    if (!attachments?.length && !linked) return null
    return (
        <>
            <Typography sx={{
                fontSize: 14,
                fontWeight: 'medium',
                mt: 0.5,
                mb: 0.5,
                color: '#00001F'
            }}>
                Tệp đính kèm
            </Typography>
            {attachments?.map((attachment, index) => {
                return (
                    <SingleDocument
                        handleOpen={() => handleClick(attachment)}
                        key={index}
                        document={{
                            name: attachment?.attachment_file_name,
                            size: attachment?.attachment_file_size,
                            type: attachment?.attachment_file_type,
                        }}
                    />
                )
            })}
            {!!linked && <a target="_blank" href={linked}>Download </a>}
        </>
    )
}

export default Attachment