import DayoffApi from '@/apis/beta/Employee/DayoffApi'
import { format_date_short, queryClientRefetcher } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import { DAYOFF_TIME_OPTIONS } from '@/libs/constants'
import DataGridCustomize from '@components/DataGrid'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { useEmployeeContext } from '@contexts/employee'
import { useNotificationContext } from '@contexts/notification'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, TextField, Typography } from '@mui/material'
import { orange } from '@mui/material/colors'
import React from 'react'
import { useQueryClient } from 'react-query'

const ModalConfirmMultiple = ({ dayoffs = [], onClose }) => {
    const { employee } = useEmployeeContext()
    const { data: { master_inspectors } } = DayoffApi.CurrentRoles()

    const queryClient = useQueryClient()

    const { setNotification } = useNotificationContext()
    const [feedback, setFeedback] = React.useState('Oke/ Duyệt')

    const [columns] = React.useState([
        {
            field: 'day_off_id', headerName: 'Mã Phiếu',
            width: 120,
        }, {
            field: 'employee_name', headerName: 'Tên Nhân Viên',
            width: 200,
        }, {
            field: "day_off_number",
            headerName: "Số Ngày Nghỉ",
            width: 120,
        },
        {
            field: "day_off_option",
            headerName: "Chi Tiết",
            width: 400,
            renderCell: ({ value }) => (
                <Typography sx={{ fontSize: 14 }}>
                    {format_date_short(value?.startdayoff)} {`-`}{" "}
                    {
                        DAYOFF_TIME_OPTIONS?.find((o) => o?.value === value?.dayoff_option)
                            ?.label
                    }
                </Typography>
            ),
        },
    ])

    const onSubmit = async () => {
        const isAdmin = master_inspectors?.includes(employee?.employee_id)
        let form = isAdmin ? {
            day_off_leader_confirm: true,
            day_off_feedback_bod: feedback,
            day_off_bod_confirm: true,
            day_off_status: 2
        } : {
            day_off_leader_confirm: true,
            day_off_feedback_lead: feedback,
            day_off_status: 4
        }

        const requests = dayoffs?.map(async (dayoff) => {
            return DayoffApi.Approve({
                id: dayoff?.day_off_id,
                data: form
            })
        })
        const response = await Promise.all(requests)
        const success = response?.filter(s => s?.status === 200)
        // const failed = response?.filter(s => s?.errors)
        setNotification({
            open: true,
            message: `Các phiếu ${success?.map(item => item?.data?.dayoff?.day_off_id).join(', ')} đã được duyệt thành công.`,
            severity: "success"
        })

        queryClientRefetcher(queryClient, [
            "business.list",
            "business.show"
        ])
        return onClose()
    }
    return (
        <Dialog
            fullWidth
            open={true}
            maxWidth="md"
        >
            <DialogTitle>
                Bạn đang chọn chức năng duyệt nhiều phiếu.
                <Typography sx={{ fontSize: 14, color: orange[500] }}>
                    Điều này có thể xảy ra trường hợp bỏ lỡ thông tin quan trọng trong phiếu công tác. Vui lòng cân nhắc lại trước khi thao tác.
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: "medium" }}>
                    Danh sách phiếu nghỉ phép đang được chọn
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DataGridCustomize
                    rows={dayoffs?.map((dayoff) => ({ ...dayoff, id: dayoff?.day_off_id })) || []}
                    columns={columns}
                    components={{
                        Toolbar: "disabled",
                        Pagination: "disabled"
                    }}
                />
            </DialogContent>
            <DialogContentText sx={{ p: 3 }}>
                <FormControl fullWidth>
                    <TextField
                        label="Phản hồi của bạn *"
                        InputLabelProps={{
                            shrink: true
                        }}
                        multiline
                        minRows={2}
                        value={feedback}
                        onChange={(event) => setFeedback(event?.target?.value)}
                    />
                </FormControl>
            </DialogContentText>
            <DialogActions>
                <Button size='small' onClick={onClose}>
                    Huỷ Thao Tác
                </Button>
                <Button
                    size='small'
                    variant='contained'
                    onClick={onSubmit}
                >
                    Xác nhận
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withSuspense(ModalConfirmMultiple, MuiSkeleton["LinearProgress"])