import SubcontractApi from "@/apis/beta/Contract/SubcontractApi";
import { subcontractValidator } from "@/libs/validators";
import NumericFormatCustom from "@components/NumericFormatCustom";
import DatePicker from "@components/datepickers/DateRangPicker";
import { useNotificationContext } from "@contexts/notification";
import { vestResolver } from "@hookform/resolvers/vest";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import _ from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import SubcontractCode from "./SubcontractCode";
import { queryClientRefetcher } from "@/functions";
import withPermission from "@/hocs/withPermission";
import { useState } from "react";
import ModalConfirm from "@components/shared/ModalConfirm";
import Finance from "../helpers/Finance";
import FileApi from "@/apis/shared/File/FileApi";

const Create = ({ contract, type = "create", handleClose }) => {
  const queryClient = useQueryClient();
  const [attachment, setAttachment] = useState({
    file: null,
    loading: false,
    percen: 0,
  });

  const methods = useForm({
    resolver: vestResolver((props) =>
      subcontractValidator({
        ...props,
      })
    ),
    defaultValues: {
      subcontract_code: `${contract?.contract_number_id}/PL-`,
      subcontract_code_id: "",
      subcontract_name: "",
      subcontract_description: "",
      subcontract_value: 0,
      subcontract_note: '',
      subcontract_effective_date: contract?.subcontract_effective_date,
      subcontract_expiry_date: contract?.subcontract_expiry_date,
      subcontract_status: 1
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue
  } = methods
  const { setNotification } = useNotificationContext();
  const { mutateAsync, isLoading } = useMutation(SubcontractApi.Create)
  const [warning, setWarning] = useState({ enabled: false, message: '' })

  const onSubmit = async (values) => {

    let form = {
      ...(_.pick(values, [
        'subcontract_name',
        'subcontract_description',
        'subcontract_note',
        'subcontract_value',
        'subcontract_status',
        'subcontract_effective_date', 'subcontract_expiry_date'
      ]))
    }
    form = {
      ...form,
      subcontract_code: `${values?.subcontract_code}${values?.subcontract_code_id}`
    }
    const subcontractCode = await SubcontractApi.CheckCode({
      contractId: contract?.contract_id,
      subcontractCode: form?.subcontract_code
    })
    if (!!subcontractCode && !!subcontractCode?.data?.subcontracts?.length) {
      return setWarning({
        enabled: true,
        message: "Cảnh báo, mã phụ lục hợp đồng đã tồn tại.",
        data: form
      })
    }
    const res = await submitSubcontract(form)
    if (res?.status === 200) {
      callback()
      handleClose()
      return setNotification({
        open: true,
        message: res?.message,
        severity: "success",
      })
    }
    return setNotification({
      open: true,
      message: res?.message,
      severity: "error",
    })
  };

  const callback = () => {
    queryClientRefetcher(queryClient, [
      "contract.subcontract.list",
      "subcontract_code_number"
    ])
  }

  const submitSubcontract = async (form) => {
    let uploaded = null
    if (!!attachment?.file && !!attachment.file?.name) {
      uploaded = await onSubmitAttachment();

      if (!uploaded) {
        // setSubmit(false);
        return setNotification({
          open: true,
          message: "Upload file thất bại.",
          severity: "error",
        });
      }
    }
    const response = await mutateAsync({
      contractId: contract?.contract_id,
      data: {
        ...form,
        subcontract_document: !uploaded ? [] : [
          {
            name: attachment?.file?.name,
            type: attachment?.file?.type,
            size: attachment?.file?.size,
            url: `pub/subcontracts/documents/${uploaded}`
          }
        ]
      }
    })
    if (!response || response?.errors) {
      return {
        status: 400,
        message: response?.errors?.[0]?.message || 'Thêm phụ lục hợp đồng mới thất bại.'
      }
    }

    handleClose()
    return {
      status: 200,
      message: 'Thêm phụ lục hợp đồng thành công.'
    }
  }

  const onSubmitAttachment = async () => {
    const folder = `subcontracts/documents`;
    const file = attachment?.file;

    const token = await FileApi.GetPresignedTokenAttachment({
      filename: {
        name: file?.name,
        folder,
      },
    });
    setAttachment((prevState) => ({
      ...prevState,
      loading: false,
      percen: 0,
    }));

    await FileApi.UploadAttachmentToStorage({
      token: token?.token,
      file,
      handleUploadFile: setAttachment,
    });

    return token?.filename;
  };


  return (
    <FormProvider {...methods}>
      {warning?.enabled && <ModalConfirm
        dialog={{
          title: warning?.message,
          content: `Mã PLHĐ: ${warning?.data?.subcontract_code} đã tồn tại, vui lòng đổi mã khác và khởi tạo lại PLHĐ hoặc nhấn tiếp tục để tạo PLHĐ với mã PLHĐ này.`
        }}
        handleSubmit={() => submitSubcontract(warning?.data)}
        callback={() => callback()}
        handleClose={() => setWarning({ enabled: false, data: undefined, message: '' })}
      />}
      <Box
        sx={{ p: 3, width: { xs: '100%', md: 600 } }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
            mb: 2,
          }}
        >
          {type === "create"
            ? "Thêm Mới Phụ Lục Hợp Đồng"
            : "Chỉnh Sửa Phụ Lục Hợp Đồng"}
        </Typography>
        <Typography>Số Phụ Lục</Typography>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
            <Controller
              control={control}
              name="subcontract_code"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.subcontract_code}
                  helperText={errors?.subcontract_code?.message}
                  disabled
                  placeholder="Nhập số phụ lục"
                  size="small"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <SubcontractCode contractId={contract?.contract_id} />
          </Grid>
        </Grid>
        <Controller
          control={control}
          name="subcontract_name"
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors?.subcontract_name}
              helperText={errors?.subcontract_name?.message}
              placeholder="Nhập tên phụ lục hợp đồng..."
              size="small"
              fullWidth
              label="Tên Phụ Lục Hợp Đồng *"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
        <FormControl fullWidth sx={{ mt: 2 }}>
          <DatePicker
            value={[dayjs(watch(`subcontract_effective_date`)), dayjs(watch(`subcontract_expiry_date`))]}
            handler={(newDates) => {
              setValue(`subcontract_effective_date`, newDates[0])
              setValue(`subcontract_expiry_date`, newDates[1])
            }}
          />
        </FormControl>
        <Controller
          name="subcontract_value"
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <TextField
                label="Giá trị hợp đồng *"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={value}
                onChange={onChange}
                InputProps={{
                  inputComponent: NumericFormatCustom
                }}
                placeholder="Nhập giá trị hợp đồng..."
              />
            </FormControl>
          )} />
        <Controller
          name="subcontract_note"
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <TextField
                label="Ghi chú *"
                InputLabelProps={{ shrink: true }}
                multiline
                size="small"
                minRows={3}
                value={value}
                onChange={onChange}
                placeholder="Nhập ghi chú hợp đồng..."
              />
            </FormControl>
          )} />
        <Finance attachment={attachment} setAttachment={setAttachment} />
        <Box sx={{
          display: "flex", justifyContent: "flex-end", mt: 2, gap: 2, flexDirection: {
            xs: 'column', md: 'row'
          }
        }}>
          <Button
            size="small"
            disabled={isLoading}
            sx={{ height: 36, mr: 2 }}
            onClick={handleClose}
            variant="text"
          >
            Huỷ Thao Tác
          </Button>
          <Button
            size="small"
            sx={{ height: 36 }}
            disabled={isLoading}
            variant="contained"
            startIcon={isLoading && <CircularProgress size={14} sx={{ color: "#777680" }} />}
            type="submit"
          >
            {type === "create" ? "tạo mới" : "cập nhật"}
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};
export default withPermission(Create, {
  feature: 'subcontract',
  action: 'create',
  type: 'Button'
})
