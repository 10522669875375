import DepartmentApi from '@/apis/beta/Employee/DepartmentApi'
import { queryClientRefetchScope } from "@/functions"
import withPermission from '@/hocs/withPermission'
import { useNotificationContext } from '@contexts/notification'
import { Box, Button, CircularProgress, Drawer, FormControl, TextField, Typography } from '@mui/material'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

const DepartmentCreate = ({
    onClose
}) => {
    const methods = useForm({
        defaultValues: {
            department_name: '',
            department_code: ''
        },
    })
    const { setNotification } = useNotificationContext()
    const queryClient = useQueryClient()

    const { mutateAsync, isLoading } = useMutation(DepartmentApi.Create)

    const onSubmit = async (values) => {
        const response = await mutateAsync({ data: values })
        if (!response || response?.errors) {
            return setNotification({ open: true, message: response?.errors?.[0]?.message, severity: "error" })
        }

        queryClientRefetchScope(queryClient, 'department')
        setNotification({ open: true, message: "Tạo phòng ban thành công.", severity: "success" })
        return onClose()
    }
    return (
        <Drawer open={true} anchor='right' sx={{ p: 2 }}>
            <FormProvider {...methods}>
                <Box component="form" sx={{ p: '24px 16px' }}>
                    <Typography sx={{ fontSize: { xs: 16, md: 18 }, fontWeight: 'medium' }}>
                        Thêm Phòng Ban Mới
                    </Typography>
                    <Controller
                        name='department_name'
                        render={({ field }) => (
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <TextField
                                    {...field}
                                    label="Tên phòng ban *"
                                    InputLabelProps={{ shrink: true }}
                                    placeholder='Nhập tên phòng ban...'
                                    required
                                    size='small'
                                />
                            </FormControl>
                        )}
                    />
                    <Controller
                        name='department_code'
                        render={({ field }) => (
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <TextField
                                    {...field}
                                    label="Mã phòng ban *"
                                    InputLabelProps={{ shrink: true }}
                                    placeholder='Mã phòng ban...'
                                    required
                                    size='small'
                                />
                            </FormControl>
                        )}
                    />
                    <Box sx={{ pt: 3, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}>
                        <Button size='small'
                            variant='outlined'
                            disable={isLoading}
                            onClick={onClose}
                        >
                            Huỷ Thao Tác
                        </Button>
                        <Button size='small'
                            disable={isLoading}
                            variant='contained'
                            onClick={methods.handleSubmit(onSubmit)}
                            startIcon={isLoading && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                        >
                            Lưu Thay Đổi
                        </Button>
                    </Box>
                </Box>
            </FormProvider>
        </Drawer>
    )
}

export default withPermission(DepartmentCreate, { feature: 'department', action: 'create' })