import CustomerContactApi from '@/apis/beta/Customer/CustomerContactApi'
import { queryClientRefetchScope } from '@/functions'
import ModalConfirm from '@components/shared/ModalConfirm'
import { EditOutlined, LockOutlined } from '@mui/icons-material'
import { Button, Drawer } from '@mui/material'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import ContactUpdateForm from "../../../Update"
import _ from 'lodash'

const ContactUpdate = ({ customerId = -1, contactId, contact }) => {

    const [onEdit, setEdit] = useState(false)

    return (
        <>
            {onEdit && <Drawer anchor='right' open={true} sx={{ width: { xs: '100%', md: 650 } }}
                onClose={() => setEdit(false)}
            >
                <ContactUpdateForm
                    onClose={() => setEdit(false)}
                    customerId={customerId}
                    contactId={contactId}
                    defaultValues={{
                        ...(_.pick(contact, ['contact_type', 'contact_label', 'contact_ordered', 'contact_status'])),
                        personal_information: {
                            name: contact?.name,
                            email: contact?.email,
                            country_phone_number: contact?.country_phone_number,
                            phone_number: contact?.phone_number,
                            gender: contact?.gender,
                            position: contact?.position,
                            marriage: contact?.marriage,
                            birthday: contact?.birthday
                        },
                        company: {},
                        customer_service_information: {
                            customer_service_interestings: contact?.customer_service_interestings || [],
                            customer_cs_followers: contact?.customer_cs_followers || [],
                            customer_sale_followers: contact?.customer_sale_followers || [],
                            customer_industry: contact?.customer_industry,
                            customer_source: contact?.customer_source,
                            customer_note: contact?.customer_note,
                            customer_source_event: contact?.customer_source_event,
                            customer_contact_social: contact?.customer_contact_social || []
                        }
                    }} />
            </Drawer>}
            <Button
                size='small'
                onClick={() => setEdit(true)}
                startIcon={<EditOutlined fontSize='small' />} variant='outlined' disabled={onEdit}>
                Chỉnh Sửa
            </Button>
        </>

    )
}

export default ContactUpdate