import UploadTask from "@features/Module/Drives/Home/UploadTask";
import React, { useContext } from "react";

const UploadFileContext = React.createContext();

export const UploadFileContextProvider = ({ children }) => {
  const [task, setTask] = React.useState([]);

  return (
    <UploadFileContext.Provider value={{ task, setTask }}>
      {children}
      {!!task?.length && <UploadTask tasks={task} />}
    </UploadFileContext.Provider>
  );
};

export const useUploadFile = () => {
  return useContext(UploadFileContext);
};
