import { format_numeral_price } from '@/functions'
import { Typography, Box } from '@mui/material'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const Software = ({ position }) => {

    const { control } = useFormContext()

    const softwareAddon = useWatch({
        control: control,
        name: `product_configoptions.[${position}]`
    })

    if (!softwareAddon || !softwareAddon?.select || parseInt(softwareAddon?.select?.product_addon_id) < 0) return;

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1.5 }}>
            <Typography sx={{ color: "#777680" }}>
                {softwareAddon?.select?.product_addon_name}
            </Typography>
            <Typography component="span">
                <Typography sx={{ display: 'inline-flex', textDecoration: 'underline', color: "#777680" }}>đ</Typography>
                {format_numeral_price(softwareAddon?.select?.product_addon_pricing)}
            </Typography>
        </Box>
    )
}

export default Software