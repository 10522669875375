import withPermission from '@/hocs/withPermission'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import Header from './Header'

const Detail = () => {
    const { employee_id } = useParams()

    return (
        <React.Fragment>
            <Header />
            <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: 200 }}>
                <Typography component="div" color="custom.grey" sx={{
                    m: 'auto',
                    flexWrap: { xs: 'wrap', md: 'auto' }
                }}>
                    Feature in development!
                    <Link to={`/employees/${employee_id}/work_contracts`} replace>
                        <Typography color="primary" sx={{
                            m: 'auto 0 auto 4px',
                            display: 'inline',
                            textDecoration: 'underline'
                        }}>Back to employee workcontracts.</Typography>
                    </Link>
                </Typography>
            </Box>
        </React.Fragment>
    )
}

export default withPermission(Detail, { feature: "work-contract", action: 'show' })