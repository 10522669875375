import { CUSTOMER_REGISTER_TYPES } from '@/libs/constants'
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import SelectCustomer from './SelectCustomer'

const Customer = ({ customerId = -1, locked = false }) => {
    console.log(locked)
    const { control, setValue, watch } = useFormContext()

    useEffect(() => {
        setValue(`metadata.customer.customer_id`, customerId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId])
    return (
        <Box sx={{
            mt: 1,
            p: 2,
            border: 1,
            borderRadius: '4px',
            borderColor: "#9e9e9e"
        }}>
            <Typography sx={{ fontWeight: 'medium' }}>Thông Tin Khách Hàng</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex', width: '100%',
                        flexDirection: { xs: 'column', md: 'row' },
                    }}>
                        <Typography sx={{
                            color: "#777680",
                            minWidth: 150,
                            m: 'auto 0'
                        }}>Loại khách hàng</Typography>
                        <Controller
                            control={control}
                            name="metadata.customer.customer_type"
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <FormControl fullWidth>
                                        <RadioGroup
                                            value={value}
                                            onChange={onChange}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: { xs: 'column', md: 'row' },
                                                gap: { xs: 0, md: 2 }
                                            }}
                                        >
                                            {CUSTOMER_REGISTER_TYPES.map((item, index) => {
                                                return (
                                                    <FormControlLabel
                                                        key={index}
                                                        {...item}
                                                        control={<Radio size="small" />}
                                                    />
                                                )
                                            })}
                                        </RadioGroup>
                                    </FormControl>
                                )
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography sx={{ mb: 0.5, fontSize: 14 }}>Tên Khách Hàng *</Typography>
                    <Controller
                        control={control}
                        name="metadata.customer.customer_id"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <SelectCustomer
                                        value={value}
                                        onChange={onChange}
                                        setValue={setValue}
                                        disabled={locked} />
                                </FormControl>
                            )
                        }}
                    />
                </Grid>
                {(watch("metadata.customer.customer_id") === -1 && !!watch("metadata.customer.customer_name")) && <Grid item xs={12} md={6}>
                    <Typography sx={{ mb: 0.5, fontSize: 14 }}>Email Khách Hàng</Typography>
                    <Controller
                        control={control}
                        name="metadata.customer.customer_email"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        value={value}
                                        onChange={onChange}
                                        placeholder='Nhập email khách hàng...'
                                    />
                                </FormControl>
                            )
                        }}
                    />
                </Grid>}
                {(watch("metadata.customer.customer_type") === "company") && <Grid item xs={12} md={6}>
                    <Typography sx={{ mb: 0.5, fontSize: 14 }}>Tên Công Ty *</Typography>
                    <Controller
                        control={control}
                        name="metadata.customer.customer_company_name"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        value={value}
                                        onChange={onChange}
                                        placeholder='Nhập tên công ty...'
                                    />
                                </FormControl>
                            )
                        }}
                    />
                </Grid>}
                <Grid item xs={12} md={6}>
                    <Typography sx={{ mb: 0.5, fontSize: 14 }}>Chức Vụ *</Typography>
                    <Controller
                        control={control}
                        name="metadata.customer.customer_position"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        value={value}
                                        onChange={onChange}
                                        placeholder='Nhập chức vụ/ vị trí khách hàng...'
                                    />
                                </FormControl>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography sx={{ mb: 0.5, fontSize: 14 }}>Website</Typography>
                    <Controller
                        control={control}
                        name="metadata.customer.customer_company_website"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        value={value}
                                        onChange={onChange}
                                        placeholder='Nhập website...'
                                    />
                                </FormControl>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography sx={{ mb: 0.5, fontSize: 14 }}>Số Điện Thoại</Typography>
                    <Controller
                        control={control}
                        name="metadata.customer.customer_phone"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        value={value}
                                        onChange={onChange}
                                        placeholder='Nhập số điện thoại khách hàng...'
                                    />
                                </FormControl>
                            )
                        }}
                    />
                </Grid>
            </Grid>

        </Box>
    )
}

export default Customer