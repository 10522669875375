import withPermission from '@/hocs/withPermission'
import { CallMergeOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'

const ButtonMergeInvoice = ({
    disabled = true,
    handleClick
}) => {
    return (
        <Button variant='outlined'
            disabled={disabled}
            size="small"
            onClick={handleClick}
            startIcon={<CallMergeOutlined />}
            sx={{ height: 36, m: 'auto 16px' }}
        >
            Gộp Hoá Đơn
        </Button>
    )
}

export default withPermission(ButtonMergeInvoice, {
    feature: "invoice",
    action: "merge",
    type: "Button"
})