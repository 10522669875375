import { Button } from '@mui/material'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import withPermission from '@/hocs/withPermission'
import { EditOutlined } from '@mui/icons-material'

const ButtonUpdateCustomer = () => {
    const { customer_id } = useParams()
    return (
        <Link to={`/customers/${customer_id}/update`} replace>
            <Button variant='outlined'
                size='small'
                startIcon={<EditOutlined fontSize='small' />}
            >
                Chỉnh Sửa
            </Button>
        </Link>
    )
}

export default withPermission(ButtonUpdateCustomer, { feature: "customer", action: "update", type: "button" })