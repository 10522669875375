import { format_numeral_price } from "@/functions"
import DepartmentActions from "./shared/Actions"
import Employees from "./shared/helpers/Employees"

const Headers = [
    {
        field: 'department_id',
        headerName: 'ID',
        width: 80,
    }, {
        field: 'department_code',
        headerName: 'Mã Phòng Ban',
        width: 200,
    }, {
        field: 'department_name',
        headerName: 'Tên Phòng Ban',
        width: 250,
    }, {
        field: 'employee_count',
        headerName: 'Số Lượng Nhân Viên',
        width: 150,
        valueFomatter: ({ value }) => format_numeral_price(value)
    }, {
        field: 'department_employees',
        headerName: 'Nhân Viên Nào?',
        width: 500,
        renderCell: ({ value }) => <Employees employees={value} />
    }, {
        field: 'actions',
        headerName: '',
        width: 40,
        renderCell: ({ row }) => <DepartmentActions department={row} actions={['update', 'destroy']} />
    }
]
const buildDepartments = ({ departments = [] }) => {
    return departments?.map(department => ({
        ...department,
        id: department?.department_id
    }))
}

const DepartmentManagementLibs = {
    columns: Headers,
    filterables: [],
    rows: buildDepartments
}
export default DepartmentManagementLibs