import { formatRequestUnit, formatVolumeUnit } from '@/functions';
import withSuspense from '@/hocs/withSuspense';
import EChart, { SCHEMES, TOOLTIP_OPTIONS } from '@components/EChart';
import DurationRanges from '@components/Templates/SwiftFederation/LMS/shared/DurationRanges';
import MuiSkeleton from '@components/shared/MuiSkeleton';
import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useState } from 'react';
import { resolveMlyticUsage } from '../services';
import Compare from './Compare';

const MlyticCDNRequests = ({
    service
}) => {
    const today = dayjs()
    const [durationRanges, setDurationRanges] = useState({
        range: 7,
        gte: today.subtract(7, 'day'),
        lte: today,
    })


    const { data: [dnsQueries], isLoading } = resolveMlyticUsage(service?.service_creatable_uuid, {
        ...durationRanges,
        usage_type: 'cdn_request_sum'
    })
    const timestamps = dnsQueries?.diagrams?.cdn_request_sum?.labels?.map((item) => dayjs(item)?.format("YYYY-MM-DD")) || [];

    const options = {
        tooltip: {
            trigger: 'axis',
            ...TOOLTIP_OPTIONS
        },
        grid: {
            top: '5%',
            left: -45,
            right: '0%',
            bottom: '0%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: timestamps,
            axisLine: {
                lineStyle: {
                    type: [3, 3],
                    dashOffset: 3
                }
            },
            axisLabel: {
                align: 'left',
                formatter: (value) => dayjs(value)?.format("DD MMM")
            }
        },
        yAxis: {
            type: 'value',
            splitNumber: 2,
            splitLine: {
                show: true,
                lineStyle: {
                    color: "#e0e0e0",
                    type: [3, 3],
                    dashOffset: 3
                }
            },
            axisLabel: {
                align: 'left',
                margin: 50,
                color: 'black',
                formatter: (value) => formatRequestUnit(value)
            }
        },
        color: [SCHEMES.primary[0]],
        series: [
            {
                data: dnsQueries?.diagrams?.cdn_request_sum?.datasets || [],
                type: 'line',
                animation: false,
                showSymbol: false,
            }
        ]
    };

    return (
        <>
            <Box sx={{ pt: 1.5, pb: 1 }}>
                <Typography fontSize={14} fontWeight={700} data-ui={`Mlytics.CDN.Traffics.[${service.service_creatable_uuid}]`}>
                    Total CDN Request
                </Typography>
                <DurationRanges durationRanges={durationRanges} setDurationRanges={setDurationRanges} />
            </Box>
            {!isLoading && dnsQueries ? (
                <Box>
                    <Compare
                        results={dnsQueries?.diagrams?.cdn_request_sum?.compare_result}
                        query={dnsQueries?.query}
                    />
                    <EChart options={options} />
                    <Box sx={{ textAlign: 'center', mt: 2 }}>
                        <Box sx={{ borderRadius: 1, bgcolor: grey[100], px: 1.5, py: 1, display: 'inline-block' }}>
                            <Typography fontSize={13}>
                                Total Requests: {formatVolumeUnit(_.sum(dnsQueries?.diagrams?.cdn_request_sum?.datasets, (x) => x))}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <MuiSkeleton.LoadingDotBox />
            )}
        </>
    )
}

export default withSuspense(MlyticCDNRequests, MuiSkeleton["LoadingDotBox"])