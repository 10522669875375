import moment from "moment";
import React from "react";
// eslint-disable-next-line no-unused-vars
import { decodeBase64, format_date_short } from "../functions";
import _ from "lodash";

const queryString = require("query-string");

export const DEFAULT_OPS = {
  skipNull: true,
  arrayFormat: "bracket",
  skipEmptyString: true,
};

class FilterUtils {
  parse = (hash) => {
    const object = {};

    retloop: for (var input in hash) {
      const value = hash[input];
      const parts = input.split("[");

      let last = object;

      for (let i in parts) {
        let part = parts[i];

        if (part.split("").pop() === "]") {
          part = part.slice(0, -1);
        }

        if (Number(i) === Number(parts.length - 1)) {
          last[part] = value;

          continue retloop;
        } else if (!last.hasOwnProperty(part)) {
          last[part] = {};
        }

        last = last[part];
      }
    }

    return object;
  };

  stringify = (query) => {
    return Object.entries(query)
      .map((k) => {
        const a = Object.entries(k[1]).map((j) => {

          const b = Object.entries(j[1]).map((v) => {
            const c = Object.entries(v[1]).map((i) => `[${i[0]}]=${i[1]}`);

            return `[${v[0]}]${c}`;
          });

          return `filters[${k[0]}][${j[0]}]${b}`;
        });

        return a;
      })
      .flat()
      .join("&");
  };

  formatArrayFiltersToObject = (key, array) => {
    try {
      return {
        [key]: array
          .map((item, index) => ({
            [index]: item,
          }))
          ?.map((item, index) => ({
            [index]: _.first(Object.values(item).flat()),
          }))
          .reduce((r, c) => Object.assign(r, c), {}),
      };
    } catch (exception) {
      alert("Exception: " + exception?.message);

      return {};
    }
  };
}

export const Filter = new FilterUtils();

const rebuildDefaultKeyQueries = (key) => {
  let today = moment();

  switch (key) {
    case "invoices.list.filters":
      return `filters[$and][0][invoices.invoice_status][$in]=1&filters[$and][1][invoice_duedate][$gte]=${format_date_short(
        today.startOf("months")
      )}&filters[$and][2][invoice_duedate][$lte]=${format_date_short(
        today.endOf("months")
      )}`;
    // case "customers.list.filters":
    // 	return `filters[$and][0][customer_service_informations.customer_status][$in]=qualified-prospects`
    case "dayoffs.list.filters":
      return `filters[$and][0][day_offs.day_off_status][$eq]=0&filters[$and][1][day_off_items.day_off_item_date][$gte]=${format_date_short(
        today.startOf("months")
      )}&filters[$and][2][day_off_items.day_off_item_date][$lte]=${format_date_short(
        today.endOf("months")
      )}&filters[$and][3][day_offs.day_off_leader_confirm][$eq]=false`;
    case "businesses.list.filters":
      return `filters[$and][0][businesses.business_status][$eq]=0&filters[$and][1][businesses.business_startdate][$gte]=${format_date_short(
        today.startOf("months")
      )}&filters[$and][2][businesses.business_startdate][$lte]=${format_date_short(
        today.endOf("months")
      )}&filters[$and][3][businesses.business_leader_confirm][$eq]=false`;

    case "manage-businesses.list.filters":
      return `filters[$and][0][businesses.business_status][$eq]=0&filters[$and][1][businesses.business_startdate][$gte]=${format_date_short(
        today.startOf("months")
      )}&filters[$and][2][businesses.business_startdate][$lte]=${format_date_short(
        today.endOf("months")
      )}&filters[$and][3][businesses.business_leader_confirm][$eq]=false`;
    default:
      return ``;
  }
};

const useFilters = (key, location) => {
  const search =
    location?.search ||
    decodeBase64(localStorage.getItem(key) || "") ||
    rebuildDefaultKeyQueries(key);

  const [state, setState] = React.useState(() => {
    return Filter.parse(queryString?.parse(search, DEFAULT_OPS))?.filters || {};
  });

  return [state, setState];
};

export default useFilters;
