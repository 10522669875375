import { Box, Typography } from "@mui/material";
import React from "react";

const METADATA_KEYS = {
  service_id: "ID Dịch vụ",
  business_id: "ID Phiếu Công Tác",
  ticket_id: "ID Ticket",

  service_name: "Tên Dịch vụ",
  description: "Mô tả",
  business_content: "Nội Dung Công Tác",
  business_place: "Địa Điểm Công Tác",
  ticket_content: "Nội Dung",
  meeting_link: 'Địa Chỉ/ Link meeting'
};

const Metadata = ({ metadata }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 0.25 }}>
      {Object.keys(metadata)?.map((item) => {
        const label = METADATA_KEYS[item];
        if (!label) return null;
        return (
          <Typography
            key={item}
            component="span"
            sx={{ fontSize: 13 }}
            color="text.grey"
          >
            - {label}:{" "}
            <Typography sx={{ fontSize: 13, display: "inline-flex" }}>
              {metadata[item]}
            </Typography>
          </Typography>
        );
      })}
    </Box>
  );
};

export default Metadata;
