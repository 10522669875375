import { CloseRounded } from "@mui/icons-material";
import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import { format_numeral_price } from "@/functions";

const ChipCustomize = ({
  handleDelete,
  filterAttribute = {
    label: "?Không xác định?",
    collections: [],
  },
}) => {
  const renderHTMLOperatorFilter = () => {
    switch (filterAttribute?.filter?.operator) {
      case "$in":
      case "$included":
      case "$eq":
        return null;
      case "$ne":
        return <Typography sx={{ fontSize: 12, mr: 0.5 }}>is not</Typography>;
      case "$json":
      case "$contains":
        return <Typography sx={{ fontSize: 12, mr: 0.5 }}>~=</Typography>;
      case "$notContains":
        return (
          <Typography sx={{ fontSize: 12, mr: 0.5 }}>
            is not contains
          </Typography>
        );
      case "$gt":
      case "$gte":
        return <Typography sx={{ fontSize: 12, mr: 0.5 }}>từ</Typography>;
      case "$lt":
      case "$lte":
        return <Typography sx={{ fontSize: 12, mr: 0.5 }}>đến</Typography>;
      default:
        return (
          <Typography color="error" sx={{ fontSize: 12, mr: 0.5 }}>
            Error Operator!
          </Typography>
        );
    }
  };

  const renderHTMLValueFilter = () => {
    switch (filterAttribute?.type) {
      case "object":
        if (filterAttribute?.filter?.operator === "$json")
          return filterAttribute?.value;
        if (["$in", "$included"].includes(filterAttribute?.filter?.operator)) {
          try {
            let values = filterAttribute?.value;
            if (typeof filterAttribute?.value === "string") {
              values = Array.from(filterAttribute?.value?.split(","));
            }
            if (typeof filterAttribute?.value === "number") {
              values = [filterAttribute.value?.toString()];
            }
            values = values.map((v) => v.toString());
            return filterAttribute?.collections
              ?.filter((option) => values?.includes(option?.value?.toString()))
              ?.map((option) => option?.label)
              .join(", ");
          } catch (e) {
          }
        }
        return (
          filterAttribute?.collections?.find(
            (option) =>
              option?.value.toString() === filterAttribute?.value?.toString()
          )?.label || <span style={{ color: "#ef5350" }}>Error Filter!</span>
        );
      case "date":
        return filterAttribute?.value;
      case "price":
        return `${format_numeral_price(filterAttribute?.value)}đ`;
      default:
        if (filterAttribute?.filter?.operator === "$in") {
          return filterAttribute?.value?.split(",").join(", ");
        }
        return filterAttribute?.value;
    }
  };
  return (
    <Chip
      sx={{
        height: 32,
        borderRadius: "4px",
        p: "0 4px",
        bgcolor: "#E3E1EC",
      }}
      deleteIcon={
        <CloseRounded color="inherit" sx={{ width: 16, height: 16 }} />
      }
      onDelete={handleDelete}
      label={
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: "medium",
              textTransform: "capitalize",
              mr: 0.5,
            }}
          >
            {filterAttribute?.label}:
          </Typography>
          {filterAttribute?.filter?.operator !== "$in" &&
            renderHTMLOperatorFilter()}
          {["$in", "$included"].includes(filterAttribute?.filter?.operator) ? (
            <Typography sx={{ fontSize: 12 }}>
              [{renderHTMLValueFilter()}]
            </Typography>
          ) : (
            renderHTMLValueFilter()
          )}
        </Box>
      }
    />
  );
};

export default ChipCustomize;
