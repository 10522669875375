import BusinessApi from "@/apis/beta/Employee/BusinessApi";
import BreadCrumb from "@/components/shared/BreadCrumb";
import { isObjEmpty } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useEmployeeContext } from "@contexts/employee";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import BusinessInformation from "./BusinessInformation";
import ContactInformation from "./ContactInformation";
import Feedback from "./Feedback";

const Detail = () => {
	const { employee } = useEmployeeContext();
	const { business_id } = useParams();
	const {
		data: { business },
	} = BusinessApi.Detail({ id: business_id });
	const {
		data: { leaders, master_inspectors },
	} = BusinessApi.CurrentRoles();


	return (
		<Box>
			<Box sx={{ mb: 2 }}>
				<Typography
					sx={{
						fontSize: { xs: 18, md: 24 },
						fontWeight: "medium",
					}}
				>
					Chi Tiết Phiếu Công Tác
				</Typography>
				<BreadCrumb
					data={[
						{ label: "Danh Sách Phiếu Công Tác", reactLink: "/businesses" },
						{ label: `IBN - ${business_id}` },
					]}
				/>
			</Box>
			<BusinessInformation business={business} />
			{(!!business && (business?.business_type === "meeting" || !isObjEmpty(business?.meeting))) && (
				<ContactInformation
					business={business}
					meeting={business?.meeting}
					contact={business?.contact}
					saleplan={business?.saleplan}
				/>
			)}
			<Box sx={{ p: 2 }}>
				{(leaders?.includes(employee?.employee_id) && business?.employee_id !== employee?.employee_id) && (
					<Feedback.Leader data={business} />
				)}
				{(master_inspectors && master_inspectors?.includes(employee?.employee_id)) && (
					<Feedback.Bod data={business} />
				)}
			</Box>
		</Box>
	);
};

export default withSuspense(
	withPermission(Detail, { feature: "business", action: "show" }),
	MuiSkeleton["Loading"]
);
