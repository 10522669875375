import { Box, FormControl, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const ProductDescription = ({ type = "create" }) => {
  const { control } = useFormContext();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: 1,
        mt: 2,
      }}
    >
      <Box sx={{ display: "flex", minWidth: 300 }}>
        <Typography sx={{ fontSize: 14, color: "#777680" }}>
          Thông Tin Mô Tả/ Ghi Chú
        </Typography>
      </Box>
      <Controller
        control={control}
        name="product_description"
        render={({ field }) => {
          return (
            <FormControl fullWidth>
              <TextField
                multiline
                {...field}
                placeholder="Nhập thông tin mô tả sản phẩm..."
              />
            </FormControl>
          );
        }}
      />
    </Box>
  );
};

export default ProductDescription;
