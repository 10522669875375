import React from 'react'
import ServiceAddonApi from '@/apis/beta/Service/ServiceAddonApi'
import ServiceAddonForm from '../shared/Form'
import withPermission from '@/hocs/withPermission'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'

const Update = ({
    service,
    handleClose,
    callbackService,
    data
}) => {
    const defaultValues = {
        service_addon_amount: data?.service_addon_amount || 0,
        service_addon_billing_cycle: data?.service_addon_billing_cycle || "lifetime",
        service_addon_name: data?.service_addon_name || "",
        service_addon_startdate: new Date(data?.service_addon_startdate),
        service_addon_duedate: new Date(data?.service_addon_duedate),
        service_addon_status: data?.service_addon_status || 0,
        service_addon_note: data?.service_addon_note || "",
        service_addon_create_invoice: data?.service_addon_create_invoice || true,
        service_addon_tax: data?.service_addon_tax || true,
        service_addon_setup_fee: data?.service_addon_setup_fee || 0
    }

    const onSubmit = async (data) => {
        const response = await ServiceAddonApi.Create({
            serviceId: service?.service_id,
            data: data
        })
        if (!response || response.status !== 201) {
            return { message: "Thêm mới dịch vụ addon thất baị", status: 'error' }
        }
        return { message: "Thêm mới dịch vụ addon thành công", status: 'success' }
    }
    return (
        <ServiceAddonForm handleClose={handleClose}
            callbackService={callbackService}
            service={service}
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            type="update"
        />
    )
}

export default withSuspense(
    withPermission(
        Update, {
        feature: 'service',
        action: 'service-addon-update',
        type: "Block"
    }), MuiSkeleton["Loading"])
