import BusinessApi from '@/apis/beta/Employee/BusinessApi'
import { CellDescription, CellStatus } from "@/components/DataGrid/Cell"
import { buildQueryFilterCondition } from "@/functions"
import withPermission from '@/hocs/withPermission'
import withSuspense from "@/hocs/withSuspense"
import useDebounce from "@/hooks/useDebounce"
import { BUSINESS_STATUS, BUSINESS_TYPES, CONFIRM_STATUS } from '@/libs/constants'
import DataGridCustomize from '@components/DataGrid'
import MuiSkeletonDefault from '@components/Skeletons/Default'
import { Typography } from '@mui/material'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import BusinessRangeTime from './BusinessRangeTime'

const Business = () => {
    const { employee_id } = useParams()
    const [searchQuery, setSearchQuery] = React.useState('')
    const debouncedSearchQuery = useDebounce(searchQuery, 500)

    const filterableBusiness = React.useCallback(() => {
        if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === '') return '';
        let formatSearch = debouncedSearchQuery
        try {
            const rgx = /IBN-|ibn-/
            formatSearch = formatSearch?.replace(rgx, '')
        } catch (error) {
            console.log('error when formatting search query', error?.message)
        }
        const fieldQueries = [
            {
                name: 'businesses.business_id', operator: "$eq",
                disabled: !parseInt(formatSearch), value: parseInt(formatSearch)
            },
        ]
        return buildQueryFilterCondition("$or", 0, fieldQueries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery])
    const [columns] = React.useState(
        [
            {
                field: 'businesses.business_id', headerName: 'Mã Phiếu', width: 120,
                valueGetter: ({ row }) => row?.business_id,
                renderCell: ({ value }) => {
                    return (
                        <Link to={`/businesses/${value}`} replace>
                            <Typography sx={{ fontSize: 14, textDecoration: 'underline' }} color="primary">
                                IBN-{value}
                            </Typography>
                        </Link>
                    )
                }
            }, {
                field: 'business_type', headerName: 'Loại Phiếu', width: 150,
                valueGetter: ({ row }) => BUSINESS_TYPES?.find(t => t.value === row?.business_type)?.label || 'Không xác định'
            }, {
                field: 'business_time', headerName: 'Thời Gian', width: 230,
                valueGetter: ({ row }) => {
                    return ({
                        business_startdate: row?.business_startdate,
                        business_enddate: row?.business_enddate
                    })
                },
                renderCell: ({ value }) => <BusinessRangeTime data={value} />
            }, {
                field: 'business_place', headerName: 'Nơi công tác', width: 200,
                renderCell: ({ value }) => <CellDescription description={value} />
            }, {
                field: 'business_leader_confirm', headerName: 'Trưởng Phòng', width: 120,
                valueGetter: ({ row }) => CONFIRM_STATUS?.find(t => t?.value === row?.business_leader_confirm),
                renderCell: ({ value }) => <CellStatus component="muiTypography" data={value} />,
            }, {
                field: 'business_bod_confirm', headerName: 'BOD/ BOM', width: 120,
                valueGetter: ({ row }) => CONFIRM_STATUS?.find(t => t?.value === row?.business_bod_confirm),
                renderCell: ({ value }) => <CellStatus component="muiTypography" data={value} />
            }, {
                field: 'businesses.business_status', headerName: 'Trạng Thái', width: 150,
                valueGetter: ({ row }) => BUSINESS_STATUS.find(t => t?.value === row?.business_status),
                renderCell: ({ value }) => <CellStatus component="muiChip" data={value} />
            }, {
                field: 'business_content', headerName: 'Nội Dung', width: 200,
                renderCell: ({ value }) => <CellDescription description={value} />
            }
        ]
    )

    const [page, setPage] = React.useState({
        page: 0,
        pageSize: 5
    });
    const { data, isFetching } = BusinessApi.List({
        page: page?.page + 1,
        pageSize: page?.pageSize,
        query: `&filters[$and][0][businesses.employee_id][$eq]=${employee_id}`,
        searchQuery: filterableBusiness() !== "" ? filterableBusiness() : null
    })

    return (
        <React.Fragment>
            <DataGridCustomize
                rows={data?.businesses?.map((item) => ({ ...item, id: item?.business_id })) || []}
                columns={columns}
                loading={isFetching}
                componentsProps={{
                    toolbar: {
                        inputSearch: {
                            value: searchQuery,
                            onChange: setSearchQuery,
                            placeholder: "Nhập mã phiếu công tác tìm kiếm..."
                        }
                    },
                    pagination: {
                        rowsPerPageOptions: [5, 10, 20, 50],
                        page: page?.page || 0,
                        pageSize: page?.pageSize || 5,
                        onPageChange: (newPage) => setPage(prevState => ({ ...prevState, page: newPage })),
                        onPageSizeChange: (newPageSize) => setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
                    }
                }}
                rowCount={data?.pagination?.total}
            />
        </React.Fragment>
    )
}

export default withSuspense(withPermission(Business, { feature: 'employee', action: 'index', type: 'component' }), MuiSkeletonDefault)