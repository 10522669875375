import ProductAddon from "./Customized/ProductAddon";
import Information from "./Package/Information";
import Payment from "./Package/Payment";

const ServiceComponentShareds = {
  "Customized.Addons": ProductAddon,
  "Package.Information": Information,
  "Package.Payment": Payment,
};
export default ServiceComponentShareds;
