import List from './List';
import Create from './Create';
import Detail from './Detail';
import Product from './Product'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Create,
    Detail,
    List,
    Product
};
