import { Box, Skeleton } from '@mui/material'
import React from 'react'

const MuiScrollTabs = () => {
    return (
        <Box sx={{ pl: 1.5, display: 'flex', gap: 2, flexDirection: 'row' }}>
            {[1, 2, 3, 4].map((item) => {
                return (
                    <Box sx={{ display: 'flex', gap: 1, mb: 2 }} key={item}>
                        <Skeleton variant='circular' sx={{ height: 28, width: 28 }} />
                        <Skeleton variant='text' sx={{ height: 28, width: 150 }} />
                    </Box>
                )
            })}
        </Box>
    )
}

export default MuiScrollTabs