import { Box } from '@mui/material'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import Configoptions from './Configoptions'
import ProductBillingCycle from './ProductBillingCycle'
import ProductName from './Title'

const ProductConfigoption = () => {
    const { control } = useFormContext()
    const { fields } = useFieldArray({
        control: control,
        name: "product_configoptions"
    })

    return (
        <Box sx={{ width: 'calc(100% - 16px - 350px)', p: 3, border: '1px solid rgba(0, 0, 0, 0.18)' }}>
            <ProductName />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                {fields.map((field, index) => {
                    const Component = Configoptions[field?.type?.toUpperCase()]
                    if (!Component) return null
                    return (
                        <React.Fragment key={field?.id}>
                            <Component position={index} />
                        </React.Fragment>
                    )
                })}
            </Box>
            <ProductBillingCycle />
        </Box>
    )
}

export default ProductConfigoption