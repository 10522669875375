import CancelService from "./CancelService"
import CreateAddon from "./CreateAddon"
import CreateInvoiceRenew from "./CreateInvoiceRenew"
import CreateInvoiceStatement from "./CreateInvoiceStatement"
import ReduceServiceConfiguration from "./ReduceServiceConfiguration"
import RegisterService from "./RegisterService"
import ServiceCreateNote from "./ServiceCreateNote"
import ServiceRenew from "./RenewService"

const ServiceButtonUI = {
  CancelService: CancelService,
  RegisterService: RegisterService,
  CreateInvoiceRenew: CreateInvoiceRenew,
  CreateNote: ServiceCreateNote,
  CreateServiceAddon: CreateAddon,
  CreateInvoiceStatement: CreateInvoiceStatement,
  ReduceServiceConfiguration: ReduceServiceConfiguration,
  RenewService: ServiceRenew,
}
export default ServiceButtonUI