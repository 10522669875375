import ItemPaperStatistical from '@/components/shared/ItemPaperStatistical'
import { Grid } from '@mui/material'
import React from 'react'

const Statistical = ({ data }) => {
    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <ItemPaperStatistical label={"Tổng Số Nhân Viên"} value={data?.total} />
            </Grid>
        </Grid>
    )
}

export default Statistical