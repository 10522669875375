import { Box, Typography } from "@mui/material";
import React from "react";

const FORMAT_NAMES = {
  vCore: "CPU",
  cpu: "CPU",
  SSD: "DISK",
  Memory: "RAM",
  IP: "IP",
};

const ProductInformation = ({ informations = [] }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        gap: 0.75,
      }}
    >
      {informations?.map((information, index) => {
        if (!FORMAT_NAMES[information?.name]) return null;
        return (
          <Typography
            sx={{ fontSize: 14, m: "auto 0" }}
            color="text.grey"
            key={index}
          >
            {FORMAT_NAMES[information?.name]}
            {":"}{" "}
            <Typography
              sx={{ fontSize: 14, display: "inline-flex" }}
              color="text.black"
            >
              {information?.value}
            </Typography>
          </Typography>
        );
      })}
    </Box>
  );
};

export default ProductInformation;
