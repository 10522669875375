import CustomerContactApi from '@/apis/beta/Customer/CustomerContactApi'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'

const Service = ({ value = '', onChange, customerId, ...props }) => {
    const { data: contacts } = CustomerContactApi.List({ customerId })

    if (!contacts || !contacts.length) return null;
    return (
        <SingleSelectCustomize
            options={[...contacts]}
            value={[...contacts]?.find(contact => value === contact?.customer_contact_id) || null}
            onChange={(event, newValue) => onChange(newValue?.customer_contact_id || '')}
            formatCollection={{ label: 'name', value: 'customer_contact_id' }}
            compareAttributes={['name']}
            {...props}
            input={{
                ...props?.input,
            }}
        />
    )
}

export default withSuspense(Service, MuiSkeleton['TextField'])