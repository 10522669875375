import { BaseComponents } from "@components/Components";
import DatePicker from "@components/DatePicker";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

export const CustomerComponents = {
  TextField: ({
    value,
    onChange,
    placeholder = "Nhập thông tin...",
    ...props
  }) => {
    return (
      <FormControl fullWidth>
        <TextField
          size="small"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          {...props}
        />
      </FormControl>
    );
  },
  SelectClient: ({ value, onChange, options }) => {
    return (
      <FormControl fullWidth>
        <SingleSelectCustomize
          value={options?.find((x) => x?.value === value) || null}
          options={options}
          onChange={(event, newValue) => onChange(newValue?.value || "")}
        />
      </FormControl>
    );
  },
  SelectRadio: ({ value, onChange, options }) => {
    return (
      <FormControl fullWidth>
        <RadioGroup row value={value} onChange={onChange}>
          {options?.map((option, index) => (
            <FormControlLabel
              {...option}
              key={index}
              control={<Radio size="small" value={option?.value} />}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  },
  SaleTracking: ({ value, onChange, name }) => (
    <BaseComponents.SaleTracking
      value={value}
      onChange={onChange}
      name={name}
    />
  ),
  CompanyTax: ({ value, onChange, name, ...props }) => (
    <BaseComponents.CompanyTax
      value={value}
      onChange={onChange}
      name={name}
      {...props}
    />
  ),
  DatePicker: ({ value, onChange }) => (
    <FormControl fullWidth>
      <DatePicker value={value} onChange={onChange} />
    </FormControl>
  ),
  Contact: ({ value, onChange, name, ...props }) => (
    <BaseComponents.Contact
      value={value}
      onChange={onChange}
      name={name}
      {...props}
    />
  ),
  ServiceInteresting: ({ value, onChange }) => (
    <BaseComponents.ServiceInteresting value={value} onChange={onChange} />
  ),
};
