import { AddOutlined } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import React from 'react'
import withPermission from '@/hocs/withPermission'
import { Link, useNavigate } from 'react-router-dom'
import CustomerSaleplanApi from '@/apis/beta/Customer/CustomerSaleplanApi'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import ModalConfirmRedirect from '../../../../Detail/shared/ModalConfirmRedirect'

const ButtonCreateSaleplan = ({ customerId,
    ...customer
}) => {

    let navigate = useNavigate()
    const [modal, setModal] = React.useState(false)
    const { data } = CustomerSaleplanApi.Processing({ customerId: customerId })

    return (
        <React.Fragment>
            {modal && <ModalConfirmRedirect
                close={() => setModal(false)}
                data={{
                    redirect: `/customers/${customerId}/saleplans/${data?.saleplan_id}`,
                    dialog: {
                        title: "Tồn Tại Kế Hoạch Bán Hàng",
                        content: <Typography component="div">
                            Tìm thấy một kế hoạc bán hàng của khách hàng đang trong quá trình xử lý.
                            <Typography component="div">
                                Click
                                <Typography color="primary" sx={{
                                    display: 'inline-flex',
                                    fontWeight: 'medium',
                                    m: 'auto 4px'
                                }}>
                                    "Tiếp tục"
                                </Typography>
                                để đi đến xem hoặc cập nhật thông tin kế hoạch bán hàng hoặc "Đóng" để bỏ qua.
                            </Typography>
                        </Typography>
                    }
                }}
            />}
            <Link to={`/customers/${customerId}/orders/new?order_type=saleplan`}
                replace>
                <Button variant='outlined'
                    sx={{ height: { xs: 40, md: 42 } }}
                    fullWidth
                    disabled={customer?.customer_service_information?.customer_status === "cancelled"}
                    startIcon={<AddOutlined fontSize='small' />}
                    onClick={(event) => {
                        event.preventDefault()
                        if (!!data) {
                            return setModal(true)
                        }
                        return navigate(`/customers/${customerId}/orders/new?order_type=saleplan`, { replace: true })
                    }}
                    size="small"
                >
                    Kế Hoạch Bán Hàng
                </Button>
            </Link>
        </React.Fragment>

    )
}

export default withSuspense(withPermission(ButtonCreateSaleplan, {
    feature: 'customer',
    action: 'customer-saleplan-create',
    type: "Button"
}), MuiSkeleton["ButtonOne"])