import { useLayoutContext } from '@contexts/layout'
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

const ManagerLinked = () => {
    const { permissions } = useLayoutContext()

    return (
        <Box>
            <Typography sx={{
                fontSize: { xs: 18, md: 24 },
                fontWeight: 'medium',
                mb: 2
            }}>
                Quản Lý Danh Sách Liên Kết [routers]
            </Typography>
            <Grid container spacing={2}>
                {permissions?.map((item, index) => {
                    if (item?.actions?.length === 0) return null;
                    return (
                        <Grid item xs={12} md={6} key={index}>
                            <Box sx={{
                                border: "1px solid rgba(0, 0, 0, 0.38)",
                                p: 2
                            }}>
                                <Typography sx={{
                                    fontWeight: 'medium',
                                    fontSize: 14,
                                    textTransform: "capitalize"
                                }}>
                                    {item?.feature?.replaceAll("-", "")}
                                </Typography>
                                {item?.actions?.map((href, i) => {
                                    return (
                                        <Typography sx={{ fontSize: 14 }} color="primary" key={i}>
                                            {!!href?.pathname ? href?.pathname : "---"}
                                        </Typography>
                                    )
                                })}
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    )
}

export default ManagerLinked