import AddOutlined from '@mui/icons-material/AddOutlined'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import Create from '../../../Create'

const CreateLink = () => {
    const [create, setCreate] = useState(false)
    return (
        <>
            {create && <Create onClose={() => setCreate(false)} />}
            <Button size='small'
                variant='outlined'
                disabled={create}
                onClick={() => setCreate(true)}
                startIcon={<AddOutlined fontSize='small' />}>
                Thêm Phòng Ban
            </Button>
        </>

    )
}

export default CreateLink