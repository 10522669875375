import DataGrid from "@/components/DataGrid";
import { Box } from "@mui/material";
import clsx from "clsx";
import { Fragment, useMemo } from "react";
import ServiceVPSLibs from "../functions";
import ServiceActivities from "../../shared/Service/ServiceActivities";
import ServiceManager from "@components/Templates/SwiftFederation/shared/ServiceManager";

const ServiceVPS = ({ service, addons }) => {

    const initialColumns = useMemo(() => {
        return [...ServiceVPSLibs.rows({ addons, service })]
    }, [service, addons]);

    return (
        <Fragment>
            <ServiceActivities
                module={ServiceVPSLibs.module}
                activities={ServiceVPSLibs.activities}
                service={service} />
            <Box sx={{ mt: "10px" }}>
                <DataGrid
                    rows={
                        initialColumns?.filter((service) => !service?.hide)?.map((service, index) => ({
                            ...service,
                            id: index + 1,
                        })) || []
                    }
                    columns={ServiceVPSLibs?.columns}
                    components={{
                        Toolbar: "disabled",
                        Pagination: "disabled",
                    }}
                    disableSelectionOnClick
                    disableColumnFilter
                    getRowClassName={({ row }) => {
                        return clsx(`grid-dc-vnetwork`, row?.classNames || "");
                    }}
                />
            </Box>
            <ServiceManager service={service} />
        </Fragment>

    );
};
export default ServiceVPS;