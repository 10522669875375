import ContactApi from "@/apis/beta/Customer/ContactApi";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box } from "@mui/material";
import React from "react";
import Assignment from "./Assignment";
import Message from "./Message";
import ThreadStatusChange from "./ThreadStatus";
import WelcomeMessage from "./WelcomeMessage";

const ContactConversation = ({ conversationId, assignee }) => {
	const { data } = ContactApi.Conversation({
		conversationId
	})

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 1,
				maxHeight: 300,
				overflow: "hidden",
				overflowY: "auto",
				"&::-webkit-scrollbar": {
					left: 0,
					width: 2.5,
					"&-track": {
						background: "#f1f1f1",
					},
					"&-thumb": {
						background: "#000",
					},
				},
			}}
		>
			{data?.messages &&
				data?.messages?.map((message, index) => {
					if (message?.type === "WELCOME_MESSAGE") {
						return <WelcomeMessage message={message} key={index} />;
					}
					if (message?.type === "MESSAGE") {
						return (
							<Message message={message} assignee={assignee} key={index} />
						);
					}
					if (message?.type === "ASSIGNMENT") {
						return <Assignment message={message} key={index} />;
					}
					if (message?.type === "THREAD_STATUS_CHANGE") {
						return <ThreadStatusChange message={message} key={index} />;
					}
					return null;
				})}
		</Box>
	);
};

export default withSuspense(ContactConversation, MuiSkeleton["LinearProgress"]);
