import React from 'react';
import { Grid, Box, Button } from '@mui/material';
import useFilters from '@/hooks/useFilters';

const Toolbar = React.memo(({ callback, filters }) => {
  const location = window.location

  const [query, updateQuery] = useFilters(filters?.key, location);

  const handleAddQuery = (hash) => {
    const filter = Object.assign(query[hash?.condition] || {}, {});
    filter[Object.keys(filter).length] = hash.filter;

    const filters = { ...query, [hash?.condition]: filter };

    return [updateQuery(filters), callback(filters)];
  };
  return (
    <Grid container>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Box sx={{ p: 0 }}>
          <Button
            onClick={() =>
              handleAddQuery({
                condition: '$or',
                filter: { customer_status: { $eq: 2 } },
              })
            }
            sx={{ bgcolor: 'grey.50' }}
          >
            Update Filter
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
});

export default Toolbar;
