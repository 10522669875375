import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions();

function AutocompleteAddedNewValue({
  value,
  onChange: setValue,
  onAddNewValue,
  placeholder,
  ...props
}) {
  if (!props?.options)
    throw new Error("Missing options props for AutocompleteAddedNewValue");

  return (
    <Autocomplete
      fullWidth
      size="small"
      value={value}
      onChange={(_, newValue) => {
        if (typeof newValue === "string") {
          onAddNewValue(newValue);
          setValue({
            label: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          onAddNewValue(newValue);
          // Create a new value from the user input
          setValue({
            label: newValue.inputValue,
          });
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.label
        );
        if (inputValue !== "" && !isExisting) {
          typeof onAddNewValue === "function" &&
            filtered.push({
              inputValue,
              label: `Add "${inputValue}"`,
            });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={props.options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          fullWidth
          disabled={props?.disabled}
          placeholder={placeholder}
        />
      )}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      {...props}
    />
  );
}

export default AutocompleteAddedNewValue;
