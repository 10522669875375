import React from 'react';
import { Box, Typography } from '@mui/material';

import HelperBase from '../shared/HelperBase';

const Default = () => {
  return (
    <Box>
      <Typography sx={{ fontSize: 14, color: 'grey.700' }}>
        Helper
      </Typography>
      <HelperBase />
    </Box>
  );
};

export default Default;
