import ErrorHelperText from '@components/shared/ErrorHelperText'
import FORM_BASE from '@components/shared/FormCustomized/form'
import { FormControl } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ServiceControlTimes = () => {
    const { control, watch, setValue } = useFormContext()

    useEffect(() => {
        const startdate = watch(`service_management_billing.service_billing_startdate`)
        const statement = watch(`service_management_billing.statement`)
        if (statement === 'ck1') {
            setValue(`service_management_billing.service_control_startdate`, dayjs(startdate?.$d || startdate).set('day', 1));
            setValue(`service_management_billing.service_control_enddate`, dayjs(startdate?.$d || startdate).endOf('month'));
        } else {
            const days = dayjs(startdate).day
            const month = dayjs(startdate).month
            setValue(`service_management_billing.service_control_startdate`, dayjs(startdate?.$d || startdate).set('day', 23).set('month', days < 23 ? (month - 1) : month));
            setValue(`service_management_billing.service_control_startdate`, dayjs(startdate?.$d || startdate).set('day', 22).set('month', days < 23 ? month : (month + 1)));
            setValue(`service_management_billing.service_control_enddate`, dayjs(startdate?.$d || startdate).endOf('month'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch(`service_management_billing.statement`), watch(`service_management_billing.service_billing_startdate`)])
    return (
        <Controller
            control={control}
            name="service_management_billing.statement"
            render={({ field: { value, onChange }, fieldState: { error } }) =>
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <FORM_BASE.SingleSelect
                        value={value}
                        onChange={onChange}
                        input={{
                            label: 'Chu Kỳ Đối Soát *',
                            placeholder: 'Chọn chu kỳ đối soát'
                        }}
                        options={[
                            { value: 'ck1', label: 'Đầu Tháng Đến Cuối Tháng (Ex: 01/01 đến 31/01)' },
                            { value: 'ck2', label: 'Từ Ngày 23 Đến Ngày 22 (Ex: 23/01 đến 22/02)' },
                        ]}
                    />
                    {!!error && <ErrorHelperText message={error?.message} />}
                </FormControl>
            }
        />
    )
}

export default ServiceControlTimes