import { UNLIMITED } from "@/libs/constants";
import NumericFormatCustom from "@components/NumericFormatCustom";
import { Box, FormControl, TextField, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { Controller, useFormContext } from "react-hook-form";

const ProductPricingInit = () => {
  const { control } = useFormContext();

  return (
    <Controller
      name="product_pricing_configures.value"
      control={control}
      render={({ field: { value, onChange } }) => {
        const isUnlimited = parseFloat(value) >= UNLIMITED;
        return (
          <FormControl fullWidth sx={{ m: "auto 0 0 0" }}>
            <TextField
              label="Cấu hình cam kết *"
              InputLabelProps={{ shrink: true }}
              value={isUnlimited ? "" : value}
              onChange={(event) => {
                onChange(event?.target?.value);
                // onChangeCommitment(event);
              }}
              placeholder="0"
              size="small"
              InputProps={{
                sx: {
                  paddingRight: 0,
                },
                inputComponent: isUnlimited ? "input" : NumericFormatCustom,
                endAdornment: (
                  <Box
                    sx={{
                      display: "flex",
                      height: "40px",
                      width: {
                        xs: isUnlimited ? "calc(100%)" : "calc(100% / 3)",
                        md: isUnlimited ? "calc(100%)" : "calc(100% / 4)",
                      },
                      transition: "width 0.75s",
                      bgcolor: "rgba(0, 0, 0, 0.18)",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (isUnlimited) {
                        return onChange(0);
                      }
                      return onChange(UNLIMITED);
                    }}
                  >
                    <Typography
                      sx={{
                        m: "auto 8px auto auto",
                        fontSize: 13,
                        textTransform: "uppercase",
                        color: isUnlimited ? green[800] : "#777680",
                      }}
                    >
                      Unlimited
                    </Typography>
                  </Box>
                ),
              }}
            />
          </FormControl>
        );
      }}
    />
  );
};

export default ProductPricingInit;
