import BreadCrumb from "@components/shared/BreadCrumb";
import { Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import ProductTabs from "../ProductTabs";

const Layout = ({ children }) => {
  const { product_id } = useParams();
  return (
    <React.Fragment>
      <Typography className="page-title">Thông Tin Sản Phẩm</Typography>
      <BreadCrumb
        data={[
          { label: "Danh Sách Sản Phẩm", reactLink: "/products" },
          { label: product_id || "---" },
        ]}
      />
      <ProductTabs />
      {children}
    </React.Fragment>
  );
};

export default Layout;
