import { DAYOFF_STATUS_CONFIRM_TYPES, DAYOFF_STATUS_TYPES } from "@/libs/constants";
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { Box, Button, Chip, Paper, Typography } from '@mui/material';
import moment from "moment";
import React from 'react';

const Detail = ({
    data,
    handleClose
}) => {

    const [dayoffStatus] = React.useState(DAYOFF_STATUS_TYPES?.find(t => t?.value === parseInt(data?.business_status)))
    const [dayoffLeaderStatus] = React.useState(DAYOFF_STATUS_CONFIRM_TYPES?.find(t => t?.value === data?.business_leader_confirm))
    const [dayoffBodStatus] = React.useState(DAYOFF_STATUS_CONFIRM_TYPES?.find(t => t?.value === data?.business_bod_confirm))

    return (
        <Paper sx={{ p: 3, height: '100vh', width: "100%", minWidth: 450, maxWidth: 450 }} elevation={8}>
            <Typography sx={{ mb: 2, textTransform: "uppercase" }}>
                Thông Tin Phiếu Công Tác
            </Typography>
            <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography sx={{ color: "#9e9e9e", width: "40%" }}>Mã Phiếu</Typography>
                <Typography sx={{ width: "60%" }}>{["IBN", data?.business_id].join("-")}</Typography>
            </Box>
            <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography sx={{ color: "#9e9e9e", width: "40%" }}>Tên Nhân Viên</Typography>
                <Typography sx={{ width: "60%" }}>{data?.employee?.employee_name}</Typography>
            </Box>
            <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography sx={{ color: "#9e9e9e", width: "40%" }}>Phòng Ban</Typography>
                <Typography sx={{ width: "60%" }}>
                    {data?.employee_department_name}</Typography>
            </Box>
            <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography sx={{ color: "#9e9e9e", width: "40%" }}>Bắt Đầu</Typography>
                <Typography sx={{ width: "60%" }}>{moment(data?.business_startdate).format("DD/MM/YYYY HH:mm")}</Typography>
            </Box>
            <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography sx={{ color: "#9e9e9e", width: "40%" }}>Kết Thúc</Typography>
                <Typography sx={{ width: "60%" }}>{moment(data?.business_enddate).format("DD/MM/YYYY HH:mm")}</Typography>
            </Box>
            <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography sx={{ color: "#9e9e9e", width: '40%' }}>Nội Dung Công Tác</Typography>
                <Box sx={{ width: '60%', }}>
                    <Typography sx={{ width: "100%", display: 'flex', flexWrap: 'wrap', textAlign: 'left' }}>{data?.business_content}</Typography>
                </Box>
            </Box>
            <Typography sx={{ mt: 2, mb: 2, textTransform: "uppercase" }}>Trạng Thái Phiếu Công Tác</Typography>
            <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography sx={{ color: "#9e9e9e", width: "40%" }}>Trạng Thái</Typography>
                <Box sx={{ width: "60%" }}>
                    <Chip
                        label={dayoffStatus?.label}
                        size='small'
                        sx={{
                            m: 'auto 0',
                            color: dayoffStatus?.color,
                            bgcolor: `${dayoffStatus?.color}1F`
                        }}
                    />
                </Box>
            </Box>
            {data?.business_note &&
                <Box sx={{ mb: 1, display: 'flex', p: 2, bgcolor: "#78909C1F", borderRadius: "7px" }}>
                    <ErrorOutlineOutlined color='primary' fontSize='small' sx={{ mr: 1 }} />
                    <Box>
                        <Typography color='primary' sx={{ mb: 0.5 }}>
                            Thông tin ghi chú
                        </Typography>
                        <Typography sx={{ flexWrap: 'wrap', textAlign: 'left' }}>{data?.business_note}</Typography>
                    </Box>
                </Box>}
            <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography sx={{ color: "#9e9e9e", width: "40%" }}>Trưởng Phòng</Typography>
                <Box sx={{ width: "60%" }}>
                    <Chip
                        label={dayoffLeaderStatus?.label}
                        size='small'
                        sx={{
                            m: 'auto 0',
                            color: dayoffLeaderStatus?.chip,
                            bgcolor: `${dayoffLeaderStatus?.chip}1F`
                        }}
                    />
                </Box>
            </Box>
            {data?.business_feedback_lead &&
                <Box sx={{ mb: 1, display: 'flex', p: 2, bgcolor: "#78909C1F", borderRadius: "7px" }}>
                    <ErrorOutlineOutlined color='primary' fontSize='small' sx={{ mr: 1 }} />
                    <Box>
                        <Typography color='primary' sx={{ mb: 0.5 }}>
                            Phản hồi của Trưởng Bộ Phận
                        </Typography>
                        <Typography sx={{ flexWrap: 'wrap', textAlign: 'left' }}>{data?.business_feedback_lead}</Typography>
                    </Box>
                </Box>}
            <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography sx={{ color: "#9e9e9e", width: "40%" }}>BOD/ BOM</Typography>
                <Box sx={{ width: "60%" }}>
                    <Chip
                        label={dayoffBodStatus?.label}
                        size='small'
                        sx={{
                            m: 'auto 0',
                            color: dayoffBodStatus?.color,
                            bgcolor: `${dayoffBodStatus?.color}1F`
                        }}
                    />
                </Box>
            </Box>
            {data?.business_feedback_bod &&
                <Box sx={{ mb: 1, display: 'flex', p: 2, bgcolor: "#78909C1F", borderRadius: "7px" }}>
                    <ErrorOutlineOutlined color='primary' fontSize='small' sx={{ mr: 1 }} />
                    <Box>
                        <Typography color='primary' sx={{ mb: 0.5 }}>
                            Phản hồi của BOD/ BOM
                        </Typography>
                        <Typography sx={{ flexWrap: 'wrap', textAlign: 'left' }}>{data?.business_feedback_bod}</Typography>
                    </Box>
                </Box>}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: "flex-end" }}>
                <Button size="small"
                    onClick={handleClose}
                    variant='outlined' sx={{ height: 36 }}>Đóng</Button>
            </Box>
        </Paper>
    )
}
export default Detail
