import withPermission from '@/hocs/withPermission'
import { Button } from '@mui/material'
import React from 'react'

const Update = ({
    submit = true,
    onClick
}) => {
    return (
        <Button size='small' variant='outlined'
            disabled={!!submit}
            onClick={onClick}
        >
            Huỷ thao tác
        </Button>
    )
}

export default withPermission(Update, {
    feature: "product",
    action: "product-feature-update",
    type: "Button"
})