import CustomerContactApi from '@/apis/beta/Customer/CustomerContactApi'
import { queryClientRefetchScope } from '@/functions'
import { COUNTRIES, CUSTOMER_SOURCES, GENDERS, SERVICES } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import { useNotificationContext } from '@contexts/notification'
import { vestResolver } from '@hookform/resolvers/vest'
import { Box, Button, FormControl, Grid, Typography } from '@mui/material'
import { useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import CONTACT_FORM from '../shared/ContactForm'
import { contactUpdateValidator } from '../validators'

const CONTACT_TYPES = [
    { value: 'customer', label: 'Người Đại Diện' },
    { value: 'company', label: 'Công Ty/ Doanh Nghiệp' },
]
const CONTACT_LABELS = [
    { value: 'contact-first', label: 'Thông Tin Mặc Định' },
    { value: 'service-manager', label: 'Quản Lý/ Tạo Dịch Vụ' },
    { value: 'invoice-manager', label: 'Quản Lý/ Thông Tin Xuất Hoá Đơn' },
]

const ContactUpdate = ({
    customerId = -1,
    contactId,
    onClose,
    defaultValues = {}
}) => {

    const queryClient = useQueryClient()
    const { setNotification } = useNotificationContext()

    const { mutateAsync, isLoading } = useMutation(CustomerContactApi.Update)
    const methods = useForm({
        resolver: vestResolver((props) => contactUpdateValidator({ ...props, email: defaultValues?.personal_information?.email || '' })),
        defaultValues: useMemo(() => defaultValues, [defaultValues])
    })

    const company = useMemo(() => {
        return methods.watch(`contact_type`) === 'company'
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methods.watch(`contact_type`)])

    const FIELDS = [
        {
            name: 'contact_type', component: 'SingleSelect',
            params: {
                options: CONTACT_TYPES,
                input: {
                    label: 'Loại Thông Tin Liên Hệ',
                    placeholder: 'Chọn loại thông tin liên hệ...'
                },
                disabled: true
            }
        }, {
            name: 'contact_label', component: 'SingleSelect',
            params: {
                options: CONTACT_LABELS,
                input: {
                    label: 'Label/ Mục Đích',
                    placeholder: 'Chọn mục đích tạo contact này...'
                },
            }
        },
        {
            name: 'company.company_name', component: 'TextField', params: {
                label: 'Tên Công Ty',
                placeholder: 'Nhập tên công ty...',
            },
            hidden: !company
        },
        {
            name: 'company.company_tax', component: 'TextField', params: {
                label: 'Mã Số Thuế',
                placeholder: 'Nhập mã số thuế...',
            },
            hidden: !company
        },
        {
            name: 'company.company_established_date', component: 'DateField', params: {
                label: 'Ngày Thành Lập Công Ty',
            },
            hidden: !company

        },
        {
            name: 'company.company_address', component: 'TextField', params: {
                label: 'Địa Chỉ',
                placeholder: 'Nhập địa chỉ công ty...',
            },
            hidden: !company

        },
        {
            name: 'company.company_website', component: 'TextField', params: {
                label: 'Website',
                placeholder: 'Website công ty...',
            },
            hidden: !company
        },
        {
            name: 'personal_information.name', component: 'TextField', params: { label: 'Họ & Tên', placeholder: 'Nhập họ & tên...' },
            hidden: company
        },
        {
            name: 'personal_information.email', component: 'TextField', params: { label: 'Email', placeholder: 'Nhập email...' },
            hidden: company
        },
        {
            name: 'personal_information.country_phone_number', component: 'SingleSelect', cols: 4,
            hidden: company,
            params: {
                options: COUNTRIES,
                input: {
                    label: 'Mã Vùng',
                    placeholder: 'Chọn mã vùng số điện thoại...'
                }
            }
        },
        {
            name: 'personal_information.phone_number', component: 'TextField',
            cols: 8, params: { label: 'Số Điện Thoại', placeholder: 'Nhập số điện thoại...' },
            hidden: company,

        },
        {
            name: 'personal_information.gender', component: 'SingleSelect', cols: 4,
            hidden: company,
            params: {
                options: GENDERS,
                input: {
                    label: 'Giới Tính *',
                    placeholder: 'Chọn giới tính...'
                },
            }
        },
        {
            name: 'personal_information.position', component: 'TextField', cols: 8,
            params: { label: 'Vị Trí', placeholder: 'Nhập vị trí/ chức vụ...' },
            hidden: company,
        },
        {
            name: 'personal_information.birthday', component: 'DateField', params: { label: 'Ngày Sinh' }, hidden: company,
        },
        {
            name: 'contact_ordered', component: 'TextField', params: {
                label: 'Ưu Tiên',
                placeholder: 'Nhập số thứ tự ưu tiên...',
                InputProps: { inputComponent: NumericFormatCustom }
            }
        }, {
            name: 'customer_service_information.customer_service_interestings', component: 'MultiSelect.Products',
            params: {
                input: {
                    label: 'Dịch Vụ Quan Tâm',
                    placeholder: 'Chọn dịch vụ quan tâm...'
                },
            },
            hidden: !['service-manager', 'first-contact']?.includes(methods.watch(`contact_label`)) || company,
        }, {
            name: 'customer_service_information.customer_source', component: 'SingleSelect',
            params: {
                options: CUSTOMER_SOURCES,
                input: {
                    label: 'Dịch vụ quan tâm',
                    placeholder: 'Chọn dịch vụ quan tâm...'
                },
            },
            hidden: !['service-manager', 'first-contact']?.includes(methods.watch(`contact_label`)) || company,
        }, {
            name: 'customer_service_information.customer_source_event', component: 'TextField',
            params: {
                label: 'Tên Sự Kiện',
                placeholder: 'Nhập tên sự kiện khai thác thông tin khách hàng...'
            },
            hidden: !(methods.watch(`customer_service_information.customer_source`) === 'event'),
        }, {
            name: 'customer_service_information.customer_sale_followers', component: 'MultiSelect.Employees',
            params: {
                input: {
                    label: 'Nhân Viên Sale Follow',
                    placeholder: 'Chọn nhân viên...'
                },
            },
            hidden: !['service-manager', 'first-contact']?.includes(methods.watch(`contact_label`)) || company,
        }, {
            name: 'customer_service_information.customer_cs_followers', component: 'MultiSelect.Employees',
            params: {
                scope: 'CSKH',
                input: {
                    label: 'Nhân Viên CS Follow',
                    placeholder: 'Chọn nhân viên...'
                },
            },
            hidden: !['service-manager', 'first-contact']?.includes(methods.watch(`contact_label`)) || company,
        }, {
            hidden: !['service-manager', 'first-contact']?.includes(methods.watch(`contact_label`)) || company,
            name: 'customer_service_information.customer_contact_social',
            component: 'ArrayField',
            params: {
                styled: {
                    maxHeight: 120, overflow: 'hidden', overflowY: "auto"
                },
                label: 'Kênh Liên Hệ',
                name: 'customer_service_information.customer_contact_social',
                attributes: [{
                    name: 'contact_channel',
                    component: 'TextField',
                    cols: { xs: 4, md: 4, lg: 4 },
                    params: {
                        label: 'Kênh Liên Hệ',
                        placeholder: 'Nhập kênh liên hệ...',
                    }
                }, {
                    name: 'contact_channel_information',
                    component: 'TextField',
                    cols: { xs: 6, md: 6.5, lg: 6.5 },
                    params: {
                        label: 'Thông Tin Chi Tiết',
                        placeholder: 'Nhập thông tin liên hệ...',
                    }
                }],
            }
        },
        {
            name: 'customer_service_information.customer_note', component: 'TextField',
            params: {
                multiline: true,
                label: 'Ghi Chú',
                placeholder: 'Nhập ghi chú...',
            },
            hidden: !['service-manager', 'first-contact']?.includes(methods.watch(`contact_label`)) || company,
        },
    ]

    const onSubmit = async (values) => {
        const form = { ...values }
        const response = await mutateAsync({ customerId, contactId, contact: form })

        if (!response || response?.error || response?.errors) {
            return setNotification({ open: true, message: response?.errors?.[0]?.message, severity: 'error' })
        }
        queryClientRefetchScope(queryClient, 'customer')
        onClose()
        return setNotification({ open: true, message: 'Cập nhật thông tin liên hệ thành công.', severity: 'success' })
    }
    return (
        <FormProvider {...methods}>
            <Box sx={{ height: '100vh', width: { xs: '100%', md: 600 }, p: 3 }}
                component="form" onSubmit={methods.handleSubmit(onSubmit)}>
                <Typography sx={{ fontWeight: 'medium', pb: 1.75, fontSize: 18 }}>
                    Chỉnh Sửa Thông Tin Liên Hệ
                </Typography>
                <Grid container spacing={2}>
                    {FIELDS?.map(({ name, component, params, ...props }, index) => {
                        if (props.hidden) return null;

                        const Component = CONTACT_FORM[component]

                        if (name === 'customer_service_information.customer_cs_followers') { console.log(Component) }
                        if (!Component) return null;


                        return <Grid item xs={12} md={props?.cols || 12} key={index}>
                            <Controller
                                name={name}
                                control={methods.control}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <FormControl fullWidth>
                                        <Component {...params} value={value} onChange={onChange} />
                                        {!!error?.message && <ErrorHelperText message={error?.message} />}
                                    </FormControl>
                                )}
                            />
                        </Grid>
                    })}
                </Grid>
                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: 2,
                    mt: 2,
                    justifyContent: 'flex-end'
                }}>
                    <Button size='small' variant='outlined' disabled={isLoading} onClick={onClose}>
                        Huỷ Thao Tác
                    </Button>
                    <Button size='small' variant='contained' disabled={isLoading || !methods?.formState?.isDirty} type="submit">
                        Cập Nhật
                    </Button>
                </Box>
            </Box>
        </FormProvider>
    )
}

export default ContactUpdate