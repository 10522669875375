import React from 'react'

const useOnBeforeUnload = (handler) => {
    React.useEffect(() => {
        const listener = (event) => {
            event.preventDefault();
            if (!handler) return
            return handler(event);
        }
        window.addEventListener("beforeunload", listener);

        return () => {
            window.removeEventListener("beforeunload", listener)
        }
    }, [handler]);
}


export default useOnBeforeUnload