import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const MuiSkeletonDefault = () => {
    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', justifyContent: 'center', height: 200, width: '100%' }}>
                <CircularProgress size={40} sx={{ m: 'auto' }} />
            </Box>
        </React.Fragment>
    )
}

export default MuiSkeletonDefault