import ServiceSystemApi from "@/apis/beta/Service/ServiceSystemApi";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useParams } from "react-router-dom";
import ServiceSystemCreate from "./Create";
import ServiceSystemUpdate from "./Update";
import { Fragment, useState } from "react";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";

const ServiceSystemInformation = () => {
  const { service_id: serviceId } = useParams();
  const { data } = ServiceSystemApi.Detail({ serviceId });

  const [show, setShow] = useState(true);

  if (!data || !data?.system)
    return <ServiceSystemCreate serviceId={serviceId} />;
  return (
    <Fragment>
      {show ? (
        <Fragment>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 0.5 }}>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ fontSize: 14, m: "auto 4px auto 0" }}>
                Ẩn thông tin
              </Typography>
              <IconButton
                size="small"
                sx={{ m: "auto 0" }}
                onClick={() => setShow(false)}
              >
                <VisibilityOffOutlined fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </Fragment>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography
            color="primary"
            sx={{
              fontSize: 14,
              fontWeight: "medium",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setShow(true)}
          >
            Xem thông tin cấu hình
          </Typography>
        </Box>
      )}
      <Collapse in={show}>
        <ServiceSystemUpdate
          attachments={data?.attachments || []}
          serviceId={serviceId}
          data={data?.system}
        />
      </Collapse>
    </Fragment>
  );
};

export default withSuspense(
  ServiceSystemInformation,
  MuiSkeleton["PaperInformation"]
);
