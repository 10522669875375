import DriveApi from "@/apis/beta/Drives/DriveApi";
import DepartmentApi from "@/apis/beta/Employee/DepartmentApi";
import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import withSuspense from "@/hocs/withSuspense";
import { MenuProps } from "@/styles/common";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import GradientCircularProgress from "@components/shared/MuiSkeleton/GradientLoading";
import MultipleSelectCustomize from "@components/shared/MultipleSelectCustomize";
import { useNotificationContext } from "@contexts/notification";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { Fragment, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";

const DrivesShare = ({ handleClose, open, path }) => {
  const { data: employees, isLoading } = EmployeeApi.ListEmployees();
  const { data: departments } = DepartmentApi.ListDepartments()
  const { setNotification } = useNotificationContext();


  const { data, isLoading: loading, refetch } = DriveApi.GetDriveShareEmployee(path);

  const { mutateAsync, isLoading: AddDriveShareLoading } = useMutation(
    DriveApi.AddDriveShare,
    {
      onError: (error) => {
        setNotification({
          open: true,
          message:
            error?.response?.data?.errors?.[0]?.message ||
            "Something went wrong",
          severity: "error",
        });
      },
    }
  );


  const {
    control,
    formState: { errors },
    handleSubmit,
    watch
  } = useForm({
    defaultValues: useMemo(() => {
      return {
        path: data?.data?.path || '',
        employees: data?.data?.employees || [],
        departments: data?.data?.departments || [],
        is_public: data?.data?.is_public || false,
        permission: data?.data?.permission || 'view'
      }
    }, [data])
  });

  const onSubmit = async (values) => {
    const response = await mutateAsync({
      ...values,
      path: values?.path || path
    });
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message || "Something went wrong",
        severity: "error",
      });
    }

    setNotification({
      open: true,
      message: "Success",
      severity: "success",
    });

    handleClose()

    refetch()
  };

  const handleCopyText = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setNotification({
        open: true,
        message: "URL copied to clipboard",
        severity: "success",
      });
    }).catch(() => {
      setNotification({
        open: true,
        message: "Failed to copy URL",
        severity: "error",
      });
    });
  };

  const url = `${window.location.origin}/files/drives?path=${encodeURIComponent(path)}`;
  const isFetching = AddDriveShareLoading || isLoading || loading;

  return (
    <Dialog open={open} handleClose={handleClose} fullWidth maxWidth="md">
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Chia sẻ đường dẫn</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="center" mt={2}>
            <Grid item xs={12} sm={10}>
              <TextField
                label="Đường dẫn chia sẻ"
                size="small"
                fullWidth
                variant="outlined"
                value={url}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleCopyText(url)}
                fullWidth
              >
                <ContentCopyIcon />
              </Button>
            </Grid>
          </Grid>
          <Collapse in={!watch("is_public")}>
            <Fragment>
              <Typography sx={{ fontSize: 14, mt: 1.5, mb: 0.5 }} color="text.grey">Chọn nhân viên</Typography>
              <Controller
                control={control}
                name="employees"
                render={({ field: { onChange, value } }) => {
                  const selecteds = employees?.filter(e => value?.includes(e?.employee_id))
                  return (
                    <FormControl fullWidth>
                      <MultipleSelectCustomize
                        limitTags={3}
                        value={selecteds}
                        options={employees || []}
                        formatCollection={{
                          value: "employee_id",
                          label: "employee_name",
                        }}
                        compareAttributes={["employee_name"]}
                        onChange={(event, newValue) => onChange(newValue?.map(x => x?.employee_id) || [])}
                        sx={{
                          "&.MuiAutocomplete-option": {
                            textTransform: "uppercase",
                          },
                        }}
                      />
                    </FormControl>)
                }}
              />
              <Typography sx={{ fontSize: 14, mt: 1.5, mb: 0.5 }} color="text.grey">Chọn phòng ban</Typography>
              <Controller
                control={control}
                name="departments"
                render={({ field: { onChange, value } }) => {
                  const selecteds = departments?.departments?.filter(dept => value?.includes(dept?.department_id)) || []
                  return (
                    <FormControl fullWidth>
                      <MultipleSelectCustomize
                        limitTags={3}
                        value={selecteds}
                        options={departments?.departments || []}
                        formatCollection={{
                          value: "department_id",
                          label: "department_name",
                        }}
                        compareAttributes={["department_name"]}
                        onChange={(event, newValue) => onChange(newValue?.map(dept => dept?.department_id) || [])}
                        sx={{
                          "&.MuiAutocomplete-option": {
                            textTransform: "uppercase",
                          },
                        }}
                      />
                    </FormControl>)
                }}
              />
              <Typography sx={{ fontSize: 14, mt: 1.5, mb: 0.5 }} color="text.grey">Chọn quyền</Typography>
              <Controller
                name="permission"
                disabled={isFetching}
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <Select
                      {...field}
                      size="small"
                      variant="outlined"
                      MenuProps={{
                        ...MenuProps
                      }}
                      disabled={isFetching}
                      fullWidth
                    >
                      <MenuItem value="view">Chỉ Xem</MenuItem>
                      <MenuItem value="edit">Thao Tác Chỉnh Sửa</MenuItem>
                    </Select>
                    {errors?.permission?.message && (
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {errors?.permission?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Fragment>
          </Collapse>
          <Box mt={2} sx={{ display: 'flex', flexDirection: 'row', gap: '1.5', alignItems: 'center' }}>
            <Controller
              name="is_public"
              disabled={isFetching}
              control={control}
              render={({ field: { onChange, value } }) => {
                return <Checkbox
                  size="small"
                  checked={value} onChange={onChange} />
              }}
            />
            <Typography sx={{ fontSize: 14 }} color="text.grey">Public file (bất kì ai có đường dẫn có thể xem)</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" size="small" onClick={handleClose} disabled={isFetching}>
            Huỷ
          </Button>
          <Button
            variant="contained"
            size="small"
            disabled={isFetching}
            startIcon={isFetching && <GradientCircularProgress />}
            type="submit"
          >
            Tạo
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default withSuspense(DrivesShare, MuiSkeleton["LinearProgress"]);
