import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, FormControl, Typography } from '@mui/material'
import dayjs from 'dayjs'
import _ from 'lodash'
import { Fragment } from 'react'


const getArrayFromValues = (min = 0, max = 10) => {
    return _.range(min, max)
}
const AnalyticHeader = ({ times = {}, setTimes }) => {

    const options = getArrayFromValues(2012, dayjs().get('year') + 1)?.map(x => ({ value: x, label: x }))

    const onChange = (year) => {
        setTimes({ lte: times?.lte?.set('year', year), year })
    }
    return (
        <Fragment>
            <Box sx={{ display: 'flex', mb: 2, justifyContent: 'flex-end', gap: 2, alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
                    <Typography sx={{ fontSize: 14 }} color='text.grey'>
                        Time Ranges:
                    </Typography>
                    <FormControl sx={{ minWidth: 150 }}>
                        <SingleSelectCustomize
                            options={options.reverse()}
                            value={options?.find(x => x?.value === times?.year) || undefined}
                            input={{
                                placeholder: "Chọn năm..."
                            }}
                            onChange={(event, newValue) => onChange(newValue?.value)}
                        />
                    </FormControl>
                </Box>
            </Box>
        </Fragment>
    )
}

export default AnalyticHeader