import Service from "./Service";
import ServiceAddon from "./ServiceAddon";
import Invoice from "./Invoice";
import Customer from "./Customer";
import DayOffExport from "./DayOffExport";
import BusinessesExport from "./BusinessesExport";
import Contact from "./Contact";
import TicketExport from "./TicketExport";
import ContractsExport from "./ContractsExport";

const Blank = () => {
  return <></>;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // CustomerQualifiedProspects,
  // ManageCustomerSold,
  Customer,
  Service,
  ServiceAddon,
  Invoice,
  Blank,
  DayOffExport,
  BusinessesExport,
  Contact,
  ContractsExport,
  TicketExport,
};
