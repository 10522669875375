import DayoffApi from "@/apis/beta/Employee/DayoffApi"
import BreadCrumb from "@/components/shared/BreadCrumb"
import withPermission from '@/hocs/withPermission'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeletonDefault from "@components/Skeletons/Default"
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import DayoffForm from '../shared/Form'

const Cancel = () => {

    const { dayoff_id } = useParams()
    const { data, refetch: callback } = DayoffApi.Detail(dayoff_id)

    return (
        <Box>
            <Typography sx={{ fontSize: { xs: 18, md: 24 }, fontWeight: 'medium' }}>Huỷ Phiếu Nghỉ</Typography>
            <BreadCrumb
                data={[
                    { label: "Tài khoản cá nhân", reactLink: "/account/dayoffs" },
                    { label: `PN-${dayoff_id}` },
                    { label: "Huỷ Phiếu Nghỉ" }
                ]}
            />
            <DayoffForm
                type='cancel'
                data={data}
                callback={callback}
            />
        </Box>
    )
}

export default withSuspense(withPermission(Cancel, { feature: "dayoff", action: "destroy" }), MuiSkeletonDefault)