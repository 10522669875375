import { Collapse } from "@mui/material";
import React, { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import ServiceComponentShareds from "../shared";
import DeviceLiquidationAddons from "./private/Addon";
import ServiceManagementBilling from "../../shared/ServiceManagementBilling";

const DeviceLiquidation = ({ isPoc, product }) => {
  const { watch } = useFormContext();
  return (
    <Fragment>
      <Collapse
        in={!!product && !!Boolean(watch(`product_informations`)?.length > 0)}
      >
        {React.createElement(
          ServiceComponentShareds["Package.Information"],
          { product },
          {}
        )}
      </Collapse>
      <Collapse
        in={
          product?.product_addons?.length !== 0 &&
          !isPoc &&
          !!Boolean(watch(`product_customizable`))
        }
      >
        <DeviceLiquidationAddons productAddons={product?.product_addons} />
      </Collapse>
      {watch(`product_template`) !== "DeviceLiquidation" &&
        React.createElement(
          ServiceComponentShareds["Package.Payment"],
          { isPoc: isPoc },
          {}
        )}
      {watch(`product_template`) !== "DeviceLiquidation" && !isPoc && (
        <ServiceManagementBilling />
      )}
    </Fragment>
  );
};

export default DeviceLiquidation;
