import { GENDERS } from "@/libs/constants";
import DatePicker from "@components/DatePicker";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import Country from "../shared/Country";
import District from "../shared/District";
import Province from "../shared/Province";
import Ward from "../shared/Ward";
import ErrorHelperText from "@/components/shared/ErrorHelperText";

const Owner = () => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <Typography
        sx={{
          fontSize: {
            xs: 16,
            md: 18,
          },
          fontWeight: "medium",
        }}
      >
        Thông tin bản khai tên miền (Thông Tư Số 24/2015/TT-BTTTT Của Bộ Thông
        Tin Và Truyền Thông)
      </Typography>
      <Typography sx={{ fontSize: 14, fontWeight: "medium" }}>
        Loại Bản Khai *
      </Typography>
      <Controller
        name="domain.domain_extra_fields.for"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <RadioGroup
              value={value}
              onChange={onChange}
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                gap: 0.5,
              }}
            >
              {[
                { value: "canhan", label: "Cá Nhân" },
                { value: "congty", label: "Doanh Nghiệp" },
              ]?.map((item, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    {...item}
                    control={<Radio size="small" />}
                  />
                );
              })}
            </RadioGroup>
          );
        }}
      />
      <Typography sx={{ fontSize: 14, fontWeight: "medium", mb: 1.5 }}>
        Thông tin chủ thể
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            name="domain.domain_extra_fields.uiName"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="Tên Chủ Thể (cá nhân/công ty) *"
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                    error={!!errors?.domain?.domain_extra_fields?.uiName}
                    helperText={errors?.domain?.domain_extra_fields?.uiName}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {watch("domain.domain_extra_fields.for") === "congty" ? (
            <Controller
              name="domain.domain_extra_fields.uiTaxCode"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      label="Mã Số Thuế *"
                      InputLabelProps={{ shrink: true }}
                      value={value}
                      onChange={onChange}
                      error={!!errors?.domain?.domain_extra_fields?.uiTaxCode}
                      helperText={
                        errors?.domain?.domain_extra_fields?.uiTaxCode?.message
                      }
                    />
                  </FormControl>
                );
              }}
            />
          ) : (
            <Controller
              name="domain.domain_extra_fields.uiID_Number"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      label="CMND/CCCD *"
                      placeholder="Nhập CMND/ CCCD..."
                      InputLabelProps={{ shrink: true }}
                      value={value}
                      onChange={onChange}
                      error={!!errors?.domain?.domain_extra_fields?.uiID_Number}
                      helperText={
                        errors?.domain?.domain_extra_fields?.uiID_Number
                          ?.message
                      }
                    />
                  </FormControl>
                );
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Country reset="uiProvince" nameControlManager="uiCountry" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Province
            reset="uiDistrict"
            priority="uiCountry"
            nameControlManager="uiProvince"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <District
            reset="uiWard"
            priority="uiProvince"
            nameControlManager="uiDistrict"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Ward
            province="uiProvince"
            priority="uiDistrict"
            nameControlManager="uiWard"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="domain.domain_extra_fields.uiAddress"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="Địa chỉ *"
                    placeholder="Nhập địa chỉ khách hàng..."
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                    error={!!errors?.domain?.domain_extra_fields?.uiAddress}
                    helperText={
                      errors?.domain?.domain_extra_fields?.uiAddress?.message
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="domain.domain_extra_fields.uiEmail"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="Email *"
                    placeholder="Nhập email của khách hàng..."
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                    error={!!errors?.domain?.domain_extra_fields?.uiEmail}
                    helperText={
                      errors?.domain?.domain_extra_fields?.uiEmail?.message
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="domain.domain_extra_fields.uiPhone"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="Số Điện Thoại * "
                    placeholder="Nhập số điện thoại..."
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                    error={!!errors?.domain?.domain_extra_fields?.uiPhone}
                    helperText={
                      errors?.domain?.domain_extra_fields?.uiPhone?.message
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        {watch("domain.domain_extra_fields.for") === "canhan" && (
          <Grid item xs={12} md={6}>
            <Controller
              name="domain.domain_extra_fields.uiGender"
              control={control}
              render={({ field: { value, onChange } }) => {
                const selected = GENDERS.find((x) => x.value === value) || null;
                return (
                  <FormControl fullWidth>
                    <SingleSelectCustomize
                      value={selected}
                      options={GENDERS.slice(0, 2)}
                      onChange={(event, newValue) =>
                        onChange(newValue?.value || "")
                      }
                      input={{
                        placeholder: "chọn giới tính...",
                        label: "Giới tính *",
                      }}
                    />
                    {!!errors?.domain?.domain_extra_fields?.uiGender && (
                      <ErrorHelperText
                        message={
                          errors?.domain?.domain_extra_fields?.uiGender?.message
                        }
                      />
                    )}
                  </FormControl>
                );
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Controller
            name="domain.domain_extra_fields.uiFax"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="Số FAX"
                    placeholder="Số FAX..."
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        {watch("domain.domain_extra_fields.for") === "canhan" && (
          <Grid item xs={12} md={6}>
            <Controller
              name="domain.domain_extra_fields.uiBirthdate"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl fullWidth>
                    <DatePicker
                      value={value}
                      onChange={onChange}
                      label="Ngày Sinh *"
                      size="small"
                    />
                    {!!errors?.domain?.domain_extra_fields?.uiBirthdate && (
                      <ErrorHelperText
                        message={
                          errors?.domain?.domain_extra_fields?.uiBirthdate
                            ?.message
                        }
                      />
                    )}
                  </FormControl>
                );
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Controller
            name="domain.domain_extra_fields.uiCompany"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="Tên Công Ty"
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="domain.domain_extra_fields.uiPosition"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="Chức Vụ"
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Owner;
