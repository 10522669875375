import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";

export const EXPLAINATION_RESULT_STATUS = [
  { value: "", label: "Chưa Cập Nhật" },
  { value: "processing", label: "Đang Thực Hiện" },
  { value: "pending", label: "Pending" },
  { value: "losed", label: "Khai Thác Khách Hàng Thất Bại" },
  { value: "completed", label: "Mở Sản Phẩm/ Dịch Vụ Thành Công" },
];

const ExplainationResult = ({ value, onChange, ...props }) => {
  return (
    <SingleSelectCustomize
      options={EXPLAINATION_RESULT_STATUS}
      value={
        EXPLAINATION_RESULT_STATUS?.find((x) => x?.value === value) || null
      }
      compareAttributes={["label"]}
      onChange={(event, value) => onChange(value?.value || "")}
      {...props}
      input={{
        placeholder: "Nhập tên dịch vụ tìm kiếm...",
        ...props?.input,
      }}
      sx={{
        "&.MuiAutocomplete-option": {
          textTransform: "uppercase",
        },
      }}
    />
  );
};

export default ExplainationResult;
