import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import PermissionGroupApi from "@/apis/beta/Permission/PermissionGroupApi";
import {
  buildQueryFilterCondition,
  queryClientRefetcher,
  sleep,
} from "@/functions";
import useDebounce from "@/hooks/useDebounce";
import { useNotificationContext } from "@contexts/notification";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useQueryClient } from "react-query";
import Present from "../Present";

const ModalEmployee = ({ groupId = -1, assigneeIds = [], handleClose }) => {
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [selecteds, setSelecteds] = React.useState(assigneeIds);

  const filterableEmployees = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
    const fieldQueries = [
      {
        name: "employees.employee_id",
        operator: "$eq",
        disabled: !parseInt(debouncedSearchQuery),
        value: parseInt(debouncedSearchQuery),
      },
      {
        name: "employees.employee_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "employees.employee_code",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
    ];
    return buildQueryFilterCondition("$or", 0, fieldQueries);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const { setNotification } = useNotificationContext();

  const [submit, setSubmit] = React.useState(false);
  const onConfirm = async () => {
    if (_.orderBy(selecteds).toString() === _.orderBy(assigneeIds).toString()) {
      return setNotification({
        open: true,
        message: "Nothing for update!!!",
        severity: "error",
      });
    }
    setSubmit(true);
    const response = await PermissionGroupApi.Assignee({
      id: groupId,
      assigneeIds: selecteds,
    });
    await sleep(500);
    setSubmit(false);
    if (!response || response.status !== 200)
      return setNotification({
        open: true,
        message: "Cập nhật phân quyền nhân viên thất bại",
        severity: "error",
      });
    setNotification({
      open: true,
      message: "Cập nhật phân quyền nhân viên thành công",
      severity: "success",
    });
    queryClientRefetcher(queryClient, [
      "permission-groups.list",
      "permission-group.detail",
      "employee.permissions",
    ]);
    return handleClose();
  };

  const {
    data: { employees },
    isFetching,
  } = EmployeeApi.List({
    page: 1,
    pageSize: 5,
    query: filterableEmployees() || ``,
  });

  return (
    <Dialog fullWidth maxWidth="md" open={true}>
      <DialogTitle>Chỉnh Sửa Phân Quyền Nhân Viên</DialogTitle>
      <DialogContentText sx={{ p: "0 24px" }}>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <TextField
            size="small"
            fullWidth
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            placeholder="Nhập tên nhân viên tìm kiếm..."
          />
        </FormControl>
        {isFetching ? (
          <CircularProgress size={24} />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 0.5,
            }}
          >
            {employees?.length === 0 ? (
              <Typography></Typography>
            ) : (
              employees?.map((e, index) => {
                if (selecteds?.includes(e?.employee_id)) return null;
                return (
                  <Box
                    sx={{
                      display: "flex",
                      p: 1,
                      border: "1px solid #FFF",
                      ":hover": {
                        cursor: "pointer",
                        bgcolor: "rgba(0, 0, 0, 0.18)",
                        border: "1px solid #1a237e",
                      },
                    }}
                    onClick={() =>
                      setSelecteds((prev) => [e?.employee_id, ...prev])
                    }
                    key={index}
                  >
                    <FormControl fullWidth sx={{ maxWidth: 100 }}>
                      <Typography sx={{ fontSize: 14 }}>
                        [ID: {e?.employee_id}]
                      </Typography>
                    </FormControl>
                    <FormControl fullWidth sx={{ maxWidth: 200 }}>
                      <Typography sx={{ fontSize: 14 }}>
                        [Mã NV: {e?.employee_code}]
                      </Typography>
                    </FormControl>
                    <FormControl fullWidth>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: "medium",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        [ {e?.employee_name} ]
                      </Typography>
                    </FormControl>
                  </Box>
                );
              })
            )}
          </Box>
        )}
      </DialogContentText>
      <DialogContent>
        <Typography
          sx={{
            fontWeight: "medium",
            mb: 1,
          }}
        >
          Danh Sách Phân Quyền
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Present
            assigneeIds={assigneeIds}
            selecteds={selecteds}
            setSelecteds={setSelecteds}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          disabled={submit}
          onClick={handleClose}
          sx={{ height: 36 }}
        >
          Huỷ Thao Tác
        </Button>
        <Button
          variant="contained"
          size="small"
          disabled={submit}
          startIcon={
            submit && <CircularProgress size={14} sx={{ color: "#777980" }} />
          }
          onClick={onConfirm}
          sx={{
            height: 36,
            minWidth: 120,
          }}
        >
          Cập nhật
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEmployee;
