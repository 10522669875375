import ErrorHelperText from "@components/shared/ErrorHelperText";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { FormControl } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

export const DOMAIN_PAYMENT_TYPES = [
  { value: true, label: "Trả trước" },
  { value: false, label: "Trả sau" },
];

const DomainPaymentType = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name="product_paid"
      control={control}
      render={({ field: { value, onChange } }) => {
        const selected =
          DOMAIN_PAYMENT_TYPES.find((x) => x.value === value) || null;
        return (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <SingleSelectCustomize
              options={DOMAIN_PAYMENT_TYPES}
              value={selected}
              input={{
                label: "Hình thức thanh toán *",
                placeholder: "Chọn hình thức thanh toán...",
              }}
              onChange={(event, newValue) => onChange(newValue?.value || "-1")}
            />
            {!!errors?.product_payment_type && (
              <ErrorHelperText
                message={errors?.product_payment_type?.message}
              />
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default DomainPaymentType;
