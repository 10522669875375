import { Box, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";

const Contracts = ({ contracts, isAddon = false }) => {
  const lastContract = useMemo(() => {
    if (isAddon) {
      return _.sortBy(contracts, ["subcontract_expiry_date"]).at(-1) || null;
    }
    return _.sortBy(contracts, ["expiry_date"]).at(-1) || null;
  }, [contracts, isAddon]);
  if (!lastContract) return null;
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        p: "8px 12px",
        alignItems: "center",
      }}
    >
      <Link
        to={`/customer_contracts/${lastContract?.contract_id}${
          isAddon ? `/subcontracts/${lastContract?.subcontract_id}` : ""
        }`}
        replace
        target="_blank"
      >
        <Typography
          sx={{ fontSize: 14, textDecoration: "underline" }}
          color="primary"
        >
          {lastContract?.[!isAddon ? "contract_number_id" : "subcontract_code"]}
        </Typography>
      </Link>
    </Box>
  );
};

export default Contracts;
