import { create, enforce, test } from "vest";

const serviceAddonValidator = create((data = {}) => {
  test(`service_name`, "Vui lòng chọn sản phẩm/ dịch vụ mua thêm.", () => {
    enforce(data.service_name).isNotEmpty();
  });

  test(`service_price`, "Đơn giá bán không được để trống", () => {
    enforce(data.service_price).isNotEmpty();
  });

  test(`service_addon_qty`, "Vui lòng nhập số lượng mua", () => {
    const isValid = parseFloat(data?.service_addon_qty) >= 1;
    enforce(isValid).isTruthy();
  });
});

export default serviceAddonValidator;
