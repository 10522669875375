import { format_date_short } from "@/functions";
import { INDUSTRIES } from "@/libs/constants";
import { CellPricing } from "@components/DataGrid/Cell";
import _ from "lodash";
import LastedUpdate from "./LastedUpdate";

const INITIAL_COLUMNS = [
    {
        field: "sale_tracking_id",
        headerName: "SALE Phụ Trách",
        minWidth: 260,
    }, {
        field: "customer_industry",
        headerName: "Lĩnh Vực",
        width: 180,
    }, {
        field: "customer_email",
        headerName: "Email",
        width: 200,
    }, {
        field: "customer_created_at",
        headerName: "Ngày Tạo KH",
        width: 150,
        renderCell: ({ value }) => !!value && format_date_short(value)
    }, {
        field: "explainations.service_interestings",
        headerName: "Dịch Vụ Quan Tâm",
        width: 200,
        valueGetter: ({ row }) => row?.explaination?.service_interestings?.join(', '),
    }, {
        field: "explainations.budget_explaination",
        headerName: "Budget/ Month",
        width: 200,
        valueGetter: ({ row }) => row?.explaination?.budget_explaination,
        renderCell: ({ value }) => !!value && <CellPricing price={value} />
    }, {
        field: "explainations.expected_date",
        headerName: "Thời Gian Dự Tính Bán Hàng",
        width: 200,
        valueGetter: ({ row }) => row?.explaination?.expected_date,
        renderCell: ({ value }) => !!value && format_date_short(value)
    }, {
        field: "journeys.journey_update",
        headerName: "Cập Nhật Gần Nhất",
        width: 800,
        valueGetter: ({ row }) => {
            if (!row?.journey_updates || !row?.journey_updates?.length) return null
            return _.slice(row?.journey_updates, row?.journey_updates?.length - 4)?.map((journeyUpdate) => {
                return journeyUpdate
            })
        },
        renderCell: ({ value }) => {
            if (!value) return null;
            return <LastedUpdate journeyUpdates={value} />
        }
    },
]


const buildCustomerQualifiedProspects = ({ customers = [] }) => {
    let actives = []
    _.each(customers, (customer) => {
        const journeys = customer?.journeys
        const journey = journeys?.[0]
        actives.push({
            hierarchy: [customer?.customer_id],
            customer_id: customer?.customer_id,
            customer_name: customer?.customer_name?.toUpperCase(),
            customer_company_name: customer?.customer_company_name?.toUpperCase(),
            customer_industry: customer?.customer_industry?.toUpperCase(),
            customer_email: customer?.customer_email,
            customer_created_at: customer?.customer_created_at,
            customer_service_interestings: customer?.customer_service_interestings,
            explaination: journey?.explaination,
            journey_updates: journey?.journey_updates,
            sale_tracking_id: journey?.sales?.map(employee => employee?.employee_name).join(', ')
        })
        if (!!journeys && journeys?.length) {
            journeys?.slice(1)?.forEach((journeyLoop) => {
                actives.push({
                    hierarchy: [customer?.customer_id, journeyLoop?.customer_journey_id],
                    customer_id: '',
                    customer_name: '',
                    customer_company_name: '',
                    customer_industry: customer?.customer_industry?.toUpperCase(),
                    customer_email: customer?.customer_email,
                    customer_created_at: customer?.customer_created_at,
                    explaination: journeyLoop?.explaination,
                    journey_updates: journeyLoop?.journey_updates,
                })
            })
        }
    })
    return actives
}


const buildFilterables = ({ employees = [], productGroups = [] }) => {
    return [
        {
            field: "personal_informations.name",
            label: "Tên Khách Hàng",
            operators: { type: "string" }
        }, {
            field: "personal_informations.email",
            label: "Email Khách Hàng",
            operators: { type: "string" }
        }, {
            field: "customers.customer_created_at",
            label: "Ngày Tạo KH",
            operators: { type: "date" }
        }, {
            field: "customer_service_informations.customer_sale_followers",
            label: "SALE Phụ Trách",
            operators: {
                type: "object",
                operators: ["$included"],
                collections: employees?.map((employee) => ({ value: employee?.employee_id, label: employee?.employee_name })),
            },
        }, {
            field: "customer_service_informations.customer_industry",
            label: "Lĩnh Vực",
            operators: { type: "object", collections: INDUSTRIES },
        }, {
            field: "customer_service_informations.customer_service_interestings",
            label: "Dịch Vụ Quan Tâm",
            operators: {
                type: "object",
                collections: productGroups?.map((productGroup) => ({ value: productGroup?.product_group_name?.toUpperCase(), label: productGroup?.product_group_name })),
            },
        },
    ]
}
const CustomerQualifiedProspectLibs = {
    columns: INITIAL_COLUMNS,
    rows: buildCustomerQualifiedProspects,
    filterables: buildFilterables
}
export default CustomerQualifiedProspectLibs;