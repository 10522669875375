import CVApi from '@/apis/beta/Employee/CVApi';
import useMutationWithNotification from '@/hooks/useMutationWithNotification';
import useQueryWithCustomized from '@/hooks/useQueryWithCustomized';
import AutocompleteAddedNewValue from '@components/AutocompleteAddedNewValue';
import React from 'react'

const SelectSourceCv = ({
  value,
  onChange,
  field,
}) => {
  const { data: sources, isLoading: sourcesLoading, refetch } = useQueryWithCustomized(
    "get-cv-source",
    CVApi.GetAllCvSource
  );

  const { mutateAsync: addNewCvMutate, isLoading: addNewCvMutateLoading, notify } =
    useMutationWithNotification(CVApi.AddNewCvSource);

  const onAddNewValue = async (newValue) => {
    const newSourceName = newValue?.inputValue;
    notify("warning", "Source đang được thêm");
    const response = await addNewCvMutate({ cv_source_name: newSourceName });
    if(!response?.errors) {
        notify("success", "Source đã được thêm");
        onChange({label: response?.data?.cv_source_name, value: response?.data?.cv_source_id})
    }
    refetch()
  };

  const newValue =
    sources?.data?.filter((item) => item?.cv_source_id === value)?.map(item => ({label: item?.cv_source_name, value: item?.cv_source_id}))?.[0] || value;

  return (
    <AutocompleteAddedNewValue
      value={newValue}
      onAddNewValue={onAddNewValue}
      onChange={onChange}
      options={
        sources?.data?.map((item) => ({
          label: item?.cv_source_name,
          value: item?.cv_source_id,
        })) || []
      }
      disabled={sourcesLoading || addNewCvMutateLoading}
      {...field}
    />
  );
};

export default SelectSourceCv