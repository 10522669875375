import CustomerServiceApi from "@/apis/beta/Customer/CustomerServiceApi";
import { format_date_short, removeObjectKeys } from "@/functions";
import { useNotificationContext } from "@contexts/notification";
import { vestResolver } from "@hookform/resolvers/vest";
import { Box, Button, CircularProgress, Paper } from "@mui/material";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PocTimer from "../../Poc/PocTimer";
import OrderNote from "../OrderNote";
import OrderProducts from "../OrderProducts";
import orderValidator from "./validators";

const OrderForm = ({
  saleplanClosed = true,
  type = "create",
  defaultValues = {},
  customerId = -1,
  saleplanId = -1,
}) => {
  let navigate = useNavigate();
  const { setNotification } = useNotificationContext();

  const [submit, setSubmit] = useState(false);

  const methods = useForm({
    resolver: vestResolver((props) =>
      orderValidator({ ...props, method: type })
    ),
    defaultValues: defaultValues,
  });

  const onSubmit = async (values) => {
    if (!values?.order_products || values?.order_products?.length === 0) {
      return setNotification({
        open: true,
        severity: "error",
        message: "[Giỏ hàng hiện tại của bạn đang trống.]",
      });
    }
    let form = {
      ...removeObjectKeys(values, [
        "customer_id",
        "order_products",
        "saleplan_expected_date",
        "order_startdate",
        "order_duedate",
      ]),
      order_metadata: {
        products: values?.order_products || [],
        order_startdate: format_date_short(values?.order_startdate),
        order_duedate: format_date_short(values?.order_duedate),
      },
    };
    return await handleSubmitMetadata(
      parseInt(values?.customer_id),
      values?.order_type,
      form
    );
  };

  const handleSubmitMetadata = async (
    customerId = -1,
    orderType = "",
    metadata = {}
  ) => {
    setSubmit(true);
    switch (orderType) {
      // case "saleplan":
      //   const saleplanMetadata = {
      //     saleplan_expected_date: format_date_short(
      //       methods.getValues("saleplan_expected_date")
      //     ),
      //     saleplan_note: metadata?.order_note,
      //     saleplan_amount: methods.getValues(`saleplan_amount`),
      //     saleplan_metadata: {
      //       products: metadata?.order_metadata?.products || [],
      //     },
      //   };
      //   if (type === "update") {
      //     return await hanldeUpdateSaleplan({
      //       customerId: customerId,
      //       saleplan: saleplanMetadata,
      //     });
      //   } else {
      //     return await hanldeCreateSaleplan({
      //       customerId: customerId,
      //       saleplan: saleplanMetadata,
      //     });
      //   }
      case "trial":
      case "new-order":
        return await hanldeCreateOrder({
          customerId: customerId,
          order: metadata,
        });
      default:
        setSubmit(false);
        return setNotification({
          open: true,
          message: "Undefined order type for create order.",
          severity: "error",
        });
    }
  };

  const hanldeCreateOrder = async ({ customerId = -1, order = {} }) => {
    const response = await CustomerServiceApi.CreatePOC({
      customerId: customerId,
      data: order,
    });
    setSubmit(false);
    if (!response || response?.errors)
      return setNotification({
        open: true,
        message: "Thêm mới sản phẩm dịch vụ thất bại.",
        severity: "error",
      });
    setNotification({
      open: true,
      message: "Thêm mới sản phẩm dịch vụ thành công.",
      severity: "success",
    });
    return navigate(`/customers/${customerId}/services`, { replace: true });
  };

  return (
    <FormProvider {...methods}>
      <Paper
        component="form"
        sx={{ p: 3 }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {methods.watch("order_type") === "trial" && (
            <PocTimer disabled={saleplanClosed} />
          )}
          <OrderProducts disabled={saleplanClosed} />
          <OrderNote disabled={saleplanClosed} />
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}
        >
          <Button
            size="small"
            variant="contained"
            type="submit"
            startIcon={
              submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
            }
            sx={{ minWidth: 300 }}
            disabled={submit}
          >
            tạo POC
          </Button>
        </Box>
      </Paper>
    </FormProvider>
  );
};

export default OrderForm;
