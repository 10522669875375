import { format_numeral_price } from "@/functions";
import { SERVICE_PERIODS } from "@/libs/constants";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";

const Overview = ({ products = [] }) => {
  const { setValue, watch } = useFormContext();

  const orderAmount = React.useMemo(() => {
    let amount = 0;
    products.forEach((product) => {
      if (!product?.product_paid) {
        // not plus amount for product payment type -> postpaid
      } else {
        amount += parseFloat(product?.product_pricing_amount);
      }
      const coff =
        SERVICE_PERIODS.find(
          (item) => item?.value === product?.product_billing_cycle
        )?.coefficient || 0;
      product?.product_addons?.forEach((addon) => {
        amount +=
          Math.round(
            parseFloat(addon?.qty) *
              parseFloat(addon?.pricing_apply) *
              coff *
              100
          ) / 100;
      });
    });
    try {
      setValue("order_amount", amount);
      if (watch(`order_type`) === "saleplan") {
        setValue("saleplan_amount", amount);
      }
    } catch (error) {
      console.log(error?.message);
    }
    return amount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  return (
    <Box
      sx={{
        mt: 2,
        mb: 2,
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Box sx={{ m: "auto 0" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            borderBottom: "1px solid rgba(0, 0, 0, 0.18)",
            mb: 1.5,
          }}
        >
          <Typography>Tạm Tính: </Typography>
          <Box
            sx={{
              minWidth: 200,
              display: "inline-flex",
              justifyContent: "flex-end",
            }}
          >
            <Typography component="div">
              <Typography
                sx={{
                  display: "inline-flex",
                  textDecoration: "underline",
                }}
              >
                đ
              </Typography>
              {format_numeral_price(orderAmount)}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            borderBottom: "1px solid rgba(0, 0, 0, 0.18)",
            mb: 1.5,
          }}
        >
          <Typography sx={{ fontSize: 14, color: "#777680" }}>
            @Thuế:{" "}
          </Typography>
          <Box
            sx={{
              minWidth: 200,
              display: "inline-flex",
              justifyContent: "flex-end",
            }}
          >
            <Typography component="div" sx={{ color: "#777680" }}>
              <Typography
                sx={{
                  fontSize: 14,
                  display: "inline-flex",
                  color: "#777680",
                  textDecoration: "underline",
                }}
              >
                đ
              </Typography>
              {format_numeral_price(orderAmount * 0.1)}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            borderBottom: "1px solid rgba(0, 0, 0, 0.18)",
          }}
        >
          <Typography sx={{ fontSize: 18 }}>Thành Tiền: </Typography>
          <Box
            sx={{
              minWidth: 200,
              display: "inline-flex",
              justifyContent: "flex-end",
            }}
          >
            <Typography
              component="div"
              sx={{
                fontSize: 18,
              }}
            >
              <Typography
                sx={{
                  display: "inline-flex",
                  textDecoration: "underline",
                }}
              >
                đ
              </Typography>
              {format_numeral_price(orderAmount + orderAmount * 0.1)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Overview;
