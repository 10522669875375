import { CUSTOMER_STATUS_TYPES } from '@/libs/constants'
import { CellStatus } from '@components/DataGrid/Cell'

const ContactStatus = ({ contactStatus = 'pending' }) => {
    console.log(`contactStatus`, contactStatus)
    return (
        <CellStatus data={CUSTOMER_STATUS_TYPES?.find(item => item?.value === contactStatus)} component='muiChip' />
    )
}

export default ContactStatus