import SubcontractApi from '@/apis/beta/Contract/SubcontractApi';
import withSuspense from '@/hocs/withSuspense'
import NumericFormatCustom from '@components/NumericFormatCustom';
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { FormControl, TextField } from '@mui/material';
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form';

const SubcontractCode = ({
    contractId = -1
}) => {
    const { setValue, control } = useFormContext()
    const { data: subcontractCodeNumber } = SubcontractApi.SubcontractCodeNumber({ contractId });
    useEffect(() => {
        setValue(`subcontract_code_id`, subcontractCodeNumber?.subcontract_code || "")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subcontractCodeNumber])
    return (
        <Controller
            name="subcontract_code_id"
            control={control}
            render={({ field }) => {
                return (
                    <FormControl fullWidth>
                        <TextField
                            {...field}
                            label="Số PLHĐ"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                inputComponent: NumericFormatCustom
                            }}
                            size="small"
                            placeholder='Nhập số PLHĐ...'
                        />
                    </FormControl>
                )
            }}
        />
    )
}

export default withSuspense(SubcontractCode, MuiSkeleton["TextField"])