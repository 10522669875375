import CustomerContactApi from '@/apis/beta/Customer/CustomerContactApi'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { FormControl, Paper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import ContactDetail from '../Detail'

const ContactList = ({ customerId = -1 }) => {
    const { data: contacts } = CustomerContactApi.List({ customerId })
    const [searchParams, setSearchParams] = useSearchParams()

    const [contact, setContact] = useState(contacts?.find(cta => !!cta?.contact_first) || contacts?.[0] || null)

    const [onWaiting, setOnWaiting] = useState(false)

    useEffect(() => {
        setOnWaiting(true)

        const timer = setTimeout(() => {
            setOnWaiting(false)
        }, [300])
        return () => clearTimeout(timer)
    }, [contact])

    useEffect(() => {
        return setContact(contacts?.find(cta => cta?.customer_contact_id === contact?.customer_contact_id) || contacts?.find(cta => !!cta?.contact_first) || contacts?.[0] || null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contacts])

    useEffect(() => {
        if (!searchParams.get('contact_id')) return;

        return setContact(contacts?.find(cta => cta?.customer_contact_id === parseInt(searchParams?.get('contact_id'))))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams.get('contact_id')])

    return (
        <>
            <Typography sx={{ fontWeight: 'medium', mb: { xs: 1.25, md: 0.5 } }}>
                Danh Sách Liên Hệ Của Khách Hàng
            </Typography>
            <Paper sx={{ p: 2 }} elevation={8}>
                <FormControl fullWidth sx={{ mb: 2.25 }}>
                    <SingleSelectCustomize
                        options={contacts}
                        value={contacts?.find(cta => cta?.customer_contact_id === contact?.customer_contact_id)}
                        formatCollection={{
                            label: 'name',
                            value: 'customer_contact_id',
                        }}
                        compareAttributes={['name', 'email']}
                        onChange={(event, newValue) => {
                            setContact(newValue)
                            setSearchParams({})
                        }}
                    />
                </FormControl>
                {!onWaiting
                    ? <ContactDetail contact={contact} isCustomer={contact?.contact_type === 'customer'} />
                    : <MuiSkeleton.LoadingDotBox />
                }
            </Paper>
        </>
    )
}

export default withSuspense(ContactList, MuiSkeleton["LoadingDotBox"])