import { UNLIMITED } from "@/libs/constants";
import NumericFormatCustom from "@components/NumericFormatCustom";
import { AddOutlined } from "@mui/icons-material";
import { Box, Button, FormControl, TextField } from "@mui/material";
import { useState } from "react";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";

const ProductConfigoptionPricingSteps = ({ index }) => {
  const { control, watch } = useFormContext();

  const { fields, append, update } = useFieldArray({
    control: control,
    name: `product_configoptions.[${index}].product_pricing_steps`,
  });

  const pricings = useWatch({
    control: control,
    name: `product_configoptions.[${index}].product_pricing_steps`,
  });

  const appendTierd = () => {
    const lastChild = pricings[pricings.length - 1];
    if (!lastChild) return;
    try {
      update(pricings.length - 1, {
        ...lastChild,
        end: lastChild?.start * 5,
      });
    } catch (error) {
      console.log(error?.message);
    }

    return append({
      ...lastChild,
      start: lastChild?.start * 5,
      end: 999999999,
    });
  };
  const [updating, setUpdating] = useState(false);

  return (
    <Box>
      {fields &&
        fields.map((field, k) => {
          if (
            watch(`product_payment_type`) === "prevpaid-crosscheck" &&
            !(
              parseFloat(field?.end) >
              parseFloat(watch(`product_configoptions.[${index}].value`) || 0)
            )
          )
            return null;
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 1.5,
                mt: 2,
                p: {
                  xs: k % 2 !== 0 ? "8px 4px" : "8px 0",
                  md: 0,
                },
                bgcolor:
                  k % 2 !== 0
                    ? {
                        xs: "rgba(0,0,0,0.12)",
                        md: "#FFF",
                      }
                    : "#FFF",
              }}
              key={field.id}
            >
              <Controller
                name={`product_configoptions.[${index}].product_pricing_steps.[${k}].start`}
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <FormControl fullWidth>
                      <TextField
                        value={value}
                        onChange={onChange}
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                        }}
                        disabled={pricings.length - 1 > k}
                        size="small"
                        label="Từ"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  );
                }}
              />
              <Controller
                name={`product_configoptions.[${index}].product_pricing_steps.[${k}].end`}
                control={control}
                render={({ field: { value, onChange } }) => {
                  const isUnlimited = parseFloat(value) === UNLIMITED;

                  return (
                    <FormControl fullWidth>
                      <TextField
                        value={isUnlimited ? "UNLIMITED" : value}
                        onChange={onChange}
                        InputProps={{
                          inputComponent: isUnlimited
                            ? "input"
                            : NumericFormatCustom,
                        }}
                        size="small"
                        disabled={pricings.length - 1 > k + 1}
                        label="Đến"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  );
                }}
              />
              <Controller
                name={`product_configoptions.[${index}].product_pricing_steps.[${k}].pricing`}
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <FormControl fullWidth>
                      <TextField
                        value={value}
                        onChange={onChange}
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                        }}
                        size="small"
                        disabled
                        label="Đơn giá niêm yết *"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  );
                }}
              />
              <Controller
                name={`product_configoptions.[${index}].product_pricing_steps.[${k}].pricing_minimum`}
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <FormControl fullWidth>
                      <TextField
                        value={value}
                        disabled
                        onChange={onChange}
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                        }}
                        size="small"
                        label="Đơn giá min *"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  );
                }}
              />
              <Controller
                name={`product_configoptions.[${index}].product_pricing_steps.[${k}].pricing_apply`}
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <FormControl fullWidth>
                      <TextField
                        value={value}
                        onChange={onChange}
                        onBlur={(event) => {
                          console.log(event);
                          if (
                            watch(`product_configoptions.[${index}].method`) ===
                            "tierd"
                          ) {
                            setUpdating(true);
                            fields?.forEach((item, _i) => {
                              update(_i, {
                                ...item,
                                pricing_apply: event?.target?.value,
                              });
                            });
                            setUpdating(false);
                          }
                        }}
                        disabled={updating}
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                        }}
                        size="small"
                        label="Đơn giá bán *"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  );
                }}
              />
            </Box>
          );
        })}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 2,
        }}
      >
        <Button
          size="small"
          variant="outlined"
          startIcon={<AddOutlined fontSize="small" />}
          onClick={() => appendTierd()}
        >
          Thêm mốc
        </Button>
      </Box>
    </Box>
  );
};

export default ProductConfigoptionPricingSteps;
