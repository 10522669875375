import withPermission from '@/hocs/withPermission'
import { AddOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const CreateScope = () => {
    return (
        <Link to="/scopes/new" replace>
            <Button size='small' variant='contained' startIcon={<AddOutlined fontSize='small' />}>
                Tạo quyền truy cập
            </Button>
        </Link>
    )
}

export default withPermission(CreateScope, { feature: 'scope', action: 'create', type: 'Button' })