import { Box, Typography } from '@mui/material'
import React from 'react'

const CellBreakdown = ({
    data = []
}) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: "column", gap: 0.5 }}>
            {data?.map((item) => {
                return (
                    <Typography sx={{ fontSize: 14, m: 'auto 0' }}>
                        {item}
                    </Typography>
                )
            })}
        </Box>
    )
}

export default CellBreakdown