import useDataGridParams from "@/hooks/useDataGridParams";
import DataGridCustomize from "@components/DataGrid";
import { usePreviewFileContext } from "@contexts/preview-file";
import React, { useEffect, useMemo, useState } from "react";
import {
  formatFileSize,
  returnIconsFileName,
  returnNameAndIcon,
} from "../../shared";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { Box, IconButton, Popper } from "@mui/material";
import PopperAction from "@components/PopperAction";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CreateFolder from "../Dialog/CreateFolder";
import { useMutation } from "react-query";
import DriveApi from "@/apis/beta/Drives/DriveApi";
import { useConfirmContext } from "@contexts/confirm";
import Info from "../Dialog/Info";
import ChooseNewPath from "../Dialog/ChooseNewPath";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";

const FileListDataGrid = ({ data, isFetching, prefix, refetch }) => {
  const initialColumns = [
    { field: "id", headerName: "ID", width: 150 },
    {
      field: "name",
      headerName: "File Name",
      width: 700,
      renderCell: ({ row }) => {
        return <Box width="100%">{returnIconsFileName(row?.key)}</Box>;
      },
    },
    { field: "size", headerName: "File Size", width: 150 },
    { field: "createdAt", headerName: "Last Modified", width: 150 },
    {
      field: "action",
      headerName: "Actions",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setPopper({
                anchorEl: e?.currentTarget,
                data: { ...row, preview: () => handlePreviewFile(row) },
              });
            }}
          >
            <MoreVertIcon />
          </IconButton>
        );
      },
    },
  ];

  const previewFile = usePreviewFileContext();
  const { columns, setColumns } = useDataGridParams(initialColumns);
  const [searchParams, setSearchParams] = useSearchParams();

  const [dialogState, setDialogState] = useState({
    info: false,
    rename: false,
    chooseNewPath: false,
  });

  const [popper, setPopper] = useState({
    anchorEl: null,
    data: null,
  });

  const { showConfirmation } = useConfirmContext();

  const { mutateAsync: deleteFile, isLoading: deleteFileLoading } = useMutation(
    DriveApi.Delete
  );

  const {
    mutateAsync: renameFile,
    notify,
  } = useMutationWithNotification(DriveApi.RenameFile);

  const actions = [
    {
      name: "move",
      icon: "DriveFileMoveIcon",
      label: "Di Chuyển",
      handleClick: () => toggleDialog("chooseNewPath"),
    },
    {
      name: "rename",
      icon: "DriveFileRenameOutlineIcon",
      label: "Đổi Tên",
      handleClick: () => {
        toggleDialog("rename");
      },
    },
    {
      name: "info",
      icon: "InfoIcon",
      label: "Chi tiết",
      handleClick: () => toggleDialog("info"),
    },
    {
      name: "delete",
      icon: "Delete",
      label: "Xoá File",
      handleClick: (data) => {
        const key = data?.key.replace("pub/drives/", "");
        showConfirmation({
          title: "Bạn có chắc muốn xoá file này",
          open: true,
          description: "Thao tác này không thể khôi phục file, xin cẩn thận",
          loading: deleteFileLoading,
          handler: async () => {
            await deleteFile(key);
            refetch();
          },
        });
      },
    },
  ];

  const toggleDialog = (dialogName) => {
    setDialogState((prevState) => ({
      ...prevState,
      [dialogName]: !prevState[dialogName],
    }));
  };

  const handleMoveFile = async (new_path) => {
    const { name } = returnNameAndIcon(popper?.data?.key);
    const old_prefix = popper?.data?.key?.replace("pub/drives/", "");
    const new_prefix = `${new_path}${name}`.substring(1);

    if (old_prefix === new_prefix)
      return notify("error", "Đường dẫn mới và cũ bị chùng nhau");

    notify("warning", "Đang thực hiện di chuyển file vui lòng chờ");

    const response = await renameFile({ old_prefix, new_prefix });

    if (!response?.errors) {
      notify("success", "Di chuyển file thành công");
      setSearchParams((prev) => ({ ...prev, path: new_path }));
    }
  };

  const handlePreviewFile = (row) => {
    const formatPath = (path) => {
      return path?.replace("pub/drives/", "");
    };
    previewFile?.show(
      formatPath(row?.key),
      3600,
      fileList?.map((item) => formatPath(item?.key))
    );
  };

  const fileList = useMemo(() => {
    return (
      data?.data?.objects?.contents
        ?.filter((item) => item?.size)
        ?.map((item, index) => ({
          size: formatFileSize(item?.size),
          createdAt: moment(item?.last_modified).format("DD/MM/YYYY"),
          id: index + 1,
          key: item?.key,
        })) || []
    );
  }, [data?.data?.objects?.contents]);

  useEffect(() => {
    const path = searchParams.get("path")?.substring(1);
    const fileName = searchParams.get("fileName");

    if (fileName && path) {
      previewFile?.show(path + fileName);
    }
  }, [previewFile, searchParams]);

  return (
    <>
      {popper?.anchorEl !== undefined && (
        <Popper
          sx={{ zIndex: 100 }}
          anchorEl={popper?.anchorEl}
          open={Boolean(popper.anchorEl)}
        >
          <PopperAction
            {...popper}
            actions={actions}
            handleClose={() =>
              setPopper((prev) => ({ ...prev, anchorEl: undefined }))
            }
          />
        </Popper>
      )}

      {dialogState.chooseNewPath && (
        <ChooseNewPath
          open={dialogState.chooseNewPath}
          handleClose={() => toggleDialog("chooseNewPath")}
          onChange={handleMoveFile}
          title="Chọn nơi di chuyển"
        />
      )}

      {dialogState.rename && (
        <CreateFolder
          prefix={prefix}
          type="file"
          open={dialogState.rename}
          handleClose={() => toggleDialog("rename")}
          folderName={
            popper?.data?.key?.split("/")?.[
            popper?.data?.key?.split("/")?.length - 1
            ]
          }
          refetch={refetch}
        />
      )}

      {dialogState.info && (
        <Info
          open={dialogState.info}
          handleClose={() => toggleDialog("info")}
          data={popper.data}
        />
      )}

      <DataGridCustomize
        onRowClick={({ row }) => handlePreviewFile(row)}
        rows={fileList}
        rowCount={data?.data?.objects?.contents?.length || 0}
        columns={columns}
        loading={isFetching}
        getRowKey={(row) => row?.key}
        componentsProps={{
          toolbar: {
            store: "drives.list.hiddenColumns",
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: "drives",
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            onPageChange: () => { },
            onPageSizeChange: () => { },
          },
        }}
        sx={{ mt: 2 }}
      />
    </>
  );
};

export default FileListDataGrid;
