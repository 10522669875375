import { requestWithToken } from "@/functions";
import { API_V3_URL } from "@/libs/constants";

class InvoiceItemApi {

    Create = async ({
        invoiceId = -1,
        data
    }) => {
        return await requestWithToken(`${API_V3_URL}/invoices/${invoiceId}/invoice_items`,
            "POST", {
            invoice_item: data
        }, {}, {})
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                return error.response;
            });
    }

    Update = async ({
        invoiceId = -1,
        invoiceItemId = -1,
        data
    }) => {
        return await requestWithToken(`${API_V3_URL}/invoices/${invoiceId}/invoice_items/${invoiceItemId}`,
            "PUT", {
            invoice_item: data
        }, {}, {})
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                return error.response;
            });
    }

    Cancelled = async ({
        invoiceId = -1,
        invoiceItemId
    }) => {
        return await requestWithToken(`${API_V3_URL}/invoices/${invoiceId}/invoice_items/${invoiceItemId}`,
            "DELETE", {}, {}, {})
            .then(({ data }) => {
                return data
            })
            .catch((error) => {
                return error.response;
            });
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new InvoiceItemApi();