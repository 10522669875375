import { format_numeral_price } from '@/functions'
import { Box, Typography } from '@mui/material'
import React from 'react'

const ServiceAddons = ({ product_addons = [] }) => {
    return (
        <Box sx={{ mt: 2 }}>
            <Typography sx={{ fontSize: 14, color: "#777680", mb: 0.5 }}>
                Sản Phẩm/ Dịch Vụ Addons
            </Typography>
            <Box sx={{ p: 2, bgcolor: "#7776801F", width: '100%' }}>
                <Box sx={{
                    display: { xs: 'none', md: 'flex' },
                    mb: 0.5,
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: 1.5,
                }}>
                    <Typography
                        sx={{
                            minWidth: 282,
                            fontSize: 14,
                            color: "#777680"
                        }}>
                        Tên Sản Phẩm/ Dịch Vụ
                    </Typography>
                    <Typography
                        sx={{
                            minWidth: 150,
                            fontSize: 14,
                            color: "#777680"
                        }}>
                        Số Lượng Mua
                    </Typography>
                    <Typography
                        sx={{
                            minWidth: 150,
                            fontSize: 14,
                            color: "#777680"
                        }}>
                        Đơn Giá Niêm Yết
                    </Typography>
                    <Typography
                        sx={{
                            minWidth: 150,
                            fontSize: 14,
                            color: "#777680"
                        }}>
                        Đơn Giá Bán
                    </Typography>
                </Box>
                {product_addons?.map((item, index) => {
                    return (
                        <Box sx={{
                            display: 'flex',
                            mb: 0.5,
                            flexDirection: { xs: 'column', md: 'row' },
                            gap: 1.5
                        }} key={index}>
                            <Typography
                                component="div"
                                sx={{
                                    minWidth: 282,
                                    fontSize: 14,
                                    textTransform: 'uppercase'
                                }}>
                                {item.product_name}
                            </Typography>
                            <Typography
                                sx={{
                                    minWidth: 150,
                                    fontSize: 14
                                }}>
                                x{format_numeral_price(item?.qty)}
                            </Typography>
                            <Typography
                                component="div"
                                sx={{
                                    minWidth: 150,
                                    fontSize: 14
                                }}>
                                <Typography sx={{
                                    display: 'inline-flex',
                                    fontSize: 14,
                                    textDecoration: "underline"
                                }}>
                                    đ
                                </Typography>
                                {format_numeral_price(item?.pricing)}
                            </Typography>
                            <Typography
                                component="div"
                                sx={{
                                    minWidth: 150,
                                    fontSize: 14
                                }}>
                                <Typography sx={{
                                    display: 'inline-flex',
                                    fontSize: 14,
                                    textDecoration: "underline"
                                }}>
                                    đ
                                </Typography>
                                {format_numeral_price(item?.pricing_apply)}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default ServiceAddons