import CompanyApi from '@/apis/beta/Company/CompanyApi'
import { useNotificationContext } from "@/contexts/notification"
import withSuspense from '@/hocs/withSuspense'
import { INDUSTRIES } from '@/libs/constants'
import { companyValidator } from '@/libs/validators'
import FormCustomized from '@components/shared/FormCustomized'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { vestResolver } from '@hookform/resolvers/vest'
import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

const COMPANY_FIELDS = [
    {
        name: 'company_tax', component: 'TextField', cols: { xs: 12, md: 4, lg: 4 },
        params: { label: 'Mã Số Thuế Công Ty *', placeholder: 'Nhập mã số thuế công ty...' }
    }, {
        name: 'company_name', component: 'TextField', cols: { xs: 12, md: 8, xl: 8 },
        params: { label: 'Tên Công Ty *', placeholder: 'Nhập tên công ty...' }
    }, {
        name: 'company_established_date', component: 'DateField', cols: { xs: 12, md: 4, xl: 4 },
        params: { label: 'Ngày Thành Lập Công Ty', placeholder: 'Nhập tên công ty...' }
    },

    {
        name: 'company_industry', component: 'SingleSelect', cols: { xs: 12, md: 4, xl: 4 },
        params: {
            input: { label: 'Lĩnh Vực *', placeholder: 'Chọn lĩnh vực của công ty...', }, options: INDUSTRIES
        }
    },
    {
        name: 'company_website', component: 'TextField', cols: { xs: 12, md: 4, xl: 4 },
        params: { label: 'Website Công Ty', placeholder: 'Website công ty...' }
    },
    {
        name: 'company_address', component: 'TextField', cols: { xs: 12, md: 12, xl: 12 },
        params: { label: 'Địa Chỉ *', placeholder: 'Nhập tên công ty...' }
    },
]

const CompanyForm = ({
    type,
    data,
    callback,
    handleClose
}) => {
    const { setNotification } = useNotificationContext();
    const [submit, setSubmit] = React.useState(false)

    const methods = useForm({
        resolver: vestResolver((data) => companyValidator({
            ...data,
            type: type,
            company_name_old: data?.company_name,
            company_tax_old: data?.company_tax
        })),
        defaultValues: {
            company_name: data?.company_name || "",
            company_tax: data?.company_tax || "",
            company_website: data?.company_website || "",
            company_established_date: moment(data?.company_established_date),
            company_industry: data?.company_industry,
            company_address: data?.company_address || "",
        }
    })

    const { handleSubmit } = methods
    const onSubmit = async (values) => {
        setSubmit(true)
        const response = type === 'create' ?
            await CompanyApi.Create({ ...values, customer_id: [data?.customerId] })
            : await CompanyApi.Update(values, data?.company_id)
        setSubmit(false)
        if (!response || (response.status !== 200 && response.status !== 201)) {
            return setNotification({ open: true, message: response?.errors[0]?.message, severity: "error" })
        }
        setNotification({
            open: true,
            message: `Cập nhật thông tin công ty khách hàng thành công.`,
            severity: "success"
        })
        try {
            localStorage.removeItem(`company.data.create`)
            callback()
        } catch (error) { alert("Company Create Exception: " + error?.message) }
        return handleClose()
    }
    return (
        <FormProvider {...methods}>
            <Paper sx={{ width: '100%', height: '100%', minWidth: 360, p: 3 }} component="form" onSubmit={handleSubmit(onSubmit)}>
                <Typography sx={{ mb: 2 }}>
                    {type === "update" ? "Chỉnh Sửa Thông Tin" : "Thêm Mới"} Công Ty
                </Typography>
                <FormCustomized attributes={COMPANY_FIELDS} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                    <Button
                        size='small'
                        variant='text'
                        disabled={submit}
                        onClick={handleClose}
                        sx={{ height: 36 }}>Đóng</Button>
                    <Button
                        size='small'
                        variant='outlined'
                        type='submit'
                        disabled={submit}
                        startIcon={submit && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                        sx={{ height: 36, ml: 2 }}>
                        {type === "update" ?
                            "Cập Nhật" : "Thêm Mới"}</Button>
                </Box>
            </Paper>
        </FormProvider>
    )
}

export default withSuspense(CompanyForm, MuiSkeleton["PaperInformation"])