import CustomerServiceCreditApi from "@/apis/beta/Customer/CustomerServiceCreditApi";
import { format_numeral_price } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import ItemPaperStatistical from "@components/shared/ItemPaperStatistical";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Grid } from "@mui/material";
import React from "react";
import CreditCommitment from "./private/CreditCommitment";

const Statistic = ({ customerId }) => {
  const { data } = CustomerServiceCreditApi.Credit({ customerId: customerId });

  const formatToArray = () => {
    return [
      {
        value: format_numeral_price(data?.total),
        label: "Tổng Tiền",
        endText: true,
      },
      {
        value: format_numeral_price(data?.used),
        label: "Đã Sử Dụng",
        endText: true,
      },
      {
        value: format_numeral_price(data?.available),
        label: "Khả Dụng",
        endText: true,
      },
    ];
  };
  return (
    <Grid container spacing={2} sx={{ mb: 2 }} id="credit">
      {formatToArray().map((item, index) => (
        <Grid item xs={12} md={3} key={index}>
          <ItemPaperStatistical {...item} />
        </Grid>
      ))}
      <Grid item xs={12} md={3}>
        <CreditCommitment customerId={customerId} />
      </Grid>
    </Grid>
  );
};

export default withSuspense(
  withPermission(Statistic, {
    feature: "customer",
    action: "customer-credit-statistic",
    type: "Block",
  }),
  MuiSkeleton["StatisticalOne"]
);
