import EmployeeApi from "@/apis/beta/Employee/EmployeeApi"
import UserApi from "@/apis/UserApi"
import { format_date_short } from "@/functions"
import { EMPLOYEE_TYPES } from "@/libs/constants"
import { AccountCircleOutlined } from '@mui/icons-material'
import { Box, Grid, IconButton, Paper, Typography } from '@mui/material'
import React from 'react'
import withSuspense from "@/hocs/withSuspense"
import MuiSkeleton from "@components/shared/MuiSkeleton"

const Information = () => {
    const [current] = UserApi.Current()
    const { data: employee } = EmployeeApi.Detail(current?.employee_id)

    return (
        <Paper sx={{ p: "30px", mb: 2 }}>
            <Box sx={{ display: 'flex', mb: 2 }}>
                <IconButton className="information" sx={{ m: 'auto 8px auto 0' }}>
                    <AccountCircleOutlined sx={{ color: '#FFF' }} />
                </IconButton>
                <Typography sx={{
                    m: 'auto 0',
                    fontSize: 24,
                    color: '#00000f'
                }}>#{employee?.employee_id}</Typography>
            </Box>
            <Grid container spacing={{ xs: 1, md: 2 }}>
                <Grid item xs={12} md={3}>
                    <Typography sx={{ color: '#777680' }}>
                        Họ & Tên Nhân Viên:
                    </Typography>
                    <Typography sx={{ color: '#00000f' }}>
                        {employee?.employee_name}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'row', md: 'column' },
                        gap: { xs: 1.5, md: 0 }
                    }}>
                        <Typography sx={{ color: '#777680' }}>
                            Mã Nhân Viên:
                        </Typography>
                        <Typography sx={{ color: '#00000f' }}>
                            {employee?.employee_code}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'row', md: 'column' },
                        gap: { xs: 1.5, md: 0 }
                    }}>
                        <Typography sx={{ color: '#777680' }}>
                            ID:
                        </Typography>
                        <Typography>
                            {employee?.employee_id}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'row', md: 'column' },
                        gap: { xs: 1.5, md: 0 }
                    }}>
                        <Typography sx={{ color: '#777680' }}>
                            Ngày Sinh:
                        </Typography>
                        <Typography>
                            {format_date_short(employee?.employee_birthday)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{ color: '#777680' }}>
                        Số Điện Thoại:
                    </Typography>
                    <Typography sx={{ color: "#00000F" }}>
                        {employee?.employee_phone_number}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{ color: '#777680' }}>
                        Email:
                    </Typography>
                    <Typography>
                        {employee?.employee_email || "Chưa có thông tin"}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{ color: '#777680' }}>
                        Vị Trí:
                    </Typography>
                    <Typography>
                        {employee?.employee_position}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{ color: '#777680' }}>
                        Hình Thức Nhân Viên:
                    </Typography>
                    <Typography>
                        {EMPLOYEE_TYPES?.find(t => t?.value === employee?.employee_type)?.label || ''}
                    </Typography>
                </Grid>
                {/* <Grid item xs={12} md={3}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'row', md: 'column' },
                        gap: { xs: 1.5, md: 0 }
                    }}>
                        <Typography sx={{ color: '#777680' }}>
                            Phân Quyền:
                        </Typography>
                        <Typography sx={{ color: '#00000f', flexWrap: 'wrap' }}>
                            {employee?.employee_role}
                        </Typography>
                    </Box>
                </Grid> */}
                <Grid item xs={12} md={3}>
                    <Typography sx={{ color: '#777680' }}>
                        Ngày Bắt Đầu Làm Việc:
                    </Typography>
                    <Typography>
                        {!!employee?.start_worked_date ? format_date_short(employee?.start_worked_date) : "Chưa có thông tin"}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'row', md: 'column' },
                        gap: { xs: 1.5, md: 0 }
                    }}>
                        <Typography sx={{ color: '#777680' }}>
                            Số ngày phép còn lại:
                        </Typography>
                        <Typography>
                            {employee?.employee_day_off_left}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

        </Paper>
    )
}

export default withSuspense(Information, MuiSkeleton["Paper"])