import DayoffApi from '@/apis/beta/Employee/DayoffApi'
import { buildQueryFilterCondition } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import KeyboardArrowDownOutlined from '@mui/icons-material/KeyboardArrowDownOutlined'
import { Box, Collapse, Divider, IconButton, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'

const NextDayoff = () => {

    const filterableRecentDayoff = React.useCallback(() => {
        const fieldQueries = [
            {
                name: 'day_off_items.day_off_item_date',
                operator: "$eq",
                value: dayjs().add(1, 'days').format("DD/MM/YYYY")
            }, {
                name: 'day_offs.day_off_status',
                operator: "$ne",
                value: 1
            },
        ]
        return buildQueryFilterCondition("$and", 0, fieldQueries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { data: { day_offs: data } } = DayoffApi.List({
        query: filterableRecentDayoff()
    })

    const [show, setShow] = React.useState(true)

    return (
        <React.Fragment>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 0.5,
                mt: 2
            }}>
                <Typography sx={{
                    fontSize: 14,
                    fontWeight: 'medium',
                }}>Ngày Mai</Typography>
                <IconButton size='small'
                    sx={{ height: 24, width: 24 }}
                    onClick={() => setShow(prev => !prev)}>
                    <KeyboardArrowDownOutlined fontSize='small'
                        sx={{
                            transform: show ? "rotate(-180deg)" : "none",
                            transition: 'transform 0.5s ease-in-out'
                        }} />
                </IconButton>
            </Box>
            <Collapse in={show}>
                {(!data || data?.length === 0) ?
                    <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'center' } }}>
                        <Typography sx={{
                            m: 'auto',
                            color: "#777680",
                            fontSize: 14
                        }}>
                            Không có phiếu nghỉ.
                        </Typography>
                    </Box>
                    : <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 0.5
                    }}>
                        {data?.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {index !== 0 && <Divider sx={{ m: '4px 0' }} />}
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography sx={{ fontSize: 14 }}>
                                            {item?.employee_name}
                                        </Typography>
                                        <Typography sx={{ textTransform: "uppercase", color: "#777680", fontSize: 14 }}>
                                            {item?.employee_department_name}
                                        </Typography>
                                    </Box>
                                </React.Fragment>
                            )
                        })}
                    </Box>
                }
            </Collapse>
        </React.Fragment>
    )
}

export default withSuspense(NextDayoff, MuiSkeleton["MultiDayoffLine"])