import React from 'react'
import PeopleOutlineOutlined from "@mui/icons-material/PeopleOutlineOutlined";
import CancelOutlined from "@mui/icons-material/CancelOutlined"
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AdfScannerOutlinedIcon from '@mui/icons-material/AdfScannerOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import PersonalOutlinedIcon from "@mui/icons-material/PersonOutlined"
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined"
import ChatIcon from '@mui/icons-material/Chat';

const ICONS = {
    DefaultIcon: DoNotDisturbAltOutlinedIcon,
    CancelIcon: CancelOutlined,
    AssigneeIcon: PeopleOutlineOutlined,
    ContractIcon: AssignmentTurnedInOutlinedIcon,
    ScanIcon: AdfScannerOutlinedIcon,
    CalendarIcon: CalendarMonthOutlinedIcon,
    QrCodeIcon: QrCodeOutlinedIcon,
    OwnerIcon: PersonalOutlinedIcon,
    AlarmIcon: AccessTimeOutlinedIcon,
    ChatIcon: ChatIcon,
}

const PrivateIconWithFormat = ({ icon }, props) => {

    return React.createElement(ICONS[icon] || ICONS["DefaultIcon"], {
        sx: { fontSize: 18, m: 'auto 8px auto 0', color: "#9e9e9e", ...props },
    })

}

export default PrivateIconWithFormat