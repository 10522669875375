import { Box, Typography } from '@mui/material'
import React from 'react'
import DepartmentShared from '../shared'
import withPermission from '@/hocs/withPermission'
import ListDepts from './ListDepts'

const DepartmentList = () => {
    return (
        <>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
                flexDirection: { xs: 'column', md: 'row' },
                mb: 1.5
            }}>
                <Typography sx={{ fontSize: { xs: 18, md: 24 }, fontWeight: 'medium' }}>
                    Danh Sách Phòng Ban
                </Typography>
                <DepartmentShared.Buttons.CreateLink />
            </Box>
            <ListDepts />
        </>
    )
}

export default withPermission(DepartmentList, { feature: 'department', action: 'index' })