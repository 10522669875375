import DataGrid from "@/components/DataGrid";
import { Box } from "@mui/material";
import clsx from "clsx";
import { Fragment, useMemo } from "react";
import SwiftStorageServiceLibs from "../functions";
import ServiceActivities from "../../../shared/Service/ServiceActivities";
import ServiceApi from "@/apis/beta/Service/ServiceApi";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import ServiceManager from "../../shared/ServiceManager";
// import ServiceManager from "./ServiceManager";

const SwiftStorageService = ({ service, addons }) => {

    const { data: servicePrice } = ServiceApi.PricingAndConfigoptions({ id: service?.service_id })

    const { data: configs } = ServiceApi.PackageConfigs({ serviceId: service?.service_id })

    const initialColumns = useMemo(() => {
        return [...SwiftStorageServiceLibs.rows({
            service: {
                ...service,
                commitment: servicePrice?.service_pricing_configure?.commitment
            }, addons, histories: configs
        })]
    }, [service, servicePrice, addons, configs]);

    return (
        <Fragment>
            <ServiceActivities activities={SwiftStorageServiceLibs.activities} service={service} />
            <Box sx={{ mt: "10px" }}>
                <DataGrid
                    initialState={{
                        pinnedColumns: { right: ["actions"] },
                    }}
                    rows={
                        initialColumns?.map((service, index) => ({
                            ...service,
                            id: index + 1,
                        })) || []
                    }
                    columns={SwiftStorageServiceLibs?.columns}
                    components={{
                        Toolbar: "disabled",
                        Pagination: "disabled",
                    }}
                    disableSelectionOnClick
                    disableColumnFilter
                    getRowClassName={({ row }) => {
                        return clsx(`grid-dc-vnetwork`, row?.classNames || "");
                    }}
                />
            </Box>
            <ServiceManager service={service} />
        </Fragment>

    );
};
export default withSuspense(SwiftStorageService, MuiSkeleton["DataGrid"]);