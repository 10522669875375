import CustomerApi from '@/apis/beta/Customer/CustomerApi'
import NotificationInformation from "@/components/NotificationMessage/Information"
import { useNotificationContext } from "@/contexts/notification"
import withPermission from '@/hocs/withPermission'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { Box, FormControl, Paper, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import Buttons from "./Buttons"

const CustomerMailer = () => {
    const { customer_id } = useParams()
    const { setNotification } = useNotificationContext()

    const [submit, setSubmit] = React.useState(null)

    const { data, refetch: callback } = CustomerApi.Mailer({
        customerId: customer_id
    })

    const [defaultValues] = React.useState({
        mailer_receiver: data?.mailer_receiver?.join(","),
        mailer_cc: data?.mailer_cc?.join(","),
        mailer_bcc: data?.mailer_bcc?.join(","),
    })

    const { control, handleSubmit, reset } = useForm({
        defaultValues: defaultValues
    })

    const onSubmit = async (values) => {
        setSubmit("submit")
        const response = await CustomerApi.UpdateMailer({
            customerId: customer_id,
            data: values
        })
        setSubmit(null)
        if (!response || response?.errors) {
            return setNotification({
                open: true,
                message: response?.errors?.[0]?.message || "Failed",
                severity: "error"
            })
        }
        setNotification({
            open: true,
            message: "Cập nhật mailer khách hàng thành công.",
            severity: "success"
        })
        try {
            callback()
        } catch (error) {
            console.log(error?.message)
        }
    }

    const onreset = async () => {
        setSubmit("reset")
        reset({ ...defaultValues })
        setNotification({
            open: true,
            message: "Resetted.",
            severity: 'success'
        })
        return setSubmit(null)
    }

    return (
        <Paper component="form" sx={{
            maxWidth: { xs: '100%', md: '80%' },
            p: 3
        }} onSubmit={handleSubmit(onSubmit)}>
            <NotificationInformation title="Mailer" message="Đây là thông tin email gửi hoá đơn dịch vụ cho khách hàng và các thông tin liên quan khác." />
            <Box sx={{
                display: 'flex',
                flexDirection: {
                    xs: 'column',
                    md: 'row'
                },
                gap: 1, mt: 2
            }}>
                <Box sx={{
                    display: 'flex',
                    width: { xs: '100%', md: '35%' }
                }}>
                    <Typography sx={{
                        fontSize: 14,
                        color: "#777680",
                        m: 'auto 0'
                    }}>
                        Người nhận:
                    </Typography>
                </Box>
                <Controller
                    name="mailer_receiver"
                    control={control}
                    rules={{
                        required: "Vui lòng nhập ít nhất một email người nhận.",
                    }}
                    render={({
                        field: { value, onChange },
                        fieldState: { error }
                    }) => {
                        return (
                            <FormControl fullWidth>
                                <TextField
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error?.message}
                                    placeholder="Email người nhận..."
                                    size='small' />
                            </FormControl>
                        )
                    }} />
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: {
                    xs: 'column',
                    md: 'row'
                },
                gap: 1,
                mt: 1.5
            }}>
                <Box sx={{
                    display: 'flex',
                    width: { xs: '100%', md: '35%' }
                }}>
                    <Typography sx={{
                        fontSize: 14,
                        color: "#777680",
                        m: 'auto 0'
                    }}>
                        CC:
                    </Typography>
                </Box>
                <Controller
                    name="mailer_cc"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FormControl fullWidth>
                                <TextField
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Email cc..."
                                    size='small' />
                            </FormControl>
                        )
                    }} />
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: {
                    xs: 'column',
                    md: 'row'
                },
                gap: 1,
                mt: 1.5
            }}>
                <Box sx={{
                    display: 'flex',
                    width: { xs: '100%', md: '35%' }
                }}>
                    <Typography sx={{
                        fontSize: 14,
                        color: "#777680",
                        m: 'auto 0'
                    }}>
                        BCC:
                    </Typography>
                </Box>
                <Controller
                    name="mailer_bcc"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FormControl fullWidth>
                                <TextField
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Email bcc..."
                                    size='small' />
                            </FormControl>
                        )
                    }} />
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 1,
                mt: 2
            }}>
                <Buttons.Cancel submit={submit} onClick={onreset} />
                <Buttons.Update submit={submit} />
            </Box>

        </Paper>
    )
}

export default withSuspense(
    withPermission(CustomerMailer, {
        feature: "customer",
        action: "customer-mailer-index"
    })
    , MuiSkeleton["Paper"]) 