import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";
class AssigneesApi {
    List = ({
        assigneeComponent = null,
        page = 1,
        pageSize = 10
    }) => {
        return useQueryWithCustomized(
            ["assignees.list", assigneeComponent, page, pageSize],
            async () => {
                return await requestWithToken(
                    `${API_V3_URL}/assignees/list?assignee_compoment=${assigneeComponent}&page=${page}&page_size=${pageSize}`
                )
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data;
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
                enabled: !!localStorage.getItem("SA"),
            }
        );
    }

    Create = async (assigneeData) => {
        return await requestWithToken(`${API_V3_URL}/assignees`, "POST", {
            assignee: assigneeData
        })
            .then(({ data }) => {
                if (data.error) return data;
                return data;
            })
            .catch((error) => {
                return error?.response;
            });
    }

    Update = async (id, assigneeData) => {
        return await requestWithToken(`${API_V3_URL}/assignees/${id}`, "PUT", {
            assignee: assigneeData
        })
            .then(({ data }) => {
                if (data.error) return data;
                return data;
            })
            .catch((error) => {
                return error?.response;
            });
    }

    Delete = async (id) => {
        return await requestWithToken(`${API_V3_URL}/assignees/${id}`, "DELETE")
            .then(({ data }) => {
                if (data.error) return data;
                return data;
            })
            .catch((error) => {
                return error?.response;
            });
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AssigneesApi();
