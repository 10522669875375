import { format_date_short, format_numeral_price } from "@/functions";
import { INDUSTRIES } from "@/libs/constants";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";

export const getJourneyUpdates = ({ journeys = [], startdate, enddate }) => {
  if (journeys.length === 0) return [];
  let updates = [];

  journeys?.forEach((journey) => {
    updates.push(
      ...journey?.journey_updates?.filter(
        (journey_update) =>
          moment(startdate) <=
            moment(journey_update?.journey_update_transaction_at) &&
          moment(journey_update?.journey_update_transaction_at) <=
            moment(enddate)
      )
    );
  });
  return (
    updates?.map(
      (update) =>
        `${update?.journey_update_step} : ${update?.journey_update_note}`
    ) || []
  );
};

export const renderHeader = ({ startdate, enddate }) => {
  return `${format_date_short(startdate)} - ${format_date_short(enddate)}`;
};

export const buildCustomerQualifiedProspectCols = ({
  cols = 4,
  today = Date.now,
}) => {
  return Array.from({ length: cols }).map((__, v) => {
    let startdate = moment(today)
      .startOf("week")
      .add(-13 + v * 7, "d");
    let enddate = moment()
      .endOf("week")
      .add(-13 + v * 7, "d");
    let metadata = {
      startdate: startdate,
      enddate: enddate,
    };

    return {
      field: `customer_journey_week_${v + 1}`,
      headerName: renderHeader(metadata),
      width: 250,
      valueGetter: ({ row }) =>
        getJourneyUpdates({
          journeys: row?.journeys,
          ...metadata,
        }),
      renderCell: ({ value }) => <JourneyUpdates data={value} />,
    };
  });
};

const JourneyUpdates = ({ data }) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      {data?.map((item, index) => {
        return (
          <Typography sx={{ fontSize: 14, m: "auto 0" }} key={index}>
            {item}
          </Typography>
        );
      })}
    </Box>
  );
};

export const QualifiedProspectColumns = [
  {
    field: "customers.customer_id",
    headerName: "ID",
    width: 60,
    valueGetter: ({ row }) => row?.customer_id,
    operators: { type: "number" },
    renderCell: ({ value }) => (
      <Link to={`/customers/${value}`} replace>
        <Typography className="react-link-table">{value}</Typography>
      </Link>
    ),
    ordered: 1,
  },
  {
    field: "customer_name",
    headerName: "Tên Khách Hàng",
    width: 250,
    valueGetter: ({ row }) => {
      if (row?.customer_company_name && !!row?.customer_company_name)
        return row?.customer_company_name;
      return row?.customer_name;
    },
    operators: { type: "string" },
    ordered: 2,
  },
  {
    field: "sale_tracking_id",
    headerName: "SALE Phụ Trách",
    minWidth: 200,
    valueGetter: ({ row }) => {
      return (
        row?.journeys?.map((journey) => {
          return journey?.sales?.map((e) => e?.employee_name)?.join(", ");
        }) || []
      );
    },
    renderCell: ({ value }) => <JourneyRow data={value} />,
  },
  {
    field: "customer_service_informations.customer_industry",
    headerName: "Lĩnh Vực",
    width: 180,
    valueGetter: ({ row }) =>
      INDUSTRIES.find((industry) => industry?.value === row?.customer_industry)
        ?.label || "",
  },
  {
    field: "customer_email",
    headerName: "Email",
    width: 200,
  },
  {
    field: "customer_created_at",
    headerName: "Ngày Tạo",
    valueFormatter: ({ value }) => (!value ? "" : format_date_short(value)),
    width: 120,
  },
  {
    field: "service_interestings",
    headerName: "Sản phẩm/ dịch vụ quan tâm",
    minWidth: 200,
    valueGetter: ({ row }) => {
      return (
        row?.journeys?.map((journey) => {
          return journey?.explaination?.service_interestings?.join(", ");
        }) || []
      );
    },
    renderCell: ({ value }) => <JourneyRow data={value} />,
  },
  {
    field: "provider_explaination",
    headerName: "KH đang dùng nhà cung cấp nào?",
    minWidth: 250,
    hide: true,
    valueGetter: ({ row }) => {
      return (
        row?.journeys?.map((journey) => {
          return journey?.explaination?.provider_explaination;
        }) || []
      );
    },
    renderCell: ({ value }) => <JourneyRow data={value} />,
  },
  {
    field: "budget_explaination",
    headerName: "Budget/ Month",
    minWidth: 150,
    valueGetter: ({ row }) => {
      return (
        row?.journeys?.map((journey) => {
          if (!!journey?.explaination?.budget_explaination)
            return format_numeral_price(
              journey?.explaination?.budget_explaination
            );
          return ``;
        }) || []
      );
    },
    renderCell: ({ value }) => <JourneyRow data={value} />,
  },
  {
    field: "problem_explaination",
    headerName: "Vấn đề, nỗi đau",
    hide: true,
    valueGetter: ({ row }) => {
      return (
        row?.journeys?.map((journey) => {
          if (!!journey?.explaination?.problem_explaination)
            return format_numeral_price(
              journey?.explaination?.problem_explaination
            );
          return ``;
        }) || []
      );
    },
    width: 180,
    renderCell: ({ value }) => <JourneyRow data={value} />,
  },
  {
    field: "expected_explaination",
    headerName: "Mong đợi/ mong muốn của khách hàng",
    hide: true,
    valueGetter: ({ row }) => {
      return (
        row?.journeys?.map((journey) => {
          if (!!journey?.explaination?.expected_explaination)
            return format_numeral_price(
              journey?.explaination?.expected_explaination
            );
          return ``;
        }) || []
      );
    },
    width: 200,
    renderCell: ({ value }) => <JourneyRow data={value} />,
  },
  {
    field: "expected_date",
    headerName: "Thời gian & dự tính bán hàng",
    width: 150,
    valueGetter: ({ row }) => {
      return (
        row?.journeys?.map((journey) => {
          if (!!journey?.explaination?.expected_explaination)
            return format_numeral_price(
              journey?.explaination?.expected_explaination
            );
          return ``;
        }) || []
      );
    },
    renderCell: ({ value }) => <JourneyRow data={value} />,
  },
];

export const JourneyRow = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {data?.map((item, index) => {
        return (
          <Typography sx={{ m: "auto 0", fontSize: 14 }} key={index}>
            {item}
          </Typography>
        );
      })}
    </Box>
  );
};
