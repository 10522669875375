import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'

const Description = ({ description = '' }) => {
    return (
        <Box sx={{
            display: 'flex',
            alignItems: "center",
            height: '100%',
            width: '100%',
            p: '4px 16px'
        }}>
            <Tooltip title={description} placement='top'>
                <Typography sx={{ fontSize: 14 }}>{description}</Typography>
            </Tooltip>
        </Box>
    )
}

export default Description