import { Checkbox, FormControl, FormControlLabel } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

const Taxable = () => {
    const { control } = useFormContext()
    return (
        <Controller
            control={control}
            name="service_tax"
            render={({ field: { value, onChange } }) =>
                <FormControl fullWidth>
                    <FormControlLabel
                        label="Áp Dụng Thuế"
                        control={
                            <Checkbox size='small' checked={value} onChange={onChange} />
                        }
                    />
                </FormControl>
            }
        />
    )
}

export default Taxable