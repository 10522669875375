import ServiceApi from "@/apis/beta/Service/ServiceApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import dayjs from "dayjs";
import React from "react";
import { useParams } from "react-router-dom";
import ServiceAddonForm from "../shared/Form";

const Create = ({ handleClose }) => {
  const { service_id } = useParams();
  const { data: service } = ServiceApi.Detail({ id: service_id });

  const defaultValues = {
    service_product_id: -1,
    service_product_name: "",
    service_amount: 0,
    service_billing_cycle: "",
    service_name: "",
    service_start_date: dayjs(),
    service_duedate: dayjs(service?.service_duedate) || dayjs(),
    service_type: "addon",
    service_status: "payment-pending",
    service_tax: true,
    service_addon_qty: 1,
    service_note: "",
    service_extra_fields: {
      round: true,
    },
    service_management_billing: {
      service_billing_startdate: dayjs(),
      service_billing_enddate: dayjs().add(1, "months").add(-1, "days"),
      service_nextbill_startdate: dayjs(),
      service_nextbill_enddate: dayjs().add(1, "months"),
    },
  };
  const onSubmit = async (data) => {
    const response = await ServiceApi.CreateAddon({
      serviceId: service?.service_id,
      addon: data,
    });
    if (!response || response?.errors) {
      return { message: "Thêm mới dịch vụ addon thất bại.", status: "error" };
    }
    return { message: "Thêm mới dịch vụ addon thành công.", status: "success" };
  };

  return (
    <ServiceAddonForm
      handleClose={handleClose}
      service={service}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      type="create"
    />
  );
};

export default withSuspense(
  withPermission(Create, {
    feature: "service",
    action: "service-addon-create",
    type: "Block",
  }),
  MuiSkeleton["LinearProgress"]
);
