import withPermission from '@/hocs/withPermission'
import { AddOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'

const ButtonCreate = ({ setAction, disabled }) => {
    return (
        <Button variant='contained'
            disabled={disabled}
            startIcon={<AddOutlined fontSize="small" />}
            size="small"
            sx={{
                height: {
                    xs: 36, md: 42
                }
            }}
            onClick={() => setAction({ open: true, name: "create-service-addon", data: {} })}
        >
            Thêm Dịch Vụ
        </Button>
    )
}

export default withPermission(ButtonCreate, { feature: "service", action: "service-addon-create", type: "button" })