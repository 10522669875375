import WorkDayApi from "@/apis/beta/Employee/WorkDayApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import HeaderTitle from "@components/Title/HeaderTitle";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import PaperLoading from "@features/Module/WarehouseNew/Shared/Loading/PaperLoading";
import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router";
import RequestWorkDays from "../Home/Dialog/RequestWorkDays";

const ApprovalMissDate = () => {
  const params = useParams();

  const { data, isLoading } = useQueryWithCustomized(
    [`get-miss-date-${params?.id}`],
    () => WorkDayApi.GetMissDate(params?.id)
  );

  const [showDialog, setShowDialog] = useState(false);

  if (isLoading) return <PaperLoading />;

  return (
    <Box>
      <HeaderTitle>
        Yêu Cầu Xét Duyệt Ngày Công Của Nhân Viên -{" "}
        {data?.data?.employee?.employee_name}
      </HeaderTitle>

      <Button
        variant="contained"
        size="small"
        sx={{ mt: 2 }}
        fullWidth
        onClick={() => setShowDialog(true)}
      >
        Xem Chi Tiết
      </Button>

      {showDialog && (
        <RequestWorkDays
          open={showDialog}
          metadata={data?.data?.miss_date?.metadata}
          employee_id={data?.data?.employee?.employee_id}
          employee_name={data?.data?.employee?.employee_name}
          handleClose={() => setShowDialog(false)}
          miss_date_id={data?.data?.miss_date?.id}
        />
      )}
    </Box>
  );
};

export default withSuspense(
  withPermission(ApprovalMissDate, {
    feature: "workdays",
    action: "get-miss-date",
  }),
  MuiSkeleton["GridInformation"]
);
