import ProductApi from '@/apis/beta/Product/ProductApi';
import { buildQueryFilterCondition, format_date_short } from '@/functions';
import { UNLIMITED } from '@/libs/constants';
import { useStateArrayContext } from '@contexts/stateArray';
import { Box, Button, Collapse, Typography } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import DataProducts from './DataProducts';
import Product from './Product';
import ServiceBilling from './ServiceBilling';

const modules = {
    NodeServer: Product
}
const GCORE_SCOPES = [
    {
        module: "NodeServer",
        queries: buildQueryFilterCondition('$and', 0, [
            {
                name: "product_groups.product_group_id",
                operator: "$eq",
                value: 22,
            },
            {
                name: "product_type",
                operator: "$eq",
                value: "node",
            },
            {
                name: "tw_products.product_hidden",
                operator: "$eq",
                value: 0,
            },
        ]),
        props: {
            label: "Cloud Service Node (k8s) *"
        }
    },
    {
        module: "NodeServer",
        queries: buildQueryFilterCondition('$and', 0, [
            {
                name: "product_groups.product_group_id",
                operator: "$eq",
                value: 22,
            },
            {
                name: "product_type",
                operator: "$ne",
                value: "node",
            },
            {
                name: "tw_products.product_hidden",
                operator: "$eq",
                value: 0,
            },
        ]),
        props: {
            label: "Cloud Service Package"
        }
    }
]

const GcoreCloudService = ({
    closer,
    saver
}) => {

    const [inserting, setInserting] = useState(false)
    const {
        state: products,
        update,
        insert,
        removeIndex,
        clear
    } = useStateArrayContext()
    const [billing, setBilling] = useState({
        service_billing_startdate: moment(),
        service_billing_enddate: moment(),
        service_nextbill_startdate: moment(),
        service_nextbill_enddate: moment(),
        service_control_startdate: moment(),
        service_control_enddate: moment(),
    })

    const onSelect = async (payload) => {
        if (!payload || !payload?.product_id) return;
        setInserting(true)
        const data = await ProductApi.GetProduct({ id: payload?.product_id })
        setInserting(false)
        const product = data?.product
        if (!!product) {
            insert({
                ...product,
                product_value: 1,
                product_price: product?.product_pricing_configures?.pricing,
                service_price_exceeds: product?.price_exceed_ranges || [{ range_start: 0, range_end: UNLIMITED, pricing: 0 }]
            })
        }
    }

    const rebuilder = (data = [], billing = {}) => {
        return data?.map((product) => {
            return {
                ...(_.pick(product, [
                    'product_id',
                    'product_name',
                    'product_group_id',
                    'product_group_name',
                    'product_template',
                    'product_price',
                    'service_price_exceeds',
                    'product_value',
                    'product_informations'
                ])),
                service_management_billing: billing
            }
        })
    }
    const rebuildServiceCluster = () => {
        let serviceBilling = {
            service_billing_startdate: format_date_short(
                billing?.service_billing_startdate?.$d ||
                billing?.service_billing_startdate
            ),
            service_billing_enddate: format_date_short(
                billing?.service_billing_enddate?.$d
            ),
            service_nextbill_startdate: format_date_short(
                billing?.service_nextbill_startdate?.$d
            ),
            service_nextbill_enddate: format_date_short(
                billing?.service_nextbill_enddate?.$d
            ),
            service_control_startdate: format_date_short(
                billing?.service_control_startdate?.$d
            ),
            service_control_enddate: format_date_short(
                billing?.service_control_enddate?.$d
            )
        };
        try {
            let cloudService = {
                product_id: 236,
                product_name: "Cloud Service Cluster",
                product_group_id: 22,
                product_group_name: 'Cloud Service',
                product_template: 'NodeServerCluster',
                product_addons: rebuilder(products, serviceBilling),
                service_management_billing: serviceBilling
            }
            return cloudService

        } catch (error) {
            return {}
        }
    }

    const ok = () => {
        saver(rebuildServiceCluster())
        closer()
        clear()
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', gap: 1.25 }}>
                {GCORE_SCOPES.map((scope) => {
                    const Component = modules[scope?.module] || undefined
                    if (!Component) return null
                    const componentProps = {
                        ...scope,
                        ...scope?.props
                    }
                    return <Component {...componentProps} onSelect={onSelect} />
                })}
            </Box>
            <Typography sx={{ fontSize: 14, mt: 1.25, fontWeight: 'medium', mb: 0.75 }}>
                Cấu hình đã chọn
            </Typography>
            <DataProducts products={products} remove={removeIndex} update={update} isFetching={inserting} />
            <Collapse in={products && products?.length > 0}>
                <ServiceBilling billing={billing} setBilling={setBilling} />
            </Collapse>
            <Box sx={{ display: 'flex', mt: 2, justifyContent: 'flex-end', gap: 2 }}>
                <Button size='small'
                    onClick={() => {
                        clear()
                        closer()
                    }}
                >
                    Huỷ Thao Tác
                </Button>
                <Button size='small' variant='outlined' onClick={ok}>
                    Thêm Sản Phẩm
                </Button>
            </Box>
        </Box>
    )
}

export default GcoreCloudService