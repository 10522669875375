import React, { useState } from "react";
import { Button, Box, CircularProgress, Typography, Stack } from "@mui/material";
import html2canvas from "html2canvas";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { downloadBase64File, formatNumber } from "@/functions";
import WorkDayApi from "@/apis/beta/Employee/WorkDayApi";

const EmployeeSalarySlip = ({ employee, watch }) => {
  const {
    salery_gross,
    bhxh,
    work_days_standard,
    work_days_bonus,
    extra_salary,
    commission,
    month,
    year,
    bhxh_minus,
    others_minus,
    tax,
  } = watch();

  const [loadingScreenShotElement, setLoadingScreenShotElement] =
    useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const work_days = Number(watch()?.work_days) + Number(work_days_bonus);

  const convertCurrencyStringToNumber = (str) => {
    return Number(str?.split(".").join(""));
  };

  const dataInfo = [
    {
      title: "Thông tin",
      data: [
        { label: "Mã số nhân viên", value: employee?.employee_id },
        { label: "Họ và tên", value: employee?.employee_name },
        { label: "Chức vụ", value: employee?.employee_position },
        {
          label: "Tổng lương gross",
          value:
            formatNumber(convertCurrencyStringToNumber(salery_gross)) || "",
        },
        {
          label: "Mức đóng BHXH",
          value: formatNumber(convertCurrencyStringToNumber(bhxh)) || "",
        },
      ],
    },
    {
      title: "Chấm công",
      data: [
        { label: "Ngày công chuẩn", value: Number(work_days_standard) },
        { label: "Ngày công thực tế", value: Number(work_days) },
        {
          label: "Ngày nghĩ không phép",
          value: Number(work_days_standard) - Number(work_days),
        },
        {
          label: "Ngày nghĩ lễ tết hưởng lương",
          value: work_days_bonus,
        },
        { label: "Tổng công tính lương", value: work_days },
      ],
    },
    {
      title: "Tiền lương",
      data: [
        {
          label: "Lương theo ngày công",
          value: formatNumber(
            (convertCurrencyStringToNumber(salery_gross) /
              Number(work_days_standard)) *
            Number(work_days) || 0
          ),
        },
        {
          label: "Phụ cấp, thưởng",
          value: formatNumber(convertCurrencyStringToNumber(extra_salary)),
        },
        {
          label: "Hoa hồng",
          value: formatNumber(convertCurrencyStringToNumber(commission)),
        },
      ],
    },
    {
      title: "Các khoảng trừ",
      data: [
        {
          label: "Trích nộp BHXH",
          value: formatNumber(convertCurrencyStringToNumber(bhxh_minus)),
        },
        {
          label: "Khấu trừ thuế thu nhập cá nhân",
          value: formatNumber(convertCurrencyStringToNumber(tax)),
        },
        {
          label: "Các khoảng trừ khác (nếu có)",
          value: formatNumber(convertCurrencyStringToNumber(others_minus)),
        },
      ],
    },
    {
      title: "Tổng tiền thực nhận",
      data: [
        {
          label: "Tổng lương trong tháng",
          value: formatNumber(
            (
              (convertCurrencyStringToNumber(salery_gross) / Number(work_days_standard)) * Number(work_days) +
              convertCurrencyStringToNumber(extra_salary) +
              convertCurrencyStringToNumber(commission)
            ) -
            (convertCurrencyStringToNumber(bhxh_minus) +
              convertCurrencyStringToNumber(others_minus) +
              convertCurrencyStringToNumber(tax))
          ),

        },
      ],
    },
  ];

  const { mutateAsync, isLoading } = useMutation(WorkDayApi.SendSalarySlip, {
    onError: () => {
      enqueueSnackbar("Something went wrong");
    },
  });

  const screenShotElement = (element, callback) => {
    setLoadingScreenShotElement(true);

    html2canvas(element)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 10);
        callback(imgData);
      })
      .catch(() => {
        setLoadingScreenShotElement(false);
      })
      .finally(() => setLoadingScreenShotElement(false));
  };

  const handleExportPDF = () => {
    screenShotElement(document.getElementById("pdf-salary"), (imgData) =>
      downloadBase64File(
        imgData,
        `Phiếu lương - ${employee?.employee_name} - Tháng ${month} - Năm ${year}`
      )
    );
  };

  const handleSendMailSalarySlip = async () => {
    screenShotElement(
      document.getElementById("pdf-salary"),
      async (imgData) => {
        const response = await mutateAsync({
          employee_id: employee?.employee_id,
          base64_data: imgData,
          month,
          year,
        });
        if (response?.errors) return enqueueSnackbar("Something went wrong");
        enqueueSnackbar("Gửi phiếu lương qua mail cho nhân viên thành công");
      }
    );
  };

  return (
    <Box sx={{ backgroundColor: "#fff" }}>
      <Box sx={{ pt: 2 }}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ gap: 2}}>
            <Button
              disabled={loadingScreenShotElement}
              startIcon={loadingScreenShotElement && <CircularProgress size={18} />}
              onClick={handleExportPDF}
              variant="contained"
              size="small"
            >
              Tải phiếu
            </Button>
            <Button
              disabled={isLoading || loadingScreenShotElement}
              startIcon={
                (loadingScreenShotElement || isLoading) && (
                  <CircularProgress size={18} />
                )
              }
              variant="contained"
              size="small"
              onClick={handleSendMailSalarySlip}
              sx={{ ml: 2 }}
            >
              Gửi phiếu lương
            </Button>
        </Stack>
      </Box>
      <Box
        id="pdf-salary"
        p={2}
        sx={{ backgroundColor: "#f9fafb", borderRadius: 2, mt: 2 }}
      >
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <img alt="logo" width={200} src="/vnetwork-logo.png" />
            <Box>
              <Typography margin={0}>
                Tháng {month}/{year}
              </Typography>
            </Box>
          </Box>

          <Typography textAlign="center" mt={4} fontWeight={600} fontSize={20}>
            Phiếu lương nhân viên
          </Typography>

          <table width="100%" style={{ marginTop: 20 }}>
            {dataInfo?.map((item) => (
              <>
                <tr
                  style={{
                    border: "1px solid #dddddd",
                  }}
                >
                  <th style={{ padding: 8, textAlign: "center" }}>
                    <Typography fontWeight={600}>{item?.title}</Typography>
                  </th>
                </tr>

                {item?.data?.map((item) => (
                  <>
                    <tr>
                      <td
                        style={{
                          padding: 8,
                          textAlign: "left",
                          border: "1px solid #dddddd",
                          width: "50%",
                        }}
                      >
                        <Typography>{item?.label}: </Typography>
                      </td>
                      <td
                        style={{
                          padding: 8,
                          textAlign: "left",
                          border: "1px solid #dddddd",
                          width: "50%",
                        }}
                      >
                        <Typography>{item?.value}</Typography>
                      </td>
                    </tr>
                  </>
                ))}
              </>
            ))}
          </table>
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeeSalarySlip;
