import { WarningAmberOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'

const NotificationWarning = ({
    message
}) => {
    return (
        <Box sx={{
            borderRadius: '14px',
            bgcolor: "#FF980014",
            p: '20px',
            display: 'flex'
        }}>
            <WarningAmberOutlined sx={{ m: "0 8px auto 0", color: "#FF9800" }} />
            <Typography sx={{
                flexWrap: 'wrap',
                textAlign: "justify",
                fontSize: 14,
                mt: 0.5
            }}>
                {message}
            </Typography>
        </Box>
    )
}

export default NotificationWarning