import ManagementAccount from "./ManagementAccount";
import ManagementBusiness from "./ManagementBusiness";
import ManagementDayOff from "./ManagementDayOff";
import ManagementEmployee from "./ManagementEmployee";
import ManagementWorkContract from "./ManagementWorkContract";
import ManagementSubWorkContract from "./SubWorkContract";
import ManagementResume from "./ManagementResume";
import ManagementWorkDay from "./ManagementWorkDay";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ManagementEmployee,
  ManagementDayOff,
  ManagementBusiness,
  ManagementWorkContract,
  ManagementAccount,
  ManagementSubWorkContract,
  ManagementResume,
  ManagementWorkDay,
};
