import { DeleteOutlined } from '@mui/icons-material';
import { Box, FormControl, IconButton, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

const CustomerContact = () => {
    const { control } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control: control,
        name: "customer.customer_contact_social"
    })

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                flexDirection: {
                    xs: "column", md: "row"
                },
                justifyContent: 'space-between',
                mb: { xs: 1.5, md: 0.5 }
            }}>
                <Typography sx={{ color: "#777680", fontSize: 14 }}>
                    Kênh liên hệ chính *
                </Typography>
                <Typography color="primary"
                    onClick={() => append({
                        contact_channel: "",
                        contact_channel_information: ""
                    })}
                    sx={{
                        fontSize: 14,
                        textDecoration: "underline",
                        ":hover": {
                            cursor: 'pointer'
                        }
                    }}>
                    + Thêm kênh liên hệ
                </Typography>
            </Box>
            {fields && fields.map((field, index) => {
                return (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: 1.5,
                        borderBottom: {
                            xs: '1px solid rgba(0, 0, 0, 0.18)',
                            md: 'unset'
                        },
                        mb: 1.5
                    }} key={field.id}>
                        <Controller
                            control={control}
                            name={`customer.customer_contact_social.[${index}].contact_channel`}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <FormControl fullWidth sx={{
                                        maxWidth: {
                                            xs: '100%',
                                            md: '35%'
                                        }
                                    }}>
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            size="small"
                                            placeholder="Ex: Facebook, Zalo,..."
                                        />
                                    </FormControl>
                                )
                            }} />
                        <Controller
                            control={control}
                            name={`customer.customer_contact_social.[${index}].contact_channel_information`}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <FormControl sx={{
                                        maxWidth: {
                                            xs: 'calc(100% - 8px - 40px)',
                                            md: 'calc((100% - 12px * 2 - 35% - 40px))'
                                        }
                                    }} fullWidth>
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            size="small"
                                            placeholder="https://www.facebook.com..."
                                        />
                                    </FormControl>
                                )
                            }} />
                        <IconButton size='small'
                            sx={{ width: 40, height: 40, m: 'auto 0 auto auto' }}
                            onClick={() => remove(index)}>
                            <DeleteOutlined fontSize='small' sx={{ ":hover": { color: "#ef5350" } }} />
                        </IconButton>
                    </Box>
                )
            })}
        </Box>
    )
}

export default CustomerContact