import DatePicker from '@components/DatePicker'
import NumericFormatCustom from '@components/NumericFormatCustom'
import DateRangePicker from '@components/datepickers/DateRangPicker'
import MultipleSelectCustomize from '@components/shared/MultipleSelectCustomize'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, FormControl, TextField } from '@mui/material'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import ServerCollection from '../ServerCollection'

const Comparison = () => {
    const { control, watch, setValue } = useFormContext()

    const clientComponent = useMemo(() => {
        const location = [watch(`input.type`), watch(`operator.operator`)].join(':')
        switch (location) {
            case ["string", "$eq"].join(':'):
            case ["string", "$ne"].join(':'):
            case ["string", "$contains"].join(':'):
            case ["string", "$notContains"].join(':'):
                return (
                    <Controller
                        name='input.string'
                        control={control}
                        rules={{
                            required: { message: 'Vui lòng nhập thông tin tìm kiếm...' }
                        }}
                        render={({ field, fieldState: { error } }) => {
                            return (
                                <FormControl fullWidth id="form.input.string">
                                    <TextField
                                        {...field}
                                        size='small'
                                        error={!!error}
                                        helperText={error?.message}
                                        placeholder='Nhập giá trị tìm kiếm...'
                                    />
                                </FormControl>
                            )
                        }}
                    />
                )
            case ["number", "$eq"].join(':'):
            case ["number", "$ne"].join(':'):
            case ["number", "$lte"].join(':'):
            case ["number", "$gte"].join(':'):
            case ["price", "$eq"].join(':'):
            case ["price", "$ne"].join(':'):
            case ["price", "$lte"].join(':'):
            case ["price", "$gte"].join(':'):
                return (
                    <Controller
                        name='input.string'
                        control={control}
                        rules={{
                            required: { message: 'Vui lòng nhập thông tin tìm kiếm...' }
                        }}
                        render={({ field, fieldState: { error } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        {...field}
                                        InputProps={{
                                            inputComponent: NumericFormatCustom
                                        }}
                                        error={!!error}
                                        helperText={error?.message}
                                        size='small'
                                        placeholder='Nhập giá trị tìm kiếm...'
                                    />
                                </FormControl>
                            )
                        }}
                    />
                )
            case ["string", "$in"].join(':'):
            case ["string", "$nin"].join(':'):
            case ["number", "$in"].join(':'):
            case ["number", "$nin"].join(':'):
                return (
                    <Controller
                        name='input.string'
                        rules={{ rules: { message: 'Vui lòng nhập giá trị tìm kiếm...' } }}
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        error={!!error}
                                        {...field}
                                        size='small'
                                        helperText={!!error ? error?.message : "Sử dụng dấu phẩy để phân biệt các giá trị"}
                                        placeholder='Nhập giá trị tìm kiếm...'
                                    />
                                </FormControl>
                            )
                        }}
                    />
                )
            case ["object", "$in"].join(':'):
            case ["object", "$nin"].join(':'):
            case ["object", "$included"].join(':'):
                return <Controller
                    name='input.objects'
                    control={control}
                    render={({ field: { value, onChange } }) => {
                        const options = watch(`input.collections`) || []
                        return (
                            <FormControl fullWidth id="form.input.objects">
                                <MultipleSelectCustomize
                                    value={value}
                                    options={options}
                                    onChange={(_, newValues) => onChange(newValues)}
                                    input={{
                                        placeholder: 'Chọn giá trị tìm kiếm...',
                                    }}
                                />
                            </FormControl>
                        )
                    }}
                />
            case ["object", "$eq"].join(':'):
            case ["object", "$ne"].join(':'):
                return <Controller
                    name='input.objects'
                    control={control}
                    render={({ field: { value, onChange } }) => {
                        const options = watch(`input.collections`) || []
                        const object = (value && value?.length) ? value[0] : null
                        return (
                            <FormControl fullWidth id="form.input.objects">
                                <SingleSelectCustomize
                                    value={object}
                                    options={options}
                                    onChange={(_, newValues) => onChange([newValues])}
                                    input={{
                                        placeholder: 'Chọn giá trị tìm kiếm...',
                                    }}
                                />
                            </FormControl>
                        )
                    }}
                />
            case ["date", "$eq"].join(':'):
            case ["date", "$ne"].join(':'):
            case ["date", "$gte"].join(':'):
            case ["date", "$lte"].join(':'):
                return <Controller
                    name='input.startdate'
                    control={control}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FormControl fullWidth>
                                <DatePicker value={value} onChange={onChange} />
                            </FormControl>
                        )
                    }}
                />
            case ["date", "$btw"].join(':'):
                return (
                    <FormControl fullWidth>
                        <DateRangePicker
                            value={[dayjs(watch(`input.startdate`)), dayjs(watch(`input.enddate`))]}
                            handler={(newDates) => {
                                setValue(`input.startdate`, newDates[0])
                                setValue(`input.enddate`, newDates[1])
                            }}
                            dflex={true}
                        />
                    </FormControl>
                )
            // case ["price", "$btw"].join(':'):
            //     return (
            //         <FormControl fullWidth>
            //         </FormControl>
            //     )
            default: return <TextField disabled size='small' placeholder='Không tìm thấy input...' />
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('input.type'), watch('operator.operator')])
    return (
        <>
            <Box sx={{ mt: 2 }}>
                {watch('input.mode') === 'client' ?
                    clientComponent :
                    <ServerCollection
                        operation={watch('operator.operator')}
                        scope={watch('input.scope')}
                        value={watch('input.objects')}
                        onChange={(newValues) => setValue(`input.objects`, [...newValues])}
                    />}
            </Box>
        </>
    )
}

export default Comparison