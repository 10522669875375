import ScopeApi from '@/apis/beta/Scope/ScopeApi'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { FormControl } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

const ScopeModule = () => {
    const { control } = useFormContext()

    const { data: { modules } } = ScopeApi.ListModules()
    return (
        <Controller
            name='scope_module'
            control={control}
            render={({ field: { value, onChange } }) => (
                <FormControl fullWidth>
                    <SingleSelectCustomize
                        options={modules || []}
                        value={modules?.find(module => module?.value === value) || null}
                        input={{
                            label: 'Tính Năng *',
                            placeholder: 'Chọn tính năng/ Module name...'
                        }}
                        onChange={(_, newValue) => onChange(newValue?.value || '')}
                    />
                </FormControl>

            )}
        />
    )
}

export default withSuspense(ScopeModule, MuiSkeleton["TextField"])