import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { CellStatus } from "@/components/DataGrid/Cell";
import { format_date_short, format_numeral_price } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import { SERVICE_STATUS_TYPES, UNLIMITED } from "@/libs/constants";
import DataGridCustomize from "@components/DataGrid";
import PopperAction from "@components/PopperAction";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { MoreVertOutlined } from "@mui/icons-material";
import { Box, IconButton, Popper, Typography } from "@mui/material";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import Modules from "../../../ServiceAddon/Modules";
import ServiceModules from "../../v2/Actions";
import Toolbar from "../../v2/Toolbar";
import {
  buildCurrentlyServiceInformations,
  buildServiceInformations,
} from "./functions";

const VnisService = () => {
  const { service_id } = useParams();

  const INITIAL_SERVICE_ACTIONS = [
    {
      order: 1,
      icon: "Edit",
      name: "edit",
      label: "Chỉnh sửa thông tin dịch vụ",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "service-edit",
          data: row,
        }),
    },
    {
      order: 2,
      icon: "Edit",
      name: "active",
      label: "Kích Hoạt Dịch Vụ",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "service-active",
          data: row,
        }),
    },
    {
      order: 3,
      icon: "Delete",
      name: "cancel",
      label: "Stop/ Huỷ dịch vụ",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "service-cancel",
          data: row,
        }),
    },
    {
      order: 4,
      icon: "See",
      name: "contract",
      label: "Xem hợp đồng dịch vụ",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "view-contract",
          data: row,
        }),
    },
    {
      order: 5,
      icon: "Edit",
      name: "informations",
      label: "Chỉnh sửa cấu hình",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "informations",
          data: row,
        }),
    },
  ];

  const { data: information } = ServiceApi.ServiceInformation({
    serviceId: service_id,
  });

  const [modal, setModal] = React.useState({
    open: false,
    type: undefined,
    data: undefined,
  });

  const [initialColumns] = React.useState([
    {
      field: "id",
      headerName: ".No",
      width: 40,
      renderCell: ({ value }) => value + 1,
    },
    {
      field: "status",
      headerName: "Trạng thái/ Tên Addon",
      width: 300,
    },
    {
      field: "domains",
      headerName: "Protected Domains(DNS - No of Records)",
      width: 200,
      renderCell: ({ value }) =>
        value >= UNLIMITED ? "UNLITMITED" : value || "",
    },
    {
      field: "traffic-vncdn",
      headerName: "CDN & ORIGIN SHIELD Traffic-VNCDN",
      width: 200,
      renderCell: ({ value }) =>
        value >= UNLIMITED ? "UNLITMITED" : value || "",
    },
    {
      field: "request-vncdn",
      headerName: "CDN & ORIGIN SHIELD  Request - VNCDN",
      width: 200,
      renderCell: ({ value }) =>
        value >= UNLIMITED ? "UNLITMITED" : value || "",
    },
    {
      field: "dns-request",
      headerName: "DNS  Request - Tier price",
      width: 200,
      renderCell: ({ value }) =>
        value >= UNLIMITED ? "UNLITMITED" : value || "",
    },
    {
      field: "service_start_date",
      headerName: "Ngày bắt đầu sử dụng dịch vụ",
      width: 220,
      renderCell: ({ value }) => format_date_short(value),
    },
    {
      field: "service_duedate",
      headerName: "Ngày kết thúc",
      width: 160,
      renderCell: ({ value }) => format_date_short(value),
    },
    {
      field: "service_billing",
      headerName: "Chu kỳ thanh toán hiện tại",
      valueGetter: ({ row }) => {
        return `${format_date_short(
          row?.service_billing_startdate
        )} - ${format_date_short(row?.service_billing_enddate)}`;
      },
      width: 240,
    },
    {
      field: "service_amount",
      width: 200,
      renderHeader: (params) => (
        <Box sx={{ width: "100%", height: "100%" }}>
          <Typography sx={{ fontWeight: "medium", fontSize: 14 }}>
            {"Đơn Giá Bán "}(Theo tháng)
          </Typography>
        </Box>
      ),
      renderCell: ({ value }) => format_numeral_price(value),
    },
    {
      field: "service_status",
      headerName: "Trạng Thái",
      width: 150,
      valueGetter: ({ row }) =>
        SERVICE_STATUS_TYPES.find((stt) => stt?.value === row?.service_status),
      renderCell: ({ value }) => (
        <CellStatus component="muiChip" data={value} />
      ),
    },
    {
      field: "service_contract_name",
      headerName: "Hợp đồng",
      width: 200,
      renderCell: ({ value, row }) => {
        if (!value) return null;
        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              textAlign: "center",
            }}
          >
            <Typography
              color="primary"
              sx={{
                fontSize: 14,
                m: "auto 0",
              }}
              onClick={() =>
                setAction({
                  open: true,
                  name: "view-contract",
                  data: row,
                })
              }
            >
              {value}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      hideable: true,
      width: 40,
      renderCell: ({ row }) => {
        if (row?.status === "Cấu Hình Hiện Tại") return null;
        const actions = findServiceActions(row?.service_status);
        if (!actions || actions?.length === 0) return null;
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <IconButton
              onClick={(event) => {
                setPopper((prev) => ({
                  ...prev,
                  anchorEl: event?.target,
                  data: row,
                  actions: actions,
                }));
              }}
              title="Hành động"
            >
              <MoreVertOutlined fontSize="small" sx={{ m: "auto" }} />
            </IconButton>
          </Box>
        );
      },
    },
  ]);

  const [columns, setColumns] = React.useState([...initialColumns]);
  React.useEffect(() => {
    setColumns([...initialColumns]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialColumns, information?.service_informations]);

  const { data } = ServiceApi.Addons({ serviceId: service_id });

  const buildProductConfigoptions = React.useCallback(() => {
    let metadata = {
      status: "Ban đầu",
      ..._.pick(information, [
        "service_id",
        "service_start_date",
        "service_duedate",
        "service_billing_startdate",
        "service_billing_enddate",
        "service_amount",
        "service_recurring",
        "service_tax",
        "service_status",
        "service_contract_name",
      ]),
    };

    const addonMetadata = data?.service_addons?.map((addon) => {
      let temp = {};
      switch (addon?.service_product_name?.toLowerCase().trim()) {
        case "protected domains (dns-no of records)":
          temp["domains"] = addon?.service_addon_qty || 0;
          break;
        case "cdn & origin shield traffic-vncdn":
          temp["traffic-vncdn"] = addon?.service_addon_qty || 0;
          break;
        case "cdn & origin shield request - vncdn":
        case " cdn & origin shield request - vncdn":
        case " cdn & origin shield request-vncdn":
        case "cdn & origin shield request-vncdn":
          temp["request-vncdn"] = addon?.service_addon_qty || 0;
          break;
        case "dns request - tier price":
        case "dns  request - tier price":
          temp["dns-request"] = addon?.service_addon_qty || 0;
          break;
        default:
          break;
      }
      return {
        ...metadata,
        service_id: addon?.service_id,
        service_name: addon?.service_name,
        status: `${addon?.service_name}`,
        service_start_date: addon?.service_start_date,
        service_billing_startdate: addon?.service_billing_startdate,
        service_billing_enddate: addon?.service_billing_enddate,
        service_duedate: addon?.service_duedate,
        service_tax: addon?.service_tax,
        service_amount: addon?.service_amount,
        service_status: addon?.service_status,
        service_contract_name: addon?.service_contract?.contract_number_id,
        ...temp,
      };
    });
    metadata = buildServiceInformations(
      information?.service_informations,
      metadata
    );

    return [
      metadata,
      ...addonMetadata,
      buildCurrentlyServiceInformations(
        information,
        metadata,
        data?.service_addons
      ),
    ];
  }, [data, information]);

  const [popper, setPopper] = React.useState({
    anchorEl: undefined,
    data: undefined,
    actions: [],
  });
  const [action, setAction] = React.useState({
    open: false,
    name: undefined,
    data: undefined,
  });

  const onClosePopper = () =>
    setPopper((prev) => ({
      ...prev,
      anchorEl: undefined,
      data: undefined,
      actions: [],
    }));

  const onCloseAction = () =>
    setAction({ open: false, name: undefined, data: undefined });

  const renderComponents = (type) => {
    switch (type) {
      case "service-edit":
        return (
          <ServiceModules.ServiceEdit
            serviceId={service_id}
            onClose={onCloseAction}
            data={action?.data}
          />
        );
      case "service-active":
        return (
          <ServiceModules.ServiceActive
            serviceId={service_id}
            onClose={onCloseAction}
            data={action?.data}
          />
        );
      case "service-cancel":
        return (
          <ServiceModules.ServiceCancel
            onClose={onCloseAction}
            data={action?.data}
          />
        );
      case "view-contract":
        return (
          <ServiceModules.ViewContract
            onClose={onCloseAction}
            data={action?.data}
          />
        );
      case "informations":
        return (
          <ServiceModules.Informations
            onClose={onCloseAction}
            data={action?.data}
          />
        );
      default:
        break;
    }
  };

  const findServiceActions = (status) => {
    if (status === "active") {
      return INITIAL_SERVICE_ACTIONS?.filter((x) => x?.name !== "active");
    }
    if (status?.match(/(cancel|stop)/)) {
      return INITIAL_SERVICE_ACTIONS?.filter((x) => x?.name !== "informations");
    }
    return INITIAL_SERVICE_ACTIONS;
  };

  return (
    <React.Fragment>
      {action?.open && renderComponents(action?.name)}
      {popper?.anchorEl !== undefined && (
        <Popper
          anchorEl={popper?.anchorEl}
          sx={{
            zIndex: 99,
          }}
          open={Boolean(popper.anchorEl)}
          onMouseLeave={onClosePopper}
        >
          <PopperAction {...popper} handleClose={onCloseAction} />
        </Popper>
      )}
      {modal.open && modal.type === "create" && (
        <Modules.Create
          handleClose={() => setModal({ open: false, type: undefined })}
        />
      )}
      <DataGridCustomize
        rows={
          buildProductConfigoptions()?.map((item, index) => ({
            ...item,
            id: index,
          })) || []
        }
        columns={columns}
        sx={{ mt: 2 }}
        components={{
          Toolbar: Toolbar,
          Pagination: "disabled",
        }}
        componentsProps={{
          toolbar: {
            handleClick: () => setModal({ open: true, type: "create" }),
            disabled: modal?.open,
            serviceId: service_id,
          },
        }}
        getRowClassName={({ row }) => {
          if (row?.status === "Cấu Hình Hiện Tại")
            return clsx(`grid-dc-vnetwork`, `row-highlight`);
        }}
        initialState={{
          pinnedColumns: { right: ["actions"] },
        }}
      />
    </React.Fragment>
  );
};

export default withSuspense(
  withPermission(VnisService, { feature: "service", action: "informations" }),
  MuiSkeleton["DataGrid"]
);
