import React, { useEffect, useState } from "react";
import { CV_SCREEN_RESULTS } from "../../shared";
import { MenuItem, Select } from "@mui/material";
import { useNotificationContext } from "@contexts/notification";
import { useConfirmContext } from "@contexts/confirm";
import CVApi from "@/apis/beta/Employee/CVApi";

const ScreenResults = ({ row, refetch }) => {
  const [state, setState] = useState(row?.cv_screen_results);

  const { showConfirmation } = useConfirmContext();
  const { setNotification } = useNotificationContext();

  const update = async (mailTemplate) => {
    setNotification({
      open: true,
      message: "Update successfully",
      severity: "success",
    });

    await CVApi.Accept({
      id: row?.id,
      mail_send: [
        ...(row?.mail_send?.length > 0 ? row?.mail_send : []),
        mailTemplate,
      ],
      mailTemplate,
      cv_screen_results: state,
    });

    refetch();
  };

  useEffect(() => {
    if (state === row?.cv_screen_results) return;

    const mailTemplate = CV_SCREEN_RESULTS?.find(
      (item) => item?.value === state
    )?.mail_template;

    if (mailTemplate) {
      return showConfirmation({
        handler: () => update(mailTemplate),
        title: "Thao tác này đi kèm gửi mail",
        open: true,
        description: `Template mail được sử dụng ${mailTemplate}`,
        loading: false,
        onCancel: () => {
          setState(row?.cv_screen_results);
        },
      });
    }

    update(mailTemplate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <Select
      onChange={(e) => {
        setState(e?.target?.value);
      }}
      value={state}
      size="small"
      fullWidth
      disabled={state === 1}
    >
      {CV_SCREEN_RESULTS.map((item) => (
        <MenuItem value={item?.value} key={item?.value}>
          {item?.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ScreenResults;
