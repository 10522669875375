import PermissionApi from "@/apis/beta/Permission/PermissionApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { EditOutlined } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { indigo } from "@mui/material/colors";
import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";

const List = () => {
  const {
    data: { permissions },
  } = PermissionApi.List();

  const chainBasewebFeature = React.useCallback(() => {
    const features = permissions?.map((rl) => {
      return {
        webFeature: rl?.permission_name?.split("#")[0],
        action: {
          id: rl?.permission_id,
          action: rl?.permission_name?.split("#")[1],
          pathname: rl?.permission_pathname,
        },
      };
    });

    const newCols = _.chain(Array.from(features))
      .groupBy("webFeature")
      ?.map((actions, key) => {
        return {
          id: key,
          website: key?.split("::")[0],
          feature: key?.split("::")[1],
          actions: actions?.map(({ action }) => ({ ...action })),
        };
      })
      ?.value();
    return _.orderBy(newCols, ["feature"]);
  }, [permissions]);

  const [collections, setCollections] = React.useState(chainBasewebFeature());
  React.useEffect(() => {
    setCollections(chainBasewebFeature());
  }, [chainBasewebFeature]);

  return (
    <Box>
      <Header />
      <Grid container spacing={2}>
        {collections?.map((role, index) => {
          return (
            <Grid item xs={12} md={6} key={index}>
              <Box
                sx={{
                  border: "1px solid rgba(0, 0, 0, 0.38)",
                  p: 3,
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column-reverse", md: "row" },
                    gap: 1,
                  }}
                >
                  <Typography
                    component="div"
                    sx={{
                      fontSize: 14,
                      fontWeight: "medium",
                      textTransform: "uppercase",
                      mb: 0.5,
                    }}
                  >
                    [{role?.website}]
                    <Typography
                      color="primary"
                      sx={{
                        display: "inline-block",
                        fontSize: 14,
                        fontWeight: "medium",
                        ml: 0.5,
                      }}
                    >
                      [{role?.feature}]
                    </Typography>
                  </Typography>
                  <Link to={`/roles/${role?.id}`} replace>
                    <IconButton size="small" sx={{ m: "auto 0" }}>
                      <EditOutlined
                        fontSize="small"
                        sx={{
                          ":hover": {
                            color: indigo[900],
                          },
                        }}
                      />
                    </IconButton>
                  </Link>
                </Box>
                {role?.actions?.length !== 0 &&
                  role?.actions?.map((action, i) => {
                    return (
                      <Typography
                        component="div"
                        key={i}
                        sx={{
                          fontSize: 14,
                        }}
                      >
                        {i + 1}. [{action?.action}]
                      </Typography>
                    );
                  })}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default withSuspense(
  withPermission(List, { feature: "permission", action: "index" }),
  MuiSkeleton["Loading"]
);
