import BusinessApi from '@/apis/beta/Employee/BusinessApi'
import EmployeeApi from "@/apis/beta/Employee/EmployeeApi"
import UserApi from "@/apis/UserApi"
import BusinessListShared from "@/components/Business/List"
import SkeletonDataGrid from "@/components/Skeletons/DataGrid"
import { buildQueryFilterCondition } from "@/functions"
import withSuspense from "@/hocs/withSuspense"
import useDebounce from "@/hooks/useDebounce"
import Destroy from '@features/Module/Employee/ManagementBusiness/Destroy'
import { AddOutlined } from '@mui/icons-material'
import { Box, Button, Skeleton } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const Business = () => {
    const [current] = UserApi.Current()
    const { data: employee } = EmployeeApi.Detail(current?.employee_id)

    const [searchQuery, setSearchQuery] = React.useState('')
    const debouncedSearchQuery = useDebounce(searchQuery, 500)

    const filterableBusiness = React.useCallback(() => {
        if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === '') return '';
        const fieldQueries = [
            {
                name: 'businesses.business_id', operator: "$eq",
                disabled: !parseInt(debouncedSearchQuery), value: parseInt(debouncedSearchQuery)
            },
        ]
        return buildQueryFilterCondition("$or", 0, fieldQueries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery])

    const [action, setAction] = React.useState({
        open: false,
        name: undefined,
        data: undefined,
    })

    const clearAction = () => setAction({ open: false, name: undefined, data: undefined })

    const INITIAL_ACTION_LIST = [
        {
            name: "update",
            icon: "Edit",
            label: "Chỉnh Sửa",
            isHref: true,
            href: {
                prefix: `/businesses/`,
                key: "business_id"
            },
        }, {
            name: "cancel",
            icon: "Delete",
            label: "Huỷ Phiếu",
            handleClick: (data) => {
                setAction(prev => ({ ...prev, open: true, data: data, name: "cancel" }))
            }
        }
    ]

    const [page, setPage] = React.useState({
        page: 0,
        pageSize: 20
    });
    const { data, isFetching, refetch: callbackBusinesses } = BusinessApi.List({
        page: page?.page + 1,
        pageSize: page?.pageSize,
        query: `&filters[$and][0][businesses.employee_id][$eq]=${current?.employee_id}`,
        searchQuery: filterableBusiness() !== "" ? filterableBusiness() : null
    })

    return (
        <React.Fragment>
            {(action.open && action?.name === "cancel") &&
                <Destroy data={action?.data}
                    callback={callbackBusinesses}
                    handleClose={clearAction}
                />}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <Link to="/businesses/new" replace>
                    <Button variant='contained'
                        disabled={action.open}
                        size="small"
                        startIcon={<AddOutlined fontSize='small' />}
                        sx={{
                            height: { xs: 36, md: 42 },
                            minWidth: 180
                        }}
                    >
                        Tạo Phiếu Công Tác
                    </Button>
                </Link>
            </Box>
            <BusinessListShared
                filter={{
                    filter: "account_businesses.list.filters",
                    columnsHidden: "account_businesses.hiddenColumn"
                }}
                inputSearch={{
                    placeholder: "Nhập mã phiếu tìm kiếm...",
                    value: searchQuery,
                    onChange: setSearchQuery
                }}
                employee={employee}
                callbackBusinesses={callbackBusinesses}
                feature="account"
                data={data?.businesses}
                isFetching={isFetching}
                departments={[{ department_id: employee?.department_id, department_name: employee?.employee_department }]}
                pagination={{
                    rowsPerPageOptions: [5, 10, 20, 50],
                    page: page?.page || 0,
                    pageSize: page?.pageSize || 5,
                    onPageChange: (newPage) => setPage(prevState => ({ ...prevState, page: newPage })),
                    onPageSizeChange: (newPageSize) => setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
                    total: data?.pagination?.total
                }}
                employees={[employee]}
                initialActions={INITIAL_ACTION_LIST}
            />
        </React.Fragment>
    )
}


const SkeletonList = () => {
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <Skeleton variant='rectangular' sx={{ height: 42, borderRadius: '4px', width: 120 }} />
            </Box>
            <SkeletonDataGrid />
        </Box>
    )
}
export default withSuspense(Business, SkeletonList)