import ProductApi from '@/apis/beta/Product/ProductApi'
import withSuspense from '@/hocs/withSuspense'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, Button, Drawer, FormControl, Typography } from '@mui/material'
import React from 'react'

const CountrySelection = ({
    closer,
    saver,
    selecteds = []
}) => {

    const { data } = ProductApi.Countries()
    const [state, setState] = React.useState(null)
    const [error, setError] = React.useState(false)

    const onSubmit = () => {
        if (selecteds?.map(({ name }) => name).includes(state?.countryIsoCode?.toLowerCase())) {
            return setError(true)
        }
        if (selecteds?.map(({ name }) => name).includes('local') && state?.countryIsoCode?.toLowerCase() === "vn") {
            return setError(true)
        }
        return saver(state)
    }

    return (
        <Drawer anchor='right'
            open={true}
        >
            <Box sx={{ height: "100vh", p: 3, minWidth: { xs: 300, md: 450 } }}>
                <Typography sx={{ fontSize: 18, fontWeight: 'medium', mb: 1 }}>
                    Thêm Quốc Gia Định Giá Băng Thông
                </Typography>
                <Typography sx={{ fontSize: 14, color: "#777680", mb: 0.5 }}>
                    Chọn Quốc Gia/ Vùng *
                </Typography>
                <FormControl fullWidth>
                    <SingleSelectCustomize
                        value={state}
                        options={data || []}
                        compareAttributes={['countryIsoCode', 'countryName']}
                        formatCollection={{
                            value: "countryIsoCode",
                            label: "countryName"
                        }}
                        input={{
                            placeholder: "Chọn vùng..."
                        }}
                        onChange={(event, newValue) => {
                            setState(newValue)
                            setError(false)
                        }}
                    />
                    {!!error && <ErrorHelperText message="Quốc gia/ vùng này đã tồn tại." />}
                </FormControl>

                <Box sx={{
                    mt: 3,
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'flex-end',
                    gap: 1.5
                }}>
                    <Button variant='text' size="small" sx={{ height: 36 }}
                        onClick={closer}
                    >Huỷ thao tác</Button>
                    <Button variant='outlined' size="small" sx={{ height: 36, minWidth: 120 }}
                        onClick={onSubmit}
                    >Thêm</Button>
                </Box>
            </Box>
        </Drawer>
    )
}

export default withSuspense(CountrySelection, MuiSkeleton["TextField"])