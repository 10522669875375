import { format_date_short } from "@/functions";
import { INDUSTRIES } from "@/libs/constants";
import { CellLink } from "@components/DataGrid/Cell";
import _ from "lodash";

const buildCustomerPOCs = ({ customers = [], employees = [] }) => {
    let pocs = []
    const customerUniqs = _.groupBy(customers, (customer) => customer?.customer_id)
    _.each(customerUniqs, (services, customerId) => {
        const service = services[0]

        pocs.push({
            hierarchy: [customerId],
            customer_id: service?.customer_id,
            customer_name: service?.customer_name?.toUpperCase(),
            customer_company_name: service?.customer_company_name?.toUpperCase(),
            service_status: service?.service_status,
            service_note: service?.service_note,
            service_start_date: service?.service_start_date,
            service_stopped_at: service?.service_stopped_at || service?.service_expiry_date,
            service_id: service?.service_id,
            service_name: service?.service_product_name?.toUpperCase(),
            customer_industry: (service?.company_industry || service?.customer_industry)?.toUpperCase(),
            sale_tracking_id: employees?.filter(employee => service?.employee_sale_ids?.includes(employee?.employee_id))?.map((employee) => employee?.employee_name)?.join(', ')
        })
        if (!!services && services?.length > 1) {
            services?.slice(1)?.forEach((s) => {
                pocs.push({
                    hierarchy: [customerId, s?.service_id],
                    customer_id: '',
                    customer_name: '',
                    customer_company_name: '',
                    service_status: s?.service_status,
                    service_note: s?.service_note,
                    service_start_date: s?.service_start_date,
                    service_stopped_at: s?.service_stopped_at || s?.service_expiry_date,
                    service_id: s?.service_id,
                    service_name: s?.service_product_name?.toUpperCase(),
                    customer_industry: (s?.company_industry || s?.customer_industry)?.toUpperCase(),
                    sale_tracking_id: employees?.filter(employee => s?.employee_sale_ids?.includes(employee?.employee_id))?.map((employee) => employee?.employee_name)?.join(', ')
                })
            })
        }
    })
    return pocs
}

const buildFilterables = ({ employees = [], productGroups = [] }) => {
    return [
        {
            field: "personal_informations.name",
            label: "Tên Khách Hàng",
            operators: { type: "string" }
        }, {
            field: "tw_services.employee_sale_ids",
            label: "SALE Phụ Trách",
            operators: {
                type: "object",
                operators: ["$included"],
                collections: employees?.map((employee) => ({ value: employee?.employee_id, label: employee?.employee_name })),
            },
        }, {
            field: "customer_service_informations.customer_industry",
            label: "Lĩnh Vực",
            operators: { type: "object", collections: INDUSTRIES },
        }, {
            field: "product_groups.product_group_id",
            label: "Sản Phẩm",
            operators: {
                type: "object",
                collections: productGroups?.map((productGroup) => ({ value: productGroup?.product_group_id, label: productGroup?.product_group_name })),
            },
        },
    ]
}

const INITIAL_COLUMNS = [
    {
        field: "sale_tracking_id",
        headerName: "SALE Phụ Trách",
        minWidth: 260,
    }, {
        field: "customer_industry",
        headerName: "Lĩnh Vực",
        width: 180,
    }, {
        field: "service_id",
        headerName: "Dịch Vụ",
        width: 300,
        valueGetter: ({ row }) => {
            return ({
                label: `${row?.service_id} - ${row?.service_name}`,
                href: ["services", row?.service_id],
            })
        },
        renderCell: ({ value }) => { return (<CellLink data={value} />) }
    }, {
        field: "service_start_date",
        headerName: "Ngày Bắt Đầu POC",
        width: 150,
        renderCell: ({ value }) => !!value && format_date_short(value)
    }, {
        field: "service_stopped_at",
        headerName: "Ngày Kết Thúc POC",
        width: 150,
        renderCell: ({ value }) => !!value && format_date_short(value)
    }, {
        field: "explainations.customer_expected_explaination",
        valueGetter: ({ row }) => row?.explainations?.customer_expected_explaination || '',
        headerName: "Mong đợi",
        width: 200,
    }, {
        field: 'explainations.customer_expected_date',
        headerName: 'Thời Gian Dự Tính Bán Hàng',
        width: 200,
        valueGetter: ({ row }) => row?.explaination?.customer_expected_date,
        renderCell: ({ value }) => !!value && format_date_short(value)
    }, {
        field: 'service_status',
        headerName: 'Trạng Thái Dịch Vụ',
        width: 150,
        valueGetter: ({ row }) => row?.service_status === 'cancel' ? 'Đã Off' : 'Đang Sử Dụng'
    }, {
        field: 'service_note',
        headerName: 'Kết Quả POC',
        width: 150
    },
]

const CustomerPOCLibs = {
    columns: INITIAL_COLUMNS,
    rows: buildCustomerPOCs,
    filterables: buildFilterables
}
export default CustomerPOCLibs