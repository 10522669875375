import DataGrid from '@/components/DataGrid'
import { CellPricing, CellStatus } from '@/components/DataGrid/Cell'
import { format_date_short } from "@/functions"
import { SERVICE_STATUS_TYPES } from "@/libs/constants"
import { MoreVertOutlined } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import React from 'react'

const List = ({
    addons,
    handleClick
}) => {
    const initialColumns = [
        {
            field: 'service_name', headerName: 'Tên Sản Phẩm/ Dịch Vụ Mua Thêm',
            width: 350,
        }, {
            field: 'service_addon_qty', headerName: 'Số Lượng', width: 120
        }, {
            field: 'created_at', headerName: 'Ngày Đăng Ký',
            width: 140,
            renderCell: ({ value }) => format_date_short(value),
        }, {
            field: 'service_amount', headerName: 'Giá',
            width: 180,
            renderCell: ({ value }) => <CellPricing price={value} />
        }, {
            field: 'service_duedate', headerName: 'Ngày Gia Hạn',
            width: 140,
            renderCell: ({ value }) => format_date_short(value),
        }, {
            field: 'service_status', headerName: 'Trạng Thái',
            width: 150,
            valueGetter: ({ row }) =>
                SERVICE_STATUS_TYPES.find(status => status?.value === row.service_status),
            renderCell: ({ value }) => <CellStatus component="muiChip" data={value} />
        }, {
            field: 'actions', headerName: 'Thao Tác', hideable: true,
            width: 80,
            renderCell: ({ row }) => <Box sx={{ width: "100%", display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={(event) => handleClick(event, row)}>
                    <MoreVertOutlined />
                </IconButton>
            </Box>
        }]
    const [columns] = React.useState(initialColumns)
    return (
        <Box>
            <DataGrid
                rows={addons?.map((service) => ({
                    ...service,
                    id: service?.service_id
                })) || []}
                columns={columns}
                components={{
                    Toolbar: 'disabled',
                    Pagination: 'disabled'
                }}
            />
        </Box>
    )
}

export default List