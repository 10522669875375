import { DICTIONARIES } from '@/libs/constants';
import { Box, Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import _ from 'lodash';
import React from 'react';

const DialogShowJsonData = (props) => {
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [gridWidth, setGridWidth] = React.useState(3);

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      event.target.value.split("-")[0],
    );
    setGridWidth(
      event.target.value.split("-")[1]
    )
  };

  const buildKeyToString = (key = '') => {
    let keys = key?.toString()?.split('_')
    let arrs = []
    keys?.forEach(element => {
      let newStr = DICTIONARIES[element]
      if (!newStr || props?.noTranslate) {
        newStr = element
      }
      arrs.push(newStr)
    });

    return props?.noTranslate? arrs.join(" ") : arrs.reverse().join(" ")
  }

  const renderObject = (object) => {
    return (
      <Box>
        {_.map(object, (obj, key) => {
          if (!obj) return null
          if (Array.isArray(obj)) {
            return (
              <Box key={key} sx={{
                display: 'flex',
                flexWrap: 'auto',
                flexDirection: 'row',
                gap: 0.75,
                width: '100%'
              }}>
                <Typography key={key} sx={{
                  fontSize: 14,
                  textTransform: 'capitalize',
                  color: "#777680",
                  minWidth: '50%',
                  width: '50%'
                }}>
                  - {buildKeyToString(key)}:
                </Typography>
                <Box>
                  {renderObject(obj)}
                </Box>
              </Box>
            )
          }
          if (typeof obj === 'object') {
            return (
              <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: "column",
                gap: 1.25
              }}>
                {typeof key !== "number" && <Typography key={key}
                  sx={{
                    fontSize: 14,
                    textTransform: 'uppercase',
                    color: "#777680",
                    fontWeight: "medium"
                  }}>
                  + {buildKeyToString(key)}: {" "}
                </Typography>}
                <Box sx={{ pl: '12px' }}>
                  {renderObject(obj)}
                </Box>
                <Divider sx={{ m: '8px 0' }} />
              </Box>
            )
          }
          return (
            <Box key={key} sx={{
              display: 'flex',
              flexWrap: 'auto',
              flexDirection: 'row',
              gap: 0.75,
              width: '100%'
            }}>
              <Typography key={key} sx={{
                fontSize: 14,
                textTransform: 'capitalize',
                color: "#777680",
                minWidth: 200,
                width: 200
              }}>
                - {buildKeyToString(key)}:
              </Typography>
              <Typography sx={{
                display: 'inline-flex',
                fontSize: 14,
                fontWeight: 'medium',
                width: '100%'
              }}>
                {obj}
              </Typography>
            </Box>
          )
        })}
      </Box>
    )
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogTitle>
        <Box sx={{
          flexGrow: 1,
        }}>
          <Grid container>
            <Grid item xs={4}
            >
              <Typography sx={{
                mr: 0.5,
                fontWeight: 'bold',
              }} color="text.grey">
                Thông tin chi tiết
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Box
                noValidate
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  ml: 'auto',
                  mr: 0,
                  width: 'fit-content',
                }}
              >
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel htmlFor="max-width">Tỉ lệ khung</InputLabel>
                  <Select
                    size="small"
                    autoFocus
                    value={`${maxWidth}-${gridWidth}`}
                    onChange={handleMaxWidthChange}
                    label="maxWidth"
                    inputProps={{
                      name: 'max-width',
                      id: 'max-width',
                    }}
                  >
                    <MenuItem value="xs-4">xs</MenuItem>
                    <MenuItem value="sm-3">sm</MenuItem>
                    <MenuItem value="md-2">md</MenuItem>
                    <MenuItem value="lg-2">lg</MenuItem>
                    <MenuItem value="xl-2">xl</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Box>

      </DialogTitle>
      <DialogContent>

        <DialogContent>
          {typeof props?.metadata === 'object' ? props?.metadata && renderObject(props?.metadata) : 
          <>
            <Typography sx={{
              fontSize: 14
            }}>
              {props?.metadata}
            </Typography>
          </>
          }
          {/* {convertMetadata(props.metadata, gridWidth)} */}
        </DialogContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Đóng</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogShowJsonData;
