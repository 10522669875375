import { NotificationImportantOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

const InvoiceNotification = ({ commissions }) => {
  return (
    <Box
      sx={{
        bgcolor: "#ffcd385F",
        borderRadius: "8px",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: 1,
        p: 2,
      }}
    >
      <NotificationImportantOutlined color="primary" fontSize="small" />
      <Typography
        component="div"
        sx={{
          fontSize: 14,
          m: "auto 0",
        }}
      >
        Vui lòng thanh toán hoá đơn đúng ngày thanh toán (trong tháng hiện tại hoặc trước ngày 1 mỗi tháng), các hoá đơn phát sinh thao tác thanh toán sau ngày 1 mỗi tháng không thể thực hiện thanh toán cho tháng trước đó để phục vụ cho việc tính hoa hồng.
      </Typography>
    </Box>
  );
};

export default InvoiceNotification;
