import ServiceAddonApi from '@/apis/beta/Service/ServiceAddonApi'
import DatePicker from '@/components/DatePicker'
import { useNotificationContext } from "@/contexts/notification"
import { sleep } from '@/functions'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, TextField, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import MuiCurrency from '@/components/shared/CurrencyInputCustomize'

const AddonRenew = ({
    service,
    data,
    callbackService,
    handleClose
}) => {
    const { setNotification } = useNotificationContext();
    const { control, handleSubmit } = useForm({
        defaultValues: {
            service_addon_name: data?.service_addon_name,
            service_addon_startdate: data?.service_addon_startdate ? moment(data?.service_addon_startdate) : moment(),
            service_addon_duedate: data?.service_addon_duedate ? moment(data?.service_addon_duedate) : moment(),
            service_addon_amount: data?.service_addon_amount
        }
    })
    const [submit, setSubmit] = React.useState(false)

    const handleServiceAddonRenew = async (data) => {
        setSubmit(true)
        const response = await ServiceAddonApi.Renew({ serviceId: service?.service_id, serviceAddonId: data?.service_addon_id })
        await sleep(500)
        setSubmit(false)
        if (!response) {
            return setNotification({
                open: true,
                message: 'Gia hạn dịch vụ addon thất bại.',
                severity: 'error'
            })
        }
        handleClose()
        callbackService()
        return setNotification({
            open: true,
            message: 'Gia hạn dịch vụ addon thành công.',
            severity: 'success'
        })

    }
    return (
        <Dialog open={true} fullWidth>
            <DialogTitle>
                Gia Hạn Sản Phẩm
            </DialogTitle>
            <DialogContent>
                <Box component={"form"}>
                    <Typography sx={{ mb: 0.5 }}>Sản Phẩm/ Dịch Vụ Chính</Typography>
                    <TextField
                        disabled
                        value={service?.name}
                        size="small"
                        sx={{ bgcolor: "#E3E1EC" }}
                        fullWidth
                    />
                    <Divider sx={{ m: '20px 0' }} />
                    <Typography sx={{ mb: 0.5 }}>Sản phẩm/ Dịch Vụ Mua Thêm*</Typography>
                    <Controller
                        name="service_addon_name"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                disabled
                                size='small'
                                placeholder="Tên sản phẩm/ dịch vụ mua thêm..."
                                sx={{ mb: 2, bgcolor: "#E3E1EC" }}
                                fullWidth
                            />
                        } />
                    <Box sx={{ display: 'flex', mb: 2 }}>
                        <Box sx={{ width: '50%' }}>
                            <Typography sx={{ mb: 0.5 }}>Ngày Bắt Đầu</Typography>
                            <Controller
                                name="service_addon_startdate"
                                control={control}
                                render={({ field: { value, onChange } }) =>
                                    <FormControl disabled fullWidth>
                                        <DatePicker onChange={onChange} value={value} />
                                    </FormControl>
                                } />
                        </Box>
                        <Box sx={{ ml: 2, width: '50%' }}>
                            <Typography sx={{ mb: 0.5 }}>Ngày Kết Thúc</Typography>
                            <Controller
                                name="service_addon_duedate"
                                control={control}
                                render={({ field: { value, onChange } }) =>
                                    <FormControl disabled fullWidth >
                                        <DatePicker onChange={onChange} value={value} />
                                    </FormControl>
                                } />
                        </Box>
                    </Box>
                    <Typography sx={{ mb: 0.5 }}>Phí Duy Trì *</Typography>
                    <Controller
                        name="service_addon_amount"
                        control={control}
                        render={({ field: { value, onChange } }) =>
                            <FormControl fullWidth>
                                <MuiCurrency value={value} onChange={onChange} />
                            </FormControl>
                        } />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant='text' sx={{ height: 36 }}
                    size="small"
                    disabled={submit}
                    onClick={handleClose}
                >Huỷ Thao Tác</Button>
                <Button variant='contained' sx={{ height: 36 }}
                    disabled={submit}
                    size="small"
                    onClick={handleSubmit(handleServiceAddonRenew)}
                    startIcon={submit ? <CircularProgress size={14} sx={{ color: "#777680" }} /> : undefined}
                >Gia Hạn Dịch Vụ</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddonRenew