import { format_date_short } from "@/functions"
import { DAYOFF_TIME_OPTIONS } from "@/libs/constants"
import { RemoveRedEyeOutlined } from '@mui/icons-material'
import { Box, Paper, Popper, Typography } from '@mui/material'
import React from 'react'

const DayoffInformation = ({ times }) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    return (
        <Box sx={{ display: 'flex' }}>
            {format_date_short(times[0]?.startdayoff)} - {DAYOFF_TIME_OPTIONS?.find(opt =>
                opt?.value === parseInt(times[0]?.dayoff_option)
            )?.label || "Không xác định"}
            {times?.length > 1 &&
                <RemoveRedEyeOutlined fontSize='small' color='primary' sx={{ ml: 1 }}
                    onClick={(event) => setAnchorEl(event?.currentTarget)}
                />
            }
            {anchorEl && <Popper anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                sx={{ inset: "8px auto auto -80px !important" }}
                onMouseLeave={() => setAnchorEl(null)}
            >
                <Paper sx={{ p: 2 }} elevation={10}>
                    {times?.map((item, index) =>
                        <Typography
                            key={index}
                            sx={{ fontSize: 14, mb: 1 }}>{format_date_short(item?.startdayoff)} -
                            {DAYOFF_TIME_OPTIONS?.find(opt =>
                                opt?.value === parseInt(item?.dayoff_option)
                            )?.label || "Không xác định"}
                        </Typography>
                    )}
                </Paper>
            </Popper>}
        </Box>
    )
}

export default DayoffInformation