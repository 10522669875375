import WarehouseBoxApi from '@/apis/beta/Warehouse/WarehouseBoxApi';
import withSuspense from '@/hocs/withSuspense';
import useDebounce from '@/hooks/useDebounce';
import MuiSkeleton from '@components/shared/MuiSkeleton';
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize';
import { FormControl } from '@mui/material';
import { Fragment, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const IDC = ({ index }) => {
    const { control, watch, setValue } = useFormContext()

    const [searchQuery, setSearchQuery] = useState('')
    const debouncedSearchQuery = useDebounce(searchQuery, 400);

    const { data: { warehouse_boxes: boxes }, isFetching } = WarehouseBoxApi.index({
        page: 1,
        pageSize: 50,
        type: 1,
        searchQuery: debouncedSearchQuery
    })
    return (
        <Fragment>
            <Controller
                control={control}
                name={`idc.[${index}].id`}
                render={({ field: { value, onChange } }) => {
                    const selected = boxes?.find(box => box?.warehouse_box_id === value) || null
                    return (
                        <FormControl fullWidth>
                            <SingleSelectCustomize
                                loading={isFetching}
                                options={boxes}
                                value={selected}
                                compareAttributes={['warehouse_box_name']}
                                formatCollection={{
                                    value: 'warehouse_box_id',
                                    label: 'warehouse_box_name'
                                }}
                                input={{
                                    label: 'IDC',
                                    placeholder: 'Chọn IDC...',
                                    value: debouncedSearchQuery,
                                    onChange: (event) => setSearchQuery(event?.target?.value)
                                }}
                                onChange={(event, newValue) => {
                                    setValue(`idc.[${index}].name`, newValue?.warehouse_box_name || '')
                                    onChange(newValue?.warehouse_box_id || -1)
                                }}
                                mode="server"
                            />
                        </FormControl>)
                }}
            />
            <Controller
                control={control}
                name={`idc.[${index}].rack_id`}
                render={({ field: { value, onChange } }) => {
                    const options = boxes?.find(box => box?.warehouse_box_id === parseInt(watch(`idc.[${index}].id`)))?.warehouse_racks || []
                    const selected = options?.find(rack => rack?.warehouse_rack_id === value) || null
                    return (
                        <FormControl fullWidth>
                            <SingleSelectCustomize
                                options={options}
                                value={selected}
                                disabled={parseInt(watch(`idc.[${index}].id`)) < 1}
                                compareAttributes={['warehouse_rack_name']}
                                input={{
                                    label: 'Rack',
                                    placeholder: 'Chọn Rack...',
                                }}
                                formatCollection={{
                                    value: 'warehouse_rack_id',
                                    label: 'warehouse_rack_name'
                                }}
                                onChange={(event, newValue) => {
                                    setValue(`idc.[${index}].rack_name`, newValue?.warehouse_rack_name || '')
                                    onChange(newValue?.warehouse_rack_id || -1)
                                }}
                            />
                        </FormControl>)
                }}
            />
        </Fragment>

    )
}

export default withSuspense(IDC, MuiSkeleton["TextField"])
