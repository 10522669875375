import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { format_date_monent_js } from "@/functions";
import DataGridCustomize from "@components/DataGrid";
import DialogShowJsonData from "@components/DialogShowJsonData";
import { Box, Typography } from "@mui/material";
import Link from '@mui/material/Link';
import { Fragment, useState } from "react";

export const SERVICE_UPDATE_TYPES = [
  {
    value: "SERVICE-CREATE-INVOICE-RENEW",
    label: "Tạo Hoá Đơn Gia Hạn Dịch Vụ",
  },
  { value: "SERVICE-UPDATE-PRICING", label: "Cập Nhật Giá Dịch Vụ" },
  { value: "SERVICE-CHANGE-PRICE", label: "Thay Đổi Giá & Gói" },
  { value: "SERVICE-CREATE", label: "Tạo Mới Dịch Vụ" },
  { value: "SERVICE-CANCELLED", label: "Huỷ Dịch Vụ" },
  { value: "SERVICE-REGISTER", label: "Đăng Ký Dịch Vụ" },
  { value: "ADDON-CREATED", label: "Tạo Dịch Vụ Addon" },
  {
    value: "SERVICE-CREATE-INVOICE-RENEW",
    label: "Tạo Hoá Đơn Gia Hạn Dịch Vụ",
  },
  {
    value: "SERVICE-UPDATE-INFORMATION",
    label: "Cập Nhật Thông Tin Cấu Hình",
  },
  {
    value: "SERVICE-BILLING-UPDATE",
    label: "Cập Nhật Chu Kỳ Thanh Toán",
  },
  { value: "SERVICE-CREATE-NOTE", label: "Thêm Ghi Chú" },
  { value: "SERVICE-ENABLED", label: "Kích Hoạt Dịch Vụ" },
  { value: "SERVICE-METADATA-CREATE", label: "Khởi Tạo Dữ Liệu Dịch Vụ - METADATA" },
  { value: "SERVICE-METADATA-UPDATE", label: "Cập Nhật Dữ Liệu Dịch Vụ - METADATA" },
  { value: "SERVICE-CHANGE-PRICE-REGISTER", label: "Thay Đổi Giá Bán Mới Dịch Vụ" },
  { value: "SERVICE-CHANGE-PRICE-RECURRING", label: "Thay Đổi Giá Gia Hạn Dịch Vụ" },
  { value: "SERVICE-CHANGE-TIME-STARTDATE", label: "Thay Đổi Ngày Bắt Đầu Dịch Vụ" },
  { value: "SERVICE-CHANGE-TIME-CURRENT", label: "Thay Đổi Thời Gian Thanh Toán Hiện Tại" },
  { value: "SERVICE-CHANGE-TIME-FIRSTBILL", label: "Thay Đổi Thời Gian Thanh Toán Đầu Tiên" },
  { value: "SERVICE-CHANGE-TIME-NEXTBILL", label: "Thay Đổi Thời Gian Gia Hạn" },
  { value: "SERVICE-CHANGE-TIME-STATEMENT", label: "Thay Đổi Thời Gian Đối Soát" },
  { value: "SERVICE-CHANGE-TIME-OFFSET", label: "Đặt Lại Thời Gian Sinh Trước Hoá Đơn Tự Động" },
  { value: "SERVICE-CHANGE-COMMISSION", label: "Thay Đổi Người Hưởng Hoa Hồng" },
  { value: "SERVICE-CHANGE-STATEMENT", label: "Thay Đổi Thời Gian Đối Soát" },
];
// 
const ServiceUpdates = ({ serviceId }) => {
  const { data } = ServiceApi.Logger({ serviceId });

  const [dialogInfor, setDialogInfor] = useState({
    open: false,
    data: {},
    title: "Thông tin chi tiết"
  })

  const [columns] = useState([
    {
      field: "id",
      headerName: ".No",
      width: 40,
    },
    {
      field: "service_update_type",
      headerName: "Thao Tác",
      width: 230,
      valueGetter: ({ value }) =>
        SERVICE_UPDATE_TYPES.find((x) => x.value === value)?.label,
    },
    {
      field: "service_update_description",
      headerName: "Mô Tả",
      width: 300,
    },
    {
      field: "service_update_created_at",
      headerName: "TG. Thực Hiện",
      width: 150,
      valueGetter: ({ value }) =>
        format_date_monent_js(value, "DD/MM/YYYY HH:mm"),
    },
    {
      field: "employee_name",
      headerName: "Người Thực Hiện",
      width: 180,
    },
    {
      field: "metadata",
      headerName: "Thông Tin Chi Tiết",
      width: 300,
      renderCell: ({ value }) => {
        return (
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              alignItems: "center",
            }}
            onClick={() => setDialogInfor({ open: true, data: value })}
          >
            <Link
              sx={{
                textDecoration: "underline",
              }}
              color="primary"
            >
              Xem chi tiết
            </Link>
          </Box>
        );
      },
    },
  ]);
  const onClose = () => {
    setDialogInfor((prev) => ({ ...prev, open: false, data: {} }));
  }

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          mt: 2,
          mb: 1,
          alignItems: "center",
        }}
      >
        <DialogShowJsonData
          open={dialogInfor.open}
          onClose={onClose}
          title={dialogInfor.title}
          metadata={dialogInfor.data}
        />
        <Typography sx={{ fontWeight: "medium" }}>
          Thông Tin/ Lịch Sử Thao Tác
        </Typography>
      </Box>
      <DataGridCustomize
        initialState={{
          pinnedColumns: { right: ["actions"] },
        }}
        rows={(data || [])
          ?.filter(
            (x) => x?.service_update_type !== "SERVICE-SWIFTFEDERATION-LOGS"
          )
          ?.map((item, index) => ({ ...item, id: index + 1 }))}
        columns={columns}
        components={{
          Toolbar: "disable",
          Pagination: "disabled",
        }}
      />
    </Fragment>
  );
};

export default ServiceUpdates;
