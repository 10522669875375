import CustomerServiceCreditApi from "@/apis/beta/Customer/CustomerServiceCreditApi";
import DataGrid from "@/components/DataGrid";
import CellWithScrollSmall, {
  CellLink,
  CellPricing,
} from "@/components/DataGrid/Cell";
import { format_date_monent_js } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import { CREDIT_TYPES } from "@/libs/constants";
import PopperAction from "@components/PopperAction";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { MoreVertOutlined } from "@mui/icons-material";
import { Box, IconButton, Popper, Typography } from "@mui/material";
import React from "react";
import CreditStatistical from "./Statistical";
import Toolbar from "./Toolbar";
import Update from "./Update";
import DataGridFilter from "@components/DataGrid/Filter";
import { useLocation } from "react-router-dom";
import useFilters, { Filter } from "@/hooks/useFilters";

const Credit = ({ customerId }) => {
  const location = useLocation();
  const [query, updateQuery] = useFilters("customer.credits", location);

  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const filterableCustomerCredit = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
    const acceptFilters = [
      {
        name: "customer_service_credits.customer_service_credit_id",
        condition: "$eq",
        value: parseInt(debouncedSearchQuery),
        disabled: !parseInt(debouncedSearchQuery),
      },
    ];
    const queryNumber = 0;
    let filter = "";
    acceptFilters?.forEach((field, index) => {
      if (field.disabled) return;
      filter += `&filters[$or][${queryNumber + index}][${field?.name}][${
        field?.condition
      }]=${field?.value}`;
    });
    return filter;
  }, [debouncedSearchQuery]);

  const [popper, setPopper] = React.useState({
    anchorEl: undefined,
    data: undefined,
  });

  const [actions] = React.useState([
    {
      order: 1,
      name: "update",
      icon: "Edit",
      label: "Chỉnh Sửa",
      handleClick: (data) => {
        setAction((prev) => ({
          ...prev,
          open: true,
          data: data,
          name: "update",
        }));
        setPopper((prev) => ({
          ...prev,
          anchorEl: undefined,
          data: undefined,
        }));
      },
    },
  ]);

  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [columns] = React.useState([
    {
      field: "customer_service_credit_id",
      headerName: "ID Credit",
      width: 100,
      renderCell: ({ value }) => `CRE-${value}`,
    },
    {
      field: "executor",
      headerName: "Thực hiện bởi",
      width: 180,
      valueGetter: ({ row }) => row?.metadata?.executor,
    },
    {
      field: "service_referrer",
      headerName: "Dịch Vụ Áp Dụng",
      width: 250,
      valueGetter: ({ row }) => {
        return {
          label: row?.service_referrer?.service_name,
          href: ["services", row?.service_referrer?.service_id],
        };
      },
      renderCell: ({ value, row }) => {
        if (row?.credit_referrer_type === "default") {
          return <></>;
        }
        return <CellLink data={value} />;
      },
    },
    {
      field: "credit_amount",
      headerName: "Tổng Tiền",
      width: 130,
      renderCell: ({ value }) => <CellPricing price={value} />,
    },
    {
      field: "credit_created_at",
      headerName: "Ngày Tạo",
      width: 150,
      valueFormatter: ({ value }) =>
        format_date_monent_js(value, "DD/MM/YYYY HH:mm"),
    },
    {
      field: "credit_note",
      headerName: "Nội Dung",
      width: 300,
      renderCell: ({ value }) => <CellWithScrollSmall content={value} />,
    },
    {
      field: "credit_type",
      headerName: "Loại",
      width: 80,
      valueGetter: ({ row }) =>
        CREDIT_TYPES.find((t) => t.value === row?.credit_type) ||
        CREDIT_TYPES[0],
      operators: {
        type: "object",
        collections: CREDIT_TYPES,
      },
      renderCell: ({ value }) => (
        <Typography
          sx={{ fontSize: 14 }}
          color={`custom.${value?.color || "default"}`}
        >
          {value?.label}
        </Typography>
      ),
    },
    {
      field: "action",
      headerName: "",
      width: 40,
      renderCell: ({ row }) => (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <IconButton
            size="small"
            sx={{ m: "auto" }}
            title="Thao tác"
            onClick={(event) => {
              setPopper((prev) => ({
                ...prev,
                anchorEl: event?.target,
                data: row,
              }));
            }}
          >
            <MoreVertOutlined fontSize="small" />
          </IconButton>
        </Box>
      ),
    },
  ]);
  const { data, isFetching } = CustomerServiceCreditApi.List({
    customerId: customerId,
    page: page?.page + 1,
    pageSize: page?.pageSize,
    query: Filter.stringify(query),
    searchQuery: filterableCustomerCredit(),
  });

  const [action, setAction] = React.useState(false);

  const onClose = () =>
    setAction({ open: true, data: undefined, type: undefined });

  const filterables = columns
    .filter((column) => !!column?.operators && !column?.hide)
    .map((column) => {
      return {
        field: column?.key ? column.key : column.field,
        label: column?.headerName,
        operators: column?.operators,
      };
    });

  return (
    <Box sx={{ mt: 2 }}>
      {popper?.anchorEl !== undefined && (
        <Popper anchorEl={popper?.anchorEl} open={Boolean(popper.anchorEl)}>
          <PopperAction
            {...popper}
            actions={actions}
            handleClose={() =>
              setPopper((prev) => ({
                ...prev,
                anchorEl: undefined,
                data: undefined,
              }))
            }
          />
        </Popper>
      )}
      {!!action?.name && action?.name === "update" && (
        <Update customerId={customerId} data={action?.data} onClose={onClose} />
      )}

      <CreditStatistical customerId={customerId} />
      <DataGridFilter
        callback={updateQuery}
        filters={{
          key: "customer.credits",
          filterables: filterables,
        }}
      />
      <DataGrid
        rows={
          data?.credits?.map((credit) => ({
            ...credit,
            id: credit?.customer_service_credit_id,
          })) || []
        }
        columns={columns}
        loading={isFetching}
        components={{
          Toolbar: Toolbar,
        }}
        componentsProps={{
          toolbar: {
            customerId: customerId,
            searchQuery: searchQuery,
            setSearchQuery: setSearchQuery,
          },
          pagination: {
            rowsPerPageOptions: [5, 10, 20],
            page: page?.page || 0,
            pageSize: page?.pageSize || 20,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        disableSelectionOnClick
        rowCount={data?.pagination?.total}
        pinnedColumns={{
          right: ["action"],
        }}
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(Credit, {
    feature: "customer",
    action: "customer-credit-index",
    type: "Block",
  }),
  MuiSkeleton["DataGrid"]
);
