import EChart, { SCHEMES, TOOLTIP_OPTIONS, useMarkArea } from "@/components/EChart";
import { Box, FormControl, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { renderToString } from 'react-dom/server';


import { getArrayFromValues } from "@/functions";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import dayjs from "dayjs";
import { useState } from "react";
import { resolveServiceAnalyticTranscoding } from "../services";
import withSuspense from "@/hocs/withSuspense";

const MarkerSolid = renderToString(<Box sx={{ display: 'inline-block', width: 10, height: 10, bgcolor: "#2196f3", my: 'auto', mr: 1 }} />)

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan-Next Year']

const TranscodingDurations = ({ service }) => {

    const [year, setYear] = useState(parseInt(dayjs().get('year')))
    const {
        data: [transcoding],
        isLoading
    } = resolveServiceAnalyticTranscoding(service?.service_creatable_uuid, {
        gte: dayjs().set('year', year).set('month', 1).endOf('month'),
        lte: dayjs().set('year', year).set('month', 12).startOf('month')
    })

    const timestamps = MONTHS?.slice(0, transcoding?.length + 1)

    const zeroTimestamps = transcoding?.length < 12 ? MONTHS.slice(transcoding?.length, transcoding?.length + 1) : ['Jan-Next Year']

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [markAreaStart, markAreaStop] = useMarkArea(timestamps, zeroTimestamps)

    const YEAR_OPTIONS = getArrayFromValues(2023, dayjs().get('year') + 1)?.map(x => ({ value: x, label: x }))

    const options = {
        tooltip: {
            trigger: 'axis',
            ...TOOLTIP_OPTIONS,
            formatter: function (params) {
                const [current] = params
                return `${MarkerSolid}<b>${current?.name}</b> ${current?.value !== 0 ? `${current?.value} hours` : '-'}<br/>`
            }
        },
        grid: {
            top: '5%',
            left: -45,
            right: '0%',
            bottom: '0%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: MONTHS?.slice(0, transcoding?.length + 1),
            axisLine: {
                lineStyle: {
                    type: [3, 3],
                    dashOffset: 3
                }
            },
            axisLabel: {
                align: 'left',
            }
        },
        yAxis: {
            type: 'value',
            splitNumber: 2,
            splitLine: {
                show: true,
                lineStyle: {
                    color: "#e0e0e0",
                    type: [3, 3],
                    dashOffset: 3
                }
            },
            axisLabel: {
                align: 'left',
                margin: 50,
                color: 'black',
                formatter: (value) => `${value} Hours`
            }
        },
        color: [SCHEMES.mono[0], SCHEMES.primary[4], SCHEMES.secondary[0]],
        series: [
            {
                name: 'Total',
                type: 'line',
                stack: 'Total',
                data: transcoding?.map((item) => item.total),
                smooth: true,
                markArea: zeroTimestamps?.length !== 0 && {
                    data: [
                        [
                            {
                                name: 'Updating Data',
                                xAxis: markAreaStart,
                                itemStyle: {
                                    color: '#f0f0f0'
                                },
                                label: {
                                    show: true,
                                    color: '#aaa',
                                    offset: [-125, 7.5],
                                    fontSize: 16,
                                    rotate: 90
                                }
                            }, {
                                xAxis: markAreaStop
                            }
                        ]
                    ]
                }
            },
            {
                name: 'Transcode',
                type: 'line',
                stack: 'Transcode',
                data: transcoding?.map((item) => {
                    return parseFloat(item?.total) - parseFloat(item?.transmux)
                }),
                smooth: true
            }, {
                name: 'Transmux',
                type: 'line',
                stack: 'Transmux',
                data: transcoding?.map((item) => item?.transmux),
                smooth: true
            },
        ]
    };

    return (
        <>
            <Box sx={{
                borderBottom: `1px solid ${grey[300]}`,
                pb: 1,
                mt: 2,
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 1.5,
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Typography fontSize={14} fontWeight={700} data-ui={`Transcoding.Dashboard.Duration.[${service.service_creatable_uuid}]`}>
                    Transcoding Usage(Duration, Unit: Hours)
                </Typography>
                <FormControl fullWidth sx={{ maxWidth: { xs: '100%', md: 200 } }}>
                    <SingleSelectCustomize
                        options={YEAR_OPTIONS.reverse()}
                        value={YEAR_OPTIONS.find(x => x?.value === year) || undefined}
                        input={{
                            label: "Chọn năm..."
                        }}
                        onChange={(event, newValue) => {
                            setYear(newValue?.value || -1)
                        }}
                    />
                </FormControl>
            </Box>
            {!isLoading && transcoding ? (
                <Box sx={{ mt: 3 }}>
                    <EChart options={options} />
                    <Box sx={{
                        my: 2,
                        display: 'flex',
                        flexDirection: {
                            xs: 'column', md: 'row'
                        },
                        gap: { xs: 0.25, md: 3 }
                    }}>
                        <Stack direction="row" justifyContent="space-between">
                            <Stack direction="row">
                                <Box sx={{ display: 'inline-block', width: 10, height: 10, bgcolor: SCHEMES.mono[0], my: 'auto', mr: 1 }} />
                                <Typography fontSize={14} sx={{ my: 'auto' }}>Total</Typography>
                            </Stack>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Stack direction="row">
                                <Box sx={{ display: 'inline-block', width: 10, height: 10, bgcolor: SCHEMES.primary[4], my: 'auto', mr: 1 }} />
                                <Typography fontSize={14} sx={{ my: 'auto' }}>Transcode</Typography>
                            </Stack>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Stack direction="row">
                                <Box sx={{ display: 'inline-block', width: 10, height: 10, bgcolor: SCHEMES.secondary[0], my: 'auto', mr: 1 }} />
                                <Typography fontSize={14} sx={{ my: 'auto' }}>Transmux</Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </Box>
            ) : (
                <MuiSkeleton.GradientCircularProgress />
            )}
        </>
    )
}

export default withSuspense(TranscodingDurations, MuiSkeleton["LoadingDotBox"])