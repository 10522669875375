import { useMemo } from "react";
import ServiceSystemForm from "../shared/SystemForm";

const ServiceSystemUpdate = ({
  serviceId = -1,
  data = {},
  attachments = [],
}) => {
  const defaultValues = useMemo(() => {
    return {
      dns_enable: data?.dns_enable,
      dns_managements: data?.dns_managements || [],
      documentations: data?.documentations || [],
      metadata: data?.metadata,
    };
  }, [data]);

  const defaultAttachments = useMemo(() => {
    return attachments?.map((x) => {
      return {
        attachment_id: x?.attachment_id,
        name: x?.attachment_file_name,
        size: x?.attachment_file_size,
        type: x?.attachment_file_type,
        url: x?.attachment_file_url,
        attachment_closed: x?.attachment_closed,
      };
    });
  }, [attachments]);
  return (
    <ServiceSystemForm
      type="update"
      serviceId={serviceId}
      defaultAttachments={defaultAttachments}
      defaultValues={defaultValues}
    />
  );
};

export default ServiceSystemUpdate;
