import Create from "./Create";
import Destroy from './Destroy';
import List from './List';
import Detail from './Detail';
import Update from './Update';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    List,
    Create,
    Destroy,
    Update,
    Detail
};
