import { Box, Typography } from '@mui/material'
import React from 'react'

const POC = () => {
    return (
        <>
            <Box sx={{ display: 'flex', width: '100%', height: { xs: '100%', md: 200 }, justifyContent: 'center', pt: 2 }}>
                <img alt="logo" width="auto" src="/images/original-service-poc.png" />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 0.75 }}>
                <Typography sx={{ fontSize: 14, fontWeight: 'medium' }} color="primary">
                    Đây là dịch vụ POC.
                </Typography>
            </Box>
        </>
    )
}

export default POC