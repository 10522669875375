import NumericFormatCustom from "@components/NumericFormatCustom";
import { DeleteOutlineOutlined, TimelineOutlined } from "@mui/icons-material";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, createElement, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import AddonSelect from "./AddonSelect";
import AddonStep from "./AddonStep";
import Amount from "./Amount";
import Pricing from "./Price/Pricing";
import PricingApply from "./Price/PricingApply";
import PricingMinimum from "./Price/PricingMinimum";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { PRODUCT_UNITS } from "@/libs/constants";

const PRICES = [
  {
    name: "pricing",
    label: "Đơn giá niêm yết *",
    disabled: true,
  },
  {
    name: "pricing_minimum",
    label: "Đơn giá min *",
    disabled: true,
  },
];

const ICONS = {
  Visibility: VisibilityOutlined,
  VisibilityOff: VisibilityOffOutlined,
};

const ProductAddon = ({ productAddons = [] }) => {
  const [state, setState] = useState(false);
  const { control, watch, setValue } = useFormContext();

  const {
    fields: addons,
    append,
    remove,
  } = useFieldArray({
    control: control,
    name: "product_addons",
  });

  const [hideable, setHideable] = useState(false);

  return (
    <Fragment>
      {state && (
        <AddonSelect
          append={append}
          selecteds={addons?.map((x) => x?.product_id) || []}
          addons={productAddons}
          onClose={() => setState(false)}
        />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            gap: 0.5,
          }}
        >
          <IconButton
            size="small"
            sx={{ height: 24, width: 24, m: "auto 0" }}
            onClick={() => setHideable(!hideable)}
          >
            {createElement(
              ICONS[hideable ? "VisibilityOff" : "Visibility"],
              {
                fontSize: "small",
                color: "primary",
                sx: {},
              },
              null
            )}
          </IconButton>
          <Typography
            sx={{ fontSize: 14, mb: 0.75, mt: 1.25, fontWeight: "medium" }}
            color="text.black"
          >
            Thiết Lập Cấu Hình *
          </Typography>
        </Box>
        <Typography
          onClick={() => setState(true)}
          sx={{
            fontSize: 14,
            m: "auto 0",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          color="primary"
        >
          + More [ADDON]
        </Typography>
      </Box>
      <Collapse in={!hideable}>
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: 1.5, mt: 1.5 }}
        >
          {addons?.map((addon, index) => {
            return (
              <Box
                key={addon?.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      md: "row",
                    },
                    gap: 1.5,
                  }}
                >
                  <Controller
                    control={control}
                    name={`product_addons.[${index}].qty`}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <FormControl
                          fullWidth
                          sx={{
                            maxWidth: {
                              xs: "100%",
                              md: "calc((((100% - 12px * 2) / 3) * 2) / 2)",
                            },
                            minWidth: {
                              xs: "100%",
                              md: "calc((((100% - 12px * 2) / 3) * 2) / 2)",
                            },
                          }}
                        >
                          <TextField
                            disabled={parseInt(value) === 0}
                            size="small"
                            label={
                              addon?.product_name +
                              "   " +
                              "(Nhập số lượng mua) *"
                            }
                            InputLabelProps={{
                              shrink: true,
                              sx: { color: "#00001F", fontWeight: "medium" },
                            }}
                            value={value}
                            onChange={onChange}
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                            placeholder="Nhập số lượng mua..."
                          />
                        </FormControl>
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name={`product_addons.[${index}].description`}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <FormControl
                          fullWidth
                          sx={{
                            maxWidth: {
                              xs: "100%",
                              md: "calc((((100% - 12px * 2) / 3) * 2) / 2)",
                            },
                            minWidth: {
                              xs: "100%",
                              md: "calc((((100% - 12px * 2) / 3) * 2) / 2)",
                            },
                          }}
                        >
                          <TextField
                            size="small"
                            label="Mô tả sản phẩm"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={value}
                            onChange={onChange}
                            placeholder="Nhập mô tả sản phẩm..."
                          />
                        </FormControl>
                      );
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <Controller
                      control={control}
                      name={`product_addons.[${index}].unit`}
                      render={({ field: { value } }) => {
                        const selected =
                          PRODUCT_UNITS?.find((x) => x?.value === value) ||
                          null;
                        return (
                          <FormControl fullWidth>
                            <SingleSelectCustomize
                              value={selected}
                              options={PRODUCT_UNITS}
                              disabled
                              input={{
                                placeholder: "Chọn đơn vị tính",
                                label: "Đơn vị tính",
                              }}
                            />
                          </FormControl>
                        );
                      }}
                    />
                    {!!addon?.statemented && (
                      <FormControl fullWidth>
                        <FormControlLabel
                          label="Đối soát"
                          control={
                            <Checkbox
                              size="small"
                              defaultChecked={false}
                              checked={parseInt(addon?.qty) === 0}
                              onClick={() => {
                                if (parseInt(addon?.qty) === 0) {
                                  return setValue(
                                    `product_addons.[${index}].qty`,
                                    1
                                  );
                                }
                                setValue(`product_addons.[${index}].qty`, 0);
                              }}
                            />
                          }
                        />
                      </FormControl>
                    )}
                    <IconButton
                      size="small"
                      onClick={() => remove(index)}
                      sx={{ m: "auto 0" }}
                    >
                      <DeleteOutlineOutlined fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  aria-labelledby="box-addon-price"
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: 1.5,
                  }}
                >
                  {!watch(`product_template`)?.match(/(Liquidation)/) &&
                    PRICES?.map((price, i) => {
                      return (
                        <Controller
                          key={i}
                          name={`product_addons.[${index}].${price?.name}`}
                          control={control}
                          render={({ field: { value, onChange } }) => {
                            return (
                              <FormControl fullWidth>
                                <TextField
                                  {...price}
                                  InputLabelProps={{
                                    shrink: true,
                                    sx: { textTransform: "capitalize" },
                                  }}
                                  InputProps={{
                                    inputComponent: NumericFormatCustom,
                                  }}
                                  placeholder="Nhập đơn giá..."
                                  value={value}
                                  onChange={onChange}
                                  size="small"
                                />
                              </FormControl>
                            );
                          }}
                        />
                      );
                    })}
                  {!watch(`product_addons.[${index}].price_range_enabled`) && (
                    <Collapse
                      sx={{
                        minWidth: {
                          xs: "100%",
                          md: "calc((100% - 12px * 2) / 3)",
                        },
                      }}
                      in={
                        !watch(`product_addons.[${index}].price_range_enabled`)
                      }
                    >
                      <Controller
                        name={`product_addons.[${index}].pricing_apply`}
                        control={control}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <FormControl fullWidth>
                              <TextField
                                label="Đơn Giá Bán *"
                                InputLabelProps={{
                                  shrink: true,
                                  sx: { textTransform: "capitalize" },
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                }}
                                placeholder="Nhập đơn giá..."
                                value={value}
                                onChange={onChange}
                                size="small"
                              />
                            </FormControl>
                          );
                        }}
                      />
                    </Collapse>
                  )}
                </Box>
                {Boolean(
                  watch(`product_addons.[${index}].product_addon_range_enabled`)
                ) && (
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Box
                        component="span"
                        sx={{ display: "flex" }}
                        onClick={() => {
                          setValue(
                            `product_addons.[${index}].price_range_enabled`,
                            !watch(
                              `product_addons.[${index}].price_range_enabled`
                            )
                          );
                        }}
                      >
                        <TimelineOutlined
                          fontSize="small"
                          color="primary"
                          sx={{ m: "auto 2px auto 0" }}
                        />
                        <Typography sx={{ fontSize: 13 }} color="primary">
                          Cấu hình giá khác [
                          {!watch(`product_addons.[${index}].price_range_enabled`)
                            ? "Theo Range"
                            : "Bình Thường"}
                          ]
                        </Typography>
                      </Box>
                    </Box>
                  )}
                <Collapse
                  in={!!watch(`product_addons.[${index}].price_range_enabled`)}
                >
                  <AddonStep position={index} />
                </Collapse>
                <Divider sx={{ m: "0 0" }} light />
              </Box>
            );
          })}
        </Box>
      </Collapse>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 1.5,
          mt: 1.5,
        }}
      >
        <Pricing />
        <PricingMinimum />
        <PricingApply />
      </Box>
      <Amount />
      {!watch(`product_template`)?.match(/(Customized|Liquidation)/) && (
        <Controller
          name="product_addon_mergebill"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl component="span" sx={{ mt: 1.5 }}>
                <FormControlLabel
                  label={
                    <Typography sx={{ fontSize: 14 }} color="text.black">
                      Không tách bill dịch vụ addon *
                      <Typography
                        sx={{ fontSize: 14, display: "inline-flex" }}
                        color="text.grey"
                      >
                        Khi chọn option này, hoá đơn dịch vụ sẽ chỉ có dòng hoá
                        đơn của dịch vụ chính, bỏ qua các dòng hoá đơn của dịch
                        vụ addon, tiền dịch vụ addon sẽ được tính vào dịch vụ
                        chính.
                      </Typography>
                    </Typography>
                  }
                  control={
                    <Checkbox
                      sx={{ mt: 0 }}
                      size="small"
                      checked={value}
                      defaultChecked={false}
                      onChange={onChange}
                    />
                  }
                />
              </FormControl>
            );
          }}
        />
      )}
    </Fragment>
  );
};

export default ProductAddon;
