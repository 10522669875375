import { getAttachmentIcon } from '@/functions'
import { ATTACHMENT_ICONS } from "@/libs/constants"
import DragDropFile from '@components/shared/DragDropFile'
import { DeleteOutlined } from '@mui/icons-material'
import { Box, Collapse, IconButton, Typography } from '@mui/material'
import React from 'react'

const WContractDocument = ({
    setAttachment,
    attachment = null
}) => {

    const fileHandler = (file) => {
        const extension = file.name.split(".")[1]?.toLowerCase()
        if (extension === undefined) return
        return setAttachment(file)
    }
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 0.5, md: 2 },
            mt: 2
        }}>
            <Box sx={{ display: 'flex', minWidth: { xs: '100%', md: 300 } }}>
                <Typography sx={{
                    m: '8px auto auto 0',
                    color: "#777680"
                }}>
                    File Hợp Đồng
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                gap: 2
            }}>
                <DragDropFile handler={fileHandler} />
                <Collapse in={!!attachment}>
                    <Box sx={{
                        display: 'flex',
                        gap: 1,
                        height: 42,
                    }}>
                        {React.createElement(ATTACHMENT_ICONS[getAttachmentIcon(attachment?.type) || "TXT"], {
                            color: "primary",
                            sx: {
                                height: 42, width: 42
                            }
                        })}
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{
                                fontSize: 14,
                                justifyContent: 'left',
                                m: 'auto 0',
                                color: '#777680',
                                flexWrap: 'wrap',
                            }}>
                                {attachment?.name}
                            </Typography>
                            <Typography sx={{ fontSize: 12 }}>
                                {attachment?.size > 1024000
                                    ? `${Math.round(attachment?.size / 1024000)} Mb`
                                    : `${Math.round(attachment?.size / 1000)} Kb`}
                            </Typography>
                        </Box>
                        <IconButton size='small' sx={{ height: 28, width: 28, m: 'auto 0' }}
                            onClick={() => setAttachment(null)}
                        >
                            <DeleteOutlined fontSize='small' sx={{ ":hover": { color: "#ef5350" } }} />
                        </IconButton>
                    </Box>
                </Collapse>
            </Box>
        </Box>
    )
}

export default WContractDocument