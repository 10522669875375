import React from 'react';
// import { NotificationChannel } from '../channels/consumer';
import UserApi from '@/apis/UserApi';

const EmployeeContext = React.createContext();

export const EmployeeProvider = ({ children }) => {
  const [employee] = UserApi.Current();
  // const cable = NotificationChannel(
  //   `/notification?token=${employee?.employee_uuid}`
  // );

  return (
    <EmployeeContext.Provider value={{ employee }}>
      {children}
    </EmployeeContext.Provider>
  );
};

export const useEmployeeContext = () => {
  return React.useContext(EmployeeContext);
};
