import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import React from 'react'
import { resolveMlyticDNSRecords } from '../services'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { CellStatus } from '@components/DataGrid/Cell'

const MlyticDNSRecords = ({ service }) => {

    const { data: [dnsRecords], isLoading, isFetching } = resolveMlyticDNSRecords(service?.service_creatable_uuid)

    return (
        <Box>
            <Box sx={{
                mt: 1.5,
                mb: 1,
                display: 'flex',
                justifyContent: 'space-between', alignItems: 'center'
            }}>
                <Typography sx={{ fontSize: 14, fontWeight: 'medium' }}>
                    DNS (No of Records): {dnsRecords?.length}
                </Typography>
            </Box>
            <TableContainer>
                <Table size='small' sx={{
                    border: "1px solid #333",
                    bgcolor: "#7776801F"
                }} aria-label="lms.no_of_live_jobs">
                    <TableHead sx={{ bgcolor: "#FFF" }}>
                        <TableRow>
                            <TableCell align="left">. No</TableCell>
                            <TableCell>Domain</TableCell>
                            <TableCell align="right">cName</TableCell>
                            <TableCell align="right">Enable</TableCell>
                        </TableRow>
                    </TableHead>
                    {(!isLoading && !isFetching && !!dnsRecords) ? <TableBody>
                        {dnsRecords?.map((domain, index) => (
                            <TableRow
                                key={index}
                            >
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell component="th" scope="row">
                                    {domain?.domain}
                                </TableCell>
                                <TableCell align="right">
                                    {domain?.cname}
                                </TableCell>
                                <TableCell align="right">
                                    <CellStatus
                                        component='muiChip'
                                        data={{
                                            label: !!domain?.enable ? "Đang Kích Hoạt" : "Off",
                                            muiChip: { variant: !!domain?.enable ? "customize-primary" : 'customize-pending' },
                                        }}
                                    />
                                    {Object.values(domain)[0]?.h265MaxConcurrentNum || ''}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody> : <MuiSkeleton.LoadingDotBox />}
                </Table>
            </TableContainer>
        </Box>
    )
}

export default withSuspense(MlyticDNSRecords, MuiSkeleton["LoadingDotBox"])