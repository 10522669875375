import ProductApi from "@/apis/beta/Product/ProductApi";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { FormControl } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const ServiceDecrease = ({ service }) => {
  const { data: product } = ProductApi.Detail({
    id: service?.service_product_id,
  });
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const onChangeProduct = (productId) => {
    const addon = product?.product_addons?.find(
      (x) => x?.product_addon_id === productId
    );
    setValue("service_product_id", productId);
    setValue("service_product_name", addon?.product_name);
    setValue("service_name", addon?.product_name);
    setValue("service_addon_qty", 1);
  };
  return (
    <Controller
      name="service_product_id"
      control={control}
      render={({ field: { value, onChange } }) => {
        const selected =
          product?.product_addons?.find(
            (item) => item?.product_addon_id === value
          ) || null;
        return (
          <FormControl fullWidth sx={{ mb: 1.5 }}>
            <SingleSelectCustomize
              value={selected}
              options={product?.product_addons || []}
              input={{
                label: "Chọn cấu hình giảm *",
                placeholder: "Chọn cấu hình...",
              }}
              compareAttributes={["product_addon_id", "product_name"]}
              formatCollection={{
                value: "product_addon_id",
                label: "product_name",
              }}
              onChange={(event, newValue) => {
                onChangeProduct(newValue?.product_addon_id || -1);
                return onChange(newValue?.product_addon_id || -1);
              }}
            />
            {!!errors?.service_name && (
              <ErrorHelperText message={errors?.service_name?.message} />
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default ServiceDecrease;
