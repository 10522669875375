import axios from "axios";
import { Buffer } from "buffer";
import moment from "moment";
import * as XLSX from "xlsx";

import { AUTH_URL, UNLIMITED } from "@/libs/constants";
import dayjs from "dayjs";
import _ from "lodash";
import FileApi from "./apis/shared/File/FileApi";

const queryString = require("query-string");
const numeral = require("numeral");

const queryStringFormats = {
  skipNull: true,
  arrayFormat: "bracket",
  skipEmptyString: true,
};

export const queryStringParse = (queries) =>
  queryString.parse(queries, queryStringFormats);

export const requestSimple = async (
  url,
  method = "GET",
  data = {},
  headers = {},
  options = {}
) => {
  return await axios({
    method: method,
    url: url,
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      ...headers,
    },
    ...options,
    data: data,
  });
};

const refreshToken = async () => {
  return await requestSimple(
    `${AUTH_URL}/auth/v2/refresh_token`,
    "GET",
    {},
    {},
    {
      withCredentials: true,
    }
  )
    .then(({ data }) => {
      if (data.errors) return undefined;

      const { access_token, exp } = data.data;

      localStorage.setItem("SA", access_token);
      localStorage.setItem("EXP", exp);

      return access_token;
    })
    .catch((error) => {
      console.log("refresh token failed with error: ", error);
    });
};

export const requestWithToken = async (
  url,
  method = "GET",
  data = {},
  headers = {},
  options = {}
) => {
  const accessTokenIsExpired =
    Date.now() / 1000 > Number(localStorage.getItem("EXP") || 0);
  const accessToken = accessTokenIsExpired
    ? await refreshToken()
    : localStorage.getItem("SA");

  if (typeof accessToken === "undefined" || accessToken === null) {
    localStorage.removeItem("SA");
    localStorage.removeItem("EXP");
    localStorage.removeItem("UUID");
    window.location.href = "/auth/login";
  }

  return requestSimple(
    url,
    method,
    data,
    {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    },
    options
  );
};

// deprecated

export const truncate = (string = "", length = 100) => {
  if (string?.length > length) {
    return string.substring(0, length) + "...";
  }

  return string;
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const format_date_short = (value) => {
  return moment(value).format("DD/MM/YYYY");
};
export const format_date_time = (value, rejectSecond = false) => {
  if (rejectSecond) return moment(value).format("DD/MM/YYYY HH:mm");
  return moment(value).format("DD/MM/YYYY HH:mm:ss");
};

export const encodeBase64 = (string = "") => {
  if (!string) return null;
  return Buffer.from(string).toString("base64");
};

export const decodeBase64 = (string = "") => {
  return Buffer.from(string, "base64").toString("ascii");
};

export const format_numeral_price = (number, isFloat = false) => {
  if (!number || number === undefined) return 0;
  return numeral(number).format(isFloat ? "0,0.99" : "0,0");
};

export const format_date_with_time = (value) => {
  return moment(value).format("DD-MM-YYYY HH:mm:ss");
};

// deprecated

export const get_type_file = (type) => {
  const word = [
    ".doc",
    ".docx",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const pdf = ["application/pdf"];
  const csv = [
    ".csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];
  const pptx = [
    ".pps",
    ".jpg",
    ".txt",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  if (word?.includes(type)) {
    return { bgcolor: "#039BE5", name: "W" };
  }
  if (pdf?.includes(type)) {
    return { bgcolor: "#f57c00", name: "PDF" };
  }
  if (csv?.includes(type)) {
    return { bgcolor: "#43a047", name: "CSV" };
  }
  if (pptx?.includes(type)) {
    return { bgcolor: "#EF5350", name: "PP" };
  }
  return { bgcolor: "#777680", name: "F" };
};

export const handleRedirectOpenLink = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const handleScrollSmoothOffsetTop = (ref) => {
  if (!ref) return;
  window.scrollTo({
    top: ref?.offsetTop - 50,
    behavior: "smooth",
  });
};
export const calculatorAmountFormObject = (objects, field) => {
  let total = 0;
  _.forEach(objects, (value, key) => {
    total += parseFloat(value[field]);
  });
  return total;
};

export const getMuiDataGridRowHeight = (
  data = {},
  options = [],
  elementPerRow = 1
) => {
  const configures = _.pick(data, options);
  const totalConfig = _.filter(
    configures,
    (config) => parseInt(config) !== 0
  )?.length;
  const height = Math.round(totalConfig / elementPerRow) * 32 + 16;
  return height < 52 ? 52 : height;
};

export const getAttachmentIcon = (fileType) => {
  const FILE_TYPES = [
    {
      value: "TXT",
      type: [
        ".doc",
        ".docx",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ],
    },
    {
      value: "PDF",
      type: ["application/pdf"],
    },
    {
      value: "CSV",
      type: [
        ".csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ],
    },
    {
      value: "IMAGE",
      type: [
        "image/png",
        "image/apng",
        "image/avif",
        "image/gif",
        "image/jpeg",
        "image/svg+xml",
        "image/webp",
      ],
    },
    {
      value: "VIDEO",
      type: ["video/mp4", "audio/webm", "video/webm"],
    },
    {
      value: "PPTX",
      type: [
        ".pps",
        ".jpg",
        ".txt",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      ],
    },
  ];
  return (
    FILE_TYPES.find((type) => type.type?.includes(fileType))?.value ||
    FILE_TYPES[0].value
  );
};

export const buildQueryFilterCondition = (
  condition = "$or",
  startQueryIndex = 0,
  fieldQueries = []
) => {
  if (!fieldQueries?.length) return null;
  let fiterables = "";
  fieldQueries?.forEach((field, index) => {
    if (field?.disabled) return;
    fiterables += `&filters[${condition}][${startQueryIndex + index}][${field?.name
      }][${field?.operator}]=${field?.value}`;
  });
  return fiterables;
};

export const csvMaker = (data = []) => {
  let csvRows = [];
  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(","));

  data.forEach((row, index) => {
    const values = Object.values(row).join(",");
    csvRows.push(values);
  });
  return csvRows.join(`\n`);
};

export const csvDownload = (data) => {
  const blob = new Blob([data], { type: "text/csv" });

  const url = URL.createObjectURL(blob);
  try {
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", "download.csv");
    a.click();
  } catch (error) {
    console.log(error.message);
  }
};

export const isObjEmpty = (obj = {}) => {
  return Object.values(obj).length === 0 && obj.constructor === Object;
};

export const dayjsCompare = (v1, v2, cod = "d") => {
  const date1 = dayjs(dayjs(v1).format("YYYY-MM-DD"));
  const date2 = dayjs(dayjs(v2).format("YYYY-MM-DD"));

  return date1.diff(date2, cod);
};

export const handlerProgressive = (v = 0, pricings = []) => {
  let amount = 0;

  pricings
    ?.filter((item) => item?.start < v)
    ?.forEach((item) => {
      if (item.end <= v) {
        amount += (item?.end - item?.start) * item?.pricing_apply;
      } else {
        amount += (v - item?.start) * item?.pricing_apply;
      }
    });
  return Math.round(amount * 100) / 100;
};

export const comaprisonMultiValue = (
  prevValue = 0,
  compareValue = 0,
  condition = "$gt"
) => {
  switch (condition) {
    case "$gt":
      return prevValue > compareValue;
    case "$lt":
      return prevValue < compareValue;
    case "$eq":
      return prevValue === compareValue;
    case "$gte":
      return prevValue >= compareValue;
    case "$lte":
      return prevValue <= compareValue;
    case "$ne":
      return prevValue !== compareValue;
    default:
      return false;
  }
};

// deprecated

export const getPricingConfigoption = ({
  method = "default",
  value,
  pricingApply = 0,
  options = [],
}) => {
  switch (method) {
    case "default":
      return Math.round(value * pricingApply * 100) / 100;
    case "tierd":
      const idx = options?.findIndex((t) => t.start <= value && t.end >= value);
      if (idx === -1) return 0;
      return Math.round(value * options[idx]?.pricing_apply * 100) / 100;
    case "progressive":
      return handlerProgressive(value, options);
    default:
      return 0;
  }
};

export const customize_truncate = (string = "", length) => {
  const chars = new RegExp("[a-zA-Z0-9]");
  if (string?.length > length) {
    if (chars.test(string[length])) {
      // eslint-disable-next-line no-useless-escape
      let arrs = string.slice(0, length)?.split(" ");
      string = arrs.slice(0, arrs.length - 1)?.join(" ") + "...";
    }
  }
  return string;
};

export const removeObjectKeys = (obj = {}, keys = []) => {
  let results = {};
  for (const key in obj) {
    if (!keys.includes(key)) results[key] = obj[key];
  }
  return results;
};

// deprecated

export const handlerCopyToClipboard = (txt, setNotification) => {
  try {
    navigator.clipboard.writeText(txt).then(
      () => {
        setNotification({
          open: true,
          message: "Copy to clipboard is successful!",
          severity: "success",
        });
      },
      (err) => {
        setNotification({
          open: true,
          message: `Copy to clipboard is failed! Err [${err?.message}]`,
          severity: "error",
        });
      }
    );
  } catch (error) {
    console.log(error?.message);
  }
};

export const reformatterPercentage = (num = 0) => {
  return (Math.round(num * 10000) / 100).toFixed(2);
};

export function format_bytes(bytes, radix = 1000, expectedOutput = "GB") {
  if (bytes === 0) return "0 Bytes";
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const range = sizes.findIndex((x) => x === expectedOutput);

  return parseFloat(Math.round(bytes / Math.pow(radix, range)));
}

export const reformatterBandwidth = (num = 0, unit = "B") => {
  // unit = ["B", "Bps"]
  let tierd = 0;

  if (num >= 1000) {
    num = Math.round((num / 1000) * 100) / 100;
    tierd = 1; // kb
  }
  if (num >= 1000) {
    num = Math.round((num / 1000) * 100) / 100;
    tierd = 2; // Mb
  }
  if (num >= 1000) {
    num = Math.round((num / 1000) * 100) / 100;
    tierd = 3; // Gb
  }
  if (num >= 1000) {
    num = Math.round((num / 1000) * 100) / 100;
    tierd = 4; // Tb
  }
  if (num >= 1000) {
    num = Math.round((num / 1000) * 100) / 100;
    tierd = 5; // Pb
  }
  let units = {};
  switch (tierd) {
    case 0:
      units = { b: "B", bps: "Bps" };
      break;
    case 1:
      units = { b: "Kb", bps: "Kbps" };
      break;
    case 2:
      units = { b: "Mb", bps: "Mbps" };
      break;
    case 3:
      units = { b: "Gb", bps: "Gbps" };
      break;
    case 4:
      units = { b: "Tb", bps: "Tbps" };
      break;
    case 5:
      units = { b: "Pb", bps: "Pbps" };
      break;
    default:
      break;
  }
  return num?.toFixed(2) + ` ${units[`${unit}`]}`;
};

// deprecated

export const modifyText = (txt) => {
  const metadata = {
    create: "Tạo",
    update: "Cập Nhật",
  };
  return metadata[txt] || "? Undefined ?";
};

export const performPreviousAddStep = (number = 0) => {
  let end = 0;
  switch (parseInt(number)) {
    case 0:
      end = 5000;
      break;
    case 5000:
      end = 10000;
      break;
    case 10000:
      end = 50000;
      break;
    case 50000:
      end = 100000;
      break;
    case 100000:
      end = 200000;
      break;
    case 200000:
      end = 300000;
      break;
    case 300000:
      end = 400000;
      break;
    case 400000:
      end = 500000;
      break;
    case 500000:
      end = 800000;
      break;
    case 800000:
      end = 1000000;
      break;
    case 1000000:
      end = 999999999;
      break;
    case 2:
      end = 5;
      break;
    case 10:
      end = 25;
      break;
    case 25:
      end = 50;
      break;
    case 50:
      end = 100;
      break;
    case 100:
      end = 999999999;
      break;
    case 80:
      end = 200;
      break;
    case 200:
      end = 999999999;
      break;
    default:
      break;
  }
  return end;
};

export const selectObjectKeys = (obj = {}, keys = []) => {
  let results = {};
  for (const key in obj) {
    if (keys.includes(key)) results[key] = obj[key];
  }
  return results;
};

export const convertCsvToExcelBuffer = (csvString = "") => {
  const arrayOfArrayCsv = csvString.split("\n").map((row) => {
    return row.split(",");
  });
  const wb = XLSX.utils.book_new();
  const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
  XLSX.utils.book_append_sheet(wb, newWs);
  const rawExcel = XLSX.write(wb, { type: "base64" });
  return rawExcel;
};

export const convertCsvToExcel = async (fileBuffer, res) => {
  const csvFileTxt = fileBuffer.toString();
  const excelBuffer = convertCsvToExcelBuffer(csvFileTxt);
  res.setHeader(
    "Content-Type",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  );
  res.status(200).send(Buffer.from(excelBuffer, "base64"));
};

export const activeTab = ({ collections = [], feature = "", action = "" }) => {
  const available =
    collections?.find(
      (collection) =>
        collection?.feature === feature && collection?.action === action
    ) || {};
  return !isObjEmpty(available);
};

export const GMTtoLocaleTime = (date) => {
  return moment(date).add(-7, "hours");
};
export const format_gmt_datetime = (date, format = "DD/MM/YYYY HH:MM") => {
  return GMTtoLocaleTime(date)?.format(format);
};

export const downloadFileWithBinaryResponse = (fileName, data) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const arrayRange = (start, stop, step) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (_, index) => start + index * step
  );

// params e: EventInput, onChange: React Hook Form controller onChange
export const formatCurrency = (e, onChange) => {
  const numberRegex = /^\d+$/;
  const value = e?.target?.value?.split(".").join("");

  if (!value?.trim()) return onChange("0");

  if (!numberRegex.test(value)) {
    return;
  }

  const valueFormat = new Intl.NumberFormat("vi-VN", {
    currency: "VND",
  }).format(Number(value));

  onChange(valueFormat);
};

export const formatNumber = (num) => {
  return new Intl.NumberFormat("vi-VN", {
    currency: "VND",
  }).format(Number(num));
};

export function downloadBase64File(contentBase64, fileName) {
  const linkSource = `${contentBase64}`;
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);

  downloadLink.href = linkSource;
  downloadLink.target = "_self";
  downloadLink.download = fileName;
  downloadLink.click();

  downloadLink.remove();
}

export const format_date_monent_js = (date, scope = "DD/MM/YYYY") => {
  return moment(date).format(scope);
};
export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 GB";

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
// deprecated

export const queryClientRefetcher = (provider, keys) => {
  if (typeof keys === "string") {
    provider.refetchQueries([`${keys}`]);
  } else {
    keys?.forEach((key) => {
      try {
        provider.refetchQueries([`${key}`]);
      } catch (error) {
      }
    });
  }
};

export const getRacks = (devices, warehouse_racks) => {
  const racks = [];
  devices.forEach((item) => {
    if (item["warehouse_box_item_reference_type"] === "WarehouseBox") {
      const rack = _.find(warehouse_racks, {
        warehouse_box_id: item["warehouse_box_item_reference_id"],
      });
      if (rack) {
        racks.push({
          label: `${rack.warehouse_box_name}`,
          value: "",
          unit: "",
        });
      }
    } else {
      const rack = _.find(
        warehouse_racks,
        (o) =>
          o["warehouse_rack_id"] === item["warehouse_box_item_reference_id"]
      );
      if (rack) {
        racks.push({
          label: `${rack.warehouse_rack_name} - ${rack.warehouse_box_name}`,
          value: "",
          unit: "",
        });
      }
    }
  });
  return _.uniqBy(racks, (o) => o.label);
};

export const getDataGridRowHeight = (array, capacity) => {
  let max = _.maxBy(array, (o) => o.length);
  const configures = _.pick(capacity, [
    "capacity_cpu",
    "capacity_ram",
    "capacity_storage",
    "capacity_power_supply",
  ]);
  const totalConfig = _.filter(
    configures,
    (config) => parseInt(config) !== 0
  )?.length;

  max = totalConfig > max.length ? totalConfig : max.length;
  return max * 25;
};

export const convertMonthToBillingCycle = (month = 0) => {
  switch (month) {
    case 0:
      return "";
    case 1:
      return "monthly";
    case 2:
      return "bimonthly";
    case 3:
      return "quarterly";
    case 4:
      return "fourmonth";
    case 5:
      return "fivemonth";
    case 6:
      return "semiannually";
    case 7:
      return "sepmonth";
    case 8:
      return "octmonth";
    case 9:
      return "novmonth";
    case 10:
      return "decmonth";
    case 11:
      return "elevenmonth";
    case 12:
      return "annually";
    case 24:
      return "biennially";
    case 36:
      return "triennially";
    default:
      return "";
  }
};

export function extractBlobImageFromMarkdown(markdownText) {
  const regex = /!\[.*?\]\((blob:[^)]+)\)/g;
  const matches = markdownText.matchAll(regex);
  const files = [];

  for (const match of matches) {
    const blobUrl = match[1];
    files.push(blobUrl);
  }

  return files;
}

export async function blobToFile(blobUrl, fileName) {
  try {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    const file = new File([blob], `${fileName}.${blob.type.split("/")[1]}`, {
      type: blob.type,
    });
    return file;
  } catch (error) {
    console.error("Error converting blob to file:", error);
  }
}

export const onAttachmentPushing = async (file, folder, setNotification) => {
  const token = await FileApi.GetPresignedTokenAttachment({
    filename: {
      name: `${Date.now()}-${file?.name}`,
      folder,
    },
  });

  if (!token || token?.status !== 200) {
    return setNotification({
      open: true,
      message: "Upload hợp đồng thất bại.",
      severity: "error",
    });
  }

  const uploaded = await FileApi.UploadAttachmentToStorage({
    token: token?.token,
    file: file,
    handleUploadFile: () => { },
  });

  if (!uploaded) {
    return setNotification({
      open: true,
      message: "Tải dữ liệu thất bại",
      severity: "error",
    });
  }

  return (
    {
      attachment_file_name: file?.name,
      attachment_file_url: [folder, token?.filename].join("/"),
      attachment_file_type: file?.type,
      attachment_file_size: file?.size,
    } || {}
  );
};
export const queryClientRefetchScope = (provider, scope) => {
  let keys = [];
  switch (scope) {
    case "service":
      keys = [
        "services.list",
        "services.statement.list",
        "services.statistic",
        "services.detail",
        "service.informations",
        "services.configoptions",
        "service.pricing",
        "services.addons.list",
        "service.service-mailer",
        "service.logger",
        "service.service-contract-referrer",
        "service.usages",
        "service.billing",
        "service.invoices",
        "service.notes",
        "service.subscription.list",
        "service.system-informations",
        "service.mailers",
        "service.configoption_prices"
      ];
      break;
    case "employee":
      keys = [
        'employee.list',
        'employees.all',
        'employees.preview-all',
        'employees.detail'
      ]
      break;
    case "scope":
      keys = [
        'scopes.all',
        'scopes'
      ]
      break;
    case "ticket":
      keys = [
        'tickets',
        'tickets.detail',
      ]
      break;
    case "department":
      keys = [
        'departments.all',
        'departments'
      ]
      break;
    case "customer":
      keys = [
        'customers.contacts',
        'customers.list',
        'customers.detail',
        'customer.customer_updates',
        'company.company_updates'
      ]
      break;
    // eslint-disable-next-line no-fallthrough
    default: return
  }
  if (typeof keys === "string") {
    provider.refetchQueries([`${keys}`]);
  } else {
    keys?.forEach((key) => {
      try {
        provider.refetchQueries([`${key}`]);
      } catch (error) {
      }
    });
  }
};
// deprecated
export async function generateToken(resource, secretKey) {
  const enc = new TextEncoder();
  const key = await crypto.subtle.importKey(
    "raw",
    enc.encode(secretKey),
    { name: "HMAC", hash: "SHA-1" },
    false,
    ["sign"]
  );

  const signature = await crypto.subtle.sign("HMAC", key, enc.encode(resource));

  const hashArray = Array.from(new Uint8Array(signature));

  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");

  return "0" + hashHex.slice(0, 20);
}

export function buildTime() {
  const stime = new Date();
  const etime = new Date(stime.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days in milliseconds

  const formatDate = (date) => {
    const pad = (num) => num.toString().padStart(2, "0");

    return (
      date.getUTCFullYear().toString() +
      pad(date.getUTCMonth() + 1) +
      pad(date.getUTCDate()) +
      pad(date.getUTCHours()) +
      pad(date.getUTCMinutes()) +
      pad(date.getUTCSeconds())
    );
  };

  return {
    stime: formatDate(stime),
    etime: formatDate(etime),
  };
}
export const format_number_usage = (number, digits = 0) => {
  if (!number || number === undefined) return 0;
  if (number >= UNLIMITED) return `UNLIMITED`
  return (Math.round(number * 100) / 100).toFixed(digits === 0 ? 0 : digits || 2);
};


export function resolveMaxOfArray(array = []) {
  return _.max(array) || 0
}

export function formatBandwidthUnit(value, digit = 2) {
  const unit = ["bps", "Kbps", "Mbps", "Gbps", "Tbps", "Pbps", "Ebps", "Zbps", "Ybps", "Bbps", "Nbps", "Dbps", "Cbps", "Xbps"];

  let i = 0;
  while (value > 1000) {
    value = value / 1000;
    ++i;
  }

  return (Math.round(value * 100) / 100).toFixed(digit || 2) + " " + unit[i];
}

export function formatVolumeUnit(value, multiply = 1000, digit = 2) {
  const unit = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB", "BB", "NB", "DB", "CB", "XB"];
  let i = 0;

  while (value >= multiply) {
    value = value / multiply;
    ++i;
  }

  return (Math.round(value * 100) / 100).toFixed(digit) + " " + unit[i];
}


export const getArrayFromValues = (min = 0, max = 10) => {
  return _.range(min, max)
}


export function formatRequestUnit(value, digit = 2) {
  const unit = ["", "K", "Mil", "Bil"];

  let i = 0;
  while (value > 1000) {
    value = value / 1000;
    ++i;
  }

  return (Math.round(value * 100) / 100).toFixed(digit) + "" + unit[i];
}

export const containsSpecialCharacter = (str) => {
  const regex = /[!@#$%^&*(),.?":;{}|<>]/;
  return regex.test(str);
};

export const makeFirstLetterUppercase = (text = '') => {
  return text?.charAt(0)?.toUpperCase() + text?.slice(1)?.toLowerCase();
}
export const getRangeDates = (start, end) => {
  const startdate = dayjs(start)
  const enddate = dayjs(end)
  const months = enddate.diff(startdate, 'months')
  const dates = enddate.diff(startdate.add(months, 'months'), 'days')
  return [months, dates]
}

export const payloadToQueries = (payload = {}) => {
  let queries = []
  _.forEach(payload, (value, key) => {
    if (typeof value !== 'object') {
      queries.push(`${key}=${value}`)
    }
  })
  return queries?.join('&')
}

export const pagyResponse = (pagination = {}) => {
  return {
    page: pagination?.page,
    pageSize: pagination?.page_size,
    rowCount: pagination?.total
  }
}

export const sortPriorities = (arrs = [], sorters = [], key = "sample") => {
  return arrs?.sort((a, b) => sorters.indexOf(a[`${key}`]) - sorters.indexOf(b[`${key}`]));
}

export const roundjs = (num = 0, digits = 2) => {
  const range = 10 ** (digits)
  return Math.round(num * range) / range
}