import { requestWithToken, encodeBase64 } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class ProductGroupApi {
  List = (query) => {
    return useQueryWithCustomized(
      ["product-groups.list", query],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/product_groups${
            query ? `?filters=${encodeBase64(query)}` : ""
          }`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.product_groups || [];
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
        enabled: !!localStorage.getItem("SA"),
      }
    );
  };

  Products = () => {
    return useQueryWithCustomized(
      ["product-groups.groups.list"],
      async () => {
        return await requestWithToken(`${API_V3_URL}/product_groups/groups`)
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.products;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
        enabled: !!localStorage.getItem("SA"),
      }
    );
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ProductGroupApi();
