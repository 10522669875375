import ContactApi from "@/apis/beta/Customer/ContactApi";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Typography } from "@mui/material";

const Explaination = ({ data }) => {
  const { data: contact } = ContactApi.Explaination({
    contactId: data?.contact_source_id,
  });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        gap: 0.5,
        justifyContent: "flex-end",
      }}
    >
      {contact?.journeys?.map((item, index) => {
        return (
          <Typography
            sx={{ fontSize: 14, textTransform: "capitalize" }}
            key={index}
          >
            {item?.customer_journey_step}
          </Typography>
        );
      })}
    </Box>
  );
};

export default withSuspense(
  Explaination,
  MuiSkeleton["GradientCircularProgress"]
);
