import { UNLIMITED } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import { Box, Collapse, FormControl, TextField, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import { Controller, useFormContext } from 'react-hook-form'

const CONFIGS = ['cpu', 'ram', 'storage', 'ip']

const MetadataVPS = () => {
    const { control, watch } = useFormContext()
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {CONFIGS?.map((config, index) => {
                return (
                    <Collapse key={index} in={watch(`product_type`) === config}>
                        <Controller
                            name={`qty.${config}`}
                            key={index}
                            control={control}
                            render={({ field: { value, onChange } }) => {
                                const isUnlimited = parseFloat(value) >= UNLIMITED;
                                return (
                                    <FormControl fullWidth sx={{ mb: 2 }}>
                                        <TextField
                                            size='small'
                                            label={config?.toUpperCase() + ' *'}
                                            InputLabelProps={{ shrink: true }}
                                            value={isUnlimited ? "UNLIMITED" : value}
                                            onChange={onChange}
                                            InputProps={{
                                                sx: {
                                                    paddingRight: 0,
                                                    paddingLeft: 0
                                                },
                                                inputComponent: isUnlimited ? "input" : NumericFormatCustom,
                                                endAdornment: <Box sx={{
                                                    display: "flex",
                                                    height: "40px",
                                                    width: isUnlimited ? "calc(100%)" : "calc(100% / 2)",
                                                    transition: "width 0.75s ease-in-out",
                                                    bgcolor: isUnlimited ? "#2e7d321F" : "rgba(0, 0, 0, 0.18)",
                                                    borderTopRightRadius: "4px",
                                                    borderBottomRightRadius: "4px",
                                                    cursor: "pointer",
                                                    alignItems: "center"
                                                }}
                                                    onClick={() => {
                                                        if (parseFloat(value) >= UNLIMITED) {
                                                            return;
                                                        }
                                                        onChange(UNLIMITED)
                                                    }}
                                                >
                                                    <Typography sx={{
                                                        m: 'auto 8px auto auto',
                                                        fontSize: 13,
                                                        textTransform: 'uppercase',
                                                        color: value === UNLIMITED ? green[800] : "#777680"
                                                    }}>
                                                        Unlimited
                                                    </Typography>
                                                </Box>,
                                            }}
                                            placeholder='Nhập số lượng mua...'
                                        />
                                    </FormControl>)
                            }
                            }
                        />
                    </Collapse>
                )
            })}
        </Box>
    )
}

export default MetadataVPS