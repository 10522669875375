import { Box, Skeleton } from '@mui/material'
import React from 'react'


const MultiDayoffLine = () => {
    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 0.5 }}>
                <Skeleton variant='text' sx={{ width: 100 }} />
                <Skeleton variant='circular' sx={{ width: 24, height: 24 }} />
            </Box>
            {[1, 2].map(item => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }} key={item}>
                        <Skeleton variant='text' sx={{ width: 100, height: 18 }} />
                        <Skeleton variant='text' sx={{ width: 150, height: 24 }} />
                    </Box>
                )
            })}
        </React.Fragment>
    )
}
export default MultiDayoffLine