import withPermission from '@/hocs/withPermission'
import React from 'react'
import InvoiceNoteForm from '../shared/Form'

const Create = ({ ...props }) => {
    return (
        <InvoiceNoteForm type="create" {...props} />
    )
}

export default withPermission(Create, { feature: "invoice", action: "invoice-note-create", type: "Block" })