import { vestResolver } from "@hookform/resolvers/vest";
import { Box, Button, Collapse, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import rebuildProduct from "./functions";
import ServiceTemplates from "./modules";
import Product from "./shared/Product";
import ProductGroup from "./shared/ProductGroup";
import { serviceCreateValidatorCores } from "./validators";

const PRIVAT_TEMPLATES = ["CloudService", "Mlytics"]

const ServiceForm = ({
	customerId = -1,
	orderredProductIds = [],
	type = "create",
	saver,
	closer,
	defaultValues = {},
	isPoc = false,
}) => {
	const [product, setProduct] = useState({
		product_group_id: defaultValues?.product_group_id,
		product_group_name: defaultValues?.product_group_name,
		product_id: defaultValues?.product_id,
		product_name: defaultValues?.product_name,
	});

	const methods = useForm({
		resolver: isPoc
			? undefined
			: vestResolver((props) =>
				(serviceCreateValidatorCores[props?.product_template] || serviceCreateValidatorCores["Default"])({
					...props,
					orderredProductIds: orderredProductIds,
					method: type,
				})
			),
		defaultValues: { ...defaultValues },
	});

	const onChangeProductGroup = (newProductGroup) => {
		switch (newProductGroup?.product_group_id) {
			case 15:
				methods.setValue('product_template', "Mlytics")
				break;
			case 22:
				methods.setValue('product_template', "CloudService")
				break;
			default:
				methods.setValue('product_template', "Blank")
				break;
		}
		setProduct((prev) => ({
			...prev,
			product_group_id: newProductGroup?.product_group_id,
			product_group_name: newProductGroup?.product_group_name,
		}));
	};

	const serviceTemplate = (template) => {
		const componentProps = {
			isPoc,
			customerId,
			product,
			closer,
			saver
		};
		const Component = ServiceTemplates[template] || ServiceTemplates["Blank"];
		if (!Component) return ServiceTemplates["Blank"];
		return <Component {...componentProps} />;
	};

	const onSubmit = (values) => {
		let form = rebuildProduct(values, values?.product_template);
		if (type === "update") {
			form.index = values.index;
		}
		return saver(form);
	};

	return (
		<FormProvider {...methods}>
			<Paper component="form" sx={{ p: 3 }} elevation={8}>
				<Typography
					sx={{
						fontWeight: "medium",
						textTransform: "capitalize",
						mb: 1.5,
					}}
				>
					Thêm sản phẩm/ dịch vụ
				</Typography>
				{customerId !== -1 && (
					<Box
						sx={{
							display: "flex",
							flexDirection: { xs: "column", md: "row" },
							gap: 2,
							mb: 2,
						}}
					>
						<ProductGroup
							isPoc={isPoc}
							customerId={customerId}
							disabled={type !== "create"}
							onChangeProductGroup={onChangeProductGroup}
						/>
						{!PRIVAT_TEMPLATES?.includes(methods.watch(`product_template`)) && <Product
							disabled={type !== "create"}
							product={product}
							setProduct={setProduct}
						/>}
					</Box>
				)}
				<Collapse in={!!methods.watch(`product_template`)}>
					{serviceTemplate(methods.watch(`product_template`))}
				</Collapse>
				{methods.watch(`product_template`) !== 'CloudService' && <Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						flexDirection: { xs: "column", md: "row" },
						gap: 1.5,
						mt: 3,
					}}
				>
					<Button
						size="small"
						variant="text"
						sx={{ height: 36 }}
						onClick={closer}
					>
						Huỷ Thao Tác
					</Button>
					<Button
						size="small"
						variant="outlined"
						onClick={methods.handleSubmit(onSubmit)}
						sx={{ height: 36, minWidth: 150 }}
					>
						{type === "create" ? "Thêm Sản Phẩm" : "Cập nhật"}
					</Button>
				</Box>}
			</Paper>
		</FormProvider>
	);
};

export default ServiceForm;
