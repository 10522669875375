import EmployeeApi from '@/apis/beta/Employee/EmployeeApi'
import { format_date_short, queryClientRefetchScope } from '@/functions'
import DatePicker from '@components/DatePicker'
import GradientCircularProgress from '@components/shared/MuiSkeleton/GradientLoading'
import { useNotificationContext } from '@contexts/notification'
import { Box, Button, Drawer, FormControl, Typography } from '@mui/material'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

const Workdates = ({ data, onClose }) => {
    const queryClient = useQueryClient()
    const { setNotification } = useNotificationContext()
    const [dates, setDates] = useState([data?.start_worked_date, data?.end_worked_date])

    const { mutateAsync, isLoading } = useMutation(EmployeeApi.UpdateWorkDates)

    const onSubmit = async () => {
        const response = mutateAsync({
            employeeId: data?.employee_id,
            workdates: {
                start_worked_date: format_date_short(dates[0]),
                end_worked_date: format_date_short(dates[1]),
            }
        })
        if (!response || response?.errors) {
            return setNotification({ open: true, message: response?.errors?.[0]?.message, severity: 'error' })
        }
        setNotification({ open: true, message: 'Cập nhật ngày làm việc thành công', severity: 'success' })
        queryClientRefetchScope(queryClient, 'employee')
        return onClose()
    }
    return (
        <Drawer anchor="right" open={true} sx={{
            minWidth: { xs: '100%', md: 400 },
            p: "24px 16px"
        }}>
            <Box sx={{ p: '24px 16px' }}>
                <Typography sx={{ fontWeight: 'medium', mb: 2 }}>
                    Chỉnh sửa ngày làm việc
                </Typography>
                <FormControl fullWidth>
                    <DatePicker
                        label="Ngày bắt đầu làm việc"
                        value={dates[0]}
                        onChange={(newValue) => setDates(prev => [newValue, prev[1]])}
                    />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <DatePicker
                        label="Ngày kết thúc làm việc"
                        value={dates[1]}
                        disabled={!data?.employee_disable}
                        onChange={(newValue) => setDates(prev => [prev[0], newValue])}
                    />
                </FormControl>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: 2,
                    mt: 2
                }}>
                    <Button size='small' variant='outlined' onClick={onClose} disabled={isLoading}>
                        Huỷ Thao Tác
                    </Button>
                    <Button size='small' variant='contained' onClick={onSubmit} disabled={isLoading}
                        startIcon={isLoading && <GradientCircularProgress />}
                    >
                        Cập Nhật
                    </Button>
                </Box>
            </Box>
        </Drawer>
    )
}

export default Workdates