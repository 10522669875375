import { Grid } from '@mui/material'
import React from 'react'
export const { default: StatisticalOne } = require('./StatisticalOne');

const Statistical = () => {
    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            {[1, 2, 3, 4].map((i) => <StatisticalOne key={i} />)}
        </Grid>
    )
}

export default Statistical