import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { sleep } from "@/functions";
import { useNotificationContext } from "@contexts/notification";
import ServiceDownConfigure from "@features/Module/Service/ServiceAddon/DownConfigure";
import { AddOutlined, AutorenewOutlined } from "@mui/icons-material";
import KeyboardArrowDownOutlined from "@mui/icons-material/KeyboardArrowDownOutlined";
import { Box, Button, CircularProgress, Collapse } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useQueryClient } from "react-query";
const Toolbar = ({ serviceId, handleClick, disabled = true }) => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = React.useState(false);

  const syncInformations = async () => {
    setSubmit(true);
    const response = ServiceApi.SyncServiceInformations({ serviceId });
    await sleep(500);
    setSubmit(false);
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message:
          response?.errors?.[0]?.message ||
          "Cấu hình dịch vụ đã được đồng bộ thất bại.",
        severity: "error",
      });
    }
    try {
      queryClient.refetchQueries([
        "services.addons.list",
        "services.detail",
        "services.informations",
      ]);
    } catch (error) {
      console.log(`debug.error=[refecthQueryClient].message:`, error?.message);
    }
    return setNotification({
      open: true,
      message: "Cấu hình dịch vụ đã được đồng bộ thành công.",
      severity: "success",
    });
  };

  const [down, setDown] = useState(false);

  return (
    <Fragment>
      <Collapse in={down}>
        <ServiceDownConfigure
          serviceId={serviceId}
          onClose={() => setDown(false)}
        />
      </Collapse>
      <Box
        sx={{
          minHeight: { xs: "auto", md: 76 },
          maxWidth: "100%",
          mt: { xs: 2, md: 0 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 1.5,
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          size="small"
          disable={disabled || submit || down}
          onClick={syncInformations}
          startIcon={
            submit ? (
              <CircularProgress size={14} sx={{ color: "#777680" }} />
            ) : (
              <AutorenewOutlined fontSize="small" />
            )
          }
          sx={{
            height: 36,
            maxWidth: { xs: "100%", md: 150 },
            m: "auto 16px",
          }}
        >
          Đồng Bộ Dữ Liệu
        </Button>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="outlined"
            disabled={disabled || submit || down}
            size="small"
            onClick={() => setDown(true)}
            startIcon={<KeyboardArrowDownOutlined fontSize="small" />}
            sx={{
              height: 36,
              maxWidth: { xs: "100%", md: 150 },
              m: "auto 0",
            }}
          >
            Giảm Cấu Hình
          </Button>
          <Button
            variant="contained"
            disabled={disabled || submit || down}
            size="small"
            onClick={handleClick}
            startIcon={<AddOutlined fontSize="small" />}
            sx={{
              height: 36,
              maxWidth: { xs: "100%", md: 150 },
              m: "auto 16px",
            }}
          >
            Thêm dịch vụ
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};

export default Toolbar;
