import ContractApi from '@/apis/beta/Contract/ContractApi'
import { queryClientRefetcher } from '@/functions'
import withPermission from '@/hocs/withPermission'
import ModalConfirm from '@components/shared/ModalConfirm'
import { Button } from '@mui/material'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

const RetentionContract = ({ contractId = -1, contract = {} }) => {
    const queryClient = useQueryClient()
    const [open, setOpen] = useState(false)
    const { mutateAsync } = useMutation(ContractApi.Retention)

    const onSubmit = async () => {
        const response = await mutateAsync({ contractId })
        if (!response || response?.errors) {
            return {
                status: 400,
                message: response?.errors?.[0]?.message || 'Failed when create contract.',
            }
        }
        return {
            status: 200,
            message: 'Gia hạn hợp đồng thành công.',
        }
    }
    const callbackContract = async () => {
        try {
            queryClientRefetcher(queryClient, [
                'contract.list',
                'contract.detail',
                'contract.subcontract.list'
            ])
        } catch (error) {
            console.error(`error` + error.message)
        }
    }
    return (
        <>
            {open && <ModalConfirm dialog={{
                title: 'Gia Hạn Hợp Đồng',
                content: `Dịch vụ ${contract?.service_name} của khách hàng ${contract?.customer_company_name || contract?.customer_name} sẽ được gia hạn dưới dạng phụ lục hợp đồng.`
            }}

                handleSubmit={onSubmit}
                handleClose={() => setOpen(false)}
                callback={callbackContract}
            />}
            <Button size='small' variant='contained' disabled={open} onClick={() => setOpen(true)}>
                Gia Hạn Hợp Đồng
            </Button>
        </>
    )
}

export default withPermission(RetentionContract, { feature: 'contract', action: 'customer-contract-retention', type: 'Button' })