import { Typography } from "@mui/material";
import React from "react";

const HeaderTitle = ({ children }) => {
  return (
    <Typography
      sx={{
        fontSize: { xs: 18, md: 24 },
        fontWeight: "medium",
      }}
    >
      {children}
    </Typography>
  );
};

export default HeaderTitle;
