import { Box } from '@mui/material';
import React from 'react'
import ButtonOne from '../ButtonOne';

const Buttons = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%', minWidth: 250 }}>
            {[1, 2, 3, 4, 5].map((item) => {
                return (
                    <ButtonOne key={item} />
                )
            })}
        </Box>
    )
}

export default Buttons