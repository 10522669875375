import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import WarehouseModelApi from "@/apis/beta/Warehouse/WarehouseModelApi";
import WarehouseResoureServiceApi from "@/apis/beta/Warehouse/WarehouseResoureServiceApi";
import WarehouseTypeApi from "@/apis/beta/Warehouse/WarehouseTypeApi";
import { buildQueryFilterCondition, encodeBase64 } from "@/functions";
import {
  API_WAREHOUSE_V1_URL,
  WAREHOUSE_ITEM_STOCK_STATUS,
} from "@/libs/constants";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

const Filters = ({
  updateQuery,
  useFilterModel = false,
  query,
  types_ids,
  customer = false,
  show = {
    warehouse_type: true,
    warehouse_model: true,
    warehouse_item_availability: false,
    warehouse_box: true,
    warehouse_resource_service_code: true,
    warehouse_resource_service_name: true,
  },
}) => {
  const { data: warehouse_models } = WarehouseModelApi.All({ short: true });
  const { data: warehouse_boxes } = WarehouseBoxApi.getAll();
  const { data: warehouse_resource_services } =
    WarehouseResoureServiceApi.getAll();
  const { data: warehouse_types } = WarehouseTypeApi.All();
  const [stroredTypes, setStroredTypes] = useState([])
  const { control, watch, setValue } = useForm({
    defaultValues: {
      "warehouse_types-warehouse_type_id": "",
      "warehouse_items-warehouse_model_id": "",
      "warehouse_box_items-warehouse_box_item_reference_type": "",
      "warehouse_box_items-warehouse_box_id": "",
      "warehouse_items-warehouse_item_availability": "",
      "warehouse_resource_services-warehouse_resource_service_code": "",
      "warehouse_resource_services-warehouse_resource_service_name": "",
    },
  });
  const warehouse_models_options = () => {
    if (types_ids) {
      if (!watch("warehouse_types-warehouse_type_id")) return warehouse_models?.filter((item) => {
        if (types_ids?.length === 0 && !stroredTypes?.includes(item?.warehouse_type?.warehouse_type_id)) return item
        if (types_ids?.includes(item?.warehouse_type?.warehouse_type_id)) {
          types_ids?.map((item) => {
            if (!stroredTypes?.includes(item) && item) {
              setStroredTypes((prev) => [...prev, item])
            }
          })
          return item
        }
      }
      );
    }
    if (!watch("warehouse_types-warehouse_type_id")) return warehouse_models;
    return warehouse_models?.filter(
      (item) =>
        item?.warehouse_type?.warehouse_type_id ===
        watch("warehouse_types-warehouse_type_id")
    );
  }
  useEffect(() => {
    if (!watch("warehouse_box_items-warehouse_box_id")) return;
    setValue(
      "warehouse_box_items-warehouse_box_item_reference_type",
      warehouse_boxes?.find(
        (item) =>
          item?.warehouse_box_id ===
          watch("warehouse_box_items-warehouse_box_id")
      )?.warehouse_box_type === 1
        ? "WarehouseRack"
        : "WarehouseBox"
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("warehouse_box_items-warehouse_box_id")]);

  useEffect(() => {
    const fieldQueries = Object?.keys(watch())
      ?.filter((key) => watch(key))
      ?.map((key) => ({
        name: key?.replace("-", "."),
        operator: "$eq",
        value: watch(key),
      }));

    if (fieldQueries?.length > 0) {
      updateQuery(buildQueryFilterCondition("$and", 0, fieldQueries));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watch())]);

  const generateLinkDownload = (query, customer = false, types_ids) => {
    const queryTypes = types_ids?.map((item) => `type[]=${item}`)?.join("&");
    const queryFilters = query ? `filters=${encodeBase64(query)}` : "";
    const queryCustomer = customer ? "customer=true" : "";

    return `${API_WAREHOUSE_V1_URL}/exports/warehouse_items?${[
      queryTypes,
      queryFilters,
      queryCustomer,
      "file=true",
    ]
      ?.filter((item) => Boolean(item))
      .join("&")}`;
  };
  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={2}>
        {useFilterModel && (
          <>
            {
              show?.warehouse_type && types_ids?.length > 1  && <Grid item lg={2} md={4} xs={12}>
                <Controller
                  control={control}
                  name="warehouse_types-warehouse_type_id"
                  render={({ field: { value, onChange } }) => (
                    <FormControl sx={{ flex: 1 }} fullWidth>
                      <Typography fontSize={14} fontWeight={600}>
                        Type
                      </Typography>
                      <Select value={value} size="small" fullWidth sx={{ mt: 2 }} onChange={(e) => {
                        onChange(e.target.value);
                        setValue("warehouse_items-warehouse_model_id", "");
                      }}>
                        {types_ids ? ([{label:"Bỏ chọn", value: ""}]).concat(warehouse_types?.filter((item) => {
                          if (types_ids?.length === 0 && !stroredTypes?.includes(item?.warehouse_type_id)) return item
                          if (!item?.warehouse_type_parent && types_ids?.includes(item?.warehouse_type_id)) {
                            return item
                          }
                          if (item?.warehouse_type_parent && types_ids?.includes(item?.warehouse_type_id)) {
                            return item
                          }
                        }))?.map((item, index) => (
                          <MenuItem key={index} value={item?.value}>
                            {item?.label}
                          </MenuItem>
                        )) : [{label:"Bỏ chọn", value: ""}].concat(warehouse_types)?.map((item, index) => (
                          <MenuItem key={index} value={item?.value}>
                            {item?.label}
                          </MenuItem>))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            }

            {
              show?.warehouse_model && warehouse_models_options()?.length > 1 && <Grid item lg={2} md={4} xs={12}>
                <Controller
                  control={control}
                  name="warehouse_items-warehouse_model_id"
                  render={({ field }) => (
                    <FormControl sx={{ flex: 1 }} fullWidth>
                      <Typography fontSize={14} fontWeight={600}>
                        Models
                      </Typography>
                      <Select {...field} size="small" fullWidth sx={{ mt: 2 }}>
                        {([{label:"Bỏ chọn", value: ""}].concat(warehouse_models_options()))?.map((item, index) => (
                          <MenuItem key={index} value={item?.value}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            }
          </>
        )}

        {
          show?.warehouse_item_availability && <Grid item lg={2} md={4} xs={12}>
            <Controller
              control={control}
              name="warehouse_items-warehouse_item_availability"
              render={({ field }) => (
                <FormControl sx={{ flex: 1 }} fullWidth>
                  <Typography fontSize={14} fontWeight={600}>
                    Stock status
                  </Typography>
                  <Select {...field} size="small" fullWidth sx={{ mt: 2 }}>
                    {[{label:"Bỏ chọn", value: ""}].concat(WAREHOUSE_ITEM_STOCK_STATUS)?.map((item, index) => (
                      <MenuItem key={index} value={item?.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        }

        {show?.warehouse_box &&
          <Grid item lg={2} md={4} xs={12}>
            <Controller
              control={control}
              name="warehouse_box_items-warehouse_box_id"
              render={({ field }) => (
                <FormControl sx={{ flex: 1 }} fullWidth>
                  <Typography fontSize={14} fontWeight={600}>
                    KHO / IDC
                  </Typography>
                  <Select {...field} size="small" fullWidth sx={{ mt: 2 }}>
                    {[{label:"Bỏ chọn", value: ""}].concat(warehouse_boxes)?.map((item, index) => (
                      <MenuItem key={index} value={item?.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        }

        {show?.warehouse_resource_service_code && <Grid item lg={2} md={4} xs={12}>
          <Controller
            control={control}
            name="warehouse_resource_services-warehouse_resource_service_code"
            render={({ field }) => (
              <FormControl sx={{ flex: 1 }} fullWidth>
                <Typography fontSize={14} fontWeight={600}>
                  Cụm đầu tư
                </Typography>
                <Select {...field} size="small" fullWidth sx={{ mt: 2 }}>
                  {[{warehouse_resource_service_code:"Bỏ chọn", value: ""}].concat(_.uniqBy(warehouse_resource_services,"warehouse_resource_service_code"))?.map((item, index) => (
                    <MenuItem
                      value={item?.warehouse_resource_service_code === "Bỏ chọn" ? "" : item?.warehouse_resource_service_code}
                      key={index}
                    >
                      {item?.warehouse_resource_service_code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>}

        {
          show?.warehouse_resource_service_name && (types_ids?.includes(1) || types_ids?.includes(2))  && <Grid item lg={2} md={4} xs={12}>
            <Controller
              control={control}
              name="warehouse_resource_services-warehouse_resource_service_name"
              render={({ field }) => (
                <FormControl sx={{ flex: 1 }} fullWidth>
                  <Typography fontSize={14} fontWeight={600}>
                    Dịch vụ
                  </Typography>
                  <Select {...field} size="small" fullWidth sx={{ mt: 2 }}>
                    {[{warehouse_resource_service_name:"Bỏ chọn", value: ""}].concat(_.uniqBy(warehouse_resource_services?.concat([
                      {
                        warehouse_resource_service_name: "CDN_TA",
                      },
                      {
                        warehouse_resource_service_name: "CDN_FA",
                      },
                      {
                        warehouse_resource_service_name: "CDN_DA",
                      },
                      {
                        warehouse_resource_service_name: "CDN_MT",
                      }
                    ]),"warehouse_resource_service_name"))?.map((item, index) => (
                      <MenuItem
                        value={item?.warehouse_resource_service_name === "Bỏ chọn" ? "" : item?.warehouse_resource_service_name}
                        key={index}
                      >
                        {item?.warehouse_resource_service_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        }
      </Grid>

      <a href={generateLinkDownload(query, customer, types_ids)}
        target="_blank" rel="noreferrer"
      >
        <Button variant="contained" size="small" sx={{ mt: 2 }}>
          Tải Dữ Liệu
        </Button>
      </a>
    </Paper>
  );
};

export default Filters;
