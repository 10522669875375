import CompanyApi from '@/apis/beta/Company/CompanyApi'
import { format_date_short, format_date_time } from '@/functions'
import withPermission from '@/hocs/withPermission'
import withSuspense from '@/hocs/withSuspense'
import { INDUSTRIES } from '@/libs/constants'
import BreadCrumb from '@components/shared/BreadCrumb'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { BusinessOutlined } from '@mui/icons-material'
import { Box, Chip, FormControl, Grid, IconButton, Paper, Typography } from '@mui/material'
import { indigo } from '@mui/material/colors'
import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import Buttons from './Buttons'
import Customer from './Customer'

const Show = () => {
    const { company_id } = useParams()

    const { data } = CompanyApi.Detail(company_id)

    const methods = useForm({
        defaultValues: {
            company_website: data?.company_website,
            company_industry: data?.company_industry
        }
    })
    return (
        <Box>
            <Typography sx={{
                fontSize: { xs: 18, md: 24 },
                fontWeight: "medium"
            }}>
                Thông Tin Công Ty
            </Typography>
            <BreadCrumb
                data={[
                    { label: "Danh Sách Công Ty", reactLink: "/companies" },
                    { label: "Thông Tin Công Ty", reactLink: "" },
                    { label: company_id }
                ]}
            />
            <FormProvider {...methods}>
                <Grid container spacing={2} direction={{ xs: "column-reverse", md: "row" }}>
                    <Grid item xs={12} md={9.5}>
                        <Paper sx={{
                            p: 3,
                            display: "flex",
                            flexDirection: "column",
                            gap: 1.5
                        }} component="form"
                        >
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: { xs: 'column-reverse', md: 'row' }
                            }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0.5 }}>
                                    <IconButton size="medium" sx={{ m: 'auto 0' }}>
                                        <BusinessOutlined fontSize='small' sx={{ color: indigo[900] }} />
                                    </IconButton>
                                    <Typography sx={{
                                        fontSize: { xs: 16, md: 20 },
                                        m: 'auto 0',
                                        fontWeight: "medium"
                                    }}> C - {company_id}</Typography>
                                </Box>
                                <Chip
                                    size='small'
                                    variant='outlined'
                                    label={<Typography sx={{ fontSize: 14, color: "#777680" }}>
                                        Cập nhật lần cuối: {format_date_time(data?.company_updated_at)}
                                    </Typography>}
                                />
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: { xs: "column", md: 'row' },
                                gap: { xs: 1, md: 0 }
                            }}>
                                <Box sx={{
                                    minWidth: { xs: '100%', md: 300 },
                                    display: 'flex',
                                    alignItems: "center"
                                }}>
                                    <Typography sx={{ fontSize: 14, color: "#777680" }}>
                                        Tên Công Ty
                                    </Typography>
                                </Box>
                                <Typography sx={{ m: 'auto 0' }}>
                                    {data?.company_name}
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: { xs: "column", md: 'row' },
                                gap: { xs: 1, md: 0 }
                            }}>
                                <Box sx={{
                                    minWidth: { xs: '100%', md: 300 },
                                    display: 'flex',
                                    alignItems: "center"
                                }}>
                                    <Typography sx={{ fontSize: 14, color: "#777680" }}>
                                        Mã Số Thuế
                                    </Typography>
                                </Box>
                                <Typography sx={{ m: 'auto 0' }}>
                                    {data?.company_tax}
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: { xs: "column", md: 'row' },
                                gap: { xs: 1, md: 0 }
                            }}>
                                <Box sx={{
                                    minWidth: { xs: '100%', md: 300 },
                                    display: 'flex',
                                    alignItems: "center"
                                }}>
                                    <Typography sx={{ fontSize: 14, color: "#777680" }}>
                                        Ngày Thành Lập
                                    </Typography>
                                </Box>
                                <Typography sx={{ m: 'auto 0' }}>
                                    {format_date_short(data?.company_established_date)}
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: { xs: "column", md: 'row' },
                                gap: { xs: 1, md: 0 }
                            }}>
                                <Box sx={{
                                    minWidth: { xs: '100%', md: 300 },
                                    display: 'flex',
                                    alignItems: "center"
                                }}>
                                    <Typography sx={{ fontSize: 14, color: "#777680" }}>
                                        Website
                                    </Typography>
                                </Box>
                                <Link to={`/${data?.company_website}`}
                                    replace
                                    rel="noopenner noreferrer"
                                    target="_blank"
                                >
                                    <Typography sx={{
                                        m: 'auto 0',
                                        color: indigo[900],
                                        textDecoration: "underline"
                                    }}>
                                        {data?.company_website}
                                    </Typography>
                                </Link>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: { xs: "column", md: 'row' },
                                gap: { xs: 1, md: 0 }
                            }}>
                                <Box sx={{
                                    minWidth: { xs: '100%', md: 300 },
                                    display: 'flex',
                                    alignItems: "center"
                                }}>
                                    <Typography sx={{ fontSize: 14, color: "#777680" }}>
                                        Lĩnh Vực
                                    </Typography>
                                </Box>
                                <Controller
                                    name="company_industry"
                                    control={methods.control}
                                    render={({ field: { value, onChange } }) => {

                                        const selected = INDUSTRIES.find(x => x.value === value)
                                        return (
                                            <FormControl fullWidth>
                                                <SingleSelectCustomize
                                                    value={selected}
                                                    options={INDUSTRIES}
                                                    input={{
                                                        placeholder: "Chọn lĩnh vực kinh doanh..."
                                                    }}
                                                    onChange={(event, newValue) => onChange(newValue?.value || "")}
                                                />
                                            </FormControl>
                                        )
                                    }}
                                />
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: { xs: "column", md: 'row' },
                                gap: { xs: 1, md: 0 }
                            }}>
                                <Box sx={{
                                    minWidth: { xs: '100%', md: 300 },
                                    display: 'flex',
                                    alignItems: "center"
                                }}>
                                    <Typography sx={{ fontSize: 14, color: "#777680" }}>
                                        Địa Chỉ
                                    </Typography>
                                </Box>
                                <Typography sx={{ m: 'auto 0' }}>
                                    {data?.company_address}
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={2.5}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: "column",
                            gap: 1.5
                        }}>
                            <Buttons.Update companyId={company_id} />
                        </Box>
                    </Grid>
                </Grid>
            </FormProvider>
            <Customer companyId={company_id} />
        </Box>
    )
}

export default
    withSuspense(withPermission(Show, {
        feature: "company",
        action: "show"
    }), MuiSkeleton["Paper"])