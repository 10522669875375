import { format_bytes } from '@/functions'
import DataGridCustomize from '@components/DataGrid'
import { contractDownload } from '@features/Module/Contract/shared/initialActions'
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined'
import { Box, IconButton, Typography } from '@mui/material'
import { useState } from 'react'

const ContractAttachment = ({ attachments = [] }) => {
    const [columns] = useState([
        { field: 'id', headerName: ". No", width: 60 },
        {
            field: 'attachment_file_name',
            headerName: "Tên HĐ",
            width: 350,
            renderCell: ({ value, row }) => {
                const link = !row?.attachment_file_url ? '#' : `${process.env.REACT_APP_DOMAIN_FILE
                    }${row?.attachment_file_url.replace("pub/", "")}`
                return (
                    <Typography component="span"
                        onClick={() => handleOpenView({
                            link, contractId: row?.attachment_reference_id
                        })}
                        sx={{ fontSize: 14 }} color="primary">
                        {value}
                    </Typography>)
            }
        },
        {
            field: 'attachment_file_size',
            headerName: "Kích Thước",
            width: 100,
            renderCell: ({ value }) => format_bytes(value, 1024, 'KB') + 'Kb'
        },
        {
            field: 'attachment_closed',
            headerName: "Trạng Thái",
            width: 100,
            renderCell: ({ value }) => !value ? "" : "Đã Đóng"
        },
        {
            field: 'action',
            headerName: "",
            width: 60,
            renderCell: ({ row }) => {
                const link = !row?.attachment_file_url ? '#' : `${process.env.REACT_APP_DOMAIN_FILE
                    }${row?.attachment_file_url.replace("pub/", "")}`
                return <IconButton size='small'
                    onClick={() => contractDownload({ contractLink: link, contractFilename: row?.attachment_file_name })}>
                    <FileDownloadOutlined fontSize='small' color="primary" />
                </IconButton>
            }
        },
    ])
    const handleOpenView = ({ link = '', contractId }) => {
        try {
            window.open(
                link,
                contractId,
                "height=720, width=1080, left=100, top=100, resizable=yes, scrollbars=yes, toolbar=yes, menubar=no, location=no, directories=no, status=yes"
            );
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <Box sx={{ mt: 2, mb: 1.5 }}>
            <Typography sx={{
                fontWeight: 'medium',
                fontSize: {
                    xs: 16,
                    md: 18
                },
                mb: 0.5,
            }} color="">Tài Liệu HĐ</Typography>
            <DataGridCustomize
                components={{
                    Toolbar: 'disabled',
                    Pagination: 'disabled'
                }}
                columns={columns}
                rows={attachments?.map((attachment, index) => ({ ...attachment, id: index + 1 }))}
            />
        </Box>
    )
}

export default ContractAttachment