import React from "react";
import ResolveProblemItem from "./ResolveProblemItem";
import _ from "lodash";
const StepResolveProblem = ({
  type = "contracts",
  contract_processes = [],
  employees,
  callback,
}) => {
  const [processes, setProcesses] = React.useState(contract_processes);
  React.useEffect(() => {
    setProcesses(contract_processes);
  }, [contract_processes]);
  return (
    <React.Fragment>
      {_.orderBy(processes, (o) => o.contract_process_id)?.map(
        (contractProcess, index) => (
          <ResolveProblemItem
            key={index}
            contractProcess={contractProcess}
            employees={employees}
            contractProcessType={type}
            callback={callback}
          />
        )
      )}
    </React.Fragment>
  );
};

export default StepResolveProblem;
