import ColocationCreateView from "@components/Templates/Colocation/CreateView";
import VPSCreate from "@components/Templates/VPS(CloudServer)/CreateView";
import CloudServicek8s from "../../ClusterService(k8s)/CreateView";
import VnisUI from "../../Vnis(Mlytics)/CreateView";
import Blank from "./Blank";
import DeviceLiquidation from "./DeviceLiquidation";
import { Domain } from "./Domain";
import EGPlatform from "./EGPlatform";
import EmailServer from "./EmailServer";
import { Swiftfederation } from "./SwiftFederation";

const ServiceTemplates = {
  Blank: Blank,
  "SwiftFederation.ContentDeliveryNetwork":
    Swiftfederation.ContentDeliveryNetwork,
  "SwiftFederation.Trans":
    Swiftfederation.Transcoding,
  "SwiftFederation.StorageLFD":
    Swiftfederation.StorageLFD,
  "SwiftFederation.Default": Swiftfederation.Default,
  "Domain.PaVietnam": Domain.PaVietnam,
  "Domain.ResellerClub": Domain.ResellerClub,
  Colocation: ColocationCreateView,
  CloudServer: VPSCreate,
  CloudService: CloudServicek8s,
  VnisService: VnisUI,
  VPS: VPSCreate,
  VNIS: VnisUI,
  EmailServer: EmailServer,
  EGPlatform: EGPlatform,
  DedicatedServer: DeviceLiquidation,
  BareMetalServer: DeviceLiquidation,
  DeviceLiquidation: DeviceLiquidation,
  "Other.DeviceLiquidation": DeviceLiquidation,
  SSLService: EmailServer,
  Mlytics: VnisUI
};

export default ServiceTemplates;
