import EChart, { SCHEMES, TOOLTIP_OPTIONS, useMarkArea } from "@/components/EChart";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import dayjs from "dayjs";
import { useState } from "react";
import { resolveServiceAnalyticLMSTranscodingDurations } from "../services";
import DurationRanges from "../shared/DurationRanges";

const TranscodingDurations = ({ service }) => {

    const today = dayjs()
    const [durationRanges, setDurationRanges] = useState({
        range: 30,
        label: 'Last 30 Days',
        gte: today?.subtract(31, 'day').format('DD/MM/YYYY'),
        lte: today?.format('DD/MM/YYYY'),
    })
    const {
        data: [durations],
        isLoading,
        isFetching
    } = resolveServiceAnalyticLMSTranscodingDurations(service?.service_creatable_uuid, {
        ...durationRanges
    })

    const timestamps = durations?.map((item) => dayjs(item.spot_time_str)?.format("YYYY-MM-DD"));
    const zeroTimestamps = durations?.filter((item) => parseFloat(item?.total) === 0)?.map((item) => dayjs(item.spot_time_str)?.format("YYYY-MM-DD"));

    const [markAreaStart, markAreaStop] = useMarkArea(timestamps, zeroTimestamps)

    const options = {
        tooltip: {
            trigger: 'axis',
            ...TOOLTIP_OPTIONS,
        },
        grid: {
            top: '5%',
            left: -45,
            right: '0%',
            bottom: '0%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: timestamps,
            axisLine: {
                lineStyle: {
                    type: [3, 3],
                    dashOffset: 3
                }
            },
            axisLabel: {
                align: 'left',
                formatter: (value) => dayjs(value)?.format("DD MMM")
            }
        },
        yAxis: {
            type: 'value',
            splitNumber: 2,
            splitLine: {
                show: true,
                lineStyle: {
                    color: "#e0e0e0",
                    type: [3, 3],
                    dashOffset: 3
                }
            },
            axisLabel: {
                align: 'left',
                margin: 50,
                color: 'black',
                formatter: (value) => value?.toString() + ' Hours'
            }
        },
        color: [SCHEMES.primary[0], SCHEMES.primary[0]],
        series: [
            {
                data: durations?.map((item) => item.total),
                type: 'line',
                animation: false,
                showSymbol: false,
                markArea: zeroTimestamps?.length !== 0 && {
                    data: [
                        [
                            {
                                name: 'Updating Data',
                                xAxis: markAreaStart,
                                itemStyle: {
                                    color: '#f0f0f0'
                                },
                                label: {
                                    show: true,
                                    color: '#aaa',
                                    offset: [-125, 7.5],
                                    fontSize: 16,
                                    rotate: 90
                                }
                            }, {
                                xAxis: markAreaStop
                            }
                        ]
                    ]
                }
            },
        ]
    };

    return (
        <>
            <Box sx={{ borderBottom: `1px solid ${grey[300]}`, pb: 1, mt: 2 }}>
                <Typography fontSize={14}
                    fontWeight={700} data-ui={`LMS.Analytics.Transoding.Durations.[${service.service_uuid}]`}>
                    Transcoding Usage(Duration, Unit:Hours)
                </Typography>
                <DurationRanges durationRanges={durationRanges} setDurationRanges={setDurationRanges} />
            </Box>
            {(!isLoading && !isFetching && durations) ? (
                <Box sx={{ mt: 3 }}>
                    <EChart options={options} />
                </Box>
            ) : (
                <MuiSkeleton.LoadingDotBox />
            )}
        </>
    )
}

export default withSuspense(TranscodingDurations, MuiSkeleton["LoadingDotBox"])