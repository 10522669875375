import { encodeBase64, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class WorkContractApi {
    List = ({
        query = null,
        searchQuery = null,
        page = 1,
        pageSize = 20
    }) => {
        return useQueryWithCustomized(
            ["workcontract.list", query, searchQuery, page, pageSize],
            async () => {
                return await requestWithToken(
                    `${API_V3_URL}/work_contracts?${query ? `&filters=${encodeBase64(query)}` : ''}${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ''}&page=${page}&page_size=${pageSize}`
                )
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data;
                    })
                    .catch((error) => {
                        return error.response;
                    })
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
                enabled: !!localStorage.getItem("SA"),
            }
        );
    }

    Detail = ({
        employeeId = -1,
        workcontractId = -1
    }) => {
        return useQueryWithCustomized(
            ["workcontract.detail", employeeId, workcontractId],
            async () => {
                return await requestWithToken(
                    `${API_V3_URL}/employees/${employeeId}/work_contracts/${workcontractId}`
                )
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data?.work_contract;
                    })
                    .catch((error) => {
                        return error.response;
                    })
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
                enabled: !!localStorage.getItem("SA"),
            }
        );
    }

    Create = async ({ employeeId = -1, data = {} }) => {
        return await requestWithToken(`${API_V3_URL}/employees/${employeeId}/work_contracts`, "POST", {
            work_contract: data
        }, {}, {})
            .then(({ data }) => {
                if (data?.error) return data
                return data?.data
            })
            .catch((error) => { return error?.response })
    }

    Update = async ({ employeeId = -1, workcontractId = -1, data = {} }) => {
        return await requestWithToken(`${API_V3_URL}/employees/${employeeId}/work_contracts/${workcontractId}`, "PUT", {
            work_contract: data
        }, {}, {})
            .then(({ data }) => {
                if (data?.error) return data
                return data?.data
            })
            .catch((error) => { return error?.response })
    }

    Cancel = async ({ id = -1, reason = "" }) => {
        return await requestWithToken(`${API_V3_URL}/work_contracts/${id}`, "DELETE", {
            work_contract: {
                note_content: reason
            }
        }, {}, {})
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                return error.response
            })
    }

    GWorkcontractNumber = ({ key = '' }) => {
        return useQueryWithCustomized(
            ["workcontract.g.work_contract_number", key],
            async () => {
                return await requestWithToken(`${API_V3_URL}/work_contracts/generate_work_contract_number`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data?.work_contract_number;
                    })
                    .catch((error) => {
                        return error.response;
                    })
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
                enabled: !!localStorage.getItem("SA"),
            }
        );
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new WorkContractApi();
