import ErrorHelperText from "@/components/shared/ErrorHelperText"
import { GENDERS } from '@/libs/constants'
import DatePicker from '@components/DatePicker'
import Location from '@components/Location'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const UIDeclaration = () => {
    const { control, watch, formState: { errors } } = useFormContext()

    return (
        <Box>
            <Typography sx={{
                fontSize: 14, textAlign: 'center',
                color: "#777680"
            }}>
                (Thông Tư Số 24/2015/TT-BTTTT Của Bộ Thông Tin Và Truyền Thông)
            </Typography>
            <Typography sx={{ fontSize: 14, fontWeight: "medium" }}>
                Loại Bản Khai *
            </Typography>
            <Controller
                name="for"
                control={control}
                render={({ field: { value, onChange } }) => {
                    return (
                        <RadioGroup value={value}
                            onChange={onChange}
                            sx={{
                                display: "flex",
                                flexDirection: {
                                    xs: "column", md: "row"
                                },
                                gap: 0.5
                            }}
                        >
                            {[
                                { value: "canhan", label: "Cá Nhân" },
                                { value: "congty", label: "Doanh Nghiệp" }
                            ]?.map((item, index) => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        {...item}
                                        control={<Radio size='small' />}
                                    />
                                )
                            })}
                        </RadioGroup>
                    )
                }} />
            <Typography sx={{ fontSize: 14, fontWeight: "medium", mb: 1.5 }}>
                Thông tin chủ thể
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="uiName"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Tên Chủ Thể (cá nhân/công ty) *"
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors?.uiName}
                                        helperText={errors?.uiName}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    {watch("for") === "congty" ? (
                        <Controller
                            name="uiTaxCode"
                            control={control}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <FormControl fullWidth>
                                        <TextField
                                            size='small'
                                            label="Mã Số Thuế *"
                                            InputLabelProps={{ shrink: true }}
                                            value={value}
                                            onChange={onChange}
                                            error={!!errors?.uiTaxCode}
                                            helperText={errors?.uiTaxCode?.message}
                                        />
                                    </FormControl>
                                )
                            }} />
                    ) : (<Controller
                        name="uiID_Number"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="CMND/CCCD *"
                                        placeholder='Nhập CMND/ CCCD...'
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors?.uiID_Number}
                                        helperText={errors?.uiID_Number?.message}
                                    />
                                </FormControl>
                            )
                        }} />)
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name="uiCountry"
                        render={({ field: { value, onChange } }) => {
                            console.log(value)
                            return (
                                <Location.Country
                                    value={value || null}
                                    onChange={(event, newValue) => onChange(newValue)}
                                    error={{
                                        isError: false,
                                        message: ""
                                    }}
                                />
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name="uiProvince"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Location.Province
                                    preSelected={value?.value}
                                    country={watch(`uiCountry`)?.value || '-1'}
                                    onChange={(event, newValue) => onChange(newValue)}
                                    error={{
                                        isError: false,
                                        message: ""
                                    }}
                                />
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name="uiDistrict"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Location.District
                                    preSelected={value?.value}
                                    province={watch(`uiProvince`)?.value || '-1'}
                                    onChange={(event, newValue) => onChange(newValue)}
                                    error={{
                                        isError: false,
                                        message: ""
                                    }}
                                />
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name="uiWard"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Location.Ward
                                    preSelected={value?.value}
                                    province={watch(`uiProvince`)?.value || '-1'}
                                    district={watch(`uiDistrict`)?.value || '-1'}
                                    onChange={(event, newValue) => onChange(newValue)}
                                    error={{
                                        isError: false,
                                        message: ""
                                    }}
                                />
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Controller
                        control={control}
                        name="uiAddress"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Location.Address
                                    value={value}
                                    onChnage={onChange}
                                    preSelected={value?.value}
                                    error={{
                                        isError: false,
                                        message: ""
                                    }}
                                />
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name='uiEmail'
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Email *"
                                        placeholder='Nhập email của khách hàng...'
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors?.uiEmail}
                                        helperText={errors?.uiEmail?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name='uiPhone'
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Số Điện Thoại * "
                                        placeholder='Nhập số điện thoại...'
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors?.uiPhone}
                                        helperText={errors?.uiPhone?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                {watch("for") === "canhan" && (
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="uiGender"
                            control={control}
                            render={({ field: { value, onChange } }) => {
                                const selected = GENDERS.find(x => x.value === value) || null
                                return (
                                    <FormControl fullWidth>
                                        <SingleSelectCustomize
                                            value={selected}
                                            options={GENDERS.slice(0, 2)}
                                            onChange={(event, newValue) => onChange(newValue?.value || "")}
                                            input={{
                                                placeholder: "chọn giới tính...",
                                                label: "Giới tính"
                                            }}
                                        />
                                        {!!errors?.uiGender
                                            && <ErrorHelperText
                                                message={errors?.uiGender?.message} />}
                                    </FormControl>
                                )
                            }} />
                    </Grid>
                )}
                <Grid item xs={12} md={6}>
                    <Controller
                        name='uiFax'
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Số FAX"
                                        placeholder='Số FAX...'
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                {watch("for") === "canhan" && (
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="uiBirthdate"
                            control={control}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <FormControl fullWidth>
                                        <DatePicker
                                            value={value}
                                            onChange={onChange}
                                            label="Ngày Sinh *"
                                            size='small'
                                        />
                                        {!!errors?.uiBirthdate
                                            && <ErrorHelperText
                                                message={errors?.uiBirthdate?.message} />}
                                    </FormControl>
                                )
                            }} />
                    </Grid>
                )}
                <Grid item xs={12} md={6}>
                    <Controller
                        name="uiCompany"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Tên Công Ty"
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="uiPosition"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Chức Vụ"
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default UIDeclaration