import { format_numeral_price } from '@/functions'
import { PRODUCT_UNITS } from '@/libs/constants'
import { Box, Typography } from '@mui/material'
import React from 'react'

export const SERVICE_INFORMATION_HIDDENS = ["software", "cloudsecurities", "ssl"]

const ServiceInformation = ({ serviceInformations = [] }) => {

    if (serviceInformations.length === 0) return null
    return (
        <React.Fragment>
            <Typography sx={{
                fontSize: 14,
                mb: 0.5,
                color: "#777680",
            }}>
                Thông Tin Sản Phẩm/ Dịch Vụ
            </Typography>
            <Box sx={{ p: 2, bgcolor: "#7776801F", width: '100%' }}>
                <Box sx={{
                    display: { xs: 'none', md: 'flex' },
                    mb: 0.5,
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: 1.5,
                }}>
                    <Typography
                        sx={{
                            minWidth: 282,
                            fontSize: 14,
                            color: "#777680"
                        }}>
                        Tên Cấu Hình
                    </Typography>
                    <Typography
                        sx={{
                            minWidth: 150,
                            fontSize: 14,
                            color: "#777680"
                        }}>
                        Giá Trị
                    </Typography>
                </Box>
                {serviceInformations?.map((item, index) => {
                    if (SERVICE_INFORMATION_HIDDENS.includes(item?.product_infomation_type)) return null
                    return (
                        <Box sx={{
                            display: 'flex',
                            mb: 0.5,
                            flexDirection: { xs: 'column', md: 'row' },
                            gap: 1.5
                        }} key={index}>
                            <Typography
                                color="primary"
                                sx={{
                                    minWidth: 282,
                                    fontSize: 14,
                                    textTransform: 'uppercase'
                                }}>
                                {item.name}
                            </Typography>
                            <Typography
                                sx={{
                                    minWidth: 150,
                                    fontSize: 14
                                }}>
                                {item.unit === "qty" && `x`}{format_numeral_price(item?.value)}
                                {PRODUCT_UNITS.slice(1).find((k) => k.value === item.unit)?.label}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
        </React.Fragment>
    )
}

export default ServiceInformation