import ProductApi from '@/apis/beta/Product/ProductApi';
import withPermission from '@/hocs/withPermission';
import withSuspense from '@/hocs/withSuspense';
import MuiSkeleton from '@components/shared/MuiSkeleton';
import { useNotificationContext } from '@contexts/notification';
import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import { Box, Button, FormControl, IconButton, Paper, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Buttons from "./Buttons";

const Features = () => {
    const { product_id } = useParams()

    const [submit, setSubmit] = React.useState(null)

    const clearSubmit = () => setSubmit(null)

    const { setNotification } = useNotificationContext()

    const { data, refetch: callback } = ProductApi.Features({ productId: product_id })

    const methods = useForm({
        defaultValues: {
            product_features: data || []
        }
    })

    const { fields, append, remove } = useFieldArray({
        control: methods.control,
        name: 'product_features'
    })

    const onReset = () => {
        setSubmit("reset")
        methods.reset({ "product_features": data })

        return clearSubmit()
    }

    const onSubmit = async (values) => {
        setSubmit("submit")
        const response = await ProductApi.UpdateProductFeatures({
            productId: product_id,
            data: values?.product_features || []
        })
        clearSubmit()
        if (!response || response?.errors) {
            return setNotification({
                open: true,
                message: response?.errors?.[0]?.message,
                severity: 'error'
            })
        }
        setNotification({
            open: true,
            message: "Cập nhật tính năng thành công.",
            severity: 'success'
        })
        try {
            callback()
        } catch (error) {
            console.log(error?.message)
        }
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', md: 'row' },
            gap: 2
        }}>
            <FormProvider {...methods}>
                <Paper sx={{ width: '100%', p: 3 }} component="form" onSubmit={methods.handleSubmit(onSubmit)}>
                    {fields.length === 0 && <Box sx={{
                        display: 'flex',
                        minHeight: 150
                    }}>
                        <Typography sx={{
                            m: 'auto',
                            fontSize: 14,
                            color: "#777680"
                        }}>
                            Tính năng sản phẩm hiện tại đang trống.
                        </Typography>
                    </Box>}
                    {fields && fields?.map((field, index) => {
                        return (
                            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1.5, mb: 2 }} key={field.id}>
                                <Controller
                                    name={`product_features.[${index}].product_feature_description`}
                                    control={methods.control}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <FormControl fullWidth>
                                                <TextField
                                                    InputLabelProps={{ shrink: true }}
                                                    label="Description"
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder="Nhập mô tả..."
                                                    size='small'
                                                />
                                            </FormControl>
                                        )
                                    }} />
                                <Controller
                                    name={`product_features.[${index}].product_feature_highlight`}
                                    control={methods.control}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <FormControl fullWidth>
                                                <TextField
                                                    InputLabelProps={{ shrink: true }}
                                                    label="Highlight"
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder="Phần highlight..."
                                                    size='small'
                                                />
                                            </FormControl>
                                        )
                                    }} />

                                <IconButton size='small' onClick={() => remove(index)}>
                                    <DeleteOutlined fontSize='small' sx={{
                                        ":hover": {
                                            color: "#ef5350"
                                        }
                                    }} />
                                </IconButton>
                            </Box>
                        )
                    })}
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <Button size="small" variant='outlined'
                            startIcon={<AddOutlined fontSize='small' />}
                            onClick={() => append({
                                product_feature_description: "",
                                product_feature_highlight: ""
                            })}>
                            Thêm tính năng
                        </Button>
                    </Box>
                    <Box sx={{
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: { xs: 1.5, md: 2 }
                    }}>
                        <Buttons.Cancel onClick={onReset} submit={submit} />
                        <Buttons.Update submit={submit} />
                    </Box>
                </Paper></FormProvider>
            <Box sx={{ minWidth: '20%' }} />
        </Box>
    )
}

export default withSuspense(withPermission(
    Features, {
    feature: "product",
    action: "product-feature-index"
}, MuiSkeleton["Paper"]))