import { format_gmt_datetime } from '@/functions'
import { WEEK_DAYS } from '@/libs/constants'
import { Chip, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'

const BusinessTimer = ({
    business_startdate,
    business_enddate
}) => {

    const [day] = React.useState(WEEK_DAYS?.find(day => day?.value === moment(business_startdate).isoWeekday()))
    return (
        <Chip
            variant='outlined'
            icon={<IconButton size='small'
                sx={{
                    height: 28, width: 28,
                    m: 'auto 0',
                    bgcolor: `${day?.color}`,
                    ":hover": {
                        bgcolor: `${day?.color}`
                    }
                }}
            >
                <Typography sx={{ fontSize: 14, color: "#000" }}>{day?.label}</Typography>
            </IconButton>}
            sx={{ borderColor: day?.color }}
            label={<Typography sx={{ fontSize: 14 }}>
                {format_gmt_datetime(business_startdate, "DD/MM HH:mm")} - {format_gmt_datetime(business_enddate, "DD/MM HH:mm")}
            </Typography>}
        />
    )
}

export default BusinessTimer