import { getDataGridUtilityClass, gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridRootProps, useGridSelector } from "@mui/x-data-grid-pro";

import { Box, IconButton, Typography } from "@mui/material";
import { unstable_composeClasses as composeClasses } from '@mui/utils';
import { Link } from "react-router-dom";


const useUtilityClasses = (ownerState) => {
    const { classes } = ownerState;
    const slots = {
        root: ['treeDataGroupingCell'],
        toggle: ['treeDataGroupingCellToggle'],
    };
    return composeClasses(slots, getDataGridUtilityClass, classes);
};
const GridTreeDataGroupingCell = ({
    id,
    field,
    rowNode,
    total,
    ...props
}) => {
    const rootProps = useGridRootProps();
    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(
        apiRef,
        gridFilteredDescendantCountLookupSelector
    );



    const ownerState = {
        classes: rootProps.classes,
    };

    const classes = useUtilityClasses(ownerState);

    const filteredDescendantCount =
        filteredDescendantCountLookup[rowNode.id] ?? 0;


    const Icon = rowNode.childrenExpanded
        ? rootProps.components.TreeDataCollapseIcon
        : rootProps.components.TreeDataExpandIcon;


    const handleClick = (event) => {
        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
        apiRef.current.setCellFocus(id, field);
        event.stopPropagation();
    };

    return (
        <Box className={classes.root} sx={{ ml: rowNode.depth * 3 }}>
            <Box className={classes.toggle}>
                {filteredDescendantCount > 0 ? (
                    <>
                        <IconButton
                            size='small'
                            onClick={handleClick}
                            tabIndex={-1}
                            aria-label={
                                rowNode.childrenExpanded
                                    ? apiRef.current?.getLocaleText('treeDataCollapse')
                                    : apiRef.current?.getLocaleText('treeDataExpand')
                            }
                            sx={{ mr: 1 }}
                        >
                            <Icon fontSize='inherit' />
                        </IconButton>
                        <Typography sx={{ fontSize: 14 }} component="span">
                            <Link to={props?.linked} replace>
                                <Typography component='span'
                                    color="primary"
                                    sx={{ fontSize: 14 }}>
                                    {rowNode?.groupingKey?.split(' - ')?.[0] || ''}
                                    <Typography component='span'
                                        sx={{ fontSize: 14 }} color="text.black">
                                        {" - "} {rowNode?.groupingKey?.split(' - ')?.[1]}
                                    </Typography>
                                </Typography>
                            </Link>
                            <Typography component='span'
                                color="text.secondary"
                                sx={{ fontSize: 14, ml: 0.25 }}
                            >
                                ({total})
                            </Typography>
                        </Typography>
                    </>
                ) : (
                    <Link to={props?.linked} replace>
                        <Typography component='span'
                            color="primary"
                            sx={{ fontSize: 14, ml: 4.5 }}>
                            {!!rowNode?.groupingKey ? rowNode?.groupingKey?.split(' - ')?.[0] : rowNode?.groupingKey}
                            <Typography component='span'
                                sx={{ fontSize: 14 }} color="text.black">
                                {!!rowNode?.groupingKey ? (' - ' + rowNode?.groupingKey?.split(' - ')?.[1]) : ''}
                            </Typography>
                        </Typography>
                    </Link>

                )}
            </Box>
        </Box>
    );
};
export default GridTreeDataGroupingCell