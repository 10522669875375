import { KeyboardArrowDownOutlined } from '@mui/icons-material'
import { Box, Checkbox, Collapse, FormControl, FormControlLabel, IconButton, Typography } from '@mui/material'
import _ from 'lodash'
import React from 'react'

const ManageItem = ({ data, selecteds = [], setSelecteds }) => {
    const [active, setActive] = React.useState(false)

    const getActionQtys = React.useCallback(() => {
        if (data?.actions?.length === 0) return 0
        let count = 0
        data?.actions?.forEach(e => {
            if (selecteds && selecteds?.includes(e?.permission_id)) {
                count += 1
            }
        })
        return count
    }, [data?.actions, selecteds])

    const [qty, setQty] = React.useState(getActionQtys())
    React.useEffect(() => {
        setQty(getActionQtys())
    }, [getActionQtys])

    const handleChangeMeta = (event) => {
        let checked = event?.target?.checked
        const actionIds = data?.actions?.map((action) => action.permission_id)
        if (checked) {
            if (!active) { setActive(true) }
            return setSelecteds(prev => _.uniq([...prev, ...actionIds]))
        } else {
            return setSelecteds(prev => _.uniq([...prev?.filter(id => !actionIds.includes(id))]))
        }
    }

    return (
        <Box sx={{ p: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormControl fullWidth>
                    <FormControlLabel
                        label={<Typography component="div"
                            color="custom.grey"
                            sx={{
                                fontSize: 14,
                                textTransform: 'uppercase'
                            }}>
                            [ {data?.feature?.split("::")[0] === "datacentral" ? "DC" : data?.feature?.split("::")[0]} ]
                            <Typography sx={{
                                fontSize: 14,
                                display: 'inline',
                                textTransform: 'capitalize',
                                ml: 0.5
                            }}
                                color={qty === data?.actions?.length
                                    ? "custom.success"
                                    : qty === 0 ? "custom.grey" : "primary"}
                            >
                                [ {data?.feature?.split("::")[1]} ]
                            </Typography>
                        </Typography>}
                        control={<Checkbox size='small' checked={qty === data?.actions?.length} onChange={handleChangeMeta} />}
                    />
                </FormControl>
                <IconButton size='small' onClick={() => setActive(prev => !prev)}
                    sx={{ width: 30, height: 30, m: 'auto 0' }}
                >
                    <KeyboardArrowDownOutlined fontSize='small' sx={{
                        transition: 'all 0.5s ease-in-out',
                        transform: active && 'rotate(-180deg)'
                    }} />
                </IconButton>
            </Box>
            <Collapse in={active}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: 1 }}>
                    {data?.actions && data?.actions?.length !== 0 && data?.actions?.map((action, index) => {
                        let check = selecteds?.includes(action?.permission_id)
                        return (
                            <FormControlLabel key={index}
                                control={<Checkbox size='small' checked={check}
                                    onChange={(event) => {
                                        if (event?.target?.checked) {
                                            return setSelecteds(prev => _.uniq([...prev, parseInt(action?.permission_id)]))
                                        } else {
                                            return setSelecteds(prev => [...prev?.filter(id => id !== parseInt(action?.permission_id))])
                                        }
                                    }}
                                />}
                                label={action?.permission_action_name}
                                sx={{ width: { xs: 'calc((100% - 8px * 4) / 2)', md: 'calc((100% - 8px * 6) / 4)' } }}
                            />
                        )
                    })}
                </Box>
            </Collapse>
        </Box>
    )
}

export default ManageItem