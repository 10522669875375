import { requestWithToken, encodeBase64 } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V2_URL, API_V3_URL } from "@/libs/constants";

class ServiceAddonApi {
  List = ({ query, page, pageSize }) =>
    useQueryWithCustomized(
      ["service-addons.list", query, page, pageSize],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/service_addons${query ? `?filters=${encodeBase64(query)}` : "?"
          }&page=${page}&page_size=${pageSize}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );

  Create = async ({ serviceId, data }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/service_addons`,
      "POST",
      {
        service_addon: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        return {
          service: data?.data?.service,
          status: data?.status,
        };
      })
      .catch((error) => {
        return error.response;
      });
  };

  Renew = async ({ serviceId, serviceAddonId }) => {
    return await requestWithToken(
      `${API_V2_URL}/services/${serviceId}/service_addons/${serviceAddonId}/renew`,
      "POST",
      {},
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Decrease = async ({ serviceId, data }) => {
    return await requestWithToken(
      `${API_V3_URL}/services/${serviceId}/service_addons/decrease`,
      "POST",
      { service_addon: data },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ServiceAddonApi();
