import { Box, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const InvoiceCustomer = ({ customer }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 1,
            justifyContent: 'space-between',
        }}>
            <Box sx={{
                display: 'flex',
                width: { xs: '100%', md: '40%' }
            }}>
                <Typography sx={{
                    fontSize: 14,
                    color: "#777680",
                    m: 'auto 0'
                }}>
                    Khách Hàng
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Link to={`/customers/${customer?.customer_id}/finance`}>
                    <Typography color="primary"
                        sx={{ ":hover": { textDecoration: 'underline' } }}
                    >{customer?.customer_id} - {customer?.customer_name}</Typography>
                </Link>
            </Box>
        </Box>
    )
}

export default InvoiceCustomer