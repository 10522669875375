import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import { Button } from "@mui/material";

const ViewMore = ({ open, swap }) => {
  return (
    <Button
      size="small"
      variant="outlined"
      onClick={() => swap((open) => !open)}
      startIcon={
        open ? (
          <VisibilityOutlined fontSize="small" />
        ) : (
          <VisibilityOffOutlined fontSize="small" />
        )
      }
    >
      Xem Thêm Thông Tin
    </Button>
  );
};

export default ViewMore;
