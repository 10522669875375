import ScopeCreate from "./Create";
import List from "./List";
import Detail from "./Detail";

const ScopeManagementResource = {
    Create: ScopeCreate,
    List,
    Detail
}

export default ScopeManagementResource