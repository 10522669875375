import ServiceEdit from "./ServiceEdit";
import ServiceActive from "./Active";
import ServiceCancel from "./Stop";
import ViewContract from "./ViewContract";
import Informations from "./Informations";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ServiceEdit: ServiceEdit,
  ServiceActive: ServiceActive,
  ServiceCancel: ServiceCancel,
  ViewContract: ViewContract,
  Informations: Informations,
};
