import { Box, CircularProgress, Drawer, Grid, Typography } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import moment from "moment";
import { format_date_with_time } from "@/functions";
import WorkDayApi from "@/apis/beta/Employee/WorkDayApi";

const DrawerBussinessDetail = ({ open, onClose, values }) => {
  const { data, isLoading, isError } = useQuery(
    [`get-info-${JSON.stringify(values)}`],
    () => WorkDayApi.GetInfoDayOffOrBusiness(values)
  );

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box px={2} minWidth={800}>
        {(isError || data?.errors) && (
          <Typography fontWeight={400} color="error">
            {data?.errors?.[0]?.message || "Something went wrong"}
          </Typography>
        )}

        {isLoading && (
          <Box p={2}>
            <CircularProgress />
          </Box>
        )}

        <Box>
          {data?.data?.map((item) => {
            const dataInfo = [
              {
                label: "Mã Phiếu",
                value: item?.business_id,
              },
              {
                label: "Nội dung công việc",
                value: item?.business_content,
              },
              {
                label: "Bắt Đầu",
                value: format_date_with_time(
                  moment(item?.business_startdate).add(-7, "hours")
                ),
              },
              {
                label: "Kết Thúc",
                value: format_date_with_time(
                  moment(item?.business_enddate).add(-7, "hours")
                ),
              },
            ];

            return (
              <Box
                key={item?.business_id}
                mt={2}
                sx={{ border: "1px solid #e5e7eb", borderRadius: 4 }}
                p={2}
              >
                <Typography fontSize={18} fontWeight={600} mb={2}>
                  Chi Tiết Phiếu #{item?.business_id}
                </Typography>

                <Box>
                  <Grid container spacing={2}>
                    {dataInfo?.map((item) => (
                      <Grid key={item?.label} item md={6} sm={6} xs={12}>
                        <Typography sx={{ mb: 0.5, color: "#777680" }}>
                          {item?.label}:
                        </Typography>
                        <Typography sx={{ color: "#00000F" }}>
                          {item?.value}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Drawer>
  );
};

export default DrawerBussinessDetail;
