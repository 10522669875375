import React from "react";
import PriceTemplates from "./Templates";

const Price = ({ data }) => {
  const Component = PriceTemplates[data?.service_creatable_type || "Default"];

  return React.createElement(Component || PriceTemplates["Default"], {
    data,
  });
};

export default Price;
