import {
  Box,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "react-query";
import EmployeeSalarySlip from "./EmployeeSalarySlip";
import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import WorkDayApi from "@/apis/beta/Employee/WorkDayApi";
import { useSnackbar } from "notistack";
import { arrayRange, formatCurrency } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
import MuiSkeleton from "@components/shared/MuiSkeleton";

const Salary = () => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      employee: null,
      work_days: "",
      work_days_standard: "",
      salery_gross: "0",
      extra_salary: "0",
      commission: "0",
      month: new Date(Date.now()).getMonth() + 1,
      year: new Date(Date.now()).getFullYear(),
      work_days_bonus: "0",
      bhxh: "0",
      bhxh_minus: "0",
      tax: "0",
      others_minus: "0",
    },
  });

  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading } = EmployeeApi.ListEmployees();

  const { mutateAsync, isLoading: TotalWorkDaysInMonthLoading } = useMutation(
    WorkDayApi.TotalWorkDaysInMonth
  );

  const isFetching = isLoading || TotalWorkDaysInMonthLoading;

  useEffect(() => {
    (async () => {
      const month = watch("month");
      const year = watch("year");
      const employee_id = watch("employee");

      const response = await mutateAsync({
        employee_id: Number(employee_id),
        year: Number(year),
        month: Number(month),
      });

      if (response?.errors) return enqueueSnackbar("Something went wrong");

      setValue("work_days", response?.total);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("month"), watch("year"), watch("employee")]);
  useEffect(() => {
    const calculateStandardWorkDays = async () => {
      const month = watch("month");
      const year = watch("year");
      const response = await mutateAsync({ year, month });

      if (response?.errors) {
        enqueueSnackbar("Đã xảy ra lỗi");
      } else {
        const daysInMonth = new Date(year, month, 0).getDate();
        let standardWorkDays = daysInMonth;

        for (let i = 1; i <= daysInMonth; i++) {
          const date = new Date(year, month - 1, i);

          if (date.getDay() === 0) {
            standardWorkDays--;
          }
        }

        setValue("work_days_standard", standardWorkDays);
      }
    };

    calculateStandardWorkDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("month"), watch("year"), mutateAsync, enqueueSnackbar, setValue]);
  return (
    <Box>
      <Box
        display="flex"
        flexDirection={{ lg: "row", xs: "column" }}
        justifyContent="center"
        gap={2}
      >
        <Box sx={{ width: { lg: "50%", xs: "100%" } }}>
          <Box
            sx={{
              p: 2,
              backgroundColor: "#fff",
              maxWidth: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: 18, md: 24 },
                fontWeight: "medium",
              }}
            >
              Xuất Phiếu Lương
            </Typography>

            <Box mt={2}>
              <Box>
                <Typography>Chọn nhân viên</Typography>
                <Controller
                  name="employee"
                  control={control}
                  rules={{ required: { value: true, message: "Required" } }}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Select
                        {...field}
                        size="small"
                        fullWidth
                        disabled={isFetching}
                        sx={{ mt: 1 }}
                      >
                        {data?.map((item) => (
                          <MenuItem value={item?.employee_id}>
                            {item?.employee_name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.employee?.message && (
                        <Typography color="error">
                          {errors?.employee?.message}
                        </Typography>
                      )}
                    </FormControl>
                  )}
                />
              </Box>

              <Box mt={1}>
                <Typography>Chọn tháng</Typography>
                <Controller
                  name="month"
                  control={control}
                  rules={{ required: { value: true, message: "Required" } }}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Select
                        {...field}
                        size="small"
                        fullWidth
                        disabled={isFetching}
                        sx={{ mt: 1 }}
                      >
                        {Array.from(Array(12).keys())?.map((item) => (
                          <MenuItem value={item + 1}>{item + 1}</MenuItem>
                        ))}
                      </Select>
                      {errors?.month?.message && (
                        <Typography color="error">
                          {errors?.month?.message}
                        </Typography>
                      )}
                    </FormControl>
                  )}
                />
              </Box>

              <Box mt={1}>
                <Typography>Chọn năm</Typography>
                <Controller
                  name="year"
                  control={control}
                  rules={{ required: { value: true, message: "Required" } }}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Select
                        {...field}
                        size="small"
                        fullWidth
                        disabled={isFetching}
                        sx={{ mt: 1 }}
                      >
                        {arrayRange(
                          1990,
                          new Date(Date.now()).getFullYear(),
                          1
                        ).map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                      {errors?.year?.message && (
                        <Typography color="error">
                          {errors?.year?.message}
                        </Typography>
                      )}
                    </FormControl>
                  )}
                />
              </Box>

              <Box mt={2}>
                <Controller
                  name="work_days"
                  control={control}
                  rules={{ required: { value: true, message: "Required" } }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Ngày công trong tháng"
                      size="small"
                      sx={{ mt: 2 }}
                      type="number"
                      fullWidth
                      error={errors?.work_days}
                      helperText={errors?.work_days?.message}
                      disabled={isFetching}
                    />
                  )}
                />

                <Controller
                  name="work_days_bonus"
                  control={control}
                  rules={{ required: { value: true, message: "Required" } }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Ngày công thêm"
                      size="small"
                      sx={{ mt: 2 }}
                      type="number"
                      fullWidth
                      error={errors?.work_days}
                      helperText={errors?.work_days?.message}
                      disabled={isFetching}
                    />
                  )}
                />

                <Controller
                  name="work_days_standard"
                  control={control}
                  rules={{ required: { value: true, message: "Required" } }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Ngày công chuẩn"
                      size="small"
                      sx={{ mt: 2 }}
                      fullWidth
                      type="number"
                      error={errors?.work_days_standard}
                      helperText={errors?.work_days_standard?.message}
                      disabled={isFetching}
                    />
                  )}
                />

                <Controller
                  name="salery_gross"
                  control={control}
                  rules={{ required: { value: true, message: "Required" } }}
                  render={({ field: { onChange, ...field } }) => (
                    <TextField
                      {...field}
                      label="Tổng lương gross"
                      size="small"
                      sx={{ mt: 2 }}
                      fullWidth
                      onChange={(e) => formatCurrency(e, onChange)}
                      error={errors?.salery_gross}
                      helperText={errors?.salery_gross?.message}
                      disabled={isFetching}
                    />
                  )}
                />

                <Controller
                  name="extra_salary"
                  control={control}
                  rules={{ required: { value: true, message: "Required" } }}
                  render={({ field: { onChange, ...field } }) => (
                    <TextField
                      {...field}
                      label="Phụ cấp, thưởng"
                      size="small"
                      sx={{ mt: 2 }}
                      onChange={(e) => formatCurrency(e, onChange)}
                      fullWidth
                      error={errors?.extra_salary}
                      helperText={errors?.extra_salary?.message}
                    />
                  )}
                />
                <Controller
                  name="commission"
                  control={control}
                  rules={{ required: { value: true, message: "Required" } }}
                  render={({ field: { onChange, ...field } }) => (
                    <TextField
                      {...field}
                      label="Hoa hồng"
                      size="small"
                      sx={{ mt: 2 }}
                      onChange={(e) => formatCurrency(e, onChange)}
                      fullWidth
                      error={errors?.commission}
                      helperText={errors?.commission?.message}
                    />
                  )}
                />

                <Controller
                  name="bhxh"
                  control={control}
                  rules={{ required: { value: true, message: "Required" } }}
                  render={({ field: { onChange, ...field } }) => (
                    <TextField
                      {...field}
                      label="Mức đóng BHXH"
                      size="small"
                      sx={{ mt: 2 }}
                      fullWidth
                      onChange={(e) => formatCurrency(e, onChange)}
                      error={errors?.salery_gross}
                      helperText={errors?.salery_gross?.message}
                      disabled={isFetching}
                    />
                  )}
                />

                <Controller
                  name="bhxh_minus"
                  control={control}
                  rules={{ required: { value: true, message: "Required" } }}
                  render={({ field: { onChange, ...field } }) => (
                    <TextField
                      {...field}
                      label="Trích nộp BHXH"
                      size="small"
                      sx={{ mt: 2 }}
                      fullWidth
                      onChange={(e) => formatCurrency(e, onChange)}
                      error={errors?.salery_gross}
                      helperText={errors?.salery_gross?.message}
                      disabled={isFetching}
                    />
                  )}
                />

                <Controller
                  name="tax"
                  control={control}
                  rules={{ required: { value: true, message: "Required" } }}
                  render={({ field: { onChange, ...field } }) => (
                    <TextField
                      {...field}
                      label="Khấu trừ thuế thu nhập cá nhân"
                      size="small"
                      sx={{ mt: 2 }}
                      fullWidth
                      onChange={(e) => formatCurrency(e, onChange)}
                      error={errors?.salery_gross}
                      helperText={errors?.salery_gross?.message}
                      disabled={isFetching}
                    />
                  )}
                />

                <Controller
                  name="others_minus"
                  control={control}
                  rules={{ required: { value: true, message: "Required" } }}
                  render={({ field: { onChange, ...field } }) => (
                    <TextField
                      {...field}
                      label="Các khoản trừ khác"
                      size="small"
                      sx={{ mt: 2 }}
                      fullWidth
                      onChange={(e) => formatCurrency(e, onChange)}
                      error={errors?.salery_gross}
                      helperText={errors?.salery_gross?.message}
                      disabled={isFetching}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box maxWidth="100%" width={{ lg: "50%", xs: "100%" }}>
          <EmployeeSalarySlip
            employee={data?.find(
              (item) => item?.employee_id === watch("employee")
            )}
            watch={watch}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default withSuspense(
  withPermission(Salary, {
    feature: "workdays",
    action: "total-work-days-in-month",
  }),
  MuiSkeleton["GridInformation"]
);
