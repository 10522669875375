import { INVOICE_PAYMENT_METHODS } from '@/libs/constants'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, FormControl, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const InvoicePaymentMethod = ({ invoice_status }) => {
    const { control } = useFormContext()
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 1,
            justifyContent: 'space-between',
        }}>
            <Box sx={{
                display: 'flex',
                width: { xs: '100%', md: '40%' }
            }}>
                <Typography sx={{
                    fontSize: 14,
                    color: "#777680",
                    m: 'auto 0'
                }}>
                    Phương Thức Thanh Toán
                </Typography>
            </Box>
            <Controller
                name="payment_method"
                control={control}
                render={({ field: { value, onChange } }) => {
                    const selected = INVOICE_PAYMENT_METHODS.find(method => method.value === parseInt(value)) || null
                    return (
                        <FormControl fullWidth>
                            <SingleSelectCustomize
                                disabled={invoice_status !== 1}
                                value={selected}
                                options={INVOICE_PAYMENT_METHODS}
                                input={{
                                    placeholder: "Chọn phương thức thanh toán..."
                                }}
                                onChange={(event, newValue) => onChange(newValue?.value || -1)}
                            />
                        </FormControl>
                    )
                }} />
        </Box>
    )
}

export default InvoicePaymentMethod