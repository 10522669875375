import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Typography } from '@mui/material'
import { indigo } from "@mui/material/colors"
import _ from 'lodash'
import React from 'react'

const ModalRole = ({
    name = "",
    roles = [],
    handleClose
}) => {

    const reformatterRole = () => {
        return roles?.map((role) => {
            return ({
                websiteFeature: role?.permission_name?.split("#")[0],
                action: role?.permission_name?.split("#")[1]
            })
        })
    }

    const reformatterGroup = () => {
        const groups = _.chain(reformatterRole()).groupBy('websiteFeature').map((action, feature) => {
            return ({
                feature: feature,
                actions: action
            })
        }).value()
        return _.sortBy(groups, ['feature'])
    }

    return (
        <Dialog open={true} maxWidth='md' fullWidth>
            <DialogTitle>{name}</DialogTitle>
            <DialogContent>
                {Array.from(reformatterGroup())?.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <Typography sx={{
                                fontSize: 14,
                                mb: 1
                            }}>
                                {item?.feature}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                {item?.actions && item?.actions?.map((action, i) => {
                                    return (
                                        <FormControl key={i} sx={{ minWidth: 120 }}>
                                            <FormControlLabel
                                                label={action?.action}
                                                labelPlacement="bottom"
                                                control={<Checkbox size='small'
                                                    checked={true}
                                                    disabled
                                                    sx={{
                                                        "&.Mui-disabled": {
                                                            color: indigo[900]
                                                        }
                                                    }} />} />
                                        </FormControl>
                                    )
                                })}
                            </Box>
                            <Divider sx={{ m: '8px 0' }} />
                        </React.Fragment>
                    )
                })}
            </DialogContent>
            <DialogActions>
                <Button variant='outlined'
                    size='small'
                    sx={{
                        height: 42,
                        minWidth: 120
                    }}
                    onClick={handleClose}
                >
                    Đóng
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalRole