import { API_V3_URL } from "@/libs/constants";
import { requestWithToken, encodeBase64 } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";

class PostmanTrackingApi {

    List = ({
        query = null,
        searchQuery = null,
        page = 1,
        pageSize = 20
    }) => {
        return useQueryWithCustomized(
            ["postman-trackings.list", query, searchQuery, page, pageSize],
            async () => {
                return await requestWithToken(`${API_V3_URL}/postman_trackings?${query ? `&filters=${encodeBase64(query)}` : ''}${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ''}&page=${page}&page_size=${pageSize}`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data;
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            }
        );
    }

    Detail = (id) =>
        useQueryWithCustomized(
            ["postman-trackings.detail", id],
            async () => {
                return await requestWithToken(`${API_V3_URL}/postman_trackings/${id}`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data;
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            }
        );

    Create = async (data) => {
        return await requestWithToken(`${API_V3_URL}/postman_trackings`, "POST", {
            postman_tracking: data
        }, {}, {})
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                return error.response
            })
    }

    Update = async (data, id) => {
        return await requestWithToken(`${API_V3_URL}/postman_trackings/${id}`, "PUT", {
            postman_tracking: data
        }, {}, {})
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                return error.response
            })
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PostmanTrackingApi();
