import { dateToString } from "@components/Templates/Conversant(CDN)/services"
import StorageChart from "./StorageChart"
import { useState } from "react"
import dayjs from "dayjs"
import AnalyticHeader from "./Header"

const Analytic = ({ service }) => {
    const [times, setTimes] = useState({
        lte: dayjs(),
        year: dayjs().get('year')
    })

    return (
        <>
            <AnalyticHeader times={times} setTimes={setTimes} />
            <StorageChart
                service={{
                    service_uuid: service?.service_creatable_uuid
                }}
                query={{
                    gte: dateToString(times?.gte),
                    lte: dateToString(times?.lte)
                }}
            />
        </>
    )
}

export default Analytic