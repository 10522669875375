import ServiceNoteApi from "@/apis/beta/Service/ServiceNoteApi";
import { queryClientRefetchScope } from "@/functions";
import withPermission from "@/hocs/withPermission";
import { useNotificationContext } from "@contexts/notification";
import AddOutlined from "@mui/icons-material/AddOutlined";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

const ServiceCreateNote = ({ service }) => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();
  const { mutateAsync, isLoading } = useMutation(ServiceNoteApi.Create);

  const [note, setNote] = useState("");

  const [openModal, setOpenModal] = useState(false);

  const onClose = () => setOpenModal(false);

  const onSubmit = async () => {
    let form = {
      note_content: note,
    };

    const response = await mutateAsync({
      serviceId: service?.service_id,
      data: form,
    });
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }
    setNotification({
      open: true,
      message: "Thêm ghi chú dịch vụ thành công.",
      severity: "success",
    });
    queryClientRefetchScope(queryClient, "service");
    return onClose();
  };

  return (
    <React.Fragment>
      {openModal && (
        <Dialog open={true} maxWidth="md" fullWidth>
          <DialogTitle>Thêm Ghi Chú</DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <TextField
                  label="Ghi chú *"
                  multiline
                  minRows={3}
                  value={note}
                  placeholder="Nhập ghi chú..."
                  onChange={(event) => {
                    setNote(event?.target?.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button size="small" variant="outlined" onClick={onClose}>
              Huỷ Thao Tác
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={onSubmit}
              startIcon={
                isLoading && (
                  <CircularProgress size={14} sx={{ color: "#777680" }} />
                )
              }
            >
              Xác nhận
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Button
        size="small"
        variant="outlined"
        disabled={isLoading}
        startIcon={<AddOutlined fontSize="small" />}
        sx={{ height: { xs: 36, md: 42 } }}
        onClick={() => setOpenModal(true)}
      >
        Thêm Ghi Chú
      </Button>
    </React.Fragment>
  );
};

export default withPermission(ServiceCreateNote, {
  feature: "service",
  action: "cancelled",
  type: "button",
});
