import { CUSTOMER_SOURCES } from "@/libs/constants";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { FormControl, Typography } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const CustomerSource = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <React.Fragment>
      <Typography sx={{ mb: 0.5, color: "#777680", fontSize: 14 }}>
        Nguồn Khai Thác *
      </Typography>
      <Controller
        control={control}
        name="customer.customer_source"
        render={({ field: { value, onChange } }) => {
          const selected =
            CUSTOMER_SOURCES?.find((source) => source?.value === value) || null;
          return (
            <FormControl fullWidth>
              <SingleSelectCustomize
                value={selected}
                options={CUSTOMER_SOURCES?.slice(0, 5)}
                input={{ placeholder: "Chọn nguồn khách hàng khai thác..." }}
                onChange={(event, newValue) =>
                  onChange(newValue?.value || null)
                }
              />
              {!!errors?.customer?.customer_source && (
                <ErrorHelperText
                  message={errors?.customer?.customer_source?.message}
                />
              )}
            </FormControl>
          );
        }}
      />
    </React.Fragment>
  );
};

export default CustomerSource;
