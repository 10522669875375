import { Button } from '@mui/material'
import React from 'react'
import CartIcon from '@mui/icons-material/AddShoppingCartOutlined';
import withPermission from '@/hocs/withPermission';
import { Link } from 'react-router-dom';

const ButtonCreateOrder = ({ customerId }) => {

    return (
        <Link to={`/customers/${customerId}/orders/new?order_type=new-order`} replace>
            <Button
                variant='outlined'
                size='small'
                startIcon={<CartIcon />}
                sx={{ height: { xs: 36, md: 42 } }} >
                Đơn Hàng Mới
            </Button>
        </Link>

    )
}

export default withPermission(ButtonCreateOrder, { feature: 'service', action: 'create', type: "button" })