import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { SCOPE_APPLIEDS } from '../../functions'


const ScopeApplied = () => {
    const { control } = useFormContext()
    return (
        <Controller
            name="scope_applied"
            control={control}
            render={({ field: { value, onChange } }) => {
                return (
                    <SingleSelectCustomize
                        options={[...SCOPE_APPLIEDS]}
                        value={SCOPE_APPLIEDS?.find(scope => scope.value === value) || null}
                        onChange={(_, newValue) => onChange(newValue?.value || '')}
                        input={{
                            label: 'Phạm Vi Phân Quyền *',
                            placeholder: 'Chọn Phạm Vi Phân Quyền...'
                        }}
                    />
                )
            }}
        />
    )
}

export default ScopeApplied