import { encodeBase64, pagyResponse, payloadToQueries, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class DepartmentApi {

    ListDepartments = () => {
        return useQueryWithCustomized(
            "departments.list",
            async () => {
                return await requestWithToken(
                    `${API_V3_URL}/departments/list`
                )
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return {
                            departments: data?.data?.departments || []
                        }
                    })
                    .catch((error) => {
                        return error.response;
                    })
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
                enabled: !!localStorage.getItem("SA"),
            }
        );
    }

    List = ({ query, searchQuery, page = 1, pageSize = 25 }) => {
        const payload = {
            filters: encodeBase64(query),
            queries: encodeBase64(searchQuery),
            page,
            page_size: pageSize,
        }
        return useQueryWithCustomized(
            ["departments.all", query, searchQuery, page, pageSize],
            async () => {
                return await requestWithToken(
                    `${API_V3_URL}/departments?${payloadToQueries(payload)}`
                )
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return {
                            departments: data?.data?.departments || [],
                            pagination: pagyResponse(data?.data?.pagination)
                        }
                    })
                    .catch((error) => {
                        return error.response;
                    })
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
                enabled: !!localStorage.getItem("SA"),
            }
        );
    }

    Create = async ({ data }) => {
        return await requestWithToken(`${API_V3_URL}/departments`, "POST", {
            department: data
        })
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                return error.response;
            });
    }

    Update = async ({ departmentId = -1, data }) => {
        return await requestWithToken(`${API_V3_URL}/departments/${departmentId}`, "PUT", {
            department: data
        })
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                return error.response;
            });
    }

    Destroy = async ({ departmentId = -1 }) => {
        return await requestWithToken(`${API_V3_URL}/departments/${departmentId}`, "DELETE")
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                return error.response;
            });
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new DepartmentApi();
