import CustomerApi from '@/apis/beta/Customer/CustomerApi'
import ItemPaperStatistical from '@/components/shared/ItemPaperStatistical'
import { format_numeral_price } from '@/functions'
import withPermission from '@/hocs/withPermission'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { Grid } from '@mui/material'
import React from 'react'

const Statistical = ({ customerId }) => {
    const { data } = CustomerApi.CustomerInvoiceStatistics(customerId)
    const formatToArray = () => {
        return [
            { value: format_numeral_price(data?.paid), label: "Tổng Đã Thanh Toán", endText: true },
            { value: format_numeral_price(data?.unpaid), label: "Chưa Thanh Toán", endText: true },
            { value: format_numeral_price(data?.cancelled), label: "Huỷ", endText: true }
        ]
    }

    return (
        <Grid container spacing={2}>
            {formatToArray().map((item, index) =>
                <Grid item xs={12} md={4} key={index}>
                    <ItemPaperStatistical {...item} />
                </Grid>
            )}
        </Grid>
    )
}

export default withSuspense(
    withPermission(Statistical, {
        feature: "customer",
        action: "customer-invoice-statistic",
        type: "Block"
    }), MuiSkeleton["Statistical"])