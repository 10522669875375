import ServiceApi from '@/apis/beta/Service/ServiceApi'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import React from 'react'

const SubContract = ({ serviceId = -1 }) => {
    const { data } = ServiceApi.ContractReferrer({ serviceId })
    if (!data) return ''
    return (
        <div>Contract</div>
    )
}

export default withSuspense(SubContract, MuiSkeleton["GradientCircularProgress"])