import ManufactureApi from "@/apis/beta/Warehouse/ManufactureApi";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

const Manufacture = ({ position = -1, value = null, onChange }) => {
  const { setValue, watch } = useFormContext();

  const { data: manufactures } = ManufactureApi.getAll();

  useEffect(() => {
    if (!!value || !manufactures || position < 0) return;

    const selected = manufactures?.find(
      (manufacture) =>
        manufacture?.manufacture_id ===
        watch(`product_addons.[${position}].manufacture_id`)
    );

    if (!!selected) {
      setValue(
        `product_addons.[${position}].warehouse_brand_name`,
        selected?.manufacture_name
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, watch(`product_addons.[${position}].manufacture_id`)]);
  return (
    <SingleSelectCustomize
      value={
        manufactures?.find((manufacture) => manufacture?.label === value) ||
        null
      }
      options={[...manufactures]}
      onChange={(event, newValue) => onChange(newValue?.label || null)}
      input={{
        label: "Manufacture",
        placeholder: "Chọn manufacture...",
      }}
    />
  );
};

export default withSuspense(Manufacture, MuiSkeleton["TextField"]);
