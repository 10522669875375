import { SERVICE_PERIODS } from '@/libs/constants'
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export const PRIORITIES = ["monthly", "quarterly", "semiannually", "annually", "biennially", "triennially"]

export const sortBillingCycles = (arrs = []) => {
    return arrs?.sort((a, b) => PRIORITIES.indexOf(a) - PRIORITIES.indexOf(b));
}

const ProductBillingCycle = ({ locale = 'vi-VN' }) => {

    const { control, watch } = useFormContext()

    const productBillings = React.useMemo(() => {
        let data = []

        let billings = watch("product_billing")?.vi
        if (locale !== 'vi-VN') {
            billings = watch("product_billing")?.en
        }
        sortBillingCycles(Object.keys(billings?.terms))?.forEach(item => {
            if (billings?.accepteds?.includes(item)) {
                data.push(SERVICE_PERIODS.find(s => s.value === item))
            }
        });

        return data
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch("product_billing"), locale])

    return (
        <Box sx={{ p: '16px 0 0 0' }}>
            <Typography sx={{ fontSize: { xs: 16, md: 20 } }}>
                Chọn thời hạn mua
            </Typography>
            <Controller
                name="product_billing_cycle"
                control={control}
                render={({ field: { value, onChange } }) => {
                    return (
                        <FormControl fullWidth>
                            <RadioGroup value={value}
                                onChange={onChange}
                            >
                                {productBillings?.map((item, index) => {
                                    const label = [item?.coefficient, locale === "vi-VN" ? "Tháng" : "Month"].join(" ")
                                    return (
                                        <FormControlLabel
                                            key={index}
                                            label={label}
                                            value={item?.value}
                                            control={<Radio size='small' />}
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </FormControl>
                    )
                }}
            />
        </Box>
    )
}

export default ProductBillingCycle