import { format_numeral_price } from "@/functions";
import { Box, Typography } from "@mui/material";
import React from "react";

const OriginShieldMultiCDN = ({ data }) => {
  return (
    <Box
      sx={{
        p: "8px 16px",
        border: "1px solid rgba(0, 0,0,0.18)",
        borderRadius: "4px",
        mb: 1.25,
      }}
    >
      <Typography sx={{ fontWeight: "medium", fontSize: 14, mb: 0.75 }}>
        CDN & Origin Shield Multi CDN
      </Typography>
      <Typography sx={{ fontSize: 13, mb: 0.75 }} color="text.grey">
        Tổng Requests:
        <Typography
          sx={{ fontSize: 13, display: "inline-flex", ml: 0.5 }}
          color="text.black"
        >
          {format_numeral_price(data?.total_request)}
        </Typography>
      </Typography>
      <Typography sx={{ fontSize: 13, mb: 0.25 }} color="text.grey">
        Tổng Traffics:
        <Typography
          sx={{ fontSize: 13, display: "inline-flex", ml: 0.5 }}
          color="text.black"
        >
          {format_numeral_price(
            Math.floor(data?.total_traffic / 1000000000, 2)
          )}{" "}
          GB
        </Typography>
      </Typography>
    </Box>
  );
};

export default OriginShieldMultiCDN;
