import List from "./List";
import Detail from "./Detail";
import New from "./New";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  List,
  Detail,
  New,
};
