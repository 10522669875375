import { CUSTOMER_SOURCES, INDUSTRIES, SERVICES } from '@/libs/constants'
import PaperCustomized from '@components/shared/PaperCustomized'
import { Box, Paper } from '@mui/material'
import { useMemo } from 'react'
import SelfRegister from '../SelfRegister'
import CustomerStatus from '../shared/CustomerStatus'
import ButtonUpdateCustomer from '@features/Module/Customer/Buttons/ButtonUpdateCustomer'

const QualifiedProspect = ({ customer: {
    customer_service_information: csi, ...customer
} }) => {

    const buildMetadata = useMemo(() => {
        return [
            { label: 'Tên Khách Hàng', value: customer?.customer_information?.name, component: 'Typography' },
            {
                label: 'Email', value: customer?.customer_information?.email, component: 'Link', params: {
                    href: `mailto:${customer?.customer_information?.email}`
                }
            },
            { label: 'Số Điện Thoại', value: customer?.customer_information?.phone_number, component: 'Typography' },
            { label: 'Vị Trí', value: customer?.customer_information?.position, component: 'Typography' },
            {
                label: 'Lĩnh Vực',
                value: INDUSTRIES?.find(industry => industry.value === csi?.customer_industry)?.label, component: 'Typography'
            },
            {
                label: 'Nguồn Khách Hàng',
                value: CUSTOMER_SOURCES?.find(source => source?.value === csi?.customer_source)?.label,
                component: 'Typography'
            },
            {
                label: 'Tên Sự Kiện',
                value: csi?.customer_source_event,
                component: 'Typography',
                hidden: csi?.customer_source !== 'event'
            },
            {
                label: 'Dịch Vụ Quan Tâm',
                value: SERVICES?.filter(source => csi?.customer_service_interestings?.includes(source?.value))?.map((source) => source?.label)?.join(', '),
                component: 'Typography',
                cols: {
                    xs: 12,
                    md: csi?.customer_source !== 'event' ? 8 : 4,
                    xl: 6
                }
            },
            {
                label: 'Sales',
                value: csi?.customer_sale_followers?.map(sale => sale?.employee_name)?.join(', '),
                component: 'Typography',
                cols: { xs: 12, md: 6, xl: 6 }
            },
            {
                label: 'CSKH',
                value: csi?.customer_cs_followers?.map(cs => cs?.employee_name)?.join(', '),
                component: 'Typography',
                cols: { xs: 12, md: 6, xl: 6 }
            },
            {
                label: 'Kênh Liên Hệ',
                value: csi?.customer_contact_social,
                cols: { xs: 12, md: 12, xl: 12 },
                component: 'ArrayField',
                params: {
                    attributes: [
                        { name: 'contact_channel', label: 'Kênh Liên Hệ' },
                        { name: 'contact_channel_information', label: 'Thông Tin' }
                    ]
                }
            },
            {
                label: 'Ghi Chú',
                value: csi?.customer_note,
                component: 'Typography',
                cols: { xs: 12, md: 12, xl: 12 }
            },
        ]
    }, [customer, csi])
    return (
        <Paper sx={{ p: 2 }} elevation={8}>
            <CustomerStatus {...customer} customer_service_information={csi} />
            {csi?.customer_source === "self" && <SelfRegister />}
            <PaperCustomized
                attributes={buildMetadata}
            />
            <Box sx={{
                mt: 2,
                display: 'flex',
                justifyContent: 'flex-end',
                gap: { xs: 2, md: 1.5 }
            }}>
                <ButtonUpdateCustomer />
            </Box>
        </Paper>
    )
}

export default QualifiedProspect