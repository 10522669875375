import EmployeeApi from '@/apis/beta/Employee/EmployeeApi'
import { buildQueryFilterCondition } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import useDebounce from '@/hooks/useDebounce'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import TransitionGroups from '@components/shared/TranstionGroups'
import { PersonOutlineOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const IgnoreEmployee = () => {
    const { control, watch, setValue } = useFormContext()
    const [searchQuery, setSearchQuery] = useState('')
    const debouncedSearchQuery = useDebounce(searchQuery, 400)

    const nestedQueries = useCallback(() => {
        let queryFields = [{
            name: "employees.employee_name",
            operator: "$contains",
            disabled: !debouncedSearchQuery?.trim(),
            value: debouncedSearchQuery
        }, {
            name: "employees.employee_id",
            operator: "$in",
            disabled: !watch(`scope_metadata.ignores`)?.length,
            value: watch(`scope_metadata.ignores`)?.filter(x => !!parseInt(x))?.join(','),
        }]
        return buildQueryFilterCondition('$or', 0, queryFields)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery, watch(`scope_metadata.ignores`)])

    const query = useCallback(() => {
        let queryFields = [{
            name: "departments.department_id",
            operator: "$in",
            disabled: !watch(`scope_metadata.departments`)?.length,
            value: watch(`scope_metadata.departments`)?.filter(x => !!parseInt(x))?.join(','),
        }]
        return buildQueryFilterCondition('$and', 0, queryFields)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch(`scope_metadata.departments`)])

    const { data: { employees } } = EmployeeApi.List({
        query: query(),
        searchQuery: nestedQueries(),
        pageSize: (watch(`scope_metadata.ignores`)?.length + 10)
    })

    useEffect(() => {
        if (watch(`scope_applied`) !== 'DEPARTMENT') return;
        if (!watch(`scope_metadata.departments`)?.length) {
            return setValue(`scope_metadata.ignores`, [])
        }
        const selecteds = watch(`scope_metadata.ignores`) || []
        const newValues = employees?.filter(employee => selecteds?.includes(employee?.employee_id))?.map(employee => employee?.employee_id) || []

        return setValue(`scope_metadata.ignores`, newValues)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch(`scope_metadata.departments`), employees, watch(`scope_applied`)])

    return (
        <>
            <Typography sx={{
                fontSize: 14,
                fontWeight: 'medium',
            }}
                color="text.black"
            >
                Không Bao Gồm Các Nhân Viên Dưới Đây
            </Typography>
            <Typography sx={{ fontSize: 13 }} color="text.grey" component="span">
                Người được chỉ định sẽ
                <Typography sx={{ fontSize: 13, m: 'auto 4px' }} component="span" color="error">
                    không được truy cập
                </Typography>
                tệp thông tin của các nhân viên được chọn dưới đây trong các phòng ban đã chọn.
            </Typography>
            <Box aria-label='team.IgnoreEmployee'>
                <Controller
                    name="scope_metadata.ignores"
                    control={control}
                    render={({ field: { value = [], onChange } }) => {
                        console.log(value)
                        return (
                            <TransitionGroups
                                values={employees?.filter(employee => value?.includes(employee?.employee_id))}
                                onDelete={(e) => onChange([...value?.filter(x => x !== e?.employee_id)])}
                                label={'employee_name'}
                                icon={<PersonOutlineOutlined fontSize='small' color="primary" />}
                            >
                                <SingleSelectCustomize
                                    disabled={!watch(`scope_metadata.departments`)?.length}
                                    clearOnBlur
                                    options={[...employees?.filter(employee => !value?.includes(employee?.employee_id))]}
                                    value={null}
                                    formatCollection={{ label: 'employee_name', value: 'employee_id' }}
                                    compareAttributes={['employee_name', 'employee_email_company']}
                                    input={{
                                        placeholder: "Chọn nhân viên...",
                                        value: searchQuery,
                                        onChange: (event) => setSearchQuery(event.target.value)
                                    }}
                                    onChange={(_, newValue) => {
                                        if (!newValue?.employee_id) return;
                                        onChange([...value?.filter(employeeId => !!parseInt(employeeId)), newValue?.employee_id])
                                        setSearchQuery('')
                                    }}
                                    mode='server'
                                />
                            </TransitionGroups>
                        )
                    }}
                />
            </Box>
        </>
    )
}

export default withSuspense(IgnoreEmployee, MuiSkeleton['TextField'])