import { Box, Typography } from "@mui/material";
import React from "react";

const ContactOnClickOpen = ({ value, onClick }) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <Typography sx={{ fontSize: 14 }}>{value}</Typography>
    </Box>
  );
};

export default ContactOnClickOpen;
