import List from './List';
import Create from './Create';
import Update from './Update';
import Detail from './Detail';
import Subcontract from "./Detail/Subcontract";
import ContractProcesses from "./Detail/ContractProcesses";
import SampleContract from "../SampleContract";
import PartnerContract from "../PartnerContract";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    List,
    Create,
    Update,
    Detail,
    Subcontract,
    ContractProcesses,
    SampleContract,
    PartnerContract
};