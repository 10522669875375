import ServiceSystemApi from "@/apis/beta/Service/ServiceSystemApi";
import FileApi from "@/apis/shared/File/FileApi";
import { useNotificationContext } from "@contexts/notification";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Attachment from "../Attachment";
import DNSManagement from "../DNSManagement";
import Documentation from "../Documentation";
import Metadata from "../Metadata";

export const PREFIX_SERVICE_PATHNAME =
  "contracts/contractServices/documentations";

const ServiceSystemForm = ({
  type = "create",
  serviceId = -1,
  defaultValues = {
    dns_enable: true,
    dns_managements: [
      {
        name: "",
        type: "",
        target: "",
      },
    ],
    documentations: [{ name: "", link: "" }],
    metadata: [{ key: "", value: "" }],
  },
  defaultAttachments = [],
}) => {
  const { setNotification } = useNotificationContext();
  const [attachments, setAttachments] = useState([...defaultAttachments]);
  const methods = useForm({
    defaultValues,
  });

  const [statePushing, setPushing] = React.useState({
    loading: false,
    index: undefined,
    percen: 0,
  });

  const onSubmit = async (values) => {
    const response =
      type === "create"
        ? await ServiceSystemApi.Create({
            serviceId,
            data: { ...values },
          })
        : await ServiceSystemApi.Update({
            serviceId,
            data: { ...values },
          });

    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }

    let fullfiles = [];
    try {
      const fileClosed = attachments?.filter((x) => x?.attachment_closed);

      if (!!fileClosed && fileClosed?.length !== 0) {
        await Promise.all(
          fileClosed.map((file) => {
            return ServiceSystemApi.CancelAttachment({
              serviceId,
              attachmentId: file?.attachment_id,
            });
          })
        ).then((responses) => responses);
      }

      const newFiles = attachments?.filter((x) => !x?.attachment_id);
      const tokens = await processGetPresignAttachments({ files: newFiles });
      fullfiles = await Promise.all(
        newFiles?.map((attachment, index) => {
          setPushing((prevState) => ({
            ...prevState,
            loading: true,
            index: index + fileClosed?.length,
            percen: 0,
          }));
          return processPushAttachments(attachment, tokens[index], index);
        })
      ).then((responses) => {
        return responses?.map((x) => x);
      });

      if (fullfiles?.length !== 0) {
        await Promise.all(
          fullfiles?.map((post) => {
            return ServiceSystemApi.CreateAttachment({
              serviceId,
              data: {
                ...post,
              },
            });
          })
        ).then((multiple) => multiple);
      }
    } catch (error) {}

    setNotification({
      open: true,
      message: response?.data?.message || "Cập nhật thông tin thành công.",
      severity: "success",
    });
  };

  const processGetPresignAttachments = async ({ files }) => {
    return await Promise.all(
      files?.map((item) => {
        return FileApi.GetPresignedTokenAttachment({
          filename: {
            name: item?.name,
            folder: PREFIX_SERVICE_PATHNAME,
          },
        });
      })
    ).then((values) => values);
  };

  const processPushAttachments = async (file, token, index) => {
    await FileApi.UploadAttachmentToStorage({
      token: token?.token,
      file: file,
      handleUploadFile: setPushing,
    });
    setPushing((prev) => ({ ...prev, percen: 0 }));
    return (
      {
        attachment_file_name: file?.name,
        attachment_file_url: [
          "pub",
          PREFIX_SERVICE_PATHNAME,
          token?.filename,
        ].join("/"),
        attachment_file_type: file?.type,
        attachment_file_size: file?.size,
      } || {}
    );
  };
  return (
    <FormProvider {...methods}>
      <Paper
        sx={{ p: 2, mt: 2 }}
        component="form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Controller
          name={`dns_enable`}
          control={methods.control}
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl fullWidth>
                <FormControlLabel
                  label="DNS Management"
                  control={
                    <Checkbox
                      size="small"
                      checked={value}
                      onChange={onChange}
                    />
                  }
                />
              </FormControl>
            );
          }}
        />
        <Collapse in={methods.watch(`dns_enable`)}>
          <DNSManagement />
        </Collapse>
        <Metadata />
        <Documentation />
        <Attachment
          statePushing={statePushing}
          attachments={attachments}
          setAttachments={setAttachments}
        />
        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
          }}
        >
          <Button size="small" variant="contained" type="submit">
            {type === "create" ? "Khởi tạo dữ liệu" : "Cập Nhật"}
          </Button>
        </Box>
      </Paper>
    </FormProvider>
  );
};

export default ServiceSystemForm;
