import InvoiceNoteApi from "@/apis/beta/Invoice/InvoiceNoteApi";
import ErrorHelperText from "@/components/shared/ErrorHelperText";
import { useNotificationContext } from "@/contexts/notification";
import { queryClientRefetcher } from "@/functions";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

const InvoiceNoteForm = ({
  type = "create",
  handleClose,
  data,
  invoice_id,
}) => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = React.useState(false);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      note_content: data?.note_content || "",
    },
  });

  const onSubmit = async (values) => {
    setSubmit(true);
    let form = {
      ...values,
    };
    const response =
      type === "create"
        ? await InvoiceNoteApi.Create({ invoiceId: invoice_id, data: form })
        : await InvoiceNoteApi.Update({
            invoiceId: invoice_id,
            noteId: data?.note_id,
            data: form,
          });
    setSubmit(false);
    if (!response || response?.error) {
      return setNotification({
        open: true,
        severity: "error",
        message: "Thêm mới ghi chú thất bại",
      });
    }
    queryClientRefetcher(queryClient, ["invoice.notes.list"]);

    setNotification({
      open: true,
      severity: "success",
      message: "Thêm mới ghi chú thành công.",
    });
    return handleClose();
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="note_content"
        rules={{ required: "Thông tin ghi chú không được để trống" }}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth>
            <TextField
              multiline
              {...field}
              placeholder="Nhập thông tin ghi chú hoá đơn..."
            />
            {!!error && <ErrorHelperText message={error?.message} />}
          </FormControl>
        )}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 2,
          gap: 1.5,
        }}
      >
        <Button
          size="small"
          onClick={handleClose}
          sx={{
            height: 36,
            minWidth: 120,
          }}
          disabled={submit}
        >
          Huỷ thao tác
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{
            height: 36,
            minWidth: 120,
          }}
          disabled={submit}
          type="submit"
          startIcon={
            submit && <CircularProgress size={14} sx={{ color: "#757575" }} />
          }
        >
          {type === "create" ? "Tạo Mới" : "cập nhật"}
        </Button>
      </Box>
    </Box>
  );
};

export default InvoiceNoteForm;
