import PopperAction from '@components/PopperAction';
import { MoreVertOutlined } from '@mui/icons-material'
import { Box, IconButton, Popper } from '@mui/material'
import React, { useMemo, useState } from 'react'
import ScopeActionUIs from './UI';

const INITIAL_SCOPE_ACTIONS = [
    { icon: "EmotionSentiment", name: "assignee", label: "Người Được Phép Truy Cập" },
    { icon: "Delete", name: "destroy", label: "Huỷ Nhóm Quyền" }
]

const ScopeActions = ({ scope = {}, actions = [] }) => {
    const [popper, setPopper] = useState({ anchorEl: undefined, data: undefined, actions: [] });
    const [action, setAction] = useState({ open: false, name: undefined, data: undefined });

    const onClosePopper = () => setPopper((prev) => ({ ...prev, anchorEl: undefined, data: undefined, actions: [] }));
    const onCloseAction = () => setAction({ open: false, name: undefined, data: undefined });



    const rebuildActions = useMemo(() => {
        let actionBuilders = INITIAL_SCOPE_ACTIONS?.filter((item) => actions?.includes(item?.name))?.map((item) => {
            return ({
                ...item,
                handleClick: (row) =>
                    setAction({
                        open: true,
                        name: item?.name,
                        data: row,
                    }),
            })
        })
        return actionBuilders
    }, [actions])


    const renderComponents = (ui = '') => {
        if (!ui) return null;
        const Component = ScopeActionUIs[ui]
        if (!Component) return null
        const componentProps = {
            scopeId: scope?.scope_id,
            scope: scope,
            onClose: onCloseAction
        }

        return <Component {...componentProps} />
    }
    if (!rebuildActions || rebuildActions?.length === 0) return null

    return (
        <Box
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
            {action?.open && renderComponents(action?.name)}
            {popper?.anchorEl !== undefined && (
                <Popper
                    anchorEl={popper?.anchorEl}
                    sx={{
                        zIndex: 99,
                    }}
                    open={Boolean(popper.anchorEl)}
                    onMouseLeave={onClosePopper}
                >
                    <PopperAction {...popper} handleClose={onCloseAction} />
                </Popper>
            )}
            <IconButton
                size="small"
                onClick={(event) => {
                    setPopper((prev) => ({
                        ...prev,
                        anchorEl: event?.currentTarget,
                        data: scope,
                        actions: rebuildActions,
                    }));
                }}
            >
                <MoreVertOutlined fontSize="small" />
            </IconButton>
        </Box>
    )
}

export default ScopeActions