import ProductApi from "@/apis/beta/Product/ProductApi";
import { buildQueryFilterCondition } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import NodeIcon from '@mui/icons-material/InstallDesktopOutlined';
import { Box, FormControl, IconButton } from "@mui/material";
import { useCallback, useState } from "react";

const Product = ({ disabled = false, queries = '', onSelect = () => { }, ...props }) => {

    const [state, setState] = useState("");

    const debouncedSearchQuery = useDebounce(state, 500);

    const filterableProduct = useCallback(() => {
        if (!debouncedSearchQuery || debouncedSearchQuery.trim() === "")
            return null;
        const fieldQueries = [
            {
                name: "product_name",
                operator: "$contains",
                value: debouncedSearchQuery,
            },
        ];
        return buildQueryFilterCondition("$or", 0, fieldQueries);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery]);

    const {
        data: { products },
        isFetching,
    } = ProductApi.ListProducts({
        query: queries,
        searchQuery: filterableProduct(),
    });

    return (
        <Box sx={{
            width: {
                xs: '100%',
                sm: 'calc((100% - 16px * 2) / 2)',
                md: 'calc((100% - 16px * 2) / 3)'
            },
            bgcolor: "#FFFFFF00",
            border: '1px solid rgb(26, 35, 126)',
            borderRadius: '4px',
            p: '8px 16px',
            display: 'flex',
            flexDirection: 'column',
            gap: 0.75
        }}>
            <IconButton color='primary' size='large' sx={{
                m: 'auto auto auto 0'
            }}>
                <NodeIcon fontSize='large' />
            </IconButton>
            <FormControl fullWidth>
                <SingleSelectCustomize
                    disabled={disabled}
                    loading={isFetching}
                    options={products}
                    onChange={async (event, newValue) => {
                        return await onSelect(newValue || null)
                    }}
                    compareAttributes={["product_id", "product_name"]}
                    formatCollection={{
                        value: "product_id",
                        label: "product_name",
                    }}
                    input={{
                        label: props?.label || "Cloud Service Node (k8s) *",
                        placeholder: "Chọn sản phẩm/ dịch vụ...",
                        value: state,
                        onChange: (e) => setState(e?.target?.value || ""),
                    }}
                />
            </FormControl>
        </Box>
    );
};

export default withSuspense(Product, MuiSkeleton["TextField"]);
