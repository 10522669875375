import { handleRedirectOpenLink } from '@/functions'
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
const ModalConfirmRedirect = ({
    close,
    data = {}
}) => {
    return (
        <Dialog open={true} fullWidth>
            <DialogTitle>
                {data?.dialog?.title}
            </DialogTitle>
            <DialogContent>
                <Typography component="div" sx={{ flexWrap: 'wrap', textAlign: "justify" }}>
                    {data?.dialog?.content}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant='text'
                    onClick={() => !data?.close ? close() : data.close()}
                    size="small"
                    sx={{ height: 36 }}
                >Đóng</Button>
                <Link to={data?.redirect || ''} target="_blank" onClick={(event) => {
                    event.preventDefault()
                    close()
                    if (!!data?.clicker) {
                        return data?.clicker()
                    } else {
                        return handleRedirectOpenLink(data?.redirect)
                    }
                }}>
                    <Button variant='outlined' sx={{
                        height: 36,
                        minWidth: 150
                    }}
                        endIcon={<ArrowRightAltOutlinedIcon fontSize='small' />}
                        size="small"
                    >
                        Tiếp Tục
                    </Button>
                </Link>
            </DialogActions>
        </Dialog>
    )
}

export default ModalConfirmRedirect