import { BaseComponents } from "@components/Components";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { TextField } from "@mui/material";

const BaseContactComponents = {
  TextField: ({ ...props }) => (
    <TextField
      size="small"
      InputLabelProps={{ shrink: true }}
      placeholder="Nhập thông tin..."
      {...props}
    />
  ),
  SingleSelect: ({ onChange, ...props }) => (
    <SingleSelectCustomize
      {...props}
      input={{
        label: props?.label,
        placeholder: `Chọn...`,
      }}
      value={props?.options?.find((x) => x?.value === props?.value) || null}
      onChange={(event, newValue) => {
        onChange(newValue?.value || "");
      }}
    />
  ),
  ServiveInteresting: BaseComponents.ServiceInteresting,
};
export default BaseContactComponents;
