import { encodeBase64, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class BusinessApi {
    List = ({
        query = null,
        searchQuery = null,
        page = 1,
        pageSize = 20
    }) => {
        return useQueryWithCustomized(
            ["business.list", query, searchQuery, page, pageSize],
            async () => {
                return await requestWithToken(
                    `${API_V3_URL}/businesses?${query ? `&filters=${encodeBase64(query)}` : ''}${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ''}&page=${page}&page_size=${pageSize}`
                )
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data;
                    })
                    .catch((error) => {
                        return error.response;
                    })
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
                enabled: !!localStorage.getItem("SA"),
            }
        );
    }

    Create = async (data) => {
        return await requestWithToken(`${API_V3_URL}/businesses`, "POST", {
            ...data
        }, {}, {})
            .then(({ data }) => {
                if (data.error) return data;
                return data
            })
            .catch((error) => {
                return error?.response
            })
    }

    Update = async ({ id, data }) => {
        return await requestWithToken(`${API_V3_URL}/businesses/${id}`, "PUT", {
            business: { ...data }
        }, {}, {})
            .then(({ data }) => {
                if (data.error) return data;
                return data?.data
            })
            .catch((error) => {
                return error?.response
            })
    }

    Cancel = async (id, feedback) => {
        return await requestWithToken(`${API_V3_URL}/businesses/${id}/cancel`, "PUT", {
            business_messages: feedback
        }, {}, {})
            .then(({ data }) => {
                if (data.error) return data;
                return data
            })
            .catch((error) => {
                return error?.response
            })
    }

    Detail = ({ id }) => {
        return useQueryWithCustomized(
            ["business.show", id],
            async () => {
                return await requestWithToken(
                    `${API_V3_URL}/businesses/${id}`
                )
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data;
                    })
                    .catch((error) => {
                        return error.response;
                    })
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
                enabled: !!localStorage.getItem("SA"),
            }
        );
    }

    LeaderConfirm = async (id, data) => {
        return await requestWithToken(`${API_V3_URL}/businesses/${id}/leader_confirm`, "PUT", {
            business: { ...data }
        }, {}, {})
            .then(({ data }) => {
                if (data.error) return data;
                return data
            })
            .catch((error) => {
                return error?.response
            })
    }

    CurrentRoles = () => {
        return useQueryWithCustomized(
            ["current_role.business_role"],
            async () => {
                return await requestWithToken(
                    `${API_V3_URL}/businesses/current_roles`
                )
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data;
                    })
                    .catch((error) => {
                        return error.response;
                    })
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
                enabled: !!localStorage.getItem("SA"),
            }
        );
    }

    Metting = async ({ customerId }) => {
        return await requestWithToken(`${API_V3_URL}/businesses/business_metting_customer`, "POST", {
            business: { customer_id: customerId }
        }, {}, {})
            .then(({ data }) => {
                if (data.error) return data;
                return data?.data?.meetings
            })
            .catch((error) => {
                return error?.response
            })
    }

    Approve = async ({ businessId, data = {} }) => {
        return await requestWithToken(`${API_V3_URL}/businesses/${businessId}/approve`, "PUT", {
            business: { ...data }
        }, {}, {})
            .then(({ data }) => {
                if (data.error) return data;
                return data
            })
            .catch((error) => {
                return error?.response
            })
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new BusinessApi();
