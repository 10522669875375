import CreateContract from "./CreateContract";
import CreateSubcontract from "./CreateSubcontract";
import EditContract from "./EditContract";
import RetensionContract from "./RetentionContract";
import CreateContractProcess from "./CreateProcess";
import Create from "./Create";



const ContractActions = {
    Create,
    Clone: CreateContract,
    Retension: RetensionContract,
    Edit: EditContract,
    CreateSubcontract: CreateSubcontract,
    CreateContractProcess: CreateContractProcess
}
export default ContractActions