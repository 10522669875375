import { Box, Typography } from '@mui/material'
import React from 'react'

const WelcomeMessage = ({
    message
}) => {
    return (
        <Box sx={{
            display: "flex",
            justifyContent: 'center'
        }}>
            <Typography sx={{ fontSize: 14, m: 'auto 0' }}>
                {message?.text}
            </Typography>
        </Box>
    )
}

export default WelcomeMessage