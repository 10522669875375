import PopperAction from '@components/PopperAction';
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined'
import { Box, IconButton, Popper } from '@mui/material'
import React, { useState } from 'react'
import Update from '../../Update';

const PartnerSubcontractActions = ({ data }) => {
    const [anchorEl, setAnchorEl] = useState(undefined);
    const [action, setAction] = useState({
        open: false,
        name: undefined,
        data: undefined,
    });

    const onCloseAction = () =>
        setAction({ open: false, name: undefined, data: undefined });


    const [actions] = useState([
        {
            order: 1,
            name: "edit",
            label: "Chỉnh Sửa",
            icon: "Edit",
            handleClick: (data) => {
                setAction((prev) => ({
                    ...prev,
                    open: true,
                    data: data,
                    name: "edit",
                }));
                setAnchorEl(undefined);
            },
        },
    ])
    return (
        <Box>
            {action?.open && action?.name === "edit" && (
                <Update data={data} handleClose={onCloseAction} />
            )}
            {anchorEl !== undefined && (
                <Popper
                    anchorEl={anchorEl}
                    sx={{
                        zIndex: 99,
                    }}
                    open={Boolean(anchorEl)}
                    onMouseLeave={() => setAnchorEl(undefined)}
                >
                    <PopperAction
                        actions={actions || []}
                        handleClose={onCloseAction}
                    />
                </Popper>
            )}
            <IconButton size='small'
                onClick={(event) => setAnchorEl(event?.currentTarget)}
            >
                <MoreVertOutlined fontSize='small' />
            </IconButton>
        </Box>
    )
}

export default PartnerSubcontractActions