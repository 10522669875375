import { Box, Collapse, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

const DomainDNS = () => {

    const { control, watch } = useFormContext()

    const { fields } = useFieldArray({
        control: control,
        name: "domain.domain_extra_fields.dns"
    })

    return (
        <Box sx={{ mt: 1 }}>
            <Typography sx={{ fontSize: 14, fontWeight: "medium" }}>
                Thông tin DNS
            </Typography>
            <Controller
                name="domain.dns_option"
                control={control}
                render={({ field: { value, onChange } }) => {
                    return (
                        <FormControl fullWidth>
                            <RadioGroup
                                value={value}
                                onChange={onChange}
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: "column", md: 'row' },
                                    gap: 0.5
                                }}>
                                {[
                                    { value: "default", label: "DNS mặc định" },
                                    { value: "custom", label: "DNS khác" },
                                ].map((item, index) => {
                                    return (
                                        <FormControlLabel
                                            key={index}
                                            {...item}
                                            control={<Radio size='small' />}
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </FormControl>
                    )
                }}
            />
            <Collapse in={watch("domain.dns_option") !== "default"}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: "column",
                    gap: 1.5,
                    m: '16px 0'
                }}>
                    {fields.map((field, index) => {
                        return (
                            <Controller
                                control={control}
                                name={`domain.domain_extra_fields.dns.[${index}].name`}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={`DNS ${index + 1}`}
                                                InputLabelProps={{ shrink: true }}
                                                value={value}
                                                size='small'
                                                onChange={onChange}
                                                placeholder="Nhập DNS..."
                                            />
                                        </FormControl>
                                    )
                                }}
                                key={field.id}
                            />
                        )
                    })}
                </Box>
            </Collapse>
        </Box>
    )
}

export default DomainDNS