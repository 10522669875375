import ServiceSystemApi from "@/apis/beta/Service/ServiceSystemApi";
import withPermission from "@/hocs/withPermission";
import { useNotificationContext } from "@contexts/notification";
import { MailOutlined } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import React from "react";

const Sendmail = ({ serviceId = -1, submit, setSubmit }) => {
  const { setNotification } = useNotificationContext();

  const handleSendmail = async () => {
    setSubmit("sendmail");
    const response = await ServiceSystemApi.NotificationMailer({ serviceId });
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }
    setSubmit(null);
    return setNotification({
      open: true,
      message: response?.data?.message || "Gửi mail thành công",
      severity: "success",
    });
  };
  return (
    <Button
      size="small"
      variant="outlined"
      disabled={!!submit}
      startIcon={
        submit === "sendmail" ? (
          <CircularProgress size={14} sx={{ color: "#777680" }} />
        ) : (
          <MailOutlined fontSize="small" />
        )
      }
      onClick={handleSendmail}
    >
      Gửi Email
    </Button>
  );
};

export default withPermission(Sendmail, {
  action: "service-system-sendmail",
  type: "Button",
  feature: "service",
});
