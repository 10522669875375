import ErrorHelperText from "@components/shared/ErrorHelperText";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { FormControl } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const SERVICE_PAYMENTS = [
  { value: false, label: "Trả Sau" },
  { value: true, label: "Trả Trước" },
];

const ProductPrepaid = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      name="product_paid"
      control={control}
      render={({ field: { value, onChange } }) => {
        const selected =
          SERVICE_PAYMENTS?.find((v) => v.value === value) || null;
        return (
          <FormControl fullWidth>
            <SingleSelectCustomize
              options={SERVICE_PAYMENTS}
              value={selected}
              input={{
                label: "Hình thức thanh toán",
                placeholder: "Chọn hình thức thanh toán...",
              }}
              onChange={(event, newValue) => onChange(newValue?.value || -1)}
            />
            {!!errors?.product_paid && (
              <ErrorHelperText message={errors?.product_paid?.message} />
            )}
          </FormControl>
        );
      }}
    />
  );
};
export default ProductPrepaid;
