import ContractApi from '@/apis/beta/Contract/ContractApi'
import { queryClientRefetcher } from '@/functions'
import withPermission from '@/hocs/withPermission'
import ModalConfirm from '@components/shared/ModalConfirm'
import { Button } from '@mui/material'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

const CreateContract = ({ contractId = -1, contract = {} }) => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const [open, setOpen] = useState(false)
    const { mutateAsync } = useMutation(ContractApi.Clone)

    const onSubmit = async () => {
        const response = await mutateAsync({ contractId })
        if (!response || response?.errors) {
            return {
                status: 400,
                message: response?.errors?.[0]?.message || 'Failed when create contract.',
            }
        }
        return {
            status: 200,
            message: 'Tạo hợp đồng mới thành công.',
            action: navigate(`/customer_contracts/${response?.data?.contract?.contract_id}`, { replace: true })
        }
    }
    const callbackContract = async () => {
        try {
            queryClientRefetcher(queryClient, ['contract.list', 'contract.detail'])
        } catch (error) {
            console.error(`error` + error.message)
        }
    }
    return (
        <>
            {open && <ModalConfirm dialog={{
                title: 'Gia Hạn Hợp Đồng với số Hợp Đồng Mới',
                content: `Dịch vụ ${contract?.service_name} của khách hàng ${contract?.customer_company_name || contract?.customer_name} sẽ được gia hạn với một số hợp đồng mới. Nhấn chọn đồng ý để tạo hợp đồng mới.`
            }}

                handleSubmit={onSubmit}
                handleClose={() => setOpen(false)}
                callback={callbackContract}
            />}
            <Button size='small' variant='contained' disabled={open} onClick={() => setOpen(true)}>
                Gia Hạn Hợp Đồng Với Số HĐ Mới
            </Button>
        </>
    )
}

export default withPermission(CreateContract, { feature: 'contract', action: 'customer-contract-clone', type: 'Button' })