import { Fragment, useState } from 'react'
// import AnalyticHeader from './Header'
// import BandwidthChart from './BandwidthChart'
import dayjs from 'dayjs'
// import { dateToString } from '../services'
// import LocationTrafficChart from './LocationTrafficChart'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import TimeRanges from '@components/Templates/Conversant(CDN)/Analytics/Header/TimeRanges'
import { Box } from '@mui/material'
import GcoreBoard from './PreviewBoard'
import Projects from './Projects'
import { resolveServiceGcoreProjects } from './services'
import CSVDownload from './CSVDownload'

const today = dayjs()
const ClusterAnalytic = ({ service }) => {
    const [state, setState] = useState({
        value: '30d',
        projects: [],
        gte: today?.subtract(30, 'days'),
        lte: today,
        response_format: 'json',
    })

    const { data: [projects], isLoading: projectPending } = resolveServiceGcoreProjects(service?.service_creatable_uuid)
    return (
        <Fragment>
            <Box sx={{
                mb: 1,
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: { xs: 2, md: 1 },
                justifyContent: 'flex-end'
            }}>
                <TimeRanges times={state} setTimes={setState} />
                {!projectPending && <Projects projects={projects?.data?.data || []} onChange={setState} value={state} />}
                <CSVDownload uuid={service?.service_creatable_uuid} payload={state} />
            </Box>
            {!projectPending && <GcoreBoard uuid={service?.service_creatable_uuid} payload={state} />}
        </Fragment>
    )
}

export default withSuspense(ClusterAnalytic, MuiSkeleton["LoadingDotBox"])