import EChart, { TOOLTIP_OPTIONS } from "@/components/EChart";
import { Box, Typography } from "@mui/material";
import { grey, indigo } from "@mui/material/colors";
import { renderToString } from 'react-dom/server';

import { formatVolumeUnit } from "@/functions";

import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import dayjs from "dayjs";
import { resolveServiceAnalyticStorageUsages } from "../../services";

const MarkerSolid = renderToString(<Box sx={{ display: 'inline-block', width: 10, height: 10, bgcolor: "#2196f3", my: 'auto', mr: 1 }} />)

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const StorageChart = (props) => {

    const {
        data: [storage],
        isLoading
    } = resolveServiceAnalyticStorageUsages(props?.service?.service_uuid, props?.query)

    const options = {
        tooltip: {
            trigger: 'axis',
            ...TOOLTIP_OPTIONS,
            formatter: function (params) {
                const [current] = params
                // eslint-disable-next-line no-mixed-operators
                return `${MarkerSolid}<b>${current?.name}</b> ${current?.value !== 0 && formatVolumeUnit(current?.value * 1000 ** 3) || '-'}<br/>`
            }
        },
        xAxis: {
            type: 'category',
            data: MONTHS
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: Array.from({ length: 12 }, (_, i) => i + 1)?.map((i) => {
                    const value = ((storage?.find(item => item?.month === i)?.diskUsage || 0) / 1000 ** 3)
                    return {
                        value,
                        itemStyle: {
                            color: (dayjs().month() + 1) === i ? indigo[900] : 'rgb(84, 112, 198)'
                        }
                    }
                }),
                type: 'bar'
            }
        ]
    }
    return (
        <>
            <Box sx={{ borderBottom: `1px solid ${grey[300]}`, pb: 1 }}>
                <Typography fontSize={14} fontWeight={700} data-ui={`SwiftFederation.Dashboard.StorageChart.[${props?.service?.service_uuid}]`}>
                    Storage Monthly Usage (GB)
                </Typography>
            </Box>
            {(!isLoading && storage) ? <Box sx={{ mt: 3 }}>
                <EChart options={options} />
                <Box sx={{ textAlign: 'center' }}>
                    <Box sx={{ borderRadius: 1, bgcolor: grey[100], px: 1.5, py: 1, display: 'inline-block' }}>
                        <Typography fontSize={13}>
                            Peak Disk Usage ({MONTHS[dayjs().get('month')]}): {formatVolumeUnit(storage?.find(x => x?.month === dayjs().get('month') + 1)?.diskUsage)}
                        </Typography>
                    </Box>
                </Box>
            </Box> : <MuiSkeleton.GradientCircularProgress />}
        </>
    )
}

export default withSuspense(StorageChart, MuiSkeleton["GradientCircularProgress"])