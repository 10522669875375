import ContractProcessApi from "@/apis/beta/Contract/ContractProcessApi";
import FileApi from "@/apis/shared/File/FileApi";
import BorderLinearProgress from "@/components/BorderLinearProgress";
import DatePicker from "@/components/DatePicker";
import ErrorHelperText from "@/components/shared/ErrorHelperText";
import SingleDocument from "@/components/shared/SingleDocument";
import { useNotificationContext } from "@/contexts/notification";
import { contractNumberProcessValidator } from "@/libs/validators";
import { BaseComponents } from "@components/Components";
import DragDropFileProcess from "@components/shared/DragDropFileProcess";
import { vestResolver } from "@hookform/resolvers/vest";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  TextField,
  Typography
} from "@mui/material";
import moment from "moment";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

const ProcessForm = ({
  contractNumber = {},
  handleClose,
  type = "contracts",
  subcontract = {},
}) => {

  const [submit, setSubmit] = React.useState(false);
  const { setNotification } = useNotificationContext();

  const [files, setFiles] = React.useState([]);
  const queryClient = useQueryClient();

  const [uploadFiles, handleUploadFiles] = React.useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: vestResolver(contractNumberProcessValidator),
    defaultValues: {
      contract_process_title: "",
      contract_process_deadline: moment(),
      contract_process_problem: "", // mo ta van de,
      contract_process_assignee: process.env.NODE_ENV === 'production' ? [79] : [],
    },
  });

  const fileHandler = async (newFiles) => {
    if (!Array.isArray(newFiles)) {
      newFiles = Array.from(newFiles);
    }

    if (newFiles.length === 0) return;
  
    newFiles.forEach((file) => {
      const extension = file?.name.split(".")[1]?.toLowerCase();
      if (extension === undefined) {
        return setNotification({
          open: true,
          severity: "error",
          message: "Thể loại tập tin không được hỗ trợ",
        });
      }
    });
    return setFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  const onSubmit = async (values) => {
    setSubmit(true);
    let form = { ...values }

    if (files.length > 0) {
      const prefixPathname =
        type === "subcontracts"
          ? "subcontracts/contractProcesses/documents"
          : "contracts/contractProcesses/documents";
      const uploadPromises = files.map(async (file) => {
        const token = await FileApi.GetPresignedTokenAttachment({
          filename: {
            name: file?.name,
            folder: prefixPathname,
          },
        });
        if (!token.token || token.status !== 200) {
          setSubmit(false);
          return setNotification({
            open: true,
            message: "Lấy thông tin xử lý file thất bại",
            severity: "error",
          });
        }
        handleUploadFiles(prev => [...prev, { file, token, loading: true }]);
        const uploaded = await FileApi.UploadAttachmentToStorage({
          token: token?.token,
          file: file,
          handleUploadFile: (updatedFile) => handleUploadFiles(prev => prev.map(f => f.file === file ? { ...f, ...updatedFile } : f)),
        });
        if (!uploaded) {
          setSubmit(false);
          return setNotification({
            open: true,
            message: "Tải dữ liệu thất bại",
            severity: "error",
          });
        }
        return {
          name: file?.name,
          type: file?.type,
          size: file?.size,
          url: ["pub", prefixPathname, token?.filename].join("/"),
        };
      });
      const uploadedFiles = await Promise.all(uploadPromises);
      form = {
        ...form,
        contract_process_document: uploadedFiles,
      };
    }

    let message = "";
    if (type === "contracts") {
      message = `Thêm vấn đề trong hợp đồng ${contractNumber?.contract_number_id} `;
    } else {
      message = `Thêm vấn đề trong phụ lục hợp đồng ${subcontract?.subcontract_code} `;
    }

    await ContractProcessApi.Create(
      contractNumber?.contract_number_stt || subcontract?.subcontract_id,
      {
        contract_process_link: window.location.href,
        contract_process: { ...form },
      },
      type
    ).then((response) => {
      if (!response || response?.status !== 201) {
        return setNotification({
          open: true,
          message: `${message} thất bại.`,
          severity: "error",
        });
      }
      if (type === "contracts") {
        queryClient.refetchQueries(["contract.detail"]);
      } else {
        queryClient.refetchQueries(["subcontract.detail"]);
      }

      return [
        setNotification({
          open: true,
          message: `${message} thành công.`,
          severity: "success",
        }),
        handleClose(),
      ];
    });
  };

  return (
    <Box
      sx={{
        mb: 2,
        height: "100%",
        p: '24px 16px',
        width: {
          xs: '100%',
          md: 650
        }
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography sx={{
        fontSize: { xs: 16, md: 24 },
        fontWeight: "medium",
        mb: 2
      }}>
        Tiến Trình Mới
      </Typography>
      <Typography
        sx={{
          color: "#757575",
          display: 'flex',
          mb: 2
        }}>
        Mã Số Hợp Đồng:{" "}
        <span style={{ color: "#000" }}>
          {contractNumber?.contract_number_id ||
            subcontract?.subcontract_code ||
            "---"}
        </span>
      </Typography>
      <Controller
        name="contract_process_title"
        control={control}
        render={({ field }) => (
          <FormControl sx={{ mb: 2 }} fullWidth>
            <TextField
              {...field}
              label="Tiêu Đề *"
              InputLabelProps={{ shrink: true }}
              error={!!errors?.contract_process_title}
              helperText={errors?.contract_process_title?.message}
              size="small"
              placeholder="Nhập tiêu đề tiến trình..."
            />
          </FormControl>
        )}
      />
      <Controller
        name="contract_process_deadline"
        control={control}
        render={({ field: { value, onChange } }) => (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <DatePicker value={value} onChange={onChange}
              label="Thời Hạn Xử Lý *"
              InputLabelProps={{ shrink: true }}
            />
            {!!errors?.contract_process_deadline && (
              <ErrorHelperText
                message={errors?.contract_process_deadline?.message}
              />
            )}
          </FormControl>
        )}
      />
      <Box>
        <Controller
          name="contract_process_assignee"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <BaseComponents.Employee
                value={value}
                onChange={onChange}
                queries={[
                  {
                    name: "employees.employee_disabled",
                    operator: "$eq",
                    value: 0,
                  },
                ]}
                input={{
                  label: 'Nhân Viên Tiếp Nhận *',
                  placeholder: "Chọn nhân viên tiếp nhận..."
                }} />
            )
          }}
        />
      </Box>
      <Controller
        name="contract_process_problem"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <TextField
              label="Mô Tả Vấn Đề Cần Hỗ Trợ*"
              InputLabelProps={{ shrink: true }}
              multiline
              minRows={3}
              {...field}
              placeholder="Nhập mô tả vấn đề cần hỗ trợ..."
            />
            {!!errors?.contract_process_problem && (
              <ErrorHelperText
                message={errors?.contract_process_problem?.message}
              />
            )}
          </FormControl>
        )}
      />
      <Box aria-label="process.attachments">
        <DragDropFileProcess handler={fileHandler} />
        {files.length > 0 && (
          <React.Fragment>
            {files.map((file, index) => (
              <SingleDocument
                key={index}
                document={file}
                handleDelete={() => setFiles(prev => prev.filter((_, i) => i !== index))}
              />
            ))}
            {Array.isArray(uploadFiles) && uploadFiles.some(file => file.loading) && (
              <BorderLinearProgress value={uploadFiles.find(file => file.loading)?.percen || 0} />
            )}
          </React.Fragment>
        )}
      </Box>
      <Box sx={{
        mt: 2,
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: { xs: 'column', md: 'row' },
        gap: 2
      }}>
        <Button
          variant="text"
          size="small"
          onClick={handleClose}
          disabled={submit}
        >
          Huỷ Thao Tác
        </Button>
        <Button
          variant="outlined"
          size="small"
          type="submit"
          startIcon={
            submit && <CircularProgress size={14} sx={{ color: "#757575" }} />
          }
        >
          Tạo Tiến Trình
        </Button>
      </Box>
    </Box>
  );
};
export default ProcessForm;
