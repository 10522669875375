import DayoffApi from "@/apis/beta/Employee/DayoffApi";
import DataGrid from "@/components/DataGrid";
import { useNotificationContext } from "@/contexts/notification";
import { format_date_short, queryClientRefetcher } from "@/functions";
import {
  DAYOFF_STATUS_CONFIRM_TYPES,
  DAYOFF_TIME_OPTIONS,
  DAYOFF_TYPE_TYPES,
} from "@/libs/constants";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useQueryClient } from "react-query";

const ModalConfirm = ({ type = "leader-confirm", handleClose, data }) => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();

  const [submit, setSubmit] = React.useState(false);

  const [feedback, setFeedback] = React.useState({
    description: "Oke",
    option: true,
  });

  const [error, setError] = React.useState(false);
  const onSubmit = async () => {
    if (type !== "leader-confirm") {
      return setNotification({
        open: true,
        message: "Tính năng hiện tại không khả dụng.",
        severity: "error",
      });
    }
    if (feedback?.description === "") return setError(true);
    setSubmit(true);
    const response = await DayoffApi.Approve({
      id: data?.day_off_id,
      data: {
        day_off_feedback_lead: feedback?.description,
        day_off_leader_confirm: feedback?.option,
        day_off_status: !!feedback?.option ? 2 : 3,
      },
    });
    setSubmit(false);
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        severity: "error",
        message:
          response?.errors?.[0]?.message ||
          response?.error ||
          "Xác nhận phiếu nghỉ thất bại",
      });
    }
    queryClientRefetcher(queryClient, ["all_day_offs.list", "day_off.detail"]);
    setNotification({
      open: true,
      severity: "success",
      message: response?.data?.message || "Xác nhận phiếu nghỉ thành công",
    });
    return handleClose();
  };

  const handleChangeInput = (event) => {
    error && setError(false);
    return setFeedback((prev) => ({
      ...prev,
      description: event?.target?.value,
    }));
  };

  const [columns] = React.useState([
    {
      field: "day_off_number",
      headerName: "Số Ngày Nghỉ",
      width: 120,
    },
    {
      field: "day_off_option",
      headerName: "Chi Tiết",
      width: 400,
      renderCell: ({ value }) => (
        <Typography sx={{ fontSize: 14 }}>
          {format_date_short(value?.startdayoff)} {`-`}{" "}
          {
            DAYOFF_TIME_OPTIONS?.find((o) => o?.value === value?.dayoff_option)
              ?.label
          }
        </Typography>
      ),
    },
  ]);
  return (
    <Dialog open={true} fullWidth maxWidth="md">
      <DialogTitle>Duyệt Phiếu Nghỉ</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", mb: 1 }}>
          <Box sx={{ width: "50%", display: "flex" }}>
            <Typography>
              Tên Nhân Viên: {data?.employee?.employee_name}
            </Typography>
          </Box>
          <Box sx={{ width: "50%", display: "flex" }}>
            <Typography>Phòng Ban: {data?.employee_department_name}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", mb: 2 }}>
          <Typography sx={{ mr: 0.5 }}>Loại Phiếu:</Typography>
          <Typography
            color={
              DAYOFF_TYPE_TYPES?.find((t) => t.value === data?.day_off_type)
                ?.value === 0
                ? "primary"
                : "#66bb6a"
            }
          >
            {
              DAYOFF_TYPE_TYPES?.find((t) => t.value === data?.day_off_type)
                ?.label
            }
          </Typography>
        </Box>
        <Paper sx={{ mb: 2 }}>
          <DataGrid
            rows={
              data?.day_off_range_time?.map((day_off, index) => {
                return {
                  id: index,
                  day_off_number:
                    parseInt(day_off?.dayoff_option) === 2 ? 1 : 0.5,
                  day_off_option: { ...day_off },
                };
              }) || []
            }
            columns={columns}
            components={{
              Toolbar: "disabled",
              Pagination: "disabled",
            }}
          />
        </Paper>
        <Typography sx={{ mb: 0.5 }}>Lý do xin nghỉ phép:</Typography>
        <TextField multiline fullWidth value={data?.day_off_reason} disabled />
        {type === "bod-confirm" && (
          <React.Fragment>
            <Typography sx={{ mb: 0.5, mt: 2 }}>
              Xác nhận của Trưởng Bộ Phận
            </Typography>
            <FormControl fullWidth disabled>
              <RadioGroup row value={true}>
                {DAYOFF_STATUS_CONFIRM_TYPES?.map((type) => (
                  <FormControlLabel
                    key={type.value}
                    label={type.value ? " Đồng ý" : "Từ chối"}
                    control={<Radio value={type?.value} size="small" />}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <Typography sx={{ mb: 0.5, mt: 1 }}>Phản hồi</Typography>
            <TextField
              multiline
              fullWidth
              value={data?.day_off_feedback_lead}
              disabled
            />
          </React.Fragment>
        )}
        <Typography sx={{ mb: 0.5, mt: 2 }}>
          Xác nhận của {type === "bod-confirm" ? "BOD/ BOM" : "Trưởng Bộ Phận"}
        </Typography>
        <FormControl fullWidth>
          <RadioGroup
            row
            value={feedback?.option}
            onChange={() =>
              setFeedback((prev) => ({ ...prev, option: !prev?.option }))
            }
          >
            {DAYOFF_STATUS_CONFIRM_TYPES?.map((type) => (
              <FormControlLabel
                key={type.value}
                label={type.value ? " Đồng ý" : "Từ chối"}
                control={<Radio value={type?.value} size="small" />}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Typography sx={{ mb: 0.5, mt: 1 }}>Phản hồi</Typography>
        <FormControl fullWidth>
          <TextField
            multiline
            value={feedback?.description}
            onChange={handleChangeInput}
            placeholder="Nhập phản hồi phiếu nghỉ..."
          />
          {error && (
            <FormHelperText sx={{ color: "#ef5350" }}>
              Vui lòng nhập thông tin phản hồi phiếu.
            </FormHelperText>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          sx={{ height: { xs: 40, md: 42 } }}
          onClick={handleClose}
        >
          Huỷ Thao Tác
        </Button>
        <Button
          variant="contained"
          size="small"
          disabled={submit}
          startIcon={
            submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
          }
          sx={{ height: { xs: 40, md: 42 }, minWidth: 150 }}
          onClick={onSubmit}
        >
          Xác Nhận
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirm;
