import ErrorHelperText from "@/components/shared/ErrorHelperText";
import { FormControl, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const WorkPurpose = () => {
  const { control } = useFormContext();
  return (
    <React.Fragment>
      <Grid item xs={12} md={3.5}>
        <Typography>Mục Đích Công Tác *</Typography>
      </Grid>
      <Grid item xs={12} md={8.5}>
        <Controller
          name="business_content"
          control={control}
          rules={{ required: "Mục đích công tác không được để trống" }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <TextField
                multiline
                value={value}
                onChange={onChange}
                placeholder="Nhập mục đích công tác..."
              />
              {!!error && <ErrorHelperText message={error?.message} />}
            </FormControl>
          )}
        />
      </Grid>
    </React.Fragment>
  );
};

export default WorkPurpose;
