import React from 'react'
import { FormControl } from '@mui/material'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SandboxApi from '@/apis/beta/Sandbox/SandboxApi'

const District = ({
    preSelected,
    onChange,
    province = "",
    error: {
        isError = false,
        message = ""
    } }) => {
    const [districts] = (!province || province === '-1') ? [] : SandboxApi.Districts(province)

    return (
        <FormControl fullWidth>
            <SingleSelectCustomize
                disabled={!province || province === "" || province === "-1"}
                value={districts?.find(x => x?.value === preSelected) || null}
                options={districts}
                input={{
                    label: "Quận/ Huyện",
                    placeholder: "Chọn quận/ huyện..."
                }}
                onChange={onChange}
            />
            {!!isError && <ErrorHelperText message={message} />}
        </FormControl>
    )
}

export default withSuspense(District, MuiSkeleton["TextField"])
