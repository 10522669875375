import List from './List';
import Create from './Create';
import Detail from './Detail';
import Update from './Update';
import CustomerAssignee from './Assign';
import Report from './Management';

import OrderModule from '../Order';

const Assignee = CustomerAssignee["List"]
const Order = OrderModule["Create"]
const POC = OrderModule["POC"]

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  List,
  Create,
  Detail,
  Update,
  Assignee,
  Order,
  Report,
  POC
};
