import CustomizedProgressBars from "@components/BorderLinearProgress";
import DragDropFile from "@components/shared/DragDropFile";
import SingleDocument from "@components/shared/SingleDocument";
import { Box, Collapse, Divider, FormControl, Typography } from "@mui/material";
import { Fragment } from "react";

const Attachment = ({ attachments = [], setAttachments, statePushing }) => {
  const fileHandler = (file) => {
    let temps = attachments || [];
    temps.push(file);
    return setAttachments([...temps]);
  };

  return (
    <Fragment>
      <Divider sx={{ m: "16px 0" }} />
      <Typography sx={{ fontSize: 14, fontWeight: "medium", mb: 1 }}>
        Tập tin đính kèm:
      </Typography>
      <FormControl fullWidth>
        <DragDropFile
          handler={fileHandler}
          attachmentAccept={[
            "application/pdf",
            ".doc",
            ".docx",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ]}
        />
      </FormControl>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
        {attachments?.map((attachment, index) => {
          return (
            <Box
              key={index}
              sx={{
                width: "100%",
              }}
            >
              <SingleDocument
                document={attachment}
                handleDelete={() => {
                  if (attachment?.attachment_id > 0) {
                    return setAttachments((prev) => [
                      ...prev?.slice(0, index),
                      { ...attachment, attachment_closed: true },
                      ...prev?.slice(index + 1),
                    ]);
                  } else {
                    return setAttachments((prev) => [
                      ...prev?.filter((x, i) => i !== index),
                    ]);
                  }
                }}
                sx={{
                  pointerEvents: attachment?.attachment_closed
                    ? "none"
                    : "unset",
                  bgcolor: attachment?.attachment_closed
                    ? "rgba(0, 0, 0, 0.08)"
                    : "unset",
                }}
              />
              <Collapse
                in={
                  !!statePushing &&
                  !!statePushing?.loading &&
                  statePushing?.index === index
                }
              >
                <CustomizedProgressBars value={statePushing?.percen} />
              </Collapse>
            </Box>
          );
        })}
      </Box>
    </Fragment>
  );
};

export default Attachment;
