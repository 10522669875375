import InvoiceApi from "@/apis/beta/Invoice/InvoiceApi";
import { useNotificationContext } from "@contexts/notification";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { useQueryClient } from "react-query";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { INVOICE_MAILER_CC } from "@/libs/constants";

const PrecheckSendmail = ({ onClose, invoiceId }) => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();

  const [submit, setSubmit] = React.useState(false);

  const { data } = InvoiceApi.PrecheckMailer({
    invoiceId,
  });

  const onSubmit = async () => {
    setSubmit(true);
    const response = await InvoiceApi.SendMail({ invoiceId });

    console.log(response);
    setSubmit(false);
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        severity: "error",
        message:
          response?.errors?.[0]?.message ||
          "Gửi hoá đơn thanh toán khách hàng thất bại.",
      });
    }
    try {
      queryClient.refetchQueries(["invoice.mailers.list"]);
      queryClient.refetchQueries(["invoice.loggers"]);
      queryClient.refetchQueries(["invoice.informations"]);
      queryClient.refetchQueries(["invoice.detail"]);
      queryClient.refetchQueries(["invoice.list"]);
    } catch (error) {
      console.log(error);
    }
    setNotification({
      open: true,
      severity: "success",
      message:
        response?.message ||
        "Gửi hoá đơn thanh toán cho khách hàng thành công.",
    });
    return onClose();
  };

  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <DialogTitle>
        Gửi mail thông báo gia hạn/ đối soát dịch vụ
        <Typography>
          Dưới đây là thông tin gửi mail của hoá đơn này, bạn có thể kiểm tra và
          chỉnh sửa nếu có thay đổi/ sai lệch thông tin.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", mb: 1.5, mt: 1, gap: 1 }}>
          <Typography
            sx={{
              fontSize: 14,
              color: "#777680",
            }}
          >
            Người nhận:
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              color: "#00001F",
            }}
          >
            {data?.["receivers"]?.join(", ")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", mb: 1.5, gap: 1 }}>
          <Typography
            sx={{
              fontSize: 14,
              color: "#777680",
            }}
          >
            CC:
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              color: "#00001F",
            }}
          >
            {data?.["cc"]?.join(", ")} {INVOICE_MAILER_CC.join(", ")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", mb: 1.5, gap: 1 }}>
          <Typography
            sx={{
              fontSize: 14,
              color: "#777680",
            }}
          >
            BCC:
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              color: "#00001F",
            }}
          >
            {data?.["bcc"]?.join(", ")}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={submit} size="small">
          Huỷ Thao Tác
        </Button>
        <Button
          onClick={onSubmit}
          variant="contained"
          size="small"
          disabled={submit}
        >
          Xác Nhận & Gửi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withSuspense(PrecheckSendmail, MuiSkeleton["LinearProgress"]);
