import CustomerApi from '@/apis/beta/Customer/CustomerApi'
import { sleep } from '@/functions'
import withPermission from '@/hocs/withPermission'
import ModalConfirm from '@components/shared/ModalConfirm'
import { NotInterestedOutlined } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const CancelCustomer = ({
    disabled = true,
    setDisabled,
    customerId = null,
    customerName = "",
    callback,
    ...customer
}) => {
    const navigate = useNavigate()
    const [submit, setSubmit] = React.useState(false)
    const [openModal, setOpenModal] = React.useState(false)

    const onSubmit = async () => {
        setSubmit(true)

        const response = await CustomerApi.UpdateCSI({
            customerId: customerId,
            data: {
                customer_status: "cool-lead"
            }
        })
        await sleep(500)
        setSubmit(false)
        if (!response || response?.errors) {
            return {
                status: response?.status || 404,
                message: "Huỷ theo dõi khách hàng thất bại."
            }
        }
        return {
            status: 200,
            message: "Huỷ theo dõi khách hàng thành công.",
            action: navigate(`/customers`, { replace: true })
        }
    }

    const handlerClick = () => {
        if (openModal) {
            setDisabled(false)
            return setOpenModal(false);
        }
        setDisabled(true)
        return setOpenModal(true)
    }

    return (
        <React.Fragment>
            {openModal && <ModalConfirm
                callback={callback}
                handleClose={handlerClick}
                handleSubmit={onSubmit}
                dialog={{
                    title: "Trả khách hàng sang trạng thái tự do",
                    content: <Typography component="div">
                        Khách hàng
                        <Typography sx={{ display: 'inline', fontWeight: 'medium', m: 'auto 4px' }}>
                            [{customer?.customer_information?.name}]
                        </Typography>
                        sẽ được trả sang trạng thái tự do để BOD/Leader có thể chỉ định một sale khác tiếp tục tương tác với khách hàng này. Thao tác sẽ không thể được khôi phục, bạn có muốn thực hiện?
                    </Typography>
                }} />}
            <Button size='small'
                variant='outlined'
                fullWidth
                disabled={disabled || submit || customer?.customer_service_information?.customer_status === "cancelled"}
                startIcon={<NotInterestedOutlined fontSize='small' />}
                sx={{ height: { xs: 40, md: 42 } }}
                onClick={handlerClick}
            >
                huỷ theo dõi
            </Button>
        </React.Fragment>
    )
}

export default withPermission(CancelCustomer, {
    feature: "customer",
    action: "cancel",
    type: "Button"
})