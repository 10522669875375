import InvoiceApi from "@/apis/beta/Invoice/InvoiceApi";
import DataGrid from "@/components/DataGrid";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Chip } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";

export const INVOICE_MAILER_STATUS_TYPES = [
  { value: "pending", label: "Chưa gửi", chip: "pending" },
  { value: "completed", label: "Đã gửi", chip: "success" },
];

export const INVOICE_MAILER_REFERRER_TYPES = [
  { value: "StatementInvoice", label: "Đối Soát", chip: "warning" },
  { value: "PaymentInvoice", label: "Hoá Đơn", chip: "success" },
];
const Mailer = () => {
  const { invoice_id: invoiceId } = useParams()

  const { data } = InvoiceApi.InvoiceMailers({
    invoiceId,
    page: 1,
    pageSize: 999,
    query: null,
  });

  const [columns] = useState([
    {
      field: "mailer_subject",
      headerName: "Tiêu Đề",
      valueGetter: ({ row }) => row?.metadata?.subject || 'Vnetwork JSC',
      width: 180,
    },
    {
      field: "metadata.mailer.receivers",
      headerName: "Người Nhận",
      valueGetter: ({ row }) => {
        if (typeof row?.metadata?.mailer?.receivers === "string") {
          return [row?.metadata?.mailer?.receivers || ''];
        }
        return row?.metadata?.mailer?.receivers || [];
      },
      width: 200,
      renderCell: ({ value }) => value?.join(", "),
    },
    {
      field: "mailer_cc",
      headerName: "Email Cc",
      valueGetter: ({ row }) => {
        if (typeof row?.metadata?.mailer?.cc === "string") {
          return [row?.metadata?.mailer?.cc];
        }
        return row?.metadata?.mailer?.cc || [];
      },
      width: 150,
      renderCell: ({ value }) => value?.join(", "),
    },
    {
      field: "metadata.sender",
      headerName: "Người Gửi",
      valueGetter: ({ row }) => {
        if (!row?.metadata?.sender) return "automation-0";
        return (
          JSON.parse(row?.metadata?.sender?.replaceAll("=>", ":") || '{}')
            ?.employee_name || ""
        );
      },
      width: 200,
    },
    {
      field: "task_created_at",
      headerName: "Tạo Lệnh Gửi Lúc",
      width: 150,
      renderCell: ({ value }) =>
        value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "---",
    },
    {
      field: "task_completed_at",
      headerName: "Thời Gian Gửi",
      width: 150,
      renderCell: ({ value }) =>
        value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "---",
    },
    {
      field: "task_referrer_type",
      headerName: "Loại Hoá Đơn",
      width: 120,
      valueGetter: ({ row }) =>
        INVOICE_MAILER_REFERRER_TYPES?.find(
          (x) => x?.value === row?.task_referrer_type
        )?.label || "---",
    },
    {
      field: "task_status",
      headerName: "Trạng Thái",
      width: 120,
      valueGetter: ({ row }) =>
        INVOICE_MAILER_STATUS_TYPES?.find((t) => t?.value === row?.task_status),
      renderCell: ({ value }) => (
        <Chip
          size="small"
          variant={`customize-${value?.chip}`}
          label={value?.label}
        />
      ),
    },
  ]);

  return (
    <Box sx={{ mt: 2 }}>
      <DataGrid
        columns={[...columns]}
        rows={data?.tasks?.map((item, index) => ({ ...item, id: index }))}
        components={{
          Toolbar: "disabled",
          Pagination: "disabled"
        }}
        paginationMode="client"
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(Mailer, {
    feature: "invoice",
    action: "mailers",
    type: "Table"
  }),
  MuiSkeleton["DataGrid"]
);
