import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class JourneyUpdateApi {
  List = ({ customerId, journeyId, pageSize }) => {
    return useQueryWithCustomized(
      ["customers.journeys.updates", customerId, journeyId, pageSize],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/customers/${customerId}/journeys/${journeyId}/updates?page_size=${pageSize}`
        )
          .then(({ data }) => {
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Create = async ({ customerId, journeyId, data }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/journeys/${journeyId}/updates`,
      "POST",
      {
        journey_update: { ...data },
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error?.response;
      });
  };

  Attachment = async ({ journeyId, values, customerId, jUpdateId }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/journeys/${journeyId}/updates/${jUpdateId}/attachment`,
      "PUT",
      {
        attachment: values,
      }
    );

    return response.data;
  };

  Update = async ({ customerId, journeyId, journeyUpdateId, data }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/journeys/${journeyId}/updates/${journeyUpdateId}`,
      "PUT",
      {
        journey_update: { ...data },
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error?.response;
      });
  };
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new JourneyUpdateApi();
