import ServiceTimeApi from "@/apis/beta/Service/ServiceTimeApi";
import { queryClientRefetchScope } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import DatePicker from "@components/DatePicker";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useNotificationContext } from "@contexts/notification";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

const ServiceStartdate = ({ service, onClose }) => {
    const queryClient = useQueryClient();
    const { setNotification } = useNotificationContext();

    const { mutateAsync, isLoading } = useMutation(ServiceTimeApi.ServiceStartdate)

    const { control, handleSubmit } = useForm({
        defaultValues: {
            service_start_date: service?.service_start_date
        },
    });

    const onSubmit = async (values) => {
        const response = await mutateAsync({
            serviceId: service?.service_id,
            data: { ...values }
        });

        if (!response || response?.errors)
            return setNotification({
                open: true,
                message: response?.errors?.[0]?.message,
                severity: "error",
            });

        setNotification({
            open: true,
            message: `Cập nhật ngày bắt đầu dịch vụ thành công.`,
            severity: "success",
        });
        queryClientRefetchScope(queryClient, 'service')
        return onClose();
    };
    return (
        <Dialog open={true} maxWidth="md" fullWidth>
            <DialogTitle>Thay Đổi Ngày Bắt Đầu Dịch Vụ</DialogTitle>
            <DialogContent>
                <Controller
                    name="service_start_date"
                    control={control}
                    rules={{ required: 'Vui lòng chọn ngày bắt đầu dịch vụ...' }}
                    render={({ field: { value, onChange }, fieldState: { error } }) => {
                        return (
                            <FormControl fullWidth sx={{ mt: 1.5 }}>
                                <DatePicker
                                    value={value}
                                    onChange={onChange}
                                    label="Ngày bắt đầu sử dụng dịch vụ *"
                                />
                                {!!error && <ErrorHelperText message={error?.message} />}
                            </FormControl>
                        );
                    }}
                />
                <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end", gap: 2 }}>
                    <Button
                        size="small"
                        variant="outlined"
                        disabled={isLoading}
                        onClick={onClose}
                    >
                        Huỷ Thao Tác
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        disabled={isLoading}
                        startIcon={isLoading && <CircularProgress size={14} />}
                    >
                        Lưu Thay Đổi
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default withSuspense(withPermission(ServiceStartdate, {
    feature: 'service',
    action: 'update',
    type: 'Button'
}), MuiSkeleton["LinearProgress"]);
