import CVApi from "@/apis/beta/Employee/CVApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import DatePicker from "@components/DatePicker";
import HeaderTitle from "@components/Title/HeaderTitle";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { FileUpload } from "@mui/icons-material";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import SelectSourceCv from "../Shared/SelectSourceCv";
import { DEPARTMENT, TYPES_OF_POSITION } from "../shared";

const New = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    setError,
    register,
    clearErrors,
  } = useForm({
    defaultValues: {
      cv_source_id: "",
      types_of_position: "",
      department: "",
      job_title: "",
      name: "",
      email: "",
      phone: "",
      address: "",
      file: "",
      about: "",
      day_of_birth: "",
    },
  });

  const { data: jobs, isLoading } = useQueryWithCustomized(
    ["get-job"],
    async () => {
      const response = await fetch(`${process.env.REACT_APP_JOBS}`);
      const data = await response.json();
      return data;
    }
  );

  const dataInfo = [
    {
      label: "Name",
      value: (
        <Controller
          rules={{ required: { value: true, message: "Required" } }}
          control={control}
          name="name"
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              fullWidth
              helperText={errors?.name?.message}
              error={!!errors?.name}
            />
          )}
        />
      ),
    },
    {
      label: "Email",
      value: (
        <Controller
          rules={{
            required: { value: true, message: "Required" },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
          }}
          control={control}
          name="email"
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              fullWidth
              helperText={errors?.email?.message}
              error={!!errors?.email}
            />
          )}
        />
      ),
    },
    {
      label: "Day Of Birth",
      value: (
        <Controller
          control={control}
          name="day_of_birth"
          render={({ field }) => (
            <FormControl fullWidth>
              <DatePicker {...field} size="small" fullWidth />
              {/* {errors?.day_of_birth?.message && (
                <ErrorHelperText message={errors?.day_of_birth?.message} />
              )} */}
            </FormControl>
          )}
        />
      ),
    },
    {
      label: "Phone",
      value: (
        <Controller
          rules={{
            required: { value: true, message: "Required" },
            pattern: {
              value: /((09|03|07|08|05)+([0-9]{8})\b)/,
              message: "Invalid phone number",
            },
          }}
          control={control}
          name="phone"
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              fullWidth
              helperText={errors?.phone?.message}
              error={!!errors?.phone}
            />
          )}
        />
      ),
    },
    {
      label: "Address",
      value: (
        <Controller
          rules={{ required: { value: true, message: "Required" } }}
          control={control}
          name="address"
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              fullWidth
              helperText={errors?.address?.message}
              error={!!errors?.address}
            />
          )}
        />
      ),
    },
    {
      label: "Position Applied",
      value: (
        <Controller
          rules={{ required: { value: true, message: "Required" } }}
          control={control}
          name="job_title"
          render={({ field }) => (
            <FormControl fullWidth>
              <Select
                {...field}
                size="small"
                disabled={isLoading}
                fullWidth
                error={!!errors?.job_title}
              >
                {jobs?.data?.map(({ id, attributes }) => (
                  <MenuItem value={attributes?.title} key={id}>
                    {attributes?.title}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ color: red["400"] }}>
                {errors?.job_title?.message}
              </FormHelperText>
            </FormControl>
          )}
        />
      ),
    },
    {
      label: "Source",
      value: (
        <Controller
          rules={{ required: { value: true, message: "Required" } }}
          control={control}
          name="cv_source_id"
          render={({ field: { onChange, value, ...field } }) => {
            return (
              <SelectSourceCv field={field} value={value} onChange={onChange} />
            );
          }}
        />
      ),
    },
    {
      label: "Types of Position",
      value: (
        <Controller
          rules={{ required: { value: true, message: "Required" } }}
          control={control}
          name="types_of_position"
          render={({ field }) => (
            <FormControl fullWidth>
              <Select
                {...field}
                size="small"
                fullWidth
                error={!!errors?.types_of_position}
              >
                {TYPES_OF_POSITION.map((item) => (
                  <MenuItem value={item?.value} key={item?.value}>
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ color: red["400"] }}>
                {errors?.types_of_position?.message}
              </FormHelperText>
            </FormControl>
          )}
        />
      ),
    },
    {
      label: "Department",
      value: (
        <Controller
          rules={{ required: { value: true, message: "Required" } }}
          control={control}
          name="department"
          render={({ field }) => (
            <FormControl fullWidth>
              <Select
                {...field}
                size="small"
                fullWidth
                error={!!errors?.department}
              >
                {DEPARTMENT.map((item) => (
                  <MenuItem value={item?.value} key={item?.value}>
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ color: red["400"] }}>
                {errors?.department?.message}
              </FormHelperText>
            </FormControl>
          )}
        />
      ),
    },
  ];

  const navigate = useNavigate();

  const {
    mutateAsync: createNewCV,
    isLoading: createNewCVLoading,
    notify,
  } = useMutationWithNotification(CVApi.CreateNewCV);

  const onSubmit = async (values) => {
    const newValues = {
      ...values,
      cv_source_id: values?.cv_source_id?.value,
    };

    const formData = new FormData();

    for (const key in newValues) {
      formData.append(key, newValues[key]);
    }

    formData.append("type_file", "file");

    const response = await createNewCV(formData);

    if (!response?.errors) {
      notify("success", "Thêm CV thành công");
      navigate("/resume");
    }
  };

  const handleSelectFile = () => {
    const input = document.createElement("input");

    input.type = "file";

    input.click();

    input.onchange = (event) => {
      const [file] = event.target.files;

      if (file.size / 1000000 > 100) {
        return setError("file", { message: "Only accept files under 100MB!" });
      }

      clearErrors("file");

      setValue("file", file);

      input.remove();
    };
  };

  useEffect(() => {
    register("file", {
      required: { value: true, message: "File is required" },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <HeaderTitle>Thêm CV mới</HeaderTitle>
      <Paper
        sx={{ p: 3, mb: 1, mt: 2 }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          {dataInfo
            ?.filter((item) => !item?.hidden)
            ?.map((item) => (
              <Grid key={item?.label} item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Typography sx={{ mb: 1, color: "#777680" }}>
                  {item?.label}:
                </Typography>
                <Typography sx={{ color: "#00000F" }}>{item?.value}</Typography>
              </Grid>
            ))}
        </Grid>

        <Box mt={2}>
          <Typography sx={{ mb: 1, color: "#777680" }}>File:</Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <Button
              onClick={handleSelectFile}
              variant="outlined"
              size="small"
              fullWidth
            >
              <FileUpload />
              <Typography fontSize={14} sx={{ ml: 1 }}>
                Chọn file định dạng PDF, có dung lượng dưới 10MB
              </Typography>
            </Button>

            {watch("file") && (
              <Button fullWidth variant="contained" size="small">
                <PictureAsPdfOutlinedIcon />
                <Typography fontSize={14} sx={{ ml: 1 }}>
                  {watch("file")?.name}
                </Typography>
              </Button>
            )}
          </Box>

          {errors?.file?.message && (
            <FormHelperText sx={{ color: red["400"] }}>
              {errors?.file?.message}
            </FormHelperText>
          )}
        </Box>

        <Box mt={2}>
          <Typography sx={{ mb: 1, color: "#777680" }}>About:</Typography>
          <Controller
            control={control}
            name="about"
            render={({ field }) => <TextField multiline {...field} />}
          />
        </Box>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2 }}
          disabled={createNewCVLoading}
          startIcon={createNewCVLoading && <CircularProgress size={14} />}
        >
          Thêm CV
        </Button>
      </Paper>
    </Box>
  );
};

export default withSuspense(
  withPermission(New, { feature: "resume", action: "create" }),
  MuiSkeleton["GridInformation"]
);
