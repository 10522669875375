import { Chip } from '@mui/material'
import React from 'react'
import { DAYOFF_STATUS_TYPES } from '@/libs/constants'

const FeedbackChip = ({
    isConfirm = false,
    status = 0
}) => {

    const [leaderStatus, setLeaderStatus] = React.useState(DAYOFF_STATUS_TYPES.find(stt => stt?.value === status))

    React.useEffect(() => {
        setLeaderStatus(DAYOFF_STATUS_TYPES.find(stt => stt?.value === status))
    }, [status])

    return (
        <Chip size='small'
            sx={{ width: 120, fontWeight: 'medium' }}
            variant={!isConfirm ? `customize-pending` : `customize-${leaderStatus?.chip}`}
            label={isConfirm
                ? leaderStatus?.label
                : `Chờ xử lý`}
        />
    )
}

export default FeedbackChip