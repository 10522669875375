import BorderLinearProgress from "@/components/BorderLinearProgress"
import DragDropFile from '@components/shared/DragDropFile'
import SingleDocument from "@components/shared/SingleDocument"
import { useNotificationContext } from '@contexts/notification'
import { Box, Typography } from '@mui/material'
import React from 'react'

const Attachment = ({
    attachment,
    setAttachment
}) => {
    const { setNotification } = useNotificationContext()

    const fileHandler = (file) => {
        const extension = file?.name?.split(".")[1]?.toLowerCase()
        if (extension === undefined) {
            return setNotification({
                open: true,
                severity: 'error',
                message: 'Thể loại tập tin không được hỗ trợ'
            })
        }
        setAttachment((prev) => ({ ...prev, file: file }))
    }

    return (
        <Box sx={{ display: 'flex', mt: 2, flexDirection: { xs: 'column', md: 'row' }, gap: 1 }}>
            <Box sx={{ minWidth: 300 }}>
                <Typography sx={{
                    fontSize: 14,
                    color: "#777680"
                }}>
                    Tệp Đính Kèm
                </Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
                <DragDropFile handler={fileHandler} />
                {!!attachment?.file && <Box sx={{ mt: 2 }}>
                    <SingleDocument document={attachment?.file}
                        handleDelete={() => setAttachment(prev => ({ ...prev, file: null }))} />
                    {attachment?.loading &&
                        <BorderLinearProgress value={attachment?.percen} sx={{ mt: 0.5 }} />}
                </Box>}
            </Box>
        </Box>
    )
}

export default Attachment