import SandboxApi from '@/apis/beta/Sandbox/SandboxApi'
import withSuspense from '@/hocs/withSuspense'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { FormControl } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const TemporaryAddressCity = () => {

    const { control, formState: { errors }, watch } = useFormContext()

    const [provinces] = SandboxApi.Provinces(watch(`employee_country`))

    return (
        <Controller
            name="employee_temporary_address_city"
            control={control}
            render={({ field: { value, onChange } }) => {
                const selected = provinces?.find(country => country.value === value) || null
                return (
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <SingleSelectCustomize
                            value={selected}
                            disabled={!watch(`employee_country`) || watch(`employee_country`) === -1}
                            options={_.uniqBy(provinces, item => item["value"])}
                            input={{
                                placeholder: "Chọn thành phố/ tỉnh...",
                                label: "Thành phố/ Tỉnh (*)"
                            }}
                            compareAttributes={["label", "value"]}
                            onChange={(event, newValue) => {
                                onChange(newValue?.value || -1)
                            }}
                        />
                        {!!errors?.employee_temporary_address_city && <ErrorHelperText
                            message={errors?.employee_temporary_address_city?.message}
                        />}
                    </FormControl>
                )
            }} />
    )
}

export default withSuspense(TemporaryAddressCity, MuiSkeleton["TextField"])