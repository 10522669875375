import ContractApi from '@/apis/beta/Contract/ContractApi'
import InputSearch from '@components/DataGrid/Toolbar/InputSearch'
import ModalConfirm from '@components/shared/ModalConfirm'
import { AllInclusiveOutlined } from '@mui/icons-material'
import { Box, Button, FormControl, Typography } from '@mui/material'
import { useState } from 'react'
import { useMutation } from 'react-query'

const Toolbar = ({
    callback,
    contractIds = [],
    setContractIds,
    contracts = []
}) => {
    const [open, setOpen] = useState(false)

    const { mutateAsync } = useMutation(ContractApi.Merged)

    const onSubmit = async () => {
        const response = await mutateAsync({ contractIds })
        if (!response || response?.errors) {
            return {
                status: 404,
                message: response?.errors?.[0]?.message
            }
        }
        return {
            status: 200,
            message: response?.data?.message
        }
    }
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
            p: "16px 16px 8px 16px"
        }}>
            {open && <ModalConfirm
                dialog={{
                    title: "Gộp Số Hợp Đồng Dịch Vụ",
                    content: <Box>
                        <Typography sx={{ fontSize: 14 }} color="text.black">
                            Các số hợp đồng sau sẽ được gom về chung một số hợp đồng
                        </Typography>
                        <Box sx={{
                            height: 200,
                            maxHeight: 200,
                            overflow: "hidden",
                            overflowY: 'auto',
                            p: '10px',
                            border: "1px solid #333",
                            "&::-webkit-scrollbar": {
                                width: "3px",
                                "&-track": {
                                    background: "#FFF",
                                },
                                "&-thumb": {
                                    background: "#1a237e",
                                },
                            },
                        }}>
                            {contractIds?.map((id) => {
                                return <Box sx={{ display: 'inline-flex' }} key={id}>
                                    <Typography sx={{ fontSize: 14, minWidth: 200 }} color='text.grey'>
                                        ID: {id}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }} color='text.black'>
                                        {contracts?.find(item => item?.contract_id === id)?.contract_number_id}
                                    </Typography>
                                </Box>
                            })}
                        </Box>
                    </Box>,
                }}
                handleClose={() => {
                    setContractIds([])
                    setOpen(false)
                }}
                handleSubmit={onSubmit}
                callback={callback}
            />}
            <Button size='small' variant='outlined'
                onClick={() => setOpen(true)}
                disabled={contractIds?.length <= 1}
                startIcon={<AllInclusiveOutlined fontSize='small' />}
            >
                Gom Số Hợp Đồng Dịch Vụ
            </Button>
            <FormControl fullWidth sx={{ maxWidth: { xs: '100%', md: 300 } }}>
                <InputSearch />
            </FormControl>
        </Box>
    )
}

export default Toolbar