import { API_PUBLIC_URL, API_V3_URL } from "@/libs/constants";
import { Box } from "@mui/system";
import React from "react";

const PdfPreview = ({ url }) => {
  const PROXY_PDF_URL = `${API_PUBLIC_URL}/proxy_pdf?url=`;

  return (
    <Box height="100%" sx={{ backgroundColor: "#fff" }}>
      <iframe src={`${PROXY_PDF_URL}${encodeURIComponent(url)}`} width="100%" height="100%" />
    </Box>
  );
};

export default PdfPreview;
