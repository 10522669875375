import { CONTRACT_NUMBER_STATUS_TYPES } from '@/libs/constants'
import { Box, Typography } from '@mui/material'
import React from 'react'

const ContractStatus = ({ data = [] }) => {
    return (
        <Box sx={{
            p: "4px",
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: "column",
            justifyContent: { xs: "center", md: "flex-start" }
        }}>
            {data?.map((contract, index) => {

                const stt = CONTRACT_NUMBER_STATUS_TYPES?.find(item => item?.value === contract?.contract_status)
                return (
                    <Typography key={index}
                        {...stt?.muiTypography}
                        sx={{
                            fontSize: 14,
                            m: 'auto 0 auto auto'
                        }}>
                        {stt?.label}
                    </Typography>
                )
            })}
        </Box>
    )
}

export default ContractStatus