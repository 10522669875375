import DepartmentApi from '@/apis/beta/Employee/DepartmentApi'
import { queryClientRefetchScope } from "@/functions"
import withPermission from '@/hocs/withPermission'
import { useNotificationContext } from '@contexts/notification'
import { Button, CircularProgress, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'

const DepartmentDestroy = ({
    onClose,
    department,
    departmentId
}) => {
    const { setNotification } = useNotificationContext()
    const queryClient = useQueryClient()

    const { mutateAsync, isLoading } = useMutation(DepartmentApi.Destroy)

    const onSubmit = async () => {
        const response = await mutateAsync({ departmentId })
        if (!response || response?.errors) {
            return setNotification({ open: true, message: response?.errors?.[0]?.message, severity: "error" })
        }

        queryClientRefetchScope(queryClient, 'department')
        setNotification({ open: true, message: "Huỷ phòng ban thành công.", severity: "success" })
        return onClose()
    }
    return (
        <Dialog open={true}
            fullWidth
            maxWidth="md">
            <DialogTitle>
                Huỷ Phòng Ban {department?.department_name}
            </DialogTitle>
            <DialogActions>
                <Button size='small'
                    variant='outlined'
                    disable={isLoading}
                    onClick={onClose}
                >
                    Huỷ Thao Tác
                </Button>
                <Button size='small'
                    disable={isLoading}
                    variant='contained'
                    onClick={onSubmit}
                    startIcon={isLoading && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                >
                    Lưu Thay Đổi
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withPermission(DepartmentDestroy, { feature: 'department', action: 'index', type: 'Button' })