import PopperAction from "@components/PopperAction";
import {
    MoreVertOutlined,
} from "@mui/icons-material";
import { Box, Collapse, IconButton, Popover, Typography } from "@mui/material";
import React, { Fragment } from "react";
import TranscodingCommit from "./Commit";
import PriceRanges from "./PriceRanges";

const TranscodePreview = ({ data = {}, remover, updater, disabled, insert }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [INITIAL_ACTIONS] = React.useState([
        {
            order: 1,
            name: "Add",
            icon: "Add",
            label: "Duplicated",
            handleClick: (newProduct) => {
                try {
                    insert({ ...newProduct });
                } catch (error) {
                    console.log(error);
                }
                return onClose();
            },
        },
        {
            order: 2,
            name: "delete",
            icon: "Delete",
            label: "Xoá",
            handleClick: () => {
                try {
                    remover();
                } catch (error) {
                    console.log(error);
                }
                return onClose();
            },
        },
    ]);

    const onClose = () => setAnchorEl(undefined);

    return (
        <Fragment>
            {!!Boolean(anchorEl) && (
                <Popover anchorEl={anchorEl} open={!!Boolean(anchorEl)}>
                    <PopperAction
                        actions={INITIAL_ACTIONS}
                        data={data}
                        handleClose={onClose}
                    />
                </Popover>
            )}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column-reverse", md: "row" },
                    gap: 1,
                    justifyContent: "space-between",
                    border: "1px solid rgba(0, 0, 0, 0.18)",
                    p: 2,
                }}
            >
                <Box sx={{ width: "100%" }}>
                    <Typography
                        component="div"
                        sx={{
                            fontSize: 14,
                            fontWeight: "medium",
                            mb: 0.5,
                            textDecoration: "underline",
                        }}
                    >
                        {data?.product_group_name} - {data?.product_name}
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: "#777680",
                                ml: 0.5,
                                display: "inline-block",
                            }}
                        >
                            {"( "}
                            {data?.product_payment_type === 'postpaid' ? 'Pay as you go' : "Gói Cố Định"}{" )"}
                        </Typography>
                    </Typography>
                    <Collapse in={data?.product_payment_type !== 'postpaid'}>
                        <TranscodingCommit data={data} />
                    </Collapse>
                    <Typography sx={{ fontSize: 13, fontWeight: 'medium', mt: 1.5 }}>
                        Chu Kỳ Đối Soát: {" "}
                        <Typography component="span" sx={{ fontSize: 13 }} color="text.grey">
                            {data?.service_management_billing?.statement === 'ck2' ? 'Ngày 23 Đến Ngày 22' : 'Đầu Tháng Đến Cuối Tháng'}
                        </Typography>
                    </Typography>
                    <PriceRanges ranges={data?.service_pricing_configure?.steps} />
                </Box>
                <IconButton
                    size="small"
                    sx={{
                        m: "0 0 auto auto",
                        bgcolor: "rgba(0, 0, 0, 0.08)",
                    }}
                    disabled={!!Boolean(anchorEl)}
                    onClick={(event) => setAnchorEl(event?.currentTarget)}
                >
                    <MoreVertOutlined fontSize="small" />
                </IconButton>
            </Box>
        </Fragment>
    );
};

export default TranscodePreview;
