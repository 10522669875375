import PopperAction from '@components/PopperAction'
import { MoreVertOutlined } from '@mui/icons-material'
import { Box, IconButton, Popover, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from "react-router-dom"
import ModalEmployee from './ModalEmployee'

const Title = ({ name, data }) => {

    let navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClose = () => setAnchorEl(null)

    const [INITIAL_ACTIONS] = React.useState([
        {
            order: 1,
            name: "edit",
            label: "Chỉnh Sửa",
            icon: "Edit",
            handleClick: (values) => {
                handleClose()
                return navigate(`/group-roles/${values?.group_permission_id}/update`, { replace: true })
            }
        }, {
            order: 2,
            name: "manage-employee",
            icon: "Edit",
            label: "Phân Quyền",
            handleClick: () => {
                handleClose()
                return setModal(true)
            }
        }
    ])

    const [modal, setModal] = React.useState(false)

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            gap: 1,
            mb: 2
        }}>
            <Typography
                color="primary"
                sx={{
                    fontSize: 18,
                    fontWeight: 'medium',
                    textTransform: 'uppercase'
                }}
            >
                {name}
            </Typography>
            <IconButton size='small'
                sx={{
                    m: 'auto 0 auto auto'
                }}
                onClick={(event) => setAnchorEl(event?.currentTarget)}
            >
                <MoreVertOutlined fontSize='small' color="primary" />
            </IconButton>
            {anchorEl && <Popover anchorEl={anchorEl} open={!!anchorEl}>
                <PopperAction handleClose={handleClose}
                    actions={INITIAL_ACTIONS}
                    data={data}
                />
            </Popover>}
            {modal && <ModalEmployee groupId={data?.group_permission_id}
                assigneeIds={data?.group_permission_assignee_employee_ids}
                handleClose={() => setModal(false)}
            />}
        </Box>
    )
}

export default Title