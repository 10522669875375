import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

const PAPER_CUSTOMIZED_BASE = {
    Typography: ({ value, ...props }) => <Typography color="text.black" {...props}>{value}</Typography>,
    Link: ({ value, ...props }) => <a {...props} style={{
        color: '#6b9ded'
    }}>{value}</a>,
    ArrayField: ({ value: values, ...props }) => (
        <Box>
            {values?.map((item, index) => (
                <Box key={index} sx={{ display: 'flex', mb: 0.75 }}>
                    {props?.attributes?.map((attribute, i) => {
                        return (
                            <Typography key={i}>
                                {i !== 0 && ": "}
                                {item?.[attribute?.name]}
                            </Typography>
                        )
                    })}
                </Box>
            ))}
        </Box>
    )
}

const GridPaper = ({ attributes = [] }) => {
    return (
        <Grid container spacing={2}>
            {attributes.map(({ label, value, component, ...attribute }, index) => {
                if (attribute?.hidden) return null;

                const Component = PAPER_CUSTOMIZED_BASE[component]

                return (
                    <Grid item xs={12} md={attribute?.cols?.md || 4} xl={attribute?.cols?.xl || 3} key={index}>
                        <Typography sx={{ fontSize: 14 }} color="text.grey">
                            {label}{":"}
                        </Typography>
                        <Component value={value} {...attribute?.params} />
                    </Grid>
                )
            })}

        </Grid>
    )
}

const PaperCustomized = ({ layout = 'Grid', attributes = [] }) => {
    return <GridPaper attributes={attributes} />
}

export default PaperCustomized

