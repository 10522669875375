import DomainName from "./DomainName"
import DomainType from "./DomainType"
import DomainBillingCycle from "./DomainBillingCycle"
import EppCode from "./EppCode"
import DomainDNS from "./DNS"
import CustomerInformation from "./Information"
import DomainAddon from "./DomainAddon"
import DomainPricingAmount from "./DomainPricingAmount"
import DomainPaymentType from "./DomainPaymentType"

const DomainComponents = {
    DomainType: DomainType,
    DomainName: DomainName,
    DomainBilling: DomainBillingCycle,
    EppCode: EppCode,
    DNS: DomainDNS,
    Addons: DomainAddon,
    CustomerInformation: CustomerInformation,
    PricingAmount: DomainPricingAmount,
    PaymentType: DomainPaymentType
}

export default DomainComponents