import { format_numeral_price } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useState } from 'react'
import { resolveServiceAnalyticLMSTranscodingDurationofLives } from '../services'
import TranscodeTimes from '../shared/TranscodeTimes'

const DurationLive = ({ service }) => {

    const today = dayjs()
    const [year, setYear] = useState({
        value: today?.format('MM-YYYY'),
        label: today?.format('MM-YYYY'),
        gte: today?.startOf('month').format('DD/MM/YYYY'),
        lte: today?.endOf('month').format('DD/MM/YYYY'),
    })
    const {
        data: [liveDurations],
        isLoading,
        isFetching
    } = resolveServiceAnalyticLMSTranscodingDurationofLives(service?.service_creatable_uuid, {
        gte: year?.gte,
        lte: year?.lte
    })

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box sx={{
                    mt: 1.5,
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between', alignItems: 'center'
                }}>
                    <Typography sx={{ fontSize: 14, fontWeight: 'medium' }}>
                        Duration of Live
                    </Typography>
                    <TranscodeTimes year={year} setYear={setYear} />
                </Box>
                <TableContainer>
                    <Table size='small' sx={{
                        border: "1px solid #333",
                        bgcolor: "#7776801F"
                    }} aria-label="lms.transcode.duration_of_live">
                        <TableHead sx={{ bgcolor: "#FFF" }}>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Tổng</TableCell>
                                <TableCell align="right">Transcode</TableCell>
                                <TableCell align="right">Transmux</TableCell>
                                <TableCell align="right">Audio</TableCell>
                            </TableRow>
                        </TableHead>
                        {(!isLoading && !isFetching && !!liveDurations) ? <TableBody>
                            {_.map(liveDurations, (liveDuration, key) => {
                                const [cpu] = Object.values(liveDuration)[0]

                                const cpuTranscode = parseFloat(cpu?.total) - (parseFloat(cpu?.audioOnly) + parseFloat(cpu?.transmux))

                                return (
                                    <TableRow key={key}>
                                        <TableCell component="th" scope="row">
                                            {Object.keys(liveDuration)[0]}
                                        </TableCell>
                                        <TableCell align="right">{format_numeral_price(cpu?.total, true)}</TableCell>
                                        <TableCell align="right">{format_numeral_price(cpuTranscode)}</TableCell>
                                        <TableCell align="right">{format_numeral_price(cpu?.audioOnly)}</TableCell>
                                        <TableCell align="right">{format_numeral_price(cpu?.transmux)}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody> : <MuiSkeleton.LoadingDotBox />}
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}

export default withSuspense(DurationLive, MuiSkeleton["LoadingDotBox"])