import { DAYOFF_TYPE_TYPES } from '@/libs/constants'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'

const DayoffType = () => {
    const { control, formState: { errors } } = useFormContext()
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 0.5, md: 2 },
            mt: 2
        }}>
            <Box sx={{ display: 'flex', width: { xs: '100%', md: '30%' } }}>
                <Typography component="div"
                    sx={{ color: "#777680" }}
                >
                    Hình Thức Nghỉ Phép
                    <Tooltip title="Áp dụng ngày phép nếu chọn nghỉ có lương & còn ngày phép"
                        sx={{ display: 'inline' }}>
                        <Typography sx={{ ":hover": { cursor: 'pointer' }, display: 'inline' }}>*</Typography>
                    </Tooltip>
                </Typography>
            </Box>
            <Controller
                control={control}
                name="day_off_type"
                render={({ field: { value, onChange } }) => {
                    return (
                        <FormControl fullWidth>
                            <RadioGroup row
                                value={value}
                                onChange={(event) => onChange(event?.target?.value)}
                            >
                                {DAYOFF_TYPE_TYPES?.map((type, index) =>
                                    <FormControlLabel
                                        key={type?.value}
                                        {...type}
                                        control={<Radio size='small' value={type?.value} />}
                                    />
                                )}
                            </RadioGroup>
                            {!!errors?.day_off_type && <ErrorHelperText message={errors?.day_off_type?.message} />}
                        </FormControl>
                    )
                }} />
        </Box>
    )
}

export default DayoffType