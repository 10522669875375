import ProductApi from "@/apis/beta/Product/ProductApi";
import { removeObjectKeys } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import ProductInformation from "@components/Templates/ProductForm/shared/ProductInformation";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useNotificationContext } from "@contexts/notification";
import { Box } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Buttons from "./Buttons";

const Informations = () => {
  const { product_id } = useParams();
  const { data, refetch: callback } = ProductApi.Detail({ id: product_id });

  const reformatterProductInformations = React.useMemo(() => {
    return {
      product_type: data?.product_type, // customized | package
      product_informations:
        data?.product_informations?.map((item) => ({
          name: item?.name,
          value: item?.value,
          product_infomation_id: item?.product_infomation_id,
          product_infomation_type: item?.product_infomation_type,
          max: item?.max,
          min: item?.min,
          optional: item?.optional,
          option: item?.option,
          unit: item?.unit,
          subtitle: item?.subtitle,
          customize: item?.customize,
        })) || [],
    };
  }, [data]);
  const [submit, setSubmit] = React.useState(false);

  const { setNotification } = useNotificationContext();

  const methods = useForm({
    reValidateMode: "onChange",
    defaultValues: { ...reformatterProductInformations },
  });

  const rebuildProductInformations = (arrs = []) => {
    return arrs?.map((item) =>
      removeObjectKeys(item, ["created_at", "tw_product_id", "updated_at"])
    );
  };

  const onReset = () => {
    return methods.reset({ ...reformatterProductInformations });
  };

  const onSubmit = async (values) => {
    setSubmit("submit");
    const response = await ProductApi.UpdateProductInformations({
      productId: product_id,
      data: rebuildProductInformations(values?.product_informations),
    });
    setSubmit(null);
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }
    setNotification({
      open: true,
      message: `Cập nhật thông tin sản phẩm thành công.`,
      severity: "success",
    });
    try {
      callback();
    } catch (error) {
      console.log(error?.message);
    }
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <ProductInformation />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: { xs: 1.5, md: 2 },
            flexDirection: { xs: "column", md: "row" },
            mt: 3,
          }}
        >
          <Buttons.Cancel submit={submit} onClick={onReset} />
          <Buttons.Update submit={submit} />
        </Box>
      </Box>
    </FormProvider>
  );
};

export default withSuspense(
  withPermission(Informations, {
    feature: "product",
    action: "product-information-index",
  }),
  MuiSkeleton["Papper"]
);
