import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const PRIVATE_PRICING_METHODS = [
    { value: 'progressive', label: "Luỹ Tiến" },
    { value: 'tierd', label: "Theo Thoả Thuận" },
]

const ProductPricingMethod = () => {
    const { control } = useFormContext()
    return (
        <Box>
            <Typography sx={{ fontSize: 14, color: "#777680" }}>
                Hình Thức Đối Soát*
            </Typography>
            <Controller
                name="product_pricing_configures.method"
                control={control}
                render={({ field: { value, onChange } }) => {
                    return (
                        <FormControl fullWidth>
                            <RadioGroup row sx={{ display: 'flex', flexDirection: { xs: "column", md: "row" }, gap: 1 }}
                                value={value}
                                onChange={onChange}
                            >
                                {PRIVATE_PRICING_METHODS.map((k, v) => {
                                    return (
                                        <FormControlLabel
                                            {...k}
                                            key={v}
                                            control={<Radio size='small' />}
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </FormControl>
                    )
                }}
            />
        </Box>
    )
}

export default ProductPricingMethod