import ContractApi from "@/apis/beta/Contract/ContractApi";
import FileApi from "@/apis/shared/File/FileApi";
import BorderLinearProgress from "@/components/BorderLinearProgress";
import DatePicker from "@/components/DatePicker";
import MuiCurrency from "@/components/shared/CurrencyInputCustomize";
import ErrorHelperText from "@/components/shared/ErrorHelperText";
import InputFileCustomize from "@/components/shared/InputFileCustomize";
import SingleDocument from "@/components/shared/SingleDocument";
import { useNotificationContext } from "@/contexts/notification";
import { contractValidator } from "@/libs/validators";
import { vestResolver } from "@hookform/resolvers/vest";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";

const FormContract = ({ data, handleClose, callbackContractNumber }) => {
  const { setNotification } = useNotificationContext();
  const [file, setFile] = React.useState(null);
  const [submit, setSubmit] = React.useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: vestResolver((props) =>
      contractValidator({
        ...props,
        document: file,
      })
    ),
    defaultValues: {
      contract_number_id: data?.contract_number_id,
      contract_key: "",
      contract_value: 0,
      effective_date: new Date(),
      expiry_date: new Date(),
      contract_note: "",
    },
  });

  const [uploadFile, handleUploadFile] = React.useState({
    loading: false,
    percen: 0,
  });

  const fileHandler = async (files) => {
    const extension = files[0].name.split(".")[1]?.toLowerCase();
    if (extension === undefined) {
      return setNotification({
        open: true,
        severity: "error",
        message: "Thể loại tập tin không được hỗ trợ",
      });
    }
    return setFile(files[0]);
  };

  const onSubmit = async (values) => {
    setSubmit(true);
    let form = {
      ...values,
      contract_type: 1,
    };
    if (!!file && !!file?.name) {
      const prefixPathname = "contracts/documents";
      const token = await FileApi.GetPresignedTokenAttachment({
        filename: {
          name: file?.name,
          folder: prefixPathname,
        },
      });
      if (!token.token || token.status !== 200) {
        setSubmit(false);
        return setNotification({
          open: true,
          message: "Lấy thông tin xử lý file thất bại",
          severity: "error",
        });
      }
      handleUploadFile((prevState) => ({
        ...prevState,
        loading: true,
        percen: 0,
      }));
      const uploaded = await FileApi.UploadAttachmentToStorage({
        token: token?.token,
        file: file,
        handleUploadFile: handleUploadFile,
      });
      handleUploadFile((prevState) => ({
        ...prevState,
        loading: false,
        percen: 0,
      }));
      if (!uploaded) {
        setSubmit(false);
        return setNotification({
          open: true,
          message: "Tải dữ liệu thất bại",
          severity: "error",
        });
      }

      form = {
        ...form,
        contract_attachment: Array.from([
          {
            attachment_file_name: file?.name,
            attachment_file_type: file?.type,
            attachment_file_size: file?.size,
            attachment_file_url: ["pub", prefixPathname, token?.filename].join(
              "/"
            ),
          },
        ]),
      };
    }

    await ContractApi.createContractCustomer({
      contract: form,
    }).then((response) => {
      console.log(response);
      if (!response?.contract) {
        return setNotification({
          open: true,
          message: response?.errors?.[0]?.message || "Tạo hợp đồng thất bại.",
          severity: "error",
        });
      }
      callbackContractNumber();
      handleClose();
      setNotification({
        open: true,
        message: "Tạo hợp đồng thành công.",
        severity: "success",
      });
    });
  };

  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <DialogTitle>Tạo Hợp Đồng Khách Hàng</DialogTitle>
      <DialogContent>
        <Box component="form">
          <Typography sx={{ mb: 0.5 }}>Mã Số Hợp Đồng *</Typography>
          <Controller
            control={control}
            name="contract_number_id"
            render={({ field }) => (
              <TextField
                {...field}
                disabled
                error={!!errors?.contract_number_id}
                helperText={errors?.contract_number_id?.message}
                size="small"
                fullWidth
              />
            )}
          />
          <Typography sx={{ mb: 0.5, mt: 2 }}>Từ Khoá *</Typography>
          <Controller
            control={control}
            name="contract_key"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.contract_key}
                helperText={errors?.contract_key?.message}
                placeholder="Nhập từ khoá..."
                size="small"
                fullWidth
              />
            )}
          />
          <Typography sx={{ mb: 0.5, mt: 2 }}>Tổng Tiền Hợp Đồng *</Typography>
          <Controller
            control={control}
            name="contract_value"
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <MuiCurrency onChange={onChange} value={value} min={0} />
                {!!errors?.contract_value && (
                  <ErrorHelperText message={errors?.contract_value?.message} />
                )}
              </FormControl>
            )}
          />
          <Typography sx={{ mb: 0.5, mt: 2 }}>
            Ngày Bắt Đầu Có Hiệu Lực *
          </Typography>
          <Controller
            control={control}
            name="effective_date"
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <DatePicker value={value} onChange={onChange} />
              </FormControl>
            )}
          />
          <Typography sx={{ mb: 0.5, mt: 2 }}>
            Ngày Hết Hạn Hiệu Lực *
          </Typography>
          <Controller
            control={control}
            name="expiry_date"
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <DatePicker value={value} onChange={onChange} />
                {!!errors?.expiry_date && (
                  <ErrorHelperText message={errors?.expiry_date?.message} />
                )}
              </FormControl>
            )}
          />
          <Typography sx={{ mb: 0.5, mt: 2 }}>Tệp Tin Đính Kèm *</Typography>
          <InputFileCustomize
            fileHandler={fileHandler}
            accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
          />
          {!!errors?.file && (
            <ErrorHelperText message={errors?.file?.message} />
          )}
          {file && (
            <React.Fragment>
              <SingleDocument
                document={file}
                handleDelete={() => setFile(null)}
              />
              {uploadFile?.loading && (
                <BorderLinearProgress value={uploadFile?.percen} />
              )}
            </React.Fragment>
          )}
          <Typography sx={{ mb: 0.5, mt: 2 }}>Ghi Chú</Typography>
          <Controller
            control={control}
            name="contract_note"
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  multiline
                  {...field}
                  placeholder="Nhập ghi chú về hợp đồng..."
                />
              </FormControl>
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          sx={{ height: 36 }}
          variant="text"
          disabled={submit}
          onClick={handleClose}
        >
          Huỷ Thao Tác
        </Button>
        <Button
          size="small"
          sx={{ height: 36 }}
          variant="outlined"
          startIcon={
            submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
          }
          disabled={submit}
          onClick={handleSubmit(onSubmit)}
        >
          Tạo Hợp Đồng
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormContract;
