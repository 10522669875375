import NumericFormatCustom from "@components/NumericFormatCustom";
import { FormControl, TextField } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const ServicePricingApply = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name="service_pricing_apply"
      render={({ field: { value, onChange } }) => (
        <FormControl fullWidth sx={{ mb: 1.5 }}>
          <TextField
            label="Đơn giá giảm/ Tính trên 1 đơn vị tính *"
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            placeholder="0..."
            value={value}
            onChange={onChange}
            InputProps={{
              inputComponent: NumericFormatCustom,
            }}
            error={!!errors?.service_pricing_apply}
            helperText={errors?.service_pricing_apply?.message}
          />
        </FormControl>
      )}
    />
  );
};

export default ServicePricingApply;
