import InvoiceApi from "@/apis/beta/Invoice/InvoiceApi";
import { downloadFileWithBinaryResponse } from "@/functions";
import withPermission from "@/hocs/withPermission";
import { DownloadOutlined } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { useMutation } from "react-query";

const InvoicePDF = ({ invoiceId, type = 'invoice' }) => {
  const { mutateAsync, isLoading } = useMutation(InvoiceApi.Download);

  const onSubmit = async () => {
    const response = await mutateAsync({
      invoiceId, type
    });
    if (!response) return;
    downloadFileWithBinaryResponse(
      type === 'invoice' ?
        `Thong_bao_gia_han_dich_vu_invoice_${invoiceId}.pdf` :
        `Bien_ban_doi_soat_dich_vu_invoice_${invoiceId}.pdf`
      ,
      response
    );
  };

  return (
    <Button
      size="small"
      variant="contained"
      onClick={onSubmit}
      disabled={isLoading}
      startIcon={
        isLoading ? (
          <CircularProgress size={14} sx={{ color: "#777680" }} />
        ) : (
          <DownloadOutlined fontSize="small" />
        )
      }
    >
      Tải PDF
    </Button>
  );
};

export default withPermission(InvoicePDF, {
  feature: "invoice",
  action: "pdf",
  type: "Button",
});
