import { Box, Typography } from "@mui/material";
import React from "react";

const FORMAT_NAMES = {
  "Protected Domains (DNS-No of Records)": "Domains",
  " CDN & ORIGIN SHIELD Traffic-VNCDN": "Traffic-VNCDN",
  "CDN & ORIGIN SHIELD Traffic-VNCDN": "Traffic-VNCDN",
  "CDN & ORIGIN SHIELD REQUEST - VNCDN": "Request-VNCDN",
  "DNS  Request - Tier price": "DNS Request",
};

const FORMAT_UNITS = {
  "Protected Domains (DNS-No of Records)": "",
  " CDN & ORIGIN SHIELD Traffic-VNCDN": "GB",
  "CDN & ORIGIN SHIELD Traffic-VNCDN": "GB",
  "CDN & ORIGIN SHIELD REQUEST - VNCDN": "Milion Requests",
  "DNS  Request - Tier price": "Milion Requests",
};

const ProductInformation = ({ informations = [] }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        gap: 0.75,
      }}
    >
      {informations?.map((information, index) => {
        if (!FORMAT_NAMES[information?.name]) return null;
        return (
          <Typography
            sx={{ fontSize: 14, m: "auto 0" }}
            color="text.grey"
            key={index}
          >
            {FORMAT_NAMES[information?.name]}
            {":"}{" "}
            <Typography
              sx={{ fontSize: 14, display: "inline-flex" }}
              color="text.black"
            >
              {information?.value}
              {FORMAT_UNITS[information?.name]}
            </Typography>
          </Typography>
        );
      })}
    </Box>
  );
};

export default ProductInformation;
