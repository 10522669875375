import ServiceAmount from "./Amount";
import Description from "./Description";
import ServicePrice from "./Price";
import Product from "./Product";
import ServiceQty from "./Qty";
import ServiceFirstTime from "./ServiceFirstTime";
import ServiceName from "./ServiceName";
import ServiceNextTime from "./ServiceNextTime";
import ServiceStartdate from "./Startdate";
import Taxable from "./Taxable";

const ADDON_SHAREDS = {
    Product,
    Description,
    ServiceQty,
    ServicePrice,
    ServiceAmount,
    Taxable,
    ServiceStartdate,
    ServiceFirstTime,
    ServiceNextTime,
    ServiceName
}
export default ADDON_SHAREDS