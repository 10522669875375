import CustomerApi from '@/apis/beta/Customer/CustomerApi'
import EmployeeApi from '@/apis/beta/Employee/EmployeeApi'
import DataGrid from '@/components/DataGrid'
import BreadCrumb from "@/components/shared/BreadCrumb"
import ModalConfirm from "@/components/shared/ModalConfirm"
import SkeletonDataGrid from '@/components/Skeletons/DataGrid'
import { buildQueryFilterCondition, format_date_short } from '@/functions'
import withPermission from '@/hocs/withPermission'
import withSuspense from '@/hocs/withSuspense'
import useDebounce from '@/hooks/useDebounce'
import { CUSTOMER_STATUS } from '@/libs/constants'
import EyeIcon from '@mui/icons-material/VisibilityOutlined'
import { Avatar, Box, Chip, IconButton, Paper, Popper, Skeleton, Typography } from '@mui/material'
import { indigo } from '@mui/material/colors'
import React from 'react'
import { Link } from 'react-router-dom'
import Buttons from '../../Buttons'

const ModalAssigneeCustom = React.lazy(() => import("../ModalAssigneeCustome"))

const List = () => {
    const { data: employees } = EmployeeApi.ListEmployees({});

    const [searchQuery, setSearchQuery] = React.useState('')
    const debouncedSearchQuery = useDebounce(searchQuery, 500)

    const filterableCustomerDivider = React.useCallback(() => {
        if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === '') return '';
        const fieldQueries = [
            {
                name: 'customers.customer_id',
                operator: "$eq",
                value: parseInt(debouncedSearchQuery),
                disabled: !parseInt(debouncedSearchQuery)
            },
            { name: 'customer_name', operator: "$contains", value: debouncedSearchQuery },
            { name: 'customer_email', operator: "$contains", value: debouncedSearchQuery },
            { name: 'customer_phone', operator: "$contains", value: debouncedSearchQuery },
        ]
        return buildQueryFilterCondition("$or", 0, fieldQueries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery])

    const [selected] = React.useState([])
    const [openModalFollowers, setOpenModalFollowers] = React.useState({
        anchorEl: null,
        data: undefined
    })

    const [modalConfirm, setModalConfirm] = React.useState({
        open: false,
        type: undefined
    })
    const [page, setPage] = React.useState({
        page: 0,
        pageSize: 10
    })
    const initialColumns = [{
        field: 'customers.customer_id', headerName: 'ID',
        width: 60,
        valueGetter: ({ row }) => row?.customer_id,
        renderCell: ({ value }) => <Link to={`/customers/${value}`}>
            <Typography className='react-link-table'>
                {value}
            </Typography>
        </Link>
    }, {
        field: 'customer_name', width: 200,
        hideable: true,
        headerName: 'Tên Khách Hàng',
        renderCell: ({ value, row }) => <Link to={`/customers/${row?.id}`}>
            <Typography className='react-link-table'>
                {value}
            </Typography>
        </Link>
    }, {
        field: 'customer_email',
        width: 250,
        headerName: 'Email',
        renderCell: ({ value }) => value
    }, {
        field: 'customer_phone', headerName: 'Số Điện Thoại',
        width: 140,
        renderCell: ({ value }) => !["-", "+84"]?.includes(value) ? value
            : <Typography color='error' sx={{
                fontSize: 14
            }}>Chưa có thông tin</Typography>
    }, {
        field: 'customer_dob', headerName: 'Ngày Sinh',
        width: 140,
        renderCell: ({ value }) => value ? format_date_short(value) : "---"
    }, {
        field: 'customer_gender', headerName: 'Giới Tính',
        width: 150,
        hide: true,
        renderCell: ({ value }) => value || "---"
    }, {
        field: 'customer_website', headerName: 'Website',
        width: 150,
        renderCell: ({ value }) => value || "---"
    }, {
        field: 'customer_started_at', headerName: 'Thời gian bắt đầu',
        width: 150,
        renderCell: ({ value }) => value ? format_date_short(value) : "---"
    }, {
        field: 'customer_created_at', headerName: 'Thời gian tạo',
        width: 150,
        hide: true,
        renderCell: ({ value }) => value ? format_date_short(value) : "---"
    }, {
        field: 'customer_updated_at', headerName: 'Chỉnh sửa cuối',
        width: 150,
        hide: true,
        renderCell: ({ value }) => format_date_short(value)
    }, {
        field: 'employee_history', headerName: "Tài khoản được chỉ định",
        width: 200,
        valueGetter: ({ row }) => employees?.filter(e => row?.employee_history?.includes(e?.employee_id)),
        renderCell: ({ value = [] }) =>
            <Box sx={{ display: 'flex' }}>
                <Typography sx={{ fontSize: 14, m: 'auto 0' }}>
                    {value?.length === 0 ? "Chưa có nhân viên theo dõi"
                        : value[0]?.employee_name}
                </Typography>
                {value?.length > 1 && <React.Fragment>
                    <Typography sx={{ fontSize: 14, m: 'auto 0 auto 4px' }}>
                        {`(${value.length - 1})`}
                    </Typography>
                    <IconButton size='small' onClick={(event) => {
                        setOpenModalFollowers(prevState => ({ ...prevState, anchorEl: event?.target, data: value }))
                    }}>
                        <EyeIcon sx={{ m: 'auto 0', color: '#4C57A9', fontSize: 16 }} />
                    </IconButton>
                </React.Fragment>}
            </Box>
    }, {
        field: 'customer_status', headerName: 'Trạng Thái',
        width: 120,
        valueGetter: ({ row }) => CUSTOMER_STATUS.find((customer_stt) => customer_stt?.value === row.customer_status),
        renderCell: ({ value }) => {
            return (
                <Chip
                    size='verysmall'
                    variant={`customize-${value?.chip}`}
                    label={value?.label} />
            )
        }
    }]

    const historyColumsHidden = JSON.parse(localStorage.getItem("customer-assignees.hiddenColum")) || []
    const [columns, setColumns] = React.useState(initialColumns?.map((column) => {
        if (historyColumsHidden?.includes(column?.field)) {
            return { ...column, hide: true }
        }
        return { ...column }
    }) || initialColumns)

    const getModalConfirm = (type) => {
        switch (type) {
            case "assignee-auto": return <ModalConfirm callback={callback}
                handleClose={() => setModalConfirm(prevState => ({ ...prevState, open: false, type: undefined }))}
                handleSubmit={undefined}
                dialog={{
                    title: "Tự Động Chia Nhân Viên",
                    content: <Typography>
                        Toàn bộ khách hàng sẽ được chia tự động về các nhân viên để theo dõi <b>
                            {(selected?.length === data?.customers?.length || selected?.length === 0) ? [] : data?.customers?.filter((customer) =>
                                selected?.includes(customer?.customer_id)
                            )?.map(x => x.customer_id).join(`, `)} </b> sẽ được chia tự động về các nhân viên để theo dõi
                    </Typography>
                }}
            />;
            case "assignee-custom": return <ModalAssigneeCustom
                handleClose={() => setModalConfirm({ open: false, type: undefined })}
                customers={data?.customers?.filter((customer) =>
                    selected?.includes(customer?.customer_id)
                )}
            />

            default: return null;

        }
    }

    const { data, isFetching, refetch: callback } = CustomerApi.CustomerDivide({
        page: page?.page + 1,
        pageSize: page?.pageSize,
        query: filterableCustomerDivider() !== "" ? filterableCustomerDivider() : null
    });

    return (
        <Box>
            {(modalConfirm?.open && modalConfirm.type !== undefined) && getModalConfirm(modalConfirm.type)}
            {(Boolean(openModalFollowers?.anchorEl) && openModalFollowers?.data !== undefined) && <Popper
                anchorEl={openModalFollowers?.anchorEl}
                open={Boolean(openModalFollowers?.anchorEl)}
            >
                <Paper sx={{ p: 2 }} onMouseLeave={(event) => {
                    event.preventDefault()
                    setOpenModalFollowers(prevState => ({ ...prevState, anchorEl: null, data: undefined }))
                }}>
                    {openModalFollowers?.data?.map((eid) => {
                        const currentEmployee = employees?.find(e => e?.employee_id === eid);
                        return (
                            <Box sx={{ display: 'flex', mb: '10px' }} key={eid}>
                                <Avatar sx={{
                                    height: 32,
                                    width: 32,
                                    m: 'auto 8px auto 0',
                                    bgcolor: indigo[900]
                                }}>
                                    {currentEmployee?.employee_name?.at(0) || null}
                                </Avatar>
                                <Box>
                                    <Typography sx={{ fontSize: 14 }}>{currentEmployee?.employee_name}</Typography>
                                    <Typography sx={{ color: "#777687", fontSize: 12 }}>{currentEmployee?.employee_position + " " + currentEmployee?.employee_department}</Typography>
                                </Box>
                            </Box>
                        )
                    })}
                </Paper>
            </Popper>}
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className='page-title'>
                    Chia Khách Hàng
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {<Buttons.ButtonAssigneeAuto handleClick={() => setModalConfirm({ open: true, type: "assignee-auto" })} />}
                    {<Buttons.ButtonAssigneeCustom handleClick={() => setModalConfirm({ open: true, type: "assignee-custom" })} />}
                </Box>
            </Box>
            <BreadCrumb
                data={[
                    { label: "Khách Hàng", reactLink: "/customers" },
                    { label: "Chia khách hàng" }
                ]}
            />
            <DataGrid
                rows={data?.customers?.map((customer) => ({ ...customer, id: customer?.customer_id })) || []}
                columns={columns}
                loading={isFetching}
                componentsProps={{
                    toolbar: {
                        store: "customer-assignees.list.hiddenColumns",
                        columns: columns?.filter(col => !col?.hideable),
                        setColumns: setColumns,
                        initialColumns: initialColumns,
                        feature: 'customer-assignees',
                        inputSearch: {
                            placeholder: "Nhập ID, tên khách hàng, email hoặc số điện thoại tìm kiếm",
                            value: searchQuery,
                            onChange: setSearchQuery
                        }
                    },
                    pagination: {
                        page: page?.page || 0,
                        pageSize: page?.pageSize || 20,
                        onPageChange: (newPage) => setPage(prevState => ({ ...prevState, page: newPage })),
                        onPageSizeChange: (newPageSize) => setPage((prevState) => ({ ...prevState, pageSize: newPageSize }))
                    }
                }}
                rowCount={data?.pagination?.total}
            />
        </Box>
    )
}

const SkeletonList = () => {
    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className='page-title'>
                    Chia Khách Hàng
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Skeleton variant='rectangular' sx={{ width: 200, height: 42, borderRadius: '4px', mr: 2 }} />
                    <Skeleton variant='rectangular' sx={{ width: 120, height: 42, borderRadius: '4px' }} />
                </Box>
            </Box>
            <BreadCrumb
                data={[
                    { label: "Khách Hàng", reactLink: "/customers" },
                    { label: "Chia khách hàng" }
                ]}
            />
            <SkeletonDataGrid />
        </React.Fragment>
    )
}
export default withSuspense(withPermission(List, { feature: 'customer', action: 'assignee' }), SkeletonList)