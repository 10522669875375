import DatePicker from "@components/DatePicker";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import { Box, FormControl, Typography } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const ContractEffectiveDate = ({ disabled }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: 0.5,
      }}
    >
      <Box sx={{ minWidth: { xs: "100%", md: 300 } }}>
        <Typography
          sx={{
            fontSize: 14,
          }}
          color="text.grey"
        >
          Hiệu Lực HĐ *
        </Typography>
        <Typography sx={{ fontSize: 13 }} color="text.grey">
          (Ngày bắt đầu sử dụng dịch vụ)
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
        }}
      >
        <Controller
          name="effective_date"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl
                fullWidth
                sx={{
                  ".MuiIconButton-edgeEnd": {
                    m: "unset",
                  },
                }}
              >
                <DatePicker
                  value={value}
                  disabled={disabled}
                  onChange={onChange}
                  label="Ngày bắt đầu"
                />
                {!!errors?.effective_date && (
                  <ErrorHelperText message={errors?.effective_date?.message} />
                )}
              </FormControl>
            );
          }}
        />
        <Controller
          name="expiry_date"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl
                fullWidth
                sx={{
                  ".MuiIconButton-edgeEnd": {
                    m: "unset",
                  },
                }}
              >
                <DatePicker
                  value={value}
                  disabled={disabled}
                  onChange={onChange}
                  label="Ngày kết thúc"
                />
                {!!errors?.expiry_date && (
                  <ErrorHelperText message={errors?.expiry_date?.message} />
                )}
              </FormControl>
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default ContractEffectiveDate;
