import { create, enforce, skipWhen, test } from "vest";

const productConfigoptionValidator = create((data = {}) => {

    test(`name`, 'Tên không được để trống.', () => {
        enforce(data.name).isNotEmpty()
    })

    skipWhen(
        (res) => res.hasErrors("name")
            || data.method === "create" ||
            (data.method === "update" && data.name === data.previousName),
        () => test.memo(`name`, `Cấu hình này đã tồn tại.`, () => {
            enforce(!data.configoptions.includes(data.name)).isTruthy()
        }, [data.name])
    )

    test(`unit`, 'Vui lòng chọn đơn vị tính.', () => {
        const isValid = data.unit?.toString() !== "-1"
        enforce(isValid).isTruthy()
    })

    test(`steps`, 'Vui lòng cấu hình giá.', () => {
        enforce(data.steps.length > 0).isTruthy()
    })

    skipWhen(
        (res) => res.hasErrors("steps"),
        () => {
            let v = 0
            data?.steps.forEach((step, index) => {
                test(`steps.[${index}].start`, `Mốc không hợp lệ.`, () => {
                    enforce(parseFloat(step.start) >= v).isTruthy()
                })
                v = parseFloat(step.start)
                test(`steps.[${index}].end`, `Mốc không hợp lệ.`, () => {
                    enforce(parseFloat(step.end) >= v).isTruthy()
                })
                v = parseFloat(step.end)
                test(`steps.[${index}].pricing`, `Giá không hợp lệ.`, () => {
                    enforce(parseFloat(step.pricing) >= 0).isTruthy()
                })
                test(`steps.[${index}].pricing_minimum`, `Giá min không hợp lệ.`, () => {
                    enforce(parseFloat(step.pricing) >= 0).isTruthy()
                })
                skipWhen(
                    (res) => res.hasErrors(`steps.[${index}].pricing_minimum`) || res.hasErrors(`steps.[${index}].pricing`),
                    () => test(`steps.[${index}].pricing_minimum`, `Giá min không được lớn hơn giá niêm yết.`, () => {
                        const isValid = parseFloat(step.pricing) >= (parseFloat(step.pricing_minimum) || 0)
                        enforce(isValid).isTruthy()
                    })
                )
            })
        }
    )
})

export default productConfigoptionValidator;