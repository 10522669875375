import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import { format_date_short } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import { AccountCircleOutlined } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useParams } from "react-router-dom";

const Information = () => {
  const { employee_id } = useParams();
  const { data: employee } = EmployeeApi.Detail(employee_id);

  return (
    <Paper sx={{ p: "30px", mb: 2 }}>
      <Box sx={{ display: "flex", mb: 2 }}>
        <IconButton className="information" sx={{ m: "auto 8px auto 0" }}>
          <AccountCircleOutlined sx={{ color: "#FFF" }} />
        </IconButton>
        <Typography
          sx={{
            m: "auto 0",
            fontSize: 24,
            color: "#00000f",
          }}
        >
          #{employee_id}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              gap: 0.5,
            }}
          >
            <Typography sx={{ color: "#777680", fontSize: 14 }}>
              Họ & Tên Nhân Viên:
            </Typography>
            <Typography sx={{ textTransform: "capitalize" }}>
              {employee?.employee_name}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              gap: 0.5,
            }}
          >
            <Typography sx={{ color: "#777680", fontSize: 14 }}>
              Mã Nhân Viên:
            </Typography>
            <Typography>{employee?.employee_code}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              gap: 0.5,
            }}
          >
            <Typography sx={{ color: "#777680", fontSize: 14 }}>
              Phòng Ban:
            </Typography>
            <Typography>{employee?.employee_department}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              gap: 0.5,
            }}
          >
            <Typography sx={{ color: "#777680", fontSize: 14 }}>
              Vị Trí:
            </Typography>
            <Typography>{employee?.employee_position}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              gap: 0.5,
            }}
          >
            <Typography sx={{ color: "#777680", fontSize: 14 }}>
              Ngày Bắt Đầu Làm Việc:
            </Typography>
            <Typography
              sx={{ color: !employee?.start_worked_date && "#777680" }}
            >
              {!!employee?.start_worked_date
                ? format_date_short(employee?.start_worked_date)
                : "Chưa có thông tin"}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              gap: 0.5,
            }}
          >
            <Typography sx={{ color: "#777680", fontSize: 14 }}>
              Email Công Ty:
            </Typography>
            <Typography>{employee?.employee_email_company}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              gap: 0.5,
            }}
          >
            <Typography sx={{ color: "#777680", fontSize: 14 }}>
              Ngày Sinh:
            </Typography>
            <Typography>
              {!!employee?.employee_birthday &&
                format_date_short(employee?.employee_birthday)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

const SkeletonInformation = () => {
  const loop = _.range(0, 11);
  return (
    <Paper sx={{ p: "30px", mb: 2 }}>
      <Box sx={{ display: "flex", mb: 2 }}>
        <Skeleton
          variant="circular"
          sx={{ height: 40, width: 40, m: "auto 8px auto 0" }}
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: 24, m: "auto 0", width: 100 }}
        />
      </Box>
      <Grid container spacing={2}>
        {Array.from(loop, (item) => (
          <Grid item xl={3} lg={3} md={3} sm={4} xs={6} key={item}>
            <Skeleton variant="text" sx={{ width: 120 }} />
            <Skeleton variant="text" sx={{ width: 150 }} />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default withSuspense(
  withPermission(Information, { feature: "employee", action: "show" }),
  SkeletonInformation
);
