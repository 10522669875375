import ContractApi from "@/apis/beta/Contract/ContractApi";
import BreadCrumb from "@components/shared/BreadCrumb";
import { Box, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import Information from "./Information"
import SubcontractApi from "@/apis/beta/Contract/SubcontractApi";
import Process from "./Process";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import withPermission from "@/hocs/withPermission";


const Detail = () => {
    const params = useParams();
    const { data: contract } = ContractApi.Detail(params?.contract_id)
    const { data: subcontract, refetch: callbackSubcontract } = SubcontractApi.Detail(params?.contract_id, params?.subcontract_id)
    const { data: employees } = EmployeeApi.ListEmployees({ page: 1, pageSize: 999 })


    return (
        <Box>
            <Typography sx={{ fontSize: { xs: 18, md: 24 }, fontWeight: 'medium' }}>
                Thông Tin Phụ Lục Hợp Đồng
            </Typography>
            <BreadCrumb
                data={[
                    { label: "Danh Sách Hợp Đồng", reactLink: "/customer_contracts" },
                    { label: "Chi Tiết Hợp Đồng", reactLink: `/customer_contracts/${params?.contract_id}` },
                    { label: subcontract?.subcontract_code },
                ]}
            />

            <Paper sx={{ p: 3 }}>
                <Process contract={contract} subcontract={subcontract} employees={employees} callbackSubcontract={callbackSubcontract} />
                <Divider sx={{ m: '16px' }} />
                <Information contract={contract} subcontract={subcontract} />
            </Paper>
        </Box>
    )
}
export default withSuspense(withPermission(Detail, {
    feature: 'subcontract',
    action: 'show'
}), MuiSkeleton["PaperInformation"])