import { Box, Paper, Skeleton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';

const SkeletonDataGrid = (props) => {
    const [columns] = React.useState([
        {
            field: "col-id",
            renderHeader: () => <Skeleton variant='text' sx={{ width: 20, height: 24 }} />,
            width: 60,
            renderCell: () => <Skeleton variant='text' sx={{ width: 40, height: 24 }} />
        }, {
            field: "col-name",
            renderHeader: () => <Skeleton variant='text' sx={{ width: 80, height: 24 }} />,
            width: 120,
            valueGetter: () => {
                return Math.random() * (120 - 50) + 40;
            },
            renderCell: ({ value }) => <Skeleton variant='text' sx={{ width: value }} />
        }, {
            field: "col-config",
            renderHeader: () => <Skeleton variant='text' sx={{ width: 80, height: 24 }} />,
            width: 200,
            renderCell: () => <Box sx={{ display: 'flex' }}>
                <Skeleton variant='rectangular' sx={{
                    width: 60, height: 18,
                    borderRadius: '8px',
                }} />
                <Skeleton variant='rectangular' sx={{
                    ml: 2,
                    borderRadius: '8px',
                    width: 85, height: 18
                }} />
            </Box>
        }, {
            field: "col-created",
            renderHeader: () => <Skeleton variant='text' sx={{
                width: 80, height: 24
            }} />,
            width: 200,
            renderCell: () => <Skeleton variant='text' sx={{
                width: 80, height: 24
            }} />
        }, {
            field: "col-price",
            renderHeader: () => <Skeleton variant='text' sx={{
                width: 80, height: 24
            }} />,
            width: 150,
            renderCell: () => <Skeleton variant='text' sx={{ width: 120 }} />
        }, {
            field: "col-des",
            renderHeader: () => <Skeleton variant='text' sx={{
                width: 120, height: 24
            }} />,
            flex: 1,
            valueGetter: () => {
                return Math.random() * (100 - 30) + 30;
            },
            renderCell: ({ value }) => <Skeleton variant='text' sx={{ width: `${value}%` }} />
        }, {
            field: "col-action",
            renderHeader: () => <Skeleton variant='text' sx={{
                width: 60, height: 24
            }} />,
            width: 80,
            renderCell: () => <Skeleton variant='circular' sx={{ height: 32, width: 32, m: 'auto' }} />
        }
    ])
    return (
        <Box>
            {props?.filterEnabled &&
                <Paper sx={{ p: 2, display: 'flex', mb: 2 }}>
                    <Skeleton variant='rectangular' sx={{ borderRadius: '4px', height: 36, width: 112 }} />
                </Paper>}
            <DataGrid
                {...props}
                columns={columns}
                autoHeight
                rows={Array.from({ length: props.length || 5 }, (x, i) => i + 1).map(number => ({ id: number }))}
                components={{
                    Toolbar: props?.components?.Toolbar === 'disabled' ? null : props?.components?.Toolbar || Toolbar,
                    Pagination: props?.components?.Pagination === 'disabled' ? null : Pagination,
                }}
                hideFooter={props?.components?.Pagination === 'disabled'}
                hideFooterPagination={props?.components?.Pagination === 'disabled'}
                paginationMode="client"
            />
        </Box>
    )
}
const Toolbar = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, pb: 0 }}>
            <Skeleton variant='rectangular' sx={{ height: 40, width: 200, borderRadius: '4px' }} />
            <Skeleton variant='rectangular' sx={{ height: 40, width: 200, borderRadius: '4px' }} />
        </Box>
    )
}

const Pagination = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Skeleton variant='text' sx={{ height: 24, width: 150, m: 'auto 8px' }} />
            <Skeleton variant='text' sx={{ height: 24, width: 150, m: 'auto 8px' }} />
            <Skeleton variant='circular' sx={{ height: 32, width: 32, m: 'auto 0' }} />
            <Skeleton variant='circular' sx={{ height: 32, width: 32, m: 'auto 8px' }} />
        </Box>
    )
}

export default SkeletonDataGrid