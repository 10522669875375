import withPermission from '@/hocs/withPermission';
import { AddOutlined } from '@mui/icons-material';
import { Button, Drawer } from '@mui/material';
import { useState } from 'react';
import Subcontract from '../../../Detail/Subcontract';

const CreateSubcontract = ({ contract }) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <Drawer anchor="right" sx={{ width: 450 }} open={open}>
                <Subcontract.Create contract={contract} handleClose={() => setOpen(false)} />
            </Drawer>
            <Button size='small'
                startIcon={<AddOutlined />}
                variant="contained"
                onClick={() => { setOpen({ open: true }) }}
            >
                Thêm Phụ Lục Hợp Đồng
            </Button>
        </>
    )
}

export default withPermission(CreateSubcontract, {
    feature: 'subcontract',
    action: 'create',
    type: 'Button'
})