import DatePicker from "@components/DatePicker"
import ErrorHelperText from "@components/shared/ErrorHelperText"
import MultipleSelectCustomize from "@components/shared/MultipleSelectCustomize"
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize"
import { DeleteOutlineOutlined } from "@mui/icons-material"
import AddOutlined from "@mui/icons-material/AddOutlined"
import { Box, Checkbox, FormControl, FormControlLabel, Grid, IconButton, Switch, TextField, Typography } from "@mui/material"
import _ from "lodash"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"
import Contact from "./Contact"
import DateRangeCustomized from "./DateRangeCustomized"
import Employees from "./Employees"
import InputUnlimitCustomized from "./InputUnlimitCustomized"
import Products from "./Product"
import TablePrices from "./TablePrices"
import Service from "./Service"
import CustomerService from "./CustomerService"

const ArrayFields = ({ label, name, attributes = [], ...props }) => {

    const { control } = useFormContext()
    const { fields, append, remove } = useFieldArray({
        control, name
    })

    return (
        <>
            <Typography sx={{ fontSize: 14, mb: 2 }} color="primary">
                {label}
            </Typography>
            <Box sx={{ p: 2, border: '1px solid #333', ...props?.styled }}>
                {fields?.map((field, index) => {

                    const sumaryCols = _.sumBy(attributes, (attr) => attr?.cols?.md || 12)
                    const actionCols = Math.ceil(sumaryCols / 12) * 12 - sumaryCols


                    return (
                        <Grid container spacing={2} key={field?.id} sx={{ mb: 1.5 }}
                        >
                            {attributes?.map((attribute, subIndex) => {

                                const Component = FORM_BASE[attribute?.component]
                                if (!Component) return null

                                return (
                                    <Grid item xs={12} md={attribute?.cols?.md || 12} key={subIndex}>
                                        <Controller
                                            name={`${name}.[${index}].${attribute?.name}`}
                                            control={control}
                                            render={({ field: { value, onChange }, fieldState: { error } }) => {
                                                return (
                                                    <FormControl fullWidth>
                                                        <Component value={value} onChange={onChange} {...attribute?.params} />
                                                        {!!error?.message && <ErrorHelperText message={error?.message} />}
                                                    </FormControl>
                                                )
                                            }}
                                        />
                                    </Grid>
                                )
                            })}
                            {!props?.offAction && <Grid item xs={2} md={actionCols || 12} >
                                <Box display="flex" justifyContent="flex-end" alignItems="center">
                                    <IconButton size='small'
                                        sx={{ border: '1px solid #777680', mt: '4px' }}
                                        onClick={() => remove(index)}
                                    >
                                        <DeleteOutlineOutlined color="error" fontSize="small" />
                                    </IconButton>
                                </Box>
                            </Grid>}
                        </Grid>
                    )
                })}
                {!props?.offAction && <Box sx={{ mt: 1.25, display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton size="small" onClick={() => {
                        let hash = {}
                        for (let i = 0; i < attributes?.length; i++) {
                            hash[attributes[i]?.name] = ''
                        }
                        append({ ...hash })
                    }}>
                        <AddOutlined fontSize="small" color="primary" />
                    </IconButton>
                </Box>}
            </Box >
        </>
    )
}

const FORM_BASE = {
    SingleSelect: ({ value, onChange, options, ...props }) => {
        return <SingleSelectCustomize
            options={options}
            value={options?.find(option => option?.value === value) || undefined}
            onChange={(event, newValue) => onChange(newValue?.value || '')}
            {...props}
        />
    },
    MultiSelect: ({ value: values, onChange, options, ...props }) => {
        return <MultipleSelectCustomize
            options={options}
            value={options?.filter(option => values?.includes(option?.value)) || undefined}
            onChange={(event, newValues) => onChange([...newValues?.map(option => option?.value)])}
            {...props}
        />
    },
    InputUnlimitCustomized: ({ value, onChange, ...props }) => <InputUnlimitCustomized value={value} onChange={onChange} {...props} />,
    TextField: ({ value, ...props }) => <TextField size="small" value={value} {...props} InputLabelProps={{ shrink: true }} />,
    DateField: ({ value, onChange, ...props }) => <DatePicker value={value} onChange={onChange} {...props} />,
    DateRange: ({ value: values, onChange, ...props }) => <DateRangeCustomized values={values} onChange={onChange} {...props} />,
    ArrayField: ArrayFields,
    "SingleSelect.Contact": ({ value, onChange, ...props }) => <Contact value={value} onChange={onChange} {...props} />,
    "SingleSelect.CustomerService": ({ value, onChange, ...props }) => <CustomerService value={value} onChange={onChange} {...props} />,
    "SingleSelect.Service": ({ value, onChange, ...props }) => <Service value={value} onChange={onChange} {...props} />,
    "MultiSelect.Employees": ({ value: values, onChange, ...props }) => <Employees values={values} onChange={onChange} {...props} />,
    "MultiSelect.Products": ({ value: values, onChange, ...props }) => <Products values={values} onChange={onChange} {...props} />,
    TablePrices: TablePrices,
    Typography: ({ children, ...props }) => <Typography {...props}>{children}</Typography>,
    Switch: ({ value, onChange, ...props }) => <Box sx={{
        width: '100%',
        display: 'flex',
        alignContent: 'center',
        border: '1px solid rgb(192, 192, 192)',
        borderRadius: '4px',
        pl: '16px',
        height: '40px'
    }}>
        <FormControlLabel
            control={<Switch size="small" value={value} onChange={onChange} />}
            {...props}
            label={<Typography sx={{ fontSize: 14 }}>{props?.label}</Typography>}
        />
    </Box>,
    Checkbox: ({ value, onChange, ...props }) => <Box sx={{
        width: '100%',
        display: 'flex',
        alignContent: 'center',
        border: '1px solid rgb(192, 192, 192)',
        borderRadius: '4px',
        pl: '16px',
        height: '40px'
    }}>
        <FormControlLabel
            control={<Checkbox size="small" checked={value} onChange={onChange} />}
            {...props}
            label={<Typography sx={{ fontSize: 14 }}>{props?.label}</Typography>}
        />
    </Box>,
}
export default FORM_BASE