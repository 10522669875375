import { create, enforce, test } from "vest";

const serviceConfigoptionValidators = create((data = {}) => {
	let infos = [];
	data?.service_configoptions?.forEach((info, index) => {
		test(
			`service_configoptions.[${index}].name`,
			"Tên cấu hình không được để trống.",
			() => {
				enforce(info?.name).isNotEmpty();
			}
		);
		test(
			`service_configoptions.[${index}].name`,
			`Tên cấu hình đã tồn tại ở dòng ${infos?.findIndex((x) => x === info?.name) + 1
			}.`,
			() => {
				const isValid = infos.findIndex((x) => x === info?.name) === -1;
				enforce(isValid)?.isTruthy();
			}
		);
		test(
			`service_configoptions.[${index}].value`,
			"Thông số cấu hình không được để trống.",
			() => {
				enforce(info?.value).isNotEmpty();
			}
		);
		test(
			`service_configoptions.[${index}].unit`,
			"Đơn vị tính không được để trống.",
			() => {
				enforce(info?.unit).isNotEmpty();
			}
		);
		infos.push(info?.name);
	});
});

export default serviceConfigoptionValidators;
