import { Button } from '@mui/material'
import React, { useState } from 'react'
import FormFeedback from '../../FormFeedback'

const Resolve = ({
    contractProcess,
    type
}) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <Button
                variant="outlined"
                size="small"
                disabled={open}
                onClick={() => setOpen(true)}
            >
                Phản Hồi/ Xử Lý
            </Button>
            {open && (
                <FormFeedback
                    contractProcessType={type}
                    contractProcess={contractProcess}
                    handleClose={() => setOpen(false)}
                />
            )}
        </>
    )
}

export default Resolve