import Create from "./Create"
import Update from "./Update"
import Destroy from "./Destroy"
import List from "./List"
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Create,
    Update,
    Destroy,
    List
}   