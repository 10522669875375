import React from 'react'
import withPermission from '@/hocs/withPermission'
import SubcontractUpdate from "./SubcontractUpdate"

const Update = ({ onClose, defaultValues = {}}) => {
    return (
        <SubcontractUpdate
            defaultValues={defaultValues}
            onClose={onClose}
        />
    );
};

export default withPermission(Update, { feature: 'subcontract', action: 'update' });