import React from 'react'
import { handleScrollSmoothOffsetTop } from '@/functions';

const useAutoScrollToElement = (ref, dependencies = []) => {
    React.useEffect(() => {
        if (!ref?.current) {
            return; // skip scroll
        }
        return handleScrollSmoothOffsetTop(ref?.current)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, ...dependencies]);
}

export default useAutoScrollToElement