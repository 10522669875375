import ProductGroupApi from '@/apis/beta/Product/ProductGroupApi'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import MultipleSelectCustomize from '@components/shared/MultipleSelectCustomize'

const Products = ({ values = [], onChange, scope, ...props }) => {
    const { data: products, isFetching } = ProductGroupApi.List(
        `filters[$and][0][product_groups.product_group_hidden][$eq]=false`
    );

    return (
        <MultipleSelectCustomize
            loading={isFetching}
            options={products}
            value={products?.filter(option => values?.includes(option?.product_group_name?.toUpperCase())) || []}
            onChange={(event, newValues) => onChange([...newValues?.map(option => option?.product_group_name?.toUpperCase())])}
            formatCollection={{ label: 'product_group_name', value: 'product_group_name' }}
            compareAttributes={['product_group_name']}
            input={{
                ...props?.input,
            }}
            mode='server'
        />
    )
}

export default withSuspense(Products, MuiSkeleton['TextField'])