import { Button } from '@mui/material'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import withPermission from '@/hocs/withPermission'
import { EditOutlined } from '@mui/icons-material'

const ButtonUpdateCustomer = ({
    ...customer
}) => {
    const { customer_id } = useParams()
    return (
        <Link to={`/customers/${customer_id}/update`} replace>
            <Button variant='contained'
                disabled={customer?.customer_service_information?.customer_status === "cancelled"}
                size='small'
                fullWidth
                startIcon={<EditOutlined fontSize='small' />}
                sx={{ height: { xs: 40, md: 42 } }}
            >
                Chỉnh Sửa
            </Button>
        </Link>
    )
}

export default withPermission(ButtonUpdateCustomer, { feature: "customer", action: "update", type: "button" })