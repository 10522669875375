import withSuspense from '@/hocs/withSuspense'
import { CONTRACT_NUMBER_STATUS_TYPES } from '@/libs/constants'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, FormControl, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ContractStatus = () => {

    const { control, formState: { errors } } = useFormContext()

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 0.5
        }}>
            <Box sx={{
                minWidth: {
                    xs: '100%', md: 300
                }
            }}>
                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                    Trạng Thái *
                </Typography>
            </Box>
            <Controller
                name="contract_status"
                control={control}
                render={({ field: { value, onChange } }) => {

                    const selected = CONTRACT_NUMBER_STATUS_TYPES?.find(v => parseInt(v?.value) === parseInt(value)) || null
                    return (
                        <FormControl fullWidth>
                            <SingleSelectCustomize
                                value={selected}
                                disabled={true}
                                options={CONTRACT_NUMBER_STATUS_TYPES}
                                onChange={(event, newValue) => onChange(newValue.value >= 0 ? newValue.value : -1)}
                                input={{
                                    placeholder: "Chọn trạng thái hợp đồng...",
                                }}
                            />
                            {!!errors?.contract_status && <ErrorHelperText message={errors?.contract_status?.message} />}
                        </FormControl>
                    )
                }} />
        </Box>
    )
}

export default withSuspense(ContractStatus, MuiSkeleton["FormControlTextField"])