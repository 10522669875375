import DataGridCustomize from '@components/DataGrid';
import ServiceActivities from '@components/Templates/shared/Service/ServiceActivities';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { Fragment, useMemo } from 'react';
import SSLServiceLibs from '../functions';
import ServiceManager from '@components/Templates/SwiftFederation/shared/ServiceManager';

const SSLService = ({
    service
}) => {
    const initialColumns = useMemo(() => {
        return [...SSLServiceLibs.rows({ service })]
    }, [service]);

    return (
        <Fragment>
            <ServiceActivities activities={SSLServiceLibs.activities} service={service} />
            <Box sx={{ mt: "10px" }}>
                <DataGridCustomize
                    initialState={{
                        pinnedColumns: { right: ["actions"] },
                    }}
                    rows={
                        initialColumns?.filter((service) => !service?.hide)?.map((service, index) => ({
                            ...service,
                            id: index + 1,
                        })) || []
                    }
                    columns={SSLServiceLibs?.columns}
                    components={{
                        Toolbar: "disabled",
                        Pagination: "disabled",
                    }}
                    disableSelectionOnClick
                    disableColumnFilter
                    getRowClassName={({ row }) => {
                        return clsx(`grid-dc-vnetwork`, row?.classNames || "");
                    }}
                />
            </Box>
            <ServiceManager service={service} />
        </Fragment>

    );
}

export default SSLService