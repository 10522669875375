export const buildServiceInformations = (informations = [], object = {}) => {
    informations?.forEach((item) => {
        let obj = {}
        switch (item?.name?.toLowerCase()) {
            case "website":
                obj["website"] = item.value
                break;
            case "sub-domain":
                obj["sub-domain"] = item.value
                break;
            case "park-domain":
                obj["park-domain"] = item.value
                break;
            case "power":
                obj["power"] = item.value
                break;
            case "mssql":
                obj["mssql"] = item.value
                break;
            case "attachment":
                obj["attachment"] = item?.value
                break;
            case "mysql":
                obj["mysql"] = item.value
                break;
            case "account":
                obj["account"] = item?.value
                break
            case "cpu":
            case "vcore":
                obj["vCore"] = item.value
                break;
            case "ssd":
                obj["storage"] = item.value
                break;
            case "ram":
            case "memory":
                obj["ram"] = item.value
                break
            case "disk":
                obj["storage"] = item.value
                break
            case "software":
                obj["software"] = item.value
                break
            case "operator system":
                break
            case "bandwidth":
                obj["bandwidth"] = item.value
                break
            case "ip":
                obj["ip"] = item.value
                break
            case "storage":
                obj["storage"] = item.value
                break
            case "datacenter":
                obj["idc"] = item.value
                break
            default:
                if (!!item?.name.toLowerCase()?.match(/cores/)) {
                    obj["cpu"] = item.value;
                }
                if (!!item?.name.toLowerCase()?.match(/ssd/) || !!item.name?.match(/hdd/) || !!item.name?.match(/sas/)) {
                    obj["storage"] = item.name;
                }
                break
        }
        object = (Object.assign(object, obj))
    })
    return object
}

export const buildServiceInformationColumns = (informations = []) => {
    let arrs = []
    informations.forEach((item, index) => {
        switch (item?.name?.toLowerCase()) {
            case "website":
                return arrs.push({
                    field: 'website',
                    headerName: "Website",
                    width: 120,
                    renderCell: ({ value }) => !!value ? value : ""
                })
            case "sub-domain":
                return arrs.push({
                    field: 'sub-domain',
                    headerName: "Sub Domain",
                    width: 120,
                    renderCell: ({ value }) => !!value ? value : ""
                })
            case "park-domain":
                return arrs.push({
                    field: 'park-domain',
                    headerName: "Park Domain",
                    width: 120,
                    renderCell: ({ value }) => !!value ? value : ""
                })
            case "power":
                return arrs.push({
                    field: 'power',
                    headerName: "Power",
                    width: 120,
                    renderCell: ({ value }) => !!value ? value : ""
                })
            case "mssql":
                return arrs.push({
                    field: 'mssql',
                    headerName: "MSSQL",
                    width: 120,
                    renderCell: ({ value }) => !!value ? value : ""
                })
            case "attachment":
                return arrs.push({
                    field: 'attachment',
                    headerName: "Attachment",
                    width: 120,
                    renderCell: ({ value }) => !!value ? value : ""
                })
            case "mysql":
                return arrs.push({
                    field: 'mysql',
                    headerName: "MySQL",
                    width: 120,
                    renderCell: ({ value }) => !!value ? value : ""
                })
            case "account":
                return arrs.push({
                    field: 'account',
                    headerName: "Account",
                    width: 120,
                    renderCell: ({ value }) => !!value ? value : ""
                })
            case "vcore":
            case "cpu":
                return arrs.push({
                    field: 'vCore',
                    headerName: "vCore/ CPU",
                    width: 120,
                    renderCell: ({ value }) => !!value ? value : ""
                })
            case "memory":
            case "ram":
                return arrs.push({
                    field: 'ram',
                    headerName: 'RAM (xGB)',
                    width: 120,
                    renderCell: ({ value }) => !!value ? value : ""
                })
            case "storage":
                return arrs.push({
                    field: 'storage',
                    headerName: 'Storage (xGB)',
                    width: 120,
                    renderCell: ({ value }) => !!value ? value : ""
                })
            case "software":
                return arrs.push({
                    field: 'license',
                    headerName: 'License/ Software',
                    width: 120,
                    renderCell: ({ value }) => !!value ? value : ""
                })
            case "idc":
            case "datacenter":
                return arrs.push({
                    field: 'idc',
                    headerName: 'IDC/ RACK',
                    width: 120,
                    renderCell: ({ value }) => !!value ? value : ""
                })
            case "operator system":
                return;
            // case "bandwidth":
            //     return arrs.push({
            //         field: 'bandwidth',
            //         headerName: 'Bandwidth (xGpbs)',
            //         width: 150,
            //         renderCell: ({ value }) => !!value ? value : ""
            //     })
            case "ip":
                return arrs.push({
                    field: 'ip',
                    headerName: 'IP',
                    width: 120,
                    renderCell: ({ value }) => !!value ? value : ""
                })
            default:
                if (!!item?.name?.toLowerCase()?.match(/ssd/)
                    || !!item?.name?.toLowerCase().match(/hdd/)
                    || !!item?.name?.toLowerCase().match(/sas/)) {
                    return arrs.push({
                        field: 'storage',
                        headerName: 'Disk',
                        width: 120,
                        renderCell: ({ value }) => !!value ? value : ""
                    })
                }
                if (!!item?.name.toLowerCase()?.match(/cores/)) {
                    return arrs.push({
                        field: 'vCore',
                        headerName: 'vCore/ CPU',
                        width: 120,
                        renderCell: ({ value }) => !!value ? value : ""
                    })
                }
                return null
        }
    })

    const myIndex = ["storage", "ssd", "disk", "ram", "vCore"]
    return arrs.sort(function (a, b) {
        var x = myIndex.indexOf(a?.field)
        var y = myIndex.indexOf(b?.field)

        return ((x > y) ? -1 : ((x < y) ? 1 : 0))
    })
} 

export const buildCurrentlyServiceInformations = (service = {}, metadata, addons = []) => {
    console.log(`debug:service-first-setting:`, metadata)
    let currently = {
        ...metadata,
        status: "Cấu Hình Hiện Tại",
        service_start_date: service?.service_start_date,
        service_billing_startdate: service?.service_billing_startdate,
        service_billing_enddate: service?.service_billing_enddate,
        service_enddate: service?.service_duedate,
        service_tax: service?.service_tax,
        service_status: service?.service_status,
        service_contract_name: ''
    }

    console.log(addons)

    addons?.forEach((addon) => {
        if (addon?.service_status !== "cancel" && addon?.service_status !== "stop") {
            switch (addon?.service_product_name?.toLowerCase()) {
                case "vcore":
                case "cpu":
                    currently["vCore"] += addon?.service_addon_qty
                    break;
                case "ip":
                    currently["ip"] += addon?.service_addon_qty
                    break;
                case "ram":
                    currently["ram"] += addon?.service_addon_qty
                    break;
                case "ram(x32gb)":
                    currently["ram"] += addon?.service_addon_qty * 32
                    break;
                case "ram(x64gb)":
                    currently["ram"] += addon?.service_addon_qty * 64
                    break;
                case "ssd":
                case "disk":
                case "storage":
                    currently["storage"] += addon?.service_addon_qty
                    break;
                case "ssd - 960gb":
                    currently["storage"] += addon?.service_addon_qty * 960
                    break
                case "website":
                    currently["website"] += addon?.service_addon_qty
                    break
                case "sub-domain":
                    currently["sub-domain"] += addon?.service_addon_qty
                    break
                case "park-domain":
                    currently["park-domain"] += addon?.service_addon_qty
                    break
                case "power":
                    currently["power"] += addon?.service_addon_qty
                    break
                case "mssql":
                    currently["mssql"] += addon?.service_addon_qty
                    break
                case "attachment":
                    currently["attachment"] += addon?.service_addon_qty
                    break
                case "mysql":
                    currently["mysql"] += addon?.service_addon_qty
                    break
                case "account":
                    currently["account"] += addon?.service_addon_qty
                    break
                case "licence transcoding":
                    currently["license"] += addon?.service_addon_qty
                    break;
                case "rack":
                    currently["idc"] = addon?.service_addon_qty
                    break;
                default:
                    break;
            }
        }
    })

    return currently
}