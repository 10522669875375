import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";

class WarehouseProcessLogApi {
  Index = (id) => {
    return useQueryWithCustomized(
      [
        "warehouse_process_logs", id],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_process_logs/${id}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              warehouse_process_logs: data?.data?.warehouse_process_logs,
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            console.log(error.response);
            return {
              warehouse_process_logs: [],
            };
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };
  async createProcessLog(data) {
    const response = await requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_process_logs`,
      "POST",
      data
    );
    return response?.data;
  }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new WarehouseProcessLogApi();
