import { FormControl, Grid, TextField, Box, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import TemporaryAddressCity from './TemporaryAddressCity'
import TemporaryAddressDistrict from './TemporaryAddressDistrict'
import TemporaryAddressWard from './TemporaryAddressWard'

const EmployeeTemporaryAddress = () => {

    const { control, formState: { errors } } = useFormContext()

    return (
        <Box
            sx={{
                p: 1.5,
                mt: 2,
                border: "1px solid rgba(0, 0, 0, 0.18)",
                borderRadius: 1,
            }}
        >
            <Typography
                sx={{
                    fontSize: { xs: 16, md: 18 },
                    fontWeight: "medium",
                    mb: 1,
                }}
            >
                Địa Chỉ Tạm Trú
            </Typography>
            <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
                <TemporaryAddressCity />
            </Grid>
            <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
                <TemporaryAddressDistrict />
            </Grid>
            <Grid item xs={12}>
                <TemporaryAddressWard />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="employee_temporary_address"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <TextField
                                    value={value}
                                    label="[Số Nhà/Căn Hộ] - [Tên Đường/Hẻm/Ngõ] (*)"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onChange={onChange}
                                    size='small'
                                    placeholder='Địa chỉ...'
                                    error={!!errors?.employee_temporary_address}
                                    helperText={errors?.employee_temporary_address?.message}
                                />
                            </FormControl>
                        )
                    }} />
            </Grid>
        </Box>
    )
}

export default EmployeeTemporaryAddress