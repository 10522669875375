import EmployeeApi from '@/apis/beta/Employee/EmployeeApi'
import withSuspense from '@/hocs/withSuspense'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import MultipleSelectCustomize from '@components/shared/MultipleSelectCustomize'
import { Box, FormControl, Typography } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ScheduleGuest = () => {

    const { data } = EmployeeApi.ListEmployees({ page: 1, pageSize: 999 })
    const { control, formState: { errors } } = useFormContext()
    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1, mt: 2 }}>
            <Box sx={{ width: 300, minWidth: 300, display: 'flex' }}>
                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                    Người Tham Gia *
                </Typography>
            </Box>
            <Controller
                name="schedule_guest_id"
                control={control}
                render={({ field: { value, onChange } }) => {
                    return (
                        <FormControl fullWidth>
                            <MultipleSelectCustomize
                                options={_.sortBy(data, ['employee_name'])}
                                value={value}
                                compareAttributes={['employee_name']}
                                formatCollection={{ value: 'employee_id', label: 'employee_name' }}
                                input={{ placeholder: "Nhập tên nhân viên tìm kiếm..." }}
                                onChange={(event, value) => {
                                    onChange(value)
                                }}
                            />
                            {!errors?.schedule_guest_id && <ErrorHelperText message={errors?.schedule_guest_id?.message} />}
                        </FormControl>
                    )
                }} />
        </Box>
    )
}

export default withSuspense(ScheduleGuest, MuiSkeleton["FormControlTextField"])