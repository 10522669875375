import { Box, Typography } from '@mui/material'
import React from 'react'
import withPermission from '@/hocs/withPermission'

const Create = () => {
    return (
        <Box>
            <Typography sx={{ m: 'auto 0', fontSize: 14, color: 'grey.700', mb: 2 }}>
                Service.Create
            </Typography>
        </Box>
    )
}

export default withPermission(Create, { feature: 'service', action: 'create' })