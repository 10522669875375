import DataGridCustomize from "@components/DataGrid";
import { Fragment, useState } from "react";

const CDN = ({ products = [] }) => {
  const [columns] = useState([
    {
      field: "product_id",
      headerName: "ID",
      width: 60,
    },
    {
      field: "product_name",
      headerName: "Tên Sản Phẩm",
      width: 250,
    },
  ]);
  return (
    <Fragment>
      <DataGridCustomize
        columns={columns}
        components={{
          Toolbar: "disabled",
          Pagination: "disabled",
        }}
        rows={products?.map((item) => ({ ...item, id: item?.product_id }))}
      />
    </Fragment>
  );
};

export default CDN;
