import { format_numeral_price } from "@/functions";
import { Box, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";

const ServicePricing = ({ product_pricing_amount, product_addons = [] }) => {
  const handlerAmount = useCallback(() => {
    let tt = parseFloat(product_pricing_amount);
    // const coff = SERVICE_PERIODS.find(item => item.value === product_billing_cycle)?.coefficient
    if (product_addons && product_addons.length !== 0) {
      product_addons?.forEach((addon) => {
        tt += parseInt(addon?.qty) * parseFloat(addon?.pricing_apply);
      });
    }
    return tt;
  }, [product_pricing_amount, product_addons]);

  const [amount, setAmount] = useState(handlerAmount());

  React.useEffect(() => {
    setAmount(handlerAmount());
  }, [handlerAmount]);
  return (
    <Box sx={{ display: "flex" }}>
      <Typography
        component="div"
        sx={{
          fontSize: 14,
          color: "#777680",
          mt: 1,
        }}
      >
        Đơn giá bán:
        <Typography
          sx={{
            fontSize: 14,
            display: "inline-block",
            textDecoration: "underline",
            ml: 0.5,
            color: "#00000F",
          }}
        >
          đ
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            display: "inline-block",
            color: "#00000F",
          }}
        >
          {format_numeral_price(amount)}
        </Typography>
      </Typography>
    </Box>
  );
};

export default ServicePricing;
