import ButtonBaseExport from "../shared/ButtonBaseCustom";
import { useMutation } from "react-query";
import { Filter } from "@/hooks/useFilters";
import { downloadFileWithBinaryResponse } from "@/functions";
import TicketApi from "@/apis/beta/Ticket/TicketApi";

const TicketExport = ({ query }) => {
  const { mutateAsync, isLoading } = useMutation(TicketApi.TicketExport);

  const handler = async () => {
    const response = await mutateAsync({
      file: true,
      query: Filter.stringify(query),
    });
    if (!response) return;
    downloadFileWithBinaryResponse(`${Date.now()}.xlsx`, response);
  };

  return (
    <ButtonBaseExport
      handler={handler}
      loading={isLoading}
      disabled={isLoading}
    />
  );
};

export default TicketExport;
