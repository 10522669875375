import DialogPreviewFiles from "@components/DialogPreviewFiles";
import { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import qs from "query-string";

const PreviewFileContext = createContext();

export const PreviewFileContextProvider = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [state, setState] = useState({
    open: false,
    key: null,
    expires_in: 3600,
    useDrivePath: true,
    listFile: [],
    usePrefixStr: false,
  });

  const fileName = state?.key?.split("/")?.[state?.key?.split("/")?.length - 1];

  const show = (key, expires_in = 3600, listFile) => {
    setState({
      open: true,
      key,
      expires_in,
      useDrivePath: true,
      listFile,
    });
  };

  const showNotUseDrivePath = (
    key,
    expires_in = 3600,
    listFile,
    usePrefixStr
  ) => {
    setState({
      open: true,
      key,
      expires_in,
      useDrivePath: false,
      listFile,
      usePrefixStr,
    });
  };

  const close = () => {
    setState({
      open: false,
      key: null,
      expires_in: 3600,
      listFile: [],
    });

    searchParams.has("fileName") &&
      searchParams.delete("fileName");
    setSearchParams({ ...qs.parse(searchParams.toString()) });
  };

  const switchPath = (path) => {
    setState((prev) => ({ ...prev, key: path }));
  };

  return (
    <PreviewFileContext.Provider value={{ show, close, showNotUseDrivePath }}>
      {children}

      {state?.open && (
        <DialogPreviewFiles
          handleClose={close}
          name={fileName}
          path={state?.key}
          open={state?.open}
          type={fileName?.split(".")?.[fileName?.split(".")?.length - 1]}
          useDrivePath={state?.useDrivePath}
          listFile={state?.listFile}
          switchPath={switchPath}
          usePrefixStr={state?.usePrefixStr}
        />
      )}
    </PreviewFileContext.Provider>
  );
};

export const usePreviewFileContext = () => {
  return useContext(PreviewFileContext);
};
