import InvoiceApi from "@/apis/beta/Invoice/InvoiceApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import { transactionValidator } from "@/libs/validators";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { vestResolver } from "@hookform/resolvers/vest";
import { Box } from "@mui/material";
import moment from "moment";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Buttons from "../Buttons";
import InvoiceInformationMetadata from "../Metadata";

const Information = ({ invoiceId = -1 }) => {
  const { data: invoice } = InvoiceApi.Information({ invoiceId: invoiceId });

  const methods = useForm({
    resolver: vestResolver(transactionValidator),
    reValidateMode: "onChange",
    defaultValues: useMemo(() => {
      return {
        payment_method: invoice?.invoice_payment_method || 0,
        transaction_amount: invoice?.invoice_total,
        transaction_fee: 0,
        invoice_paid: invoice?.invoice_paid
          ? moment(invoice?.invoice_paid).add(-7, "hours")
          : moment(),
        commissions: invoice?.commissions || [],
      }
    }, [invoice])
  });

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          gap: 2,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <InvoiceInformationMetadata invoice={invoice} />
        </Box>
        <Box sx={{ minWidth: "20%" }}>
          <Buttons
            invoice={invoice}
            packages={[
              "Approved",
              "PaymentTransaction",
              "CancelInvoice",
              // "RefundInvoice",
              "SendEmail",
              "SendStatement",
              "PreviewInvoice",
              "PreviewStatement",
              "DestroyInvoice",
              "CreateNote",
            ]}
          />
        </Box>
      </Box>
    </FormProvider>
  );
};

export default withSuspense(
  withPermission(Information, { feature: "invoice", action: "show" }),
  MuiSkeleton["PaperInformationButtons"]
);
