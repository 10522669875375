import { selectObjectKeys } from "@/functions";
import { SERVICE_METADATA } from "@/libs/constants";
import ServiceForm from "@components/Templates/ServiceForm";
import ServicePreview from "@components/Templates/ServiceForm/shared/ServicePreview";
import { AddOutlined } from "@mui/icons-material";
import { Box, Button, Collapse, Typography } from "@mui/material";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import Overview from "./Overview";

const OrderProducts = ({ disabled }) => {
  const { control, watch } = useFormContext();
  const { fields, append, remove, update } = useFieldArray({
    control: control,
    name: "order_products",
  });

  const [modal, setModal] = React.useState({
    open: false,
    type: null,
    data: null,
  });

  const performServiceMetadata = (metadata) => {
    return selectObjectKeys(metadata, [
      "product_group_id",
      "product_group_name",
      "product_id",
      "product_name",
      "product_payment_type",
      "product_pricing_amount",
      "product_type",
      "product_billing_cycle",
      "product_configoptions",
      "product_informations",
      "product_pricing_configures",
      "product_billing_configures",
      "product_template",
      "product_addons",
      "product_paid",
      "product_export_contract",
    ]);
  };

  const saver = (newValue) => {
    if (newValue?.index >= 0) {
      update(newValue?.index, performServiceMetadata(newValue));
    } else {
      append(performServiceMetadata(newValue));
    }
    return closer();
  };

  const closer = () => setModal({ open: false, type: null, data: null });

  return (
    <React.Fragment>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ minWidth: 300 }}>
          <Typography sx={{ fontSize: 14, color: "#777680" }}>
            Dịch Vụ *
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {modal?.open && (
            <Collapse in={modal?.open}>
              <ServiceForm
                customerId={parseInt(watch(`customer_id`))}
                type={modal?.type || "create"}
                orderredProductIds={
                  fields?.map((item) => item?.product_id) || []
                }
                saver={saver}
                closer={closer}
                isPoc={true}
                defaultValues={modal?.data}
              />
            </Collapse>
          )}
          {!modal?.open && (
            <Collapse in={!modal?.open}>
              <Box sx={{ display: "flex" }}>
                <Button
                  size="small"
                  variant="outlined"
                  disabled={disabled}
                  onClick={() =>
                    setModal({
                      open: true,
                      type: "create",
                      data: SERVICE_METADATA,
                    })
                  }
                  startIcon={<AddOutlined fontSize="small" />}
                  sx={{
                    height: { xs: 36, md: 40 },
                    minWidth: { xs: "100%", md: 150 },
                  }}
                >
                  Chọn Dịch Vụ
                </Button>
              </Box>
            </Collapse>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", mt: 2 }}>
        <Box sx={{ minWidth: 300 }}>
          <Typography sx={{ fontSize: 14, color: "#777680" }}>
            Danh Sách Dịch Vụ Đã Chọn
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          {(!fields || fields?.length === 0) && (
            <Typography sx={{ m: "auto 0", fontSize: 14, color: "#777680" }}>
              Chưa có dịch vụ được thêm.
            </Typography>
          )}
          {fields &&
            fields?.map((field, index) => {
              return (
                <ServicePreview
                  data={{ ...field, index: index }}
                  key={field.id}
                  disabled={disabled}
                  remover={() => remove(index)}
                  updater={() =>
                    setModal({
                      open: true,
                      type: "update",
                      data: { ...field, index: index },
                    })
                  }
                />
              );
            })}
          {watch(`order_type`) !== "trial" && <Overview products={fields} />}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default OrderProducts;
