import { format_date_short, format_numeral_price } from "@/functions";
import DataGridCustomize from "@components/DataGrid";
import React, { Fragment, useState } from "react";
import ProductInformation from "./ProductInformation";
import Actions from "./Actions";
import Addons from "../../../Detail/Addons";

const Vnis = ({ products = [] }) => {
  const [columns] = useState([
    {
      field: "product_id",
      headerName: "ID",
      width: 60,
      hideable: true,
    },
    {
      field: "product_name",
      headerName: "Tên Sản Phẩm",
      width: 250,
    },
    {
      field: "product_informations",
      headerName: "Cấu Hình Sản Phẩm",
      width: 250,
      renderCell: ({ value }) => <ProductInformation informations={value} />,
    },
    {
      field: "price",
      headerName: "Đơn Giá Niêm Yết",
      width: 150,
      valueGetter: ({ row }) =>
        format_numeral_price(row?.pricing?.pricing || 0),
    },
    {
      field: "price_minimum",
      headerName: "Đơn Giá Min",
      width: 150,
      valueGetter: ({ row }) =>
        format_numeral_price(row?.pricing?.pricing_minimum || 0),
    },
    {
      field: "pricing_apply_startdate",
      headerName: "Thời Gian Bắt Đầu",
      width: 300,
      renderCell: ({ row }) =>
        format_date_short(row?.pricing?.pricing_apply_startdate),
    },
    {
      field: "pricing_apply_enddate",
      headerName: "Thời Gian Kết Thúc Đầu",
      width: 300,
      renderCell: ({ row }) =>
        format_date_short(row?.pricing?.pricing_apply_duedate),
    },
    {
      field: "actions",
      headerName: "",
      width: 60,
      renderCell: ({ row }) => <Actions data={row} />,
    },
  ]);
  return (
    <Fragment>
      <DataGridCustomize
        columns={columns}
        components={{
          Toolbar: "disabled",
          Pagination: "disabled",
        }}
        rows={products?.map((item) => ({ ...item, id: item?.product_id }))}
      />
      <Addons productId={products?.[0]?.product_id} />
    </Fragment>
  );
};

export default Vnis;
