import withPermission from '@/hocs/withPermission'
import { Button } from '@mui/material'
import React from 'react'

const ButtonAssigneeAuto = ({ handleClick }) => {
    return (
        <Button variant='outlined' size='small'
            onClick={handleClick}
            sx={{
                boxShadow: 'none',
                height: 42,
                m: 'auto 0'
            }}
        >
            Tự Động Chỉ Định
        </Button>
    )
}

export default withPermission(ButtonAssigneeAuto, { feature: 'customer', action: 'assignee-auto' })