import CustomerSpamApi from "@/apis/beta/Customer/CustomerSpamApi";
import DataGrid from "@/components/DataGrid";
import DataGridFilter from "@/components/DataGrid/Filter";
import { buildQueryFilterCondition, format_date_short } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import useFilters, { Filter } from "@/hooks/useFilters";
import { CUSTOMER_SOURCES } from "@/libs/constants";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import ButtonCreate from "./ButtonCreate";

const List = () => {
  const location = useLocation();
  const [query, updateQuery] = useFilters(
    "customer-spams.list.filters",
    location
  );

  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const filterableCustomerSpam = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
    const fieldQueries = [
      {
        name: "customer_spam_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "customer_spam_email",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "customer_spam_phone",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
    ];
    return buildQueryFilterCondition("$or", 0, fieldQueries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const initialColumns = [
    {
      field: "customer_spams.customer_spam_id",
      headerName: "ID",
      width: 60,
      hideable: true,
      operators: { type: "number" },
      valueGetter: ({ row }) => row?.customer_spam_id,
      renderCell: ({ value }) => value,
    },
    {
      field: "customer_spam_name",
      headerName: "Tên",
      width: 200,
      operators: { type: "string" },
    },
    {
      field: "customer_spam_email",
      headerName: "Email",
      width: 250,
      operators: { type: "string" },
    },
    {
      field: "customer_spam_phone",
      headerName: "Số Điện Thoại",
      width: 150,
      operators: { type: "string" },
    },
    {
      field: "customer_spams.customer_spam_source",
      headerName: "Nguồn",
      width: 150,
      valueGetter: ({ row }) =>
        CUSTOMER_SOURCES?.find(
          (s) => s.value === parseInt(row?.customer_spam_source)
        )?.label,
      operators: {
        type: "object",
        collections: CUSTOMER_SOURCES,
      },
    },
    {
      field: "customer_spam_created_at",
      headerName: "Ngày Tạo",
      width: 150,
      renderCell: ({ value }) => format_date_short(value),
      operators: {
        type: "date",
      },
    },
    {
      field: "customer_spam_updated_at",
      headerName: "Ngày Cập Nhật",
      width: 150,
      renderCell: ({ value }) => format_date_short(value),
      operators: { type: "date" },
    },
    {
      field: "customer_spam_question",
      headerName: "Câu hỏi",
      width: 350,
      renderCell: ({ value }) => (
        <Box
          sx={{
            display: "flex",
            height: 52,
            width: "100%",
            overflow: "hidden",
            overflowX: "auto",
            "&::-webkit-scrollbar": {
              height: 1.5,
              "&-track": {
                background: "#f1f1f1",
              },
              "&-thumb": {
                background: "#000",
              },
            },
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              flexWrap: "wrap",
              m: "auto 0",
              textAlign: "justify",
            }}
          >
            {value}
          </Typography>
        </Box>
      ),
    },
  ];
  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const historyColumsHidden =
    JSON.parse(localStorage.getItem("customer-spams.list.hiddenColumns")) || [];
  const [columns, setColumns] = React.useState(
    initialColumns?.map((column) => {
      if (historyColumsHidden?.includes(column?.field)) {
        return { ...column, hide: true };
      }
      return { ...column };
    }) || initialColumns
  );
  const filterables = columns
    .filter((column) => !!column?.operators && !column?.hide)
    .map((column) => {
      return {
        field: column?.key ? column.key : column.field,
        label: column?.headerName,
        operators: column?.operators,
      };
    });

  const { data, isFetching } = CustomerSpamApi.List({
    page: page?.page + 1,
    pageSize: page?.pageSize || 10,
    query: Filter.stringify(query),
    searchQuery:
      filterableCustomerSpam() !== "" ? filterableCustomerSpam() : null,
  });

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography sx={{ fontSize: { xs: 18, md: 24 }, fontWeight: "medium" }}>
          Khách Hàng Spam
        </Typography>
        <ButtonCreate />
      </Box>
      <DataGridFilter
        callback={updateQuery}
        filters={{
          key: "customers-spams.list.filters",
          filterables: filterables,
        }}
      />
      <DataGrid
        rows={
          data?.customer_spams?.map((customerSpam) => ({
            ...customerSpam,
            id: customerSpam?.customer_spam_id,
          })) || []
        }
        columns={columns}
        loading={isFetching}
        componentsProps={{
          toolbar: {
            store: "customer-spams.list.hiddenColumns",
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: "customer-spams",
            inputSearch: {
              placeholder: "Nhập tên, email hoặc số điện thoại tìm kiếm",
              value: searchQuery,
              onChange: setSearchQuery,
            },
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize || 20,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        rowCount={data?.pagination?.total}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(List, { feature: "customer", action: "customer-spam-index" }),
  MuiSkeleton["DataGrid"]
);
