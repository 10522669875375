
import Create from "./CreateScope"
import ConfirmCreate from "./Create"
import Update from "./ScopeUpdate"

const ScopeButtons = {
    Create,
    Update,
    ConfirmCreate
}

export default ScopeButtons