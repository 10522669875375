import React from 'react';
import { EmployeeProvider } from '../contexts/employee';

const withEmployee = (Component) => (props) => {
  return (
    <EmployeeProvider>
      <Component {...props} />
    </EmployeeProvider>
  );
};

export default withEmployee;
