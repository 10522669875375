import { Box, Collapse } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import DomainComponents from "../private";
import NoneAccept from "../NoneAccept";
import ServiceManagementBilling from "@components/Templates/ServiceForm/shared/ServiceManagementBilling";

const PaVietnam = ({ customerId, isPoc }) => {
  const { watch } = useFormContext();

  if (isPoc) {
    return <NoneAccept />;
  }

  return (
    <Box>
      <DomainComponents.DomainType />
      <DomainComponents.DomainName />
      <DomainComponents.DomainBilling />
      <DomainComponents.PricingAmount />
      <DomainComponents.PaymentType />
      <ServiceManagementBilling />
      <Collapse in={watch("domain.domain_type") === "transfer"}>
        <DomainComponents.EppCode />
      </Collapse>
      <DomainComponents.Addons />
      <DomainComponents.DNS />
      <DomainComponents.CustomerInformation customerId={customerId} />
    </Box>
  );
};

export default PaVietnam;
