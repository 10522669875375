import { Box, Checkbox } from '@mui/material'

const PaymentMethod = ({ data, setState }) => {
    return (
        <Box display="flex" sx={{
            alignItems: 'center',
            width: '100%',
            alignContent: 'center'
        }}>
            <Checkbox
                size='small'
                color="primary"
                checked={data?.payment_config_method === 'progressive'}
                onChange={(event) => {
                    const checked = event.target.checked

                    setState(preData => [
                        ...preData?.slice(0, data?.id),
                        {
                            ...data,
                            payment_config_method: checked ? 'progressive' : 'tierd',
                        },
                        ...preData?.slice(data?.id + 1)
                    ])
                }}
            />

        </Box>
    )
}

export default PaymentMethod