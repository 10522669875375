import { format_numeral_price } from '@/functions'
import { Box, Typography } from "@mui/material"
import React from 'react'

const Metadata = ({ configoptions = {} }) => {


    const [data] = React.useState(JSON.parse(configoptions))
    return (
        <Box sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            p: "4px 16px 4px 8px",
            gap: 0.5,
            flexDirection: 'column'
        }}>
            {Object.keys(data)?.map((key) => {
                return (
                    <Typography component="div"
                        sx={{
                            fontSize: 13,
                            color: "#777680",
                            textTransform: "uppercase",
                            fontWeight: 'medium',
                            m: 'auto 0'
                        }}>
                        [{key} =
                        <Typography sx={{
                            ml: 0.5,
                            display: 'inline-block',
                            fontSize: 13
                        }}>
                            {format_numeral_price(data?.[`${key}`] || 0)}
                        </Typography>]
                    </Typography>
                )
            })
            }
        </Box>
    )
}

export default Metadata