import { DeleteOutlineOutlined } from "@mui/icons-material";
import AddOutlined from "@mui/icons-material/AddOutlined";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import {
  Box,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { indigo } from "@mui/material/colors";
import { Fragment, createElement, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import AddonSelect from "./AddonSelect";
import Amount from "./Amount";
import NumericFormatCustom from "@components/NumericFormatCustom";
import { PRODUCT_UNITS } from "@/libs/constants";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import Manufacture from "./Manufactures";
import WarehouseModel from "./WarehouseModel";
import ServerConfigBuilder from "./Server";
const ICONS = {
  Visibility: VisibilityOutlined,
  VisibilityOff: VisibilityOffOutlined,
};

const PRIVATE_SERVICE_MODELS = [
  "server",
  "processor",
  // "cpu",
  "disk",
  "memory",
  "ram",
  "card",
  "network",
];

const DeviceLiquidationAddons = ({ productAddons = [] }) => {
  const [state, setState] = useState(false);
  const { control } = useFormContext();

  const {
    fields: addons,
    append,
    insert,
    remove,
  } = useFieldArray({
    control: control,
    name: "product_addons",
  });

  const [hideable, setHideable] = useState(false);

  if (!addons || addons?.length === 0) return null;

  return (
    <Fragment>
      {state && (
        <AddonSelect
          append={append}
          selecteds={addons?.map((x) => x?.product_id) || []}
          addons={productAddons}
          onClose={() => setState(false)}
        />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            gap: 0.5,
          }}
        >
          <IconButton
            size="small"
            sx={{ height: 24, width: 24, m: "auto 0" }}
            onClick={() => setHideable(!hideable)}
          >
            {createElement(
              ICONS[hideable ? "VisibilityOff" : "Visibility"],
              {
                fontSize: "small",
                color: "primary",
                sx: {},
              },
              null
            )}
          </IconButton>
          <Typography
            sx={{ fontSize: 14, mb: 0.75, mt: 1.25, fontWeight: "medium" }}
            color="text.black"
          >
            Thiết Lập Cấu Hình *
          </Typography>
        </Box>
        <Typography
          onClick={() => setState(true)}
          sx={{
            fontSize: 14,
            m: "auto 0",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          color="primary"
        >
          + More [ADDON]
        </Typography>
      </Box>
      <Collapse in={!hideable}>
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: 1.5, mt: 1.5 }}
        >
          {addons?.map((addon, index) => {
            if (addon?.product_type === "cpu") return null;
            return (
              <Box
                key={addon?.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                }}
              >
                {index !== 0 && <Divider sx={{ m: "0 0" }} light />}
                {index === 0 && (
                  <Typography sx={{ fontSize: 14, color: "#ef5350" }}>
                    Vui lòng thêm đúng số lượng (Ex: 4x RAM DDR4 32GB thì thêm 4
                    thanh MEMORY tương ứng).
                  </Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 0.5,
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: "medium",
                      textTransform: "uppercase",
                    }}
                    color="primary"
                  >
                    {addon?.product_name}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 0.5,
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => insert(index + 1, { ...addon })}
                    >
                      <AddOutlined
                        fontSize="small"
                        sx={{ ":hover": { color: indigo[900] } }}
                      />
                    </IconButton>
                    <IconButton size="small" onClick={() => remove(index)}>
                      <DeleteOutlineOutlined
                        fontSize="small"
                        sx={{ ":hover": { color: "#ef5350" } }}
                      />
                    </IconButton>
                  </Box>
                </Box>
                {PRIVATE_SERVICE_MODELS.includes(addon?.product_type) && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      gap: 1.5,
                    }}
                  >
                    <Controller
                      control={control}
                      name={`product_addons.[${index}].warehouse_model_name`}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <FormControl
                            sx={{
                              maxWidth: {
                                xs: "100%",
                                md: "calc((100% - 12px * 2) / 3)",
                              },
                              minWidth: {
                                xs: "100%",
                                md: "calc((100% - 12px * 2) / 3)",
                              },
                            }}
                            fullWidth
                          >
                            <WarehouseModel
                              position={index}
                              warehouseType={addon?.product_type || ""}
                              value={value}
                              onChange={onChange}
                            />
                          </FormControl>
                        );
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        gap: 1.5,
                        width: "100%",
                      }}
                    >
                      <Controller
                        control={control}
                        name={`product_addons.[${index}].warehouse_brand_name`}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <FormControl fullWidth>
                              <Manufacture
                                position={index}
                                value={value}
                                onChange={onChange}
                              />
                            </FormControl>
                          );
                        }}
                      />
                      <Controller
                        control={control}
                        name={`product_addons.[${index}].warehouse_item_service_tag`}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <FormControl fullWidth>
                              <TextField
                                size="small"
                                label="Service Tag/ Series (nếu có)"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={value}
                                onChange={onChange}
                                placeholder="Nhập Service Tag/ Series..."
                              />
                            </FormControl>
                          );
                        }}
                      />
                    </Box>
                  </Box>
                )}
                {(PRIVATE_SERVICE_MODELS?.slice(2)?.includes(
                  addon?.product_type
                ) ||
                  ["power", "u"].includes(addon?.product_type)) && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      gap: 1.5,
                      width: {
                        xs: "100%",
                        md: "calc((100% - 12px * 2) / 3 * 2 + 12px)",
                      },
                      m: "auto 0 auto auto",
                    }}
                  >
                    <Controller
                      control={control}
                      name={`product_addons.[${index}].warehouse_capacity_value`}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <FormControl fullWidth>
                            <TextField
                              size="small"
                              label="Thông số kỹ thuật/ Dung lượng"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                inputComponent: NumericFormatCustom,
                              }}
                              value={value}
                              onChange={onChange}
                              placeholder="0..."
                            />
                          </FormControl>
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name={`product_addons.[${index}].warehouse_capacity_unit`}
                      render={({ field: { value, onChange } }) => {
                        const selected =
                          PRODUCT_UNITS.find((x) => x.value === value) || null;
                        return (
                          <FormControl fullWidth>
                            <SingleSelectCustomize
                              value={selected}
                              options={PRODUCT_UNITS}
                              onChange={(event, newValue) =>
                                onChange(newValue?.value || "")
                              }
                              input={{
                                label: "Đơn vị tính",
                                placeholder: "Chọn đơn vị tính...",
                              }}
                            />
                          </FormControl>
                        );
                      }}
                    />
                  </Box>
                )}
                {addon?.product_type?.match(/(server|processor)/) && (
                  <Box
                    sx={{
                      width: {
                        xs: "100%",
                        md: "calc((100% - 12px * 2) / 3 * 2 + 12px)",
                      },
                      m: "4px 0 auto auto",
                    }}
                  >
                    <ServerConfigBuilder
                      position={addons?.findIndex(
                        (addon) => addon?.product_type === "cpu"
                      )}
                    />
                  </Box>
                )}
                {!PRIVATE_SERVICE_MODELS.includes(addon?.product_type) && (
                  <Controller
                    control={control}
                    name={`product_addons.[${index}].description`}
                    render={({ field }) => {
                      return (
                        <FormControl fullWidth>
                          <TextField
                            size="small"
                            label="Thông Tin/ Mô Tả"
                            InputLabelProps={{ shrink: true }}
                            {...field}
                            placeholder="Nhập thông tin mô tả..."
                          />
                        </FormControl>
                      );
                    }}
                  />
                )}
              </Box>
            );
          })}
        </Box>
      </Collapse>
      <Amount />
    </Fragment>
  );
};

export default DeviceLiquidationAddons;
