import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { format_date_short } from "@/functions";
import ModalConfirm from "@components/shared/ModalConfirm";
import React from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";

const CreateInvoiceRenew = ({ onClose, service }) => {
  let navigate = useNavigate();
  const { mutateAsync } = useMutation(ServiceApi.CreateInvoiceRenew);

  const onSubmit = async () => {
    const response = await mutateAsync(service?.service_id);
    if (!response || response?.errors) {
      return {
        status: 400,
        message: response?.errors?.[0]?.message,
      };
    }
    return {
      status: 200,
      message: `Tạo hoá đơn gia hạn dịch vụ thành công`,
      action: navigate(
        `/invoices/${response?.service?.invoice?.invoice_id}/` ||
        `/services/${service?.service_id}/invoices`,
        {
          replace: true,
        }
      ),
    };
  };

  return (
    <ModalConfirm
      handleSubmit={onSubmit}
      handleClose={onClose}
      dialog={{
        title: `Tạo Hoá Đơn Gia Hạn Cho Dịch Vụ ${service?.service_name}`,
        content: `Hệ thống sẽ tạo một hoá đơn gia hạn cho dịch vụ ${service?.service_name
          } với chu kỳ từ ${format_date_short(
            service?.service_nextbill_startdate
          )} đến ${format_date_short(service?.service_nextbill_enddate)}.`,
      }}
    />
  );
};

export default CreateInvoiceRenew;
