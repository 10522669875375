import withPermission from '@/hocs/withPermission'
import { DeleteForeverOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import ModalDeleteCustomer from './ModalDelete'

const SolfDelete = ({
    disabled = true,
    setDisabled,
    customerId = null,
    customerName = "",
    callback,
    ...customer
}) => {
    const [submit, setSubmit] = React.useState(false)
    const [openModal, setOpenModal] = React.useState(false)

    const handlerClick = () => {
        if (openModal) {
            setDisabled(false)
            return setOpenModal(false);
        }
        setDisabled(true)
        return setOpenModal(true)
    }

    return (
        <React.Fragment>
            {openModal &&
                <ModalDeleteCustomer
                    onClose={handlerClick}
                customer={customer}
                customerId={customerId}
                callback={callback}
                submit={submit}
                setSubmit = { setSubmit}
                />
            }
            <Button size='small'
                variant='outlined'
                color="error"
                fullWidth
                disabled={disabled
                    || submit
                    || ["active", "trial"]?.includes(customer?.customer_service_information?.customer_status)}
                startIcon={<DeleteForeverOutlined fontSize='small'  />}
                sx={{ height: { xs: 40, md: 42 } }}
                onClick={handlerClick}
            >
                Xoá khách hàng
            </Button>
        </React.Fragment>
    )
}

export default withPermission(SolfDelete, {
    feature: "customer",
    action: "cancel",
    type: "Button"
})