import { Box, Typography } from '@mui/material'
import React from 'react'
import withPermission from '@/hocs/withPermission'

const List = () => {
    return (
        <Box>
            <Typography sx={{ mb: 2, fontSize: 14, color: 'grey.700' }}>
                Employees.MailAssignees.List
            </Typography>
        </Box>
    )
}

export default withPermission(List, { feature: 'mail', action: 'index' })