import { Box, FormControl, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const ScheduleContent = () => {
  const { control } = useFormContext();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: 1,
        mt: 2,
      }}
    >
      <Box sx={{ width: 300, minWidth: 300, display: "flex" }}>
        <Typography sx={{ fontSize: 14, color: "#777680" }}>
          Nội dung cuộc họp *
        </Typography>
      </Box>
      <Controller
        control={control}
        name="schedule_content"
        render={({ field: { value, onChange } }) => {
          return (
            <FormControl fullWidth>
              <TextField
                multiline
                placeholder="Nhập nội dung chính về hoạt động & meeting..."
                value={value}
                onChange={onChange}
              />
            </FormControl>
          );
        }}
      />
    </Box>
  );
};

export default ScheduleContent;
