import { format_numeral_price } from '@/functions'
import { Typography, Box } from '@mui/material'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const Ram = ({ position }) => {

    const { control } = useFormContext()

    const ramAddons = useWatch({
        control: control,
        name: `product_configoptions.[${position}]`
    })

    if (!ramAddons || (ramAddons?.product_addons?.filter((item) => parseInt(item?.product_addon_qty) > 0)?.length <= 0)) return;
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            mb: 1
        }}>
            {ramAddons?.product_addons?.filter((item) => parseInt(item?.product_addon_qty) > 0)?.map((addon, index) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }} key={index}>
                        <Typography component="span" sx={{ color: "#777680" }}>
                            {parseInt(addon?.product_addon_qty) !== 1 && <Typography sx={{
                                color: "#00001f",
                                display: 'inline-flex',
                                mr: 0.5
                            }}>
                                x{format_numeral_price(addon?.product_addon_qty)}
                            </Typography>}
                            {addon?.product_addon_name}
                        </Typography>
                        <Typography component="span">
                            <Typography sx={{
                                display: 'inline-flex',
                                textDecoration: 'underline',
                                color: "#777680"
                            }}>đ</Typography>
                            {format_numeral_price(parseFloat(addon?.product_addon_pricing) * parseFloat(addon?.product_addon_qty))}
                        </Typography>
                    </Box>
                )
            })}
        </Box>
    )
}

export default Ram