import { format_date_short, format_numeral_price } from "@/functions"
import { SERVICE_STATUS_TYPES } from "@/libs/constants"
import { CellPricing, CellStatus } from "@components/DataGrid/Cell"
import _ from "lodash"
import Contract from "../shared/Service/ServiceContract"
import ServiceActtions from "../shared/Service/ServiceActions"
import VPSServiceButtons from "./private/Buttons"
import { Box, Typography } from "@mui/material"

const VSPButtons = [
    'CreateNote',
    'RegisterService',
    'CancelService',
    'CreateInvoiceRenew',
]

const VPSActions = [
    'service-start-date',
    'service-edit',
    'service-date-renew',
    'service-active',
    'service-next-billing',
    'service-current-billing',
    'service-cancel',
    'service-price-amount',
    'service-price-recurring',
    'service-commissions',
    'service-create-invoice-renew',
    'service-metadata'
]

const buildServiceAddonVPS = ({ addons = [] }) => {
    return addons?.map((service) => {
        return ({
            ...(_.pick(service, [
                'service_id',
                'service_amount',
                'service_status',
                'service_recurring',
                'service_start_date',
                'service_stopped_at',
                'service_referrer_id',
                'service_referrer_type',
                'service_create_is_trial',
                'service_employee_commissions',
                'service_metadata',
                'service_billing_enddate',
                'service_billing_startdate',
                'service_nextbill_enddate',
                'service_nextbill_startdate'
            ])),
            service_amount: service?.service_addon_qty * service?.service_amount,
            service_recurring: service?.service_addon_qty * service?.service_recurring,
            service_name: service?.service_product_name?.toUpperCase(),
        })
    })
}
const buildServiceVPS = ({ service = {} }) => {
    return {
        ...(_.pick(service, [
            'service_id',
            'service_amount',
            'service_status',
            'service_recurring',
            'service_start_date',
            'service_stopped_at',
            'service_referrer_id',
            'service_referrer_type',
            'service_create_is_trial',
            'service_employee_commissions',
            'service_metadata',
            'service_billing_enddate',
            'service_billing_startdate',
            'service_nextbill_enddate',
            'service_nextbill_startdate'
        ])),
        service_name: service?.service_product_name?.toUpperCase(),
    }
}

const buildServiceVPSLastRow = ({ service = {}, addons = [] }) => {
    let metadataVPS = { ...service?.service_metadata }
    let service_recurring = service?.service_recurring

    addons?.forEach((addon) => {
        metadataVPS.cpu += addon?.service_metadata?.cpu
        metadataVPS.storage += addon?.service_metadata?.storage
        metadataVPS.ram += addon?.service_metadata?.ram
        metadataVPS.ip += addon?.service_metadata?.ip

        service_recurring += (addon?.service_addon_qty * addon?.service_recurring)
    })

    return {
        ...(_.pick(service, [
            'service_id',
            'service_amount',
            'service_status',
            'service_recurring',
            'service_start_date',
            'service_stopped_at',
            'service_referrer_id',
            'service_referrer_type',
            'service_create_is_trial',
            'service_employee_commissions',
            'service_metadata',
            'service_billing_enddate',
            'service_billing_startdate',
            'service_nextbill_enddate',
            'service_nextbill_startdate'
        ])),
        service_name: `Cấu hình hiện tại`,
        classNames: "row-service-finally",
        service_recurring: service_recurring,
        service_metadata: { ...metadataVPS }
    }
}

const serviceVPSRows = ({ service = {}, addons = [] }) => {
    return [
        buildServiceVPS({ service }),
        ...buildServiceAddonVPS({ addons }),
        buildServiceVPSLastRow({ service, addons })
    ]
}

const INITIAL_COLUMNS = [
    {
        field: "id",
        headerName: ".",
        width: 60,
    },
    {
        field: "service_name",
        headerName: "Sản Phẩm",
        width: 300,
    },
    {
        field: "service_metadata.cpu",
        headerName: "CPU",
        width: 100,
        valueGetter: ({ row }) => row?.service_metadata?.cpu,
        renderCell: ({ value }) => !!value && format_numeral_price(value)
    },
    {
        field: "service_metadata.ram",
        headerName: "RAM",
        width: 100,
        valueGetter: ({ row }) => row?.service_metadata?.ram,
        renderCell: ({ value }) => !!value && format_numeral_price(value)
    },
    {
        field: "service_metadata.storage",
        headerName: "DISK",
        width: 100,
        valueGetter: ({ row }) => row?.service_metadata?.storage,
        renderCell: ({ value }) => !!value && format_numeral_price(value)
    }, {
        field: "service_metadata.ip",
        headerName: "IP",
        width: 100,
        valueGetter: ({ row }) => row?.service_metadata?.ip,
        renderCell: ({ value }) => !!value && format_numeral_price(value)
    }, {
        field: "sale_employee_ids",
        headerName: "Người Hưởng Hoa Hồng",
        width: 220,
        valueGetter: ({ row }) =>
            row?.service_employee_commissions
                ?.map((e) => e?.employee_name)
                ?.join(", "),
    },
    {
        field: "service_amount",
        width: 200,
        headerName: "Đơn Giá Bán (Theo tháng *) ",
        renderCell: ({ value }) => {
            if (value < 0) {
                return <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', pr: 0.5 }}>
                    <Typography sx={{ fontSize: 14 }}>
                        <Typography component="span" sx={{ fontSize: 14, color: "#777680", textDecoration: 'underline' }}>đ</Typography>
                        ({format_numeral_price(Math.abs(value))})
                    </Typography>
                </Box>
            }
            return <CellPricing price={value} />
        }
    },
    {
        field: "service_recurring",
        width: 200,
        headerName: "Đơn Giá Gia Hạn (Theo tháng *) ",
        renderCell: ({ value }) => {
            if (value < 0) {
                return <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', pr: 0.5 }}>
                    <Typography sx={{ fontSize: 14 }}>
                        <Typography component="span" sx={{ fontSize: 14, color: "#777680", textDecoration: 'underline' }}>đ</Typography>
                        ({format_numeral_price(Math.abs(value))})
                    </Typography>
                </Box>
            }
            return <CellPricing price={value} />
        }
    },
    {
        field: "services.service_status",
        headerName: "Trạng Thái",
        width: 140,
        valueGetter: ({ row }) =>
            SERVICE_STATUS_TYPES?.find((stt) => stt?.value === row?.service_status),
        renderCell: ({ value }) => {
            return <CellStatus component="muiChip" data={value} />;
        },
    },
    {
        field: "service_start_date",
        headerName: "Ngày Bắt Đầu Sử Dụng DV",
        width: 200,
        valueFormatter: ({ value }) => !!value ? format_date_short(value) : '',
    },
    {
        field: "service_stopped_at",
        headerName: "Ngày Kết Thúc DV",
        width: 200,
        valueFormatter: ({ value }) => !!value ? format_date_short(value) : '',
    },
    {
        field: "service_billing_startdate",
        headerName: "Chu Kỳ Thanh Toán Hiện Tại",
        width: 200,
        valueGetter: ({ row }) => {
            if (row?.service_create_is_trial) return `TRIAL`;
            if (!row?.service_billing_startdate || !row?.service_billing_enddate)
                return ``;
            return `(${format_date_short(
                row?.service_billing_startdate
            )} - ${format_date_short(row?.service_billing_enddate)})`;
        },
    }, {
        field: "service_nextbill_startdate",
        width: 220,
        headerName: "Chu Kỳ Gia Hạn",
        valueGetter: ({ row }) => {
            if (row?.service_create_is_trial) return `TRIAL`;
            if (!row?.service_nextbill_startdate || !row?.service_nextbill_enddate)
                return ``;
            return `(${format_date_short(
                row?.service_nextbill_startdate
            )} - ${format_date_short(row?.service_nextbill_enddate)})`;
        },
    }, {
        field: "service_contract_name",
        headerName: "Hợp Đồng Hiện Tại",
        minWidth: 350,
        valueGetter: ({ row }) => {
            return {
                ui: row?.service_referrer_type === 'Reducer::Override::Service' ? "SubContract" : 'Contract',
                service_id: row?.service_id,
                service_referrer_id: row?.service_referrer_id
            }
        },
        renderCell: ({ value }) => {
            const ContractUI = Contract[value?.ui]
            if (!ContractUI) return null
            return <ContractUI {...value} />
        }
    }, {
        field: "actions",
        headerName: "",
        width: 40,
        renderCell: ({ row }) => <ServiceActtions service={row} actions={[...VPSActions]} />
    },
]

const ServiceVPSLibs = {
    columns: INITIAL_COLUMNS,
    rows: serviceVPSRows,
    activities: VSPButtons,
    actions: VPSActions,
    module: {
        Component: VPSServiceButtons,
        actions: ['ServiceCreateAddon']
    }
}
export default ServiceVPSLibs