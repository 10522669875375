import NumericFormatCustom from "@components/NumericFormatCustom";
import { FormControl, TextField } from "@mui/material";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

const Amount = () => {
  const { watch, control, setValue } = useFormContext();

  useEffect(() => {
    const amount =
      parseFloat(watch(`product_addon_pricing_amount`)) +
      parseFloat(watch(`product_pricing_amount`));

    return setValue("product_pricing_amount", amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(`product_addon_pricing_amount`)]);

  return (
    <Controller
      name="product_pricing_amount"
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <TextField
              size="small"
              value={value}
              onChange={onChange}
              label="Tạm tính (Gói - Không bao gồm giá Addon nếu đã có cấu hình đơn giá addon) *"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
          </FormControl>
        );
      }}
    />
  );
};

export default Amount;
