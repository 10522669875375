import CompanyTax from "./CompanyTax";
import Contact from "./Contact";
import Customer from "./Customer";
import Employee from "./Employee";
import SaleTracking from "./SaleTracking";
import Service from "./Service";
import ServiceInteresting from "./ServiceInteresting";

export const BaseComponents = {
  SaleTracking: ({ value = [], name = "", onChange, ...props }) => {
    return (
      <SaleTracking value={value} name={name} onChange={onChange} {...props} />
    );
  },
  Employee: ({ value = [], name = "", onChange, queries = [], ...props }) => (
    <Employee value={value} name={name} onChange={onChange} queries={queries} {...props} />
  ),
  CompanyTax: ({ value = "", name = "", onChange, call }) => {
    return (
      <CompanyTax value={value} name={name} onChange={onChange} call={call} />
    );
  },
  Contact: ({ value = "", name = "", onChange, call }) => {
    return (
      <Contact value={value} name={name} onChange={onChange} call={call} />
    );
  },
  ServiceInteresting: ({ value, onChange, ...props }) => {
    return (
      <ServiceInteresting
        value={value}
        onChange={onChange}
        {...props}
        input={{
          label: props?.label,
          placeholder: `Chọn...`,
        }}
      />
    );
  },
  Customer: ({ value, onChange, name, disabled = false }) => {
    return <Customer value={value} onChange={onChange} name={name} disabled={disabled} />;
  },
  Service: ({ value, onChange, name, customerId }) => {
    return (
      <Service
        value={value}
        onChange={onChange}
        name={name}
        customerId={customerId}
      />
    );
  },
};
