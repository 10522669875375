import { validationCustomizeDatetime } from '@/libs/validators';
import { create, enforce, test } from "vest";

const workContractValidators = create((data = {}) => {

    test('work_contract_number', 'Mã số hợp đồng không hợp lệ.', () => {
        enforce(data?.work_contract_number).isNotEmpty()
    })
    test('employee_id', 'Nhân viên không được để trống.', () => {
        enforce(parseInt(data?.employee_id) > 0).isTruthy()
    })
    test('work_contract_term', 'Thời hạn hợp đồng không được để trống.', () => {
        enforce(data?.work_contract_term).isNotEmpty()
    })
    test('work_contract_startdate', 'Ngày bắt đầu hợp đồng không hợp lệ.', () => {
        const gte = validationCustomizeDatetime({
            timeCompare: data?.work_contract_startdate,
            timeDiff: "2013-01-01",
            condition: "gte"
        })
        enforce(gte).isTruthy()
    })
    test('work_contract_enddate', 'Ngày kết thúc hợp đồng không hợp lệ.', () => {
        const gte = validationCustomizeDatetime({
            timeCompare: data?.work_contract_enddate,
            timeDiff: data?.work_contract_startdate,
            condition: "gte"
        })
        enforce(gte).isTruthy()
    })
    test('work_contract_status', 'Trạng thái hợp đồng không hợp lệ.', () => {
        const isValid = parseInt(data?.work_contract_status) >= 0 && parseInt(data?.work_contract_status) <= 5
        enforce(isValid).isTruthy()
    })
})
export default workContractValidators;
