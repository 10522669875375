import NumericFormatCustom from '@components/NumericFormatCustom'
import { FormControl, TextField } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ServicePrice = () => {
    const { control } = useFormContext()
    return (
        <Controller
            control={control}
            name="service_price"
            render={({ field: { value, onChange } }) =>
                <FormControl fullWidth>
                    <TextField
                        size='small'
                        label="Đơn giá bán * (?/ 1 Đơn vị tính)"
                        InputLabelProps={{ shrink: true }}
                        value={value}
                        onChange={onChange}
                        InputProps={{
                            inputComponent: NumericFormatCustom
                        }}
                        placeholder='Nhập đơn giá bán...'
                    />
                </FormControl>
            }
        />
    )
}

export default ServicePrice