import NumericFormatCustom from "@components/NumericFormatCustom";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import { FormControl, TextField } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const PricingApply = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name="product_pricing_amount"
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <FormControl fullWidth sx={{ mb: 1.5 }}>
            <TextField
              size="small"
              label="Giá bán *"
              value={value}
              placeholder="0"
              onChange={onChange}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
            {!!errors?.product_pricing_amount && (
              <ErrorHelperText
                message={errors?.product_pricing_amount?.message}
              />
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default PricingApply;
