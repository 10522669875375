import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";
class CustomerSaleplanApi {

    List = ({ customerId = -1 }) => {
        return useQueryWithCustomized(
            ["customers.saleplans", customerId],
            async () => {
                return await requestWithToken(`${API_V3_URL}/customers/${customerId}/customer_saleplans`)
                    .then(({ data }) => {
                        return data?.data?.saleplans;
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000
            }
        )
    }


    Create = async ({ customerId = null, data = {} }) => {
        return await requestWithToken(`${API_V3_URL}/customers/${customerId}/customer_saleplans`, "POST", {
            saleplan: data
        }, {}, {})
            .then(({ data }) => {
                if (data.error) return data;
                return data?.data
            })
            .catch((error) => {
                return error.response;
            });
    }

    Update = async ({ customerId = null, saleplanId = null, data = {} }) => {
        return await requestWithToken(`${API_V3_URL}/customers/${customerId}/customer_saleplans/${saleplanId}`, "PUT", {
            saleplan: data
        }, {}, {})
            .then(({ data }) => {
                if (data.error) return data;
                return data?.data?.saleplan;
            })
            .catch((error) => {
                return error.response;
            });
    }

    Detail = ({ customerId = null, saleplanId = null }) => {
        return useQueryWithCustomized(
            ["customers.saleplans.detail", customerId, saleplanId],
            async () => {
                return await requestWithToken(`${API_V3_URL}/customers/${customerId}/customer_saleplans/${saleplanId}`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data?.saleplan;
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000
            }
        );
    }

    Approve = async ({ customerId, saleplanId }) => {
        return await requestWithToken(`${API_V3_URL}/customers/${customerId}/customer_saleplans/${saleplanId}/approved`, "PUT", {
        }, {}, {})
            .then(({ data }) => {
                if (data.error) return data;
                return data?.data;
            })
            .catch((error) => {
                return error.response;
            });
    }

    Destroy = async ({ customerId, saleplanId }) => {
        return await requestWithToken(`${API_V3_URL}/customers/${customerId}/customer_saleplans/${saleplanId}`, "DELETE", {}, {}, {})
            .then(({ data }) => {
                if (data.error) return data;
                return data?.status;
            })
            .catch((error) => {
                return error.response;
            });
    }

    FindLast = async ({ customerId = -1 }) => {
        return await requestWithToken(`${API_V3_URL}/customers/${customerId}/customer_saleplans/latest`, "GET", {}, {}, {})
            .then(({ data }) => {
                if (data.error) return data;
                return data?.data?.saleplan;
            })
            .catch((error) => {
                return error.response;
            });
    }

    Processing = ({ customerId = null }) => {
        return useQueryWithCustomized(
            ["customers.saleplans.processing", customerId],
            async () => {
                return await requestWithToken(`${API_V3_URL}/customers/${customerId}/customer_saleplans/latest`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data?.saleplan;
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000
            }
        );
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CustomerSaleplanApi();
