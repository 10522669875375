import DayoffApi from '@/apis/beta/Employee/DayoffApi'
import { buildQueryFilterCondition, format_date_short } from "@/functions"
import withPermission from '@/hocs/withPermission'
import withSuspense from "@/hocs/withSuspense"
import useDebounce from "@/hooks/useDebounce"
import { DAYOFF_STATUS_CONFIRM_TYPES, DAYOFF_STATUS_TYPES, DAYOFF_TYPE_TYPES } from '@/libs/constants'
import DataGridCustomize from '@components/DataGrid'
import MuiSkeletonDefault from '@components/Skeletons/Default'
import { Chip, Typography } from '@mui/material'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import DayoffRangeTime from "./DayoffRangeTime"

const Dayoff = () => {
    const { employee_id } = useParams()

    const [searchQuery, setSearchQuery] = React.useState('')
    const debouncedSearchQuery = useDebounce(searchQuery, 500)

    const [page, setPage] = React.useState({
        page: 0,
        pageSize: 5
    });

    const filterableDayoffAccount = React.useCallback(() => {
        if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === '') return '';
        const fieldQueries = [
            {
                name: 'day_offs.day_off_id',
                operator: "$eq",
                value: parseInt(debouncedSearchQuery),
                disabled: !parseInt(debouncedSearchQuery) || debouncedSearchQuery.match(/[/,-]/)
            },
            {
                name: 'day_off_items.day_off_item_date',
                operator: "$eq",
                disabled: !debouncedSearchQuery.match(/[/,-]/),
                value: debouncedSearchQuery.toString()
            },
        ]
        return buildQueryFilterCondition("$or", 0, fieldQueries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery])

    const [columns] = React.useState([
        {
            field: 'day_offs.day_off_id', headerName: 'Mã Phiếu',
            width: 120,
            hideable: true,
            operators: { type: 'number' },
            valueGetter: ({ row }) => row?.day_off_id,
            renderCell: ({ value }) => <Link to={`/dayoffs/${value}`} replace>
                <Typography color="primary" sx={{
                    fontSize: 14,
                    textDecoration: 'underline'
                }}>
                    {value}
                </Typography>
            </Link>
        }, {
            field: 'day_offs.day_off_type', headerName: 'Loại',
            width: 150,
            valueGetter: ({ row }) => DAYOFF_TYPE_TYPES.find(
                (status) => status?.value === row?.day_off_type
            ),
            renderCell: ({ value }) =>
                <Typography color={value?.value === 0 ? "primary" : "#66BB6A"} sx={{ fontSize: "0.875rem" }}>{value?.label}</Typography>
        }, {
            field: 'number_of_day_off', headerName: 'Số Ngày Nghỉ', width: 120,
        }, {
            field: 'day_off_items.day_off_item_date', headerName: 'Ngày Nghỉ',
            valueGetter: ({ row }) => row?.day_off_range_time,
            width: 200,
            renderCell: ({ value }) => <DayoffRangeTime data={value || []} />,
        }, {
            field: 'day_offs.day_off_leader_confirm', headerName: 'Trưởng Phòng',
            width: 150,
            valueGetter: ({ row }) => {
                const stt = DAYOFF_STATUS_CONFIRM_TYPES.find(
                    (stt) => stt.value === row?.day_off_leader_confirm
                )
                return (row?.day_off_status === 3 && !stt?.value) ? { value: 3, label: "Từ chối", color: "error" } : stt
            },
            renderCell: ({ value }) =>
                <Typography sx={{ fontSize: 14 }} color={`custome.${value?.color}`}>{value?.label}</Typography>
        }, {
            field: 'day_offs.day_off_bod_confirm', headerName: 'BOD/ BOM',
            width: 150,
            valueGetter: ({ row }) => {
                const stt = DAYOFF_STATUS_CONFIRM_TYPES.find(
                    (stt) => stt.value === row.day_off_bod_confirm
                )
                return (row?.day_off_status === 3 && !stt?.value) ? { value: 3, label: "Từ chối", color: "#ef5350" } : stt
            },
            renderCell: ({ value }) =>
                <Typography sx={{ fontSize: 14 }} color={value?.color}>{value?.label}</Typography>
        }, {
            field: 'day_offs.day_off_status', headerName: 'Trạng Thái',
            width: 150,
            valueGetter: ({ row }) => {
                return DAYOFF_STATUS_TYPES.find(t => t?.value === row?.day_off_status)
            },
            renderCell: ({ value }) =>
                <Chip size='small'
                    label={value?.label}
                    variant={`customize-${value?.chip}`}
                />
        }, {
            field: 'day_off_created_at', headerName: 'Ngày Tạo Phiếu',
            operators: { type: 'date' },
            width: 150,
            renderCell: ({ value }) => format_date_short(value),
        }]
    )

    const { data, isFetching } = DayoffApi.List({
        query: `&filters[$and][0][day_offs.employee_id][$eq]=${employee_id}`,
        searchQuery: filterableDayoffAccount() !== "" ? filterableDayoffAccount() : "",
        page: page?.page + 1,
        pageSize: page?.pageSize
    })


    return (
        <DataGridCustomize
            rows={data?.day_offs?.map((day_off) => ({ ...day_off, id: day_off?.day_off_id })) || []}
            columns={columns}
            loading={isFetching}
            componentsProps={{
                toolbar: {
                    inputSearch: {
                        value: searchQuery,
                        onChange: setSearchQuery,
                        placeholder: "Nhập mã phiếu nghỉ tìm kiếm..."
                    }
                },
                pagination: {
                    rowsPerPageOptions: [5, 10, 20, 50],
                    page: page?.page || 0,
                    pageSize: page?.pageSize || 5,
                    onPageChange: (newPage) => setPage(prevState => ({ ...prevState, page: newPage })),
                    onPageSizeChange: (newPageSize) => setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
                }
            }}
            rowCount={data?.pagination?.total}
        />
    )
}

export default withSuspense(withPermission(Dayoff, { feature: "dayoff", action: "index", type: "component" }), MuiSkeletonDefault)