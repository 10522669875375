import ServiceApi from "@/apis/beta/Service/ServiceApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useAutoScrollToElement from "@/hooks/useAutoScrollToElement";
import PopperAction from "@components/PopperAction";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import NotFound from "@features/App/NotFound";
import { Box, Collapse, Popover } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import ButtonCreate from "./ButtonCreate";
import Modules from "./Modules";
import Renew from "./Renew";

const INITIAL_ACTIONS = [
  "create-service-addon",
  "update-service-addon",
  "renew-service-addon",
];

const ServiceAddon = () => {
  const { service_id } = useParams();
  const { data: services, refetch: callback } = ServiceApi.Addons({
    serviceId: service_id,
  });

  const [action, setAction] = React.useState({
    open: false,
    data: undefined,
    name: undefined,
  });

  const formRef = React.useRef(null);

  const [popper, setPopper] = React.useState({
    anchorEl: undefined,
    service_addon: undefined,
  });

  const handleClick = (event, data) =>
    setPopper((prev) => ({
      ...prev,
      anchorEl: event?.target,
      service_addon: data,
    }));

  const handleClearMetadata = () =>
    setAction({ open: false, name: undefined, data: undefined });

  const handleClosePopper = () =>
    setPopper({ anchorEl: null, service_addon: null });
  const renderServiceAddonForm = () => {
    const metadata = {
      data: action?.data,
      handleClose: handleClearMetadata,
      callbackService: callback,
    };
    switch (action?.name) {
      case "create-service-addon":
        return <Modules.Create {...metadata} />;
      case "update-service-addon":
        return <Modules.Update {...metadata} />;
      case "renew-service-addon":
        return <Renew {...metadata} />;
      default:
        return <NotFound />;
    }
  };

  const [actions] = React.useState([
    {
      order: 1,
      name: "update",
      icon: "Edit",
      label: "Chỉnh Sửa",
      handleClick: (data) => {
        setPopper((prev) => ({
          ...prev,
          open: true,
          data: data,
          name: "update-service-addon",
        }));
        handleClosePopper();
      },
    },
    {
      order: 2,
      name: "renew",
      icon: "Renew",
      label: "Gia Hạn",
      handleClick: (data) => {
        setPopper((prev) => ({
          ...prev,
          open: true,
          data: data,
          name: "renew-service-addon",
        }));
        handleClosePopper();
      },
    },
  ]);

  useAutoScrollToElement(formRef?.current, [action]);

  return (
    <Box sx={{ mt: 3 }}>
      {Boolean(popper?.anchorEl) && (
        <Popover open={true} anchorEl={popper?.anchorEl}>
          <PopperAction
            data={{ ...popper?.service_addon }}
            actions={actions}
            handleClose={() =>
              setPopper({ anchorEl: undefined, service_addon: undefined })
            }
          />
        </Popover>
      )}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <ButtonCreate setAction={setAction} disabled={!!popper?.anchorEl} />
      </Box>
      <Collapse in={action?.open && action?.name !== undefined} ref={formRef}>
        {INITIAL_ACTIONS.includes(action?.name) && renderServiceAddonForm()}
      </Collapse>
      <Modules.List addons={services || []} handleClick={handleClick} />
    </Box>
  );
};

export default withSuspense(
  withPermission(ServiceAddon, {
    feature: "service",
    action: "service-addon-index",
  }),
  MuiSkeleton["DataGrid"]
);
