import { Box, Typography } from '@mui/material'
import { indigo } from '@mui/material/colors'
import React from 'react'
import { Link } from 'react-router-dom'

const CellBreakdownLink = ({
    data = []
}) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: "column", gap: 0.5 }}>
            {data?.map((item) => {
                return (
                    <Link to={item?.href?.join("/")} replace>
                        <Typography sx={{
                            fontSize: 14,
                            m: 'auto 0',
                            ":hover": {
                                color: indigo[900],
                                textDecoration: "underline"
                            }
                        }}>
                            {item?.label}
                        </Typography>
                    </Link>
                )
            })}
        </Box>
    )
}

export default CellBreakdownLink