import { dayjsCompare } from "@/functions";
import { create, enforce, test } from "vest";

const productPricingValidator = create((data = {}) => {
  console.log(`debug:validator-form-product-pricing`, data);

  test("pricing", "Đơn giá niêm yết không được để trống.", () => {
    enforce(data?.pricing).isNotEmpty();
  });

  test("pricing_minimum", "Đơn giá min không được để trống.", () => {
    enforce(data?.pricing).isNotEmpty();
  });
  test("pricing", "Đơn giá niêm yết không hợp lệ.", () => {
    enforce(parseFloat(data?.pricing) !== 0).isTruthy();
  });

  test(
    "pricing_apply_startdate",
    "Ngày bắt đầu áp dụng không được để trống.",
    () => {
      enforce(data?.pricing_apply_startdate).isNotEmpty();
    }
  );
  test(
    "pricing_apply_duedate",
    "Ngày kết thúc áp dụng không được để trống.",
    () => {
      enforce(data?.pricing_apply_duedate).isNotEmpty();
    }
  );

  test(
    "pricing_apply_duedate",
    "Ngày kết thúc áp dụng không được nhỏ hơn ngày bắt đầu.",
    () => {
      const days = dayjsCompare(
        data?.pricing_apply_duedate,
        data?.pricing_apply_startdate,
        "d"
      );
      enforce(days >= 0).isTruthy();
    }
  );
});

export default productPricingValidator;
