import { format_numeral_price } from '@/functions'
import { useStateArrayContext } from '@contexts/stateArray'
import { Box, Typography } from '@mui/material'
import _ from 'lodash'
import React from 'react'

const Footer = () => {
    const {
        state: products,
    } = useStateArrayContext()

    return (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', p: '16px 24px' }}>
            <Typography sx={{ fontWeight: 'medium' }}>
                Tạm Tính: {format_numeral_price(_.sumBy(products, (item) => item?.product_value * item?.product_price))}
            </Typography>
        </Box>
    )
}

export default Footer