import ContractApi from "@/apis/beta/Contract/ContractApi";
import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import DataGrid from "@/components/DataGrid";
import DataGridFilter from "@/components/DataGrid/Filter";
import PopperAction from "@/components/PopperAction";
import { useNotificationContext } from "@/contexts/notification";
import { buildQueryFilterCondition } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import useFilters, { Filter } from "@/hooks/useFilters";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Drawer, Popper } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import ContractFormEdit from "../../shared/ContractFormEdit";
import { GET_INITIAL_ACTIONS } from "../../shared/initialActions";
import { GET_INITIAL_COLUMNS } from "../../shared/initialColumns";
import Header from "./Header";
import ModalConfirm from "@components/shared/ModalConfirm";

const Statistical = React.lazy(() => import("../../shared/Statistical"));

const List = () => {
  const location = useLocation();
  const [query, updateQuery] = useFilters("contracts.list.filters", location);

  const { data: employees } = EmployeeApi.ListEmployees({
    page: 1,
    pageSize: 999,
  });
  const { setNotification } = useNotificationContext();

  const [searchQuery, setSearchQuery] = React.useState("");
  let debouncedSearchQuery = useDebounce(searchQuery, 300);

  const filterableCustomerContract = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
    const fieldQueries = [
      {
        name: "contracts.contract_number_id",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "contracts.contract_key",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "companies.company_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "customers.customer_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "contracts.customer_contact_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
    ];
    return buildQueryFilterCondition("$or", 0, fieldQueries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const [popper, setPopper] = React.useState({
    anchorEl: undefined,
    data: undefined,
  });
  const [action, setAction] = React.useState({
    open: false,
    name: undefined,
    data: undefined,
  });

  const INITIAL_ACTIONS = GET_INITIAL_ACTIONS({
    feature: "contracts",
    setPopper: setPopper,
    setAction: setAction,
    setNotification: setNotification,
  });

  const INITIAL_COLUMNS = GET_INITIAL_COLUMNS({
    feature: "customer_contracts",
    collections: {
      employees: employees?.map((e) => ({
        value: e?.employee_id,
        label: e?.employee_name,
      })),
    },
    actions: INITIAL_ACTIONS,
    setPopper: setPopper,
  });

  const historyColumsHidden =
    JSON.parse(localStorage.getItem("contracts.list.hiddenColumns")) || [];
  const [columns, setColumns] = React.useState(
    INITIAL_COLUMNS?.map((column) => {
      if (historyColumsHidden?.includes(column?.field)) {
        return { ...column, hide: true };
      }
      return { ...column };
    }) || INITIAL_COLUMNS
  );

  const filterables = columns
    .filter((column) => !!column?.operators && !column?.hide)
    .map((column) => {
      return {
        field: column?.key ? column.key : column.field,
        label: column?.headerName,
        operators: column?.operators,
      };
    });

  const {
    data,
    isFetching,
    refetch: callbackContracts,
  } = ContractApi.List({
    page: page?.page + 1,
    pageSize: page?.pageSize,
    query: Filter.stringify(query),
    searchQuery:
      filterableCustomerContract() === "" ? null : filterableCustomerContract(),
    contractType: "CustomerContract",
  });
  const handleClose = () =>
    setAction({ open: false, data: undefined, name: undefined });

  const renderComponents = () => {
    switch (action?.name) {
      case "edit-contract":
        return (
          <Drawer anchor="right" open={true} sx={{ width: 600 }}>
            <ContractFormEdit
              callbackContracts={callbackContracts}
              contract={action?.data}
              handleClose={handleClose}
            />
          </Drawer>
        );
      case "retension-contract":
        const contract = action.data;
        return (
          <Drawer anchor="right" open={true} sx={{ width: 600 }}>
            <ModalConfirm
              dialog={{
                title: "Gia Hạn Hợp Đồng",
              }}
              handleSubmit={() => onExtensionContract(contract)}
              handleClose={handleClose}
            />
          </Drawer>
        );

      default:
        return null;
    }
  };

  const onExtensionContract = async (contract) => {
    const response = await ContractApi.Extension({
      contractId: contract?.contract_id,
    });

    if (!response || response.errors) {
      return {
        status: response?.status || 404,
        message: response?.errors?.[0]?.message || "Gia hạn hợp đồng thất bại.",
      };
    }
    return {
      status: 200,
      message: "Gia hạn hợp đồng thành công.",
    };
  };

  return (
    <Box>
      {popper?.anchorEl !== undefined && (
        <Popper
          sx={{ zIndex: 999 }}
          anchorEl={popper?.anchorEl} open={Boolean(popper.anchorEl)}>
          <PopperAction
            {...popper}
            handleClose={() =>
              setPopper((prev) => ({
                ...prev,
                anchorEl: undefined,
                data: undefined,
              }))
            }
          />
        </Popper>
      )}
      <Header />
      {action.open && renderComponents()}
      <Statistical
        data={[{ label: "Số Lượng Hợp Đồng", value: data?.report?.length }]}
      />
      <Box sx={{ mt: 2 }}>
        <DataGridFilter
          callback={updateQuery}
          filters={{
            key: "contracts.list.filters",
            filterables: filterables,
            exportFeature: "ContractsExport"
          }}
        />
      </Box>
      <DataGrid
        rows={
          data?.contracts?.map((contract) => ({
            ...contract,
            id: contract?.contract_id,
          })) || []
        }
        columns={columns}
        loading={isFetching}
        componentsProps={{
          toolbar: {
            store: "contracts.list.hiddenColumns",
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: INITIAL_COLUMNS,
            inputSearch: {
              value: searchQuery,
              onChange: setSearchQuery,
              placeholder: "Nhập mã số hoặc từ khoá HĐ tìm kiếm...",
            },
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        rowCount={data?.pagination?.total}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        pinnedColumns={{
          right: ["action"],
        }}
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(List, {
    feature: "contract",
    action: "customer-contract-index",
  }),
  MuiSkeleton["DataGrid"]
);
