import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class CustomerManagementApi {

    List = ({ customerId = -1 }) => {
        return useQueryWithCustomized(
            ["customers.customer-managements", customerId],
            async () => {
                return await requestWithToken(`${API_V3_URL}/customers/${customerId}/customer_managements`)
                    .then(({ data }) => {
                        if (data.error) return data;
                        return data?.data;
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000
            }
        )
    }

    Create = async ({ customerId = -1, managements = [] }) => {
        return await requestWithToken(`${API_V3_URL}/customers/${customerId}/customer_managements`, "POST", {
            customer_management: {
                managements: managements
            }
        }, {}, {})
            .then(({ data }) => {
                if (data?.error) return data;
                return data
            })
            .catch((error) => {
                return error?.response
            })
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CustomerManagementApi();
