import Address from "./Address"
import Country from "./Country"
import District from "./District"
import Province from "./Province"
import Ward from "./Ward"

const Location = {
    Country: Country,
    Province: Province,
    District: District,
    Ward: Ward,
    Address: Address
}
export default Location