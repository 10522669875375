import React from 'react'
import withPermission from '@/hocs/withPermission'
import CompanyForm from "../shared/CompanyForm"

const Create = ({
    data,
    handleClose,
    callback
}) => {
    return (
        <CompanyForm
            type="create"
            data={data}
            handleClose={handleClose}
            callback={callback}
        />
    )
}

export default withPermission(Create, { feature: 'company', action: 'create' })