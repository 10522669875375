import BusinessApi from '@/apis/beta/Employee/BusinessApi';
import NotificationWarning from '@/components/NotificationMessage/Warning';
import ErrorHelperText from "@/components/shared/ErrorHelperText";
import { queryClientRefetcher } from "@/functions";
import { useEmployeeContext } from '@contexts/employee';
import { useNotificationContext } from '@contexts/notification';
import { Box, Button, CircularProgress, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import BadCustomer from './BadCustomer';
import Improvement from './Improvement';
import Saleplan from './Saleplan';

const ContactInformation = ({ business, contact, saleplan, meeting }) => {

    const queryClient = useQueryClient()
    const { employee } = useEmployeeContext();
    const { setNotification } = useNotificationContext();

    const methods = useForm({
        // resolver: vestResolver((props) => businessUpdateValidator({
        //     ...props
        // })),
        defaultValues: {
            meeting: {
                meeting_result: meeting?.meeting_result || "",
                meeting_failed_reason: meeting?.meeting_failed_reason || "",
                meeting_failed_explanation: meeting?.meeting_failed_explanation || "",
                meeting_provider_explaination:
                    meeting?.meeting_provider_explaination || "",
                meeting_budget_explaination: meeting?.meeting_budget_explaination || "",
                meeting_influencer_explaination:
                    meeting?.meeting_influencer_explaination || "",
                meeting_problem_explaination:
                    meeting?.meeting_problem_explaination || "",
                meeting_success_improvement: meeting.meeting_success_improvement || "",
            },
            saleplan: {
                saleplan_expected_date: moment(saleplan?.saleplan_expected_date),
                saleplan_note: saleplan?.saleplan_note,
                products: saleplan?.products,
            },
        },
    });
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = methods;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const collectContactInformation = () => {
        return [
            {
                mobile: 2,
                default: 4,
                label: "Chức Vụ",
                value: contact?.customer_position || "---",
            },
            {
                mobile: 3,
                default: 3,
                label: "SĐT Liên Hệ",
                value: contact?.customer_phone || "---",
            },
            {
                mobile: 4,
                default: 2,
                label: "Địa Chỉ Liên Hệ",
                value: business?.business_place || "---",
            },
            {
                mobile: 5,
                default: 5,
                label: "Công Ty",
                hidden: !contact?.customer_company_name,
                value: contact?.customer_company_name || "---",
            },
        ];
    };
    const [submit, setSubmit] = React.useState(false);

    const onSubmit = async (values) => {
        setSubmit(true);
        let form = {
            meeting: {
                ..._.pick(meeting, [
                    "meeting_id",
                    "meeting_content",
                    "meeting_summary",
                ]),
                meeting_status: "completed",
                ...values?.meeting,
            },
            saleplan: {
                // products: values?.saleplan?.products,
                saleplan_note: values?.saleplan?.saleplan_note,
                saleplan_expected_date: values?.saleplan?.saleplan_expected_date,
            },
        };
        const response = await BusinessApi.Update({
            id: business?.business_id,
            data: form,
        });
        setSubmit(false);
        if (!response || response?.errors) {
            return setNotification({
                open: true,
                message: response?.errors?.[0]?.message,
                severity: "error",
            });
        }

        queryClientRefetcher(queryClient, [
            "business.list",
            "business.show"
        ])
        return setNotification({
            open: true,
            message: response?.message || "Cập nhật phiếu công tác thành công.",
            severity: "success",
        });
    };

    return (
        <FormProvider {...methods}>
            <Paper
                sx={{ p: 3 }}
                elevation={0}
                component="form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Typography
                    sx={{
                        mb: 1,
                        textTransform: "uppercase",
                        fontWeight: "medium",
                    }}
                >
                    Thông Tin Công Tác
                </Typography>
                <Grid container spacing={{ xs: 1, md: 2 }}>
                    <Grid item xs={12} md={4}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: { xs: "row", md: "column" },
                                gap: 0.5,
                            }}
                        >
                            <Typography sx={{ color: "#777680" }}>Người liên hệ:</Typography>
                            <Link
                                to={
                                    meeting?.meeting_guests?.[0]?.guest_referrer_id
                                        ? `/customers/${meeting?.meeting_guests?.[0]?.guest_referrer_id}`
                                        : "#"
                                }
                                replace
                            >
                                <Typography
                                    color="primary"
                                    sx={{ textDecoration: "underline" }}
                                >
                                    {contact?.customer_name || "---"}
                                </Typography>
                            </Link>
                        </Box>
                    </Grid>
                    {_.sortBy(Array.from(collectContactInformation()), [
                        isMobile ? "mobile" : "default",
                    ])?.map((item, index) => {
                        if (item.hidden) return null;
                        return (
                            <Grid item xs={12} md={4} key={index}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: { xs: "row", md: "column" },
                                        gap: 0.5,
                                    }}
                                >
                                    <Typography sx={{ color: "#777680" }}>{item?.label}</Typography>
                                    <Typography>
                                        {item?.value || "---"}
                                    </Typography>
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>
                <NotificationWarning
                    message={
                        "Từ 15/02/2023 đối với phiếu công tác Gặp Khách Hàng cần được bổ sung kết quả công tác sau khi hoàn tất."
                    }
                />
                <Box
                    sx={{
                        border: 1,
                        borderRadius: "4px",
                        borderColor: "#777680",
                        mt: 2,
                        p: 2,
                        bgcolor: "#7776801f",
                        pointerEvents:
                            employee?.employee_id !== business?.employee_id ? "none" : "auto",
                    }}
                >
                    <Typography sx={{ fontWeight: "medium", mb: 1 }}>
                        Kết Quả Công Tác
                    </Typography>
                    <Controller
                        control={control}
                        name="meeting.meeting_result"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl
                                    fullWidth
                                    disabled={meeting?.meeting_status === "completed"}
                                >
                                    <RadioGroup
                                        row
                                        value={value}
                                        sx={{ gap: 1 }}
                                        onChange={(event) => {
                                            onChange(event.target.value);
                                        }}
                                    >
                                        <FormControlLabel
                                            label="Tiềm Năng"
                                            value="followup"
                                            control={<Radio size="small" />}
                                        />
                                        <FormControlLabel
                                            label="Không Tiềm Năng"
                                            value="closed"
                                            control={<Radio size="small" />}
                                        />
                                    </RadioGroup>
                                    {!!errors?.meeting?.meeting_result && (
                                        <ErrorHelperText
                                            message={errors?.meeting?.meeting_result?.message}
                                        />
                                    )}
                                </FormControl>
                            );
                        }}
                    />
                    {(methods.watch("meeting.meeting_result") === "followup") && (
                        <Saleplan
                            saleplanId={saleplan?.saleplan_id}
                            disabled={meeting?.meeting_status === "completed"}
                        />
                    )}
                    {(methods.watch("meeting.meeting_result") === "closed") && (
                        <BadCustomer disabled={meeting?.meeting_status === "completed"} />
                    )}
                    <Improvement disabled={business?.business_leader_confirm} />
                    <Box
                        sx={{
                            mt: 2,
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                            size="small"
                            variant="contained"
                            sx={{ height: { xs: 36, md: 42 }, minWidth: 150 }}
                            type="submit"
                            disabled={
                                employee?.employee_id !== business?.employee_id ||
                                submit ||
                                business?.business_status === 1
                            }
                            startIcon={
                                submit && (
                                    <CircularProgress size={14} sx={{ color: "#777680" }} />
                                )
                            }
                        >
                            cập nhật
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </FormProvider>
    );
};

export default ContactInformation;
