import React from "react";

import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
const DialogRemoveItems = ({
  data = [],
  loading = false,
  removeItems = ()=>{},
  open = false,
  handleClose = () => {}
}) => {
  
  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bạn có chắc là tách{data?.length > 1 ? " các":""} linh kiện này ra khỏi thiết bị?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Huỷ</Button>
          <Button 
          onClick={
            () => {
                removeItems()
            }
          } 
          autoFocus
          disabled = {loading}>
            Đồng ý
          </Button>
        </DialogActions>
      </Dialog>
  );
};
export default DialogRemoveItems;
