import { requestWithToken } from "@/functions";
import { API_SERVICE_URL } from "@/libs/constants";
import { useQuery } from "react-query";
import qs from 'query-string'

async function resolveStorage(url = '') {
    const response = await requestWithToken(url)

    console.log(response, url)
    return response?.data?.data?.storage || []
    // ?.data?.data?.bandwidth
}

export function resolveServiceAnalyticStorageUsages(service_uuid, query) {
    const current = qs.stringify(query);
    // const history = qs.stringify(resolveHistoricTimeRange(query, range))
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery(["customer.services.usages.storage", service_uuid, query], () => Promise.all([
        resolveStorage(`${API_SERVICE_URL}/v2/services/${service_uuid}/analytic/usages/storage?${current}`)
    ]), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        staleTime: 30 * 60 * 1000,
        enabled: typeof service_uuid !== "undefined",
        suspense: true
    })
}
