import EChart, { SCHEMES, TOOLTIP_OPTIONS } from "@/components/EChart";
import { Box, FormControl, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { renderToString } from 'react-dom/server';


import { format_numeral_price } from "@/functions";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import dayjs from "dayjs";
import _ from "lodash";
import { useMemo, useState } from "react";
import { resolveServiceAnalyticTranscodingUsages } from "../services";
import Domain from "./Domain";
import withSuspense from "@/hocs/withSuspense";

const MarkerSolid = renderToString(<Box sx={{ display: 'inline-block', width: 10, height: 10, bgcolor: "#2196f3", my: 'auto', mr: 1 }} />)

const TranscodingDurations = ({ service }) => {

    const today = dayjs()
    const [year, setYear] = useState({
        value: today?.format('MM-YYYY'),
        label: today?.format('MM-YYYY'),
        gte: today?.startOf('month').format('DD/MM/YYYY'),
        lte: today?.endOf('month').format('DD/MM/YYYY'),
    })
    const {
        data: [transcoding],
        isLoading
    } = resolveServiceAnalyticTranscodingUsages(service?.service_creatable_uuid, {
        gte: year?.gte,
        lte: year?.lte
    })

    const domains = useMemo(() => {
        let arrs = []
        _.forEach(transcoding, (values, key) => {
            const item = Object.values(values)?.[0]?.[0]
            arrs.push({
                name: Object.keys(values)?.[0],
                usage: parseFloat(item?.total || 0),
                transmux: parseFloat(item?.transmux || 0),
                transcode: parseFloat(item?.total || 0) - parseFloat(item?.transmux || 0),
            })
        })
        return _.sortBy(arrs, ['usage']).reverse()
    }, [transcoding])

    const YEAR_OPTIONS = useMemo(() => {
        const today = dayjs()
        return Array.from({ length: 12 }, (__, i) => i)?.map((i) => {
            const month = today.subtract(i, 'month')
            return ({
                value: month?.format('MM-YYYY'),
                label: month?.format('MM-YYYY'),
                gte: month.startOf('month').format('DD/MM/YYYY'),
                lte: month.endOf('month').format('DD/MM/YYYY'),
            })
        })
    }, [])
    const options = {
        tooltip: {
            trigger: 'axis',
            ...TOOLTIP_OPTIONS,
            formatter: function (params) {
                const [current] = params
                return `${MarkerSolid}<b>${current?.name}</b> ${current?.value !== 0 ? `${current?.value} hours` : '-'}<br/>`
            }
        },
        grid: {
            top: '5%',
            left: -45,
            right: '0%',
            bottom: '0%',
            containLabel: true,
        },
        xAxis: {
            type: 'value',
            boundaryGap: false,
            axisLabel: {
                align: 'left',
            },
            splitNumber: 2,
        },
        yAxis: {
            type: 'category',
            data: domains?.filter(domain => domain?.usage !== 0)?.map((domain) => domain?.name) || [],
            splitLine: {
                show: true,
                lineStyle: {
                    color: "#e0e0e0",
                    type: [3, 3],
                    dashOffset: 3
                }
            },
            axisLabel: {
                align: 'left',
                margin: 100,
                color: 'black',
            }
        },
        color: [SCHEMES.mono[0], SCHEMES.primary[4], SCHEMES.secondary[0]],
        series: [
            {
                type: 'bar',
                name: 'Total',
                data: domains?.filter(domain => domain?.usage !== 0)?.map((domain) => domain?.usage) || [],
            },
            {
                type: 'bar',
                name: 'Transcode',
                data: domains?.filter(domain => domain?.usage !== 0)?.map((domain) => domain?.transcode) || [],
            },
            {
                type: 'bar',
                name: 'Transmux',
                data: domains?.filter(domain => domain?.usage !== 0)?.map((domain) => domain?.transmux) || [],
            },
        ]
    }

    return (
        <>
            <Box sx={{
                borderBottom: `1px solid ${grey[300]}`,
                pb: 1,
                mt: 2,
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 1.5,
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Typography fontSize={14} fontWeight={700} data-ui={`Transcoding.Dashboard.Duration.[${service.service_creatable_uuid}]`}>
                    Monthly Usage of VMS (Duration, Unit: Hours)
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: { xs: 2, md: 1.5 } }}>
                    <Domain domains={domains} />
                    <FormControl fullWidth sx={{ maxWidth: { xs: '100%', md: 200 }, minWidth: { xs: '100%', md: 200 } }}>
                        <SingleSelectCustomize
                            value={YEAR_OPTIONS?.find(x => x?.value === year?.value)}
                            options={YEAR_OPTIONS || []}
                            input={{
                                label: "Chọn thời gian"
                            }}
                            compareAttributes={['label', 'value']}
                            formatCollection={{
                                value: 'value',
                                label: 'label'
                            }}
                            onChange={(event, newValue) => {
                                setYear(newValue)
                            }}
                        />
                    </FormControl>
                </Box>
            </Box>
            {!isLoading && transcoding ? (
                <Box sx={{ mt: 3 }}>
                    <EChart options={options} />
                    <Box sx={{ textAlign: 'center' }}>
                        <Box sx={{ borderRadius: 1, bgcolor: grey[100], px: 1.5, py: 1, display: 'inline-block' }}>
                            <Typography fontSize={13}>
                                Usage ({year?.label}): {format_numeral_price(domains?.[0]?.usage, true)} Hours
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{
                        my: 2,
                        display: 'flex',
                        flexDirection: {
                            xs: 'column', md: 'row'
                        },
                        gap: { xs: 0.25, md: 3 }
                    }}>
                        <Stack direction="row" justifyContent="space-between">
                            <Stack direction="row">
                                <Box sx={{ display: 'inline-block', width: 10, height: 10, bgcolor: SCHEMES.mono[0], my: 'auto', mr: 1 }} />
                                <Typography fontSize={14} sx={{ my: 'auto' }}>Total</Typography>
                            </Stack>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Stack direction="row">
                                <Box sx={{ display: 'inline-block', width: 10, height: 10, bgcolor: SCHEMES.primary[4], my: 'auto', mr: 1 }} />
                                <Typography fontSize={14} sx={{ my: 'auto' }}>Transcode</Typography>
                            </Stack>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Stack direction="row">
                                <Box sx={{ display: 'inline-block', width: 10, height: 10, bgcolor: SCHEMES.secondary[0], my: 'auto', mr: 1 }} />
                                <Typography fontSize={14} sx={{ my: 'auto' }}>Transmux</Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </Box>
            ) : (
                <MuiSkeleton.GradientCircularProgress />
            )}
        </>
    )
}

export default withSuspense(TranscodingDurations, MuiSkeleton["LoadingDotBox"])