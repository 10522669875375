import withPermission from '@/hocs/withPermission';
import ContactIcon from '@mui/icons-material/ContactEmergencyOutlined';
import { Button } from '@mui/material';
import { useState } from 'react';
import ContactCreate from '../../../Contact/Create';
import { useParams } from 'react-router-dom';

const AddContact = ({ customer_service_information: csi, ...customer }) => {

    console.log(customer)
    const { customer_id: customerId } = useParams()
    const [opened, setOpened] = useState(false)
    return (
        <>
            {opened && <ContactCreate onClose={() => setOpened(false)} customerId={customerId} />}
            <Button variant='outlined'
                disabled={opened || ['qualified-prospects', 'cool-lead', 'cancel'].includes(csi?.customer_status)}
                size='small'
                fullWidth
                startIcon={<ContactIcon fontSize='small' />}
                onClick={() => setOpened(true)}
                sx={{ height: { xs: 40, md: 42 } }}
            >
                Thêm mới liên hệ
            </Button>
        </>
    );
}

export default withPermission(AddContact, { feature: "customer", action: "update", type: "button" });