import React from "react";
import { NavLink } from "react-router-dom";
import { Box, Typography, Link } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const PermissionDenied = () => {
  return (
    <Box sx={{ width: "100%", height: "100%", display: "flex" }}>
      <Box sx={{ m: "auto" }}>
        <Typography variant="h3" sx={{ mb: 3 }}>
          Error code: 403 - Permission Denied
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <KeyboardBackspaceIcon sx={{ mr: 1, color: "primary.main" }} />
          <Link component={NavLink} to={"/"}>
            <Typography sx={{ fontSize: 14 }}>Back to Dashboard</Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default PermissionDenied;
