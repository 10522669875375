import { Box, Typography } from '@mui/material'
import React from 'react'
import withPermission from '@/hocs/withPermission'

const Destroy = () => {
    return (
        <Box>
            <Typography sx={{ fontSize: 14, color: 'grey.700', mb: 2 }}>
                Employee.ManagementEmployees.Destroy
            </Typography>
        </Box>
    )
}

export default withPermission(Destroy, { feature: 'employee', action: 'destroy' })