import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class ServiceMetadataApi {
    Summary = ({ serviceId = -1 }) => {
        return useQueryWithCustomized(
            ["services.summary", serviceId],
            async () => {
                return await requestWithToken(`${API_V3_URL}/services/${serviceId}/service_metadata/summary`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return { metadata: data?.data?.metadata }
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            })
    };

    Update = async ({ serviceId = -1, data = {} }) => {
        return await requestWithToken(`${API_V3_URL}/services/${serviceId}/metadata`,
            "PUT", {
            service_metadata: data
        }, {})
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new ServiceMetadataApi();
