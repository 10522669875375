import { Box } from '@mui/material';
import React from 'react';
import { useQueryClient } from "react-query";
import AddressInformation from './AddressInformation';
import CancelCustomer from "./CancelCustomer";
import ChangeStatus from "./ChangeStatus";
import CreateOrder from './CreateOrder';
import CreateSaleplan from './CreateSaleplan';
import SoftDelete from "./SoftDelete";
import SwitchCustomerType from './SwitchCustomerType';
import UpdateCustomer from "./Update";
import AddContact from './AddContact';

const Modules = {
    'CreateOrder': CreateOrder,
    'CreateSaleplan': CreateSaleplan,
    'CancelCustomer': CancelCustomer,
    'SoftDelete': SoftDelete,
    'UpdateCustomer': UpdateCustomer,
    'ChangeStatus': ChangeStatus,
    "AddressInformation": AddressInformation,
    'Switch': SwitchCustomerType,
    'AddContact': AddContact
}

const GroupButtons = ({
    packages = [],
    customer
}) => {

    const queryClient = useQueryClient()
    const [isdisabled, setDisabled] = React.useState(false)

    const handleCallback = () => {
        const refetchQueries = [
            "customers.detail",
            "customers.list",
            "customers.statistics",
            "customers.all",
            "customer.customer-journeys",
        ]
        refetchQueries?.forEach((key) => {
            try {
                queryClient.refetchQueries([`${key}`])
            } catch (error) {
                // pass times
            }
        })
    }
    const metadata = {
        customerId: customer?.customer_id,
        customerName: customer?.customer_information?.name,
        callback: handleCallback,
        ...customer,
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: { xs: 1.5, md: 1.5 },
            minWidth: { xs: '100%', md: 250 }
        }}>
            {packages?.map((item, index) => {
                const Button = Modules[item]
                if (!Button) return null;
                return (
                    <Button key={index} {...customer} {...metadata} disabled={isdisabled} setDisabled={setDisabled} />
                )
            })}
        </Box>
    )
}

export default GroupButtons