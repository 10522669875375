import { Box, Typography } from "@mui/material";
import { indigo } from "@mui/material/colors";

const ContactProfile = ({ value }) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
      }}
    >
      {value?.match(/(http|www)/) ? (
        <a
          href={value}
          target="_blank"
          rel="relnoopener noreferrer"
          style={{
            textDecoration: "underline",
            color: indigo[900],
          }}
        >
          {value}
        </a>
      ) : (
        <Typography sx={{ fontSize: 14 }}>{value}</Typography>
      )}
    </Box>
  );
};

export default ContactProfile;
