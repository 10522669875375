import ProductApi from '@/apis/beta/Product/ProductApi';
import { buildQueryFilterCondition } from '@/functions';
import ServiceTimes from '@components/Templates/ServiceForm/modules/SwiftFederation/shared/ServiceTimes';
import MuiSkeleton from '@components/shared/MuiSkeleton';
import { Box } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Product from './Product';
import ProductConfigoptions from './ProductConfigoptions';
import ProductPrice from './ProductPrice';
import ServiceControlTimes from '@components/Templates/shared/Product/ServiceControlTimes';

const VNIS_CONFIGOPTIONS = [
    { name: 'domains', unit: 'qty' },
    { name: 'origin_shield_traffics', unit: 'gb' },
    { name: 'origin_shield_requests', unit: 'milion-requests' },
    { name: 'dns_requests', unit: 'milion-requests' },
    { name: 'basic_cdn_traffics', unit: 'gb' },
    { name: 'basic_cdn_requests', unit: 'milion-requests' },
]

const modules = {
    ProductVnis: Product
}

const MlyticService = ({ isPoc, ...isPOC }) => {
    console.log(isPOC)
    const { setValue } = useFormContext()
    const [inserting, setInserting] = useState(false)

    const MLYTIC_SCOPES = [
        {
            module: "ProductVnis",
            queries: buildQueryFilterCondition('$and', 0,
                [
                    { name: "product_groups.product_group_id", operator: "$eq", value: 15 },
                    { name: "product_type", operator: "$eq", value: "package" },
                    { name: "tw_products.product_hidden", operator: "$eq", value: 0 },
                ]),
            props: {
                label: "Chọn gói VNIS (*)",
                onSelect: (payload) => {
                    onSelect(payload, 'service')
                }
            }
        }
    ]
    const onSelect = async (payload) => {
        if (!payload || !payload?.product_id) return;
        setInserting(true);

        let productConfigoptions = await ProductApi.GetProductInformations({ id: payload?.product_id })

        if (productConfigoptions?.length === 0) {
            productConfigoptions = VNIS_CONFIGOPTIONS
        }
        setValue(`product_name`, payload?.product_name)
        setValue(`product_id`, payload?.product_id)
        setValue('product_configoptions', productConfigoptions?.map(x => _.pick(x, ['name', 'unit', 'value'])) || VNIS_CONFIGOPTIONS)
        setInserting(false)
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', gap: 1.25 }}>
                {MLYTIC_SCOPES.map((scope) => {
                    const Component = modules[scope?.module] || undefined
                    if (!Component) return null
                    const componentProps = {
                        ...scope,
                        ...scope?.props
                    }
                    return <Component {...componentProps} />
                })}
            </Box>
            {inserting ? <MuiSkeleton.LoadingDotBox /> : <ProductConfigoptions />}
            {!isPOC && <ProductPrice />}
            {!isPoc && <ServiceControlTimes />}
            {!isPOC && <ServiceTimes />}
        </Box>
    )
}

export default MlyticService