import ServiceAddonApi from "@/apis/beta/Service/ServiceAddonApi";
import { queryClientRefetchScope } from "@/functions";
import withPermission from "@/hocs/withPermission";
import { useNotificationContext } from "@contexts/notification";
import ServiceAddonForm from "@features/Module/Service/ServiceAddon/shared/Form";
import AddOutlined from "@mui/icons-material/AddOutlined";
import { Button, Drawer } from "@mui/material";
import dayjs from "dayjs";
import { Fragment, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

const ServiceCreateAddon = ({ service }) => {
  const { setNotification } = useNotificationContext();
  const queryClient = useQueryClient();
  const [isCreated, setCreated] = useState(false);
  const { mutateAsync } = useMutation(ServiceAddonApi.Create);

  const onSubmit = async (values) => {
    let form = values;
    const response = await mutateAsync({
      serviceId: service?.service_id,
      data: form,
    });
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }
    queryClientRefetchScope(queryClient, "service");

    return setNotification({
      open: true,
      message: "Thêm mới dịch vụ addon thành công",
      severity: "success",
    });
  };
  return (
    <Fragment>
      {
        <Drawer
          anchor="right"
          open={isCreated}
          onClose={() => setCreated(false)}
        >
          <ServiceAddonForm
            service={service}
            type="create"
            defaultValues={{
              service_product_id: -1,
              service_product_name: "",
              service_amount: 0,
              service_billing_cycle: "",
              service_name: "",
              service_start_date: dayjs(),
              service_duedate: dayjs(service?.service_duedate) || dayjs(),
              service_type: "addon",
              service_status: "payment-pending",
              service_tax: true,
              service_addon_qty: 1,
              service_note: "",
              service_management_billing: {
                service_billing_startdate: dayjs(),
                service_billing_enddate: dayjs(),
                service_nextbill_startdate: dayjs(),
                service_nextbill_enddate: dayjs(),
              },
            }}
            onSubmit={onSubmit}
            handleClose={() => setCreated(false)}
          />
        </Drawer>
      }
      <Button
        size="small"
        variant="outlined"
        disabled={isCreated}
        onClick={() => setCreated(true)}
        startIcon={<AddOutlined fontSize="small" />}
      >
        Addon
      </Button>
    </Fragment>
  );
};

export default withPermission(ServiceCreateAddon, {
  feature: "service",
  action: "index",
  type: "Button",
});
