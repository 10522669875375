import PermissionApi from '@/apis/beta/Permission/PermissionApi';
import withSuspense from '@/hocs/withSuspense';
import MuiSkeletonDefault from '@components/Skeletons/Default';
import { Box, Divider, Typography } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import ManageItem from '../ManageItem';

const ManageList = ({ selecteds = [],
    setSelecteds
}) => {
    const { data: { permissions } } = PermissionApi.List();

    const reformatterCollections = React.useCallback(() => {
        let result = _(permissions)
            .groupBy(x => [x?.permission_website_name, x?.permission_feature_name].join("::"))
            .map((value, key) => ({ feature: key, actions: value }))
            .value();
        return _.orderBy(result, x => x.feature, ["asc"])
    }, [permissions])

    const [collections, setCollections] = React.useState(reformatterCollections())
    React.useEffect(() => {
        setCollections(reformatterCollections())
    }, [reformatterCollections])
    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1 }}>
            <Box sx={{ display: 'flex', width: 300, minWidth: 300 }}>
                <Typography sx={{ m: '4px auto auto 0' }} color="custom.grey">
                    Quản Lý Cấp Quyền
                </Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
                {collections?.map((role, index) => {
                    return (
                        <React.Fragment key={index}>
                            <ManageItem data={role} selecteds={selecteds} setSelecteds={setSelecteds} />
                            <Divider sx={{ m: '8px 0' }} />
                        </React.Fragment>
                    )
                })}
            </Box>
        </Box>
    )
}

export default withSuspense(ManageList, MuiSkeletonDefault)