
import _ from 'lodash';
import { enforce, test } from 'vest';
const vest = require("vest")

export const dayoffValidator = vest.create((data = {}) => {

    test(`day_off_reason`, 'Lý do xin nghỉ phép không được để trống.', () => {
        enforce(data?.day_off_reason).isNotEmpty();
    });
    if (parseInt(data?.day_off_type) !== 0) {
        test("day_off_type",
            "Số ngày nghỉ phép vượt quá giới hạn ngày nghỉ phép năm còn lại được hưởng.",
            () => {
                let numberDayoff = 0;
                _.forEach(data?.day_off_range_time, dayoff => {
                    if (dayoff.dayoff_option <= 1) {
                        return numberDayoff += 0.5
                    }
                    return numberDayoff += 1;
                })
                return enforce(numberDayoff <= data?.employee_day_off_left).isTruthy()
            }
        )
    }
    if (data?.type !== "create") {
        test(`day_off_note`, 'Lý do huỷ/chỉnh sửa phiếu nghỉ phép không được để trống.', () => {
            enforce(data?.day_off_note).isNotEmpty();
        });
    }
})