import ServiceNoteApi from "@/apis/beta/Service/ServiceNoteApi";
import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import WarehouseProcessApi from "@/apis/beta/Warehouse/WarehouseProcessApi";
import WarehouseProcessLogApi from "@/apis/beta/Warehouse/WarehouseProcessLogApi";
import { queryClientRefetchScope } from "@/functions";
import withPermission from "@/hocs/withPermission";
import ModalConfirm from "@components/shared/ModalConfirm";
import { useEmployeeContext } from "@contexts/employee";
import { IosShareOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Fragment, useState } from "react";
import { useQueryClient } from "react-query";

const WarehouseProcesse = ({ service, setShowProcessProblems, refetchServiceProcess }) => {
  const { employee } = useEmployeeContext();
  const queryClient = useQueryClient();
  const [isCreated, setCreated] = useState(false);

  const prebuildWarehouseProcessExport = async (items) => {
    return {
      warehouse_process_type: 3, // Phiếu xuất kho
      warehouse_process_to:
        service?.customer_company_name || service?.customer_name,
      warehouse_process_from: "KHO VNETWORK",
      warehouse_process_reason: "Tạo phiếu xuất bán device liquidation",
      warehouse_process_note: "Tạo phiếu xuất bán device liquidation",
      employee_id: employee?.employee_id,
      customer_id: service?.service_customer_id,
      warehouse_process_metadata: items,
    };

    // return form;
  };

  const get_id_items = () => {
    const warehouseItemIds = [];
    let isServiceTagEmpty = false
    service?.service_informations
      ?.filter((x) => x?.service_information_type?.toLowerCase() !== "cpu")
      ?.forEach((item) => {
        if (item?.warehouse_item_service_tag) {
          warehouseItemIds.push(item?.warehouse_item_service_tag?.split("|")[1]);
        } else {
          isServiceTagEmpty = true
        }
      });
    return { warehouse_item_ids: warehouseItemIds, isServiceTagEmpty }
  }


  const onSubmit = async () => {
    //check empty
    let warehouseItemCheck = get_id_items();
    if (warehouseItemCheck.isServiceTagEmpty) {
      return {
        status: 400,
        message: "Chưa chọn đủ thông tin service tag.",
      };
    }
    //check exported
    let params = warehouseItemCheck?.warehouse_item_ids?.map(x => "warehouse_item_id[]=" + x).join("&");
    const items = await WarehouseItemApi.GetItemsByIds({
      params: params
    })
    const ids = []
    items?.forEach((x) => {
      ids?.push(x?.warehouse_item_id)
      x?.children?.forEach((child) => {
        ids?.push(child?.warehouse_item_id)
      })
    })
    params = ids?.map(x => "warehouse_item_id[]=" + x).join("&");

    const checkItemExported = await WarehouseItemApi.CheckItemsExported({
      params: params
    })

    const checkItemExistInProcess = await WarehouseProcessApi.CheckItemExistInProcess({
      params: params
    })
    if (checkItemExistInProcess?.length > 0 || checkItemExported?.length > 0) {
      setShowProcessProblems(prev => {
        return {
          ...prev,
          hide: false,
          data: {
            ItemsExported: checkItemExported,
            ItemsInProcess: checkItemExistInProcess
          }
        }
      }
      )
      return {
        status: 400,
        // message: "Sản phẩm tồn tại trong phiếu bán đang chờ khác!! Vui lòng chọn sản phẩm khác hoặc huỷ phiếu đang chờ!",
        message: "Sản phẩm tồn tại trong phiếu bán đang chờ khác hoặc đã bán (xem tại chi tiết lỗi)",
      };
    }
    let form = await prebuildWarehouseProcessExport(items);

    const response = await WarehouseProcessApi.Create(form, service?.service_id);
    if (!response || response?.errors) {
      return {
        status: 400,
        message:
          response?.errors?.[0]?.message || "Tạo phiếu xuất kho thất bại.",
      };
    }
    const action_process = response?.data?.warehouse_process?.warehouse_process_code?.split("-")[0]?.toLowerCase();
    const warehouse_process_log = {
      warehouse_process_id: response?.data?.warehouse_process?.warehouse_process_id,
      warehouse_process_name: response?.data?.warehouse_process?.warehouse_process_code,
      warehouse_process_update_type: `CREATE-${action_process?.toUpperCase()}`,
      warehouse_process_updater_id: employee?.employee_id,
      warehouse_process_updater_name: employee?.employee_name,
      metadata: response?.data?.warehouse_process,
      warehouse_process_update_description: `Tạo phiếu xuất kho cho dịch vụ Device Liquidation`,
    }
    await ServiceNoteApi.Create({
      serviceId: service?.service_id,
      data: {
        note_content: `Tạo phiếu xuất kho mã phiếu ${response?.data?.warehouse_process?.warehouse_process_code} cho dịch vụ Device Liquidation`,
      }
    })
    await WarehouseProcessLogApi.createProcessLog(warehouse_process_log);
    if (refetchServiceProcess) { refetchServiceProcess() };
    return {
      status: 200,
      message: "Tạo phiếu xuất kho thành công.",
    };
  };
  const callback = () => queryClientRefetchScope(queryClient, "service");
  return (
    <Fragment>
      {isCreated && (
        <ModalConfirm
          handleSubmit={onSubmit}
          callback={callback}
          handleClose={() => setCreated(false)}
          dialog={{
            title: "Tạo Phiếu Xuất Kho",
            content: `Thông tin phiếu xuất kho sẽ được tạo với thông tin trong bảng thông tin cấu hình này.`,
          }}
        />
      )}
      <Button
        size="small"
        variant="contained"
        disabled={isCreated}
        onClick={() => setCreated(true)}
        startIcon={<IosShareOutlined fontSize="small" />}
      >
        Tạo Phiếu Xuất Kho
      </Button>
    </Fragment>
  );
};

export default withPermission(WarehouseProcesse, {
  feature: "service",
  action: "process-export",
  type: "Button",
});
