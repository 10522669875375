import { format_numeral_price } from "@/functions";
import { Box, Typography } from "@mui/material";

const Usage = ({ data }) => {
  return (
    <Box
      sx={{
        p: "8px 16px",
        border: "1px solid rgba(0, 0,0,0.18)",
        borderRadius: "4px",
        mb: 1.25,
      }}
    >
      <Typography sx={{ fontWeight: "medium", fontSize: 14, mb: 0.75 }}>
        Thông số sử dụng:
      </Typography>
      <Typography sx={{ fontSize: 13, mb: 0.75 }} color="text.grey">
        CDN Requests:
        <Typography
          sx={{ fontSize: 13, display: "inline-flex", ml: 0.5 }}
          color="text.black"
        >
          {format_numeral_price(data?.cdnRequestUsage)}/{" "}
          {format_numeral_price(data?.cdnRequestQuota)} requests
        </Typography>
      </Typography>
      <Typography sx={{ fontSize: 13, mb: 0.75 }} color="text.grey">
        CDN Traffics:
        <Typography
          sx={{ fontSize: 13, display: "inline-flex", ml: 0.5 }}
          color="text.black"
        >
          {format_numeral_price(
            Math.floor(data?.cdnTrafficUsage / 1000000000, 2)
          )}
          / {format_numeral_price(data?.cdnTrafficQuota / 1000000000)} GB
        </Typography>
      </Typography>
      <Typography sx={{ fontSize: 13, mb: 0.75 }} color="text.grey">
        DNS Queries:
        <Typography
          sx={{ fontSize: 13, display: "inline-flex", ml: 0.5 }}
          color="text.black"
        >
          {format_numeral_price(data?.dnsQueryUsage)}/{" "}
          {format_numeral_price(data?.dnsQueryQuota)} queries
        </Typography>
      </Typography>
      <Typography sx={{ fontSize: 13, mb: 0.75 }} color="text.grey">
        DNS Records:
        <Typography
          sx={{ fontSize: 13, display: "inline-flex", ml: 0.5 }}
          color="text.black"
        >
          {format_numeral_price(data?.dnsRecordUsage)}/{" "}
          {format_numeral_price(data?.dnsRecordQuota)} records
        </Typography>
      </Typography>
      <Typography sx={{ fontSize: 13, mb: 0.75 }} color="text.grey">
        Multi CDN Integration:
        <Typography
          sx={{ fontSize: 13, display: "inline-flex", ml: 0.5 }}
          color="text.black"
        >
          {format_numeral_price(data?.mcdnIntegrationUsage)}/{" "}
          {format_numeral_price(data?.mcdnIntegrationQuota)}
        </Typography>
      </Typography>
    </Box>
  );
};

export default Usage;
