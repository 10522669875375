import React from 'react'
import { FormControl } from '@mui/material'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SandboxApi from '@/apis/beta/Sandbox/SandboxApi'

const Province = ({
    preSelected,
    onChange,
    country,
    error: {
        isError = false,
        message = ""
    } }) => {
    const [provinces] = (!country || country === '-1') ? [] : SandboxApi.Provinces(country)

    return (
        <FormControl fullWidth>
            <SingleSelectCustomize
                disabled={!country || country === "" || country === "-1"}
                value={provinces?.find(x => x?.value === preSelected) || null}
                options={provinces}
                input={{
                    label: "Tỉnh/ Thành phố",
                    placeholder: "Chọn tỉnh/ thành phố..."
                }}
                onChange={onChange}
            />
            {!!isError && <ErrorHelperText message={message} />}
        </FormControl>
    )
}

export default withSuspense(Province, MuiSkeleton["TextField"])
