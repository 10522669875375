import ScopeApi from '@/apis/beta/Scope/ScopeApi'
import { makeFirstLetterUppercase } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import { DICTIONARIES } from '@/libs/constants'
import DataGridCustomize from '@components/DataGrid'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { useEmployeeContext } from '@contexts/employee'
import ScopeManagementLibs from '@features/Module/Setting/Scope(ManagementResource)/shared/functions'
import { Typography } from '@mui/material'
import EmployeeLibs from './shared/functions'

const Scope = () => {
    const { employee } = useEmployeeContext()
    const { data: { scopes, roles } } = ScopeApi.EmployeeScopes({ employeeId: employee?.employee_id })
    return (
        <>
            <Typography sx={{ fontWeight: 'medium', mb: 1 }}>
                Quản Lý Nguồn Dữ Liệu Truy Cập
            </Typography>
            <DataGridCustomize
                components={{
                    Toolbar: 'disabled',
                    Pagination: 'disabled'
                }}
                rows={scopes?.map((scope, index) => ({ ...scope, id: index }))}
                columns={[...ScopeManagementLibs?.columns?.slice(0, 6)]}
            />
            <Typography sx={{ fontWeight: 'medium', mb: 1, mt: 2 }}>
                Quản Lý Quyền
            </Typography>
            <DataGridCustomize
                treeData
                groupingColDef={{
                    headerName: 'Module | Tính Năng',
                    field: 'hierarchy',
                    width: 500,
                    valueGetter: ({ row }) => {
                        return row?.hierarchy?.join(' ')?.replace(/[0-9]/g, '')?.split(/[-_ ]/)?.map(x => makeFirstLetterUppercase(DICTIONARIES[x] || x))?.reverse()?.join(' ')?.replace(/,/, '')
                    }
                }}
                getTreeDataPath={(row) => row?.hierarchy}
                components={{
                    Toolbar: 'disabled',
                    Pagination: 'disabled'
                }}
                rows={[...EmployeeLibs?.rows?.permission({ roles })]}
                columns={[...EmployeeLibs?.columns?.permission]}
                paginationMode='client'
            />
        </>
    )
}

export default withSuspense(Scope, MuiSkeleton["LoadingDotBox"])