import DatePicker from '@components/DatePicker'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import { Box, FormControl, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const PocTimer = () => {
    const { control, formState: { errors } } = useFormContext()
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: "column", md: "row" },
            gap: 0.5
        }}>
            <Box sx={{ minWidth: { xs: "100%", md: 300 } }}>
                <Typography sx={{
                    fontSize: 14,
                    color: "#777680",
                    mb: { xs: 1, md: 0 }
                }}>
                    Thời Gian Dùng Thử *
                </Typography>
            </Box>
            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 2
            }}>
                <Controller
                    name="order_startdate"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FormControl fullWidth sx={{
                                ".MuiIconButton-edgeEnd": {
                                    m: 'unset'
                                }
                            }}>
                                <DatePicker value={value}
                                    onChange={onChange} label="Ngày bắt đầu" />
                                {!!errors?.effective_date && <ErrorHelperText message={errors?.order_startdate?.message} />}
                            </FormControl>
                        )
                    }} />
                <Controller
                    name="order_duedate"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FormControl fullWidth sx={{
                                ".MuiIconButton-edgeEnd": {
                                    m: 'unset'
                                }
                            }}>
                                <DatePicker value={value}
                                    onChange={onChange} label="Ngày kết thúc" />
                                {!!errors?.expiry_date && <ErrorHelperText message={errors?.order_duedate?.message} />}
                            </FormControl>
                        )
                    }} />
            </Box>

        </Box>
    )
}

export default PocTimer