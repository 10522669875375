import React from "react";
import CurrencyInput from "react-currency-input-field";

const MuiCurrency = ({ value, onChange, ...props }) => {
  return (
    <CurrencyInput
      style={{
        width: "100%",
        height: "40px",
        border: "1px solid #9e9e9e",
        borderRadius: "4px",
        boxSizing: "border-box",
        alignItems: "center",
        color: "rgba(0,0,0,0.87)",
        lineHeight: "1.4375em",
        padding: "8.5px 14px",
        fontFamily:
          '"Google Sans",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
        fontSize: "13px",
        backgroundColor: "#FFF",
        ...props?.sx,
      }}
      placeholder="Nhập giá trị..."
      value={value}
      onValueChange={(value, name) => onChange(value)}
      disabled={props?.disabled}
      {...props}
    />
  );
};

export default MuiCurrency;
