import DatePicker from "@components/DatePicker";
import DateRangeCustomize from "@components/datepickers/DateRangeCustomize";
import NumericFormatCustom from "@components/NumericFormatCustom";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import ServiceApi from "@/apis/beta/Service/ServiceApi";
import ServiceBillingApi from "@/apis/beta/Service/ServiceBillingApi";
import * as _ from "lodash";
import { format_date_short, queryClientRefetcher } from "@/functions";
import { useNotificationContext } from "@contexts/notification";
import { useQueryClient } from "react-query";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import moment from "moment";

const ServiceEdit = ({ onClose, data }) => {
  const { setNotification } = useNotificationContext();
  const queryClient = useQueryClient();

  const [submit, setSubmit] = React.useState(false);
  const { data: service } = ServiceApi.ServiceInformation({
    serviceId: data?.service_id,
  });

  const { control, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      service_start_date: moment(service?.service_start_date).format(
        "YYYY-MM-DD"
      ),
      service_billing_startdate: dayjs(service?.service_billing_startdate),
      service_billing_enddate: dayjs(service?.service_billing_enddate),
      service_nextbill_startdate: dayjs(
        service?.service_management_billing?.service_nextbill_startdate
      ),
      service_nextbill_enddate: dayjs(
        service?.service_management_billing?.service_nextbill_enddate
      ),
      service_amount: service?.service_amount || 0,
      service_recurring: service?.service_recurring || 0,
    },
  });

  const onChangeDates = (values = []) => {
    setValue(`service_billing_startdate`, values[0]);
    return setValue(`service_billing_enddate`, values[1]);
  };

  const onChangeNextDates = (values = []) => {
    setValue(`service_nextbill_startdate`, values[0]);
    return setValue(`service_nextbill_enddate`, values[1]);
  };

  const onSubmit = async (values) => {
    setSubmit(true);
    const serviceMetadata = {
      ..._.pick(values, ["service_amount", "service_recurring"]),
      service_start_date: format_date_short(values?.service_start_date),
    };
    const response = ServiceApi.Update({
      serviceId: data?.service_id,
      data: serviceMetadata,
    });
    // process service update informations
    if (!response || response?.errors) {
      setSubmit(false);
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }

    const form = {
      service_billing_startdate: format_date_short(
        values?.service_billing_startdate?.$d
      ),
      service_billing_enddate: format_date_short(
        values?.service_billing_enddate?.$d
      ),
      service_nextbill_startdate: format_date_short(
        values?.service_nextbill_startdate?.$d
      ),
      service_nextbill_enddate: format_date_short(
        values?.service_nextbill_enddate?.$d
      ),
    };
    const billing = await ServiceBillingApi.Update({
      serviceId: data?.service_id,
      data: form,
    });
    // process service update informations
    if (!billing || billing?.errors) {
      setSubmit(false);
      return setNotification({
        open: true,
        message: billing?.errors?.[0]?.message,
        severity: "error",
      });
    }

    queryClientRefetcher(queryClient, [
      "service.informations",
      "service.logger",
      "services.list",
      "services.detail",
    ]);

    setNotification({
      open: true,
      message: "Cập nhật thông tin dịch vụ thành công",
      severity: "success",
    });
    return onClose();
  };
  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth="md"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <DialogTitle>Thay Đổi Thông Tin Dịch Vụ</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            mt: 1.5,
          }}
        >
          <Controller
            name="service_start_date"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl
                  fullWidth
                  sx={{
                    mb: 1,
                  }}
                >
                  <DatePicker
                    label={"Ngày bắt đầu sử dụng dịch vụ"}
                    value={value}
                    onChange={onChange}
                  />
                </FormControl>
              );
            }}
          />
          <Controller
            name="service_amount"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl
                  fullWidth
                  sx={{
                    mb: 1,
                  }}
                >
                  <TextField
                    size="small"
                    label="Đơn giá bán *"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                    value={value}
                    onChange={onChange}
                  />
                </FormControl>
              );
            }}
          />
          <Controller
            name="service_recurring"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl
                  fullWidth
                  sx={{
                    mb: 1,
                  }}
                >
                  <TextField
                    size="small"
                    label="Đơn giá gia hạn *"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                    value={value}
                    onChange={onChange}
                  />
                </FormControl>
              );
            }}
          />
          <Typography
            sx={{
              fontSize: 14,
              mb: 0.75,
            }}
            color="text.grey"
          >
            Chu kỳ thanh toán đầu tiên
          </Typography>
          <FormControl
            fullWidth
            sx={{
              mb: 1.5,
            }}
          >
            <DateRangeCustomize
              value={[
                watch(`service_billing_startdate`),
                watch(`service_billing_enddate`),
              ]}
              handler={onChangeDates}
            />
          </FormControl>
          <Typography
            sx={{
              fontSize: 14,
              mb: 0.75,
            }}
            color="text.grey"
          >
            Chu Kỳ Gia Hạn
          </Typography>
          <FormControl
            fullWidth
            sx={{
              mb: 1.5,
            }}
          >
            <DateRangeCustomize
              value={[
                watch(`service_nextbill_startdate`),
                watch(`service_nextbill_enddate`),
              ]}
              handler={onChangeNextDates}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="outlined"
          disable={submit}
          onClick={onClose}
        >
          Huỷ Thao Tác
        </Button>
        <Button
          size="small"
          disable={submit}
          variant="contained"
          type="submit"
          startIcon={
            submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
          }
        >
          Lưu Thay Đổi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withSuspense(ServiceEdit, MuiSkeleton["LinearProgress"]);
