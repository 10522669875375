import {
    buildQueryFilterCondition,
    format_date_short
} from "@/functions";
import ADDON_SHAREDS from "@components/Templates/shared/Service/Addons/shared";
import {
    Box,
    Button,
    CircularProgress,
    TextField,
    Typography
} from "@mui/material";
import dayjs from "dayjs";
import _ from "lodash";
import { FormProvider, useForm } from "react-hook-form";

const VnisAddonUp = ({
    service = {},
    onClose = () => console.log(``),
    submit = false,
    onSubmit,
}) => {
    const methods = useForm({
        defaultValues: {
            service_product_id: -1,
            service_product_name: '',
            service_start_date: dayjs(service?.service_billing_startdate)?.format("YYYY-MM-DD"),
            service_addon_qty: 1,
            service_price: 0,
            service_amount: 0,
            service_tax: service?.service_tax,
            service_note: '',
            service_billing_startdate: dayjs(),
            service_billing_enddate: dayjs().add(1, 'months').endOf('months'),
            service_nextbill_startdate: dayjs()?.add(1, 'months')?.endOf('months')?.add(1, 'days'),
            service_nextbill_enddate: dayjs()?.add(1, 'months')?.endOf('months')?.add(1, 'days').endOf('months')
        },
    });
    const { handleSubmit } = methods;

    const handleClickSubmit = async (values) => {
        let form = _.omit(values, [
            'service_billing_startdate',
            'service_billing_enddate',
            'service_nextbill_startdate',
            'service_nextbill_enddate',
            'product_type'
        ]);

        const serviceTimes = {
            service_billing_startdate: format_date_short(values?.service_billing_startdate?.$d || values?.service_billing_startdate),
            service_billing_enddate: format_date_short(values?.service_billing_enddate?.$d || values?.service_billing_enddate),
            service_nextbill_startdate: format_date_short(values?.service_nextbill_startdate?.$d || values?.service_nextbill_startdate),
            service_nextbill_enddate: format_date_short(values?.service_nextbill_enddate?.$d || values?.service_nextbill_enddate)
        }
        form = {
            ...form,
            service_start_date: format_date_short(form?.service_start_date?.$d || form?.service_start_date),
            service_management_billing: serviceTimes,
            service_template: 'Mlytics'
        };
        await onSubmit(form);
    };
    return (
        <FormProvider {...methods}>
            <Box
                sx={{ p: 3, mb: 3, minWidth: { xs: '100%', md: 700 } }}
                component="form"
                onSubmit={handleSubmit(handleClickSubmit)}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography
                        sx={{
                            fontSize: 18,
                            fontWeight: "medium",
                        }}
                    >
                        Mua - Mua - Mua
                    </Typography>
                    <TextField
                        disabled
                        label="Dịch Vụ"
                        value={service?.service_name}
                        size="small"
                        placeholder="Chưa xác định..."
                        sx={{
                            bgcolor: "#E3E1EC1F",
                        }}
                        fullWidth
                    />
                    <ADDON_SHAREDS.Product
                        query={buildQueryFilterCondition('$and', 0, [
                            { name: "tw_products.product_group_id", operator: "$eq", value: 21 },
                            { name: "product_type", operator: "$ne", value: "node" },
                            { name: "product_type", operator: "$contains", value: 'vnis' },
                            { name: "tw_products.product_hidden", operator: "$eq", value: 0 },
                            { name: "tw_products.product_id", operator: "$in", value: [395, 396, 397, 398] },
                        ])}
                    />
                    <ADDON_SHAREDS.ServiceName />
                    <ADDON_SHAREDS.ServiceStartdate />
                    <ADDON_SHAREDS.ServiceQty />
                    <ADDON_SHAREDS.ServicePrice />
                    <ADDON_SHAREDS.ServiceAmount />
                    <Box sx={{
                        p: 1.5,
                        border: '1px solid #333',
                        borderRadius: '4px'
                    }}>
                        <Typography sx={{
                            fontSize: 14,
                            fontWeight: 'medium',
                            mb: 1.5
                        }}
                            color="primary"
                        >
                            Thời Gian Dịch Vụ
                        </Typography>
                        <ADDON_SHAREDS.ServiceFirstTime />
                        <Box sx={{ mb: 1.5 }} />
                        <ADDON_SHAREDS.ServiceNextTime />
                    </Box>
                    <ADDON_SHAREDS.Taxable />
                    <ADDON_SHAREDS.Description />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        gap: 2,
                        justifyContent: "flex-end",
                        mt: 2,
                    }}
                >
                    <Button
                        disabled={submit}
                        size="small"
                        onClick={onClose}
                        variant="text"
                    >
                        Huỷ Thao Tác
                    </Button>
                    <Button
                        type="submit"
                        size="small"
                        disabled={submit}
                        startIcon={
                            submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
                        }
                        variant="contained"
                    >
                        Thêm mới
                    </Button>
                </Box>
            </Box>
        </FormProvider>
    );
};
export default VnisAddonUp