import { API_V3_URL } from "@/libs/constants";
import { requestWithToken, encodeBase64 } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";

class CustomerSpamApi {
    List = ({
        query = null,
        searchQuery = null,
        page = 1,
        pageSize = 10
    }) => {
        return useQueryWithCustomized(
            ["customers-spam.list", query, searchQuery, page, pageSize],
            async () => {
                return await requestWithToken(`${API_V3_URL}/customer_spams?${query ? `&filters=${encodeBase64(query)}` : ''}${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ''}&page=${page}&page_size=${pageSize}`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return {
                            customer_spams: data?.data?.customer_spams,
                            pagination: data?.data?.pagination,
                        };
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                staleTime: 10 * 60 * 1000,
            }
        );
    }


    Create = async (data) => {
        return await requestWithToken(`${API_V3_URL}/customer_spams`,
            "POST", {
            customer_spam: data
        }, {}, {})
            .then(({ data }) => {
                return {
                    customer_spam: data?.data?.customer_spam,
                    status: data?.status
                };
            })
            .catch((error) => {
                return error.response;
            });
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CustomerSpamApi();
