import React from "react";
import InvoiceMainTabs from "../InvoiceMainTabs";
import { Typography } from "@mui/material";

const Layout = ({ children }) => {
	return (
		<React.Fragment>
			<Typography sx={{
				fontWeight: 'medium',
				fontSize: {
					xs: 18,
					md: 24
				}
			}}>
				Hoá Đơn
			</Typography>
			<InvoiceMainTabs />
			{children}
		</React.Fragment>
	);
};

export default Layout;
