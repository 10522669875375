import { format_date_short, requestWithToken } from "@/functions";
import { API_SERVICE_URL } from "@/libs/constants";
import { useQuery } from "react-query";
import qs from 'query-string'

async function resolveMlyticAnalytics(url = '') {
    const response = await requestWithToken(url)

    return response?.data?.data
}

export function resolveMlyticDNSRecords(service_uuid) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery(["customer.services.mlytics.dns_records", service_uuid], () => Promise.all([
        resolveMlyticAnalytics(`${API_SERVICE_URL}/viewers/services/${service_uuid}/analytic/mlytics/dns_records`)
    ]), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        staleTime: 30 * 60 * 1000,
        enabled: typeof service_uuid !== "undefined",
        suspense: true
    })
}


export function resolveMlyticUsage(service_uuid, query) {
    if (!query?.gte || !query?.lte) return {}

    const current = qs.stringify({
        gte: format_date_short(query?.gte?.$d || query?.gte),
        lte: format_date_short(query?.lte?.$d || query?.lte),
        usage_type: query?.usage_type
    })

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery(["customer.services.mlytics.dns_queries", service_uuid, current], () => Promise.all([
        resolveMlyticAnalytics(`${API_SERVICE_URL}/viewers/services/${service_uuid}/analytic/mlytics/usage?${current}`)
    ]), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        staleTime: 30 * 60 * 1000,
        enabled: typeof service_uuid !== "undefined",
        suspense: true
    })
}
