import { Box, Chip, StepLabel, Typography } from '@mui/material';
import React from 'react';
import PrivateIconWithFormat from "../shared/icons"
import SingleDocument from '@components/shared/SingleDocument';
import { Link } from 'react-router-dom';

const StepCompleted = ({ contractNumber }) => {
    return (
        <React.Fragment>
            <StepLabel
                StepIconProps={{
                    sx: {
                        fontSize: 20,
                        mb: "26px"
                    }
                }}
            >
                <Chip size='verysmall'
                    variant='customize-success'
                    label="Hoàn Tất" />
                <Box sx={{ display: 'flex' }}>
                    <PrivateIconWithFormat icon="ContractIcon" />
                    <Typography sx={{ mr: 2 }}>
                        Hợp đồng {contractNumber?.contract_number_id} xử lý hoàn tất.
                    </Typography>
                </Box>
                {contractNumber?.contract_attachments?.length > 0 && <React.Fragment>
                    {contractNumber?.contract_attachments.map((attachment) =>
                        <Link
                            target='_blank'
                            to={` ${(attachment?.attachment_file_url) ? `${process.env.REACT_APP_DOMAIN_FILE}${attachment?.attachment_file_url.replace("pub/", "")}` : "#"}`}
                        >
                            <SingleDocument
                                key={attachment?.attachment_id}
                                document={{
                                    name: attachment?.attachment_file_name,
                                    size: attachment?.attachment_file_size,
                                    type: attachment?.attachment_file_type
                                }} />
                        </Link>
                    )}
                </React.Fragment>}
            </StepLabel>
        </React.Fragment>
    )
}

export default StepCompleted