import React from 'react'

import { COUNTRIES } from "@/libs/constants"
import { FormControl } from '@mui/material'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import ErrorHelperText from '@components/shared/ErrorHelperText'

const Coutry = ({
    value,
    onChange,
    error: {
        isError = false,
        message = ""
    } }) => {
    return (
        <FormControl fullWidth>
            <SingleSelectCustomize
                value={value}
                options={COUNTRIES}
                input={{
                    label: "Quốc gia",
                    placeholder: "Chọn quốc gia"
                }}
                onChange={onChange}
            />
            {!!isError && <ErrorHelperText message={message} />}
        </FormControl>
    )
}

export default Coutry