import { format_date_time } from '@/functions'
import { Box, StepLabel, Typography } from '@mui/material'
import React from 'react'
import PrivateIconWithFormat from '../shared/icons'

const StepStart = ({ contractNumber = {} , subcontract = {} }) => {
    return (
        <React.Fragment>
            <StepLabel
                StepIconProps={{
                    sx: {
                        fontSize: 20,
                        mb: "20px"
                    }
                }}>
                <Typography
                    color="primary"
                    sx={{
                        fontWeight: 'medium',
                    }}>{`Mã số hợp đồng ${contractNumber?.contract_number_id || subcontract?.subcontract_code }`}</Typography>
                <Box sx={{ display: 'flex' }}>
                    <PrivateIconWithFormat icon="CalendarIcon" />
                    <Typography sx={{ mr: 2 }}>
                        {format_date_time(contractNumber?.contract_number_created_at || subcontract?.subcontract_created_at, true)}
                    </Typography>
                    <PrivateIconWithFormat icon="OwnerIcon" />
                    <Typography>
                        {contractNumber?.employee?.employee_name || subcontract?.employee?.employee_name}
                    </Typography>
                </Box>
            </StepLabel>
        </React.Fragment>
    )
}

export default StepStart