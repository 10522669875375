import PermissionApi from "@/apis/beta/Permission/PermissionApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import BreadCrumb from "@components/shared/BreadCrumb";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router";
import Form from "../shared/Form";

const Detail = () => {
  const { prefix } = useParams();
  const {
    data: { permissions },
  } = PermissionApi.Detail(prefix);

  const defaultValues = {
    website: permissions[0]?.permission_name?.split("::")[0],
    feature: permissions[0]?.permission_name?.split("::")[1]?.split("#")[0],
    actions: permissions?.map((p) => {
      return {
        action_name: p?.permission_action_name,
        permission_pathname: p?.permission_pathname,
        module: p?.module,
        is_outlet: p?.is_outlet || false,
        prefix_outlet: p?.prefix_outlet || "",
      };
    }),
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: 18, md: 24 },
          fontWeight: "medium",
        }}
      >
        Chi Tiết Phân Quyền
      </Typography>
      <BreadCrumb
        data={[
          { label: "Quản Lý Phân Quyền", reactLink: "/roles" },
          { label: "Chỉnh Sửa Phân Quyền" },
        ]}
      />
      <Form type="update" defaultValues={defaultValues} />
    </Box>
  );
};

export default withSuspense(
  withPermission(Detail, { feature: "permission", action: "show" }),
  MuiSkeleton["Loading"]
);
