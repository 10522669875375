import { Autocomplete, Box, MenuItem, TextField, Typography } from '@mui/material';
import React from 'react';

const SingleSelectCustomize = ({
    options = [],
    value,
    compareAttributes = ['label'],
    onChange,
    formatCollection = { value: "value", label: "label" },
    input = { placeholder: "Nhập từ khoá tìm kiếm..." },
    noOptionsText = {
        Element: null // React.ReactNode
    },
    mode = "client",
    optionDetail = [],
    ...props
}) => {
    const getOptionLabel = (option) => {
        if (optionDetail.length === 0) return option?.[formatCollection?.label]
        let optionLabel = ''
        for (let attribute of optionDetail?.filter(attr => attr?.position === 'prefix')) {
            optionLabel += `${attribute?.label}: ${option?.[attribute?.attribute]} | ` || ''
        }
        optionLabel += option?.[formatCollection?.label]
        for (let attribute of optionDetail?.filter(attr => attr?.position === 'suffix')) {
            optionLabel += ` | ${attribute?.label}: ${option?.[attribute?.attribute]}` || ''
        }
        return optionLabel
    }
    return (
        <Autocomplete
            filterOptions={(options, params) => {
                // none of compare attributes
                if (compareAttributes?.length === 0) return options;
                // parse input value compare to lowercase
                const stringLower = params?.inputValue?.toLowerCase()
                if (stringLower && stringLower?.trim() === "") return options;
                // collect options
                let newOptions = []
                for (let option of options) {
                    let flag = false
                    for (let attribute of compareAttributes) {
                        const data = option[attribute]?.toString()?.toLowerCase()
                        if (data?.match(stringLower)) {
                            flag = true
                        }
                    }
                    if (flag) { newOptions.push(option) }
                }
                return newOptions
            }}
            {...props}
            size="small"
            filterSelectedOptions
            options={options}
            value={value}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        label={input?.label}
                        InputLabelProps={{ shrink: !!input?.label }}
                        value={input?.value || params?.inputProps?.value}
                        onChange={input?.onChange || params?.inputProps?.onChange}
                        placeholder={input?.placeholder}
                    />
                )
            }}

            renderOption={(props, option) => {
                let styleds = { ...props, key: option?.[formatCollection?.value] }
                return (
                    <MenuItem sx={{ fontSize: 14, height: 38 }}
                        {...styleds}
                    >
                        {getOptionLabel(option)}
                    </MenuItem>
                )
            }}
            clearText={"Xoá dữ liệu"}
            noOptionsText={
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: {
                        xs: 'column',
                        md: 'row'
                    },
                    gap: 1
                }}>
                    <Typography sx={{ fontSize: 14, color: "#9e9e9e" }}>
                        {(value?.length === options?.length && mode === "client")
                            ? "Bạn đã chọn tất cả."
                            : "Không tìm thấy kết quả thích hợp."}
                    </Typography>
                    {noOptionsText?.Element}
                </Box>
            }
            getOptionLabel={(option) => option?.[formatCollection?.label]?.toString() || ``}
            onChange={onChange}
            isOptionEqualToValue={(option, value) => option?.[formatCollection?.value] === value?.[formatCollection?.value]}
            onKeyDown={(event) => {
                if (["Backspace", "Delete"].includes(event.key)) {
                    return onChange(null);
                }
            }}
        />
    )
}

export default SingleSelectCustomize