import { Button } from '@mui/material'
import React from 'react'
import withPermission from '@/hocs/withPermission'
import { Link } from 'react-router-dom'

const ButtonAssignee = () => {
    return (
        <Link to={`/customers/customer-assignees`} replace>
            <Button
                variant='outlined'
                sx={{
                    height: {
                        xs: 36, md: 42
                    }
                }}
                size="small"
            >
                Chia Khách Hàng
            </Button>
        </Link>

    )
}

export default withPermission(ButtonAssignee, { feature: 'customer', action: 'assignee', type: "button" })