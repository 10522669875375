import WorkcontractApi from "@/apis/beta/Employee/WorkcontractApi";
import DataGrid from "@/components/DataGrid";
import { CellLink, CellStatus } from "@/components/DataGrid/Cell";
import {
  buildQueryFilterCondition,
  format_date_short,
  handleRedirectOpenLink,
} from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import { WORK_CONTRACT_STATUS } from "@/libs/constants";
import PopperAction from "@components/PopperAction";
import MuiSkeletonDefault from "@components/Skeletons/Default";
import FormatTextNumeralVND from "@components/shared/FormatTextNumeralVND";
import { useNotificationContext } from "@contexts/notification";
import { MoreVertOutlined } from "@mui/icons-material";
import { Box, IconButton, Popper } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import ButtonCreate from "./ButtonCreate";

const List = () => {
  const { employee_id } = useParams();
  const { setNotification } = useNotificationContext();

  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const filterableWorkContract = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
    const fieldQueries = [
      {
        name: "work_contracts.work_contract_id",
        operator: "$eq",
        value: parseInt(debouncedSearchQuery),
        disabled: !parseInt(debouncedSearchQuery),
      },
      {
        name: "work_contracts.work_contract_number",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
    ];
    return buildQueryFilterCondition("$or", 0, fieldQueries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [popper, setPopper] = React.useState({
    anchorEl: undefined,
    data: undefined,
  });

  const [columns] = React.useState([
    {
      field: "work_contracts.work_contract_id",
      headerName: "ID",
      width: 80,
      valueGetter: ({ row }) => ({
        label: `HĐ-${row?.work_contract_id}`,
        href: [
          "employees",
          employee_id,
          "work_contracts",
          row?.work_contract_id,
        ],
      }),
      renderCell: ({ value }) => <CellLink data={value} />,
    },
    {
      field: "work_contract_type",
      headerName: "Loại Hợp Đồng",
      width: 150,
      renderCell: ({ value }) => value === 1 && "Chính Thức",
    },
    {
      field: "work_contract_number",
      headerName: "Mã Hợp Đồng",
      width: 150,
    },
    {
      field: "work_contract_startdate",
      headerName: "Ngày bắt đầu",
      width: 150,
      valueFormatter: ({ value }) => format_date_short(value),
    },
    {
      field: "work_contract_enddate",
      headerName: "Ngày kết thúc",
      width: 150,
      valueFormatter: ({ value }) => format_date_short(value),
    },
    {
      field: "work_contract_term",
      headerName: "Thời hạn hợp đồng",
      width: 160,
    },
    {
      field: "work_contract_status",
      headerName: "TT. Hợp Đồng",
      width: 150,
      valueGetter: ({ row }) =>
        WORK_CONTRACT_STATUS.find(
          (stt) => stt?.value === row?.work_contract_status
        ),
      renderCell: ({ value }) => (
        <CellStatus component="muiChip" data={value} />
      ),
    },
    {
      field: "work_contract_created_at",
      headerName: "Ngày Upload HĐ",
      width: 150,
      valueFormatter: ({ value }) => format_date_short(value),
    },
    {
      field: "work_contract_salary_basic",
      headerName: "Lương CB",
      width: 150,
      renderCell: ({ value }) => <FormatTextNumeralVND value={value} />,
    },
    {
      field: "work_contract_salary_allowance",
      headerName: "Lương Trợ Cấp",
      width: 150,
      renderCell: ({ value }) => <FormatTextNumeralVND value={value} />,
    },
    {
      field: "actions",
      headerName: "Thao Tác",
      width: 80,
      renderCell: ({ row }) => {
        let currentActions = actions;
        if (row?.work_contract_status === 3) {
          currentActions = currentActions?.filter(
            (item) =>
              item?.name !== "cancel-workcontract" &&
              item?.name !== "update-workcontract"
          );
        }
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <IconButton
              onClick={(event) => {
                setPopper((prev) => ({
                  ...prev,
                  anchorEl: event?.target,
                  data: row,
                  actions: currentActions,
                }));
              }}
              title="Hành động"
            >
              <MoreVertOutlined sx={{ m: "auto" }} />
            </IconButton>
          </Box>
        );
      },
    },
  ]);

  const { data, isFetching } = WorkcontractApi.List({
    page: page?.page + 1,
    pageSize: page?.pageSize || 20,
    query: `&filters[$and][0][employees.employee_id][$eq]=${employee_id}`,
    searchQuery:
      filterableWorkContract() === "" ? null : filterableWorkContract(),
  });

  const [actions] = React.useState([
    {
      order: 1,
      name: "open-workcontract",
      icon: "See",
      label: "Xem HĐNV",
      handleClick: async (values) => {
        if (!values?.link) {
          return setNotification({
            open: true,
            message: "Không tìm thấy file hợp đồng tải lên.",
            severity: "error",
          });
        }
        handleClosePopper();
        return handleRedirectOpenLink(values?.link);
      },
    },
    {
      order: 2,
      name: "update-workcontract",
      icon: "Edit",
      href: {
        prefix: `/employees/${employee_id}/work_contracts/`,
        key: "work_contract_id",
        suffix: "/update",
      },
      label: "Chỉnh Sửa HĐNV",
    },
  ]);
  const handleClosePopper = () =>
    setPopper({ anchorEl: undefined, data: undefined, actions: [] });

  return (
    <Box>
      {popper?.anchorEl !== undefined && (
        <Popper anchorEl={popper?.anchorEl} open={Boolean(popper.anchorEl)}>
          <PopperAction {...popper} handleClose={handleClosePopper} />
        </Popper>
      )}
      <ButtonCreate employeeId={employee_id} />
      <DataGrid
        rows={
          data?.work_contracts?.map((wContract) => ({
            ...wContract,
            id: wContract?.work_contract_id,
          })) || []
        }
        columns={columns}
        loading={isFetching}
        componentsProps={{
          toolbar: {
            inputSearch: {
              placeholder: "Nhập ID/ mã hợp đồng tìm kiếm...",
              value: searchQuery,
              onChange: setSearchQuery,
            },
          },
          pagination: {
            rowsPerPageOptions: [5, 10, 20],
            page: page?.page || 0,
            pageSize: page?.pageSize || 5,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        rowCount={data?.pagination?.total}
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(List, { feature: "employee", action: "list" }),
  MuiSkeletonDefault
);
