import ServiceApi from '@/apis/beta/Service/ServiceApi'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { Box, Typography } from '@mui/material'
import React from 'react'

const ServiceTag = ({ serviceId = -1 }) => {

    const { data } = ServiceApi.ServiceTag({ serviceId })
    return (
        <Box sx={{ height: '100%', width: '100%', alignContent: 'center', justifyContent: 'flex-end', pr: '8px' }}>
            <Typography sx={{ fontSize: 14 }}>
                {data}
            </Typography>
        </Box>
    )
}

export default withSuspense(ServiceTag, MuiSkeleton["GradientCircularProgress"])