import DatePicker from "@components/datepickers/DateRangPicker";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import { Box, Collapse, FormControl, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import StatementOption from "./StatementOption";

const ServiceManagementBilling = () => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const onChangeFirstBilling = (newValue) => {
    setValue(
      `service_management_billing.service_billing_startdate`,
      newValue[0]
    );
    setValue(`service_management_billing.service_billing_enddate`, newValue[1]);
    setValue(
      `service_management_billing.service_nextbill_startdate`,
      dayjs(newValue[1]).add(1, "days")
    );
  };

  const onChangeNextBilling = (newValue) => {
    setValue(
      `service_management_billing.service_nextbill_startdate`,
      newValue[0]
    );
    setValue(
      `service_management_billing.service_nextbill_enddate`,
      newValue[1]
    );
  };
  return (
    <Fragment>
      <Box
        sx={{
          mt: 1.25,
          p: 2,
          borderRadius: "4px",
          border: "1px solid #00001F",
        }}
      >
        <Typography
          sx={{ fontWeight: "medium", fontSize: 14, mb: 0.75 }}
          color="primary"
        >
          [Thiết Lập Thời Gian Dịch Vụ]
        </Typography>
        <Typography sx={{ fontSize: 13, mb: 0.75 }} color="text.black">
          Chu Kỳ Thanh Toán Đầu Tiên *
        </Typography>
        <FormControl fullWidth sx={{ mb: 1 }}>
          <DatePicker
            value={[
              dayjs(
                watch(`service_management_billing.service_billing_startdate`)
              ),
              dayjs(
                watch(`service_management_billing.service_billing_enddate`)
              ),
            ]}
            onChange={onChangeFirstBilling}
          />
          {!!errors?.service_management_billing?.service_billing_startdate && (
            <ErrorHelperText
              message={
                errors?.service_management_billing?.service_billing_startdate
                  ?.message
              }
            />
          )}
          {!!errors?.service_management_billing?.service_billing_enddate && (
            <ErrorHelperText
              message={
                errors?.service_management_billing?.service_billing_enddate
                  ?.message
              }
            />
          )}
        </FormControl>
        {!(watch(`product_template`)?.match(/(Liquidation)/)) && <Fragment>
          <Typography sx={{ fontSize: 13, mb: 0.75 }} color="text.black">
            Chu Kỳ Gia Hạn *
          </Typography>
          <FormControl fullWidth>
            <DatePicker
              value={[
                dayjs(
                  watch(`service_management_billing.service_nextbill_startdate`)
                ),
                dayjs(
                  watch(`service_management_billing.service_nextbill_enddate`)
                ),
              ]}
              onChange={onChangeNextBilling}
            />
            {!!errors?.service_management_billing?.service_nextbill_startdate && (
              <ErrorHelperText
                message={
                  errors?.service_management_billing?.service_nextbill_startdate
                    ?.message
                }
              />
            )}
            {!!errors?.service_management_billing?.service_nextbill_enddate && (
              <ErrorHelperText
                message={
                  errors?.service_management_billing?.service_nextbill_enddate
                    ?.message
                }
              />
            )}
          </FormControl>
        </Fragment>}
        <Collapse
          in={
            !!watch(`product_template`) &&
            watch(`product_template`)?.match(
              /(SwiftFederation|VNIS|Colocation)/
            )
          }
        >
          <StatementOption />
        </Collapse>
      </Box>
    </Fragment>
  );
};

export default ServiceManagementBilling;
