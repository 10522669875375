import FORM_BASE from '@components/shared/FormCustomized/form';
import { FormControl } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const Prepaid = ({
    index
}) => {
    const { control } = useFormContext()

    return (
        <Controller
            name={`service_configoptions.[${index}].commitment.prepaid`}
            control={control}
            render={({ field: { value, onChange } }) => {
                return (
                    <FormControl fullWidth>
                        <FORM_BASE.SingleSelect
                            value={value}
                            onChange={onChange}
                            input={{
                                label: 'Hình Thức Thanh Toán *',
                                placeholder: 'Chọn hình thức thanh toán...',
                            }}
                            options={[{ value: true, label: 'Trả trước' }, { value: false, label: 'Trả sau' }]}
                        />
                    </FormControl>
                );
            }}
        />
    )
}

export default Prepaid