import BreadCrumb from "@/components/shared/BreadCrumb";
import withPermission from '@/hocs/withPermission';
import { Box, Typography } from '@mui/material';
import React from 'react';
import Form from '../shared/Form';

const INITIAL_ROLE_DATA = {
    website: "datacentral",
    feature: "account",
    actions: [
        {
            action_name: 'index',
            permission_pathname: '/account',
            module: "Account.List",
            is_outlet: false,
            prefix_outlet: ""
        }, {
            action_name: 'create',
            permission_pathname: '/account/new',
            module: "Account.Create",
            is_outlet: false,
            prefix_outlet: ""
        }, {
            action_name: 'update',
            permission_pathname: '/account/:account_id/update',
            module: "Account.Update",
            is_outlet: false,
            prefix_outlet: ""
        }, {
            action_name: 'delete',
            permission_pathname: '',
            module: "",
            is_outlet: false,
            prefix_outlet: ""
        }, {
            action_name: 'show',
            permission_pathname: '/account/:account_id',
            module: "Account.Detail",
            is_outlet: false,
            prefix_outlet: ""
        }
    ]
}

const Create = () => {
    return (
        <Box>
            <Typography sx={{
                fontSize: { xs: 18, md: 24 },
                fontWeight: 'medium'
            }}>
                Thêm Phân Quyền
            </Typography>
            <BreadCrumb
                data={[
                    { label: "Quản Lý Phân Quyền", reactLink: "/roles" },
                    { label: "Thêm Phân Quyền" }
                ]}
            />
            <Form defaultValues={INITIAL_ROLE_DATA} />
        </Box>

    )
}

export default withPermission(Create, { feature: 'permission', action: 'create' })