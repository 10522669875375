import { format_date_short, requestWithToken } from "@/functions";
import { API_SERVICE_URL } from "@/libs/constants";
import dayjs from "dayjs";
import qs from "qs";
import { useQuery } from "react-query";
export function dateToString(date) {
    return dayjs(date)?.format('YYYY-MM-DDTHH:mm:00[Z]')
}

async function resolveBandwidth(url = '') {
    const response = await requestWithToken(url)
    return {
        count: response?.data?.data?.count || 0,
        results: response?.data?.data?.results || [],
        totals: response?.data?.data?.totals || [],
        price_status: response?.data?.data?.price_status || ''
    }
}

export function resolveServiceGcoreAnalyticHistoric(service_uuid, query) {

    const current = qs.stringify({
        gte: format_date_short(query?.gte?.$d || query?.gte),
        lte: format_date_short(query?.lte?.$d || query?.lte),
        projects: query?.projects?.join(', '),
        reponse_format: query?.response_format
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery(["customer.services.gcore.usage", service_uuid, query], () => Promise.all([
        resolveBandwidth(`${API_SERVICE_URL}/viewers/services/${service_uuid}/analytic/gcores?${current}`),
    ]), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        staleTime: 30 * 60 * 1000,
        enabled: typeof service_uuid !== "undefined",
        suspense: true
    })
}

export function resolveServiceGcoreProjects(service_uuid) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery(["customer.services.gcore.projects", service_uuid], () => Promise.all([
        requestWithToken(`${API_SERVICE_URL}/viewers/services/${service_uuid}/analytic/gcores/projects`),
    ]), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        staleTime: 30 * 60 * 1000,
        enabled: typeof service_uuid !== "undefined",
        suspense: true
    })
}

export const serviceGcoreReportAnalytic = async (service_uuid = '', query) => {
    const queries = qs.stringify({
        gte: format_date_short(query?.gte?.$d || query?.gte),
        lte: format_date_short(query?.lte?.$d || query?.lte),
        projects: query?.projects?.join(', '),
        reponse_format: 'csv_records'
    });

    const response = await requestWithToken(`${API_SERVICE_URL}/viewers/services/${service_uuid}/analytics/gcores?${queries}`,
        'GET',
        {},
        {
            "Content-Type": "application/vnd.ms-excel",
            "CONTENT-DISPOSITION": "attachment",
        }, { withCredentials: true, responseType: "blob" })
    return response?.data;
}
