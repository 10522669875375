import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import { buildQueryFilterCondition } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { FormControl } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

const CustomerAutocomplete = ({
  name,
  value = "",
  onChange,
  disabled = false,
}) => {
  const { watch } = useFormContext();
  const [state, setState] = useState("");

  const debouncedSearchQuery = useDebounce(state, 400);

  const filterableCustomer = useCallback(() => {
    let fieldQueries = [];
    if (parseInt(watch(`${name}`)) > 0) {
      fieldQueries.push({
        name: "customers.customer_id",
        operator: "$eq",
        value: parseInt(watch(`${name}`)),
      });
    }

    if (!!debouncedSearchQuery && !(debouncedSearchQuery?.trim() === "")) {
      fieldQueries.push(
        ...[
          {
            name: "customers.customer_id",
            operator: "$eq",
            disabled: !parseInt(debouncedSearchQuery),
            value: parseInt(debouncedSearchQuery),
          },
          {
            name: "customers.customer_name",
            operator: "$contains",
            value: debouncedSearchQuery,
          },
          {
            name: "company_name",
            operator: "$contains",
            value: debouncedSearchQuery,
          },
          {
            name: "personal_informations.email",
            operator: "$contains",
            value: debouncedSearchQuery,
          },
        ]
      );
    }
    return buildQueryFilterCondition("$or", 0, fieldQueries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery, watch(`${name}`)]);

  const {
    isFetching,
    data: { customers },
  } = CustomerApi.List({
    page: 1,
    pageSize: 15,
    query: filterableCustomer(),
  });

  const rebuildCustomerSelections = useMemo(() => {
    if (!customers || customers?.length === 0) return [];

    return customers?.map((customer) => {
      const attrs = [
        customer?.customer_id,
        customer?.customer_type === "individual"
          ? ""
          : customer?.customer_company?.company_name,
        customer?.customer_name,
      ];

      let label = attrs.filter((x) => !!x).join(" - ");

      return {
        value: customer?.customer_id,
        label,
      };
    });
  }, [customers]);

  return (
    <FormControl fullWidth>
      <SingleSelectCustomize
        disabled={disabled}
        value={
          rebuildCustomerSelections?.find((x) => x?.value === value) || null
        }
        options={rebuildCustomerSelections}
        onChange={(event, newValue) => {
          onChange(newValue?.value || null);
        }}
        input={{
          label: "Khách hàng *",
          placeholder: "Nhập tên khách hàng..",
          value: state,
          onChange: (e) => setState(e?.target?.value || ""),
        }}
        sx={{
          "&.MuiAutocomplete-option": {
            textTransform: "uppercase",
          },
        }}
        loading={isFetching}
        mode="server"
      />
    </FormControl>
  );
};

export default withSuspense(CustomerAutocomplete, MuiSkeleton["TextField"]);
