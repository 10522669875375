import { CellStatus } from "@components/DataGrid/Cell"
import { Typography } from "@mui/material"
import { Link } from "react-router-dom"

const ContractUI = {
    Typography: ({ value, ...props }) => <Typography sx={{}}>{value}</Typography>,
    Link: ({ value, to }) => <Link to={to}>
        <Typography sx={{}} color="primary">
            {value}
        </Typography>
    </Link>,
    muiChip: ({ ...props }) => <CellStatus {...props} />
}
export default ContractUI