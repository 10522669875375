import { COUNTRIES, EMPLOYEE_NATIONS, RELIGIONS } from '@/libs/constants'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, FormControl, Grid, TextField, Typography } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const EmployeeNativeLand = () => {

    const { control, formState: { errors } } = useFormContext()
    return (
        <Box sx={{
            p: 1.5,
            border: '1px solid rgba(0, 0, 0, 0.18)',
            borderRadius: 1
        }}>
            <Typography sx={{
                fontSize: { xs: 16, md: 18 },
                fontWeight: 'medium',
                mb: 1
            }}>
                Quê Quán
            </Typography>
            <Grid container spacing={{ xs: 1.5, md: 2 }}>
                <Grid item xs={12}>
                    <Controller
                        name="employee_domicile"
                        rules={{ required: "Nguyên quán là bắt buộc" }}
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        value={value}
                                        label="Nguyên quán (*)"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={onChange}
                                        size="small"
                                        placeholder="Nguyên quán..."
                                        error={!!errors?.employee_domicile}
                                        helperText={
                                            errors?.employee_domicile?.message
                                        }
                                    />
                                </FormControl>
                            );
                        }} />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="employee_nationality"
                        control={control}
                        rules={{ required: "Quốc tịch là bắt buộc" }}
                        render={({ field: { value, onChange } }) => {
                            const selected = COUNTRIES.find(country => country.value === value) || null
                            return (
                                <FormControl fullWidth>
                                    <SingleSelectCustomize
                                        value={selected}
                                        options={_.uniqBy(COUNTRIES, item => item["value"])}
                                        input={{
                                            placeholder: "Chọn quốc tịch...",
                                            label: "Quốc tịch (*)"
                                        }}
                                        onChange={(event, newValue) => {
                                            onChange(newValue?.value || -1)
                                        }}
                                    />
                                    {!!errors?.employee_nationality && <ErrorHelperText
                                        message={errors?.employee_nationality?.message}
                                    />}
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
                    <Controller
                        name="employee_religion"
                        control={control}
                        rules={{ required: "Tôn giáo là bắt buộc" }}
                        render={({ field: { value, onChange } }) => {
                            const selected = RELIGIONS.find(religion => religion.value === value) || null
                            return (
                                <FormControl fullWidth>
                                    <SingleSelectCustomize
                                        value={selected}
                                        options={RELIGIONS}
                                        input={{
                                            placeholder: "Chọn tôn giáo...",
                                            label: "Tôn giáo (*)"
                                        }}
                                        onChange={(event, newValue) => {
                                            onChange(newValue?.value || -1)
                                        }}
                                    />
                                    {!!errors?.employee_religion && <ErrorHelperText
                                        message={errors?.employee_religion?.message}
                                    />}
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
                    <Controller
                        name="employee_nation"
                        control={control}
                        rules={{ required: "Dân tộc là bắt buộc" }}
                        render={({ field: { value, onChange } }) => {
                            const selected = EMPLOYEE_NATIONS.find(nation => nation.value === value) || null

                            return (
                                <FormControl fullWidth>
                                    <SingleSelectCustomize
                                        value={selected}
                                        options={EMPLOYEE_NATIONS}
                                        input={{
                                            placeholder: "Chọn dân tộc...",
                                            label: "Dân tộc (*)"
                                        }}
                                        onChange={(event, newValue) => {
                                            onChange(newValue?.value || -1)
                                        }}
                                    />
                                    {!!errors?.employee_religion && <ErrorHelperText
                                        message={errors?.employee_nation?.message}
                                    />}
                                </FormControl>
                            )
                        }} />
                </Grid>

            </Grid>
        </Box>
    )
}

export default EmployeeNativeLand