import ServiceApi from '@/apis/beta/Service/ServiceApi'
import { format_date_short } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import ModalConfirm from '@components/shared/ModalConfirm'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { Box, Drawer, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import ContractReferrer from './ContractReferrer'

const Contract = ({ ...props }) => {
    const { data } = ServiceApi.ContractReferrer({ serviceId: props?.service_id })

    const { mutateAsync: serviceContractGeneration } = useMutation(ServiceApi.GenerateContract)

    const [action, setAction] = useState('')

    const generateContract = async () => {
        const response = await serviceContractGeneration({
            serviceId: props?.service_id
        })
        if (!response || response?.errors) {
            return {
                status: 400,
                message: response?.errors?.[0]?.message || "Failed when generate contract.",
            };
        }
        return {
            status: 200,
            message: "Tạo mới hợp đồng thành công.",
        };
    }

    const onClose = () => setAction('')

    const renderComponents = (type) => {
        switch (type) {
            case "create-contract-references":
                return (
                    <Drawer open={true} anchor='right'>
                        <Box sx={{ p: 2, minWidth: { xs: '100%', md: 500 } }}>
                            <Typography sx={{
                                fontWeight: 'medium',
                                pb: 2
                            }}>
                                Liên Kết Hợp Đồng
                            </Typography>
                            <ContractReferrer serviceId={props?.service_id} onClose={onClose} />
                        </Box>
                    </Drawer>
                )
            case "generate-contract":
                return (
                    <ModalConfirm
                        dialog={{
                            title: "Xác nhận tạo mới hợp đồng cho dịch vụ này",
                            content: `Hệ thống sẽ tạo một hợp đồng mới cho dịch vụ này.`,
                        }}
                        handleSubmit={generateContract}
                        handleClose={onClose}
                    />
                );
            default:
                return null;
        }
    };

    if (!data) return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 0.5
        }}>
            {!!action && renderComponents(action)}
            <Typography color="primary" sx={{
                fontSize: 14,
                textDecoration: 'underline',
                cursor: 'pointer'
            }} onClick={() => setAction('create-contract-references')}>
                Đã có hợp đồng? Liên kết ngay...
            </Typography>
            <Typography color="text.grey" sx={{ fontSize: 14 }}>
                hoặc
            </Typography>
            <Typography color="primary" sx={{
                fontSize: 14,
                cursor: 'pointer'
            }} onClick={() => setAction('generate-contract')}>
                Tạo mới
            </Typography>
        </Box>
    )
    return (
        <Link to={`/customer_contracts/${data?.contract_id}`}>
            <Typography color="primary" sx={{ fontSize: 14 }}>
                {data?.contract_number_id}

                {(!!data?.effective_date && !!data?.expiry_date) &&
                    <Typography sx={{
                        display: 'inline-flex',
                        fontSize: 14,
                        ml: 0.75
                    }} color="text.grey">
                        {"("}{format_date_short(data?.effective_date)} {" "}
                        {format_date_short(data?.expiry_date)}{")"}
                    </Typography>}
            </Typography>
        </Link>
    )
}

export default withSuspense(Contract, MuiSkeleton["GradientCircularProgress"])