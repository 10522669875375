import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import ProductGroupApi from "@/apis/beta/Product/ProductGroupApi";
import withSuspense from "@/hocs/withSuspense";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { FormControl } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

const ServiceProductGroup = ({
  isPoc = false,
  customerId,
  onChangeProductGroup,
  disabled = true,
}) => {

  const [searchParams] = useSearchParams()
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const {
    data: { interestings },
  } = CustomerApi.ServiceInteresting({
    customerId,
    queries: {
      contact_id: searchParams?.get('contact_id'),
      journey_id: searchParams?.get('journey_id'),
    }
  });
  const { data } = ProductGroupApi.List(
    `filters[$and][0][product_group_id][$ne]=21${isPoc ? `&filters[$and][1][product_group_id][$ne]=20` : ""
    }`
    //
  );

  const handlerReset = () => {
    try {
      setValue("product_id", -1);
      // setValue("product_template", "Blank");
      setValue("product_name", "");
      setValue("product_billing_cycle", "");
      setValue("product_payment_type", "");
      setValue("product_paid", true);
      setValue("product_type", "");
      setValue("product_pricing_amount", 0);
      setValue("product_configoptions", []);
      setValue("product_informations", []);
      setValue("product_pricing_configures", {});
      setValue("product_billing_configures", []);
      setValue("product_addons", []);
    } catch (error) {
      return console.log(error?.message);
    }
  };

  const handleChange = (event, newValue) => {
    if (!onChangeProductGroup) return;
    try {
      onChangeProductGroup(newValue);
      handlerReset();
    } catch (error) {
      console.log(error?.message);
    }
  };

  return (
    <Controller
      control={control}
      name="product_group_id"
      render={({ field: { value, onChange } }) => {
        let selected =
          data?.find(
            (item) => parseInt(item?.product_group_id) === parseInt(value)
          ) || null;
        return (
          <FormControl fullWidth>
            <SingleSelectCustomize
              value={selected}
              disabled={disabled}
              options={data?.filter((x) =>
                interestings.includes(x?.product_group_name?.toUpperCase())
              )}
              onChange={(event, newValue) => {
                handleChange(event, newValue);
                setValue(
                  "product_group_name",
                  newValue?.product_group_name || ""
                );
                onChange(newValue?.product_group_id || -1);
              }}
              compareAttributes={["product_group_id", "product_group_name"]}
              formatCollection={{
                value: "product_group_id",
                label: "product_group_name",
              }}
              input={{
                label: "Nhóm dịch vụ *",
                placeholder: "Nhập tên nhóm sản phẩm/ dịch vụ tìm kiếm...",
              }}
            />
            {!!errors?.product_group_id && (
              <ErrorHelperText message={errors?.product_group_id?.message} />
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default withSuspense(ServiceProductGroup, MuiSkeleton["TextField"]);
