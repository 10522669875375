import React from 'react';
import { Box, LinearProgress, IconButton, Typography } from '@mui/material';
import {
  GridOverlay,
  DataGridPro,
  useGridApiContext,
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
  getDataGridUtilityClass,
} from '@mui/x-data-grid-pro';
import { unstable_composeClasses as composeClasses } from '@mui/utils';
import { useGridRootProps } from '@mui/x-data-grid';

export const { default: Toolbar } = require('./Toolbar');

const useUtilityClasses = (ownerState) => {
  const { classes } = ownerState;
  const slots = {
    root: ['treeDataGroupingCell'],
    toggle: ['treeDataGroupingCellToggle'],
  };
  return composeClasses(slots, getDataGridUtilityClass, classes);
};

export const GridTreeDataGroupingCell = ({
  id,
  field,
  rowNode,
  total,
  ...props
}) => {
  const rootProps = useGridRootProps();
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );


  
  const ownerState = {
    classes: rootProps.classes,
  };

  const classes = useUtilityClasses(ownerState);

  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;


  const Icon = rowNode.childrenExpanded
    ? rootProps.components.TreeDataCollapseIcon
    : rootProps.components.TreeDataExpandIcon;


  const handleClick = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Box className={classes.root} sx={{ ml: rowNode.depth * 3 }}>
      <Box className={classes.toggle}>
        {filteredDescendantCount > 0 ? (
          <>
            <IconButton
              size='small'
              onClick={handleClick}
              tabIndex={-1}
              aria-label={
                rowNode.childrenExpanded
                  ? apiRef.current?.getLocaleText('treeDataCollapse')
                  : apiRef.current?.getLocaleText('treeDataExpand')
              }
              sx={{ mr: 1 }}
            >   
                <Icon fontSize='inherit' />
            </IconButton>
            <Typography
              component='span'
              onClick={handleClick}
              sx={{ fontSize: 13, cursor: 'pointer' }}
            >
              {rowNode.groupingKey} ({total})
            </Typography>
          </>
        ) : (
          <Typography component='span' sx={{ fontSize: 13, ml: 1.5 }}>
            {rowNode.groupingKey}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export const LoadingOverlay = () => {
  return (
    <GridOverlay>
      <Box style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </Box>
    </GridOverlay>
  );
};

export default DataGridPro;
