import React from 'react';
import { Typography } from '@mui/material';

const Accountant = () => {
	return (
		<Typography sx={{ fontSize: 14, color: 'grey.700' }}>
			Dashboard.Accountant
		</Typography>
	);
};

export default Accountant;
