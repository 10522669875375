import { Box, Typography } from '@mui/material'
import React from 'react'

const Assignment = ({ message }) => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: "center"
        }}>
            <Typography sx={{
                fontSize: 13,
                m: 'auto 0',
                fontWeight: "medium"
            }}>
                {message?.type}
            </Typography>
        </Box>
    )
}

export default Assignment