import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { queryClientRefetchScope } from "@/functions";
import withPermission from "@/hocs/withPermission";
import { useNotificationContext } from "@contexts/notification";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import ServicePrivateMetadata from "./PrivateMetedata";

const ServiceMetadata = ({ service }) => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();
  const { mutateAsync, isLoading } = useMutation(ServiceApi.Update);
  const methods = useForm({
    defaultValues: useMemo(() => {
      return {
        ...(_.pick(service?.metadata, [
          "domain",
          "ip",
          "name",
          "os",
          "username",
          "uid",
          "idc",
        ])),
        provider: service?.metadata?.fields?.provider
      };
    }, [service]),
  });
  const { formState, handleSubmit, control } = methods
  const onSubmit = async (values) => {
    let form = {
      service_extra_fields: {
        ...service?.service_extra_fields,
        ...values,
      },
    };
    const response = await mutateAsync({
      serviceId: service?.service_id,
      data: form,
    });
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }
    queryClientRefetchScope(queryClient, "service");

    return setNotification({
      open: true,
      message: "Cập nhật thông tin cấu hình dịch vụ thành công.",
      severity: "success",
    });
  };


  return (
    <FormProvider {...methods}>
      <Typography
        sx={{ fontSize: { xs: 14, md: 16 }, fontWeight: "medium", mt: 1.5 }}
        color="primary"
      >
        Thông Tin Khác (Technical)
      </Typography>
      <Paper
        sx={{
          p: "16px 24px",
          mt: 1,
          pointerEvents: service?.service_status === 'cancel' ? 'none' : 'auto'
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {service?.service_status === 'cancel' && <Typography sx={{
          fontSize: 14,
          color: "#ef5350", mb: 2
        }}>
          Dịch vụ đã ngưng sử dụng, bạn không thể thao tác trên dịch vụ đã ngưng sử dụng.
        </Typography>}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 1.5,
            mb: 1.5
          }}
        >
          <Controller
            name="domain"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  size="small"
                  label="Domain"
                  InputLabelProps={{ shrink: true }}
                  {...field}
                  placeholder="Nhập tên miền..."
                />
              </FormControl>
            )}
          />
          <Controller
            name="ip"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  size="small"
                  label="IP"
                  InputLabelProps={{ shrink: true }}
                  {...field}
                  placeholder="Nhập ip..."
                />
              </FormControl>
            )}
          />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  size="small"
                  label="Tên Portal"
                  disabled={
                    service?.service_creatable_type !== "ConversantService"
                  }
                  InputLabelProps={{ shrink: true }}
                  {...field}
                  placeholder="Nhập tên portal..."
                />
              </FormControl>
            )}
          />
        </Box>
        <Grid container spacing={2} sx={{ mb: 1.5 }}>
          <Grid item xs={12} md={4}>
            <Controller
              name="uid"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <TextField
                    disabled={service?.service_creatable_type !== "VnisService"}
                    size="small"
                    label="Customer ID (for VNIS)*"
                    InputLabelProps={{ shrink: true }}
                    {...field}
                    placeholder="Nhập customer id..."
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="Username"
                    InputLabelProps={{ shrink: true }}
                    {...field}
                    placeholder="Username..."
                  />
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        {!!service?.service_creatable_type
          && React.createElement(ServicePrivateMetadata[service?.service_creatable_type] || ServicePrivateMetadata["Default"])}
        {
          <Collapse in={formState?.isDirty}>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "flex-end",
                gap: 2,
              }}
            >
              <Button size="small" variant="outlined" disabled={isLoading}>
                Huỷ
              </Button>
              <Button
                size="small"
                variant="contained"
                disabled={isLoading}
                type="submit"
                startIcon={
                  isLoading && (
                    <CircularProgress size={14} sx={{ color: "#777680" }} />
                  )
                }
              >
                Lưu Thay Đổi
              </Button>
            </Box>
          </Collapse>
        }
      </Paper>
    </FormProvider>
  );
};

export default withPermission(ServiceMetadata, {
  feature: "service",
  action: "technical",
  type: "Block",
});
