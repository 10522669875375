import { format_numeral_price } from '@/functions'
import { PRODUCT_UNITS, SERVICE_PERIODS } from '@/libs/constants'
import PopperAction from '@components/PopperAction'
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined'
import { Box, IconButton, Popover, Typography } from '@mui/material'
import { indigo } from '@mui/material/colors'
import React from 'react'

const AddonOne = ({
    data = {},
    remover,
    updater
}) => {

    const [INITIAL_ACTIONS] = React.useState([
        {
            order: 1,
            icon: "Edit",
            label: "Chỉnh Sửa",
            handleClick: (values) => {
                try {
                    updater({ open: true, data: values, type: "update" })
                } catch (error) {
                    console.log('failed in remove')
                }
                clear()
            }
        }, {
            order: 2,
            icon: "Delete",
            label: "Xoá",
            handleClick: () => {
                try {
                    remover()
                } catch (error) {
                    console.log('failed in remove')
                }
                clear()
            }
        },
    ])
    const [anchorEl, setAnchorEl] = React.useState({
        ref: null,
    })

    const clear = () => setAnchorEl({ ref: null })
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: "column", md: 'row' },
            gap: 1.5,
            border: '1px solid rgba(0, 0, 0, 0.38)',
            p: 2,
            mb: 2,
            ":hover": {
                border: "1px solid #000099"
            }
        }}>
            <Box sx={{ width: '100%' }}>
                <Typography component="div" sx={{ fontSize: 14, fontWeight: '500', mb: 0.5 }}>
                    {data?.product_name}
                    <Typography sx={{ display: 'inline-block', m: 'auto 4px', fontSize: 13, color: "#777680" }}>
                        ( {SERVICE_PERIODS.find(item => item.value === data.product_billing)?.label} )
                    </Typography>
                </Typography>
                <Typography component="div" sx={{ fontSize: 14, mb: 0.5, mr: 0.5, color: "#777680" }}>
                    Đơn giá:
                    <Typography sx={{
                        display: 'inline-block', m: 'auto 0 auto 4px', fontSize: 13, color: "#777680",
                        textDecoration: "underline"
                    }}>
                        đ
                    </Typography>
                    <Typography sx={{ display: 'inline-block', m: 'auto 0', fontSize: 13, color: "#777680" }}>
                        {format_numeral_price(data.product_pricing_configures?.default?.pricing)}/
                    </Typography>
                    <Typography sx={{ display: 'inline-block', m: 'auto 0 auto 4px', fontSize: 13, color: "#777680" }}>
                        {PRODUCT_UNITS.find(item => item.value === data.product_pricing_configures.product_pricing_unit)?.label}
                    </Typography>
                </Typography>
            </Box>
            <IconButton size='small' sx={{ height: 40, width: 40, minWidth: 40 }}
                onClick={(event) => setAnchorEl({ ref: event?.currentTarget })}
            >
                <MoreVertOutlined fontSize='small' sx={{
                    ":hover": {
                        color: indigo[900]
                    }
                }} />
            </IconButton>
            {!!anchorEl.ref && <Popover open={true} anchorEl={anchorEl.ref}>
                <PopperAction actions={[...INITIAL_ACTIONS]} handleClose={clear} data={data} />
            </Popover>}
        </Box>
    )
}

export default AddonOne