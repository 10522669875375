import { encodeBase64, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class DayoffApi {
    List = ({
        query = null,
        searchQuery = null,
        page = 1,
        pageSize = 20
    }) => {
        return useQueryWithCustomized(
            ["all_day_offs.list", query, searchQuery, page, pageSize],
            async () => {
                return await requestWithToken(
                    `${API_V3_URL}/employees/day_offs?${query ? `&filters=${encodeBase64(query)}` : ''}${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ''}&page=${page}&page_size=${pageSize}`
                )
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data;
                    })
                    .catch((error) => {
                        return error.response;
                    })
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
                enabled: !!localStorage.getItem("SA"),
            }
        );
    }

    Detail = (id) => {
        return useQueryWithCustomized(
            ["day_off.detail", id],
            async () => {
                return await requestWithToken(
                    `${API_V3_URL}/employees/day_offs/${id}`
                )
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data?.day_off;
                    })
                    .catch((error) => {
                        return error.response;
                    })
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
                enabled: !!localStorage.getItem("SA"),
            }
        );
    }

    Create = async (data) => {
        return await requestWithToken(`${API_V3_URL}/day_offs`, "POST", {
            day_off: data
        }, {}, {})
            .then(({ data }) => {
                if (data.error) return data;
                return data
            })
            .catch((error) => {
                return error?.response
            })
    }

    Update = async (id, data) => {
        return await requestWithToken(`${API_V3_URL}/day_offs/${id}`, "PUT", {
            day_off: data
        }, {}, {})
            .then(({ data }) => {
                if (data.error) return data;
                return data
            })
            .catch((error) => {
                return error?.response
            })
    }

    Cancel = async (id, feedback) => {
        return await requestWithToken(`${API_V3_URL}/day_offs/${id}/cancel`, "PUT", {
            feedback: feedback
        }, {}, {})
            .then(({ data }) => {
                if (data.error) return data;
                return data
            })
            .catch((error) => {
                return error?.response
            })
    }

    Approve = async ({ id = -1, data = {} }) => {
        return await requestWithToken(`${API_V3_URL}/day_offs/${id}/approve`, "PUT", {
            day_off: data
        }, {}, {})
            .then(({ data }) => {
                if (data.error) return data;
                return data
            })
            .catch((error) => {
                return error?.response
            })
    }

    CurrentRoles = () => {
        return useQueryWithCustomized(
            ["current_role.day_off_role"],
            async () => {
                return await requestWithToken(
                    `${API_V3_URL}/day_offs/current_roles`
                )
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return {
                            leaders: data?.data?.leaders || [],
                            master_inspectors: data?.data?.master_inspectors || []
                        }
                    })
                    .catch((error) => {
                        return error.response;
                    })
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
                enabled: !!localStorage.getItem("SA"),
            }
        );
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new DayoffApi();
