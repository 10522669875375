import { UNLIMITED } from "@/libs/constants";
import NumericFormatCustom from "@components/NumericFormatCustom";
import { Box, TextField, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import React from "react";

const InputUnlimitCustomized = ({ value, onChange, ...props }) => {

    const isUnlimited = parseInt(value) >= UNLIMITED
    return (
        <TextField
            value={value}
            onChange={onChange}
            InputLabelProps={{
                shrink: true
            }}
            size="small"
            InputProps={{
                sx: { pr: 0 },
                inputComponent: NumericFormatCustom,
                endAdornment: <Box sx={{
                    display: 'flex',
                    height: '40px',
                    bgcolor: "rgba(0, 0, 0, 0.18)",
                    pr: '10px',
                    pl: '10px',
                    alignItems: 'center',
                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                    cursor: "pointer",
                    transition: "width 0.75s",
                    width: {
                        xs: isUnlimited ? "calc(100%)" : "calc(100% / 3)",
                        md: isUnlimited ? "calc(100%)" : "calc(100% / 4)",
                    },
                }}
                    onClick={() => {
                        if (isUnlimited) {
                            return onChange(0);
                        }
                        return onChange(UNLIMITED);
                    }}>
                    <Typography sx={{
                        color: isUnlimited ? green[800] : "#00001F",
                        fontSize: 12,
                        fontWeight: 'medium',
                        m: 'auto 0 auto auto'
                    }}>UNLIMITED</Typography>
                </Box>
            }}
            {...props}

        />
    )
}
export default InputUnlimitCustomized