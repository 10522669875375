import { useNotificationContext } from "@contexts/notification";
import { useMutation } from "react-query";

const useMutationWithNotification = (handler, mutationHookProps) => {
  const { setNotification } = useNotificationContext();

  const notify = (type, message = "Thần gọi hàm này nó méo truyền message") => {
    setNotification({
      open: true,
      severity: type || "error",
      message: message,
    });
  };

  const notifyError = (error) => {
    setNotification({
      open: true,
      message:
        error?.errors?.[0]?.message ||
        error?.response?.data?.errors?.[0]?.message ||
        "Something went wrong",
      severity: "error",
    });
  };

  const mutate = useMutation(handler, {
    onError: (error) => {
      notifyError(error);
    },
    onSuccess: (response) => {
      if (response?.errors) notifyError(response);
    },
    ...mutationHookProps,
  });

  return {
    ...mutate,
    notify,
  };
};

export default useMutationWithNotification;
