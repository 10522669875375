import DepartmentApi from '@/apis/beta/Employee/DepartmentApi'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import TransitionGroups from '@components/shared/TranstionGroups'
import FamilyRestroomOutlinedIcon from '@mui/icons-material/FamilyRestroomOutlined'
import { Box, Typography } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

const Dept = () => {
    const { control } = useFormContext()
    const { data: { departments } } = DepartmentApi.ListDepartments()

    return (
        <>
            <Typography sx={{ fontSize: 14, fontWeight: 'medium' }} color="text.black">
                Truy Xuất Dữ Liệu Theo Phòng Ban
            </Typography>
            <Typography sx={{ fontSize: 13 }} color="text.grey">
                Người được chỉ định sẽ được truy cập tệp thông tin của các nhân viên được chọn dưới đây.
            </Typography>
            <Box aria-label='scopes.department.dept'>
                <Controller
                    name="scope_metadata.departments"
                    control={control}
                    render={({ field: { value = [], onChange } }) => {
                        return (
                            <TransitionGroups
                                values={departments?.filter(department => value?.includes(department?.department_id))}
                                onDelete={(e) => onChange([...value?.filter(x => x !== e?.department_id)])}
                                label={'department_name'}
                                icon={<FamilyRestroomOutlinedIcon fontSize='small' color="primary" />}
                            >
                                <SingleSelectCustomize
                                    clearOnBlur
                                    options={[...departments?.filter(department => !value?.includes(department?.department_id))]}
                                    value={null}
                                    formatCollection={{ label: 'department_name', value: 'department_id' }}
                                    compareAttributes={['department_name']}
                                    input={{
                                        placeholder: "Chọn phòng ban..."
                                    }}
                                    onChange={(_, newValue) => {
                                        if (!newValue?.department_id) return;
                                        onChange([
                                            ...value?.filter(departmentId => !!parseInt(departmentId)),
                                            newValue?.department_id
                                        ])
                                    }}
                                />
                            </TransitionGroups>
                        )
                    }}
                />
            </Box>

        </>
    )
}

export default withSuspense(Dept, MuiSkeleton["TextField"])