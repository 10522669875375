import isEmail from "validator/lib/isEmail";
import { create, enforce, test } from "vest";

enforce.extend({ isEmail });

const customerMarketingValidator = create((data = {}) => {
  test("contact_source_name", "Tên khách hàng không được để trống.", () => {
    enforce(data?.contact_source_name).isNotEmpty();
  });

  test("contact_source_email", "Email khách hàng không được để trống.", () => {
    enforce(data?.contact_source_email).isNotEmpty();
  });

  test("contact_source_email", "Email khách hàng không hợp lệ.", () => {
    enforce(data?.contact_source_email).isEmail();
  });

  test("contact_source_position", "Chức vụ được để trống.", () => {
    enforce(data?.contact_source_position).isNotEmpty();
  });
  test("contact_source_phone", "Số điện thoại không được để trống.", () => {
    enforce(data?.contact_source_phone).isNotEmpty();
  });

  test("contact_source_channel", "Vui lòng chọn kênh liên hệ.", () => {
    enforce(data?.contact_source_channel).isNotEmpty();
  });
  test("contact_source_interested_services", "Vui lòng chọn giải pháp.", () => {
    enforce(data?.contact_source_interested_services).isNotEmpty();
  });
  test("contact_source_industry", "Vui lòng chọn kênh liên hệ.", () => {
    enforce(data?.contact_source_industry).isNotEmpty();
  });
});

export default customerMarketingValidator;
