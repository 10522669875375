import { requestWithToken, encodeBase64 } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_BETA_URL, API_V3_URL } from "@/libs/constants";
import _ from "lodash";

class ReportApi {
  InvoiceIncomes = ({ query, gte, lte, searchQuery }) => {
    return useQueryWithCustomized(
      ["reports.invoice.incomes", query, gte, lte],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/reports/invoices/incomes?gte=${gte}&lte=${lte}${
            !!query && `&filters=${encodeBase64(query)}`
          }`
        )
          .then(({ data }) => {
            if (data?.errors) return data;

            const invoices = data?.data?.invoices;
            return {
              invoices: invoices,
              employees: data?.data?.employees || [],
              pagination: data?.data?.pagination || {},
            };
          })
          .catch((error) => error.response);
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };
  MonthlyRenewable = (month) =>
    useQueryWithCustomized(
      ["reports.MonthlyRenewable", month],
      async () => {
        return await requestWithToken(
          `${API_BETA_URL}/reports/templates/incomes`
        )
          .then(({ data }) => {
            if (data?.errors) return data;

            let rows = _.flatten(data?.data?.quaters);
            rows = _.map(data?.data?.quaters, (item, index) => {
              return {
                id: index,
                hierarchy: [item.quarter, item.month, item.name],
                ...item,
              };
            });

            return {
              quaters: data?.data?.quaters
                ? data?.data?.quaters.map((invoice, index) => ({
                    ...invoice,
                    id: index,
                  }))
                : [],
              rows: rows,
            };
          })
          .catch((error) => error.response);
      },
      {
        staleTime: 10 * 60 * 1000,
        suspense: true,
      }
    );

  MonthlyServiceRenewable = (month) =>
    useQueryWithCustomized(
      ["reports.MonthlyServiceRenewable", month],
      async () => {
        return await requestWithToken(
          `${API_BETA_URL}/reports/templates/services`
        )
          .then(({ data }) => {
            if (data?.errors) return data;

            let rows = _.flatten(data?.data?.quaters);

            rows = _.map(data?.data?.quaters, (item, index) => {
              return {
                id: index,
                hierarchy: [item.quarter, item.month, item.name],
                ...item,
              };
            });

            return {
              quaters: data?.data?.quaters
                ? data?.data?.quaters.map((service, index) => ({
                    ...service,
                    id: index,
                  }))
                : [],
              rows: rows,
            };
          })
          .catch((error) => error.response);
      },
      {
        staleTime: 10 * 60 * 1000,
        suspense: true,
      }
    );
}
export const rowItemsResolver = (rows, key, rowNode) =>
  rows?.filter((item) => item[key] === rowNode.groupingKey);

export default ReportApi = new ReportApi();
