import { requestWithToken } from "@/functions";
import { API_V3_URL } from "@/libs/constants";
import qs from "query-string";

class DocumentApi {
  CreateShareDocument = async ({ values }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/document`,
      "POST",
      values
    );
    return response.data;
  };

  GetAllMyDocument = async (values) => {
    const response = await requestWithToken(
      `${API_V3_URL}/document?${qs.stringify(values)}`,
      "GET"
    );
    return response.data;
  };

  GetAllShareDocument = async (values) => {
    const response = await requestWithToken(
      `${API_V3_URL}/document/share?${qs.stringify(values)}`,
      "GET"
    );
    return response.data;
  };

  DestroyShareDocument = async ({ id }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/document`,
      "DELETE",
      { id }
    );
    return response.data;
  };

  FindOneShareDocument = async (id) => {
    if (!id) return null;
    const response = await requestWithToken(
      `${API_V3_URL}/document/${id}`,
      "GET"
    );
    return response.data;
  };

  UpdateShareDocument = async ({ values }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/document/${values?.id}`,
      "PUT",
      { ...values }
    );
    return response.data;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new DocumentApi();
