import CustomerApi from '@/apis/beta/Customer/CustomerApi'
import { csvDownload, csvMaker, dayjsCompare, format_date_short, isObjEmpty } from '@/functions'
import { CUSTOMER_JOURNEYS } from '@/libs/constants'
import { Popover } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import ButtonBaseExport, { ToolExport } from '../shared'
const Customer = () => {

    const [state, setState] = React.useState([dayjs("05/01/2023"), dayjs("05/31/2023")])

    const ref = React.useRef()

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handlerClick = (e) => {
        if (!anchorEl) return setAnchorEl(e?.currentTarget)
        return setAnchorEl(null)
    }

    const getdataCustomer = async () => {
        return await CustomerApi.Report({
            type: "potential"
        })
    }

    const handlerFomatterJourneys = (data) => {

        let starting = dayjs(state[0]).startOf("w").add(1, 'd')
        let end = dayjs(state[1]).endOf("w").add(1, 'd')

        let expected = 0 // 
        let obj = {}
        while (dayjsCompare(end, starting) > 0 && expected <= 20) {
            // eslint-disable-next-line no-loop-func
            const index = data.findIndex(item => dayjsCompare(starting, item["c_journey_week_started_at"]) === 0)

            const objKey = [starting.format('DD/MM/YYYY'), dayjs(starting).add(6, 'd').format('DD/MM/YYYY')].join(" - ")

            let values = ``
            if (index > -1) {
                values = CUSTOMER_JOURNEYS.find(j => j.value === data[index]['c_journey_week_step'])?.label?.replaceAll(",", "")
            }
            obj[objKey] = values
            starting = dayjs(starting).add(7, 'd')
            expected += 1
        }
        return obj
    }

    const csvFormatterCustomer = async () => {
        let starting = dayjs(state[0]).startOf("w").add(1, 'd')
        let end = dayjs(state[1]).endOf("w").add(1, 'd')

        let timer = localStorage.getItem('csv.customers.reports.timer')
        let metadata = localStorage.getItem('csv.customers.reports.data')
        if (timer && metadata && timer === JSON.stringify([starting, end].join(","))) {
            const csvRows = csvMaker(JSON.parse(metadata))
            return csvDownload(csvRows)
        }
        let customers = JSON.parse(localStorage.getItem('customers.reports.data') || `{}`)
        if (!customers || isObjEmpty(customers)) {
            const data = await getdataCustomer()
            try {
                localStorage.setItem('customers.reports.data', JSON.stringify(data.customers))
                customers = data.customers
            } catch (error) {
                return alert(error.message)
            }
        }
        const results = customers?.slice(1, 2).map((customer, index) => {
            return ({
                "No.": index + 1,
                "ID": customer.customer_id,
                "Tên Khách Hàng": customer.customer_name,
                "Lĩnh Vực": customer.customer_industry_temp,
                "Sản Phẩm/ Dịch Vụ Quan Tâm": customer.customer_service_interesting_temp,
                "Nhân Viên Theo Dõi": customer?.employee?.map((employee) => employee?.employee_name).join("/t"),
                "Khách hàng đang dùng nhà cung cấp nào?": customer?.meeting?.meeting_provider_explaination,
                "Budget/ Month": customer?.meeting?.meeting_budget_explaination,
                "Người đại diện": "QQQ",
                "Vấn đề/ Nỗi đau": customer?.meeting?.meeting_problem_explaination,
                "Mong đợi": customer?.meeting?.meeting_success_explanation,
                "Thời gian bán hàng dự tính": format_date_short(customer.saleplan?.saleplan_expected_date),

                // services: customer?.services?.map((service) => service.service_name).join(", "),
                ...(handlerFomatterJourneys(customer.customer_journey_week_follows))

            })
        })
        if (results && results.length !== 0) {
            try {
                localStorage.setItem('csv.customers.reports.data', JSON.stringify(results))

                localStorage.setItem('csv.customers.reports.timer', JSON.stringify([starting, end].join(",")))

                localStorage.removeItem("customers.reports.data")

                const csvRows = csvMaker(results)
                return csvDownload(csvRows)

            } catch (error) {
                alert(error.message)
            }
        }
    }

    return (
        <>
            <ButtonBaseExport handler={handlerClick} />
            <Popover ref={ref} open={Boolean(anchorEl)} anchorEl={anchorEl}
                sx={{ inset: "36px 0" }}
                onClose={() => setAnchorEl(null)}
            >
                <ToolExport
                    value={state}
                    handler={setState}
                    handlerSubmit={csvFormatterCustomer}
                    handleClose={() => setAnchorEl(null)}
                />
            </Popover>
        </>
    )
}

export default Customer