import DatePicker from '@components/datepickers/DateRangPicker'
import { FormControl } from '@mui/material'
import dayjs from 'dayjs'
import { useFormContext } from 'react-hook-form'

const ServiceNextTime = () => {
    const { setValue, watch } = useFormContext()
    return (
        <FormControl fullWidth>
            <DatePicker
                value={[dayjs(watch(`service_nextbill_startdate`)), dayjs(watch(`service_nextbill_enddate`))]}
                handler={(newDates) => {
                    setValue(`service_nextbill_startdate`, newDates[0])
                    setValue(`service_nextbill_enddate`, newDates[1])
                }}
                localeText={{
                    start: "Thời Gian Thanh Toán Tiếp Theo",
                    end: 'Ngày Kết Thúc'
                }}
            />
        </FormControl>
    )
}

export default ServiceNextTime