import ProductApi from "@/apis/beta/Product/ProductApi";
import { queryClientRefetcher } from "@/functions";
import { useNotificationContext } from "@contexts/notification";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

const Addon = ({ onClose, data }) => {
  const { setNotification } = useNotificationContext();
  const queryClient = useQueryClient();
  const { product_id: productId } = useParams();
  const [submit, setSubmit] = useState(false);
  const onSubmit = async () => {
    setSubmit(true);
    const response = await ProductApi.RemoveAddon({
      productId,
      addon_id: data?.product_addon_id,
    });
    setSubmit(false);
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message || "Failed!",
        severity: "error",
      });
    }
    queryClientRefetcher(queryClient, ["product.addons"]);
    setNotification({
      open: true,
      message: `Xoá thành công.`,
      severity: "success",
    });
    return onClose();
  };
  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogTitle>
        Xoá Addon - [{data?.product_addon_id} - {data?.product_name}]
      </DialogTitle>
      <DialogContent>
        <Typography>
          Sản phẩm addon này sẽ bị xoá khỏi danh sách sản phẩm dịch vụ addon cho
          dịch vụ hiện tại. Thao tác không thể khôi phục, bạn có muốn thực hiện?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          disabled={submit}
          variant="outlined"
          onClick={onClose}
        >
          Huỷ Thao Tác
        </Button>
        <Button
          size="small"
          disabled={submit}
          variant="contained"
          onClick={onSubmit}
          startIcon={
            submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
          }
        >
          Xác Nhận & Huỷ
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Addon;
