import withSuspense from "@/hocs/withSuspense";
import List from "../Shared/List";
import DocumentApi from "@/apis/beta/Document/DocumentApi";
import withPermission from "@/hocs/withPermission";
import MuiSkeleton from "@components/shared/MuiSkeleton";

const Home = () => {
  return (
    <List
      fetchFn={DocumentApi?.GetAllMyDocument}
      name="my-document"
      title="Tài liệu bạn đã chia sẻ"
      showButtonAction
    />
  );
};

export default withSuspense(
  withPermission(Home, {
    feature: "document",
    action: "index",
  }),
  MuiSkeleton["DataGrid"]
);
