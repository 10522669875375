import DatePicker from '@/components/DatePicker'
import { AddOutlined, DeleteOutlineOutlined } from '@mui/icons-material'
import { Box, Button, FormControl, Grid, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import ButtonGroupDate from './GroupButtons'

const Timer = () => {
    const { control } = useFormContext()
    const { fields, append, remove } = useFieldArray({
        control: control,
        name: "metadata.days"
    })
    const days = useWatch({
        control: control,
        name: "metadata.days"
    })

    const handleAppendDay = () => {
        if (days.length === 0) return append({
            timestamp: moment(),
            business_option: 2
        })
        const newDate = moment(days?.[days?.length - 1]?.timestamp?.["$d"]).add(1, 'd')
        return append({
            timestamp: newDate,
            business_option: 2
        })
    }
    return (
        <React.Fragment>
            <Grid item xs={12} md={3.5}>
                <Typography>Thời Gian</Typography>
            </Grid>
            <Grid item xs={12} md={8.5}>
                <Box sx={{
                    p: 2,
                    border: 1,
                    borderRadius: '4px',
                    borderColor: "#9e9e9e"
                }}>
                    {fields?.map((item, index) => {
                        return (
                            <Box key={item?.id} sx={{
                                mb: 1,
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                gap: { xs: 1.5, md: 2 },
                                justifyContent: 'space-between',
                                borderBottom: { xs: "1px solid rgba(0, 0, 0, 0.18)", md: "none" }
                            }}>
                                <Box sx={{ width: '100%' }}>
                                    <Controller
                                        name={`metadata.days.[${index}].timestamp`}
                                        control={control}
                                        render={({ field: { value, onChange } }) =>
                                            <FormControl fullWidth>
                                                <DatePicker value={value} onChange={onChange} />
                                            </FormControl>}
                                    />
                                </Box>
                                <Box sx={{
                                    minWidth: { xs: "100%", md: '50%' },
                                    width: { xs: "100%", md: '50%' },
                                    display: "flex",
                                    justifyContent: 'flex-end',
                                    flexDirection: 'row',
                                    gap: { xs: 1, md: 2 }
                                }}>
                                    <Controller
                                        name={`metadata.days.[${index}].business_option`}
                                        control={control}
                                        render={({ field: { value, onChange } }) =>
                                            <FormControl fullWidth>
                                                <ButtonGroupDate value={value} onChange={onChange} />
                                            </FormControl>}
                                    />
                                    <IconButton size="small"
                                        onClick={() => remove(index)}
                                        sx={{
                                            height: 40, width: 40,
                                            m: 'auto 0',
                                        }}>
                                        <DeleteOutlineOutlined sx={{
                                            fontSize: 20,
                                            ":hover": {
                                                color: "#ef5350"
                                            }
                                        }} />
                                    </IconButton>
                                </Box>
                            </Box>
                        )
                    })}
                    <Button startIcon={<AddOutlined />}
                        variant="contained"
                        disableElevation
                        sx={{ height: 36, width: 150, mt: 2 }}
                        onClick={handleAppendDay}
                        size="small">Thêm Ngày</Button>
                </Box>
            </Grid>
        </React.Fragment>
    )
}

export default Timer