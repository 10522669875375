import { Typography } from '@mui/material'
import React from 'react'

const DayoffTitle = ({ type }) => {
    return (
        <Typography sx={{
            textTransform: "uppercase",
            fontWeight: 'medium'
        }}>
            {type === "create" ? "Tạo Phiếu Nghỉ Phép" : type === "cancel" ? "Huỷ Phiếu Nghỉ" : "Cập nhật phiếu nghỉ"}
        </Typography>
    )
}

export default DayoffTitle