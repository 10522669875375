import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ReportWatchApi from '@/apis/beta/Threshold/Report/ReportWatchApi';
import { useNotificationContext } from '@contexts/notification';

export default function DialogConfirmChangeStatus(props) {
  const { setNotification } = useNotificationContext();
  const handleClose = () => {
    props.handleClose();
  };
  const handleAccept = async() => {
    const { report_uuid, report_status } = props 

    if (!report_status || !report_uuid) {
      return setNotification({ open: true, message: "Không tìm thấy dữ liệu để thao tác", severity: 'error' })
    }
    const nextStatus = report_status === "active" ? "inactive" : "active"
    const message = nextStatus === "active" ? "Đăng ký" : "Huỷ đăng ký"
    await ReportWatchApi.UpdateReport(report_uuid, {
      report: {
        report_status: nextStatus
      }
    })
      .then((res) => {
        if (res?.data?.report) {
          if (props.refetch !== undefined) {
            props.refetch();
          }
          return setNotification({
            open: true,
            message: `${message} báo cáo thành công`,
            severity: "success",
          })
        }
        return setNotification({
          open: true,
          message: res?.errors?.[0]?.message || `Thực hiện ${message} thất bại`,
          severity: "error",
        })
      })
      .catch((error) => {
        return setNotification({
          open: true,
          message: error?.response?.data?.errors[0]?.message || `Thực hiện ${message} thất bại`,
          severity: "error",
        })
      })
    return handleClose()
  }
  
    return (
    <React.Fragment>
      <Dialog
        open={props?.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        {props?.report_status === 'active' ? "Hủy đăng ký báo cáo" : "Đăng ký báo cáo"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bạn có chắc muốn <strong>{props?.report_status === 'active' ? "hủy đăng ký báo cáo" : "đăng ký báo cáo"}</strong> theo định kỳ cho khách hàng <strong>{props?.report_name}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Hủy</Button>
          <Button onClick={() => handleAccept()} autoFocus>
            Đồng ý
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}