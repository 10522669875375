import ContactApi from "@/apis/beta/Customer/ContactApi";
import { format_date_time } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import { CONTACT_CHANNEL } from "@/libs/constants";
import DataGridCustomize from "@components/DataGrid";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box } from "@mui/material";
import React from "react";
import ContactHeader from "./Header";

const ContactHR = () => {
  const initialColumns = [
    {
      field: "contact_source_name",
      headerName: "Tên",
      width: 230,
      operators: { type: "string" },
    },
    {
      field: "contact_source_position",
      headerName: "Chức Vụ",
      width: 180,
      operators: { type: "string" },
    },
    {
      field: "contact_source_phone",
      headerName: "Số Điện Thoại",
      width: 150,
      operators: { type: "string", operators: ["$contains"] },
    },
    {
      field: "contact_source_email",
      headerName: "Email",
      width: 250,
      operators: { type: "string", operators: ["$contains"] },
    },
    {
      field: "contact_source_profile",
      headerName: "Profile (Hồ Sơ MXH) ",
      width: 200,
      operators: { type: "string" },
    },
    {
      field: "contact_source_company",
      headerName: "Công Ty",
      width: 200,
      operators: { type: "string" },
    },
    {
      field: "contact_source_industry",
      headerName: "Ngành",
      width: 200,
      operators: { type: "string" },
    },
    {
      field: "contact_source_website",
      headerName: "Website",
      width: 150,
      operators: { type: "string" },
    },
    {
      field: "contact_source_notes",
      headerName: "Ghi Chú",
      minWidth: 150,
      operators: { type: "string" },
    },
    {
      field: "contact_source_channel",
      headerName: "Kênh Liên Hệ",
      width: 160,
      operators: { type: "object", collections: CONTACT_CHANNEL },
    },
    {
      field: "contact_source_customer_journey_status",
      headerName: "Trạng Thái",
      width: 200,
    },
    {
      field: "contact_sources.contact_source_created_at",
      headerName: "Ngày Tạo",
      width: 150,
      valueGetter: ({ row }) =>
        format_date_time(row?.contact_source_created_at, true),
      operators: { type: "date" },
    },
  ];
  const historyColumsHidden =
    JSON.parse(
      localStorage.getItem("contact-source-marketings.list_hr.hiddenColumns")
    ) || [];

  const [columns, setColumns] = React.useState(
    initialColumns?.map((column) => {
      if (historyColumsHidden?.includes(column?.field)) {
        return { ...column, hide: true };
      }
      return { ...column };
    }) || initialColumns
  );

  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const { data, isFetching } = ContactApi.List({
    page: page?.page + 1,
    pageSize: page?.pageSize,
    query: `filters[$and][0][contact_sources.contact_source_status][$eq]=HR`,
  });
  return (
    <Box>
      <ContactHeader />
      <DataGridCustomize
        rows={
          data?.contacts?.map((contact) => ({
            ...contact,
            id: contact?.contact_source_id,
          })) || []
        }
        columns={columns}
        loading={isFetching}
        componentsProps={{
          toolbar: {
            store: "contact-source-marketings.list_hr.hiddenColumns",
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: "contact",
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize || 20,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        rowCount={data?.pagination?.total}
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(ContactHR, {
    feature: "contact",
    action: "contact-human-resource-index",
  }),
  MuiSkeleton["DataGrid"]
);
