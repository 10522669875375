import { INITIALIZE_SERVICE_GROUPS, SERVICE_STATUS_TYPES } from '@/libs/constants'
import MultipleSelectCustomize from '@components/shared/MultipleSelectCustomize'
import { FormControl, Grid, TextField } from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { queryToCondition } from '../../PopperPopupState/functions'
import DatePicker from '@components/datepickers/DateRangPicker'

const BASE = {
    TextField: ({ value, onChange, ...props }) => {
        return <TextField InputLabelProps={{ shrink: true }}
            value={value} onChange={onChange} size='small' {...props} />
    },
    MultipleSelectCustomize: ({ value: values = [], onChange, options = [], ...props }) => {
        const selecteds = options?.filter(option => values?.includes(option?.value)) || []
        return <MultipleSelectCustomize
            value={selecteds}
            options={options}
            onChange={(__, newValues) => onChange(newValues?.map(option => option?.value) || [])}
            {...props}
        />
    },
    DatePicker: ({ value, onChange, ...props }) => {
        return (
            <DatePicker value={value} handler={onChange} {...props} />
        )
    }
}

const ServiceFilter = ({
    updateQuery,
    callback,
}) => {

    const [state, setState] = useState([
        {
            keys: ['tw_services.service_status'],
            operator: '$in',
            type: 'object',
            component: 'MultipleSelectCustomize',
            value: [],
            onChange: (newValues) => setState(preState => [
                {
                    ...preState[0],
                    value: newValues
                },
                ...preState?.slice(1)]),
            params: {
                options: SERVICE_STATUS_TYPES?.filter(x => ['active', 'trial', 'cancel'].includes(x?.value)),
                input: {
                    label: 'Trạng Thái Dịch Vụ',
                    placeholder: 'Chọn trạng thái dịch vụ tìm kiếm...'
                }
            }
        }, {
            keys: ['service_product_id'],
            operator: '$in',
            type: 'object',
            component: 'MultipleSelectCustomize',
            value: [],
            onChange: (newValues) => setState(preState => [
                ...preState?.slice(0, 1),
                {
                    ...preState[1],
                    value: newValues
                },
                ...preState?.slice(2)]),
            params: {
                options: INITIALIZE_SERVICE_GROUPS,
                input: {
                    label: 'Nhóm Dịch Vụ',
                    placeholder: 'Chọn nhóm dịch vụ...'
                }
            }
        }, {
            keys: ['tw_services.service_start_date'],
            operator: '$btw',
            type: 'date',
            component: "DatePicker",
            value: [null, null],
            onChange: (newValues) => setState(preState => [
                ...preState?.slice(0, 2),
                {
                    ...preState[2],
                    value: newValues
                },
                ...preState?.slice(3),
            ]),
            params: {
                localeText: {
                    start: 'Ngày Bắt Đầu Sử Dụng Dịch Vụ',
                    end: ''
                }
            }
        }
    ])

    useEffect(() => {
        let andConditions = []
        _.forEach(state, (obj) => {
            if (!!obj.value) {
                obj?.keys?.forEach(fieldSelect => {
                    if (obj.type === "object" && obj.value.length === 0) {
                        return;
                    }
                    if (obj.type === "date" && !obj.value[0]) {
                        return;
                    }
                    const q = queryToCondition({
                        fieldSelect: fieldSelect,
                        input: {
                            string: obj.value,
                            objects: obj?.type === "number" ? obj.value?.split(',')?.filter(x => x.trim() !== '')?.map(x => ({ value: x })) : obj.value?.map(x => ({ value: x })),
                            type: obj.type,
                            startdate: obj.type === "date" ? obj.value[0] : null,
                            enddate: obj.type === "date" ? obj.value[1] : null
                        },
                        operator: { operator: obj.operator }
                    })
                    if (obj.type === "date" && obj.operator === "$btw") {
                        andConditions.push(...q)
                        return;
                    }
                    andConditions.push(q)
                });
            }
        })

        const filters = {
            $and: andConditions
        }

        updateQuery(filters)
        callback(filters)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])
    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            {state?.map((column, index) => {
                if (column?.hide) return null;

                const Component = BASE[column?.component]
                if (!Component) return null;

                return (
                    <Grid item xs={12} md={4} xl={3} key={index}>
                        <FormControl fullWidth>
                            <Component
                                value={column.value}
                                onChange={column?.onChange}
                                options={[...column?.options || []]}
                                {...column?.params || {}}
                            />
                        </FormControl>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default ServiceFilter