import { format_numeral_price } from '@/functions'
import { PRODUCT_UNITS, SERVICE_PERIODS } from '@/libs/constants'
import PopperAction from '@components/PopperAction'
import KeyboardArrowDownOutlined from '@mui/icons-material/KeyboardArrowDownOutlined'
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined'
import { Box, Collapse, IconButton, Popover, Typography } from '@mui/material'
import React from 'react'
import ProductAddons from "../ProductAddons"
import { useNavigate } from "react-router-dom"

export const PRODUCT_INFORMATION_TYPES = [
    { value: 'cpu', label: "CPU" },
    { value: 'os', label: "Operator System" },
    { value: 'datacenter', label: "Datacenter" },
    { value: 'ram', label: "Ram" },
    { value: 'disk', label: "Disk" },
    // { value: 'storage', label: "Backup" }
]

export const PRODUCT_ADDONS_TYPES = [
    { value: 'ram', label: "Ram" },
    { value: 'disk', label: "Disk" },
    { value: 'bandwidth', label: "Bandwidth" },
    { value: 'software', label: "Software" },
    { value: 'ip', label: "IP" },
    { value: "cloudsecurities", label: "cloudsecurities" },
    { value: "ssl", label: "SSL" },
    { value: "storage", label: "Backup" },
    { value: "cloudstorage", label: "Backup Cloud Storage" }
]

export const PRODUCT_INFORMATIONS = ["os", "datacenter", "cpu", "ram", "disk", "ssl", "cloudsecurities", "cloudstorage", "storage", "bandwidth", "software", "ip"]

export const ADDONS = ["ram", "disk", "ssl", "cloudsecurities", "cloudstorage", "storage", "bandwidth", "software", "ip"]

export const sortPriorities = (arrs = [], sorters = PRODUCT_INFORMATIONS, key = "product_information_type") => {
    return arrs?.sort((a, b) => sorters.indexOf(a[`${key}`]) - sorters.indexOf(b[`${key}`]));
}


const ProductOne = ({ product, position, remove }) => {

    let navigate = useNavigate()

    const [see, setSee] = React.useState(false)

    const [addon, setAddon] = React.useState(false)

    const [actions] = React.useState([
        {
            order: 1,
            name: "edit",
            icon: "Edit",
            label: "Chỉnh sửa",
            handleClick: (data) => {
                localStorage.setItem(`cart.product.configures`, JSON.stringify(data))
                localStorage.setItem(`cart.product.configures.index`, position)
                navigate(`/products/temple/${data?.product_id}?edit=true`, { replace: true })
                setAnchorEl(null)
            }
        },
        {
            order: 2,
            name: "delete",
            icon: "Delete",
            label: "Xoá",
            handleClick: () => {
                remove(position)
                setAnchorEl(null)
            }
        },
    ])
    const [anchorEl, setAnchorEl] = React.useState(null)

    return (
        <>
            {!!anchorEl &&
                <Popover anchorEl={anchorEl} open={true}>
                    <PopperAction
                        data={product}
                        actions={actions}
                        handleClose={() => setAnchorEl(null)}
                    />
                </Popover>}
            <Box sx={{ width: '100%' }}>
                <Typography component="div" sx={{ fontWeight: 'medium' }}>
                    {product?.product_group_name} - {product?.product_name}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography component="span" sx={{ fontSize: 14 }}>
                        {SERVICE_PERIODS.find(item => item?.value === product?.product_billing_cycle)?.coefficient} Tháng
                    </Typography>
                    <Typography component="span" sx={{ fontSize: 14, color: "#777680" }}>
                        <Typography sx={{ fontSize: 14, textDecoration: 'underline', display: 'inline-flex' }}>đ</Typography>
                        {format_numeral_price(product?.product_pricing_total)}
                    </Typography>
                </Box>
                <Box sx={{ mt: 0.5 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                            color="primary"
                            component="span"
                            onClick={() => setSee(prev => !prev)}
                            sx={{
                                fontSize: 14,
                                textDecoration: 'underline',
                                ":hover": {
                                    cursor: 'pointer'
                                }
                            }}>Thông tin cấu hình
                        </Typography>
                        <KeyboardArrowDownOutlined
                            fontSize='small' sx={{
                                width: 16,
                                height: 16,
                                m: 'auto 0',
                                transform: see ? 'rotate(-180deg)' : 'unset',
                                transition: 'transform 0.5 ease-in-out'
                            }} />
                    </Box>
                    <Collapse in={see}>
                        {sortPriorities(product?.product_informations)?.map((information, i) => {
                            const pInformation = PRODUCT_INFORMATION_TYPES?.find(type => type.value === information?.product_infomation_type)
                            if (!pInformation) return null;
                            return (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    mt: i === 0 ? 2 : 1,
                                    width: '100%'
                                }} key={i}>
                                    <Box sx={{ width: '100%' }}>
                                        <Typography sx={{
                                            color: "#777680",
                                            fontSize: 14
                                        }}>
                                            {pInformation?.label || ""}:
                                        </Typography>
                                    </Box>
                                    <Box sx={{ minWidth: '70%' }}>
                                        {["os", "datacenter"]?.includes(information?.product_infomation_type) &&
                                            <Typography component="span" sx={{
                                                color: "#00001F",
                                                fontSize: 14
                                            }}>
                                                {product?.product_configoptions?.find(e => e?.type === information?.product_infomation_type)?.select?.label}
                                            </Typography>}
                                        {["ram", "disk", "cpu"]?.includes(information?.product_infomation_type) &&
                                            <Typography component="span" sx={{
                                                display: 'inline-flex',
                                                color: "#00001F",
                                                fontSize: 14,
                                                flexWrap: 'wrap',
                                                ml: 0.5
                                            }}>
                                                {information?.value}{PRODUCT_UNITS.slice(1).find(item => item?.value === information?.unit)?.label || ''}
                                            </Typography>}
                                        {["ram", "disk"].includes(information?.product_infomation_type) &&
                                            <Typography sx={{
                                                color: "#777680",
                                                fontSize: 14,
                                                display: 'inline-block'
                                            }}>
                                                ( Mặc định)
                                            </Typography>}
                                    </Box>
                                </Box>
                            )
                        })}
                    </Collapse>
                </Box>
                <Box sx={{ mt: 0.5 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                            color="primary"
                            component="span"
                            onClick={() => setAddon(prev => !prev)}
                            sx={{
                                fontSize: 14,
                                textDecoration: 'underline',
                                ":hover": {
                                    cursor: 'pointer'
                                }
                            }}>Dịch vụ mua kèm/ addons
                        </Typography>
                        <KeyboardArrowDownOutlined
                            fontSize='small' sx={{
                                width: 16,
                                height: 16,
                                m: 'auto 0',
                                transform: addon ? 'rotate(-180deg)' : 'unset',
                                transition: 'transform 0.5 ease-in-out'
                            }} />
                    </Box>
                    <Collapse in={addon}>
                        <ProductAddons data={sortPriorities(product?.product_configoptions, ADDONS, "type")} />
                    </Collapse>
                </Box>
            </Box>
            <IconButton size='small'
                onClick={(event) => setAnchorEl(event?.currentTarget)}
                sx={{
                    m: '0 0 auto auto',
                    height: 40, width: 40,
                    minWidth: 40
                }}>
                <MoreVertOutlined fontSize='small' />
            </IconButton>
        </>
    )
}

export default ProductOne