import PopperAction from "@components/PopperAction";
import MoreVertOutlined from "@mui/icons-material/MoreVertOutlined";
import { Box, IconButton, Popper } from "@mui/material";
import { useMemo, useState } from "react";
import ServiceModules from "./modules";

const Action = ({ ...service }) => {
  const INITIAL_SERVICE_ACTIONS = [
    {
      order: 0,
      icon: "Date",
      name: "service-startdate",
      label: "Ngày Bắt Đầu Dịch Vụ",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "service-startdate",
          data: row,
        }),
    },
    {
      order: 1,
      icon: "MoreTime",
      name: "service-billing-current",
      label: "Thời Gian Thanh Toán Hiện Tại",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "service-billing-current",
          data: row,
        }),
    },
    {
      order: 1,
      icon: "MoreTime",
      name: "service-billing-next",
      label: "Thời Gian Gia Hạn",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "service-billing-next",
          data: row,
        }),
    },
    {
      order: 2,
      icon: "Price",
      name: "price-register",
      label: "Thay Đổi Giá Bán Mới",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "service-update-price-register",
          data: row,
        }),
    },
    {
      order: 3,
      icon: "Price",
      name: "price-recurring",
      label: "Thay Đổi Giá Gia Hạn",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "service-update-price-recurring",
          data: row,
        }),
    },
    {
      order: 4,
      icon: "Statement",
      name: "service-statement-date",
      label: "Thời Gian Đối Soát",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "service-statement-date",
          data: row,
        }),
    },
    {
      order: 4,
      icon: "Diverity",
      name: "change-sale-employee-id",
      label: "Thay Đổi Người Hưởng Hoa Hồng",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "change-sale-employee-id",
          data: row,
        }),
    },
    {
      order: 5,
      icon: "DateEvent",
      name: "service-setdate-auto-renew",
      label: "Đặt Ngày Sinh Hoá Đơn Gia Hạn",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "service-setdate-auto-renew",
          data: row,
        }),
    },
    {
      order: 6,
      icon: "Receipt",
      name: "create-invoice-renew",
      label: "Tạo Hoá Đơn Gia Hạn",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "create-invoice-renew",
          data: row,
        }),
    },
    {
      order: 6,
      icon: "UnLock",
      name: "active",
      label: "Kích Hoạt Dịch Vụ",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "service-active",
          data: row,
        }),
    },
    {
      order: 7,
      icon: "Stop",
      name: "cancel",
      label: "Stop/ Huỷ dịch vụ",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "service-cancel",
          data: row,
        }),
    },
    {
      order: 8,
      icon: "Setting",
      name: "informations",
      label: "Chỉnh sửa cấu hình",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "informations",
          data: row,
        }),
    }, {
      order: 9,
      icon: "Contract",
      name: "generate-contract",
      label: "Hợp Đồng",
      handleClick: (row) =>
        setAction({
          open: true,
          name: "generate-contract",
          data: row,
        }),
    },
  ];
  const actions = useMemo(() => {
    let temps = INITIAL_SERVICE_ACTIONS;
    if (
      service?.service_status === "active" ||
      service?.service_status === "trial" ||
      service?.service_status === "cancel" ||
      service?.service_status === "stop"
    ) {
      temps = temps?.filter((x) => x.name !== "active");
    }
    if (service?.service_creatable_type === "DeviceLiquidation") {
      temps = temps?.filter(
        (x) => ![
          "informations",
          "create-invoice-renew",
          'price-recurring',
          'service-setdate-auto-renew'
        ].includes(x.name)
      );
    }
    if (['ConversantService', 'StorageLFDService', 'TranscodingService', 'TransmuxingService', 'RecordingService'].includes(service?.service_creatable_type)) {
      temps = temps?.filter(
        (x) => !["price-register", "price-recurring", 'informations'].includes(x.name)
      );
    } else {
      temps = temps?.filter(
        (x) => !["service-statement-date"].includes(x.name)
      );
    }
    if (
      service?.service_status === "cancel" ||
      service?.service_status === "stop"
    ) {
      temps = []
    }
    if (service?.service_referrer_type === "Reducer::Override::Service") {
      temps = temps.filter((x) => x.name !== "view-contract");
    }
    return temps;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);

  const [popper, setPopper] = useState({
    anchorEl: undefined,
    data: undefined,
    actions: [],
  });
  const [action, setAction] = useState({
    open: false,
    name: undefined,
    data: undefined,
  });
  const onClosePopper = () =>
    setPopper((prev) => ({
      ...prev,
      anchorEl: undefined,
      data: undefined,
      actions: [],
    }));

  const onCloseAction = () =>
    setAction({ open: false, name: undefined, data: undefined });

  const renderComponents = (type) => {
    switch (type) {
      case 'service-startdate':
        return (
          <ServiceModules.ServiceStartdate
            serviceId={service?.service_id}
            onClose={onCloseAction}
            service={service}
          />
        );
      case 'service-billing-current':
        return (
          <ServiceModules.ServiceBillingCurrent
            serviceId={service?.service_id}
            onClose={onCloseAction}
            service={service}
          />
        );
      case 'service-statement-date':
        return (
          <ServiceModules.ServiceBillingStatement
            serviceId={service?.service_id}
            onClose={onCloseAction}
            service={service}
          />
        );
      case 'service-billing-next':
        return (
          <ServiceModules.ServiceBillingNext
            serviceId={service?.service_id}
            onClose={onCloseAction}
            service={service}
          />
        );
      case 'service-update-price-register':
        return (
          <ServiceModules.PriceRegister
            serviceId={service?.service_id}
            onClose={onCloseAction}
            service={service}
          />
        );
      case 'service-update-price-recurring':
        return (
          <ServiceModules.PriceRecurring
            serviceId={service?.service_id}
            onClose={onCloseAction}
            service={service}
          />
        );
      // case "service-edit":
      //   return (
      //     <ServiceModules.ServiceEdit
      //       serviceId={service?.service_id}
      //       onClose={onCloseAction}
      //       data={service}
      //     />
      //   );
      case "service-active":
        return (
          <ServiceModules.ServiceActive
            serviceId={service?.service_id}
            onClose={onCloseAction}
            data={service}
          />
        );
      case "service-cancel":
        return (
          <ServiceModules.ServiceCancel
            onClose={onCloseAction}
            data={service}
          />
        );
      case "generate-contract":
      case "view-contract":
        return (
          <ServiceModules.ViewContract onClose={onCloseAction} data={service} />
        );
      case "informations":
        return (
          <ServiceModules.Informations onClose={onCloseAction} data={service} />
        );
      case "service-setdate-auto-renew":
        return (
          <ServiceModules.ServiceSetdateRenew
            onClose={onCloseAction}
            service={service}
          />
        );
      case "create-invoice-renew":
        return (
          <ServiceModules.CreateInvoiceRenew
            onClose={onCloseAction}
            data={service}
          />
        );
      case "change-sale-employee-id":
        return (
          <ServiceModules.EmployeeSaleCommission
            onClose={onCloseAction}
            data={service}
          />
        );
      default:
        break;
    }
  };
  if (!!service?.disabledAction || actions?.length === 0) return null;
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      {action?.open && renderComponents(action?.name)}
      {popper?.anchorEl !== undefined && (
        <Popper
          anchorEl={popper?.anchorEl}
          sx={{
            zIndex: 99,
          }}
          open={Boolean(popper.anchorEl)}
          onMouseLeave={onClosePopper}
        >
          <PopperAction {...popper} handleClose={onCloseAction} />
        </Popper>
      )}
      <IconButton
        size="small"
        onClick={(event) => {
          setPopper((prev) => ({
            ...prev,
            anchorEl: event?.target,
            data: service,
            actions,
          }));
        }}
      >
        <MoreVertOutlined fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default Action;
