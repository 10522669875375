import { encodeBase64, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class ProductApi {
  List = ({ query = null, searchQuery = null }) => {
    return useQueryWithCustomized(
      ["product.list", query, searchQuery],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/products?${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ""}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              pagination: data?.data?.pagination,
              products: data?.data?.products,
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  ListProducts = ({ query = null, searchQuery = null }) => {
    return useQueryWithCustomized(
      ["product.all", query, searchQuery],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/products/list?${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ""}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              products: data?.data?.products || []
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Create = async ({ data = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/products`,
      "POST",
      {
        product: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error
      });
  };

  Update = async ({ data = {}, id = null }) => {
    return await requestWithToken(
      `${API_V3_URL}/products/${id}`,
      "PUT",
      {
        product: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error || data?.errors) return data;
        return {
          data: data.data,
          status: data?.status,
        };
      })
      .catch((error) => { return error });
  };

  Detail = ({ id }) => {
    return useQueryWithCustomized(
      ["product.detail", id],
      async () => {
        return await requestWithToken(`${API_V3_URL}/products/${id}`)
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.product;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        suspense: true,
        enabled: !!localStorage.getItem("SA"),
      }
    );
  };

  GetProduct = async ({ id = -1 }) => {
    return await requestWithToken(`${API_V3_URL}/products/${id}`)
      .then(({ data }) => {
        if (data.error) return data;
        return { product: data?.data?.product };
      })
      .catch((error) => {
        return error?.response;
      });
  };
  GetProductInformations = async ({ id = -1 }) => {
    return await requestWithToken(`${API_V3_URL}/products/${id}/information`)
      .then(({ data }) => {
        if (data.error || data?.errors) return data;
        return data?.data?.informations || []
      })
      .catch((error) => {
        return error?.response;
      });
  };

  Countries = () => {
    return useQueryWithCustomized(
      ["product.coutries"],
      async () => {
        return await requestWithToken(`${API_V3_URL}/products/countries`)
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.countries;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        suspense: true,
        enabled: !!localStorage.getItem("SA"),
      }
    );
  };

  FetchProductPublic = (id) => {
    return useQueryWithCustomized(
      ["product.public.one", id],
      async () => {
        return await requestWithToken(
          `http://localhost:3001/api_public/products/${id}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.product;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        suspense: true,
      }
    );
  };

  Addons = () => {
    return useQueryWithCustomized(
      ["product.addons"],
      async () => {
        return await requestWithToken(`${API_V3_URL}/products/addons`)
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.products;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        suspense: true,
      }
    );
  };

  Features = ({ productId }) => {
    return useQueryWithCustomized(
      ["product.features", productId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/products/${productId}/feature`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.product_features;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        suspense: true,
      }
    );
  };
  UpdateProductFeatures = async ({ productId = null, data = [] }) => {
    return await requestWithToken(
      `${API_V3_URL}/products/${productId}/feature`,
      "PUT",
      {
        product: {
          product_features: data,
        },
      }
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error?.response;
      });
  };

  Pricing = ({ productId }) => {
    return useQueryWithCustomized(
      ["product.pricing", productId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/products/${productId}/pricing`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        suspense: true,
      }
    );
  };

  UpdateProductPricing = async ({ productId = null, data = [] }) => {
    return await requestWithToken(
      `${API_V3_URL}/products/${productId}/pricing`,
      "PUT",
      {
        product: {
          product_pricing_currencies: data,
        },
      }
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error?.response;
      });
  };

  UpdateProductInformations = async ({ productId = null, data = [] }) => {
    return await requestWithToken(
      `${API_V3_URL}/products/${productId}/information`,
      "PUT",
      {
        product: {
          product_informations: data,
        },
      }
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error?.response;
      });
  };

  CreatePricing = async ({ productId = -1, data }) => {
    return await requestWithToken(
      `${API_V3_URL}/products/${productId}/pricing`,
      "POST",
      {
        pricing: data,
      }
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data;
      })
      .catch((error) => {
        return error?.response;
      });
  };

  PushAddon = async ({ productId = -1, addon_id }) => {
    return await requestWithToken(
      `${API_V3_URL}/products/${productId}/addons/addon`,
      "POST",
      {
        addon_id,
      }
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data;
      })
      .catch((error) => {
        return error?.response;
      });
  };

  RemoveAddon = async ({ productId = -1, addon_id }) => {
    return await requestWithToken(
      `${API_V3_URL}/products/${productId}/addons/addon`,
      "DELETE",
      {
        addon_id,
      }
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data;
      })
      .catch((error) => {
        return error?.response;
      });
  };
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new ProductApi();
