import Invoice from '@features/Module/Invoice';
import { Box } from '@mui/material';
import React from 'react';
import DashboardWelcome from '../shared/DashboardWelcome';

const Sale = () => {
  return (
    <Box>
      <DashboardWelcome />
      <Invoice.List />
    </Box>
  );
};

export default Sale;
