import CompanyApi from "@/apis/beta/Company/CompanyApi";
import { buildQueryFilterCondition } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { FormControl } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

const CompanyTax = ({ name, value = [], onChange, ...props }) => {
  const { watch } = useFormContext();
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 400);

  const filterables = useCallback(() => {
    let fieldQueries = [
      {
        name: "companies.company_tax",
        operator: "$contains",
        disabled: !watch(`${name}`),
        value: watch(`${name}`),
      },
      {
        name: "companies.company_tax",
        operator: "$contains",
        disabled: !debouncedSearchQuery,
        value: debouncedSearchQuery,
      },
    ];
    return buildQueryFilterCondition("$or", 0, fieldQueries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);
  const { data, isFetching } = CompanyApi.List({
    query: filterables(),
    page: 1,
    pageSize: 10,
  });

  const exectOptions = useMemo(() => {
    return data?.companies?.map((c) => {
      return {
        value: c?.company_tax,
        label: `${c?.company_tax} (${c?.company_name})`,
      };
    });
  }, [data]);

  const [isEmpty, setEmpty] = useState(false);

  const handleSelect = (tax) => {
    const company = data?.companies?.find((x) => x?.company_tax === tax);
    if (!!company && !!props.call) {
      return props.call(company);
    }
  };

  return (
    <FormControl fullWidth>
      <SingleSelectCustomize
        value={exectOptions?.find((x) => x?.value === value) || null}
        options={[
          ...exectOptions,
          ...[isEmpty ? { label: searchQuery, value: searchQuery } : []],
        ]}
        freeSolo
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            onChange(newValue);
            return setEmpty(true);
          } else if (newValue && newValue.inputValue) {
            onChange(newValue);
            return setEmpty(true);
          } else {
            setEmpty(false);
            onChange(newValue?.value);
            return handleSelect(newValue?.value);
          }
        }}
        onBlur={(event) => {
          return onChange(searchQuery);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === "Tab") {
            setEmpty(true);
            return onChange(searchQuery);
          }
        }}
        input={{
          placeholder: "Nhập mã số thuế tìm kiếm...",
          value: searchQuery,
          onChange: (e) => setSearchQuery(e?.target?.value || ""),
        }}
        sx={{
          "&.MuiAutocomplete-option": {
            textTransform: "uppercase",
          },
        }}
        loading={isFetching}
        mode="server"
      />
    </FormControl>
  );
};

export default withSuspense(CompanyTax, MuiSkeleton["TextField"]);
