import { Box, Typography } from '@mui/material'
import React from 'react'
import ButtonCreate from './ButtonCreate'

const Header = ({
    ...props
}) => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: {
                xs: 'column',
                md: 'row'
            },
            gap: 2,
            mb: 2
        }}>
            <Typography sx={{
                fontSize: { xs: 18, md: 24 },
                fontWeight: 'medium'
            }}>
                Danh Sách Nhân Viên
            </Typography>
            <ButtonCreate {...props} />
        </Box>
    )
}

export default Header