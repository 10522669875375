import { PRODUCT_UNITS } from '@/libs/constants'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { FormControl } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ProductPricingUnit = () => {
    const { control, formState: { errors } } = useFormContext()
    return (
        <Controller control={control}
            name='product_pricing_configures.product_pricing_unit'
            render={({ field: { value, onChange } }) => {
                const selected = PRODUCT_UNITS?.find(v => v.value === value) || null
                return (
                    <FormControl fullWidth>
                        <SingleSelectCustomize
                            options={PRODUCT_UNITS}
                            readOnly
                            value={selected}
                            input={{
                                label: "Đơn vị tính *"
                            }}
                            onChange={(event, newValue) => onChange(newValue?.value || "-1")}
                        />
                        {!!errors?.product_pricing_unit &&
                            <ErrorHelperText message={errors?.product_pricing_unit?.message} />}
                    </FormControl>
                )
            }} />
    )
}

export default ProductPricingUnit