import { Box, Popper, Typography } from "@mui/material";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useConfirmContext } from "@contexts/confirm";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import DriveApi from "@/apis/beta/Drives/DriveApi";
import { useQueryClient } from "react-query";
import CreateFolder from "../Dialog/CreateFolder";
import DrivesShare from "../Dialog/DrivesShare";
import queryString from "query-string";
import PopperAction from "@components/PopperAction";
import ChooseNewPath from "../Dialog/ChooseNewPath";
import Info from "../Dialog/Info";

const Folder = ({
  item,
  disabled,
  prefix,
  refetch,
  shared = false,
  employees,
}) => {
  const [dialogState, setDialogState] = useState({
    rename: false,
    share: false,
    chooseNewPath: false,
    zipping: false,
    info: false,
  });

  const [popper, setPopper] = useState({
    anchorEl: null,
    data: null,
  });

  const { showConfirmation } = useConfirmContext();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const { mutateAsync, isLoading } = useMutationWithNotification(
    DriveApi.Delete
  );

  const {
    mutateAsync: moveFolder,
    isLoading: moveFolderLoading,
    notify,
  } = useMutationWithNotification(DriveApi.Rename);

  const handleClick = () => {
    if (!disabled)
      setSearchParams({
        ...queryString.parse(searchParams.toString()),
        path: shared ? item?.key : `${searchParams?.get("path")}${item?.name}/`,
      });
  };

  const toggleDialog = (dialogName) => {
    setDialogState((prevState) => ({
      ...prevState,
      [dialogName]: !prevState[dialogName],
    }));
  };

  const handleContextMenu = (e, data) => {
    e.preventDefault();
    if (!disabled)
      setPopper({
        anchorEl: e?.currentTarget,
        data: { ...data, preview: handleClick },
      });
  };

  const handleDeleteFolder = async () => {
    const path = searchParams.get("path");

    const key =
      !path || path === "/"
        ? item.name
        : `${path
          .split("/")
          ?.filter((item) => item)
          .join("/")}/${item.name}`;

    await mutateAsync(key);

    queryClient.refetchQueries([`drives.list.${path}`]);
  };

  const handleMoveFile = async (new_path) => {
    const old_prefix = popper?.data?.key?.replace("pub/drives", "");

    if (new_path?.includes(old_prefix))
      return notify("error", "Đường dẫn mới và cũ bị chùng nhau");

    const new_prefix = `${new_path}${item?.name}/`;

    notify("warning", "Đang thực hiện di chuyển file vui lòng chờ");

    const response = await moveFolder({ old_prefix, new_prefix });

    if (!response?.errors) {
      notify("success", "Di chuyển folder thành công");
      setSearchParams((prev) => ({ ...prev, path: new_path }));
    }
  };

  const isFetching = moveFolderLoading || disabled;

  const folderActions = [
    {
      name: "move",
      icon: "DriveFileMoveIcon",
      label: "Di Chuyển",
      handleClick: () => toggleDialog("chooseNewPath"),
      disabled: shared,
    },
    {
      name: "rename",
      icon: "DriveFileRenameOutlineIcon",
      label: "Đổi Tên",
      handleClick: () => toggleDialog("rename"),
      disabled: shared,
    },
    {
      name: "delete",
      icon: "Delete",
      label: "Xoá File",
      handleClick: () =>
        showConfirmation({
          title: `Bạn có chắc muốn xoá thư mục ${item?.name}`,
          open: true,
          description:
            "Thao tác này xoá vĩnh viễn, không thể khôi phục, hãy cẩn thận!",
          loading: isLoading,
          handler: handleDeleteFolder,
        }),
      disabled: shared,
    },
    {
      name: "share",
      icon: "ShareIcon",
      label: "Chia Sẻ",
      handleClick: () => toggleDialog("share"),
      disabled: shared,
    },
    {
      name: "info",
      icon: "InfoIcon",
      label: "Chi tiết",
      handleClick: () => toggleDialog("info"),
    },
    // {
    //   name: "zipping",
    //   icon: "FolderZipIcon",
    //   label: "Nén thư mục",
    //   handleClick: () => toggleDialog("zipping"),
    //   disabled: true,
    // },
  ];

  return (
    <div>
      <Box
        sx={{
          cursor: isFetching ? "not-allowed" : "pointer",
          backgroundColor: "#f3f4f6",
          ":hover": { backgroundColor: "#e5e7eb" },
          borderRadius: 1,
          py: 1.5,
          px: 2,
          transition: "all 0.3s ease",
        }}
        onClick={handleClick}
        onContextMenu={(e) => handleContextMenu(e, item)}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <FolderCopyOutlinedIcon
            sx={{ color: isFetching ? "#6b7280" : "black" }}
          />

          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              fontSize: 14,
              fontWeight: 400,
              color: isFetching ? "#6b7280" : "black",
            }}
          >
            {employees?.find((em) => em.employee_uuid === item?.name)
              ?.employee_name || item?.name}
          </Typography>
        </Box>
      </Box>

      {
        dialogState.rename && (
          <CreateFolder
            refetch={refetch}
            prefix={prefix}
            folderName={item?.name}
            handleClose={() => toggleDialog("rename")}
            open={dialogState.rename}
          />
        )
      }

      {
        dialogState.chooseNewPath && (
          <ChooseNewPath
            open={dialogState.chooseNewPath}
            handleClose={() => toggleDialog("chooseNewPath")}
            onChange={handleMoveFile}
            title="Chọn nơi di chuyển"
            prefix={popper?.data?.key}
          />
        )
      }

      {
        dialogState.share && (
          <DrivesShare
            handleClose={() => toggleDialog("share")}
            open={dialogState.share}
            path={`${searchParams?.get("path")}${item.name}/`}
          />
        )
      }

      {
        dialogState.info && (
          <Info
            open={dialogState.info}
            handleClose={() => toggleDialog("info")}
            data={popper.data}
          />
        )
      }

      {
        popper?.anchorEl !== undefined && (
          <Popper
            sx={{ zIndex: 100 }}
            anchorEl={popper?.anchorEl}
            open={Boolean(popper.anchorEl)}
          >
            <PopperAction
              {...popper}
              actions={folderActions}
              handleClose={() =>
                setPopper((prev) => ({ ...prev, anchorEl: undefined }))
              }
            />
          </Popper>
        )
      }
    </div >
  );
};

export default Folder;
