import { PRIORITIES } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import FORM_BASE from '@components/shared/FormCustomized/form'
import { DeleteOutlineOutlined } from '@mui/icons-material'
import AddOutlined from '@mui/icons-material/AddOutlined'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

const WarningValues = ({ name }) => {
    const { control } = useFormContext()
    const { fields, append, remove } = useFieldArray({ control, name })
    return (
        <>
            <Typography sx={{ fontSize: 14, fontWeight: 'medium', mb: 2 }} color="text.black">
                Cài Đặt Thông Báo Ngưỡng Sử Dụng
            </Typography>
            {fields?.map((field, index) => {
                return (
                    <Grid container spacing={2} key={field.id} sx={{ pb: 2 }}>
                        <Grid item xs={6} md={3}>
                            <Controller
                                name={`${name}.[${index}].priority`}
                                control={control}
                                render={({ field: { value, onChange } }) =>
                                    <FORM_BASE.SingleSelect
                                        options={PRIORITIES}
                                        value={value}
                                        input={{
                                            label: "Độ Ưu Tiên",
                                            placeholder: "Chọn độ ưu tiên...",
                                        }}
                                        onChange={onChange}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={4} md={3}>
                            <Controller
                                name={`${name}.[${index}].value`}
                                control={control}
                                render={({ field: { value, onChange } }) =>
                                    <FORM_BASE.TextField
                                        InputProps={{
                                            inputComponent: NumericFormatCustom
                                        }}
                                        value={value}
                                        label="Mốc Cảnh Báo"
                                        placeholder="Nhập mốc cảnh báo..."
                                        onChange={onChange}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={2} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <IconButton size='small' onClick={() => remove(index)}
                                color="error"
                                title='Xoá mốc này'
                            >
                                <DeleteOutlineOutlined fontSize='small' />
                            </IconButton>
                        </Grid>
                    </Grid>
                )
            })}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton size='small' title="Thêm Mốc Cảnh Báo" sx={{ border: '1px solid rgb(192, 192, 192)' }}>
                    <AddOutlined
                        fontSize='small'
                        color='primary'
                        onClick={() => append({ priority: 'low', value: 0 })}
                    />
                </IconButton>
            </Box>
        </>
    )
}

export default WarningValues