import React from 'react';
import { Box, Typography } from '@mui/material';

import { useEmployeeContext } from '@/contexts/employee';

const DashboardWelcome = () => {
	const { employee } = useEmployeeContext();

	return (
		<Box>
			<Typography sx={{ fontSize: '1.75rem' }}>
				Welcome back, {employee?.employee_name}
			</Typography>
		</Box>
	);
};

export default DashboardWelcome;
