import { DeleteOutlineOutlined } from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

const Documentation = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "documentations",
  });

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          gap: 1.5,
        }}
      >
        <Typography sx={{ fontSize: 14, fontWeight: "medium", mb: 0.5 }}>
          Tài liệu hướng dẫn sử dụng:
        </Typography>
        <Typography
          onClick={() => append({ name: "", link: "" })}
          sx={{ fontSize: 14, textDecoration: "underline", m: "auto 0" }}
          color="primary"
        >
          + Thêm tài liệu
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 1.5, mt: 1.5 }}
        aria-labelledby="documetations"
      >
        {fields &&
          fields?.map((field, index) => {
            return (
              <Box
                key={field?.id}
                sx={{ display: "flex", flexDirection: "row", gap: 1.5 }}
              >
                <Controller
                  name={`documentations.[${index}].name`}
                  rules={{ required: "Tên tài liệu bắt buộc" }}
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      sx={{
                        maxWidth: { xs: "100%", md: "calc((100% - 16px) / 4)" },
                      }}
                    >
                      <TextField
                        size="small"
                        {...field}
                        label="Tên tài liệu *"
                        placeholder="Nhập tên tài liệu..."
                        InputLabelProps={{ shrink: true }}
                        helperText={
                          errors?.documentations?.[`${index}`]?.name?.message
                        }
                        error={!!errors?.documentations?.[`${index}`]?.name}
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  rules={{ required: "Link tài liệu bắt buộc" }}
                  name={`documentations.[${index}].link`}
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        {...field}
                        label="Link tài liệu*"
                        placeholder="Nhập link tài liệu..."
                        InputLabelProps={{ shrink: true }}
                        helperText={
                          errors?.documentations?.[`${index}`]?.link?.message
                        }
                        error={!!errors?.documentations?.[`${index}`]?.link}
                      />
                    </FormControl>
                  )}
                />
                <IconButton size="small" onClick={() => remove(index)}>
                  <DeleteOutlineOutlined
                    fontSize="small"
                    sx={{ ":hover": { color: "#ef5350" } }}
                  />
                </IconButton>
              </Box>
            );
          })}
      </Box>
    </Fragment>
  );
};

export default Documentation;
