import { AddOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import withPermission from '@/hocs/withPermission'
import { Link } from 'react-router-dom'

const ButtonCreate = () => {
    return (
        <Link
            to={'/group-roles/new'}
            replace
        >
            <Button
                variant='contained'
                size="small"
                startIcon={<AddOutlined />}
            >
                Thêm nhóm quyền
            </Button>
        </Link>

    )
}

export default withPermission(ButtonCreate, { feature: 'group-role', action: 'create', type: "button" })