import Create from './Create';
import List from './List';
import Detail from './Detail';
import Update from './Update';
import Destroy from './Destroy';
import UpdateLeaveDay from './UpdateLeaveDay';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    List,
    Create,
    Detail,
    Update,
    Destroy,
    UpdateLeaveDay
};
