import ServiceApi from "@/apis/beta/Service/ServiceApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import Templates from "./Templates";
import ServiceButtons from "./ServiceButtons";
import ServiceUpdates from "./ServiceUpdates";
import ServiceMetadata from "./ServiceMetadata";
import ServerInformations from "./Templates/DeviceLiqudation/ServiceInformations";
import { Collapse } from "@mui/material";

const IGNORE_DEFAULT_UI = ["ClusterService", "VnisService", "ConversantService", "StorageLFDService", "TranscodingService", "DomainService", "EmailService", "SSLService", "CloudService", "ColocationService"]

const ServiceInformationAndFields = () => {
	const { service_id: id } = useParams();
	const { data } = ServiceApi.Detail({ id });
	const { data: addons } = ServiceApi.Addons({ serviceId: id });

	const serviceConfigTemplate = () => {
		const Template =
			Templates[data?.service_creatable_type || "Default"] || null;
		return React.createElement(Template || Templates["Default"], {
			serviceId: id,
			service: { ...data },
			addons: addons?.service_addons || [],
		});
	};
	return (
		<Fragment>
			{!IGNORE_DEFAULT_UI?.includes(data?.service_creatable_type) && <ServiceButtons service={data} />}
			{serviceConfigTemplate()}
			{!IGNORE_DEFAULT_UI?.includes(data?.service_creatable_type) && <ServiceMetadata service={data} />}
			<Collapse
				in={
					[
						"DedicatedService",
						"DeviceLiquidation",
						"VBMService",
						"ColocationService",
					].includes(data?.service_creatable_type) &&
					data?.service_informations?.length > 0
				}
			>
				<>
					{([
						"DedicatedService",
						"DeviceLiquidation",
						"VBMService",
						"ColocationService",
					].includes(data?.service_creatable_type) &&
						data?.service_informations?.length > 0)
						&&
						<ServerInformations
							service={data}
							addons={addons?.service_addons || []}
						/>}
				</>
			</Collapse>
			<ServiceUpdates serviceId={id} />
		</Fragment>
	);
};

export default withSuspense(
	withPermission(ServiceInformationAndFields, {
		feature: "service",
		action: "show",
	}),
	MuiSkeleton["DataGrid"]
);
