import Box from '@mui/material/Box';
import { blue, green } from '@mui/material/colors';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 42,
    borderRadius: '2px',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar1Buffer}`]: {
        backgroundColor: theme.palette.mode === 'light' ? green[200] : '#308fe8',
    },
    [`& .${linearProgressClasses.bar2Buffer}`]: {
        backgroundColor: theme.palette.mode === 'light' ? blue[500] : '#308fe8',
    },
    [`& .${linearProgressClasses.dashed}`]: {
        backgroundColor: theme.palette.mode === 'light' ? blue[200] : blue[200],
        backgroundImage: "unset",
        "hover": {
            backgroundColor: "#000"
        }
    },
}));


export default function CustomizedProgressBars(props) {
    const { offset, maximum, addon } = props

    return (
        <Box sx={{ flexGrow: 1 }} {...props}>
            <BorderLinearProgress
                variant='buffer'
                value={(offset + addon === maximum) ? 100 : (offset / maximum) * 100}
                valueBuffer={((offset + addon) / maximum) * 100}
            />
        </Box>
    );
}
