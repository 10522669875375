import { Box, Typography } from '@mui/material'
import React from 'react'

const DayoffEmployee = ({ employee }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 0.5, md: 2 },
            mt: 2
        }}>
            <Box sx={{ display: 'flex', width: { xs: '100%', md: '30%' } }}>
                <Typography sx={{ color: "#777680" }}>
                    Phòng Ban
                </Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Typography>
                    {employee?.employee_department}
                </Typography>
            </Box>
        </Box>
    )
}

export default DayoffEmployee