import { Box, FormControl, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const EmployeeInsuranceInformation = () => {

    const { control, formState: { errors } } = useFormContext()
    return (
        <Box sx={{
            p: 1.5,
            border: '1px solid rgba(0, 0, 0, 0.18)',
            borderRadius: 1
        }}>
            <Typography sx={{
                fontSize: { xs: 16, md: 18 },
                fontWeight: 'medium',
                mb: 1
            }}>
                Bảo Hiểm
            </Typography>
            <Grid container spacing={{ xs: 1.5, md: 2 }}>
                <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
                    <Controller
                        name="employee_health_insurance"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        value={value}
                                        label="Số Thẻ Bảo Hiểm Y Tế (nếu có)"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onChange={onChange}
                                        size='small'
                                        placeholder='Số thẻ BHYT...'
                                        error={!!errors?.employee_health_insurance}
                                        helperText={errors?.employee_health_insurance?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
                    <Controller
                        name="employee_social_insurance"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        value={value}
                                        label="Mã Số Sổ BHXH (nếu có)"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onChange={onChange}
                                        size='small'
                                        placeholder='Mã Số Sổ BHXH...'
                                        error={!!errors?.employee_social_insurance}
                                        helperText={errors?.employee_social_insurance?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="employee_signup_hospital"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        value={value}
                                        label="Nơi Đăng Kí Khám Chữa Bệnh (nếu có)"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onChange={onChange}
                                        size='small'
                                        placeholder='Nơi Đăng Kí Khám Chữa Bệnh...'
                                        error={!!errors?.employee_signup_hospital}
                                        helperText={errors?.employee_signup_hospital?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default EmployeeInsuranceInformation