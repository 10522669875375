import { AddOutlined, DeleteOutlineOutlined } from '@mui/icons-material'
import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

const ActionList = () => {
    const { control, watch, formState: { errors } } = useFormContext()
    const { fields, remove, append } = useFieldArray({
        control: control,
        name: 'actions'
    })
    return (
        <React.Fragment>
            <Box sx={{
                mb: 2,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 0.5
            }}>
                <Typography sx={{ fontWeight: 'medium' }}>
                    Quản Lý Danh Sách Quyền
                </Typography>
                <Button size='small' startIcon={<AddOutlined />}
                    onClick={() => append({
                        action_name: '', permission_pathname: '', module: "", is_outlet: false, prefix_outlet: ""
                    })}
                    sx={{ height: 36 }}>
                    Thêm Action
                </Button>
            </Box>
            {fields?.map((field, index) => {
                return (
                    <React.Fragment key={field?.id}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            gap: 1.5,
                            mb: 2
                        }}>
                            <Box sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                gap: 1.5
                            }}>
                                <Controller control={control}
                                    name={`actions.[${index}].action_name`}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <FormControl fullWidth>
                                                <TextField size='small'
                                                    label="Tên Fn(*)"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder="Funtion..."
                                                    error={!!errors?.actions?.[`${index}`]?.action_name}
                                                    helperText={errors?.actions?.[`${index}`]?.action_name?.message}
                                                />
                                            </FormControl>
                                        )
                                    }} />
                                <Controller control={control}
                                    name={`actions.[${index}].permission_pathname`}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <FormControl fullWidth>
                                                <TextField size='small'
                                                    label="uri (*)"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder="Đường dẫn..."
                                                />
                                            </FormControl>
                                        )
                                    }} />
                                <Controller control={control}
                                    name={`actions.[${index}].module`}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <FormControl fullWidth>
                                                <TextField size='small'
                                                    label="Module Element(*)"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder="Module element..."
                                                />
                                            </FormControl>
                                        )
                                    }} />
                                <Controller control={control}
                                    name={`actions.[${index}].prefix_outlet`}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <FormControl fullWidth>
                                                <TextField size='small'
                                                    label="Prefix"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={value}
                                                    onChange={onChange}
                                                    disabled={!watch(`actions.[${index}].is_outlet`)}
                                                    placeholder="Nhập đường dẫn..."
                                                />
                                            </FormControl>
                                        )
                                    }} />
                                <Controller control={control}
                                    name={`actions.[${index}].is_outlet`}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <FormControl sx={{ minWidth: 80 }}>
                                                <FormControlLabel
                                                    value={value}
                                                    onChange={onChange}
                                                    control={<Checkbox size='small' />}
                                                />
                                            </FormControl>
                                        )
                                    }} />
                            </Box>
                            <IconButton size='small' sx={{ minWidth: 40 }} onClick={() => remove(index)}>
                                <DeleteOutlineOutlined fontSize='small' color="error" />
                            </IconButton>
                        </Box>
                    </React.Fragment>
                )
            })}
        </React.Fragment>
    )
}

export default ActionList