export const CV_SCREEN_RESULTS = [
  {
    label: "PASS",
    value: 1,
  },
  {
    label: "FAIL",
    value: 0,
    mail_template: "thank_you_letter",
  },
];

export const FINAL_STATUS = [
  {
    label: "Interview",
    value: 0,
    mail_template: "send_mail_interview",
    disable: true,
  },
  {
    label: "Từ chối phỏng vấn",
    value: 1,
  },
  {
    label: "Pass Interview",
    value: 2,
  },
  {
    label: "Fail Interview",
    value: 3,
    mail_template: "thank_talent",
  },
  {
    label: "Đề xuất vị trí khác",
    value: 4,
  },
  {
    label: "Tuyển dụng",
    value: 5,
  },
  {
    label: "Từ chối Onboard",
    value: 6,
  },
  {
    label: "Fail Screen CV",
    value: 7,
    mail_template: "thank_you_letter",
  },
  {
    label: "Not open",
    value: 8,
  },
];

export const TYPES_OF_POSITION = [
  {
    label: "Trainee",
    value: "Trainee",
  },
  {
    label: "Team Lead",
    value: "Team Lead",
  },
  {
    label: "Intern",
    value: "Intern",
  },
  {
    label: "Manager",
    value: "Manager",
  },
  {
    label: "Staff",
    value: "Staff",
  },
  {
    label: "Head",
    value: "Head",
  },
];

export const SOURCE = [
  {
    label: "LinkedIn",
    value: "LinkedIn",
  },
  {
    label: "Facebook",
    value: "Facebook",
  },
  {
    label: "VNETWORK",
    value: "VNETWORK",
  },
  {
    label: "Vietnamwork",
    value: "Vietnamwork",
  },
  {
    label: "Vieclam24h",
    value: "Vieclam24h",
  },
  {
    label: "Ybox",
    value: "Ybox",
  },
  {
    label: "timviec365",
    value: "timviec365",
  },
  {
    label: "Internal",
    value: "Internal",
  },
];

export const DEPARTMENT = [
  {
    label: "MKT",
    value: "MKT",
  },
  {
    label: "CS",
    value: "CS",
  },
  {
    label: "SALES",
    value: "SALES",
  },
  {
    label: "TECH",
    value: "TECH",
  },
  {
    label: "DEV",
    value: "DEV",
  },
  {
    label: "FINANCE",
    value: "FINANCE",
  },
  {
    label: "HR",
    value: "HR",
  },
  {
    label: "B.O.D",
    value: "B.O.D",
  },
];
