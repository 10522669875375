import withPermission from '@/hocs/withPermission'
import { AddOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const ButtonCreate = () => {
    return (
        <Link to="/customers/new" replace>
            <Button variant='contained'
                startIcon={<AddOutlined sx={{ m: 'auto 0' }} fontSize="small" />}
                sx={{
                    height: { xs: 36, md: 42 },
                    ml: 2
                }}
                size="small"
            >
                Thêm Khách Hàng
            </Button>
        </Link>

    )
}

export default withPermission(ButtonCreate, { feature: 'customer', action: 'create', type: "button" })