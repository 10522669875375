import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { FormControl } from '@mui/material'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'

const TranscodeTimes = ({
    today = dayjs(),
    year = {},
    setYear
}) => {
    const YEAR_OPTIONS = useMemo(() => {
        return Array.from({ length: 12 }, (__, i) => i)?.map((i) => {
            const month = today.subtract(i, 'month')
            return ({
                value: month?.format('MM-YYYY'),
                label: month?.format('MM-YYYY'),
                gte: month.startOf('month').format('DD/MM/YYYY'),
                lte: month.endOf('month').format('DD/MM/YYYY'),
            })
        })
    }, [today])
    return (
        <FormControl fullWidth sx={{ maxWidth: { xs: '100%', md: 200 }, minWidth: { xs: '100%', md: 200 } }}>
            <SingleSelectCustomize
                value={YEAR_OPTIONS?.find(x => x?.value === year?.value)}
                options={YEAR_OPTIONS || []}
                input={{
                    label: "Chọn thời gian",
                    placeholder: "Chọn thời gian..."
                }}
                compareAttributes={['label', 'value']}
                formatCollection={{
                    value: 'value',
                    label: 'label'
                }}
                onChange={(event, newValue) => {
                    setYear(newValue)
                }}
            />
        </FormControl>
    )
}

export default TranscodeTimes