import EmployeeApi from "@/apis/beta/Employee/EmployeeApi"
import { sleep } from "@/functions"
import withSuspense from "@/hocs/withSuspense"
import MuiSkeletonDefault from "@components/Skeletons/Default"
import { useNotificationContext } from "@contexts/notification"
import { UpdateOutlined } from "@mui/icons-material"
import { Box, Button, CircularProgress, Grid, TextField, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useParams } from "react-router-dom"

const Banking = () => {
    const [submit, setSubmit] = React.useState(false)
    const { setNotification } = useNotificationContext()
    const { employee_id } = useParams()
    const { data: employee } = EmployeeApi.Detail(employee_id)

    const employeeBankingInformation = () => {
        return [
            {
                label: 'Số Tài Khoản Ngân Hàng',
                value: employee?.employee_information?.employee_account_bank_number,
                security: true
            }, {
                label: 'Tên Ngân Hàng',
                value: employee?.employee_information?.employee_bank_name
            }, {
                label: 'Chi Nhánh',
                value: employee?.employee_information?.employee_bank_branch
            }, {
                label: 'Mã Số Thuế Cá Nhân',
                value: employee?.employee_information?.employee_tin,
                security: true
            }
        ]
    }

    const copyToClipboard = (txt) => {
        try {
            navigator.clipboard.writeText(txt).then(() => {
                setNotification({ open: true, message: "Copy to clipboard is successful!", severity: 'success' })
            }, (err) => {
                setNotification({
                    open: true,
                    message: `Copy to clipboard is failed! Err [${err?.message}]`,
                    severity: 'error'
                })
            })
        } catch (error) {
            console.log(error?.message)
        }
    }

    const requestUpdateEmployeeBanking = async () => {
        setSubmit(true)
        await sleep(500)
        setSubmit(false)
        return setNotification({
            open: true, message: "Tính năng đang được cập nhật.", severity: 'success'
        })
    }
    return (
        <Box sx={{
            border: '1px solid rgba(0, 0, 0, 0.18)',
            borderRadius: "4px",
            p: 3
        }}>
            <Typography sx={{
                textTransform: "uppercase",
                fontWeight: 'medium',
                mb: 2
            }}>
                Thông Tin Thuế & Ngân Hàng
            </Typography>
            <Grid container spacing={2}>
                {Array.from(employeeBankingInformation())?.map((item, index) => {
                    return (
                        <Grid item xs={12} md={6} key={index}>
                            <Typography component="div"
                                sx={{
                                    mb: 0.5,
                                    fontSize: 14,
                                    color: '#777680'
                                }}>{item?.label}
                                {item?.security && <Tooltip title="Double clicked for copy.">
                                    <Typography sx={{ color: '#000', fontSize: 14, display: 'inline' }}>*</Typography>
                                </Tooltip>}
                            </Typography>
                            <TextField size='small'
                                value={item?.security
                                    ? item?.value?.slice(0, item?.value?.length >= 4 ? item.value?.length - 4 : 0) + `****`
                                    : item?.value || "Chưa có thông tin"}
                                disabled
                                onDoubleClick={item?.security ? () => copyToClipboard(item?.value) : undefined}
                                fullWidth />
                        </Grid>
                    )
                })}
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                <Button size="small" variant="outlined"
                    sx={{ height: { xs: 36, md: 42 } }}
                    startIcon={submit ? <CircularProgress size={14} sx={{ color: "#777680" }} /> : <UpdateOutlined />}
                    disabled={submit}
                    onClick={requestUpdateEmployeeBanking}
                >
                    Yêu cầu cập nhật
                </Button>
            </Box>
        </Box>
    )
}

export default withSuspense(Banking, MuiSkeletonDefault)