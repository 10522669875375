import { Paper, Tooltip, Typography } from '@mui/material'
import MuiSkeleton from '../MuiSkeleton'
import HelpOutlineOutlined from '@mui/icons-material/HelpOutlineOutlined'

const ItemPaperStatistical = ({
    label,
    subtitle = "",
    value,
    endText,
    isFetching = false,
    ...props
}) => {
    return (
        <Paper sx={{ p: 2 }}>
            {isFetching && <MuiSkeleton.Text />}
            {!isFetching && <Typography sx={{ color: '#777680', mb: 1 }}>{label}
                {!!subtitle && <Typography component="span" sx={{ fontWeight: 'medium', }} color="text.black">
                    {" ("}{subtitle}{")"}
                </Typography>}
                {props?.title && <Tooltip title={props?.title}>
                    <HelpOutlineOutlined fontSize='small' sx={{
                        height: 14, width: 14, color: "#00001F",
                        ":hover": {
                            cursor: "pointer"
                        }
                    }} />
                </Tooltip>
                }
            </Typography>}
            {isFetching && <MuiSkeleton.GradientCircularProgress />}
            {!isFetching && <Typography sx={{ color: '#00000F', fontWeight: "bold", fontSize: { xs: 20, md: 28 } }}>
                {value || 0}
                {endText && <span style={{ textDecoration: 'underline' }}>đ</span>}
            </Typography>}
        </Paper>
    )
}

export default ItemPaperStatistical