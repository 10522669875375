import ServiceAddonApi from "@/apis/beta/Service/ServiceAddonApi";
import { queryClientRefetchScope } from "@/functions";
import withPermission from "@/hocs/withPermission";
import EmailServiceAddon from "@components/Templates/EmailService/Addon/Create";
import { useNotificationContext } from "@contexts/notification";
import AddOutlined from "@mui/icons-material/AddOutlined";
import { Button, Drawer } from "@mui/material";
import { Fragment, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

const ServiceCreateAddon = ({ service }) => {
    const { setNotification } = useNotificationContext();
    const queryClient = useQueryClient();
    const [isCreated, setCreated] = useState(false);

    const { mutateAsync } = useMutation(ServiceAddonApi.Create);

    const onSubmit = async (values) => {
        let form = values;
        const response = await mutateAsync({
            serviceId: service?.service_id,
            data: form,
        });
        if (!response || response?.errors) {
            return setNotification({
                open: true,
                message: response?.errors?.[0]?.message,
                severity: "error",
            });
        }
        queryClientRefetchScope(queryClient, "service");
        setCreated(false)

        return setNotification({
            open: true,
            message: "Thêm mới dịch vụ addon thành công",
            severity: "success",
        });
    };

    return (
        <Fragment>
            {
                <Drawer
                    anchor="right"
                    open={isCreated}
                    onClose={() => setCreated(false)}
                >
                    <EmailServiceAddon service={service}
                        onSubmit={onSubmit}
                        onClose={() => setCreated(false)}
                    />
                </Drawer>
            }
            <Button
                size="small"
                variant="outlined"
                disabled={isCreated}
                onClick={() => setCreated(true)}
                startIcon={<AddOutlined fontSize="small" />}
            >
                Addon
            </Button>
        </Fragment>
    );
};

export default withPermission(ServiceCreateAddon, {
    feature: "service",
    action: "index",
    type: "Button",
});