import Layout from "./Layout"
import Detail from "./Detail"
import Information from "./Information"
import Configoption from "./Detail"
import Pricing from "./Pricing"
import Addon from "./Addons"
import Promotions from "./Promotions"
import Features from "./Features"

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Layout,
    Detail,
    Information,
    Configoption,
    Pricing,
    Addon,
    Promotions,
    Features
}