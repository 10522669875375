import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
const Sale = () => {
  return (
    <Box>
      <Typography sx={{ fontSize: 14, color: 'grey.700' }}>
        Helper Sales
      </Typography>
      <Grid container sx={{ my: 3 }} spacing={2}>
        <Grid item lg={4} md={4} xs={12}>
        </Grid>
        <Grid item lg={8} md={8} xs={12}>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Sale;
