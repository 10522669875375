
import StepCompleted from "./StepCompleted";
import StepReceviePostmanTracking from "./StepReceviePostmanTracking";
import StepScanUpload from "./StepScanUpload";
import StepResolveProblem from "./StepResolveProblem";
import StepPrintAndSign from "./StepPrintAndSign";
import StepStart from "./StepStart";
import StepSendPostmanTracking from "./StepSendPostmanTracking";
import StepCancel from "./StepCancel"
import StepScanUploadSubContract from "./StepScanUploadSubContract";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    StepStart,
    StepPrintAndSign,
    StepResolveProblem,
    StepSendPostmanTracking,
    StepReceviePostmanTracking,
    StepScanUpload,
    StepCompleted,
    StepCancel,
    StepScanUploadSubContract
}