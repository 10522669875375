import NumericFormatCustom from '@components/NumericFormatCustom'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import { FormControl, TextField } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ProductPricingInit = () => {

    const { control, formState: { errors } } = useFormContext()

    return (
        <Controller
            name="product_pricing_configures.pricing"
            control={control}
            render={({ field: { value } }) => {
                return (
                    <FormControl fullWidth>
                        <TextField size='small'
                            label="Giá niêm yết *"
                            value={value}
                            placeholder="0"
                            disabled
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                inputComponent: NumericFormatCustom
                            }}
                        />
                        {!!errors?.product_pricing_configures?.pricing &&
                            <ErrorHelperText message={errors?.product_pricing_configures?.pricing?.message} />}
                    </FormControl>
                )
            }} />
    )
}

export default ProductPricingInit