import { format_date_time } from "@/functions";
import { Box, Chip, StepLabel, Typography } from "@mui/material";
import React from "react";
import PrivateIconWithFormat from "../shared/icons";

const StepPrintAndSign = ({ contractNumber }) => {
  return (
    <React.Fragment>
      <StepLabel
        StepIconProps={{
          sx: {
            fontSize: 20,
            mb: "100px",
          },
        }}
      >
        <Chip
          size="verysmall"
          variant="customize-lightBlue"
          label="In & Trình Ký"
        />
        <Box sx={{ display: "flex", mb: 1 }}>
          <PrivateIconWithFormat icon="CalendarIcon" />
          <Typography sx={{ mr: 2 }}>
            Các vấn đề cần xử lý đã hoàn tất vào lúc{" "}
            {format_date_time(
              contractNumber?.contract_number_created_at,
              false
            )}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <PrivateIconWithFormat icon="AssigneeIcon" />
          <Typography sx={{ color: "#9e9e9e", fontSize: 14, m: "auto 0" }}>
            Người Tiếp Nhận:{" "}
            <span style={{ color: "#000" }}>
              {contractNumber?.employee?.employee_name}
            </span>
          </Typography>
        </Box>
      </StepLabel>
    </React.Fragment>
  );
};

export default StepPrintAndSign;
