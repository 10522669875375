import { UNLIMITED } from "@/libs/constants";
import NumericFormatCustom from "@components/NumericFormatCustom";
import FormCustomized from "@components/shared/FormCustomized";
import {
    Collapse
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import POC from "../POC";
import ServiceTimes from "../shared/ServiceTimes";

const StorageLFD = ({ isPoc = false }) => {
    const { watch } = useFormContext();

    if (isPoc) return <POC />

    const STORAGE_LFD_FIELDS = [
        {
            name: 'product_payment_type', component: "SingleSelect", cols: { xs: 12, md: 6, xl: 6 },
            params: {
                options:
                    [
                        { value: "postpaid", label: "Trả Sau Theo Lưu Lượng Sử Dụng" },
                        { value: "prevpaid-crosscheck", label: "Theo Gói Cam Kết, Đối Soát Vượt" }
                    ],
                input: {
                    label: 'Hình Thức Dịch Vụ *',
                    placeholder: 'Chọn hình thức dịch vụ...'
                }
            }
        }, {
            name: 'product_pricing_configures.unit', component: "SingleSelect", cols: { xs: 12, md: 6, xl: 6 },
            params: {
                options: [{ value: 'gb', label: 'GB' }],
                input: {
                    label: 'Đơn Vị Tính *',
                    placeholder: 'Chọn đơn vị tính...'
                }
            },
        }, {
            name: 'product_pricing_configures.value', component: "InputUnlimitCustomized", cols: { xs: 8, md: 8, xl: 5 },
            params: {
                label: 'Cấu Hình Cam Kết *',
                placeholder: 'Nhập cấu hình cam kết...',
                helperText: 'Example: 1000 GB/ 1 tháng hoặc 1000 GB cho 12 tháng.'
            },
            hidden: watch(`product_payment_type`) === 'postpaid'
        }, {
            name: 'product_pricing_configures.term', component: "SingleSelect", cols: { xs: 4, md: 4, xl: 1 },
            params: {
                input: {
                    label: 'Tháng *',
                    placeholder: 'Chọn số tháng của gói cam kết...',
                },
                options: Array.from({ length: 12 }, (x, i) => i + 1).map(number => ({ value: number, label: number }))
            },
            hidden: watch(`product_payment_type`) === 'postpaid'
        }, {
            name: 'product_pricing_configures.pricing_apply', component: "TextField", cols: { xs: 8, md: 6, xl: 6 },
            params: {
                label: 'Đơn Giá Bán *',
                placeholder: 'Nhập đơn giá bán...',
                InputProps: { inputComponent: NumericFormatCustom }
            },
            hidden: watch(`product_payment_type`) === 'postpaid' || parseInt(watch(`product_pricing_configures.value`)) >= UNLIMITED
        }, {
            name: 'product_pricing_configures.pricing_amount', component: "TextField", cols: { xs: 8, md: 6, xl: 6 },
            params: {
                label: 'Giá Bán *',
                placeholder: 'Nhập giá bán...',
                InputProps: { inputComponent: NumericFormatCustom }
            },
            hidden: watch(`product_payment_type`) === 'postpaid'
        }, {
            name: 'product_pricing_configures.prepaid', component: "SingleSelect", cols: { xs: 8, md: 6, xl: 6 },
            params: {
                options: [{ value: true, label: 'Trả Trước' }, { value: false, label: 'Trả Sau' }],
                input: {
                    label: 'Hình Thức Thanh Toán *',
                    placeholder: 'Chọn hình thức thanh toán...',
                },
            },
            hidden: watch(`product_payment_type`) === 'postpaid'
        }, {
            name: 'product_pricing_configures.method', component: "SingleSelect", cols: { xs: 12, md: 12, xl: 12 },
            params: {
                options: [
                    { value: 'progressive', label: 'Luỹ Tiến' },
                    { value: 'tierd', label: 'Theo Thoả Thuận' }
                ],
                input: {
                    label: 'Hình Thức Đối Soát *',
                    placeholder: 'Chọn hình thức đối soát dịch vụ...',
                },
            }
        },
        {
            name: 'product_pricing_configures.product_pricing_steps',
            component: 'TablePrices',
            cols: { xs: 12, md: 12, xl: 12 },
            params: {
                label: 'Bảng Giá',
                name: 'product_pricing_configures.product_pricing_steps',
                attributes: [
                    {
                        name: 'start',
                        component: 'TextField',
                        cols: { xs: 4, md: 3, lg: 3 },
                        params: {
                            label: 'Từ *',
                            placeholder: 'Nhập số bắt đầu Range giá...',
                            InputProps: {
                                inputComponent: NumericFormatCustom,
                            },
                        }
                    }, {
                        name: 'end',
                        component: 'InputUnlimitCustomized',
                        cols: { xs: 4, md: 3, lg: 3 },
                        params: {
                            label: 'Đến *',
                            placeholder: 'Nhập số kết thúc Range giá...',
                        }
                    }, {
                        name: 'pricing_apply',
                        component: 'TextField',
                        cols: { xs: 4, md: 3, lg: 3 },
                        params: {
                            InputProps: {
                                inputComponent: NumericFormatCustom,
                            },
                            label: 'Đơn giá *',
                            placeholder: 'Nhập đơn giá...',
                        }
                    }],
            }
        },
        {
            name: 'label',
            unController: true,
            component: "Typography",
            params: {
                children: "[Thời Gian Thanh Toán]",
                color: "primary",
                sx: { fontSize: 14, fontWeight: 'medium' }
            }
        },
        {
            name: 'service_management_billing.statement', component: "SingleSelect", cols: { xs: 12, md: 12, xl: 12 },
            params: {
                options: [
                    { value: 'ck1', label: 'Theo Tháng - Đầu Tháng Đến Cuối Tháng - (Example: 01/01 - 31/01)' },
                    { value: 'ck2', label: 'Ngày 23 Đến Ngày 22 - (Example: 23/01 - 22/02)' }
                ],
                input: {
                    label: 'Chu Kỳ Đối Soát *',
                    placeholder: 'Chọn chu kỳ đối soát dịch vụ...',
                },
            }
        }
    ]

    return (
        <>
            <FormCustomized attributes={STORAGE_LFD_FIELDS}
                submitLabel="Thêm Sản Phẩm"
            />
            <Collapse in={watch(`product_payment_type`) !== 'postpaid'}>
                <ServiceTimes />
            </Collapse>
        </>

    );
};

export default StorageLFD;