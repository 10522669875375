import ServiceAddonApi from "@/apis/beta/Service/ServiceAddonApi";
import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { useNotificationContext } from "@/contexts/notification";
import { queryClientRefetcher } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { vestResolver } from "@hookform/resolvers/vest";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import ServiceaddonQtyDescrease from "./shared/ServiceAddonQty";
import ServiceAmount from "./shared/ServiceAmount";
import ServiceDecrease from "./shared/ServiceDescrease";
import ServicePricingApply from "./shared/ServicePricingApply";
import ServiceStoppedAt from "./shared/ServiceStopAt";
import serviceDecreaseValidator from "./validator";

const ServiceDownConfigure = ({ serviceId, onClose }) => {
  const { data: service } = ServiceApi.Detail({ id: serviceId });
  const defaultValues = {
    service_product_id: -1,
    service_product_name: "",
    service_pricing_apply: 0,
    service_billing_cycle: "",
    service_name: "",
    service_type: "addon",
    service_status: "active",
    service_tax: true,
    service_addon_qty: 1,
    service_note: "Khách hàng... giảm câu hình dịch vụ",
  };
  const queryClient = useQueryClient();

  const [submit, setSubmit] = React.useState(false);
  const { setNotification } = useNotificationContext();
  const methods = useForm({
    resolver: vestResolver(serviceDecreaseValidator),
    defaultValues: defaultValues,
  });
  const { control, handleSubmit } = methods;

  const onSubmit = async (values) => {
    const response = await ServiceAddonApi.Decrease({
      serviceId: service?.service_id,
      data: {
        ...values,
      },
    });
    setSubmit(false);
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }
    return response;
  };

  const handleClickSubmit = async (values) => {
    setSubmit(true);
    const response = await onSubmit(values);
    queryClientRefetcher(queryClient, [
      "service.informations",
      "services.addons.list",
      "service.logger",
      "service.invoices",
      "customer.invoices",
    ]);
    onClose();
    return setNotification({
      open: true,
      message: response?.message,
      severity: response?.status,
    });
  };
  return (
    <FormProvider {...methods}>
      <Paper
        sx={{ p: 3, mb: 3 }}
        component="form"
        onSubmit={handleSubmit(handleClickSubmit)}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: 16,
                textTransform: "uppercase",
                fontWeight: "medium",
              }}
            >
              Điều Chỉnh Giảm Cấu Hình Sản Phẩm Dịch Vụ
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ServiceDecrease service={{ ...service }} />
            <ServiceaddonQtyDescrease />
            <ServicePricingApply />
            <ServiceAmount />
            <ServiceStoppedAt />
            <Controller
              name={"service_note"}
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControl fullWidth>
                  <TextField
                    label="Ghi chú"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={value}
                    onChange={onChange}
                    multiline
                    minRows={3}
                    placeholder="Nhập ghi chú..."
                  />
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
            justifyContent: "flex-end",
            mt: 2,
          }}
        >
          <Button
            disabled={submit}
            size="small"
            onClick={onClose}
            sx={{
              height: {
                xs: 36,
                md: 42,
              },
            }}
            variant="text"
          >
            Huỷ Thao Tác
          </Button>
          <Button
            sx={{
              height: {
                xs: 36,
                md: 42,
              },
              minWidth: 150,
            }}
            type="submit"
            size="small"
            disabled={submit}
            startIcon={
              submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
            }
            variant="contained"
          >
            Xác Nhận & Hạ Cấu Hình
          </Button>
        </Box>
      </Paper>
    </FormProvider>
  );
};

export default withSuspense(
  ServiceDownConfigure,
  MuiSkeleton["LinearProgress"]
);
