import ProductGroupApi from "@/apis/beta/Product/ProductGroupApi"
import withSuspense from "@/hocs/withSuspense"
import ErrorHelperText from '@components/shared/ErrorHelperText'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, FormControl, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ProductGroup = ({ type = "create" }) => {
    const { data: groups } = ProductGroupApi.List()
    const { control, formState: { errors }, setValue } = useFormContext()

    const handleAfterOnchange = (productGroup = {}) => {
        const template = productGroup?.product_group_template || "Normal"
        try {
            if (template === "PrivateCDN") {
                setValue('product_type', "customized")
            }
            setValue('product_template', productGroup?.product_group_template || "Normal")
        } catch (error) {

        }
    }
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 1
        }}>
            <Box sx={{ display: 'flex', minWidth: 300 }}>
                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                    Nhóm Sản Phẩm *
                </Typography>
            </Box>
            <Controller control={control}
                name="product_group_id"
                render={({ field: { value, onChange } }) => {
                    let selected = groups.find(s => s.product_group_id === value) || null
                    return (
                        <FormControl fullWidth>
                            <SingleSelectCustomize
                                disabled={!!selected && type === "update"}
                                value={selected}
                                options={groups || []}
                                formatCollection={{
                                    value: 'product_group_id',
                                    label: "product_group_name"
                                }}
                                compareAttributes={['product_group_id', 'product_group_name']}
                                input={{
                                    placeholder: "Nhập tên gói sản phẩm/ dịch vụ..."
                                }}
                                onChange={(event, newValue) => {
                                    onChange(newValue?.product_group_id || -1)
                                    handleAfterOnchange(newValue)
                                }}
                            />
                            {!!errors?.product_group_id && <ErrorHelperText message={errors.product_group_id.message} />}
                        </FormControl>
                    )
                }} />
        </Box>
    )
}


export default withSuspense(ProductGroup, MuiSkeleton["TextField"])