import ScheduleApi from '@/apis/beta/Schedule/ScheduleApi'
import withSuspense from '@/hocs/withSuspense'
import { SCHEDULE_TYPES } from '@/libs/constants'
import { AddOutlined, ArrowForwardOutlined, MoreVertOutlined } from '@mui/icons-material'
import PersonOutlined from '@mui/icons-material/PersonOutlined'
import { Box, Button, IconButton, Paper, Skeleton, Typography } from '@mui/material'
import dayjs from 'dayjs'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

const DailyBookingRoom = ({
    data
}) => {

    const { data: schedules } = ScheduleApi.ScheduleBookingRoom({
        date: data?.value
    })

    const getBookingCurrentDate = () => {
        let results = schedules
        if (data?.title === "Hôm nay") {
            results = results?.filter(sch => moment(sch?.schedule_started_at) >= moment() || moment(sch?.schedule_finished_at) >= moment())
            results = results?.map((sch) => {
                if (moment(sch?.schedule_started_at) >= moment() && moment(sch?.schedule_finished_at) >= moment()) {
                    return ({ ...sch, active: true })
                }
                return { ...sch }
            })
        }
        return _.orderBy(results, ['schedule_started_at'], ['asc']).slice(0, 3)
    }
    return (
        <Box sx={{
            p: 2,
            borderRadius: "4px",
            bgcolor: "#1a237e1f",
            width: { xs: '100%', md: '370px' },
            minWidth: { xs: '100%', md: '370px' },
            mr: 2
        }}>
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ m: 'auto 0', fontSize: 14 }}>{data?.title}, {data?.value}</Typography>
                <IconButton size='small' disabled>
                    <MoreVertOutlined fontSize='small' />
                </IconButton>
            </Box>
            <Box sx={{ height: '192px', minHeight: '192px' }}>
                {getBookingCurrentDate().length === 0 && <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                    <Typography sx={{ color: "#777680", fontSize: 14 }}>Lịch Trống.</Typography>
                </Box>}
                {getBookingCurrentDate()?.map((item, index) => {
                    return (
                        <Box sx={{ display: 'flex', mb: 2 }} key={index}>
                            <Box sx={{ minWidth: 60, width: 60, height: 40, display: 'flex', justifyContent: "center" }}>
                                <Typography sx={{ fontWeight: 'medium', m: 'auto 0' }}>
                                    {dayjs(item?.schedule_started_at).add(-7, 'hours').format("HH:mm")}
                                </Typography>
                            </Box>
                            <Box sx={{
                                minWidth: '4px',
                                height: '40px',
                                width: '4px',
                                bgcolor: item?.active ? "#66bb6a" : "#777680",
                                m: ' 4px'
                            }} />
                            <Box sx={{ width: '100%', p: 0.5 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography sx={{ fontSize: 12, color: "#757575" }}>{item?.schedule_title}</Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <PersonOutlined sx={{ fontSize: 14, m: 'auto 4px auto 0' }} />
                                        <Typography sx={{ fontSize: 12, m: 'auto 0' }}>{item?.schedule_employee_name}</Typography>
                                    </Box>
                                </Box>
                                <Typography sx={{ fontSize: 14 }}>
                                    {SCHEDULE_TYPES?.find(t => t.value === item?.schedule_type)?.label}
                                </Typography>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
            <Link to={"/schedules/new"} replace>
                <Button
                    variant='contained'
                    size="small"
                    sx={{ width: '100%', height: 36 }}
                    startIcon={schedules?.length <= 3 && <AddOutlined />}
                    endIcon={schedules?.length > 3 && <ArrowForwardOutlined />}
                >
                    {schedules?.length > 3 ? "Xem thêm" : "Thêm Hoạt Động"}
                </Button>
            </Link>
        </Box>
    )
}

const SkeletonDailyBookingRoom = () => {
    return (
        <Paper sx={{
            p: 2,
            borderRadius: 0,
            bgcolor: "#9e9e9e1f",
            opacity: 0.5,
            width: 320,
            minWidth: 320,
            mr: 2
        }}>
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Skeleton variant="text" sx={{ width: 120 }} />
                <IconButton size='small' disabled>
                    <MoreVertOutlined fontSize='small' />
                </IconButton>
            </Box>
            <Box sx={{ height: '192px', minHeight: '192px' }}>
                {[1, 2, 3].map((item, index) => {
                    return (
                        <Box sx={{ display: 'flex', mb: 2 }} key={index}>
                            <Box sx={{ minWidth: 60, width: 60, height: 40, display: 'flex', justifyContent: "center" }}>
                                <Typography sx={{ fontWeight: 'medium', m: 'auto 0' }}>
                                    08:30
                                </Typography>
                            </Box>
                            <Box sx={{
                                minWidth: '4px',
                                height: '40px',
                                width: '4px',
                                bgcolor: item?.active ? "#66bb6a" : "#777680",
                                m: ' 4px'
                            }} />
                            <Box sx={{ width: '100%', p: 0.5 }}>
                                <Skeleton variant="text" sx={{ width: 150, fontSize: 12 }} />
                                <Skeleton variant="text" sx={{ width: 180, fontSize: 14 }} />
                            </Box>
                        </Box>
                    )
                })}
            </Box>
            <Skeleton variant="rectangular" sx={{
                height: 36,
                borderRadius: '4px'
            }} />
        </Paper>
    )
}

export default withSuspense(DailyBookingRoom, SkeletonDailyBookingRoom)