import Account from "./Account";
import Banking from "./Banking";
import Business from "./Business";
import Dayoff from "./DayOff";
import Information from "./Information";
import Layout from "./Layout";
import Profile from "./Profile";
import SelfInformation from "./SelfInformation";
import WorkContract from "./WorkContract";
import Scope from "./Scope";


// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Layout,
    Account,
    Dayoff,
    Business,
    WorkContract,
    Information,
    Banking,
    Profile,
    SelfInformation,
    Scope
}