import { UNLIMITED } from "@/libs/constants";
import NumericFormatCustom from "@components/NumericFormatCustom";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

const AddonStep = ({ position }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: `product_addons.[${position}].price_range_steps`,
  });
  return (
    <Fragment>
      <Box
        aria-labelledby="box-price-addon-steps"
        sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}
      >
        {fields &&
          fields?.map((field, index) => {
            return (
              <Box
                key={field?.id}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: 1.5,
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1.5,
                    minWidth: { xs: "100%", md: "calc((100% - 12px) / 2)" },
                  }}
                >
                  <Controller
                    name={`product_addons.[${position}].price_range_steps.[${index}].start`}
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <FormControl fullWidth>
                          <TextField
                            label="Từ *"
                            InputLabelProps={{ shrink: true }}
                            value={value}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                            placeholder="Nhập giá trị..."
                          />
                        </FormControl>
                      );
                    }}
                  />
                  <Controller
                    name={`product_addons.[${position}].price_range_steps.[${index}].end`}
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <FormControl fullWidth>
                          <TextField
                            label="Đến *"
                            InputLabelProps={{ shrink: true }}
                            value={
                              parseInt(value) >= UNLIMITED ? "UNLIMTED" : value
                            }
                            onChange={onChange}
                            size="small"
                            InputProps={{
                              sx: {
                                pr: 0,
                              },
                              inputComponent:
                                parseInt(value) >= UNLIMITED
                                  ? null
                                  : NumericFormatCustom,
                              endAdornment:
                                index === fields?.length - 1 &&
                                parseInt(value) < UNLIMITED ? (
                                  <Box
                                    sx={{
                                      height: "100%",
                                      bgcolor: "rgba(0,0,0,0.1)",
                                      borderTopRightRadius: "4px",
                                      borderBottomRightRadius: "4px",
                                      display: "flex",
                                      justifyContent: "center",
                                      width: {
                                        xs: "50%",
                                        md: "38%",
                                      },
                                      minWidth: { xs: "50%", md: "38%" },
                                      pr: 0,
                                      transform: "width 0.5s ease-in-out",
                                    }}
                                    onClick={() => onChange(UNLIMITED)}
                                  >
                                    <Typography
                                      sx={{ fontSize: 13, m: "auto 0" }}
                                      color="text.success"
                                    >
                                      UNLIMITED
                                    </Typography>
                                  </Box>
                                ) : null,
                            }}
                            placeholder="Nhập giá trị..."
                          />
                        </FormControl>
                      );
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1.5,
                    width: "100%",
                  }}
                >
                  <Controller
                    name={`product_addons.[${position}].price_range_steps.[${index}].pricing_apply`}
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <FormControl fullWidth>
                          <TextField
                            label="Đơn giá bán *"
                            InputLabelProps={{ shrink: true }}
                            value={value}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                            placeholder="Nhập giá trị..."
                          />
                        </FormControl>
                      );
                    }}
                  />
                  <IconButton
                    size="small"
                    onClick={() => remove(index)}
                    disabled={index === 0}
                  >
                    <DeleteOutlineOutlined fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            );
          })}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 0.75 }}>
        <Box sx={{ display: "flex" }} component="span">
          <Typography
            sx={{ fontSize: 13 }}
            color="primary"
            onClick={() => {
              return append({
                ...fields[0],
              });
            }}
          >
            Thêm Mốc
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
};

export default AddonStep;
