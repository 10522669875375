import ServiceSystemApi from "@/apis/beta/Service/ServiceSystemApi";
import withPermission from "@/hocs/withPermission";
import Loading from "@components/shared/MuiSkeleton/Loading";
import { Box } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router";

const Preview = () => {
  const { service_id: serviceId } = useParams();

  const { data, isLoading, isError } = useQuery(
    [`service-preview-${serviceId}`],
    () =>
      ServiceSystemApi.Preview({
        serviceId,
      })
  );

  if (isError) {
    return null;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box width="100%">
      <div dangerouslySetInnerHTML={{ __html: data }} />
    </Box>
  );
};

export default withPermission(Preview, {
  feature: "service",
  action: "service-system-preview",
});
