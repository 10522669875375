import PostmanTrackingApi from "@/apis/beta/PostmanTracking/PostmanTrackingApi";
import DatePicker from "@/components/DatePicker";
import ErrorHelperText from "@/components/shared/ErrorHelperText";
import { useNotificationContext } from "@/contexts/notification";
import { sleep } from "@/functions";
import {
  POSTMAN_TRACKING_PROVIDERS,
  POSTMAN_TRACKING_STATUS,
} from "@/libs/constants";
import { MenuProps } from "@/styles/common";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

const FormPostmanTracking = ({ data, handleClose, callbackContractNumber }) => {
  const queryClient = useQueryClient();
  const [submit, setSubmit] = React.useState(false);
  const { setNotification } = useNotificationContext();

  const [disabledForm, setDisabledForm] = React.useState(
    [2, 3].includes(data?.postman_tracking_status)
  );
  React.useEffect(() => {
    setDisabledForm([2, 3].includes(data?.postman_tracking_status));
  }, [data]);

  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      postman_tracking_status: data?.postman_tracking_status,
      postman_tracking_feedback: data?.postman_tracking_feedback || "",
      postman_tracking_receive_date: data?.postman_tracking_receive_date
        ? moment(data.postman_tracking_receive_date)
        : moment(),
    },
  });

  const onSubmit = async (values) => {
    setSubmit(true);
    let form = {
      ...values,
    };
    const response = await PostmanTrackingApi.Update(
      form,
      data?.postman_tracking_id
    );
    await sleep(500);
    setSubmit(false);
    if (!response || response.status !== 200) {
      return setNotification({
        open: true,
        message: "Cập nhật trạng thái thư thất bại.",
        severity: "error",
      });
    }
    try {
      callbackContractNumber();
    } catch (error) {
      setNotification({ open: true, message: error, severity: "error" });
    }
    setNotification({
      open: true,
      message: "Cập nhật trạng thái thư thành công.",
      severity: "success",
    });
    queryClient.refetchQueries(["contract-number.detail"]);
    return handleClose();
  };
  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <DialogTitle>Trạng Thái Thư</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} component="form">
          <Grid item xl={4.5} lg={4.5} md={4.5} sm={5} xs={12}>
            <Typography sx={{ color: "#757575" }}>Mã Vận Đơn</Typography>
          </Grid>
          <Grid item xl={7.5} lg={7.5} md={7.5} sm={7} xs={12}>
            <Typography>{data?.postman_tracking_mvd}</Typography>
          </Grid>
          <Grid item xl={4.5} lg={4.5} md={4.5} sm={5} xs={12}>
            <Typography sx={{ color: "#757575" }}>Mã Số Hợp Đồng</Typography>
          </Grid>
          <Grid item xl={7.5} lg={7.5} md={7.5} sm={7} xs={12}>
            <Typography>
              {data?.postman_tracking_package?.split(",").join(" |")}
            </Typography>
          </Grid>
          <Grid item xl={4.5} lg={4.5} md={4.5} sm={5} xs={12}>
            <Typography sx={{ color: "#757575" }}>
              Công Ty/ Người Nhận
            </Typography>
          </Grid>
          <Grid item xl={7.5} lg={7.5} md={7.5} sm={7} xs={12}>
            <Typography>{data?.postman_tracking_partner}</Typography>
          </Grid>
          <Grid item xl={4.5} lg={4.5} md={4.5} sm={5} xs={12}>
            <Typography sx={{ m: "0 auto auto 0", color: "#757575" }}>
              Đơn Vị Chuyển Phát
            </Typography>
          </Grid>
          <Grid item xl={7.5} lg={7.5} md={7.5} sm={7} xs={12}>
            <Typography
              sx={{ m: "0 auto auto 0", flex: "wrap", textAlign: "justify" }}
            >
              {
                POSTMAN_TRACKING_PROVIDERS.find(
                  (provider) =>
                    provider.value === data?.postman_tracking_provider
                )?.label
              }
            </Typography>
          </Grid>
          <Grid item xl={4.5} lg={4.5} md={4.5} sm={5} xs={12}>
            <Typography sx={{ m: "0 auto auto 0", color: "#757575" }}>
              Số Điện Thoại
            </Typography>
          </Grid>
          <Grid item xl={7.5} lg={7.5} md={7.5} sm={7} xs={12}>
            <Typography
              sx={{ m: "0 auto auto 0", flex: "wrap", textAlign: "justify" }}
            >
              {data?.postman_tracking_phone}
            </Typography>
          </Grid>
          <Grid item xl={4.5} lg={4.5} md={4.5} sm={5} xs={12}>
            <Typography sx={{ m: "0 auto auto 0", color: "#757575" }}>
              Ngày Nhận
            </Typography>
          </Grid>
          <Grid item xl={7.5} lg={7.5} md={7.5} sm={7} xs={12}>
            <Controller
              name="postman_tracking_receive_date"
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControl fullWidth>
                  <DatePicker
                    value={value}
                    onChange={onChange}
                    disabled={disabledForm}
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xl={4.5} lg={4.5} md={4.5} sm={5} xs={12}>
            <Typography sx={{ m: "0 auto auto 0", color: "#757575" }}>
              Trạng Thái
            </Typography>
          </Grid>
          <Grid item xl={7.5} lg={7.5} md={7.5} sm={7} xs={12}>
            <Controller
              name="postman_tracking_status"
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControl fullWidth>
                  <Select
                    size="small"
                    value={value}
                    onChange={(event) => onChange(event?.target?.value)}
                    disabled={disabledForm}
                    MenuProps={MenuProps}
                  >
                    {POSTMAN_TRACKING_STATUS?.map((status) => (
                      <MenuItem
                        key={status.value}
                        disabled={data?.postman_tracking_status === 3}
                        value={status.value}
                        sx={{ height: 38, fontSize: 14 }}
                      >
                        {status?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          {watch("postman_tracking_status") === 3 && (
            <React.Fragment>
              <Grid item xl={4.5} lg={4.5} md={4.5} sm={5} xs={12}>
                <Typography sx={{ m: "0 auto auto 0", color: "#757575" }}>
                  Lý Do Huỷ Thư *
                </Typography>
              </Grid>
              <Grid item xl={7.5} lg={7.5} md={7.5} sm={7} xs={12}>
                <Controller
                  name="postman_tracking_feedback"
                  control={control}
                  rules={{ required: "Lý do huỷ thư không được để trống." }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth disabled={disabledForm}>
                      <TextField
                        multiline
                        disabled={disabledForm}
                        {...field}
                        placeholder="Nhập lý do huỷ thư..."
                      />
                      {!!error && <ErrorHelperText message={error?.message} />}
                    </FormControl>
                  )}
                />
              </Grid>
            </React.Fragment>
          )}

          <Grid item xl={4.5} lg={4.5} md={4.5} sm={5} xs={12}>
            <Typography sx={{ m: "0 auto auto 0", color: "#757575" }}>
              Địa Chỉ
            </Typography>
          </Grid>
          <Grid item xl={7.5} lg={7.5} md={7.5} sm={7} xs={12}>
            <Typography
              sx={{ m: "0 auto auto 0", flex: "wrap", textAlign: "justify" }}
            >
              {data?.postman_tracking_address}
            </Typography>
          </Grid>
          <Grid item xl={4.5} lg={4.5} md={4.5} sm={5} xs={12}>
            <Typography sx={{ m: "0 auto auto 0", color: "#757575" }}>
              Ghi chú
            </Typography>
          </Grid>
          <Grid item xl={7.5} lg={7.5} md={7.5} sm={7} xs={12}>
            <Typography
              sx={{ m: "0 auto auto 0", flex: "wrap", textAlign: "justify" }}
            >
              {data?.postman_tracking_description || (
                <span style={{ color: "#757575" }}>
                  Không có thông tin ghi chú.
                </span>
              )}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ height: 36 }}
          variant={disabledForm ? "outlined" : "text"}
          size="small"
          disabled={submit}
          onClick={handleClose}
        >
          {!disabledForm ? "huỷ thao tác" : "Đóng"}
        </Button>
        {!disabledForm && (
          <Button
            sx={{ height: 36 }}
            variant="outlined"
            size="small"
            disabled={submit}
            onClick={handleSubmit(onSubmit)}
            startIcon={
              submit && <CircularProgress size={14} sx={{ color: "#757575" }} />
            }
          >
            cập nhật
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FormPostmanTracking;
