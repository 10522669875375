import ServiceApi from "@/apis/beta/Service/ServiceApi";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Collapse, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Addon from "./Addon";
import Service from "./Service";

const Informations = ({ onClose, data }) => {
  const { data: service } = ServiceApi.Detail({ id: data?.service_id });
  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <DialogTitle>Chỉnh sửa cấu hình dịch vụ</DialogTitle>
      <DialogContent>
        <Collapse
          in={
            !!service &&
            service?.service_referrer_type !== "Reducer::Override::Service"
          }
        >
          <Service service={service} onClose={onClose} />
        </Collapse>
        <Collapse
          in={
            !!service &&
            service?.service_referrer_type === "Reducer::Override::Service"
          }
        >
          <Addon service={service} onClose={onClose} />
        </Collapse>
      </DialogContent>
    </Dialog>
  );
};

export default withSuspense(Informations, MuiSkeleton["LinearProgress"]);
