import { Box, Paper, Skeleton } from '@mui/material'
import React from 'react'

const PaperInformation = () => {
    return (
        <Paper sx={{
            display: 'flex',
            p: 3,
            flexDirection: 'column',
            gap: 1.5
        }}>
            {Array.from({ length: 8 }).map((_, i) => i)?.map(i => {
                return (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: 1,
                        justifyContent: 'space-between',
                    }} key={i}>
                        <Box sx={{
                            display: 'flex',
                            width: { xs: '100%', md: '40%' }
                        }}>
                            <Skeleton variant='text' sx={{ width: 150, m: 'auto 0' }} />
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Skeleton variant='text' sx={{ width: 200, m: 'auto 0' }} />
                        </Box>
                    </Box>
                )
            })}
        </Paper>
    )
}

export default PaperInformation