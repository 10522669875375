import { format_numeral_price } from '@/functions'
import { Box, Typography } from '@mui/material'
import React from 'react'

const ServicePaid = ({ data = [] }) => {
    return (
        <Box sx={{
            p: "4px",
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: "column",
            justifyContent: { xs: "center", md: "flex-start" }
        }}>
            {data?.map((service, index) =>
                <Typography key={index} sx={{
                    fontSize: 14, m: 'auto 0 auto auto',
                }}>
                    đ{format_numeral_price(service?.service_invoice_paid)}
                </Typography>
            )}
        </Box>
    )
}

export default ServicePaid