import NumericFormatCustom from '@components/NumericFormatCustom'
import { Box, FormControl, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const InvoicePaymentFee = ({ invoice_status }) => {
    const { control } = useFormContext()
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 1,
            justifyContent: 'space-between',
        }}>
            <Box sx={{
                display: 'flex',
                width: { xs: '100%', md: '40%' }
            }}>
                <Typography sx={{
                    fontSize: 14,
                    color: "#777680",
                    m: 'auto 0'
                }}>
                    Phí Thanh Toán/ Phạt
                </Typography>
            </Box>
            <Controller
                name="transaction_fee"
                control={control}
                render={({ field: { value, onChange } }) => {
                    return (
                        <FormControl fullWidth>
                            <TextField size='small'
                                disabled={invoice_status !== 1}
                                value={value}
                                InputProps={{
                                    inputComponent: NumericFormatCustom
                                }}
                                onChange={onChange}
                            />
                        </FormControl>
                    )
                }} />
        </Box>
    )
}

export default InvoicePaymentFee