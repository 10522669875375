import CustomerApi from '@/apis/beta/Customer/CustomerApi';
import MuiScrollTabs from '@/components/shared/MuiScrollTabs';
import { buildQueryFilterCondition, isObjEmpty } from '@/functions';
import withSuspense from '@/hocs/withSuspense';
import { Filter } from '@/hooks/useFilters';
import { queryToCondition } from '@components/DataGrid/Filter/PopperPopupState/functions';
import MuiSkeleton from '@components/shared/MuiSkeleton';
import { useCustomerContext } from '@contexts/customer';
import { useLayoutContext } from '@contexts/layout';
import {
    DoneOutlined as CustomerActiveIcon,
    AcUnitOutlined as CustomerCoolLeadIcon,
    Groups3Outlined as CustomerQualifiedProspectIcon,
    StopCircleOutlined as CustomerStopIcon,
    HourglassTopOutlined as CustomerTrialIcon
} from '@mui/icons-material';
import _ from 'lodash';
import React, { useCallback } from 'react';

const CustomerTabs = ({
    prefix
}) => {
    const { permissions } = useLayoutContext();

    const customerCollections = React.useMemo(() => {
        let arrs = [];
        permissions?.forEach((collection) => {
            if (["customer"].includes(collection.feature)) {
                arrs.push(...collection?.actions);
            }
        });
        return arrs;
    }, [permissions]);


    const isHiddenTab = ({ f = "", a = "" }) => {
        const available =
            customerCollections?.find(
                ({ feature, action }) => feature === f && action === a
            ) || {};
        return isObjEmpty(available);
    };

    const { queryString, queries } = useCustomerContext()

    const filterableCustomers = React.useCallback(() => {
        if (!queryString || queryString?.trim() === '') return ``

        const queries = [
            {
                name: 'customers.customer_id', operator: "$eq",
                disabled: !parseInt(queryString), value: parseInt(queryString)
            }, {
                name: 'customers.customer_name', operator: "$contains",
                value: queryString
            }, {
                name: 'company_name', operator: "$contains",
                value: queryString
            }, {
                name: 'customers.customer_email', operator: "$contains",
                value: queryString
            }
        ]
        return buildQueryFilterCondition('$or', 0, queries)
    }, [queryString])

    const queryConditions = useCallback(() => {
        let andConditions = []
        _.forEach(queries, (obj) => {
            if (!!obj.value) {
                if (obj.type === "object" && obj.value.length === 0) {
                    return;
                }
                if (obj.type === "date" && !obj.value[0]) {
                    return;
                }
                const q = queryToCondition({
                    fieldSelect: obj.key === 'service' ? 'customer_service_informations.service_name_stoppeds' : obj?.key,
                    input: {
                        string: obj.value,
                        objects: obj.value?.map(x => ({ value: x })),
                        type: obj.type,
                        startdate: obj.type === "date" ? obj.value[0] : null,
                        enddate: obj.type === "date" ? obj.value[1] : null
                    },
                    operator: { operator: obj.operator }
                })
                console.log(q)
                if (obj.type === "date" && obj.operator === "$btw") {
                    andConditions.push(...q)
                    return;
                }
                andConditions.push(q)
            }
        })

        const filters = {
            $and: andConditions
        }
        return filters
    }, [queries])

    const { data } = CustomerApi.Statistical({
        query: Filter.stringify(queryConditions()),
        searchQuery: filterableCustomers()
    })

    const initialTabs = [
        {
            value: "qualified-prospects",
            label: `Tiềm Năng (${data?.qualified_prospects})`,
            href: '',
            icon: CustomerQualifiedProspectIcon,
            hidden: isHiddenTab({ f: "customer", a: "customer-qualified-prospects" }),
            title: 'Khách Hàng Dạng Tiềm Năng/ Đang Trong Quá Trình Tiếp Cận & Khai Thác'
        },
        {
            value: "active",
            label: `Đã Khai Thác (${data?.active})`,
            href: 'active',
            icon: CustomerActiveIcon,
            hidden: isHiddenTab({ f: "customer", a: "customer-active" }),
            title: 'Khách Hàng Có Dịch Vụ Đang Sử Dụng'
        },
        {
            value: "coollead",
            label: `cool - lead (${data?.cool_lead})`,
            href: 'coollead',
            icon: CustomerCoolLeadIcon,
            hidden: isHiddenTab({ f: "customer", a: "customer-cool-lead" }),
            title: 'Khách Hàng Ở Trạng Thái Tự Do/ Không Có Sale Member Theo Dõi'
        }, {
            value: "trial",
            label: `trial (${data?.trial})`,
            href: 'trial',
            icon: CustomerTrialIcon,
            hidden: isHiddenTab({ f: "customer", a: "customer-trial" }),
            title: 'Khách Hàng Đã/ Đang Tạo Dịch Vụ POC/ Dùng Thử'
        }, {
            value: "stop",
            label: `stop (${data?.stop})`,
            href: 'stop',
            icon: CustomerStopIcon,
            hidden: isHiddenTab({ f: "customer", a: "customer-stop" }),
            title: 'Khách Hàng Đã Từng Sử Dụng Dịch Vụ Nhưng Đã Ngưng Sử Dụng Toàn Bộ Dịch Vụ'
        },
    ]

    return (
        <MuiScrollTabs
            initialTabs={initialTabs?.filter((tab) => !tab?.hidden)}
            prefix={prefix} />
    )
}

export default withSuspense(CustomerTabs, MuiSkeleton["ScrollTabs"])