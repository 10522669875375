import { format_date_short } from "@/functions"
import { SERVICE_STATUS_TYPES } from "@/libs/constants"
import { CellPricing, CellStatus } from "@components/DataGrid/Cell"
import _ from "lodash"
import ServiceActtions from "../shared/Service/ServiceActions"
import Contract from "../shared/Service/ServiceContract/Contract"

const SSLButtons = [
    'CreateNote',
    // 'RegisterService',
    'CancelService',
    'CreateInvoiceRenew',
]

const SSLActions = [
    'service-start-date',
    'service-edit',
    'service-date-renew',
    'service-active',
    'service-next-billing',
    'service-current-billing',
    'service-cancel',
    'service-price-amount',
    'service-price-recurring',
    'service-commissions',
    'service-create-invoice-renew',
]

const buildSSLService = ({ service = {} }) => {
    return {
        ...(_.pick(service, [
            'service_id',
            'service_amount',
            'service_status',
            'service_recurring',
            'service_start_date',
            'service_stopped_at',
            'service_referrer_id',
            'service_referrer_type',
            'service_create_is_trial',
            'service_employee_commissions',
            'service_metadata',
            'service_billing_enddate',
            'service_billing_startdate',
            'service_nextbill_enddate',
            'service_nextbill_startdate'
        ])),
        service_name: service?.service_product_name?.toUpperCase()
    }
}

const serviceRows = ({ service = {} }) => {
    return [
        buildSSLService({ service })
    ]
}

const INITIAL_COLUMNS = [
    {
        field: "service_name",
        headerName: "Tên Dịch Vụ",
        width: 250,
    },
    {
        field: "sale_employee_ids",
        headerName: "Người Hưởng Hoa Hồng",
        width: 220,
        valueGetter: ({ row }) =>
            row?.service_employee_commissions
                ?.map((e) => e?.employee_name)
                ?.join(", "),
    },
    {
        field: "service_amount",
        width: 155,
        headerName: "Đơn Giá Bán (/năm *) ",
        renderCell: ({ value }) => value && <CellPricing price={value} />,
    },
    {
        field: "service_recurring",
        width: 185,
        headerName: "Đơn Giá Gia Hạn (/năm *) ",
        renderCell: ({ value }) => value && <CellPricing price={value} />,
    },
    {
        field: "services.service_status",
        headerName: "Trạng Thái",
        width: 140,
        valueGetter: ({ row }) =>
            SERVICE_STATUS_TYPES?.find((stt) => stt?.value === row?.service_status),
        renderCell: ({ value }) => {
            return <CellStatus component="muiChip" data={value} />;
        },
    },
    {
        field: "service_start_date",
        headerName: "Ngày Bắt Đầu Sử Dụng DV",
        width: 200,
        valueFormatter: ({ value }) => !!value ? format_date_short(value) : '',
    },
    {
        field: "service_stopped_at",
        headerName: "Ngày Kết Thúc DV",
        width: 200,
        valueFormatter: ({ value }) => !!value ? format_date_short(value) : '',
    },
    {
        field: "service_billing_startdate",
        headerName: "Chu Kỳ Thanh Toán Hiện Tại",
        width: 200,
        valueGetter: ({ row }) => {
            if (row?.service_create_is_trial) return `TRIAL`;
            if (!row?.service_billing_startdate || !row?.service_billing_enddate)
                return ``;
            return `(${format_date_short(
                row?.service_billing_startdate
            )} - ${format_date_short(row?.service_billing_enddate)})`;
        },
    }, {
        field: "service_nextbill_startdate",
        width: 220,
        headerName: "Chu Kỳ Gia Hạn",
        valueGetter: ({ row }) => {
            if (row?.service_create_is_trial) return `TRIAL`;
            if (!row?.service_nextbill_startdate || !row?.service_nextbill_enddate)
                return ``;
            return `(${format_date_short(
                row?.service_nextbill_startdate
            )} - ${format_date_short(row?.service_nextbill_enddate)})`;
        },
    }, {
        field: "service_contract_name",
        headerName: "Hợp Đồng Hiện Tại",
        minWidth: 350,
        valueGetter: ({ row }) => {
            return {
                ui: row?.service_referrer_type === 'Reducer::Override::Service' ? "SubContract" : 'Contract',
                service_id: row?.service_id,
                service_referrer_id: row?.service_referrer_id
            }
        },
        renderCell: ({ value }) => {
            const ContractUI = Contract[value?.ui]
            if (!ContractUI) return null
            return <ContractUI {...value} />
        }
    }, {
        field: "actions",
        headerName: "",
        width: 40,
        renderCell: ({ row }) => <ServiceActtions service={row} actions={[...SSLActions]} />
    },
]

const SSLServiceLibs = {
    columns: INITIAL_COLUMNS,
    rows: serviceRows,
    activities: SSLButtons,
    actions: SSLActions
}
export default SSLServiceLibs