import { encodeBase64, requestWithToken } from "@/functions";
import { API_V3_URL } from "@/libs/constants";

class CvAPI {
  async getList({ page, pageSize, q, query }) {
    const response = await requestWithToken(
      `${API_V3_URL}/cv?page=${page}&pageSize=${pageSize}&q=${q}${
        query ? `&filters=${encodeBase64(query)}` : ""
      }`
    );
    return response?.data;
  }

  async getOne(id) {
    const response = await requestWithToken(`${API_V3_URL}/cv/${id}`);
    return response?.data;
  }

  async Accept(values) {
    const response = await requestWithToken(
      `${API_V3_URL}/cv/accept/${values?.id}`,
      "PUT",
      values
    );
    return response?.data;
  }

  async SendMailInterView(values) {
    const response = await requestWithToken(
      `${API_V3_URL}/cv/send_mail_interview`,
      "POST",
      values
    );
    return response?.data;
  }

  async CreateNewCV(values) {
    const response = await requestWithToken(`${API_V3_URL}/cv`, "POST", values);
    return response?.data;
  }

  async GetAllCvSource() {
    const response = await requestWithToken(
      `${API_V3_URL}/cv/source/get_all_cv_source`
    );
    return response?.data;
  }

  async AddNewCvSource({ cv_source_name }) {
    const response = await requestWithToken(
      `${API_V3_URL}/cv/source//add_new_cv_source`,
      "POST",
      { cv_source_name }
    );
    return response?.data;
  }

  async Destroy({ id }) {
    const response = await requestWithToken(`${API_V3_URL}/cv/${id}`, "DELETE");
    return response?.data;
  }
}

export default CvAPI = new CvAPI();
