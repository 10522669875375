import React from "react";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import PeopleIcon from '@mui/icons-material/People';
import MuiScrollTabs from "@components/shared/MuiScrollTabs";

const CustomerContractTabs = ({prefix}) => {
    const initialTabs = [
        {
            value: "detail",
            label: "Chi Tiết Hợp Đồng",
            href: '',
            icon: ContentPasteIcon
        },
        {
            value: "processes",
            label: "Quá Trình Trao đổi Hợp Đồng",
            href: 'processes',
            icon: PeopleIcon
        },
    ]
    return ( <MuiScrollTabs initialTabs={initialTabs} prefix={prefix} />)
}
export default CustomerContractTabs;