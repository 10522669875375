import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { FormControl } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const Operation = () => {
    const { control, watch } = useFormContext()
    return (
        <Controller
            name="operator.operator"
            control={control}
            render={({ field: { value, onChange } }) => {
                const conditionQueries = watch(`operator.collections`) || []
                const selected = conditionQueries?.find(cod => cod?.operator === value) || null
                return (
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <SingleSelectCustomize
                            value={selected}
                            options={conditionQueries}
                            formatCollection={{
                                value: 'operator',
                                label: 'label'
                            }}
                            compareAttributes={['label', 'operator']}
                            onChange={(_, newValue) => {
                                onChange(newValue?.operator || "")
                            }}
                            input={{
                                label: 'Phép so sánh (tìm kiếm) *',
                                placeholder: 'Chọn phép tìm kiếm...'
                            }}
                        />
                    </FormControl>
                )
            }}
        />
    )
}

export default Operation