import InvoiceItemApi from "@/apis/beta/Invoice/InvoiceItemApi";
import { format_date_time, queryClientRefetcher } from "@/functions";
import withPermission from "@/hocs/withPermission";
import { PRODUCT_UNITS } from "@/libs/constants";
import NumericFormatCustom from "@components/NumericFormatCustom";
import FormCustomized from "@components/shared/FormCustomized";
import { useNotificationContext } from "@contexts/notification";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

const Update = ({ onClose, invoiceId, invoiceItemId, data }) => {
	console.log(data)
	const queryClient = useQueryClient();
	const { setNotification } = useNotificationContext();
	const [submit, setSubmit] = React.useState(false);

	const methods = useForm({
		defaultValues: {
			invoice_item_name: data?.invoice_item_name,
			invoice_item_referrer_id: data?.invoice_item_referrer_id,
			invoice_item_unit: data?.invoice_item_unit,
			invoice_item_subtitle: data?.invoice_item_subtitle,
			invoice_item_hidden: false,
			invoice_item_description: data?.invoice_item_description,
			invoice_item_value: data?.invoice_item_value || 0,
			invoice_item_subtotal: data?.invoice_item_subtotal || 0,
			invoice_item_deposit: data?.invoice_item_deposit || 0,
			invoice_item_amount: data?.invoice_item_amount || 0,
			invoice_item_tax: data?.invoice_item_tax,
			invoice_item_pricing_apply: data?.invoice_item_pricing_apply || 0,
			invoice_item_startdate: dayjs(data?.invoice_item_startdate) || null,
			invoice_item_enddate: dayjs(data?.invoice_item_enddate) || null
		},
	});
	const { watch, setValue } = methods

	const onSubmit = async (values) => {
		setSubmit(true);
		let form = {
			...values,
			invoice_item_startdate: format_date_time(values?.invoice_item_startdate?.$d || values?.invoice_item_startdate),
			invoice_item_enddate: format_date_time(values?.invoice_item_enddate?.$d || values?.invoice_item_enddate)
		};
		const response = await InvoiceItemApi.Update({
			invoiceId: invoiceId,
			invoiceItemId: invoiceItemId,
			data: form,
		});
		if (!response || response?.errors) {
			return setNotification({
				open: true,
				message: response?.errors?.[0]?.message,
				severity: "error",
			});
		}

		queryClientRefetcher(queryClient, [
			"invoices.list",
			"invoices.statistic",
			"service_invoices.list",
			"customer.invoices",
			"invoice.detail",
			"invoice.invoice_items",
			"invoice.informations",
			"invoice.transactions.list",
			"invoice.loggers",
		]);
		setNotification({
			open: true,
			message:
				response?.data?.message || "Cập nhật thông tin hoá đơn thành công",
			severity: "success",
		});
		return onClose();
	};
	const cols = { xs: 12, md: 12, xl: 12 }
	const INVOICE_ITEM_FIELDS = [
		{
			name: 'invoice_item_referrer_id', component: 'SingleSelect.CustomerService', cols,
			params: {
				customerId: data?.customerId,
				input: {
					label: 'Dịch Vụ *', placeholder: 'Chọn dịch vụ...'
				}
			}
		},
		{
			name: 'invoice_item_name', component: 'TextField', cols, params: {
				label: 'Tên/ Thông Tin Dòng Hoá Đơn',
				placeholder: 'Nhập tên/thông tin dòng hoá đơn...'
			},
		}, {
			name: 'invoice_item_description', component: 'TextField', cols, params: {
				label: 'Thông Tin Mô Tả Hoá Đơn',
				placeholder: 'Nhập thông tin mô tả hoá đơn...',
				multiline: true
			},
		}, {
			name: 'invoice_item_subtitle', component: 'TextField', cols, params: {
				label: 'Thông Tin Mô Hiển Thị Thêm',
				placeholder: 'Nhập thông tin mô tả hoá đơn...',
			},
		}, {
			name: 'invoice_item_value', component: 'InputUnlimitCustomized', cols,
			params: {
				label: "Thông Số Sử Dụng (Ex: Số Tháng, Số Sử Dụng/ Số Năm,...)",
				placeholder: 'Nhập thông số sử dụng...'
			}
		},
		{
			name: 'invoice_item_pricing_apply', component: 'TextField', cols: { xs: 12, md: 6, xl: 6 },
			params: {
				label: 'Đơn Giá Bán *',
				placeholder: 'Nhập đơn giá bán...',
				InputProps: { inputComponent: NumericFormatCustom }
			}
		},
		{
			name: 'invoice_item_subtotal', component: 'TextField', cols: { xs: 12, md: 6, xl: 6 },
			params: {
				label: 'Tạm Tính *',
				placeholder: 'Nhập giá tạm tính Ex: T.Số sử dụng * đơn giá bán...',
				InputProps: { inputComponent: NumericFormatCustom }
			}
		}, {
			name: 'invoice_item_deposit', component: 'TextField', cols: { xs: 12, md: 6, xl: 6 },
			params: {
				label: 'Miễn Giảm/ Chiết Khấu',
				placeholder: 'Nhập giá miễn giảm/ chiết khấu thành tiền...',
				InputProps: { inputComponent: NumericFormatCustom }
			}
		},
		{
			name: 'invoice_item_amount', component: 'TextField', cols: { xs: 12, md: 6, xl: 6 },
			params: {
				label: 'Thành Tiền',
				placeholder: 'Nhập giá thành tiền...',
				InputProps: { inputComponent: NumericFormatCustom }
			}
		},
		{
			name: 'invoice_item_tax', component: 'Checkbox', cols: { xs: 12, md: 6, xl: 6 },
			params: { label: 'Thuế (VAT = 10%)' }
		},
		{
			name: 'invoice_item_unit', component: 'SingleSelect', cols: { xs: 12, md: 6, xl: 6 },
			params: {
				options: PRODUCT_UNITS,
				input: { label: 'Đơn Vị Tính', placeholder: 'Chọn đơn vị tính...' }
			}
		},
		{
			name: 'dates', unController: true, component: 'DateRange', cols,
			params: {
				values: [watch(`invoice_item_startdate`), watch(`invoice_item_enddate`)],
				onChange: (newDates) => {
					setValue(`invoice_item_startdate`, newDates[0])
					setValue(`invoice_item_enddate`, newDates[1])
				}
			}
		},
	]

	React.useEffect(() => {
		const subtotal =
			parseFloat(watch("invoice_item_value")) *
			parseFloat(watch("invoice_item_pricing_apply")) || 0;
		return setValue(`invoice_item_subtotal`, subtotal);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch("invoice_item_value"), watch("invoice_item_pricing_apply")]);

	React.useEffect(() => {
		const amount =
			parseFloat(watch("invoice_item_subtotal")) -
			parseFloat(watch("invoice_item_deposit")) || 0;
		return setValue(`invoice_item_amount`, amount);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch("invoice_item_subtotal"), watch("invoice_item_deposit")]);

	return (
		<FormProvider {...methods}>
			<Dialog open={true} maxWidth="md" fullWidth component="form" onSubmit={methods.handleSubmit(onSubmit)}>
				<DialogTitle>Chỉnh sửa thông tin hoá đơn</DialogTitle>
				<DialogContent>
					<Box
						component="form"
						sx={{ display: "flex", flexDirection: "column", gap: 1.5, mt: 2 }}
					>
						<FormCustomized attributes={INVOICE_ITEM_FIELDS} />
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						size="small"
						disable={submit}
						variant="outlined"
						onClick={onClose}
					>
						Huỷ Thao Tác
					</Button>
					<Button
						size="small"
						type="submit"
						variant="contained"
					>
						Lưu Thay Đổi
					</Button>
				</DialogActions>
			</Dialog>
		</FormProvider>
	);
};

export default withPermission(Update, {
	feature: "invoice",
	action: "invoice-item-update",
	type: "Modal",
});
