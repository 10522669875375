import ServiceApi from '@/apis/beta/Service/ServiceApi'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { useNotificationContext } from '@contexts/notification'
import { AutorenewOutlined } from '@mui/icons-material'
import { Box, Button, Collapse, Drawer, Grow, Typography } from '@mui/material'
import React from 'react'
import Contract from './Contract'
import ContractReferrer from './ContractReferrer'

const ViewContract = ({
    data,
    onClose
}) => {
    const { setNotification } = useNotificationContext()

    const { data: contract } = ServiceApi.ContractReferrer({
        serviceId: data?.service_id
    })

    const [picked, setPicked] = React.useState(false)

    const [automating, setAutomating] = React.useState(false)

    const onSubmit = async () => {
        setAutomating(true)

        const response = await ServiceApi.GenerateContract({
            serviceId: data?.service_id
        })
        setAutomating(false)
        if (!response || response?.errors) {
            return setNotification({
                open: true,
                message: response?.errors?.[0]?.message,
                severity: 'error'
            })
        }
        return setNotification({
            open: true,
            message: response?.errors?.[0]?.message,
            severity: 'error'
        })
    }

    return (
        <Drawer open={true}
            anchor="right"
            sx={{
                height: '100vh', zIndex: 99, minWidth: { xs: '100%', md: '500px' },
            }}
        >
            <Box sx={{ p: '24px 16px', height: '100%', width: 400 }}>
                <Typography sx={{ mb: 1.5, fontWeight: 'medium', fontSize: { xs: 16, md: 18 } }}>
                    Hợp Đồng Dịch Vụ
                </Typography>
                <Collapse in={!!contract}>
                    <Contract contract={contract} />
                </Collapse>
                <Collapse in={!contract}>
                    <Typography sx={{ fontSize: 14, mb: 1 }} color="text.grey">
                        Không tìm thấy hợp đồng của dịch vụ này.
                    </Typography>
                    {!picked && <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                        <Button size='small' fullWidth
                            onClick={onSubmit}
                            variant='contained'
                            disabled={automating}
                            startIcon={<AutorenewOutlined fontSize='small'
                                sx={
                                    !automating ? {} :
                                        {
                                            animation: 'ripple 0.8s infinite',
                                            color: "#00001F",
                                            '@keyframes ripple': {
                                                '0%': {
                                                    transform: 'rotate(0deg)',
                                                },
                                                '50%': {
                                                    transform: 'rotate(-180deg)',
                                                },
                                                '100%': {
                                                    transform: 'rotate(-360deg)',
                                                },
                                            },
                                        }}

                            />}>
                            Auto Sinh Hợp Đồng Mới
                        </Button>
                        {<Collapse in={!automating}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0.5 }}>
                                <Typography color="text.grey" sx={{
                                    fontSize: 14,
                                }}>
                                    Đã có hợp đồng?
                                </Typography>
                                <Typography color="primary"
                                    onClick={() => setPicked(true)}
                                    sx={{
                                        fontSize: 14,
                                        textDecoration: 'underline',
                                        cursor: 'pointer'
                                    }}>
                                    Liên kết ngay
                                </Typography>
                            </Box>
                        </Collapse>}
                    </Box>}
                    {picked &&
                        <Grow in={picked} style={{
                            transformOrigin: '0 0 0'
                        }}>
                            <Box>
                                <ContractReferrer serviceId={data?.service_id}
                                    onClose={() => setPicked(false)}
                                />
                            </Box>
                        </Grow>}
                </Collapse>
                {!picked &&
                    <Grow in={!picked} style={{
                        transformOrigin: '0 0 0'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            gap: 2,
                            mt: 2
                        }}>
                            <Button size='small'
                                variant='outlined'
                                disabled={automating}
                                onClick={onClose} fullWidth>
                                Đóng
                            </Button>
                        </Box>
                    </Grow>}
            </Box>
        </Drawer>
    )
}

export default withSuspense(ViewContract, MuiSkeleton["LinearProgress"])