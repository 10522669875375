import DatePicker from "@components/DatePicker";
import MultipleSelectCustomize from "@components/shared/MultipleSelectCustomize";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";

export const JourneyUpdateComponents = {
  TextField: ({ ...props }) => (
    <TextField {...props} size="small" InputLabelProps={{ shrink: true }} />
  ),
  DatePicker: ({ ...props }) => <DatePicker {...props} />,
  Select: ({ value, onChange, options = [], ...props }) => {
    return (
      <SingleSelectCustomize
        value={options?.find((x) => value === x?.value) || null}
        onChange={(event, newValue) => onChange(newValue?.value || "")}
        options={options}
        input={{
          label: props?.label,
        }}
        {...props}
      />
    );
  },
  MultiSelect: ({ value = [], onChange, options = [], ...props }) => {
    return (
      <MultipleSelectCustomize
        value={options?.filter((x) => value?.includes(x?.value))}
        onChange={(event, newValue) =>
          onChange(newValue?.map((x) => x?.value) || [])
        }
        options={options}
        input={{
          label: props?.label,
        }}
      />
    );
  },
  Flag: ({ value, onChange, ...props }) => (
    <FormControlLabel
      {...props}
      control={
        <Checkbox
          component="span"
          size="small"
          checked={value}
          onChange={onChange}
        />
      }
    />
  ),
};
