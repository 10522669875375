import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import DataGridFilter from "@/components/DataGrid/Filter";
import {
  buildQueryFilterCondition,
  format_date_short,
  format_numeral_price,
  format_date_monent_js,
} from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import useFilters, { Filter } from "@/hooks/useFilters";
import { INDUSTRIES, SERVICES } from "@/libs/constants";
import BaseDataGridCustomer, {
  BaseCustomerComponents,
  LoadingCustomer,
} from "@components/ManagementCustomer";
import { useCustomerContext } from "@contexts/customer";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { JourneyRow } from "../v2/QualifiedProspect/functions";

const initialState = { page: 0, pageSize: 10 };
const INITIAL_STATUS = "cool-lead";

const CustomerCoolLead = () => {
  const location = useLocation();

  const [query, updateQuery] = useFilters(`customers.list.filters`, location);

  const { queryString } = useCustomerContext();
  const debouncedSearchQuery = useDebounce(queryString, 500);

  const filterableCustomer = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
    const fieldQueries = [
      {
        name: "customers.customer_id",
        operator: "$eq",
        disabled: !parseInt(debouncedSearchQuery),
        value: parseInt(debouncedSearchQuery),
      },
      {
        name: "customers.customer_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "company_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "customers.customer_email",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
    ];
    return buildQueryFilterCondition("$or", 0, fieldQueries);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const [page, setPage] = React.useState(initialState);

  const { data, isFetching } = CustomerApi.CustomerJourneys({
    page: page?.page + 1,
    pageSize: page?.pageSize,
    query: Filter.stringify(query),
    searchQuery: filterableCustomer(),
    type: INITIAL_STATUS,
  });

  const [filterables] = React.useState([
    {
      field: "customers.customer_id",
      label: "ID Khách Hàng",
      operators: {
        type: "number",
      },
    },
    {
      field: "customers.customer_name",
      label: "Tên Khách Hàng",
      operators: {
        type: "string",
      },
    },
    {
      field: "customer_service_informations.customer_industry",
      label: "Lĩnh Vực",
      operators: {
        type: "object",
        collections: INDUSTRIES,
      },
    },
    {
      field: "customer_service_informations.customer_service_interestings",
      label: "SP/ DV Quan Tâm",
      operators: {
        type: "object",
        collections: SERVICES,
      },
    },
  ]);
  const [columns] = React.useState([
    {
      field: "customer_history_employee",
      headerName: "Lịch Sử Nhân Viên Followers",
      valueGetter: ({ row }) => {
        return (
          row?.customer_histories?.map((history) => {
            return history?.employees;
          }) || []
        );
      },
      minWidth: 300,
      renderCell: ({ value }) => <JourneyRow data={value} />,
    },
    {
      field: "customer_history_reason",
      headerName: "Thời Gian & Lý Do Huỷ Follow",
      valueGetter: ({ row }) => {
        return (
          row?.customer_histories?.map((history) => {
            return `(${format_date_monent_js(
              history?.transfer_customer_at,
              "DD/MM/YYYY HH:mm"
            )}): ${history?.transfer_customer_reason}`;
          }) || []
        );
      },
      minWidth: 300,
      renderCell: ({ value }) => <JourneyRow data={value} />,
    },
    {
      field: "customer_service_informations.customer_service_interestings",
      headerName: "Sản phẩm/ dịch vụ quan tâm",
      valueGetter: ({ row }) =>
        SERVICES.filter((item) =>
          row?.customer_service_interestings?.includes(item?.value)
        )?.map((item) => item?.label) || [],
      width: 250,
      renderCell: ({ value }) => (
        <BaseCustomerComponents.Service services={value} />
      ),
    },
    {
      field: "customer_service_informations.customer_provider_explaination",
      hide: true,
      valueGetter: ({ row }) =>
        row?.customer_provider_explaination || undefined,
      headerName: "KH đang dùng nhà cung cấp nào?",
      width: 250,
    },
    {
      field: "customer_service_informations.customer_budget_explaination",
      headerName: "Budget/ Month",
      width: 180,
      valueGetter: ({ row }) => row?.customer_budget_explaination,
      renderCell: ({ value }) =>
        !!value ? (
          format_numeral_price(value)
        ) : (
          <Typography sx={{ fontSize: 14, color: "#777980" }}>
            Chưa rõ
          </Typography>
        ),
      hide: true,
    },
    {
      field: "customer_service_informations.customer_problem_explaination",
      headerName: "Vấn đề, nỗi đau",
      hide: true,
      width: 180,
      valueGetter: ({ row }) => row?.customer_problem_explaination,
    },
    {
      field: "customer_guest",
      valueGetter: ({ row }) => row?.customer_name,
      headerName: "Người đại diện",
      width: 180,
      hide: true,
    },
    {
      field: "customer_service_informations.customer_expected_explaination",
      hide: true,
      valueGetter: ({ row }) =>
        row?.customer_expected_explaination || undefined,
      headerName: "Mong đợi",
      width: 150,
    },
    {
      field: "customer_service_informations.customer_expected_date",
      headerName: "Thời gian & dự tính bán hàng",
      width: 180,
      valueGetter: ({ row }) => row?.customer_expected_date || undefined,
      renderCell: ({ value }) =>
        !!value ? format_date_short(value) : "Chưa có",
    },
  ]);

  return (
    <Box>
      {/* {data?.employees && data.employees.length !== 0 && ( */}
        <DataGridFilter
          callback={updateQuery}
          filters={{
            key: `customers.list.filters`,
            filterables: filterables,
            exportFeature: "ManageCustomerCoollead",
          }}
        />
      {/* )} */}
      <BaseDataGridCustomer
        status={INITIAL_STATUS}
        columns={[...columns]}
        page={page}
        handlerPage={setPage}
        isFetching={isFetching}
        customers={data?.customers}
        total={data?.pagination?.total}
        getRowHeight={({ model }) => {
          const PADDING_OFFSET_Y = 8;
          const HISTORIES =
            24 * (model?.customer_histories?.length || 0) + PADDING_OFFSET_Y;
          return Math.max(HISTORIES, 52);
        }}
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(CustomerCoolLead, {
    feature: "customer",
    action: "customer-cool-lead",
  }),
  LoadingCustomer
);
