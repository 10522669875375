import BreadCrumb from '@components/shared/BreadCrumb'
import { Box, Typography } from '@mui/material'
import React from 'react'

const Header = ({ dayoffId }) => {

    const redirect = localStorage.getItem('dayoff.referrer.redirect')

    return (
        <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontSize: { xs: 18, md: 24 }, fontWeight: "medium" }}>
                Thông Tin Phiếu
            </Typography>
            <BreadCrumb
                data={[
                    { label: "Danh Sách Phiếu Nghỉ", reactLink: !redirect ? "/dayoffs" : `/${redirect}` },
                    { label: `[PN - ${dayoffId}]` }
                ]}
            />
        </Box>
    )
}

export default Header