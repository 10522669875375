import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class PermissionApi {
  List = () => {
    return useQueryWithCustomized(
      "permissions.list",
      async () =>
        await requestWithToken(`${API_V3_URL}/permissions`)
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              permissions: data?.data?.permissions || [],
            };
          })
          .catch((error) => {
            return error.response;
          }),
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
        enabled: !!localStorage.getItem("SA"),
      }
    );
  };

  Detail = (feature) => {
    return useQueryWithCustomized(
      ["permission.detail", feature],
      async () => {
        return await requestWithToken(`${API_V3_URL}/permissions/${feature}`)
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        suspense: true,
        enabled: !!localStorage.getItem("SA"),
      }
    );
  };

  PostFeaturePermissions = async (data) => {
    return await requestWithToken(
      `${API_V3_URL}/permissions/features`,
      "POST",
      { data },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Destroy = async (feature) => {
    return await requestWithToken(
      `${API_V3_URL}/permissions/${feature}`,
      "DELETE",
      {},
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PermissionApi();
