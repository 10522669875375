import ErrorHelperText from "@components/shared/ErrorHelperText";
import {
  Box,
  FormControl,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const DayoffReason = ({ type }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: { xs: 0.5, md: 2 },
        mt: 2,
      }}
    >
      <Box sx={{ display: "flex", width: { xs: "100%", md: "30%" } }}>
        <Typography component="div" sx={{ color: "#777680" }}>
          Lý Do Xin Nghỉ Phép
          <Tooltip
            title="Lý do nghỉ phép chỉ có bạn, Team Leader, BOD và HR Manager mới xem được."
            sx={{ display: "inline" }}
          >
            <Typography
              sx={{ ":hover": { cursor: "pointer" }, display: "inline" }}
            >
              *
            </Typography>
          </Tooltip>
        </Typography>
      </Box>
      <Controller
        control={control}
        name="day_off_reason"
        render={({ field: { value, onChange } }) => {
          return (
            <FormControl fullWidth>
              <TextField
                multiline
                disabled={type === "cancel"}
                value={value}
                placeholder="Nhập lý do nghỉ phép..."
                onChange={onChange}
              />
              {!!errors?.day_off_reason && (
                <ErrorHelperText message={errors?.day_off_reason?.message} />
              )}
            </FormControl>
          );
        }}
      />
    </Box>
  );
};

export default DayoffReason;
