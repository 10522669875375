import { Box, Typography } from '@mui/material'
import React from 'react'
import withPermission from '@/hocs/withPermission'
import ReportTabs from '../shareds/ReportTab'

const Marketing = () => {
    return (
        <Box>
            <ReportTabs />
            <Typography sx={{ fontSize: 14, color: 'grey.700', mb: 2 }}>Report.Marketing</Typography>
        </Box>
    )
}

export default withPermission(Marketing, { feature: 'report', action: 'report-marketing' })