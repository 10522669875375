import CVApi from "@/apis/beta/Employee/CVApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import ResumeInfo from "./ResumeInfo";
import Loading from "@components/shared/MuiSkeleton/Loading";
import SendMailInterviewDialog from "./Dialog/SendMailInterview";
import MuiSkeleton from "@components/shared/MuiSkeleton";

const Detail = () => {
  const { id } = useParams();

  const [showDialogSendMailInterview, setShowDialogSendMailInterview] =
    useState({
      data: null,
      show: false,
    });

  const { data, isLoading, refetch } = useQueryWithCustomized(
    `get-cv-${id}`,
    () => CVApi.getOne(id)
  );

  if (isLoading) return <Loading />;

  return (
    <Box p={1}>
      <ResumeInfo
        data={data}
        setShowDialogSendMailInterview={setShowDialogSendMailInterview}
        refetch={refetch}
      />

      {showDialogSendMailInterview?.data &&
        showDialogSendMailInterview?.show && (
          <SendMailInterviewDialog
            updateData={showDialogSendMailInterview?.data}
            open={showDialogSendMailInterview?.show}
            handleClose={() => setShowDialogSendMailInterview(false)}
            refetch={refetch}
          />
        )}
    </Box>
  );
};

export default withSuspense(
  withPermission(Detail, { feature: "resume", action: "find-one" }),
  MuiSkeleton["GridInformation"]
);
