import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import React from "react";
import Form from "../Shared/Form";
import MuiSkeleton from "@components/shared/MuiSkeleton";

const New = () => {
  return <Form />;
};

export default withSuspense(
  withPermission(New, {
    feature: "document",
    action: "create",
  }),
  MuiSkeleton["GridInformation"]
);
