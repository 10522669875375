import { requestWithToken, encodeBase64 } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";
import queryString from "query-string";

class WarehouseModelApi {
  List = ({ page = 1, pageSize = 20, queries = null }) => {
    return useQueryWithCustomized(
      ["warehouse.model.list", page, pageSize, queries],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_models?page=${page}&page_size=${pageSize}${
            !queries ? "" : `&filters=${encodeBase64(queries)}`
          }`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.warehouse_models?.map((o) => ({
              value: o?.warehouse_model_id,
              label: o?.warehouse_model_name.toUpperCase(),
              warehouse_type_name:
                o?.warehouse_type?.warehouse_type_name?.toUpperCase(),
              warehouse_type_id: o?.warehouse_type?.warehouse_type_id,
              warehouse_group_id: o?.warehouse_type?.warehouse_group_id,
              ...o,
            }));
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );
  };
  All = (params = {}) => {
    const paramsQueryString = queryString.stringify(params, {
      skipNull: true,
      arrayFormat: "bracket",
      skipEmptyString: true,
    });

    console.log(paramsQueryString);

    return useQueryWithCustomized(
      ["warehouse.model.all", paramsQueryString],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_models/list?${paramsQueryString}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.warehouse_models?.map((o) => ({
              value: o?.warehouse_model_id,
              label: o?.warehouse_model_name.toUpperCase(),
              warehouse_type_name:
                o?.warehouse_type?.warehouse_type_name?.toUpperCase(),
              warehouse_type_id: o?.warehouse_type?.warehouse_type_id,
              warehouse_group_id: o?.warehouse_type?.warehouse_group_id,
              ...o,
            }));
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );
  };

  listSlots = () =>
    useQueryWithCustomized(
      ["warehouse.slot.all"],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_models/list_slots`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.warehouse_slots?.map((o) => ({
              value: o?.slot_id,
              label: o?.slot_name.toUpperCase(),
              ...o,
            }));
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );
  listSpecifications = () =>
    useQueryWithCustomized(
      ["warehouse.specification.all"],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_models/list_specifications`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.warehouse_specifications?.map((o) => ({
              value: o?.specification_id,
              label: o?.specification_name.toUpperCase(),
              ...o,
            }));
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );
  Create = (params) =>
    requestWithToken(`${API_WAREHOUSE_V1_URL}/warehouse_models`, "POST", {
      warehouse_model: params,
    });
  Update = (params) =>
    requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_models/${params?.id}`,
      "PUT",
      { warehouse_model: params }
    );
}

export default WarehouseModelApi = new WarehouseModelApi();
