import VnisPackage from './Package'

const Price = ({ service }) => {

    return (
        <>
            <VnisPackage serviceId={service?.service_id}
                price={service?.service_pricing_configure}
            />
        </>
    )
}

export default Price