import JourneyUpdateApi from "@/apis/beta/Customer/Journey/JourneyUpdateApi";
import { CUSTOMER_JOURNEYS, CUSTOMER_JOURNEY_STATUS } from "@/libs/constants";
import { useNotificationContext } from "@contexts/notification";
import { AddOutlined, CloudUploadOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { JourneyUpdateComponents } from "../UpdateRender";
import { queryClientRefetcher } from "@/functions";
import { JourneyHelperModals } from "../Modal";
import JourneyApi from "@/apis/beta/Customer/Journey/JourneyApi";
import JourneyAttachment from "../JourneyAttachment";
import FileApi from "@/apis/shared/File/FileApi";
import { useNavigate } from "react-router";

const PRIVATE_JOURNEY_UPDATES = [
  {
    key: "journey_update_transaction_at",
    label: "Thời Gian Thực Hiện",
    component: "DatePicker",
  },
  {
    key: "journey_flag",
    label: "Ghim",
    component: "Flag",
  },
  {
    key: "journey_update_step",
    label: "Quá Trình",
    component: "Select",
    options: CUSTOMER_JOURNEYS,
  },
  {
    key: "journey_update_status",
    label: "Trạng Thái",
    component: "Select",
    options: CUSTOMER_JOURNEY_STATUS,
  },
  {
    key: "journey_update_note",
    label: "Mô Tả",
    component: "TextField",
    placeholder: "Nhập công việc/ ghi chú bạn thực hiện",
    cols: 12,
    multiline: true,
    InputLabelProps: {
      shrinK: true,
    },
  },
];

const JourneyUpdateCreate = ({ customerId, journeyId, journey }) => {
  const { setNotification } = useNotificationContext();

  const folder = `customers/journeys/deals/${customerId}`;

  const [attachment, setAttachment] = useState({
    file: null,
    loading: false,
    percen: 0,
  });

  const queryClient = useQueryClient();
  const methods = useForm({
    defaultValues: {
      journey_update_step: journey?.customer_journey_step || "searching",
      journey_update_status:
        journey?.customer_journey_step_status || "processing",
      journey_update_note: "Mục đích : ????? Kết quả: ?????? Follow up:?????? ",
      journey_flag: false,
      journey_update_transaction_at: moment(),
    },
  });

  const [enable, setEnable] = useState(false);

  const [submit, setSubmit] = useState(false);

  const [responseModal, setModal] = useState({
    open: false,
    modal: undefined,
    metadata: {}
  });

  const revalidatePrecheck = (nextStatus) => {
    if (
      journey?.customer_journey_step === "poc" &&
      ["completed", "processing"]?.includes(
        journey?.customer_journey_step_status
      )
    ) {
      if (nextStatus === "skip" || nextStatus === "pending") return false;
      return true;
    }
    return false;
  };
  const [showBusinessDialog, setShowBusinessDialog] = useState(false);
  const navigate = useNavigate();
  const onSubmit = async (values) => {
    setSubmit(true);
    if (revalidatePrecheck(values?.journey_update_status)) {
      await journeyPrecheckPOC();
    }

    const response = await JourneyUpdateApi.Create({
      customerId,
      journeyId,
      data: { ...values },
    });

    if (!response || response?.errors) {
      setSubmit(false);
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });

    }

    if (attachment.file) {
      const uploaded = await onSubmitAttachment();

      if (!uploaded) {
        setSubmit(false);
        return setNotification({
          open: true,
          message: "Upload file thất bại.",
          severity: "error",
        });

      }

      await JourneyUpdateApi.Attachment({
        journeyId,
        jUpdateId: response?.data?.journey_update?.journey_update_id,
        customerId,
        values: {
          attachment_file_name: attachment.file.name,
          attachment_file_type: attachment.file.type,
          attachment_file_url: `pub/${folder}/${uploaded}`,
          attachment_file_size: attachment.file.size,
        },
      });
    }

    methods.reset({});
    setEnable(false);
    setSubmit(false);

    queryClientRefetcher(queryClient, [
      "customers.journeys",
      "customers.journeys.updates",
    ]);
    try {
      setModal({
        open: true,
        modal: response?.data?.next_step || "",
        metadata: response?.data?.metadata
      });

      setNotification({
        open: true,
        message: "Cập nhật thông tin thành công.",
        severity: "success",
      });

    } catch (error) {
      setSubmit(false);
      setNotification({
        open: true,
        message: "Có lỗi xảy ra trong quá trình cập nhật.",
        severity: "error" + error?.message,
      });
    }
  };

  const onSubmitAttachment = async () => {
    const file = attachment?.file;

    const token = await FileApi.GetPresignedTokenAttachment({
      filename: {
        name: file?.name,
        folder,
      },
    });
    setAttachment((prevState) => ({
      ...prevState,
      loading: false,
      percen: 0,
    }));

    await FileApi.UploadAttachmentToStorage({
      token: token?.token,
      file,
      handleUploadFile: setAttachment,
    });

    return token?.filename;
  };

  const onClose = () => setModal({ open: false, modal: undefined, metadata: {} });
  const renderresponseModal = () => {
    const metadata = {
      customer: {
        customer_id: customerId,
        contact_id: journey?.contact_id,
        journey_id: journey?.customer_journey_id
      },
      ...responseModal?.metadata
    };
    switch (responseModal?.modal) {
      case "CreatePOC":
        return <JourneyHelperModals.POC onClose={onClose} {...metadata} />;
      case "CreateService":
        return (
          <JourneyHelperModals.CreateService onClose={onClose} {...metadata} />
        );
      case "CreateBusiness":
        return <JourneyHelperModals.CreateBusiness onClose={onClose} {...metadata} />
      case "CreateTicket":
        return <JourneyHelperModals.CreateTicket onClose={onClose} {...metadata} />
      default:
        return null;
    }
  };

  const journeyPrecheckPOC = async () => {
    const precheck = await JourneyApi.PrecheckPOC({
      customerId,
      journeyId,
    });
    if (!precheck || precheck?.errors) {
      setSubmit(false);
      return setNotification({
        open: true,
        message:
          precheck?.errors?.[0]?.message ||
          "Vui lòng tạo dịch vụ dùng thử trước khi thực hiện thao tác này.",
        severity: "error",
      });
    }
  };
  const handleBusinessDialogClose = () => {
    setShowBusinessDialog(false);
  };

  const handleBusinessDialogConfirm = () => {
    setShowBusinessDialog(false);
    navigate('/businesses/new');
  };

  return (
    <Grid container spacing={2} sx={{ mt: 0.5 }}>
      {responseModal?.open && !!responseModal?.modal && renderresponseModal()}
      <Grid item xs={12} md={3}>
        <Typography sx={{ fontSize: 14 }} color="text.grey">
          Cập Nhật Thông Tin
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Collapse in={enable}>
          <FormProvider {...methods}>
            <Paper
              component="form"
              onSubmit={methods.handleSubmit(onSubmit)}
              sx={{ p: "10px 12px", mb: 1.25 }}
              elevation={8}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  mb: 2.5
                }}
              >
                Để tạo hợp đồng dịch vụ, vui lòng chọn quá trình
                <Typography sx={{ fontSize: 14, m: 'auto 4px' }} component="span" color="error">Contract - Ký hợp đồng</Typography>
                và trạng thái
                <Typography sx={{ fontSize: 14, m: 'auto 4px' }} component="span" color="error">Đang Thực Hiện</Typography>
              </Typography>
              <Grid container spacing={2}>
                {PRIVATE_JOURNEY_UPDATES?.map((item, index) => {
                  const Component =
                    JourneyUpdateComponents[item.component] || null;
                  if (!Component) return null;
                  let disabled = false;
                  return (
                    <Grid item xs={12} sm={12} md={item?.cols || 6} key={index}>
                      <Controller
                        name={item?.key}
                        control={methods.control}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <FormControl fullWidth>
                              <Component
                                disabled={disabled || false}
                                value={value}
                                onChange={onChange}
                                options={item?.options}
                                {...item}
                              />
                            </FormControl>
                          );
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Collapse in={methods?.watch(`journey_update_step`) === "deal"}>
                <JourneyAttachment
                  state={attachment}
                  file={attachment?.file}
                  setFile={setAttachment}
                />
              </Collapse>
              <Box
                sx={{
                  justifyContent: "flex-end",
                  display: "flex",
                  mt: 1.25,
                  gap: 1.25,
                }}
              >
                <Button
                  size="small"
                  disabled={submit}
                  onClick={() => setEnable(false)}
                >
                  Đóng
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  type="submit"
                  disabled={submit}
                  endIcon={
                    submit ? (
                      <CircularProgress size={14} sx={{ color: "#777680" }} />
                    ) : (
                      <CloudUploadOutlined fontSize="small" />
                    )
                  }
                >
                  Cập nhật
                </Button>
              </Box>
            </Paper>
          </FormProvider>
        </Collapse>

        {!enable && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              size="small"
              disabled={submit}
              onClick={() => setEnable(true)}
              startIcon={
                submit || attachment.loading ? (
                  <CircularProgress size={14} sx={{ color: "#777680" }} />
                ) : (
                  <AddOutlined fontSize="small" />
                )
              }
            >
              Thêm cập nhật
            </Button>
          </Box>
        )}
      </Grid>
      <Dialog fullWidth maxWidth="sm"
        open={showBusinessDialog}
        onClose={handleBusinessDialogClose}
      >
        <DialogTitle>Xác nhận</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bạn có muốn tạo phiếu công tác?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="outlined" onClick={handleBusinessDialogClose}>Hủy</Button>
          <Button size="small" variant="contained"
            onClick={handleBusinessDialogConfirm}
            color="primary"
          >
            Tạo mới
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default JourneyUpdateCreate;
