import { PRODUCT_UNITS } from '@/libs/constants'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, FormControl } from '@mui/material'
import React from 'react'

const PriceUnit = ({ data, setState }) => {
    return (
        <Box sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center' }}>
            <FormControl fullWidth>
                <SingleSelectCustomize
                    value={PRODUCT_UNITS?.find(unit => unit?.value === data?.unit) || undefined}
                    options={PRODUCT_UNITS}
                    input={{
                        placeholder: 'Chọn đơn vị tính...',
                    }}
                    onChange={(event, newValue) => {
                        setState(preData => [
                            ...preData?.slice(0, data?.id),
                            {
                                ...data,
                                unit: newValue?.value,
                            },
                            ...preData?.slice(data?.id + 1)
                        ])
                    }}
                />
            </FormControl>
        </Box>
    )
}

export default PriceUnit