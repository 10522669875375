import { format_date_short } from "@/functions";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";

const ContractTimer = ({ contracts, isAddon = false }) => {
  const lastContract = useMemo(() => {
    if (isAddon) {
      return _.sortBy(contracts, ["subcontract_expiry_date"]).at(-1) || null;
    }
    return _.sortBy(contracts, ["expiry_date"]).at(-1) || null;
  }, [contracts, isAddon]);
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        p: "8px 12px",
        alignItems: "center",
      }}
    >
      <Typography sx={{ fontSize: 14 }}>
        {format_date_short(
          lastContract?.[
            !isAddon ? "effective_date" : "subcontract_effective_date"
          ]
        )}{" "}
        -{" "}
        {format_date_short(
          lastContract?.[!isAddon ? "expiry_date" : "subcontract_expiry_date"]
        )}
      </Typography>
    </Box>
  );
};

export default ContractTimer;
