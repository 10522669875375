import EmployeeApi from '@/apis/beta/Employee/EmployeeApi'
import withSuspense from '@/hocs/withSuspense'
import { DeleteOutlineOutlined } from '@mui/icons-material'
import { Box, Chip, CircularProgress, IconButton, Typography, FormControl } from '@mui/material'
import React from 'react'

const Present = ({
    selecteds = [],
    assigneeIds = [],
    setSelecteds
}) => {
    const [more, setMore] = React.useState(false)
    const { data: employees } = EmployeeApi.ListEmployees({ page: 1, pageSize: 999 })

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5
        }}>
            {selecteds?.slice(0, more ? selecteds?.length : 3)?.map((item, index) => {
                const employee = employees?.find(e => e?.employee_id === item)
                return (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: { xs: 'column', md: 'row' },
                        p: "0 8px",
                    }}
                        key={index}>
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <FormControl fullWidth sx={{ maxWidth: 100 }}>
                                <Typography sx={{ fontSize: 14 }}>
                                    [ID: {employee?.employee_id}]
                                </Typography>
                            </FormControl>
                            <FormControl fullWidth>
                                <Typography sx={{ fontSize: 14, textTransform: 'capitalize', fontWeight: 'medium' }}>
                                    [ {employee?.employee_name} ]
                                </Typography>
                            </FormControl>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            minWidth: { xs: '100%', md: 'auto' },
                            gap: 1
                        }}>
                            {!assigneeIds?.includes(item) && <Chip size='small'
                                variant='outlined'
                                color="success"
                                label="Mới"
                                sx={{ m: 'auto 0' }}
                            />}
                            <IconButton sx={{ m: 'auto 0' }} size='small'
                                onClick={() => setSelecteds(prev => [...prev?.filter(id => id !== item)])}
                            >
                                <DeleteOutlineOutlined fontSize='small' sx={{
                                    ":hover": { color: "#ef5350" }
                                }} />
                            </IconButton>
                        </Box>
                    </Box>
                )
            })}
            {!more && selecteds.length > 3 && <Typography
                onClick={() => setMore(true)}
                sx={{
                    fontSize: 14,
                    ":hover": {
                        textDecoration: 'underline',
                        cursor: 'pointer'
                    }
                }} color="primary">+ {selecteds.length - 3} nhân viên...</Typography>}
        </Box>
    )
}
const Loading = () => {
    return (
        <CircularProgress size={14} />
    )
}

export default withSuspense(Present, Loading)