import { create, test, enforce } from 'vest';

// @ts-ignore
import isEmail from 'validator/lib/isEmail';

enforce.extend({ isEmail });

const validators = create((data = {}) => {
  // test('email', 'Email không hợp lệ', () => {
  //   enforce(data.email).isEmail();
  // });
  test('email', 'Email không được để trống', () => {
    enforce(data.email).isNotEmpty();
  });
  test('password', 'Mật khẩu không được để trống', () => {
    enforce(data.password).isNotEmpty();
  });
  test(
    'password',
    'Mật khẩu không thể ngắn hơn 6 ký tự và dài hơn 20 ký tự.',
    () => {
      enforce(data.password).longerThanOrEquals(6).shorterThanOrEquals(20);
    }
  );
});

export default validators;
