import { DeleteOutlineOutlined } from '@mui/icons-material'
import { Collapse, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { TransitionGroup } from 'react-transition-group'

const renderItem = ({
    onDelete,
    label,
    icon,
    ...item
}) => {
    return (
        <ListItem
            sx={{
                height: '42px',
                ":hover": {
                    bgcolor: "rgba(0,0,0,0.08)",
                    cursor: "pointer"
                },
                borderRadius: '4px',
                alignItems: 'center'
            }}
            secondaryAction={!!onDelete && <IconButton edge="end" title="Delete" size='small' onClick={() => onDelete(item)}>
                <DeleteOutlineOutlined fontSize='small' color="error" />
            </IconButton>}
        >
            {!!icon && <ListItemIcon sx={{ width: '20px', minWidth: '20px', mr: '10px' }}>{icon}</ListItemIcon>}
            <ListItemText primary={label} />
        </ListItem>
    )
}
const TransitionGroups = ({
    children,// JSX Element
    values = [],
    onDelete,
    label = 'label',
    subtitle = undefined,
    icon
}) => {

    return (
        <>
            {children}
            <List dense sx={{
                mt: 1,
                borderTop: '1px solid #333',
                pt: 0.5,
                maxHeight: 'calc(42px * 5)',
                overflow: "hidden",
                overflowY: 'auto',
                "&::-webkit-scrollbar": {
                    width: '2px',
                    "&-track": {
                        background: '#f1f1f1'
                    },
                    "&-thumb": {
                        background: "#000"
                    }
                }
            }}>
                <TransitionGroup>
                    {values?.map((item, index) => {
                        return (
                            <Collapse key={index}>
                                {renderItem({ onDelete, label: item?.[label] + (!!subtitle ? ` (${item?.[subtitle]})` : ''), ...item, icon })}
                            </Collapse>
                        )
                    })}
                </TransitionGroup>
            </List>
        </>
    )
}
export default TransitionGroups