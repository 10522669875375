import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class ServiceConfigoptionApi {
    List = ({ serviceId = -1 }) => {
        return useQueryWithCustomized(
            ["service.configoptions.list", serviceId],
            async () => {
                return await requestWithToken(`${API_V3_URL}/services/${serviceId}/configoptions`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return {
                            configoptions: data?.data?.configoptions || []
                        }
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            }
        );
    };


    Update = async ({ serviceId = -1, data = [] }) => {
        return await requestWithToken(`${API_V3_URL}/services/${serviceId}/configoptions/update_configoptions`,
            "PUT", {
            service: { configoptions: data }
        }, {}, {})
            .then((data) => {
                if (data?.error || data?.errors) return data;
                return data;
            }).catch((error) => {
                return error.response;
            })
    };

    ConfigoptionPrices = ({ serviceId = -1 }) => {
        return useQueryWithCustomized(
            ["service.configoption_prices", serviceId],
            async () => {
                return await requestWithToken(`${API_V3_URL}/services/${serviceId}/configoptions/price_exceeds`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return {
                            configoptions: data?.data?.configoptions || []
                        }
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            }
        );
    };

    UpdateConfigoptionPrices = async ({ serviceId = -1, configoptions = [] }) => {
        return await requestWithToken(`${API_V3_URL}/services/${serviceId}/configoptions`, "PUT", {
            service: { configoptions: configoptions }
        })
            .then(({ data }) => {
                if (data?.error || data?.errors) return data;
                return data;
            }).catch((err) => { return err?.response })
    };


}
// eslint-disable-next-line import/no-anonymous-default-export
export default new ServiceConfigoptionApi()