import { Box, Typography } from '@mui/material'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { sortPriorities } from '../Configoption'
import MailStorage from "./MailStorage"
import Ram from "./Ram"
import Software from "./Software"
import SSL from "./SSL"
import CloudStorage from "./CloudStorage"

const Modules = {
    SOFTWARE: Software,
    RAM: Ram,
    DISK: Ram,
    BANDWIDTH: Ram,
    IP: Ram,
    CLOUDSECURITIES: Software,
    SSL: SSL,
    STORAGE: MailStorage,
    CLOUDSTORAGE: CloudStorage
}

export const PRODUCT_ADDONS_TYPES = [
    { value: 'ram', label: "Ram" },
    { value: 'disk', label: "Disk" },
    { value: 'bandwidth', label: "Bandwidth" },
    { value: 'software', label: "Software" },
    { value: 'ip', label: "IP" },
    { value: "cloudsecurities", label: "cloudsecurities" },
    { value: "ssl", label: "SSL" },
    { value: "storage", label: "Backup" },
    { value: "cloudstorage", label: "Backup Cloud Storage" }
]

const ProductAddons = () => {
    const { control } = useFormContext()

    const productAddons = useWatch({
        control: control,
        name: "product_configoptions"
    })

    return (
        <Box>
            <Typography sx={{ fontWeight: 'medium', mb: 1, fontSize: 18 }}>
                Dịch Vụ mua Thêm
            </Typography>
            {Array.from(sortPriorities(productAddons))?.map((information, index) => {
                const pInformation = PRODUCT_ADDONS_TYPES?.find(type => type.value === information?.type)
                if (!pInformation) return null;
                let Component = Modules[information?.type?.toUpperCase()]
                if (!Component) return null
                return <Component key={index} position={index} />
            })}
        </Box>
    )
}

export default ProductAddons