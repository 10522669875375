import { create, enforce, test } from "vest";

const customerManagementValidator = create((data = []) => {

    data?.managements?.forEach((management, index) => {
        test(`managements.[${index}].customer_management_name`, "Tên nhóm không được để trống", () => {
            enforce(management.customer_management_name).isNotEmpty()
        })

        test(`managements.[${index}].mailer_receiver`, "Người nhận mail nhóm này không được để trống.", () => {
            enforce(management.mailer_receiver).isNotEmpty()
        })

        // test(`managements.[${index}].servicer_referrer`, "Vui lòng chọn dịch vụ cho nhóm dịch vụ này.", () => {
        //     enforce(management.servicer_referrer?.length > 1).isTruthy()
        // })
    })
})

export default customerManagementValidator;