import { format_numeral_price } from '@/functions'
import { PRODUCT_UNITS, UNLIMITED } from '@/libs/constants'
import { Box, Typography } from '@mui/material'
import React from 'react'

const ServiceConfigoption = ({ serviceConfigoptions = [] }) => {

    if (serviceConfigoptions.length === 0) return null
    return (
        <React.Fragment>
            <Typography sx={{
                fontSize: 14,
                mb: 0.5,
                color: "#777680",
            }}>
                Thông Tin Cấu Hình
            </Typography>
            <Box sx={{ p: 2, bgcolor: "#7776801F", width: '100%' }}>
                <Box sx={{
                    display: { xs: 'none', md: 'flex' },
                    mb: 0.5,
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: 1.5,
                }}>
                    <Typography
                        sx={{
                            minWidth: 120,
                            fontSize: 14,
                            color: "#777680"
                        }}>
                        Tên Cấu Hình
                    </Typography>
                    <Typography
                        sx={{
                            minWidth: 150,
                            fontSize: 14,
                            color: "#777680"
                        }}>
                        Hình Thức Đối Soát
                    </Typography>
                    <Typography
                        sx={{
                            minWidth: 150,
                            fontSize: 14,
                            color: "#777680"
                        }}>
                        Cấu Hình Cam Kết
                    </Typography>
                    <Typography
                        sx={{
                            minWidth: 150,
                            fontSize: 14,
                            color: "#777680"
                        }}>
                        Đơn Giá Niêm Yết*
                    </Typography>
                    <Typography
                        sx={{
                            minWidth: 150,
                            fontSize: 14,
                            color: "#777680"
                        }}>
                        Đơn Giá Bán*
                    </Typography>
                </Box>
                {serviceConfigoptions?.map((item, index) => {
                    console.log('service configoptions, src/components/templates/serviceform/servicepreview/serviceconfigoption', item)
                    return (
                        <Box sx={{
                            display: 'flex',
                            mb: 0.5,
                            flexDirection: { xs: 'column', md: 'row' },
                            gap: 1.5
                        }} key={index}>
                            <Typography
                                color="primary"
                                sx={{
                                    minWidth: 120,
                                    fontSize: 14,
                                    textTransform: 'uppercase'
                                }}>
                                {item.name}
                            </Typography>
                            <Typography
                                sx={{
                                    minWidth: 150,
                                    fontSize: 14
                                }}>
                                {item.method === "progressive" ? "Luỹ Tiến" : "Theo Thoả Thuận"}
                            </Typography>
                            <Typography
                                sx={{
                                    minWidth: 150,
                                    fontSize: 14
                                }}>
                                {parseFloat(item?.value) >= UNLIMITED ? "UNLIMITED" :
                                    `${format_numeral_price(item?.value)}
                                    ${PRODUCT_UNITS.slice(1).find((k) => k.value === item.product_pricing_unit)?.label}`}
                            </Typography>
                            <Typography
                                component="div"
                                sx={{
                                    minWidth: 150
                                }}>
                                <Typography sx={{
                                    display: 'inline-block',
                                    fontSize: 14,
                                    textDecoration: "underline",
                                    color: "#00000F",
                                    ml: 0.5
                                }}>
                                    đ
                                </Typography>
                                <Typography sx={{
                                    display: 'inline-block',
                                    fontSize: 14,
                                    color: "#00000F"
                                }}>
                                    {format_numeral_price(item?.pricing)}
                                </Typography>
                            </Typography>
                            <Typography
                                component="div"
                                sx={{
                                    minWidth: 150
                                }}>
                                <Typography sx={{
                                    display: 'inline-block',
                                    fontSize: 14,
                                    textDecoration: "underline",
                                    color: "#00000F",
                                    ml: 0.5
                                }}>
                                    đ
                                </Typography>
                                <Typography sx={{
                                    display: 'inline-block',
                                    fontSize: 14,
                                    color: "#00000F"
                                }}>
                                    {format_numeral_price(item?.pricing_apply)}
                                </Typography>
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
        </React.Fragment>
    )
}

export default ServiceConfigoption