import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import CustomerServiceCreditApi from "@/apis/beta/Customer/CustomerServiceCreditApi";
import ErrorHelperText from "@/components/shared/ErrorHelperText";
import { useNotificationContext } from "@/contexts/notification";
import { buildQueryFilterCondition, sleep } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import NumericFormatCustom from "@components/NumericFormatCustom";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

export const CUSTOMER_CREDIT_APPLIEDS = [
  { value: "Service", label: "Ký Quỹ Dịch Vụ" },
  { value: "default", label: "Mặc Định" },
];
export const CUSTOMER_CREDIT_PAYMENT_METHODS = [
  { value: "banktransfer", label: "Chuyển Khoản Ngân Hàng" },
  { value: "other", label: "Khác" },
];

const CreditForm = ({
  onClose,
  type = "create",
  customerId = -1,
  defaultValues = {},
}) => {
  const queryClient = useQueryClient();
  const [submit, setSubmit] = React.useState(false);
  const { setNotification } = useNotificationContext();

  const filterableCustomerService = React.useCallback(() => {
    let queries = [
      {
        name: "tw_services.service_status",
        condition: "$eq",
        value: "active",
      },
    ];
    if (defaultValues?.credit_referrer_id !== -1) {
      queries.push({
        name: "tw_services.service_id",
        condition: "$eq",
        value: defaultValues?.credit_referrer_id,
      });
    }
    return buildQueryFilterCondition("$or", 0, queries);
  }, [defaultValues]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    // resolver: vestResolver(creditValidator),
    defaultValues: { ...defaultValues },
  });
  const {
    data: { services },
  } = CustomerApi.CustomerServices({
    customerId,
    pageSize: 99,
    query: filterableCustomerService(),
  });

  const onSubmit = async (data) => {
    const form = {
      ...data,
    };
    setSubmit(true);
    const credit =
      type === "create"
        ? await CustomerServiceCreditApi.Create({
            customerId,
            data: form,
          })
        : CustomerServiceCreditApi.Update({
            customerId,
            creditId: defaultValues?.customer_service_credit_id,
            data: _.omit(data, ["customer_service_credit_id"]),
          });
    await sleep(500);
    setSubmit(false);
    if (!credit) {
      setNotification({
        open: true,
        message: "Cập nhật tiền ký quỹ thất bại",
        serverity: "error",
      });
    }
    setNotification({
      open: true,
      message: "Cập nhật tiền ký quỹ thành công.",
    });
    handleCallback();
    return onClose();
  };

  const handleCallback = () => {
    let debug = "";
    console.log(`debug.refetchQueries.customer:${customerId}.task:started`);
    const refetchQueries = [
      "invoices.list",
      "invoices.statistic",
      "customer.invoices", // list customer invoice
      "customers-invoices.statistics", // report customer invoice
      "customer.serivce-credits", // list credits
      "customer.service-credit",
    ];
    refetchQueries?.forEach((key) => {
      debug += `refetchQueriesKey:${key}.`;
      try {
        queryClient.refetchQueries([`${key}`]);
        debug += "status:completed.";
      } catch (error) {
        debug += `status:failed.message:${error?.message}`;
      }
    });
    console.log(debug);
    console.log(`debug.refetchQueries.customer:${customerId}.task:completed`);
  };
  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <DialogTitle sx={{ fontSize: 24 }}>Tiền ký quỹ khách hàng</DialogTitle>
      <DialogContent>
        <Box component={"form"}>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: "medium",
            }}
          >
            Loại ký quỹ
          </Typography>
          <Controller
            name="credit_type"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl
                sx={{ mb: 1.5 }}
                disabled={type !== "create"}
                fullWidth
              >
                <RadioGroup
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: { xs: 0.5, md: 1.5 },
                  }}
                  value={value}
                  onChange={onChange}
                >
                  {[
                    { value: "deposit", label: "Thêm ký quỹ" },
                    { value: "applied", label: "Trừ ký quỹ" },
                  ].map((item, index) => (
                    <FormControlLabel
                      {...item}
                      key={index}
                      control={<Radio size="small" />}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          />
          <Collapse in={watch("credit_type") === "deposit"}>
            <React.Fragment>
              <Controller
                name="payment_method"
                control={control}
                render={({ field: { value, onChange } }) => {
                  const selected = CUSTOMER_CREDIT_PAYMENT_METHODS.find(
                    (item) => item?.value === value
                  );
                  return (
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <SingleSelectCustomize
                        disabled={type !== "create"}
                        options={CUSTOMER_CREDIT_PAYMENT_METHODS}
                        onChange={(event, newValue) =>
                          onChange(newValue?.value || -1)
                        }
                        input={{
                          placeholder: "Chọn hình thức thanh toán...",
                          label: "Hình thức thanh toán *",
                        }}
                        value={selected}
                      />
                      {!!errors?.payment_method && (
                        <ErrorHelperText
                          message={errors?.payment_method?.message}
                        />
                      )}
                    </FormControl>
                  );
                }}
              />
              <Controller
                name="credit_referrer_type"
                control={control}
                render={({ field: { value, onChange } }) => {
                  const selected = CUSTOMER_CREDIT_APPLIEDS.find(
                    (item) => item?.value === value
                  );
                  return (
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <SingleSelectCustomize
                        disabled={type !== "create"}
                        options={CUSTOMER_CREDIT_APPLIEDS}
                        onChange={(event, newValue) =>
                          onChange(newValue?.value || -1)
                        }
                        input={{
                          placeholder: "Chọn hình thức ký quỹ...",
                          label: "Hình thức ký quỹ *",
                        }}
                        value={selected}
                      />
                      {!!errors?.service_referrer_id && (
                        <ErrorHelperText
                          message={errors?.service_referrer_id?.message}
                        />
                      )}
                    </FormControl>
                  );
                }}
              />
              <Collapse in={watch(`credit_referrer_type`) === "Service"}>
                <React.Fragment>
                  <Controller
                    name="credit_referrer_id"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      const selected = services
                        ?.map((item) => ({
                          value: item?.service_id,
                          label: item?.service_name,
                        }))
                        .find((item) => item?.service_id === value);
                      return (
                        <FormControl fullWidth sx={{ mb: 1 }}>
                          <SingleSelectCustomize
                            options={services?.map((item) => ({
                              value: item?.service_id,
                              label: `${item?.service_id} - ${item?.service_name}`,
                            }))}
                            onChange={(event, newValue) =>
                              onChange(newValue?.value || -1)
                            }
                            input={{
                              placeholder: "Chọn dịch vụ ký quỹ...",
                              label: "Dịch vụ *",
                            }}
                            value={selected}
                          />
                          {!!errors?.service_referrer_id && (
                            <ErrorHelperText
                              message={errors?.service_referrer_id?.message}
                            />
                          )}
                        </FormControl>
                      );
                    }}
                  />
                </React.Fragment>
              </Collapse>
            </React.Fragment>
          </Collapse>
          <Controller
            control={control}
            name="credit_amount"
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth sx={{ mt: 1 }}>
                <TextField
                  label="Số tiền *"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  value={value}
                  onChange={onChange}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  error={!!errors?.credit_amount}
                  helperText={errors?.credit_amount?.message}
                  placeholder="Nhập số tiền ký quỹ khách hàng..."
                />
              </FormControl>
            )}
          />
          <Typography sx={{ mb: 0.5, mt: 2 }}>Nội Dung</Typography>
          <Controller
            name="credit_note"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                fullWidth
                multiline
                minRows={3}
                {...field}
                placeholder="Nhập nội dung..."
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={submit}
          sx={{ height: 42 }}
          onClick={onClose}
          size="small"
        >
          Huỷ Thao Tác
        </Button>
        <Button
          variant="contained"
          disabled={submit}
          size="small"
          startIcon={
            submit ? (
              <CircularProgress size={16} sx={{ color: "#bfbfbf" }} />
            ) : undefined
          }
          sx={{ height: 42 }}
          onClick={handleSubmit(onSubmit)}
        >
          Cập Nhật
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withSuspense(CreditForm, MuiSkeleton["LinearProgress"]);
