import CompanyApi from "@/apis/beta/Company/CompanyApi";
import { CellBreakdownLink, CellLink } from "@/components/DataGrid/Cell";
import { buildQueryFilterCondition, format_date_short } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import { INDUSTRIES } from "@/libs/constants";
import DataGridCustomize from "@components/DataGrid";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { AddOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React from "react";

const List = () => {
  const initialColumns = [
    {
      field: "companies.company_id",
      headerName: "C - ID",
      width: 80,
      hideable: true,
      operators: { type: "number" },
      valueGetter: ({ row }) => {
        return {
          label: `C - ${row?.company_id}`,
          href: ["companies", row?.company_id],
        };
      },
      renderCell: ({ value }) => <CellLink data={value} />,
    },
    {
      field: "company_name",
      headerName: "Tên Công Ty",
      width: 200,
      operators: { type: "string" },
      valueGetter: ({ row }) => row?.company_name,
    },
    {
      field: "customers",
      headerName: "Khách Hàng/ Người Đại Diện",
      width: 220,
      valueGetter: ({ row }) => {
        return row?.customers?.map((customer) => ({
          href: ["/customers", customer?.customer_id],
          label: customer?.customer_name,
        }));
      },
      renderCell: ({ value }) => <CellBreakdownLink data={value} />,
    },
    {
      field: "company_industry",
      headerName: "Lĩnh Vực",
      width: 180,
      valueGetter: ({ row }) => {
        return (
          INDUSTRIES.find((x) => x?.value === row?.company_industry)?.label ||
          ""
        );
      },
    },
    {
      field: "company_address",
      headerName: "Địa Chỉ Công Ty",
      width: 180,
    },
    {
      field: "company_website",
      headerName: "Website",
      width: 200,
    },
    {
      field: "company_established_date",
      headerName: "Ngày Thành Lập",
      width: 150,
      valueGetter: ({ row }) =>
        format_date_short(row?.company_established_date),
    },
  ];

  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const filterableCompany = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery.trim() === "")
      return null;

    const queries = [
      {
        name: "companies.company_id",
        operator: "$eq",
        disabled: !parseInt(debouncedSearchQuery),
        value: parseInt(debouncedSearchQuery),
      },
      {
        name: "companies.company_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "companies.company_website",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
    ];
    return buildQueryFilterCondition("$or", 0, queries);
  }, [debouncedSearchQuery]);

  const [columns, setColumns] = React.useState([...initialColumns]);
  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const { data, isFetching } = CompanyApi.List({
    page: page?.page + 1,
    pageSize: page?.pageSize || 20,
    query: filterableCompany() || null,
  });

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 0.5,
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Box sx={{}}>
          <Typography
            sx={{
              fontSize: { xs: 18, md: 24 },
              fontWeight: "medium",
            }}
          >
            Danh sách công ty
          </Typography>
        </Box>
        <Button
          size="small"
          variant="contained"
          startIcon={<AddOutlined fontSize="small" />}
        >
          Thêm công ty
        </Button>
      </Box>
      <DataGridCustomize
        rows={
          data?.companies?.map((company) => ({
            ...company,
            id: company?.company_id,
          })) || []
        }
        columns={columns}
        loading={isFetching}
        getRowHeight={({ model }) => {
          const PADDING_OFFSET_Y = 8;
          const COUNT_CUSTOMER =
            24 * (model?.customers?.length || 0) + PADDING_OFFSET_Y;
          return Math.max(COUNT_CUSTOMER, 52);
        }}
        componentsProps={{
          toolbar: {
            store: "companies.list.hiddenColumns",
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: "companies",
            inputSearch: {
              placeholder: "Nhập ID/ Tên/ Website công ty...",
              value: searchQuery,
              onChange: setSearchQuery,
            },
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize || 20,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        rowCount={data?.pagination?.total}
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(List, { feature: "company", action: "index" }),
  MuiSkeleton["DataGrid"]
);
