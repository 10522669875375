import DayoffApi from '@/apis/beta/Employee/DayoffApi'
import { buildQueryFilterCondition } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import { useEmployeeContext } from '@contexts/employee'
import { AddOutlined, ArrowForwardOutlined, VisibilityOutlined } from '@mui/icons-material'
import { Box, Button, Paper, Skeleton, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const DayoffInformation = () => {
    const { employee } = useEmployeeContext()
    const filterableRecentDayoff = React.useCallback(() => {
        const fieldQueries = [
            {
                name: 'day_offs.day_off_status',
                operator: "$eq",
                value: 0
            },
        ]
        return buildQueryFilterCondition("$or", 0, fieldQueries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { data } = DayoffApi.List({
        query: filterableRecentDayoff(),
        pageSize: 3
    })

    const { data: roles } = DayoffApi.CurrentRoles()

    return (
        <Paper sx={{ p: 3, mb: 3 }} elevation={8}>
            <Typography sx={{
                mb: 2,
                fontSize: 14,
                fontWeight: 'medium'
            }} color="primary">
                Phiếu nghỉ phép {(roles?.leaders?.includes(employee?.employee_id) ||
                    roles?.master_inspectors?.includes(employee?.employee_id))
                    ? "đang chờ bạn xử lý" : "đang chờ xử lý của bạn"
                }({data?.pagination?.total})</Typography>
            <Box sx={{ display: 'flex', flexDirection: "column", gap: 0.5 }}>
                {data?.day_offs?.map(item => {
                    return (
                        <Typography component="div"
                            sx={{ fontSize: 14, color: "#777680" }}>
                            (DO - {item?.day_off_id}):
                            <Typography sx={{
                                display: 'inline-flex',
                                fontSize: 14,
                                ml: '4px',
                                color: "#777680"
                            }}>
                                {item?.employee_name}
                            </Typography>
                        </Typography>
                    )
                })}
            </Box>
            <Box sx={{
                mt: 1,
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 1.5,
                justifyContent: 'space-between'
            }}>
                <Link to="/dayoffs/new" replace>
                    <Button size="small"
                        sx={{ height: 36 }}
                        startIcon={<AddOutlined />}>
                        Phiếu Nghỉ
                    </Button>
                </Link>
                {(roles?.leaders?.includes(employee?.employee_id) ||
                    roles?.master_inspectors?.includes(employee?.employee_id)) ?
                    <Link to="/dayoffs" replace>
                        <Button sx={{ height: 36 }}
                            size="small"
                            endIcon={<ArrowForwardOutlined />}>
                            Quản Lý Nghỉ Phép
                        </Button>
                    </Link>
                    : <Link to="/account/dayoffs" replace>
                        <Button sx={{ height: 36 }}
                            size="small"
                            endIcon={<VisibilityOutlined />}>
                            Danh Sách Phiếu
                        </Button>
                    </Link>
                }
            </Box>
        </Paper>
    )
}

const SkeletonDayoffInformation = () => {
    return (
        <Paper sx={{ p: 3, mb: 3 }} elevation={8}>
            <Skeleton variant='text' sx={{ mb: 2, width: 170 }} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Skeleton sx={{ height: 36, width: 120, borderRadius: '4px' }} variant="outlined" />
            </Box>
        </Paper>
    )
}

export default withSuspense(DayoffInformation, SkeletonDayoffInformation)