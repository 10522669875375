export const buildServiceInformations = (informations = [], object = {}) => {
  informations?.forEach((item) => {
    let obj = {};
    switch (item?.name?.toLowerCase().trim()) {
      case "mail-address":
      case "mail-account":
        obj["account"] = item.value;
        break;
      case "mail-sent-max":
        obj["mail-sent-max"] = item.value;
        break;
      case "addon-domain":
        obj["domain"] = item.value;
        break;
      case "ip":
        obj["ip"] = item.value;
        break;
      case "storage":
        obj["storage"] = item.value;
        break;
      default:
        break;
    }
    object = Object.assign(object, obj);
  });
  return object;
};

export const buildServiceInformationColumns = (informations = []) => {
  let arrs = [];
  informations.forEach((item, index) => {
    switch (item?.name?.toLowerCase().trim()) {
      case "mail-account":
      case "mail-address":
        return arrs.push({
          field: "account",
          headerName: "Account",
          width: 120,
          renderCell: ({ value }) => (!!value ? value : ""),
        });
      case "mail-sent-max":
        return arrs.push({
          field: "mail-sent-max",
          headerName: "Dung lượng gửi tối đa",
          width: 120,
          renderCell: ({ value }) => (!!value ? value : ""),
        });
      case "addon-domain":
        return arrs.push({
          field: "domain",
          headerName: "Addon Domain",
          width: 120,
          renderCell: ({ value }) => (!!value ? value : ""),
        });
      case "ip":
        return arrs.push({
          field: "ip",
          headerName: "IP",
          width: 120,
          renderCell: ({ value }) => (!!value ? value : ""),
        });
      case "storage":
        return arrs.push({
          field: "storage",
          headerName: "Dung Lượng",
          width: 120,
          renderCell: ({ value }) => (!!value ? value : ""),
        });
      default:
        return null;
    }
  });

  const myIndex = ["account", "storage", "request-vncdn", "ip"];
  return arrs.sort(function (a, b) {
    var x = myIndex.indexOf(a?.field);
    var y = myIndex.indexOf(b?.field);

    return x > y ? -1 : x < y ? 1 : 0;
  });
};

export const buildCurrentlyServiceInformations = (
  service = {},
  metadata,
  addons = []
) => {
  console.log(`debug:service-first-setting:`, metadata);
  let currently = {
    ...metadata,
    status: "Cấu Hình Hiện Tại",
    service_start_date: service?.service_start_date,
    service_billing_startdate: service?.service_billing_startdate,
    service_billing_enddate: service?.service_billing_enddate,
    service_enddate: service?.service_duedate,
    service_tax: service?.service_tax,
    service_status: service?.service_status,
    service_contract_name: "",
  };

  addons?.forEach((addon) => {
    if (
      addon?.service_status !== "cancel" &&
      addon?.service_status !== "stop"
    ) {
      switch (addon?.service_product_name?.toLowerCase()?.trim()) {
        case "mail-account":
        case "mail-address":
          currently["account"] += addon?.service_addon_qty;
          break;
        case "mail-sent-max":
          currently["mail-sent-max"] += addon?.service_addon_qty;
          break;
        case "addon-domain":
          currently["domain"] += addon?.service_addon_qty;
          break;
        case "ip":
          currently["ip"] += addon?.service_addon_qty;
          break;
        case "storage":
          currently["storage"] += addon?.service_addon_qty;
          break;
        default:
          break;
      }
    }
  });

  return currently;
};
