import { Box, Typography } from "@mui/material";
import React from "react";

const ContactChannel = ({ channels = [] }) => {
  if (!Array.isArray(channels) || channels?.length === 0) return null;

  return (
    <Box>
      {channels?.map((channel, index) => {
        if (!channel?.contact_channel) return null;

        return (
          <Typography key={index} color="primary">
            {"- "}
            {channel?.contact_channel}
            {": "}
            <Typography component="span" color="text.black">
              {channel?.contact_channel_information}
            </Typography>
          </Typography>
        );
      })}
    </Box>
  );
};

export default ContactChannel;
