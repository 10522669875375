import { format_numeral_price } from '@/functions'
import { PRODUCT_UNITS } from '@/libs/constants'
import { Box, Typography } from '@mui/material'
import React from 'react'

const ServicePricingConfigures = ({ product_type, product_template, product_pricing_configures, ...props }) => {

    if (product_template !== "Customized") return <></>
    return (
        <React.Fragment>
            <Typography sx={{
                fontSize: 14,
                color: "#777680",
            }}>
                Thông Tin Cấu Hình Thanh Toán
            </Typography>
            <Box sx={{ p: 2, bgcolor: "#7776801F", width: '100%' }}>
                <Box sx={{
                    display: { xs: 'none', md: 'flex' },
                    mb: 0.5,
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: 1.5,
                }}>
                    <Typography
                        sx={{
                            minWidth: 282,
                            fontSize: 14,
                            color: "#777680"
                        }}>
                        Hình Thức Đối Soát
                    </Typography>
                    <Typography
                        sx={{
                            minWidth: 150,
                            fontSize: 14,
                            color: "#777680"
                        }}>
                        Cấu Hình Cam Kết
                    </Typography>
                    <Typography
                        sx={{
                            minWidth: 150,
                            fontSize: 14,
                            color: "#777680"
                        }}>
                        Đơn Giá Niêm Yết *
                    </Typography>
                    <Typography
                        sx={{
                            minWidth: 150,
                            fontSize: 14,
                            color: "#777680"
                        }}>
                        Đơn Giá Bán *
                    </Typography>
                </Box>
                <Box sx={{
                    display: { xs: 'none', md: 'flex' },
                    mb: 0.5,
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: 1.5,
                }}>
                    <Typography
                        sx={{
                            minWidth: 282,
                            fontSize: 14
                        }}>
                        {product_pricing_configures.method === "progressive" ? "Luỹ Tiến" : "Theo Thoả Thuận"}
                    </Typography>
                    <Typography
                        sx={{
                            minWidth: 150,
                            fontSize: 14
                        }}>
                        {format_numeral_price(product_pricing_configures.value)}{PRODUCT_UNITS.find((k) => k.value === product_pricing_configures.product_pricing_unit)?.label}
                    </Typography>
                    <Typography
                        component="div"
                        sx={{
                            minWidth: 150,
                            fontSize: 14
                        }}>
                        <Typography sx={{ fontSize: 14, display: 'inline-block', textDecoration: "underline" }}>
                            đ
                        </Typography>
                        {format_numeral_price(product_pricing_configures.pricing)}
                    </Typography>
                    <Typography
                        component="div"
                        sx={{
                            minWidth: 150,
                            fontSize: 14
                        }}>
                        <Typography sx={{ fontSize: 14, display: 'inline-block', textDecoration: "underline" }}>
                            đ
                        </Typography>
                        {format_numeral_price(product_pricing_configures.pricing_apply)}
                    </Typography>
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default ServicePricingConfigures