import BreadCrumb from '@components/shared/BreadCrumb'
import { Typography } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'

const Header = () => {
    const { customer_id } = useParams()
    return (
        <React.Fragment>
            <Typography sx={{ fontSize: { xs: 18, md: 24 }, fontWeight: 'medium' }}>
                Cập Nhật Thông Tin Khách Hàng
            </Typography>
            <BreadCrumb
                data={[
                    { label: "Khách Hàng", reactLink: "/customers" },
                    { label: customer_id, reactLink: `/customers/${customer_id}` },
                    { label: "Cập Nhật Thông Tin" }
                ]}
            />
        </React.Fragment>
    )
}

export default Header