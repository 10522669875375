import { Box, Chip, Grid, Paper, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'

const DAY_OF_WEEKS = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]

const Schedule = () => {

    const [today] = React.useState(moment())

    return (
        <Paper sx={{ p: 3, mb: 3 }} elevation={8}>
            <Chip size='small'
                label={`Tuần ${moment().isoWeek()} - Năm ${moment().format("YYYY")}`}
                variant="contained"
                sx={{ bgcolor: "#1a237e1f", fontWeight: "medium" }}
            />
            <Grid container spacing={2} >
                {DAY_OF_WEEKS.map((day, index) => {
                    const exactDay = moment().day(day)
                    return (
                        <Grid item xl={2.4} lg={2.4} md={3} sm={3} xs={4} key={index}>
                            <Box sx={{
                                borderRadius: '4px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                height: '50px',
                                p: 0.5,
                                bgcolor: exactDay.get('D') === today.get('D') ? "#9e9e9e1f"
                                    : "#1a237e1f",
                                border:
                                    `1px solid ${exactDay.get('D') === today.get('D') ? "rgb(26, 35, 126)" : "#FFF"}`
                            }}>
                                <Box>
                                    <Typography sx={{
                                        fontSize: 10,
                                        fontWeight: 'medium'
                                    }}>
                                        {day}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14, m: 'auto auto 0 0' }}>{exactDay.format("DD")}
                                        <sup style={{ fontSize: 10 }}>th</sup>
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    borderRadius: '100%', height: '5px', width: '5px',
                                    bgcolor: exactDay.get('D') === today.get('D') ? "#ef5350" : "#FFF"
                                }} />
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </Paper>
    )
}

export default Schedule