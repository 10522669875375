import { CONTACT_STATUS_TYPES } from "@/libs/constants";
import FormatterStatus from "@components/DataGrid/Cell/FormatterStatus";
import { Box } from "@mui/material";
import { useMemo } from "react";

const ContactStatus = ({ value }) => {
  const data = useMemo(() => {
    return CONTACT_STATUS_TYPES?.find((x) => x.value === value) || null;
  }, [value]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        alignItems: "center",
      }}
    >
      <FormatterStatus
        component="muiChip"
        data={data || CONTACT_STATUS_TYPES[3]}
      />
    </Box>
  );
};

export default ContactStatus;
