import { requestWithToken } from "@/functions";
import { API_SERVICE_URL } from "@/libs/constants";
import { useQuery } from "react-query";
import qs from 'query-string'

async function resolveLMSTranscoding(url = '') {
    const response = await requestWithToken(url)

    return response?.data?.data
}

export function resolveServiceAnalyticLMSTranscodingUsages(service_uuid, query) {
    const current = qs.stringify(query);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery(["customer.services.transcoding.lms.duration", service_uuid, query], () => Promise.all([
        resolveLMSTranscoding(`${API_SERVICE_URL}/viewers/services/${service_uuid}/analytic/usages/lms/durations/transcoding?${current}`)
    ]), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        staleTime: 30 * 60 * 1000,
        enabled: typeof service_uuid !== "undefined",
        suspense: true
    })
}


export function resolveServiceAnalyticTranscodingUsages(service_uuid, query) {
    const current = qs.stringify(query);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery(["customer.services.transcoding.vms.usages", service_uuid, query], () => Promise.all([
        resolveLMSTranscoding(`${API_SERVICE_URL}/viewers/services/${service_uuid}/analytic/usages/vms/transcoding?${current}`)
    ]), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        staleTime: 30 * 60 * 1000,
        enabled: typeof service_uuid !== "undefined",
        suspense: true
    })
}



export function resolveServiceLMSNoofLiveJobs(service_uuid, query) {
    const current = qs.stringify(query);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery(["customer.services.lms.no_of_live_jobs", service_uuid, query], () => Promise.all([
        resolveLMSTranscoding(`${API_SERVICE_URL}/viewers/services/${service_uuid}/analytic/usages/no_of_live_jobs?${current}`)
    ]), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        staleTime: 30 * 60 * 1000,
        enabled: typeof service_uuid !== "undefined",
        suspense: true
    })
}


export function resolveServiceAnalyticLMSTranscodingDurationofLives(service_uuid, query) {
    const current = qs.stringify(query);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery(["customer.services.transcoding.lms.duration_of_lives", service_uuid, query], () => Promise.all([
        resolveLMSTranscoding(`${API_SERVICE_URL}/viewers/services/${service_uuid}/analytic/usages/lms/durations/transcoding?${current}`)
    ]), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        staleTime: 30 * 60 * 1000,
        enabled: typeof service_uuid !== "undefined",
        suspense: true
    })
}
// transcoding/durations

export function resolveServiceAnalyticLMSTranscodingDurations(service_uuid, query) {
    if (!query?.gte || !query?.lte) return []
    const current = qs.stringify(query);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery(["customer.services.lms.transcoding.durations", service_uuid, query], () => Promise.all([
        resolveLMSTranscoding(`${API_SERVICE_URL}/viewers/services/${service_uuid}/analytic/usages/lms/transcoding/durations?${current}`)
    ]), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        staleTime: 30 * 60 * 1000,
        enabled: typeof service_uuid !== "undefined",
        suspense: true
    })
}