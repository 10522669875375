import { encodeBase64, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";

class WarehouseBoxApi {
  getAll = () =>
    useQueryWithCustomized(
      ["warehouse.boxes.all"],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_boxes/list`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return (
              data?.data?.warehouse_boxes?.map((o) => ({
                value: o.warehouse_box_id,
                label: o.warehouse_box_name,
                type:
                  o.warehouse_box_type !== 0 ? "WarehouseRack" : "WarehouseBox",
                ...o,
              })) || []
            );
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );

  index = ({
    query = null,
    searchQuery = null,
    page = 1,
    pageSize = 20,
    type,
  }) =>
    useQueryWithCustomized(
      ["warehouse.warehouse_boxes.list", query, searchQuery, page, pageSize],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_boxes?${
            query ? `&filters=${encodeBase64(query)}` : ""
          }${
            searchQuery ? `&queries=${searchQuery}` : ""
          }&page=${page}&page_size=${pageSize}&warehouse_box_type=${type}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              warehouse_boxes: data?.data?.warehouse_boxes.map((o) => ({
                id: o.warehouse_box_id,
                ...o,
              })),
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            return {
              warehouse_boxes: [],
              error: error.response,
            };
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );

  racks = (id) =>
    useQueryWithCustomized(
      ["warehouse.racks.all", id],
      async () => {
        if (!id) return null;

        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_boxes/${id}/racks`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              warehouse_racks: data?.data?.warehouse_racks?.map((o) => ({
                value: o.warehouse_rack_id,
                label: o.warehouse_rack_name,
                type: "WarehouseRack",
                ...o,
              })),
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        // suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );

  requestRacks = async (id) =>
    await requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_boxes/${id}/racks`
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return {
          warehouse_racks: data?.data?.warehouse_racks?.map((o) => ({
            value: o.warehouse_rack_id,
            label: o.warehouse_rack_name,
            type: "WarehouseRack",
            ...o,
          })),
        };
      })
      .catch((error) => {
        console.log(error.response);
        return { warehouse_racks: [] };
      });

  regions = () =>
    useQueryWithCustomized(
      ["warehouse.regions.all"],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_boxes/warehouse_box_regions`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              warehouse_box_regions: data?.data?.warehouse_box_regions?.map(
                (o) => ({
                  value: o.warehouse_box_region,
                  label: o.warehouse_box_region,
                  ...o,
                })
              ),
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        // suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );

  create = (params) =>
    requestWithToken(`${API_WAREHOUSE_V1_URL}/warehouse_boxes`, "POST", {
      warehouse_box: params,
    });
  update = (id, params) =>
    requestWithToken(`${API_WAREHOUSE_V1_URL}/warehouse_boxes/${id}`, "PUT", {
      warehouse_box: params,
    });

  show = (id, callback) =>
    useQueryWithCustomized(
      ["warehouse_boxes.detail", id],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_boxes/${id}`
        )
          .then(({ data }) => {
            if (data?.errors) return callback(data.errors);

            return {
              warehouse_box: data?.data?.warehouse_box,
            };
          })
          .catch((error) => {
            console.log(error);
            return error.response;
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        notifyOnChangePropsExclusions: ["isStale"],
        // suspense: true,
      }
    );
}

export default WarehouseBoxApi = new WarehouseBoxApi();
