import { Box, FormControl, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const OrderNote = ({ disabled }) => {
  const { control } = useFormContext();
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ minWidth: 300 }}>
        <Typography sx={{ fontSize: 14, color: "#777680" }}>Ghi Chú</Typography>
      </Box>
      <Controller
        name="order_note"
        control={control}
        render={({ field }) => {
          return (
            <FormControl fullWidth>
              <TextField
                multiline
                {...field}
                disabled={disabled}
                placeholder="Nhập thông tin ghi chú..."
              />
            </FormControl>
          );
        }}
      />
    </Box>
  );
};

export default OrderNote;
