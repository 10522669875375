import { useNotificationContext } from "@/contexts/notification";
import {
	format_date_short,
	queryClientRefetcher
} from "@/functions";
import { UNLIMITED } from "@/libs/constants";
import NumericFormatCustom from "@components/NumericFormatCustom";
import FormCustomized from "@components/shared/FormCustomized";
import ServiceTimes from "@components/Templates/ServiceForm/modules/SwiftFederation/shared/ServiceTimes";
import { vestResolver } from "@hookform/resolvers/vest";
import {
	Box,
	Button,
	CircularProgress,
	Typography
} from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import ProductAddon from "./ProductAddon";
import serviceAddonValidator from "./validator";

const ServiceAddonForm = ({
	service,
	handleClose,
	defaultValues,
	onSubmit,
	type = "create",
}) => {

	const queryClient = useQueryClient();
	const { setNotification } = useNotificationContext();

	const [submit, setSubmit] = useState(false);

	const methods = useForm({
		resolver: vestResolver(serviceAddonValidator),
		defaultValues: defaultValues,
	});
	const { handleSubmit } = methods;

	const cols = { xs: 12, md: 12, xl: 12 }
	const SERVICE_ADDON_FIELDS = [
		{
			name: 'service_start_date', component: 'DateField', cols,
			params: { label: 'Ngày bắt đầu sử dụng *' }
		}, {
			name: 'service_addon_qty', component: 'InputUnlimitCustomized', cols,
			params: { label: 'Thông Số/ Số Lượng Mua *' }
		},
		{
			name: 'service_price', component: 'TextField', cols: { xs: 12, md: 6, xl: 6 },
			params: {
				label: 'Đơn Giá Bán *', placeholder: "Nhập đơn giá bán dịch vụ...",
				InputProps: { inputComponent: NumericFormatCustom }
			}
		},
		{
			name: 'service_amount', component: 'TextField', cols: { xs: 12, md: 6, xl: 6 }, params: {
				label: 'Tạm Tính *', placeholder: "Nhập giá tạm tính dịch vụ...",
				InputProps: { inputComponent: NumericFormatCustom }
			}
		},
		{
			name: 'service_note', component: 'TextField', cols, params: {
				label: 'Ghi Chú *', placeholder: "Nhập thông tin ghi chú dịch vụ...", multiline: true
			}
		},
		{
			name: 'service_tax', component: 'Checkbox', cols, params: {
				label: 'Thuế (VAT = 10%)'
			}
		},
	]
	const handleClickSubmit = async (values) => {
		setSubmit(true)
		let form = _.pick(values, [
			'service_product_id',
			'service_product_name',
			'service_name',
			'service_start_date',
			'service_price',
			'service_addon_qty',
			'service_note',
			'service_tax',
			'service_type'
		])
		form = {
			...form,
			service_configoptions: values?.product_configoptions || [],
			service_start_date: format_date_short(form?.service_start_date?.$d),
			service_management_billing: {
				service_billing_enddate: format_date_short(
					values?.service_management_billing?.service_billing_enddate?.$d
				),
				service_billing_startdate: format_date_short(
					values?.service_management_billing?.service_billing_startdate?.$d
				),
				service_nextbill_enddate: format_date_short(
					values?.service_management_billing?.service_nextbill_enddate?.$d
				),
				service_nextbill_startdate: format_date_short(
					values?.service_management_billing?.service_nextbill_startdate?.$d
				),
			},
		};

		const response = await onSubmit(form);
		setSubmit(false)
		queryClientRefetcher(queryClient, [
			"service.informations",
			"services.addons.list",
			"service.logger",
			"service.invoices",
			"customer.invoices",
		]);
		handleClose();
		return setNotification({
			open: true,
			message: response?.message,
			severity: response?.status,
		});
	};

	useEffect(() => {
		if (parseFloat(methods.watch(`service_addon_qty`)) >= UNLIMITED) return;

		methods.setValue(
			`service_amount`,
			_.toNumber(methods.watch(`service_price`)) * methods.watch(`service_addon_qty`)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [methods.watch(`service_price`), methods.watch(`service_addon_qty`)])

	return (
		<FormProvider {...methods}>
			<Box
				sx={{ p: 3, mb: 3 }}
				component="form"
				onSubmit={handleSubmit(handleClickSubmit)}
			>
				<Typography
					sx={{
						fontSize: 18,
						textTransform: "uppercase",
						fontWeight: "medium",
						mb: 2
					}}
				>
					{type === "create"
						? "Thêm sản phẩm/ dịch vụ mua thêm"
						: "Chỉnh sửa sản phẩm/ dịch vụ mua thêm"}
				</Typography>
				<ProductAddon service={service} />
				<FormCustomized attributes={SERVICE_ADDON_FIELDS} />
				<ServiceTimes />
				<Box
					sx={{
						display: "flex",
						flexDirection: { xs: "column", md: "row" },
						gap: 2,
						justifyContent: "flex-end",
						mt: 2,
					}}
				>
					<Button
						disabled={submit}
						size="small"
						onClick={handleClose}
						sx={{
							height: {
								xs: 36,
								md: 42,
							},
						}}
						variant="text"
					>
						Huỷ Thao Tác
					</Button>
					<Button
						sx={{
							height: {
								xs: 36,
								md: 42,
							},
							minWidth: 150,
						}}
						type="submit"
						size="small"
						disabled={submit}
						startIcon={
							submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
						}
						variant="contained"
					>
						{type === "create" ? "Thêm mới" : "Cập nhật"}
					</Button>
				</Box>
			</Box>
		</FormProvider>
	);
};

export default ServiceAddonForm;
