import EmployeeApi from "@/apis/beta/Employee/EmployeeApi"
import SandboxApi from "@/apis/beta/Sandbox/SandboxApi"
import { format_date_time, sleep } from "@/functions"
import withPermission from "@/hocs/withPermission"
import withSuspense from "@/hocs/withSuspense"
import MuiSkeletonDefault from "@components/Skeletons/Default"
import { useNotificationContext } from "@contexts/notification"
import { UpdateOutlined } from "@mui/icons-material"
import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { useParams } from "react-router-dom"
import { COUNTRIES } from '@/libs/constants'

const Profile = () => {
    const { setNotification } = useNotificationContext()
    const [submit, setSubmit] = React.useState(false)
    const { employee_id } = useParams()
    const { data: employee } = EmployeeApi.Detail(employee_id)

    const employee_information = employee?.employee_information
    const [provinces] = SandboxApi.Provinces(employee_information?.employee_country)

    const selectedProvinceTemporary = provinces?.find(province =>
        province.value === employee_information?.employee_temporary_address_city || province.isSelected
    )?.value
    const selectedProvincePermanent = provinces?.find(province =>
        province.value === employee_information?.employee_permanent_address_city || province.isSelected
    )?.value

    const [districts] = selectedProvinceTemporary ? SandboxApi.Districts(selectedProvinceTemporary) : []
    const [districtsPermanent] = selectedProvincePermanent ? SandboxApi.Districts(selectedProvincePermanent) : []

    const selectedDistrictTemporary = districts?.find(district =>
        district.value === employee_information?.employee_temporary_address_district
    );
    const selectedDistrictPermanent = districtsPermanent?.find(district =>
        district.value === employee_information?.employee_permanent_address_district
    );

    const [wardsTemporary] = selectedDistrictTemporary ? SandboxApi.Wards(selectedProvinceTemporary, selectedDistrictTemporary.value) : [];
    const [wardsPermanent] = selectedDistrictPermanent ? SandboxApi.Wards(selectedProvincePermanent, selectedDistrictPermanent.value) : [];

    const employeeInformation = () => {
        return [
            {
                label: 'Quốc Tịch',
                value: COUNTRIES?.find(i => i.value === employee_information?.employee_nationality)?.label ?? employee?.employee_information?.employee_nationality ?? 'Chưa có thông tin'
            }, {
                label: 'Quốc Gia',
                value: COUNTRIES?.find(i => i.value === employee_information?.employee_country)?.label ?? employee?.employee_information?.employee_country ?? 'Chưa có thông tin'
            }, {
                label: 'Dân Tộc',
                value: employee?.employee_nation?.label ?? employee?.employee_information?.employee_nation ?? 'Chưa có thông tin'
            }, {
                label: 'Tôn Giáo',
                value: employee?.employee_religion?.label ?? employee?.employee_information?.employee_religion ?? 'Chưa có thông tin'
            }, {
                label: 'Địa Chỉ Tạm Trú (Số Nhà/ Căn Hộ - Tên Đường/ Hẻm/ Nghõ)',
                value: employee?.employee_information?.employee_temporary_address,
            }, {
                label: 'Tỉnh/ Thành Phố',
                value: provinces?.find(i => i.value === employee_information?.employee_temporary_address_city)?.label ?? 'Chưa có thông tin'
            }, {
                label: 'Quận/ Huyện',
                value: employee?.employee_temporary_address_district ?? districts?.find(i => i.value === employee?.employee_information?.employee_temporary_address_district)?.label ?? 'Chưa có thông tin'
            }, {
                label: 'Phường/ Xã',
                value: employee?.employee_temporary_address_ward ?? wardsTemporary?.find(i => i.value === employee?.employee_information?.employee_temporary_address_ward)?.label ?? 'Chưa có thông tin'
            }, {
                label: 'Địa Chỉ Thường Trú (Số Nhà/ Căn Hộ - Tên Đường/ Hẻm/ Nghõ)',
                value: employee?.employee_information?.employee_permanent_address,
            }, {
                label: 'Tỉnh/ Thành Phố',
                value: provinces?.find(i => i.value === employee_information?.employee_permanent_address_city)?.label ?? 'Chưa có thông tin'
            }, {
                label: 'Quận/ Huyện',
                value: districtsPermanent?.find(i => i.value === employee?.employee_information?.employee_permanent_address_district)?.label ?? employee?.employee_permanent_address_district ?? 'Chưa có thông tin'
            }, {
                label: 'Phường/ Xã',
                value: wardsPermanent?.find(i => i.value === employee?.employee_information?.employee_permanent_address_ward)?.label ?? employee?.employee_permanent_address_ward ?? 'Chưa có thông tin'
            }
        ]
    }
    const requestUpdateEmployeeInformation = async () => {
        setSubmit(true)
        await sleep(500)
        setSubmit(false)
        return setNotification({
            open: true, message: "Tính năng đang được cập nhật.", severity: 'success'
        })
    }

    return (

        <Box sx={{
            border: '1px solid rgba(0, 0, 0, 0.18)',
            borderRadius: "4px",
            p: 3
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 0.5,
                mb: { xs: 1, md: 2 }
            }}>
                <Typography sx={{
                    fontWeight: 'medium',
                    textTransform: "uppercase"
                }}>Thông tin chi tiết</Typography>
                <Typography component="div" sx={{
                    fontSize: 12,
                    color: "#777680",
                    m: 'auto 0 0 0'
                }}>Cập nhật lần cuối [{format_date_time(employee?.employee_updated_at)}]</Typography>
            </Box>
            <Grid container spacing={2}>
                {Array.from(employeeInformation())?.map((item, index) => {
                    return (
                        <Grid item xs={12} md={6} key={index}>
                            <Typography component="div"
                                sx={{
                                    mb: 0.5,
                                    fontSize: 14,
                                    color: '#777680'
                                }}>{item?.label}
                            </Typography>
                            <TextField size='small'
                                value={item?.value || "Chưa có thông tin"}
                                disabled
                                fullWidth />
                        </Grid>
                    )
                })}
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                <Button size="small" variant="outlined"
                    sx={{ height: { xs: 36, md: 42 } }}
                    startIcon={submit ? <CircularProgress size={14} sx={{ color: "#777680" }} /> : <UpdateOutlined />}
                    disabled={submit}
                    onClick={requestUpdateEmployeeInformation}
                >
                    Yêu cầu cập nhật
                </Button>
            </Box>
        </Box>
    )
}

export default withSuspense(withPermission(Profile, { feature: "employee", action: "profile", type: "component" }), MuiSkeletonDefault)