import withPermission from '@/hocs/withPermission';
import ContractFormEdit from '@features/Module/Contract/shared/ContractFormEdit';
import { EditOutlined } from '@mui/icons-material';
import { Drawer, IconButton } from '@mui/material';
import React, { Fragment, useState } from 'react'

const ContractEdit = ({ contract }) => {
    const [openDrawer, setDrawer] = useState(false);

    return (
        <Fragment>
            {openDrawer && (
                <Drawer anchor="right" open={openDrawer} sx={{ width: 600 }}>
                    <ContractFormEdit contract={contract} handleClose={() => setDrawer(false)} />
                </Drawer>
            )}
            <IconButton size="small" sx={{ bgcolor: "#7776801F", height: 40, width: 40 }}
                disabled={openDrawer}
                onClick={() => setDrawer(true)}
            >
                <EditOutlined
                    color="primary"
                    fontSize="small"
                />
            </IconButton>
        </Fragment>
    )
}

export default withPermission(ContractEdit, { feature: 'contract', action: 'customer-contract-update', type: 'Button' })