import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";
import qs from 'query-string'

class DriveApi {
  Show = async ({ path, size, marker = null }) => {
    let query = {}

    if (path) {
      query.path = path
    }

    if (marker) {
      query.marker = marker
    }

    if (size) {
      query.size = size
    }

    const response = await requestWithToken(
      `${API_V3_URL}/drives?${qs.stringify(query)}`
    );
    return response?.data;
  };

  GetPresignedUrl = async ({ key, expires_in }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/drives/presigned_url_get`,
      "POST",
      { key, expires_in }
    );

    return response?.data;
  };

  Create = async (key) => {
    const response = await requestWithToken(`${API_V3_URL}/drives`, "POST", {
      type: "folder",
      file: null,
      key: key,
    });

    return response?.data;
  };

  PresignedUrl = async (key) => {
    const response = await requestWithToken(
      `${API_V3_URL}/drives/presigned_url`,
      "POST",
      {
        key: key,
      }
    );

    return response?.data;
  };

  GetDriveShareEmployee = (path) =>
    useQueryWithCustomized(
      ["drives.shared", path],
      async () => {
        return await requestWithToken(`${API_V3_URL}/drives/share?path=${path}`)
          .then(({ data }) => {
            if (data?.error || data?.errors) return data;
            return data
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        suspense: true
      }
    );

  AddDriveShare = async (values) => {
    const response = await requestWithToken(
      `${API_V3_URL}/drives/share`,
      "POST",
      values
    );
    return response?.data;
  };

  Delete = async (key) => {
    const response = await requestWithToken(`${API_V3_URL}/drives`, "DELETE", {
      key: key,
      batch_delete: true,
    });

    return response?.data;
  };

  Rename = async ({ old_prefix, new_prefix }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/drives/rename`,
      "PUT",
      { old_prefix, new_prefix }
    );
    return response?.data;
  };

  RenameFile = async ({ old_prefix, new_prefix }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/drives/rename_file`,
      "PUT",
      { old_prefix, new_prefix }
    );
    return response?.data;
  };

  Zipping = async ({ path, password }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/drives/zipped`,
      "POST",
      {
        path,
        password,
      }
    );

    return response?.data;
  };
}

export default DriveApi = new DriveApi();
