import { format_date_short } from '@/functions';
import { Paper, Typography } from '@mui/material';
import React from 'react';
import ManageList from './Manage';
import ManageEmployee from './ManageEmployee';
import Title from './Title';

const Detail = ({ data }) => {

    return (
        <Paper sx={{ p: '24px 16px 24px 24px', height: '100%' }}>
            <Title name={data?.group_permission_name} data={data} />
            <ManageList roles={data?.permissions} name={data?.group_permission_name} />
            <ManageEmployee assigneeIds={data?.group_permission_assignee_employee_ids} />
            <Typography sx={{ fontSize: 14, mt: 2 }} color="custom.grey">
                Tạo ngày: {format_date_short(data?.created_at)}
            </Typography>
        </Paper>
    )
}
export default Detail