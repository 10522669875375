import OS from "./OS"
import DATACENTER from "./Datacenter"
import Bandwidth from "./Bandwidth"
import Ram from "./Ram"
import Disk from "./Disk"
import Software from "./Software"
import IP from "./IP"
import BackupCloudStorage from "./BackupCloudStorage"
import MailStorage from "./MailStorage"
import CloudSecurities from "./CloudSecurities"
import SSL from "./SSL"

const Configoptions = {
    OS: OS,
    DATACENTER: DATACENTER,
    BANDWIDTH: Bandwidth,
    RAM: Ram,
    DISK: Disk,
    SOFTWARE: Software,
    IP: IP,
    CLOUDSTORAGE: BackupCloudStorage,
    STORAGE: MailStorage,
    CLOUDSECURITIES: CloudSecurities,
    SSL: SSL
}

export default Configoptions