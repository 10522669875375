import ProductApi from "@/apis/beta/Product/ProductApi";
import ProductFormTemplate from "@/components/Templates/ProductForm";
import { sleep } from "@/functions";
import { useNotificationContext } from "@contexts/notification";
import { vestResolver } from "@hookform/resolvers/vest";
import { Box, Button, CircularProgress, Collapse, Paper } from "@mui/material";
import _ from "lodash";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProductDescription from "../ProductDescription";
import ProductGroup from "../ProductGroup";
import ProductName from "../ProductName";
import ProductSubtitle from "../ProductSubtitle";
import ProductType from "../ProductType";
import productValidator from "./validator";

const ProductForm = ({ defaultValues, type = "create", product = {} }) => {
  const { product_id } = useParams();
  let navigate = useNavigate();

  const [submit, setSubmit] = React.useState(false);

  const { setNotification } = useNotificationContext();
  const methods = useForm({
    reValidateMode: "onChange",
    resolver: vestResolver((props) =>
      productValidator({
        ...props,
        action: type,
        product_name_old: product?.product_name,
      })
    ),
    defaultValues: { ...defaultValues },
  });
  const onSubmit = async (values) => {
    setSubmit(true);
    handlerSaveChange();

    let form = {
      ..._.pick(values, [
        "product_group_id",
        "product_name",
        "product_subtitle",
        "product_type",
        "product_description",
        "product_informations",
        "product_addons",
        "product_template",
      ]),
    };
    if (values.product_template === "PrivateCDN") {
      form.product_configoptions = values.product_configoptions;
    } else {
      if (values.product_type === "customized") {
        form.product_pricing_configures = values.product_pricing_configures;
      } else {
        form.product_billing_configures = {};
        values?.product_billing_configures?.forEach((configure) => {
          form.product_billing_configures[configure?.name] = configure?.pricing;
        });
      }
    }

    const response =
      type === "create"
        ? await ProductApi.Create({ data: form })
        : await ProductApi.Update({ data: form, id: product_id });
    await sleep(500);
    setSubmit(false);
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message || "Failed.",
        severity: "error",
      });
    }
    setNotification({
      open: true,
      message: response?.message || "Success.",
      severity: "success",
    });
    try {
      if (type === "update") {
        localStorage.removeItem("product.update.data");
      } else {
        localStorage.removeItem("product.create.data");
      }
    } catch (error) {
      console.log(error?.message);
    }
    return navigate("/products", { replace: true });
  };

  const handlerSaveChange = () => {
    const metadata = JSON.stringify(methods.getValues());
    if (metadata === JSON.stringify(defaultValues)) return;
    localStorage.setItem(
      type === "update" ? "product.update.data" : "product.create.data",
      metadata
    );
  };

  return (
    <FormProvider {...methods}>
      <Paper
        component="form"
        onSubmit={methods.handleSubmit(onSubmit)}
        sx={{ p: 3 }}
      >
        <ProductGroup type={type} />
        <ProductName />
        <ProductSubtitle />
        <ProductType />
        {methods.watch("product_template") === "PrivateCDN" && (
          <Collapse in={methods.watch("product_template") === "PrivateCDN"}>
            <ProductFormTemplate.PrivateCDN />
          </Collapse>
        )}
        {methods.watch("product_template") !== "PrivateCDN" && (
          <Collapse
            in={
              methods.watch("product_template") !== "PrivateCDN" &&
              !!methods.watch("product_template")
            }
          >
            <ProductFormTemplate.Normal />
          </Collapse>
        )}
        <ProductDescription />
        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "flex-end",
            gap: 1.5,
          }}
        >
          <Link to="/products" replace>
            <Button
              size="small"
              variant="outlined"
              sx={{ height: { xs: 36, md: 42 }, minWidth: 150 }}
              disabled={submit}
            >
              Huỷ Thao Tác
            </Button>
          </Link>
          <Button
            variant="contained"
            size="small"
            type="submit"
            // disabled={submit}
            sx={{ height: { xs: 36, md: 42 }, minWidth: 150 }}
            startIcon={
              submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
            }
          >
            {type === "update" ? "cập nhật" : "Thêm Sản Phẩm"}
          </Button>
        </Box>
      </Paper>
    </FormProvider>
  );
};

export default ProductForm;
