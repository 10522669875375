import CVApi from "@/apis/beta/Employee/CVApi";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import DataGridCustomize from "@components/DataGrid";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
import useDataGridParams from "@/hooks/useDataGridParams";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { format_date_short } from "@/functions";
import { blue } from "@mui/material/colors";
import ScreenResults from "./ScreenResults";
import FinalStatus from "./FinalStatus";
import { usePreviewFileContext } from "@contexts/preview-file";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { useConfirmContext } from "@contexts/confirm";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import DataGridFilter from "@components/DataGrid/Filter";
import { Filter } from "@/hooks/useFilters";

const List = () => {
  const preview = usePreviewFileContext();
  const { showConfirmation } = useConfirmContext();

  const {
    mutateAsync: DestroyMutate,
    isLoading: DestroyLoading,
    notify,
  } = useMutationWithNotification(CVApi.Destroy);

  const { data: jobs } = useQueryWithCustomized(["get-job"], async () => {
    const response = await fetch(`${process.env.REACT_APP_JOBS}`);
    const data = await response.json();
    return data;
  });

  const { data: sources } = useQueryWithCustomized(
    "get-cv-source",
    CVApi.GetAllCvSource
  );

  const initialColumns = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
      operators: {
        type: "string",
      },
    },
    {
      field: "job_title",
      headerName: "Position Applied",
      width: 250,
      operators: {
        type: "object",
        collections: jobs?.data?.map(({ attributes }) => ({
          label: attributes?.title,
          value: attributes?.title,
        })),
      },
    },
    {
      field: "address",
      headerName: "Address",
      width: 180,
    },
    {
      field: "day_of_birth",
      headerName: "DayOfBirth",
      width: 180,
      renderCell: ({ row }) =>
        row?.day_of_birth ? (
          <Typography fontSize={14}>
            {format_date_short(row?.day_of_birth)}
          </Typography>
        ) : null,
    },
    {
      field: "created_at",
      headerName: "Date Apply",
      width: 120,
      renderCell: ({ row }) => (
        <Typography fontSize={14}>
          {format_date_short(row?.created_at)}
        </Typography>
      ),
    },
    {
      field: "cvs.cv_source_id",
      headerName: "Source",
      width: 120,
      renderCell: ({ row }) => (
        <Typography fontSize={14}>
          {row?.cv_source_name || row?.source}
        </Typography>
      ),
      operators: {
        type: "object",
        collections:
          sources?.data?.map((item) => ({
            label: item?.cv_source_name,
            value: item?.cv_source_id,
          })) || [],
      },
    },
    {
      field: "cv_screen_results",
      headerName: "CV Screen Results",
      width: 180,
      renderCell: ({ row }) => <ScreenResults row={row} refetch={refetch} />,
    },
    {
      field: "final_status",
      headerName: "Final Status",
      width: 180,
      renderCell: ({ row }) => <FinalStatus refetch={refetch} row={row} />,
    },
    {
      field: "url",
      headerName: "Resume/ Profile CV",
      width: 180,
      renderCell: ({ row }) => (
        <Typography
          onClick={(e) => {
            e.stopPropagation();
            if (!row?.url) return;

            row?.type_file === "file"
              ? preview.show("submit_cv/" + row?.url)
              : window.open(row.url);
          }}
          fontSize={14}
          style={{
            color: blue[500],
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Xem chi tiết
        </Typography>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: ({ row }) => (
        <IconButton>
          <DeleteOutline
            onClick={(e) => {
              e.stopPropagation();
              showConfirmation({
                title: "Bạn có chắc muốn xoá CV này",
                open: true,
                description: "Thao tác này không thể khôi phục",
                loading: DestroyLoading,
                handler: async () => {
                  const response = await DestroyMutate({ id: row?.id });
                  if (!response?.errors) {
                    refetch();
                    notify("success", "Xoá thành công");
                  }
                },
                onCancel: () => { },
              });
            }}
            color="error"
          />
        </IconButton>
      ),
    },
  ]

  const {
    columns,
    debouncedSearchQuery,
    page,
    searchQuery,
    setColumns,
    setPage,
    setSearchQuery,
    updateQuery,
    query,
  } = useDataGridParams(initialColumns, 20, "resume.list.filters");

  const router = useNavigate();

  const { data, isFetching, refetch } = useQueryWithCustomized(
    `get-list-cv-${JSON.stringify(page)}-${debouncedSearchQuery}-${JSON.stringify(query)}`,
    () =>
      CVApi.getList({
        page: page?.page + 1,
        pageSize: page?.pageSize,
        q: debouncedSearchQuery,
        query: Filter.stringify(query),
      })
  );

  const filterables = initialColumns
    ?.filter((column) => !!column?.operators && !column?.hide)
    .map((column) => {
      return {
        field: column?.key ? column?.key : column?.field,
        label: column?.headerName,
        operators: column?.operators,
      };
    });
  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          sx={{
            fontSize: { xs: 18, md: 24 },
            fontWeight: "medium",
          }}
        >
          Danh Sách CV Ứng Viên
        </Typography>

        <Link to="/resume/new">
          <Button variant="contained" size="small">
            Thêm CV
          </Button>
        </Link>
      </Box>

      <DataGridFilter
        callback={updateQuery}
        filters={{
          key: "resume.list.filters",
          filterables: filterables,
        }}
      />

      <DataGridCustomize
        onRowClick={({ row }) => router(`/resume/${row?.id}`)}
        rows={data?.data || []}
        columns={columns}
        loading={isFetching}
        rowCount={data?.pagination?.total_pages * page?.pageSize || 0}
        componentsProps={{
          toolbar: {
            store: "resume.list.hiddenColumns",
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: "resume",
            inputSearch: {
              placeholder: "Nhập tên ứng viên, email, tên công việc,...",
              value: searchQuery,
              onChange: setSearchQuery,
            },
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize || 20,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(List, { feature: "resume", action: "get-list-cv" }),
  MuiSkeleton["DataGrid"]
);