import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import { buildQueryFilterCondition } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import MultipleSelectCustomize from "@components/shared/MultipleSelectCustomize";
import { FormControl } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

const Employee = ({
    name,
    value = [],
    onChange,
    queries = [],
    ...props
}) => {
    const [searchQuery, setSearchQuery] = useState("");
    const debouncedSearchQuery = useDebounce(searchQuery, 400);

    const offsetFilterables = useCallback(() => {
        if (!queries) return null;
        return buildQueryFilterCondition('$or', 0, queries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queries]);

    const filterables = useCallback(() => {
        if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
        let fieldQueries = [
            {
                name: "employees.employee_id",
                operator: "$eq",
                disabled: !parseInt(debouncedSearchQuery),
                value: parseInt(debouncedSearchQuery),
            },
            {
                name: "employee_name",
                operator: "$contains",
                value: debouncedSearchQuery,
            },
            {
                name: "employee_code",
                operator: "$contains",
                value: debouncedSearchQuery,
            },
        ];
        return buildQueryFilterCondition("$or", 0, fieldQueries);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery]);

    const { data: employees, isFetching } = EmployeeApi.ListEmployees({
        query: offsetFilterables(),
        searchQuery: filterables(),
    });

    const selecteds = useMemo(() => {
        if (!value || value.length === 0) return [];

        return (
            employees
                ?.filter((e) => value?.includes(e?.employee_id))
                ?.map((employee) => {
                    return {
                        value: employee?.employee_id,
                        label: employee?.employee_name,
                    };
                }) || []
        );
    }, [value, employees]);
    const exectOptions = useMemo(() => {
        return employees?.map((e) => {
            return {
                value: e?.employee_id,
                label: e?.employee_name,
            };
        });
    }, [employees]);

    return (
        <FormControl fullWidth>
            <MultipleSelectCustomize
                limitTags={3}
                value={selecteds}
                options={exectOptions}
                onChange={(_, newValue) =>
                    onChange(newValue?.map((x) => x?.value) || [])
                }
                input={{
                    placeholder: "Nhập tên nhân viên tìm kiếm...",
                    value: searchQuery,
                    onChange: (event) => setSearchQuery(event?.target?.value),
                    ...props?.input,
                }}
                sx={{
                    "&.MuiAutocomplete-option": {
                        textTransform: "uppercase",
                    },
                }}
                loading={isFetching}
                mode="server"
            />
        </FormControl>
    );
};

export default withSuspense(Employee, MuiSkeleton["TextField"]);