import { FormControl, TextField } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ScopeName = () => {

    const { control } = useFormContext()
    return (
        <Controller
            control={control}
            name="scope_name"
            render={({ field }) => (
                <FormControl fullWidth>
                    <TextField {...field} label="Tên *" InputLabelProps={{ shrink: true }} size='small'
                        placeholder='Đặt tên phân quyền dữ liệu truy cập...'
                    />
                </FormControl>
            )}
        />
    )
}

export default ScopeName