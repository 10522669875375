import {
  blueGrey,
  green,
  grey,
  indigo,
  lightBlue,
  orange,
  red,
  purple,
} from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const breakpoints = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default createTheme({
  ...breakpoints,
  palette: {
    primary: {
      light: indigo[500],
      main: indigo[900],
      contrastText: "#ffffff",
    },
    common: {
      white: "#fff",
      grey: "#777980",
    },
    warning: {
      main: orange[500],
      contrastText: "#ffffff",
    },
    custom: {
      grey: grey[500],
      default: "#000000",
      dedicate: green[500],
      virturalization: orange[500],
      success: green[500],
      pending: orange[500],
      error: red[500],
      blueGrey: blueGrey[500],
      purple: purple[500],
    },
    // start of chip for config service cpu.ram.ssd.hdd.sas
    cpuChip: {
      contrastText: "#ffffff",
      main: green[400],
    },
    ramChip: {
      contrastText: "#ffffff",
      main: orange[400],
    },
    ssdChip: {
      contrastText: "#ffffff",
      main: indigo[400],
    },
    hddChip: {
      contrastText: "#ffffff",
      main: blueGrey[400],
    },
    sasChip: {
      contrastText: "#ffffff",
      main: lightBlue[400],
    },
    capacity_cpu: {
      contrastText: "#ffffff",
      main: orange[400],
    },
    capacity_ram: {
      contrastText: "#ffffff",
      main: indigo[400],
    },
    capacity_storage: {
      contrastText: "#ffffff",
      main: blueGrey[400],
    },
    capacity_power_supply: {
      contrastText: "#ffffff",
      main: lightBlue[400],
    },
    text: {
      grey: "#777680",
      black: "#00001F",
      success: "#00a152",
      warning: orange[400],
    },
    //end of chip config
  },
  typography: {
    fontFamily: [
      '"Google Sans"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ].join(","),
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.MuiFormControlLabel-label": {
            fontSize: 14,
            margin: "auto 0",
          },
          "&.page-title": {
            fontSize: 24,
            fontWeight: 500,
          },
          "&.react-link": {
            color: grey[500],
            "&:hover": {
              textDecoration: "underline",
              color: indigo[900],
            },
          },
          "&.react-link-table": {
            color: indigo[900],
            fontSize: 14,
            "&:hover": {
              textDecoration: "underline",
            },
          },
          "&.breadcrumb": {
            color: grey[500],
            margin: "auto 4px",
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.MuiInputBase-sizeSmall": {
            minHeight: "40px",
            fontSize: 14,
          },
          "&.MuiInputBase-multiline": {
            "textarea[aria-invalid='false']": {
              minHeight: "46px !important",
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.information": {
            width: "40px",
            height: "40px",
            backgroundColor: indigo[900],
          },
          [breakpoints.breakpoints.down("md")]: {
            margin: "auto 0 auto auto",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === "small" && {
            fontSize: 13,
            marginBottom: "4px",
            fontWeight: "medium",
          }),
          ...(ownerState.size === "verysmall" && {
            fontSize: 11,
            marginBottom: "4px",
            height: "24px",
            fontWeight: "medium",
          }),
          ...(ownerState?.variant === "customize-primary" && {
            color: indigo[400],
            backgroundColor: [indigo[400], "1F"].join(""),
          }),
          ...(ownerState?.variant === "customize-success" && {
            color: green[400],
            backgroundColor: [green[400], "1F"].join(""),
          }),
          ...(ownerState?.variant === "customize-error" && {
            color: red[400],
            backgroundColor: [red[400], "1F"].join(""),
          }),
          ...(ownerState?.variant === "customize-pending" && {
            color: orange[400],
            backgroundColor: [orange[400], "1F"].join(""),
          }),
          ...(ownerState?.variant === "customize-purple" && {
            color: purple[400],
            backgroundColor: [purple[400], "1F"].join(""),
          }),
          ...(ownerState?.variant === "customize-blueGrey" && {
            color: blueGrey[400],
            backgroundColor: [blueGrey[400], "1F"].join(""),
          }),
          ...(ownerState?.variant === "customize-lightBlue" && {
            color: lightBlue[400],
            backgroundColor: [lightBlue[400], "1F"].join(""),
          }),
        }),
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          "& .MuiButtonGroup-grouped": {
            "&:hover": {
              // border: "none",
            },
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: grey[200],
          "&.MuiSkeleton-rectangular": {
            borderRadius: "4px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState?.size === "small" &&
            ownerState?.sx?.height === 36 && {
            fontWeight: 450,
          }),
          ...(ownerState.size === "small" && {
            [breakpoints.breakpoints.down("md")]: {
              height: 40,
              minWidth: "100%",
            },
            [breakpoints.breakpoints.up("md")]: {
              height: 42,
              minWidth: 150,
            },
          }),
          padding: 12,
          textTransform: "capitalize",
          "&.MuiButton-containedPrimary": {
            color: "#ffffff",
          },
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(0, 3, 51, 0.08)",
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.active": {
            backgroundColor: grey[200],
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&.MuiMenuItem-root": {
            backgroundColor: "#FFF",
          },
          "&.MuiInputBase-inputSizeSmall": {
            fontSize: 14,
            minHeight: "unset",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.MuiInputBase-sizeSmall": {
            fontSize: 13,
            minHeight: "40px",
          },
          "&.MuiAutocomplete-input": {
            padding: "0px 4px 7px 6px !important",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
          "&.MuiInputBase-sizeSmall": {
            fontSize: 13,
            minHeight: "40px",
          },
          "&.MuiAutocomplete-input": {
            padding: "0px 4px 7px 6px !important",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "&.MuiInputBase-sizeSmall": {
            minHeight: "40px",
          },
          ".MuiIconButton-edgeEnd": {
            m: "unset",
          },
          "&.MuiTextField-root": {
            width: "100%",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState?.color === "primary" && {
            color: indigo[900],
          }),
          ...(ownerState?.color === "error" && {
            color: red[500],
          }),
          ...(ownerState?.color === "warning" && {
            color: orange[500],
          }),
        }),
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiTabScrollButton-root": {
            "&.Mui-disabled": {
              display: "none",
            },
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          ".MuiDataGrid-main": {
            "div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']":
            {
              display: "none",
            },
          },
          ".MuiDataGrid-row": {
            "&.grid-dc-vnetwork": {
              "&.row-warning": {
                backgroundColor: "#ffb5b54F",
              },
              "&.row-disabled": {
                backgroundColor: "#7776802F",
              },
              "&.row-highlight": {
                backgroundColor: green[100],
              },
              "&.row-service-finally": {
                backgroundColor: "#eceff1",
              },
            },
          },
          ".MuiDataGrid-columnHeader--sorted": {
            ".MuiSvgIcon-root": {
              color: indigo[900],
            },
          },
          ".MuiDataGrid-cellCheckbox": {
            ".MuiSvgIcon-root": {
              height: '20px',
              width: '20px'
            }
          },
          ".MuiDataGrid-columnHeaderTitleContainer": {
            ".MuiDataGrid-columnHeaderTitleContainerContent": {
              ".MuiSvgIcon-root": {
                height: '20px',
                width: '20px'
              }
            }
          }
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          ".MuiPickersPopper-paper .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateRangeCalendar-root":
          {
            "div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']":
            {
              display: "none",
            },
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          "&.MuiDivider-light": {
            borderColor: indigo[900],
            borderWidth: "0px 0px 1px",
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "0px 24px 24px",
          "&.MuiDialogActions-spacing": {
            gap: "16px",
            [breakpoints.breakpoints.down("md")]: {
              gap: 12,
              flexDirection: "column",
            },
            button: {
              "&:not(:first-of-type)": {
                marginLeft: 0,
              },
            },
          },
          [breakpoints.breakpoints.down("md")]: {
            ".MuiButton-root": {
              "&.MuiButton-sizeSmall": {
                height: 40,
                minWidth: "100%",
              },
              "&.MuiButton-sizeMedium": {
                minHeight: 40,
                minWidth: "100%",
              },
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          ".MuiInputBase-multiline": {
            textarea: {
              fontSize: "14px",
            },
          },
        },
      },
    },
  },
});
