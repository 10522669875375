import MuiScrollTabs from "@/components/shared/MuiScrollTabs";
import { isObjEmpty } from "@/functions";
import { useLayoutContext } from "@contexts/layout";
import { ConstructionOutlined } from "@mui/icons-material";
import ServiceListIcon from "@mui/icons-material/FormatListBulletedOutlined";
import LegendToggleOutlinedIcon from "@mui/icons-material/LegendToggleOutlined";
import React from "react";
import ManageAccountsOutlined from "@mui/icons-material/ManageAccountsOutlined";
const ServiceMainTabs = () => {
	const { permissions } = useLayoutContext();

	const serviceCollections = React.useMemo(() => {
		let arrs = [];
		permissions?.forEach((collection) => {
			if (["service"].includes(collection.feature)) {
				arrs.push(...collection?.actions);
			}
		});
		return arrs;
	}, [permissions]);

	const isHiddenTab = ({ f = "", a = "" }) => {
		const available =
			serviceCollections?.find(
				({ feature, action }) => feature === f && action === a
			) || {};
		return isObjEmpty(available);
	};
	const initialTabs = [
		{
			value: "default",
			label: "Danh Sách Dịch Vụ",
			href: "",
			icon: ServiceListIcon,
			hidden: isHiddenTab({ f: "service", a: "index" }),
			title: "Tổng Dịch Vụ Được Tạo Trên Hệ Thống"
		},
		{
			value: "confirmations",
			label: "Dịch Vụ Chờ Xác Nhận",
			href: "confirmations",
			icon: ManageAccountsOutlined,
			hidden: isHiddenTab({ f: "service", a: "confirmations" }),
			title: "Dịch Vụ Do Bộ Phận Khác CSKH Tạo & Đang Chờ Xác Nhận"
		},
		{
			value: "poc",
			label: "Dịch Vụ POC",
			href: "poc",
			icon: ConstructionOutlined,
			hidden: isHiddenTab({ f: "service", a: "poc" }),
			title: "Dịch Vụ POC/ Dùng Thử Được Tạo Trên Hệ Thống"
		},
		{
			value: "device-liquidation",
			label: "Device Liquidation",
			href: "device_liquidations",
			icon: ConstructionOutlined,
			hidden: isHiddenTab({ f: "service", a: "device-liquidation" }),
			title: "Server/ Thiết Bị Bán Cho Khách Hàng..."
		},
		{
			value: "statement",
			label: "Dịch Vụ Đối Soát",
			href: "statement",
			icon: LegendToggleOutlinedIcon,
			hidden: isHiddenTab({ f: "service", a: "statement" }),
			title: "Các Dịch Vụ Đối Soát (CDN...)"
		},
	];
	return (
		<MuiScrollTabs
			prefix={`services`}
			initialTabs={initialTabs?.filter((tab) => !tab?.hidden)}
		/>
	);
};

export default ServiceMainTabs;
