import CallMergeOutlined from '@mui/icons-material/CallMergeOutlined'
import { Button } from '@mui/material'
import React from 'react'
import withPermission from '@/hocs/withPermission'

const ButtonMergeInvoice = ({ handleClick, disabled }) => {
    return (
        <Button
            disabled={disabled}
            startIcon={<CallMergeOutlined />}
            onClick={handleClick}
            size="small"
            variant='outlined' sx={{
                height: 36,
                m: 'auto 0'
            }}
        >
            Merge Invoices
        </Button>
    )
}

export default withPermission(ButtonMergeInvoice, { feature: 'customer.invoice', action: 'merge', type: 'button' })