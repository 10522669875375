import CustomerApi from '@/apis/beta/Customer/CustomerApi'
import ItemPaperStatistical from '@/components/shared/ItemPaperStatistical'
import withPermission from '@/hocs/withPermission'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { Grid } from '@mui/material'
import React from 'react'

const Statistical = () => {

    const { data } = CustomerApi.Statistics()

    const formatToArray = () => {
        return [
            { label: "Tổng Khách Hàng", value: data.total },
            { label: "Khách Hàng Đã Chốt", value: data.active },
            { label: "Khách Hàng Tiềm Năng", value: data?.qualified_prospects },
            { label: "Khách Hàng Trial", value: data?.trial },
        ]
    }
    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            {Array.from(formatToArray()).map((item, index) =>
                <Grid item xs={12} sm={6} md={3} key={index}>
                    <ItemPaperStatistical {...item} />
                </Grid>
            )}
        </Grid>
    )
}

export default withSuspense(withPermission(Statistical, {
    feature: "customer",
    action: "statistic",
    type: "Block"
}), MuiSkeleton["Statistical"])