import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class WorkDayApi {
  async Index({ year, month }) {
    const response = await requestWithToken(`${API_V3_URL}/work_days`, "POST", {
      year,
      month,
    });
    return response?.data;
  }

  listHoliday = ({
    month = null, year = null,
    page = 1,
    pageSize = 10
  }) => {
    return useQueryWithCustomized(
      ["work_days.list_holiday", page, pageSize],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/work_days/list_holiday?page=${page}&page_size=${pageSize}&month=${month}&year=${year}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
        enabled: !!localStorage.getItem("SA"),
      }
    );
  }
  async detailHoliday(holidayId) {
    try {
      const response = await requestWithToken(
        `${API_V3_URL}/work_days/holiday/${holidayId}`,
        "GET"
      );
      return response?.data;
    } catch (error) {
      console.error("Error fetching holiday detail:", error);
      return null;
    }
  }

  async UpdateHoliday({ holidayId, updatedHolidayData }) {
    try {
      const response = await requestWithToken(
        `${API_V3_URL}/work_days/holiday/${holidayId}`,
        "PUT",
        updatedHolidayData
        // { holidays: updatedHolidayData }
      );
      return response?.data;
    } catch (error) {
      console.error("Error updating holiday:", error);
      throw error;
    }
  }


  async DeleteHoliday(holidayId) {
    const response = await requestWithToken(
      `${API_V3_URL}/work_days/holiday/${holidayId}`,
      "DELETE"
    );
    return response?.data;
  }

  async ExportDataWorkDayInMonth({ year, month }) {
    const response = await requestWithToken(
      `${API_V3_URL}/work_days/export_data_work_day_in_month?year=${year}&month=${month}`,
      "GET",
      {},
      {
        "Content-Type": "application/vnd.ms-excel",
        "CONTENT-DISPOSITION": "attachment",
      },
      { withCredentials: true, responseType: "blob" }
    );

    return response?.data;
  }

  async FilterWorkDay(formValue) {
    const response = await requestWithToken(
      `${API_V3_URL}/work_days/export_excel_file_with_client_data`,
      "POST",
      {
        from: formValue?.from?.format("DD/MM/YYYY"),
        to: formValue?.to?.format("DD/MM/YYYY"),
        page: formValue?.page + 1,
        pageSize: formValue?.pageSize,
        q: formValue?.q,
      }
    );

    return response?.data;
  }

  async SyncData(file) {
    const formData = new FormData();
    formData.append("file", file);
    const response = await requestWithToken(
      `${API_V3_URL}/work_days/sync_data`,
      "POST",
      formData
    );

    return response?.data;
  }

  async TotalWorkDaysInMonth({ employee_id, year, month }) {
    const response = await requestWithToken(
      `${API_V3_URL}/work_days/total_work_days_in_month`,
      "POST",
      { employee_id, year, month }
    );
    return response?.data;
  }

  async ExportDataSalarySlip(formValues) {
    const response = await requestWithToken(
      `${API_V3_URL}/work_days/export_data_salary_slip`,
      "POST",
      formValues
    );

    return response?.data;
  }

  async RequestEditWorkDays(values) {
    const response = await requestWithToken(
      `${API_V3_URL}/work_days/request_edit_work_days`,
      "POST",
      {
        ...values,
        type_work: values?.type,
      }
    );

    return response?.data;
  }

  async EditWorkDays(values) {
    const response = await requestWithToken(
      `${API_V3_URL}/work_days/edit_work_days`,
      "PUT",
      values
    );

    return response?.data;
  }

  async SendSalarySlip(values) {
    const response = await requestWithToken(
      `${API_V3_URL}/work_days/send_salary_slip`,
      "POST",
      values
    );

    return response?.data;
  }

  async GetInfoDayOffOrBusiness({
    work_date_type,
    work_day_date,
    employee_id,
  }) {
    const response = await requestWithToken(
      `${API_V3_URL}/work_days/get_info_day_off_or_business`,
      "POST",
      {
        work_date_type,
        work_day_date,
        employee_id,
      }
    );

    return response?.data;
  }

  async SyncHoliday(values) {
    const response = await requestWithToken(
      `${API_V3_URL}/work_days/sync_holiday`,
      "POST",
      values
    );
    return response?.data;
  }

  async GetMissDate(miss_date_id) {
    const response = await requestWithToken(
      `${API_V3_URL}/work_days/get_miss_date/${miss_date_id}`
    );
    return response?.data;
  }

  async EditListWorkDay(values) {
    const response = await requestWithToken(
      `${API_V3_URL}/work_days/edit_list_work_day`,
      "POST",
      {
        ...values,
      }
    );
    return response?.data;
  }
}

export default WorkDayApi = new WorkDayApi();
