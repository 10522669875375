import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  Typography,
} from "@mui/material";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import BreakdownGlobal from "./BreakdownGlobal";
import ProductConfigoptionOne from "./ProductConfigoptionOne";

const ProductConfigoption = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "product_configoptions",
  });

  const [isMergeTraffic, setMergeTraffic] = React.useState(false);

  const onChangeMergeTraffic = (event) => {
    const checked = event?.target?.checked;
    if (checked && fields.length > 1) {
      fields.forEach((item, index) => {
        if (item?.name !== "local") {
          remove(index);
        }
      });
    }
    return setMergeTraffic(event?.target?.checked);
  };

  return (
    <Box aria-labelledby="product-configoption">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          gap: 1,
          mb: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: 14,
            m: "auto 0",
            fontWeight: "medium",
          }}
        >
          Thông tin cấu hình *
        </Typography>
        <FormControlLabel
          label={
            <Typography sx={{ fontSize: 13 }} color="primary">
              Gộp băng thông
            </Typography>
          }
          control={
            <Checkbox
              size="small"
              checked={isMergeTraffic}
              onChange={onChangeMergeTraffic}
            />
          }
        />
      </Box>
      {fields &&
        fields.map((field, index) => {
          return (
            <React.Fragment key={field.id}>
              <ProductConfigoptionOne
                index={index}
                data={field}
                remover={() => remove(index)}
              />
            </React.Fragment>
          );
        })}
      {!isMergeTraffic && (
        <Collapse in={!isMergeTraffic}>
          <BreakdownGlobal append={append} />
        </Collapse>
      )}
    </Box>
  );
};

export default ProductConfigoption;
