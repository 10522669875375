import ServiceSystemApi from "@/apis/beta/Service/ServiceSystemApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import DataGridCustomize from "@components/DataGrid";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { INVOICE_MAILER_STATUS_TYPES } from "@features/Module/Invoice/Detail/Mailer";
import { Chip, Typography } from "@mui/material";
import moment from "moment";
import React, { Fragment, useState } from "react";

const MailerList = ({ serviceId }) => {
  const [page, setPage] = useState({
    page: 0,
    pageSize: 10,
  });

  const { data } = ServiceSystemApi.ServiceMailers({
    serviceId,
    page: page?.page + 1,
    pageSize: page?.pageSize,
  });

  const [columns] = React.useState([
    {
      field: "mailer_subject",
      headerName: "Tiêu Đề",
      valueGetter: ({ row }) => row?.metadata?.subject || "---",
      width: 250,
    },
    {
      field: "mailer_receiver",
      headerName: "Người Nhận",
      valueGetter: ({ row }) =>
        row?.metadata?.metadata?.mailer?.receivers || [],
      width: 200,
      renderCell: ({ value }) => value?.join(", "),
    },
    {
      field: "mailer_cc",
      headerName: "Email Cc",
      width: 200,
      valueGetter: ({ row }) => row?.metadata?.metadata?.mailer?.cc || [],
      renderCell: ({ value }) => value?.join(", "),
    },
    {
      field: "mailer_sender",
      headerName: "Người Gửi",
      valueGetter: ({ row }) => row?.metadata?.sender?.employee_name || "",
      width: 200,
    },
    {
      field: "mailer_send_at",
      headerName: "Ngày Gửi",
      width: 150,
      valueGetter: ({ row }) => row?.task_updated_at,
      renderCell: ({ value }) =>
        value ? moment(value).format("DD/MM/YYYY HH:mm") : "---",
    },
    {
      field: "mailer_status",
      headerName: "Trạng Thái",
      width: 120,
      valueGetter: ({ row }) =>
        INVOICE_MAILER_STATUS_TYPES?.find((t) => t?.value === row?.task_status),
      renderCell: ({ value }) => (
        <Chip
          size="small"
          variant={`customize-${value?.chip}`}
          label={value?.label}
        />
      ),
    },
  ]);
  return (
    <Fragment>
      <Typography sx={{ fontSize: 14, fontWeight: "medium", mt: 2, mb: 1 }}>
        Lịch sử gửi mail dịch vụ
      </Typography>
      <DataGridCustomize
        rows={
          data?.tasks?.map((service, index) => ({
            ...service,
            id: index,
          })) || []
        }
        components={{
          Toolbar: "disabled",
        }}
        columns={columns}
        componentsProps={{
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        rowCount={data?.pagination?.total}
      />
    </Fragment>
  );
};

export default withSuspense(
  withPermission(MailerList, {
    feature: "service",
    action: "mailers",
    type: "Table",
  }),
  MuiSkeleton["DataGrid"]
);
