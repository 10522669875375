import ServiceApi from '@/apis/beta/Service/ServiceApi'
import { format_numeral_price, formatVolumeUnit } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { Box, Popover, Typography } from '@mui/material'
import _ from 'lodash'
import { useState } from 'react'
import Preview from '../Preview'
import { buildLine, checkLineCommitment, findLine, formatLineName, KeepLineCommitment, loggingProgressive, loggingTierds } from './functions'

const Metrics = {
    local: 'BW Trong Nước',
    global: 'BW Quốc Tế',
    tw: 'BW Taiwan',
    cn: 'BW China',
    storage: 'Dung Lượng Lưu Trữ',
    'transcoding': 'Transcode (số giờ)',
    'recording': 'Recording (số giờ)',
    'transmuxing': 'Transmuxing (số giờ)',
    'jobs': 'Luồng',
}

const groupByNamed = ({ lines = [] }) => {
    return _.groupBy(lines, (line) => {
        return [line?.invoice_item_name, line?.invoice_item_method]
    })
}

const isAddonLine = ({ line }) => {
    return Boolean(line?.invoice_item_description?.toLowerCase()?.match(/(gia hạn|renew|addon)/))
}

const reGroupInvoiceItemsByDate = ({ lines = [] }) => {


    const groups = _.groupBy(lines, (line) => {
        return [line?.invoice_item_startdate, line?.invoice_item_enddate]
    })

    if (!groups || groups.length === 0) {
        return []
    }
    let rows = []

    _.forEach(groups, (values, billings) => {
        const groupings = groupByNamed({ lines: values })
        console.log(`lines`, groupings)
        let metadata = []
        _.forEach(groupings, (items, keys) => {
            // eslint-disable-next-line no-unused-vars
            const [name, method, ...anythings] = keys?.split(',')
            let data = []
            const subTotal = findLine({ lines: items })

            if (!!subTotal && subTotal?.invoice_item_value > 0) {
                if (items?.length === 1) {
                    if (isAddonLine(items[0])) {
                        data.push(buildLine({
                            line: items[0],
                            metadata: {
                                invoice_item_name: name,
                                invoice_item_description: 'Gia hạn gói cố định'
                            }
                        }))
                    }
                } else {
                    const cookingDatas = findLine({ lines: items, scope: 'usages' })

                    if (checkLineCommitment({ lines: items })) {
                        const commitment = findLine({ lines: items, scope: 'commitment' })
                        if (KeepLineCommitment({ subTotal, commitment })) {
                            data.push(buildLine({
                                line: subTotal, metadata: {
                                    invoice_item_name: name
                                },
                                scope: 'subtotal'
                            }))
                        }

                        if (!commitment?.invoice_item_payment_desposit) {
                            // trả sau theo gói
                            data.push(buildLine({
                                line: commitment,
                                metadata: {
                                    invoice_item_name: name
                                },
                                scope: 'commitment'
                            }))
                        }
                        if (cookingDatas?.length > 0) {
                            if (method === 'tierd') {
                                data.push(loggingTierds({ lines: cookingDatas, name: name }))
                            }
                            if (method === 'progressive') {
                                loggingProgressive({ lines: cookingDatas })?.forEach(item => {
                                    data.push(item)
                                })
                            }
                        }
                    } else {
                        if (method === 'tierd') {
                            data.push(loggingTierds({ lines: cookingDatas, name: name, description: 'Tổng sử dụng' }))
                        }
                        if (method === 'progressive') {
                            data.push(buildLine({
                                line: subTotal, metadata: {
                                    invoice_item_name: name
                                },
                                scope: 'subtotal'
                            }))
                            loggingProgressive({ lines: cookingDatas })?.forEach(item => {
                                data.push(item)
                            })
                        }
                    }
                }
            }
            if (!!data && data?.length > 0) {
                metadata.push({
                    name: formatLineName(data[data?.length - 1]?.invoice_item_name),
                    counting: data?.length,
                    items: data
                })
            }
        })
        if (!!metadata && metadata.length > 0) {
            rows.push({
                invoice_items: metadata,
                row_count: metadata?.length,
                invoice_item_billing_cycle: billings
            })
        }
    })
    return rows
}

const ServiceUsage = ({ data = {} }) => {

    const { data: {
        usages: summary,
        lines,
        amount
    } } = ServiceApi.SummaryUsing({ serviceId: data?.service_id })

    const [previewable, setPreviewable] = useState(undefined)

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            p: 0.5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }}>
            {Boolean(previewable) && <Popover anchorEl={previewable} open={true} onClose={() => setPreviewable(undefined)}>
                <Preview lines={reGroupInvoiceItemsByDate({ lines })} />
            </Popover>}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                {Object.keys(summary)?.map((item, index) => {
                    return (
                        <Typography sx={{ fontSize: 14 }} key={index}>
                            <Typography sx={{
                                display: 'inline-flex',
                                fontWeight: 'medium',
                                fontSize: 14,
                                textTransform: 'capitalize',
                                minWidth: 150,
                                width: 150
                            }}>
                                {Metrics[item]}
                            </Typography>
                            {":"}
                            <Typography sx={{
                                ml: 0.25,
                                display: 'inline-flex',
                                fontSize: 14,
                            }}>
                                {!!item?.match(/(local|global|storage lfd)/)
                                    ? formatVolumeUnit(summary[item])
                                    : summary[item]}
                            </Typography>
                        </Typography>
                    )
                })}
            </Box>
            <Box sx={{ height: '100%', display: 'flex', p: 'auto 0 0 auto', alignItems: "center" }}>
                <Typography
                    onClick={(event) => setPreviewable(event?.currentTarget)}
                    sx={{
                        fontSize: 14,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    }}
                    color="primary"
                >
                    Tạm Tính: {format_numeral_price(amount)}
                </Typography>
            </Box>
        </Box>
    )
}

export default withSuspense(ServiceUsage, MuiSkeleton["GradientCircularProgress"])