import NumericFormatCustom from '@components/NumericFormatCustom'
import { Box, Checkbox, FormControl, FormControlLabel, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { format_numeral_price } from '@/functions'

const BackupCloudStorage = ({ position }) => {

    const { control, watch } = useFormContext()

    const { fields } = useFieldArray({
        control: control,
        name: `product_configoptions.[${position}].product_addons`
    })

    return (
        <Box>
            <Typography sx={{
                fontSize: { xs: 16, md: 20 },
                fontWeight: 'medium',
            }}>
                Dịch vụ thêm
            </Typography>
            {fields && fields.map((field, index) => {
                return (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }} key={field.id}>
                        <Controller
                            name={`product_configoptions.[${position}].product_addons.[${index}].selected`}
                            control={control}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <FormControl fullWidth>
                                        <FormControlLabel
                                            label={field?.product_addon_name === "Storage" ? "Dung lượng thêm" : "Backup dữ liệu theo tuần"}
                                            control={<Checkbox size="small"
                                                checked={value}
                                                onChange={onChange}
                                                defaultChecked={value}
                                                sx={{
                                                    "&.Mui-checked": {
                                                        color: "#ef5350"
                                                    }
                                                }} />} />
                                    </FormControl>
                                )
                            }} />
                        <Controller
                            name={`product_configoptions.[${position}].product_addons.[${index}].product_addon_qty`}
                            control={control}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <FormControl fullWidth sx={{
                                        pl: { xs: 'unset', md: '28px' }
                                    }}>
                                        <TextField
                                            size='small'
                                            value={value}
                                            disabled={!watch(`product_configoptions.[${position}].product_addons.[${index}].selected`)}
                                            onChange={onChange}
                                            InputProps={{
                                                inputComponent: NumericFormatCustom,
                                                endAdornment: <Typography sx={{
                                                    fontSize: 14,
                                                    minWidth: 100,
                                                    textAlign: 'end'
                                                }}
                                                    component="span"
                                                >
                                                    <Typography sx={{
                                                        display: 'inline-flex',
                                                        color: "#777680",
                                                        fontSize: 14,
                                                        textDecoration: 'underline'
                                                    }}>đ</Typography>
                                                    {format_numeral_price(field?.product_addon_pricing)}/ GB</Typography>
                                            }}
                                            placeholder="Nhập dung lượng cần mua..."
                                        />
                                    </FormControl>
                                )
                            }}
                        />
                    </Box>
                )
            })}
        </Box>
    )
}

export default BackupCloudStorage