import { format_number_usage, format_numeral_price } from '@/functions'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'

const PriceRanges = ({ ranges = [] }) => {
    return (
        <Box>
            <Typography sx={{ fontSize: 13, pt: 2 }} color="primary">
                Bảng Giá Đối Soát Vượt:
            </Typography>
            <TableContainer component={Box} >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontSize: 13 }}>Khoảng Giá</TableCell>
                            <TableCell sx={{ fontSize: 13 }}>Giá</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ranges.map((range, i) => {
                            return (
                                <TableRow key={i}>
                                    <TableCell sx={{ fontSize: 13 }}>
                                        {"("}{format_number_usage(range?.start)} - {format_number_usage(range?.end)}{"]"}
                                    </TableCell>
                                    <TableCell sx={{ fontSize: 13 }}>{format_numeral_price(range?.pricing_apply)}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default PriceRanges