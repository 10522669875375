// styled

const InputPropsTypeNumber = (props) => {
  let inputProps = {
    min: props?.min || 0,
    ...props,
  };
  return { inputProps: { ...inputProps } };
};

const MenuProps = {
  PaperProps: {
    style: {
      marginTop: 5,
      maxHeight: 48 * 4.5 + 8,
      width: 154.94,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

export { MenuProps, InputPropsTypeNumber };
