import InvoiceApi from '@/apis/beta/Invoice/InvoiceApi'
import ItemPaperStatistical from '@/components/shared/ItemPaperStatistical'
import { buildQueryFilterCondition, format_date_short, format_numeral_price } from '@/functions'
import withPermission from '@/hocs/withPermission'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { Grid } from '@mui/material'
import dayjs from 'dayjs'
import moment from 'moment'
import React from 'react'

// const PRIVATE_OPTIONS = [
//     { value: "this-month", label: "Tháng hiện tại" },
//     { value: "this-quaterly", label: "Quý hiện tại" },
//     { value: "this-year", label: "Năm hiện tại" },
//     { value: "last-30-days", label: "Last 30days" },
//     { value: "customize", label: "Tuỳ chỉnh" },
//     { value: "use-query", label: "Theo bộ tìm kiếm" },
//     { value: "un-query", label: "Tất cả thời gian" }
// ]
const Statistical = ({
    query,
    searchQuery
}) => {
    const [today] = React.useState(dayjs())

    const [state] = React.useState({
        choice: 'use-query',
        startdate: today.startOf('m'),
        enddate: today?.endOf('m')
    })
    const queryCallback = React.useCallback(() => {
        let queries = []
        switch (state?.choice) {
            case "this-quaterly":
                const startOfQuaterly = moment().quarter(moment().quarter()).startOf('quarter');
                const endOfQuaterly = moment().quarter(moment().quarter()).endOf('quarter');
                queries = [
                    {
                        name: 'invoices.invoice_created_at',
                        operator: "$gte",
                        value: format_date_short(startOfQuaterly)
                    }, {
                        name: 'invoices.invoice_created_at',
                        operator: "$lte",
                        value: format_date_short(endOfQuaterly)
                    }
                ]
                break;
            case "use-query": return query;
            case "this-month":
                queries = [
                    {
                        name: 'invoices.invoice_created_at',
                        operator: "$gte",
                        value: format_date_short(moment().startOf("month"))
                    }, {
                        name: 'invoices.invoice_created_at',
                        operator: "$lte",
                        value: format_date_short(moment().endOf("month"))
                    }
                ]
                break;
            case "this-year":
                const startofYear = format_date_short(today?.startOf("year"))
                const endofYear = format_date_short(today?.endOf("year"))
                queries = [
                    {
                        name: 'invoices.invoice_created_at',
                        operator: "$gte",
                        value: startofYear
                    }, {
                        name: 'invoices.invoice_created_at',
                        operator: "$lte",
                        value: endofYear
                    }
                ]
                break;
            case "last-30-days":
                const startdate = moment().add(-1, "month").add(1, 'd')
                const enddate = moment()
                queries = [
                    {
                        name: 'invoices.invoice_created_at',
                        operator: "$gte",
                        value: format_date_short(startdate)
                    }, {
                        name: 'invoices.invoice_created_at',
                        operator: "$lte",
                        value: format_date_short(enddate)
                    }
                ]
                break;
            case "customize":
                console.log(state)
                queries = [
                    {
                        name: 'invoices.invoice_created_at',
                        operator: "$gte",
                        value: format_date_short(state?.startdate?.$d)
                    }, {
                        name: 'invoices.invoice_created_at',
                        operator: "$lte",
                        value: format_date_short(state?.enddate?.$d)
                    }
                ]
                break;
            case "un-query": return ``;
            default: return ``;
        }
        return buildQueryFilterCondition("$and", 0, queries)
    }, [state, today, query])

    const { data, isFetching } = InvoiceApi.ReportFinance({
        query: queryCallback(),
        searchQuery: state?.choice === `use-query` ? searchQuery : null,
    });

    const formatToArray = () => {
        return [
            {
                label: "Tổng Hoá Đơn",
                subtitle: format_numeral_price(data?.count?.total),
                value: format_numeral_price(data?.finance?.total), endText: true,
                title: "Tổng số lượng hoá đơn"
            },
            {
                label: "Đã Thanh Toán",
                subtitle: format_numeral_price(data?.count?.paid),
                value: format_numeral_price(data?.finance?.paid), endText: true,
                title: "Hoá đơn đã được thanh toán - Trạng thái hoá đơn -> Đã Thanh Toán"
            },
            {
                label: "Công Nợ",
                subtitle: format_numeral_price(data?.count?.unpaid),
                value: format_numeral_price(data?.finance?.unpaid), endText: true,
                title: "Hoá đơn chưa được thanh toán - Trạng thái hoá đơn -> Chưa Thanh Toán"
            },
            {
                label: "Đã Huỷ",
                subtitle: format_numeral_price(data?.count?.cancelled),
                value: format_numeral_price(data?.finance?.cancelled), endText: true,
                title: "Hoá đơn đã bị huỷ - Dịch Vụ Đã Huỷ/ Không Tiếp Tục Gia Hạn -> Hoá đơn dịch vụ sẽ bị huỷ theo"
            },
        ]
    }
    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            {formatToArray().map((item, index) =>
                <Grid item xs={12} sm={6} md={3} key={index} sx={{ zIndex: 1 }}>
                    <ItemPaperStatistical {...item} isFetching={isFetching} />
                </Grid>
            )}
        </Grid>
    )
}

export default withSuspense(withPermission(Statistical, { feature: "invoice", action: "statistic", type: "Block" }), MuiSkeleton["Statistical"])