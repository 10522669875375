import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import DataGrid from "@/components/DataGrid";
import { CellStatus } from "@/components/DataGrid/Cell";
import DataGridFilter from "@/components/DataGrid/Filter";
import PopperAction from "@/components/PopperAction";
import ModalConfirm from "@/components/shared/ModalConfirm";
import { buildQueryFilterCondition } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import useFilters, { Filter } from "@/hooks/useFilters";
import {
  CUSTOMER_SOURCES,
  CUSTOMER_STATUS_TYPES,
  GENDERS,
  SERVICES,
} from "@/libs/constants";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Popper, Typography } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import Buttons from "../Buttons";
import Statistical from "./Statistical";

const List = () => {
  const location = useLocation();
  const [query, updateQuery] = useFilters("customers.list.filters", location);

  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const filterableCustomer = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
    const fieldQueries = [
      {
        name: "customers.customer_id",
        operator: "$eq",
        disabled: !parseInt(debouncedSearchQuery),
        value: parseInt(debouncedSearchQuery),
      },
      {
        name: "customers.customer_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "company_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "customers.customer_email",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
    ];
    return buildQueryFilterCondition("$or", 0, fieldQueries);
  }, [debouncedSearchQuery]);

  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [popper, setPopper] = React.useState({
    anchorEl: undefined,
    data: undefined,
    actions: [],
  });

  const initialColumns = [
    {
      field: "customers.customer_id",
      headerName: "ID",
      width: 60,
      hideable: true,
      operators: { type: "number" },
      valueGetter: ({ row }) => row?.customer_id,
      renderCell: ({ value }) => (
        <Link to={`/customers/${value}`} replace>
          <Typography className="react-link-table">{value}</Typography>
        </Link>
      ),
    },
    {
      field: "customer_name",
      headerName: "Tên",
      width: 200,
      operators: { type: "string" },
      valueGetter: ({ row }) => {
        let txt = row?.customer_name;
        if (
          !!row?.customer_company?.company_name &&
          row?.customer_company?.company_name !== ""
        ) {
          txt = row?.customer_company?.company_name;
          txt += `(${row?.customer_name})`;
        }
        return txt;
      },
      hideable: true,
    },
    {
      field: "customer_email",
      headerName: "Email",
      width: 250,
      operators: { type: "string" },
    },
    {
      field: "customer_phone",
      headerName: "Số Điện Thoại",
      width: 150,
      operators: { type: "string" },
    },
    {
      field: "customers.customer_status",
      headerName: "Trạng Thái",
      width: 120,
      operators: {
        type: "object",
        collections: CUSTOMER_STATUS_TYPES || [],
        initQueries: [
          {
            condition: "$and",
            filter: { "customers.customer_status": { $eq: 1 } },
          },
        ],
      },
      valueGetter: ({ row }) =>
        CUSTOMER_STATUS_TYPES?.find(
          (stt) => stt?.value === row?.customer_status
        ),
      renderCell: ({ value }) => (
        <CellStatus component="muiChip" data={value} />
      ),
    },
    // {
    //   field: 'notes',
    //   headerName: 'Ghi Chú',
    //   width: 150,
    //   renderCell: ({ value }) => <Cell content={value} />,
    // },
    // {
    //   field: 'employees.employee_id',
    //   headerName: 'Nhân Viên Phụ Trách',
    //   width: 150,
    //   valueGetter: ({ row }) => row.customer_owners?.join(', '),
    //   renderCell: ({ value }) => value,
    //   operators: {
    //     type: 'object',
    //     collections: employees?.map((employee) => ({
    //       value: employee?.employee_id,
    //       label: employee?.employee_name,
    //     })),
    //   },
    // },
    // {
    //   field: 'customer_created_at',
    //   headerName: 'Ngày Tạo',
    //   width: 150,
    //   renderCell: ({ value }) => format_date_short(value),
    //   operators: {
    //     type: 'date',
    //     initQueries: [
    //       {
    //         condition: '$and',
    //         filter: {
    //           customer_created_at: {
    //             $gte: moment().startOf('month').format('DD/MM/YYYY'),
    //           },
    //         },
    //       },
    //       {
    //         condition: '$and',
    //         filter: {
    //           customer_created_at: {
    //             $lte: moment().endOf('month').format('DD/MM/YYYY'),
    //           },
    //         },
    //       },
    //     ],
    //   },
    // },
    {
      field: "customer_source",
      headerName: "Nguồn Khách Hàng",
      width: 150,
      valueGetter: ({ row }) =>
        CUSTOMER_SOURCES.find(
          (source) => source?.value === row?.customer_source
        ),
      renderCell: ({ value }) => value?.label || "Không xác định",
      operators: {
        type: "obejct",
        collections: CUSTOMER_SOURCES || [],
      },
    },
    {
      field: "customer_gender",
      headerName: "Giới Tính",
      width: 150,
      hide: true,
      valueGetter: ({ row }) =>
        GENDERS.find((gender) => gender.value === row.customer_status),
      renderCell: ({ value }) => value?.label || "Không xác định",
      operators: {
        type: "object",
        collections: GENDERS || [],
      },
    },
    {
      field: "customer_industry",
      headerName: "Lĩnh Vực",
      width: 150,
    },
    // {
    //   field: 'customer_dob',
    //   headerName: 'Ngày Sinh',
    //   width: 150,
    //   renderCell: ({ value }) => format_date_short(value),
    //   hide: true,
    // },
    // {
    //   field: 'company_id',
    //   headerName: 'Công Ty',
    //   width: 150,
    //   renderCell: ({ value }) => value,
    //   hide: true,
    // },
    {
      field: "customer_position",
      headerName: "Chức Vụ",
      width: 150,
    },
    {
      field: "customer_website",
      headerName: "Website",
      width: 150,
      operators: { type: "string" },
    },
    {
      field: "customer_service_interesting",
      headerName: "Dịch Vụ Quan Tâm",
      width: 150,
      valueGetter: ({ row }) =>
        SERVICES.filter(
          (service) => service?.value === row?.customer_service_interesting
        ),
      renderCell: ({ value }) => value?.map((s) => s?.label).join(", "),
      hide: true,
      operators: {
        type: "object",
        collections: SERVICES || [],
      },
    },
    // {
    //   field: 'customer_updated_at',
    //   headerName: 'Dịch Vụ Sử Dụng',
    //   width: 150,
    //   valueGetter: ({ row }) =>
    //     SERVICES.filter(
    //       (service) => service?.value === row?.customer_service_interesting
    //     ),
    //   renderCell: ({ value }) => value?.map((s) => s?.label).join(', '),
    //   hide: true,
    //   operators: {
    //     type: 'object',
    //     collections: SERVICES || [],
    //   },
    // },
    // {
    //   field: 'action',
    //   headerName: 'Thao Tác',
    //   hideable: true,
    //   width: 80,
    //   valueGetter: ({ row }) => {
    //     return _.pick(row, [
    //       'customer_id',
    //       'customer_name',
    //       'customer_email',
    //       'customer_status',
    //       'customer_owner_ids',
    //     ]);
    //   },
    //   renderCell: ({ value }) => {
    //     let customerActions = [];
    //     if (value.customer_owner_ids.includes(employee?.employee_id)) {
    //       customerActions.push(
    //         ...Array.from(
    //           actions?.filter((act) =>
    //             ['update', 'send-email'].includes(act.name)
    //           )
    //         )
    //       );
    //       if (value.customer_status === 1) {
    //         customerActions.push(
    //           actions.find((act) => act.name === 'mark-spam')
    //         );
    //       }
    //     }
    //     if (customerActions.length === 0) return <></>;
    //     return (
    //       <Box
    //         sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
    //       >
    //         <IconButton
    //           onClick={(event) => {
    //             setPopper((prev) => ({
    //               ...prev,
    //               anchorEl: event?.target,
    //               data: value,
    //               actions: customerActions,
    //             }));
    //           }}
    //           title='Hành động'
    //         >
    //           <MoreVertOutlined sx={{ m: 'auto' }} />
    //         </IconButton>
    //       </Box>
    //     );
    //   },
    // },
  ];
  const historyColumsHidden =
    JSON.parse(localStorage.getItem("customer.list.hiddenColumns")) || [];

  const [columns, setColumns] = React.useState(
    initialColumns?.map((column) => {
      if (historyColumsHidden?.includes(column?.field)) {
        return { ...column, hide: true };
      }
      return { ...column };
    }) || initialColumns
  );

  const filterables = columns
    .filter((column) => !!column?.operators && !column?.hide)
    .map((column) => {
      return {
        field: column?.key ? column.key : column.field,
        label: column?.headerName,
        operators: column?.operators,
      };
    });

  const [action, setAction] = React.useState({
    open: false,
    name: undefined,
    data: undefined,
  });

  const handleClosePopper = () =>
    setPopper((prev) => ({
      ...prev,
      anchorEl: undefined,
      data: undefined,
      actions: [],
    }));

  const handleClearAction = () =>
    setAction({ open: false, name: undefined, data: undefined });

  const handleMarkSpamCustomer = async (data) => {
    const response = await CustomerApi.MarkSpam(data?.customer_id);
    if (!response || response.status !== 200) {
      return {
        status: response?.status || 404,
        message: "Chuyển khách hàng thất bại.",
      };
    }
    return {
      status: 200,
      message: "Chuyển khách hàng sang trạng thái spam thành công.",
    };
  };
  const getModalConfirm = (modelType, data) => {
    switch (modelType) {
      case "mark-spam":
        return (
          <ModalConfirm
            callback={callbackCustomers}
            handleClose={handleClearAction}
            handleSubmit={() => handleMarkSpamCustomer(data)}
            dialog={{
              title: "Khách hàng Spam",
              content: (
                <Typography>
                  Khách hàng <b>"{data?.customer_email}"</b> sẽ được chuyển sang
                  khách hàng spam, bạn sẽ không thể tìm thấy khách hàng này tại
                  quản lí khách hàng nữa, toàn bộ thông tin về sản phẩm/hóa đơn
                  cũng sẽ được xóa. Thao tác sẽ không thể được khôi phục, bạn có
                  muốn thực hiện?
                </Typography>
              ),
            }}
          />
        );
      case "send-email":
        return (
          <ModalConfirm
            callback={callbackCustomers}
            handleClose={handleClearAction}
            handleSubmit={() =>
              console.log("You have been clicked on send-email")
            }
            dialog={{
              title: "Gửi email cho khách hàng",
              content: "Gửi email cho khách hàng....",
            }}
          />
        );

      default:
        return null;
    }
  };

  const {
    data,
    isFetching,
    refetch: callbackCustomers,
  } = CustomerApi.List({
    page: page?.page + 1,
    pageSize: page?.pageSize,
    query: Filter.stringify(query),
    searchQuery: filterableCustomer() !== "" ? filterableCustomer() : null,
  });

  return (
    <Box>
      {popper?.anchorEl !== undefined && (
        <Popper
          anchorEl={popper?.anchorEl}
          open={Boolean(popper.anchorEl)}
          onMouseLeave={handleClosePopper}
        >
          <PopperAction {...popper} handleClose={handleClosePopper} />
        </Popper>
      )}
      {action.open &&
        action?.name !== undefined &&
        getModalConfirm(action?.name, action?.data)}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          flexDirection: {
            xs: "column",
            md: "row",
          },
          gap: 2,
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
          }}
        >
          <Typography className="page-title">Khách Hàng</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: {
              xs: "space-between",
              md: "flex-end",
            },
          }}
        >
          {<Buttons.ButtonAssignee />} {<Buttons.ButtonCreate />}
        </Box>
      </Box>
      <Statistical />
      <DataGridFilter
        callback={updateQuery}
        filters={{
          key: "customers.list.filters",
          filterables: filterables,
        }}
      />
      <DataGrid
        rows={
          data?.customers?.map((customer) => ({
            ...customer,
            id: customer?.customer_id,
          })) || []
        }
        columns={columns}
        loading={isFetching}
        componentsProps={{
          toolbar: {
            store: "customer.list.hiddenColumns",
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: "customer",
            inputSearch: {
              placeholder:
                "Nhập tên, email hoặc số điện thoại khách hàng tìm kiếm...",
              value: searchQuery,
              onChange: setSearchQuery,
            },
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize || 20,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        rowCount={data?.pagination?.total}
      />
    </Box>
  );
};
export default withSuspense(
  withPermission(List, { feature: "customer", action: "index" }),
  MuiSkeleton["DataGrid"]
);
