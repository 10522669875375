import Layout from "./Layout";
import Information from "./ServiceInformation";
import Addon from "./ServiceAddon";
import Mailer from "./ServiceMailer";
import Invoice from "./Invoice";
import Subscription from "./ServiceSubscription";
import Setting from "./ServiceSetting";
import Pricing from "./ServicePricingConfigures";
import Usage from "./ServiceUsage";
import Analytics from "./Analytics";


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Layout,
  Information,
  Pricing,
  Addon,
  Mailer,
  Invoice,
  Subscription,
  Setting,
  Usage,
  Analytics
};
