import { INDUSTRIES } from '@/libs/constants';
import ErrorHelperText from '@components/shared/ErrorHelperText';
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize';
import { FormControl, Typography } from '@mui/material';
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form';

const CustomerIndustry = () => {
    const { control, setValue, formState: { errors } } = useFormContext();

    return (
        <React.Fragment>
            <Typography sx={{ mb: 0.5, color: "#777680", fontSize: 14 }}>
                Lĩnh Vực/ Ngành Nghề *
            </Typography>
            <Controller
                control={control}
                name="customer.customer_industry"
                render={({ field: { value, onChange } }) => {
                    const selected = INDUSTRIES?.find(idus => idus?.value === value) || null
                    return (
                        <FormControl fullWidth>
                            <SingleSelectCustomize
                                value={selected}
                                options={INDUSTRIES}
                                input={{ placeholder: "Chọn Lĩnh Vực/ Ngành Nghề..." }}
                                onChange={(event, newValue) => {
                                    onChange(newValue?.value || null)
                                    setValue("customer.customer_industry_detail", -1)
                                }}
                            />
                            {!!errors?.customer?.customer_industry && <ErrorHelperText message={errors?.customer?.customer_industry?.message} />}
                        </FormControl>
                    )
                }} />
        </React.Fragment>
    )
}

export default CustomerIndustry