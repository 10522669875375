import { UNLIMITED } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import { useStateArrayContext } from '@contexts/stateArray'
import { FormControl, TextField } from '@mui/material'
import React from 'react'

const ServicePriceExceed = ({ data }) => {
    const { updateAttribute } = useStateArrayContext()

    return (
        <FormControl fullWidth>
            <TextField
                size='small'
                InputProps={{
                    inputComponent: NumericFormatCustom
                }}
                value={data?.service_price_exceeds?.[0]?.pricing}
                placeholder='0'
                onChange={(event) => {
                    updateAttribute({
                        service_price_exceeds: [
                            { range_start: 0, range_end: UNLIMITED, pricing: event?.target?.value }
                        ]
                    }, data?.id)
                }}
            />
        </FormControl>
    )
}

export default ServicePriceExceed