import { format_date_short } from "@/functions";
import { POSTMAN_TRACKING_STATUS } from "@/libs/constants";
import { Box, Button, Chip, StepLabel, Typography } from '@mui/material';
import React from 'react';
import PrivateIconWithFormat from '../shared/icons';
import FormPostmanTracking from './FormPostmanTracking';

const StepSendPostmanTracking = ({ data, callbackContractNumber }) => {

    const [postmanStatus, setPostmanStatus] = React.useState(POSTMAN_TRACKING_STATUS.find(stt => stt.value === data?.postman_tracking_status))

    const [openForm, setOpenForm] = React.useState(false)

    React.useEffect(() => {
        setPostmanStatus(POSTMAN_TRACKING_STATUS.find(stt => stt.value === data?.postman_tracking_status))
    }, [data])

    return (
        <React.Fragment>
            {openForm && <FormPostmanTracking
                callbackContractNumber={callbackContractNumber}
                handleClose={() => setOpenForm(false)}
                data={data} />}
            <StepLabel
                StepIconProps={{
                    sx: {
                        fontSize: 20,
                        mb: `132px`
                    }
                }}>
                <Chip size='verysmall'
                    variant='customize-blueGrey'
                    label="Xử Lý Thư" />
                <Box sx={{ display: 'flex', mb: 1 }}>
                    <PrivateIconWithFormat icon="CalendarIcon" />
                    <Typography sx={{ mr: 2 }}>
                        Đã gửi thư cho đơn vị chuyển phát vào {format_date_short(data?.postman_tracking_sent_date)}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', mb: 1 }}>
                    <PrivateIconWithFormat icon="QrCodeIcon" />
                    <Typography>
                        Mã Vận Đơn {data?.postman_tracking_mvd}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', mb: 1 }}>
                    <PrivateIconWithFormat icon="AssigneeIcon" />
                    <Typography sx={{ color: "#9e9e9e", fontSize: 14, m: 'auto 0' }}>
                        Người Xử Lý: <span style={{ color: "#000" }}>
                            {data?.employee?.employee_name}
                        </span>
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ color: "#757575", m: 'auto 8px auto 0', fontSize: 14 }}>Trạng Thái: </Typography>
                    <Typography sx={{ color: postmanStatus?.color, m: 'auto 8px auto 0', fontSize: 14 }}>{postmanStatus?.label}</Typography>
                    <Button sx={{ height: 36 }} size="small"
                        onClick={() => setOpenForm(true)}
                        disabled={openForm}
                    >
                        {[2, 3].includes(postmanStatus?.value) ? "Xem" : "Cập Nhật Trạng Thái"}
                    </Button>
                </Box>
            </StepLabel>
        </React.Fragment>
    )
}

export default StepSendPostmanTracking