import withPermission from "@/hocs/withPermission";
import { EditOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { Fragment, useState } from "react";
import CreditModal from "../CreditModal";

const ButtonChangeCredit = ({ customerId, credit }) => {
  const [editable, setEditable] = useState(false);
  return (
    <Fragment>
      {editable && (
        <CreditModal
          onClose={() => setEditable(false)}
          commitCredit={credit}
          customerId={customerId}
        />
      )}
      <IconButton
        size="small"
        onClick={() => setEditable(true)}
        disabled={editable}
        aria-label="Chỉnh sửa"
      >
        <EditOutlined fontSize="small" color="primary" />
      </IconButton>
    </Fragment>
  );
};

export default withPermission(ButtonChangeCredit, {
  feature: "customer",
  action: "show",
  type: "Button",
  // action: 'change-credit-commitment',
});
