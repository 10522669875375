import { CUSTOMER_JOURNEYS, CUSTOMER_JOURNEY_STATUS } from "@/libs/constants";
import { Typography } from "@mui/material";
import { useState } from "react";

const JourneyStatus = ({ journey }) => {
  const [step] = useState(
    CUSTOMER_JOURNEYS.find((x) => x?.value === journey?.customer_journey_step)
      ?.label
  );
  return (
    <Typography
      sx={{ display: "inline-flex", fontSize: 14, ml: 0.5 }}
      color="text.success"
    >
      {step}
      <Typography
        sx={{ display: "inline-flex", m: "auto 4px", fontSize: 14 }}
        color="text.grey"
      >
        {" "}
        |{" "}
      </Typography>
      {
        CUSTOMER_JOURNEY_STATUS?.find(
          (x) => x?.value === journey?.customer_journey_step_status
        )?.label
      }
    </Typography>
  );
};

export default JourneyStatus;
