import { DeleteOutlineOutlined } from '@mui/icons-material'
import AddOutlined from '@mui/icons-material/AddOutlined'
import { Box, FormControl, IconButton, Typography } from '@mui/material'
import { useFieldArray, useFormContext } from 'react-hook-form'
import IDC from './IDC'

const ColocationMetadata = () => {
    const { control } = useFormContext()
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'idc'
    })
    return (
        <Box sx={{ mt: 1 }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center', mb: 0.75
            }}>
                <Typography sx={{
                    fontSize: 14,
                    color: '#00001F'
                }}>
                    Thông Tin IDC
                </Typography>
                <IconButton size='small' onClick={() => append({ id: -1, name: '', rack_id: -1, rack_name: '' })}>
                    <AddOutlined fontSize='small' color="primary" />
                </IconButton>
            </Box>
            {fields?.map((field, index) => {
                return (
                    <Box sx={{ display: 'flex', flexDirection: { xs: "column", md: 'row' }, gap: 1.5, mb: 1.5 }} key={field.id}>
                        <IDC index={index} />
                        <FormControl fullWidth>
                            <IconButton size='small'
                                onClick={() => remove(index)}
                                sx={{
                                    height: 40,
                                    width: 40
                                }}>
                                <DeleteOutlineOutlined fontSize='small' color="error" />
                            </IconButton>
                        </FormControl>
                    </Box>
                )
            })}
        </Box>
    )
}

export default ColocationMetadata