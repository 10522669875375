import { GENDERS } from "@/libs/constants";
import DatePicker from "@components/DatePicker";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import Country from "../shared/Country";
import District from "../shared/District";
import Province from "../shared/Province";
import Ward from "../shared/Ward";

const Admin = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <Typography sx={{ fontSize: 14, fontWeight: "medium", mb: 1.5 }}>
        Thông tin người quản lý(admin)
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            name="domain.domain_extra_fields.adminName"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="Họ và Tên *"
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                    error={!!errors?.domain?.domain_extra_fields?.adminName}
                    helperText={
                      errors?.domain?.domain_extra_fields?.adminName?.message
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="domain.domain_extra_fields.adminID_Number"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="CMND/CCCD *"
                    placeholder="Nhập CMND/ CCCD..."
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                    error={
                      !!errors?.domain?.domain_extra_fields?.adminID_Number
                    }
                    helperText={
                      errors?.domain?.domain_extra_fields?.adminID_Number
                        ?.message
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Country
            nextProvince="adminProvince"
            nameControlManager="adminCountry"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Province
            reset="adminDistrict"
            priority="adminCountry"
            nameControlManager="adminProvince"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <District
            reset="adminWard"
            priority="adminProvince"
            nameControlManager="adminDistrict"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Ward
            province="adminProvince"
            priority="adminDistrict"
            nameControlManager="adminWard"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="domain.domain_extra_fields.adminAddress"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="Địa chỉ *"
                    placeholder="Nhập địa chỉ khách hàng..."
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                    error={!!errors?.domain?.domain_extra_fields?.adminAddress}
                    helperText={
                      errors?.domain?.domain_extra_fields?.adminAddress?.message
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="domain.domain_extra_fields.adminEmail"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="Email *"
                    placeholder="Nhập email của khách hàng..."
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                    error={!!errors?.domain?.domain_extra_fields?.adminEmail}
                    helperText={
                      errors?.domain?.domain_extra_fields?.adminEmail?.message
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="domain.domain_extra_fields.adminPhone"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="Số Điện Thoại * "
                    placeholder="Nhập số điện thoại..."
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                    error={!!errors?.domain?.domain_extra_fields?.adminPhone}
                    helperText={
                      errors?.domain?.domain_extra_fields?.adminPhone?.message
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="domain.domain_extra_fields.adminGender"
            control={control}
            render={({ field: { value, onChange } }) => {
              const selected = GENDERS.find((x) => x.value === value) || null;
              return (
                <FormControl fullWidth>
                  <SingleSelectCustomize
                    value={selected}
                    options={GENDERS.slice(0, 2)}
                    onChange={(event, newValue) =>
                      onChange(newValue?.value || "")
                    }
                    input={{
                      placeholder: "chọn giới tính...",
                      label: "Giới tính *",
                    }}
                  />
                  {!!errors?.domain?.domain_extra_fields?.adminGender && (
                    <ErrorHelperText
                      message={
                        errors?.domain?.domain_extra_fields?.adminGender
                          ?.message
                      }
                    />
                  )}
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="domain.domain_extra_fields.adminFax"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="Số FAX"
                    placeholder="Số FAX..."
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="domain.domain_extra_fields.adminBirthdate"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <DatePicker
                    value={value}
                    onChange={onChange}
                    label="Ngày Sinh *"
                  />
                  {!!errors?.domain?.domain_extra_fields?.adminBirthdate && (
                    <ErrorHelperText
                      message={
                        errors?.domain?.domain_extra_fields?.adminBirthdate
                          ?.message
                      }
                    />
                  )}
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="domain.domain_extra_fields.uiCompany"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="Tên Công Ty"
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                    placeholder="Nhập tên công ty..."
                    error={!!errors?.domain?.domain_extra_fields?.uiCompany}
                    helperText={
                      errors?.domain?.domain_extra_fields?.uiCompany?.message
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="domain.domain_extra_fields.uiPosition"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="Chức Vụ"
                    placeholder="Nhập thông tin chức vụ admin..."
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                    error={!!errors?.domain?.domain_extra_fields?.uiPosition}
                    helperText={
                      errors?.domain?.domain_extra_fields?.uiPosition?.message
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="domain.domain_extra_fields.sendmail"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={value}
                        onChange={onChange}
                      />
                    }
                    label="Gửi mail thông báo cho khách hàng(PA)"
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Admin;
