import ServicePriceApi from "@/apis/beta/Service/ServicePriceApi";
import { queryClientRefetchScope } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import NumericFormatCustom from "@components/NumericFormatCustom";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useNotificationContext } from "@contexts/notification";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

const PriceRecurring = ({ service, onClose }) => {
    const queryClient = useQueryClient();
    const { setNotification } = useNotificationContext();
    const [submit, setSubmit] = useState(false);

    const { control, handleSubmit } = useForm({
        defaultValues: {
            service_recurring: service?.service_recurring,
        },
    });

    const onSubmit = async (values) => {
        setSubmit(true);
        const response = await ServicePriceApi.ChangePriceRecurring({
            serviceId: service?.service_id,
            data: { ...values },
        });
        setSubmit(false);

        if (!response || response?.errors)
            return setNotification({
                open: true,
                message: response?.errors?.[0]?.message,
                severity: "error",
            });

        setNotification({
            open: true,
            message: `Cập nhật giá bán gia hạn dịch vụ thành công.`,
            severity: "success",
        });
        queryClientRefetchScope(queryClient, 'service')
        return onClose();
    };
    return (
        <Dialog open={true} maxWidth="md" fullWidth>
            <DialogTitle>Thay Đổi Giá Gia Hạn Dịch Vụ</DialogTitle>
            <DialogContent>
                <Controller
                    name="service_recurring"
                    control={control}
                    rules={{ required: 'Vui lòng nhập đơn giá gia hạn dịch vụ...' }}
                    render={({ field, fieldState: { error } }) => {
                        return (
                            <FormControl fullWidth sx={{ mt: 1.5 }}>
                                <TextField
                                    {...field}
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="Đơn giá gia hạn *"
                                    InputProps={{
                                        inputComponent: NumericFormatCustom,
                                    }}
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            </FormControl>
                        );
                    }}
                />
                <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end", gap: 2 }}>
                    <Button
                        size="small"
                        variant="outlined"
                        disabled={submit}
                        onClick={onClose}
                    >
                        Huỷ Thao Tác
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        disabled={submit}
                        startIcon={submit && <CircularProgress size={14} />}
                    >
                        Lưu Thay Đổi
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default withSuspense(PriceRecurring, MuiSkeleton["LinearProgress"]);
