import { FormControl, TextField } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const Description = () => {
    const { control } = useFormContext()
    return (
        <Controller
            name='service_note'
            control={control}
            render={({ field: { value, onChange } }) => (
                <FormControl fullWidth>
                    <TextField
                        label="Ghi chú"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={value}
                        onChange={onChange}
                        multiline
                        minRows={3}
                        placeholder="Nhập ghi chú..." />
                </FormControl>
            )}
        />
    )
}

export default Description