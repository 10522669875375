import BorderLinearProgress from "@/components/BorderLinearProgress"
import withPermission from "@/hocs/withPermission"
import { CONTRACTS_STATUS } from '@/libs/constants'
import DragDropFile from '@components/shared/DragDropFile'
import SingleDocument from '@components/shared/SingleDocument'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, FormControl, Typography } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

const Finance = ({
    attachment,
    setAttachment,
    disabled
}) => {
    const { control } = useFormContext()

    const fileHandler = (file) => {
        const extension = file?.name?.split(".")[1]?.toLowerCase();
        if (extension === undefined) return;
        setAttachment((prev) => ({ ...prev, file: file }));
    };
    return (
        <>
            <Controller
                name="subcontract_status"
                control={control}
                render={({ field: { value, onChange } }) =>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <SingleSelectCustomize
                            value={CONTRACTS_STATUS?.slice(1)?.find(x => x.value === value) || undefined}
                            options={CONTRACTS_STATUS?.slice(1)}
                            input={{
                                label: "Trạng thái PLHĐ *",
                                placeholder: "Chọn trạng thái PLHĐ..."
                            }}
                            onChange={(event, newValue) => onChange(newValue?.value || -1)}
                        />
                    </FormControl>
                }
            />

            <Typography
                component="div"
                sx={{
                    mt: 2,
                    fontSize: 14,
                    color: "#777680",
                }}
            >
                Tệp Đính Kèm
            </Typography>
            <Box sx={{ width: "100%" }}>
                {!disabled && (
                    <DragDropFile handler={fileHandler} />
                )}
                {!!attachment?.file && (
                    <Box sx={{ mt: disabled ? 0 : 2 }}>
                        <SingleDocument
                            document={attachment?.file}
                            handleDelete={
                                disabled
                                    ? undefined
                                    : () => setAttachment((prev) => ({ ...prev, file: null }))
                            }
                        />
                        {attachment?.loading && (
                            <BorderLinearProgress
                                value={attachment?.percen}
                                sx={{ mt: 0.5 }}
                            />
                        )}
                    </Box>
                )}
            </Box>
        </>
    )
}

export default withPermission(Finance, { feature: 'subcontract', action: 'finance', type: "Block" })