import DayoffApi from "@/apis/beta/Employee/DayoffApi";
import DepartmentApi from "@/apis/beta/Employee/DepartmentApi";
import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import DayoffListShared from "@/components/Dayoff/List";
import { useEmployeeContext } from "@/contexts/employee";
import { buildQueryFilterCondition } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import useFilters, { Filter } from "@/hooks/useFilters";

import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import ModalConfirmPrivate from "./ModalConfirm";

const List = () => {
  const location = useLocation();
  const [query, updateQuery] = useFilters("dayoffs.list.filters", location);

  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const filterableManageDayoff = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
    const fieldQueries = [
      {
        name: "day_offs.day_off_id",
        operator: "$eq",
        value: parseInt(debouncedSearchQuery),
        disabled:
          !parseInt(debouncedSearchQuery) ||
          debouncedSearchQuery.match(/[/,-]/),
      },
      {
        name: "day_off_items.day_off_item_date",
        operator: "$eq",
        disabled: !debouncedSearchQuery.match(/[/,-]/),
        value: debouncedSearchQuery.toString(),
      },
      {
        name: "employees.employee_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
    ];
    return buildQueryFilterCondition("$or", 0, fieldQueries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const { employee } = useEmployeeContext();

  const { data: employees } = EmployeeApi.ListEmployees({});
  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const INITIAL_ACTION_LIST = [
    {
      name: "dayoff-leader-confirm",
      icon: "Admin",
      label: "Xác Nhận Trưởng Phòng",
      handleClick: (data) => {
        setAction((prev) => ({
          ...prev,
          open: true,
          data: data,
          name: "dayoff-leader-confirm",
        }));
      },
    },
    {
      name: "dayoff-bod-confirm",
      icon: "Admin",
      label: "Xác Nhận BOD",
      handleClick: (data) => {
        setAction((prev) => ({
          ...prev,
          open: true,
          data: data,
          name: "dayoff-bod-confirm",
        }));
      },
    },
  ];
  const { data: roles } = DayoffApi.CurrentRoles();

  const { data: departments } = DepartmentApi.ListDepartments();

  const {
    data,
    isFetching,
    refetch: callbackDayOffs,
  } = DayoffApi.List({
    page: page?.page + 1,
    pageSize: page?.pageSize,
    query: Filter.stringify(query),
    searchQuery:
      filterableManageDayoff() !== "" ? filterableManageDayoff() : null,
  });

  const handleClose = () => {
    return setAction((prev) => ({
      ...prev,
      open: false,
      data: undefined,
      name: undefined,
    }));
  };
  const [action, setAction] = React.useState({
    open: false,
    data: undefined,
    name: undefined,
  });
  const getModalConfirm = (modelType, data) => {
    switch (modelType) {
      case "dayoff-leader-confirm":
        return (
          <ModalConfirmPrivate
            type="leader-confirm"
            callbackDayOffs={callbackDayOffs}
            handleClose={handleClose}
            data={{
              ...data,
              departments: departments?.departments,
              employee: employees?.find(
                (e) => e?.employee_id === data?.employee_id
              ),
            }}
          />
        );
      case "dayoff-bod-confirm":
        return (
          <ModalConfirmPrivate
            type="bod-confirm"
            callbackDayOffs={callbackDayOffs}
            handleClose={handleClose}
            data={{
              ...data,
              departments: departments?.departments,
              employee: employees?.find(
                (e) => e?.employee_id === data?.employee_id
              ),
            }}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Box>
      <Header />
      <DayoffListShared
        filter={{
          filter: "dayoffs.list.filters",
          columnsHidden: "dayoffs.hiddenColumn",
        }}
        inputSearch={{
          value: searchQuery,
          onChange: setSearchQuery,
          placeholder: "Nhập mã phiếu nghỉ hoặc tên nhân viên tìm kiếm...",
        }}
        inspector={{ ...roles }}
        updateQuery={updateQuery}
        employee={employee}
        callbackDayOffs={callbackDayOffs}
        feature="management-dayoffs"
        data={data?.day_offs}
        isFetching={isFetching}
        departments={departments?.departments}
        pagination={{
          rowsPerPageOptions: [10, 20, 50, 100],
          page: page?.page || 0,
          pageSize: page?.pageSize || 20,
          onPageChange: (newPage) =>
            setPage((prevState) => ({ ...prevState, page: newPage })),
          onPageSizeChange: (newPageSize) =>
            setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          total: data?.pagination?.total,
        }}
        employees={employees}
        initialActions={INITIAL_ACTION_LIST}
      />
      {action.open && getModalConfirm(action?.name, action.data)}
    </Box>
  );
};
export default withSuspense(
  withPermission(List, { feature: "dayoff", action: "index" }),
  MuiSkeleton["DataGrid"]
);
