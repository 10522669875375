import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { FormControl } from '@mui/material'

const Domain = ({ domains = [] }) => {
    return (
        <FormControl
            disabled
            fullWidth sx={{ maxWidth: { xs: '100%', md: 300 }, minWidth: { xs: '100%', md: 300 } }}>
            <SingleSelectCustomize
                disabled
                value={{ name: 'ALL' }}
                options={[{ name: 'ALL' }, ...domains]}
                compareAttributes={['name']}
                formatCollection={{
                    label: 'name',
                    value: 'name'
                }}
                input={{
                    label: 'Partner (*)',
                    placeholder: "Chọn Partner/ Customer...."
                }}
            />

        </FormControl>
    )
}

export default Domain