import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { queryClientRefetcher } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import { PRODUCT_UNITS } from "@/libs/constants";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useNotificationContext } from "@contexts/notification";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useCallback, useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

const DeviceLiquidation = () => {
  const { setNotification } = useNotificationContext();
  const queryClient = useQueryClient();
  const { service_id: serviceId } = useParams();

  const { data: info } = ServiceApi.ServiceInformation({
    serviceId,
  });

  const buildServiceInformations = useCallback(() => {
    return info?.service_informations?.map((item) => {
      return _.pick(item, [
        "service_information_id",
        "name",
        "unit",
        "value",
        "description",
        "warehouse_item_service_tag",
      ]);
    });
  }, [info]);
  const methods = useForm({
    defaultValues: {
      service_informations: buildServiceInformations(),
    },
  });
  const { fields } = useFieldArray({
    control: methods.control,
    name: "service_informations",
  });

  const [submit, setSubmit] = useState(false);

  const onSubmit = async (values) => {
    setSubmit(true);
    const response = await ServiceApi.UpdateServiceInformations({
      serviceId,
      data: { ...values },
    });
    setSubmit(false);

    if (!response || response?.errors)
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });

    setNotification({
      open: true,
      message: `Cập nhật cấu hình dịch vụ thành công.`,
      severity: "success",
    });
    queryClientRefetcher(queryClient, [
      "services.addons.list",
      "service.informations",
      "services.detail",
    ]);
  };

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
        {fields?.map((item, index) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 1,
                mb: 1,
              }}
              key={item?.id}
            >
              <FormControl fullWidth>
                <TextField
                  value={item?.value}
                  label={item?.name}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    sx: {
                      pr: 0,
                    },
                    endAdornment: (
                      <Box
                        sx={{
                          height: "100%",
                          width: 100,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ fontSize: 14 }} color="text.grey">
                          {PRODUCT_UNITS?.find((x) => x.value === item?.unit)
                            ?.label || ""}
                        </Typography>
                      </Box>
                    ),
                  }}
                  size="small"
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  value={item?.description || "Không có thông tin..."}
                  label="Mô tả"
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                />
              </FormControl>
              <Controller
                control={methods.control}
                name={`service_informations.[${index}].warehouse_item_service_tag`}
                render={({ field: { value, onChange } }) => {
                  return (
                    <FormControl fullWidth>
                      <TextField
                        label="Service Tag"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={value}
                        onChange={onChange}
                        size="small"
                        placeholder="Nhập thông tin service tag..."
                      />
                    </FormControl>
                  );
                }}
              />
            </Box>
          );
        })}
        <Collapse in={!!methods?.formState?.isDirty}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: { xs: "column", md: "row" },
              gap: 1.5,
            }}
          >
            <Button
              disabled={submit}
              size="small"
              variant="outlined"
              onClick={() =>
                methods.reset({
                  service_informations: buildServiceInformations(),
                })
              }
            >
              Reset
            </Button>
            <Button
              size="small"
              variant="contained"
              type="submit"
              disabled={submit}
              startIcon={
                submit && (
                  <CircularProgress size={14} sx={{ color: "#777680" }} />
                )
              }
            >
              Cập Nhật
            </Button>
          </Box>
        </Collapse>
      </Box>
    </FormProvider>
  );
};

export default withSuspense(
  withPermission(DeviceLiquidation, {
    feature: "service",
    action: "informations",
  }),
  MuiSkeleton["DataGrid"]
);
