import {
  Box,
  Chip,
  CircularProgress,
  Drawer,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import dayjs from "dayjs";

import WorkDayApi from "@/apis/beta/Employee/WorkDayApi";
import DataGridCustomize from "@components/DataGrid";

const DrawerDayOfffDetail = ({ open, onClose, values }) => {
  const { data, isLoading, isError } = useQuery(
    [`get-info-${JSON.stringify(values)}`],
    () => WorkDayApi.GetInfoDayOffOrBusiness(values)
  );

  const dataInfo = [
    {
      label: "Mã Phiếu",
      value: data?.data?.[0]?.day_off_id,
    },
    {
      label: "Loại Phiếu",
      value:
        data?.data?.[0]?.day_off_type === 0 ? "Nghĩ Không Phép" : "Nghĩ Phép Năm",
    },
    {
      label: "Số Ngày Nghĩ",
      value: data?.data?.[0]?.day_off_range_time?.length,
    },
    {
      label: "Ngày Tạo",
      value: dayjs(data?.data?.[0]?.day_off_created_at).format("DD/MM/YYYY"),
    },
    {
      label: "Trạng Thái",
      value: (
        <Chip
          color={data?.data?.[0]?.day_off_status === 0 ? "warning" : "success"}
          label={
            data?.data?.[0]?.day_off_status === 0 ? "Chờ Duyệt" : "Đã Duyệt"
          }
        />
      ),
    },
  ];

  const columns = [
    {
      field: "startdayoff",
      headerName: "Ngày Nghĩ",
      width: 300,
      renderCell: ({ row }) => (
        <Typography fontSize={14}>
          {dayjs(row?.startdayoff).format("DD/MM/YYYY")}
        </Typography>
      ),
    },
    {
      field: "dayoff_option",
      headerName: "Thời Gian",
      width: 300,
      renderCell: ({ row }) => (
        <Typography fontSize={14}>
          {row?.dayoff_option === 0
            ? "Buổi Sáng"
            : row?.dayoff_option === 1
              ? "Buổi Chiều"
              : "Cả Ngày"}
        </Typography>
      ),
    },
  ];

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box p={2} minWidth={800}>
        <Typography fontSize={20} fontWeight={600} mb={2}>
          Chi tiết ngày nghỉ
        </Typography>

        {(isError || data?.errors) && (
          <Typography fontWeight={400} color="error">
            {data?.errors?.[0]?.message || "Something went wrong"}
          </Typography>
        )}

        {isLoading && <CircularProgress />}

        {data?.data?.[0] && (
          <Box>
            <Grid container spacing={2}>
              {dataInfo?.map((item) => (
                <Grid key={item?.label} item md={6} sm={6} xs={12}>
                  <Typography sx={{ mb: 0.5, color: "#777680" }}>
                    {item?.label}:
                  </Typography>
                  <Typography sx={{ color: "#00000F" }}>
                    {item?.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>

            <Box mt={3}>
              <Typography>Lý Do:</Typography>
              <TextField
                sx={{ mt: 2 }}
                disabled
                aria-readonly
                fullWidth
                value={data?.data?.[0]?.day_off_reason}
                rows={10}
              />
            </Box>

            <Box mt={2}>
              <Typography>Chi Tiết:</Typography>
              <Box mt={2}>
                <DataGridCustomize
                  columns={columns}
                  rows={data?.data?.[0]?.day_off_range_time || []}
                  autoHeight
                  getRowId={(row) => row?.startdayoff}
                  hideFooter
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default DrawerDayOfffDetail;
