import BusinessApi from '@/apis/beta/Employee/BusinessApi'
import { queryClientRefetcher } from '@/functions'
import DataGridCustomize from '@components/DataGrid'
import { useNotificationContext } from '@contexts/notification'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, TextField, Typography } from '@mui/material'
import { orange } from '@mui/material/colors'
import React from 'react'
import { useQueryClient } from 'react-query'

const ModalConfirmMultiple = ({ businesses = [], onClose }) => {

    const queryClient = useQueryClient()

    const { setNotification } = useNotificationContext()
    const [feedback, setFeedback] = React.useState('Oke/ Duyệt')

    const [columns] = React.useState([
        {
            field: 'business_id', headerName: 'Mã Phiếu',
            width: 120,
        }, {
            field: 'business_employee_name', headerName: 'Tên Nhân Viên',
            width: 200,
        }, {
            field: 'business_content',
            headerName: 'Nội Dung',
            width: 150,
        }
    ])

    const onSubmit = async () => {
        const requests = businesses.map(async (business) => {
            return BusinessApi.Approve({
                businessId: business?.business_id,
                data: {
                    business_leader_feedback: feedback,
                    business_leader_confirm: true,
                    business_status: 0
                }
            })
        })
        const response = await Promise.all(requests)
        const success = response?.filter(s => s?.status === 200)
        // const failed = response?.filter(s => s?.errors)
        setNotification({
            open: true,
            message: `Các phiếu ${success?.map(item => item?.data?.business?.business_id).join(', ')} đã được duyệt thành công.`,
            severity: "success"
        })

        queryClientRefetcher(queryClient, [
            "business.list",
            "business.show"
        ])
        return onClose()
    }
    return (
        <Dialog
            fullWidth
            open={true}
            maxWidth="md"
        >
            <DialogTitle>
                Bạn đang chọn chức năng duyệt nhiều phiếu.
                <Typography sx={{ fontSize: 14, color: orange[500] }}>
                    Điều này có thể xảy ra trường hợp bỏ lỡ thông tin quan trọng trong phiếu công tác. Vui lòng cân nhắc lại trước khi thao tác.
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: "medium" }}>
                    Danh sách phiếu công tác đang được chọn
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DataGridCustomize
                    rows={businesses?.map((business) => ({ ...business, id: business?.business_id })) || []}
                    columns={columns}
                    components={{
                        Toolbar: "disabled",
                        Pagination: "disabled"
                    }}
                />
            </DialogContent>
            <DialogContentText sx={{ p: 3 }}>
                <FormControl fullWidth>
                    <TextField
                        label="Phản hồi của bạn *"
                        InputLabelProps={{
                            shrink: true
                        }}
                        multiline
                        minRows={2}
                        value={feedback}
                        onChange={(event) => setFeedback(event?.target?.value)}
                    />
                </FormControl>
            </DialogContentText>
            <DialogActions>
                <Button size='small' onClick={onClose}>
                    Huỷ Thao Tác
                </Button>
                <Button
                    size='small'
                    variant='contained'
                    onClick={onSubmit}
                >
                    Xác nhận
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalConfirmMultiple