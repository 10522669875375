import { Box, CircularProgress, Paper, Typography,Grid } from "@mui/material";
import dayjs from "dayjs";
import { useState,useMemo,useEffect } from "react";
import { useQuery } from "react-query";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import HighLightDay from "./HighLightDay";
import WorkDayDetail from "./WorkDayDetail";
import WorkDayApi from "@/apis/beta/Employee/WorkDayApi";
import { useEmployeeContext } from "@contexts/employee";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
// import PermissionDenied from "@features/App/PermissionDenied";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import {resolve_work_n_day} from "../shared";

const Index = () => {
  const [date, setDate] = useState(
    dayjs(dayjs(Date.now()).format("MM/DD/YYYY"))
  );

  const { employee } = useEmployeeContext();

  const [year, setYear] = useState(date?.get("y"));
  const [month, setMonth] = useState(date?.get("M") + 1);
  const [totalDays, settotalDays] = useState(0);
  // react query
  const { data, isLoading} = useQuery(
    `get-word-day-${year}-${month}`,
    () => WorkDayApi.Index({ year, month })
  );

  // if (isError) return <PermissionDenied />;
  
  useEffect(() => {
    if (data?.data) {
      const totalDays = data.data.reduce((total, day) => {
        return total + resolve_work_n_day(day.work_n_day);
      }, 0);
      settotalDays(totalDays);
    }
  }, [data]);
  

  const standardWorkdays = useMemo(() => {
    const startOfMonth = dayjs().year(year).month(month - 1).startOf('month');
    const endOfMonth = dayjs().year(year).month(month - 1).endOf('month');
    let count = 0;
  
    for (let day = startOfMonth; day.isBefore(endOfMonth.add(1, 'day'), 'day'); day = day.add(1, 'day')) {
      if (day.day() !== 0) { 
        count++;
      }
    }
    
    return count;
  }, [year, month]);

  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: 18, md: 24 },
          fontWeight: "medium",
        }}
      >
        Ngày Công Của Bạn
      </Typography>
      <Paper sx={{ p: 2, mt: 2, mb: 2, width: 340 }}>
        <Typography sx={{ color: '#777680', mb: 1 }}>
          Số ngày công trong tháng {month}/{year}:
          <span style={{ marginLeft: '8px', color: '#00000F', fontWeight: 'bold', fontSize: { xs: '20px', md: '28px' } }}>
            {totalDays} / {standardWorkdays}
          </span>
        </Typography>
      </Paper>
      <Grid container mt={2} gap={2}>
            <Box width={{ xs: '100%', md: "340px" }}>
              <Paper sx={{ p: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StaticDatePicker
                    showToolbar={false}
                    onChange={(value) => setDate(value)}
                    onMonthChange={(value) => {
                      setDate(value);
                      setMonth(value?.get("M") + 1);
                      setYear(value?.get("y"));
                    }}
                    onYearChange={(value) => {
                      setDate(value);
                      setYear(value?.get("y"));
                    }}
                    defaultValue={date}
                    value={date}
                    loading={isLoading || !data?.data}
                    renderLoading={() => <CircularProgress size={25} />}
                    renderDay={(day, select, props) => (
                      <HighLightDay
                        highlightedDays={data?.data}
                        day={day}
                        select={select}
                        others={props}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Paper>
            </Box>
            <WorkDayDetail
              work_day={data?.data?.find(
                (item) => new Date(item?.work_day_date).getDate() === date.date()
              )}
              isLoading={isLoading}
              employee={employee}
            />
        </Grid>
    </Box>
  );
};

export default withSuspense(
  withPermission(Index, {
    feature: "workdays",
    action: "index",
  }),
  MuiSkeleton["DataGrid"]
);
