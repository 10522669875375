import { Box, Card, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import SettingTab from '../../shared/SettingTab'
const Mainboard = () => {
    return (
        <Box>
            <Typography sx={{ color: 'grey.700', mb: 2, fontSize: 14 }}>
                Settings.General.Mainboard
            </Typography>
            <SettingTab />
            <Box sx={{ mt: 2 }}>
                <Link to='settings/assignees'>
                    <Box as={Card} sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        p: 3,
                        width: 'calc(100%/3)'
                    }}>
                        <Typography sx={{
                            m: 'auto',
                            fontSize: 20,
                            fontWeight: 'medium'
                        }}>Gửi mail</Typography>
                    </Box>
                </Link>
            </Box>
        </Box>
    )
}

export default Mainboard