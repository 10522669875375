import { requestSimple } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_SANDBOX_URL } from "@/libs/constants";

class SandboxApi {
    Provinces = (country) => {
        const { data } = useQueryWithCustomized(
            ["provinces.list", country],
            async () => {
                if (!country) return null;
                return await requestSimple(
                    `${API_SANDBOX_URL}/countries/states/${country}`
                )
                    .then(({ data }) => {
                        if (data?.error) return data;

                        return data;
                    })
                    .catch((error) => {
                        return error.response;
                    })
            },
            {
                staleTime: 10 * 60 * 1000,
                suspense: true,
                // enabled: !!localStorage.getItem("SA"),
            }
        );
        return [data?.data?.states];
    }

    Districts = (province) => {
        const { data } = useQueryWithCustomized(
            ["districts.list", province],
            async () => {
                if (!province) return null;
                return await requestSimple(
                    `${API_SANDBOX_URL}/countries/districts/${province}`
                )
                    .then(({ data }) => {
                        if (data?.error) return data;

                        return data;
                    })
                    .catch((error) => {
                        return error.response;
                    })
            },
            {
                staleTime: 10 * 60 * 1000,
                suspense: true,
                // enabled: !!localStorage.getItem("SA"),
            }
        );
        return [data?.data?.districts];
    }
    Wards = (province, district) => {
        const { data } = useQueryWithCustomized(
            ["wards.list", province, district],
            async () => {
                if (!province || !district) return null;
                return await requestSimple(
                    `${API_SANDBOX_URL}/countries/districts/${province}/wards/${district}`
                )
                    .then(({ data }) => {
                        if (data?.error) return data;

                        return data;
                    })
                    .catch((error) => {
                        return error.response;
                    })
            },
            {
                staleTime: 10 * 60 * 1000,
                suspense: true,
                // enabled: !!localStorage.getItem("SA"),
            }
        );
        return [data?.data?.wards];
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SandboxApi();
