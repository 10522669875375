import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, Menu, MenuItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import DocumentApi from "@/apis/beta/Document/DocumentApi";
import { useConfirmContext } from "@contexts/confirm";

const ButtonAction = ({ row, refetch }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { notify, mutateAsync, isLoading } = useMutationWithNotification(
    DocumentApi.DestroyShareDocument
  );

  const { showConfirmation } = useConfirmContext();

  const handleDelete = async () => {
    const response = await mutateAsync({ id: row?.id });

    if (!response?.errors) {
      notify("success", `Xoá tài liệu id: ${row?.id} thành công`);
      refetch();
    }
  };

  return (
    <div>
      <Button
        onClick={(e) => {
          e?.stopPropagation();
          setAnchorEl(e?.currentTarget);
        }}
      >
        <MoreVertIcon />
      </Button>

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem>
          <Link
            to={`/files/document/update/${row?.id}`}
            style={{ display: "flex", alignItems: "center" }}
          >
            <EditIcon sx={{ mr: 2 }} /> Chỉnh sửa
          </Link>
        </MenuItem>

        <MenuItem
          onClick={() =>
            showConfirmation({
              title: "Bạn có chắc muốn xoá",
              open: true,
              description: "Thao tác này không thể khôi phục",
              loading: isLoading,
              handler: handleDelete,
            })
          }
        >
          <DeleteIcon sx={{ mr: 2 }} /> Xoá
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ButtonAction;
