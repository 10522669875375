import DatePicker from "@components/DatePicker";
import MuiCurrency from "@components/shared/CurrencyInputCustomize";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import InputFileCustomize from "@components/shared/InputFileCustomize";
import BorderLinearProgress from "@/components/BorderLinearProgress"
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import SingleDocument from "@components/shared/SingleDocument";
import { useNotificationContext } from "@contexts/notification";
import FileApi from "@/apis/shared/File/FileApi";
import SubcontractApi from "@/apis/beta/Contract/SubcontractApi";
import { useQueryClient } from "react-query";


const FormSubcontract = ({ handleClose ,  subcontract , contract }) => {
    const { control, handleSubmit, formState: { errors } } = useForm()
    const [submit, setSubmit] = React.useState(false);
    const [file, setFile] = React.useState(null);
    const { setNotification } = useNotificationContext();
    const queryClient = useQueryClient();

    const [uploadFile, handleUploadFile] = React.useState({
        loading: false,
        percen: 0
    })

    const fileHandler = async (files) => {
        const extension = files[0].name.split(".")[1]?.toLowerCase()
        if (extension === undefined) {
            return setNotification({
                open: true,
                severity: 'error',
                message: 'Thể loại tập tin không được hỗ trợ'
            })
        }
        return setFile(files[0])
    }
    
    const onSubmit = async (data) => {
        let form = data;
        if (!!file && !!file?.name) {
            const prefixPathname = "subcontracts/documents"
            const token = await FileApi.GetPresignedTokenAttachment({
                filename: {
                    name: file?.name,
                    folder: prefixPathname
                }
            })
            if (!token.token || token.status !== 200) {
                setSubmit(false)
                return setNotification({
                    open: true, message: "Lấy thông tin xử lý file thất bại", severity: 'error'
                })
            }
            handleUploadFile(prevState => ({ ...prevState, loading: true, percen: 0 }))
            const uploaded = await FileApi.UploadAttachmentToStorage({
                token: token?.token,
                file: file,
                handleUploadFile: handleUploadFile
            })
            handleUploadFile(prevState => ({ ...prevState, loading: false, percen: 0 }))
            if (!uploaded) {
                setSubmit(false)
                return setNotification({
                    open: true,
                    message: "Tải dữ liệu thất bại",
                    severity: "error"
                })
            }

            form = {
                ...form,
                subcontract_document: Array.from(
                    [{
                        name: file?.name,
                        type: file?.type,
                        size: file?.size,
                        url: ["pub", prefixPathname, token?.filename].join("/")
                    }]
                )
            }
        }
        SubcontractApi.Update({contractId: contract?.contract_id , subcontractId: subcontract?.subcontract_id, data: form}).then(response => {
            if (!response || !response?.data?.subcontract) {
                return setNotification({
                    open: true, message: "Cập nhật phụ lục hợp đồng mới thất bại.", severity: "error"
                })
            }

            queryClient.refetchQueries(["subcontract.detail"])

            return [
                handleClose(),
                setNotification({
                    open: true, message: "Cập nhật phụ lục hợp đồng mới thành công.", severity: "success"
                })
            ]
        })
    }

    return (
        <>
            <Dialog
                open={true}
                maxWidth='sm'
                fullWidth>
                <DialogTitle>
                    Cập Nhật Phụ Lục Hợp Đồng Khách Hàng
                </DialogTitle>
                <DialogContent>
                    <Box component="form">
                        <Typography sx={{ mb: 0.5 }}>Mã Số Phụ Lục Hợp Đồng</Typography>
                        <TextField
                            value={subcontract?.subcontract_code}
                            disabled
                            size='small'
                            fullWidth
                        />
                        <Typography sx={{ mb: 0.5, mt: 2 }}>Tổng Tiền *</Typography>
                        <Controller
                            control={control}
                            name="subcontract_value"
                            defaultValue={subcontract?.subcontract_value}
                            render={({ field: { value, onChange } }) =>
                                <FormControl fullWidth>
                                    <MuiCurrency
                                        onChange={onChange}
                                        value={value}
                                        min={0}
                                    />
                                    {!!errors?.subcontract_value &&
                                        <ErrorHelperText message={errors?.subcontract_value?.message} />}
                                </FormControl>
                            } />
                        <Typography sx={{ mb: 0.5, mt: 2 }}>Ngày Bắt Đầu Có Hiệu Lực *</Typography>
                        <Controller
                        control={control}
                        defaultValue={subcontract?.subcontract_effective_date}
                        name="subcontract_effective_date"
                        render={({ field: { value, onChange } }) =>
                            <FormControl fullWidth>
                                <DatePicker value={value} onChange={onChange} />
                            </FormControl>
                        } />
                        <Typography sx={{ mb: 0.5, mt: 2 }}>Ngày Hết Hạn Hiệu Lực *</Typography>
                        <Controller
                        control={control}
                        defaultValue={subcontract?.subcontract_expiry_date}
                        name="subcontract_expiry_date"
                        render={({ field: { value, onChange } }) =>
                            <FormControl fullWidth>
                                <DatePicker value={value} onChange={onChange} />
                                {!!errors?.expiry_date && <ErrorHelperText message={errors?.expiry_date?.message} />}
                            </FormControl>
                        } />
                        <Typography sx={{ mb: 0.5, mt: 2 }}>Tệp Tin Đính Kèm *</Typography>
                        <InputFileCustomize fileHandler={fileHandler}
                            accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                        />
                        {!!errors?.file && <ErrorHelperText message={errors?.file?.message} />}
                        {file &&
                            <React.Fragment>
                                <SingleDocument document={file} handleDelete={() => setFile(null)} />
                                {uploadFile?.loading && <BorderLinearProgress value={uploadFile?.percen} />}
                            </React.Fragment>}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button size='small' sx={{ height: 36 }} variant="text" disabled={submit} onClick={handleClose}>
                        Huỷ Thao Tác
                    </Button>
                    <Button size='small' sx={{ height: 36 }} variant="outlined"
                        startIcon={submit && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                        disabled={submit} onClick={handleSubmit(onSubmit)}>
                        Cập Nhật Phụ Lục Hợp Đồng
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default FormSubcontract;