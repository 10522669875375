import BreadCrumb from '@components/shared/BreadCrumb'
import { Typography } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'

const InvoiceHeader = () => {
    const { invoice_id } = useParams()
    return (
        <React.Fragment>
            <Typography sx={{
                fontSize: { xs: 18, md: 24 },
                fontWeight: 'medium'
            }}>
                Chi Tiết Hoá Đơn
            </Typography>
            <BreadCrumb
                data={[
                    { label: "Danh Sách Hoá Đơn", reactLink: "/invoices" },
                    { label: invoice_id || "---" }
                ]}
            />
        </React.Fragment>
    )
}

export default InvoiceHeader