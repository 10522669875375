import ProductApi from "@/apis/beta/Product/ProductApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box } from "@mui/material";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import Metadata from "./Metadata";
import Addons from "../Addons";

const Detail = () => {
  const { product_id } = useParams();

  const { data: product, refetch: callback } = ProductApi.Detail({
    id: product_id,
  });

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          gap: 2,
          mb: 2,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Metadata product={product} callback={callback} />
        </Box>
        <Box sx={{ minWidth: "20%" }} />
      </Box>
      <Addons />
    </Fragment>
  );
};

export default withSuspense(
  withPermission(Detail, { feature: "product", action: "show" }),
  MuiSkeleton["PaperInformation"]
);
