import { encodeBase64, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";

class WarehouseProcessApi {
  index = ({ query = null, searchQuery = null, page = 1, pageSize = 20 }) =>
    useQueryWithCustomized(
      [
        "warehouse.warehouse_processes.list",
        query,
        searchQuery,
        page,
        pageSize,
      ],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_processes?${
            query ? `&filters=${encodeBase64(query)}` : ""
          }${
            searchQuery ? `&queries=${searchQuery}` : ""
          }&page=${page}&page_size=${pageSize}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              warehouse_processes: data?.data?.warehouse_processes.map((o) => ({
                id: o.warehouse_process_id,
                ...o,
              })),
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );

  Create = async (params, service_id = null) =>
    (
      await requestWithToken(
        `${API_WAREHOUSE_V1_URL}/warehouse_processes`,
        "POST",
        {
          warehouse_process: params,
          service_id: service_id,
        }
      )
    )?.data;
  Update = async ({ id, params }) => {
    return (
      await requestWithToken(
        `${API_WAREHOUSE_V1_URL}/warehouse_processes/${id}`,
        "PUT",
        { warehouse_process: params }
      )
    )?.data;
  };

  Show = (id, callback) => {
    return useQueryWithCustomized(
      ["warehouse_processes.detail", id],
      async () => {
        if (!id) return null;
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_processes/${id}`
        )
          .then(({ data }) => {
            if (data?.errors) return callback(data.errors);

            return {
              warehouse_process: data?.data?.warehouse_process,
            };
          })
          .catch((error) => {
            console.log(error);

            return error.response;
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        notifyOnChangePropsExclusions: ["isStale"],
        suspense: true,
      }
    );
  };

  Approved = (id, params) =>
    requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_processes/${id}/approved`,
      "PUT",
      params
    );
  Cancelled = async (params) => {
    const response = await requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_processes/${params?.id}/cancelled`,
      "PUT",
      params
    );

    return response?.data;
  };
  // FindServiceByServiceId = async (id) => {
  //   return useQueryWithCustomized(
  //     ["warehouse_process", id],
  //     async () => {
  //       if (!id) return null;
  //       return await requestWithToken(
  //         `${API_WAREHOUSE_V1_URL}/warehouse_processes/find_by_service/${id}`
  //       )
  //         .then(({ data }) => {
  //           if (data?.errors) return data.errors;

  //           return {
  //             warehouse_process: data?.data?.warehouse_process,
  //           };
  //         })
  //         .catch((error) => {
  //           console.log(error);

  //           return error.response;
  //         });
  //     },
  //     {
  //       staleTime: 10 * 60 * 1000,
  //       notifyOnChangePropsExclusions: ["isStale"],
  //       suspense: true,
  //     }
  //   );
  // }
  FindServiceByServiceId = ({ service_id }) =>
    useQueryWithCustomized(
      [
        "warehouse.warehouse_processes",
        service_id],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_processes/find_by_service/${service_id}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.warehouse_process
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  CheckItemExistInProcess = async ({params}) => {
    // return useQueryWithCustomized(
    //   ["warehouse_processes", id],
    //   async () => {
    //     if (!id) return null;
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_processes/check_items?${params}`
        )
          .then(({ data }) => {
            // if (data?.errors) return callback(data.errors);
            return data?.data?.warehouse_processes || []
          })
          .catch((error) => {
            console.log(error);
            return [];
          });
      }
      // {
      //   staleTime: 10 * 60 * 1000,
      //   notifyOnChangePropsExclusions: ["isStale"],
      //   suspense: true,
      // }
    // );
  CancelCompletedProcessExport = async (params) => {
    const response = await requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_processes/${params?.id}/cancel_completed_process_export`,
      "PUT",
      params
    );

    return response?.data;
  };
}

export default WarehouseProcessApi = new WarehouseProcessApi();
