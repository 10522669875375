import ServiceApi from "@/apis/beta/Service/ServiceApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useNotificationContext } from "@contexts/notification";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

const CloudServiceFields = ({ control, defaultValues }) => {
  const { data } = ServiceApi.GetServiceProviders();

  const providers = React.useMemo(
    () =>
      data
        ?.filter((provider) =>
          ["Internal.VMware", "Internal.Opennebula"].includes(provider.scope)
        )
        ?.map((provider) => provider.zones)
        ?.flat(),
    [data]
  );

  // console.log(providers);

  return (
    <>
      <Controller
        name="os"
        control={control}
        defaultValue={defaultValues?.os || ""}
        render={({ field: { value, onChange } }) => {
          return (
            <FormControl fullWidth>
              <TextField
                size="small"
                label="Operating System"
                InputLabelProps={{ shrink: true }}
                value={value}
                onChange={onChange}
                placeholder="Nhập hệ đều hành của VM"
              />
            </FormControl>
          );
        }}
      />

      <Controller
        name="provider"
        control={control}
        defaultValue={defaultValues?.provider || "-"}
        render={({ field }) => {
          return (
            <FormControl fullWidth>
              <FormControl fullWidth>
                <InputLabel shrink id="provider">
                  Service Provider
                </InputLabel>
                <Select
                  labelId="purpose"
                  label="Service Provider"
                  size="small"
                  {...field}
                >
                  <MenuItem value="-">
                    <Typography fontSize={14}>-</Typography>
                  </MenuItem>
                  {providers?.map((item) => {
                    return (
                      <MenuItem value={item.id} key={item.id}>
                        <Typography fontSize={14}>
                          {item.description}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </FormControl>
          );
        }}
      />
    </>
  );
};

const TechnicalSetting = ({ service }) => {
  const { setNotification } = useNotificationContext();
  const { service_id: serviceId } = useParams();

  const { data } = ServiceApi.ServiceInformation({ serviceId });
  const [submit, setSubmit] = React.useState(null);

  const defaultValues = React.useMemo(
    () => ({
      domain:
        data?.service_extra_fields?.domain ||
        data?.service_extra_fields?.domain_name ||
        "",
      ip:
        data?.service_extra_fields?.ip || data?.service_extra_fields?.IP || "",
      os: data?.service_extra_fields?.os || "",
      provider: data?.service_extra_fields?.provider || "-",
      round: data?.service_extra_fields?.round || false,
      uid: data?.service_extra_fields?.uid || "",
    }),
    [data]
  );

  const { control, handleSubmit } = useForm({
    defaultValues,
  });

  const onSubmit = async (values) => {
    setSubmit(true);
    const response = await ServiceApi.Update({
      serviceId,
      data: {
        service_extra_fields: {
          ...data?.service_extra_fields,
          ...values,
        },
      },
    });
    setSubmit(false);

    if (!response) {
      return setNotification({
        open: true,
        message: "Failed to update service extra fields.",
        severity: "error",
      });
    }
    try {
      // callback()
    } catch (error) {
      console.log(error?.message);
    }
    return setNotification({
      open: true,
      message: "Updated service extra fields.",
      severity: "success",
    });
  };

  return (
    <Box
      component="form"
      sx={{
        mt: 2,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: { xs: "column", md: "row" },
        gap: { xs: 1.5, md: 2 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          width: "100%",
        }}
      >
        {[
          "ConversantService",
          "TranscodingService",
          "TransmuxingService",
          "RecordingService",
          "StorageLFDService"
        ].includes(service?.service_creatable_type) && (
            <Controller
              name="name"
              control={control}
              defaultValue={
                data?.service_extra_fields?.entity ||
                data?.service_extra_fields?.name ||
                ""
              }
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      label="Tên Entity"
                      InputLabelProps={{ shrink: true }}
                      value={value}
                      onChange={onChange}
                      placeholder="Cấu hình thông tin tên entity..."
                    />
                  </FormControl>
                );
              }}
            />
          )}
        <Controller
          name="domain"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl fullWidth>
                <TextField
                  size="small"
                  label="Tên miền (nếu có)"
                  InputLabelProps={{ shrink: true }}
                  value={value}
                  onChange={onChange}
                  placeholder="Nhập tên miền (nếu có)..."
                />
              </FormControl>
            );
          }}
        />

        {service?.service_creatable_type !== "DomainService" && (
          <Controller
            name="ip"
            control={control}
            defaultValue={defaultValues.ip}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="IP (nếu có)"
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                    placeholder="Nhập IP address (nếu có)..."
                  />
                </FormControl>
              );
            }}
          />
        )}
        {service?.service_creatable_type === "VnisService" && (
          <Controller
            name="uid"
            control={control}
            defaultValue={defaultValues.ip}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="Customer ID *"
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                    placeholder="Nhập Customer ID (nếu có)..."
                  />
                </FormControl>
              );
            }}
          />
        )}
        {service?.service_creatable_type === "CloudService" && (
          <CloudServiceFields control={control} defaultValues={defaultValues} />
        )}
      </Box>
      <Button
        variant="outlined"
        disabled={submit}
        sx={{
          height: 40,
          minWidth: { xs: "100%", md: 200 },
        }}
        startIcon={
          submit && <CircularProgress size={14} sx={{ color: "#777980" }} />
        }
        onClick={handleSubmit(onSubmit)}
      >
        Cập nhật
      </Button>
    </Box>
  );
};

export default withSuspense(
  withPermission(TechnicalSetting, {
    feature: "service",
    action: "technical",
    type: "Block",
  }),
  MuiSkeleton["Loading"]
);
