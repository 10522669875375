import { Box, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const BreadCrumb = ({
    data = []
}) => {
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 2 }}>
            {data?.map((option, index) =>
                <React.Fragment key={index}>
                    {index !== 0 && <Typography className='breadcrumb'>/</Typography>}
                    {option?.reactLink ?
                        <Link to={option?.reactLink} replace key={index}>
                            <Typography className='react-link'>
                                {option?.label}
                            </Typography>
                        </Link> : <Typography color="primary">{option?.label}</Typography>}
                </React.Fragment>
            )}
        </Box>
    )
}

export default BreadCrumb