import { format_numeral_price } from '@/functions'
import { Typography, Box } from '@mui/material'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const MailStorage = ({ position }) => {

    const { control } = useFormContext()

    const mailStorageAddon = useWatch({
        control: control,
        name: `product_configoptions.[${position}]`
    })
    if (!mailStorageAddon || !mailStorageAddon?.select || !mailStorageAddon?.select?.product_addon_id) return;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 1
        }}>

            <Typography component="span" sx={{ color: "#777680" }}>
                {mailStorageAddon?.select?.product_addon_name}
                {mailStorageAddon?.select?.product_addon_id === 313 && <Typography sx={{ display: 'inline-flex', ml: 0.5 }}>
                    - {mailStorageAddon?.select?.product_addon_qty}GB
                </Typography>}
            </Typography>
            <Typography component="span" sx={{ color: "#00001F", m: 'auto 0 auto auto' }}>
                <Typography sx={{ display: 'inline-block', textDecoration: 'underline', color: "#777680" }}>đ</Typography>
                {mailStorageAddon?.select?.product_addon_id === 313 ?
                    format_numeral_price(parseFloat(mailStorageAddon?.select?.product_addon_qty) * parseFloat(mailStorageAddon?.select?.product_addon_pricing))
                    : format_numeral_price(parseFloat(mailStorageAddon?.select?.product_addon_pricing))
                }
            </Typography>
        </Box>
    )
}

export default MailStorage