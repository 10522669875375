import ServiceSubscriptionApi from "@/apis/beta/Service/ServiceSubscriptionApi";
import { queryClientRefetcher } from "@/functions";
import withPermission from "@/hocs/withPermission";
import { useNotificationContext } from "@contexts/notification";
import CounterInvoiceIcon from "@mui/icons-material/MultipleStopOutlined";
import { Box, Button, CircularProgress } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";

const ButtonCreateInvoice = ({ serviceId }) => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();
  const { mutateAsync, isLoading } = useMutation(ServiceSubscriptionApi.Create);

  const onSubmit = async () => {
    const response = await mutateAsync({
      serviceId,
      payload: {},
    });

    if (!response || response?.errors) {
      return setNotification({
        open: true,
        severity: "error",
        message: response?.errors?.[0]?.message,
      });
    }

    queryClientRefetcher(queryClient, [
      "service.invoices",
      "invoices.list",
      "invoice.detail",
    ]);
    return setNotification({
      open: true,
      severity: "success",
      message: `Tạo hoá đơn đối soát dịch vụ thành công.`,
    });
  };

  return (
    <Box sx={{ display: "flex", mt: 2, justifyContent: "flex-end" }}>
      <Button
        size="small"
        variant="contained"
        disabled={isLoading}
        onClick={onSubmit}
        startIcon={
          isLoading ? (
            <CircularProgress size={14} sx={{ color: "#777680" }} />
          ) : (
            <CounterInvoiceIcon fontSize="small" />
          )
        }
      >
        Tạo Hoá Đơn Đối Soát
      </Button>
    </Box>
  );
};

export default withPermission(ButtonCreateInvoice, {
  feature: "service",
  action: "service-subscription-create",
  type: "Button",
});
