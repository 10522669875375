import Default from './Default';
import Developer from './Developer';
import Accountant from './Accountant';
import Sale from './Sale';
import CustomerCare from './CustomerCare';
import MeasurementUnit from './MeasurementUnit';


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Default,
  Developer,
  Accountant,
  Sale,
  CustomerCare,
  MeasurementUnit
};
