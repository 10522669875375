import { Box, FormControl, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ProductName = () => {
    const { control, formState: { errors } } = useFormContext()
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 1,
            mt: 2
        }}>
            <Box sx={{ display: 'flex', minWidth: 300 }}>
                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                    Tên Sản Phẩm *
                </Typography>
            </Box>
            <Controller control={control}
                name="product_name"
                render={({ field: { value, onChange } }) => {
                    return (
                        <FormControl fullWidth>
                            <TextField
                                size='small'
                                value={value}
                                onChange={onChange}
                                placeholder='Nhập tên sản phẩm...'
                                error={!!errors?.product_name}
                                helperText={errors?.product_name?.message}
                            />
                        </FormControl>
                    )
                }} />
        </Box>
    )
}
export default ProductName