import ContactApi from "@/apis/beta/Customer/ContactApi";
import ItemPaperStatistical from "@/components/shared/ItemPaperStatistical";
import { format_numeral_price } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Grid } from "@mui/material";
import React from "react";

const Statistical = ({ query, searchQuery }) => {
  const { data } = ContactApi.Statistic({
    query,
    searchQuery,
  });

  const formatToArray = () => {
    return [
      { label: "Tổng khách hàng", value: data?.total },
      { label: "Đúng đối tượng", value: data?.matched },
      { label: "Không Đúng Đối Tượng", value: data?.un_matched },
      { label: "Spam", value: data?.spam },
      { label: "Chưa phân loại", value: data?.un_verified },
      {
        label: "Doanh Thu",
        value: data?.revenue > 0 ? format_numeral_price(data?.revenue) : "---",
      },
    ];
  };
  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      {Array.from(formatToArray()).map((item, index) => (
        <Grid item xs={6} sm={3} md={2} key={index}>
          <ItemPaperStatistical {...item} />
        </Grid>
      ))}
    </Grid>
  );
};

export default withSuspense(
  withPermission(Statistical, {
    feature: "contact",
    action: "statistic",
    type: "Block",
  }),
  MuiSkeleton["Statistical"]
);
