import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { queryClientRefetcher } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import { PRODUCT_UNITS } from "@/libs/constants";
import NumericFormatCustom from "@components/NumericFormatCustom";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { useNotificationContext } from "@contexts/notification";
import { vestResolver } from "@hookform/resolvers/vest";
import { AddOutlined, DeleteOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import _ from "lodash";
import { Fragment, useCallback, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import serviceInformationValidators from "./validator";

const Addon = ({ service, onClose }) => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = useState(false);

  const { data: info } = ServiceApi.ServiceInformation({
    serviceId: service?.service_id,
  });

  const buildServiceInformations = useCallback(() => {
    return info?.service_informations?.map((item) => {
      return _.pick(item, ["service_information_id", "name", "unit", "value"]);
    });
  }, [info]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: vestResolver(serviceInformationValidators),
    defaultValues: {
      service_informations: buildServiceInformations(),
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "service_informations",
  });

  const onSubmit = async (values) => {
    setSubmit(true);
    const response = await ServiceApi.UpdateServiceInformations({
      serviceId: service?.service_id,
      data: { ...values },
    });
    setSubmit(false);

    if (!response || response?.errors)
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });

    setNotification({
      open: true,
      message: `Cập nhật cấu hình dịch vụ ${service?.service_name} thành công, vui lòng điều chỉnh lại giá nếu có thay đổi.`,
      severity: "success",
    });
    queryClientRefetcher(queryClient, [
      "services.addons.list",
      "service.informations",
      "services.detail",
    ]);
    return onClose();
  };
  return (
    <Fragment>
      <Box
        sx={{ display: "flex", mt: 3, flexDirection: "column", gap: 1.5 }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {fields &&
          fields?.map((field, index) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: 1.5,
                }}
                key={field.id}
              >
                <Controller
                  name={`service_informations.[${index}].name`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <FormControl fullWidth>
                        <TextField
                          {...field}
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Tên *"
                          placeholder="Nhập tên dịch vụ..."
                          error={
                            !!errors?.service_informations?.[`${index}`]?.name
                          }
                          helperText={
                            errors?.service_informations?.[`${index}`]?.name
                              ?.message
                          }
                        />
                      </FormControl>
                    );
                  }}
                />
                <Controller
                  name={`service_informations.[${index}].value`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <FormControl fullWidth>
                        <TextField
                          {...field}
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            inputComponent: NumericFormatCustom,
                          }}
                          label="Thông số/ cấu hình*"
                          error={
                            !!errors?.service_informations?.[`${index}`]?.value
                          }
                          helperText={
                            errors?.service_informations?.[`${index}`]?.value
                              ?.message
                          }
                        />
                      </FormControl>
                    );
                  }}
                />
                <Controller
                  name={`service_informations.[${index}].unit`}
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    const selected =
                      PRODUCT_UNITS?.find((x) => x?.value === value) || null;
                    return (
                      <FormControl fullWidth>
                        <SingleSelectCustomize
                          value={selected}
                          options={PRODUCT_UNITS}
                          onChange={(event, newValue) =>
                            onChange(newValue?.value || "qty")
                          }
                          input={{
                            label: "Đơn vị tính *",
                          }}
                        />
                        {!!errors?.service_informations?.[`${index}`]?.unit && (
                          <ErrorHelperText
                            message={
                              errors?.service_informations?.[`${index}`]?.unit
                                ?.message
                            }
                          />
                        )}
                      </FormControl>
                    );
                  }}
                />
                <IconButton
                  size="small"
                  sx={{ m: "auto 0" }}
                  onClick={() => remove(index)}
                >
                  <DeleteOutlined
                    fontSize="small"
                    sx={{
                      ":hover": {
                        color: "#ef5350",
                      },
                    }}
                  />
                </IconButton>
              </Box>
            );
          })}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
        <Button
          size="small"
          onClick={() =>
            append({
              name: "",
              value: 1,
              unit: "qty",
            })
          }
          sx={{ height: 32 }}
          startIcon={<AddOutlined fontSize="small" />}
        >
          Thêm thông tin cấu hình
        </Button>
      </Box>
      <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end", gap: 2 }}>
        <Button
          size="small"
          variant="outlined"
          disabled={submit}
          onClick={onClose}
        >
          Huỷ Thao Tác
        </Button>
        <Button
          size="small"
          variant="contained"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={submit}
          startIcon={submit && <CircularProgress size={14} />}
        >
          Lưu Thay Đổi
        </Button>
      </Box>
    </Fragment>
  );
};

export default withSuspense(Addon, MuiSkeleton["LinearProgress"]);
