
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { FormControl } from "@mui/material";
import { useMemo } from "react";

const Filters = ({ customerContactId, setCustomerContactId, data }) => {
	const selectValue = useMemo(() => {
		if (!customerContactId) return null;
		const value = data?.find(cta => cta?.customer_contact_id === customerContactId)
		return {
			label: value?.name,
			value: value?.customer_contact_id
		}
	}, [data, customerContactId])

	return (
		<FormControl fullWidth>
			<SingleSelectCustomize
				input={{
					label: 'Liên Hệ',
					placeholder: "Chọn liên hệ",
				}}
				options={data?.map((cta) => ({
					label: cta?.name,
					value: cta?.customer_contact_id,
				}))}
				onChange={(_, newValue) => {
					setCustomerContactId(newValue?.value)
				}}
				value={selectValue}
			/>
		</FormControl>
	);
};
export default Filters;
