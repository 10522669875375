import { Box, FormControl, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const ContractNote = ({ disabled }) => {
  const { control } = useFormContext();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: 0.5,
      }}
    >
      <Box sx={{ minWidth: 300 }}>
        <Typography sx={{ fontSize: 14, color: "#777680" }}>
          Ghi Chú HĐ
        </Typography>
      </Box>
      <Controller
        name="contract_note"
        control={control}
        render={({ field }) => {
          return (
            <FormControl fullWidth>
              <TextField
                multiline
                {...field}
                disabled={disabled}
                placeholder="Nhập thông tin ghi chú..."
              />
            </FormControl>
          );
        }}
      />
    </Box>
  );
};

export default ContractNote;
