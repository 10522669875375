import React from 'react'
import { useFormContext } from 'react-hook-form'
import ProductInformation from '../shared/ProductInformation'
import ProductAddons from './ProductAddons'
import ProductBillingConfigures from "./ProductBillingConfigures"
import ProductPricingConfigures from './ProductPricingConfigures'

const Normally = () => {
    const { watch } = useFormContext()
    return (
        <React.Fragment>
            {watch("product_type") === "package" && <ProductBillingConfigures />}
            {watch("product_type") === "customized" && <ProductPricingConfigures />}
            <ProductInformation />
            <ProductAddons />
        </React.Fragment>
    )
}

export default Normally