import { downloadFileWithBinaryResponse } from '@/functions';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined'
import { Button, CircularProgress } from '@mui/material'
import React from 'react'
import { useMutation } from 'react-query';
import { serviceGcoreReportAnalytic } from '../services';

const CSVDownload = ({ uuid, payload }) => {

    const { mutateAsync, isLoading } = useMutation(serviceGcoreReportAnalytic);

    const handler = async () => {
        const response = await mutateAsync(uuid, payload)
        if (!response) return;
        downloadFileWithBinaryResponse(`${Date.now()}.xlsx`, response);
    };
    return (
        <Button size='small' variant='outlined'
            startIcon={isLoading ?
                <CircularProgress size={14} sx={{ bgcolor: "#777680" }} />
                : <FileDownloadOutlined fontSize='small' />}
            sx={{ height: '40px !important', minWidth: '120px !important' }}
            onClick={handler}
            disabled={isLoading || true}
        >
            CSV
        </Button>
    )
}

export default CSVDownload