import ServiceBillingApi from "@/apis/beta/Service/ServiceBillingApi";
import { format_date_monent_js, format_date_short, queryClientRefetchScope } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import NumericFormatCustom from "@components/NumericFormatCustom";
import DatePicker from "@components/datepickers/DateRangPicker";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useNotificationContext } from "@contexts/notification";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField
} from "@mui/material";
import dayjs from "dayjs";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

const ServiceBillingStatement = ({ service, onClose }) => {
    const { data } = ServiceBillingApi.Detail({ serviceId: service?.service_id })
    const queryClient = useQueryClient();
    const { setNotification } = useNotificationContext();

    const { mutateAsync, isLoading } = useMutation(ServiceBillingApi.Update)

    const { handleSubmit, watch, setValue, control } = useForm({
        defaultValues: useMemo(() => {
            return ({
                service_control_startdate: data?.service_control_startdate,
                service_control_enddate: data?.service_control_enddate,
                date_range_statement: data?.date_range_statement || 0
            })
        }, [data])
    });

    const onSubmit = async (values) => {
        const response = await mutateAsync({
            serviceId: service?.service_id,
            data: {
                ...values,
                service_control_startdate: format_date_short(values?.service_control_startdate?.$d || values?.service_control_startdate),
                service_control_enddate: format_date_short(values?.service_control_enddate?.$d || values?.service_control_enddate)
            }
        });

        if (!response || response?.errors)
            return setNotification({
                open: true,
                message: response?.errors?.[0]?.message,
                severity: "error",
            });

        setNotification({
            open: true,
            message: `Cập nhật thời gian đối soát dịch vụ thành công.`,
            severity: "success",
        });
        queryClientRefetchScope(queryClient, 'service')
        return onClose();
    };
    const onChangeDates = (values = []) => {
        setValue(`service_control_startdate`, values[0]?.$d || values[0])
        setValue(`service_control_enddate`, values[1]?.$d || values[1])
    }
    return (
        <Dialog open={true} maxWidth="md" fullWidth>
            <DialogTitle>Thay Đổi Thời Gian Đối Soát Dịch Vụ</DialogTitle>
            <DialogContent>
                <FormControl fullWidth sx={{ mt: 1.25, mb: 1.5 }}>
                    <DatePicker
                        value={[
                            dayjs(watch(`service_control_startdate`)),
                            dayjs(watch(`service_control_enddate`))
                        ]}
                        handler={onChangeDates}
                        localeText={{
                            start: "Từ *",
                            end: "Đến *",
                        }}
                        minDate={dayjs(watch(`service_control_enddate`)).add(-1, 'month').add(1, 'days')}
                        maxDate={dayjs(watch(`service_control_startdate`)).add(1, 'month').add(-1, 'days')}
                    />
                </FormControl>
                <Controller
                    name='date_range_statement'
                    control={control}
                    render={({ field: { value, onChange } }) =>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                label="Đặt ngày sinh đối soát*"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                size="small"
                                value={value}
                                onChange={onChange}
                                InputProps={{
                                    inputComponent: NumericFormatCustom
                                }}
                            />
                        </FormControl>
                    } />
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                        type="date"
                        disabled
                        value={format_date_monent_js(dayjs(watch(`service_control_enddate`)).add(watch(`date_range_statement`), 'days')?.$d, 'YYYY-MM-DD')}
                        label="Thời gian sinh đối soát"
                        InputLabelProps={{
                            shrink: true
                        }}
                        size="small"
                    />
                </FormControl>
                <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end", gap: 2 }}>
                    <Button
                        size="small"
                        variant="outlined"
                        disabled={isLoading}
                        onClick={onClose}
                    >
                        Huỷ Thao Tác
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        disabled={isLoading}
                        startIcon={isLoading && <CircularProgress size={14} />}
                    >
                        Lưu Thay Đổi
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default withPermission(withSuspense(ServiceBillingStatement, MuiSkeleton["LinearProgress"]), {
    feature: 'service',
    action: 'update'
});
