import PopperAction from "@components/PopperAction";
import { MoreVertOutlined } from "@mui/icons-material";
import { Box, IconButton, Popper } from "@mui/material";
import React from "react";
import ModalInvoiceItem from "../Modals";

const Action = ({ data }) => {
  const [action, setAction] = React.useState({
    open: false,
    data: undefined,
    name: undefined,
  });
  const [popper, setPopper] = React.useState({
    anchorEl: undefined,
    data: undefined,
  });

  const [actions] = React.useState([
    {
      order: 1,
      name: "invoice-item-update",
      icon: "Edit",
      label: "Chỉnh sửa",
      handleClick: (data) => {
        setAction((prev) => ({
          ...prev,
          open: true,
          data: data,
          name: "invoice-item-update",
        }));
        setPopper((prev) => ({
          ...prev,
          anchorEl: undefined,
          data: undefined,
        }));
      },
    },
    {
      order: 2,
      name: "invoice-item-destroy",
      icon: "Delete",
      label: "Xoá Line",
      handleClick: (data) => {
        setAction((prev) => ({
          ...prev,
          open: true,
          data: data,
          name: "invoice-item-destroy",
        }));
        setPopper((prev) => ({
          ...prev,
          anchorEl: undefined,
          data: undefined,
        }));
      },
    },
  ]);

  const renderModal = (type) => {
    switch (type) {
      case "invoice-item-destroy":
        return (
          <ModalInvoiceItem.Cancel
            invoiceId={data?.invoice_id}
            invoiceItemId={data?.invoice_item_id}
            onClose={() =>
              setAction({ open: false, data: undefined, name: undefined })
            }
            description={data?.invoice_item_description}
          />
        );
      case "invoice-item-update":
        return (
          <ModalInvoiceItem.Update
            invoiceId={data?.invoice_id}
            invoiceItemId={data?.invoice_item_id}
            data={data}
            onClose={() =>
              setAction({ open: false, data: undefined, name: undefined })
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
      }}
    >
      {action?.open && renderModal(action?.name)}
      {popper?.anchorEl !== undefined && (
        <Popper
          anchorEl={popper?.anchorEl}
          open={Boolean(popper.anchorEl)}
          sx={{
            zIndex: 9,
          }}
        >
          <PopperAction
            {...popper}
            actions={actions}
            handleClose={() =>
              setPopper((prev) => ({
                ...prev,
                anchorEl: undefined,
                data: undefined,
              }))
            }
          />
        </Popper>
      )}
      <IconButton
        size="small"
        title="Thao tác"
        onClick={(event) => {
          setPopper((prev) => ({
            ...prev,
            anchorEl: event?.target,
            data: data,
          }));
        }}
      >
        <MoreVertOutlined fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default Action;
