import { UNLIMITED } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import PopperAction from '@components/PopperAction'
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined'
import { Box, FormControl, Grid, IconButton, Popover, TextField, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

const PriceConfigs = ({ index = -1, committed = 0 }) => {
    const { control, watch } = useFormContext()

    const { fields, append, remove, update } = useFieldArray({
        control,
        name: `service_configoptions.[${index}].pricing.steps`
    })
    const [popper, setPopper] = useState({ data: undefined, anchorEl: undefined })

    const [actions] = useState([
        {
            order: 1,
            icon: "Add",
            label: "Thêm Dòng",
            handleClick: ({ i }) => {
                try {
                    append({
                        ...watch(`service_configoptions.[${index}].pricing.steps.[${i}]`),
                        price_id: -1,
                        start: watch(`service_configoptions.[${index}].pricing.steps.[${i}].end`),
                        end: UNLIMITED
                    })
                } catch (error) {
                    console.log('failed in remove')
                }
                return setPopper({ data: undefined, anchorEl: undefined })
            }
        }, {
            order: 2,
            icon: "Delete",
            label: "Xoá",
            handleClick: ({ i }) => {
                try {
                    remove(i)
                    update(i - 1, {
                        ...(watch(`service_configoptions.[${index}].pricing.steps.[${i - 1}]`)),
                        end: UNLIMITED
                    })
                } catch (error) {
                    console.log('failed in remove')
                }
                return setPopper({ data: undefined, anchorEl: undefined })
            }
        },
    ])
    return (
        <Box>
            {!!popper?.anchorEl &&
                <Popover open={Boolean(popper?.anchorEl)} anchorEl={popper?.anchorEl}>
                    <PopperAction
                        handleClose={() => setPopper(undefined)}
                        actions={actions}
                        data={popper?.data}
                    />
                </Popover>}
            {fields?.map((field, i) => {
                if (parseFloat(field?.end) <= committed) return null
                return (
                    <Grid container spacing={1.5} key={field?.id} sx={{ mb: 1.25 }}>
                        <Grid item xs={6} md={2.5} xl={2.5}>
                            <Controller
                                name={`service_configoptions.[${index}].pricing.steps.[${i}].start`}
                                control={control}
                                render={({ field }) =>
                                    <FormControl fullWidth>
                                        <TextField
                                            {...field}
                                            disabled={i < fields?.length - 1}
                                            label='Từ *'
                                            InputLabelProps={{ shrink: true }}
                                            size='small'
                                            InputProps={{
                                                inputComponent: NumericFormatCustom
                                            }}
                                        />
                                    </FormControl>
                                }
                            />
                        </Grid>
                        <Grid item xs={6} md={3} xl={3}>
                            <Controller
                                name={`service_configoptions.[${index}].pricing.steps.[${i}].end`}
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    const isUnlimited = parseFloat(value) >= UNLIMITED
                                    return (
                                        <FormControl fullWidth>
                                            <TextField
                                                disabled={i < fields?.length - 1}
                                                label='Đến *'
                                                InputLabelProps={{ shrink: true }}
                                                size='small'
                                                InputProps={{
                                                    sx: {
                                                        paddingRight: 0,
                                                    },
                                                    inputComponent: isUnlimited ? 'input' : NumericFormatCustom,
                                                    endAdornment: (i === fields?.length - 1) &&
                                                        <Box sx={{
                                                            display: "flex",
                                                            height: "40px",
                                                            width: isUnlimited ? "calc(100%)" : "calc(100% / 2)",
                                                            transition: "width 0.75s ease-in-out",
                                                            bgcolor: "rgba(0, 0, 0, 0.18)",
                                                            borderTopRightRadius: "4px",
                                                            borderBottomRightRadius: "4px",
                                                            cursor: "pointer",
                                                        }}
                                                            onClick={() => {
                                                                if (parseFloat(value) < UNLIMITED) {
                                                                    return onChange(UNLIMITED)
                                                                }
                                                                return onChange(field?.start)
                                                            }}
                                                        >
                                                            <Typography
                                                                color="text.success"
                                                                sx={{
                                                                    m: 'auto 8px auto auto',
                                                                    fontSize: 13,
                                                                    textTransform: 'uppercase',
                                                                    color: isUnlimited ? green[800] : "#777680"
                                                                }}>
                                                                Unlimited
                                                            </Typography>
                                                        </Box>
                                                }}
                                                onChange={onChange}
                                                value={isUnlimited ? 'UNLIMITED' : value}
                                                helperText={
                                                    (i + 1 === fields.length) && 'Vui lòng điều chỉnh trước khi thêm mốc mới'
                                                }
                                            />
                                        </FormControl>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={3} xl={3}>
                            <Controller
                                name={`service_configoptions.[${index}].pricing.steps.[${i}].pricing`}
                                control={control}
                                render={({ field }) =>
                                    <FormControl fullWidth>
                                        <TextField
                                            {...field}
                                            disabled
                                            label='Đơn giá niêm yết *'
                                            InputLabelProps={{ shrink: true }}
                                            size='small'
                                            InputProps={{
                                                inputComponent: NumericFormatCustom
                                            }}
                                        />
                                    </FormControl>
                                }
                            />
                        </Grid>
                        <Grid item xs={6} md={3} xl={3}>
                            <Controller
                                name={`service_configoptions.[${index}].pricing.steps.[${i}].pricing_apply`}
                                control={control}
                                render={({ field }) =>
                                    <FormControl fullWidth>
                                        <TextField
                                            {...field}
                                            label='Đơn giá bán *'
                                            InputLabelProps={{ shrink: true }}
                                            size='small'
                                            InputProps={{
                                                inputComponent: NumericFormatCustom
                                            }}
                                        />
                                    </FormControl>
                                }
                            />
                        </Grid>
                        {(i + 1 === fields?.length) &&
                            <Grid item xs={0.5} sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <IconButton size='small' sx={{ bgcolor: "#7776801F", m: '4px 0 auto 0' }}
                                    onClick={(event) => setPopper({
                                        data: { ...field, i },
                                        anchorEl: event?.currentTarget
                                    })}
                                >
                                    <MoreVertOutlined fontSize='small' />
                                </IconButton>
                            </Grid>}
                    </Grid>
                )
            })}
        </Box>

    )
}

export default PriceConfigs