import PermissionGroupApi from "@/apis/beta/Permission/PermissionGroupApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeletonDefault from "@components/Skeletons/Default";
import { Box, Grid } from "@mui/material";
import Detail from "../Detail";
import Header from "./Header";

const List = () => {
  const { data } = PermissionGroupApi.List();

  return (
    <Box>
      <Header />
      <Grid container spacing={2} sx={{ height: "100%" }}>
        {data?.group_permissions?.map((item, index) => {
          return (
            <Grid item xs={12} md={6} key={index}>
              <Detail data={item} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default withSuspense(
  withPermission(List, { feature: "group-role", action: "index" }),
  MuiSkeletonDefault
);
