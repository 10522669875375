import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiScrollTabs from "@components/shared/MuiScrollTabs";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import ServiceInformation from "@mui/icons-material/DisplaySettingsOutlined";
import ServicePaymentIcon from "@mui/icons-material/PaymentOutlined";
import SubscriptionIcon from "@mui/icons-material/ViewInArOutlined";
import React from "react";
import { useParams } from "react-router-dom";

const ProductTabs = () => {
  const { product_id } = useParams();
  const [INITIAL_SERVICE_TABS] = React.useState([
    {
      value: "self",
      label: "Thông Tin Cơ Bản",
      href: "",
      icon: ServiceInformation,
      // hidden: !product || isHiddenTab('show')
    },
    {
      value: "information",
      label: "Thông tin sản phẩm",
      href: "informations",
      icon: ServiceInformation,
      // hidden: !product || isHiddenTab('product-information-index')
    },
    {
      value: "pricing",
      label: "Cấu hình giá & thanh toán",
      href: "pricing",
      icon: ServicePaymentIcon,
      // hidden: !product || isHiddenTab('product-pricing-index')
    },
    {
      value: "features",
      label: "Tính năng",
      href: "features",
      icon: SubscriptionIcon,
      // hidden: !product || isHiddenTab('product-feature-index')
    },
  ]);

  return (
    <React.Fragment>
      <MuiScrollTabs
        prefix={`products/${product_id}`}
        initialTabs={INITIAL_SERVICE_TABS?.filter((tab) => !tab?.hidden)}
      />
    </React.Fragment>
  );
};

export default withSuspense(
  withPermission(ProductTabs, {
    feature: "product",
    action: "show",
  }),
  MuiSkeleton["ScrollTabs"]
);
