import { Button } from '@mui/material'
import React from 'react'
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';

const ButtonSubmit = () => {
    return (
        <Button
            fullWidth
            size='small'
            variant='contained'
            disableElevation
            type='submit'
            startIcon={<AddShoppingCartOutlinedIcon fontSize='small' />}
            sx={{
                mt: 1.5,
                bgcolor: "#ef5350 !important"
            }}>
            Thêm Vào Giỏ hàng

        </Button>
    )
}

export default ButtonSubmit