import SandboxApi from "@/apis/beta/Sandbox/SandboxApi"
import { isObjEmpty } from "@/functions"
import withSuspense from '@/hocs/withSuspense'
import ErrorHelperText from "@components/shared/ErrorHelperText"
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { FormControl } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const Districts = ({
    reset = "",
    priority = "",
    nameControlManager = ""
}) => {

    const { control, watch, formState: { errors } } = useFormContext()

    const [districts] = SandboxApi.Districts(watch(`domain.domain_extra_fields.[${priority}]`)?.value)

    return (
        <Controller
            name={`domain.domain_extra_fields.[${nameControlManager}]`}
            control={control}
            render={({ field: { value, onChange } }) => {
                return (
                    <FormControl fullWidth>
                        <SingleSelectCustomize
                            value={value}
                            disabled={!districts
                                || watch(`domain.domain_extra_fields.[${priority}]`) === ""
                                || !watch(`domain.domain_extra_fields.[${priority}]`)
                                || !!isObjEmpty(watch(`domain.domain_extra_fields.[${priority}]`))}
                            options={districts?.map((item) => ({
                                value: item.value,
                                label: item.label
                            }))}
                            onChange={(event, newValue) => onChange(newValue || null)}
                            compareAttributes={["value", "label"]}
                            input={{
                                label: "Quận/ Huyện *",
                                placeholder: "Chọn quận/ huyện..."
                            }}
                            sx={!!errors?.domain?.domain_extra_fields?.[`${nameControlManager}`] && {
                                ".MuiFormControl-root": {
                                    "label": {
                                        color: "rgb(211, 47, 47)"
                                    }
                                }
                            }}
                        />
                        {!!errors?.domain?.domain_extra_fields?.[`${nameControlManager}`] &&
                            <ErrorHelperText message={errors?.domain?.domain_extra_fields?.[`${nameControlManager}`]?.message} />}
                    </FormControl>
                )
            }} />
    )
}

export default withSuspense(Districts, MuiSkeleton["TextField"])