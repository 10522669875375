import { FormHelperText as MuiFormHelperText } from '@mui/material'
import React from 'react'

const ErrorHelperText = ({ message }) => {
    return (
        <MuiFormHelperText sx={{
            fontSize: 12,
            color: "#D32F2F"
        }}>
            {message}
        </MuiFormHelperText>
    )
}

export default ErrorHelperText