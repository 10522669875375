import React from 'react'
import DayoffForm from '../shared/Form'

const Update = ({
    data,
    callback,
    handleClose
}) => {
    return (
        <DayoffForm
            handleClose={handleClose}
            data={data}
            type="update"
            callback={callback}
        />

    )
}

export default Update