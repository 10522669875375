import ContractProcessApi from '@/apis/beta/Contract/ContractProcessApi'
import ModalConfirm from '@components/shared/ModalConfirm'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useMutation } from 'react-query'

const Cancel = ({ contractProcess, contractType = 'contracts' }) => {
    const [open, setOpen] = useState(false)

    const { mutateAsync } = useMutation(ContractProcessApi.Cancel)

    const onSubmit = async () => {
        const response = await mutateAsync({
            contractId: contractProcess?.contract_process_reference_id,
            processId: contractProcess?.contract_process_id,
            type: contractType
        })
        if (!response || response?.errors) {
            return {
                status: 404,
                message: response?.errors?.[0]?.message || 'Thao tác thất bại'
            }
        }
        return {
            status: 200,
            message: 'Huỷ vấn đề thành công.'
        }

    }
    return (
        <>
            <Button size='small' variant='text' color="error"
                onClick={() => setOpen(true)}
                disabled={open}
            >
                Huỷ Vấn Đề
            </Button>
            {open && <ModalConfirm
                dialog={{
                    title: `Xác nhận huỷ vấn đề ${contractProcess?.contract_process_title}`,
                    content: `Vui lòng chắc chắn rằng bạn muốn huỷ vấn đề này. Việc này sẽ không thể khôi phục lại.`
                }}
                handleSubmit={onSubmit}
                handleClose={() => setOpen(false)}

            />}
        </>
    )
}

export default Cancel