import { format_date_short, makeFirstLetterUppercase } from '@/functions'
import { BadgeOutlined } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'
import { useMemo } from 'react'
import ContactLocked from '../shared/Buttons/ContactLocked'
import ContactUpdate from '../shared/Buttons/ContactUpdate'
import ContactEmployee from '../shared/ContactEmployee'
import ContactChannel from '../shared/ContactInformation'
import ContactService from '../shared/ContactService'
import ContactStatus from './ContactChip'

const CONTACT_LABELS = [
    { value: 'contact-first', label: 'Thông Tin Đầu Tiên (Mặc Định)' },
    { value: 'service-manager', label: 'Quản Lý Dịch Vụ' },
    { value: 'invoice-manager', label: 'Thông Tin Xuất Hoá Đơn' }
]

const ContactDetail = ({ contact = {}, isCustomer = false }) => {

    const buildContactMetadata = useMemo(() => {
        return [
            { label: isCustomer ? 'Họ & Tên' : 'Tên Công Ty', value: contact?.name },
            { label: 'Mã Số Thuế', value: contact?.company_tax, hidden: isCustomer }, // company field
            {
                label: 'Ngày Thành Lập',
                value: !!contact?.company_established_date ? format_date_short(contact?.company_established_date) : '',
                hidden: isCustomer
            }, // company field
            {
                label: 'Website',
                value: contact?.company_website,
                hidden: isCustomer
            }, // company field
            {
                label: 'Lĩnh Vực',
                value: contact?.company_industry,
                hidden: isCustomer
            }, // company field
            {
                label: 'Địa Chỉ',
                value: contact?.company_address,
                hidden: isCustomer,
                cols: { xs: 12, md: 12, lg: 12 }
            }, // company field
            { label: 'Email', value: contact?.email, hidden: !isCustomer },
            { label: 'Số Điện Thoại', value: contact?.phone_number, hidden: !isCustomer },
            { label: 'Vị Trí', value: contact?.position, hidden: !isCustomer },
            { label: 'Lĩnh Vực/ Ngành Nghề', value: !!contact?.customer_industry && makeFirstLetterUppercase(contact?.customer_industry), hidden: !isCustomer },
            {
                label: 'Nguồn Khai Thác',
                value: !!contact?.customer_source && makeFirstLetterUppercase(contact?.customer_source),
                hidden: !isCustomer
            },
            {
                label: 'Dịch Vụ Quan Tâm',
                value: contact?.customer_service_interestings?.map((service) => makeFirstLetterUppercase(service))?.join(', '),
                hidden: !isCustomer,
                cols: { xs: 12, md: 12, xl: 6 }
            },
            {
                label: 'Sale Phụ Trách',
                value: { employeeIds: contact?.customer_sale_followers || [] },
                cols: { xs: 12, md: 12, xl: 6 },
                hidden: !isCustomer,
                component: ContactEmployee
            },
            {
                label: 'CS Phụ Trách',
                value: { employeeIds: contact?.customer_cs_followers || [] },
                cols: { xs: 12, md: 12, xl: 6 },
                hidden: !isCustomer,
                component: ContactEmployee
            },
            {
                label: 'Kênh Liên Hệ',
                value: {
                    channels: contact?.customer_contact_social || []
                },
                cols: { xs: 12, md: 12, xl: 12 },
                hidden: !isCustomer,
                component: ContactChannel
            },
            {
                label: 'Ghi Chú',
                value: contact?.customer_note,
                hidden: !isCustomer,

                cols: { xs: 12, md: 12, xl: 12 }
            }, {
                label: 'Thông Tin Dịch Vụ',
                value: {
                    customerId: contact?.customer_id,
                    contactOwnerId: contact?.contact_transfer_id
                },
                hidden: true,
                component: ContactService
            }
        ]
    }, [contact, isCustomer])

    return (
        <Box sx={{ p: 1.5 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1.25, alignItems: "center" }}>
                            <BadgeOutlined fontSize='small' color='primary' />
                            <Typography sx={{ fontWeight: 'medium' }} color="primary">
                                {CONTACT_LABELS?.find(label => label?.value === contact?.contact_label)?.label}
                            </Typography>
                        </Box>
                        <ContactStatus contactStatus={contact?.contact_status} />
                    </Box>
                </Grid>
                {buildContactMetadata?.map(({ label, value, ...props }, index) => {
                    if (props?.hidden) return null;

                    return (
                        <Grid item xs={12} md={props?.cols?.md || 4} xl={props?.cols?.xl || 3} key={index}>
                            <Typography sx={{ fontSize: 14 }} color="text.grey">
                                {label}{":"}
                            </Typography>
                            {!!props?.component ? <props.component {...value} /> :
                                <Typography color="text.black">
                                    {value}
                                </Typography>}
                        </Grid>
                    )
                })}
            </Grid>
            <Box sx={{
                mt: 2,
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: { xs: 'column', md: 'row' },
                gap: { xs: 1.5, md: 2 }
            }}>
                <ContactUpdate customerId={contact?.customer_id} contactId={contact?.customer_contact_id} contact={contact} />
                <ContactLocked customerId={contact?.customer_id} contactId={contact?.customer_contact_id} contact={contact} />
            </Box>
        </Box>
    )
}

export default ContactDetail