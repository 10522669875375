import { Box, Typography } from "@mui/material";
import ConversantService from "./ConversantService";
import VnisService from "./VnisService";

const ServiceTemplates = {
  ConversantService: ConversantService,
  StorageLFDService: ConversantService,
  TranscodingService: ConversantService,
  TransmuxingService: ConversantService,
  RecordingService: ConversantService,
  VnisService: VnisService,
  Default: () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 150,
        }}
      >
        <Typography sx={{ fontSize: 14 }}>
          Dịch vụ này hiện tại chưa có thông tin...
        </Typography>
      </Box>
    );
  },
};
export default ServiceTemplates;
