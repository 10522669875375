import { Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import ProductConfigoption from "./ProductConfigoption";
import ProductPaymentType from "./ProductPaymentType";
import ProductPricingAmount from "./ProductPricingAmount";
import ServiceManagementBilling from "@components/Templates/ServiceForm/shared/ServiceManagementBilling";
import ProductPrepaid from "../Default/private/ProductPrepaid";

const ContentDeliveryNetwork = ({ isPoc = false }) => {
  const { watch } = useFormContext();
  return (
    <Box
      sx={{
        mt: 2,
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
      }}
    >
      {!isPoc && <ProductPaymentType />}
      {!isPoc && <ProductConfigoption />}
      {watch(`product_payment_type`)?.toString() !== "-1" &&
        watch(`product_payment_type`) !== "postpaid" &&
        !isPoc && <ProductPricingAmount />}

      {!isPoc && <ProductPrepaid />}
      {!isPoc && <ServiceManagementBilling />}
    </Box>
  );
};

export default ContentDeliveryNetwork;
