import { format_date_short, format_numeral_price } from "@/functions";
import { SERVICE_STATUS_TYPES, UNLIMITED } from "@/libs/constants";
import { CellStatus } from "@components/DataGrid/Cell";
import CellPricing from "@components/DataGrid/Cell/FormatterPricing";
import { Box, Tooltip, Typography } from "@mui/material";
import Contracts from "./Contracts";
import Action from "./Action";
import ContractTimer from "./ContractTimer";
import _ from "lodash";

export const getServiceColumns = () => {
  return [
    {
      field: "sale_employee_ids",
      headerName: "Người Hưởng Hoa Hồng",
      width: 220,
      valueGetter: ({ row }) =>
        row?.service_employee_commissions
          ?.map((e) => e?.employee_name)
          ?.join(", "),
    },
    {
      field: "service_amount",
      width: 200,
      renderHeader: () => (
        <Box sx={{ width: "100%", height: "100%" }}>
          <Tooltip title="Dịch vụ SSL/Domain tính theo năm" placement="top">
            <Typography sx={{ fontWeight: "medium", fontSize: 14 }}>
              {"Đơn Giá Bán "}(Theo tháng*)
            </Typography>
          </Tooltip>
        </Box>
      ),
      valueGetter: ({ row }) => {
        if (row?.service_create_is_trial) return null;
        if (row?.service_referrer_type === "Reducer::Override::Service")
          return row?.service_addon_amount;
        return row?.service_amount;
      },
      renderCell: ({ value }) => value && <CellPricing price={value} />,
    },
    {
      field: "service_recurring",
      renderHeader: () => (
        <Box sx={{ width: "100%", height: "100%" }}>
          <Tooltip title="Dịch vụ SSL/Domain tính theo năm" placement="top">
            <Typography sx={{ fontWeight: "medium", fontSize: 14 }}>
              {"Đơn Giá Gia Hạn "}(Theo tháng*)
            </Typography>
          </Tooltip>
        </Box>
      ),
      width: 220,
      valueGetter: ({ row }) => {
        if (row?.service_create_is_trial) return null;
        if (row?.service_referrer_type === "Reducer::Override::Service")
          return row?.service_addon_recurring;
        return row?.service_recurring;
      },
      renderCell: ({ value }) => value && <CellPricing price={value} />,
    },
    {
      field: "services.service_status",
      headerName: "Trạng Thái",
      width: 140,
      valueGetter: ({ row }) =>
        SERVICE_STATUS_TYPES.find((stt) => stt?.value === row?.service_status),
      renderCell: ({ value }) => {
        return <CellStatus component="muiChip" data={value} />;
      },
    },
    {
      field: "service_start_date",
      headerName: "Ngày Bắt Đầu Sử Dụng DV",
      width: 200,
      valueFormatter: ({ value }) => format_date_short(value),
    },
    {
      field: "service_stopped_at",
      headerName: "Ngày Kết Thúc DV",
      width: 200,
      valueFormatter: ({ value }) => value && format_date_short(value),
    },
    {
      field: "service_billing_startdate",
      headerName: "Chu Kỳ Thanh Toán Hiện Tại",
      width: 200,
      valueGetter: ({ row }) => {
        if (row?.service_create_is_trial) return `TRIAL`;
        if (!row?.service_billing_startdate || !row?.service_billing_enddate)
          return ``;
        return `(${format_date_short(
          row?.service_billing_startdate
        )} - ${format_date_short(row?.service_billing_enddate)})`;
      },
    },
    {
      field: "service_nextbill_startdate",
      headerName: "Chu Kỳ Gia Hạn",
      valueGetter: ({ row }) => {
        if (row?.service_create_is_trial) return `TRIAL`;
        if (!row?.service_nextbill_startdate || !row?.service_nextbill_enddate)
          return ``;
        return `(${format_date_short(
          row?.service_nextbill_startdate
        )} - ${format_date_short(row?.service_nextbill_enddate)})`;
      },
    },
    {
      field: "service_contract_name",
      headerName: "Hợp Đồng Hiện Tại",
      minWidth: 210,
      valueGetter: ({ row }) => row?.contracts,
      renderCell: ({ value, row }) => (
        <Contracts
          contracts={value}
          isAddon={row?.service_referrer_type === "Reducer::Override::Service"}
        />
      ),
    },
    {
      field: "service_effective_date",
      headerName: "Hiệu Lực Hợp Đồng Hiện Tại",
      minWidth: 210,
      valueGetter: ({ row }) => row?.contracts,
      renderCell: ({ value, row }) => (
        <ContractTimer
          contracts={value}
          isAddon={row?.service_referrer_type === "Reducer::Override::Service"}
        />
      ),
    },
    {
      field: "actions",
      headerName: "",
      width: 40,
      renderCell: ({ row }) => <Action {...row} />,
    },
  ];
};

export const getPrivateServiceColumns = ({
  service_creatable_type = "",
  service_product_id,
}) => {
  switch (service_creatable_type) {
    case "CloudService":
      return [
        {
          field: "cpu",
          headerName: "CPU",
          width: 100,
        },
        {
          field: "disk",
          headerName: "Disk",
          width: 100,
        },
        {
          field: "memory",
          headerName: "Memory",
          width: 100,
        },
        {
          field: "ip",
          headerName: "IP",
          width: 100,
        },
        {
          field: "other",
          headerName: "Other",
          width: 100,
        },
      ];
    case "ColocationService":
      return [
        {
          field: "u",
          headerName: "Số Lượng U/Rack",
          width: 130,
        },
        {
          field: "power",
          headerName: "Nguồn Điện",
          width: 100,
        },
        {
          field: "bandwidth",
          headerName: "Bandwidth",
          width: 100,
        },
        {
          field: "ip",
          headerName: "IP",
          width: 100,
        }
      ];
    case "VBMService":
    case "DeviceLiquidation":
    case "DedicatedService":
      return [
        {
          field: "server",
          headerName: "Server/ Processor",
          width: 200,
        },
        {
          field: "cpu",
          headerName: "CPU",
          width: 100,
        },
        {
          field: "disk",
          headerName: "Disk",
          width: 100,
        },
        {
          field: "memory",
          headerName: "Memory",
          width: 100,
        },
        {
          field: "ip",
          headerName: "IP",
          width: 100,
        },
        {
          field: "bandwidth",
          headerName: "Bandwidth",
          width: 100,
        },
        {
          field: "network",
          headerName: "Network",
          width: 100,
        },
        {
          field: "card",
          headerName: "Card",
          width: 100,
        },
        {
          field: "Form Factor",
          headerName: "u",
          width: 100,
        },
        {
          field: "power",
          headerName: "Nguồn Điện",
          width: 100,
        },
        {
          field: "other",
          headerName: "Other",
          width: 100,
        },
      ];
    case "VnisService":
      return [
        {
          field: "vnis-protected-domain",
          headerName: "Protected Domains( DNS - No of Records)",
          width: 250,
          renderCell: ({ value }) => {
            if (!value) return "";
            if (parseFloat(value) >= UNLIMITED) return "UNLIMTED";
            return format_numeral_price(value);
          },
        },
        {
          field: "vnis-cdn-origin-shield-traffic-vncdn",
          headerName: "CDN & Origin Shield Traffic VNCDN",
          width: 250,
          renderCell: ({ value }) => {
            if (!value) return "";
            if (parseFloat(value) >= UNLIMITED) return "UNLIMTED";
            return format_numeral_price(value);
          },
        },
        {
          field: "vnis-cdn-origin-shield-request-vncdn",
          headerName: "CDN & Origin Shield Request VNCDN",
          width: 250,
          renderCell: ({ value }) => {
            if (!value) return "";
            if (parseFloat(value) >= UNLIMITED) return "UNLIMTED";
            return format_numeral_price(value);
          },
        },
        {
          field: "vnis-dns-request-tier-price",
          headerName: "DNS Request - Tier Price",
          width: 250,
          renderCell: ({ value }) => {
            if (!value) return "";
            if (parseFloat(value) >= UNLIMITED) return "UNLIMTED";
            return format_numeral_price(value);
          },
        },
        {
          field: "other",
          headerName: "Other",
          width: 100,
          renderCell: ({ value }) => {
            if (!value) return "";
            if (parseFloat(value) >= UNLIMITED) return "UNLIMTED";
            return format_numeral_price(value);
          },
        },
      ];
    case "EmailService":
      return [
        {
          field: "mail-account",
          headerName: "Account",
          width: 120,
          renderCell: ({ value }) => {
            if (!value) return "";
            if (parseFloat(value) >= UNLIMITED) return "UNLIMTED";
            return format_numeral_price(value);
          },
        },
        {
          field: "mail-storage",
          headerName: "Dung Lượng",
          width: 120,
          renderCell: ({ value }) => {
            if (!value) return "";
            if (parseFloat(value) >= UNLIMITED) return "UNLIMTED";
            return format_numeral_price(value);
          },
        },
        {
          field: "ip",
          headerName: "IP",
          width: 100,
          renderCell: ({ value }) => {
            if (!value) return "";
            if (parseFloat(value) >= UNLIMITED) return "UNLIMTED";
            return format_numeral_price(value);
          },
        },
        {
          field: "other",
          headerName: "Other",
          width: 100,
          renderCell: ({ value }) => {
            if (!value) return "";
            if (parseFloat(value) >= UNLIMITED) return "UNLIMTED";
            return format_numeral_price(value);
          },
        },
      ];
    case "HostingService":
      return [
        {
          field: "storage",
          headerName: "Storage",
          width: 120,
          renderCell: ({ value }) => {
            if (!value) return "";
            if (parseFloat(value) >= UNLIMITED) return "UNLIMTED";
            return format_numeral_price(value);
          },
        },
        {
          field: "website",
          headerName: "Website",
          width: 120,
          renderCell: ({ value }) => {
            if (!value) return "";
            if (parseFloat(value) >= UNLIMITED) return "UNLIMTED";
            return format_numeral_price(value);
          },
        },
        {
          field: "mysql",
          headerName: "MySQL",
          width: 120,
          renderCell: ({ value }) => {
            if (!value) return "";
            if (parseFloat(value) >= UNLIMITED) return "UNLIMTED";
            return format_numeral_price(value);
          },
        },
        {
          field: "other",
          headerName: "Other",
          width: 100,
          renderCell: ({ value }) => {
            if (!value) return "";
            if (parseFloat(value) >= UNLIMITED) return "UNLIMTED";
            return format_numeral_price(value);
          },
        },
      ];

    case "StorageLFDService":
    case "TranscodingService":
    case "TransmuxingService":
    case "RecordingService":
    case "ConversantService":
      if (service_product_id === 305) {
        return [
          {
            field: "local",
            headerName: "Băng Thông Trong Nước",
            width: 180,
            renderCell: ({ value }) => {
              if (!value) return "";
              if (parseFloat(value) >= UNLIMITED) return "UNLIMTED";
              return format_numeral_price(value);
            },
          },
          {
            field: "global",
            headerName: "Băng Thông Quốc Tế",
            width: 180,
            renderCell: ({ value }) => {
              if (!value) return "";
              if (parseFloat(value) >= UNLIMITED) return "UNLIMTED";
              return format_numeral_price(value);
            },
          },
          {
            field: "other-usage",
            headerName: "Băng Thông Khác",
            width: 150,
          },
        ];
      } else {
        return [
          {
            field: "commitment-usage",
            headerName: "Gói Cố Định",
            width: 120,
            renderCell: ({ value }) =>
              value &&
              (value >= UNLIMITED ? "UNLIMITED" : format_numeral_price(value)),
          },
        ];
      }
    default:
      return [];
  }
};

export const buildServiceInformations = ({
  service,
  serviceInformations = [],
  price = {},
}) => {
  let serviceTemp = { ...service };

  if (['ConversantService', 'StorageLFDService', 'TranscodingService', 'TransmuxingService', 'RecordingService'].includes(service?.service_creatable_type)) {
    if (service?.service_product_id === 305) {
      let service_amount = 0;
      _.forEach(price?.service_configoptions, (configoption, index) => {
        if (!!configoption?.commitment) {
          serviceTemp[configoption?.name] = configoption?.commitment?.value;
          service_amount += configoption?.commitment?.pricing_amount;
        } else {
          serviceTemp[configoption?.name] = 1;
        }
      });
      if (service_amount === 0) {
        service_amount =
          price?.service_configoptions?.[0]?.pricing?.steps?.[0]?.pricing_apply;
      }
      serviceTemp.service_amount = service_amount;
      serviceTemp.service_recurring = serviceTemp.service_amount;
    } else {
      if (!!price?.service_pricing_configure?.commitment) {
        serviceTemp["commitment-usage"] =
          price?.service_pricing_configure?.commitment?.value;
        serviceTemp.service_amount =
          price?.service_pricing_configure?.commitment?.pricing_amount;
      } else {
        serviceTemp["commitment-usage"] = 1;
        serviceTemp.service_amount =
          price?.service_pricing_configure?.steps?.[0]?.pricing_apply;
      }
      serviceTemp.service_recurring = serviceTemp.service_amount;
    }
    return {
      ...serviceTemp,
      classNames: "row-service-finally",
    };
  }

  if (
    [
      "DeviceLiquidation",
      "BareMetalServer",
      "VBMService",
      "ColocationService",
      "DedicatedService",
    ].includes(service?.service_creatable_type)
  ) {
    serviceInformations
      ?.filter((x) => x?.statemente_key !== "Reducer::Override::Service::Addon")
      ?.forEach((information) => {
        switch (information?.service_information_type) {
          case "server":
          case "processor":
            let servers = serviceInformations.filter((x) =>
              x?.service_information_type.match(/(processor|server)/)
            );
            serviceTemp = {
              ...serviceTemp,
              server: servers
                ?.map(
                  (server) =>
                    `${server?.warehouse_brand_name} - ${server?.warehouse_model_name}`
                )
                ?.join(", "),
            };
            break;
          case "cpu":
            let cpus = serviceInformations.filter((x) =>
              x?.service_information_type.match(/(cpu)/)
            );
            serviceTemp = {
              ...serviceTemp,
              cpu: _.sumBy(cpus, (x) => x?.warehouse_capacity_value || 0),
            };
            break;
          case "disk":
            let disks = serviceInformations.filter((x) =>
              ["disk", "ssd", "hdd"].includes(x?.service_information_type)
            );
            serviceTemp = {
              ...serviceTemp,
              disk: _.sumBy(disks, (x) => x?.warehouse_capacity_value || 0),
            };
            break;
          case "ram":
          case "memory":
            let memories = serviceInformations?.filter((x) =>
              x?.service_information_type?.match(/(memory|ram)/)
            );
            serviceTemp = {
              ...serviceTemp,
              memory: _.sumBy(
                memories,
                (x) => x?.warehouse_capacity_value || 0
              ),
            };
            break;
          case "ip":
            serviceTemp = {
              ...serviceTemp,
              ip: information?.warehouse_capacity_value,
            };
            break;
          case "bandwidth":
            serviceTemp = {
              ...serviceTemp,
              bandwidth: information?.warehouse_capacity_value,
            };
            break;
          case "network":
            let networks = serviceInformations.filter((x) =>
              ["network"].includes(x?.service_information_type)
            );
            serviceTemp = {
              ...serviceTemp,
              network: _.sumBy(
                networks,
                (x) => x?.warehouse_capacity_value || 0
              ),
            };
            break;
          case "rack":
            let us = serviceInformations.filter((x) =>
              ["rack", 'u'].includes(x?.service_information_type)
            );
            serviceTemp = {
              ...serviceTemp,
              u: _.sumBy(us, (x) => x?.warehouse_capacity_value || 0),
            };
            break;
          case "card":
            let cards = serviceInformations.filter((x) =>
              ["card"].includes(x?.service_information_type)
            );
            serviceTemp = {
              ...serviceTemp,
              card: _.sumBy(cards, (x) => x?.warehouse_capacity_value || 0),
            };
            break;
          case "power":
            let powers = serviceInformations.filter((x) =>
              ["power"].includes(x?.service_information_type)
            );
            serviceTemp = {
              ...serviceTemp,
              power: _.sumBy(powers, (x) => x?.warehouse_capacity_value || 0),
            };
            break;
          default:
            break;
        }
      });
  } else {
    serviceInformations?.forEach((information) => {
      switch (information?.service_information_type) {
        case "cpu":
          serviceTemp = {
            ...serviceTemp,
            cpu: information?.value || 0,
          };
          break;
        case "disk":
          serviceTemp = {
            ...serviceTemp,
            disk: information?.value || 0,
          };
          break;
        case "ip":
          serviceTemp = {
            ...serviceTemp,
            ip: information?.value || 0,
          };
          break;
        case "memory":
          serviceTemp = {
            ...serviceTemp,
            memory: information?.value || 0,
          };
          break;
        case "bandwidth":
          serviceTemp = {
            ...serviceTemp,
            bandwidth: information?.value || 0,
          };
          break;
        case "vnis-protected-domain":
          serviceTemp = {
            ...serviceTemp,
            "vnis-protected-domain": information?.value || 0,
          };
          break;
        case "vnis-cdn-origin-shield-traffic-vncdn":
          serviceTemp = {
            ...serviceTemp,
            "vnis-cdn-origin-shield-traffic-vncdn": information?.value || 0,
          };
          break;
        case "vnis-cdn-origin-shield-request-vncdn":
          serviceTemp = {
            ...serviceTemp,
            "vnis-cdn-origin-shield-request-vncdn": information?.value || 0,
          };
          break;
        case "vnis-dns-request-tier-price":
          serviceTemp = {
            ...serviceTemp,
            "vnis-dns-request-tier-price": information?.value || 0,
          };
          break;
        case "mail-account":
          serviceTemp = {
            ...serviceTemp,
            "mail-account": information?.value || 0,
          };
          break;
        case "mail-storage":
          serviceTemp = {
            ...serviceTemp,
            "mail-storage": information?.value || 0,
          };
          break;
        case "storage":
          serviceTemp = {
            ...serviceTemp,
            storage: information?.value || 0,
          };
          break;
        case "website":
          serviceTemp = {
            ...serviceTemp,
            website: information?.value || 0,
          };
          break;
        case "mysql":
          serviceTemp = {
            ...serviceTemp,
            mysql: information?.value || 0,
          };
          break;
        case "local":
          serviceTemp = {
            ...serviceTemp,
            local: information?.value || 0,
          };
          break;
        case "global":
          serviceTemp = {
            ...serviceTemp,
            global: information?.value || 0,
          };
          break;
        case "commitment":
          serviceTemp = {
            ...serviceTemp,
            commitment: information?.description,
          };
          break;

        default:
          break;
      }
    });
  }
  return { ...serviceTemp, other: "", classNames: "row-service-finally" };
};

export const buildConversantServiceLines = ({ lines = [] }) => {
  let addonTemps = [];
  _.sortBy(lines, ["service_update_id"])?.forEach((line) => {
    switch (line?.metadata?.name) {
      case "global":
        addonTemps.push({
          service_name:
            "Băng Thông Quốc Tế" +
            " " +
            format_date_short(line?.metadata?.created_at),
          ...line?.metadata,
          global: line?.metadata?.commitment,
          disabledAction: true,
        });
        break;
      case "local":
        addonTemps.push({
          service_name:
            "Băng Thông Trong Nước" +
            " " +
            format_date_short(line?.metadata?.created_at),
          ...line?.metadata,
          local: line?.metadata?.commitment,
          disabledAction: true,
        });
        break;
      default:
        addonTemps.push({
          service_name:
            "Gói cố định" + format_date_short(line?.metadata?.created_at),
          ...line?.metadata,
          "commitment-usage": line?.metadata?.commitment,
          disabledAction: true,
        });
        break;
    }
  });
  return addonTemps;
};

export const buildServiceAddonInformations = ({ service, addons = [] }) => {
  let addonTemps = [];

  addons?.forEach((addon) => {
    let service_addon_amount = addon?.service_amount;
    let service_addon_recurring = addon?.service_recurring;
    if (
      parseFloat(addon?.service_addon_qty) < UNLIMITED &&
      parseFloat(addon?.service_addon_qty) > 0
    ) {
      service_addon_amount =
        service_addon_amount * (addon?.service_addon_qty || 1);
      service_addon_recurring =
        service_addon_recurring * (addon?.service_addon_qty || 1);
    }

    switch (addon?.service_type) {
      case "cpu":
        addonTemps.push({
          ...addon,
          service_addon_recurring,
          service_addon_amount,
          cpu: addon?.service_addon_qty,
        });
        break;
      case "disk":
        addonTemps.push({
          ...addon,
          service_addon_recurring,
          service_addon_amount,
          disk: addon?.service_addon_qty,
        });
        break;
      case "ip":
        addonTemps.push({
          ...addon,
          service_addon_recurring,
          service_addon_amount,
          ip: addon?.service_addon_qty || 0,
        });
        break;
      case "ram":
      case "memory":
        addonTemps.push({
          ...addon,
          service_addon_recurring,
          service_addon_amount,
          memory: addon?.service_addon_qty,
        });
        break;
      case "bandwidth":
        addonTemps.push({
          ...addon,
          service_addon_recurring,
          service_addon_amount,
          memory: addon?.service_addon_qty,
        });
        break;
      case "vnis-cdn-origin-shield-traffic-vncdn":
        addonTemps.push({
          ...addon,
          service_addon_recurring,
          service_addon_amount,
          "vnis-cdn-origin-shield-traffic-vncdn": addon?.service_addon_qty,
        });
        break;
      case "vnis-cdn-origin-shield-request-vncdn":
        addonTemps.push({
          ...addon,
          service_addon_recurring,
          service_addon_amount,
          "vnis-cdn-origin-shield-request-vncdn": addon?.service_addon_qty,
        });
        break;
      case "vnis-protected-domain":
        addonTemps.push({
          ...addon,
          service_addon_recurring,
          service_addon_amount,
          "vnis-protected-domain": addon?.service_addon_qty,
        });
        break;
      case "vnis-dns-request-tier-price":
        addonTemps.push({
          ...addon,
          service_addon_recurring,
          service_addon_amount,
          "vnis-dns-request-tier-price": addon?.service_addon_qty,
        });
        break;
      case "mail-account":
        addonTemps.push({
          ...addon,
          service_addon_recurring,
          service_addon_amount,
          "mail-account": addon?.service_addon_qty,
        });
        break;
      case "mail-storage":
        addonTemps.push({
          ...addon,
          service_addon_recurring,
          service_addon_amount,
          "mail-storage": addon?.service_addon_qty,
        });
        break;
      case "storage":
        addonTemps.push({
          ...addon,
          service_addon_recurring,
          service_addon_amount,
          storage: addon?.service_addon_qty,
        });
        break;
      case "website":
        addonTemps.push({
          ...addon,
          service_addon_recurring,
          service_addon_amount,
          website: addon?.service_addon_qty,
        });
        break;
      case "mysql":
        addonTemps.push({
          ...addon,
          service_addon_recurring,
          service_addon_amount,
          mysql: addon?.service_addon_qty,
        });
        break;
      default:
        addonTemps.push({
          ...addon,
          service_addon_recurring,
          service_addon_amount,
          other: addon?.service_addon_qty,
        });
        break;
    }
  });
  return addonTemps;
};

export const buildServiceFinallyInformations = ({
  service,
  serviceInformations = {},
  addonInformations = [],
}) => {
  let service_recurring = serviceInformations?.service_recurring || 0;
  let service_amount = serviceInformations?.service_amount || 0;

  const addonEnableds = addonInformations.filter(
    (addon) =>
      !["cancel", "stop"].includes(addon?.service_status) &&
      !addon?.service_stopped_at
  );

  switch (service?.service_creatable_type) {
    case "CloudService":
    case "VBMService":
    case "DedicatedService":
    case "ColocationService":
      addonEnableds?.forEach((addon) => {
        let recurringPrice = addon?.service_addon_recurring;
        let price = addon?.service_addon_amount || 0;
        service_recurring += recurringPrice;
        service_amount += price;
        switch (addon?.service_type) {
          case "cpu":
            serviceInformations.cpu =
              (serviceInformations?.cpu || 0) + addon?.service_addon_qty;
            break;
          case "server":
          case "processor":
            serviceInformations.server =
              (serviceInformations?.server || 0) + addon?.service_addon_qty;
            break;
          case "ip":
            serviceInformations.ip =
              (serviceInformations?.ip || 0) + addon?.service_addon_qty;
            break;
          case "memory":
            serviceInformations.memory =
              (serviceInformations?.memory || 0) + addon?.service_addon_qty;
            break;
          case "disk":
            serviceInformations.disk =
              (serviceInformations?.disk || 0) + addon?.service_addon_qty;
            break;
          case "bandwidth":
            serviceInformations.bandwidth =
              (serviceInformations?.bandwidth || 0) + addon?.service_addon_qty;
            break;
          default:
            break;
        }
      });
      break;
    case "VnisService":
      addonEnableds?.forEach((addon) => {
        let recurringPrice = addon?.service_addon_recurring;
        let price = addon?.service_addon_amount || 0;
        service_recurring += recurringPrice;
        service_amount += price;
        switch (addon?.service_type) {
          case "vnis-cdn-origin-shield-traffic-vncdn":
            serviceInformations["vnis-cdn-origin-shield-traffic-vncdn"] =
              (serviceInformations["vnis-cdn-origin-shield-traffic-vncdn"] ||
                0) + addon?.service_addon_qty;
            break;
          case "vnis-cdn-origin-shield-request-vncdn":
            serviceInformations["vnis-cdn-origin-shield-request-vncdn"] =
              (serviceInformations["vnis-cdn-origin-shield-request-vncdn"] ||
                0) + addon?.service_addon_qty;
            break;
          case "vnis-protected-domain":
            serviceInformations["vnis-protected-domain"] =
              (serviceInformations["vnis-protected-domain"] || 0) +
              addon?.service_addon_qty;
            break;
          case "vnis-dns-request-tier-price":
            serviceInformations["vnis-dns-request-tier-price"] =
              (serviceInformations["vnis-dns-request-tier-price"] || 0) +
              addon?.service_addon_qty;
            break;
          default:
            break;
        }
      });
      break;
    case "EmailService":
      addonEnableds?.forEach((addon) => {
        let recurringPrice = addon?.service_addon_recurring;
        let price = addon?.service_addon_amount || 0;
        service_recurring += recurringPrice;
        service_amount += price;
        switch (addon?.service_type) {
          case "mail-account":
            serviceInformations["mail-account"] =
              (serviceInformations["mail-account"] || 0) +
              addon?.service_addon_qty;
            break;
          case "mail-storage":
            serviceInformations["mail-storage"] =
              (serviceInformations["mail-storage"] || 0) +
              addon?.service_addon_qty;
            break;
          case "ip":
            serviceInformations.ip =
              (serviceInformations?.ip || 0) + addon?.service_addon_qty;
            break;
          default:
            break;
        }
      });
      break;
    case "HostingService":
      addonEnableds?.forEach((addon) => {
        let recurringPrice = addon?.service_addon_recurring;
        let price = addon?.service_addon_amount || 0;
        service_recurring += recurringPrice;
        service_amount += price;
        switch (addon?.service_type) {
          case "website":
            serviceInformations.website =
              (serviceInformations.website || 0) + addon?.service_addon_qty;
            break;
          case "mysql":
            serviceInformations.mysql =
              (serviceInformations.mysql || 0) + addon?.service_addon_qty;
            break;
          case "storage":
            serviceInformations.storage =
              (serviceInformations.storage || 0) + addon?.service_addon_qty;
            break;
          case "ip":
            serviceInformations.ip =
              (serviceInformations?.ip || 0) + addon?.service_addon_qty;
            break;
          default:
            break;
        }
      });
      break;
    default:
      break;
  }
  return {
    ...serviceInformations,
    service_name: "Cấu Hình Hiện Tại",
    service_id: "",
    classNames: "row-service-finally",
    service_recurring,
    service_amount,
    disabledAction: true,
  };
};
