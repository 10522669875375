import { customize_truncate } from '@/functions'
import useOnClickOutside from '@/hooks/useOnClickOutside'
import { Box, Paper, Popover, Typography } from '@mui/material'
import React from 'react'

const FormatterDescription = ({ description = '', length = 25 }) => {

    const ref = React.useRef(null)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleAnchorEl = (event) => {
        if (description && description.length > length) {
            return setAnchorEl(event?.currentTarget)
        }
    }

    const handleClose = () => setAnchorEl(null)
    useOnClickOutside(ref, handleClose)
    return (
        <React.Fragment>
            {!!anchorEl && <Popover anchorEl={anchorEl} open={!!anchorEl}>
                <Paper sx={{ p: 2, maxWidth: 300 }} elevation={8}>
                    <Typography sx={{ fontSize: 14, flexWrap: 'wrap' }}>{description}</Typography>
                </Paper>
            </Popover>}
            <Box ref={ref}
                onMouseOver={handleAnchorEl}
                onMouseEnter={handleAnchorEl}
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex'
                }}>
                <Typography sx={{ fontSize: 14, m: 'auto 0' }}>
                    {customize_truncate(description, length)}
                </Typography>
            </Box>
        </React.Fragment>
    )
}

export default FormatterDescription