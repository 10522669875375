import { format_numeral_price } from "@/functions";
import { Box, Typography } from "@mui/material";
import React from "react";

const Default = ({ data, column = 'service_amount' }) => {
  if (!data?.[column] || data?.[column] === 0) return "";
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
      <Typography sx={{ fontSize: 14, m: "auto 0 auto auto" }}>
        đ
        {format_numeral_price(
          data?.[column] + (data?.service_addon_amount || 0)
        )}
      </Typography>
    </Box>
  );
};

export default Default;
