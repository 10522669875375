import PopperAction from "@components/PopperAction";
import MoreVertOutlined from "@mui/icons-material/MoreVertOutlined";
import { Box, IconButton, Popper } from "@mui/material";
import React, { useState } from "react";
import { Form } from "../../../../Detail/shared";

const Actions = ({ data }) => {
  const [popper, setPopper] = React.useState({
    anchorEl: undefined,
    data: undefined,
    actions: [],
  });
  const [action, setAction] = React.useState({
    open: false,
    name: undefined,
    data: undefined,
  });

  const onClosePopper = () =>
    setPopper((prev) => ({
      ...prev,
      anchorEl: undefined,
      data: undefined,
      actions: [],
    }));

  const onCloseAction = () =>
    setAction({ open: false, name: undefined, data: undefined });

  const [actions] = useState([
    {
      order: 1,
      name: "add-price",
      label: "Thêm Đơn Giá",
      icon: "Add",
      handleClick: (data) => {
        setAction((prev) => ({
          ...prev,
          open: true,
          data: data,
          name: "add-price",
        }));
        onClosePopper();
      },
    },
  ]);

  const renderModules = (name, data) => {
    switch (name) {
      case "add-price":
        return (
          <Form.AddPricing
            productId={data?.product_id}
            onClose={onCloseAction}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      {action.open &&
        action?.name !== undefined &&
        renderModules(action?.name, action?.data)}
      {popper?.anchorEl !== undefined && (
        <Popper
          anchorEl={popper?.anchorEl}
          sx={{
            zIndex: 99,
          }}
          open={Boolean(popper.anchorEl)}
          onMouseLeave={onClosePopper}
        >
          <PopperAction {...popper} handleClose={onCloseAction} />
        </Popper>
      )}
      <IconButton
        size="small"
        sx={{ m: "auto" }}
        onClick={(event) => {
          setPopper({
            anchorEl: event?.currentTarget,
            data: data,
            actions: actions,
          });
        }}
      >
        <MoreVertOutlined fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default Actions;
