import { Box, Button } from '@mui/material'
import React from 'react'

const PriceSave = ({
    onSubmit,
    disabled = true
}) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: '8px 16px 0 0' }}>
            <Button size='small'
                variant='contained' disabled={disabled} onClick={onSubmit}>
                Lưu Thông Tin
            </Button>
        </Box>
    )
}

export default PriceSave