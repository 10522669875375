import EmployeeApi from '@/apis/beta/Employee/EmployeeApi'
import { buildQueryFilterCondition } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import useDebounce from '@/hooks/useDebounce'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import TransitionGroups from '@components/shared/TranstionGroups'
import { PersonOutlineOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ScopeAssignees = () => {
    const { control, watch } = useFormContext()
    const [searchQuery, setSearchQuery] = useState('')
    const debouncedSearchQuery = useDebounce(searchQuery, 400)

    const nestedQueries = useCallback(() => {
        let queryFields = [
            {
                name: "employees.employee_name",
                operator: "$contains",
                value: debouncedSearchQuery,
                disabled: !debouncedSearchQuery?.trim()
            },
            {
                name: "employees.employee_id",
                operator: "$in",
                disabled: !(watch(`scope_assignees`)?.length),
                value: watch(`scope_assignees`)?.filter(x => !!parseInt(x))?.join(','),
            }
        ]
        return buildQueryFilterCondition('$or', 0, queryFields)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery, watch(`scope_assignees`)])

    const { data: { employees } } = EmployeeApi.List({
        query: nestedQueries(),
        pageSize: (watch(`scope_assignees`)?.length || 0) + 10
    })

    return (
        <Box sx={{
            p: '12px 16px',
            border: '1px solid rgba(0, 0, 0, 0.18)',
            borderRadius: '4px',
        }}>
            <Typography sx={{
                fontSize: 14,
                mb: 1.25,
                fontWeight: 'medium'
            }} color="text.black">
                Nhân Viên Được Cấp Quyền
            </Typography>
            <Controller
                name="scope_assignees"
                control={control}
                render={({ field: { value = [], onChange } }) => {
                    return (
                        <TransitionGroups
                            values={employees?.filter(employee => value?.includes(employee?.employee_id))}
                            onDelete={(e) => onChange([...value?.filter(x => x !== e?.employee_id)])}
                            label={'employee_name'}
                            icon={<PersonOutlineOutlined fontSize='small' color="primary" />}
                        >
                            <SingleSelectCustomize
                                clearOnBlur
                                options={[...employees?.filter(employee => !value?.includes(employee?.employee_id))]}
                                value={null}
                                formatCollection={{ label: 'employee_name', value: 'employee_id' }}
                                compareAttributes={['employee_name', 'employee_email_company']}
                                input={{
                                    placeholder: "Chọn nhân viên...",
                                    value: searchQuery,
                                    onChange: (event) => setSearchQuery(event.target.value)
                                }}
                                onChange={(_, newValue) => {
                                    if (!newValue?.employee_id) return;
                                    onChange([...value?.filter(employeeId => !!parseInt(employeeId)), newValue?.employee_id])
                                    setSearchQuery('')
                                }}
                                mode='server'
                            />
                        </TransitionGroups>
                    )
                }}
            />
        </Box>

    )
}

export default withSuspense(ScopeAssignees, MuiSkeleton["TextField"])