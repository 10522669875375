import DataGridCustomize from "@components/DataGrid";
import {
  CellDescription,
  CellLink,
  CellPricing,
} from "@components/DataGrid/Cell";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React from "react";
import { format_date_short, format_number_usage, format_numeral_price } from "@/functions";
import { PRODUCT_UNITS } from "@/libs/constants";
import Action from "../../Action";

export const isDeviceLiquidation = (name = '') => {
  return !!name?.toLowerCase()?.match(/(liquidation|phí|discount)/)?.[0]
}

const Standard = ({ invoice, data = [] }) => {
  const [columns] = React.useState([
    {
      field: "invoice_item_name",
      headerName: "Dịch Vụ",
      width: 200,
      hide: true,
      valueGetter: ({ row }) => {
        if (row?.invoice_item_referrer_type === "Reducer::Override::Service") {
          return {
            label: `(${row?.service_id}) - ${row?.invoice_item_name}`,
            href: ["services", row?.invoice_item_referrer_id],
          };
        }
        return row?.invoice_item_description;
      },
      renderCell: ({ value }) => {
        if (value?.href) return <CellLink data={value} />;
        return value;
      },
    },
    {
      field: "invoice_item_description",
      headerName: "Mô Tả",
      width: invoice?.invoice_type === 0 ? 500 : 250,
      valueGetter: ({ row }) => {
        if (isDeviceLiquidation(row?.service_name)) {
          return `${row?.invoice_item_description || row?.invoice_item_name}`;
        }

        return `${row?.invoice_item_description || row?.invoice_item_name
          } (${format_date_short(
            row?.invoice_item_startdate
          )
          } - ${format_date_short(row?.invoice_item_enddate)})`;
      },
      renderCell: ({ value }) => (
        <CellDescription description={value} length={200} />
      ),
    },
    {
      field: "invoice_item_value",
      headerName: "Thông Số",
      hide: invoice?.invoice_type === 0,
      width: 120,
      renderCell: ({ value }) => format_number_usage(value),
    },
    {
      field: "invoice_item_unit",
      headerName: "Đơn Vị Tính",
      width: 150,
      hide: invoice?.invoice_type === 0,
      valueGetter: ({ value }) =>
        PRODUCT_UNITS?.find((unit) => unit.value === value)?.label,
      renderCell: ({ value }) => (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              textTransform: "capitalize",
            }}
          >
            {value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "invoice_item_pricing_apply",
      headerName: "Đơn Giá Bán",
      width: 130,
      hide: invoice?.invoice_type === 0,
      renderCell: ({ value }) => format_numeral_price(value || 0),
    },
    {
      field: "invoice_item_pricing",
      headerName: "Đơn Giá Niêm Yết",
      width: 130,
      hide: true,
      renderCell: ({ value }) => format_numeral_price(value || 0),
    },
    {
      field: "invoice_item_subtotal",
      headerName: "Tạm Tính",
      width: 130,
      hide: invoice?.invoice_type === 0,
      renderCell: ({ value }) =>
        value === 0 ? "" : <CellPricing price={value} />,
    },
    {
      field: "invoice_item_deposit",
      headerName: "Giảm/ Deposit",
      width: 130,
      hide: invoice?.invoice_type === 0,
      renderCell: ({ value }) => <CellPricing price={value} />,
    },
    {
      field: "invoice_item_tax",
      headerName: "Thuế",
      width: 60,
      renderCell: ({ value }) => (
        <FormControlLabel
          disabled
          control={<Checkbox size="small" checked={value} />}
        />
      ),
    },
    {
      field: "invoice_item_amount",
      headerName: "Thành Tiền",
      width: 150,
      valueGetter: ({ row }) => {
        const amount = row?.invoice_item_amount;
        if (parseFloat(amount) >= 0) {
          return `${format_numeral_price(amount)
            }đ`;
        }
        return `(${format_numeral_price(Math.abs(amount))}) đ`;
      },
      renderCell: ({ value }) => (
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              float: "right",
              fontSize: 14,
              textAlign: "right",
            }}
          >
            {value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "",
      width: 40,
      renderCell: ({ row }) => {
        if (!row?.editable) return <></>;
        return <Action data={{ ...row, customerId: invoice?.customer?.customer_id }} />;
      },
    },
  ]);
  console.log(invoice)
  return (
    <DataGridCustomize
      columns={columns?.map((x) => ({ ...x, hide: false }))}
      rows={data?.map((x, index) => ({
        ...x,
        id: index,
        editable: invoice?.invoice_status === 1 ? true : false,
      }))}
      components={{
        Toolbar: "disabled",
        Pagination: "disabled",
      }}
      componentsProps={{
        toolbar: {
          invoice: invoice,
          columns: columns,
        },
      }}
      pinnedColumns={{
        right: ["actions"],
      }}
      paginationMode="client"
    />
  );
};
export default Standard;
