import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateRangePickerDay as MuiDateRangePickerDay } from '@mui/x-date-pickers-pro/DateRangePickerDay';
import React from 'react';

const DateRangePickerDay = styled(MuiDateRangePickerDay)(
    ({
        theme,
        isHighlighting,
        isStartOfHighlighting,
        isEndOfHighlighting,
        outsideCurrentMonth,
    }) => ({
        ...(!outsideCurrentMonth &&
            isHighlighting && {
            borderRadius: 0,
            backgroundColor: theme.palette.primary.light + `#1F`,
            color: theme.palette.common.white,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary.dark,
            },
        }),
        ...(isStartOfHighlighting && {
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
        }),
        ...(isEndOfHighlighting && {
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%',
        }),
    }),
);

const DateRangeCustomize = ({ value, handler, ...props }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
                value={value}
                format="DD/MM/YYYY"
                onChange={(newValue) => {
                    try {
                        handler(newValue)
                    } catch (ex) {
                        handler([null, null])
                    }
                }}
                localeText={{
                    start: "Thời gian bắt đầu",
                    end: "Thời gian kết thúc"
                }}
                slots={{
                    day: DateRangePickerDay,
                }}
                renderLoading={() => "..."}
                sx={{
                    "label": {
                        fontSize: 14,
                    },
                    ".MuiInputBase-root": {
                        height: { xs: 40, md: 40 },
                        fontSize: 14
                    },
                    ".MuiFormControl-root :not(style)": {
                    },
                    "p": {
                        "p": {
                            display: "none"
                        }
                    },
                    ...props?.styled
                }}
            />
        </LocalizationProvider>
    )
}

export default DateRangeCustomize