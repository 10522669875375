/* eslint-disable import/no-anonymous-default-export */
import { encodeBase64, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class TicketApi {
  CreateTicket = async (values) => {
    const response = await requestWithToken(`${API_V3_URL}/tickets`, "POST", {
      ticket: values,
    });

    return response?.data;
  };

  ListTicket = ({ page, pageSize, q, query, file }) => {
    return useQueryWithCustomized(
      ["tickets", page, pageSize, q, query],
      async () => {
        const response = await requestWithToken(
          `${API_V3_URL}/tickets/list?page=${page}&pageSize=${pageSize}&q=${q}${query ? `&filters=${encodeBase64(query)}` : ""
          }${file ? "&file=true" : ""}`,
          "POST"
        );
        if (response?.data?.data) {
          return response?.data?.data;
        }
        return response?.data;
      }
    );
  };

  TicketExport = async ({ query, file }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/tickets/list?${query ? `filters=${encodeBase64(query)}` : ""
      }${file ? "&file=true" : ""}`,
      "POST",
      {},
      {
        "Content-Type": "application/vnd.ms-excel",
        "CONTENT-DISPOSITION": "attachment",
      },
      { withCredentials: true, responseType: "blob" }
    );
    return response?.data;
  };

  All = () => {
    return useQueryWithCustomized(["tickets.all"], async () => {
      const response = await requestWithToken(`${API_V3_URL}/tickets/get/all`);

      if (response?.data?.data) {
        return response?.data?.data;
      }

      return response?.data;
    });
  };

  ShowTicket = ({ ticketId = -1 }) => {
    return useQueryWithCustomized(
      ['tickets.detail', ticketId],
      async () => {
        return await requestWithToken(`${API_V3_URL}/tickets/${ticketId}`, "GET")
          .then(({ data }) => {
            if (data?.error || data?.errors) return data;
            return {
              ticket: data?.data?.ticket || {}
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    )
  }

  UpdateTicket = async ({ ticket_id, values }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/tickets/${ticket_id}/update_ticket`,
      "PUT",
      {
        ticket: values,
      }
    );

    return response?.data;
  };

  FinishTicket = async ({ ticket_id, values }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/tickets/${ticket_id}/finish`,
      "PUT",
      { finish_ticket: values }
    );

    return response?.data;
  };

  Logs = async ({ page, pageSize, ticket_id }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/tickets/${ticket_id}/logs?page=${page}&pageSize=${pageSize}`
    );
    if (response?.data?.data) {
      return response?.data?.data;
    }
    return response?.data;
  };

  AddAttachment = async ({ ticket_id, values }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/tickets/${ticket_id}/attachment`,
      "POST",
      {
        ticket_attachement: values,
      }
    );

    return response?.data;
  };

  Attachments = async ({ page, pageSize, ticket_id }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/tickets/${ticket_id}/attachment?page=${page}&pageSize=${pageSize}`
    );
    if (response?.data?.data) {
      return response?.data?.data;
    }
    return response?.data;
  };

  DeleteAttachment = async ({ attachment_id }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/tickets/${attachment_id}/attachment`,
      "DELETE"
    );
    if (response?.data?.data) {
      return response?.data?.data;
    }
    return response?.data;
  };

  AddComment = async ({ ticket_id, values, mailNotification }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/tickets/${ticket_id}/comment`,
      "POST",
      {
        ticket_comment: values,
        mailNotification,
      }
    );

    return response?.data;
  };

  Comments = async ({ page, pageSize, ticket_id }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/tickets/${ticket_id}/comment?page=${page}&pageSize=${pageSize}`
    );

    if (response?.data?.data) {
      return response?.data?.data;
    }
    return response?.data;
  };

  DeleteComment = async ({ comment_id }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/tickets/${comment_id}/comment`,
      "DELETE"
    );
    if (response?.data?.data) {
      return response?.data?.data;
    }
    return response?.data;
  };

  EditComment = async ({ comment_id, values }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/tickets/${comment_id}/comment`,
      "PUT",
      {
        ticket_comment: values,
      }
    );

    return response?.data;
  };
}

export default new TicketApi();
