import ServiceApi from "@/apis/beta/Service/ServiceApi";
import ServiceBillingApi from "@/apis/beta/Service/ServiceBillingApi";
import { format_date_short, queryClientRefetchScope } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import ModalConfirm from "@components/shared/ModalConfirm";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { ReceiptLongOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Fragment, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

const CreateInvoiceStatement = ({ service }) => {
  const { data } = ServiceBillingApi.Detail({ serviceId: service?.service_id })

  const location = useNavigate();
  const queryClient = useQueryClient();
  const [isCreated, setCreated] = useState(false);
  const { mutateAsync } = useMutation(ServiceApi.CreateInvoiceRenew);

  const onSubmit = async () => {
    const response = await mutateAsync(service?.service_id);
    if (!response || response?.errors) {
      return {
        status: 400,
        message:
          response?.errors?.[0]?.message || "Failed when create invoice.",
      };
    }
    return {
      status: 200,
      message: "Tạo hoá đơn đối soát dịch vụ thành công.",
      redirect: location(
        `/invoices/${response?.data?.invoice?.invoice_id}` ||
        `/services/${service?.service_id}/invoices`,
        { replace: true }
      ),
    };
  };
  const callback = () => queryClientRefetchScope(queryClient, "service");
  return (
    <Fragment>
      {isCreated && (
        <ModalConfirm
          handleSubmit={onSubmit}
          callback={callback}
          handleClose={() => setCreated(false)}
          dialog={{
            title: "Tạo hoá đơn đối soát dịch vụ",
            content: `Dịch vụ ${service?.service_name
              } sẽ được tạo một hoá đơn đối soát với chu kỳ từ ${format_date_short(
                data?.service_control_startdate
              )} đến ${format_date_short(data?.service_control_enddate)}.`,
          }}
        />
      )}
      <Button
        size="small"
        variant="outlined"
        disabled={isCreated || service?.service_status !== "active" || !data}
        onClick={() => setCreated(true)}
        startIcon={<ReceiptLongOutlined fontSize="small" />}
      >
        Tạo Hoá Đơn Đối Soát
      </Button>
    </Fragment>
  );
};

export default withSuspense(withPermission(CreateInvoiceStatement, {
  feature: "service",
  action: "service-subscription-create",
  type: "Button",
}), MuiSkeleton["ButtonOne"]);
