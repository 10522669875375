import SearchOutlined from "@mui/icons-material/SearchOutlined";
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

const AddonSelect = ({ addons, selecteds = [], onClose, append }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [pickeds, setPickeds] = useState([]);

  const onSubmit = () => {
    pickeds?.forEach((id) => {
      const option = addons?.find((x) => x?.product_addon_id === id);
      append({
        enabled: true,
        product_id: option?.product_addon_id,
        product_name: option?.product_name,
        product_type: option?.product_type,
        statemented: !!option?.statemented,
        qty: 1,
        description: "",
        unit: option?.product_unit,
        pricing: option?.pricing?.pricing,
        pricing_apply: option?.pricing?.pricing,
        pricing_minimum: option?.pricing?.pricing_minimum,
        product_pricing_unit: option?.product_unit,
        product_addon_range_enabled: option?.product_addon_range_enabled,
        price_range_enabled: false,
        price_range_steps: [
          {
            start: 0,
            end: 999999999,
            pricing: option?.pricing?.pricing,
            pricing_apply: option?.pricing?.pricing,
            pricing_minimum: option?.pricing?.pricing_minimum,
          },
        ],
      });
    });
    return onClose();
  };

  return (
    <Drawer open={true} anchor="right">
      <Box sx={{ height: "100vh", width: 400, p: "24px 16px" }}>
        <Typography
          sx={{ fontSize: { xs: 16, md: 18 }, fontWeight: "medium", mb: 1.5 }}
        >
          Chọn Dịch Vụ ADDON
        </Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            size="small"
            placeholder="Nhập tên sản phẩm addon tìm kiếm..."
            startIcon={
              <SearchOutlined fontSize="small" sx={{ color: "#777680" }} />
            }
            value={searchQuery}
            onChange={(event) => setSearchQuery(event?.target?.value)}
          />
        </FormControl>
        <Box
          sx={{
            maxHeight: "60vh",
            display: "flex",
            flexDirection: "column",
            gap: 0.25,
            overflow: "hidden",
            overflowY: "auto",
          }}
        >
          {addons?.map((x, index) => {
            if (selecteds?.includes(x?.product_addon_id)) return null;
            if (
              !!searchQuery &&
              !!searchQuery.trim() &&
              !pickeds?.includes(x?.product_addon_id) &&
              !x?.product_name
                ?.toLowerCase()
                .includes(searchQuery?.toLowerCase())
            )
              return null;

            return (
              <FormControl fullWidth key={index}>
                <FormControlLabel
                  label={x?.product_name}
                  control={
                    <Checkbox
                      size="small"
                      onChange={() => {
                        if (pickeds?.includes(x?.product_addon_id)) {
                          return setPickeds((prevIds = []) => [
                            ...prevIds?.filter(
                              (i) => i !== x?.product_addon_id
                            ),
                          ]);
                        }
                        return setPickeds((prevIds = []) => [
                          ...prevIds,
                          x?.product_addon_id,
                        ]);
                      }}
                      checked={pickeds?.includes(x?.product_addon_id)}
                      defaultChecked={false}
                    />
                  }
                />
              </FormControl>
            );
          })}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
            justifyContent: "flex-end",
            mt: 2,
          }}
        >
          <Button size="small" variant="outlined" onClick={onClose}>
            Huỷ Thao Tác
          </Button>
          <Button size="small" variant="contained" onClick={onSubmit}>
            Thêm ADDON
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default AddonSelect;
