import useArrayObjects from "@/hooks/useArrayObjects";
import { createContext, useContext } from "react";

const StateArrayContext = createContext();

const StateArrayContextProvider = ({ children }) => {

    const props = useArrayObjects([])

    return (
        <StateArrayContext.Provider value={{ ...props }}>
            {children}
        </StateArrayContext.Provider>
    );
};

export const useStateArrayContext = () => {
    return useContext(StateArrayContext)
}

export default StateArrayContextProvider;
