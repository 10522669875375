import { Box, Typography } from '@mui/material'
import React from 'react'
import ReportTabs from '../shareds/ReportTab'
import withPermission from '@/hocs/withPermission'

const Summary = () => {
    return (
        <Box>
            <ReportTabs />
            <Typography sx={{ fontSize: 14, color: 'grey.700', mb: 2 }}>Report.Index</Typography>
        </Box>
    )
}

export default withPermission(Summary, { feature: 'report', action: 'report-summary' })