import { useNotificationContext } from "@/contexts/notification";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";

const ModalConfirm = ({
  type,
  callback,
  handleClose,
  handleSubmit,
  onlyClose,
  dialog = {
    title: "",
    content: "",
  },
  component = undefined
}) => {
  const [submit, setSubmit] = React.useState(false);
  const { setNotification } = useNotificationContext();
  const onSubmit = async () => {
    setSubmit(true);
    const response = await handleSubmit();
    setSubmit(false);
    if (!response || response?.status !== 200) {
      return setNotification({
        open: true,
        severity: "error",
        message: response?.message || "Thao tác thất bại. Vui lòng thử lại",
      });
    }
    setNotification({
      open: true,
      severity: "success",
      message: response?.message || "Thao tác thành công",
    });
    if (callback !== undefined) {
      callback();
    }
    if (response?.action) {
      handleClose();
      return response?.action();
    }
    return handleClose();
  };
  return (
    <Dialog open={true} fullWidth>
      <DialogTitle>{dialog?.title}</DialogTitle>
      <DialogContent>
        <Typography
          component="div"
          sx={{ flexWrap: "wrap", textAlign: "justify" }}
        >
          {dialog?.content}
        </Typography>
        {component}
      </DialogContent>
      <DialogActions>
        {type === "readOnly" || onlyClose ? (
          <Button
            variant="outlined"
            onClick={handleClose}
            disabled={submit}
            size="small"
            sx={{
              height: 36,
              minWidth: { xs: "100%", md: 150 },
            }}
          >
            Đóng
          </Button>
        ) : (
          <React.Fragment>
            <Button
              variant="outlined"
              onClick={handleClose}
              disabled={submit}
              sx={{
                height: 36,
                minWidth: { xs: "100%", md: 150 },
              }}
              size="small"
            >
              Huỷ Thao Tác
            </Button>
            <Button
              variant="contained"
              sx={{
                height: 36,
                minWidth: { xs: "100%", md: 150 },
              }}
              onClick={onSubmit}
              size="small"
              disabled={submit}
              startIcon={
                submit ? (
                  <CircularProgress size={16} sx={{ color: "#777680" }} />
                ) : undefined
              }
            >
              Đồng Ý
            </Button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirm;
