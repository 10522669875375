import { create, enforce, skipWhen, test } from "vest";

const productValidator = create((data = {}) => {

    test(`product_group_id`, 'Vui lòng chọn nhóm sản phẩm/ dịch vụ.', () => {
        enforce(parseInt(data?.product_group_id) > 0).isTruthy()
    })

    // product configoption validators
    skipWhen(
        data?.product_configoptions?.length === 0,
        () => {
            let names = []
            data?.product_configoptions.forEach((configoption, index) => {
                test(`product_configoptions.[${index}].name`, `Tên không được trống.`, () => {
                    enforce(configoption?.name).isNotEmpty()
                })
                test(`product_configoptions.[${index}].name`, `Tên không được trùng.`, () => {
                    enforce(!names?.includes(configoption?.name)).isTruthy()
                })
                names.push(configoption?.name)
            })
        }
    )

    test(`product_name`, 'Tên sản phẩm không được trống.', () => {
        enforce(data?.product_name).isNotEmpty()
    })

    test(`product_type`, 'Loại sản phẩm không được trống.', () => {
        enforce(data?.product_type.toString() !== "-1").isTruthy()
    })

})

export default productValidator;