import ProductApi from "@/apis/beta/Product/ProductApi";
import { format_date_short, queryClientRefetcher } from "@/functions";
import NumericFormatCustom from "@components/NumericFormatCustom";
import DatePicker from "@components/datepickers/DateRangPicker";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import { useNotificationContext } from "@contexts/notification";
import { vestResolver } from "@hookform/resolvers/vest";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import addonValidator from "./validators";
import ProductAddonApi from "@/apis/beta/Product/ProductAddonApi";
import _ from "lodash";

const CreateAddon = ({ onClose }) => {
  const { product_id: productId } = useParams();
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();
  const {
    control,
    watch,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: vestResolver(addonValidator),
    defaultValues: {
      product_name: "",
      product_description: "",
      product_type: "",
      pricing: 0,
      pricing_minimum: 0,
      pricing_apply_duedate: moment(),
      pricing_apply_startdate: moment(),
    },
  });

  const [submit, setSubmit] = useState(false);

  const onChangeDates = (dates) => {
    setValue(`pricing_apply_startdate`, dates[0]);
    setValue(`pricing_apply_duedate`, dates[1]);
    return;
  };

  const onSubmit = async (values) => {
    setSubmit(true);
    const addon = await ProductAddonApi.Create({
      productId,
      data: {
        product_name: values?.product_name,
        product_description: values?.product_description,
        product_type: values?.product_type,
      },
    });

    if (!addon || addon?.errors) {
      setSubmit(false);
      return setNotification({
        open: true,
        message: addon?.errors?.[0]?.message,
        severity: "error",
      });
    }

    await ProductApi.PushAddon({
      productId,
      addon_id: addon?.product_id,
    });
    const formPricing = {
      ..._.pick(values, ["pricing_minimum", "pricing"]),
      pricing_apply_startdate: format_date_short(
        values?.pricing_apply_startdate?.$d
      ),
      pricing_apply_duedate: format_date_short(
        values?.pricing_apply_duedate?.$d
      ),
    };

    const response = await ProductApi.CreatePricing({
      productId: addon?.product_id,
      data: formPricing,
    });
    setSubmit(false);

    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message || `Failed when create addon`,
        severity: "error",
      });
    }
    setNotification({
      open: true,
      message: "Thêm sản phẩm addon thành công.",
      severity: "success",
    });
    queryClientRefetcher(queryClient, ["product.pricing"]);
    return onClose();
  };
  return (
    <Drawer anchor="right" open={true}>
      <Box
        sx={{ width: { xs: "100%", md: 400 }, padding: 3, height: "100vh" }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography
          sx={{ fontSize: { xs: 16, md: 18 }, fontWeight: "medium", mb: 1.5 }}
          color="text.black"
        >
          Thêm Đơn Giá Dịch Vụ
        </Typography>
        <Controller
          name="product_name"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                  label="Tên sản phẩm addon *"
                  size="small"
                  value={value}
                  onChange={onChange}
                  error={!!errors?.product_name}
                  helperText={errors?.product_name?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Nhập tên sản phẩm addon..."
                />
              </FormControl>
            );
          }}
        />
        <Controller
          name="product_description"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                  label="Mô tả"
                  size="small"
                  value={value}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Nhập mô tả sản phẩm addon..."
                />
              </FormControl>
            );
          }}
        />
        <Controller
          name="product_type"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                  label="Loại sản phẩm *"
                  size="small"
                  value={value}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Ex: Ram, Disk,..."
                />
              </FormControl>
            );
          }}
        />
        <Controller
          name="pricing"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                  label="Đơn giá niêm yết *"
                  size="small"
                  value={value}
                  onChange={onChange}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  error={!!errors?.pricing}
                  helperText={errors?.pricing?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            );
          }}
        />
        <Controller
          name="pricing_minimum"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                  label="Đơn giá min *"
                  size="small"
                  value={value}
                  onChange={onChange}
                  error={!!errors?.pricing_minimum}
                  helperText={errors?.pricing_minimum?.message}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            );
          }}
        />
        <DatePicker
          onChange={onChangeDates}
          value={[
            dayjs(watch(`pricing_apply_startdate`)),
            dayjs(watch(`pricing_apply_duedate`)),
          ]}
        />
        {!!errors?.pricing_apply_startdate && (
          <ErrorHelperText message={errors?.pricing_apply_startdate?.message} />
        )}
        {!!errors?.pricing_apply_duedate && (
          <ErrorHelperText message={errors?.pricing_apply_duedate?.message} />
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 3,
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: 2,
          }}
        >
          <Button
            size="small"
            variant="outlined"
            disabled={submit}
            onClick={onClose}
          >
            Đóng
          </Button>
          <Button
            size="small"
            variant="contained"
            type="submit"
            disabled={!isDirty || submit}
            sx={{
              minWidth: { xs: "100%", md: 168 },
            }}
            startIcon={
              submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
            }
          >
            Thêm
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CreateAddon;
