import { Button, Typography } from '@mui/material'
import React from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import InvoiceApi from '@/apis/beta/Invoice/InvoiceApi';
import ModalConfirm from '@components/shared/ModalConfirm';
import withPermission from '@/hocs/withPermission';
const CancelInvoice = ({
    disabled = true,
    setDisabled,
    callback,
    invoice_id
}) => {

    const [openModal, setOpenModal] = React.useState(false)

    const handlerClick = () => {
        if (openModal) {
            setDisabled(false)
            return setOpenModal(false);
        }
        setDisabled(true)
        return setOpenModal(true)
    }

    const handleCancelledInvoice = async () => {
        const response = await InvoiceApi.Cancelled({
            invoiceId:invoice_id
        })
        if (!response || response?.status !== 200) {
            return {
                message: "Huỷ hoá đơn thất bại. Vui lòng thử lại.",
                status: 404,
            }
        }
        return {
            message: "Huỷ hoá đơn thành công.",
            status: 200,
        }
    }

    return (
        <React.Fragment>
            {openModal && <ModalConfirm
                callback={callback}
                handleClose={handlerClick}
                handleSubmit={handleCancelledInvoice}
                dialog={{
                    title: "Huỷ Hoá Đơn",
                    content: <Typography component="div">
                        Hoá đơn số
                        <Typography sx={{ display: 'inline', fontWeight: 'medium', m: 'auto 4px' }}>
                            [{invoice_id}]
                        </Typography>
                        sẽ bị huỷ. Thao tác sẽ không thể phục hồi, bạn có muốn thực hiện?
                    </Typography>
                }} />}
            <Button size="small"
                fullWidth
                variant="outlined"
                disabled={disabled}
                startIcon={<CloseOutlinedIcon />}
                onClick={handlerClick}
            >
                huỷ hoá đơn
            </Button>
        </React.Fragment>
    )
}

export default withPermission(CancelInvoice, { feature: "invoice", action: "cancelled", type: "button" })