import DriveApi from "@/apis/beta/Drives/DriveApi";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { styleLineClamp } from "@/libs/constants";
import { useEmployeeContext } from "@contexts/employee";
import { returnNameAndIcon } from "@features/Module/Drives/shared";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CreateFolder from "../CreateFolder";

const ChooseNewPath = ({ open, handleClose, onChange, title, prefix }) => {
  const { employee } = useEmployeeContext();
  const [searchParams] = useSearchParams();

  const [path, setPath] = useState(
    `/${searchParams?.get("path")?.split("/")?.[1] || employee?.employee_uuid}/`
  );
  const [history, setHistory] = useState([]);
  const [showDialogCreateFolder, setShowDialogCreateFolder] = useState(false);

  const { data, isFetching, refetch } = useQueryWithCustomized(
    [`get-data-to-choose-new-path-${path}`],
    () => DriveApi.Show({ path })
  );

  useEffect(() => {
    setHistory((prev) => [...prev?.filter((item) => item !== path), path]);
  }, [path]);

  const handleBack = () => {
    const cloneHistory = [...history];
    cloneHistory.pop();
    setPath(cloneHistory[cloneHistory?.length - 1]);
    setHistory(cloneHistory);
  };

  const handleChange = () => {
    onChange(path);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Box display="flex" alignItems="center" gap={2}>
            <Typography fontSize={20} fontWeight={600}>
              {title}
            </Typography>

            <Box display="flex" alignItems="center" gap={2}>
              <IconButton disabled={history?.length === 1} onClick={handleBack}>
                <KeyboardArrowLeftIcon />
              </IconButton>
            </Box>
          </Box>
          <Button
            sx={{ mt: { xs: 2, lg: 0, md: 0 } }}
            variant="contained"
            size="small"
            onClick={() => setShowDialogCreateFolder(true)}
          >
            Tạo thư mục mới
          </Button>
        </Box>
      </DialogTitle>

      <Box>
        {isFetching && (
          <Box p="24px">
            <CircularProgress size={30} />
          </Box>
        )}

        <Box px="24px">
          {data?.data?.objects?.common_prefixes?.map((item) => (
            <ListItem
              disablePadding
              key={item?.key}
              disabled={isFetching || item?.key === prefix}
              onClick={() => {
                if (isFetching || item?.key === prefix) return;
                setPath(item?.key?.replace("pub/drives", ""));
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <FolderCopyOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={item?.name} />
              </ListItemButton>
            </ListItem>
          ))}

          {data?.data?.objects?.contents?.map((item) => {
            const { name, icons } = returnNameAndIcon(item?.key);

            return (
              <ListItem disablePadding key={item?.key} disabled>
                <ListItemButton>
                  <ListItemIcon>{icons}</ListItemIcon>
                  <ListItemText primary={name} sx={styleLineClamp(1)} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </Box>
      </Box>

      <DialogActions>
        <Button size="small" variant="outlined" onClick={handleClose}>
          Huỷ Thao Tác
        </Button>
        <Button size="small" variant="contained" onClick={handleChange}>
          Chọn
        </Button>
      </DialogActions>

      {showDialogCreateFolder && (
        <CreateFolder
          open={showDialogCreateFolder}
          handleClose={() => setShowDialogCreateFolder(false)}
          prefix={`pub/drives${path}`}
          onChange={() => {
            refetch();
          }}
        />
      )}
    </Dialog>
  );
};

export default ChooseNewPath;
