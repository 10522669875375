import { Box } from "@mui/material";
import VPlayer from "vnetwork-player";
import "vnetwork-player/dist/vnetwork-player.min.css";

const VideoPlayer = ({ source }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%"
    >
      <Box width={800} maxWidth="100%">
        <VPlayer source={source} color="#E12F1F" />
      </Box>
    </Box>
  );
};

export default VideoPlayer;
