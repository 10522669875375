import DayoffApi from "@/apis/beta/Employee/DayoffApi";
import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import UserApi from "@/apis/UserApi";
import DayoffListShared from "@/components/Dayoff/List";
import { buildQueryFilterCondition } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import useFilters from "@/hooks/useFilters";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { AddOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const Dayoff = () => {
  const [current] = UserApi.Current();

  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const { data: employee } = EmployeeApi.Detail(current?.employee_id);

  const INITIAL_ACTION_LIST = [
    {
      order: 1,
      name: "update",
      isHref: true,
      href: {
        prefix: `/dayoffs/`,
        key: "day_off_id",
        suffix: "/update",
      },
      icon: "Edit",
      label: "Chỉnh Sửa",
    },
    {
      name: "dayoff-cancel",
      isHref: true,
      href: {
        prefix: `/dayoffs/`,
        key: "day_off_id",
      },
      icon: "Delete",
      label: "Huỷ Phiếu",
    },
  ];

  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const location = useLocation();
  const [updateQuery] = useFilters("account_dayoffs.list.filters", location);
  const filterableDayoffAccount = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
    const fieldQueries = [
      {
        name: "day_offs.day_off_id",
        operator: "$eq",
        value: parseInt(debouncedSearchQuery),
        disabled:
          !parseInt(debouncedSearchQuery) ||
          debouncedSearchQuery.match(/[/,-]/),
      },
      {
        name: "day_off_items.day_off_item_date",
        operator: "$eq",
        disabled: !debouncedSearchQuery.match(/[/,-]/),
        value: debouncedSearchQuery.toString(),
      },
    ];
    return buildQueryFilterCondition("$or", 0, fieldQueries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const {
    data,
    isFetching,
    refetch: callbackDayOffs,
  } = DayoffApi.List({
    query: `&filters[$and][0][day_offs.employee_id][$eq]=${current?.employee_id}`,
    searchQuery:
      filterableDayoffAccount() !== "" ? filterableDayoffAccount() : "",
    page: page?.page + 1,
    pageSize: page?.pageSize,
  });

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <Link to="/dayoffs/new" replace>
          <Button
            variant="contained"
            size="small"
            startIcon={<AddOutlined fontSize="small" />}
            sx={{
              height: { xs: 40, md: 42 },
              minWidth: { xs: "100%", md: 180 },
            }}
          >
            Tạo Phiếu Nghỉ
          </Button>
        </Link>
      </Box>
      <DayoffListShared
        updateQuery={updateQuery}
        inputSearch={{
          value: searchQuery,
          onChange: setSearchQuery,
          placeholder: "Nhập mã phiếu nghỉ tìm kiếm...",
        }}
        filter={{
          filter: "account_dayoffs.list.filters",
          columnsHidden: "account_dayoffs.hiddenColumn",
        }}
        employee={employee}
        callbackDayOffs={callbackDayOffs}
        feature="account"
        data={data?.day_offs}
        isFetching={isFetching}
        departments={[
          {
            department_id: employee?.department_id,
            department_name: employee?.employee_department,
          },
        ]}
        pagination={{
          rowsPerPageOptions: [5, 10, 20, 50],
          page: page?.page || 0,
          pageSize: page?.pageSize || 5,
          onPageChange: (newPage) =>
            setPage((prevState) => ({ ...prevState, page: newPage })),
          onPageSizeChange: (newPageSize) =>
            setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          total: data?.pagination?.total,
        }}
        employees={[employee]}
        initialActions={INITIAL_ACTION_LIST}
      />
    </React.Fragment>
  );
};

export default withSuspense(Dayoff, MuiSkeleton["DataGrid"]);
