import ProviderApi from "@/apis/beta/Provider/ProviderApi";
import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import WarehouseModelApi from "@/apis/beta/Warehouse/WarehouseModelApi";
import useDataGridParams from "@/hooks/useDataGridParams";
import { WAREHOUSE_STATUS_BUY } from "@/libs/constants";
import DataGridCustomize from "@components/DataGrid";
import Cell from "@components/DataGrid/Cell";
import CellConfig from "@components/DataGrid/CellConfig2";
import CellSpecification from "@components/DataGrid/CellSpecification";
import { Box, Link } from "@mui/material";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import Filters from "../../WarehouseDepreciations/Depreciations/Filters";
import DeviceAnalysist from "../Statistic";
import { useEffect } from "react";

const WarehouseItemDataGridDevice = ({
  types_id,
  name,
  customer = false,
  analysist = true,
  analysist_length = false,
  item_exports = false,
  API = WarehouseItemApi.ItemsCompany,
  showFilter = true,
}) => {
  const view = "default";

  const initialColumns = [
    {
      field: "warehouse_item_type",
      headerName: "Loại",
      minWidth: 120,
      valueGetter: ({ row }) =>
        `${_.find(warehouse_models, {
          warehouse_model_id: parseInt(
            row?.warehouse_model?.warehouse_model_id
          ),
        })?.warehouse_type?.warehouse_type_name
        }`,
      renderCell: ({ value, row }) => <Cell content={value?.toUpperCase()} />,
    },
    {
      field: "warehouse_item_name",
      headerName: "Tên ",
      minWidth: 250,
      resizable: true,
      renderCell: ({ value, row }) => (
        <Link
          component={NavLink}
          to={
            view === "default"
              ? `../warehouse/items/${row?.warehouse_item_id}/detail`
              : `../warehouse/assemble-items/${row?.warehouse_item_id}/edit`
          }
        >
          {" "}
          <Cell content={value} />
        </Link>
      ),
    },
    {
      field: "warehouse_item_service_tag",
      headerName: "Service Tag / Serial Number",
      sortable: false,
      width: 250,
      renderCell: ({ value, row }) => <Cell content={value} />,
    },
    {
      field: "capacity",
      headerName: "Capacity",
      width: 200,
      sortable: true,
      renderCell: ({ value }) => value && <CellConfig configures={value} />,
    },
    {
      field: "slots",
      headerName: "Slot",
      valueGetter: ({ row }) => {
        const slots = row?.warehouse_model?.slots?.filter((o) =>
          ["RAM", "DISK"].includes(o["slot_name"])
        );

        return slots?.map((o) => {
          if (o?.slot_name === "RAM") {
            const rams = _.sumBy(
              _.filter(row?.children, (o) => {
                return ["RAM"].includes(o?.warehouse_type?.warehouse_type_name);
              }),
              (o) => o?.total
            );
            return {
              label: `${o?.slot_name}: ${o?.slot_number - rams}`,
              value: null,
            };
          } else {
            const storages = _.sumBy(
              _.filter(row?.children, (o) => {
                return ["SSD", "SAS", "HDD", "NVME"].includes(
                  o?.warehouse_type?.warehouse_type_name
                );
              }),
              (o) => o?.total
            );
            return {
              label: `${o?.slot_name}: ${o?.slot_number - storages}`,
              value: null,
            };
          }
        });
      },
      renderCell: ({ value }) => <CellSpecification items={value} />,
      width: 250,
    },
    {
      field: "warehouse_box_name",
      headerName: "Kho / Datacenter",
      valueGetter: ({ row }) => {
        if (
          row?.warehouse_box_item &&
          row?.warehouse_box_item?.warehouse_box_item_reference_type ===
          "WarehouseBox"
        ) {
          const box = _.find(warehouse_boxes, {
            warehouse_box_id:
              row?.warehouse_box_item?.warehouse_box_item_reference_id,
          });
          return box;
        } else if (
          row?.warehouse_box_item &&
          row?.warehouse_box_item?.warehouse_box_item_reference_type ===
          "WarehouseRack" &&
          row?.warehouse_rack
        ) {
          const box = _.find(warehouse_boxes, {
            warehouse_box_id: row?.warehouse_rack?.warehouse_box_id,
          });
          return box;
        }
        return null;
      },
      renderCell: ({ value, row }) => (
        <>
          {value ? (
            value?.warehouse_box_type === 0 ? (
              <>{value?.warehouse_box_name}</>
            ) : (
              <Link
                component={NavLink}
                to={`/warehouse/boxes/${value?.warehouse_box_id}`}
              >
                {" "}
                <Cell content={value?.warehouse_box_name?.toUpperCase()} />{" "}
              </Link>
            )
          ) : (
            ""
          )}
        </>
      ),
      width: 200,
    },
    {
      field: "warehouse_box_rack",
      headerName: "Tủ Rack",
      valueGetter: ({ row }) => {
        if (
          row?.warehouse_box_item &&
          row?.warehouse_box_item?.warehouse_box_item_reference_type ===
          "WarehouseRack" &&
          row?.warehouse_rack
        ) {
          return row?.warehouse_rack;
        }
        return null;
      },
      renderCell: ({ value, row }) => (
        <>
          {value ? (
            <Link
              component={NavLink}
              to={`/warehouse/racks/${value?.warehouse_rack_id}?id=${row?.warehouse_item_id}`}
            >
              {" "}
              <Cell content={value?.warehouse_rack_name?.toUpperCase()} />{" "}
            </Link>
          ) : (
            ""
          )}
        </>
      ),
      width: 150,
    },
    {
      field: "warehouse_box_position",
      headerName: "Vị Trí",
      valueGetter: ({ row }) => {
        if (
          row?.warehouse_box_item &&
          row?.warehouse_box_item?.warehouse_box_item_reference_type ===
          "WarehouseRack" &&
          row?.warehouse_rack
        ) {
          return (
            row?.warehouse_box_item?.warehouse_box_item_position +
            (row?.warehouse_item_form_factor === 0 ||
              row?.warehouse_item_form_factor === 1
              ? ""
              : ` - ${row?.warehouse_box_item?.warehouse_box_item_position +
              (row?.warehouse_item_form_factor - 1)
              }`)
          );
        }
        return "";
      },
      width: 120,
    },
    {
      field: "processor",
      headerName: "Processor",
      width: 300,
      valueGetter: ({ row }) => {
        const cpus = _.filter(row?.children, (o) => {
          return ["CPU"].includes(o?.warehouse_type?.warehouse_type_name);
        });
        return _.map(cpus, (o) => ({
          label: `${o?.warehouse_item_name}`,
          value: o?.total,
        }));
      },
      renderCell: ({ value }) => <CellSpecification items={value} />,
    },
    {
      field: "memory",
      headerName: "Memory",
      width: 300,
      sortable: true,
      valueGetter: ({ row }) => {
        const rams = _.filter(row?.children, (o) => {
          return ["RAM"].includes(o?.warehouse_type?.warehouse_type_name);
        });
        return _.map(rams, (o) => ({
          label: `${o?.warehouse_item_name}`,
          value: o?.total,
        }));
      },
      renderCell: ({ value }) => <CellSpecification items={value} />,
    },
    {
      field: "storage",
      headerName: "Storage",
      width: 300,
      sortable: true,
      valueGetter: ({ row }) => {
        const storages = _.filter(row?.children, (o) => {
          return ["SSD", "SAS", "HDD", "NVME"].includes(
            o?.warehouse_type?.warehouse_type_name
          );
        });
        return _.map(storages, (o) => ({
          label: `${o?.warehouse_item_name}`,
          value: o?.total,
        }));
      },
      renderCell: ({ value }) => <CellSpecification items={value} />,
    },
    {
      field: "warehouse_resource_type",
      headerName: "Loại tài nguyên",
      renderCell: ({ value, row }) => 
      {
        if(value){
          return <Link 
          component={NavLink}
          to={`../warehouse/resources/${row?.warehouse_resource_id}/information?service_tag=${row?.warehouse_item_service_tag}`}
          >
            {value}
          </Link>
        }
        return ""
      },
      width: 200,
    },
    {
      field: "warehouse_resource_services",
      headerName: "Dịch vụ chạy",
      renderCell: ({ value, row }) => 
      {
        return row?.warehouse_resource_services?.map((item, index) => 
        <Link 
          key={index}
          component={NavLink} 
          sx={{ mr: 1 }}
          to={row?.warehouse_resource_type === "virturalization" ? 
            `../warehouse/resources/${row?.warehouse_resource_id}/information?service_tag=${row?.warehouse_item_service_tag}` 
            :
            `../warehouse/resources/${row?.warehouse_resource_id}/services/${item?.warehouse_resource_service_id}/edit?service_tag=${row?.warehouse_item_service_tag}`}
        >
          {item?.warehouse_resource_service_name}
        </Link>
        )
      },
      width: 200,
    },
    {
      field: "investment",
      headerName: "Cụm",
      renderCell: ({ value, row }) => 
      {
        const investments = row?.warehouse_resource_services?.map((item) => ({
          label: item?.warehouse_resource_service_code,
          value: 0
        }))
        return  <CellSpecification items={_.uniqBy(investments,"label")}/>
      },
      width: 200,
    },
    {
      field: "warehouse_item_description",
      headerName: "Mô tả",
      valueGetter: ({ row }) =>
        row?.warehouse_box_item
          ? row?.warehouse_box_item?.warehouse_box_item_description
          : "",
      renderCell: ({ value, row }) => <Cell content={value} />,
      width: 150,
    },

    {
      field: "provider_id",
      headerName: "Nhà Cung Cấp",
      hide: true,
      valueGetter: ({ row }) =>
        _.find(providers, { provider_id: row?.provider_id })?.provider_name,
      renderCell: ({ value, row }) => <Cell content={value?.toUpperCase()} />,
      width: 150,
    },

    {
      field: "warehouse_item_form_factor",
      headerName: "Form Factor",
      hide: true,
      sortable: true,
      valueGetter: ({ row }) =>
        row?.warehouse_item_form_factor === 0
          ? ""
          : row?.warehouse_item_form_factor + " U",
      width: 150,
    },
    {
      field: "warehouse_item_status",
      headerName: "Trình Trạng",
      valueGetter: ({ row }) =>
        _.find(WAREHOUSE_STATUS_BUY, {
          value: parseInt(row?.warehouse_item_status),
        })?.label,
      width: 150,
    },
  ];

  const {
    columns,
    debouncedSearchQuery,
    page,
    searchQuery,
    setColumns,
    setPage,
    setSearchQuery,
    query,
    updateQuery,
  } = useDataGridParams(initialColumns);

  const params = {
    type: types_id,
    page: page?.page + 1 || 1,
    pageSize: page?.pageSize || 20,
    searchQuery: debouncedSearchQuery,
    query,
  };

  if (customer) {
    params.customer = customer;
  }

  const { data: warehouse_items, isFetching } = API(params);

  const { data: warehouse_boxes } = WarehouseBoxApi.getAll();
  const { data: providers } = ProviderApi.getAll();
  const { data: warehouse_models } = WarehouseModelApi.All({ short: true });
  
  useEffect(() => {
    setPage((prevState) => ({ ...prevState, page: 0 }))
  },[searchQuery])
  return (
    <Box>
      {analysist && (
        <DeviceAnalysist
          debouncedSearchQuery={debouncedSearchQuery}
          type={types_id}
          device
          query={query}
        />
      )}

      {showFilter && (
        <Filters
          updateQuery={updateQuery}
          query={query}
          types_ids={types_id}
          useFilterModel
          customer={customer}
        />
      )}

      <DataGridCustomize
        rows={warehouse_items?.warehouse_items || []}
        columns={columns}
        loading={isFetching}
        getRowHeight={({ model }) => {
          const ROW_HEIGHT = 24;
          const PADDING_Y = 8;
          return Math.max(
            Object.keys(
              _.omit(model?.capacity, [
                "capacity_updated_at",
                "capacity_created_at",
                "warehouse_item_id",
                "capacity_id",
              ])
            )?.filter((item) => model?.capacity[item])?.length *
            ROW_HEIGHT +
            PADDING_Y,
            52
          );
        }}
        rowCount={warehouse_items?.pagination?.total_pages * page?.pageSize || 0}
        componentsProps={{
          toolbar: {
            store: `${name}.list.hiddenColumns`,
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: name,
            inputSearch: {
              placeholder: "Tìm kiếm,...",
              value: searchQuery,
              onChange: setSearchQuery,
            },
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize || 20,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

export default WarehouseItemDataGridDevice;
