import { format_numeral_price } from '@/functions'
import { SERVICE_PERIODS } from '@/libs/constants'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const ProductPricingAmount = () => {
    const { watch, setValue, control } = useFormContext()

    const productConfigoptions = useWatch({
        control: control,
        name: 'product_configoptions'
    })

    const handleCalcProductPricing = React.useCallback(() => {
        let productPricingPerMonth = parseFloat(watch(`product_pricing_amount`))
        productConfigoptions?.forEach((configoption) => {
            switch (configoption?.type) {
                case "software":
                case "cloudsecurities":
                case "ssl":
                    if (!!configoption?.select && configoption?.select?.product_addon_id !== -1) {
                        productPricingPerMonth += parseFloat(configoption?.select?.product_addon_pricing)
                    }
                    break;
                case "cloudstorage":
                    if (configoption?.select?.product_addon_id === 313) {
                        productPricingPerMonth += parseFloat(configoption?.select?.product_addon_pricing) * parseFloat(configoption?.select?.product_addon_qty)
                    }
                    break;
                default:
                    configoption?.product_addons?.forEach((item) => {
                        if (parseInt(item?.product_addon_qty) > 0) {
                            productPricingPerMonth += parseFloat(item?.product_addon_pricing) * parseInt(item?.product_addon_qty)
                        }
                    })
                    break;
            }
        })

        setValue(`product_pricing_per_month`, productPricingPerMonth)


        let coff = SERVICE_PERIODS.find(item => item.value === watch(`product_billing_cycle`))?.coefficient
        if (coff > 1) {
            setValue(`product_pricing_total`, Math.round(productPricingPerMonth * coff))
        } else {
            setValue(`product_pricing_total`, productPricingPerMonth)
        }
        return productPricingPerMonth
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productConfigoptions, watch("product_pricing_amount"), watch("product_billing_cycle"), watch("product_configoptions")])

    React.useEffect(() => {
        handleCalcProductPricing()

    }, [handleCalcProductPricing])
    return (
        <>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Typography component="span" sx={{ color: "#777680" }}>
                    Tạm Tính:
                </Typography>
                <Typography component="span" sx={{
                    fontWeight: 'medium'
                }}>
                    <Typography sx={{
                        display: 'inline-flex',
                        color: "#777680"
                    }}>
                        đ
                    </Typography>
                    {format_numeral_price(watch(`product_pricing_total`))}
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Typography component="span" sx={{ color: "#777680" }}>
                    VAT:
                </Typography>
                <Typography component="span" sx={{
                    fontWeight: 'medium'
                }}>
                    <Typography sx={{
                        display: 'inline-flex',
                        color: "#777680"
                    }}>
                        đ
                    </Typography>
                    {format_numeral_price(Math.round(parseFloat(watch(`product_pricing_total`)) * 0.1))}
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Typography component="span" sx={{
                    fontWeight: 'medium',
                    fontSize: 18
                }}>
                    Thành Tiền:
                </Typography>
                <Typography component="span" sx={{
                    fontWeight: 'medium',
                    fontSize: 18
                }}>
                    <Typography sx={{
                        display: 'inline-flex',
                        fontSize: 18
                    }}>
                        đ
                    </Typography>
                    {format_numeral_price(watch(`product_pricing_total`) + Math.round(parseFloat(watch(`product_pricing_total`)) * 0.1))}
                </Typography>
            </Box>
        </>
    )
}

export default ProductPricingAmount