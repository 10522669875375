import { format_date_short } from '@/functions'
import useOnClickOutside from '@/hooks/useOnClickOutside'
import { DAYOFF_TIME_OPTIONS } from '@/libs/constants'
import { Box, Paper, Popover, Typography } from '@mui/material'
import React from 'react'

const DayoffRangeTime = ({ data = [] }) => {
	const ref = React.useRef()
	const [clicked, setClicked] = React.useState(null)

	useOnClickOutside(ref, () => !!clicked ? setClicked(null) : undefined)

	return (
		<React.Fragment>
			{(!!clicked && data?.length > 2) && <Popover anchorEl={clicked} open={!!clicked}>
				<Paper elevation={10} sx={{ p: 2 }}>
					<Typography sx={{
						mb: 0.5,
						fontWeight: "medium",
						fontSize: 14
					}}>Ngày Nghỉ</Typography>
					{data?.map((item, index) => {
						return (
							<React.Fragment key={index}>
								<Typography component="div" sx={{ fontSize: 14 }}>
									{format_date_short(item?.startdayoff)}
									<Typography sx={{ m: 'auto 4px', display: 'inline', fontSize: 14 }}>
										{DAYOFF_TIME_OPTIONS?.find(op => op?.value.toString() === item?.dayoff_option?.toString())?.label}
									</Typography>
								</Typography>
							</React.Fragment>
						)
					})}
				</Paper>
			</Popover>}
			<Box ref={ref}
				onClick={(event) => data.length > 2 ? setClicked(event?.currentTarget) : undefined}
				sx={{ height: '100%', width: '100%', p: '8px 0', display: 'flex', flexDirection: 'column' }}>
				{data && data?.slice(0, 2).map((item, index) => {
					return (
						<Typography
							key={index}
							onClick={() => setClicked(true)}
							component="div"
							sx={{
								fontSize: 14,
								m: 'auto 0'
							}}>
							{format_date_short(item?.startdayoff)}
							<Typography sx={{ display: 'inline', fontSize: 14, m: 'auto 4px' }}>
								{DAYOFF_TIME_OPTIONS?.find(op => op?.value.toString() === item?.dayoff_option?.toString())?.label?.replace(/Buổi /, '')}
							</Typography>
							{(data?.length > 2 && index === 1) && <Typography
								color="primary"
								sx={{ display: 'inline', fontSize: 14, cursor: 'pointer' }}>[+{data?.length - 2}...]</Typography>}
						</Typography>
					)
				})}
			</Box>
		</React.Fragment>
	)
}

export default DayoffRangeTime