import React from 'react'
import ServicePackage from './Package'
import SwitfCDNPrice from './Price'

const SwiftCDNPrice = ({ ...props }) => {
    return (
        <>
            <ServicePackage serviceId={props?.service_id} commitments={props?.service_configoptions || []} />
            <SwitfCDNPrice serviceId={props?.service_id} configoptions={props?.service_configoptions || []} />
        </>
    )
}

export default SwiftCDNPrice