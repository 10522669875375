import { Search } from '@mui/icons-material';
import { Box, TextField } from '@mui/material';
import React from 'react';
import ButtonCreditCreate from "../ButtonCreate";
import CreateCredit from '../Create';

const Toolbar = ({
    customerId,
    searchQuery,
    setSearchQuery
}) => {

    const [openForm, setOpenForm] = React.useState(false)

    return (
        <Box sx={{
            minHeight: { xs: 'auto', md: 76 },
            maxWidth: '100%',
            mt: { xs: 2, md: 0 },
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 1.5,
            justifyContent: 'space-between'
        }}>
            <ButtonCreditCreate
                disabled={openForm}
                setOpenForm={setOpenForm}
            />
            <TextField
                value={searchQuery}
                onChange={(event) => setSearchQuery(event?.target?.value)}
                InputProps={{
                    startAdornment: <Search sx={{ color: '#bfbfbf', mr: 1 }} />,
                    placeholder: 'Nhập ID ký quỹ để tìm kiếm...',
                    sx: {
                        fontSize: 14,
                        height: 40,
                        width: { xs: 'calc(100% - 32px)', md: 300 },
                        m: 'auto 16px'
                    }
                }} />
            {openForm && <CreateCredit
                customerId={customerId}
                onClose={() => setOpenForm(false)} />}
        </Box>
    )
}

export default Toolbar