import { useState } from "react";
import useDebounce from "./useDebounce";
import { useSearchParams } from "react-router-dom";

const useDataGridParams = (
  initialColumns,
  pageSize = 20,
  disableSearchPageParams = false
) => {
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState({
    page: Number(searchParams.get("page")) || 0,
    pageSize,
  });
  const [columns, setColumns] = useState(initialColumns);
  const [searchQuery, setSearchQuery] = useState("");
  const [query, updateQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  return {
    page,
    setPage,
    columns,
    setColumns,
    searchQuery,
    setSearchQuery,
    debouncedSearchQuery,
    disableSearchPageParams,
    query,
    updateQuery,
  };
};

export default useDataGridParams;
