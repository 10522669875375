// import Create from './Create';
// import List from './List';
// import Detail from './Detail';
// import SubWorkContract from './SubWorkContract';
// // eslint-disable-next-line import/no-anonymous-default-export
// export default {
//     Create,
//     List,
//     Detail,
//     SubWorkContract
// };
import React from 'react'

const index = () => {
    return (
        <div>Feature in development!</div>
    )
}

export default index