import React from 'react'
import withPermission from '@/hocs/withPermission'
import CompanyForm from "../shared/CompanyForm"

const Update = ({
    data,
    callback,
    handleClose
}) => {
    return (
        <CompanyForm
            type="update"
            data={data}
            handleClose={handleClose}
            callback={callback}
        />
    )
}

export default withPermission(Update, { feature: 'company', action: 'update' })