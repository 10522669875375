import PermissionApi from "@/apis/beta/Permission/PermissionApi";
import ErrorHelperText from "@/components/shared/ErrorHelperText";
import { queryClientRefetcher, sleep } from "@/functions";
import { INITIAL_WEBSITES } from "@/libs/constants";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { useNotificationContext } from "@contexts/notification";
import { vestResolver } from "@hookform/resolvers/vest";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import ActionList from "./ActionList";
import suite from "./suite";

const Form = ({ type = "create", defaultValues }) => {
  const queryClient = useQueryClient();

  let navigate = useNavigate();
  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = React.useState(null);

  const methods = useForm({
    resolver: vestResolver((props) => suite({ ...props })),
    defaultValues: defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;

  const onSubmit = async (values) => {
    setSubmit("submit");
    let form = {
      website: values?.website,
      feature: values?.feature?.toLowerCase(),
      actions: values?.actions?.map((action) => ({
        action_name: action?.action_name?.toLowerCase(),
        ..._.pick(action, [
          "permission_pathname",
          "module",
          "is_outlet",
          "prefix_outlet",
        ]),
      })),
    };
    const response = await PermissionApi.PostFeaturePermissions(form);
    clearLoading();
    if (!response || response.status !== 200) {
      return setNotification({
        open: true,
        message:
          response?.data?.message || "Tạo mới/ Chỉnh sửa tính năng thất bại.",
        severity: "error",
      });
    }
    queryClientRefetcher(queryClient, [
      "permissions.list",
      "permission.detail",
    ]);
    setNotification({
      open: true,
      message:
        response?.data?.message ||
        "Tạo mới/ Chỉnh sửa tính năng - quyền hạn thành công.",
      severity: "success",
    });

    return navigate("/roles", { replace: true });
  };
  const clearLoading = () => setSubmit(null);

  const handleReset = async () => {
    setSubmit("reset");
    await sleep(500);
    clearLoading();
    reset(defaultValues);
    return setNotification({
      open: true,
      message: "Resetted!!!",
      severity: "success",
    });
  };
  return (
    <FormProvider {...methods}>
      <Paper component="form" sx={{ p: 3 }} onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
            gap: 0.5,
          }}
        >
          <Box sx={{ minWidth: "30%" }}>
            <Typography>Tên Website</Typography>
          </Box>
          <Controller
            control={control}
            name="website"
            render={({ field: { value, onChange } }) => {
              const selected = INITIAL_WEBSITES?.find(
                (website) => website?.value === value
              );
              return (
                <FormControl fullWidth>
                  <SingleSelectCustomize
                    disabled={type !== "create"}
                    value={selected}
                    options={INITIAL_WEBSITES}
                    onChange={(event, newValue) => {
                      onChange(newValue?.value || -1);
                    }}
                  />
                  {!!errors?.website && (
                    <ErrorHelperText message={errors?.website?.message} />
                  )}
                </FormControl>
              );
            }}
          />
        </Box>
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
            gap: 0.5,
          }}
        >
          <Box sx={{ minWidth: "30%" }}>
            <Typography>Tên Tính Năng</Typography>
          </Box>
          <Controller
            control={control}
            name="feature"
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    placeholder="Nhập tên tính năng..."
                    value={value}
                    error={!!errors?.feature}
                    helperText={errors?.feature?.message}
                    disabled={type !== "create"}
                    onChange={onChange}
                  />
                </FormControl>
              );
            }}
          />
        </Box>
        <ActionList />
        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
          }}
        >
          <Button
            size="small"
            variant="text"
            sx={{ height: { xs: 36, md: 42 } }}
            onClick={handleReset}
            disabled={!!submit}
            startIcon={
              submit === "reset" && (
                <CircularProgress size="small" sx={{ color: "#777980" }} />
              )
            }
          >
            Huỷ Thao Tác
          </Button>
          <Button
            size="small"
            type="submit"
            variant="contained"
            disabled={!!submit}
            startIcon={
              submit === "submit" && (
                <CircularProgress size="small" sx={{ color: "#777980" }} />
              )
            }
            sx={{
              height: { xs: 36, md: 42 },
            }}
          >
            {type === "update" ? "Chỉnh sửa" : "Thêm"} phân quyền
          </Button>
        </Box>
      </Paper>
    </FormProvider>
  );
};

export default Form;
