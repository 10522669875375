

export const TYPE_DOCUMENT = [
  {
    label: "Chính sách",
    value: "CS",
  },
  {
    label: "Quy chế/ Nội quy",
    value: "QC",
  },
  {
    label: "Quy định",
    value: "QĐ",
  },
  {
    label: "Quy trình",
    value: "QT",
  },
  {
    label: "Hướng dẫn",
    value: "HD",
  },
  {
    label: "Biểu mẫu",
    value: "BM",
  },
  {
    label: "Mục tiêu",
    value: "MT",
  },
  {
    label: "Thông báo",
    value: "TB",
  },
  {
    label: "Kế hoạch",
    value: "KH",
  },
  {
    label: "Báo cáo",
    value: "BC",
  },
  {
    label: "Biên bản",
    value: "BB",
  },
  {
    label: "Hợp đồng",
    value: "HĐ",
  },
  {
    label: "Quyết định",
    value: "QĐ",
  },
  {
    label: "Khác",
    value: "OT",
  },
];

export const LEVEL_DOCUMENT = [
  {
    label: "Tài liệu/ Hồ sơ mật",
    value: "CF",
  },
  {
    label: "Tài liệu/ Hồ Sơ hạn chế",
    value: "LM",
  },
  {
    label: "Tài liệu/ Hồ Sơ internal",
    value: "IN",
  },
];
