import InvoiceApi from "@/apis/beta/Invoice/InvoiceApi";
import DataGrid from "@/components/DataGrid";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import { CellLink, CellPricing } from "@components/DataGrid/Cell";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Chip } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import InvoiceServices from "../List/InvoiceService";
import DataGridFilter from "@components/DataGrid/Filter";
import { useLocation } from "react-router";
import useFilters, { Filter } from "@/hooks/useFilters";

export const INVOICE_MAILER_STATUS_TYPES = [
  { value: "pending", label: "Chưa gửi", chip: "pending" },
  { value: "completed", label: "Đã gửi", chip: "success" },
];

export const INVOICE_MAILER_REFERRER_TYPES = [
  { value: "StatementInvoice", label: "Đối Soát", chip: "warning" },
  { value: "PaymentInvoice", label: "Hoá Đơn", chip: "success" },
];
const Mailer = () => {
  const location = useLocation();

  const [query, updateQuery] = useFilters("invoice.mailers", location);

  const [page, setPage] = useState({
    page: 0,
    pageSize: 20,
  });

  const { data, isFetching } = InvoiceApi.InvoiceMailers({
    invoiceId: null,
    page: page?.page + 1,
    pageSize: page?.pageSize,
    query: Filter.stringify(query),
  });

  const [columns, setColumns] = React.useState([
    {
      field: "invoices.invoice_id",
      headerName: "ID - Invoice",
      width: 100,
      hideable: false,
      sortable: true,
      valueGetter: ({ row }) => ({
        label: row?.task_referrer_id,
        href: ["invoices", row?.task_referrer_id],
      }),
      operators: { type: "number" },
      renderCell: ({ value }) => <CellLink data={value} />,
    },
    {
      field: "mailer_subject",
      headerName: "Tiêu Đề",
      valueGetter: ({ row }) => row?.metadata?.subject,
      width: 180,
    },
    {
      field: "service_id",
      headerName: "Dịch Vụ",
      valueGetter: ({ row }) => row?.metadata?.services || [],
      width: 250,
      renderCell: ({ value }) => <InvoiceServices services={value} />,
    },
    {
      field: "metadata.whois.customer_name",
      headerName: "Khách Hàng",
      valueGetter: ({ row }) => {
        if (!row?.metadata?.whois || !row?.metadata?.whois?.customer_id)
          return `---`;
        return {
          label:
            row?.metadata?.whois?.customer_company_name ||
            row?.metadata?.whois?.customer_name,
          href: ["customers", row?.metadata?.whois?.customer_id],
        };
      },
      width: 200,
      renderCell: ({ value }) => {
        if (!!value?.href) return <CellLink data={value} />;
        return value;
      },
      operators: { type: "object", operators: ["$json"] },
    },
    {
      field: "metadata.mailer.receivers",
      headerName: "Người Nhận",
      valueGetter: ({ row }) => {
        if (typeof row?.metadata?.mailer?.receivers === "string") {
          return [row?.metadata?.mailer?.receivers];
        }
        return row?.metadata?.mailer?.receivers || [];
      },
      width: 200,
      operators: {
        type: "object",
        operators: ["$json"],
      },
      renderCell: ({ value }) => value?.join(", "),
    },
    {
      field: "mailer_cc",
      headerName: "Email Cc",
      valueGetter: ({ row }) => {
        if (typeof row?.metadata?.mailer?.cc === "string") {
          return [row?.metadata?.mailer?.cc];
        }
        return row?.metadata?.mailer?.cc || [];
      },
      width: 150,
      renderCell: ({ value }) => value?.join(", "),
    },
    {
      field: "metadata.billing_cycle",
      headerName: "Chu Kỳ Dịch Vụ",
      minWidth: 180,
      valueGetter: ({ row }) => row?.metadata?.billing_cycle,
    },
    {
      field: "metadata.invoice_total",
      headerName: "Số Tiền Thanh Toán",
      minWidth: 150,
      valueGetter: ({ row }) => row?.metadata?.invoice_total,
      renderCell: ({ value }) => <CellPricing price={value} />,
    },
    {
      field: "metadata.sender",
      headerName: "Người Gửi",
      valueGetter: ({ row }) => {
        if (!row?.metadata?.sender) return "automation-0";
        return (
          JSON.parse(row?.metadata?.sender?.replaceAll("=>", ":"))
            ?.employee_name || ""
        );
      },
      operators: {
        type: "object",
        operators: ["$json"],
      },
      width: 200,
    },
    {
      field: "task_created_at",
      headerName: "Tạo Lệnh Gửi Lúc",
      width: 150,
      renderCell: ({ value }) =>
        value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "---",
    },
    {
      field: "task_completed_at",
      headerName: "Thời Gian Gửi",
      width: 150,
      renderCell: ({ value }) =>
        value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "---",
      operators: { type: "date" },
    },
    {
      field: "task_referrer_type",
      headerName: "Loại Hoá Đơn",
      width: 120,
      valueGetter: ({ row }) =>
        INVOICE_MAILER_REFERRER_TYPES?.find(
          (x) => x?.value === row?.task_referrer_type
        )?.label || "---",
      operators: {
        type: "object",
        collections: INVOICE_MAILER_REFERRER_TYPES,
        operators: ["$eq"],
      },
    },
    {
      field: "task_referrer_jid",
      headerName: "Jobs Identity",
      width: 150,
      hide: true,
    },
    {
      field: "task_status",
      headerName: "Trạng Thái",
      width: 120,
      valueGetter: ({ row }) =>
        INVOICE_MAILER_STATUS_TYPES?.find((t) => t?.value === row?.task_status),
      renderCell: ({ value }) => (
        <Chip
          size="small"
          variant={`customize-${value?.chip}`}
          label={value?.label}
        />
      ),
      operators: {
        type: "object",
        collections: INVOICE_MAILER_STATUS_TYPES,
      },
    },
  ]);

  const filterables = columns
    .filter((column) => !!column?.operators && !column?.hide)
    .map((column) => {
      return {
        field: column?.key ? column.key : column.field,
        label: column?.headerName,
        operators: column?.operators,
      };
    });
  return (
    <Box sx={{ mt: 2 }}>
      <DataGridFilter
        callback={updateQuery}
        filters={{
          key: "invoice.mailers",
          filterables: [...filterables],
        }}
      />
      <DataGrid
        columns={columns}
        rows={data?.tasks?.map((item, index) => ({ ...item, id: index }))}
        components={{}}
        loading={isFetching}
        getRowHeight={({ model }) => {
          const PADDING_OFFSET_Y = 8;
          const SERVICES =
            24 * (model?.metadata?.services?.length || 0) + PADDING_OFFSET_Y;
          return Math.max(SERVICES, 52);
        }}
        componentsProps={{
          toolbar: {
            store: "invoices.list.hiddenColumns",
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: columns,
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        rowCount={data?.pagination?.total}
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(Mailer, {
    feature: "invoice",
    action: "mailers",
  }),
  MuiSkeleton["DataGrid"]
);
