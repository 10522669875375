import React from 'react';

const NotificationContext = React.createContext();

export const NotificationProvider = ({ children }) => {
  const defaults = {
    open: false,
    message: '',
    severity: 'success',
    duration: 5000,
  };

  const setNotification = (notification = { ...defaults }) =>
    setState((prevState) => ({
      ...prevState,
      notification: { ...defaults, ...notification },
    }));

  const backgroundLoading = {
    open: false,
    type: "",
    file: null,
    percent: 0,
    done: false
  }

  const setBackgroundLoading = (backgroundProps) => {
    setState((prevState) => ({
      ...prevState,
      background: { ...backgroundLoading, ...backgroundProps },
    }));
  }

  // eslint-disable-next-line no-unused-vars
  const [state, setState] = React.useState({
    notification: defaults,
    setNotification: setNotification,
    background: backgroundLoading,
    setBackground: setBackgroundLoading
  });

  return (
    <NotificationContext.Provider value={{ ...state }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  return React.useContext(NotificationContext);
};
