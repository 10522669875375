import { payloadToQueries, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_SERVICE_URL } from "@/libs/constants";

class ServiceTechnicalApi {

    MlyticCustomers = ({ queries = {} }) => {
        return useQueryWithCustomized(
            ["mlytics.customers", queries],
            async () => {
                return await requestWithToken(`${API_SERVICE_URL}/viewers/services/providers/mlytics/customers?${payloadToQueries(queries)}`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return { customers: data?.data?.customers || [] }
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            })
    };

}
// eslint-disable-next-line import/no-anonymous-default-export
export default new ServiceTechnicalApi()