import { useCustomerContext } from '@contexts/customer'
import { FormControl, Grid } from '@mui/material'
import _ from 'lodash'
import { useEffect } from 'react'
import { queryToCondition } from '../../PopperPopupState/functions'

const CustomerFilter = ({
    callback, updateQuery
}) => {
    const { queries, Components } = useCustomerContext()

    useEffect(() => {
        let andConditions = []
        _.forEach(queries, (obj) => {
            if (!!obj.value) {
                if (obj.type === "object" && obj.value.length === 0) {
                    return;
                }
                if (obj.type === "date" && !obj.value[0]) {
                    return;
                }
                const q = queryToCondition({
                    fieldSelect: obj.key === 'service' ? 'customer_service_informations.service_name_stoppeds' : obj?.key,
                    input: {
                        string: obj.value,
                        objects: obj.value?.map(x => ({ value: x })),
                        type: obj.type,
                        startdate: obj.type === "date" ? obj.value[0] : null,
                        enddate: obj.type === "date" ? obj.value[1] : null
                    },
                    operator: { operator: obj.operator }
                })
                if (obj.type === "date" && obj.operator === "$btw") {
                    andConditions.push(...q)
                    return;
                }
                andConditions.push(q)
            }
        })

        const filters = {
            $and: andConditions
        }
        updateQuery(filters)
        callback(filters)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queries])
    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            {queries?.map((column, index) => {
                if (column?.hide) return null;

                const Component = Components[column?.component]
                if (!Component) return null;

                return (
                    <Grid item xs={12} md={4} xl={3} key={index}>
                        <FormControl fullWidth>
                            <Component
                                value={column.value}
                                onChange={column?.onChange}
                                options={[...column?.options || []]}
                                {...column?.params || {}}
                            />
                        </FormControl>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default CustomerFilter