import ContactApi from "@/apis/beta/Customer/ContactApi";
import { queryClientRefetcher } from "@/functions";
import withPermission from "@/hocs/withPermission";
import ModalConfirm from "@components/shared/ModalConfirm";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

const Destroy = ({ contactIds }) => {
  const [destroy, setDestroy] = useState(false);

  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(ContactApi.Destroy);

  const onSubmit = async () => {
    const responses = await Promise.all(
      contactIds?.map(async (contactId) => {
        return await mutateAsync({
          contactId,
        });
      })
    ).then((responses) => {
      return responses?.map((x) => x);
    });

    const errors = responses?.filter((item) => item?.errors);
    if (errors?.length > 0) {
      return {
        status: 400,
        message: errors
          ?.map((item) => item?.errors?.[0]?.message || "")
          ?.join(", "),
      };
    }
    queryClientRefetcher(queryClient, ["contact.list", "contacts.statistic"]);
    return {
      status: 200,
      message: `Xoá thành công ${responses?.length} khách hàng.`,
    };
  };

  return (
    <Fragment>
      {destroy && (
        <ModalConfirm
          handleClose={() => setDestroy(false)}
          handleSubmit={onSubmit}
          dialog={{
            title: `Xoá Khách Hàng Marketing`,
            content: (
              <Typography component="div" sx={{ fontSize: 14 }}>
                Bạn đang thực hiện thao tác xoá {contactIds?.length} khách hàng
                marketings. Dữ liệu sẽ không thể khôi phục, bạn có muốn thực
                hiện?
              </Typography>
            ),
          }}
        />
      )}
      <Box sx={{ mb: 1.25, display: "flex", justifyContent: "flex-end" }}>
        <Button
          size="small"
          variant="contained"
          onClick={() => setDestroy(true)}
          disabled={isLoading}
          startIcon={
            isLoading && (
              <CircularProgress size={14} sx={{ color: "#777680" }} />
            )
          }
        >
          Xoá Khách Hàng
        </Button>
      </Box>
    </Fragment>
  );
};

export default withPermission(Destroy, {
  feature: "contact",
  action: "index",
  type: "Button",
});
