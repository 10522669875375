import { format_number_usage, format_numeral_price } from '@/functions'
import { UNLIMITED } from '@/libs/constants'
import { Typography } from '@mui/material'
import React from 'react'

const StorageLFDCommit = ({ data }) => {
    return (
        <>
            <Typography sx={{ fontSize: 13 }}>
                Thông Tin Gói: {" "}
                <Typography component="span" sx={{ fontSize: 13, fontWeight: 'medium' }} color="primary">
                    {format_number_usage(data?.service_commitment_configure?.value)}{" "}
                </Typography>
                <Typography component="span" sx={{ fontSize: 13, textTransform: 'capitalize' }}>
                    {data?.service_commitment_configure?.unit}{"/"}
                </Typography>
                <Typography component="span" sx={{ fontSize: 13 }}>
                    {format_number_usage(data?.service_commitment_configure?.term)}{" Tháng"}
                </Typography>
            </Typography>
            {data?.service_commitment_configure?.value < UNLIMITED && <Typography sx={{ fontSize: 13 }}>
                Đơn Giá Bán: {" "}
                <Typography component="span" sx={{ fontSize: 13 }}>
                    {format_numeral_price(data?.service_commitment_configure?.pricing_apply)}{"/"}
                </Typography>
                <Typography component="span" sx={{ fontSize: 13, textTransform: 'capitalize' }}>
                    {data?.service_commitment_configure?.unit}
                </Typography>
            </Typography>}
            <Typography sx={{ fontSize: 13 }}>
                Tạm Tính: {" "}
                <Typography component="span" sx={{ fontSize: 13 }}>
                    {format_numeral_price(data?.service_commitment_configure?.pricing_amount)}{"/"}
                </Typography>
                <Typography component="span" sx={{ fontSize: 13 }}>
                    {format_number_usage(data?.service_commitment_configure?.term)}{" Tháng"}
                </Typography>
            </Typography>
            <Typography sx={{ fontSize: 13 }}>
                Hình Thức Thanh Toán: {" "}
                <Typography component="span" sx={{ fontSize: 13 }}>
                    {data?.service_commitment_configure?.prepaid ? 'Trả Trước' : 'Trả Sau'}
                </Typography>
            </Typography>
        </>
    )
}

export default StorageLFDCommit