import { COUNTRIES } from '@/libs/constants'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, FormControl, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import EmployeeTemporaryAddress from '../EmployeeTemporaryAddress'
import PermanentAddressCity from './PermanentAddressCity'
import PermanentAddressDistrict from './PermanentAddressDistrict'
import PermanentAddressWard from './PermanentAddressWard'

const EmployeePermanentAddress = () => {

  const { control, formState: { errors }, watch } = useFormContext()
  return (
    <Box
      sx={{
        p: 1.5,
        border: "1px solid rgba(0, 0, 0, 0.18)",
        borderRadius: 1,
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: 16, md: 18 },
          fontWeight: "medium",
          mb: 1,
        }}
      >
        Địa Chỉ Thường Trú
      </Typography>
      <Grid container spacing={{ xs: 1.5, md: 2 }}>
        <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
          <Controller
            name="employee_country"
            rules={{ required: "Quốc gia là bắt buộc" }}
            control={control}
            render={({ field: { value, onChange } }) => {
              const selected =
                COUNTRIES.find((country) => country.value === value) || null;
              return (
                <FormControl fullWidth>
                  <SingleSelectCustomize
                    value={selected}
                    options={_.uniqBy(COUNTRIES, (item) => item["value"])}
                    input={{
                      placeholder: "Chọn quốc gia...",
                      label: "Quốc gia (*)",
                    }}
                    onChange={(event, newValue) => {
                      onChange(newValue?.value || -1);
                    }}
                  />
                  {!!errors?.employee_country && (
                    <ErrorHelperText
                      message={errors?.employee_country?.message}
                    />
                  )}
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
          <PermanentAddressCity />
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
          <PermanentAddressDistrict />
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
          <PermanentAddressWard />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="employee_permanent_address"
            rules={{ required: "Nguyên quán là bắt buộc" }}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    value={value}
                    label="[Số Nhà/Căn Hộ] - [Tên Đường/Hẻm/Ngõ] (*)"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={onChange}
                    size="small"
                    placeholder="Nguyên quán..."
                    error={!!errors?.employee_permanent_address}
                    helperText={errors?.employee_permanent_address?.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="employee_temporary_address_open"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <FormControlLabel
                    label="Địa chỉ tạm trú"
                    control={
                      <Switch
                        size="small"
                        value={value}
                        onChange={onChange}
                      />
                    }
                    sx={{
                      pl: 1,
                    }}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>

      </Grid>
      {!!watch(`employee_temporary_address_open`) && (
        <Box fullWidth>
          <EmployeeTemporaryAddress />
        </Box>
      )}
    </Box>
  );
}

export default EmployeePermanentAddress