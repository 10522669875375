import ProductApi from "@/apis/beta/Product/ProductApi";
import withSuspense from "@/hocs/withSuspense";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { FormControl } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const ProductAddon = ({ service }) => {
	const { data: product } = ProductApi.Detail({
		id: service?.service_product_id,
	});
	const {
		control,
		setValue,
		formState: { errors },
	} = useFormContext();

	const onChangeProduct = async (productId) => {
		const addon = product?.product_addons?.find(
			(x) => x?.product_addon_id === productId
		);
		setValue("service_product_id", productId);
		setValue("service_product_name", addon?.product_name);
		setValue("service_name", addon?.product_name);
		setValue("service_amount", 0);
		setValue("service_billing_cycle", "");
		setValue("service_start_date", moment());
		setValue("service_status", "payment-pending");
		setValue("service_tax", true);
		setValue("service_addon_qty", 1);
		setValue("service_pricing_apply", addon?.pricing?.pricing);
		setValue("service_pricing_minimum", addon?.pricing?.pricing_minimum);
		setValue("service_price", addon?.pricing?.pricing);
		setValue("service_type", addon?.product_type);

		const response = await ProductApi.GetProductInformations({ id: productId })
		return setValue(`product_configoptions`, response?.map(configoption => _.pick(configoption, ['value', 'name', 'unit'])) || [])
	};
	return (
		<Controller
			name="service_product_id"
			control={control}
			render={({ field: { value, onChange } }) => {
				const selected =
					product?.product_addons?.find(
						(item) => item?.product_addon_id === value
					) || null;
				return (
					<FormControl fullWidth sx={{ mb: 1.5 }}>
						<SingleSelectCustomize
							value={selected}
							options={product?.product_addons || []}
							input={{
								label: "Chọn sản phẩm/ dịch vụ mua thêm",
								placeholder: "Chọn sản phẩm/ dịch vụ chính...",
							}}
							compareAttributes={["product_addon_id", "product_name"]}
							formatCollection={{
								value: "product_addon_id",
								label: "product_name",
							}}
							onChange={(event, newValue) => {
								onChangeProduct(newValue?.product_addon_id || -1);
								return onChange(newValue?.product_addon_id || -1);
							}}
						/>
						{!!errors?.service_name && (
							<ErrorHelperText message={errors?.service_name?.message} />
						)}
					</FormControl>
				);
			}}
		/>
	);
};

export default withSuspense(ProductAddon, MuiSkeleton["TextField"]);
