import { Grid, Paper, Skeleton } from '@mui/material'
import React from 'react'

const StatisticalOne = () => {
    return (
        <Grid item xs={6} md={3}>
            <Paper sx={{ p: 2 }}>
                <Skeleton variant='text' sx={{ mb: 1, width: 120 }} />
                <Skeleton variant='rectangular' sx={{ mb: 1, width: 200, height: 42 }} />
            </Paper>
        </Grid>
    )
}

export default StatisticalOne