import { Box, Skeleton, Paper } from '@mui/material'
import React from 'react'

const PaperCustomize = () => {
    return (
        <Paper sx={{
            p: 2,
            width: '100%',
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column-reverse', md: 'row' },
                gap: 1.5
            }}>
                <Box>
                    <Skeleton variant='text' sx={{
                        fontSize: 24,
                        fontWeight: 'medium',
                        mb: 1,
                        width: 200
                    }} />
                    <Skeleton variant='text' sx={{
                        fontSize: 14,
                        width: 150
                    }} />
                </Box>
                <Skeleton variant='rectangular' sx={{
                    height: 40,
                    width: 40,
                }} />
            </Box>
        </Paper>
    )
}

export default PaperCustomize