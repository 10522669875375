import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { queryClientRefetcher } from "@/functions";
import withPermission from "@/hocs/withPermission";
import { useNotificationContext } from "@contexts/notification";
import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useQueryClient } from "react-query";

const ServiceUpdateMailer = ({ submit = true, setSubmit, serviceId = -1 }) => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();
  const { handleSubmit } = useFormContext();

  const onSubmit = async (values) => {
    setSubmit("submit");
    const response = await ServiceApi.UpdateMailer({
      serviceId,
      data: values,
    });
    setSubmit(null);
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message || "Failed",
        severity: "error",
      });
    }
    queryClientRefetcher(queryClient, ["service.service-mailer"]);
    return setNotification({
      open: true,
      message: "Cập nhật mailer dịch vụ thành công.",
      severity: "success",
    });
  };
  return (
    <Button
      size="small"
      variant="contained"
      onClick={handleSubmit(onSubmit)}
      sx={{
        height: { xs: 36, md: 42 },
        minWidth: 150,
      }}
      disabled={!!submit}
      startIcon={
        submit === "submit" && (
          <CircularProgress size={14} sx={{ color: "#777680" }} />
        )
      }
    >
      Cập nhật thông tin
    </Button>
  );
};

export default withPermission(ServiceUpdateMailer, {
  feature: "service",
  action: "service-mailer-update",
  type: "Button",
});
