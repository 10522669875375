import React from 'react'
import { LinearProgress as MuiLinearProgress } from '@mui/material'

const LinearProgress = () => {
    return (
        <MuiLinearProgress color='inherit'
            sx={{
                position: 'absolute',
                minWidth: '100%'
            }}
        />
    )
}

export default LinearProgress