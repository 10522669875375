import withPermission from '@/hocs/withPermission'
import { Button, CircularProgress } from '@mui/material'

const CreateScope = ({
    submitting = false,
    onSubmit
}) => {
    return (
        <Button size='small' variant='contained'
            disabled={submitting} startIcon={submitting && <CircularProgress size={14} sx={{ color: "#777680" }} />} onClick={onSubmit}>
            Tạo quyền truy cập
        </Button>
    )
}

export default withPermission(CreateScope, { feature: 'scope', action: 'create', type: 'Button' })