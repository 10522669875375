import { INVOICE_STATUS } from '@/libs/constants'
import { Box, Chip, Typography } from '@mui/material'
import React from 'react'

const InvoiceLabel = ({ invoice_id, invoice_status }) => {

    const [status, setStatus] = React.useState(INVOICE_STATUS.find(stt => stt.value === invoice_status))
    React.useEffect(() => {
        setStatus(INVOICE_STATUS.find(stt => stt.value === invoice_status))
    }, [invoice_status])
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 1
        }}>
            <Typography sx={{
                fontSize: 24,
                fontWeight: 'medium',
                mb: 1
            }}>
                #{invoice_id}
            </Typography>
            <Chip
                variant={`customize-${status?.chip || 'default'}`}
                label={status?.name}
                sx={{ textTransform: 'uppercase' }}
            />
        </Box>
    )
}

export default InvoiceLabel