import { SearchOutlined } from '@mui/icons-material';
import { Box, TextField } from '@mui/material';
import React from 'react';
const Toolbar = ({
    searchQuery,
    setSearchQuery
}) => {

    return (
        <Box sx={{ height: 76, display: 'flex', justifyContent: 'flex-end' }}>
            <TextField
                size="small"
                InputProps={{
                    startAdornment: <SearchOutlined sx={{ color: '#bfbfbf', mr: 1 }} />,
                }}
                value={searchQuery}
                onChange={(event) => setSearchQuery(event?.target?.value)}
                sx={{ m: 'auto 16px', width: 300 }}
                placeholder='Nhập ID/ Tên dịch vụ để tìm kiếm...'
            />
        </Box>
    )
}

export default Toolbar