import PopperAction from "@components/PopperAction";
import MoreVertOutlined from "@mui/icons-material/MoreVertOutlined";
import { Box, Drawer, IconButton, Popper } from "@mui/material";
import React, { Fragment, useMemo, useState } from "react";
import ConvertCustomer from "./ConvertCustomer";
import ConvertHR from "./ConvertHR";
import Detail from "../../Detail";
import MarkSpam from "./Markspam";

const Actions = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [action, setAction] = useState({
    open: false,
    name: undefined,
    data: undefined,
  });

  const onCloseAction = () =>
    setAction({ open: false, name: undefined, data: undefined });
  const [actions] = useState([
    {
      order: 1,
      name: "detail",
      label: "Xem",
      icon: "See",
      handleClick: (data) => {
        setAction((prev) => ({
          ...prev,
          open: true,
          data: data,
          name: "detail",
        }));
        setAnchorEl(undefined);
      },
    },
    {
      order: 2,
      name: "convert-to-hr",
      label: "Convert to HR",
      icon: "EmotionSentiment",
      handleClick: (data) => {
        setAction((prev) => ({
          ...prev,
          open: true,
          data: data,
          name: "convert-to-hr",
        }));
        setAnchorEl(undefined);
      },
    },
    {
      order: 4,
      name: "mark-spam",
      label: "Đánh Dấu Spam",
      icon: "PersonDelete",
      handleClick: (data) => {
        setAction((prev) => ({
          ...prev,
          open: true,
          data: data,
          name: "mark-spam",
        }));
        setAnchorEl(undefined);
      },
    },
    {
      order: 3,
      name: "convert-to-customer",
      label: "Convert to Customer",
      icon: "EmotionSentiment",
      handleClick: (data) => {
        setAction((prev) => ({
          ...prev,
          open: true,
          name: "convert-to-customer",
        }));
        setAnchorEl(undefined);
      },
    },
  ]);
  const contactActions = useMemo(() => {
    let items = actions;
    if (
      data?.contact_source_status !== "" &&
      data?.contact_source_status !== "UNVERIFIED"
    ) {
      items = items?.filter((act) => act?.name !== "convert-to-hr");
    }
    if (
      data?.contact_source_status !== "MATCHED" ||
      (data?.contact_source_status === "MATCHED" &&
        data?.contact_source_referrer_id > 1 &&
        data?.contact_source_referrer_type === "Reducer::Customer")
    ) {
      items = items?.filter((act) => act?.name !== "convert-to-customer");
    }
    if (
      data?.contact_source_status === "MATCHED" ||
      data?.contact_source_status === "HR"
    ) {
      items = items?.filter((act) => act?.name !== "mark-spam");
    }

    return items;
  }, [actions, data]);

  return (
    <Fragment>
      {action?.open && action?.name === "convert-to-customer" && (
        <ConvertCustomer data={data} handleClose={onCloseAction} />
      )}
      {action?.open && action?.name === "convert-to-hr" && (
        <ConvertHR data={data} handleClose={onCloseAction} />
      )}
      {action?.open && action?.name === "mark-spam" && (
        <MarkSpam data={data} handleClose={onCloseAction} />
      )}
      {action?.open && action?.name === "detail" && (
        <Drawer
          anchor="right"
          open={true}
          onClose={onCloseAction}
          sx={{
            width: { xs: "100%", md: 300 },
            borderTopLeftRadius: "4px",
          }}
        >
          <Detail contactId={data?.contact_source_id} onClose={onCloseAction} />
        </Drawer>
      )}
      {anchorEl !== undefined && (
        <Popper
          anchorEl={anchorEl}
          sx={{
            zIndex: 99,
          }}
          open={Boolean(anchorEl)}
          onMouseLeave={() => setAnchorEl(undefined)}
        >
          <PopperAction
            actions={contactActions || []}
            handleClose={onCloseAction}
          />
        </Popper>
      )}
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <IconButton
          size="small"
          onClick={(event) => setAnchorEl(event?.currentTarget)}
        >
          <MoreVertOutlined fontSize="small" />
        </IconButton>
      </Box>
    </Fragment>
  );
};

export default Actions;
