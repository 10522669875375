import { INITIAL_CONDITIONS } from "@/libs/constants"
import { ButtonGroup, Button, Box, Typography } from "@mui/material";

const ConditionGroupButton = ({
    currentFilter,
    condition,
    query,
    handleChangeQuery
}) => {
    const currentQuery = query
    const handleChangeCondition = () => {
        const keyQuery = Object.keys(currentFilter)[0]
        const objectOperatorQuery = Object.values(currentFilter)[0]
        const keyOperator = Object.keys(objectOperatorQuery)[0]
        const valueQuery = Object.values(objectOperatorQuery)[0];
        const currentConditionFilters = Object.values(currentQuery[condition])
        const currentFilterDelIndex = currentConditionFilters?.findIndex(fil =>
            Object.keys(fil)[0] === keyQuery &&
            Object.keys(Object.values(fil)[0])[0] === keyOperator &&
            Object.values(Object.values(fil)[0])[0] === valueQuery
        )
        if (currentFilterDelIndex <= -1) return;
        currentConditionFilters.splice(currentFilterDelIndex, 1);
        const filters = { ...currentQuery, [condition]: currentConditionFilters }

        let hash = {}
        hash[keyQuery] = objectOperatorQuery
        const newHash = {
            condition: condition === '$or' ? '$and' : '$or',
            filter: hash
        }
        const filter = Object.assign(currentQuery[newHash?.condition] || {}, {});
        filter[Object.keys(filter).length] = newHash.filter;

        const newFilters = { ...filters, [newHash.condition]: filter };
        return handleChangeQuery(newFilters)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <ButtonGroup variant="outlined"
                sx={{
                    height: 32
                }}
            >
                {INITIAL_CONDITIONS.map((cod, index) =>
                    <Button
                        key={index}
                        onClick={cod?.name === condition ?
                            undefined :
                            handleChangeCondition}
                        sx={cod?.name === condition ? {
                            bgcolor: '#4C57A945',
                            ":hover": {
                                bgcolor: "#4C57A945"
                            }
                        } : {
                            color: '#000',
                            ":hover": {
                                bgcolor: "#4C57A945"
                            }
                        }}>
                        <Typography sx={{
                            fontSize: 12,
                            textTransform: "lowercase",
                            fontWeight: cod?.name === condition && "medium"
                        }}>{cod?.label}</Typography>
                    </Button>
                )}
            </ButtonGroup>
        </Box>

    )
}
export default ConditionGroupButton