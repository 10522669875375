import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Fragment } from "react";
import ServiceSystemInformation from "./ServiceSystem";
import ServiceMailer from "./MailerConfigure";
import MailerList from "./MailerList";
import { useParams } from "react-router-dom";

const Technical = () => {
  const { service_id: serviceId } = useParams();
  return (
    <Fragment>
      <ServiceMailer />
      <ServiceSystemInformation />
      <MailerList serviceId={serviceId} />
    </Fragment>
  );
};

export default withSuspense(
  withPermission(Technical, {
    feature: "service",
    action: "service-mailer-index",
  }),
  MuiSkeleton["GridInformation"]
);
