import { requestWithToken, encodeBase64 } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class PositionApi {

    ListPositions = ({ query = null }) => {
        return useQueryWithCustomized(
            ["positions.list", query],
            async () => {
                return await requestWithToken(
                    `${API_V3_URL}/positions/list${query ? `?filters=${encodeBase64(query)}` : ''}`
                )
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data?.positions;
                    })
                    .catch((error) => {
                        return error.response;
                    })
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
                enabled: !!localStorage.getItem("SA"),
            }
        );
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PositionApi();
