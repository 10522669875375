import { Box, FormControl, TextField } from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";

const Metadata = () => {
  const { control, watch } = useFormContext();

  const productInformations = useWatch({
    control: control,
    name: "product_informations",
  });

  if (productInformations?.length === 0) return null;

  const SERVICE_METADATA_FIELDS = [
    {
      key: "service_extra_fields.domain",
      enabled: watch(`product_template`)?.match(/(Email|Hosting|mail|Mail)/),
      label: "Domain (nếu có) *",
    },
    {
      key: "service_extra_fields.ip",
      enabled: watch(`product_template`)?.match(/(CloudServer)/),
      label: "IP (nếu có) *",
    },
    {
      key: "service_extra_fields.name",
      enabled: watch(`product_template`)?.match(/(SwiftFederation|VNIS)/),
      label: "Entity name (nếu có) *",
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { sx: "column", md: "row" },
        gap: 1.5,
        mt: 1.5,
      }}
    >
      {SERVICE_METADATA_FIELDS?.map((field, index) => {
        if (!field?.enabled) return null;
        return (
          <Controller
            key={index}
            name={field?.key}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    label={field?.label}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    value={value}
                    onChange={onChange}
                    placeholder="Nhập thông tin..."
                  />
                </FormControl>
              );
            }}
          />
        );
      })}
    </Box>
  );
};

export default Metadata;
