import Revenue from "./Revenue";

const ContactRevenue = ({ data }) => {
  if (
    !data?.contact_source_referrer_id ||
    data?.contact_source_referrer_type !== "Reducer::Customer"
  ) {
    return null;
  }
  return <Revenue data={data} />;
};

export default ContactRevenue;
