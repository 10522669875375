import DatePicker from '@components/DatePicker'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import { Box, FormControl, Typography } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

const InvoicePaid = () => {
    const { control, formState: { errors } } = useFormContext()
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 1,
            justifyContent: 'space-between',
        }}>
            <Box sx={{
                display: 'flex',
                width: { xs: '100%', md: '40%' }
            }}>
                <Typography sx={{
                    fontSize: 14,
                    color: "#777680",
                    m: 'auto 0'
                }}>
                    Ngày Thanh Toán *
                </Typography>
            </Box>
            <Controller
                name="invoice_paid"
                control={control}
                render={({ field: { value, onChange } }) => {
                    return (
                        <FormControl fullWidth>
                            <DatePicker
                                value={value}
                                onChange={onChange}
                            />
                            {!!errors?.invoice_paid && <ErrorHelperText message={errors?.invoice_paid?.message} />}
                        </FormControl>
                    )
                }} />
        </Box>
    )
}

export default InvoicePaid