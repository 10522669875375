import { UNLIMITED } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import { Box, FormControl, TextField, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const MailStorage = () => {
    const { control, setValue, watch } = useFormContext()

    useEffect(() => {
        const price = parseFloat(watch(`service_price`)) || 0
        const qty = parseFloat(watch(`service_addon_qty`)) || 0
        if (qty >= UNLIMITED) {
            return setValue(`service_amount`, price)
        }
        setValue(`service_amount`, parseFloat(price * qty))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch(`service_addon_qty`), watch(`service_price`)])
    return (
        <>
            <Controller
                control={control}
                name="qty.storage"
                render={({ field: { value, onChange } }) => {
                    const isUnlimited = parseFloat(value) >= UNLIMITED;
                    return (
                        <FormControl fullWidth>
                            <TextField
                                size='small'
                                label="Dung lượng mua thêm/ Storage *"
                                InputLabelProps={{ shrink: true }}
                                value={isUnlimited ? "UNLIMITED" : value}
                                onChange={onChange}
                                InputProps={{
                                    sx: {
                                        paddingRight: 0,
                                    },
                                    inputComponent: isUnlimited ? "input" : NumericFormatCustom,
                                    endAdornment: <Box sx={{
                                        display: "flex",
                                        height: "40px",
                                        width: isUnlimited ? "calc(100%)" : "calc(100% / 2)",
                                        transition: "width 0.75s ease-in-out",
                                        bgcolor: isUnlimited ? "#2e7d321F" : "rgba(0, 0, 0, 0.18)",
                                        borderTopRightRadius: "4px",
                                        borderBottomRightRadius: "4px",
                                        cursor: "pointer",
                                        alignItems: "center"
                                    }}
                                        onClick={() => {
                                            if (parseFloat(value) >= UNLIMITED) {
                                                return;
                                            }
                                            onChange(UNLIMITED)
                                        }}
                                    >
                                        <Typography sx={{
                                            m: 'auto 8px auto auto',
                                            fontSize: 13,
                                            textTransform: 'uppercase',
                                            color: value === UNLIMITED ? green[800] : "#777680"
                                        }}>
                                            Unlimited
                                        </Typography>
                                    </Box>
                                }}
                                placeholder='Nhập số lượng mua...'
                            />
                        </FormControl>)
                }
                }
            />
            <Controller
                control={control}
                name="qty.account"
                render={({ field: { value, onChange } }) => {
                    const isUnlimited = parseFloat(value) >= UNLIMITED;
                    return (
                        <FormControl fullWidth>
                            <TextField
                                size='small'
                                label="Số Lượng Account *"
                                InputLabelProps={{ shrink: true }}
                                value={isUnlimited ? "UNLIMITED" : value}
                                onChange={onChange}
                                InputProps={{
                                    sx: {
                                        paddingRight: 0,
                                    },
                                    inputComponent: isUnlimited ? "input" : NumericFormatCustom,
                                    endAdornment: <Box sx={{
                                        display: "flex",
                                        height: "40px",
                                        width: isUnlimited ? "calc(100%)" : "calc(100% / 2)",
                                        transition: "width 0.75s ease-in-out",
                                        bgcolor: isUnlimited ? "#2e7d321F" : "rgba(0, 0, 0, 0.18)",
                                        borderTopRightRadius: "4px",
                                        borderBottomRightRadius: "4px",
                                        cursor: "pointer",
                                        alignItems: "center"
                                    }}
                                        onClick={() => {
                                            if (parseFloat(value) >= UNLIMITED) {
                                                return;
                                            }
                                            onChange(UNLIMITED)
                                        }}
                                    >
                                        <Typography sx={{
                                            m: 'auto 8px auto auto',
                                            fontSize: 13,
                                            textTransform: 'uppercase',
                                            color: value === UNLIMITED ? green[800] : "#777680"
                                        }}>
                                            Unlimited
                                        </Typography>
                                    </Box>
                                }}
                                placeholder='Nhập số lượng mua...'
                            />
                        </FormControl>)
                }
                }
            />
        </>
    )
}

export default MailStorage