import MuiScrollTabs from "@/components/shared/MuiScrollTabs";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import HealthAndSafetyOutlinedIcon from "@mui/icons-material/HealthAndSafetyOutlined";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import TodayIcon from "@mui/icons-material/Today";
import AddCardIcon from "@mui/icons-material/AddCard";
import React from "react";

const EmployeeTabs = () => {
  const initialTabs = [
    {
      value: "account-profile",
      label: "Thông Tin Cá Nhân",
      href: "",
      icon: ContactPageOutlinedIcon,
    },
    {
      value: "employee-dayoffs",
      label: "Nghỉ Phép",
      href: "dayoffs",
      icon: TextSnippetOutlinedIcon,
    },
    {
      value: "customer-business",
      label: "Công Tác",
      href: "businesses",
      icon: WorkHistoryOutlinedIcon,
    },
    {
      value: "employee-work-contract",
      label: "Hợp Đồng",
      href: "work-contracts",
      hidden: true,

      icon: AssignmentOutlinedIcon,
    },

    {
      value: "banking",
      label: "Thuế & Ngân Hàng",
      hidden: true,
      href: "bankings",
      icon: AccountBalanceWalletOutlinedIcon,
    },
    {
      value: "insurance",
      label: "Thông Tin Bảo Hiểm",
      hidden: true,
      href: "insurances",
      icon: HealthAndSafetyOutlinedIcon,
    },

    {
      value: "experience",
      label: "Chuyên Ngành & Kinh Nghiệm",
      href: "experiences",
      icon: EngineeringOutlinedIcon,
      hidden: true,
    },
    {
      value: "contact-homies",
      label: "Thông Tin Liên Lạc",
      href: "contact-homies",
      hidden: true,
      icon: ContactPhoneOutlinedIcon,
    },
    {
      value: "change-password",
      label: "Đổi Mật Khẩu",
      href: "change-password",
      icon: KeyOutlinedIcon,
    },
    {
      value: "workdays",
      label: "Ngày Công",
      href: "workdays",
      icon: TodayIcon,
    },
    {
      value: "vcard",
      label: "Thẻ Vcard",
      href: "vcard",
      icon: AddCardIcon,
    },
  ];
  return (
    <MuiScrollTabs
      prefix={`account`}
      initialTabs={initialTabs.filter((item) => !item?.hidden)}
    />
  );
};

export default EmployeeTabs;
