import { Box } from "@mui/material";
import React from "react";
import CustomerHeader from "../CustomerHeader";
import CustomerTabs from "../CustomerTabs";

const Layout = ({ children }) => {
  return (
    <Box>
      <CustomerHeader />
      <CustomerTabs />
      {children}
    </Box>
  );
};

export default Layout;
