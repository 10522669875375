import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class ProductAddonApi {
  All = () => {
    return useQueryWithCustomized(
      ["product.all"],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/products/1/addons?view=all`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.products || [];
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
        enabled: !!localStorage.getItem("SA"),
      }
    );
  };

  List = ({ productId = -1 }) => {
    return useQueryWithCustomized(
      ["product.addons", productId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/products/${productId}/addons`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.products || [];
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
        enabled: !!localStorage.getItem("SA"),
      }
    );
  };

  Create = async ({ productId = -1, data }) => {
    return await requestWithToken(
      `${API_V3_URL}/products/${productId}/addons`,
      "POST",
      {
        addon: data,
      }
    )
      .then(({ data }) => {
        if (data?.errors) return data;
        return data?.data?.product;
      })
      .catch((error) => {
        return error?.response;
      });
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ProductAddonApi();
