import BreadCrumb from "@components/shared/BreadCrumb";
import { Typography, Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router";

const Header = () => {
  const { employee_id } = useParams();

  return (
    <Box>
      <Typography className="page-title">Chỉnh Sửa Phép Năm</Typography>
      <BreadCrumb
        data={[
          { label: "Danh Sách Nhân Viên", reactLink: "/employees" },
          { label: employee_id },
          { label: "Chỉnh Sửa Phép Năm" },
        ]}
      />
    </Box>
  );
};

export default Header;
