import {
  DeleteOutlineOutlined,
  EditOutlined,
  FileDownloadOutlined,
  MailOutlined,
  PersonRemoveOutlined,
  VisibilityOutlined,
  ContentCopyOutlined,
  AddReactionRounded,
  PriceChangeOutlined,
  NotStartedOutlined,
  ManageHistoryOutlined,
  MoreTimeOutlined,
  Diversity1Outlined,
  ReceiptOutlined,
  DisplaySettingsOutlined,
  LockOpenOutlined,
  FeedOutlined,
  BallotOutlined,
  AssessmentOutlined,
  EditNoteOutlined,
} from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import React from "react";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Link } from "react-router-dom";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import _ from "lodash";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import AdminPanelSettingsOutlined from "@mui/icons-material/AdminPanelSettingsOutlined";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ShareIcon from "@mui/icons-material/Share";
import AddOutlined from "@mui/icons-material/AddOutlined";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import EventOutlined from "@mui/icons-material/EventOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const ICONS = {
  Default: {
    icon: HelpOutlineOutlinedIcon,
    color: "error",
  },
  Date: {
    icon: ManageHistoryOutlined,
    color: "success",
  },
  Diverity: {
    icon: Diversity1Outlined,
    color: "primary",
  },
  MoreTime: {
    icon: MoreTimeOutlined,
    color: "primary",
  },
  Add: {
    icon: AddOutlined,
    color: "success",
  },
  Edit: {
    icon: EditOutlined,
    color: "primary",
  },
  Delete: {
    icon: DeleteOutlineOutlined,
    color: "error",
  },
  See: {
    icon: VisibilityOutlined,
    color: "primary",
  },
  PersonDelete: {
    icon: PersonRemoveOutlined,
    color: "error",
  },
  Mail: {
    icon: MailOutlined,
    color: "",
  },
  Download: {
    icon: FileDownloadOutlined,
    color: "primary",
  },
  Renew: {
    icon: ReplayOutlinedIcon,
    color: "primary",
  },
  Admin: {
    icon: AdminPanelSettingsOutlined,
    color: "primary",
  },
  Copy: {
    icon: ContentCopyOutlined,
    color: "primary",
  },
  EmotionSentiment: {
    icon: AddReactionRounded,
    color: "primary",
  },
  DriveFileMoveIcon: {
    icon: DriveFileMoveIcon,
    color: "warning",
  },
  DriveFileRenameOutlineIcon: {
    icon: DriveFileRenameOutlineIcon,
    color: "primary",
  },
  ShareIcon: {
    icon: ShareIcon,
    color: "primary",
  },
  FolderZipIcon: {
    icon: FolderZipIcon,
    color: "secondary",
  },
  Verified: {
    icon: VerifiedUserOutlinedIcon,
    color: "primary",
  },
  Price: {
    icon: PriceChangeOutlined,
    color: "primary",
  },
  Money: {
    icon: PriceChangeOutlined,
    color: "primary",
  },
  Stop: {
    icon: NotStartedOutlined,
    color: "error",
  },
  Receipt: {
    icon: ReceiptOutlined,
    color: "primary",
  },
  Setting: {
    icon: DisplaySettingsOutlined,
    color: "primary",
  },
  DateEvent: {
    icon: EventOutlined,
    color: "primary",
  },
  UnLock: {
    icon: LockOpenOutlined,
    color: "success",
  },
  Contract: {
    icon: FeedOutlined,
    color: "primary",
  },
  PackageConfig: {
    icon: BallotOutlined,
    color: "primary",
  },
  Statement: {
    icon: AssessmentOutlined,
    color: "primary",
  },
  Note: {
    icon: EditNoteOutlined,
    color: "primary",
  },
  InfoIcon: {
    icon: InfoOutlinedIcon,
    color: "secondary",
  },
};

const PopperAction = ({ data, actions = [], handleClose }) => {
  const ref = React.useRef(null);

  useOnClickOutside(ref, handleClose);

  return (
    <Paper ref={ref}>
      <List>
        {_.orderBy(actions, ["order"])?.map((action, index) => {
          const { icon, ...props } = ICONS[action?.icon || "Default"];
          if (!action?.href) {
            const { handleClick, label } = action;
            return (
              <ListItem
                disabled={action?.disabled}
                key={index}
                onClick={() => !action.disabled && handleClick(data)}
                sx={{
                  height: 38,
                  ":hover": { bgcolor: "grey.100", cursor: "pointer" },
                }}
              >
                <ListItemIcon sx={{ minWidth: "30px", mr: 1 }}>
                  {React.createElement(icon, {
                    sx: { fontSize: { xs: 16, md: 20 } },
                    ...props,
                  })}
                </ListItemIcon>
                <ListItemText primary={label} {...props} />
              </ListItem>
            );
          }
          //is href
          const { label, href } = action;
          const referenceLink = [
            href?.prefix || "",
            data?.[`${href?.key}`],
            href?.suffix || "",
          ];
          return (
            <Link key={index} to={referenceLink?.join("")} replace>
              <ListItem
                sx={{
                  height: 38,
                  ":hover": { bgcolor: "grey.100", cursor: "pointer" },
                }}
              >
                <ListItemIcon sx={{ minWidth: "30px", mr: 1 }}>
                  {React.createElement(icon, {
                    sx: { fontSize: { xs: 16, md: 20 } },
                    ...props,
                  })}
                </ListItemIcon>
                <ListItemText primary={label} {...props} />
              </ListItem>
            </Link>
          );
        })}
      </List>
    </Paper>
  );
};

export default PopperAction;
