import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class ServicePriceApi {

    ChangeRegisterPrice = async ({ serviceId, data }) => {
        return await requestWithToken(
            `${API_V3_URL}/services/${serviceId}/prices/register`,
            "PUT",
            {
                service: data,
            },
            {},
            {}
        )
            .then(({ data }) => {
                return { service: data?.data?.service };
            })
            .catch((error) => {
                return error.response;
            });
    };

    ChangePriceRecurring = async ({ serviceId, data }) => {
        return await requestWithToken(
            `${API_V3_URL}/services/${serviceId}/prices/recurring`,
            "PUT",
            {
                service: data,
            },
            {},
            {}
        )
            .then(({ data }) => {
                return { service: data?.data?.service };
            })
            .catch((error) => {
                return error.response;
            });
    };

    ChangePackageConfig = async ({ serviceId, data = [] }) => {
        return await requestWithToken(
            `${API_V3_URL}/services/${serviceId}/prices/package_configs`,
            "PUT",
            {
                service: {
                    configoptions: data || []
                },
            },
            {},
            {}
        )
            .then(({ data }) => {
                return { service: data?.data?.service };
            })
            .catch((error) => {
                return error.response;
            });
    };
    ChangePackagePrices = async ({ serviceId, data = [] }) => {
        return await requestWithToken(
            `${API_V3_URL}/services/${serviceId}/prices/package_prices`,
            "PUT",
            {
                service: {
                    configoptions: data || []
                },
            },
            {},
            {}
        )
            .then(({ data }) => {
                return { service: data?.data?.service };
            })
            .catch((error) => {
                return error.response;
            });
    };

    ChangeServiceConfig = async ({ serviceId, data = [] }) => {
        return await requestWithToken(
            `${API_V3_URL}/services/${serviceId}/prices/service_configs`,
            "PUT",
            {
                service: data
            },
            {},
            {}
        )
            .then(({ data }) => {
                return { service: data?.data?.service };
            })
            .catch((error) => {
                return error.response;
            });
    };

    ChangeServicePrices = async ({ serviceId, data = [] }) => {
        return await requestWithToken(
            `${API_V3_URL}/services/${serviceId}/prices/service_prices`,
            "PUT",
            {
                service: data
            },
            {},
            {}
        )
            .then(({ data }) => {
                return { service: data?.data?.service };
            })
            .catch((error) => {
                return error.response;
            });
    };

    PaymentConfig = ({ serviceId = -1 }) => {
        return useQueryWithCustomized(
            ['services', 'payment-configs', serviceId],
            async () => {
                return await requestWithToken(`${API_V3_URL}/services/${serviceId}/payment`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return { payment: data?.data?.payment || {} }
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            })
    }

    UpdatePaymentConfig = async ({ serviceId = -1, name = '' }) => {

        return await requestWithToken(`${API_V3_URL}/services/${serviceId}/payment`, 'PUT', {
            service: {
                payment_config_optional: name
            }
        })
            .then(({ data }) => {
                if (data?.error) return data;
                return { payment: data?.data?.payment || {} }
            })
            .catch((error) => {
                return error.response;
            });
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ServicePriceApi();
