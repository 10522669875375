import { Checkbox, FormControl, FormControlLabel } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ScopeWritable = () => {
    const { control } = useFormContext({})
    return (
        <Controller
            name='scope_writable'
            control={control}
            render={({ field: { value, onChange } }) => (
                <FormControl>
                    <FormControlLabel
                        label="Chỉnh sửa"
                        control={
                            <Checkbox
                                checked={value}
                                onChange={onChange}
                                size='small' />} />
                </FormControl>
            )}
        />
    )
}

export default ScopeWritable