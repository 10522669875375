import SandboxApi from '@/apis/beta/Sandbox/SandboxApi'
import withSuspense from '@/hocs/withSuspense'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { FormControl } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const PermanentAddressWard = () => {

    const { control, formState: { errors }, watch } = useFormContext()

    const [wards] = SandboxApi.Wards(watch(`employee_permanent_address_city`), watch(`employee_permanent_address_district`))

    return (
        <Controller
            name="employee_permanent_address_ward"
            control={control}
            rules={{ required: "Phường/ xã là bắt buộc" }}
            render={({ field: { value, onChange } }) => {
                const selected = wards?.find(country => country.value === value) || null
                return (
                    <FormControl fullWidth>
                        <SingleSelectCustomize
                            value={selected}
                            disabled={!watch(`employee_permanent_address_district`) || watch(`employee_permanent_address_district`) === -1}
                            options={_.uniqBy(wards, item => item["value"])}
                            input={{
                                placeholder: "Chọn phường/ xã...",
                                label: "Phường/ Xã (*)"
                            }}
                            compareAttributes={["label", "value"]}
                            onChange={(event, newValue) => {
                                onChange(newValue?.value || -1)
                            }}
                        />
                        {!!errors?.employee_permanent_address_ward && <ErrorHelperText
                            message={errors?.employee_permanent_address_ward?.message}
                        />}
                    </FormControl>
                )
            }} />
    )
}

export default withSuspense(PermanentAddressWard, MuiSkeleton["TextField"])