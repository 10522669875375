import Location from "@/components/Location"
import { Box, FormControl, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const DefaultDeclaration = () => {
    const { control, watch, formState: { errors } } = useFormContext()

    return (
        <Box>
            <Typography sx={{ fontSize: 14, fontWeight: "medium", mb: 1.5 }}>
                Thông tin khách hàng
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="ownerName"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Tên Khách Hàng (cá nhân/ công ty) *"
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors?.domain?.domain_extra_fields?.ownerName}
                                        helperText={errors?.domain?.domain_extra_fields?.ownerName?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    {watch("for") === "congty" ? (
                        <Controller
                            name="ownerTaxCode"
                            control={control}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <FormControl fullWidth>
                                        <TextField
                                            size='small'
                                            label="Mã Số Thuế *"
                                            InputLabelProps={{ shrink: true }}
                                            value={value}
                                            onChange={onChange}
                                            placeholder="Nhập thông tin mã số thuế..."
                                            error={!!errors?.domain?.domain_extra_fields?.ownerTaxCode}
                                            helperText={errors?.domain?.domain_extra_fields?.ownerTaxCode?.message}
                                        />
                                    </FormControl>
                                )
                            }} />
                    )
                        : <Controller
                            name="ownerID_Number"
                            control={control}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <FormControl fullWidth>
                                        <TextField
                                            size='small'
                                            label="CMND/CCCD *"
                                            placeholder='Nhập CMND/ CCCD...'
                                            InputLabelProps={{ shrink: true }}
                                            value={value}
                                            onChange={onChange}
                                            error={!!errors?.domain?.domain_extra_fields?.ownerID_Number}
                                            helperText={errors?.domain?.domain_extra_fields?.ownerID_Number?.message}
                                        />
                                    </FormControl>
                                )
                            }} />
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name="ownerCountry"
                        render={({ field: { value, onChange } }) => {
                            console.log(value)
                            return (
                                <Location.Country
                                    value={value || null}
                                    onChange={(event, newValue) => onChange(newValue)}
                                    error={{
                                        isError: false,
                                        message: ""
                                    }}
                                />
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name="ownerProvince"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Location.Province
                                    preSelected={value?.value}
                                    country={watch(`ownerCountry`)?.value || '-1'}
                                    onChange={(event, newValue) => onChange(newValue)}
                                    error={{
                                        isError: false,
                                        message: ""
                                    }}
                                />
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name="ownerDistrict"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Location.District
                                    preSelected={value?.value}
                                    province={watch(`ownerProvince`)?.value || '-1'}
                                    onChange={(event, newValue) => onChange(newValue)}
                                    error={{
                                        isError: false,
                                        message: ""
                                    }}
                                />
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name="ownerWard"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Location.Ward
                                    preSelected={value?.value}
                                    province={watch(`ownerProvince`)?.value || '-1'}
                                    district={watch(`ownerDistrict`)?.value || '-1'}
                                    onChange={(event, newValue) => onChange(newValue)}
                                    error={{
                                        isError: false,
                                        message: ""
                                    }}
                                />
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Controller
                        control={control}
                        name="ownerAddress"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Location.Address
                                    value={value}
                                    onChnage={onChange}
                                    preSelected={value?.value}
                                    error={{
                                        isError: false,
                                        message: ""
                                    }}
                                />
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name='ownerEmail1'
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Email Chính *"
                                        placeholder='Nhập email chính của khách hàng...'
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors?.ownerEmail1}
                                        helperText={errors?.ownerEmail1?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name='ownerEmail2'
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Email Phụ"
                                        placeholder='Nhập email phụ...'
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors?.ownerEmail2}
                                        helperText={errors?.ownerEmail2?.message} />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name='ownerPhone'
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Số Điện Thoại * "
                                        placeholder='Nhập số điện thoại...'
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors?.ownerPhone}
                                        helperText={errors?.ownerPhone?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name='ownerFax'
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Số FAX"
                                        placeholder='Số FAX...'
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default DefaultDeclaration