import ServiceApi from "@/apis/beta/Service/ServiceApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import TechnicalSetting from "./Technical";
import ServiceTemplates from "./Templates";

const ServiceInformationAndFields = () => {
  const { service_id: id } = useParams();

  const { data } = ServiceApi.Detail({ id });

  const serviceConfigTemplate = () => {
    const Template =
      ServiceTemplates[data?.service_creatable_type || "Default"] || null;
    return React.createElement(Template || ServiceTemplates["Default"], {
      serviceId: id,
    });
  };
  return (
    <Fragment>
      {serviceConfigTemplate()}
      <TechnicalSetting service={data} />
    </Fragment>
  );
};

export default withSuspense(
  withPermission(ServiceInformationAndFields, {
    feature: "service",
    action: "informations",
  }),
  MuiSkeleton["Loading"]
);
