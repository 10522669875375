import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { FormControl } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import ErrorHelperText from "@/components/shared/ErrorHelperText"

export const PRIVATE_DOMAIN_BILLING_CYCLE = [
    { value: "annually", label: "1 năm" },
    { value: "biennially", label: "2 năm" },
    { value: "triennially", label: "3 năm" },
    { value: "quadrennial", label: "4 năm" },
    { value: "quinquennial", label: "5 năm" },
    { value: "sexennial", label: "6 năm" },
    { value: "septennial", label: "7 năm" },
    { value: "octennial", label: "8 năm" },
    { value: "novennial", label: "9 năm" },
    { value: "decennial", label: "10 năm" },
]

const DomainBillingCycle = () => {
    const { control, formState: { errors } } = useFormContext()

    return (
        <Controller
            name="product_billing_cycle"
            control={control}
            render={({ field: { value, onChange } }) => {
                const selected = PRIVATE_DOMAIN_BILLING_CYCLE.find(x => x.value === value) || null
                return (
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <SingleSelectCustomize
                            options={PRIVATE_DOMAIN_BILLING_CYCLE}
                            value={selected}
                            input={{
                                label: "Chọn thời hạn *",
                                placeholder: "Chọn thời hạn đăng ký..."
                            }}
                            onChange={(event, newValue) => onChange(newValue?.value || "")}
                        />
                        {!!errors?.product_billing_cycle &&
                            <ErrorHelperText message={errors?.product_billing_cycle?.message} />}
                    </FormControl>
                )
            }}
        />
    )
}

export default DomainBillingCycle