import {
  format_date_monent_js,
  format_date_short,
  queryClientRefetcher,
} from "@/functions";
import { CUSTOMER_JOURNEYS, CUSTOMER_JOURNEY_STATUS, REACT_APP_DOMAIN_FILE } from "@/libs/constants";
import SingleDocument from "@components/shared/SingleDocument";
import { usePreviewFileContext } from "@contexts/preview-file";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  IconButton,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Metadata from "../Metadata";
import MoreVertOutlined from "@mui/icons-material/MoreVertOutlined";
import PopperAction from "@components/PopperAction";
import JourneyUpdateApi from "@/apis/beta/Customer/Journey/JourneyUpdateApi";
import { useNotificationContext } from "@contexts/notification";
import { useQueryClient } from "react-query";

const JourneyUpdateOne = ({ item, customerId, journeyId }) => {
  const { setNotification } = useNotificationContext();
  const preview = usePreviewFileContext();

  const queryClient = useQueryClient();

  const openAttachment = async (path) => {
    preview.showNotUseDrivePath(`${REACT_APP_DOMAIN_FILE}${path.replace("pub/", "")}`);
  };

  const [submit, setSubmit] = useState(false);

  const [state, setState] = useState(item?.journey_update_note || "");

  const [popper, setPopper] = useState({ el: null, action: undefined });

  const onSubmit = async () => {
    setSubmit(true);

    const response = await JourneyUpdateApi.Update({
      customerId,
      journeyId,
      journeyUpdateId: item?.journey_update_id,
      data: {
        journey_update_note: state,
      },
    });
    setSubmit(false);
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }

    queryClientRefetcher(queryClient, ["customers.journeys.updates"]);
    setPopper({ el: null, action: undefined });

    return setNotification({
      open: true,
      message: "Chỉnh sửa thông tin thành công.",
      severity: "success",
    });
  };
  return (
    <Box
      sx={{
        p: "4px 8px",
        border: "1px solid rgba(0, 0, 0, 0.18)",
        borderRadius: "4px",
        mb: 0.5,
      }}
    >
      {Boolean(popper?.el) && (
        <Popover anchorEl={popper?.el} open={Boolean(popper?.el)}>
          <PopperAction
            actions={[
              {
                order: 1,
                icon: "Edit",
                label: "Chỉnh Sửa",
                handleClick: () => {
                  try {
                    setPopper({ el: undefined, action: "update" });
                  } catch (error) {
                    console.log("failed in remove");
                  }
                },
              },
            ]}
            handleClose={() => setPopper({ el: undefined, action: null })}
            data={item}
          />
        </Popover>
      )}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: 13 }} component="span" color="text.grey">
          {format_date_short(item?.journey_update_transaction_at)}
          {", "}
          {
            CUSTOMER_JOURNEYS?.find(
              (x) => x?.value === item?.journey_update_step
            )?.label
          }
          {", "}
          {
            CUSTOMER_JOURNEY_STATUS?.find(
              (x) => x?.value === item?.journey_update_status
            )?.label
          }
        </Typography>
        <IconButton
          size="small"
          onClick={(event) => setPopper({ el: event?.currentTarget })}
        >
          <MoreVertOutlined fontSize="small" />
        </IconButton>
      </Box>
      <Collapse in={popper?.action === "update"}>
        <Box component="form" sx={{ mb: 1.25, mt: 1.25 }}>
          <FormControl fullWidth sx={{ pr: { xs: 0, md: 3 } }}>
            <TextField
              size="small"
              value={state}
              onChange={(event) => setState(event?.target?.value)}
              multiline
              label="Ghi chú *"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <Collapse in={state !== item?.journey_update_note}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                pr: { xs: 0, md: 3 },
                mt: 1.25,
              }}
            >
              <Button
                size="small"
                disabled={submit}
                startIcon={
                  submit && (
                    <CircularProgress size={14} sx={{ color: "#777680" }} />
                  )
                }
                variant="contained"
                onClick={onSubmit}
              >
                Lưu Thay Đổi
              </Button>
            </Box>
          </Collapse>
        </Box>
      </Collapse>
      <Typography sx={{ fontSize: 14 }} color="text.black">
        {item?.journey_update_note}
      </Typography>
      {item?.attachments &&
        item?.attachments
          ?.map((attachment) => ({
            type: attachment?.attachment_file_type,
            size: attachment?.attachment_file_size,
            name: attachment?.attachment_file_name,
            url: attachment?.attachment_file_url,
          }))
          ?.map((attachment, index) => (
            <SingleDocument
              handleOpen={() => openAttachment(attachment.url)}
              document={attachment}
              key={index}
            />
          ))}
      {item?.metadata && (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <Typography sx={{ fontSize: 13 }}>Dữ liệu:</Typography>
          <Metadata metadata={item?.metadata} />
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1.25 }}>
        <Typography sx={{ fontSize: 12, fontWeight: "medium" }}>
          {item?.employee_name || ""}
        </Typography>
        <Typography sx={{ fontSize: 12 }} color="text.grey">
          , Cập nhật lúc:{" "}
          {format_date_monent_js(
            item?.journey_update_updated_at,
            "DD/MM/YYYY HH:mm"
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default JourneyUpdateOne;
