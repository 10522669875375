import { Box, Chip, StepLabel, Typography } from '@mui/material'
import React from 'react'
import PrivateIconWithFormat from '../shared/icons'

const StepStart = ({ contractNumber }) => {
    return (
        <React.Fragment>
            <StepLabel
                StepIconProps={{
                    sx: {
                        fontSize: 20,
                        mb: "64px"
                    }
                }}>
                <Chip size='verysmall' variant='customize-error' label="Đã Huỷ" />
                <Box sx={{ display: 'flex', mb: 1 }}>
                    <PrivateIconWithFormat icon="CancelIcon" />
                    <Typography sx={{ m: '0 auto auto 0', flexWrap: 'wrap' }}>
                        Hợp đồng {contractNumber?.contract_number_id} đã bị huỷ.
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', mb: 1 }}>
                    <PrivateIconWithFormat icon="AssigneeIcon" />
                    <Typography sx={{ fontSize: 14, color: "#9e9e9e", m: 'auto 0' }}>
                        Người tiếp nhận: <span style={{ color: "#000" }}>{contractNumber?.employee?.employee_name}</span>
                    </Typography>
                </Box>
            </StepLabel>
        </React.Fragment>
    )
}

export default StepStart