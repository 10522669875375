import { MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { FINAL_STATUS } from '../../shared';
import { useConfirmContext } from '@contexts/confirm';
import { useNotificationContext } from '@contexts/notification';
import CVApi from '@/apis/beta/Employee/CVApi';

const FinalStatus = ({ row, refetch }) => {
  const [state, setState] = useState(row?.final_status);

  const { showConfirmation } = useConfirmContext();
  const { setNotification } = useNotificationContext();

  const update = async (mailTemplate) => {
    setNotification({
      open: true,
      message: "Update successfully",
      severity: "success",
    });

    await CVApi.Accept({
      id: row?.id,
      mail_send: [
        ...(row?.mail_send?.length > 0 ? row?.mail_send : []),
        mailTemplate,
      ],
      mailTemplate,
      final_status: state,
    });

    refetch();
  };

  useEffect(() => {
    if (state === row?.final_status) return

    const mailTemplate = FINAL_STATUS.find(item => item?.value === state)?.mail_template

    if (mailTemplate) {
      return showConfirmation({
        handler: () => update(mailTemplate),
        title: "Thao tác này đi kèm gửi mail",
        open: true,
        description: `Template mail được sử dụng ${mailTemplate}`,
        loading: false,
        onCancel: () => {
          setState(row?.final_status);
        }
      });
    }

    update(mailTemplate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <Select
      onChange={(e) => {
        setState(e?.target?.value)
      }}
      value={state}
      size="small"
      fullWidth
      disabled={row?.cv_screen_results !== 1}
    >
      {FINAL_STATUS?.map((item) => (
        <MenuItem
          disabled={item?.disable}
          value={item?.value}
          key={item?.value}
        >
          {item?.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default FinalStatus