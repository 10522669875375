import { PRODUCT_UNITS, SERVICE_PERIODS } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { vestResolver } from '@hookform/resolvers/vest'
import { Box, Button, FormControl, Paper, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import addonValidator from './validators'

export const INITITAL_ADDON_METADATA = {
    product_name: "",
    product_billing: "lifetime",
    product_pricing_configures: {
        product_pricing_unit: "gb",
        default: {
            pricing: 0
        }
    }
}

const AddonForm = ({
    prevAddons = [],
    open,
    data,
    type = 'create',
    close,
    saver
}) => {

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: vestResolver((props) => addonValidator({
            ...props,
            type: type,
            prevAddons: prevAddons,
            product_name_old: data?.product_name
        })),
        reValidateMode: "onChange",
        defaultValues: { ...data }
    })

    const onSubmit = (values) => {
        saver({ method: type, product: values })
    }
    return (
        <Paper sx={{ p: 3, mb: 2 }} elevation={8} component="form" key={2}>
            <Typography sx={{ fontWeight: 'medium' }}>
                Dịch Vụ/ Sản Phẩm addon
            </Typography>
            <Box sx={{ display: 'flex', mt: 2, flexDirection: { xs: 'column', md: 'row', gap: 1 } }}>
                <Box sx={{ display: 'flex', minWidth: { xs: '100%', md: 'calc((100% - 8px) / 3)' } }}>
                    <Typography sx={{ fontSize: 14, color: "#777680", m: 'auto 0' }}>
                        Tên Dịch Vụ *
                    </Typography>
                </Box>
                <Controller control={control}
                    name="product_name"
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FormControl fullWidth>
                                <TextField size='small'
                                    value={value}
                                    error={!!errors.product_name}
                                    helperText={errors?.product_name?.message}
                                    onChange={onChange}
                                    placeholder="Nhập tên dịch vụ..."
                                />
                            </FormControl>
                        )
                    }} />
            </Box>
            <Box sx={{ display: 'flex', mt: 2, flexDirection: { xs: 'column', md: 'row', gap: 1 } }}>
                <Box sx={{ display: 'flex', minWidth: { xs: '100%', md: 'calc((100% - 8px) / 3)' } }}>
                    <Typography sx={{ fontSize: 14, color: "#777680", m: 'auto 0' }}>
                        Đơn Vị Tính *
                    </Typography>
                </Box>
                <Controller control={control}
                    name="product_pricing_configures.product_pricing_unit"
                    render={({ field: { value, onChange } }) => {
                        const selected = PRODUCT_UNITS?.find(item => item.value === value) || null
                        return (
                            <FormControl fullWidth>
                                <SingleSelectCustomize
                                    value={selected}
                                    options={PRODUCT_UNITS}
                                    onChange={(event, newValue) => onChange(newValue?.value || -1)}
                                    input={{
                                        placeholder: "Chọn đơn vị tính..."
                                    }}
                                />
                                {!!errors?.product_pricing_configures?.product_pricing_unit && <ErrorHelperText message={errors?.product_pricing_configures?.product_pricing_unit?.message} />}
                            </FormControl>
                        )
                    }} />
            </Box>
            <Box sx={{ display: 'flex', mt: 2, flexDirection: { xs: 'column', md: 'row', gap: 1 } }}>
                <Box sx={{ display: 'flex', minWidth: { xs: '100%', md: 'calc((100% - 8px) / 3)' } }}>
                    <Typography sx={{ fontSize: 14, color: "#777680", m: 'auto 0' }}>
                        Chu Kỳ Thanh Toán *
                    </Typography>
                </Box>
                <Controller control={control}
                    name="product_billing"
                    render={({ field: { value, onChange } }) => {
                        const selected = SERVICE_PERIODS.slice(1)?.find(item => item.value === value) || null
                        return (
                            <FormControl fullWidth>
                                <SingleSelectCustomize
                                    value={selected}
                                    options={SERVICE_PERIODS.slice(1)}
                                    onChange={(event, newValue) => onChange(newValue?.value || -1)}
                                    input={{
                                        placeholder: "Chọn chu kỳ thanh toán..."
                                    }}
                                />
                                {!!errors?.product_billing && <ErrorHelperText message={errors?.product_billing?.message} />}
                            </FormControl>
                        )
                    }} />
            </Box>
            <Box sx={{ display: 'flex', mt: 2, flexDirection: { xs: 'column', md: 'row', gap: 1 } }}>
                <Box sx={{ display: 'flex', minWidth: { xs: '100%', md: 'calc((100% - 8px) / 3)' } }}>
                    <Typography sx={{ fontSize: 14, color: "#777680", m: 'auto 0' }}>
                        Giá niêm yết (đơn giá/ đơn vị) *
                    </Typography>
                </Box>
                <Controller control={control}
                    name={`product_pricing_configures.default.pricing`}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FormControl fullWidth>
                                <TextField size='small'
                                    value={value}
                                    InputProps={{
                                        inputComponent: NumericFormatCustom
                                    }}
                                    error={!!errors.product_name}
                                    helperText={errors?.product_name?.message}
                                    onChange={onChange}
                                    placeholder="Nhập giá niêm yết..."
                                />
                            </FormControl>
                        )
                    }} />
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 2,
                gap: 2,
                flexDirection: { xs: 'column', md: 'row' }
            }}>
                <Button size='small' variant='text' sx={{ height: 36 }} onClick={close}>
                    Huỷ Thao Tác
                </Button>
                <Button size='small' variant='outlined' sx={{ height: 36, minWidth: 150 }} onClick={handleSubmit(onSubmit)}>
                    {type === "create" ? "Thêm" : "Cập nhật"} dịch vụ
                </Button>
            </Box>
        </Paper>
    )
}

export default AddonForm