import ServiceSubscriptionApi from "@/apis/beta/Service/ServiceSubscriptionApi";
import { buildQueryFilterCondition, format_date_short } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import DataGridCustomize from "@components/DataGrid";
import PopperAction from "@components/PopperAction";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Popper } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import Metadata from "./Metadata";

const ServiceSubscription = () => {
  const { service_id } = useParams();

  const [popper, setPopper] = React.useState({
    actions: [],
    anchorEl: undefined,
    data: undefined,
  });
  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [search, setSearch] = React.useState("");
  const debouncedSearchQuery = useDebounce(search, 500);

  const filterableServiceInvoices = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "")
      return null;
    const fieldQueries = [
      {
        name: "service_usages.service_usage_id",
        operator: "$eq",
        disabled: !parseInt(debouncedSearchQuery),
        value: parseInt(debouncedSearchQuery),
      },
    ];
    return buildQueryFilterCondition("$or", 0, fieldQueries);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const [initialColumns] = React.useState([
    {
      field: "id",
      headerName: "ID Đối Soát",
      width: 100,
    },
    {
      field: "usage-timer",
      headerName: "Thời gian đối soát",
      width: 250,
      renderCell: ({ row }) =>
        `${format_date_short(
          row?.service_usage_startdate
        )} - ${format_date_short(row?.service_usage_enddate)}`,
    },
    {
      field: "service_usage_metadata",
      headerName: "Thông tin đối soát",
      width: 350,
      renderCell: ({ value }) => <Metadata configoptions={value} />,
    },
    {
      field: "service_usage_created",
      headerName: "Ngày tạo",
      width: 120,
      renderCell: ({ value }) => format_date_short(value),
    },
  ]);
  const [columns, setColumns] = React.useState(initialColumns);
  React.useEffect(() => {
    setColumns(initialColumns);
  }, [initialColumns]);

  const { isFetching, data } = ServiceSubscriptionApi.List({
    serviceId: service_id,
    page: page?.page + 1,
    pageSize: page?.pageSize || 10,
    query: filterableServiceInvoices() || null,
  });

  const handleClearPopper = () =>
    setPopper({ actions: [], anchorEl: undefined, data: undefined });

  return (
    <React.Fragment>
      {popper?.anchorEl !== undefined && (
        <Popper anchorEl={popper?.anchorEl} open={Boolean(popper.anchorEl)}>
          <PopperAction {...popper} handleClose={handleClearPopper} />
        </Popper>
      )}
      <DataGridCustomize
        rows={
          data?.subscriptions?.map((subscription) => ({ ...subscription })) ||
          []
        }
        columns={columns}
        loading={isFetching}
        getRowHeight={({ model }) => {
          const LENGHT_OF_METADATA = Object.keys(
            JSON.parse(model?.service_usage_metadata)
          )?.length;
          const HEIGHT_OF_METADATA =
            LENGHT_OF_METADATA * 20 + (LENGHT_OF_METADATA - 1) * 4 + 8;
          return HEIGHT_OF_METADATA > 52 ? HEIGHT_OF_METADATA : 52;
        }}
        componentsProps={{
          toolbar: {
            initialColumns: initialColumns,
            inputSearch: {
              placeholder: "Nhập ID phiếu tìm kiếm...",
              value: search,
              onChange: setSearch,
            },
          },
          pagination: {
            page: page?.page || 0,
            pageSize: page?.pageSize || 10,
            rowsPerPageOptions: [5, 10, 25, 50],
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        rowCount={data?.pagination?.total}
      />
    </React.Fragment>
  );
};

export default withSuspense(
  withPermission(ServiceSubscription, {
    feature: "service",
    action: "service-subscription-index",
  }),
  MuiSkeleton["DataGrid"]
);
