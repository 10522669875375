import { Stack } from '@mui/material';
import React from 'react';
import withEmployee from '@/hocs/withEmployee';
import SkeletonSidebar from '../Skeletons/Sidebar';

const Sidebar = React.lazy(() => import("../Sidebar"))

const Layout = ({ children }) => {
  return (
    <Stack direction={{ xs: "column", md: "row" }}>
      <React.Suspense fallback={<SkeletonSidebar />}>
        <Sidebar />
      </React.Suspense>
      {children}
    </Stack>
  );
};

export default withEmployee(Layout);
