import { Box, Button, CircularProgress } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const Buttons = ({
    type = "create",
    submit,
    onSubmit
}) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
            <Link to="/group-roles" replace>
                <Button variant='outlined'
                    disabled={submit}
                    size='small'
                    sx={{
                        height: { xs: 36, md: 42 },
                    }}>
                    Huỷ Thao Tác
                </Button>
            </Link>
            <Button variant='contained'
                size='small'
                onClick={onSubmit}
                disabled={submit}
                startIcon={submit && <CircularProgress size={14} sx={{ color: "#777980" }} />}
                sx={{
                    height: { xs: 36, md: 42 },
                    minWidth: 120
                }}>
                {type === "create" ? 'Thêm mới' : 'Cập nhật'}
            </Button>
        </Box>
    )
}

export default Buttons