import withPermission from '@/hocs/withPermission'
import { Box, Typography } from '@mui/material'
import ScopeButtons from '../shared/Buttons'
import Scopes from './Scopes'

const List = () => {
    return (
        <>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center',
                mb: 2
            }}>
                <Typography sx={{ fontSize: { xs: 18, md: 24 }, fontWeight: 'medium' }}>
                    Danh Sách Quyền Truy Cập
                </Typography>
                <ScopeButtons.Create />
            </Box>
            <Scopes />
        </>
    )
}

export default withPermission(List, { feature: 'scope', action: 'index' })