import ServiceButtonUI from '@components/Templates/shared/Service/ServiceButtons'
import { Box } from '@mui/material'

const ServiceActivities = ({
    activities = [],
    addons = [],
    service = {},
    module = {
        Component: null,
        actions: [] // array of actions to be displayed in dropdown menu
    }// module is { Component, actions}
}) => {

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: {
                xs: 'column',
                md: 'row'
            },
            gap: {
                xs: 1.5, md: 1.25
            }
        }}>
            {activities?.map((activity, index) => {
                const ServiceButton = ServiceButtonUI[activity]
                if (!ServiceButton) return null
                return (
                    <ServiceButton service={service} key={index} addons={addons} />
                )
            })}
            {(!!module?.Component && !!module?.actions) && module?.actions?.map((activity, index) => {
                const ServiceButton = module.Component[activity]
                if (!ServiceButton) return null
                return (
                    <ServiceButton service={service} addons={addons} key={index} />
                )
            })}
        </Box>
    )
}

export default ServiceActivities