import React, { Fragment, useState } from 'react'
import AnalyticHeader from './Header'
import BandwidthChart from './BandwidthChart'
import dayjs from 'dayjs'
import { dateToString } from '../services'
import LocationTrafficChart from './LocationTrafficChart'

const Analytic = ({ service }) => {
    const [times, setTimes] = useState({
        value: '30d',
        gte: dayjs().subtract(30, 'days'),
        lte: dayjs()
    })
    return (
        <Fragment>
            <AnalyticHeader times={times} setTimes={setTimes} />
            <BandwidthChart service={{
                service_uuid: service?.service_creatable_uuid
            }}
                query={{
                    gte: dateToString(times?.gte),
                    lte: dateToString(times?.lte)
                }}
                range={times?.value}
            />
            <LocationTrafficChart service={{
                service_uuid: service?.service_creatable_uuid
            }}
                query={{
                    gte: dateToString(times?.gte),
                    lte: dateToString(times?.lte)
                }}
                range={times?.value}
            />
        </Fragment>
    )
}

export default Analytic