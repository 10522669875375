import { Typography } from "@mui/material";
import dayjs from "dayjs";
import DrawerBussinessDetail from "./List/Drawer/DrawerBussinessDetail";
import DrawerHolidayDetail from "./List/Drawer/DrawerHolidayDetail";
import DrawerDayOfffDetail from "./List/Drawer/DrawerDayOfffDetail";

const currentDate = new Date(Date.now());

export const WORK_DAY_FULL = "full";
export const WORK_DAY_HALF = "half";
export const WORK_DAY_ZERO = "zero";
export const WORK_DAY_TYPE_NORMAL = "Ngày bình thường";
export const WORK_DAY_TYPE_LEAVE = "Ngày nghỉ phép năm";
export const WORK_DAY_TYPE_BUSSINESS = "Ngày công tác";
export const WORK_DAY_TYPE_HOLIDAY = "Ngày nghỉ lễ, tết";
export const WORK_DAY_TYPE_NOT_LEAVE = "Nghỉ không phép";

export const defaultFrom = `${currentDate.getMonth() + 1
  }/01/${currentDate?.getFullYear()}`;

export const defaultTo = `${currentDate.getMonth() + 1
  }/${currentDate?.getDate()}/${currentDate?.getFullYear()}`;

export const resolve_work_n_day = (work_n_day) => {
  let result = 0;

  switch (work_n_day) {
    case WORK_DAY_ZERO: {
      result = 0;
      break;
    }

    case WORK_DAY_FULL: {
      result = 1;
      break;
    }

    case WORK_DAY_HALF: {
      result = 0.5;
      break;
    }

    default: {
      result = 0;
    }
  }

  return result;
};

export const initialColumns = [
  {
    field: "employee_code",
    headerName: "Mã Nhân Viên",
    width: 150,
    hide: true,
  },
  {
    field: "employee_name",
    headerName: "Tên Nhân Viên",
    width: 200,
  },
  {
    field: "department_name",
    headerName: "Phòng Ban",
    width: 200,
  },
  {
    field: "start_worked_date",
    headerName: "Ngày Vào Làm",
    width: 200,
    hide: true,
    hide: true,
    renderCell: ({ row }) =>
      row?.start_worked_date ? (
        <Typography fontSize={14}>
          {dayjs(row?.start_worked_date)?.format("DD/MM/YYYY")}
        </Typography>
      ) : (
        ""
      ),
  },
  {
    field: "end_worked_date",
    headerName: "Ngày OFF",
    width: 200,
    hide: true,
    renderCell: ({ row }) =>
      row?.end_worked_date ? (
        <Typography fontSize={14}>
          {dayjs(row?.end_worked_date)?.format("DD/MM/YYYY")}
        </Typography>
      ) : (
        ""
      ),
  },
  {
    field: "work_day_date",
    headerName: "Date",
    width: 120,
    renderCell: ({ row }) => (
      <Typography fontSize={14}>
        {dayjs(row?.work_day_date)?.format("DD/MM/YYYY")}
      </Typography>
    ),
  },
  {
    field: "work_day_time",
    headerName: "Work Time",
    width: 100,
    renderCell: ({ row }) => {

      return (
        <Typography
          fontSize={14}
          color="#1A237E"
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          {row?.work_day_time ? row.work_day_time.toFixed(2) : "0"}
        </Typography>
      );
    },
  },
  {
    field: "work_day_in",
    headerName: "Giờ Vào",
    width: 100,
  },
  {
    field: "work_day_out",
    headerName: "Giờ Ra",
    width: 100,
  },

  {
    field: "work_n_day_time",
    headerName: "Ngày Công Theo WorkTime",
    width: 200,
    renderCell: ({ row }) => {
      let calculatedWorkdays = resolve_work_n_day(row?.work_n_day);
    
      if (row?.work_date_type === WORK_DAY_TYPE_LEAVE || row?.work_date_type === WORK_DAY_TYPE_BUSSINESS) {
        calculatedWorkdays -= (row?.work_day_option === 2 ? 1 : 0.5);
      }

      return (
        <Typography
          fontSize={14}
          color="#1A237E"
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          {calculatedWorkdays}
        </Typography>
      );
    },
  },
  {
    field: "work_date_day_off",
    headerName: "Ngày Nghỉ Phép Năm",
    width: 200,
    renderCell: ({ row }) => {
      return (
        <Typography
          fontSize={14}
          color="#1A237E"
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          {row?.work_date_type === WORK_DAY_TYPE_LEAVE
            ? (row?.work_day_option=== 2 ? 1 : 0.5)
            : row?.work_date_type === WORK_DAY_TYPE_NOT_LEAVE
              ? 0
              : ""}
        </Typography>
      );
    },
  },
  {
    field: "work_date_business",
    headerName: "Ngày Công Tác",
    width: 150,
    renderCell: ({ row }) => {
      return (
        <Typography
          fontSize={14}
          color="#1A237E"
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          {row?.work_date_type === WORK_DAY_TYPE_BUSSINESS
            ? (row?.work_day_option=== 2 ? 1 : 0.5)
            : ""}
        </Typography>
      );
    },
  },
  {
    field: "work_n_day",
    headerName: "Ngày Công Tính Lương",
    width: 200,
    renderCell: ({ row }) => (
      <Typography
        fontSize={14}
        color="#1A237E"
        sx={{ textDecoration: "underline", cursor: "pointer" }}
      >
        {resolve_work_n_day(row?.work_n_day)}
      </Typography>
    ),
  },
  {
    field: "work_date_type",
    headerName: "Loại Nghỉ Phép",
    width: 150,
    renderCell: ({ row }) => {
      return (
        <Typography
          fontSize={14}
          color="#1A237E"
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          {row?.work_date_type}
        </Typography>
      );
    },
  },
  {
    field: "work_day_note",
    headerName: "Ghi chú",
    width: 500,
    hide: true,
  },
];

export const ListDrawer = {
  [WORK_DAY_TYPE_BUSSINESS]: DrawerBussinessDetail,
  [WORK_DAY_TYPE_HOLIDAY]: DrawerHolidayDetail,
  [WORK_DAY_TYPE_LEAVE]: DrawerDayOfffDetail,
  [WORK_DAY_TYPE_NOT_LEAVE]: DrawerDayOfffDetail,
  work_date_business: DrawerBussinessDetail,
  work_date_day_off: DrawerDayOfffDetail
};
