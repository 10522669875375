import { format_date_monent_js } from '@/functions'
import { CONTRACTS_STATUS } from '@/libs/constants'
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined'
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
import { Box, Button, FormControl, TextField, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const Contract = ({
    contract
}) => {
    return (
        <Box>
            <FormControl fullWidth sx={{
                mb: 1.5
            }}>
                <TextField label="ID Hợp Đồng*"
                    size="small"
                    disabled
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={contract?.contract_id}
                />
            </FormControl>
            <FormControl fullWidth sx={{
                mb: 1.5
            }}>
                <TextField label="Mã Hợp Đồng*"
                    size="small"
                    disabled
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={contract?.contract_number_id}
                />
            </FormControl>
            <FormControl fullWidth sx={{
                mb: 1.5
            }}>
                <TextField label="Hiệu Lực Hợp Đồng*"
                    size="small"
                    disabled
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={`${contract?.effective_date} đến ${contract?.expiry_date}`}
                />
            </FormControl>
            <FormControl fullWidth sx={{
                mb: 1.5
            }}>
                <TextField label="Ngày Tạo Trên Hệ Thống*"
                    size="small"
                    disabled
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={format_date_monent_js(contract?.contract_created_at, 'MMM, YYYY DD | HH:mm')}
                />
            </FormControl>
            <FormControl fullWidth sx={{
                mb: 1.5
            }}>
                <TextField label="Nhân Viên Phụ Trách*"
                    size="small"
                    disabled
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={`${contract?.employee_id} - ${contract?.employee_name}`}
                />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 1 }}>
                <TextField label="Trạng thái *"
                    size="small"
                    InputLabelProps={{
                        shrink: true
                    }}
                    disabled
                    value={CONTRACTS_STATUS?.find(x => x?.value === contract?.contract_status)?.label || '---'}
                />
            </FormControl>
            {!!contract?.contract_status_changed_at && <FormControl fullWidth sx={{ mb: 1 }}>
                <TextField label="Thay đổi trạng thái hợp đồng lúc"
                    size="small"
                    InputLabelProps={{
                        shrink: true
                    }}
                    disabled
                    value={
                        format_date_monent_js(contract?.contract_status_changed_at, 'MMM, YYYY DD | HH:mm:ss')
                    }
                />
            </FormControl>}
            <FormControl fullWidth sx={{
                mb: 1.5
            }}>
                <TextField label="Ghi chú"
                    size="small"
                    multiline
                    disabled
                    minRows={2}
                    InputLabelProps={{
                        shrink: true
                    }}
                    placeholder="Ghi chú hợp đồng dịch vụ..."
                    value={contract?.contract_note}
                />
            </FormControl>

            <Box sx={{ display: 'flex', mb: 2 }}>
                <Typography sx={{
                    fontSize: 14
                }} color="text.grey">
                    Số lượng bản hợp đồng đã được upload lên hệ thống: {contract?.attachments?.length}
                </Typography>
            </Box>
            <Link
                to={`/customer_contracts/${contract?.contract_id}`}
                target="_blank">
                <Button fullWidth size='small'
                    variant='outlined'
                    sx={{
                        mb: 1.5
                    }}
                    startIcon={<VisibilityOutlined fontSize='small'/>}>
                    Xem hợp đồng dịch vụ
                </Button>
            </Link>
            <Link
                to={`/customer_contracts/${contract?.contract_id}/processes`}
                target="_blank">
                <Button fullWidth size='small'
                    variant='outlined'
                    startIcon={<FormatListBulletedOutlinedIcon fontSize='small' />}>
                    Quá trình trao đổi hợp đồng
                </Button>
            </Link>
        </Box>
    )
}

export default Contract