import { GENDERS } from "@/libs/constants"
import DatePicker from '@components/DatePicker'
import Location from "@components/Location"
import ErrorHelperText from '@components/shared/ErrorHelperText'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, Checkbox, FormControl, FormControlLabel, Grid, TextField } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'


const AdminDeclaration = () => {
    const { control, formState: { errors }, watch } = useFormContext()
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="adminName"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Họ và Tên *"
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors?.domain?.domain_extra_fields?.adminName}
                                        helperText={errors?.domain?.domain_extra_fields?.adminName?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="adminID_Number"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="CMND/CCCD *"
                                        placeholder='Nhập CMND/ CCCD...'
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors?.domain?.domain_extra_fields?.adminID_Number}
                                        helperText={errors?.domain?.domain_extra_fields?.adminID_Number?.message}
                                    />
                                </FormControl>
                            )
                        }} />

                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name="uiCountry"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Location.Country
                                    value={value || null}
                                    onChange={(event, newValue) => onChange(newValue)}
                                    error={{
                                        isError: false,
                                        message: ""
                                    }}
                                />
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name="uiProvince"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Location.Province
                                    preSelected={value?.value}
                                    country={watch(`uiCountry`)?.value || '-1'}
                                    onChange={(event, newValue) => onChange(newValue)}
                                    error={{
                                        isError: false,
                                        message: ""
                                    }}
                                />
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name="uiDistrict"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Location.District
                                    preSelected={value?.value}
                                    province={watch(`uiProvince`)?.value || '-1'}
                                    onChange={(event, newValue) => onChange(newValue)}
                                    error={{
                                        isError: false,
                                        message: ""
                                    }}
                                />
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name="uiWard"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Location.Ward
                                    preSelected={value?.value}
                                    province={watch(`uiProvince`)?.value || '-1'}
                                    district={watch(`uiDistrict`)?.value || '-1'}
                                    onChange={(event, newValue) => onChange(newValue)}
                                    error={{
                                        isError: false,
                                        message: ""
                                    }}
                                />
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Controller
                        control={control}
                        name="uiAddress"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Location.Address
                                    value={value}
                                    onChnage={onChange}
                                    preSelected={value?.value}
                                    error={{
                                        isError: false,
                                        message: ""
                                    }}
                                />
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name='adminEmail'
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Email *"
                                        placeholder='Nhập email của khách hàng...'
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors?.domain?.domain_extra_fields?.adminEmail}
                                        helperText={errors?.domain?.domain_extra_fields?.adminEmail?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name='adminPhone'
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Số Điện Thoại * "
                                        placeholder='Nhập số điện thoại...'
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors?.domain?.domain_extra_fields?.adminPhone}
                                        helperText={errors?.domain?.domain_extra_fields?.adminPhone?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="adminGender"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            const selected = GENDERS.find(x => x.value === value) || null
                            return (
                                <FormControl fullWidth>
                                    <SingleSelectCustomize
                                        value={selected}
                                        options={GENDERS.slice(0, 2)}
                                        onChange={(event, newValue) => onChange(newValue?.value || "")}
                                        input={{
                                            placeholder: "chọn giới tính...",
                                            label: "Giới tính"
                                        }} />
                                    {!!errors?.domain?.domain_extra_fields?.adminGender
                                        && <ErrorHelperText
                                            message={errors?.domain?.domain_extra_fields?.adminGender?.message} />}
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name='adminFax'
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Số FAX"
                                        placeholder='Số FAX...'
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="adminBirthdate"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <DatePicker
                                        value={value}
                                        onChange={onChange}
                                        label="Ngày Sinh *"
                                    />
                                    {!!errors?.domain?.domain_extra_fields?.adminBirthdate
                                        && <ErrorHelperText
                                            message={errors?.domain?.domain_extra_fields?.adminBirthdate?.message} />}
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="uiCompany"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Tên Công Ty"
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                        placeholder="Nhập tên công ty..."
                                        error={!!errors?.domain?.domain_extra_fields?.uiCompany}
                                        helperText={errors?.domain?.domain_extra_fields?.uiCompany?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="uiPosition"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        size='small'
                                        label="Chức Vụ"
                                        placeholder="Nhập thông tin chức vụ admin..."
                                        InputLabelProps={{ shrink: true }}
                                        value={value}
                                        onChange={onChange}
                                        error={!!errors?.domain?.domain_extra_fields?.uiPosition}
                                        helperText={errors?.domain?.domain_extra_fields?.uiPosition?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="sendmail"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        control={<Checkbox size='small'
                                            checked={value}
                                            onChange={onChange}
                                        />}
                                        label="Gửi mail thông báo cho khách hàng(PA)"
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default AdminDeclaration