import DatePicker from '@components/DatePicker'
import { FormControl, TextField } from '@mui/material'
import React from 'react'

const ServiceAdditional = ({
    state,
    setState
}) => {
    return (
        <>
            <FormControl fullWidth sx={{ mt: 1.25 }}>
                <TextField
                    value={state?.reason}
                    onChange={(event) => setState(pre => ({ ...pre, reason: event?.target?.value }))}
                    size='small'
                    placeholder='Lý do/ thông tin cần bổ sung hoặc điều chỉnh...'
                    multiline
                />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 1.25 }}>
                <DatePicker
                    label="Thời hạn xử lý *" value={state?.deadline} onChange={(newValue) => setState(pre => ({ ...pre, deadline: newValue }))} />
            </FormControl>
        </>
    )
}

export default ServiceAdditional