import withPermission from '@/hocs/withPermission';
import React from 'react';
import CreditForm from "../shared/CreditForm";

const Create = ({
    customerId,
    onClose
}) => {
    return (
        <CreditForm
            type="create"
            defaultValues={{
                credit_type: "deposit",
                credit_amount: 0,
                credit_note: "",
                credit_referrer_type: "Service",
                credit_referrer_id: -1,
                payment_method: "banktransfer"
            }}
            customerId={customerId}
            onClose={onClose}
        />
    )
}

export default withPermission(Create, { feature: "customer", action: "customer-credit-create", type: "Modal" })