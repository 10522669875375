import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { buildQueryFilterCondition, queryClientRefetchScope } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import TransitionGroups from "@components/shared/TranstionGroups";
import { useNotificationContext } from "@contexts/notification";
import { PersonOutlineOutlined } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useCallback, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

const SaleCommission = ({ service, onClose }) => {

  console.log(`service`, service)
  const { mutateAsync, isLoading } = useMutation(ServiceApi.ChangeServiceCommissions);
  const { setNotification } = useNotificationContext();
  const queryClient = useQueryClient();
  const methods = useForm({
    defaultValues: { employee_sale_ids: service?.service_employee_commissions?.map(e => e?.employee_id) || [] },
  });

  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounce(searchQuery, 400)

  const nestedQueries = useCallback(() => {
    let queryFields = [
      {
        name: "employees.employee_name",
        operator: "$contains",
        value: debouncedSearchQuery,
        disabled: !debouncedSearchQuery?.trim()
      },
      {
        name: "employees.employee_id",
        operator: "$in",
        disabled: !(methods.watch(`employee_sale_ids`)?.length),
        value: methods?.watch(`employee_sale_ids`)?.filter(x => !!parseInt(x))?.join(','),
      }
    ]
    return buildQueryFilterCondition('$or', 0, queryFields)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery, methods?.watch(`employee_sale_ids`)])

  const { data: { employees } } = EmployeeApi.List({
    query: nestedQueries(),
    pageSize: (methods?.watch(`employee_sale_ids`)?.length || 0) + 10
  })

  const onSubmit = async (values) => {
    const response = await mutateAsync({
      serviceId: service?.service_id,
      data: values,
    });
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }

    queryClientRefetchScope(queryClient, "service");
    onClose();
    return setNotification({
      open: true,
      message: "Thay đổi người hưởng hoa hồng dịch vụ thành công.",
      severity: "success",
    });
  };

  return (
    <FormProvider {...methods}>
      <Dialog
        open={true}
        maxWidth="md"
        fullWidth
        component="form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <DialogTitle>Cập Nhật Thông Tin Người Hưởng Hoa Hồng</DialogTitle>
        <DialogContent sx={{ minHeight: 300 }}>
          <Controller
            name="employee_sale_ids"
            control={methods?.control}
            render={({ field: { value = [], onChange } }) => {
              return (
                <TransitionGroups
                  values={employees?.filter(employee => value?.includes(employee?.employee_id))}
                  onDelete={(e) => onChange([...value?.filter(x => x !== e?.employee_id)])}
                  label={'employee_name'}
                  icon={<PersonOutlineOutlined fontSize='small' color="primary" />}
                >
                  <SingleSelectCustomize
                    clearOnBlur
                    options={[...employees?.filter(employee => !value?.includes(employee?.employee_id))]}
                    value={null}
                    formatCollection={{ label: 'employee_name', value: 'employee_id' }}
                    compareAttributes={['employee_name', 'employee_email_company']}
                    input={{
                      placeholder: "Chọn nhân viên...",
                      value: searchQuery,
                      onChange: (event) => setSearchQuery(event?.target?.value)
                    }}
                    onChange={(_, newValue) => {
                      if (!newValue?.employee_id) return;
                      onChange([...value?.filter(employeeId => !!parseInt(employeeId)), newValue?.employee_id])
                      setSearchQuery('')
                    }}
                    mode='server'
                  />
                </TransitionGroups>
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            disabled={isLoading}
            variant="outlined"
            onClick={onClose}
          >
            Huỷ Thao Tác
          </Button>
          <Button
            size="small"
            variant="contained"
            type="submit"
            disabled={isLoading}
            startIcon={
              isLoading && (
                <CircularProgress size={14} sx={{ color: "#777680" }} />
              )
            }
          >
            Lưu Thay Đổi
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default withSuspense(SaleCommission, MuiSkeleton["LoadingDotBox"])
