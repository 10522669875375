import { Typography } from '@mui/material'
import React from 'react'

const Header = () => {
    return (
        <Typography sx={{
            fontSize: { xs: 18, md: 24 },
            fontWeight: 'medium',
            mb: 2
        }}>
            Danh Sách Hợp Đồng Nhân Viên
        </Typography>
    )
}

export default Header