import { AddOutlined } from '@mui/icons-material'
import { Box, Button, Collapse, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import CountrySelection from './CountrySelection'

const BreakdownGlobal = ({ append }) => {

    const { control } = useFormContext()

    const productConfigoptions = useWatch({ control: control, name: "product_configoptions" })

    const [selectModal, setModal] = React.useState(false)

    const closer = () => setModal(false)

    const saver = (newCountry) => {

        try {
            let globalBandwidthCog = productConfigoptions.find((item) => item?.name === 'global')
            append({
                ...globalBandwidthCog,
                name: newCountry?.countryIsoCode?.toLowerCase()
            })
        } catch (error) {
            console.log(error?.message)
        }
        return closer()
    }

    return (
        <Collapse in={productConfigoptions.map(({ name }) => name).includes("global")}>
            <Box sx={{
                display: 'flex',
                flexDirection: { xs: "column", md: 'row' },
                gap: 1.5,
                justifyContent: 'space-between'
            }}>
                <Typography
                    component="div"
                    sx={{
                        fontSize: 14,
                        display: 'flex',
                        m: 'auto 0'
                    }}>
                    Thêm option băng thông
                    <Tooltip title="Thêm đơn giá đối soát cho từng vùng cụ thể">
                        <Typography sx={{
                            display: 'inline-block',
                            m: 'auto 0 auto 4px',
                            fontSize: 14
                        }}>*</Typography>
                    </Tooltip>
                </Typography>
                <Button size='small' variant='outlined'
                    onClick={() => setModal(true)}
                    disabled={selectModal}
                    startIcon={<AddOutlined fontSize='small' />}
                    sx={{ height: 36, minWidth: 150 }}
                >
                    Thêm quốc gia
                </Button>
            </Box>
            {selectModal && <CountrySelection closer={closer}
                saver={saver}
                selecteds={productConfigoptions}
            />}
        </Collapse>
    )
}

export default BreakdownGlobal