import { FormControl, TextField } from '@mui/material'
import React from 'react'

const Address = ({
    value,
    onChange,
    error: {
        isError = false,
        message = ""
    } }) => {

    return (
        <FormControl fullWidth>
            <TextField
                label='Địa chỉ'
                InputLabelProps={{ shrink: true }}
                size='small'
                value={value}
                onChange={onChange}
                error={!!isError}
                helperText={message}
            />
        </FormControl>
    )
}

export default Address
