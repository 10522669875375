import ProductGroupApi from "@/apis/beta/Product/ProductGroupApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Typography } from "@mui/material";
import React from "react";
import Buttons from "../Buttons";
import ProductGroup from "./ProductGroup";
const List = () => {
  const { data } = ProductGroupApi.List();
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          gap: 1.5,
          mb: 2,
        }}
      >
        <Typography className="page-title">Danh Sách Sản Phẩm</Typography>
        <Buttons.ButtonCreate />
      </Box>
      {data?.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <ProductGroup productGroup={item} />
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default withSuspense(
  withPermission(List, {
    feature: "product-group",
    action: "index",
  }),
  MuiSkeleton["Paper"]
);
