import useDataGridParams from "@/hooks/useDataGridParams";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import DataGridCustomize from "@components/DataGrid";
import Header from "./Header";
import ViewFileDialog from "./Dialog/ViewFileDialog";
import moment from "moment";
import { LEVEL_DOCUMENT, TYPE_DOCUMENT } from "./shared";
import ButtonAction from "./Button";

const List = ({ fetchFn, name, title, showButtonAction = false }) => {
  const initialColumns = [
    { field: "document_id", headerName: "Mã tài liệu", width: 400 },
    { field: "name_file", headerName: "Tên tài liệu", width: 300 },
    { field: "description", headerName: "Mô tả", width: 500 },
    {
      field: "created_at",
      headerName: "Ngày tạo tài liệu",
      width: 200,
      renderCell: ({ row }) => (
        <Typography fontSize="13px">
          {moment(row?.created_at)?.format("DD/MM/YYYY")}
        </Typography>
      ),
    },
    {
      field: "start_day",
      headerName: "Ngày hiệu lực",
      width: 200,
      renderCell: ({ row }) => (
        <Typography fontSize="13px">
          {moment(row?.start_day)?.format("DD/MM/YYYY")}
        </Typography>
      ),
    },
    {
      field: "end_day",
      headerName: "Ngày hết hiệu lực",
      width: 200,
      renderCell: ({ row }) => (
        <Typography fontSize="13px">
          {moment(row?.end_day)?.format("DD/MM/YYYY")}
        </Typography>
      ),
    },
    {
      field: "document_type",
      headerName: "Loại tài liệu",
      width: 200,
      renderCell: ({ row }) => (
        <Typography fontSize="13px">
          {
            TYPE_DOCUMENT?.find((item) => item?.value === row?.document_type)
              ?.label
          }
        </Typography>
      ),
    },
    {
      field: "level",
      headerName: "Mức độ quan trọng",
      width: 200,
      renderCell: ({ row }) => (
        <Typography fontSize="13px">
          {LEVEL_DOCUMENT?.find((item) => item?.value === row?.level)?.label}
        </Typography>
      ),
    },
    {
      field: "action",
      headerName: "Hành động",
      width: 150,
      renderCell: ({ row }) =>
        showButtonAction && <ButtonAction row={row} refetch={refetch} />,
    },
  ];

  const {
    columns,
    debouncedSearchQuery,
    page,
    searchQuery,
    setColumns,
    setPage,
    setSearchQuery,
  } = useDataGridParams(initialColumns);

  const params = {
    q: debouncedSearchQuery,
    ...page,
    page: page?.page + 1,
  };

  const { data, isFetching, refetch } = useQueryWithCustomized(
    [`get-document-${name}-${JSON.stringify(params)}`, "get-documents"],
    () => fetchFn({ ...params })
  );

  const [idDialogViewFile, setIdDialogViewFile] = useState(null);

  return (
    <Box>
      <Header title={title} />

      <DataGridCustomize
        onCellClick={(params) => setIdDialogViewFile(params?.id)}
        rows={data?.data || []}
        columns={columns}
        initialState={{ pinnedColumns: { right: ["action"] } }}
        loading={isFetching}
        rowCount={data?.pagination?.total_pages * page?.pageSize || 0}
        componentsProps={{
          toolbar: {
            store: "document.list.hiddenColumns",
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: "document",
            inputSearch: {
              placeholder: "Tìm kiếm....",
              value: searchQuery,
              onChange: setSearchQuery,
            },
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize || 20,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({
                ...prevState,
                pageSize: newPageSize,
              })),
          },
        }}
        sx={{ mt: 2 }}
      />

      {Boolean(idDialogViewFile) && (
        <ViewFileDialog
          open={Boolean(idDialogViewFile)}
          handleClose={() => setIdDialogViewFile(null)}
          id={idDialogViewFile}
        />
      )}
    </Box>
  );
};

export default List;
