import DatePicker from '@components/datepickers/DateRangPicker'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, Collapse, FormControl } from '@mui/material'
import dayjs from 'dayjs'
import { Fragment } from 'react'

const TIME_RANGE_OPTIONS = [
    { value: '7d', label: 'Last 7 Days' },
    { value: '30d', label: 'Last 30 Days' },
    { value: 't-month', label: 'This Month' },
    { value: 'l-month', label: 'Last Month' },
    { value: 'custom-range', label: 'Custom Range' },
]

const TimeRanges = ({ times, setTimes }) => {

    const onChange = (newValue) => {
        const today = dayjs()
        let result = {
            gte: times?.gte,
            lte: times?.lte
        }
        switch (newValue?.value) {
            case '7d':
                result = {
                    gte: today.subtract(7, 'days'),
                    lte: today
                }
                break;
            case '30d':
                result = {
                    gte: today.subtract(31, 'days'),
                    lte: today
                }
                break;
            case 't-month':
                result = {
                    gte: today.set('date', 1),
                    lte: today
                }
                break;
            case 'l-month':
                const isThisMonth = today.get('date') === dayjs().endOf('month').get('date')
                result = {
                    gte: isThisMonth ? today.set('date', 1) : today.set('date', 1).set('month', today.get('month') - 1),
                    lte: isThisMonth ? today.set('date', 31) : today.set('date', 31).set('month', today.get('month') - 1),
                }
                break;
            case 'custom-range':
                break;
            default:
                break;
        }
        return setTimes(preProps => ({ ...preProps, ...result, value: newValue?.value }))
    }
    return (
        <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center', flexDirection: { xs: 'column', md: 'row' } }}>
            <FormControl sx={{ minWidth: 200 }}>
                <SingleSelectCustomize
                    value={TIME_RANGE_OPTIONS?.find(x => x?.value === times?.value) || undefined}
                    options={TIME_RANGE_OPTIONS}
                    input={{
                        label: 'Range (*)',
                        placeholder: "Chọn thời gian..."
                    }}
                    onChange={(event, newValue) => onChange(newValue)}
                />
            </FormControl>
            <Collapse in={times?.value === 'custom-range'}>
                <Fragment>
                    {times?.value === 'custom-range' && <DatePicker
                        handler={(newValue) => {
                            setTimes(preTimes => ({ ...preTimes, gte: newValue[0], lte: newValue[1] }))
                        }}
                        value={[dayjs(times?.gte), dayjs(times?.lte)]} />}
                </Fragment>
            </Collapse>
        </Box>
    )
}

export default TimeRanges