import BusinessApi from '@/apis/beta/Employee/BusinessApi';
import DepartmentApi from "@/apis/beta/Employee/DepartmentApi";
import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import BusinessListShared from '@/components/Business/List';
import { useEmployeeContext } from "@/contexts/employee";
import { buildQueryFilterCondition } from "@/functions";
import withPermission from '@/hocs/withPermission';
import withSuspense from '@/hocs/withSuspense';
import useDebounce from '@/hooks/useDebounce';
import useFilters, { Filter } from '@/hooks/useFilters';
import MuiSkeleton from '@components/shared/MuiSkeleton';
import { Box } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import ModalConfirmPrivate from "./ModalConfirm";

const List = () => {
    const { employee } = useEmployeeContext()
    const { data: roles } = BusinessApi.CurrentRoles()

    const location = useLocation();

    const [searchQuery, setSearchQuery] = React.useState('')
    const debouncedSearchQuery = useDebounce(searchQuery, 500)
    const filterableBusiness = React.useCallback(() => {
        if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === '') return '';
        const fieldQueries = [
            {
                name: 'businesses.business_id', operator: "$eq",
                disabled: !parseInt(debouncedSearchQuery), value: parseInt(debouncedSearchQuery)
            },
            { name: 'employee_name', operator: "$contains", value: debouncedSearchQuery },
        ]
        return buildQueryFilterCondition("$or", 0, fieldQueries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery])

    const { data: employees } = EmployeeApi.ListEmployees({ pageSize: 999 })
    const [page, setPage] = React.useState({
        page: 0,
        pageSize: 20
    });

    const INITIAL_ACTION_LIST = [
        {
            name: "business-leader-confirm",
            icon: "Edit",
            label: "Xác Nhận Trưởng Phòng",
            sx: { color: "#1a237e" },
            handleClick: (data) => {
                setAction(prev => ({ ...prev, open: true, data: data, name: "business-leader-confirm" }))
            }
        }, {
            name: "business-bod-confirm",
            icon: "Edit",
            label: "Xác Nhận BOD",
            sx: { color: "#1a237e" },
            handleClick: (data) => {
                setAction(prev => ({ ...prev, open: true, data: data, name: "business-bod-confirm" }))
            }
        }
    ]

    const { data: departments } = DepartmentApi.ListDepartments()
    const [query, updateQuery] = useFilters('manage-businesses.list.filters', location);

    const { data, isFetching, refetch: callbackBusinesses } = BusinessApi.List({
        page: page?.page + 1,
        pageSize: page?.pageSize,
        query: Filter.stringify(query),
        searchQuery: filterableBusiness() !== "" ? filterableBusiness() : null
    })

    const handleClose = () => {
        return setAction(prev => ({ ...prev, open: false, data: undefined, name: undefined }))
    }

    const getModalConfirm = (modelType, data) => {
        switch (modelType) {
            case 'business-leader-confirm':
                return <ModalConfirmPrivate
                    type="leader-confirm"
                    callbackBusinesses={callbackBusinesses}
                    handleClose={handleClose}
                    data={{
                        ...data,
                        departments: departments?.departments,
                        employee: employees?.find(e => e?.employee_id === data?.employee_id)
                    }}
                />
            case 'business-bod-confirm': return <ModalConfirmPrivate
                type="bod-confirm"
                callbackBusinesses={callbackBusinesses}
                handleClose={handleClose}
                data={{
                    ...data,
                    departments: departments?.departments,
                    employee: employees?.find(e => e?.employee_id === data?.employee_id)
                }}
            />
            default: return null;
        }
    }
    const [action, setAction] = React.useState({
        open: false,
        data: undefined,
        name: undefined
    })
    return (
        <Box>
            <Header />
            <BusinessListShared
                filter={{
                    filter: "manage-businesses.list.filters",
                    columnsHidden: "businesses.hiddenColumn"
                }}
                inspector={{ ...roles }}
                updateQuery={updateQuery}
                employee={employee}
                callbackBusinesses={callbackBusinesses}
                feature="management-businesses"
                data={data?.businesses}
                isFetching={isFetching}
                departments={departments?.departments}
                pagination={{
                    rowsPerPageOptions: [10, 20, 50, 100],
                    page: page?.page || 0,
                    pageSize: page?.pageSize || 20,
                    onPageChange: (newPage) => setPage(prevState => ({ ...prevState, page: newPage })),
                    onPageSizeChange: (newPageSize) => setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
                    total: data?.pagination?.total
                }}
                employees={employees}
                initialActions={INITIAL_ACTION_LIST}
                inputSearch={{
                    placeholder: "Nhập tên nhân viên tìm kiếm...",
                    value: searchQuery,
                    onChange: setSearchQuery
                }}
            />
            {action.open && getModalConfirm(action?.name, action.data)}
        </Box>
    )
}

export default withSuspense(withPermission(List, { feature: 'business', action: 'index' }), MuiSkeleton["DataGrid"]);