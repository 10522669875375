import { create, enforce, test } from "vest";

export const CustomerJourneyCreateValidator = create((data) => {
    test(`service_interestings`, "Vui lòng chọn dịch vụ quan tâm/ khai thác bán hàng.", () => {
        enforce(data.service_interestings).isNotEmpty()
    })
    test(`sale_tracking_id`, "Vui lòng chọn nhân viên phụ trách.", () => {
        enforce(data.sale_tracking_id).isNotEmpty()
    })

    if (parseInt(data?.customerContact?.customer_contact_id) > 0) {
        test(`contact_id`, "Vui lòng chọn thông tin liên hệ/ người bạn tiếp cận.", () => {
            enforce(data.contact_id > 0).isTruthy()
        })
    }
})