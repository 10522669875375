import CompanyApi from "@/apis/beta/Company/CompanyApi";
import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import { format_date_time } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import DataGridCustomize from "@components/DataGrid";
import DialogShowJsonData from "@components/DialogShowJsonData";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Link, Typography } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useMemo, useState } from "react";
import Description from "./Description";

export const COMPANY_UPDATE_TYPES = [
	{ value: "UPDATE", label: "Thay đổi thông tin công ty" },
	{ value: "MARKETING-CONVERT-CUSTOMER", label: "Chuyển đổi khách hàng từ nguồn Marketing team" },
	{ value: "CREATE-SERVICE-POC", label: "Tạo dịch vụ dùng thử" },
	{ value: "CREATE-SERVICE", label: "Tạo dịch vụ chính thức" },
	{ value: "CONVERT-TO-COMPANY", label: "Convert thành khách hàng doanh nghiệp" },
	{ value: "CONVERT-TO-INDIVIDUAL", label: "Convert thành khách hàng cá nhân" },
	{ value: "CHANGE-CUSTOMER-CREDIT", label: "Thay đổi mốc ký quỹ cố định" },
	{ value: "ASSIGNMENT", label: "Convert thành khách hàng doanh nghiệp" },
	{ value: "CREATE-CUSTOMER-JOURNEY", label: "Tạo quá trình bán hàng" },
	{ value: "LOCKED-CUSTOMER-JOURNEY", label: "Kết thúc quá trình bán hàng - Failed" },
	{ value: "UPDATE-CUSTOMER-JOURNEY", label: "Cập nhật quá trình bán hàng" },
	{ value: "CHANGE-CUSTOMER-MAILER", label: "Thay đổi thông tin nhận mail" },
	{ value: "UPDATE-CUSTOMER-INFORMATION", label: "Thay đổi thông tin khách hàng" },
	{ value: "CUSTOMER-NOTE", label: "Thêm thông tin ghi chú" },
];

// CHANGE-CUSTOMER-MAILER
const CustomerUpdates = ({ companyId, customerId }) => {
	const { data: companyUpdates } = CompanyApi.Updates({ companyId });
	const { data: customerUpdates } = CustomerApi.CustomerUpdates({ customerId });

	const [page, setPage] = useState({ page: 0, pageSize: 10 })
	const [dialogInfor, setDialogInfor] = useState({
		open: false,
		data: {},
		title: "Thông tin chi tiết"
	})

	const [columns] = React.useState([
		{
			field: "company_updater_name",
			minWidth: 200,
			headerName: "Nhân Viên",
		},
		{
			field: "company_update_type",
			minWidth: 200,
			headerName: "Thao tác",
			valueGetter: ({ row }) =>
				COMPANY_UPDATE_TYPES?.find((x) => x?.value === row?.company_update_type)
					?.label || "---",
		},
		{
			field: "company_update_description",
			minWidth: 400,
			headerName: "Thông tin mô tả",
			renderCell: ({ value }) => <Description description={value} />
		},
		{
			field: "company_update_created_at",
			minWidth: 150,
			headerName: "Thời gian thực hiện",
			valueFormatter: ({ value }) => format_date_time(value, true),
		},
		{
			field: "metadata",
			minWidth: 125,
			headerName: "Metadata",
			renderCell: ({ value }) => {
				return (
					<Box
						sx={{
							height: "100%",
							width: "100%",
							display: "flex",
							justifyItems: "center",
							alignItems: "center",
						}}
						onClick={() => setDialogInfor({ open: true, data: value })}
					>
						<Link
							underline="hover"
							color="inherit"
						>
							Xem chi tiết
						</Link>
					</Box>
				);
			},
		},
	]);

	const onClose = () => {
		setDialogInfor((prev) => ({ ...prev, open: false, data: {} }));
	}

	const updateOrders = useMemo(() => {
		return _.sortBy(
			[
				...[
					...(companyUpdates?.map((item) => ({
						...item,
						company_update_created_at: moment(
							item?.company_update_created_at
						),
					})) || []),
				],
				...customerUpdates?.map((item) => {
					return {
						...item,
						company_update_created_at: moment(
							item?.customer_update_created_at
						),
						company_update_description: item?.customer_update_description,
						company_update_type: item?.customer_update_type,
						company_updater_name: item?.customer_updater_name,
					};
				}),
			],
			"company_update_created_at",
			"desc"
		)?.map((x, index) => ({ ...x, id: index }))
	}, [companyUpdates, customerUpdates])


	return (
		<Box sx={{ mt: 2 }}>
			<DialogShowJsonData
				open={dialogInfor.open}
				onClose={onClose}
				title={dialogInfor.title}
				metadata={dialogInfor.data}
			/>
			<Typography sx={{ fontWeight: 500, fontSize: { xs: 16, md: 18 }, mb: 1 }}>
				Lịch Sử Thao Tác
			</Typography>
			<DataGridCustomize
				rows={updateOrders?.slice(page?.page * page?.pageSize, (page?.page + 1) * page?.pageSize)}
				columns={columns}
				components={{
					Toolbar: "disabled",
				}}
				componentsProps={{
					pagination: {
						page: page?.page || 0,
						pageSize: page?.pageSize,
						rowsPerPageOptions: [5, 10, 20],
						onPageChange: (newPage) =>
							setPage((prevState) => ({ ...prevState, page: newPage })),
						onPageSizeChange: (newPageSize) =>
							setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
					},
				}}
				rowCount={companyUpdates?.length + customerUpdates?.length}
				paginationMode="client"
			/>
		</Box>
	);
};

export default withSuspense(
	withPermission(CustomerUpdates, {
		feature: "customer",
		action: "customer-updates",
		type: "Block",
	}),
	MuiSkeleton["DataGrid"]
);
