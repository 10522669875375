import EmployeeApi from "@/apis/beta/Employee/EmployeeApi"
import UserApi from "@/apis/UserApi"
import withSuspense from "@/hocs/withSuspense"
import { Box, Grid, TextField, Typography } from '@mui/material'
import React from 'react'

const Insurance = () => {
    const [current] = UserApi.Current()
    const { data: employee } = EmployeeApi.Detail(current?.employee_id)

    return (
        <Box sx={{ border: 1, borderRadius: "4px", p: 3 }}>
            <Typography sx={{ textTransform: "uppercase", mb: 2 }}>Thông Tin Bảo Hiểm</Typography>
            <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>Số Sổ Bảo Hiểm Xã Hội</Typography>
                    <TextField size='small' value={employee?.employee_social_insurance || "Chưa có thông tin"} disabled fullWidth />
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>Số Thẻ Bảo Hiểm Y Tế</Typography>
                    <TextField size='small' value={employee?.employee_health_insurance || "Chưa có thông tin"} disabled fullWidth />
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Typography sx={{ mb: 0.5, textTransform: "capitalize" }}>nơi đăng ký khám chữa bệnh</Typography>
                    <TextField size='small' value={employee?.employee_signup_hospital || "Chưa có thông tin"} disabled fullWidth />
                </Grid>
            </Grid>
        </Box>
    )
}

export default withSuspense(Insurance, null)