import React from 'react';
import { useLayoutContext } from '../contexts/layout';
import PermissionDenied from '../features/App/PermissionDenied';

const withPermission = (Component, { feature, action, type = "page" }) => (props) => {
    const { permissions } = useLayoutContext();
    const validation = permissions?.find(p => (p.feature === feature && p?.actions.find(act => act?.action === action)))
    const isNotValid = !feature || !action || validation === undefined || validation === 'undefined'
    return (
        isNotValid ? type === "page" ? <PermissionDenied /> : null : <Component {...props} />
    )
};

export default withPermission;
