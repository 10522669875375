import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import {
	Box
} from "@mui/material";
import { useParams } from "react-router-dom";
import CompanyInformation from "../../../Company/Detail";
import ContactList from "../Contact/List";
import CustomerUpdates from "../CustomerUpdates";
import MetadataButtons from "./Buttons";
import QualifiedProspect from "./QualifiedProspected";

const QUALIFIED_PROSPECTS = ['qualified-prospects', 'cool-lead']

const CustomerInformation = () => {
	const { customer_id } = useParams();
	const { data: customer } = CustomerApi.Detail(customer_id);
	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: { xs: "column-reverse", md: "row" },
					gap: 2,
				}}
			>
				<Box sx={{ width: "100%" }}>
					{customer?.customer_service_information?.cid > -1 && (
						<CompanyInformation
							customer={customer}
							companyId={customer?.customer_service_information?.cid}
						/>
					)}
					{QUALIFIED_PROSPECTS?.includes(customer?.customer_service_information?.customer_status)
						? <QualifiedProspect customer={customer} />
						: <ContactList customerId={customer_id} />}
				</Box>
				<Box sx={{ maxWidth: { xs: "100%", md: "20%" } }}>
					<MetadataButtons
						customer={customer}
						packages={[
							"ChangeStatus",
							"Switch",
							"AddressInformation",
							"SoftDelete",
							"AddContact",
						]}
					/>
				</Box>
			</Box>
			<CustomerUpdates
				customerId={customer?.customer_id}
				companyId={customer?.customer_service_information?.cid}
			/>
		</Box>
	);
};

export default withSuspense(
	withPermission(CustomerInformation, { feature: "customer", action: "show" }),
	MuiSkeleton["GridInformation"]
);
