import { SCHEDULE_TYPES } from '@/libs/constants'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, Checkbox, FormControl, FormControlLabel, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ScheduleBookingRoom = () => {
    const { control, formState: { errors } } = useFormContext()
    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1, mt: 2 }}>
            <Box sx={{ width: 300, minWidth: 300, display: 'flex' }}>
                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                    Hình Thức Hoạt Động
                </Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row', gap: 1 },
                    mb: 1
                }}>
                    <Controller
                        name="schedule_type"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            const selected = SCHEDULE_TYPES?.find(item => item?.value === value) || null
                            return (
                                <FormControl fullWidth>
                                    <SingleSelectCustomize
                                        value={selected}
                                        options={SCHEDULE_TYPES}
                                        input={{
                                            placeholder: "Nhập hình thức hoạt động tìm kiếm..."
                                        }}
                                        onChange={(event, newValue) => onChange(newValue?.value || -1)}
                                    />
                                    {!errors?.schedule_type && <ErrorHelperText message={errors?.schedule_type?.message} />}
                                </FormControl>
                            )
                        }} />
                </Box>
                <Controller
                    control={control}
                    name="schedule_booking_room"
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FormControl fullWidth>
                                <FormControlLabel
                                    label="Sử dụng phòng họp"
                                    control={<Checkbox size='small' checked={value} onChange={onChange} />}
                                />
                            </FormControl>
                        )
                    }} />
            </Box>
        </Box>
    )
}

export default ScheduleBookingRoom