import DepartmentApi from "@/apis/beta/Employee/DepartmentApi";
import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import PositionApi from "@/apis/beta/Employee/PositionApi";
import DataGrid from "@/components/DataGrid";
import DataGridFilter from "@/components/DataGrid/Filter";
import PopperAction from "@/components/PopperAction";
import SkeletonDataGrid from "@/components/Skeletons/DataGrid";
import { buildQueryFilterCondition, format_date_short } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import useFilters, { Filter } from "@/hooks/useFilters";
import { EMPLOYEE_DISABLED_STATUS, GENDERS } from "@/libs/constants";
import { MoreVertOutlined } from "@mui/icons-material";
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Popper,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Create from "../Create";
import Header from "./Header";
import Statistical from "./Statistical";
import { useMutation } from "react-query";
import { useNotificationContext } from "@contexts/notification";
import { useConfirmContext } from "@contexts/confirm";
import Workdates from "./WorkDates";

const ACTION_UIS = {
  'edit-work-dates': Workdates
}

const List = () => {
  const location = useLocation();
  const [query, updateQuery] = useFilters("employees.list.filters", location);

  const { data: positions } = PositionApi.ListPositions({});
  const { data: departments } = DepartmentApi.ListDepartments();

  const { setNotification } = useNotificationContext();
  const { showConfirmation } = useConfirmContext();

  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const filterableEmployee = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
    const fieldQueries = [
      {
        name: "employee_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "employee_code",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "employee_email",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "employee_email_company",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "employee_phone_number",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
    ];
    return buildQueryFilterCondition("$or", 0, fieldQueries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [popper, setPopper] = React.useState({
    anchorEl: undefined,
    data: undefined,
  });

  const [action, setAction] = useState({
    name: undefined,
    data: undefined
  })

  const initialColumns = [
    {
      field: "employees.employee_id",
      headerName: "ID",
      width: 60,
      hideable: true,
      operators: { type: "number" },
      valueGetter: ({ row }) => row?.employee_id,
      renderCell: ({ value }) => (
        <Link to={`/employees/${value}/account`} replace>
          <Typography className="react-link-table">{value}</Typography>
        </Link>
      ),
    },
    {
      field: "employee_code",
      headerName: "Mã NV",
      width: 150,
      operators: { type: "string" },
    },
    {
      field: "employee_name",
      headerName: "Tên",
      width: 250,
      operators: { type: "string" },
    },
    {
      field: "employee_email_company",
      headerName: "Email Công Ty",
      width: 250,
      operators: { type: "string" },
    },
    {
      field: "employee_email",
      headerName: "Email cá nhân",
      width: 250,
      operators: { type: "string" },
    },
    {
      field: "employees.position_id",
      headerName: "Vị Trí",
      width: 250,
      valueGetter: ({ row }) => row?.employee_position,
      operators: {
        type: "object",
        collections:
          positions?.map((position) => ({
            value: position?.position_id,
            label: position?.position_name,
          })) || [],
      },
    },
    {
      field: "employees.department_id",
      headerName: "Phòng Ban",
      width: 250,
      valueGetter: ({ row }) => row?.employee_department,
      operators: {
        type: "object",
        collections: departments?.departments?.map((department) => ({
          value: department?.department_id,
          label: department?.department_name,
        })),
      },
    },
    {
      field: "employees.employee_disable",
      headerName: "Trạng Thái",
      width: 150,
      valueGetter: ({ row }) =>
        EMPLOYEE_DISABLED_STATUS.find(
          (status) => status.value === row.employee_disable
        ),
      operators: {
        type: "object",
        collections: EMPLOYEE_DISABLED_STATUS,
      },
      renderCell: ({ value }) => (
        <Typography sx={{ fontSize: 14 }} color={`custom.${value?.color} `}>
          {value?.label}
        </Typography>
      ),
    },
    {
      field: "employee_type",
      headerName: "Giới Tính",
      width: 150,
      hide: true,
      valueGetter: ({ row }) =>
        GENDERS.find((gender) => gender.value === row?.employee_type)?.label,
      operators: {
        type: "obejct",
        collections: GENDERS || [],
      },
    },
    {
      field: "employee_birthday",
      headerName: "Sinh Nhật",
      width: 150,
      renderCell: ({ value }) => (value ? format_date_short(value) : "---"),
      operators: { type: "date" },
    },
    {
      field: "employee_phone_number",
      headerName: "Số điện thoại",
      width: 150,
      operators: { type: "string" },
    },
    {
      field: "employee_leave_day",
      headerName: "Ngày Phép",
      width: 150,
      operators: { type: "string" },
      valueGetter: ({ row }) => row?.employee_day_off_left,
    },
    {
      field: "employee_created_at",
      headerName: "Ngày Tạo",
      hide: true,
      width: 150,
      renderCell: ({ value }) => format_date_short(value),
    },
    {
      field: "employee_updated_at",
      headerName: "Cập nhật lần cuối",
      width: 150,
      hide: true,
      renderCell: ({ value }) => format_date_short(value),
    },
    {
      field: "start_worked_date",
      headerName: "Ngày Bắt Đầu Làm Việc",
      width: 150,
      renderCell: ({ value }) => !!value && format_date_short(value),
    },
    {
      field: "end_worked_date",
      headerName: "Ngày Kết Thúc Làm Việc",
      width: 150,
      renderCell: ({ value }) => !!value && format_date_short(value),
    },
    {
      field: "actions",
      headerName: "",
      hideable: true,
      width: 40,
      renderCell: ({ row }) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <IconButton
              onClick={(event) => {
                setPopper((prev) => ({
                  ...prev,
                  anchorEl: event?.target,
                  data: row,
                }));
              }}
              size="small"
              title="Hành động"
            >
              <MoreVertOutlined fontSize="small" sx={{ m: "auto" }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const [columns, setColumns] = React.useState(initialColumns || []);
  const filterables = columns
    .filter(
      (column) => (column?.filterable || column?.operators) && !column?.hide
    )
    .map((column) => {
      return {
        field: column?.key ? column.key : column.field,
        label: column?.headerName,
        operators: column?.operators,
      };
    });
  const {
    data,
    isFetching,
    refetch: callback,
  } = EmployeeApi.List({
    page: page?.page + 1,
    pageSize: page?.pageSize || 20,
    query: Filter.stringify(query),
    searchQuery: filterableEmployee() === "" ? null : filterableEmployee(),
  });

  const {
    isLoading: ResetPasswordRequestLoading,
    mutateAsync: ResetPasswordRequestMutate,
  } = useMutation(EmployeeApi.ResetPasswordRequest);

  const { mutateAsync: DisabledMutate, isLoading: DisabledLoading } =
    useMutation(EmployeeApi.Disabled);

  const { mutateAsync: ResendToken } = useMutation(EmployeeApi.ResendToken)

  useEffect(() => {
    if (location.pathname === "/employees") {
      callback();
    }
  }, [location.pathname, callback]);

  const [actions] = React.useState([
    {
      order: 2,
      name: "update",
      icon: "Edit",
      label: "Chỉnh Sửa",
      href: {
        prefix: `/employees/`,
        key: "employee_id",
        suffix: "/update",
      },
    },
    {
      order: 2,
      name: "edit-work-dates",
      icon: "Date",
      label: "Chỉnh Sửa Ngày Làm Việc",
      handleClick: (values) => {
        setAction({ name: 'edit-word-dates', data: values })
        return setPopper({ anchorEl: undefined, data: undefined })
      }
    },
    {
      order: 3,
      name: "reset-password",
      label: "Reset Mật Khẩu",
      handleClick: (data) => {
        const handleReset = async (data) => {
          const response = await ResetPasswordRequestMutate({
            email_employee: data?.employee_email_company,
          });

          if (response?.errors)
            return setNotification({
              open: true,
              message: response?.errors?.[0]?.message || "Something went wrong",
              severity: "error",
            });

          setNotification({
            open: true,
            message: "Reset password thành công",
            severity: "success",
          });
        };

        showConfirmation({
          open: true,
          title: `Reset mật khẩu của ${data?.employee_name}`,
          description: "Password mới sẽ được gửi về mail của nhân viên",
          handler: () => handleReset(data),
        });
      },
    },
    {
      order: 4,
      name: "resend-token",
      label: "Resend Token",
      handleClick: (data) => {
        const handleReset = async (data) => {
          const response = await ResendToken({
            employeeId: data?.employee_id,
          });

          if (response?.errors)
            return setNotification({
              open: true,
              message: response?.errors?.[0]?.message || "Something went wrong",
              severity: "error",
            });

          setNotification({
            open: true,
            message: "Resend token thành công",
            severity: "success",
          });
        };

        showConfirmation({
          open: true,
          title: `Thông tin đã được gửi đến email công ty của bạn`,
          description: "Vui lòng đăng nhập vào email để nhận thông tin",
          handler: () => handleReset(data),
        });
      },
    },
    {
      order: 5,
      name: "edit-leave-day",
      label: "Chỉnh Sửa Phép Năm",
      href: {
        prefix: `/employees/`,
        key: "employee_id",
        suffix: "/update-leave-day",
      },
    },
    {
      order: 6,
      name: "cancel",
      icon: "Delete",
      label: "Huỷ Kích Hoạt",
      handleClick: (data) => {
        const handleDisabled = async (data) => {
          const response = await DisabledMutate({
            employee_id: data?.employee_id,
          });

          if (response?.errors)
            return setNotification({
              open: true,
              message: response?.errors?.[0]?.message || "Something went wrong",
              severity: "error",
            });

          setNotification({
            open: true,
            message: "Huỷ kích hoạt thành công",
            severity: "success",
          });
        };

        showConfirmation({
          open: true,
          title: `Huỷ kích hoạt nhân viên ${data?.employee_name}`,
          description:
            "Điều này có nghĩa là nhân viên sẽ bị mất hết khách hàng và account cũng sẽ bị khoá không thể đăng nhập vào DC",
          handler: () => handleDisabled(data),
        });
      },
    },
  ]);

  const [openDrawer, setOpenDrawer] = React.useState(false);

  return (
    <Box>
      {popper?.anchorEl !== undefined && (
        <Popper anchorEl={popper?.anchorEl} open={Boolean(popper.anchorEl)} style={{ zIndex: 1000 }}>
          <PopperAction
            {...popper}
            actions={actions}
            handleClose={() =>
              setPopper((prev) => ({
                ...prev,
                anchorEl: undefined,
                data: undefined,
              }))
            }
          />
        </Popper>
      )}
      {!!action?.name && React.createElement(ACTION_UIS['edit-work-dates'], {
        data: action?.data,
        onClose: () => setAction({ name: undefined, data: undefined })
      })}
      <Header handleClick={() => setOpenDrawer(true)} disabled={openDrawer} />
      {openDrawer && (
        <Drawer
          anchor="right"
          open={openDrawer}
          sx={{
            width: { xs: "100%", sm: 360, md: 450 },
            borderTopLeftRadius: "4px",
          }}
        >
          <Create
            handleClose={() => setOpenDrawer(false)}
            departments={departments?.departments}
            positions={positions?.positions}
            callback={callback}
          />
        </Drawer>
      )}
      <Statistical data={data?.pagination} />
      <DataGridFilter
        callback={updateQuery}
        filters={{
          key: "employee.list.filters",
          filterables: filterables,
        }}
      />
      <DataGrid
        rows={
          data?.employees?.map((employee) => ({
            ...employee,
            id: employee?.employee_id,
          })) || []
        }
        columns={columns}
        loading={isFetching || ResetPasswordRequestLoading || DisabledLoading}
        componentsProps={{
          toolbar: {
            store: "employee.list.hiddenColumns",
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: "employee",
            inputSearch: {
              placeholder:
                "Nhập tên, mã nhân viên, email hoặc số điện thoại tìm kiếm...",
              value: searchQuery,
              onChange: setSearchQuery,
            },
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize || 20,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        rowCount={data?.pagination?.total}
        pinnedColumns={{
          right: ["actions"],
        }}
      />
    </Box>
  );
};

const SkeletonList = () => {
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography className="page-title">Danh Sách Nhân Viên</Typography>
        <Skeleton
          variant="rectangular"
          sx={{ height: 42, borderRadius: "4px", width: 180 }}
        />
      </Box>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <Paper sx={{ p: 2 }}>
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem", width: 120, mb: 2 }}
            />
            <Skeleton variant="text" sx={{ fontSize: 28, width: 80 }} />
          </Paper>
        </Grid>
      </Grid>
      <SkeletonDataGrid filterEnabled={true} length={10} />
    </Box>
  );
};

export default withSuspense(
  withPermission(List, { feature: "employee", action: "index" }),
  SkeletonList
);
