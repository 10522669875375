import { Typography } from '@mui/material'
import React from 'react'
import { format_numeral_price } from "@/functions"

const FormatTextNumeralVND = (props) => {
    return (
        <Typography component="span" sx={{ ...props?.sx, fontSize: props?.sx?.fontSize || 14 }}>
            {format_numeral_price(props?.value) || 0}
            <Typography component="span" sx={{
                textDecoration: "underline",
                marginLeft: "4px",
                ...props?.sx
            }}>đ</Typography>
        </Typography>
    )
}

export default FormatTextNumeralVND