import InvoiceFilter from "./Invoice";
import ServiceFilter from "./Service";
import CustomerFilter from "./Customer";
import CustomerActive from "./CustomerActive";
import CustomerPOC from "./CustomerPOC";
import CustomerSTOP from "./CustomerSTOP";
import Contact from "./Contact";

const DynamicFilterables = {
    Invoice: InvoiceFilter,
    Service: ServiceFilter,
    CustomerQualifiedProspects: CustomerFilter,
    CustomerActive,
    CustomerPOC,
    CustomerSTOP,
    Contact
    // Add more filterable components here...
}
export default DynamicFilterables;