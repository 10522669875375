import ServiceTimeApi from "@/apis/beta/Service/ServiceTimeApi";
import { format_date_monent_js, queryClientRefetchScope } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import NumericFormatCustom from "@components/NumericFormatCustom";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useNotificationContext } from "@contexts/notification";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    Typography
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

const ServiceSetdateRenew = ({ service, onClose }) => {
    const queryClient = useQueryClient();
    const { setNotification } = useNotificationContext();
    const [submit, setSubmit] = useState(false);

    const { control, handleSubmit, watch } = useForm({
        defaultValues: {
            day_offset_renew: service?.day_offset_renew,
        },
    });

    const onSubmit = async (values) => {
        setSubmit(true);
        const response = await ServiceTimeApi.ChangeDateRenew({
            serviceId: service?.service_id,
            data: { ...values },
        });
        setSubmit(false);

        if (!response || response?.errors)
            return setNotification({
                open: true,
                message: response?.errors?.[0]?.message,
                severity: "error",
            });

        setNotification({
            open: true,
            message: `Đặt lịch sinh hoá đơn tự động thành công.`,
            severity: "success",
        });
        queryClientRefetchScope(queryClient, 'service')
        return onClose();
    };
    return (
        <Dialog open={true} maxWidth="md" fullWidth>
            <DialogTitle>
                Đặt Lịch Tạo Hoá Đơn Gia Hạn Tự Động
            </DialogTitle>
            <DialogContent>
                <Box sx={{
                    mt: 1.25,
                    p: '12px 16px',
                    border: '1px solid rgba(0, 0, 0, 0.18)',
                    borderRadius: '4px'
                }}>
                    <Typography
                        color="primary"
                        sx={{
                            fontSize: 14,
                            fontWeight: 'medium',
                            textDecoration: 'underline',
                        }}>
                        Ví dụ:
                    </Typography>
                    <Typography color="text.grey" sx={{
                        fontSize: 14,
                        mb: 1.25
                    }}>
                        Dịch vụ hết hạn ngày <b>31/12/2024</b>, nếu bạn cài đặt tạo hoá đơn gia hạn trước 14 ngày thì ngày <b>17/12/2024</b> hệ thống sẽ tư động tạo hoá đơn gia hạn cho dịch vụ này.
                    </Typography>
                    <TextField
                        disabled
                        value={service?.service_billing_enddate}
                        label="Ngày kết thúc dịch vụ hiện tại"
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        size="small"
                    />
                    <TextField
                        sx={{ mt: 2 }}
                        disabled
                        value={format_date_monent_js(moment(service?.service_billing_enddate).add(-parseInt(watch(`day_offset_renew`)), 'days'), 'yyyy-MM-DD')}
                        label="Ngày Sinh Hoá Đơn Tiếp Theo"
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        size="small"
                    />
                </Box>
                <Controller
                    name="day_offset_renew"
                    control={control}
                    rules={{ required: 'Vui lòng nhập đơn giá gia hạn dịch vụ...' }}
                    render={({ field, fieldState: { error } }) => {
                        return (
                            <FormControl fullWidth sx={{ mt: 1.5 }}>
                                <TextField
                                    {...field}
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="Cài đặt ngày sinh hoá đơn gia hạn *"
                                    InputProps={{
                                        inputComponent: NumericFormatCustom,
                                    }}
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            </FormControl>
                        );
                    }}
                />
                <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end", gap: 2 }}>
                    <Button
                        size="small"
                        variant="outlined"
                        disabled={submit}
                        onClick={onClose}
                    >
                        Huỷ Thao Tác
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        disabled={submit}
                        startIcon={submit && <CircularProgress size={14} />}
                    >
                        Lưu Thay Đổi
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default withSuspense(ServiceSetdateRenew, MuiSkeleton["LinearProgress"]);
