import DayoffApi from "@/apis/beta/Employee/DayoffApi";
import {
  customize_truncate,
  format_date_short,
  queryClientRefetcher,
} from "@/functions";
import { DAYOFF_STATUS_TYPES, DAYOFF_TYPE_TYPES } from "@/libs/constants";
import { useNotificationContext } from "@contexts/notification";
import DescriptionOutlined from "@mui/icons-material/DescriptionOutlined";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
import React from "react";
import { useQueryClient } from "react-query";
import DayoffFeedback from "./DayoffFeedback";
import DayoffRangeTime from "./DayoffRangeTime";

const Information = ({ data = {} }) => {
  const { setNotification } = useNotificationContext();

  const queryClient = useQueryClient();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [submit, setSubmit] = React.useState(false);

  const collectionInformation = () => {
    return [
      {
        mobile: 1,
        default: 1,
        label: "Mã Phiếu",
        value: data?.day_off_id,
      },
      {
        mobile: 2,
        default: 2,
        label: "Tên Nhân Viên",
        value: data?.employee_name,
      },
      {
        mobile: 3,
        default: 2,
        label: "Vị Trí",
        value: data?.employee_position_name,
      },
      {
        mobile: 4,
        default: 2,
        label: "Phòng Ban",
        value: data?.employee_department_name,
      },
      {
        mobile: 5,
        default: 2,
        label: "Loại Phiếu",
        value: DAYOFF_TYPE_TYPES.find((t) => t.value === data?.day_off_type)
          ?.label,
      },
      {
        mobile: 5,
        default: 2,
        label: "Số Ngày Nghỉ",
        value: data?.number_of_day_off,
      },
      {
        mobile: 6,
        default: 2,
        label: "Ngày Tạo",
        value: format_date_short(data?.day_off_created_at),
      },
      {
        mobile: 7,
        default: 2,
        label: "Trạng Thái",
        value: DAYOFF_STATUS_TYPES?.find(
          (t) => t?.value === parseInt(data?.day_off_status)
        )?.label,
      },
    ];
  };

  const handleCancelled = async () => {
    setSubmit(true);
    const response = await DayoffApi.Cancel(data?.day_off_id);
    setSubmit(false);
    if (!response || response.status !== 200 || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }
    setNotification({
      open: true,
      message: `Huỷ phiếu thành công.`,
      severity: "success",
    });

    queryClientRefetcher(queryClient, ["all_day_offs.list", "day_off.detail"]);
  };
  return (
    <Paper sx={{ p: 3 }} elevation={10}>
      <Box sx={{ display: "flex", mb: 2 }}>
        <IconButton className="information" sx={{ m: "auto 8px auto 0" }}>
          <DescriptionOutlined sx={{ color: "#FFF" }} />
        </IconButton>
        <Typography
          sx={{
            m: "auto 0",
            fontSize: 24,
            fontWeight: "medium",
            color: "#00000f",
          }}
        >
          #PN - {data?.day_off_id}
        </Typography>
      </Box>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        {_.sortBy(Array.from(collectionInformation()), [
          isMobile ? "mobile" : "default",
        ])?.map((item, index) => {
          if (item?.hidden) return null;
          return (
            <Grid item xl={3} lg={3} md={3} sm={12} xs={12} key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", md: "column" },
                  gap: 0.5,
                }}
              >
                <Typography sx={{ color: "#777680" }}>
                  {item?.label}:
                </Typography>
                <Typography>
                  {customize_truncate(item?.value, 35) || "Không có thông tin"}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <DayoffFeedback data={data} />
      <Typography
        component="div"
        sx={{
          m: "16px 4px 8px 0",
          color: "#777680",
        }}
      >
        Lý Do Xin Nghỉ:
        <Tooltip
          title="Lý do này chỉ có bạn, leader team và bod/ hr manager có thể xem được."
          placement="top"
          sx={{ m: "auto 0" }}
        >
          <HelpOutlineOutlined fontSize="small" sx={{ m: "auto 0" }} />
        </Tooltip>
      </Typography>
      <FormControl fullWidth>
        <TextField multiline disabled value={data?.day_off_reason} />
      </FormControl>
      <Box sx={{ mt: 2 }}>
        <Typography sx={{ mb: 0.5, color: "#777680" }}>
          Chi Tiết Phiếu Nghỉ:
        </Typography>
        <DayoffRangeTime data={data?.day_off_range_time || []} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button
          size="small"
          variant="contained"
          onClick={handleCancelled}
          startIcon={
            submit && <CircularProgress size={14} sx={{ color: "#777980" }} />
          }
          disabled={data?.day_off_status !== 0 || submit}
          sx={{
            height: { xs: 36, md: 42 },
            minWidth: 120,
          }}
        >
          Huỷ Phiếu
        </Button>
      </Box>
    </Paper>
  );
};

export default Information;
