import withPermission from '@/hocs/withPermission'
import { Box, Typography } from '@mui/material'
import React from 'react'
import FormPartnerContract from "../shared/FormPartnerContract"

const Create = ({
    handleClose,
    callback,
}) => {
    return (
        <Box sx={{ p: 3, width:{ xs: "auto", md: 550} , minWidth: 360 }}>
            <Typography className='page-title'>
                Thêm Hợp Đồng Đối Tác
            </Typography>
            <FormPartnerContract type="create" handleClose={handleClose} callback={callback} />
        </Box>
    )
}

export default withPermission(Create, { feature: 'contract', action: 'partner-contract-create' })