import React from "react";
import ServiceMainTabs from "../ServiceMainTabs";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <ServiceMainTabs />
      {children}
    </React.Fragment>
  );
};

export default Layout;
