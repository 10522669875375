import {
	Box,
	Button,
	CircularProgress,
	IconButton,
	TextField,
	Typography
} from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import useWaiting from '@/hooks/useWaiting';
import { vestResolver } from '@hookform/resolvers/vest';
import { RemoveRedEyeOutlined } from '@mui/icons-material';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import { indigo } from '@mui/material/colors';
import validators from './validators';

const PASSWORD_ICONS = {
	HIDE: RemoveRedEyeOutlined,
	SHOW: VisibilityOffOutlined
}

const LoginForm = ({ onSubmit }) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		resolver: vestResolver(validators),
	});

	const [isWaiting, onWaiting] = useWaiting(500);
	const handleOnSubmitting = (data) => onWaiting(() => onSubmit(data));

	const [showPass, setShowPass] = React.useState(false)

	return (
		<Box component='form' onSubmit={handleSubmit(handleOnSubmitting)}>
			<Box sx={{ mb: 3 }}>
				<Controller
					name='email'
					defaultValue=''
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<TextField
							{...field}
							type='text'
							label='Email (*)'
							error={!!errors.email}
							InputLabelProps={{ shrink: true }}
							InputProps={{
								endAdornment:
									<Typography sx={{ fontSize: 13, color: "#777680" }}>
										@vnetwork.vn
									</Typography>
							}}
							helperText={errors.email?.message}
							sx={{ minWidth: '100%', '& input': { fontSize: 13 } }}
						/>
					)}
				/>
			</Box>
			<Box sx={{ mb: 3 }}>
				<Controller
					name='password'
					defaultValue=''
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<TextField
							{...field}
							type={!showPass ? 'password' : 'text'}
							label='Password (*)'
							error={!!errors.password}
							InputProps={{
								endAdornment:
									<IconButton size='small'
										sx={{
											":hover": {
												color: indigo[900]
											}
										}}
										onClick={() => setShowPass(!showPass)}
									>
										{
											React.createElement(
												PASSWORD_ICONS[showPass ? "HIDE" : "SHOW"],
												{
													sx: { fontSize: { xs: 14, md: 16 } },
												}
											)
										}
									</IconButton>
							}}
							InputLabelProps={{ shrink: true }}
							helperText={errors.password?.message}
							sx={{ minWidth: '100%', '& input': { fontSize: 13 } }}
						/>
					)}
				/>
			</Box>
			<Box sx={{ mb: 3 }}>
				<Typography sx={{ fontSize: 14, textAlign: 'center' }}>
					If you forget your password, kindly reach out to the HR department for assistance.
				</Typography>
			</Box>
			<Button
				variant='contained'
				type='submit'
				disabled={isWaiting}
				startIcon={
					isWaiting ? (
						<CircularProgress
							size={16}
							sx={{ color: 'grey.500' }}
							disableShrink
						/>
					) : null
				}
				fullWidth
			>
				Login
			</Button>
		</Box>
	);
};

export default LoginForm;