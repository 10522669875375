import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { format_numeral_price, isObjEmpty } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box } from "@mui/material";

const ConversantService = ({ data }) => {

  const { data: servicePrice } = ServiceApi.PricingAndConfigoptions({ id: data?.service_id })
  if (!servicePrice) return ''

  if (data?.service_product_id === 305) {
    let price = 0

    servicePrice?.service_configoptions?.forEach(configoption => {
      if (!isObjEmpty(configoption?.commitment) && !!configoption?.commitment?.pricing_amount) {
        price += parseFloat(configoption?.commitment?.pricing_amount)
      }
    });
    if (price === 0) {
      price = servicePrice?.service_configoptions?.[0]?.pricing?.steps?.[0]?.pricing_apply
    }

    return (
      <Box sx={{
        display: "flex",
        justifyContent: "flex-end",
        width: "100%"
      }}>
        đ{format_numeral_price(price)}
      </Box>
    )
  }
  if (!servicePrice?.service_pricing_configure?.commitment || isObjEmpty(servicePrice?.service_pricing_configure?.commitment)) {
    let price = servicePrice?.service_pricing_configure?.steps?.[0]?.pricing_apply
    return (
      <Box sx={{
        display: "flex",
        justifyContent: "flex-end",
        width: "100%"
      }}>
        đ{format_numeral_price(price)}
      </Box>
    )
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
      đ{format_numeral_price(servicePrice?.service_pricing_configure?.commitment?.pricing_amount)}
    </Box>
  );
};

export default withSuspense(ConversantService, MuiSkeleton["Text"]);
