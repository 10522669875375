import ProviderApi from "@/apis/beta/Provider/ProviderApi";
import ManufactureApi from "@/apis/beta/Warehouse/ManufactureApi";
import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import WarehouseModelApi from "@/apis/beta/Warehouse/WarehouseModelApi";
import { format_date_short } from "@/functions";
import useDataGridParams from "@/hooks/useDataGridParams";
import { WAREHOUSE_STATUS_BUY } from "@/libs/constants";
import DataGridCustomize from "@components/DataGrid";
import Cell from "@components/DataGrid/Cell";
import CellConfig from "@components/DataGrid/CellConfig2";
import CellSpecification from "@components/DataGrid/CellSpecification";
import { Box, Link } from "@mui/material";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import Filters from "../../WarehouseDepreciations/Depreciations/Filters";
import DeviceAnalysist from "../Statistic";
import { useEffect } from "react";

const WarehouseItemDataGridAccessory = ({ types_id, name }) => {
  const initialColumns = [
    {
      field: "warehouse_item_type",
      headerName: "Loại",
      minWidth: 120,
      valueGetter: ({ row }) =>
        `${_.find(warehouse_models, {
          warehouse_model_id: parseInt(
            row?.warehouse_model?.warehouse_model_id
          ),
        })?.warehouse_type?.warehouse_type_name
        }`,
      renderCell: ({ value, row }) => <Cell content={value?.toUpperCase()} />,
    },
    {
      field: "warehouse_item_name",
      headerName: "Tên",
      width: 250,
      renderCell: ({ value, row }) => (
        <Link
          component={NavLink}
          to={`/warehouse/items/${row?.warehouse_item_id}/detail`}
        >
          {" "}
          <Cell content={value?.toUpperCase()} />
        </Link>
      ),
    },
    {
      field: "capacity",
      headerName: "Capacity",
      width: 200,
      sortable: true,
      renderCell: ({ value }) => value && <CellConfig configures={value} />,
    },
    {
      field: "warehouse_item_import_time",
      headerName: "Lần nhập của năm",
      width: 200,
    },
    {
      field: "warehouse_item_service_tag",
      headerName: "Service Tag / Serial Number",
      sortable: false,
      width: 250,
      renderCell: ({ value, row }) => <Cell content={value} />,
    },
    {
      field: "network",
      headerName: "Network",
      hide:
        [2, 20].filter((o) => [Number(types_id)].includes(o)).length === 0
          ? true
          : false,
      width: 300,
      sortable: false,
      valueGetter: ({ row }) => {
        let networks = _.filter(row?.children, (o) => {
          return ["CARD NETWORK", "MODULE"].includes(
            o?.warehouse_type?.warehouse_type_name
          );
        });
        networks = _.map(networks, (o) => {
          return {
            label: `${o?.warehouse_item_name} `,
            value: o?.total,
          };
        });
        return networks;
      },
      renderCell: ({ value }) => <CellSpecification items={value} />,
    },
    {
      field: "specification",
      headerName: "Thông số kỹ thuật",
      width: 700,
      valueGetter: ({ row }) => {
        return _.map(row?.warehouse_model?.specifications, (o) => ({
          label: `${o?.specification_name} : ${o?.specification_value || ""} ${o?.specification_unit || ""
            }  `,
          value: null,
        }));
      },
      renderCell: ({ value }) => <CellSpecification items={value} />,
    },
    {
      field: "warehouse_box_name",
      headerName: "Kho/Datacenter",
      valueGetter: ({ row }) => {
        if (
          row?.warehouse_box_item &&
          row?.warehouse_box_item?.warehouse_box_item_reference_type ===
          "WarehouseBox"
        ) {
          const box = _.find(warehouse_boxes, {
            warehouse_box_id:
              row?.warehouse_box_item?.warehouse_box_item_reference_id,
          });
          return box;
        } else if (
          row?.warehouse_box_item &&
          row?.warehouse_box_item?.warehouse_box_item_reference_type ===
          "WarehouseRack" &&
          row?.warehouse_rack
        ) {
          const box = _.find(warehouse_boxes, {
            warehouse_box_id: row?.warehouse_rack?.warehouse_box_id,
          });
          return box;
        }
        return null;
      },
      renderCell: ({ value, row }) => (
        <>
          {value ? (
            value?.warehouse_box_type === 0 ? (
              <>{value?.warehouse_box_name}</>
            ) : (
              <Link
                component={NavLink}
                to={`/warehouse/boxes/${value?.warehouse_box_id}`}
              >
                {" "}
                <Cell content={value?.warehouse_box_name?.toUpperCase()} />{" "}
              </Link>
            )
          ) : (
            ""
          )}
        </>
      ),
      width: 200,
    },
    {
      field: "warehouse_box_rack",
      headerName: "Tủ Rack",
      valueGetter: ({ row }) => {
        if (
          row?.warehouse_box_item &&
          row?.warehouse_box_item?.warehouse_box_item_reference_type ===
          "WarehouseRack" &&
          row?.warehouse_rack
        ) {
          return row?.warehouse_rack;
        }
        return null;
      },
      renderCell: ({ value, row }) => (
        <>
          {value ? (
            <Link
              component={NavLink}
              to={`/warehouse/racks/${value?.warehouse_rack_id}`}
            >
              {" "}
              <Cell content={value?.warehouse_rack_name?.toUpperCase()} />{" "}
            </Link>
          ) : (
            ""
          )}
        </>
      ),
      width: 200,
    },
    {
      field: "warehouse_box_position",
      headerName: "Vị Trí",
      valueGetter: ({ row }) => {
        if (
          row?.warehouse_box_item &&
          row?.warehouse_box_item?.warehouse_box_item_reference_type ===
          "WarehouseRack" &&
          row?.warehouse_rack &&
          row?.warehouse_box_item?.warehouse_box_item_position !== 0
        ) {
          return (
            row?.warehouse_box_item?.warehouse_box_item_position +
            (row?.warehouse_item_form_factor === 0 ||
              row?.warehouse_item_form_factor === 1
              ? ""
              : ` - ${row?.warehouse_box_item?.warehouse_box_item_position +
              (row?.warehouse_item_form_factor - 1)
              }`)
          );
        }
        return "";
      },
      renderCell: ({ value, row }) => <Cell content={value?.toUpperCase()} />,
      width: 200,
    },
    {
      field: "warehouse_item_description",
      headerName: "Mô tả",
      valueGetter: ({ row }) =>
        row?.warehouse_box_item?.warehouse_box_item_description,
      renderCell: ({ value, row }) => <Cell content={value} />,
      width: 150,
    },
    {
      field: "manufacture_id",
      headerName: "Nhà Sản Xuất",
      valueGetter: ({ row }) => {
        return manufactures?.find(
          (item) => item?.value === row?.warehouse_model?.manufacture_id
        )?.label;
      },
      width: 150,
    },
    {
      field: "provider_id",
      headerName: "Nhà Cung Cấp",
      valueGetter: ({ row }) =>
        _.find(providers, { provider_id: row?.provider_id })?.provider_name,
      renderCell: ({ value, row }) => <Cell content={value?.toUpperCase()} />,
      width: 150,
    },
    // {
    //     field: 'warehouse_item_availability',
    //     headerName: 'Có Sẳn (Stock Status)',
    //     renderCell: ({value})=> <LensIcon sx={{m: "auto", color: value ? grey[500] : green [900] }} />,
    //     width: 200,
    // },
    {
      field: "warehouse_item_status",
      headerName: "Trình Trạng",
      valueGetter: ({ row }) =>
        _.find(WAREHOUSE_STATUS_BUY, {
          value: parseInt(row?.warehouse_item_status),
        })?.label,
      width: 150,
    },
    {
      field: "warehouse_item_import_at",
      headerName: "Ngày Mua",
      valueGetter: ({ row }) => format_date_short(row?.warehouse_item_import_at),
      width: 200,
    },
    {
      field: "warehouse_item_warranty_expired_at",
      headerName: "Ngày Hết Hạn Bảo Hành",
      valueGetter: ({ row }) =>
        format_date_short(row?.warehouse_item_warranty_expired_at),
      width: 200,
    },
    // {
    //     field: 'employee_id_modified',
    //     headerName: 'Nhân Viên Chỉnh Sửa',
    //     valueGetter: ({ row }) => _.find(employees, {employee_id: row.employee_id_modified})?.employee_name,
    //     width: 200,
    // },
  ];

  const {
    columns,
    debouncedSearchQuery,
    page,
    searchQuery,
    setColumns,
    setPage,
    setSearchQuery,
    query,
    updateQuery,
  } = useDataGridParams(initialColumns);

  const { data, isFetching } = WarehouseItemApi.ItemsCompany({
    type: types_id,
    page: page?.page + 1 || 1,
    pageSize: page?.pageSize || 20,
    searchQuery: debouncedSearchQuery,
    query,
  });

  const { data: warehouse_boxes } = WarehouseBoxApi.getAll();
  const { data: providers } = ProviderApi.getAll();
  const { data: manufactures } = ManufactureApi.getAll();
  const { data: warehouse_models } = WarehouseModelApi.All({ short: true });
  useEffect(() => {
    setPage((prevState) => ({ ...prevState, page: 0 }))
  },[searchQuery])
  return (
    <Box>
      <DeviceAnalysist
        debouncedSearchQuery={debouncedSearchQuery}
        type={types_id}
        device={false}
        query={query+"&filters[$and][0][warehouse_items.warehouse_item_parent][$eq]=0"}
      />

      <Filters
        updateQuery={updateQuery}
        query={query}
        types_ids={types_id}
        useFilterModel
        customer={false}
      />

      <DataGridCustomize
        rows={data?.warehouse_items || []}
        columns={columns}
        loading={isFetching}
        rowCount={data?.pagination?.total_pages * page?.pageSize || 0}
        getRowHeight={({ model }) => {
          const ROW_HEIGHT = 24;
          const PADDING_Y = 8;
          return Math.max(
            Object.keys(
              _.omit(model?.capacity, [
                "capacity_updated_at",
                "capacity_created_at",
                "warehouse_item_id",
                "capacity_id",
              ])
            )?.filter((item) => model?.capacity[item]).length *
            ROW_HEIGHT +
            PADDING_Y,
            52
          );
        }}
        componentsProps={{
          toolbar: {
            store: `${name}?.list.hiddenColumns`,
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: name,
            inputSearch: {
              placeholder: "Tìm kiếm,...",
              value: searchQuery,
              onChange: setSearchQuery,
            },
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize || 20,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

export default WarehouseItemDataGridAccessory;
