import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

import DashboardWelcome from '../shared/DashboardWelcome';
import DayoffInformation from '../shared/DayoffInformation';
import BusinessInformation from '../shared/BusinessInformation';
import Schedule from '../shared/Schedule';
import Leave from '../shared/DayoffReport';
import UpcomingSchedule from '../shared/Schedule/Upcoming';

const Default = () => {
  return (
    <Box>
      <Typography sx={{ fontSize: 14, color: 'grey.700' }}>
        Dashboard
      </Typography>
      <DashboardWelcome />
      <Grid container spacing={2}>
        <Grid item xl={8.5} lg={8.5} md={8} sm={12} xs={12}>
          <Leave />
          <UpcomingSchedule />
        </Grid>
        <Grid item xl={3.5} lg={3.5} md={4} sm={12} xs={12}>
          <Schedule />
          <DayoffInformation />
          <BusinessInformation />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Default;
