import { Box, Button, Fade, Stack } from '@mui/material'
import { useState } from 'react'
import VMSTranscodingDurtion from '../VMS/TranscodingDuration'
import VMSTranscodingUsage from '../VMS/TranscodingUsage'

import LMSTranscodingDurtionLive from '../LMS/DurationLive'
import NoLiveJobs from '../LMS/LiveJobs'
import LMSTranscodingDurations from '../LMS/TranscodingDurations'

const TranscodingAnalytics = ({ service }) => {
    const [active, setActive] = useState('lms')
    return (
        <Box>
            <Stack direction="row" sx={{ bgcolor: "#7776801F" }}>
                <Button size='small' color={active === 'lms' ? 'error' : 'primary'} onClick={() => setActive('lms')}>LMS</Button>
                <Button size='small' color={active === 'vms' ? 'error' : 'primary'} onClick={() => setActive('vms')}>VMS</Button>
            </Stack>
            <Fade in={active === 'lms'}>
                <Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 2,
                        flexDirection: { xs: 'column', md: 'row' }
                    }}>
                        {active === 'lms' && <NoLiveJobs service={service} />}
                        {active === 'lms' && <LMSTranscodingDurtionLive service={service} />}
                    </Box>
                    {active === 'lms' && <LMSTranscodingDurations service={service} />}
                </Box>
            </Fade>
            <Fade in={active === 'vms'}>
                <Box>
                    {active === 'vms' && <VMSTranscodingDurtion service={service} />}
                    {active === 'vms' && <VMSTranscodingUsage service={service} />}
                </Box>
            </Fade>
        </Box>
    )
}

export default TranscodingAnalytics