import { Typography } from "@mui/material";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CoPresentOutlinedIcon from "@mui/icons-material/CoPresentOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import SlowMotionVideoOutlinedIcon from "@mui/icons-material/SlowMotionVideoOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

export function formatFileSize(bytes) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
}

export const returnNameAndIcon = (key) => {
  let icons = <></>;
  const name = key.split("/")?.[key.split("/")?.length - 1];
  const typeFile = name.split(".")?.[name.split(".").length - 1];

  switch (typeFile?.toLowerCase()) {
    case "docx":
    case "doc":
      icons = <ArticleOutlinedIcon sx={{ color: "#3b82f6", mr: 2 }} />;
      break;

    case "xlsx":
    case "xls":
      icons = <CloseOutlinedIcon sx={{ color: "#22c55e", mr: 2 }} />;
      break;
    case "ppt":
    case "pptx":
      icons = <CoPresentOutlinedIcon sx={{ color: "#ef4444", mr: 2 }} />;
      break;
    case "pdf":
      icons = <PictureAsPdfOutlinedIcon sx={{ color: "#f97316", mr: 2 }} />;
      break;
    case "gif":
    case "jpg":
    case "jpeg":
    case "jfif":
    case "pjpeg":
    case "pjp":
    case "png":
    case "svg":
    case "webp":
      icons = <ImageOutlinedIcon sx={{ color: "#f43f5e", mr: 2 }} />;
      break;
    case "mp4":
    case "mov":
    case "MOV":
    case "m4a":
    case "mp3":
    case "mkv":
      icons = <SlowMotionVideoOutlinedIcon sx={{ color: "#d946ef", mr: 2 }} />;
      break;

    default:
      icons = <InsertDriveFileOutlinedIcon sx={{ mr: 2 }} />;
  }

  return { icons, name, type: typeFile };
};

export function returnIconsFileName(key) {
  const { icons, name } = returnNameAndIcon(key);

  return (
    <Typography
      fontSize="14px"
      fontWeight="400"
      display="flex"
      alignItems="center"
    >
      {icons}
      {name}
    </Typography>
  );
}
