import ReportWatchApi from '@/apis/beta/Threshold/Report/ReportWatchApi';
import DataGridFilter from "@/components/DataGrid/Filter";
import useFilters, { Filter } from "@/hooks/useFilters";
import ItemMenuOptions from '@components/TechnicalReportService/itemMenuOptions';
import { AddOutlined } from '@mui/icons-material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import ReplayIcon from '@mui/icons-material/Replay';
import { Box, Button, Chip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import DialogAddReport from './Dialog/DialogAddReport';
import DialogEditReportData from './Dialog/DialogEditReportData';
import DialogHistoryReport from './Dialog/DialogHistoryReport';
//new
import DataGrid from "@/components/DataGrid";

import {
  format_date_short,
} from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import TaskStatus from '@components/TechnicalReportService/taskstatus';
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useNotificationContext } from "@contexts/notification";
import DialogConfirmChangeStatus from './Dialog/DialogConfirmChangeStatus';
import DialogDateTimePicker from './Dialog/DialogDatePicker';
import DialogResendReport from './Dialog/DialogResendReport';

//
const Report = () => {
  const location = useLocation();
  const [openAddReportDialog, setOpenAddReportDialog] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  //filter
  const [query, updateQuery] = useFilters("reportes.filters", location);
  const [searchQuery, setSearchQuery] = useState("");
  const [state, setState] = useState({
    action: undefined,
    payload: undefined
  })
  const onClose = () => setState({ action: undefined, payload: undefined })

  const { setNotification } = useNotificationContext();

  const historyColumsHidden =
    JSON.parse(localStorage.getItem("reportes.hiddenColumns")) || [];

  const {
    isFetching,
    data,
    refetch: callbackReports,
  } = ReportWatchApi.List({
    page: pageNumber + 1,
    pageSize: pageSize,
    query: (query?.$and || query?.$or) ? Filter.stringify(query) : null,
    searchQuery: searchQuery
  });

  const initialColumns = [
    {
      field: "report_id",
      headerName: "ID",
      width: 60,
    },
    {
      field: "report_name",
      headerName: "Tên khách hàng",
      width: 200,
      operators: { type: "string" },
    },
    {
      field: "report_priority",
      headerName: "Mức độ ưu tiên",
      width: 175,
      operators: { type: "number" },
    },
    {
      field: "report_interval",
      headerName: "Tần suất gửi",
      width: 100,
      operators: { type: "string" },
    },
    {
      field: "report_scope",
      headerName: "Hình thức gửi",
      width: 150,
      operators: { type: "string" },
    },
    {
      field: "reports.report_created_at",
      headerName: "Ngày đăng ký dịch vụ",
      width: 200,
      operators: {
        type: "date",
      },
      valueGetter: ({ row }) => row?.report_created_at,
      renderCell: ({ row, value }) => (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ fontSize: 14, m: "auto 0" }}
          >
            {format_date_short(value)}
          </Typography>
        </Box>
      ),
    },
    {
      field: "reports.report_status",
      headerName: "Trạng thái",
      width: 150,
      // hideable: true,
      valueGetter: ({ row }) =>
        row?.report_status,
      renderCell: ({ value }) =>
        <Chip size="small" label={value} color={value === "active" ? "success" : "error"} variant='outlined' />,
      operators: {
        type: "string",
      },
    },
    {
      field: "reports.report_date",
      headerName: "Ngày gửi gần nhất",
      width: 150,
      valueGetter: ({ row }) =>
        row?.report_date,
      renderCell: ({ value }) =>
        value
    },
    {
      field: "reports.report_sent_status",
      headerName: "Trạng thái gửi",
      width: 150,
      valueGetter: ({ row }) =>
        row?.report_sent_status,
      renderCell: ({ value }) =>
        value
    },
    {
      field: "reports.report_file_status",
      headerName: "Trạng thái file",
      width: 150,
      valueGetter: ({ row }) =>
        row?.report_file_status,
      renderCell: ({ value }) =>
        value
    },
    {
      field: "action",
      headerName: "Tùy chỉnh",
      width: 100,
      hideable: false,
      valueGetter: ({ row }) =>
        row?.action,
      renderCell: ({ value }) =>
        value
    }
  ];
  const [columns, setColumns] = React.useState(
    initialColumns?.map((column) => {
      if (historyColumsHidden?.includes(column?.field)) {
        return { ...column, hide: true };
      }
      return { ...column };
    }) || initialColumns
  );
  const filterables = columns
    .filter((column) => !!column?.operators && !column?.hide)
    .map((column) => {
      return {
        field: column?.key ? column.key : column.field,
        label: column?.headerName,
        operators: column?.operators,
      };
    });

  const handleOpenHistoryDialog = (report_id, report_name, report_uuid, report_interval) => {
    return setState({
      action: "histories",
      payload: {
        report_uuid,
        historyName: report_name,
      }
    });
  }
  const handleOpenEditData = useCallback((report_uuid, report_name) => {
    return setState({ action: "edit", payload: { report_uuid: report_uuid, report_name: report_name } });
  }, []);

  const handOpenAddReport = () => {
    setOpenAddReportDialog(true);
  }
  const handleCloseAddReport = () => {
    setOpenAddReportDialog(false);
  }

  const handleChangePageSize = (pageSize) => {
    setPageSize(pageSize);
  }
  const handleChangePageNumber = (number) => {
    setPageNumber(number);
  }
  data?.reportList?.sort((a, b) => {
    return a?.report_id - b?.report_id;
  })

  const handleAcceptResendReport = () => {
    setNotification({
      open: true,
      message: `Đã gửi báo cho ${state?.payload?.report_name} thành công`,
      severity: "success",
    })
    onClose();
  }

  const mountComponent = () => {
    const params = {
      refetch: callbackReports,
      handleClose: onClose,
      open: true,
      setNotification: setNotification,
      handleAcceptResend: handleAcceptResendReport,
      ...state?.payload
    }


    switch (state?.action) {
      case "edit": return <DialogEditReportData {...params} />;
      case "histories": return <DialogHistoryReport {...params} />;
      case "view": return <DialogDateTimePicker {...params} />
      case "resend": return <DialogResendReport {...params} />;
      case "change-status": return <DialogConfirmChangeStatus {...params} />;
      default: return null
    }
  }
  const rows = () => {
    return  data?.reportList?.map((report) => {
      const options = {
        name: "Tùy chọn",
        children: [
          {
            iconElement: <ReplayIcon />,
            tittle: "Gửi lại báo cáo",
            function: () => setState({
              action: 'resend',
              payload: {
                // report_id: report?.report_uuid,
                report_uuid: report?.report_uuid,
                report_name: report?.report_name,
                report_interval: report?.report_interval,
              }
            })
          },
          {
            iconElement: report.report_status === 'active' ? <NotificationsOffIcon /> : <NotificationsActiveIcon />,
            tittle: report.report_status === 'active' ? "Hủy đăng ký báo cáo" : "Đăng ký báo cáo",
            function: () => setState({
              action: 'change-status',
              payload: {
                // report_id: report?.report_uuid,
                report_uuid: report?.report_uuid,
                report_status: report?.report_status,
                report_name: report?.report_name,
              }
            })
          },
          {
            iconElement: <HistoryToggleOffIcon />,
            tittle: "Lịch sử báo cáo",
            function: () => handleOpenHistoryDialog(report?.report_id, report?.report_name, report?.report_uuid, report?.report_interval)
          },
          {
            iconElement: <AssessmentIcon />,
            tittle: "Xem báo cáo",
            function: () => setState({
              action: 'view',
              payload: {
                report_uuid: report?.report_uuid,
                report_name: report?.report_name,
              }
            })
          },
          {
            iconElement: <ContactEmergencyIcon />,
            tittle: "Sửa thông tin",
            function: () => handleOpenEditData(report?.report_uuid, report?.report_name)
          }
        ]
      }
      const taskStatusElement = <TaskStatus uuid={report?.report_uuid} query="status" />
      const taskDateElement = <TaskStatus uuid={report?.report_uuid} query="date" />
      const taskFileElement = <TaskStatus uuid={report?.report_uuid} query="checkFile" />
      return {
        ...report,
        id: report?.report_id,
        report_date: taskDateElement || "None",
        report_sent_status: taskStatusElement || "None",
        report_file_status: taskFileElement || "None",
        action:
          <ItemMenuOptions options={options} />
      }
    }) || []
  } 
  useEffect(() => {
    setPageNumber(0);
  }, [searchQuery])
  return (
    <Box sx={{ width: "100%", height: "100vh", display: "block", mb: 2 }}>
      {mountComponent()}
      <Box
        sx={{
          mb: 2,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 1.5,
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontWeight: "medium",
            fontSize: { xs: 18, md: 24 },
          }}
        >
          Quản Lý Báo Cáo (REPORT WAF)
        </Typography>
        {/* <Link to="/technical/report" replace> */}
        <Button
          size="small"
          variant="outlined"
          sx={{
            minWidth: { xs: "100%", md: 150 },
            height: { xs: 36, md: 42 },
          }}
          startIcon={<AddOutlined fontSize="small" />}
          onClick={() => handOpenAddReport()}
        >
          Thêm Báo Cáo
        </Button>
        {/* </Link> */}
      </Box>
      <DataGridFilter
        callback={updateQuery}
        filters={{
          key: "reportes.filters",
          filterables: filterables,
        }}
      />
      <DataGrid
        rows={rows()}
        columns={columns}
        loading={isFetching}
        componentsProps={{
          toolbar: {
            store: "reportes.hiddenColumns",
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: "report",
            inputSearch: {
              placeholder:
                "Nhập tên khách hàng, ...",
              value: searchQuery,
              onChange: setSearchQuery
            },
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: pageNumber || 0,
            pageSize: pageSize || 10,
            onPageChange: (newPage) =>
              handleChangePageNumber(newPage),
            onPageSizeChange: (newPageSize) =>
              handleChangePageSize(newPageSize)
          },
        }}
        rowCount={data?.pagination?.total}
      />
      <DialogAddReport
        refetch={callbackReports}
        open={openAddReportDialog}
        handleClose={handleCloseAddReport}
      />
    </Box>
  );
}

// export default Report;
export default withSuspense(
  withPermission(Report, { feature: "technical", action: "reports" }),
  MuiSkeleton["DataGrid"]
);

