import { Box, Typography } from '@mui/material'
import { AddOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Link } from 'react-router-dom';
import React from 'react'

const Header = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography sx={{ fontSize: { xs: 18, md: 24 }, fontWeight: "medium" }}>
                Danh Sách Công Tác
            </Typography>
            <Link to="/assignee_business" replace>
                <Button
                    variant="outlined"
                    size="small"
                    startIcon={<AddOutlined fontSize="small" />}
                    sx={{
                        height: { xs: 40, md: 42 },
                        minWidth: { xs: "100%", md: 180 },
                    }}
                >
                    Thông báo
                </Button>
            </Link>
        </Box>
    )
}

export default Header