import { Chip, Typography } from '@mui/material'
import React from 'react'

const FormatterStatus = ({
    component = '',
    data = {}
}) => {
    return (
        <React.Fragment>
            {component === "muiChip" && <Chip size='small' {...data?.muiChip} label={data?.label || '---'} sx={{ minWidth: 100 }} />}
            {component === "muiTypography" && <Typography {...data?.muiTypography} sx={{ fontSize: 14 }}>{data?.label || '---'}</Typography>}
            {!component && <Typography sx={{ fontSize: 14, color: '#777680' }}>{data?.label || '---'}</Typography>}
        </React.Fragment>
    )
}

export default FormatterStatus