import DataGrid from "@/components/DataGrid";
import { Box } from "@mui/material";
import clsx from "clsx";
import { Fragment, useMemo } from "react";
import SwiftCDNServiceLibs from "../functions";
import ServiceActivities from "../../../shared/Service/ServiceActivities";
import ServiceApi from "@/apis/beta/Service/ServiceApi";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import ServiceManager from "../../shared/ServiceManager";

const SwiftCDNService = ({ service, addons }) => {

    const { data } = ServiceApi.PricingAndConfigoptions({ id: service?.service_id })
    const { data: configs } = ServiceApi.PackageConfigs({ serviceId: service?.service_id })

    const initialColumns = useMemo(() => {
        return [...SwiftCDNServiceLibs.rows({ swiftCDNs: configs, swiftCDN: service, swiftCDNConfigs: data?.service_configoptions || [] })]
    }, [service, configs, data]);

    return (
        <Fragment>
            <ServiceActivities activities={SwiftCDNServiceLibs.activities} service={service} />
            <Box sx={{ mt: "10px" }}>
                <DataGrid
                    initialState={{
                        pinnedColumns: { right: ["actions"] },
                    }}
                    rows={
                        initialColumns?.map((service, index) => ({
                            ...service,
                            id: index + 1,
                        })) || []
                    }
                    columns={SwiftCDNServiceLibs?.columns({ swiftCDNConfigs: data?.service_configoptions || [] })}
                    components={{
                        Toolbar: "disabled",
                        Pagination: "disabled",
                    }}
                    disableSelectionOnClick
                    disableColumnFilter
                    getRowClassName={({ row }) => {
                        return clsx(`grid-dc-vnetwork`, row?.classNames || "");
                    }}
                />
            </Box>
            <ServiceManager service={service} />
        </Fragment>

    );
};
export default withSuspense(SwiftCDNService, MuiSkeleton["DataGrid"]);