import { encodeBase64, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class ServiceSubscriptionApi {
    List = ({
        serviceId = null,
        query = null,
        page = 1,
        pageSize = 20
    }) => {
        return useQueryWithCustomized(
            ["service.subscription.list", serviceId, query, page, pageSize],
            async () => {
                return await requestWithToken(`${API_V3_URL}/services/${serviceId}/service_subscriptions?${query ? `&filters=${encodeBase64(query)}` : ''}&page=${page}&page_size=${pageSize}`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return {
                            subscriptions: data?.data?.subscriptions,
                            pagination: data?.data?.pagination
                        }
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            }
        );
    }

    CreateCounterInvoice = async ({ serviceId }) => {
        return await requestWithToken(`${API_V3_URL}/services/${serviceId}/service_invoices/create_invoice_counter`, "POST",
            {}, {}, {})
            .then(({ data }) => {
                if (data?.error) return data;
                return data?.data;
            })
            .catch((error) => {
                return error?.response
            });
    }

    Report = async ({
        serviceId = -1,
        payload = {
            startdate: "",
            enddate: ""
        }
    }) => {
        return await requestWithToken(`${API_V3_URL}/services/${serviceId}/service_subscriptions/report`, "POST", {
            report: { ...payload }
        }, {}, {})
            .then(({ data }) => {
                if (data?.error) return data;
                return data?.data?.report;
            })
            .catch((error) => {
                return error.response;
            })
    }

    Create = async ({
        serviceId = -1,
        payload = {
            startdate: null,
            enddate: null,
            amount: 0
        }
    }) => {
        return await requestWithToken(`${API_V3_URL}/services/${serviceId}/service_subscriptions`, "POST", {
            service_subscription: { ...payload }
        }, {}, {})
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                return error.response;
            })
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ServiceSubscriptionApi();
