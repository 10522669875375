import EmployeeApi from '@/apis/beta/Employee/EmployeeApi'
import withSuspense from '@/hocs/withSuspense'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, FormControl, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ScheduleOwner = () => {

    const { data } = EmployeeApi.ListEmployees({ page: 1, pageSize: 999 })
    const { control, formState: { errors } } = useFormContext()
    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1, mt: 2 }}>
            <Box sx={{ width: 300, minWidth: 300, display: 'flex' }}>
                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                    Người Đại Diện/ Tổ Chức *
                </Typography>
            </Box>
            <Controller
                name="schedule_owner_id"
                control={control}
                render={({ field: { value, onChange } }) => {
                    const selected = data?.find(item => item?.employee_id === value) || null
                    return (
                        <FormControl fullWidth>
                            <SingleSelectCustomize
                                value={selected}
                                options={data}
                                input={{
                                    placeholder: "Nhập hình thức hoạt động tìm kiếm..."
                                }}
                                formatCollection={{
                                    label: "employee_name",
                                    value: "employee_id"
                                }}
                                compareAttributes={["employee_id", "employee_name"]}
                                onChange={(event, newValue) => onChange(newValue?.employee_id || -1)}
                            />
                            {!errors?.schedule_owner_id && <ErrorHelperText message={errors?.schedule_owner_id?.message} />}
                        </FormControl>
                    )
                }} />
        </Box>
    )
}

export default withSuspense(ScheduleOwner, MuiSkeleton["FormControlTextField"])