import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { queryClientRefetchScope } from "@/functions";
import SaleTracking from "@components/Components/SaleTracking";
import { useNotificationContext } from "@contexts/notification";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

const SaleCommission = ({ data, onClose }) => {
  const { mutateAsync, isLoading } = useMutation(ServiceApi.ChangeServiceCommissions);
  const { setNotification } = useNotificationContext();
  const queryClient = useQueryClient();
  const methods = useForm({
    defaultValues: { employee_sale_ids: data?.employee_sale_ids },
  });
  const onSubmit = async (values) => {
    const response = await mutateAsync({
      serviceId: data?.service_id,
      data: values,
    });
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }

    queryClientRefetchScope(queryClient, "service");
    onClose();
    return setNotification({
      open: true,
      message: "Thay đổi người hưởng hoa hồng dịch vụ thành công.",
      severity: "success",
    });
  };
  return (
    <FormProvider {...methods}>
      <Dialog
        open={true}
        maxWidth="md"
        fullWidth
        component="form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <DialogTitle>Cập Nhật Thông Tin Người Hưởng Hoa Hồng</DialogTitle>
        <DialogContent sx={{ minHeight: 300 }}>
          <Controller
            name="employee_sale_ids"
            control={methods.control}
            render={({ field: { value, onChange } }) => {
              return (
                <SaleTracking
                  name="employee_sale_ids"
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            disabled={isLoading}
            variant="outlined"
            onClick={onClose}
          >
            Huỷ Thao Tác
          </Button>
          <Button
            size="small"
            variant="contained"
            type="submit"
            disabled={isLoading}
            startIcon={
              isLoading && (
                <CircularProgress size={14} sx={{ color: "#777680" }} />
              )
            }
          >
            Lưu Thay Đổi
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default SaleCommission;
