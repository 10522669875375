import Default from './Default';
import Development from './Developer';
import Accountant from './Accountant';
import Sale from './Sale';
import CustomerCare from './CustomerCare';
import HR from './HR';


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Default,
  Development,
  Accountant,
  Sale,
  CustomerCare,
  HR
};
