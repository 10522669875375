import NumericFormatCustom from '@components/NumericFormatCustom'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import FORM_BASE from '@components/shared/FormCustomized/form'
import { FormControl, Grid } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

const ProductPrice = () => {
    const { control } = useFormContext()
    return (
        <>
            <Grid container spacing={2} sx={{ mt: 0 }}>
                <Grid item xs={12} md={6} />
                <Grid item xs={12} md={6} xl={6}>
                    <Controller
                        name="product_pricing_amount"
                        control={control}
                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                            <FormControl fullWidth>
                                <FORM_BASE.TextField
                                    InputProps={{
                                        inputComponent: NumericFormatCustom
                                    }}
                                    label="Đơn giá bán *"
                                    placeholder="Nhập đơn giá bán..."
                                    value={value} onChange={onChange} />
                                {!!error && <ErrorHelperText message={error?.message} />}
                            </FormControl>
                        }
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default ProductPrice