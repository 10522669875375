import React from 'react';
import { Box, Typography } from '@mui/material';

import DashboardWelcome from '../shared/DashboardWelcome';

const Developer = () => {
	return (
		<Box>
			<Typography sx={{ fontSize: 14, color: 'grey.700' }}>
				Dashboard Developer
			</Typography>
			<DashboardWelcome />
		</Box>
	);
};

export default Developer;
