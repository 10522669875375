import { useLayoutContext } from '@/contexts/layout'
import React from 'react'
import MuiScrollTabs from '@/components/shared/MuiScrollTabs'

export const INITIAL_SETTING_TABS = [
    {
        value: 0,
        name: 'mail',
        label: "General",
        path: ''
    },
    {
        value: 1,
        name: 'product',
        label: "Products",
        path: 'products'
    },
    {
        value: 2,
        name: 'group-role',
        label: "Group roles",
        path: 'group-roles',
    },
    {
        value: 3,
        name: "role",
        label: "Roles",
        path: 'roles',
    },
]

const SettingTab = () => {

    const { features } = useLayoutContext();
    const acceptTabs = INITIAL_SETTING_TABS.filter((tab) => features.includes(tab?.name))
    return (
        <MuiScrollTabs prefix={`settings`}
            initialTabs={acceptTabs}
        />
    )
}

export default SettingTab