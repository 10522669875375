import { Grid } from '@mui/material';
import React from 'react';
import InputSearch from './InputSearch';
import SelectionColumns from './SelectionColumns';

const Toolbar = ({
	store,
	columns,
	setColumns,
	initialColumns,
	inputSearch //{ value: "", onChange: Func(), placeholder: ""}
}) => {

	return (
		<Grid container spacing={2} sx={{ p: 2 }}>
			<Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ height: '100%', display: 'flex' }}>
				{(columns && columns?.length > 0) && <SelectionColumns
					store={store}
					columns={columns}
					setColumns={setColumns}
					initialColumns={initialColumns} />
				}
			</Grid>
			<Grid item xs />
			{inputSearch && <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
				<InputSearch {...inputSearch} />
			</Grid>}
		</Grid>
	);
};

export default Toolbar;
