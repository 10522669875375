import { format_numeral_price } from "@/functions";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

const FORMAT_NAMES = {
  commitment: "Gói cố định:",
  price: "Đơn giá (/GB):",
  term: "Thời gian (Tháng):",
  amount: "Tạm tính:",
};

const renderValueObj = (key, value) => {
  switch (key) {
    case "price":
    case "amount":
      return format_numeral_price(value);
    case "commitment":
      return format_numeral_price(value) + "GB";
    default:
      return value;
  }
};

const Metadata = ({ data }) => {
  const [obj] = useState(Object.assign(data));
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "row",
      }}
    >
      {Object.keys(obj)?.map((key, index) => {
        if (!obj[key]) return null;
        if (!FORMAT_NAMES[key]) return null;
        return (
          <Typography
            sx={{ fontSize: 14, m: "auto 0" }}
            color="text.grey"
            key={index}
          >
            {FORMAT_NAMES[key]}
            <Typography
              sx={{
                fontSize: 14,
                m: "auto 0 auto 4px",
                display: "inline-flex",
              }}
              color="text.black"
            >
              {renderValueObj(key, obj[key])}
            </Typography>
            {","}
          </Typography>
        );
      })}
    </Box>
  );
};

export default Metadata;
