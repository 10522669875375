import { CONTACT_CHANNEL } from "@/libs/constants";
import FormatterStatus from "@components/DataGrid/Cell/FormatterStatus";
import { Box } from "@mui/material";
import { useMemo } from "react";

const ContactChannel = ({ value }) => {
  const data = useMemo(() => {
    return CONTACT_CHANNEL?.find((x) => x.value === value) || null;
  }, [value]);

  if (!data) return null;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        alignItems: "center",
      }}
    >
      <FormatterStatus component="muiChip" data={data} />
    </Box>
  );
};

export default ContactChannel;
