import DateTimePicker from '@components/datepickers/DateTimePicker'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import { Box, FormControl, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ScheduleTimer = () => {

    const { control, formState: { errors } } = useFormContext()

    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1, mt: 2 }}>
            <Box sx={{ width: 300, minWidth: 300, display: 'flex' }}>
                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                    Thời Gian *
                </Typography>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1.5 }}>
                <Controller
                    name="schedule_started_at"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FormControl fullWidth>
                                <DateTimePicker value={value} onChange={onChange} label={"Thời gian bắt đầu"} />
                                {!!errors?.schedule_started_at && <ErrorHelperText message={errors?.schedule_started_at?.message} />}
                            </FormControl>
                        )
                    }} />
                <Controller
                    name="schedule_finished_at"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FormControl fullWidth>
                                <DateTimePicker value={value} onChange={onChange} label={"Thời gian kết thúc"} />
                                {!!errors?.schedule_started_at && <ErrorHelperText message={errors?.schedule_finished_at?.message} />}
                            </FormControl>
                        )
                    }} />
            </Box>
        </Box>
    )
}

export default ScheduleTimer