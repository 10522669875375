import { Box } from '@mui/material'
import React from 'react'
import PaperInformation from '../PaperInformation'
import Buttons from '../Buttons'

const PaperInformationButtons = () => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', md: 'row' },
            gap: 2
        }}>
            <Box sx={{ width: '100%' }}>
                <PaperInformation />
            </Box>
            <Box sx={{ minWidth: '20%' }}>
                <Buttons />
            </Box>
        </Box>
    )
}

export default PaperInformationButtons