import React from 'react';
import debounce from 'lodash/debounce';

const useWaiting = (delay = 500) => {
  const [isWaiting, setWaiting] = React.useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleWaitingDebounce = React.useCallback(
    debounce((callback) => [callback(), setWaiting(false)], delay),
    [isWaiting]
  );

  const handleOnWaiting = (callback) => {
    setWaiting(true);
    handleWaitingDebounce(callback);
  };

  return [isWaiting, handleOnWaiting];
};

export default useWaiting;
