import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

export const DNS_MANAGEMENT_TYPES = [
  { value: "CNAME", label: "CNAME" },
  { value: "A", label: "A" },
  { value: "MX", label: "MX" },
  { value: "TXT", label: "TXT" },
];

const DNSManagement = () => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "dns_managements",
  });

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          gap: 1.5,
          mb: 1,
        }}
      >
        <Typography sx={{ fontSize: 14, fontWeight: "medium", mb: 0.5 }}>
          DNS Managements:
        </Typography>
        <Typography
          onClick={() => append({ name: "", type: "", target: "" })}
          sx={{ fontSize: 14, textDecoration: "underline", cursor: "pointer" }}
          color="primary"
        >
          + Thêm record
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 1.5, mt: 1.5 }}
        aria-labelledby="dns_managements"
      >
        {fields &&
          fields?.map((field, index) => {
            return (
              <Box
                key={field?.id}
                sx={{ display: "flex", flexDirection: "row", gap: 1.5 }}
              >
                <Controller
                  name={`dns_managements.[${index}].name`}
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      sx={{ maxWidth: "calc((100% - 16px) / 4)" }}
                    >
                      <TextField
                        size="small"
                        {...field}
                        label="Name"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  )}
                />
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    gap: 1.5,
                  }}
                >
                  <Controller
                    name={`dns_managements.[${index}].type`}
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      const selected =
                        DNS_MANAGEMENT_TYPES?.find((x) => x?.value === value) ||
                        undefined;
                      return (
                        <FormControl
                          sx={{ maxWidth: "calc((100% - 16px) / 4)" }}
                          fullWidth
                        >
                          <SingleSelectCustomize
                            options={DNS_MANAGEMENT_TYPES}
                            value={selected}
                            input={{
                              label: "Chọn type ",
                            }}
                            onChange={(event, newValue) =>
                              onChange(newValue?.value || "")
                            }
                          />
                        </FormControl>
                      );
                    }}
                  />
                  <Controller
                    name={`dns_managements.[${index}].target`}
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <TextField
                          size="small"
                          {...field}
                          label="Target*"
                          placeholder="Target..."
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    )}
                  />
                  <IconButton size="small" onClick={() => remove(index)}>
                    <DeleteOutlineOutlined
                      fontSize="small"
                      sx={{ ":hover": { color: "#ef5350" } }}
                    />
                  </IconButton>
                </Box>
              </Box>
            );
          })}
      </Box>
    </Fragment>
  );
};

export default DNSManagement;
