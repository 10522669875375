import Committed from "./Committed"
import Dates from "./Dates"
import Prepaid from "./Prepaid"
import Price from "./Price"
import PriceSubtotal from "./PriceSubtotal"

const SWITCH_CDN_COMMIT = {
    Committed,
    Prepaid,
    Price,
    PriceSubtotal,
    Dates
}

export default SWITCH_CDN_COMMIT