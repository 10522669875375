import { Box, FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import MapEChart, { SCHEMES, TOOLTIP_OPTIONS } from "@/components/charts/MapEchart";


import countries from "./countries.json";
import { resolveServiceAnalyticViewers } from "../../services";
import MuiSkeleton from "@/components/shared/MuiSkeleton";
import { resolveMaxOfArray, formatVolumeUnit } from "@/functions";

const defaultSelectTypography = {
  fontWeight: 400,
  fontSize: 14,
  sx: { mt: 0.25 }
}

export default function LocationTrafficChart(props) {
  const { data: viewers, isLoading } = resolveServiceAnalyticViewers(props?.service?.service_uuid, props.query)

  const data = viewers?.map((item) => {
    const country = countries?.find((option) => option.value === item.country?.toLowerCase())

    const result = {
      value: item.volume / (1000 ** 3),
      name: country?.label
    }

    return result
  })

  const maxVolume = resolveMaxOfArray(data?.map((item) => item.value))
  const minVolume = 10

  const options = {
    height: 750,
    tooltip: {
      trigger: 'item',
      ...TOOLTIP_OPTIONS,
      formatter: function (params) {
        return params.value && `<b>${params.name}</b><br/>Volume: ${formatVolumeUnit(params.value * 1000 ** 3)}`
      }
    },
    // title: {
    //   text: 'Global Viewers Location (by volume)',
    //   subtext: 'Last 30 days',
    //   left: 'right'
    // },
    visualMap: {
      left: 'right',
      min: minVolume,
      max: maxVolume,
      inRange: {
        color: Array.from(SCHEMES.mono)?.reverse()
      },
      text: ['High', 'Low'],
      calculable: true,
      formatter: function (value) {
        return formatVolumeUnit(value * 1000 ** 3);
      }
    },
    series: [
      {
        name: 'Location Traffic',
        type: 'map',
        map: 'World',
        // roam: true,
        // silent: true,
        emphasis: {
          label: {
            show: false
          }
        },
        data: data
      }
    ]
  };

  return (
    <>
      <Box sx={{ borderBottom: `1px solid ${grey[300]}`, pb: 1 }}>
        <Typography fontSize={14} fontWeight={700} data-ui={`ContentDeliveryNetwork.Dashboard.LocationTrafficChart.[${props.service_uuid}]`}>
          Traffic by Location
        </Typography>
      </Box>
      {!isLoading && viewers ? (
        <>
          <Grid container spacing={3} sx={{ my: 3 }}>
            <Grid item md={2} xs={12}>
              <Typography fontSize={13} fontWeight={700} sx={{ mb: 0.5 }}>Data Country</Typography>
              <FormControl fullWidth>
                <Select
                  size="small"
                  name="region"
                  defaultValue="global"
                  disabled
                >
                  <MenuItem value="global">
                    <Typography {...defaultSelectTypography}>global</Typography>
                  </MenuItem>
                  <MenuItem value="asean">
                    <Typography {...defaultSelectTypography}>asia</Typography>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <MapEChart options={options} height={500} />
        </>
      ) : (
        <MuiSkeleton.GradientCircularProgress />
      )}
    </>
  )
}