import PermissionGroupApi from "@/apis/beta/Permission/PermissionGroupApi";
import { useNotificationContext } from "@/contexts/notification";
import { queryClientRefetcher, sleep } from "@/functions";
import { Paper } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Buttons from "../Buttons";
import GroupPermissionName from "../GroupPermissionName";
import ManageList from "../ManageList";

const GroupRoleForm = ({ type = "create", permissionGroup }) => {
  const queryClient = useQueryClient();
  let navigate = useNavigate();
  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = React.useState(false);

  const [selecteds, setSelecteds] = React.useState(
    permissionGroup?.group_permission_has_ids || []
  );
  React.useEffect(() => {
    setSelecteds(permissionGroup?.group_permission_has_ids || []);
  }, [permissionGroup]);

  const methods = useForm({
    defaultValues: {
      group_permission_name: permissionGroup?.group_permission_name || "",
    },
  });
  const onSubmit = async (values) => {
    setSubmit(true);
    const dataSubmit = {
      group_permission_name: values?.group_permission_name,
      group_permission_has_ids: selecteds || [],
    };
    const response =
      type === "create"
        ? await PermissionGroupApi.Create(dataSubmit)
        : await PermissionGroupApi.Update(
            permissionGroup?.group_permission_id,
            dataSubmit
          );
    await sleep(500);
    setSubmit(false);
    setNotification({
      open: true,
      message: response?.data?.message || "",
      severity: response?.status === 200 ? "success" : "error",
    });

    queryClientRefetcher(queryClient, [
      "permission-groups.list",
      "permission-group.detail",
      "employee.permissions",
    ]);
    return navigate("/group-roles", { replace: true });
  };
  return (
    <FormProvider {...methods}>
      <Paper sx={{ p: 3 }} component="form">
        <GroupPermissionName />
        <ManageList selecteds={selecteds} setSelecteds={setSelecteds} />
        <Buttons
          submit={submit}
          onSubmit={methods.handleSubmit(onSubmit)}
          type={type}
        />
      </Paper>
    </FormProvider>
  );
};

export default GroupRoleForm;
