import { format_numeral_price } from '@/functions'
import { Box, Typography } from '@mui/material'
import React from 'react'

const InvoiceTotal = ({ invoice_total }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 1,
            justifyContent: 'space-between',
        }}>
            <Box sx={{
                display: 'flex',
                width: { xs: '100%', md: '40%' }
            }}>
                <Typography sx={{
                    fontSize: 14,
                    color: "#777680",
                    m: 'auto 0'
                }}>
                    Tổng Tiền(VAT)
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Typography component="div">
                    <Typography sx={{
                        display: 'inline-block',
                        textDecoration: 'underline'
                    }}>đ</Typography>
                    {format_numeral_price(invoice_total)}
                </Typography>
            </Box>
        </Box>
    )
}

export default InvoiceTotal