import ContractApi from "@/apis/beta/Contract/ContractApi";
import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import ProcessStepper from "@features/Module/Contract/NumberContract/Detail/Process/ProcessStepper";
import { Box, CircularProgress, Step, Stepper } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import ContractInformation from "../ContractInformation";
import Header from "../Header";

const ContractProcesses = () => {
  const params = useParams();

  const { data: contract, refetch: callbackContracts } = ContractApi.Detail(
    params?.contract_id
  );
  const { data: employees } = EmployeeApi.ListEmployees({
    page: 1,
    pageSize: 999,
  });

  return (
    <Box>
      <Header contract={contract} type="process" />
      <ContractInformation contract={contract} callbackContracts={callbackContracts} />
      <Box>
        <Stepper
          activeStep={8} // go to last step
          orientation="vertical"
          sx={{ mb: 2 }}
        >
          <Step>
            <ProcessStepper.StepStart
              contractNumber={{
                ...contract,
                contract_number_stt: contract?.contract_id,
                contract_number_created_at: contract?.contract_created_at,
              }}
            />
          </Step>
          <React.Suspense fallback={<CircularProgress />}>
            {contract?.contract_processes?.length > 0 && (
              <>
                <Step>
                  <ProcessStepper.StepResolveProblem
                    employees={employees}
                    type={"contracts"}
                    callback={callbackContracts}
                    contract_processes={contract?.contract_processes}
                  />
                </Step>
              </>
            )}
          </React.Suspense>
        </Stepper>
      </Box>
    </Box>
  );
};

export default withSuspense(
  withPermission(ContractProcesses, {
    feature: "contract",
    action: "customer-contracts-processes",
  }),
  MuiSkeleton["LoadingDotBox"]
);
