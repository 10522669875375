import EmployeeApi from "@/apis/beta/Employee/EmployeeApi"
import SandboxApi from "@/apis/beta/Sandbox/SandboxApi"
import UserApi from "@/apis/UserApi"
import withSuspense from "@/hocs/withSuspense"
import { Box, Grid, TextField, Typography } from '@mui/material'
import { COUNTRIES } from '@/libs/constants'
import React from 'react'

const Profile = () => {
    const [current] = UserApi.Current()
    const { data: employee } = EmployeeApi.Detail(current?.employee_id)

    const employee_information = employee?.employee_information
    const [provinces] = SandboxApi.Provinces(employee_information?.employee_country)

    const selectedProvince = provinces?.find(province =>
        province.value === employee_information?.employee_permanent_address_city || province.isSelected
    )?.value

    const [districts] = selectedProvince ? SandboxApi.Districts(selectedProvince) : [[]]

    const selectedDistrictPermanent = districts?.find(district =>
        district.value === employee_information?.employee_permanent_address_district
    );

    const [wardsPermanent] = selectedDistrictPermanent ? SandboxApi.Wards(selectedProvince, selectedDistrictPermanent.value) : [];

    return (
        <Box sx={{ border: 1, borderRadius: "4px", p: 3 }}>
            <Typography sx={{ textTransform: "uppercase", mb: 2 }}>Thông tin chi tiết</Typography>
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>Nguyên Quán*</Typography>
                    <TextField size='small' value={employee?.employee_information?.employee_domicile || "Chưa có thông tin"} disabled fullWidth />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>Quốc Tịch*</Typography>
                    <TextField size='small' value={COUNTRIES?.find(i => i.value === employee_information?.employee_nationality)?.label ?? employee?.employee_information?.employee_nationality ?? "Chưa có thông tin"} disabled fullWidth />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography sx={{ mb: 0.5, textTransform: "capitalize" }}>Dân Tộc*</Typography>
                    <TextField size='small' value={employee?.employee_information?.employee_nation ?? employee?.employee_information?.employee_nation?.label ?? "Chưa có thông tin"} disabled fullWidth />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>Tôn Giáo*</Typography>
                    <TextField size='small' value={employee?.employee_information?.employee_religion ?? employee?.employee_information?.employee_religion?.label ?? "Chưa có thông tin"} disabled fullWidth />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>Địa Chỉ Thường Trú (Số Nhà/ Căn Hộ - Tên Đường/ Hẻm/ Nghõ)*</Typography>
                    <TextField size='small' value={employee?.employee_information?.employee_permanent_address || "Chưa có thông tin"} disabled fullWidth />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography sx={{ mb: 0.5, textTransform: "capitalize" }}>Quốc Gia*</Typography>
                    <TextField size='small' value={COUNTRIES?.find(i => i.value === employee_information?.employee_country)?.label ?? employee?.employee_information?.employee_country ?? "Chưa có thông tin"} disabled fullWidth />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>Tỉnh/ Thành Phố</Typography>
                    <TextField size='small' value={provinces?.find(i => i.value === employee_information?.employee_permanent_address_city)?.label ?? employee?.employee_information?.employee_permanent_address_city ?? "Chưa có thông tin"} disabled fullWidth />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>Quận/ Huyện*</Typography>
                    <TextField size='small' value={districts?.find(i => i.value === employee_information?.employee_permanent_address_district)?.label ?? "Chưa có thông tin"} disabled fullWidth />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography sx={{ mb: 0.5, textTransform: "capitalize" }}>Phường/ Xã*</Typography>
                    <TextField size='small' value={wardsPermanent?.find(i => i.value === employee?.employee_information?.employee_permanent_address_ward)?.label ?? "Chưa có thông tin"} disabled fullWidth />
                </Grid>
            </Grid>
        </Box>
    )
}

export default withSuspense(Profile, null)