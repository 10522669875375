import { PRODUCT_UNITS } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined'
import { Box, Checkbox, Collapse, FormControl, FormControlLabel, TextField, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import PricingSteps from './Steps'

const ProductPricingConfigures = () => {
    const { control, watch, setValue } = useFormContext()

    const [see, setSee] = React.useState(true)

    return (
        <React.Fragment>
            <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 1,
                mt: 2
            }}>
                <Box sx={{ display: 'flex', minWidth: 300 }}>
                    <Typography sx={{ fontSize: 14, color: "#777680" }}>
                        Cấu Hình Giá *
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: 1.5
                    }}>
                        <Controller
                            name="product_pricing_configures.product_pricing_method"
                            control={control}
                            render={({ field: { value } }) => {
                                const checked = value !== "default"
                                return (
                                    <FormControl fullWidth>
                                        <FormControlLabel
                                            label="Sử dụng bảng giá"
                                            control={<Checkbox size='small' checked={checked}
                                                onChange={(event) => {
                                                    const nextValue = event?.target?.checked
                                                    if (!nextValue) {
                                                        return setValue("product_pricing_configures.product_pricing_method", "default")
                                                    } else {
                                                        return setValue("product_pricing_configures.product_pricing_method", "tierd")
                                                    }
                                                }}

                                            />}
                                        />

                                    </FormControl>
                                )
                            }} />
                        <Controller
                            name="product_pricing_configures.default.pricing"
                            control={control}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <FormControl fullWidth
                                        sx={{
                                            visibility: watch(`product_pricing_configures.product_pricing_method`) === "default" ? "" : "hidden"

                                        }}
                                    >
                                        <TextField
                                            size='small'
                                            value={value}
                                            label="Đơn giá niêm yết *"
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                inputComponent: NumericFormatCustom
                                            }}
                                            onChange={onChange}
                                        />
                                    </FormControl>
                                )
                            }} />
                        <Controller
                            name="product_pricing_configures.product_pricing_unit"
                            control={control}
                            render={({ field: { value, onChange } }) => {
                                const selected = PRODUCT_UNITS.find(item => item.value === value) || null
                                return (
                                    <FormControl fullWidth>
                                        <SingleSelectCustomize
                                            options={PRODUCT_UNITS}
                                            value={selected}
                                            input={{
                                                label: "Đơn vị tính tiền *",
                                                placeholder: "Chọn đơn vị tính..."
                                            }}
                                            onChange={(event, newValue) => onChange(newValue?.value || -1)} />
                                    </FormControl>
                                )
                            }} />
                    </Box>
                    {watch(`product_pricing_configures.product_pricing_method`) === 'tierd' &&
                        <Box>
                            <Typography sx={{
                                fontSize: 14,
                                textDecoration: "underline",
                                ":hover": {
                                    cursor: 'pointer'
                                }
                            }} color="primary"
                                component="span"
                                onClick={() => setSee(prev => !prev)}
                            >
                                [Bảng giá niêm yết]
                                <Tooltip
                                    placement='top'
                                    title="Cấu hình giá dùng cho các dịch vụ có tính tiền theo bậc">
                                    {React.createElement(see ? VisibilityIcon : VisibilityOffIcon, {
                                        color: "primary",
                                        sx: {
                                            height: 14,
                                            width: 20,
                                            m: 'auto 0',
                                            display: 'inline-flex'
                                        }
                                    })}
                                </Tooltip>
                            </Typography>
                            <Collapse in={see}>
                                <PricingSteps />
                            </Collapse>
                        </Box>}
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default ProductPricingConfigures