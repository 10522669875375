import { format_numeral_price } from '@/functions'
import { PRODUCT_UNITS } from '@/libs/constants'
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import BorderLinear from '../shared/BorderLinear'

const Ram = ({ position }) => {
    const { control } = useFormContext()

    const productConfigoption = useWatch({
        control: control,
        name: `product_configoptions.[${position}]`
    })

    const { fields } = useFieldArray({
        control: control,
        name: `product_configoptions.[${position}].product_addons`
    })

    const getAddon = React.useCallback(() => {
        let t = 0
        productConfigoption?.product_addons?.forEach((item) => {
            t += parseFloat(item?.product_addon_qty) * parseFloat(item?.product_addon_value)
        })
        return t
    }, [productConfigoption])

    return (
        <Box>
            <Typography sx={{
                fontSize: { xs: 16, md: 20 },
                fontWeight: 'medium',
            }}>
                RAM
            </Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                mb: 1
            }}>
                <Box sx={{ width: 'calc((100% - 16px) / 2)' }}>
                    <Typography component="span">
                        {format_numeral_price(productConfigoption?.value)}{PRODUCT_UNITS?.slice(1).find(item => item.value === productConfigoption?.unit)?.label || ''}
                        <Typography sx={{ color: "#777680", display: 'inline-block' }}>
                            ( Mặc định)
                        </Typography>
                    </Typography>
                </Box>
                <Box sx={{ width: 'calc((100% - 16px) / 2)' }}>
                    <Typography component="span">
                        {productConfigoption?.subtitle}
                        <Typography sx={{ color: "#777680", display: 'inline-block' }}>
                            ( Tối Đa)
                        </Typography>
                    </Typography>
                </Box>
            </Box>
            <BorderLinear
                offset={productConfigoption?.value}
                maximum={productConfigoption?.max + productConfigoption?.value}
                addon={getAddon()}
            />
            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: "row",
                gap: 2,
                mt: 2,
                flexWrap: 'wrap'
            }}>
                {fields?.map((addon, index) => {
                    return (
                        <React.Fragment key={addon?.id}>
                            <Controller
                                name={`product_configoptions.[${position}].product_addons.[${index}].product_addon_qty`}
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    const selected = parseInt(value) || 0

                                    let usage = parseFloat(productConfigoption?.max)

                                    productConfigoption?.product_addons?.forEach((item, idx) => {
                                        if (index !== idx) {
                                            usage += -(parseFloat(item.product_addon_qty) * parseFloat(item?.product_addon_value))
                                        }
                                    })

                                    let options = Array.from({ length: Math.floor(usage / parseFloat(addon?.product_addon_value)) + 1 }).map((_, i) => i)

                                    return (
                                        <FormControl fullWidth sx={{
                                            maxWidth: {
                                                xs: '100%',
                                                md: 'calc((100% - 16px) / 2)'
                                            }
                                        }}>
                                            <InputLabel id={`product-addon-${addon?.product_addon_id}`}>
                                                {addon?.product_addon_name}
                                            </InputLabel>
                                            <Select
                                                labelId={`product-addon-${addon?.product_addon_id}`}
                                                size='small'
                                                value={selected}
                                                fullWidth
                                                label={addon?.product_addon_name}
                                                onChange={onChange}
                                                disabled={options?.length <= 1}
                                            >
                                                {options.map((item, index) => {
                                                    return (
                                                        <MenuItem
                                                            key={index}
                                                            value={item}
                                                        >
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    )
                                }}
                            />
                        </React.Fragment>
                    )
                })}

            </Box>
        </Box>
    )
}

export default Ram