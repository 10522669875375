import { FormControl, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

const ServiceName = () => {
    const { control } = useFormContext()
    return (
        <Controller
            control={control}
            name="service_product_name"
            render={({ field: { value, onChange } }) =>
                <FormControl fullWidth>
                    <TextField
                        size='small'
                        label="Tên Sản Phẩm/ Dịch Vụ *"
                        InputLabelProps={{ shrink: true }}
                        value={value}
                        onChange={onChange}
                        placeholder='Nhập tên sản phẩm/ dịch vu...'
                    />
                </FormControl>
            }
        />
    )
}

export default ServiceName