import { getPricingConfigoption } from "@/functions";
import { UNLIMITED } from "@/libs/constants";
import NumericFormatCustom from "@components/NumericFormatCustom";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import { FormControl, TextField } from "@mui/material";
import React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

const ProductPricingAmount = () => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const productConfigoptions = useWatch({
    control: control,
    name: "product_configoptions",
  });

  const onChangeProductConfigoptions = React.useCallback(() => {
    let amount = 0;
    if (watch(`product_payment_type`) !== "prevpaid-crosscheck") {
      amount = 0;
    } else {
      productConfigoptions?.forEach((step) => {
        let configoptionAmount = 0;
        const stepCommitmentTemp = parseInt(step?.commitment_term) || 1;
        if (parseFloat(step?.value) >= UNLIMITED) {
          configoptionAmount += parseFloat(step?.pricing_amount) || 0;
        } else {
          configoptionAmount += getPricingConfigoption({
            method: "default",
            value: parseFloat(step?.value) || 0,
            pricingApply: parseFloat(step?.pricing_apply) || 0,
            options: [],
          });
        }
        if (stepCommitmentTemp > 1 && parseInt(step?.value) > 0) {
          configoptionAmount = Math.round(configoptionAmount);
        }
        amount += configoptionAmount;
      });
    }
    return setValue(`product_pricing_amount`, amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productConfigoptions, watch(`product_payment_type`)]);

  React.useEffect(() => {
    onChangeProductConfigoptions();
  }, [onChangeProductConfigoptions]);

  return (
    <Controller
      name="product_pricing_amount"
      control={control}
      render={({ field: { value } }) => {
        return (
          <FormControl fullWidth sx={{ mb: 1.5 }}>
            <TextField
              size="small"
              label="Tạm tính *"
              value={value}
              placeholder="0"
              disabled
              InputLabelProps={{ shrink: true }}
              InputProps={{
                sx: {
                  paddingRight: 0,
                },
                inputComponent: NumericFormatCustom,
              }}
            />
            {!!errors?.product_pricing_amount && (
              <ErrorHelperText
                message={errors?.product_pricing_amount?.message}
              />
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default ProductPricingAmount;
