import { Box, Button, ButtonGroup, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { useTheme } from '@mui/material/styles';

const INITIAL_CONDITIONS = [
    { value: 0, label: "Sáng", mobileLabel: "S" },
    { value: 1, label: "Chiều", mobileLabel: "C" },
    { value: 2, label: "Cả ngày", mobileLabel: "CN" },
]
const ButtonGroupDate = ({
    onChange,
    value
}) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('md'))
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <ButtonGroup variant="outlined"
                sx={{
                    height: 40
                }}
            >
                {INITIAL_CONDITIONS.map((cod, index) =>
                    <Button
                        key={index}
                        onClick={cod?.value === value ? undefined : () => onChange(cod?.value)}
                        sx={cod?.value === value ? {
                            bgcolor: '#4C57A945',
                            width: { xs: 50, md: 80 },
                            ":hover": {
                                bgcolor: "#4C57A945"
                            }
                        } : {
                            color: '#000',
                            width: { xs: 50, md: 80 },
                            ":hover": {
                                bgcolor: "#4C57A945"
                            }
                        }}>
                        <Typography sx={{
                            fontSize: 12,
                            textTransform: "uppercase",
                            fontWeight: "medium"
                        }}>{mobile ? cod?.mobileLabel : cod?.label}</Typography>
                    </Button>
                )}
            </ButtonGroup>
        </Box>
    )
}

export default ButtonGroupDate