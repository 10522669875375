import ServiceApi from "@/apis/beta/Service/ServiceApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import BreadCrumb from "@components/shared/BreadCrumb";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

const ServiceHeader = () => {
  const { service_id } = useParams();
  const { data } = ServiceApi.Detail({ id: service_id });
  return (
    <React.Fragment>
      <Typography className="page-title">Chi Tiết Dịch Vụ/ Sản Phẩm</Typography>
      <BreadCrumb
        data={[
          { label: "Danh Sách Dịch Vụ", reactLink: "/services" },
          {
            label:
              `${data?.service_customer_id} - ${
                data?.customer_company_name || data?.customer_name
              }` || "---",
            reactLink: `/customers/${data?.service_customer_id}`,
          },
          {
            label: data?.service_name,
          },
        ]}
      />
    </React.Fragment>
  );
};

export default withSuspense(
  withPermission(ServiceHeader, {
    feature: "customer",
    action: "show",
    type: "Label",
  }),
  MuiSkeleton["TextField"]
);
