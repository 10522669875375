import { Grid, Skeleton } from '@mui/material'
import React from 'react'

const Grid39 = () => {
    return (
        <>
            <Grid item xs={12} md={3}>
                <Skeleton variant="text" sx={{ m: "0 auto auto 0", width: '75%' }} />
            </Grid>
            <Grid item xs={12} md={9}>
                <Skeleton variant="rectangular" sx={{ height: 120, width: '100%' }} />
            </Grid>
        </>
    )
}

export default Grid39