import { create, enforce, test } from "vest";

const changePasswordValidator = create((data = {}) => {

  test("password", "Vui lòng nhập mật khẩu cũ.", () => {
    enforce(data?.password).isNotEmpty();
  });
  test("new_password", "Mật khẩu mới không được để trống.", () => {
    enforce(data?.new_password).isNotEmpty();
  });

  test("new_password", "Mật khẩu quá ngắn.", () => {
    enforce(data?.new_password?.length > 8).isTruthy();
  });

  test(
    "new_password",
    "Mật khẩu không hợp lệ( Vui lòng đặt mật khẩu có ít nhất 1 ký tự đặc biệt).",
    () => {
      // eslint-disable-next-line no-useless-escape
      enforce(!!data?.new_password?.match(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/)).isTruthy();
    }
  );
  test(
    "new_password",
    "Mật khẩu không hợp lệ( Vui lòng đặt mật khẩu có ít nhất 1 ký tự hoa.)",
    () => {
      enforce(!!data?.new_password?.match(/[A-Z]/)).isTruthy();
    }
  );
  test(
    "new_password",
    "Mật khẩu không hợp lệ( Vui lòng đặt mật khẩu có ít nhất 1 ký tự số.)",
    () => {
      enforce(!!data?.new_password?.match(/[0-9]/)).isTruthy();
    }
  );

  test(
    "new_password_confirmation",
    "Vui lòng xác nhận mật khẩu đã nhập.",
    () => {
      enforce(data?.new_password_confirmation).isNotEmpty();
    }
  );

  test(
    "new_password_confirmation",
    "Mật khẩu không khớp.",
    () => {
      enforce(data?.new_password_confirmation === data?.new_password).isTruthy();
    }
  );
});

export default changePasswordValidator;
