import DatePicker from '@components/DatePicker';
import ErrorHelperText from '@components/shared/ErrorHelperText';
import { FormControl, Typography } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const CustomerDob = () => {
    const { control, formState: { errors } } = useFormContext();

    return (
        <React.Fragment>
            <Typography sx={{ mb: 0.5, color: "#777680", fontSize: 14 }}>
                Ngày Sinh
            </Typography>
            <Controller
                control={control}
                name="customer.customer_dob"
                render={({ field: { value, onChange } }) => {
                    return (
                        <FormControl fullWidth>
                            <DatePicker value={value} onChange={onChange} />
                            {!!errors?.customer?.customer_dob && <ErrorHelperText message={errors?.customer?.customer_dob} />}
                        </FormControl>
                    )
                }} />
        </React.Fragment>
    )
}

export default CustomerDob