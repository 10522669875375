import { FormControl, TextField } from '@mui/material'
import React from 'react'

const ServiceReject = ({
    state,
    setState
}) => {
    return (
        <FormControl fullWidth sx={{ mt: 1.25 }}>
            <TextField
                value={state?.reason}
                onChange={(event) => setState(pre => ({ ...pre, reason: event?.target?.value }))}
                size='small'
                placeholder='Lý do từ chối dịch vụ...'
                multiline
            />
        </FormControl>
    )
}

export default ServiceReject