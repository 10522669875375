import CustomerApi from '@/apis/beta/Customer/CustomerApi'
import withSuspense from '@/hocs/withSuspense'
import { COUNTRIES } from "@/libs/constants"
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { Box, Divider, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import Admin from './Admin'
import Customer from './Customer'
import Owner from './UI'


const INITIALIZE = {
    country: { value: "VN", label: "Vietnam" },
    province: { label: "Hồ Chí Minh", value: "SG" },
    district: { label: "Quận 1", value: "10" },
    ward: { label: "Bến Thành", value: "130" }
}
const CustomerInformation = ({
    customerId
}) => {

    const { setValue } = useFormContext()

    const { data: customer } = CustomerApi.Detail(customerId)

    React.useEffect(() => {
        const {
            customer_information,
            customer_address_information,
            customer_service_information,
            customer_company
        } = customer

        // name
        setValue(`domain.domain_extra_fields.ownerName`, customer_information?.name)
        setValue(`domain.domain_extra_fields.uiName`, customer_information?.name)
        setValue(`domain.domain_extra_fields.adminName`, customer_information?.name)

        //mail
        setValue(`domain.domain_extra_fields.ownerEmail1`, customer_information?.email)
        setValue(`domain.domain_extra_fields.ownerEmail2`, "")
        setValue(`domain.domain_extra_fields.adminEmail`, customer_information?.email)
        setValue(`domain.domain_extra_fields.uiEmail`, customer_information?.email)

        // country
        const country = !customer_address_information?.country ? INITIALIZE["country"]
            : COUNTRIES.find(x => {
                if (x.value === customer_address_information?.country) {
                    return ({
                        value: x.value,
                        label: x.label
                    })
                }
                return false
            }) || INITIALIZE["country"]

        setValue(`domain.domain_extra_fields.ownerCountry`, country)
        setValue(`domain.domain_extra_fields.uiCountry`, country)
        setValue(`domain.domain_extra_fields.adminCountry`, country)

        // province
        setValue(`domain.domain_extra_fields.ownerProvince`, INITIALIZE["province"])
        setValue(`domain.domain_extra_fields.uiProvince`, INITIALIZE["province"])
        setValue(`domain.domain_extra_fields.adminProvince`, INITIALIZE["province"])

        // district
        setValue(`domain.domain_extra_fields.ownerDistrict`, INITIALIZE["district"])
        setValue(`domain.domain_extra_fields.uiDistrict`, INITIALIZE["district"])
        setValue(`domain.domain_extra_fields.adminDistrict`, INITIALIZE["district"])

        // ward
        setValue(`domain.domain_extra_fields.ownerWard`, INITIALIZE["ward"])
        setValue(`domain.domain_extra_fields.uiWard`, INITIALIZE["ward"])
        setValue(`domain.domain_extra_fields.adminWard`, INITIALIZE["ward"])

        // address
        setValue(`domain.domain_extra_fields.ownerAddress`, INITIALIZE["Address"])
        setValue(`domain.domain_extra_fields.uiAddress`, INITIALIZE["Address"])
        setValue(`domain.domain_extra_fields.adminAddress`, INITIALIZE["Address"])

        // phone
        setValue(`domain.domain_extra_fields.ownerPhone`, customer_information?.phone_number)
        setValue(`domain.domain_extra_fields.uiPhone`, customer_information?.phone_number)
        setValue(`domain.domain_extra_fields.adminPhone`, customer_information?.phone_number)

        // gender
        setValue(`domain.domain_extra_fields.uiGender`, customer_information?.gender)
        setValue(`domain.domain_extra_fields.adminGender`, customer_information?.gender)

        // dob
        setValue(`domain.domain_extra_fields.uiBirthdate`, dayjs(customer_information?.birthday))
        setValue(`domain.domain_extra_fields.adminBirthdate`, dayjs(customer_information?.birthday))

        // dob
        setValue(`domain.domain_extra_fields.uiPosition`, customer_information?.position)
        setValue(`domain.domain_extra_fields.adminPosition`, customer_information?.position)


        setValue(`domain.domain_extra_fields.for`, customer_service_information?.customer_type === "individual" ? "canhan" : "congty")

        // tax code
        setValue(`domain.domain_extra_fields.ownerTaxCode`, customer_company?.company_tax)
        setValue(`domain.domain_extra_fields.uiTaxCode`, customer_company?.company_tax)

        // company name
        setValue(`domain.domain_extra_fields.uiCompany`, customer_company?.company_name)
        setValue(`domain.domain_extra_fields.adminCompany`, customer_company?.company_name)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer])


    return (
        <Box sx={{
            border: "1px solid rgba(0, 0, 0, 0.28)",
            borderRadius: "4px",
            p: 2
        }}
        >
            <Typography sx={{
                fontSize: { xs: 16, md: 18 },
                fontWeight: "medium",
                mb: 0.5
            }}>
                Thông tin đăng ký tên miền (*)
            </Typography>
            <Customer />
            <Divider sx={{ m: '16px 0' }} />
            <Owner />
            <Divider sx={{ m: '16px 0' }} />
            <Admin />
        </Box>
    )
}

export default withSuspense(CustomerInformation, MuiSkeleton["Loading"])