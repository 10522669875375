import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import TicketApi from "@/apis/beta/Ticket/TicketApi";
import TicketRequestApi from "@/apis/beta/Ticket/TicketRequestApi";
import TicketStatusApi from "@/apis/beta/Ticket/TicketStatusApi";
import TicketTypeApi from "@/apis/beta/Ticket/TicketTypeApi";
import { format_date_monent_js } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDataGridParams from "@/hooks/useDataGridParams";
import { Filter } from "@/hooks/useFilters";
import { TICKET_URGENCY } from "@/libs/constants";
import DataGridCustomize from "@components/DataGrid";
import { CellLink, CellStatus } from "@components/DataGrid/Cell";
import DataGridFilter from "@components/DataGrid/Filter";
import HeaderTitle from "@components/Title/HeaderTitle";
import BreadCrumb from "@components/shared/BreadCrumb";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Dialog, Slide, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TICKET_STATUS } from "../Detail/TicketInfo";
import CreateTicket from "../Shared/Dialog/CreateTicket";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const List = () => {
  const { data: employees } = EmployeeApi.All();
  const { data: ticket_status } = TicketStatusApi.ListTicketStatus();
  // const { data: customers } = CustomerApi.ListCustomer();
  const { data: ticket_requests } = TicketRequestApi.ListTicketRequest();
  const { data: ticket_types } = TicketTypeApi.ListTicketType();

  const initialColumns = [
    {
      field: "ticket_id",
      headerName: "NO.",
      width: 120,
      valueGetter: ({ row }) => {
        return {
          label: `DC Ticket - ${row?.ticket_id}`,
          href: ["tickets", row?.ticket_id, "detail"],
        };
      },
      renderCell: ({ value }) => <CellLink data={value} />,
      operators: {
        type: "string",
      },
    },
    {
      field: "tickets.employee_id",
      headerName: "Người tạo",
      width: 200,
      valueGetter: ({ row }) => row?.employee?.employee_name,
      operators: {
        type: "object",
        collections: employees?.map((item) => ({
          label: item?.employee_name,
          value: item?.employee_id,
        })),
      },
    },
    {
      field: "ticket_statuses.ticket_status_id",
      headerName: "Trạng thái ticket",
      width: 200,
      valueGetter: ({ row }) =>
        TICKET_STATUS?.find((stt) => stt?.value === row?.ticket_status_id),
      renderCell: ({ value }) => {
        return <CellStatus component="muiChip" data={value} />;
      },
      operators: {
        type: "object",
        collections: ticket_status?.map((item) => ({
          label: item?.ticket_status_title,
          value: item?.ticket_status_id,
        })),
      },
    },
    {
      field: "ticket_time_request",
      headerName: "Ngày khách hàng request",
      width: 200,
      valueFormatter: ({ value }) => !!value ? format_date_monent_js(value, 'DD/MM/YYYY HH:mm') : ''
    },
    {
      field: "tickets.customer_id",
      headerName: "Khách hàng",
      width: 200,
      valueGetter: ({ row }) => {
        if (!row?.customer_id || !row?.customer_id === -1) return undefined;
        return ({
          label: row?.customer?.customer_company_name || row?.customer?.customer_name,
          href: ["customers", row?.customer_id]
        })
      },
      renderCell: ({ value }) => value && <CellLink data={value} />
    },
     {
      field: "service_name",
      headerName: "Sản phẩm",
      width: 200,
      valueGetter: ({ row }) => row?.service?.service_name || ''
    },
    {
      field: "ticket_requests.ticket_request_id",
      headerName: "Ticket",
      width: 200,
      renderCell: ({ row }) => (
        <Typography fontSize={14}>
          {row?.ticket_request?.ticket_request_title}
        </Typography>
      ),
      operators: {
        type: "object",
        collections: ticket_requests?.map((item) => ({
          label: item?.ticket_request_title,
          value: item?.ticket_request_id,
        })),
      },
    },
    {
      field: "ticket_types.ticket_type_id",
      headerName: "Ticket Type",
      width: 400,
      renderCell: ({ row }) => (
        <Typography fontSize={14}>
          {row?.ticket_type?.ticket_type_title}
        </Typography>
      ),
      operators: {
        type: "object",
        collections: ticket_types?.map((item) => ({
          label: item?.ticket_type_title,
          value: item?.ticket_type_id,
        })),
      },
    },
    {
      field: "ticket_urgency",
      headerName: "Ticket Urgency",
      width: 200,
      valueGetter: ({ row }) =>
        TICKET_URGENCY?.find((stt) => stt?.value === row?.ticket_urgency),
      renderCell: ({ value }) => {
        return <CellStatus component="muiChip" data={value} />;
      },
      operators: {
        type: "object",
        collections: TICKET_URGENCY,
      },
    },
    {
      field: "ticket_content",
      headerName: "Nội dung chi tiết",
      width: 400,
      operators: {
        type: "string",
      },
    },
    {
      field: "ticket_time_release",
      headerName: "Ngày hoàn thành",
      width: 200,
      valueFormatter: ({ value }) => !!value ? format_date_monent_js(value, 'DD/MM/YYYY HH:mm') : '',
      operators: {
        type: "date",
      },
    },
  ];

  const [showDialogCreate, setShowDialogCreate] = useState(false);

  const {
    columns,
    debouncedSearchQuery,
    page,
    setColumns,
    setPage,
    query,
    updateQuery,
  } = useDataGridParams(initialColumns, 20, "tickets.list.filters", true);

  const { data, isFetching, refetch } = TicketApi.ListTicket({
    page: page?.page + 1,
    pageSize: page?.pageSize,
    q: debouncedSearchQuery,
    query: Filter.stringify(query),
  });

  const filterables = initialColumns
    ?.filter((column) => !!column?.operators && !column?.hide)
    .map((column) => {
      return {
        field: column?.key ? column?.key : column?.field,
        label: column?.headerName,
        operators: column?.operators,
      };
    });

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <HeaderTitle>Datacentral Ticket Manager</HeaderTitle>

        <Box display="flex" gap={2}>
          <Link to="/tickets/new">
            <Button
              onClick={() => setShowDialogCreate(true)}
              startIcon={<AddIcon />}
              size="small"
              variant="contained"
            >
              Create new Ticket
            </Button>
          </Link>
          <Link to="/tickets/board">
            <Button
              onClick={() => setShowDialogCreate(true)}
              // startIcon={<AddIcon />}
              size="small"
              variant="outlined"
            >
              Ticket Board
            </Button>
          </Link>
        </Box>
      </Box>

      <BreadCrumb
        data={[
          { label: "Ticket Manager" },
          { label: "Danh sách Ticket", reactLink: "/tickets" },
        ]}
      />

      <DataGridFilter
        callback={updateQuery}
        filters={{
          key: "tickets.list.filters",
          filterables: filterables,
          exportFeature: "TicketExport",
        }}
      />

      <DataGridCustomize
        rows={data?.tickets || []}
        columns={columns}
        loading={isFetching}
        rowCount={data?.pagination?.total_pages * page?.pageSize || 0}
        componentsProps={{
          toolbar: {
            store: "tickets.list.hiddenColumns",
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: "tickets",
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize || 20,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        sx={{ mt: 2 }}
        getRowId={(row) => row?.ticket_id}
      />
      {showDialogCreate && (
        <Dialog
          open={showDialogCreate}
          TransitionComponent={Transition}
          // onClose={() => setShowDialogCreate(false)}
          fullScreen
        >
          <CreateTicket
            handleClose={() => setShowDialogCreate(false)}
            refetch={refetch}
          />
        </Dialog>
      )}
    </Box>
  );
};

export default withSuspense(
  withPermission(List, { feature: "ticket", action: "list" }),
  MuiSkeleton["DataGrid"]
);
