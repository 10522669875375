import { Dialog, DialogContent, DialogTitle, Typography, Box, DialogActions, Button } from '@mui/material'
import React from 'react'
import { format_date_short } from "@/functions"
import { Link } from 'react-router-dom'
const ErrorMessage = ({
    data,
    customerId
}) => {
    const company = data?.find(item => item?.name === "company")?.company
    const project = data?.find(item => item?.name === "project")?.project
    return (
        <Dialog open={true}
            maxWidth="md"
            fullWidth>
            <DialogTitle color='error'>
                Lỗi khi thêm mới dữ liệu không thành công
            </DialogTitle>
            <DialogContent>
                <Box sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column', md: 'row'
                    },
                    gap: 2
                }}>
                    {company && <Box sx={{ width: '50%', minWidth: '50%' }}>
                        <Box sx={{
                            display: 'flex',
                            gap: 0.5,
                            mb: 1
                        }}>
                            <Typography sx={{ color: "#9e9e9e" }}>
                                Tên Công Ty:
                            </Typography>
                            <Typography>
                                {company?.company_name || "Tên Công Ty"}
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gap: 0.5,
                            mb: 1
                        }}>
                            <Typography sx={{ color: "#9e9e9e" }}>
                                Mã Số Thuế:
                            </Typography>
                            <Typography>
                                {company?.company_tax || "MST"}
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gap: 0.5,
                            mb: 1
                        }}>
                            <Typography sx={{ color: "#9e9e9e" }}>
                                Ngày Thành Lập:
                            </Typography>
                            <Typography>
                                {format_date_short(company?.company_established_date)}
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gap: 0.5,
                            mb: 1
                        }}>
                            <Typography sx={{ color: "#9e9e9e" }}>
                                Địa Chỉ:
                            </Typography>
                            <Typography>
                                {company?.company_address || "MST"}
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gap: 0.5,
                            mb: 1
                        }}>
                            <Typography sx={{ color: "#9e9e9e" }}>
                                Website:
                            </Typography>
                            <Typography>
                                {company?.company_website || "MST"}
                            </Typography>
                        </Box>
                    </Box>}
                    {project && <Box sx={{ width: '50%', minWidth: '50%' }}>
                        <Box sx={{
                            display: 'flex',
                            gap: 0.5,
                            mb: 2
                        }}>
                            <Typography sx={{ color: "#9e9e9e" }}>
                                Tên Dự Án
                            </Typography>
                            <Typography>
                                {project?.project_name}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ color: "#9e9e9e" }}>
                                Ghi Chú/ Mô Tả Dự Án
                            </Typography>
                            <Typography sx={{ flexWrap: 'wrap' }}>
                                {project?.project_note}
                            </Typography>
                        </Box>
                    </Box>}
                </Box>
            </DialogContent>
            <DialogActions>
                <Link to={customerId ? `/customers/${customerId}` : `/`} replace>
                    <Button size="small" sx={{ height: 36 }}>Để Sau</Button>
                </Link>
                <Button size="small" variant="contained" sx={{ height: 36 }}>Cập Nhật</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ErrorMessage