import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import { buildQueryFilterCondition } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { FormControl } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const ContactAssignee = () => {
  const { control, watch } = useFormContext();

  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const filterableCustomer = React.useCallback(() => {
    const queries = [
      {
        name: "employee_disable",
        operator: "$eq",
        value: false,
      },
      {
        name: "employees.department_id",
        operator: "$in",
        value: "1,2,3",
      },
    ];
    return buildQueryFilterCondition("$and", 0, queries);
  }, []);

  const filterableCustomerSecondary = React.useCallback(() => {
    const queries = [
      {
        name: "employee_name",
        operator: "$contains",
        disabled: !debouncedSearchQuery || debouncedSearchQuery.trim() === "",
        value: debouncedSearchQuery,
      },
      {
        name: "employee_code",
        operator: "$contains",
        disabled: !debouncedSearchQuery || debouncedSearchQuery.trim() === "",
        value: debouncedSearchQuery,
      },
      {
        name: "employee_email",
        operator: "$eq",
        disabled:
          !watch(`contact_source_assignee`)?.email ||
          watch(`contact_source_assignee`)?.email === "",
        value: watch(`contact_source_assignee`)?.email,
      },
    ];
    return buildQueryFilterCondition("$or", 0, queries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery, watch(`contact_source_assignee`)]);

  const { data, isFetching } = EmployeeApi.List({
    page: 1,
    pageSize: 15,
    query: filterableCustomer() || null,
    searchQuery: filterableCustomerSecondary() || null,
  });

  return (
    <Controller
      name="contact_source_assignee"
      control={control}
      render={({ field: { value, onChange } }) => {
        const selected =
          data?.employees.find((e) => e?.employee_email === value?.email) ||
          null;
        return (
          <FormControl fullWidth>
            <SingleSelectCustomize
              loading={isFetching}
              options={[...data?.employees]}
              value={selected}
              input={{
                placeholder: "Nhập ID/ Tên/ Mã nhân viên assignee...",
                label: "Assignee",
              }}
              formatCollection={{
                value: "employee_email",
                label: "employee_name",
                input: searchQuery,
                onChange: (event) => setSearchQuery(event?.target?.value),
              }}
              compareAttributes={[
                "employee_email",
                "employee_name",
                "employee_id",
              ]}
              onChange={(event, value) =>
                onChange({
                  email: value?.employee_email,
                })
              }
            />
          </FormControl>
        );
      }}
    />
  );
};

export default withSuspense(ContactAssignee, MuiSkeleton["TextField"]);
