import React from 'react'
import { Button } from "@mui/material"
import { AddOutlined } from "@mui/icons-material"
import { Link } from "react-router-dom"
import withPermission from '@/hocs/withPermission'

const ButtonCreate = () => {
    return (
        <Link to="/roles/new" replace>
            <Button size='small' variant='contained'
                sx={{
                    height: {
                        xs: 36, md: 42
                    },
                    minWidth: 120
                }}
                startIcon={<AddOutlined />}
            >
                Thêm Quyền
            </Button>
        </Link>
    )
}

export default withPermission(ButtonCreate, { feature: 'permission', action: 'create', type: 'Button' })