import {
    Box,
    CircularProgress,
    Grid,
    Paper,
    TextField,
    Typography,
  } from "@mui/material";
  import SearchIcon from "@mui/icons-material/SearchOutlined";
  import { useState } from "react";
  import useDebounce from "@/hooks/useDebounce";
  import { useInfiniteQuery } from "react-query";
  import { InView } from "react-intersection-observer";
  import ReportWatchApi from "@/apis/beta/Threshold/Report/ReportWatchApi";
  
  const SearchCustomers = ({
    size = 3,
    maxHeight = 680,
    onChange,
    paramsSearch = {},
  }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const debouncedValue = useDebounce(searchQuery, 500);  
    const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
      useInfiniteQuery(
        [debouncedValue, paramsSearch],
        (pageParams) =>
          ReportWatchApi.CustomersWaf(
            (() => {
                const payload = {
                ...pageParams,
                ...paramsSearch,
                customer_name: debouncedValue || "",
                };    
                return payload;
              })()
          ),
        {
          getNextPageParam: (lastPage) => {
            return lastPage?.pagination?.page_next;
          },
        }
      );
    return (
      <Grid item xs={size}>
      <Paper sx={{ height: maxHeight, overflow: "scroll", px: 2 }}>
        <Box
            sx={{ position: "sticky", top: 0, backgroundColor: "#fff", py: 2 }}
          >
            <TextField
              size="small"
              placeholder="Tìm kiếm customer..."
              fullWidth
              value={searchQuery.key_search}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <SearchIcon fontSize="small" sx={{ color: "#757575", mr: 1 }} />
                ),
              }}
            />
  
            <Box display="flex" alignItems="center" justifyContent="center">
              {isLoading && <CircularProgress size={25} sx={{ mt: 2 }} />}
            </Box>
          </Box>
  
          <Box>
            {!isLoading &&
              data?.pages?.reduce((final, item) => {
                  item?.customers && final.push(...item?.customers);
                  return final;
                }, [])
                ?.map((item,index) => (
                  <Box
                    sx={{ cursor: "pointer" }}
                    key={index}
                    onClick={() =>
                      onChange(item)
                    }
                  >
                    <Box sx={{ backgroundColor: "#f5f5f5" }} p={1} mb={1}>
                        <Box>
                            <Typography
                            fontSize={13}
                            fontWeight={600}
                            color="#0d47a1"
                            >
                            {`${item?.customer_gender} ${item?.customer_name}`}
                            </Typography>
                        </Box>
                        <Typography fontSize={12} fontWeight={600} color="#9e9e9e">
                            Mail: {item?.customer_email}
                        </Typography>
                    </Box>
                  </Box>
                ))
            }
  
            <InView
              fallbackInView
              onChange={(InVidew) => {
                if (InVidew && hasNextPage && !isFetchingNextPage) {
                  fetchNextPage();
                }
              }}
            >
              {({ ref }) => (
                <Box
                  ref={ref}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ pb: 1 }}
                >
                  {isFetchingNextPage && <CircularProgress size={25} />}
                </Box>
              )}
            </InView>
          </Box>
        </Paper>
      </Grid>
    );
  };
  
  export default SearchCustomers;
  