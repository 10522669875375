import { requestWithToken, encodeBase64, requestSimple } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_AUTOMATION, API_V3_URL } from "@/libs/constants";
class ContactApi {
  List = ({ query = null, searchQuery = null, page = 1, pageSize = 20 }) => {
    return useQueryWithCustomized(
      ["contact.list", query, page, searchQuery, pageSize],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/contacts?${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ""}&page=${page || 1
          }&page_size=${pageSize || 20}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              pagination: data?.data?.pagination,
              contacts: data?.data?.contacts,
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  ContactHR = ({ query = null, searchQuery = null, page = 1, pageSize = 20 }) => {
    return useQueryWithCustomized(
      ["contact.list_hr", query, page, searchQuery, pageSize],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/contacts/list?${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ""}&page=${page || 1
          }&page_size=${pageSize || 20}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              pagination: data?.data?.pagination,
              contacts: data?.data?.contacts,
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Detail = ({ contactId }) => {
    return useQueryWithCustomized(
      ["contact.detail", contactId],
      async () => {
        return await requestWithToken(`${API_V3_URL}/contacts/${contactId}`)
          .then(({ data }) => {
            return data?.data?.contact;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Statistic = ({ query = null, searchQuery = null }) => {
    return useQueryWithCustomized(
      ["contacts.statistic", query, searchQuery],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/contacts/statistic?${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ""}`
        )
          .then(({ data }) => {
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Update = async ({ contactId = -1, data = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/contacts/${contactId}`,
      "PUT",
      {
        contact: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Markspam = async ({ contactId = -1 }) => {
    return await requestWithToken(
      `${API_V3_URL}/contacts/${contactId}/markspam`,
      "PUT",
      {},
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Verified = async ({ contactId = -1 }) => {
    return await requestWithToken(
      `${API_V3_URL}/contacts/${contactId}/verified`,
      "PUT",
      {},
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  ConvertToHR = async ({ contactId = -1 }) => {
    return await requestWithToken(
      `${API_V3_URL}/contacts/${contactId}/convert_to_hr`,
      "PUT",
      {},
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Conversation = ({ conversationId }) => {
    return useQueryWithCustomized(
      ["contact.conversations", conversationId],
      async () => {
        return await requestSimple(
          `${API_AUTOMATION}/api/workers/conversations/${conversationId}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Create = async ({ data }) => {
    return await requestWithToken(
      `${API_V3_URL}/contacts/`,
      "POST",
      { contact: data },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Import = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await requestWithToken(
      `${API_V3_URL}/contacts/import`,
      "POST",
      formData
    );

    return response?.data;
  };

  Revenue = ({ contactId }) => {
    return useQueryWithCustomized(
      ["contact.revenue", contactId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/contacts/${contactId}/revenue`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Explaination = ({ contactId }) => {
    return useQueryWithCustomized(
      ["contact.explaination", contactId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/contacts/${contactId}/explaination`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Destroy = async ({ contactId }) => {
    return await requestWithToken(
      `${API_V3_URL}/contacts/${contactId}`,
      "DELETE",
      {},
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ContactApi();
