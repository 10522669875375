import InvoiceApi from "@/apis/beta/Invoice/InvoiceApi";
import { format_date_short } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import FormatTextNumeralVND from "@components/shared/FormatTextNumeralVND";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import {
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";

const QuickView = ({
  setAnchorEl,
  refEl = undefined,
  invoice_id = undefined,
}) => {
  const { data: invoice_items } = InvoiceApi.QuickView({
    invoiceId: invoice_id,
  });

  console.log(`invoice_items`, invoice_items);
  const [subtotal] = React.useState(
    _.sum(_.map(invoice_items, (item) => item.invoice_item_amount))
  );
  const [tax] = React.useState(
    _.sum(
      _.map(
        _.filter(invoice_items, (o) => o.invoice_item_tax),
        (item) => item.invoice_item_amount * 0.1
      )
    )
  );

  return (
    <Popover
      onClose={() =>
        setAnchorEl({
          anchorEl: undefined,
          data: undefined,
          refEl: undefined,
        })
      }
      anchorEl={refEl}
      open={true}
    >
      <Paper elevation={3} sx={{ maxWidth: 650, overflow: "hidden" }}>
        <Table size="small">
          <TableHead sx={{ bgcolor: "rgba(224, 224, 224, 1)" }}>
            <TableRow>
              <TableCell sx={{ width: "80%", py: 1 }}>
                <Typography
                  sx={{ fontSize: 13, fontWeight: 500, color: "#000000" }}
                >
                  Dịch Vụ/Mô Tả
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "20%", py: 1 }}>
                <Typography
                  sx={{ fontSize: 13, fontWeight: 500, color: "#000000" }}
                >
                  Thành Tiền
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice_items?.map((item) => {
              return (
                <TableRow sx={{ bgcolor: "#FFFFFF ! important" }}>
                  <TableCell sx={{ py: 1 }}>
                    <Typography sx={{ fontSize: 13 }}>
                      {item.invoice_item_description}(
                      {format_date_short(item.invoice_item_startdate)} -
                      {format_date_short(item.invoice_item_enddate)})
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ py: 1 }}>
                    <FormatTextNumeralVND
                      sx={{
                        fontSize: 13,
                        float: "right",
                      }}
                      value={item.invoice_item_amount}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow sx={{ bgcolor: "#FFFFFF ! important" }}>
              <TableCell sx={{ textAlign: "right", py: 1 }}>
                <Typography sx={{ fontSize: 13 }}>Tạm Tính</Typography>
              </TableCell>
              <TableCell sx={{ py: 1 }}>
                <FormatTextNumeralVND
                  sx={{
                    fontSize: 13,
                    float: "right",
                  }}
                  value={subtotal}
                />
              </TableCell>
            </TableRow>
            <TableRow sx={{ bgcolor: "#FFFFFF ! important" }}>
              <TableCell sx={{ textAlign: "right", py: 1 }}>
                <Typography sx={{ fontSize: 13 }}>@Thuế</Typography>
              </TableCell>
              <TableCell sx={{ py: 1 }}>
                <FormatTextNumeralVND
                  sx={{
                    fontSize: 13,
                    float: "right",
                  }}
                  value={tax}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ textAlign: "right", py: 1 }}>
                <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                  Tổng Hoá Đơn
                </Typography>
              </TableCell>
              <TableCell sx={{ py: 1 }}>
                <FormatTextNumeralVND
                  sx={{
                    fontSize: 14,
                    float: "right",
                  }}
                  value={_.sumBy(invoice_items, (item) => {
                    if (item.invoice_item_tax)
                      return (
                        item?.invoice_item_amount * 0.1 +
                        item?.invoice_item_amount
                      );
                    return item?.invoice_item_amount;
                  })}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Popover>
  );
};

export default withSuspense(QuickView, MuiSkeleton["LinearProgress"]);
