import ContentDeliveryNetwork from "./ContentDeliveryNetwork";
import Default from "./Default";
import StorageLFD from "./StorageLFD";
import Transcoding from "./Transcoding";

export const Swiftfederation = {
  ContentDeliveryNetwork: ContentDeliveryNetwork,
  Transcoding: Transcoding,
  StorageLFD: StorageLFD,
  Default: Default,
};
