import { UNLIMITED } from "@/libs/constants";
import NumericFormatCustom from "@components/NumericFormatCustom";
import {
  Box,
  Collapse,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import ProductCommitment from "./private/ProductCommitment";
import ProductPaymentType from "./private/ProductPaymentType";
import ProductPricingInit from "./private/ProductPricing";
import ProductPricingAmount from "./private/ProductPricingAmount";
import ProductPricingConfigures from "./private/ProductPricingConfigures";
import ProductPricingUnit from "./private/ProductPricingUnit";
import ServiceManagementBilling from "@components/Templates/ServiceForm/shared/ServiceManagementBilling";
import ProductPrepaid from "./private/ProductPrepaid";

const Customized = ({ isPoc }) => {
  const { watch, control } = useFormContext();

  if (isPoc) return <></>;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {!isPoc && (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
          }}
        >
          <ProductPaymentType />
          <ProductPricingUnit />
        </Box>
      )}
      {watch("product_payment_type") !== "postpaid" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
          }}
        >
          <Box sx={{ minWidth: { xs: "100%", md: "calc((100% - 16px) / 2)" } }}>
            <ProductCommitment />
          </Box>
          <ProductPricingInit />
          {!(
            parseFloat(watch(`product_pricing_configures.value`)) >= UNLIMITED
          ) ? (
            <Collapse
              sx={{
                width: "100%",
              }}
              in={
                !(
                  parseFloat(watch(`product_pricing_configures.value`)) >=
                  UNLIMITED
                )
              }
            >
              <Controller
                name={`product_pricing_configures.pricing_apply`}
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <FormControl fullWidth sx={{ m: "auto 0 0 0" }}>
                      <TextField
                        label="Đơn giá bán*"
                        InputLabelProps={{ shrink: true }}
                        value={value}
                        onChange={onChange}
                        placeholder="0"
                        size="small"
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                        }}
                      />
                    </FormControl>
                  );
                }}
              />
            </Collapse>
          ) : (
            <Controller
              name={`product_pricing_configures.pricing_amount`}
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl fullWidth sx={{ m: "auto 0 0 0" }}>
                    <TextField
                      label="Giá bán *"
                      InputLabelProps={{ shrink: true }}
                      value={value}
                      onChange={onChange}
                      placeholder="0"
                      size="small"
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                      }}
                    />
                  </FormControl>
                );
              }}
            />
          )}
        </Box>
      )}
      {parseFloat(watch(`product_pricing_configures.value`)) < UNLIMITED &&
        watch("product_payment_type") !== "postpaid" && (
          <Controller
            name={`product_pricing_configures.commitment_term`}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth sx={{ m: "auto 0 12px 0" }}>
                  <TextField
                    label="Thời hạn cấu hình cam kết *"
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={onChange}
                    placeholder="0"
                    size="small"
                    InputProps={{
                      sx: {
                        paddingRight: 0,
                      },
                      inputComponent: NumericFormatCustom,
                      endAdornment: (
                        <Box
                          sx={{
                            display: "flex",
                            m: "auto 0 auto auto",
                            height: "100%",
                            width: {
                              xs: "calc(100% / 3)",
                              md: "calc(100% / 4)",
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              m: "auto 8px auto auto",
                              fontSize: 13,
                              textTransform: "uppercase",
                              color: "#777680",
                            }}
                          >
                            Tháng
                          </Typography>
                        </Box>
                      ),
                    }}
                  />
                </FormControl>
              );
            }}
          />
        )}
      <ProductPricingConfigures />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 1.5,
        }}
      >
        <ProductPricingAmount />
        <ProductPrepaid />
      </Box>
      <ServiceManagementBilling />
    </Box>
  );
};

export default Customized;
