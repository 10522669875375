import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

const StatementOption = () => {
  const { watch, setValue } = useFormContext();
  const [state, setState] = useState("1-30");

  useEffect(() => {
    if (state === "1-30") {
      setValue(
        `service_management_billing.service_control_startdate`,
        dayjs(
          watch(`service_management_billing.service_billing_startdate`)
        ).startOf("month")
      );
      setValue(
        `service_management_billing.service_control_enddate`,
        dayjs(
          watch(`service_management_billing.service_billing_startdate`)
        ).endOf("month")
      );
    } else {
      setValue(
        `service_management_billing.service_control_startdate`,
        dayjs(
          watch(`service_management_billing.service_billing_startdate`)
        ).set("date", 23)
      );
      setValue(
        `service_management_billing.service_control_enddate`,
        dayjs(watch(`service_management_billing.service_billing_startdate`))
          .set("date", 22)
          .set(
            "month",
            dayjs(
              watch(`service_management_billing.service_billing_startdate`)
            ).get("month") + 1
          )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <Box sx={{ mt: 2 }}>
      <Typography sx={{ fontSize: 14 }} color="text.black">
        Chu Kỳ Đối Soát *
      </Typography>
      <RadioGroup
        row
        value={state}
        onChange={(event) => {
          setState(event?.target?.value);
        }}
      >
        <FormControlLabel
          label="Đầu tháng đến cuối tháng"
          control={<Radio size="small" value="1-30" />}
        />
        <FormControlLabel
          label="Ngày 23 đến ngày 22"
          control={<Radio size="small" value="23-22" />}
        />
      </RadioGroup>
    </Box>
  );
};

export default StatementOption;
