import DatePicker from '@components/DatePicker'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import { Box, FormControl, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const Enddate = () => {
    const { control, formState: { errors } } = useFormContext()

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 0.5, md: 2 },
            mt: 2
        }}>
            <Box sx={{ display: 'flex', minWidth: { xs: '100%', md: 300 } }}>
                <Typography sx={{
                    m: 'auto 0',
                    color: "#777680"
                }}>
                    Ngày kết thúc HĐ
                </Typography>
            </Box>
            <Controller
                control={control}
                name="work_contract_enddate"
                render={({ field: { value, onChange } }) => {
                    return (
                        <FormControl fullWidth>
                            <DatePicker value={value} onChange={onChange} />
                            {!!errors?.work_contract_enddate && <ErrorHelperText message={errors?.work_contract_enddate?.message} />}
                        </FormControl>
                    )
                }} />
        </Box>
    )
}

export default Enddate