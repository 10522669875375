import Layout from "./Layout"
import Potential from "./v2/CustomerQualifiedProspected"
import QualifiedProspect from "./v2/CustomerQualifiedProspected"
import Exploited from "./CustomerActive"
import Active from "./v2/CustomerActive"

import Coollead from "./CustomerCoolLead"
import Trial from "./v2/CustomerPOC"
import Stop from "./CustomerStop"



// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Layout,
    Potential,
    Exploited,
    Coollead,
    Trial,
    Stop,
    Active,
    QualifiedProspect
}