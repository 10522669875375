import { encodeBase64, pagyResponse, payloadToQueries, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class ServiceMetadataApi {
    ListModules = () => {
        return useQueryWithCustomized(
            ["scopes.module"],
            async () => {
                return await requestWithToken(`${API_V3_URL}/scopes/modules`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return { modules: data?.data?.modules || [] }
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            })
    };

    List = ({ query, searchQuery, page = 1, pageSize = 20 }) => {
        const payload = {
            filters: encodeBase64(query),
            queries: encodeBase64(searchQuery),
            page,
            page_size: pageSize,
        }

        return useQueryWithCustomized(
            ["scopes.all", page, pageSize, query, searchQuery],
            async () => {
                return await requestWithToken(`${API_V3_URL}/scopes?${payloadToQueries(payload)}`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return {
                            scopes: data?.data?.scopes || [],
                            pagination: pagyResponse(data?.data?.pagination)
                        }
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            })
    };

    Detail = ({ scopeId = -1 }) => {
        return useQueryWithCustomized(
            ["scopes", scopeId],
            async () => {
                return await requestWithToken(`${API_V3_URL}/scopes/${scopeId}`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return { scope: data?.data?.scope || {} }
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            })
    };

    Create = async ({ data = {} }) => {
        return await requestWithToken(`${API_V3_URL}/scopes`, "POST", {
            scope: data
        }, {}, {})
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                return error.response;
            });
    }

    Update = async ({ scopeId = -1, scope = {} }) => {
        return await requestWithToken(`${API_V3_URL}/scopes/${scopeId}`, "PUT", {
            scope: scope
        }, {}, {})
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                return error.response;
            });
    }

    Assignee = async ({ scopeId = -1, scope = {} }) => {
        return await requestWithToken(`${API_V3_URL}/scopes/${scopeId}`, "PUT", {
            scope: {
                scope_assignees: scope?.scope_assignees || []
            }
        }, {}, {})
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                return error.response;
            });
    }

    Destroy = async ({ scopeId = -1 }) => {
        return await requestWithToken(`${API_V3_URL}/scopes/${scopeId}`, "DELETE")
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                return error.response;
            });
    }

    EmployeeScopes = ({ employeeId = -1 }) => {
        return useQueryWithCustomized(
            ["scopes", "employees", employeeId],
            async () => {
                return await requestWithToken(`${API_V3_URL}/scopes/employees/${employeeId}`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return {
                            scopes: data?.data?.scopes || [],
                            roles: data?.data?.roles || []
                        }
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            })
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new ServiceMetadataApi();
