import NumericFormatCustom from '@components/NumericFormatCustom'
import { useStateArrayContext } from '@contexts/stateArray'
import { FormControl, TextField } from '@mui/material'
import React from 'react'

const ServicePrice = ({ data }) => {
    const methods = useStateArrayContext()

    return (
        <FormControl fullWidth>
            <TextField
                size='small'
                InputProps={{
                    inputComponent: NumericFormatCustom
                }}
                value={data?.product_price}
                placeholder='0'
                onChange={(event) => {
                    methods.updateAttribute({ product_price: event?.target?.value }, data?.id)
                }}
            />
        </FormControl>
    )
}

export default ServicePrice