import NumericFormatCustom from '@components/NumericFormatCustom'
import { Box, FormControl, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const WContractSalaryBasic = () => {
    const { control, formState: { errors } } = useFormContext()

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 0.5, md: 2 },
            mt: 2
        }}>
            <Box sx={{ display: 'flex', minWidth: { xs: '100%', md: 300 } }}>
                <Typography sx={{
                    m: 'auto 0',
                    color: "#777680"
                }}>
                    Lương Cơ Bản
                </Typography>
            </Box>
            <Controller
                control={control}
                name="work_contract_salary_basic"
                render={({ field: { value, onChange } }) => {
                    return (
                        <FormControl fullWidth>
                            <TextField
                                value={value}
                                onChange={onChange}
                                InputProps={{
                                    inputComponent: NumericFormatCustom
                                }}
                                size='small'
                                error={!!errors?.work_contract_salary_basic}
                                helperText={errors?.work_contract_salary_basic?.message}
                            />
                        </FormControl>
                    )
                }} />
        </Box>
    )
}

export default WContractSalaryBasic