import { TextField } from '@mui/material'
import React from 'react'
import { SearchOutlined } from '@mui/icons-material'

const InputSearch = ({ value, onChange, placeholder }) => {
    return (
        <TextField
            size='small'
            value={value}
            onChange={(event) => onChange(event?.target?.value)}
            placeholder={placeholder || "Nhập từ khoá tìm kiếm..."}
            InputProps={{
                startAdornment: <SearchOutlined fontSize='small' sx={{ color: '#757575', mr: 1 }} />,
            }}
            fullWidth
        />
    )
}

export default InputSearch