import React from 'react'
import ProductPricingMethod from './ProductPricingMethod'
import ProductPricingSteps from './ProductPricingSteps'

const ProductPricingConfigures = () => {

    return (
        <React.Fragment>
            <ProductPricingMethod />
            <ProductPricingSteps />
        </React.Fragment>
    )
}

export default ProductPricingConfigures