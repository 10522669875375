import ServiceActive from "./Active";
import Informations from "./Informations";
import PriceRecurring from "./PriceRecurring";
import PriceRegister from "./PriceRegister";
import CreateInvoiceRenew from "./Renew";
import SaleCommission from "./SaleCommission";
import ServiceBillingCurrent from "./ServiceBillingCurrent";
import ServiceBillingNext from "./ServiceBillingNext";
import ServiceBillingStatement from "./ServiceBillingStatement";
import ServiceSetDate from "./ServiceDateRenew";
import ServiceStartdate from "./ServiceStartdate";
import ServiceCancel from "./ServiceCancel";
import ServiceCreateNote from "./ServiceCreateNote";
import Configoptions from "./Configoptions";
import ServiceMetadata from "./Metadata";

// eslint-disable-next-line import/no-anonymous-default-export
const ServiceActionUI = {
  'service-start-date': ServiceStartdate,
  'service-active': ServiceActive,
  'service-cancel': ServiceCancel,
  'service-current-billing': ServiceBillingCurrent,
  'service-next-billing': ServiceBillingNext,
  'service-date-renew': ServiceSetDate,
  'service-date-statement': ServiceBillingStatement,
  'service-price-amount': PriceRegister,
  'service-price-recurring': PriceRecurring,
  'service-commissions': SaleCommission,
  'service-create-invoice-renew': CreateInvoiceRenew,
  'service-information': Informations,
  'service-configoptions': Configoptions,
  'service-note': ServiceCreateNote,
  'service-metadata': ServiceMetadata
};
export default ServiceActionUI;
