import withPermission from '@/hocs/withPermission'
import { Box, Typography } from '@mui/material'
import React from 'react'
import SampleContractForm from '../shared/SampleContractForm'

const Detail = ({
    data,
    handleClose
}) => {
    return (
        <Box sx={{ p: 3, width:{ xs: "auto", md: 550 }, minWidth: 360, height: "100vh" }}>
            <Typography sx={{ textTransform: 'uppercase', mb: 2 }}>Thông Tin Hợp Đồng Mẫu</Typography>
            <SampleContractForm type="detail"
                data={data}
                handleClose={handleClose}
            />
        </Box>
    )
}

export default withPermission(Detail, { feature: 'contract', action: 'sample-contract-show' })