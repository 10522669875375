import { ErrorOutlineOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'

const SelfRegister = () => {
    return (
        <Box
            sx={{
                p: "20px",
                borderRadius: "14px",
                bgcolor: "#FF980014",
                mb: 1.5
            }}
        >
            <Box sx={{ display: "flex" }}>
                <ErrorOutlineOutlined
                    sx={{
                        width: 24,
                        height: 24,
                        color: "#FF9800",
                        m: "auto 8px auto 0",
                    }}
                />
                <Typography sx={{ color: "#FF9800" }}>
                    SaaS Customer
                </Typography>
            </Box>
            <Typography
                sx={{
                    flexWrap: "wrap",
                    textAlign: "justify",
                    ml: 4,
                    mt: 0.5,
                }}
            >
                {
                    "Khách hàng tự đăng kí tài khoản trên VNETWORK Cloud Platform."
                }
            </Typography>
        </Box>
    )
}

export default SelfRegister