import { Box, Typography } from '@mui/material'
import React from 'react'
export const { default: CellStatus } = require('./FormatterStatus');
export const { default: CellDescription } = require('./FormatterDescription');
export const { default: CellLink } = require('./FormatterReactLink');
export const { default: CellPricing } = require('./FormatterPricing');
export const { default: CellBreakdown } = require('./FormatterAutoBreakdown');
export const { default: CellBreakdownLink } = require('./FormatterBreakdownLink');



const Cell = ({ content }) => {
    return (
        <Box sx={{
            display: 'flex',
            height: 52,
            width: '100%',
            overflow: "hidden",
            overflowX: 'auto',
            "&::-webkit-scrollbar": {
                height: 1.5,
                "&-track": {
                    background: '#f1f1f1'
                },
                "&-thumb": {
                    background: "#000"
                }
            }
        }}>
            <Typography sx={{ fontSize: 14, flexWrap: 'wrap', m: 'auto 0', textAlign: "justify" }}>
                {content}
            </Typography>
        </Box>
    )
}

export default Cell