import withPermission from '@/hocs/withPermission'
import { AddOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const ButtonCreate = () => {
    return (
        <Link to="/products/new" replace>
            <Button size='small'
                variant='contained'
                startIcon={<AddOutlined fontSize='small' />}
            >
                Thêm Sản Phẩm
            </Button>
        </Link>
    )
}

export default withPermission(ButtonCreate, { feature: "product", action: "create", type: "button" })