import { PRODUCT_TYPE } from '@/libs/constants'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, FormControl, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ProductType = ({ type = "create" }) => {
    const { control, formState: { errors }, watch } = useFormContext()
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 1,
            mt: 2
        }}>
            <Box sx={{ display: 'flex', minWidth: 300 }}>
                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                    Loại Sản Phẩm *
                </Typography>
            </Box>
            <Controller control={control}
                name="product_type"
                render={({ field: { value, onChange } }) => {
                    const selected = PRODUCT_TYPE.slice(0, 2).find(item => item?.value === value) || null
                    return (
                        <FormControl fullWidth>
                            <SingleSelectCustomize
                                disabled={watch("product_template") === "PrivateCDN"}
                                value={selected}
                                options={PRODUCT_TYPE.slice(0, 2)}
                                input={{
                                    placeholder: "Nhập tên loại sản phẩm..."
                                }}
                                onChange={(event, newValue) => onChange(newValue?.value || -1)} />
                            {!!errors?.product_type && <ErrorHelperText message={errors?.product_type?.message} />}
                        </FormControl>
                    )
                }} />
        </Box>
    )
}

export default ProductType