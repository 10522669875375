import PopperAction from "@components/PopperAction";
import MoreVertOutlined from "@mui/icons-material/MoreVertOutlined";
import { Box, IconButton, Popper } from "@mui/material";
import { useState } from "react";
// import ServiceReject from "./Reject";
// import ServiceAdditional from "./Additional";
import SubcontractUpdate from "../../Update/SubcontractUpdate";

const Actions = ({ data }) => {
    const ACTIONS = [
        {
            order: 1,
            icon: "Edit",
            name: "Edit",
            label: "Chỉnh Sửa",
            handleClick: (row) =>
                setAction({
                    open: true,
                    name: "subcontract-edit",
                    data: row,
                }),
        }, {
            order: 2,
            icon: "See",
            name: "subcontract-preview",
            label: "Xem PLHĐ",
            disabled: data?.subcontract_status !== 0 && data?.subcontract_status !== 8,
            handleClick: (row) => {
                try {
                    const link = row.link ? row.link : (row?.subcontract_attachments[0]?.attachment_file_url ? `${process.env.REACT_APP_DOMAIN_FILE}${row?.subcontract_attachments[0]?.attachment_file_url.replace("pub/", "")}` : "#")
                    window.open(link, row.contract_id, "height=720, width=1080, left=100, top=100, resizable=yes, scrollbars=yes, toolbar=yes, menubar=no, location=no, directories=no, status=yes");
                } catch (e) {
                    console.log(e)
                }
            }
        },
    ];
    const [popper, setPopper] = useState({
        anchorEl: undefined,
        data: undefined,
        actions: [],
    });
    const [action, setAction] = useState({
        open: false,
        name: undefined,
        data: undefined,
    });
    const onClosePopper = () =>
        setPopper((prev) => ({
            ...prev,
            anchorEl: undefined,
            data: undefined,
            actions: [],
        }));

    const onCloseAction = () =>
        setAction({ open: false, name: undefined, data: undefined });
    const renderComponents = (type, data) => {
        switch (type) {
            case "subcontract-edit":
                return <SubcontractUpdate onClose={onClose} defaultValues={data} />;
            default: return null;
        }
    };

    const onClose = () => {
        onCloseAction()
    }

    return (
        <Box
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            {action?.open && renderComponents(action?.name, action?.data)}
            {popper?.anchorEl !== undefined && (
                <Popper
                    anchorEl={popper?.anchorEl}
                    sx={{
                        zIndex: 99,
                    }}
                    open={Boolean(popper.anchorEl)}
                    onMouseLeave={onClosePopper}
                >
                    <PopperAction {...popper} handleClose={onCloseAction} />
                </Popper>
            )}
            <IconButton
                size="small"
                onClick={(event) => {
                    setPopper((prev) => ({
                        ...prev,
                        anchorEl: event?.currentTarget,
                        data,
                        actions: ACTIONS,
                    }));
                }}
            >
                <MoreVertOutlined fontSize="small" />
            </IconButton>
        </Box>
    );
};

export default Actions;
