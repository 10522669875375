import CustomerApi from '@/apis/beta/Customer/CustomerApi'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const ModalDeleteCustomer = ({
    customerId =-1,
    customer,
    onClose,
    callback,
    submit,
    setSubmit
}) => {

    const navigate = useNavigate()
    const [isError, setError] = React.useState(false)
    const [reasonCancel, setReasonCancel] = React.useState("Bị trùng với khách hàng cũ ID số: ")

    const onSubmit = async () => {
        if (!reasonCancel || reasonCancel?.trim() === "") {
            return setError(true)
        }
        setSubmit(true)
        const response = await CustomerApi.UpdateCSI({
            customerId: customerId,
            data: {
                customer_status: "soft-delete",
                customer_note: reasonCancel
            }
        })
        setSubmit(false)
        if (!response || response?.errors) {
            return {
                status: response?.status || 404,
                message: "Xoá khách hàng thất bại."
            }
        }
        return {
            status: 200,
            message: "Xoá khách hàng thành công.",
            action: navigate(`/customers`, { replace: true })
        }
    }

    return (
        <Dialog open={true} maxWidth="md" fullWidth>
            <DialogTitle>
                Xác nhận xoá khách hàng
            </DialogTitle>
            <DialogContent>
                <Typography component="div">
                    Khách hàng
                    <Typography sx={{ display: 'inline', fontWeight: 'medium', m: 'auto 4px' }}>
                        [{customer?.customer_information?.name}]
                    </Typography>
                    sẽ bị xoá khỏi hệ thống, mọi trách nhiệm sẽ thuộc về bạn nếu có ảnh hưởng đến dữ liệu thực tế của khách hàng này. Thao tác sẽ không thể được khôi phục, bạn có muốn thực hiện?
                </Typography>
                <FormControl fullWidth sx={{mt:2}}>
                    <TextField 
                        label="Lý do xoá *"
                        InputLabelProps={{shrink: true}}
                        placeholder='Nhập lý do xoá khách hàng...'
                        multiline
                        minRows={3}
                        value={reasonCancel}
                        onChange={(event) => {
                            if(isError) {setError(false)}
                            setReasonCancel(event?.target?.value)
                        }}
                        error={isError}
                        helperText={isError ? "Lý do xoá không được để trống" : ""}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button size='small' variant='outlined' onClick={onClose}
                disabled={submit}
                >
                    Huỷ Thao Tác
                </Button>
                <Button size='small' variant='contained' onClick={onSubmit}
                    disabled={submit || isError}
                    startIcon={submit && <CircularProgress size={14} sx={{color:"#777680"}}/>}
                >
                    Xác nhận
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalDeleteCustomer