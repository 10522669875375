import { getRangeDates } from '@/functions'
import DatePicker from '@components/datepickers/DateRangPicker'
import { FormControl } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

const ServiceFirstTime = () => {
    const { setValue, watch } = useFormContext()

    useEffect(() => {
        let startdate = dayjs(watch(`service_billing_startdate`))
        let enddate = dayjs(watch(`service_billing_enddate`))
        const [months,] = getRangeDates(startdate, enddate)
        setValue(`service_nextbill_startdate`, enddate.add(1, 'day'))
        setValue(`service_nextbill_enddate`, enddate.add(months, 'months').endOf("months"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch(`service_billing_enddate`)])
    return (
        <FormControl fullWidth>
            <DatePicker
                value={[dayjs(watch(`service_billing_startdate`)), dayjs(watch(`service_billing_enddate`))]}
                handler={(newDates) => {
                    setValue(`service_billing_startdate`, newDates[0])
                    setValue(`service_billing_enddate`, newDates[1])
                }}
                localeText={{
                    start: "Thời Gian Thanh Toán Đầu Tiên",
                    end: 'Ngày Kết Thúc'
                }}
            />
        </FormControl>
    )
}

export default ServiceFirstTime