import { format_numeral_price } from '@/functions'
import { Box, Typography } from '@mui/material'

const ServiceMetadata = ({ data }) => {
    if (data?.product_informations?.length === 0) return null
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1.5 }}>
            {data?.product_informations?.map((item, index) => {
                return (
                    <Typography key={index} sx={{
                        fontSize: 14,
                    }}>
                        {item?.name}:{" "}
                        <Typography sx={{
                            display: 'inline-block',
                            fontWeight: 'medium',
                            fontSize: 14
                        }}>
                            {format_numeral_price(item?.warehouse_capacity_value)}
                        </Typography>
                        <Typography sx={{
                            display: 'inline-block',
                            textTransform: 'capitalize',
                            fontSize: 14,
                        }}>
                            {item?.unit !== 'qty' && item?.unit}
                        </Typography>
                    </Typography>
                )
            })}
        </Box>
    )
}

export default ServiceMetadata