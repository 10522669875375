import { Paper, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const PostLastest = () => {
    return (
        <Paper sx={{ p: 3, mb: 3 }} elevation={8}>
            <Typography sx={{ mb: 2 }} color="primary">Bài viết mới</Typography>
            <Link to={"www.facebook.com"}>
                <Typography sx={{ fontSize: 14 }} color='primary'><b>Facebook</b>: Hoạt động mới</Typography>
            </Link>
        </Paper>
    )
}

export default PostLastest