import { DAYOFF_TIME_OPTIONS } from '@/libs/constants'
import DatePicker from '@components/DatePicker'
import { AddOutlined, DeleteOutlined } from '@mui/icons-material'
import { Box, Button, Divider, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

const DayoffRangeTime = ({ type }) => {
    const { control } = useFormContext()

    const { fields, append, remove } = useFieldArray({
        control: control,
        name: "day_off_range_time"
    })

    const handleAppend = () => {
        let dayLatest = moment(fields.at(fields?.length - 1)?.[`startdayoff`])
        return append({ startdayoff: dayLatest.add(1, 'days'), dayoff_option: 0 })
    }
    return (
        <React.Fragment>
            <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: { xs: 0.5, md: 2 },
                mt: { xs: 1, md: 2 }
            }}>
                <Box sx={{ display: 'flex', width: { xs: '100%', md: '30%' } }}>
                    <Typography sx={{ color: "#777680" }}>
                        Ngày Nghỉ
                    </Typography>
                </Box>
                <Box sx={{ width: '100%' }}>
                    {fields?.map((dayoff, index) => {
                        return (
                            <React.Fragment key={dayoff.id}>
                                {index !== 0 && <Divider sx={{ m: '8px 0' }} light />}
                                <Box sx={{
                                    display: 'flex',
                                    gap: { md: 2, xs: 1 },
                                    justifyContent: 'space-between',
                                    flexDirection: { xs: 'column', md: 'row' },
                                    mt: index !== 0 ? 1 : 0
                                }}>
                                    <Controller
                                        control={control}
                                        name={`day_off_range_time.[${index}].startdayoff`}
                                        render={({ field: { value, onChange } }) => {
                                            return (
                                                <FormControl fullWidth sx={{ m: 'auto 0', minWidth: { xs: '100%', md: 'auto' } }}>
                                                    <DatePicker value={value} onChange={onChange}
                                                        label={`Ngày ${index + 1}`}
                                                        disabled={type === "cancel"}
                                                    />
                                                </FormControl>
                                            )
                                        }} />
                                    <Box sx={{ minWidth: { xs: '100%', md: 'calc((100% - 28px - 16px * 2) / 2)' } }}>
                                        <Typography sx={{ fontSize: 14 }}>
                                            Thời gian nghỉ
                                        </Typography>
                                        <Controller
                                            control={control}
                                            name={`day_off_range_time.[${index}].dayoff_option`}
                                            render={({ field: { value, onChange } }) => {
                                                return (
                                                    <FormControl fullWidth>
                                                        <RadioGroup row
                                                            sx={{ flexWrap: 'wrap' }}
                                                            value={value}
                                                            disabled={type === "cancel"}
                                                            onChange={onChange}
                                                        >
                                                            {DAYOFF_TIME_OPTIONS?.map(option =>
                                                                <FormControlLabel
                                                                    {...option}
                                                                    key={option?.value}
                                                                    control={<Radio size='small' value={option?.value} />}
                                                                />)}
                                                        </RadioGroup>
                                                    </FormControl>
                                                )
                                            }} />
                                    </Box>
                                    <IconButton size="small" sx={{
                                        width: 28,
                                        height: 28,
                                        m: 'auto 0 auto auto'
                                    }}
                                        disabled={fields.length === 1}
                                        onClick={() => remove(index)}
                                    >
                                        <DeleteOutlined fontSize='small' sx={{
                                            ":hover": {
                                                color: fields.length !== 1 && "#ef5350"
                                            }
                                        }} />
                                    </IconButton>
                                </Box>
                            </React.Fragment>
                        )
                    })}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button size='small' sx={{ height: 36 }} startIcon={<AddOutlined fontSize='small' />}
                    onClick={handleAppend}
                    disabled={type === "cancel"}
                >
                    thêm ngày nghỉ
                </Button>
            </Box>
        </React.Fragment>
    )
}

export default DayoffRangeTime