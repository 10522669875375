import ServiceAddonApi from '@/apis/beta/Service/ServiceAddonApi';
import DataGrid from '@/components/DataGrid';
import { CellLink, CellStatus, CellPricing } from '@/components/DataGrid/Cell';
import { buildQueryFilterCondition, format_date_short } from '@/functions';
import withPermission from '@/hocs/withPermission';
import withSuspense from '@/hocs/withSuspense';
import useDebounce from '@/hooks/useDebounce';
import { SERVICE_STATUS_TYPES } from '@/libs/constants';
import MuiSkeleton from '@components/shared/MuiSkeleton';
import { Box } from '@mui/material';
import React from 'react';

const List = () => {
    const [page, setPage] = React.useState({
        page: 0,
        pageSize: 10
    });

    const [searchQuery, setSearchQuery] = React.useState('')
    const debouncedSearchQuery = useDebounce(searchQuery, 500)

    const filterableServiceAddon = React.useCallback(() => {
        if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === '') return '';
        const fieldQueries = [
            {
                name: 'tw_services.service_referrer_id',
                operator: "$eq",
                value: parseInt(debouncedSearchQuery),
                disabled: !parseInt(debouncedSearchQuery)
            }, {
                name: 'tw_services.service_id',
                operator: "$eq",
                value: parseInt(debouncedSearchQuery),
                disabled: !parseInt(debouncedSearchQuery)
            },
            { name: 'tw_services.service_name', operator: "$contains", value: debouncedSearchQuery },
        ]
        return buildQueryFilterCondition("$or", 0, fieldQueries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery])

    const initialColumns = [
        {
            field: 'service_id', headerName: 'ID',
            width: 60,
            hideable: true,
            valueGetter: ({ row }) => row?.service_id,
        }, {
            field: 'service_referrer_id', headerName: 'Dịch Vụ',
            width: 300,
            valueGetter: ({ row }) => ({
                href: ['services', row?.service_referrer_id],
                label: `${row?.service_referrer_id} - ${row?.service_referrer_name}`,
            }),
            renderCell: ({ value }) => <CellLink data={value} />
        }, {
            field: 'service_name', headerName: 'Tên Dịch Vụ',
            width: 200,
        }, {
            field: 'service_amount', headerName: 'Đơn Giá', width: 150,
            renderCell: ({ value }) => <CellPricing price={value} />
        }, {
            field: 'created_at', headerName: 'Ngày Tạo',
            width: 140,
            renderCell: ({ value }) => format_date_short(value),
        }, {
            field: 'service_start_date', headerName: 'Ngày Bắt Đầu DV',
            width: 140,
            renderCell: ({ value }) => format_date_short(value),
        }, {
            field: 'service_duedate', headerName: 'Ngày Kết Thúc DV',
            width: 140,
            renderCell: ({ value }) => format_date_short(value),
        }, {
            field: 'service_status', headerName: 'Trạng Thái',
            width: 150,
            valueGetter: ({ row }) => SERVICE_STATUS_TYPES.find(stt => stt?.value === row?.service_status),
            renderCell: ({ value }) => <CellStatus component="muiChip" data={value} />
        }]
    const historyColumsHidden = JSON.parse(localStorage.getItem("service-addons.list.hiddenColumns")) || []
    const [columns, setColumns] = React.useState(initialColumns?.map((column) => {
        if (historyColumsHidden?.includes(column?.field)) {
            return { ...column, hide: true }
        }
        return { ...column }
    }) || initialColumns)
    const { data, isFetching } = ServiceAddonApi.List({
        page: page?.page + 1,
        pageSize: page?.pageSize || 10,
        query: filterableServiceAddon() !== "" ? filterableServiceAddon() : ""
    });

    return (
        <Box sx={{ mt: 3 }}>
            <DataGrid
                size='small'
                rows={data?.service_addons?.map((service, index) => ({
                    ...service,
                    id: index
                })) || []}
                columns={columns}
                loading={isFetching}
                componentsProps={{
                    toolbar: {
                        store: "services.list.hiddenColumns",
                        columns: columns?.filter(col => !col?.hideable),
                        setColumns: setColumns,
                        initialColumns: initialColumns,
                        inputSearch: {
                            placeholder: "Nhập ID/ Tên sản phẩm dịch vụ mua thêm tìm kiếm...",
                            value: searchQuery,
                            onChange: setSearchQuery
                        }
                    },
                    pagination: {
                        rowsPerPageOptions: [10, 25, 50, 100],
                        page: page?.page || 0,
                        pageSize: page?.pageSize,
                        onPageChange: (newPage) => setPage(prevState => ({ ...prevState, page: newPage })),
                        onPageSizeChange: (newPageSize) => setPage((prevState) => ({ ...prevState, pageSize: newPageSize }))
                    }
                }}
                rowCount={data?.pagination?.total}
            />
        </Box>
    )
}

export default withSuspense(
    withPermission(
        List, {
        feature: 'service',
        action: 'service-addon-index',
        type: 'Block'
    }), MuiSkeleton["DataGrid"])

