import ServiceActive from "./Active";
import Informations from "./Informations";
import PriceRecurring from "./PriceRecurring";
import PriceRegister from "./PriceRegister";
import CreateInvoiceRenew from "./Renew";
import SaleCommission from "./SaleCommission";
import ServiceBillingCurrent from "./ServiceBillingCurrent";
import ServiceBillingNext from "./ServiceBillingNext";
import ServiceBillingStatement from "./ServiceBillingStatement";
import ServiceEdit from "./ServiceEdit";
import ServiceSetDate from "./ServiceSetDate";
import ServiceStartdate from "./ServiceStartdate";
import ServiceCancel from "./Stop";
import ViewContract from "./ViewContract";

// eslint-disable-next-line import/no-anonymous-default-export
const ServiceModules = {
  ServiceEdit: ServiceEdit,
  ServiceActive: ServiceActive,
  ServiceCancel: ServiceCancel,
  ViewContract: ViewContract,
  Informations: Informations,
  CreateInvoiceRenew: CreateInvoiceRenew,
  EmployeeSaleCommission: SaleCommission,
  PriceRegister: PriceRegister,
  PriceRecurring: PriceRecurring,
  ServiceStartdate: ServiceStartdate,
  ServiceBillingCurrent: ServiceBillingCurrent,
  ServiceBillingNext: ServiceBillingNext,
  ServiceBillingStatement: ServiceBillingStatement,
  ServiceSetdateRenew: ServiceSetDate,
};
export default ServiceModules;
