import BreadCrumb from '@components/shared/BreadCrumb'
import { Box, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import ScheduleForm from '../shared/Form'

const INTIAL_SCHEDULE_DATA = {
    schedule_started_at: moment(),
    schedule_finished_at: moment(),
    schedule_status: 0,
    schedule_booking_room: false,
    schedule_owner_id: -1,
    schedule_guest_id: [],
    schedule_type: "1",
    schedule_title: "",
    schedule_content: "",
    schedule_preview_information: ""
}

const Create = () => {
    return (
        <React.Fragment>
            <Box>
                <Typography sx={{ fontSize: { xs: 18, md: 24 }, fontWeight: 'medium' }}>
                    Thêm Lịch & Hoạt Động
                </Typography>
                <BreadCrumb data={[
                    { label: "Danh Sách Hoạt Động", reactLink: '/' },
                    { label: "Thêm Hoạt Động" },
                ]} />
                <ScheduleForm type="create" defaultAttachment={null}
                    data={INTIAL_SCHEDULE_DATA}
                />
            </Box>
        </React.Fragment>
    )
}

export default Create