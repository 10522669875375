import PositionApi from '@/apis/beta/Employee/PositionApi'
import { buildQueryFilterCondition } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { FormControl, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const EmployeePosition = () => {

    const { control, formState: { errors }, watch } = useFormContext()

    const [departmentId, setDepartmentId] = React.useState(watch("department_id"))
    React.useEffect(() => {
        setDepartmentId(watch("department_id"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch("department_id")])

    const filterablePositions = React.useCallback(() => {
        if (!departmentId) return '';
        const fieldQueries = [
            { name: 'positions.department_id', operator: "$eq", value: departmentId }
        ]
        return buildQueryFilterCondition("$or", 0, fieldQueries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departmentId])
    const { data, isFetching } = PositionApi.ListPositions({ query: filterablePositions() })
    return (
        <React.Fragment>
            <Typography sx={{ mb: 0.5, mt: 2 }}>
                Vị Trí/ Chức Vụ*
            </Typography>
            <Controller
                control={control}
                name="position_id"
                render={({ field: { value, onChange } }) => {
                    const selected = data?.find(pos => pos?.position_id === parseInt(value)) || null
                    return (
                        <FormControl fullWidth>
                            <SingleSelectCustomize
                                loading={isFetching}
                                value={selected}
                                formatCollection={{
                                    value: "position_id",
                                    label: 'position_name'
                                }}
                                compareAttributes={[`position_name`]}
                                options={data || []}
                                disabled={!watch("department_id") || watch("department_id") === -1}
                                onChange={(event, newValue) => onChange(newValue?.position_id || -1)}
                            />
                            {!!errors?.position_id && <ErrorHelperText message={errors?.position_id?.message} />}
                        </FormControl>)
                }} />
        </React.Fragment>
    )
}

export default withSuspense(EmployeePosition, MuiSkeleton["TextField"])