import CVApi from "@/apis/beta/Employee/CVApi";
import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import withSuspense from "@/hocs/withSuspense";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

const SendMailInterviewDialog = ({
  open,
  handleClose,
  updateData,
  refetch,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      leaders: [
        {
          employee: "",
          position: "",
        },
      ],
      interview_time: "",
      interview_date: "",
      location: "",
      note: "",
      cc: [],
    },
  });

  const { id } = useParams();

  const { data, isLoading: EmployeeLoading } = EmployeeApi.ListEmployees();

  const { mutateAsync, isLoading, notify } = useMutationWithNotification(
    ({ sendMailData, updateData }) => {
      return Promise.all([
        CVApi.SendMailInterView(sendMailData),
        CVApi.Accept(updateData),
      ]);
    }
  );

  const handleSendMail = async (values) => {
    const time =  values?.interview_time?.format("hh:mm A")

    const sendMailData = {
      ...values,
      id,
      interview_date: ` - ngày ${values?.interview_date?.format("DD/MM/YYYY")}`,
      interview_time:
        time?.split(" ")?.[time?.split(" ")?.length - 1] === "AM"
          ? time?.split(" ")?.[0] + " sáng"
          : time?.split(" ")?.[0] + " chiều",
      leaders: values?.leaders?.map((item) => ({
        ...item.employee,
        position: item?.position,
      })),
    };

    const response = await mutateAsync({ sendMailData, updateData });

    if (!response?.[0]?.errors && !response?.[1]?.errors) {
      refetch();
      notify("success", "Send mail success!");
    }

    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <Box component="form" onSubmit={handleSubmit(handleSendMail)}>
        <DialogTitle>Gửi Mail Phỏng Vấn Cho Ứng Viên</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box>
              <Box>
                {watch("leaders")?.map((_, index) => (
                  <Box mb={1} key={_.position + index}>
                    <Controller
                      rules={{
                        required: { value: true, message: "Required!" },
                      }}
                      control={control}
                      name={`leaders[${index}].employee`}
                      render={({ field: { onChange, ...field } }) => (
                        <Autocomplete
                          {...field}
                          options={
                            data?.map((item) => ({
                              id: item?.employee_id,
                              label: item?.employee_name,
                            })) || []
                          }
                          onChange={(_, newValue) => {
                            onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              helperText={
                                errors?.leaders?.[index]?.employee?.message
                              }
                              error={!!errors?.leaders?.[index]?.employee}
                              size="small"
                              label="Chọn người phỏng vấn"
                              sx={{ mt: 1, flex: 1 }}
                            />
                          )}
                        />
                      )}
                    />

                    <Controller
                      rules={{
                        required: { value: true, message: "Required!" },
                      }}
                      helperText={errors?.leaders?.[index]?.position?.message}
                      error={!!errors?.leaders?.[index]?.position}
                      name={`leaders[${index}].position`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          helperText={
                            errors?.leaders?.[index]?.position?.message
                          }
                          error={!!errors?.leaders?.[index]?.position}
                          label="Chức vụ"
                          size="small"
                          fullWidth
                          sx={{ flex: 1, mt: 2 }}
                        />
                      )}
                    />
                  </Box>
                ))}

                <Box gap={1} display="flex" flexWrap="wrap">
                  <Button
                    onClick={() =>
                      setValue("leaders", [
                        ...watch("leaders"),
                        { employee: "", position: "" },
                      ])
                    }
                    size="small"
                    variant="outlined"
                    sx={{ mt: 1 }}
                  >
                    <AddIcon />
                  </Button>
                  <Button
                    onClick={() => {
                      const leaders_clone = [...watch("leaders")];

                      if (leaders_clone?.length === 1) return;
                      leaders_clone.pop();

                      setValue("leaders", [...leaders_clone]);
                    }}
                    size="small"
                    variant="outlined"
                    sx={{ mt: 1 }}
                  >
                    <CloseIcon />
                  </Button>
                </Box>
              </Box>

              <Controller
                // rules={{ required: { value: true, message: "Required!" } }}
                control={control}
                defaultValue={[]}
                name="cc"
                render={({ field: { onChange, ...field } }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      onChange(value);
                    }}
                    multiple
                    defaultValue={[]}
                    options={
                      data?.map((item) => ({
                        id: item?.employee_id,
                        label: item?.employee_name,
                        email: item?.employee_email_company,
                      })) || []
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="CC"
                        sx={{ mt: 2 }}
                        disabled={EmployeeLoading}
                      />
                    )}
                  />
                )}
              />

              <Controller
                rules={{ required: { value: true, message: "Required!" } }}
                control={control}
                // defaultValue={[]}
                name="interview_time"
                render={({ field }) => (
                  <TimePicker
                    {...field}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        helperText={errors?.interview_time?.message}
                        error={!!errors?.interview_time}
                        label="Chọn giờ phỏng vấn"
                        sx={{ mt: 2 }}
                        disabled
                        fullWidth
                      />
                    )}
                  />
                )}
              />

              <Controller
                rules={{ required: { value: true, message: "Required!" } }}
                control={control}
                // defaultValue={[]}
                name="interview_date"
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        helperText={errors?.interview_date?.message}
                        error={!!errors?.interview_date}
                        label="Chọn ngày phỏng vấn"
                        sx={{ mt: 2 }}
                        disabled
                        fullWidth
                      />
                    )}
                  />
                )}
              />

              <Controller
                rules={{ required: { value: true, message: "Required!" } }}
                control={control}
                // defaultValue={[]}
                name="location"
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    helperText={errors?.location?.message}
                    error={!!errors?.location}
                    label="Địa điểm phỏng vấn"
                    sx={{ mt: 2 }}
                    fullWidth
                  />
                )}
              />

              <Controller
                control={control}
                name="note"
                render={({ field }) => (
                  <TextField
                    multiline
                    {...field}
                    placeholder="Lưu ý cho ứng viên"
                  />
                )}
              />
            </Box>

            <Button
              disabled={isLoading}
              startIcon={isLoading && <CircularProgress size={14} />}
              type="submit"
              variant="contained"
              sx={{ mt: 3 }}
              fullWidth
            >
              Gửi Ngay
            </Button>
          </LocalizationProvider>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default withSuspense(SendMailInterviewDialog, MuiSkeleton["LinearProgress"]);
