import Home from './Home'
import New from './New'
import Share from './Share'
import Update from './Update'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Home,
  New,
  Share,
  Update
}