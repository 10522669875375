import EmployeeApi from "@/apis/beta/Employee/EmployeeApi"
import { format_date_short, format_date_time, sleep } from "@/functions"
import withPermission from "@/hocs/withPermission"
import withSuspense from "@/hocs/withSuspense"
import MuiSkeletonDefault from "@components/Skeletons/Default"
import { useNotificationContext } from "@contexts/notification"
import { UpdateOutlined } from "@mui/icons-material"
import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { useParams } from "react-router-dom"

const SelfInformation = () => {
    const { setNotification } = useNotificationContext()
    const [submit, setSubmit] = React.useState(false)
    const { employee_id } = useParams()
    const { data: employee } = EmployeeApi.Detail(employee_id)

    const employeeSelfInformation = () => {
        return [
            {
                label: 'Số Điện Thoại',
                value: employee?.employee_phone_number
            }, {
                label: 'Email Cá Nhân',
                value: employee?.employee_email
            }, {
                label: 'Ngày Sinh',
                value: format_date_short(employee?.employee_birthday)
            }, {
                label: 'Giới Tính',
                value: employee?.employee_sex
            }
        ]
    }
    const requestUpdateEmployeeInformation = async () => {
        setSubmit(true)
        await sleep(500)
        setSubmit(false)
        return setNotification({
            open: true, message: "Tính năng đang được cập nhật.", severity: 'success'
        })
    }

    return (

        <Box sx={{
            border: '1px solid rgba(0, 0, 0, 0.18)',
            borderRadius: "4px",
            p: 3
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 0.5,
                mb: { xs: 1, md: 2 }
            }}>
                <Typography sx={{
                    fontWeight: 'medium',
                    textTransform: "uppercase"
                }}>Thông tin nhân viên</Typography>
                <Typography component="div" sx={{
                    fontSize: 12,
                    color: "#777680",
                    m: 'auto 0 0 0'
                }}>Cập nhật lần cuối [{format_date_time(employee?.employee_updated_at)}]</Typography>
            </Box>
            <Grid container spacing={2}>
                {Array.from(employeeSelfInformation())?.map((item, index) => {
                    return (
                        <Grid item xs={12} md={6} key={index}>
                            <Typography component="div"
                                sx={{
                                    mb: 0.5,
                                    fontSize: 14,
                                    color: '#777680'
                                }}>{item?.label}
                            </Typography>
                            <TextField size='small'
                                value={item?.value || "Chưa có thông tin"}
                                disabled
                                fullWidth />
                        </Grid>
                    )
                })}
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                <Button size="small" variant="outlined"
                    sx={{ height: { xs: 36, md: 42 } }}
                    startIcon={submit ? <CircularProgress size={14} sx={{ color: "#777680" }} /> : <UpdateOutlined />}
                    disabled={submit}
                    onClick={requestUpdateEmployeeInformation}
                >
                    Yêu cầu cập nhật
                </Button>
            </Box>
        </Box>
    )
}

export default withSuspense(withPermission(SelfInformation, { feature: "employee", action: "information", type: "component" }), MuiSkeletonDefault)