import { create, enforce, skipWhen, test } from "vest";

const addonValidator = create((data = {}) => {

    test(`product_name`, 'Nhập tên sản phẩm/ dịch vụ.', () => {
        enforce(data.product_name).isNotEmpty()
    })
    skipWhen(
        (res) => {
            return res.hasErrors("product_name") || (data.type === "update" && data.product_name === data.product_name_old)
        },
        () => test.memo(
            "product_name",
            "Tên dịch vụ/ sản phẩm đã tồn tại.",
            () => {
                const idx = data.prevAddons?.findIndex((item) => item?.product_name?.toLowerCase() === data.product_name.toLowerCase())
                enforce(idx === -1).isTruthy()
            },
            [data.prevAddons, data.product_name]
        )
    )

    test(`product_billing`, 'Vui lòng chọn chu kỳ thanh toán.', () => {
        enforce(data.product_billing).isNotEmpty()
    })

    test(`product_billing`, 'Vui lòng chọn chu kỳ thanh toán.', () => {
        enforce(data.product_billing !== -1).isTruthy()
    })

    test(`product_pricing_configures.product_pricing_unit`, 'Vui lòng chọn đơn vị tính.', () => {
        enforce(data.product_billing !== -1).isTruthy()
    })
    test(`product_pricing_configures.default.pricing`, 'Vui lòng nhập đơn giá.', () => {
        enforce(data.product_pricing_configures.default.pricing).isNotEmpty()
    })

    test(`product_pricing_configures.default.pricing`, 'Đơn giá không hợp lệ.', () => {
        const isValid = parseFloat(data.product_pricing_configures.default.pricing) > 0
        enforce(isValid).isTruthy()
    })
})

export default addonValidator;