import { makeFirstLetterUppercase } from "@/functions"
import { DICTIONARIES } from "@/libs/constants"
import _ from "lodash"

const EmployeePermissionColumns = [
    {
        field: 'permission_action_name',
        headerName: 'Thao Tác',
        width: 500,
        valueGetter: ({ row }) => {
            return row?.permission_action_name?.split('-')?.map(x => makeFirstLetterUppercase(DICTIONARIES[x] || x))?.reverse()?.join(' ')
        }
    }
]

const buildEmployeePermissions = ({ roles = [] }) => {
    const uniques = _.groupBy(roles, (role) => {
        return role?.permission_feature_name
    })
    let rows = []
    _.each(uniques, (childrens, __) => {
        const [permission, ...permissions] = childrens
        rows.push({
            ...permission,
            id: permission?.permission_id,
            hierarchy: [permission?.permission_feature_name]
        });

        _.orderBy(permissions, ['is_outlet', 'perfix_outlet'])?.forEach((permiss) => {
            rows?.push({
                hierarchy: [permission?.permission_feature_name, permiss?.permission_action_name + ` ` + permiss?.permission_id?.toString()],
                id: permiss?.permission_id,
                ...permiss,
            })
        })
    })
    return rows
    // const rows = useMemo(() => {
    //     const uniques = _.groupBy(data?.contracts, (row) => {
    //         return [row?.contract_number_id?.split('/VNW')[0] + '/VNW'];
    //     });
    //     let rows = [];
    //     _.each(uniques, (values, keys) => {
    //         const childrens = values;
    //         const contract = values?.at(-1);
    //         rows.push({
    //             hierarchy: [contract?.contract_number_id?.split('/VNW')[0] + '/VNW'],
    //             id: contract?.contract_id,
    //             ...contract
    //         });
    //         if (childrens?.length > 1) {
    //             childrens?.filter(x => x?.contract_id !== contract?.contract_id)?.forEach((children) => {
    //                 rows.push({
    //                     hierarchy: [contract?.contract_number_id?.split('/VNW')[0] + '/VNW', children?.contract_number_id?.split('/VNW')[1]],
    //                     ...children,
    //                     id: children?.contract_id,
    //                     className: "row-disabled",
    //                 });
    //             });
    //         }
    //     });
    //     return rows
    // }, [data?.contracts]);
}

const EmployeeLibs = {
    columns: {
        permission: EmployeePermissionColumns
    },
    rows: {
        permission: buildEmployeePermissions
    }
}

export default EmployeeLibs