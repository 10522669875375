import ReportApi, { rowItemsResolver } from "@/apis/beta/Report/ReportApi";
import DatePicker from "@/components/DatePicker";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import { format_pricing_vnd } from "@/libs/constants";
import DataGridPreview, { DataGridPreviewRowWithProductExpand, getTreeDataPath } from "@components/DataGridPreview";
import { GridTreeDataGroupingCell } from "@components/DataGridPreview/DataGridPro";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { FilterListOutlined as FilterListOutlinedIcon } from '@mui/icons-material';
import { Box, Button, MenuItem, Paper, Popper, Select, Stack, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import clsx from "clsx";
import _ from "lodash";
import React, { useState } from "react";
export const rowInvoiceTotalResolver = (rows, row, rowNode, key) => {
    return [
        _.map(rowItemsResolver(rows, 'quarter', rowNode), (item) =>
            _.sum(_.map(item[key], (item) => item.invoice_total))
        ),
        _.map(rowItemsResolver(rows, 'month', rowNode), (item) =>
            _.sum(_.map(item[key], (item) => item.invoice_total))
        ),
        _.map(row[key], (item) => item.invoice_total),
    ];
};
const initialDataGridStyles = {
    p: 2,
    '& .newregisterAmount': {
        backgroundColor: '#CDE5BE',
    },
    '& .newregisterAmount.positive': {
        backgroundColor: '#CDE5BE',
    },
    '& .renewableAmount': {
        backgroundColor: '#78AD52',
    },
    '& .cancelledAmount': {
        backgroundColor: '#f7e4b2',
    },
    '& .cancelledAmount.low': {
        backgroundColor: '#f7e4b2',
    },
    '& .cancelledAmount.medium': {
        backgroundColor: '#e0c170',
    },
    '& .cancelledAmount.high': {
        backgroundColor: '#f7b9b2',
    },
    '& .cancelledAmount.risk': {
        backgroundColor: '#b83725',
        color: '#FFFFFF',
    },
    '& .totalAmount': {
        backgroundColor: '#CDE5BE',
    },
    '& .totalAmount.low': {
        backgroundColor: '#CDE5BE',
    },
    '& .totalAmount.medium': {
        backgroundColor: '#78AD52',
    },
    '& .totalAmount.high': {
        backgroundColor: '#34810e',
        color: '#FFFFFF',
    },
    '& .totalAmount.risk': {
        backgroundColor: '#b83725',
        color: '#FFFFFF',
    },
    '& .pending': {
        backgroundColor: '#ce0b0b',
        color: '#FFFFFF',
    }
};
const now = new Date();
const defaultStartDate = new Date(now.getFullYear(), now.getMonth(), 1);
const defaultEndDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
const Income = () => {
    const { data, isFetching } = ReportApi.MonthlyRenewable(
        new Date().getMonth()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const rows = data?.rows || [];
    const [filter, setFilter] = React.useState('all');
    const [startDate, setStartDate] = React.useState(defaultStartDate);
    const [endDate, setEndDate] = React.useState(defaultEndDate);
    const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
    const [error, setError] = useState('');
    const [filteredRows, setFilteredRows] = useState(rows);
    const filterOptions = [
        { value: 'all', label: 'All' },
        { value: 'register', label: 'Register' },
        { value: 'renew', label: 'Renewable' },
        { value: 'date', label: 'Date Range' },
        { value: 'service', label: 'Service' },
    ];
    const [service, setService] = useState('');
    const [services, setServices] = useState([]);

    React.useEffect(() => {
        const serviceSet = new Set();
        rows.forEach(row => {
            serviceSet.add(row.name);
            row.newregister.forEach(item => serviceSet.add(item.name));
            row.renewable.forEach(item => serviceSet.add(item.name));
            row.cancelled.forEach(item => serviceSet.add(item.name));
        });
        setServices(Array.from(serviceSet));
    }, [rows]);

    const applyFilter = React.useCallback(() => {
        let result = rows;

        if (filter !== 'all') {
            result = result.filter(row => {
                if (filter === 'register') {
                    return row.newregister.length > 0;
                }
                if (filter === 'renew') {
                    return row.renewable.length > 0;
                }
                return true;
            });
        }

        if (filter === 'service' && service) {
            result = result.filter(row => {
                return row.newregister.concat(row.renewable).concat(row.cancelled).some(item => item.service_product_name === service);
            });
        }

        if (filter === 'date' && startDate && endDate) {
            result = result.filter(row => {
                return row.newregister.concat(row.renewable).concat(row.cancelled).some(item => {
                    const invoiceDate = new Date(item.invoice_created_at);
                    return invoiceDate >= new Date(startDate) && invoiceDate <= new Date(endDate);
                });
            });
        }

        setFilteredRows(result);
    }, [filter, service, startDate, endDate, rows]);

    const [columns] = React.useState([
        {
            field: 'newregister',
            headerName: 'Bán Mới',
            sortable: false,
            filterable: false,
            resizable: false,
            width: 100,
            valueGetter: ({ row, rowNode }) => {
                const items = [
                    _.map(
                        rowItemsResolver(rows, 'quarter', rowNode),
                        (item) => item.newregister.length
                    ),
                    _.map(
                        rowItemsResolver(rows, 'month', rowNode),
                        (item) => item.newregister.length
                    ),
                    [row.newregister?.length],
                ];

                return _.sum(items[rowNode.depth]);
            },
        },
        {
            field: 'renewable',
            headerName: 'Gia Hạn',
            sortable: false,
            filterable: false,
            resizable: false,
            width: 100,
            valueGetter: ({ row, rowNode }) => {
                const items = [
                    _.map(
                        rowItemsResolver(rows, 'quarter', rowNode),
                        (item) => item.renewable.length
                    ),
                    _.map(
                        rowItemsResolver(rows, 'month', rowNode),
                        (item) => item.renewable.length
                    ),
                    [row.renewable?.length],
                ];

                return _.sum(items[rowNode.depth]);
            },
        },
        {
            field: 'pending',
            headerName: 'unpaid',
            sortable: false,
            filterable: false,
            resizable: false,
            width: 100,
            valueGetter: ({ row, rowNode }) => {
                const items = [
                    _.map(
                        rowItemsResolver(rows, 'quarter', rowNode),
                        (item) => item.pending.length
                    ),
                    _.map(
                        rowItemsResolver(rows, 'month', rowNode),
                        (item) => item.pending.length
                    ),
                    [row.pending?.length],
                ];

                return _.sum(items[rowNode.depth]);
            },
        },
        {
            field: 'cancelled',
            headerName: 'Huỷ',
            sortable: false,
            filterable: false,
            resizable: false,
            width: 100,
            valueGetter: ({ row, rowNode }) => {
                const items = [
                    _.map(
                        rowItemsResolver(rows, 'quarter', rowNode),
                        (item) => item.cancelled.length
                    ),
                    _.map(
                        rowItemsResolver(rows, 'month', rowNode),
                        (item) => item.cancelled.length
                    ),
                    [row.cancelled?.length],
                ];

                return _.sum(items[rowNode.depth]);
            },
        },
        {
            field: 'newregister_amount',
            headerName: 'Số Tiền Bán Mới',
            sortable: false,
            filterable: false,
            resizable: false,
            width: 175,
            cellClassName: (params) => {
                let { value } = params;
                value = Number(value?.replace(/(₫|,)/g, ''));

                if (value === 0) {
                    return '';
                }

                return clsx('newregisterAmount', {
                    positive: value > 0,
                });
            },
            valueGetter: ({ row, rowNode, ...params }) => {
                const items = rowInvoiceTotalResolver(
                    rows,
                    row,
                    rowNode,
                    'newregister'
                );

                return format_pricing_vnd(_.sum(items[rowNode.depth]));
            },
            renderCell: (params) => (
                <DataGridPreviewRowWithProductExpand
                    collections={rows}
                    collectionKey='newregister'
                    {...params}
                />
            ),
        },
        {
            field: 'renewable_amount',
            headerName: 'Số Tiền Gia Hạn',
            sortable: false,
            filterable: false,
            resizable: false,
            width: 175,
            cellClassName: (params) => {
                let { value } = params;
                value = Number(value?.replace(/(₫|,)/g, ''));

                if (value === 0) {
                    return '';
                }

                return clsx('renewableAmount', {
                    positive: value > 0,
                });
            },
            valueGetter: ({ row, rowNode }) => {
                const items = rowInvoiceTotalResolver(rows, row, rowNode, 'renewable');

                return format_pricing_vnd(_.sum(items[rowNode.depth]));
            },
            renderCell: (params) => (
                <DataGridPreviewRowWithProductExpand
                    collections={rows}
                    collectionKey='renewable'
                    {...params}
                />
            ),
        },
        {
            field: 'pending_amount',
            headerName: 'Số Tiền Công Nợ',
            sortable: false,
            filterable: false,
            resizable: false,
            width: 175,
            cellClassName: (params) => {
                let { value } = params;
                value = Number(value?.replace(/(₫|,)/g, ''));

                if (value === 0) {
                    return '';
                }

                return clsx('pending', {
                    positive: value > 0,
                });
            },
            valueGetter: ({ row, rowNode }) => {
                const items = rowInvoiceTotalResolver(rows, row, rowNode, 'pending');

                return format_pricing_vnd(_.sum(items[rowNode.depth]));
            },
            renderCell: (params) => (
                <DataGridPreviewRowWithProductExpand
                    collections={rows}
                    collectionKey='pending'
                    {...params}
                />
            ),
        },
        {
            field: 'cancelled_amount',
            headerName: 'Số Tiền Huỷ',
            sortable: false,
            filterable: false,
            resizable: false,
            width: 175,
            cellClassName: (params) => {
                let { value } = params;
                value = Number(value?.replace(/(₫|,)/g, ''));

                if (value === 0) {
                    return '';
                }

                return clsx('cancelledAmount', {
                    low: value > 0,
                    medium: value > 100000000,
                    high: value > 1000000000,
                    risk: value > 2000000000,
                });
            },
            valueGetter: ({ row, rowNode }) => {
                const items = rowInvoiceTotalResolver(rows, row, rowNode, 'cancelled');

                return format_pricing_vnd(_.sum(items[rowNode.depth]));
            },
            renderCell: (params) => (
                <DataGridPreviewRowWithProductExpand
                    collections={rows}
                    collectionKey='cancelled'
                    {...params}
                />
            ),
        },
        {
            field: 'total_amount',
            headerName: 'Tổng (Mới + Gia Hạn)',
            sortable: false,
            filterable: false,
            resizable: false,
            width: 175,
            cellClassName: (params) => {
                let { value } = params;
                value = Number(value?.replace(/(₫|,)/g, ''));

                if (value === 0) {
                    return '';
                }

                return clsx('totalAmount', {
                    risk: value < 1000000000,
                    low: value > 1000000000,
                    medium: value > 5000000000,
                    high: value > 10000000000,
                });
            },
            valueGetter: ({ row, rowNode }) => {
                const total = _.map(
                    rowItemsResolver(rows, 'quarter', rowNode),
                    (item) =>
                        _.sum(
                            _.map(
                                item.newregister.concat(item.renewable),
                                (item) => item.invoice_total
                            )
                        )
                );

                if (rowNode.depth === 0) {
                    return format_pricing_vnd(_.sum(total));
                }
            },
        },
    ]);
    const groupingColDef = React.useCallback(
        () => ({
            headerName: 'Group',
            width: 275,
            renderCell: (params) => {
                const { rowNode } = params;

                const items = [
                    _.map(
                        rowItemsResolver(rows, 'quarter', rowNode),
                        (item) =>
                            _.flatten([item.cancelled, item.renewable, item.newregister])
                                .length
                    ),
                    _.map(
                        rowItemsResolver(rows, 'month', rowNode),
                        (item) =>
                            _.flatten([item.cancelled, item.renewable, item.newregister])
                                .length
                    ),
                ];

                return (
                    <GridTreeDataGroupingCell
                        total={_.sum(items[rowNode.depth])}
                        {...params}
                    />
                );
            },
        }),
        [rows]
    );

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };
    const handleServiceChange = (event) => {
        setService(event.target.value);
    };


    const handleStartDateChange = (newValue) => {
        if (new Date(newValue) > new Date(endDate)) {
            setError('Ngày bắt đầu không thể lớn hơn ngày kết thúc');
            setStartDate(newValue);
        } else {
            setStartDate(newValue);
            setError('');
        }
    };

    const handleEndDateChange = (newValue) => {
        if (new Date(newValue) < new Date(startDate)) {
            setError('Ngày kết thúc không thể nhỏ hơn ngày bắt đầu');
            setEndDate(newValue);
        } else {
            setEndDate(newValue);
            setError('');
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box>
                <Button
                    startIcon={<FilterListOutlinedIcon />}
                    variant="outlined"
                    size="small"
                    onClick={(event) => setFilterAnchorEl(event.currentTarget)}
                    sx={{
                        "&.MuiButton-root": {
                            height: 36,
                            m: "auto 0",
                        },
                    }}             
                >
                    Bộ Lọc
                </Button>
                <Popper
                    open={Boolean(filterAnchorEl)}
                    anchorEl={filterAnchorEl}
                    placement="bottom-start"
                >
                    {() => (
                        <Paper sx={{
                            width: {
                              xs: "400px",
                              sm: "350px",
                            },
                            maxWidth: "100%",
                          }}>
                            <Box sx={{ p: 2 }}>
                                <Typography sx={{ fontSize: { xs: 14, md: 18 }, fontWeight: "medium", color: "rgb(26, 35, 126)" }}>
                                    LỌC THÔNG TIN
                                </Typography>

                                <Select
                                    value={filter}
                                    onChange={handleFilterChange}
                                    fullWidth
                                    size="small"
                                    sx={{ mt: 2 }}
                                    displayEmpty
                                >
                                    {filterOptions.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {filter === 'service' && (
                                    <Select
                                        value={service}
                                        onChange={handleServiceChange}
                                        fullWidth
                                        size="small"
                                        sx={{ mt: 2 }}
                                        displayEmpty
                                    >
                                        {services.map((service, index) => (
                                            <MenuItem key={index} value={service}>
                                                {service}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                                {filter === 'date' && (
                                    <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                                        <DatePicker
                                            label="Thời gian bắt đầu"
                                            value={startDate}
                                            onChange={handleStartDateChange}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                        <DatePicker
                                            label="Thời gian kết thúc"
                                            value={endDate}
                                            onChange={handleEndDateChange}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </Stack>

                                )}
                                {error && <div style={{ color: 'red' }}>{error}</div>}
                                <Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ mt: 2 }}>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => setFilterAnchorEl(null)}
                                        sx={{ mt: 2 }}
                                    >
                                        Đóng
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                            applyFilter();
                                            setFilterAnchorEl(null);
                                        }}
                                        sx={{ mt: 2 }}
                                    >
                                        Lọc
                                    </Button>
                                </Stack>
                            </Box>
                        </Paper>
                    )}
                </Popper>
            </Box>
            <DataGridPreview
                treeData
                autoHeight
                groupingColDef={groupingColDef}
                getTreeDataPath={getTreeDataPath}
                scope='datacentral::reports.index'
                rows={filteredRows}
                isFetching={isFetching}
                options={{
                    name: 'Thống kê Hoá Đơn & Doanh Thu',
                    path: '/beta/reports/incomes',
                    columns: columns,
                }}
                sx={initialDataGridStyles}
            />
        </LocalizationProvider>
    );
};

export default withSuspense(withPermission(Income, {
    feature: "report",
    action: "report-income"
}), MuiSkeleton["DataGrid"])
