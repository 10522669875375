import NumericFormatCustom from '@components/NumericFormatCustom'
import { Box, FormControl, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

const Bandwidth = ({ position }) => {
    const { control } = useFormContext()

    const { fields } = useFieldArray({
        control: control,
        name: `product_configoptions.[${position}].product_addons`
    })
    return (
        <Box>
            <Typography sx={{
                fontSize: { xs: 16, md: 20 },
                fontWeight: 'medium',
            }}>
                Bandwidth
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 0.5, color: "#777680" }}>
                Mở rộng bandwidth (x1 Gbps).
            </Typography>
            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: "row",
                gap: 2,
                flexWrap: 'wrap'
            }}>
                {fields?.map((addon, index) => {
                    return (
                        <React.Fragment key={addon?.id}>
                            <Controller
                                name={`product_configoptions.[${position}].product_addons.[${index}].product_addon_qty`}
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <FormControl
                                            sx={{
                                                maxWidth: {
                                                    xs: '100%',
                                                    md: fields?.length > 1 ? 'calc((100% - 16px) / 2)' : '100%'
                                                }
                                            }}
                                            fullWidth
                                        >
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                label={fields?.length > 1 ? addon?.product_addon_name : ""}
                                                value={value}
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom
                                                }}
                                                placeholder="Nhập số lượng..."
                                                onChange={onChange}
                                                size='small' />
                                        </FormControl>
                                    )
                                }}
                            />
                        </React.Fragment>
                    )
                })}

            </Box>
        </Box>
    )
}

export default Bandwidth