import WorkcontractApi from "@/apis/beta/Employee/WorkcontractApi";
import ErrorHelperText from "@/components/shared/ErrorHelperText";
import { useNotificationContext } from "@/contexts/notification";
import { sleep } from "@/functions";
import withPermission from "@/hocs/withPermission";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const Destroy = ({ data = {}, callback, handleClose }) => {
  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = React.useState(false);
  const [reasonCancel, setReasonCancel] = React.useState({
    error: false,
    text: "",
  });

  const onConfirm = async () => {
    if (reasonCancel?.text?.trim() === "") {
      return setReasonCancel({ error: true });
    }
    setSubmit(true);
    const response = await WorkcontractApi.Cancel({
      id: data?.work_contract_id,
      reason: reasonCancel?.text,
    });
    await sleep(500);
    setSubmit(false);
    if (!response || response?.status !== 200 || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message || "Huỷ hợp đồng thất bại.",
        severity: "error",
      });
    }
    setNotification({
      open: true,
      message: "Huỷ hợp đồng thành công.",
      severity: "success",
    });
    try {
      callback();
    } catch (error) {
      console.log("Callback contract failed.");
    }
    return handleClose();
  };
  return (
    <Dialog fullWidth maxWidth="md" open={true}>
      <DialogTitle>
        Huỷ Hợp Đồng Nhân Viên [{data?.work_contract_number}]
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 2 }}></Typography>
        <Typography sx={{ mb: 0.5 }}>Lý Do Huỷ Hợp Đồng *</Typography>
        <TextField
          multiline
          fullWidth
          value={reasonCancel?.text}
          placeholder="Nhập lý do huỷ hợp đồng..."
          onChange={(event) => {
            setReasonCancel((prev) => ({
              ...prev,
              error: false,
              text: event?.target?.value,
            }));
          }}
        />
        {reasonCancel?.error && (
          <ErrorHelperText message={"Lý do huỷ hợp đồng không được để trống"} />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          sx={{ height: { xs: 36, md: 42 }, minWidth: 120 }}
          disabled={submit}
          onClick={handleClose}
          variant="outlined"
        >
          Huỷ Thao Tác
        </Button>
        <Button
          size="small"
          disabled={submit}
          startIcon={
            submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
          }
          sx={{ height: { xs: 36, md: 42 }, minWidth: 120 }}
          variant="contained"
          onClick={onConfirm}
        >
          Xác Nhận
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withPermission(Destroy, {
  feature: "work-contract",
  action: "destroy",
});
