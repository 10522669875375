import { FormControl, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const Improvement = ({ disabled }) => {
  const { control } = useFormContext();

  return (
    <React.Fragment>
      <Typography sx={{ fontSize: 14, fontWeight: "medium", mt: 2 }}>
        Đề Xuất Cải Thiện
      </Typography>
      <Controller
        name="meeting.meeting_success_improvement"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <FormControl fullWidth>
              <TextField
                multiline
                disabled={disabled}
                value={value}
                onChange={onChange}
                placeholder="Nhập điều cần cải thiện..."
              />
            </FormControl>
          );
        }}
      />
    </React.Fragment>
  );
};

export default Improvement;
