import CustomerContactApi from '@/apis/beta/Customer/CustomerContactApi'
import { queryClientRefetchScope } from '@/functions'
import ModalConfirm from '@components/shared/ModalConfirm'
import { LockOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

const ContactLocked = ({ customerId = -1, contactId, contact }) => {

    const [onConfirmed, setConfirmed] = useState(false)
    const queryClient = useQueryClient()

    const { mutateAsync, isLoading } = useMutation(CustomerContactApi.Locked)

    const onSubmit = async () => {
        const response = await mutateAsync({ customerId, contactId })
        if (!response || response?.error || response?.errors) {
            return {
                status: 400,
                message: response?.errors?.[0]?.message || 'Thông tin đã bị khóa.'
            }
        }
        return {
            status: 200,
            message: 'Khóa thông tin thành công.'
        }
    }
    return (
        <>
            {onConfirmed && <ModalConfirm
                handleClose={() => setConfirmed(false)}
                handleSubmit={onSubmit}
                callback={() => { queryClientRefetchScope(queryClient, 'customer') }}
                dialog={{
                    title: 'Xác Nhận Bỏ Thông Tin Liên Hệ Này',
                    content: 'Thông tin liên hệ sẽ bị vô hiệu hóa và không thể khôi phục lại. Bạn có chắc chắn muốn loại bỏ thông tin này?',
                }}
            />}
            <Button
                size='small'
                onClick={() => setConfirmed(true)}
                startIcon={<LockOutlined fontSize='small' />} variant='outlined' disabled={isLoading || onConfirmed || !!contact?.contact_first}>
                Bỏ Thông Tin Này
            </Button>
        </>

    )
}

export default ContactLocked