import { format_date_short, format_number_usage, format_numeral_price } from "@/functions";
import { PRODUCT_UNITS } from "@/libs/constants";
import DataGridCustomize from "@components/DataGrid";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import _ from "lodash";
import { useMemo, useState } from "react";
import GridTreeDataGroupingCell from "./GridTreeDataGroupingCell";
import Action from "../../Action";
import { isDeviceLiquidation } from "../Standard";

const Premium = ({ data, invoice }) => {

  const [columns] = useState([
    {
      field: "invoice_item_description",
      headerName: "Mô Tả",
      width: 350,
      renderCell: ({ value, row }) => <Box sx={{
        display: 'flex',
        width: '100%',
        p: 'auto 16px'
      }}>
        <Typography sx={{ fontSize: 14 }}>
          {value}
          {!isDeviceLiquidation(row?.service_name) && ((row?.invoice_item_startdate && row?.invoice_item_enddate) && <Typography component="span" fontSize={14} color="text.grey" sx={{ mr: 0.5 }}>
            {` (`}{format_date_short(row?.invoice_item_startdate)} {' - '}{format_date_short(row?.invoice_item_enddate)}{`)`}
          </Typography>)}
        </Typography>
      </Box>
    },
    {
      field: "invoice_item_value",
      headerName: "Thông Số Sử Dụng",
      width: 160,
      valueGetter: ({ value }) => format_number_usage(value),
      renderCell: ({ value }) => <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', p: 'auto 16px' }}>
        <Typography sx={{ fontSize: 14, textAlign: 'right' }}>
          {value}
        </Typography>
      </Box>
    }, {
      field: "invoice_item_unit", headerName: "Đơn Vị Tính", width: 120,
      valueGetter: ({ value }) => PRODUCT_UNITS?.find(unit => unit?.value === value)?.label || ''
    }, {
      field: "invoice_item_pricing_apply", headerName: "Đơn Giá Bán", width: 130,
      valueGetter: ({ value }) => format_numeral_price(value),
      renderCell: ({ value }) => <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', p: 'auto 16px' }}>
        <Typography sx={{ fontSize: 14, textAlign: 'right' }}>
          {value}
        </Typography>
      </Box>
    }, {
      field: "invoice_item_subtotal", headerName: "Tạm Tính", width: 130,
      valueGetter: ({ value }) => format_numeral_price(value),
      renderCell: ({ value }) => <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', p: 'auto 16px' }}>
        <Typography sx={{ fontSize: 14, textAlign: 'right' }}>
          {value}
        </Typography>
      </Box>
    }, {
      field: "invoice_item_desposit", headerName: "Giảm/ Deposit", width: 130,
      valueGetter: ({ value }) => format_numeral_price(value),
      renderCell: ({ value }) => {
        if (value === 0) return <></>;
        return (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', p: 'auto 16px' }}>
            <Typography sx={{ fontSize: 14, textAlign: 'right' }}>
              {value}
            </Typography>
          </Box>
        )
      }
    }, {
      field: "invoice_item_tax", headerName: "Thuế", width: 80,
      valueGetter: ({ value }) => Boolean(value),
      renderCell: ({ value }) => <FormControlLabel control={<Checkbox checked={value} disabled size="small" />} />
    }, {
      field: "invoice_item_amount", headerName: "Thành Tiền", width: 130,
      valueGetter: ({ value }) => format_numeral_price(value),
      renderCell: ({ value }) => <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', p: 'auto 16px' }}>
        <Typography sx={{ fontSize: 14, textAlign: 'right' }}>
          {value}
        </Typography>
      </Box>
    },
    {
      field: "actions",
      headerName: "",
      width: 40,
      renderCell: ({ row }) => {
        if (!row?.editable) return <></>;
        return <Action data={row} />;
      },
    },
  ])

  const subtotalInvoiceItems = (invoiceItem, invoiceItems) => {
    const method = invoiceItem?.invoice_item_method

    let metadata = { ...invoiceItem }

    if (method === 'progressive' && invoiceItems.length > 0) {
      metadata.invoice_item_pricing_apply = 'Ranges'
    }
    if (invoiceItems?.length > 0) {
      metadata.invoice_item_value = _.sumBy([invoiceItem, ...invoiceItems], (row) => row?.invoice_item_value)
      metadata.invoice_item_description = 'Tổng Sử Dụng'
      metadata.invoice_item_subtotal = _.sumBy([invoiceItem, ...invoiceItems], (row) => row?.invoice_item_subtotal)
      metadata.invoice_item_deposit = _.sumBy([invoiceItem, ...invoiceItems], (row) => row?.invoice_item_deposit)
      metadata.invoice_item_amount = _.sumBy([invoiceItem, ...invoiceItems], (row) => row?.invoice_item_amount)
    }
    return { ...metadata, editable: false }
  }

  const rows = useMemo(() => {
    let outputs = []
    const uniques = _.groupBy(data, (row) => {
      return [row?.service_id, row?.invoice_item_startdate, row?.invoice_item_enddate, row?.invoice_item_method, row?.invoice_item_unit]
    })
    _.each(uniques, (childrens, label) => {
      const [invoiceItem, ...invoiceItems] = _.sortBy(childrens?.filter(x => (x?.invoice_item_amount !== 0)), (row) => {
        return row?.invoice_item_id
      })
      // 
      const hierarchy = label?.toString()
      if (invoiceItem) {
        outputs.push({
          ...subtotalInvoiceItems(invoiceItem, invoiceItems),
          hierarchy: [hierarchy]
        })
      }
      if (invoiceItems?.length > 0) {
        outputs.push({
          ...invoiceItem,
          hierarchy: [hierarchy, invoiceItem?.invoice_item_id],
          editable: invoice?.invoice_status === 1
        })
        outputs.push(...(invoiceItems?.map((row) => ({
          ...row,
          hierarchy: [hierarchy, row?.invoice_item_id],
          editable: invoice?.invoice_status === 1
        }))))
      }
    })
    console.log(outputs)
    return outputs?.map((row, index) => ({ ...row, id: index }))

  }, [data, invoice])

  return (
    <DataGridCustomize
      treeData
      columns={columns}
      groupingColDef={{
        headerName: 'Dịch Vụ',
        field: 'hierarchy',
        width: 350,
        renderCell: (params) => {
          const { rowNode } = params;
          return (
            <GridTreeDataGroupingCell
              total={rowNode?.children?.length + 1}
              {...params}
              rowNode={{
                ...rowNode,
                groupingKey: !!params?.row?.service_id && `${params?.row?.service_id} - ${params?.row?.service_name || ''}`
              }}
              linked={`/services/${params?.row?.service_id}`}
            />
          );
        },
      }}
      rows={rows}
      getTreeDataPath={(row) => row?.hierarchy}
      components={{
        Toolbar: 'disabled',
        Pagination: "disabled",
      }}
      componentsProps={{
        toolbar: {
          columns: columns,
        },
      }}
      paginationMode="client"
    />
  );
};

export default Premium;
