import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Box, Typography, TextField, FormControl, Grid } from "@mui/material";
import DatePicker from "@components/DatePicker";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { GENDERS, MARRIGAGE_TYPES } from "@/libs/constants";
import ErrorHelperText from "@components/shared/ErrorHelperText";

const EmployeeInformation = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box
      sx={{
        p: 1.5,
        border: "1px solid rgba(0, 0, 0, 0.18)",
        borderRadius: 1,
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: 16, md: 18 },
          fontWeight: "medium",
          mb: 1,
        }}
      >
        Thông Tin Cơ Bản
      </Typography>
      <Grid container spacing={{ xs: 1.5, md: 2 }}>
        <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
          <Controller
            name="employee_name"
            control={control}
            rules={{ required: "Họ và tên là bắt buộc" }}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    disabled
                    value={value}
                    label="Họ và Tên (*)"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={onChange}
                    size="small"
                    placeholder="Họ và tên..."
                    error={!!errors?.employee_name}
                    helperText={errors?.employee_name?.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
          <Controller
            name="employee_phone_number"
            control={control}
            rules={{
              required: "Số điện thoại là bắt buộc",
              pattern: {
                value: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
                message: "Số điện thoại không đúng định dạng",
              },
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    value={value}
                    label="Số điện thoại (*)"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={onChange}
                    size="small"
                    placeholder="Số điện thoại..."
                    error={!!errors?.employee_phone_number}
                    helperText={errors?.employee_phone_number?.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
          <Controller
            name="employee_birthday"
            control={control}
            rules={{ required: "Ngày sinh là bắt buộc" }}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <DatePicker
                    value={value}
                    onChange={onChange}
                    label="Ngày sinh (*)"
                  />
                  {!!errors?.employee_birthday && (
                    <ErrorHelperText
                      message={errors?.employee_birthday?.message}
                    />
                  )}
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
          <Controller
            name="employee_birthplace"
            control={control}
            rules={{ required: "Nơi sinh là bắt buộc" }}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    value={value}
                    label="Nơi sinh (*)"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={onChange}
                    size="small"
                    placeholder="Nơi sinh..."
                    error={!!errors?.employee_birthplace}
                    helperText={errors?.employee_birthplace?.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
          <Controller
            name="employee_sex"
            control={control}
            rules={{ required: "Giới tính là bắt buộc" }}
            render={({ field: { value, onChange } }) => {
              const selected =
                GENDERS.find((gender) => gender.value === value) || null;

              return (
                <FormControl fullWidth>
                  <SingleSelectCustomize
                    value={selected}
                    options={GENDERS}
                    input={{
                      placeholder: "Chọn giới tính...",
                      label: "Giới tính (*)",
                    }}
                    onChange={(event, newValue) => {
                      onChange(newValue?.value || -1);
                    }}
                  />
                  {!!errors?.employee_sex && (
                    <ErrorHelperText message={errors?.employee_sex?.message} />
                  )}
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
          <Controller
            name="employee_marriage"
            control={control}
            rules={{ required: "Tình trạng hôn nhân là bắt buộc" }}
            render={({ field: { value, onChange } }) => {
              const selected =
                MARRIGAGE_TYPES.find((gender) => gender.value === value) ||
                null;

              return (
                <FormControl fullWidth>
                  <SingleSelectCustomize
                    value={selected}
                    options={MARRIGAGE_TYPES}
                    input={{
                      placeholder: "Chọn tình trạng hôn nhân...",
                      label: "Tình trạng hôn nhân (*)",
                    }}
                    onChange={(event, newValue) => {
                      onChange(newValue?.value || -1);
                    }}
                  />
                  {!!errors?.employee_marriage && (
                    <ErrorHelperText
                      message={errors?.employee_marriage?.message}
                    />
                  )}
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="employee_email"
            control={control}
            rules={{
              required: "Email là bắt buộc",
              pattern: {
                value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: "Email không đúng định dạng",
              },
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    value={value}
                    label="Email cá nhân (*)"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={onChange}
                    size="small"
                    placeholder="Email cá nhân..."
                    error={!!errors?.employee_email}
                    helperText={errors?.employee_email?.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmployeeInformation;
