import CustomerApi from '@/apis/beta/Customer/CustomerApi'
import React from 'react'
import { buildQueryFilterCondition } from "@/functions"
import DataGridCustomize from '@components/DataGrid'
import { Box, Typography } from '@mui/material'
import { CellLink, CellStatus } from "@/components/DataGrid/Cell"
import withSuspense from '@/hocs/withSuspense'
import withPermission from '@/hocs/withPermission'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { CUSTOMER_STATUS_TYPES } from '@/libs/constants'
import useDebounce from '@/hooks/useDebounce'

const Customer = ({ companyId = -1 }) => {
    const initialColumns = [
        {
            field: 'customers.customer_id', headerName: 'ID',
            width: 80,
            hideable: true,
            operators: { type: 'number' },
            valueGetter: ({ row }) => {
                return ({
                    label: row?.customer_id,
                    href: ["customers", row?.customer_id]
                })
            },
            renderCell: ({ value }) => <CellLink data={value} />
        }, {
            field: 'personal_informations.name', headerName: 'Tên Khách Hàng/ Người Đại Diện', width: 250,
            valueGetter: ({ row }) => row?.customer_name
        }, {
            field: 'customer_service_informations.customer_source', headerName: 'Nguồn Khách Hàng', width: 150,
            valueGetter: ({ row }) => row?.customer_source,
            renderCell: ({ value }) => <Typography sx={{
                fontSize: 14,
                textTransform: "capitalize"
            }}>
                {value}
            </Typography>
        }, {
            field: 'personal_informations.email', headerName: 'Email', minWidth: 220,
            valueGetter: ({ row }) => row?.customer_email
        }, {
            field: 'personal_informations.position', headerName: 'Vị Trí', width: 150,
            valueGetter: ({ row }) => row?.customer_position
        }, {
            field: 'customer_service_informations.customer_status',
            headerName: 'Trạng Thái',
            width: 180,
            valueGetter: ({ row }) => CUSTOMER_STATUS_TYPES.find(x => x.value === row?.customer_status) || {
                label: "Không xác định"
            },
            renderCell: ({ value }) => <CellStatus
                component="muiChip"
                data={value}
            />
        }, {
            field: 'customer_service_informations.customer_service_interestings',
            headerName: 'Dịch Vụ Quan Tâm',
            minWidth: 300,
            valueGetter: ({ row }) => row?.customer_service_interestings?.join(", ")
        }]

    const [searchQuery, setSearchQuery] = React.useState('')
    const debouncedSearchQuery = useDebounce(searchQuery, 500)

    const filterableCustomer = React.useCallback(() => {
        const queries = [
            {
                name: 'customer_service_informations.cid',
                operator: "$eq",
                value: companyId
            }, {
                name: 'personal_informations.name',
                operator: "$contains",
                disabled: !debouncedSearchQuery || debouncedSearchQuery.trim() === '',
                value: debouncedSearchQuery
            },
        ]
        return buildQueryFilterCondition("$and", 0, queries)
    }, [companyId, debouncedSearchQuery])


    const [columns, setColumns] = React.useState([...initialColumns])
    const [page, setPage] = React.useState({
        page: 0,
        pageSize: 5
    })

    const { data, isFetching } = CustomerApi.List({
        page: page.page,
        pageSize: page?.pageSize,
        query: filterableCustomer() || null
    })
    return (
        <Box sx={{ mt: 2 }}>
            <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography sx={{ fontSize: { xs: 16, md: 20 }, fontWeight: "medium" }}>
                    Khách Hàng/ Người Đại Diện *
                </Typography>
            </Box>
            <DataGridCustomize
                rows={data?.customers?.map((customer) => ({ ...customer, id: customer?.customer_id })) || []}
                columns={columns}
                loading={isFetching}
                componentsProps={{
                    toolbar: {
                        store: "company-customer.list.hiddenColumns",
                        columns: columns?.filter(col => !col?.hideable),
                        setColumns: setColumns,
                        initialColumns: initialColumns,
                        feature: 'company-customer',
                        inputSearch: {
                            placeholder: "Nhập tên khách hàng tìm kiếm...",
                            value: searchQuery,
                            onChange: setSearchQuery
                        }
                    },
                    pagination: {
                        rowsPerPageOptions: [5, 10, 25, 50],
                        page: page?.page || 0,
                        pageSize: page?.pageSize || 20,
                        onPageChange: (newPage) => setPage(prevState => ({ ...prevState, page: newPage })),
                        onPageSizeChange: (newPageSize) => setPage((prevState) => ({ ...prevState, pageSize: newPageSize }))
                    }
                }}
                rowCount={data?.pagination?.total}
            />
        </Box>
    )
}

export default
    withSuspense(withPermission(Customer, {
        feature: "customer",
        action: "show",
        type: "Block"
    }), MuiSkeleton["DataGrid"]) 