import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import RequestWorkDays from "../Dialog/RequestWorkDays";
import { resolve_work_n_day } from "../../shared";

const WorkDayDetail = ({ work_day, isLoading, employee }) => {
  const [showDialog, setShowDialog] = useState(false);

  if (isLoading) {
    return (
      <Paper sx={{ p: 3, flex: 1 }}>
        <CircularProgress size={25} />
      </Paper>
    );
  }

  if (!work_day) {
    return (
      <Paper sx={{ p: 3, flex: 1 }}>
        <Typography>Không tìm thấy thông tin</Typography>
      </Paper>
    );
  }

  const dataInfo = [
    {
      label: "Ngày làm việc",
      value: dayjs(work_day?.work_day_date).format("DD/MM/YYYY"),
    },
    {
      label: "Loại ngày",
      value: work_day?.work_date_type,
    },
    {
      label: "Giờ vào",
      value: work_day?.work_day_in,
    },
    {
      label: "Giờ ra",
      value: work_day?.work_day_out,
    },
    {
      label: "Số giờ công",
      value: work_day?.work_day_time,
    },
    {
      label: "Công tính lương",
      value: resolve_work_n_day(work_day?.work_n_day),
    },
    {
      label: "Thời gian đồng bộ cuối cùng",
      value: dayjs(work_day?.updated_at).format("HH:mm:ss DD-MM-YYYY"),
    },
    {
      label: "Có sai sót",
      value: (
        <Button
          onClick={() => setShowDialog(true)}
          variant="contained"
          size="small"
        >
          Request HR chỉnh sửa ngày công
        </Button>
      ),
    },
    {
      label: "Ghi chú",
      value: work_day?.work_day_note,
    },
  ];

  return (
    <Paper sx={{ flex: 1 }}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={2}>
          {dataInfo?.map((item) => (
            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <Typography sx={{ mb: 0.5, color: "#777680" }}>
                {item?.label}:
              </Typography>
              <Typography sx={{ color: "#00000F" }}>{item?.value}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>

      {showDialog && (
        <RequestWorkDays
          open={showDialog}
          handleClose={() => setShowDialog(false)}
          defaultDateTime={work_day?.work_day_date}
          employee_id={employee?.employee_id}
        />
      )}
    </Paper>
  );
};

export default WorkDayDetail;
