import { create, enforce, test } from "vest";

const serviceInformationValidators = create((data = {}) => {
  let infos = [];
  data?.service_informations?.forEach((info, index) => {
    console.log(info?.name, infos);

    test(
      `service_informations.[${index}].name`,
      "Tên cấu hình không được để trống.",
      () => {
        enforce(info?.name).isNotEmpty();
      }
    );
    test(
      `service_informations.[${index}].name`,
      `Tên cấu hình đã tồn tại ở dòng ${infos?.findIndex((x) => x === info?.name) + 1
      }.`,
      () => {
        const isValid = infos.findIndex((x) => x === info?.name) === -1;
        enforce(isValid)?.isTruthy();
      }
    );
    test(
      `service_informations.[${index}].unit`,
      "Đơn vị tính không được để trống.",
      () => {
        enforce(info?.unit).isNotEmpty();
      }
    );
    infos.push(info?.name);
  });
});

export default serviceInformationValidators;
