import ServicePriceApi from '@/apis/beta/Service/ServicePriceApi'
import { useNotificationContext } from '@contexts/notification'
import { Box, Button, CircularProgress, Collapse, Divider, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Typography } from '@mui/material'
import React, { Fragment, useMemo, useState } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { translates } from '../Package'
import PriceConfigs from './PriceConfigs'
import { queryClientRefetchScope } from '@/functions'
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import _ from 'lodash'

const SwitfCDNPrice = ({ serviceId = -1, configoptions = [] }) => {
    const [visibility, setVisibility] = useState(true)
    const queryClient = useQueryClient()
    const { setNotification } = useNotificationContext()
    const { mutateAsync, isLoading } = useMutation(ServicePriceApi.ChangePackagePrices)

    const methods = useForm({
        defaultValues: useMemo(() => {
            return {
                service_configoptions: configoptions?.map((configoption) => {
                    return ({
                        name: configoption?.name,
                        service_configoption_id: configoption?.id,
                        pricing: {
                            id: configoption?.pricing?.id || -1,
                            method: configoption?.pricing?.method || 'tierd',
                            unit: configoption?.pricing?.unit || 'gb',
                            pricing: configoption?.pricing?.pricing || 0,
                            pricing_apply: configoption?.pricing?.pricing_apply || 0,
                            committed: configoption?.commitment?.value,
                            steps: _.sortBy(configoption?.pricing?.steps, ['start'])?.map(price => {
                                return ({
                                    price_id: price?.service_pricing_step_id,
                                    start: price?.start,
                                    end: price?.end,
                                    pricing: price?.pricing,
                                    pricing_apply: price?.pricing_apply,
                                    pricing_minimum: price?.pricing_minimum
                                })
                            })
                        }
                    })
                })
            }
        }, [configoptions])
    })

    const { fields } = useFieldArray({
        control: methods.control,
        name: 'service_configoptions'
    })

    const rebuildServicePackagePrices = (packagePrices = []) => {
        return packagePrices?.map((configoption) => {
            return ({
                service_configoption_id: configoption?.service_configoption_id || -1,
                name: configoption?.name || 'Unknown',
                package_price: {
                    id: configoption?.pricing?.id || -1,
                    method: configoption?.pricing?.method,
                    unit: configoption?.pricing?.unit || 'gb',
                    steps: configoption?.pricing?.steps || []
                }
            })
        })
    }


    const onSubmit = async (values) => {
        const response = await mutateAsync({
            serviceId,
            data: rebuildServicePackagePrices(values?.service_configoptions || [])
        })
        if (!response || response?.errors) {
            return setNotification({ open: true, message: response?.errors?.[0]?.message, severity: 'error' })
        }
        queryClientRefetchScope(queryClient, 'service')
        return setNotification({
            open: true, message: 'Thay đổi giá đối soát dịch vụ thành công.', severity: 'success'
        })
    }
    return (
        <FormProvider  {...methods}>
            <Box sx={{
                display: 'flex',
                mb: 1.25,
                mt: 2,
                alignItems: 'center',
                gap: 1.25
            }}>
                <Typography sx={{ fontWeight: 'medium', }}>
                    Giá Đối Soát Vượt
                </Typography>
                <IconButton size='small'
                    sx={{ bgcolor: "#7776801F" }}
                    onClick={() => setVisibility(!visibility)}
                >
                    {visibility ? <VisibilityOutlined fontSize='small' color="primary" sx={{ height: 16, width: 16 }} />
                        : <VisibilityOffOutlined fontSize='small' color="error" sx={{ height: 16, width: 16 }} />}
                </IconButton>
            </Box>
            <Collapse in={visibility}>
                <Box component="form" onSubmit={methods.handleSubmit(onSubmit)} sx={{
                    p: 2,
                    border: '1px solid rgba(0,0,0,0.18)',
                    borderRadius: '4px'
                }}>
                    {fields?.map((field, index) => {
                        return (
                            <Fragment key={field?.id}>
                                <Grid container spacing={2} mb={2}>
                                    {index !== 0 && <Grid item xs={12}>
                                        <Divider light sx={{ mr: { xs: 0, md: 6.75 } }} />
                                    </Grid>}
                                    <Grid item xs={12} md={3}>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 'medium'
                                            }}>
                                            {translates(field?.name)}
                                        </Typography>
                                        <Box sx={{ mt: 1.25 }}>
                                            <Typography
                                                color="text.grey"
                                                sx={{ fontSize: 14, mb: 0.5 }}>
                                                Hình Thức Đối Soát *
                                            </Typography>
                                            <Controller
                                                name={`service_configoptions.[${index}].pricing.method`}
                                                control={methods.control}
                                                render={({ field: { value, onChange } }) =>
                                                    <FormControl fullWidth>
                                                        <RadioGroup sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                            value={value}
                                                            onChange={onChange}
                                                        >
                                                            {[
                                                                { value: 'progressive', label: 'Luỹ Tiến' },
                                                                { value: 'tierd', label: 'Theo Thoả Thuận' },
                                                            ]?.map((method) =>
                                                                <FormControlLabel
                                                                    {...method}
                                                                    key={method.value}
                                                                    control={<Radio size='small' />}
                                                                />
                                                            )}
                                                        </RadioGroup>
                                                    </FormControl>
                                                }
                                            />

                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <PriceConfigs index={index} committed={parseFloat(field?.pricing?.committed)} />
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )
                    })}
                    {methods?.formState?.isDirty && <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        pr: { xs: 0, md: 6.5 }
                    }}>
                        <Button size='small' variant='contained' type='submit' disabled={isLoading}
                            startIcon={isLoading && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                        >
                            Lưu Đơn Giá
                        </Button>
                    </Box>}
                </Box>
            </Collapse>
            <Collapse in={!visibility}>
                <MuiSkeleton.Box />
            </Collapse>
        </FormProvider>
    )
}

export default SwitfCDNPrice