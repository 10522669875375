import DepartmentApi from '@/apis/beta/Employee/DepartmentApi'
import EmployeeApi from '@/apis/beta/Employee/EmployeeApi'
import ScopeApi from '@/apis/beta/Scope/ScopeApi'
import withSuspense from '@/hocs/withSuspense'
import useFilters, { Filter } from '@/hooks/useFilters'
import DataGridCustomize from '@components/DataGrid'
import DataGridFilter from '@components/DataGrid/Filter'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { useCallback, useState } from 'react'
import { useLocation } from 'react-router'
import ScopeManagementLibs from '../../shared/functions'
import useDebounce from '@/hooks/useDebounce'
import { buildQueryFilterCondition } from '@/functions'

const rowsPerPageOptions = [10, 25, 50, 100]
const SCOPE_MANAGENENT = 'scopes.all'

const Scopes = () => {
    const location = useLocation();
    const [query, updateQuery] = useFilters(SCOPE_MANAGENENT + '.filters', location);

    const [searchQuery, setSearchQuery] = useState('')
    const debouncedSearchQuery = useDebounce(searchQuery, 500)
    const useDebounceSearchQuery = useCallback(() => {
        const queries = [
            {
                name: "scopes.scope_name",
                operator: "$contains",
                disabled: !debouncedSearchQuery?.trim(),
                value: debouncedSearchQuery,
            },
        ]
        return buildQueryFilterCondition('$or', 0, queries)
    }, [debouncedSearchQuery])

    const [page, setPage] = useState({
        page: 0,
        pageSize: 25,
    })

    const { data: { scopes, pagination } } = ScopeApi.List({
        ...page,
        page: page?.page + 1,
        query: Filter.stringify(query),
        searchQuery: useDebounceSearchQuery()
    })
    const { data: employees } = EmployeeApi.ListEmployees()
    const { data: { departments } } = DepartmentApi.ListDepartments()
    return (
        <>
            <DataGridFilter
                callback={updateQuery}
                filters={{
                    key: SCOPE_MANAGENENT + '.filters',
                    filterables: ScopeManagementLibs?.filterables,
                }}
            />
            <DataGridCustomize
                columns={ScopeManagementLibs?.columns}
                rows={ScopeManagementLibs?.rows({ employees, scopes, departments })}
                componentsProps={{
                    toolbar: {
                        feature: "contact",
                        inputSearch: {
                            placeholder: "Nhập tên quyền tìm kiếm...",
                            value: searchQuery,
                            onChange: setSearchQuery
                        }
                    },
                    pagination: {
                        ...page,
                        rowsPerPageOptions,
                        onPageChange: (newPage) =>
                            setPage((prevState) => ({ ...prevState, page: newPage })),
                        onPageSizeChange: (newPageSize) =>
                            setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
                    }
                }}
                rowCount={pagination?.rowCount}
            />
        </>
    )
}

export default withSuspense(Scopes, MuiSkeleton["DataGrid"])