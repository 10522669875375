import CustomerApi from '@/apis/beta/Customer/CustomerApi'
import SingleSelectCustomize from '@/components/shared/SingleSelectCustomize'
import { buildQueryFilterCondition } from "@/functions"
import withSuspense from "@/hocs/withSuspense"
import useDebounce from "@/hooks/useDebounce"
import MuiSkeleton from '@components/shared/MuiSkeleton'
import React, { useMemo } from 'react'

const SelectCustomer = ({
    value, onChange, disabled, setValue, error
}) => {
    console.log(value)

    const [search, setSearchQuery] = React.useState("")

    const debouncedSearchQuery = useDebounce(search, 300)

    const filterableCustomer = React.useCallback(() => {
        if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === '') {

            return buildQueryFilterCondition("$or", 0, [
                {
                    name: 'customers.customer_id',
                    operator: "$eq",
                    disabled: parseInt(value) <= 0,
                    value: value
                }
            ])
        }
        const fieldQueries = [
            {
                name: 'customers.customer_id', operator: "$eq",
                disabled: !parseInt(debouncedSearchQuery), value: parseInt(debouncedSearchQuery)
            },
            { name: 'customer_name', operator: "$contains", value: debouncedSearchQuery },
            { name: 'customer_email', operator: "$contains", value: debouncedSearchQuery },
            { name: 'company_name', operator: "$contains", value: debouncedSearchQuery },
        ]
        return buildQueryFilterCondition("$or", 0, fieldQueries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery])

    const { data, isFetching } = CustomerApi.List({
        query: filterableCustomer(),
        page: 1,
        pageSize: 10
    })

    React.useEffect(() => {
        if (parseInt(value) === -1) return;
        const customer = data?.customers?.find(c => c?.customer_id === parseInt(value))
        if (!customer) return;
        try {
            setValue("metadata.customer.customer_type", customer?.customer_id)
            setValue("metadata.customer.customer_type", customer?.customer_type)
            setValue("metadata.customer.customer_name", customer?.customer_name)
            setValue("metadata.customer.customer_position", customer?.customer_position)
            setValue("metadata.customer.customer_phone", customer?.customer_phone)
            setValue("metadata.customer.customer_email", customer?.customer_email)
            setValue("metadata.customer.customer_company_name", customer?.customer_company?.company_name)
            setValue("metadata.customer.customer_company_address", customer?.customer_company?.company_address)
            setValue("metadata.customer.customer_company_website", customer?.customer_company?.company_website)
            setValue("business_place", customer?.customer_company?.company_address)
        } catch (error) {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    const customerCollections = useMemo(() => {
        return data?.customers?.map((customer) => {
            let label = `(${customer?.customer_id})`
            if (!!customer?.customer_company?.company_name) {
                label += ` ${customer?.customer_company?.company_name} (${customer?.customer_name})`
            } else {
                label += ` ${customer?.customer_name}`
            }
            return ({
                ...customer,
                value: customer?.customer_id,
                label
            })
        }) || []
    }, [data])
    const selected = useMemo(() => {
        return customerCollections?.find(c => c?.customer_id === parseInt(value)) || null
    }, [value, customerCollections])

    return (
        <SingleSelectCustomize
            disabled={disabled}
            freeSolo
            value={selected}
            options={customerCollections}
            loading={isFetching}
            onKeyDown={(event) => {
                if (event.key === "Enter") {
                    event.preventDefault()
                }
            }}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    console.log('changing...')
                    setValue("metadata.customer.customer_name", search);
                    return onChange(-1)
                } else if (newValue && newValue.inputValue) {
                    setValue("metadata.customer.customer_name", search);
                    return onChange(-1)
                } else {
                    const customer = newValue
                    if (customer?.value === "Thêm mới") {
                        setValue("metadata.customer.customer_id", -1)
                        setValue("metadata.customer.customer_name", customer?.label)
                        return;
                    }
                    console.log(`newValue`, newValue)
                    setValue("metadata.customer.customer_name", customer?.customer_name)
                    setValue("metadata.customer.customer_position", customer?.customer_position)
                    setValue("metadata.customer.customer_phone", customer?.customer_phone)
                    setValue("metadata.customer.customer_email", customer?.customer_email)
                    setValue("metadata.customer.customer_company_name", customer?.customer_company?.company_name)
                    setValue("metadata.customer.customer_company_address", customer?.customer_company?.company_address)
                    setValue("metadata.customer.customer_company_website", customer?.customer_company?.company_website)
                    setValue("business_place", customer?.customer_company?.company_address)
                    return onChange(parseInt(newValue?.value) || -1)
                }
            }}
            input={{
                placeholder: "Nhập tên, mã, email khách hàng hoặc tên công ty tìm kiếm...",
                value: search,
                onChange: (event) => setSearchQuery(event?.target?.value)
            }}
            filterOptions={(options, params) => {
                const stringLower = params?.inputValue?.toLowerCase()
                if (stringLower && stringLower?.trim() === "") return options;
                // collect options
                let newOptions = []
                for (let option of options) {
                    let flag = false
                    for (let attribute of ["value", "label"]) {
                        const data = option[attribute]?.toString()?.toLowerCase()
                        if (data?.match(stringLower)) {
                            flag = true
                        }
                    }
                    if (flag) { newOptions.push(option) }
                }
                if (stringLower !== '') {
                    newOptions.push({
                        value: "Thêm mới",
                        label: params?.inputValue,
                    });
                }
                return newOptions
            }}
        />
    )
}
export default withSuspense(SelectCustomer, MuiSkeleton["TextField"])