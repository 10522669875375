import ServiceTimeApi from "@/apis/beta/Service/ServiceTimeApi";
import { format_date_short, queryClientRefetchScope } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import DatePicker from "@components/datepickers/DateRangPicker";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useNotificationContext } from "@contexts/notification";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl
} from "@mui/material";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

const ServiceBillingCurrent = ({ service, onClose }) => {
    const queryClient = useQueryClient();
    const { setNotification } = useNotificationContext();

    const { mutateAsync, isLoading } = useMutation(ServiceTimeApi.NextBill)

    const { handleSubmit, watch, setValue } = useForm({
        defaultValues: {
            service_nextbill_startdate: service?.service_nextbill_startdate,
            service_nextbill_enddate: service?.service_nextbill_enddate,
        },
    });

    const onSubmit = async (values) => {
        const response = await mutateAsync({
            serviceId: service?.service_id,
            data: {
                service_nextbill_startdate: format_date_short(values?.service_nextbill_startdate?.$d || values?.service_nextbill_startdate),
                service_nextbill_enddate: format_date_short(values?.service_nextbill_enddate?.$d || values?.service_nextbill_enddate)
            }
        });

        if (!response || response?.errors)
            return setNotification({
                open: true,
                message: response?.errors?.[0]?.message,
                severity: "error",
            });

        setNotification({
            open: true,
            message: `Cập nhật thời gian gia hạn dịch vụ thành công.`,
            severity: "success",
        });
        queryClientRefetchScope(queryClient, 'service')
        return onClose();
    };
    const onChangeDates = (values = []) => {
        setValue(`service_nextbill_startdate`, values[0]?.$d || values[0])
        setValue(`service_nextbill_enddate`, values[1]?.$d || values[1])
    }
    return (
        <Dialog open={true} maxWidth="md" fullWidth>
            <DialogTitle>Thay Đổi Thời Gian Gia Hạn Dịch Vụ</DialogTitle>
            <DialogContent>
                <FormControl fullWidth sx={{ mt: 1.25 }}>
                    <DatePicker
                        value={[
                            dayjs(watch(`service_nextbill_startdate`)),
                            dayjs(watch(`service_nextbill_enddate`))
                        ]}
                        handler={onChangeDates}
                        localeText={{
                            start: "Từ *",
                            end: "Đến *",
                        }}
                    />
                </FormControl>
                <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end", gap: 2 }}>
                    <Button
                        size="small"
                        variant="outlined"
                        disabled={isLoading}
                        onClick={onClose}
                    >
                        Huỷ Thao Tác
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        disabled={isLoading}
                        startIcon={isLoading && <CircularProgress size={14} />}
                    >
                        Lưu Thay Đổi
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default withSuspense(ServiceBillingCurrent, MuiSkeleton["LinearProgress"]);
