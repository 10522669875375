import { COUNTRIES } from "@/libs/constants"
import ErrorHelperText from "@components/shared/ErrorHelperText"
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { FormControl } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const Country = ({
    reset = "",
    nameControlManager = ""
}) => {
    const { control, formState: { errors }, resetField } = useFormContext()
    return (
        <Controller
            name={`domain.domain_extra_fields.[${nameControlManager}]`}
            control={control}
            render={({ field: { value, onChange } }) => {
                return (
                    <FormControl fullWidth>
                        <SingleSelectCustomize
                            value={value || null}
                            options={COUNTRIES.map((item) => ({
                                value: item.value,
                                label: item.label
                            }))}
                            onChange={(event, newValue) => {
                                try {
                                    resetField(`domain.domain_extra_fields.[${reset}]`)
                                } catch (error) {
                                    console.log(error?.message)
                                }
                                return onChange(newValue || null)
                            }}
                            compareAttributes={["value", "label"]}
                            input={{
                                label: "Quốc gia",
                                placeholder: "Chọn quốc gia"
                            }}
                            sx={!!errors?.domain?.domain_extra_fields?.[`${nameControlManager}`] && {
                                ".MuiFormControl-root": {
                                    "label": {
                                        color: "rgb(211, 47, 47)"
                                    }
                                }
                            }}
                        />
                        {!!errors?.domain?.domain_extra_fields?.[`${nameControlManager}`] &&
                            <ErrorHelperText message={errors?.domain?.domain_extra_fields?.[`${nameControlManager}`]?.message} />}
                    </FormControl>
                )
            }} />
    )
}

export default Country