import { Box, Typography } from "@mui/material";
import React from "react";

const NoPreview = ({ content = "Không có bản xem trước" }) => {
  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography fontSize="16px">{content}</Typography>
    </Box>
  );
};

export default NoPreview;
