import { requestWithToken } from "@/functions";
import { API_V3_URL } from "@/libs/constants";
class CustomerServiceApi {
  // deprecated
  CreatePOC = async ({ customerId = null, data = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/customer_services/poc`,
      "POST",
      {
        order: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error.response;
      });
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CustomerServiceApi();
