import WarehouseResourceApi from "@/apis/beta/Warehouse/WarehouseResourceApi";
import WarehouseResoureServiceApi from "@/apis/beta/Warehouse/WarehouseResoureServiceApi";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import FormResourceSevice from "../FormResourceSevice";
import { Paper } from "@mui/material";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import ResourceServiceItemLog from "../ResourceServiceItemLog";


const UpdateService = () => {
    const params =  useParams();
    const {search} = useLocation();
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(search);
    const [pageSize, setPageSize] = React.useState(20);
    const [pageNumber, setPageNumber] = React.useState(0);

    const {data: {warehouse_resource} , refetch: refetchData } = WarehouseResourceApi.show(params?.id)
    const {data: {warehouse_resource_service} , refetch: refetchService } = WarehouseResoureServiceApi.show(params?.id, params?.service_id)
    const {  refetch: refetchResourceServiceList } = WarehouseResoureServiceApi.index({
        resource_id: params?.id,
        page: 1,
        pageSize: 10
    })
    const {refetch: refetchRSItem, data} = WarehouseResoureServiceApi.getResourceServiceItemLogs({warehouse_resource_service_id: params?.service_id, pageNumber: pageNumber + 1, pageSize: pageSize})

    return (
        <>
            <Paper sx={{mt: 3, p: 3 }}>
            <FormResourceSevice 
                key={warehouse_resource_service?.warehouse_resource_service_id}
                warehouse_resource={warehouse_resource}
                warehouse_resource_service={warehouse_resource_service}
                type={"edit"} 
                refetchData={() => {
                    refetchData();
                    refetchResourceServiceList();
                    refetchService()
                    refetchRSItem();
                    }
                } 
                refetchResourceSevice={refetchService}
                handleClose={()=> 
                        {
                            refetchData();
                            refetchResourceServiceList();
                            refetchService()
                            refetchRSItem();
                            navigate(`/warehouse/resources/${params?.id}/services`)
                        }
                    }
                service_tag={searchParams?.get("service_tag")}
            />
            <ResourceServiceItemLog 
                    service_tag={searchParams?.get("service_tag")}
                    data={data}
                    resource_id={params?.id}
                    resource_service_id={params?.service_id}
                    pageNumber={pageNumber}
                    pageSize={pageSize}
                    setPageNumber={setPageNumber}
                    setPageSize={setPageSize}
                    refetch={
                        () => {
                            refetchData();
                            refetchResourceServiceList();
                            refetchService()
                            refetchRSItem();
                        }
                    }
                />
            </Paper>
        </>
    )
}
export default withSuspense(withPermission(UpdateService, {
    feature: "warehouse",
    action: "resource-service-update"
}), MuiSkeleton["DataGrid"]) 