import WarehouseModelApi from "@/apis/beta/Warehouse/WarehouseModelApi";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

const WAREHOUSE_TYPES = [
  { warehouse_type_ids: [1], labels: ["server", "processor"] },
  { warehouse_type_ids: [2], labels: ["switch"] },
  { warehouse_type_ids: [3, 4, 5, 6], labels: ["disk", "ssd", "hdd"] },
  { warehouse_type_ids: [7], labels: ["cpu"] },
  { warehouse_type_ids: [8], labels: ["memory", "ram"] },
  { warehouse_type_ids: [9], labels: ["power"] },
  { warehouse_type_ids: [10], labels: ["network"] },
  { warehouse_type_ids: [11], labels: ["other"] },
  { warehouse_type_ids: [14], labels: ["module"] },
  { warehouse_type_ids: [17], labels: ["card"] },
  { warehouse_type_ids: [19], labels: ["tray"] },
  { warehouse_type_ids: [20], labels: ["laptop"] },
  { warehouse_type_ids: [18, 21], labels: ["rail"] },
];

const WarehouseModel = ({
  position = -1,
  warehouseType = "",
  value = null,
  onChange,
}) => {
  const { setValue } = useFormContext();
  const warehouseTypePicked = useMemo(() => {
    if (!warehouseType) return null;

    const collection = WAREHOUSE_TYPES.find((x) =>
      x?.labels.includes(warehouseType?.toLowerCase())
    );
    if (!collection) return null;
    return collection;
  }, [warehouseType]);

  const { data: warehouseModels } = WarehouseModelApi.All();

  const handleChangeWarehouseModel = (warehouseModel) => {
    if (!warehouseModel || !warehouseModel?.specifications) return;

    const capacity = warehouseModel?.specifications?.find(
      (spec) => spec?.specification_name === "CAPACITY"
    );
    if (!!capacity) {
      setValue(
        `product_addons.[${position}].warehouse_capacity_value`,
        capacity?.specification_value
      );
      setValue(
        `product_addons.[${position}].warehouse_capacity_unit`,
        capacity?.specification_unit?.toLowerCase()
      );
    }
    setValue(
      `product_addons.[${position}].manufacture_id`,
      warehouseModel?.manufacture_id || -1
    );
  };
  return (
    <SingleSelectCustomize
      value={
        warehouseModels?.find((manufacture) => manufacture?.label === value) ||
        null
      }
      options={[
        ...warehouseModels?.filter((warehouseModel) =>
          warehouseTypePicked?.warehouse_type_ids?.includes(
            warehouseModel?.warehouse_type_id
          )
        ),
      ]}
      onChange={(event, newValue) => {
        onChange(newValue?.label || null);
        return handleChangeWarehouseModel(newValue);
      }}
      input={{
        label: "Model *",
        placeholder: "Chọn model...",
      }}
    />
  );
};

export default withSuspense(WarehouseModel, MuiSkeleton["TextField"]);
