import ReplayOutlined from '@mui/icons-material/ReplayOutlined'
import { Box, Button, Paper, Typography } from '@mui/material'
import React from 'react'

const MeasurementUnit = () => {

    return (
        <Box>
            <Typography sx={{
                fontSize: 24,
                fontWeight: 'medium',
                textTransform: 'uppercase',
                mb: 2
            }}>
                Hỗ trợ chuyển đổi đơn vị tính
            </Typography>
            <Paper sx={{ p: 3 }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2
                }}>
                    <Button size='small'
                        variant='outlined'
                        sx={{ height: 40 }}
                        startIcon={<ReplayOutlined fontSize='small' />}
                    >
                        Reset
                    </Button>
                    <Button size='small'
                        variant='contained'
                        sx={{ height: 40 }}
                    >
                        Convert
                    </Button>
                </Box>
            </Paper>
        </Box>
    )
}

export default MeasurementUnit