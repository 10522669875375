import ContractApi from "@/apis/beta/Contract/ContractApi"
import ServiceApi from '@/apis/beta/Service/ServiceApi'
import { buildQueryFilterCondition, queryClientRefetcher } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import useDebounce from "@/hooks/useDebounce"
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { useNotificationContext } from '@contexts/notification'
import { ArrowBackOutlined } from "@mui/icons-material"
import DatasetLinkedOutlinedIcon from '@mui/icons-material/DatasetLinkedOutlined'
import { Box, Button, CircularProgress } from '@mui/material'
import React from 'react'
import { useQueryClient } from 'react-query'

const ContractReferrer = ({
    serviceId,
    onClose
}) => {

    const [submit, setSubmit] = React.useState(false)
    const queryClient = useQueryClient()

    const { setNotification } = useNotificationContext()

    const contractReferences = React.useCallback(() => {
        const queries = [
            {
                name: 'contract_referrer_type',
                operator: "$ne",
                value: 'Reducer::Override::Service',
            },
        ]
        return buildQueryFilterCondition('$and', 0, queries)
    }, [])
    const [searchQuery, setSearchQuery] = React.useState('')
    const debounceSearchQuery = useDebounce(searchQuery, 400)

    const fitlerableContracts = React.useCallback(() => {
        if (!debounceSearchQuery || debounceSearchQuery?.trim() === '') return '';
        let queries = [
            {
                name: 'contract_number_id',
                operator: "$contains",
                value: debounceSearchQuery,
            },
        ]
        return buildQueryFilterCondition('$or', 0, queries)
    }, [debounceSearchQuery])

    const [state, setState] = React.useState(null)


    const { data, isLoading } = ContractApi.List({
        query: contractReferences(),
        searchQuery: fitlerableContracts(),
        page: 1,
        pageSize: 10
    })

    const onSubmit = async () => {
        setSubmit(true)

        const response = await ServiceApi.MapWithContract({
            serviceId,
            data: {
                contract_id: state
            }
        })
        setSubmit(false)
        if (!response || response?.errors) {
            return setNotification({
                open: true,
                message: response?.errors?.[0]?.message,
                severity: 'error'
            })
        }
        queryClientRefetcher(queryClient, [
            "contract.list",
            "service.service-contract-referrer"
        ])

        setNotification({
            open: true,
            message: 'Tạo liên kết hợp đồng & dịch vụ thành công',
            severity: 'success'
        })
        return onClose()
    }
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5
        }}>
            <SingleSelectCustomize
                isFetching={isLoading}
                value={data?.contracts?.find(contract => contract?.contract_id === state) || null}
                options={[...data?.contracts]}
                formatCollection={{
                    value: 'contract_id',
                    label: 'contract_number_id'
                }}
                compareAttributes={['contract_number_id']}
                input={{
                    placeholder: "Nhập mã hợp đồng tìm kiếm",
                    value: searchQuery,
                    onChange: (event) => setSearchQuery(event?.target?.value)
                }}
                onChange={(event, newValue) => setState(parseInt(newValue?.contract_id) || -1)}
                mode='service'
            />
            <Button
                startIcon={submit ?
                    <CircularProgress size={14} sx={{ color: "#777680" }} />
                    : <DatasetLinkedOutlinedIcon fontSize='small' />}
                variant='contained' size='small' fullWidth
                onClick={onSubmit}
                disabled={submit}
            >
                Tạo Liên Kết Hợp Đồng
            </Button>
            <Button
                startIcon={
                    <ArrowBackOutlined fontSize="small" />}
                variant='outlined' size='small' fullWidth
                onClick={onClose}
                disabled={submit}
            >
                Quay Trở Lại
            </Button>
        </Box>
    )
}

export default withSuspense(ContractReferrer, MuiSkeleton["TextField"])