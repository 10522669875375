import ProductApi from "@/apis/beta/Product/ProductApi";
import { useNotificationContext } from "@contexts/notification";
import { Box, Button, CircularProgress, Collapse, Paper } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import ProductDescription from "../../../shared/ProductDescription";
import ProductGroup from "../../../shared/ProductGroup";
import ProductName from "../../../shared/ProductName";
import ProductSubtitle from "../../../shared/ProductSubtitle";
import ProductType from "../../../shared/ProductType";

const Metadata = ({ product, callback }) => {
  const [submit, setSubmit] = React.useState(false);

  const [defaultValues] = React.useState({
    product_group_id: product?.product_group_id,
    product_name: product?.product_name,
    product_subtitle: product?.product_subtitle,
    product_type: product?.product_type,
    product_description: product?.product_description,
  });

  const { setNotification } = useNotificationContext();
  const methods = useForm({
    defaultValues: {
      ...defaultValues,
    },
  });

  const onReset = () => {
    if (JSON.stringify(defaultValues) === JSON.stringify(methods.getValues())) {
      return setNotification({
        open: true,
        message: "Nothing changed for reset.",
        severity: "warning",
      });
    }

    methods.reset({ ...defaultValues });
    return setNotification({
      open: true,
      message: "Resetted.",
      severity: "success",
    });
  };

  const onSubmit = async (values) => {
    setSubmit(true);
    let form = {
      ...values,
    };
    const response = await ProductApi.Update({
      id: product?.product_id,
      data: form,
    });
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }
    setSubmit(false);
    try {
      callback();
    } catch (error) {
      console.log("callback failed", error?.message);
    }
    return setNotification({
      open: true,
      message: "Cập nhật thông tin sản phẩm thành công",
      severity: "success",
    });
  };
  return (
    <FormProvider {...methods}>
      <Paper
        component="form"
        sx={{ p: 3 }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <ProductGroup />
        <ProductName />
        <ProductSubtitle />
        <ProductType />
        <ProductDescription />
        <Collapse in={methods?.formState?.isDirty}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              flexDirection: { xs: "column", md: "row" },
              mt: 3,
            }}
          >
            <Button
              size="small"
              variant="outlined"
              onClick={onReset}
              disabled={submit}
            >
              Huỷ thao tác
            </Button>
            <Button
              size="small"
              type="submit"
              disabled={submit}
              startIcon={
                submit && (
                  <CircularProgress size={14} sx={{ color: "#777680" }} />
                )
              }
              variant="contained"
            >
              Cập nhật
            </Button>
          </Box>
        </Collapse>
      </Paper>
    </FormProvider>
  );
};

export default Metadata;
