import DayoffApi from '@/apis/beta/Employee/DayoffApi';
import EChart from '@/components/EChart';
import { buildQueryFilterCondition } from '@/functions';
import withSuspense from '@/hocs/withSuspense';
import MuiSkeleton from '@components/shared/MuiSkeleton';
import dayjs from 'dayjs';
import React from 'react';

const NUMBER_EMPLOYER = 43

const Statistical = () => {

    const filterableRecentDayoff = React.useCallback(() => {
        const fieldQueries = [
            {
                name: 'day_off_items.day_off_item_date',
                operator: "$eq",
                value: dayjs().format("DD/MM/YYYY")
            },
        ]
        return buildQueryFilterCondition("$or", 0, fieldQueries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { data: { day_offs: recentDayoff } } = DayoffApi.List({
        query: filterableRecentDayoff()
    })

    const options = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            bottom: 10,
            left: "center"
        },
        series: [
            {
                name: 'Reporteds',
                type: 'pie',
                radius: '50%',
                data: [
                    { value: NUMBER_EMPLOYER - recentDayoff?.length, name: 'Tại Công Ty' },
                    { value: recentDayoff?.length, name: 'Nghỉ Phép' },
                    { value: Math.random(0, 15), name: 'Công Tác' }
                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };

    return (
        <EChart options={options} />
    )
}

export default withSuspense(Statistical, MuiSkeleton["EChartPie"])