import { Box, CircularProgress, Drawer, Grid, Typography } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import WorkDayApi from "@/apis/beta/Employee/WorkDayApi";

const DrawerHolidayDetail = ({ open, onClose, values }) => {
  const { data, isLoading, isError } = useQuery(
    [`get-info-${JSON.stringify(values)}`],
    () => WorkDayApi.GetInfoDayOffOrBusiness(values)
  );

  const dataInfo = [
    {
      label: "Mã Ngày Nghĩ",
      value: data?.data?.[0]?.holiday_id,
    },
    {
      label: "Công Tính Lương",
      value: data?.data?.[0]?.holiday_work_n_day === 2 ? 1 : 0.5,
    },
    {
      label: "Tên Ngày Nghĩ, Mô Tả",
      value: data?.data?.[0]?.holiday_name,
    },
    {
      label: "Ngày",
      value: dayjs(data?.data?.[0]?.holiday_date).format("DD/MM/YYYY"),
    },
  ];

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box p={2} minWidth={800}>
        {(isError || data?.errors) && (
          <Typography fontWeight={400} color="error">
            {data?.errors?.[0]?.message || "Something went wrong"}
          </Typography>
        )}

        {isLoading && (
          <Box>
            <CircularProgress />
          </Box>
        )}

        {data?.data?.[0] && (
          <Box>
            <Grid container spacing={2}>
              {dataInfo?.map((item) => (
                <Grid key={item?.label} item md={6} sm={6} xs={12}>
                  <Typography sx={{ mb: 0.5, color: "#777680" }}>
                    {item?.label}:
                  </Typography>
                  <Typography sx={{ color: "#00000F" }}>
                    {item?.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default DrawerHolidayDetail;
