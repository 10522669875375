import NumericFormatCustom from "@components/NumericFormatCustom";
import { FormControl, TextField } from "@mui/material";
import _ from "lodash";
import { useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

const PricingMinimum = () => {
  const { control, setValue } = useFormContext();

  const addons = useWatch({
    control: control,
    name: `product_addons`,
  });

  useEffect(() => {
    let amount =
      _.sumBy(
        addons,
        (addon) => parseFloat(addon?.qty) * parseFloat(addon?.pricing_minimum)
      ) || 0;

    return setValue(`product_addon_pricing_minimum`, amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addons]);

  return (
    <Controller
      name="product_addon_pricing_minimum"
      control={control}
      render={({ field: { value } }) => {
        return (
          <FormControl fullWidth>
            <TextField
              disabled
              size="small"
              label="[Addon] Giá min *"
              value={value}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
          </FormControl>
        );
      }}
    />
  );
};

export default PricingMinimum;
