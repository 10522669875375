import MuiCurrency from "@/components/shared/CurrencyInputCustomize";
import ErrorHelperText from "@/components/shared/ErrorHelperText";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const reasons = [
  { value: "budget", label: "Khách hàng không đủ budget" },
  { value: "target", label: "Người liên hệ không phải là người quyết định" },
  {
    value: "target2",
    label:
      "Người liên hệ không đủ khả năng tác động đến người quyết định mua hàng",
  },
  { value: "provider", label: "Khách hàng có nhà cung cấp chiến lược khác" },
  { value: "others", label: "Lý do khác" },
];
const BadCustomer = ({ disabled }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <Typography sx={{ fontSize: 14, fontWeight: "medium", mb: 1, mt: 1 }}>
        Lý Do Không Tiềm Năng
      </Typography>
      <Controller
        control={control}
        name="meeting.meeting_failed_reason"
        render={({ field: { value, onChange } }) => (
          <FormControl fullWidth disabled={disabled}>
            <RadioGroup
              row
              value={value}
              onChange={(event) => onChange(event?.target?.value)}
              sx={{
                flexDirection: "column",
              }}
            >
              {reasons?.map((reason, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    {...reason}
                    control={<Radio size="small" />}
                  />
                );
              })}
            </RadioGroup>
            {!!errors?.meeting?.meeting_failed_reason && (
              <ErrorHelperText
                message={errors?.meeting?.meeting_failed_reason?.message}
              />
            )}
          </FormControl>
        )}
      />
      {watch("meeting.meeting_failed_reason") && (
        <Controller
          control={control}
          name="meeting.meeting_failed_explanation"
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl fullWidth>
                {watch("meeting.meeting_failed_reason") === "budget" && (
                  <MuiCurrency
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    placeholder="Nhập budget..."
                  />
                )}
                {watch("meeting.meeting_failed_reason") === "others" && (
                  <TextField
                    multiline
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    placeholder="Nhập lý do khác ảnh hưởng đến kết quả..."
                  />
                )}
                {watch("meeting.meeting_failed_reason") === "provider" && (
                  <TextField
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    size="small"
                    placeholder="Nhập tên nhà cung cấp..."
                    InputProps={{
                      sx: { bgcolor: "#FFF" },
                    }}
                  />
                )}
                {!!errors?.meeting?.meeting_failed_explanation && (
                  <ErrorHelperText
                    message={
                      errors?.meeting?.meeting_failed_explanation?.message
                    }
                  />
                )}
              </FormControl>
            );
          }}
        />
      )}
    </Box>
  );
};

export default BadCustomer;
