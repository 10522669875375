import DatePicker from '@components/DatePicker'
import { FormControl } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ServiceStartdate = () => {
    const { control } = useFormContext()
    return (
        <Controller
            control={control}
            name="service_start_date"
            render={({ field: { value, onChange } }) =>
                <FormControl fullWidth>
                    <DatePicker value={value} onChange={onChange} label="Ngày bắt đầu sử dụng *" />
                </FormControl>
            }
        />
    )
}

export default ServiceStartdate