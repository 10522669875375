import { requestWithToken, encodeBase64 } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class CustomerInvoiceApi {

    List = ({ query, customerId, page, pageSize }) => {
        return useQueryWithCustomized(
            ["customer.invoices", query, customerId, page, pageSize],
            async () => {
                return await requestWithToken(`${API_V3_URL}/customers/${customerId}/customer_invoices?page=${page || 1}&page_size=${pageSize || 5}${query ? `&filters=${encodeBase64(query)}` : ''}`)
                    .then(({ data }) => {
                        if (data.error) return data;
                        return data?.data;
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000
            }
        )
    }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default new CustomerInvoiceApi();