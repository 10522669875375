import WorkcontractApi from "@/apis/beta/Employee/WorkcontractApi";
import { useNotificationContext } from "@/contexts/notification";
import { removeObjectKeys, sleep } from "@/functions";
import { vestResolver } from "@hookform/resolvers/vest";
import { Box, Button, CircularProgress, Paper } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import WContractDocument from "../WContractDocument";
import WorkContractEmployee from "../WContractEmployee";
import Enddate from "../WContractEnddate";
import WorkContractNumber from "../WContractNumber";
import WContractSalaryAllowance from "../WContractSalaryAllowance";
import WContractSalaryBasic from "../WContractSalaryBasic";
import Startdate from "../WContractStartdate";
import WorkcontractStatus from "../WContractStatus";
import WorkContractTerm from "../WContractTerm";
import workContractValidators from "./suite";

const WorkContractForm = ({
  type = "create",
  defaultValues = {},
  file = null,
}) => {
  let navigate = useNavigate();
  const { setNotification } = useNotificationContext();

  const [loading, setLoading] = React.useState(null);
  const handleClearLoading = () => setLoading(null);

  const [attachment, setAttachment] = React.useState(file);
  React.useEffect(() => {
    setAttachment(file);
  }, [file]);
  const methods = useForm({
    resolver: vestResolver((props) => workContractValidators({ ...props })),
    defaultValues: removeObjectKeys(defaultValues, ["work_contract_id"]),
  });

  const onSubmit = async (values) => {
    if (!attachment) {
      return setNotification({
        open: true,
        message: "Vui lòng đính kèm file hợp đồng.",
        severity: "error",
      });
    }
    let form = {
      ...values,
      work_contract_document_file_name: attachment?.name,
      work_contract_document_content_type: attachment?.type,
      work_contract_document_file_size: attachment?.size,
    };
    setLoading("submit");
    const response =
      type === "update"
        ? await WorkcontractApi.Update({
            employeeId: form?.employee_id,
            workcontractId: defaultValues?.work_contract_id,
            data: form,
          })
        : await WorkcontractApi.Create({
            employeeId: form?.employee_id,
            data: form,
          });
    await sleep(500);
    handleClearLoading();
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message || "Failed",
        severity: "error",
      });
    }
    setNotification({
      open: true,
      message: `${
        type === "create" ? "Thêm mới" : "Cập nhật"
      } hợp đồng nhân viên thành công.`,
      severity: "success",
    });
    return navigate(`/employees/${defaultValues?.employee_id}`, {
      replace: true,
    });
  };

  const handleReset = async () => {
    if (!handleCompareValues()) {
      return setNotification({
        open: true,
        message: "Nothing change for reset!",
        severity: "error",
      });
    }
    setLoading("reset");
    methods.reset({ ...defaultValues });
    setAttachment(file);
    await sleep(500);
    handleClearLoading();
    return setNotification({
      open: true,
      message: "Reset.",
      severity: "success",
    });
  };

  const handleCompareValues = () => {
    const prevValues = removeObjectKeys(defaultValues, ["work_contract_id"]);
    if (JSON.stringify(prevValues) === JSON.stringify(methods.getValues())) {
      //nothing to do for reset
      if (file?.name === attachment?.name) {
        return true;
      }
      return false;
    }
    //
    return false;
  };
  return (
    <FormProvider {...methods}>
      <Paper
        component="form"
        sx={{ p: 3 }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <WorkContractNumber />
        <WorkContractEmployee />
        <WorkContractTerm />
        <Startdate />
        <Enddate />
        <WContractSalaryBasic />
        <WContractSalaryAllowance />
        <WorkcontractStatus />
        <WContractDocument
          attachment={attachment}
          setAttachment={setAttachment}
        />
        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
          }}
        >
          <Button
            size="small"
            variant="text"
            disabled={!!loading}
            onClick={handleReset}
            startIcon={
              loading === "reset" && (
                <CircularProgress size={14} sx={{ color: "#777680" }} />
              )
            }
            sx={{ height: { xs: 36, md: 42 } }}
          >
            Huỷ Thao Tác
          </Button>
          <Button
            size="small"
            disabled={!!loading}
            type="submit"
            startIcon={
              loading === "submit" && (
                <CircularProgress size={14} sx={{ color: "#777680" }} />
              )
            }
            variant="outlined"
            sx={{ height: { xs: 36, md: 42 }, minWidth: 150 }}
          >
            {type === "create" ? "Thêm" : "Cập nhật"} hợp đồng
          </Button>
        </Box>
      </Paper>
    </FormProvider>
  );
};

export default WorkContractForm;
