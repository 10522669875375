import HeaderTitle from "@components/Title/HeaderTitle";
import { Box, Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Header = ({ title }) => {
  return (
    <Box>
      <HeaderTitle>{title || "Danh Sách Tài Liệu"}</HeaderTitle>
      <Box mt={2} textAlign="right">
        <Link to="/files/document/new">
          <Button
            variant="contained"
            size="small"
            sx={{
              "&.MuiButton-root": {
                height: 36,
                m: "auto 0",
              },
            }}
          >
            Tạo Mới
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default Header;
