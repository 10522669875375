import ProductApi from "@/apis/beta/Product/ProductApi";
import { buildQueryFilterCondition } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import {
  Box,
  Button,
  Collapse,
  FormControl,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Templates } from "../Templates";

const ProductGroup = ({ productGroup = {} }) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      product_group_name: productGroup?.product_group_name,
      product_group_code: productGroup?.product_group_code,
    },
  });
  const filterableProduct = React.useCallback(() => {
    const fieldQueries = [
      {
        name: "tw_products.product_group_id",
        operator: "$eq",
        value: productGroup?.product_group_id,
      },
    ];
    return buildQueryFilterCondition("$or", 0, fieldQueries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productGroup]);

  const {
    data: { products },
  } = ProductApi.List({
    query: filterableProduct() !== "" ? filterableProduct() : null,
  });
  const [open, setCollapse] = useState(false);

  const Component = Templates[productGroup?.product_group_name];
  const onSubmit = async () => {};
  return (
    <Paper sx={{ p: 3, mb: 2.5 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 1.5,
          gap: 2,
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="product_group_name"
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl fullWidth>
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  label="Tên nhóm sản phẩm"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Nhập tên nhóm sản phẩm..."
                />
              </FormControl>
            );
          }}
        />
        <Controller
          control={control}
          name="product_group_code"
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl fullWidth>
                <TextField
                  size="small"
                  label="Mã nhóm sản phẩm"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Nhập mã nhóm sản phẩm..."
                  value={value}
                  onChange={onChange}
                />
              </FormControl>
            );
          }}
        />
        {isDirty && (
          <Button size="small" type="submit" variant="outlined">
            Cập Nhật
          </Button>
        )}
      </Box>
      <IconButton size="small" onClick={() => setCollapse(!open)}>
        {React.createElement(
          open ? VisibilityOffOutlined : VisibilityOutlined,
          {
            fontSize: "small",
          }
        )}
      </IconButton>
      <Collapse in={open}>
        <>{!!Component && <Component products={products} />}</>
      </Collapse>
    </Paper>
  );
};

export default withSuspense(
  withPermission(ProductGroup, {
    feature: "product",
    action: "index",
  }),
  MuiSkeleton["Paper"]
);
