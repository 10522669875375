import { sleep } from '@/functions'
import { Box, Button, CircularProgress, Paper } from '@mui/material'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import PreviewInformation from '../PreviewInformation'
import ScheduleOptions from '../ScheduleOptions'
import ScheduleTitle from '../ScheduleTitle'
import ScheduleOwner from '../ScheduleOwner'
import ScheduleContent from '../SchechuleContent'
import ScheduleGuest from '../ScheduleGuest'
import ScheduleTimer from '../ScheduleTimer'
import Attachment from '../Attachment'
import FileApi from '@/apis/shared/File/FileApi'
import { useNotificationContext } from '@contexts/notification'
import ScheduleApi from '@/apis/beta/Schedule/ScheduleApi'
import moment from 'moment'

export const PREFIX_SCHEDULE_ATTACHMENT_PATHNAME = "schedules/documentations"

const ScheduleForm = ({
    type = "create",
    defaultAttachment,
    data = {}
}) => {

    const { setNotification } = useNotificationContext()

    const [submit, setSubmit] = React.useState(false)
    const methods = useForm({
        defaultValues: data
    })

    const [attachment, setAttachment] = React.useState({
        file: null,
        loading: false,
        percen: 0
    })

    React.useEffect(() => {
        setAttachment((prev) => ({ ...prev, file: defaultAttachment }))
    }, [defaultAttachment])


    const handlerUploadAttachment = async (file) => {
        const token = await FileApi.GetPresignedTokenAttachment({
            filename: {
                name: file?.name,
                folder: PREFIX_SCHEDULE_ATTACHMENT_PATHNAME
            }
        })
        if (!token || token?.status !== 200) {
            return setNotification({
                open: true,
                message: "Lấy dữ liệu upload tài liệu thất bại",
                severity: "error"
            })
        }
        setAttachment(prevState => ({ ...prevState, loading: true, percen: 0 }))
        const uploaded = await FileApi.UploadAttachmentToStorage({
            token: token?.token,
            file: file,
            handleUploadFile: setAttachment
        })
        setAttachment(prevState => ({ ...prevState, loading: false, percen: 0 }))
        if (!uploaded) {
            return setNotification({
                open: true,
                message: "Tải dữ liệu thất bại",
                severity: "error"
            })
        }
        return {
            attachment_file_name: file?.name,
            attachment_file_url: ["pub", PREFIX_SCHEDULE_ATTACHMENT_PATHNAME, token?.filename].join("/"),
            attachment_file_type: file?.type,
            attachment_file_size: file?.size
        } || {}
    }

    const onSubmit = async (values) => {
        setSubmit(true)
        let form = {
            ...values,
            schedule_started_at: moment(values?.schedule_started_at).format('DD/MM/YYYY HH:mm:00'),
            schedule_finished_at: moment(values?.schedule_finished_at).format('DD/MM/YYYY HH:mm:00'),
        }

        if (!!attachment?.file) {
            const documentMetadata = await handlerUploadAttachment(attachment?.file)
            form.schedule_document = [
                documentMetadata
            ]
        }
        const response = await ScheduleApi.Create({
            data: form
        })
        await sleep(500)
        setSubmit(false)
        if (!response || response?.errors) {
            return setNotification({
                open: true,
                message: response?.errors?.[0]?.message,
                severity: "error"
            })
        }
        return setNotification({
            open: true,
            message: "Created",
            severity: "success"
        })
    }
    return (
        <FormProvider {...methods}>
            <Paper sx={{ p: 3 }} component="form" onSubmit={methods.handleSubmit(onSubmit)}>
                <ScheduleOptions />
                <ScheduleTitle />
                <ScheduleOwner />
                <ScheduleGuest />
                <ScheduleTimer />
                <ScheduleContent />
                <PreviewInformation />
                <Attachment attachment={attachment} setAttachment={setAttachment} />
                <Box sx={{
                    display: 'flex', justifyContent: 'flex-end',
                    gap: 1.5, mt: 3
                }}>
                    <Button variant='outlined' disabled={submit}
                        sx={{ height: { xs: 35, md: 42 } }}>
                        huỷ thao tác
                    </Button>
                    <Button variant='contained'
                        // disabled={submit}
                        type="submit"
                        startIcon={submit && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                        sx={{ height: { xs: 35, md: 42 }, minWidth: 200 }}>
                        Tạo Lịch & Hoạt Động
                    </Button>
                </Box>
            </Paper>
        </FormProvider>
    )
}

export default ScheduleForm