import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import withPermission from "@/hocs/withPermission";
import { CUSTOMER_STATUS_TYPES } from "@/libs/constants";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { MultipleStopOutlined } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const ChangeStatus = ({
  disabled = true,
  setDisabled,
  customerId = null,
  customerName = "",
  callback,
  ...customer
}) => {
  const [submit, setSubmit] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const [selected, setSelected] = React.useState(
    customer?.customer_service_information?.customer_status || -1
  );

  const [offReason, setOffReason] = useState("");

  const onSubmit = async () => {
    if (customer?.customer_service_information?.customer_status === selected) {
      return {
        status: 404,
        message:
          "Trạng thái khách hàng không thay đổi, không thể thực hiện thao tác.",
      };
    }
    setSubmit(true);

    const response =
      selected === "cool-lead"
        ? await CustomerApi.Free({
          customerId,
          reason: offReason,
        })
        : await CustomerApi.UpdateCSI({
          customerId: customerId,
          data: {
            customer_status: selected,
          },
        });
    setSubmit(false);
    if (!response || response?.errors) {
      return {
        status: response?.status || 404,
        message:
          response?.errors?.[0]?.message ||
          "Thay đổi trạng thái khách hàng thất bại.",
      };
    }
    try {
      callback();
      setOpenModal(false);
      setDisabled(false);
    } catch (error) {
      //
    }
    return {
      status: 200,
      message: "Thay đổi trạng thái khách hàng thành công.",
    };
  };

  const handlerClick = () => {
    if (openModal) {
      setDisabled(false);
      return setOpenModal(false);
    }
    setDisabled(true);
    return setOpenModal(true);
  };

  return (
    <React.Fragment>
      {openModal && (
        <Dialog open={true} maxWidth="sm" fullWidth>
          <DialogTitle>
            Thay đổi trạng thái khách hàng
            <Typography
              sx={{
                fontSize: 14,
                color: "#777680",
              }}
            >
              Trạng thái khách hàng được thay đổi tự động theo hệ thống mới, nên
              hạn chế thay đổi trạng thái khách hàng bằng thao tác thủ công.
            </Typography>
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <TextField
                size="small"
                label="Trạng thái hiện tại"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
                value={
                  CUSTOMER_STATUS_TYPES?.find(
                    (stt) =>
                      stt.value ===
                      customer?.customer_service_information?.customer_status
                  )?.label
                }
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <SingleSelectCustomize
                options={CUSTOMER_STATUS_TYPES?.slice(0, 2)?.filter(
                  (stt) =>
                    ![
                      "error",
                      customer?.customer_service_information?.customer_status,
                    ].includes(stt.value)
                )}
                value={
                  CUSTOMER_STATUS_TYPES?.slice(0, 2)?.find(
                    (stt) => stt.value === selected
                  ) || null
                }
                input={{
                  label: "Trạng thái thay đổi",
                  placeholder: "Chọn trạng thái thay đổi",
                }}
                onChange={(event, newValue) =>
                  setSelected(newValue?.value || -1)
                }
              />
            </FormControl>
            <Collapse in={selected === "cool-lead"}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <TextField
                  size="small"
                  label="Lý do bỏ theo dõi khách hàng *"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Nhập lý do bỏ theo dõi khách hàng..."
                  multiline
                  value={offReason}
                  onChange={(event) => setOffReason(event?.target?.value)}
                />
              </FormControl>
            </Collapse>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setOpenModal(false);
                setDisabled(false);
              }}
              disabled={submit}
            >
              Huỷ thao tác
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={onSubmit}
              disabled={
                submit ||
                customer?.customer_service_information?.customer_status ===
                selected
              }
              startIcon={
                submit && (
                  <CircularProgress size={14} sx={{ color: "#777680" }} />
                )
              }
            >
              Xác nhận
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Button
        size="small"
        variant="contained"
        fullWidth
        disabled={disabled || submit || customer?.customer_service_information?.customer_status !== 'qualified-prospects'}
        startIcon={<MultipleStopOutlined fontSize="small" />}
        sx={{ height: { xs: 40, md: 42 } }}
        onClick={handlerClick}
      >
        đổi trạng thái
      </Button>
    </React.Fragment>
  );
};

export default withPermission(ChangeStatus, {
  feature: "customer",
  action: "update-csi",
  type: "Button",
});
