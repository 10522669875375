import { Grid, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { CustomerComponents } from "../Components";
import NumericFormatCustom from "@components/NumericFormatCustom";

const ExplainationStep = () => {
  const { control } = useFormContext();

  const CUSTOMER_FIELDS = [
    {
      field: "customer_service_information.customer_provider_explaination",
      component: "TextField",
      label: "Khách hàng đang dùng nhà cung cấp nào?",
      cols: 6,
    },
    {
      field: "customer_service_information.customer_budget_explaination",
      component: "TextField",
      label: "Budget (chi phí/ tháng)",
      cols: 6,
      props: {
        InputProps: {
          inputComponent: NumericFormatCustom,
        },
      },
    },
    {
      field: "customer_service_information.customer_influencer_explaination",
      component: "TextField",
      label: "Người có khả năng đưa ra quyết định",
      cols: 6,
    },
    {
      field: "customer_service_information.customer_expected_date",
      component: "DatePicker",
      label: "Thời gian bán hàng dự tính",
      cols: 6,
    },
    {
      field: "customer_service_information.customer_problem_explaination",
      component: "TextField",
      label: "Vấn đề của khách hàng",
      cols: 12,
      props: {
        multiline: true,
        placeholder: "...",
      },
    },
    {
      field: "customer_service_information.customer_expected_explaination",
      component: "TextField",
      label: "Mong đợi/ mong muốn",
      cols: 12,
      props: {
        multiline: true,
        placeholder: "...",
      },
    },
  ];

  return (
    <Grid container spacing={2}>
      {CUSTOMER_FIELDS?.map((field, index) => {
        if (field.hide) return null;
        const Component = CustomerComponents[field.component] || null;
        if (!Component) return null;
        return (
          <Grid item xs={field.cols || 12} md={field?.cols || 4} key={index}>
            <Typography sx={{ fontSize: 14, mb: 0.5 }} color="text.grey">
              {field.label} {field.required ? " *" : ""}
            </Typography>
            <Controller
              name={field.field}
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <Component
                    value={value}
                    onChange={onChange}
                    {...field.props}
                  />
                );
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
export default ExplainationStep;
