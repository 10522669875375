import CompanyApi from '@/apis/beta/Company/CompanyApi'
import { sleep } from '@/functions'
import withPermission from '@/hocs/withPermission'
import { useNotificationContext } from '@contexts/notification'
import { EditOutlined } from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'

const Update = ({ companyId }) => {

    const { setNotification } = useNotificationContext()
    const { handleSubmit } = useFormContext()

    const [submit, setSubmit] = React.useState(false)

    const onSubmit = async (values) => {
        setSubmit(true)
        const response = await CompanyApi.Update(
            { ...values },
            companyId
        )
        await sleep(500)
        setSubmit(false)
        if (!response || response?.errors) {
            return setNotification({
                open: true,
                severity: "error",
                message: response?.errors?.[0]?.message || "Cập nhật thông tin công ty thất bại."
            })
        }
        return setNotification({
            open: true,
            severity: "success",
            message: "Cập nhật thông tin công ty thành công."
        })
    }

    return (
        <Button
            size='small'
            variant='contained'
            disabled={submit}
            onClick={handleSubmit(onSubmit)}
            startIcon={
                submit ? <CircularProgress size={14} sx={{ color: "#777680" }} />
                    : <EditOutlined fontSize='small' />}>
            Cập nhật
        </Button>
    )
}

export default
    withPermission(Update, {
        feature: "company",
        action: "show",
        type: "Button"
    })