import ServiceConfigoptionApi from "@/apis/beta/Service/ServiceConfigoptionApi";
import { queryClientRefetcher } from "@/functions";
import { PRODUCT_UNITS } from "@/libs/constants";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import FORM_BASE from "@components/shared/FormCustomized/form";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { useNotificationContext } from "@contexts/notification";
import { vestResolver } from "@hookform/resolvers/vest";
import { AddOutlined, DeleteOutlined } from "@mui/icons-material";
import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	IconButton
} from "@mui/material";
import _ from "lodash";
import { Fragment, useMemo } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import serviceInformationValidators from "../Service/validator";

const CONFIGOPTIONS = [
	{ value: 'ip', label: 'IP' },
	{ value: 'u', label: 'U/ Rack' },
	{ value: 'power', label: 'Nguồn Điện' },
	{ value: 'global', label: 'Bandwidth (Quốc Tế)' },
	{ value: 'local', label: 'Bandwidth (Trong Nước)' },
	{ value: 'domains', label: 'Domains' },
	{ value: 'origin_shield_traffics', label: 'Origin Shield Traffics' },
	{ value: 'origin_shield_requests', label: 'Origin Shield Requests' },
	{ value: 'dns_requests', label: 'DNS Requests' },
]

const ServiceConfigoptions = ({ service: { configoptions = [], ...service }, onClose }) => {
	const queryClient = useQueryClient();
	const { setNotification } = useNotificationContext();
	const {
		control,
		handleSubmit,
	} = useForm({
		resolver: vestResolver(serviceInformationValidators),
		defaultValues: useMemo(() => {
			return {
				service_configoptions: configoptions?.map((item) => _.pick(item, ["service_configoption_id", "name", "unit", "value"]))
			}
		}, [configoptions])
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: "service_configoptions",
	});

	const { mutateAsync, isLoading } = useMutation(ServiceConfigoptionApi.Update)
	const onSubmit = async (values) => {
		const response = await mutateAsync({
			serviceId: service?.service_id,
			data: values?.service_configoptions || [],
		});

		if (!response || response?.errors)
			return setNotification({
				open: true,
				message: response?.errors?.[0]?.message,
				severity: "error",
			});

		setNotification({
			open: true,
			message: `Cập nhật cấu hình dịch vụ ${service?.service_name} thành công, vui lòng điều chỉnh lại giá nếu có thay đổi.`,
			severity: "success",
		});
		queryClientRefetcher(queryClient, [
			"services.ServiceConfigoptionss.list",
			"service.informations",
			"services.detail",
		]);
		return onClose();
	};
	return (
		<Fragment>
			<Box
				sx={{ display: "flex", mt: 3, flexDirection: "column", gap: 1.5 }}
				component="form"
				onSubmit={handleSubmit(onSubmit)}
			>
				{fields?.map((field, index) => {
					return (
						<Grid container spacing={2} key={field.id}>
							<Grid item xs={12} md={4} xl={4}>
								<Controller
									name={`service_configoptions.[${index}].name`}
									control={control}
									render={({ field, fieldState: { error } }) => {
										return (
											<FormControl fullWidth>
												<FORM_BASE.SingleSelect
													options={[...CONFIGOPTIONS]}
													value={field.value}
													onChange={field.onChange}
													input={{
														label: 'Cấu hình *',
														placeholder: "Chọn cấu hình...",
													}}
												/>
												{!!error && <ErrorHelperText message={error?.message} />}
											</FormControl>
										);
									}}
								/>
							</Grid>
							<Grid item xs={12} md={5} xl={5}>
								<Controller
									name={`service_configoptions.[${index}].value`}
									control={control}
									render={({ field: { value, onChange }, fieldState: { error } }) => {
										console.log(field.value)
										return (
											<FormControl fullWidth>
												<FORM_BASE.InputUnlimitCustomized
													value={value}
													onChange={onChange}
													label="Thông số/ cấu hình*"
												/>
												{!!error && <ErrorHelperText message={error?.message} />}
											</FormControl>
										);
									}}
								/>
							</Grid>
							<Grid item xs={12} md={2.5}>
								<Controller
									name={`service_configoptions.[${index}].unit`}
									control={control}
									render={({ field: { value, onChange }, fieldState: { error } }) => {
										const selected =
											PRODUCT_UNITS?.find((x) => x?.value === value) || null;
										return (
											<FormControl fullWidth>
												<SingleSelectCustomize
													value={selected}
													options={PRODUCT_UNITS}
													onChange={(event, newValue) =>
														onChange(newValue?.value || "qty")
													}
													input={{
														label: "Đơn vị tính *",
													}}
												/>
												{!!error && <ErrorHelperText message={error?.message} />}
											</FormControl>
										);
									}}
								/>
							</Grid>
							<Grid item xs={12} md={0.5} sx={{ alignContent: "center" }}>
								<IconButton
									size="small"
									sx={{ m: "auto 0" }}
									onClick={() => remove(index)}
								>
									<DeleteOutlined
										fontSize="small"
										sx={{
											":hover": {
												color: "#ef5350",
											},
										}}
									/>
								</IconButton>
							</Grid>
						</Grid>
					);
				})}
				<Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
					<Button
						size="small"
						onClick={() =>
							append({
								name: "",
								value: 1,
								unit: "qty",
							})
						}
						sx={{ height: 32 }}
						startIcon={<AddOutlined fontSize="small" />}
					>
						Thêm thông tin cấu hình
					</Button>
				</Box>
				<Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end", gap: 2 }}>
					<Button
						size="small"
						variant="outlined"
						disabled={isLoading}
						onClick={onClose}
					>
						Huỷ Thao Tác
					</Button>
					<Button
						size="small"
						variant="contained"
						type="submit"
						onClick={handleSubmit(onSubmit)}
						disabled={isLoading}
						startIcon={isLoading && <CircularProgress size={14} />}
					>
						Lưu Thay Đổi
					</Button>
				</Box>
			</Box>
		</Fragment>
	);
};

export default ServiceConfigoptions