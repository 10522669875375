import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import { buildQueryFilterCondition } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { FormControl } from "@mui/material";
import React, { useCallback, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

const ContractCustomer = ({ disabled }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const [state, setState] = useState("");

  const debouncedSearchQuery = useDebounce(state, 400);

  const filterableCustomer = useCallback(() => {
    let fieldQueries = [];
    if (parseInt(watch(`customer_owner`)) > 0) {
      fieldQueries.push({
        name: "customers.customer_id",
        operator: "$eq",
        value: parseInt(watch(`customer_owner`)),
      });
    }

    if (!!debouncedSearchQuery && !(debouncedSearchQuery?.trim() === "")) {
      fieldQueries.push(
        ...[
          {
            name: "customers.customer_id",
            operator: "$eq",
            disabled: !parseInt(debouncedSearchQuery),
            value: parseInt(debouncedSearchQuery),
          },
          {
            name: "customers.customer_name",
            operator: "$contains",
            value: debouncedSearchQuery,
          },
          {
            name: "company_name",
            operator: "$contains",
            value: debouncedSearchQuery,
          },
          {
            name: "customers.customer_email",
            operator: "$contains",
            value: debouncedSearchQuery,
          },
        ]
      );
    }
    return buildQueryFilterCondition("$or", 0, fieldQueries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery, watch(`customer_owner`)]);

  const {
    isFetching,
    data: { customers },
  } = CustomerApi.List({
    page: 1,
    pageSize: 15,
    query: filterableCustomer(),
  });

  const rebuildCustomerSelections = React.useMemo(() => {
    if (!customers || customers?.length === 0) return [];

    return customers?.map((customer) => {
      let label = customer?.customer_id;
      if (customer?.customer_type !== "individual") {
        label += ` - ${customer?.customer_company?.company_name || customer?.customer_name}`;
      } else {
        label += ` - ${customer?.customer_name}`;
      }

      return {
        value: customer?.customer_id,
        label,
      };
    });
  }, [customers]);

  return (
    <Controller
      name="customer_owner"
      control={control}
      render={({ field: { value, onChange } }) => {
        let options = rebuildCustomerSelections;
        const selected =
          options?.find((v) => parseInt(v?.value) === parseInt(value)) || null;
        return (
          <FormControl
            fullWidth
            sx={{
              width: { xs: "100%" },
            }}
          >
            <SingleSelectCustomize
              loading={isFetching}
              value={selected}
              disabled={disabled}
              options={options}
              onChange={(event, newValue) => onChange(newValue?.value || -1)}
              input={{
                placeholder: "Nhập tên & Id khách hàng tìm kiếm...",
                value: state,
                onChange: (e) => setState(e?.target?.value || ""),
                label: "Khách Hàng/ Chủ Thể *",
              }}
              mode="server"
            />
            {!!errors?.contract_owner && (
              <ErrorHelperText message={errors?.contract_owner?.message} />
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default withSuspense(ContractCustomer, MuiSkeleton["TextField"]);
