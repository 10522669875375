import withPermission from '@/hocs/withPermission'
import { Button, CircularProgress } from '@mui/material'

const ScopeUpdate = ({
    submitting = false,
    onSubmit
}) => {
    return (
        <Button size='small' variant='contained'
            disabled={submitting} startIcon={submitting && <CircularProgress size={14} sx={{ color: "#777680" }} />} onClick={onSubmit}>
            Cập Nhật
        </Button>
    )
}

export default withPermission(ScopeUpdate, { feature: 'scope', action: 'update', type: 'Button' })