import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

import DashboardWelcome from '../shared/DashboardWelcome';
import DayoffInformation from '../shared/DayoffInformation';
import BusinessInformation from '../shared/BusinessInformation';
import Schedule from '../shared/Schedule';
import Leave from '../shared/DayoffReport';
import Post from "../shared/Post"
import UpcomingSchedule from '../shared/Schedule/Upcoming';

const Hr = () => {
    return (
        <Box>
            <Typography sx={{ fontSize: 14, color: 'grey.700' }}>
                Dashboard
            </Typography>
            <DashboardWelcome />
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} xl={8.5}>
                    <Leave />
                    <UpcomingSchedule />
                </Grid>
                <Grid item xl={3.5} lg={3.5} md={4} sm={12} xs={12}>
                    <Schedule />
                    <Post />
                    <DayoffInformation />
                    <BusinessInformation />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Hr;
