import { useMutation } from "react-query";
import ExcelApi from "@/apis/shared/Reports/ExcelApi";
import { downloadFileWithBinaryResponse, encodeBase64 } from "@/functions";
import { Filter } from "@/hooks/useFilters";
import ButtonBaseExport from "../shared/ButtonBaseCustom";

const Contact = ({ query }) => {
  const { mutateAsync, isLoading } = useMutation(ExcelApi.Contact);

  const handler = async () => {
    const response = await mutateAsync({
      filters: encodeBase64(Filter.stringify(query)),
    });
    if (!response) return;
    downloadFileWithBinaryResponse(`${Date.now()}.xlsx`, response);
  };

  return (
    <ButtonBaseExport
      handler={handler}
      loading={isLoading}
      disabled={isLoading}
    />
  );
};

export default Contact;
