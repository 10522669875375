import ScopeApi from '@/apis/beta/Scope/ScopeApi'
import { queryClientRefetchScope } from "@/functions"
import withPermission from '@/hocs/withPermission'
import { useNotificationContext } from '@contexts/notification'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import ScopeForm from '../../Forms'


const ScopeAssignee = ({
    onClose,
    scope,
    scopeId
}) => {

    const methods = useForm({
        defaultValues: {
            scope_assignees: scope?.scope_assignees?.map((employee) => employee?.employee_id) || []
        }
    })

    const { setNotification } = useNotificationContext()
    const queryClient = useQueryClient()

    const { mutateAsync, isLoading } = useMutation(ScopeApi.Assignee)

    const onSubmit = async (values) => {
        const response = await mutateAsync({ scopeId, scope: values })
        if (!response || response?.errors) {
            return setNotification({ open: true, message: response?.errors?.[0]?.message, severity: "error" })
        }

        queryClientRefetchScope(queryClient, 'scope')
        setNotification({ open: true, message: "Cập nhật quyền truy cập thành công.", severity: "success" })
        return onClose()
    }
    return (
        <Dialog open={true}
            fullWidth
            maxWidth="md">
            <DialogTitle>
                Cấp Quyền Cho Nhân Viên {scope?.scope_name}
            </DialogTitle>
            <FormProvider {...methods}>
                <DialogContent>
                    <Box component="form">
                        <ScopeForm.ScopeAssignees />
                    </Box>
                </DialogContent>
            </FormProvider>
            <DialogActions>
                <Button size='small'
                    variant='outlined'
                    disable={isLoading}
                    onClick={onClose}
                >
                    Huỷ Thao Tác
                </Button>
                <Button size='small'
                    disable={isLoading}
                    variant='contained'
                    onClick={methods.handleSubmit(onSubmit)}
                    startIcon={isLoading && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                >
                    Lưu Thay Đổi
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withPermission(ScopeAssignee, { feature: 'scope', action: 'assignee' })