import BreadCrumb from "@components/shared/BreadCrumb";
import { Box, Typography } from "@mui/material";
import ContractActions from "../../shared/Actions";
import CustomerContractTabs from "../../shared/CustomerContractTabs";

const Header = ({ type = "detail", contract }) => {
    return (
        <Box>
            <Box sx={{ display: {xs: "block", md: "flex"}, justifyContent: 'space-between' }}>
                <Box>
                    <Typography className='page-title'>
                        Thông Tin Hợp Đồng Khách Hàng
                    </Typography>
                    <BreadCrumb
                        data={[
                            { label: "Danh Sách Hợp Đồng Khách Hàng", reactLink: "/customer_contracts" },
                            { label: contract?.contract_number_id },
                        ]}
                    />
                </Box>
                {type === 'detail'
                    ? <ContractActions.CreateSubcontract contract={contract} />
                    : <ContractActions.CreateContractProcess contract={contract} />}
            </Box>
            <CustomerContractTabs prefix={`customer_contracts/${contract?.contract_id}`} />
        </Box>
    )
}
export default Header;