/* eslint-disable react-hooks/exhaustive-deps */
import DomainApi from "@/apis/beta/Domain/DomainApi";
import withSuspense from "@/hocs/withSuspense";
import NumericFormatCustom from "@components/NumericFormatCustom";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { FormControl, TextField } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { PRIVATE_DOMAIN_BILLING_CYCLE } from "../DomainBillingCycle";

const DomainPricingAmount = () => {
  const { data } = DomainApi.Supports();
  const {
    control,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext();

  // perform product_billing_configures

  const rebuildProductBillingConfigures = (extension) => {
    const billingConfigures = {
      product_billing_metadata: {
        domain_price_id: extension?.domain_price_id,
        dp_extension: extension?.dp_extension,
        dp_price_register: extension?.dp_price_register,
        dp_price_renew: extension?.dp_price_renew,
        dp_price_transfer: extension?.dp_price_transfer,
      },
      service_billing_terms: [
        {
          name: "annually",
          pricing: extension?.dp_price_register,
          pricing_minimum: extension?.dp_price_register,
        },
      ],
    };
    setValue(`product_billing_configures`, billingConfigures);
  };

  React.useEffect(
    () => {
      if (!!errors?.domain?.domain_value || !!errors?.product_billing_cycle) {
        return setValue(`product_pricing_amount`, 0);
      }
      const extension = data.find((x) => {
        const rgx = new RegExp([x?.dp_extension, "$"]?.join(""), "g");
        return rgx.test(watch(`domain.domain_value`));
      });
      if (!extension) return setValue(`product_pricing_amount`, 0);

      const isRegister = watch(`domain.domain_type`) === "register";

      const period = PRIVATE_DOMAIN_BILLING_CYCLE?.findIndex(
        (x) => x?.value === watch(`product_billing_cycle`)
      );
      if (isRegister && period >= 0) {
        setValue(`product_pricing_init`, extension?.dp_price_register);
        setValue(
          `product_pricing_amount`,
          extension?.dp_price_register + extension?.dp_price_renew * period
        );
      } else {
        setValue(`product_pricing_init`, extension?.dp_price_transfer);
        setValue(
          `product_pricing_amount`,
          extension?.dp_price_transfer * (period + 1)
        );
      }
      return rebuildProductBillingConfigures(extension);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      watch(`domain.domain_type`),
      watch(`domain.domain_value`),
      watch(`product_billing_cycle`),
    ],
    errors,
    data
  );
  return (
    <Controller
      name="product_pricing_amount"
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <TextField
              label="Tạm tính *"
              size="small"
              value={value}
              onChange={onChange}
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
          </FormControl>
        );
      }}
    />
  );
};

export default withSuspense(DomainPricingAmount, MuiSkeleton["TextField"]);
