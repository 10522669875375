import { encodeBase64, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";
import queryString from "query-string";

class WarehouseItemApi {
  Index = ({
    query = null,
    searchQuery = null,
    page = 1,
    pageSize = 20,
    type = null,
    customer = null,
    item_exports = false,
  }) => {
    const paramsQueryString = queryString.stringify(
      {
        page: page,
        page_size: pageSize,
        type: type,
        customer: customer,
        item_exports: item_exports,
      },
      {
        skipNull: true,
        arrayFormat: "bracket",
        skipEmptyString: true,
      }
    );
    return useQueryWithCustomized(
      [
        "warehouse.warehouse_item.index",
        query,
        searchQuery,
        page,
        pageSize,
        paramsQueryString,
      ],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_items?${paramsQueryString} ${
            query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${searchQuery}` : ""}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              warehouse_items: data?.data?.warehouse_items?.map((o, index) => ({
                // id: o?.warehouse_item_id,
                id: index,
                ...o,
              })),
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            console.log(error.response);
            return {
              warehouse_items: [],
            };
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Statistic = ({
    query = null,
    searchQuery = null,
    type = null,
    customer = null,
    analysist = true,
  }) => {
    const paramsQueryString = queryString.stringify(
      {
        type: type,
        customer: customer,
        analysist: analysist,
      },
      {
        skipNull: true,
        arrayFormat: "bracket",
        skipEmptyString: true,
      }
    );

    return useQueryWithCustomized(
      ["warehouse.warehouse_item.statistic", query, searchQuery, type],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_items?${paramsQueryString}${
            query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${searchQuery}` : ""}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.statistic;
          })
          .catch((error) => {
            console.log(error.response);
            return {
              statistic: {},
            };
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Detail = (id, callback) =>
    useQueryWithCustomized(
      ["warehouse_items.detail", id],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_items/${id}/detail`
        )
          .then(({ data }) => {
            if (data?.errors) return callback(data.errors);

            return {
              warehouse_item: data?.data?.warehouse_item,
            };
          })
          .catch((error) => {
            console.log(error);
            return error.response;
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        notifyOnChangePropsExclusions: ["isStale"],
        suspense: true,
      }
    );

  Update = (id, params) =>
    requestWithToken(`${API_WAREHOUSE_V1_URL}/warehouse_items/${id}`, "PUT", {
      warehouse_item: params,
    });

  RemoveChild = (id) =>
    requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_items/${id}/remove_child`,
      "PUT",
      {}
    );
  RemoveChildMany = (ids, warehouse_item_parent) =>
    requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_items/remove_childs`,
      "PUT",
      {
        warehouse_item_parent: warehouse_item_parent,
        ids: ids
      }
    );

  getAll = (query = "") =>
    useQueryWithCustomized(
      ["warehouse.items.all", query],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_items/list${
            query ? `?&filters=${encodeBase64(query)}` : ""
          }`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.warehouse_items;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );

  Search = async (params) => {
    if (!params?.haveSearchWhenKeyWordEmpty && !params?.key_search?.trim())
      return null;

    const paramsQueryString = queryString.stringify(
      { ...params, page: params?.pageParam || 1 },
      {
        skipNull: true,
      }
    );

    const results = await requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_items/search?${paramsQueryString}`,
      "GET",
      {}
    ).then((response) => {
      const data = response.data.data;
      if (response.data.status !== 200) {
        console.log("error", data.data.errors[0].message);
        return [];
      }

      return {
        warehouse_items: data?.warehouse_items.map((o) => ({
          id: o.warehouse_item_id,
          ...o,
        })),
        pagination: data?.pagination,
      };
    });

    return results;
  };

  Show = (id, callback) =>
    useQueryWithCustomized(
      ["warehouse_items.show", id],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_items/${id}`
        )
          .then(({ data }) => {
            if (data?.errors) return callback(data.errors);

            return {
              warehouse_item: data?.data?.warehouse_item,
            };
          })
          .catch((error) => {
            console.log(error);
            return error.response;
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        notifyOnChangePropsExclusions: ["isStale"],
        suspense: true,
      }
    );

  CheckItemsExist = async (params) => {
    const paramsQueryString = queryString.stringify(params, {
      skipNull: true,
      arrayFormat: "bracket",
      skipEmptyString: true,
    });

    return (
      await requestWithToken(
        `${API_WAREHOUSE_V1_URL}/warehouse_items/check_service_tags_exist?${paramsQueryString}`,
        "GET",
        {}
      )
    )?.data;
  };

  Cords = async (paramsQueryString) => {
    return await requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_items/cords?${paramsQueryString}`
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return { warehouse_items: data?.data?.warehouse_items };
      })
      .catch((error) => {
        console.log(error.response);
        return { warehouse_items: [] };
      });
  };

  FindCords = async ({ id, paramsQueryString }) =>
    await requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_items/cords/${id}?${paramsQueryString}`
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return { warehouse_item: data?.data?.warehouse_item };
      })
      .catch((error) => {
        console.log(error.response);
        return { warehouse_item: {} };
      });

  updateDepreciation = (id, params) =>
    requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_items/${id}/depreciation`,
      "PUT",
      { depreciation: params }
    );

  ItemsExport = ({
    query = null,
    searchQuery = null,
    page = 1,
    pageSize = 20,
    type = null,
    customer = null,
    item_exports = false,
  }) => {
    const paramsQueryString = queryString.stringify(
      {
        page: page,
        page_size: pageSize,
        type: type,
        customer: customer,
        item_exports: item_exports,
      },
      {
        skipNull: true,
        arrayFormat: "bracket",
        skipEmptyString: true,
      }
    );

    return useQueryWithCustomized(
      [
        "warehouse.warehouse_item_export.list",
        query,
        searchQuery,
        page,
        pageSize,
        paramsQueryString,
      ],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_items/items_export?${paramsQueryString} ${
            query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${searchQuery}` : ""}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              warehouse_items: data?.data?.warehouse_items.map((o) => ({
                id: o.warehouse_item_id,
                ...o,
              })),
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            console.log(error.response);
            return {
              warehouse_items: [],
            };
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };
  ItemsExported = ({
    query = null,
    searchQuery = null,
    page = 1,
    pageSize = 20,
    type = null,
    customer = null,
    item_exports = false,
  }) => {
    const paramsQueryString = queryString.stringify(
      {
        page: page,
        page_size: pageSize,
        type: type,
        customer: customer,
        item_exports: item_exports,
      },
      {
        skipNull: true,
        arrayFormat: "bracket",
        skipEmptyString: true,
      }
    );
    return useQueryWithCustomized(
      [
        "warehouse.warehouse_item.list",
        query,
        searchQuery,
        page,
        pageSize,
        paramsQueryString,
      ],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_items/items_exported?${paramsQueryString} ${
            query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${searchQuery}` : ""}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              warehouse_items: data?.data?.warehouse_items.map((o) => ({
                id: o.warehouse_item_id,
                ...o,
              })),
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            console.log(error.response);
            return {
              warehouse_items: [],
            };
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };
  Search_ServiceTags = ({service_information_type, warehouse_brand_name, warehouse_model_name}) => {
    const paramsQueryString = queryString.stringify(
      {
        service_information_type: service_information_type,
        warehouse_brand_name: warehouse_brand_name,
        warehouse_model_name: warehouse_model_name,
        warehouse_box_item_reference_id: 1,
        warehouse_box_item_reference_type: "WarehouseBox"
      },
      {
        skipNull: true,
        arrayFormat: "bracket",
        skipEmptyString: true,
      }
    );
    return useQueryWithCustomized(
      [
        "warehouse.warehouse_item.list",
        service_information_type,
        warehouse_model_name,
        warehouse_brand_name
      ],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_items/search/service_tags?${paramsQueryString}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            console.log(data)
            return {
              warehouse_items: data?.data?.warehouse_items,
            };

          })
          .catch((error) => {
            console.log(error.response);
            return {
              warehouse_items: [],
            };
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  // SearchItemWithServiceTag= async ({ service_tag }) =>
  //   await requestWithToken(
  //     `${API_WAREHOUSE_V1_URL}/warehouse_items/search/service_tags?service_tag=${service_tag}`
  //   )
  //     .then(({ data }) => {
  //       if (data?.error) return data;
  //       return { warehouse_item: data?.data?.warehouse_items || {} };
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //       return { warehouse_item: {} };
  //     });
  SearchItemWithIds = async ({ params }) =>
        await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_items/search/service_tags?${params}`
        )
        .then(({ data }) => {
          if (data?.error) return data;
          return data?.data?.warehouse_items || [] ;
        })
        .catch((error) => {
          console.log(error.response);
          return [];
        });
  CheckItemsExported = async ({ params }) =>
      await requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_items/check_items_exported/?${params}`
      )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data?.warehouse_items || [] ;
      })
      .catch((error) => {
        console.log(error.response);
        return [];
      });
  GetItemsByIds = async ({ params }) =>
    await requestWithToken(
    `${API_WAREHOUSE_V1_URL}/warehouse_items/get_items_by_ids/?${params}`
    )
    .then(({ data }) => {
      if (data?.error) return data;
      return data?.data?.warehouse_items || [] ;
    })
    .catch((error) => {
      console.log(error.response);
      return [];
    });
  getAllWithProcess = (query = "") =>
    useQueryWithCustomized(
      ["warehouse.items.all", query],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_items/list_with_process${
            query ? `?&filters=${encodeBase64(query)}` : ""
          }`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.warehouse_items;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );
  ItemsCompany = ({
    query = null,
    searchQuery = null,
    page = 1,
    pageSize = 20,
    type = null,
    customer = null,
    item_exports = false,
  }) => {
    const paramsQueryString = queryString.stringify(
      {
        page: page,
        page_size: pageSize,
        type: type,
        customer: customer,
        item_exports: item_exports,
      },
      {
        skipNull: true,
        arrayFormat: "bracket",
        skipEmptyString: true,
      }
    );
    return useQueryWithCustomized(
      [
        "warehouse.warehouse_item.company",
        query,
        searchQuery,
        page,
        pageSize,
        paramsQueryString,
      ],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_items/items_company?${paramsQueryString} ${
            query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${searchQuery}` : ""}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              warehouse_items: data?.data?.warehouse_items?.map((o, index) => ({
                // id: o?.warehouse_item_id,
                id: index,
                ...o,
              })),
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            console.log(error.response);
            return {
              warehouse_items: [],
            };
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };
  GetLogs = ({
    warehouse_item_id
  }) => {
    return useQueryWithCustomized(
      [
        "warehouse.warehouse_item.logs",
        warehouse_item_id
      ],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_item_logs/${warehouse_item_id}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.warehouse_item_logs || []; 
          })
          .catch((error) => {
            console.log(error.response);
            return [];
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new WarehouseItemApi();
