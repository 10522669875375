import { format_numeral_price } from '@/functions'
import { Box, Typography } from '@mui/material'
import React from 'react'

const CellPricing = ({
    price,
    ...props
}) => {
    return (
        <Box sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            pr: 0.5
        }}>
            <Typography component="div" sx={{
                fontSize: 14,
                m: 'auto 0 auto auto',
                width: '100%',
                textAlign: 'right',
                ...props?.sx
            }}>
                <Typography sx={{
                    fontSize: 14,
                    color: "#777680",
                    textDecoration: "underline",
                    display: 'inline-flex',
                    ...props?.sx
                }}>
                    đ
                </Typography>
                {format_numeral_price(price)}
            </Typography>
        </Box>
    )
}

export default CellPricing