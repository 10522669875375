import EmployeeApi from '@/apis/beta/Employee/EmployeeApi'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { Typography } from '@mui/material'

const ContactEmployee = ({ employeeIds = [] }) => {

    const { data: { employees } } = EmployeeApi.List({
        query: !!employeeIds ? `filters[$and][0][employees.employee_id][$in]=${employeeIds?.join(',')}` : null
    })
    if (!employeeIds?.length) return null;

    return (
        <Typography>
            {employees?.map((employee, index) =>
                <Typography key={index} component="span">
                    {index !== 0 && ", "}
                    {employee?.employee_name}
                </Typography>
            )}
        </Typography>
    )
}

export default withSuspense(ContactEmployee, MuiSkeleton["GradientCircularProgress"])