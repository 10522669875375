import ServiceMetadataApi from "@/apis/beta/Service/ServiceMetadataApi";
import { queryClientRefetchScope } from "@/functions";
import NumericFormatCustom from "@components/NumericFormatCustom";
import { useNotificationContext } from "@contexts/notification";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

const ServiceMetadata = ({ onClose, service: data }) => {
    const queryClient = useQueryClient()
    const { setNotification } = useNotificationContext()

    const { mutateAsync, isLoading } = useMutation(ServiceMetadataApi.Update)

    const { control, handleSubmit } = useForm({
        defaultValues: {
            cpu: data?.service_metadata?.['cpu'] || 0,
            ram: data?.service_metadata?.['ram'] || 0,
            storage: data?.service_metadata?.['storage'] || 0,
            ip: data?.service_metadata?.['ip'] || 0
        }
    })
    const onSubmit = async (values) => {
        const response = await mutateAsync({ serviceId: data?.service_id, data: values })

        if (!response || response?.errors || response?.error) {
            return setNotification({
                open: true, message: response?.errors?.[0]?.message || 'Cập nhật thông tin dịch vụ thất bại',
                severity: 'error',
            })
        }

        onClose()
        queryClientRefetchScope(queryClient, 'service')
        return setNotification({
            open: true, message: 'Cập nhật thông tin dịch vụ thành công',
            severity: 'success',
        })
    }
    return (
        <Dialog open={true} maxWidth="md" fullWidth>
            <DialogTitle>Chỉnh sửa cấu hình dịch vụ</DialogTitle>
            <DialogContent>
                {['cpu', 'ram', 'storage', 'ip']?.map((item, index) => {
                    return (
                        <Box sx={{
                            display: 'flex', mt: 1.5,
                            flexDirection: 'row', gap: 2
                        }} key={item.id}>
                            <FormControl fullWidth>
                                <TextField size="small"
                                    InputLabelProps={{ shrink: true }}
                                    label="Tên Cấu Hình"
                                    disabled
                                    inputProps={{
                                        style: {
                                            textTransform: "uppercase",
                                        }
                                    }}
                                    value={item} />
                            </FormControl>
                            <Controller
                                name={`${item}`}
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Giá Trị Cấu Hình"
                                                InputLabelProps={{ shrink: true }}
                                                value={value}
                                                onChange={onChange}
                                                size="small"
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom
                                                }}
                                            />
                                        </FormControl>
                                    )
                                }}
                            />
                        </Box>
                    )
                })}
            </DialogContent>
            <DialogActions>
                <Button size="small" onClick={onClose} disabled={isLoading}>Huỷ Thao Tác</Button>
                <Button size="small"
                    startIcon={isLoading && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                    variant="outlined" disabled={isLoading} onClick={handleSubmit(onSubmit)}>Contained</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ServiceMetadata
