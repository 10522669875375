import {
  Box,
  FormControl,
  TextField
} from "@mui/material";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

const Contact = ({ name }) => {
  const { control } = useFormContext();

  const { fields } = useFieldArray({
    control: control,
    name: `${name}`,
  });

  return (
    <Box>
      {fields &&
        fields.map((field, index) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: 1.5,
                borderBottom: {
                  xs: "1px solid rgba(0, 0, 0, 0.18)",
                  md: "unset",
                },
                mb: 1.5,
              }}
              key={field.id}
            >
              <Controller
                control={control}
                name={`customer.customer_contact_social.[${index}].contact_channel`}
                render={({ field: { value, onChange } }) => {
                  return (
                    <FormControl
                      fullWidth
                      sx={{
                        maxWidth: {
                          xs: "100%",
                          md: "35%",
                        },
                      }}
                    >
                      <TextField
                        value={value}
                        onChange={onChange}
                        size="small"
                        placeholder="Ex: Facebook, Zalo,..."
                      />
                    </FormControl>
                  );
                }}
              />
              <Controller
                control={control}
                name={`customer.customer_contact_social.[${index}].contact_channel_information`}
                render={({ field: { value, onChange } }) => {
                  return (
                    <FormControl fullWidth>
                      <TextField
                        value={value}
                        onChange={onChange}
                        size="small"
                        placeholder="https://www.facebook.com..."
                      />
                    </FormControl>
                  );
                }}
              />
            </Box>
          );
        })}
    </Box>
  );
};

export default Contact;
