import WorkcontractApi from '@/apis/beta/Employee/WorkcontractApi'
import withPermission from '@/hocs/withPermission'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeletonDefault from '@components/Skeletons/Default'
import moment from 'moment'
import React from 'react'
import { useParams } from 'react-router-dom'
import WorkContractForm from '../shared/WorkContractForm'
import Header from './Header'

const Update = () => {
    const { employee_id, work_contract_id } = useParams()
    const { data } = WorkcontractApi.Detail({ employeeId: employee_id, workcontractId: work_contract_id })

    const defaultValues = {
        work_contract_id: data?.work_contract_id,
        work_contract_number: data?.work_contract_number,
        employee_id: employee_id,
        work_contract_term: data?.work_contract_term,
        work_contract_startdate: moment(data?.work_contract_startdate),
        work_contract_enddate: moment(data?.work_contract_enddate),
        work_contract_status: data?.work_contract_status,
        work_contract_salary_basic: data?.work_contract_salary_basic,
        work_contract_salary_allowance: data?.work_contract_salary_allowance
    }
    return (
        <React.Fragment>
            <Header />
            <WorkContractForm type="update" defaultValues={defaultValues}
                file={{
                    name: data?.work_contract_document_file_name,
                    size: data?.work_contract_document_file_size,
                    type: data?.work_contract_document_content_type
                }}
            />
        </React.Fragment>
    )
}

export default withSuspense(withPermission(Update, { feature: "work-contract", action: 'update' }), MuiSkeletonDefault)