import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import { buildQueryFilterCondition } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import MultipleSelectCustomize from "@components/shared/MultipleSelectCustomize";
import { FormControl } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

const SaleTracking = ({ name, value = [], onChange, ...props }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 400);

  const offsetFilterables = useCallback(() => {
    let offsetFieldQueries = [
      {
        name: "employees.employee_id",
        operator: "$eq",
        value: [30, ...value].join(","), // VNETWORK JSC
      },
      {
        name: "employees.department_id",
        operator: "$eq",
        value: [1, 2, 3].join(","), // VNETWORK JSC
      },
    ];
    return buildQueryFilterCondition("$or", 0, offsetFieldQueries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const filterables = useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
    let fieldQueries = [
      {
        name: "employees.employee_id",
        operator: "$eq",
        disabled: !parseInt(debouncedSearchQuery),
        value: parseInt(debouncedSearchQuery),
      },
      {
        name: "employee_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "employee_code",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
    ];
    return buildQueryFilterCondition("$or", 0, fieldQueries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const { data: employees, isFetching } = EmployeeApi.ListEmployees({
    query: offsetFilterables(),
    searchQuery: filterables(),
  });

  const selecteds = useMemo(() => {
    if (!value || value.length === 0) return [];

    return (
      employees
        ?.filter((e) => value?.includes(e?.employee_id))
        ?.map((employee) => {
          return {
            value: employee?.employee_id,
            label: employee?.employee_name,
          };
        }) || []
    );
  }, [value, employees]);
  return (
    <FormControl fullWidth>
      <MultipleSelectCustomize
        limitTags={3}
        value={selecteds}
        options={employees?.map(employee => ({ label: employee?.employee_name, value: employee?.employee_id }))}
        onChange={(event, newValue) =>
          onChange(newValue?.map((x) => x?.value) || [])
        }
        input={{
          placeholder: "Nhập tên nhân viên tìm kiếm...",
          value: searchQuery,
          onChange: (event) => setSearchQuery(event?.target?.value),
        }}
        sx={{
          "&.MuiAutocomplete-option": {
            textTransform: "uppercase",
          },
        }}
        loading={isFetching}
        mode="server"
      />
    </FormControl>
  );
};

export default withSuspense(SaleTracking, MuiSkeleton["TextField"]);
