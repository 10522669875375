import { modifyText, performPreviousAddStep } from '@/functions'
import { PRODUCT_UNITS, UNLIMITED } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { vestResolver } from '@hookform/resolvers/vest'
import { AddOutlined, DeleteOutlineOutlined } from '@mui/icons-material'
import { Box, Button, FormControl, IconButton, Paper, TextField, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import React from 'react'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import productConfigoptionValidator from './validator'

const ProductConfigoption = ({
    type = 'create',
    configoptions = [],
    defaultValues = {},
    close,
    saver
}) => {

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: vestResolver((props) => productConfigoptionValidator({
            ...props,
            method: type,
            previousName: defaultValues?.name,
            configoptions: configoptions
        })),
        reValidateMode: "onChange",
        defaultValues: defaultValues
    })
    const pricingConfigures = useWatch({ control: control, name: "steps" })

    const { fields, append, remove, update } = useFieldArray({ control: control, name: "steps" })

    const handleAddPrice = () => {
        let newStep = { start: 0, end: 0, pricing: 0 }
        if (pricingConfigures?.length === 0) {
            newStep.end = 5000
        } else {
            const lastIdx = pricingConfigures?.length - 1
            newStep.start = pricingConfigures[lastIdx]?.end || 0
            newStep.end = performPreviousAddStep(newStep.start)
        }
        return append(newStep)
    }

    return (
        <Paper sx={{ p: 3 }} elevation={8} component="form">
            <Typography sx={{ fontWeight: 'medium', mb: 1.5 }}>
                {modifyText(type)} cấu hình dịch vụ
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1.5 }}>
                <Controller
                    name="name"
                    control={control}
                    render={({ field: { value, onChange } }) =>
                        <FormControl fullWidth>
                            <TextField
                                label="Tên *"
                                value={value}
                                onChange={onChange}
                                InputLabelProps={{ shrink: true }}
                                placeholder='Nhập tên...'
                                size='small'
                                error={!!errors?.name}
                                helperText={errors?.name?.message}
                            />
                        </FormControl>} />
                <Controller
                    name="unit"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                        const selected = PRODUCT_UNITS.find(item => item.value === value) || null
                        return (
                            <FormControl fullWidth>
                                <SingleSelectCustomize
                                    value={selected}
                                    options={PRODUCT_UNITS}
                                    input={{
                                        label: "Đơn vị tính",
                                        placeholder: "Chọn đơn vị tính..."
                                    }}
                                    onChange={(event, newValue) => onChange(newValue?.value || -1)} />
                                {!!errors?.unit && <ErrorHelperText message={errors?.unit?.message} />}
                            </FormControl>
                        )
                    }} />
            </Box>
            <Typography color="primary" sx={{ fontSize: 14, mt: 2 }}>
                [Bảng giá niêm yết]
            </Typography>
            {fields && fields.map((field, index) => {
                return (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: 1.5,
                        mt: 2
                    }} key={field.id}>
                        <Box sx={{
                            display: 'flex',
                            gap: 1.5,
                            flexDirection: {
                                xs: 'column', md: 'row'
                            },
                            width: {
                                xs: '100%', md: "calc((100% - 12px) / 2)"
                            }
                        }}>
                            <Controller
                                name={`steps.[${index}].start`}
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Từ *"
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom
                                                }}
                                                error={!!errors?.steps?.[index]?.start}
                                                helperText={errors?.steps?.[index]?.start?.message}
                                                value={value}
                                                onChange={onChange}
                                                placeholder='0'
                                                size='small' />
                                        </FormControl>
                                    )
                                }} />
                            <Controller
                                name={`steps.[${index}].end`}
                                control={control}
                                render={({ field: { value, onChange } }) => {

                                    const isUnlimited = parseFloat(value) === UNLIMITED
                                    return (
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Đến *"
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    sx: {
                                                        paddingRight: 0,
                                                    },
                                                    inputComponent: isUnlimited ? "input" : NumericFormatCustom,
                                                    endAdornment: (index === fields.length - 1) ?
                                                        <Box sx={{
                                                            display: 'flex',
                                                            m: 'auto 0 auto auto',
                                                            width: '100%',
                                                            maxWidth: { xs: 'calc(100% / 3)', md: 'calc(100% / 2)' },
                                                            height: '100%',
                                                            bgcolor: "rgba(0, 0, 0, 0.18)",
                                                            borderTopRightRadius: '4px',
                                                            borderBottomRightRadius: '4px',
                                                            cursor: "pointer"
                                                        }}
                                                            onClick={() => {
                                                                if (parseFloat(value) >= UNLIMITED) {
                                                                    return;
                                                                }
                                                                return update(index, { ...field, end: UNLIMITED })
                                                            }}
                                                        >
                                                            <Typography sx={{
                                                                m: 'auto 8px auto auto',
                                                                fontSize: 13,
                                                                textTransform: 'uppercase',
                                                                color: value === UNLIMITED ? green[800] : "#777680"
                                                            }}>
                                                                Unlimited
                                                            </Typography>
                                                        </Box>
                                                        : null
                                                }}
                                                error={!!errors?.steps?.[index]?.end}
                                                helperText={errors?.steps?.[index]?.end?.message}
                                                value={isUnlimited ? "" : value}
                                                onChange={onChange}
                                                placeholder='0'
                                                fullWidth
                                                size='small' />
                                        </FormControl>
                                    )
                                }} />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gap: 1.5,
                            flexDirection: {
                                xs: 'column', sm: 'row'
                            },
                            width: {
                                xs: '100%', sm: "calc((100% - 12px) / 2)"
                            }
                        }}>
                            <Controller
                                name={`steps.[${index}].pricing`}
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Giá niêm yết *"
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom
                                                }}
                                                error={!!errors?.steps?.[index]?.pricing}
                                                helperText={errors?.steps?.[index]?.pricing?.message}
                                                value={value}
                                                onChange={onChange}
                                                placeholder='0'
                                                size='small' />
                                        </FormControl>
                                    )
                                }} />
                            <Controller
                                name={`steps.[${index}].pricing_minimum`}
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Giá min *"
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom
                                                }}
                                                error={!!errors?.steps?.[index]?.pricing_minimum}
                                                helperText={errors?.steps?.[index]?.pricing_minimum?.message}
                                                value={value}
                                                onChange={onChange}
                                                placeholder='0'
                                                size='small' />
                                        </FormControl>
                                    )
                                }} />
                            <IconButton sx={{
                                width: 40,
                                height: 40
                            }}
                                disabled={(index !== fields.length - 1) || index === 0}
                                size="small"
                                onClick={() => remove(index)}>
                                <DeleteOutlineOutlined sx={{ ":hover": { color: "#ef5350" } }} fontSize="small" />
                            </IconButton>
                        </Box>
                    </Box>
                )
            })}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1.5 }}>
                <Button variant='text' startIcon={<AddOutlined />}
                    onClick={handleAddPrice}
                    disabled={parseFloat(pricingConfigures[pricingConfigures.length - 1]?.end) >= UNLIMITED}
                    size="small"
                    sx={{
                        height: 36,
                        textDecoration: "underline",
                        fontWeight: 400
                    }}>
                    Thêm mốc giá
                </Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
                <Button variant='text' size='small' sx={{ height: 36 }} onClick={close}>
                    Huỷ Thao Tác
                </Button>
                <Button variant='outlined' size='small' sx={{ height: 36, minWidth: 150 }}
                    onClick={handleSubmit(saver)}>
                    {modifyText(type)}
                </Button>
            </Box>
        </Paper>
    )
}

export default ProductConfigoption