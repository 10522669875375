import General from './General';
import GroupRole from './GroupRole';
import Product from './Product';
import Role from './Role'
import ManageLink from './ManageLink'
import Shedule from './Schedule'
import Scope from './Scope(ManagementResource)'
import Department from './Department'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    General,
    Product,
    GroupRole,
    Role,
    Shedule,
    Scope,
    Department,
    ManageLink
};
