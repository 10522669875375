import { DescriptionOutlined } from '@mui/icons-material'
import { Box, Chip, Grid, IconButton, Paper, Skeleton, Typography } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import ContractApi from "@/apis/beta/Contract/ContractApi"
import withSuspense from "@/hocs/withSuspense"
import { format_date_short, format_numeral_price } from "@/functions"

const PartnerContractInformation = () => {
    const { partner_contract_id } = useParams()
    const { data: partnerContract } = ContractApi.Detail(partner_contract_id)

    const convertdataToArray = () => {
        let arr = []
        try {
            arr = [
                { label: "Số Hợp Đồng", value: partnerContract?.contract_number_id },
                { label: "Dịch Vụ", value: partnerContract?.contract_service?.label },
                { label: "Ngày Hiệu Lực", value: format_date_short(partnerContract?.effective_date) },
                { label: "Giá Trị", value: `${format_numeral_price(partnerContract?.contract_value)} đ` },
                { label: "Nhân Viên Phụ Trách", value: partnerContract?.employee?.employee_name },
                { label: "Ngày Hết Hạn", value: format_date_short(partnerContract?.expiry_date) },
                { label: "Trạng Thái", value: partnerContract?.contract_status === 0, componentRender: "Chip" },
                { label: "Từ Khoá Hợp Đồng", value: partnerContract?.contract_key },
            ]
        } catch (error) {
            return []
        }
        return arr
    }

    return (
        <Paper sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', mb: 1.5 }}>
                <IconButton className="information" sx={{
                    m: 'auto 8px auto 0'
                }}>
                    <DescriptionOutlined sx={{ color: "#FFFFFF" }} />
                </IconButton>
                <Typography sx={{
                    fontSize: 24,
                    fontWeight: "medium"
                }}>#{partner_contract_id}</Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={9} sm={12} xs={12}>
                    <Grid container spacing={2}>
                        {convertdataToArray()?.map((attribute, index) =>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12} key={index}>
                                <Typography sx={{ color: "#9e9e9e" }}>{attribute?.label} :</Typography>
                                {attribute?.componentRender === "Chip" ? <Chip size='small'
                                    variant={`customize-${attribute.value ? 'success' : 'error'}`}
                                    label={attribute.value ? "Kích Hoạt" : "Huỷ"}
                                />
                                    : <Typography>{attribute?.value}</Typography>}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

const SkeletonPartnerContractInformation = () => {
    return (
        <Paper sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', mb: 1.5 }}>
                <Skeleton variant="circular" sx={{ height: 40, width: 40, mr: 1 }} />
                <Skeleton variant='text' sx={{ width: 150, fontSize: 24, fontWeight: 'medium' }} />
            </Box>
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={9} sm={12} xs={12}>
                    <Grid container spacing={2}>
                        {[1, 2, 3, 4, 5, 6, 7, 8].map((attribute, index) =>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12} key={index}>
                                <Skeleton variant='text' sx={{ width: 120 }} />
                                <Skeleton variant='text' sx={{ width: 180 }} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}
export default withSuspense(PartnerContractInformation, SkeletonPartnerContractInformation)