import { EMPLOYEE_BANKING_NAME_ACCEPTS } from '@/libs/constants'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, FormControl, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const EmployeeBankingInformation = () => {

    const { control, formState: { errors } } = useFormContext()
    return (
        <Box sx={{
            p: 1.5,
            border: '1px solid rgba(0, 0, 0, 0.18)',
            borderRadius: 1
        }}>
            <Typography sx={{
                fontSize: { xs: 16, md: 18 },
                fontWeight: 'medium',
                mb: 1
            }}>
                Thuế & Ngân Hàng
            </Typography>
            <Grid container spacing={{ xs: 1.5, md: 2 }}>
                <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
                    <Controller
                        name="employee_account_bank_number"
                        control={control}
                        rules={{ required: "Số tài khoản là bắt buộc" }}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        value={value}
                                        label="Số Tài Khoản (*)"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onChange={onChange}
                                        size='small'
                                        placeholder='Số tài khoản ...'
                                        error={!!errors?.employee_account_bank_number}
                                        helperText={errors?.employee_account_bank_number?.message}
                                    />
                                </FormControl>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
                    <Controller
                        name="employee_bank_name"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            const selected = EMPLOYEE_BANKING_NAME_ACCEPTS.find(banking => banking.value === value) || null
                            return (
                                <FormControl fullWidth>
                                    <SingleSelectCustomize
                                        value={selected}
                                        options={EMPLOYEE_BANKING_NAME_ACCEPTS}
                                        input={{
                                            placeholder: "Chọn ngân hàng...",
                                            label: "Ngân hàng (*)"
                                        }}
                                        onChange={(event, newValue) => {
                                            onChange(newValue?.value || -1)
                                        }}
                                    />
                                    {!!errors?.employee_bank_name && <ErrorHelperText
                                        message={errors?.employee_bank_name?.message}
                                    />}
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
                    <Controller
                        name="employee_bank_branch"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        value={value}
                                        label="Chi Nhánh Ngân Hàng"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onChange={onChange}
                                        size='small'
                                        placeholder='Chi nhánh ngân hàng...'
                                        error={!!errors?.employee_bank_branch}
                                        helperText={errors?.employee_bank_branch?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
                    <Controller
                        name="employee_tin"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl fullWidth>
                                    <TextField
                                        value={value}
                                        label="Mã Số Thuế Cá Nhân (nếu có)"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onChange={onChange}
                                        size='small'
                                        placeholder='MST cá nhân...'
                                        error={!!errors?.employee_tin}
                                        helperText={errors?.employee_tin?.message}
                                    />
                                </FormControl>
                            )
                        }} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default EmployeeBankingInformation