import ContactApi from "@/apis/beta/Customer/ContactApi";
import { format_numeral_price } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Typography } from "@mui/material";

const Revenue = ({ data }) => {
  const { data: contact } = ContactApi.Revenue({
    contactId: data?.contact_source_id,
  });
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        pr: 1,
        justifyContent: "flex-end",
      }}
    >
      <Typography sx={{ fontSize: 14 }}>
        {contact?.amount > 0 ? format_numeral_price(contact?.amount) : ""}
      </Typography>
    </Box>
  );
};

export default withSuspense(Revenue, MuiSkeleton["GradientCircularProgress"]);
