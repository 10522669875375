import { Box, Checkbox, FormControl, FormControlLabel, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

const DomainAddon = () => {

    const { control } = useFormContext()

    const { fields } = useFieldArray({
        control: control,
        name: "domain.addons"
    })

    return (
        <Box sx={{ mt: 1.5 }}>
            <Typography sx={{ fontSize: 14, fontWeight: "medium" }}>
                Domain Add-ons
            </Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 1
            }}>
                {fields && fields.map((field, index) => {
                    return (
                        <Controller
                            control={control}
                            name={`domain.addons.[${index}].active`}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <FormControl fullWidth>
                                        <FormControlLabel
                                            value={value}
                                            label={field?.label}
                                            control={<Checkbox size='small'
                                                onChange={onChange}
                                                checked={value}
                                            />}
                                        />
                                    </FormControl>
                                )
                            }}
                            key={field.id}
                        />

                    )
                })}
            </Box>
        </Box>
    )
}

export default DomainAddon