import { format_numeral_price } from '@/functions'
import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'


export const DAYS_OFFSET_OF_MONTH = 30
const ContractTotal = ({ data }) => {

    const performMountContracTotal = (item) => {

        let tt = 0
        let months = (dayjs(item?.expiry_date).add(1, 'days')).diff(dayjs(item?.effective_date), "months")

        tt = Math.round(parseFloat(item?.contract_value) * months * 100) / 100
        let days = (dayjs(item?.expiry_date).add(1, 'days')).diff(dayjs(item?.effective_date).add(months, "months"), "days")
        if (days > 0) {
            const pricicingPerday = Math.round(parseFloat(item?.contract_value) / DAYS_OFFSET_OF_MONTH * 100) / 100
            tt += Math.round(pricicingPerday * days * 100) / 100
        }

        return tt || 0
    }
    return (

        <Box sx={{
            p: "4px",
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: "column",
            justifyContent: { xs: "center", md: "flex-start" }
        }}>
            {data?.map((service, index) =>
                <Typography key={index} sx={{
                    fontSize: 14, m: 'auto 0 auto auto',
                }}>
                    đ{format_numeral_price(performMountContracTotal(service))}
                </Typography>
            )}
        </Box>
    )
}

export default ContractTotal