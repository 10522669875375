import ServicePriceApi from '@/apis/beta/Service/ServicePriceApi'
import { queryClientRefetchScope } from '@/functions'
import { useNotificationContext } from '@contexts/notification'
import { Box, Button, CircularProgress, Collapse, Divider, FormControl, Grid, Typography } from '@mui/material'
import { useMemo } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
// import SWITCH_CDN_COMMIT from './Commit'
import ServiceConfigoptionApi from '@/apis/beta/Service/ServiceConfigoptionApi'
import withSuspense from '@/hocs/withSuspense'
import FORM_BASE from '@components/shared/FormCustomized/form'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import WarningValues from '@components/Templates/SwiftFederation/ContentDeliveryNetwork(CDN)/ServicePackage/Package/Commit/WarningValues'

export const BANDWIDTHS = {
    domains: 'Domains',
    origin_shield_traffics: 'OriginShieldTraffics',
    origin_shield_requests: 'OriginShieldRequests',
    dns_requests: 'DNSRequests',
    basic_cdn_traffics: 'BasicCDNTraffics',
    basic_cdn_requests: 'BasicCDNRequests',
}

export const translates = (name = '') => {
    return BANDWIDTHS[name]
}

const Price = ({ serviceId = -1 }) => {
    const queryClient = useQueryClient()
    const { setNotification } = useNotificationContext()

    const { data: { configoptions } } = ServiceConfigoptionApi.List({ serviceId: serviceId })

    const { mutateAsync, isLoading } = useMutation(ServicePriceApi.ChangePackageConfig)

    const methods = useForm({
        defaultValues: useMemo(() => {
            return {
                service_configoptions: configoptions?.map((configoption) => {
                    const commit = configoption?.commitment || undefined
                    return {
                        unit: configoption?.unit,
                        name: configoption?.name,
                        id: configoption?.id,
                        service_configoption_id: configoption?.service_configoption_id,
                        commitment: {
                            warning_enabled: commit?.warning_enabled || false,
                            warning_values: commit?.warning_values || []
                        }
                    }
                })
            }
        }, [configoptions])
    })

    const { fields } = useFieldArray({
        control: methods.control,
        name: "service_configoptions",
    });

    const rebuildServicePackageConfigs = (packageConfigs = []) => {
        return packageConfigs?.map((configoption) => {
            return ({
                service_configoption_id: configoption?.service_configoption_id || -1,
                name: configoption?.name || 'Unknown',
                package_config: {
                    warning_enabled: configoption?.commitment?.warning_enabled,
                    warning_values: configoption?.commitment?.warning_values || []
                }
            })
        })
    }

    const onSubmit = async (values) => {
        const response = await mutateAsync({
            serviceId,
            data: rebuildServicePackageConfigs(values?.service_configoptions || [])
        })
        if (!response || response?.errors) {
            return setNotification({ open: true, message: response?.errors?.[0]?.message, severity: 'error' })
        }
        queryClientRefetchScope(queryClient, 'service')
        return setNotification({
            open: true, message: 'Thay đổi gói cố định thành công', severity: 'success'
        })
    }
    return (
        <FormProvider {...methods}>
            <Box
                component="form"
                onSubmit={methods.handleSubmit(onSubmit)}
                sx={{
                    p: 2,
                    border: '1px solid rgba(0,0,0,0.18)',
                    borderRadius: '4px'
                }}>
                <Typography sx={{ fontWeight: 'medium', mb: 1.25 }}>
                    Gói Cố Định
                </Typography>
                {fields?.map((field, index) => {
                    return (
                        <Grid container spacing={2} key={field.id} mb={2}>
                            {index !== 0 && <Grid item xs={12}>
                                <Divider light sx={{}} />
                            </Grid>}
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 14, fontWeight: 'medium' }}>
                                    {translates(field?.name)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name={`service_configoptions.[${index}].commitment.warning_enabled`}
                                    control={methods.control}
                                    render={({ field: { value, onChange } }) =>
                                        <FormControl fullWidth>
                                            <FORM_BASE.Switch value={value} onChange={onChange} label="Thông Báo Ngưỡng Sử Dụng" />
                                        </FormControl>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Collapse in={!!methods.watch(`service_configoptions.[${index}].commitment.warning_enabled`)}>
                                    <WarningValues name={`service_configoptions.[${index}].commitment.warning_values`} />
                                </Collapse>
                            </Grid>
                        </Grid>
                    )
                })}
                {methods?.formState?.isDirty && <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <Button
                        disabled={isLoading}
                        startIcon={isLoading && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                        type='submit'
                        variant='contained'
                        size='small'
                    >
                        Thay Đổi Gói
                    </Button>
                </Box>}
            </Box>
        </FormProvider>
    )
}

export default withSuspense(Price, MuiSkeleton["LoadingDotBox"])