import BreadCrumb from "@/components/shared/BreadCrumb"
import withPermission from '@/hocs/withPermission'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useNavigate, useSearchParams } from "react-router-dom"
import BusinessForm from '../shared/Form'

const Create = () => {
    let navigate = useNavigate()

    const [searchParams] = useSearchParams()
    console.log(searchParams.get('customer_id'))

    return (
        <Box>
            <Typography sx={{
                fontSize: { xs: 18, md: 24 },
                fontWeight: 'medium'
            }}>Tạo Phiếu Công Tác</Typography>
            <BreadCrumb
                data={[
                    { label: "Tài Khoản Cá Nhân", reactLink: "/account/businesses" },
                    { label: "Tạo Phiếu Công Tác" }
                ]}
            />
            <BusinessForm
                customerId={searchParams.get('customer_id') || -1}
                handleClose={() => { return navigate('/account/businesses', { replace: true }) }} type='create' />
        </Box>
    )
}

export default withPermission(Create, { feature: "business", action: 'create' })