import React, { createContext, useContext } from "react";
import StateArrayContextProvider from "./stateArray";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import MultipleSelectCustomize from "@components/shared/MultipleSelectCustomize";
import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import { TextField } from "@mui/material";
import DatePicker from "@components/datepickers/DateRangPicker";
import { INDUSTRIES, SERVICES } from "@/libs/constants";

const CustomerContext = createContext();

const CustomerContextProvider = ({ children }) => {
    const Components = {
        Employee: withSuspense(({ value: values = [], onChange, ...props }) => {
            const { data: employees } = EmployeeApi.ListEmployees()
            return (
                <MultipleSelectCustomize
                    {...props}
                    value={employees?.filter(e => values?.includes(e?.employee_id))}
                    options={[...employees]}
                    formatCollection={{ label: 'employee_name', value: 'employee_id' }}
                    compareAttributes={['employee_name', 'employee_email_company']}
                    onChange={(_event, newValues) => onChange([...newValues?.map(e => e?.employee_id)])}
                    input={{
                        label: 'Nhân Viên/ Sale Phụ Trách',
                        placeholder: 'Nhập tên nhân viên/ sale phụ trách...',
                    }}
                />
            )
        }, MuiSkeleton["TextField"]),
        TextField: ({ value, onChange, ...props }) => {
            return <TextField size="small" {...props} value={value} onChange={onChange} />
        },
        DatePicker: ({ value: values = [], onChange, ...props }) => {
            return (
                <DatePicker value={values} handler={onChange} {...props} />
            )
        },
        MultiSelect: ({ value: values = [], options = [], onChange, ...props }) => {
            return <MultipleSelectCustomize
                options={options}
                value={options?.filter(option => values.includes(option?.value))}
                onChange={(__, newValues) => onChange(newValues?.map(option => option?.value))}
                {...props}
            />
        }
    }

    const initialQueries = [
        {
            key: 'customer_service_informations.customer_sale_followers',
            value: [],
            type: 'object', operator: '$inRange',
            component: 'Employee',
            onChange: (newValues) => onChangeQueries({ key: 'customer_service_informations.customer_sale_followers', value: newValues }),
        },
        {
            key: 'customer_service_informations.customer_industry',
            value: [],
            type: 'object', operator: '$in',
            component: 'MultiSelect',
            params: {
                options: INDUSTRIES,
                input: { label: 'Lĩnh Vực', placeholder: 'Chọn lĩnh vực tìm kiếm...' }
            },
            onChange: (newValues) => onChangeQueries({ key: 'customer_service_informations.customer_industry', value: newValues })
        },
        {
            key: 'service',
            value: [],
            type: 'object', operator: '$inRange',
            component: 'MultiSelect',
            params: { options: SERVICES, input: { label: 'Dịch vụ', placeholder: 'Chọn dịch vụ tìm kiếm' } },
            onChange: (newValues) => onChangeQueries({ key: 'service', value: newValues })
        },
        {
            key: 'customer_created_at',
            value: [null, null],
            type: 'date', operator: '$btw',
            component: 'DatePicker',
            onChange: (newValues) => onChangeQueries({ key: 'customer_created_at', value: newValues })
        }
    ]

    const onChangeQueries = ({ key, value }) => {
        console.log(`onChangeQueries`, key, value)
        return setCustomerState(prevState => ({
            ...prevState,
            queries: prevState?.queries?.map(query => query.key === key ? { ...query, value } : query)
        }))
    }

    const onChangeQueryString = (newString = '') => {
        return setCustomerState(prevState => ({
            ...prevState,
            queryString: newString
        }))
    }

    const onChangeQuery = (newString = '') => {
        return setCustomerState(prevState => ({
            ...prevState,
            queryContext: newString
        }))
    }

    const [customerState, setCustomerState] = React.useState({
        queryString: '',
        onChangeQueryString: onChangeQueryString,
        queryContext: '',
        onChangeQuery,
        queries: initialQueries,
        onChangeQueries,
        Components
    })

    return (
        <CustomerContext.Provider value={{ ...customerState }}>
            <StateArrayContextProvider>
                {children}
            </StateArrayContextProvider>
        </CustomerContext.Provider>
    );
};

export const useCustomerContext = () => {
    return useContext(CustomerContext)
}

export default CustomerContextProvider;
