import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class DomainApi {
    Supports = () =>
        useQueryWithCustomized(
            ["domain.support.list"],
            async () => {
                return await requestWithToken(`${API_V3_URL}/domains/supports`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data?.domains;
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                staleTime: 10 * 60 * 1000,
                suspense: true
            }
        );

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new DomainApi();
