import { MenuProps } from "@/styles/common";
import {
  Box,
  MenuItem,
  Select,
  TablePagination,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const PaginationCustomized = ({
  total = 0,
  pageSize = 0,
  page = 0,
  onChange,
}) => {
  if (total === 0 || pageSize === 0) return null;

  return (
    <Box sx={{ display: "flex", m: "auto 16px auto 8px" }}>
      <Typography sx={{ fontSize: 14, m: "auto 4px auto 0" }}>Trang</Typography>
      <Select
        size="small"
        sx={{
          m: "auto",
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
        }}
        value={page + 1}
        onChange={(event) => {
          onChange(event?.target?.value - 1);
        }}
        MenuProps={{
          ...MenuProps,
          PaperProps: {
            style: {
              maxHeight:
                48 *
                (Math.ceil(total / pageSize) > 4
                  ? 4.5
                  : Math.ceil(total / pageSize)) +
                8,
              width: 60,
              height: 250,
              overflow: "hidden",
              overflowY: "auto",
              marginBottom: 0,
              "&::WebkitScrollbar": {
                width: "2px",
                "&-track": {
                  background: "#777680",
                },
                "&-thumb": {
                  background: "#1a237e",
                },
              },
            },
          },
        }}
      >
        {Array.from({ length: Math.ceil(total / pageSize) })
          .map((_, i) => i + 1)
          ?.map((number) => {
            return (
              <MenuItem
                key={number}
                value={number}
                disabled={number === page + 1}
              >
                {number}
              </MenuItem>
            );
          })}
      </Select>
    </Box>
  );
};

const Pagination = (props) => {
  const {
    onPageSizeChange,
    onPageChange,
    rowsPerPageOptions,
    pageSize,
    page,
    rowCount,
  } = props;


  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (page === 0) {
      return;
    } else {
      if (rowCount / pageSize < page) {
        onPageChange(
          searchParams.get('page') || 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, rowCount]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <TablePagination
        labelRowsPerPage="Chọn số lượng bản ghi"
        labelDisplayedRows={
          (props = ({ from, to, count }) => {
            return `${from} đến ${to} | ${count} bản ghi`;
          })
        }
        component="div"
        count={rowCount}
        page={page}
        onPageChange={(event, newPage) => onPageChange(newPage)}
        onRowsPerPageChange={(event) =>
          onPageSizeChange(event?.target?.value || 10)
        }
        rowsPerPage={pageSize}
        rowsPerPageOptions={rowsPerPageOptions}
      />
      <PaginationCustomized
        page={page}
        total={rowCount}
        pageSize={pageSize}
        onChange={onPageChange}
      />
    </Box>
  );
};

export default Pagination;
