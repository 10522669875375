import DepartmentApi from "@/apis/beta/Employee/DepartmentApi";
import PositionApi from "@/apis/beta/Employee/PositionApi";
import { buildQueryFilterCondition } from "@/functions";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { Grid, FormControl } from "@mui/material";
import React, { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

const EmployeeDepartment = () => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const employee_department = watch("employee_department");

  const filterablePositions = useMemo(() => {
    const fieldQueries = [
      {
        name: "positions.department_id",
        operator: "$eq",
        value: employee_department,
      },
    ];

    return buildQueryFilterCondition("$or", 0, fieldQueries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee_department]);

  const { data: departments } = DepartmentApi.ListDepartments();

  const { data: positions } = filterablePositions
    ? PositionApi.ListPositions({
        query: filterablePositions,
      })
    : { data: null }

  return (
    <>
      <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
        <Controller
          name="employee_department"
          control={control}
          render={({ field: { value, onChange } }) => {
            const selected =
              departments?.departments?.find(
                (department) => department?.department_id === value
              ) || null;
            return (
              <FormControl fullWidth>
                <SingleSelectCustomize
                  value={{value: selected?.department_id, label: selected?.department_name}}
                  options={departments?.departments?.map((item) => ({
                    label: item?.department_name,
                    value: item?.department_id,
                  }))}
                  input={{
                    placeholder: "Chọn phòng ban...",
                    label: "Phòng ban (*)",
                  }}
                  onChange={(event, newValue) => {
                    onChange(newValue?.value || -1);
                  }}
                />
                {!!errors?.employee_sex && (
                  <ErrorHelperText message={errors?.employee_sex?.message} />
                )}
              </FormControl>
            );
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} xl={6} lg={4}>
        <Controller
          name="employee_position"
          control={control}
          render={({ field: { value, onChange } }) => {
            const selected =
              positions?.find(
                (position) => position?.position_id === value
              ) || null;

            return (
              <FormControl fullWidth>
                <SingleSelectCustomize
                  value={{value: selected?.position_id, label: selected?.position_name}}
                  options={positions?.map((item) => ({
                    label: item?.position_name,
                    value: item?.position_id,
                  }))}
                  input={{
                    placeholder: "Chọn vị trí...",
                    label: "Vị trí (*)",
                  }}
                  onChange={(event, newValue) => {
                    onChange(newValue?.value || -1);
                  }}
                />
                {!!errors?.employee_sex && (
                  <ErrorHelperText message={errors?.employee_sex?.message} />
                )}
              </FormControl>
            );
          }}
        />
      </Grid>
    </>
  );
};

export default EmployeeDepartment;
