import { format_number_usage, format_numeral_price } from "@/functions"
import { UNLIMITED } from "@/libs/constants"
import _ from "lodash"

export const buildInvoiceItem = ({ item = {}, overrideMetadata = {} }) => {
    let invoiceItem = {
        ...item,
        ...overrideMetadata,
    }
    return {
        ...invoiceItem,
        invoice_item_value:
            invoiceItem?.invoice_item_value >= UNLIMITED ? "Không Giới Hạn" :
                format_numeral_price(invoiceItem?.invoice_item_value)
    }
}

export const findLine = ({ lines = [], scope = 'subtotal' }) => {
    switch (scope) {
        case 'commitment':
            return lines?.find(line => line?.invoice_item_description?.toLowerCase()?.match(/commitment/))
        case 'subtotal':
            return lines?.find(line => line?.invoice_item_description?.toLowerCase()?.match(/^subtotal/)) || null
        case 'usages':
            return lines?.filter(line => !line?.invoice_item_description?.toLowerCase()?.match(/^(subtotal|addon|commitment)/)) || []
        default:
            return null
    }
}

export const checkLineCommitment = ({ lines = [] }) => {
    return Boolean(findLine({ lines, scope: 'commitment' }))
}

export const KeepLineCommitment = ({ subTotal, commitment }) => {
    return Boolean(!!subTotal && subTotal?.invoice_item_value > 0
        && (
            subTotal?.invoice_item_value > commitment?.invoice_item_value
        ))
}

export const buildLine = ({ line = {}, metadata = {}, scope = 'default' }) => {
    switch (scope) {
        case "subtotal":
            return buildInvoiceItem({
                item: line, overrideMetadata: {
                    invoice_item_name: metadata?.invoice_item_name,
                    invoice_item_description: 'Tổng sử dụng'
                }
            })
        case "commitment":
            return buildInvoiceItem({
                item: line, overrideMetadata: {
                    invoice_item_name: metadata?.invoice_item_name,
                    invoice_item_description: 'Gói cố định'
                }
            })
        case "default":
            return buildInvoiceItem({ item: line, overrideMetadata: metadata })
        default:
            break;
    }
}

export const loggingTierds = ({ lines = [], name = '', description = 'Sử dụng vượt gói cố định' }) => {
    const line = {
        ...lines[0],
        invoice_item_amount: _.sumBy(lines, (line) => parseFloat(line?.invoice_item_amount)),
        invoice_item_deposit: _.sumBy(lines, (line) => parseFloat(line?.invoice_item_deposit)),
        invoice_item_subtotal: _.sumBy(lines, (line) => parseFloat(line?.invoice_item_subtotal)),
        invoice_item_value: _.sumBy(lines, (line) => parseFloat(line?.invoice_item_value)),
    }
    return buildLine({
        line: line, metadata: {
            invoice_item_name: name,
            invoice_item_description: description
        }
    })
}

export const loggingProgressive = ({ lines = [] }) => {
    const reGroups = _.groupBy(lines, (line) => {
        return [line?.invoice_item_name, line?.invoice_item_method, line?.invoice_item_pricing_apply]
    })
    if (!reGroups) return []

    let arrs = []
    _.forEach(reGroups, (values, keys) => {
        const startRange = values[0]?.invoice_item_description?.split(' - ')?.[0]?.replace(/[,(\]]/, '')
        const endRange = values[values.length - 1]?.invoice_item_description?.split(' - ')?.[1]?.replace(/[,(\]]/, '')

        let invoice_item_value = `${format_number_usage(startRange)} - ${endRange}`
        arrs.push(buildLine({
            line: values[values?.length - 1],
            metadata: {
                invoice_item_value,
                invoice_item_name: keys?.split(',')[0],
                invoice_item_description: 'Range',
                invoice_item_amount: _.sumBy(values, (u) => u?.invoice_item_amount),
                invoice_item_deposit: _.sumBy(values, (u) => u?.invoice_item_deposit),
                invoice_item_subtotal: _.sumBy(values, (u) => u?.invoice_item_subtotal)
            }
        }))
    })
    return arrs
}
export const formatLineName = (name = '') => {
    switch (name?.toLowerCase()) {
        case 'local':
            return 'Băng thông trong nước';
        case 'global':
            return 'Băng thông quốc tế';
        case 'tw':
            return 'Băng thông Taiwan';
        case 'cn':
            return 'Băng thông China';
        case 'transcoding':
            return 'Transcoding';
        case 'transmuxing':
            return 'Transmuxing';
        case 'recording':
            return 'Recording';
        case 'storage lfd':
        case 'storage minio':
            return 'Dung lượng lưu trữ';
        case 'jobs':
            return 'Số luồng sử dụng';
        default:
            return name;
    }
}