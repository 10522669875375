import BreadCrumb from "@/components/shared/BreadCrumb";
import withPermission from '@/hocs/withPermission';
import { Box, Typography } from '@mui/material';
import React from 'react';
import GroupRoleForm from '../shared/GroupRoleForm';

const Create = () => {
    return (
        <Box>
            <Typography className='page-title'>
                Thêm Nhóm Quyền
            </Typography>
            <BreadCrumb
                data={[
                    { label: "Quản Lý Nhóm Quyền", reactLink: "/group-roles" },
                    { label: "Thêm Nhóm Quyền" },
                ]}
            />
            <GroupRoleForm type="create" />
        </Box>

    )
}

export default withPermission(Create, { feature: 'group-role', action: 'create' })