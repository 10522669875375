import ServiceApi from "@/apis/beta/Service/ServiceApi";
import withSuspense from "@/hocs/withSuspense";
import DataGridCustomize from "@components/DataGrid";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import React from "react";
import { format_date_short } from "@/functions";
import Metadata from "./Metadata";

const ConversantService = ({ serviceId }) => {
  const { data } = ServiceApi.Logger({ serviceId });
  const [initialColumns] = React.useState([
    {
      field: "service_update_description",
      headerName: "Mô Tả",
      width: 250,
    },
    {
      field: "metadata",
      headerName: "Thông Tin Chi Tiết",
      width: 450,
      renderCell: ({ value }) => <Metadata data={value} />,
    },
    {
      field: "service_update_created_at",
      headerName: "Thời Gian Thực Hiện",
      width: 180,
      valueFormatter: ({ value }) => format_date_short(value),
    },
  ]);
  return (
    <DataGridCustomize
      rows={data
        ?.filter(
          (item) => item?.service_update_type === "SERVICE-SWIFTFEDERATION-LOGS"
        )
        ?.map((item, index) => ({ ...item, id: index }))}
      columns={initialColumns}
      components={{
        Toolbar: "disabled",
        Pagination: "disabled",
      }}
      rowCount={data?.length}
    />
  );
};

export default withSuspense(ConversantService, MuiSkeleton["DataGrid"]);
