import { Box, Typography } from "@mui/material";
import React from "react";
import EmployeeTabs from "../AccountTabs";
import Information from "../Information";

const Layout = ({ children }) => {
  return (
    <Box>
      <Typography className="page-title" sx={{ mb: 2 }}>
        Tài Khoản
      </Typography>
      <Information />
      <EmployeeTabs />
      {children}
    </Box>
  );
};

export default Layout;
